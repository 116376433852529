/* eslint-disable no-shadow */
import React, {
  useEffect,
  useState,
  useRef,
  createRef,
} from 'react';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';
import {
  Row,
  Col,
  Steps,
  Layout,
  Checkbox,
  Input,
  Icon,
  Select,
  message,
  Upload,
} from 'antd';
import {
  SpButton,
  SpH5,
  SpText,
  SpError,
  PaymentWrapper,
  PaymentFormWrapper,
} from 'components/DesignKit';
import imageOnError from 'utils/imageOnError';
import { formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  paymentPagesActions,
  paymentPagesDetailsActions,
  accountSlugActions,
  notificationActions,
} from 'store/actions';

import {
  PAGES,
  COUNTRIES,
  MAX_AMOUNT,
  CURRENCYCODE,

  MESSAGES,
  COUNTRY_PHONE_CODE,
} from 'appconstants';
import CONSTANTS from 'store/constants';
import formatAmount from 'utils/formatAmount';
import formatPaymentNumber from 'utils/formatNumber';
import SimpleReactValidator from 'simple-react-validator';
import PaymentClose from 'components/NavIcons/paymentClose';

import getPlaceholder from 'utils/getPaymentPagePlaceholder';
import getPaymentMetaData from 'utils/getPaymentMetaData';
import getPaymentCloneData from 'utils/getPaymentCloneData';
import Loading from 'components/Loading';

import Previewicon from 'components/NavIcons/previewIcon';
import Uploadicon from 'components/NavIcons/uploadIcon';
import { NOTIFICATION } from 'store/actionTypes';
import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';
import reorderIcon from '../../assets/imgs/reorder-icon.png';
import arrowUp from '../../assets/imgs/arrow-upward.png';
import arrowDown from '../../assets/imgs/arrow-down.png';
import arrowUpDisabled from '../../assets/imgs/arrow-upward-disabled.png';
import arrowDownDisabled from '../../assets/imgs/arrow-down-disabled.png';

import PageDetails from './components/PageDetails';
import AccontSlug from './components/AccontSlug';
import ReviewPaymentPage from './components/ReviewPaymentPage';


const { Step } = Steps;
const { Header } = Layout;
const { Option } = Select;
const { TextArea } = Input;

type Props = ContextRouter & {
  selectedAccount: Object,
  addPaymentPages: Function,
  fetchPaymentPagesDetails: Function,
  paymentPagesDetailsStatus: string,
  paymentPagesDetails: Object,
  setNotification: Function,
  validator: boolean,
  submitting: boolean,
  loading: boolean,
  accountSlug: Array<Object>,
  fetchAccountSlug: Function,
  history: {
    replace: Function,
  },
};

const AddPaymentPage = (props: Props) => {
  const {
    selectedAccount,
    history,
    addPaymentPages,
    validator,
    submitting,
    setNotification,
    loading,
    paymentPagesDetails,
    fetchPaymentPagesDetails,
    paymentPagesDetailsStatus,
    accountSlug,
    fetchAccountSlug,
  } = props;
  const { gid } = props.match.params;
  const GOOGLE_MAPS_API_KEY = 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds';
  const [, forceUpdate] = useState();
  const pageDetailsRef = createRef();
  const [refArray, setRefArray] = useState([pageDetailsRef]);
  const [current, setCurrent] = useState(0);
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  // const [documenturl, setDocumenturl] = useState();
  // const [description, setDescription] = useState();
  const [logo, setLogo] = useState();

  const [fileupload, setFileupload] = useState();

  const [termsAndConditions, setPageTerms] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [preview, setPreview] = useState();
  const [fileName, setFileName] = useState();
  const [contactUsEmail, setEmail] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [phoneNumberCode, setPhoneCode] = useState();
  const [show, setShow] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [venueAddress, setAddress] = useState('');
  const [receiptNote, setReceiptNote] = useState('');
  const [coordinate, setCoordinates] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [ytVideoHeading, setYoutubeHeading] = useState('');
  const [ytVideoDescription, setYoutubeDescription] = useState('');
  const [ytVideoId, setytVideoId] = useState('');
  const [location, setLocation] = useState(null);
  const [phoneFormate, setPhoneFormate] = useState();
  const [primaryColor, setColorCode] = useState('#007bff');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [paymentHeading, setPaymentHeading] = useState(null);
  const [serviceFeeEnabled, setServiceFeeEnabled] = useState(false);
  const [serviceFeeRate, setServiceFeeRate] = useState(null);
  const [taxPercentage, setTaxPercentage] = useState(null);
  const [serviceFee, setServiceFee] = useState(null);
  const [supportOfflinePayment, setSupportOfflinePayment] = useState(false);
  const [enableTip, setEnableTip] = useState(false);
  const [isTipOptional, setIsTipOptional] = useState(true);
  const [firstOption, setFirstOption] = useState(null);
  const [secondOption, setSecondOption] = useState(null);
  const [thirdOption, setThirdOption] = useState(null);
  const [showCombo, setShowCombo] = useState(true);
  const [loader, setLoading] = useState(false);
  const [showAmountValidationError, setShowAmountValidationError] = useState(false);
  const [addParent, setAddParent] = useState([
    {
      label: null,
      value: null,
      children: [
        {
          label: null,
          value: null,
        },
      ],
    },
  ]);
  const currencyName = (selectedAccount && (selectedAccount.currency) && (selectedAccount.currency.name));
  const supportedCurrency = (selectedAccount && selectedAccount.supportedCurrencies);
  const {
    contactEmail,
    name,
    largeLogo,
  } = selectedAccount;
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;

  const account = { name: name || null, largeLogo: largeLogo || null };
  const [slugGid, setSlugGid] = useState(null);
  const [slugLink, setSlugLink] = useState('');
  const [slugFlag, setSlugFlag] = useState(false);

  const pageData = {
    title,
    content,
    logo,
    preview,
    fileName,
    contactUsEmail,
    contactPhone,
    phoneNumberCode,
    termsAndConditions,
    location,
    primaryColor,
    venueAddress,
    receiptNote,
    coordinate,
    redirectUrl,
    paymentHeading,
    ytVideoId,
    youtubeUrl,
    phoneFormate,
    ytVideoHeading,
    ytVideoDescription,
    serviceFeeEnabled,
    serviceFeeRate,
    serviceFee,
    taxPercentage,
    supportOfflinePayment,
    slugGid,
    slugLink,
  };

  const newSlug = () => {
    const currentPage = 1;
    const params = '&sortBy=createdAt&direction=DESC';
    fetchAccountSlug({ currentPage, params });
  };

  useEffect(() => {
    if (gid) {
      fetchPaymentPagesDetails(gid);
    }
    newSlug();
  }, []);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      minLimit: {
        message: 'Min limit should be greater than zero',
        rule: val => val >= 1,
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      minAmount: {
        message: 'Min amount should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 1,
      },
      optionalMinAmount: {
        message: 'Min amount should be greater than or equal to zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      minValidAmount: {
        message: 'Max amount should be greater than zero',
        rule: val => val && Number(val) >= 1,
      },
      maxAmount: {
        message: 'Max amount should be greater than Min amount',
        rule: (val, params) => val && (Number(val) > Number(params[0])),
      },
      uploadLimit: {
        message: 'Upload limit should be between 1 and 100',
        rule: (val) => val && Number(val) > 0 && Number(val) <= 100,
      },
      tipAmount: {
        message: 'Tip should be > 0 and <= to 100',
        rule: val => Number(val && val.replace(/,/g, '')) > 0 && Number(val && val.replace(/,/g, '')) <= 100,
      },
      tipValidation1: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
      tipValidation: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const [productDetailsArray, setProductDetailsArray] = useState([{
    type: 'PRICE_AMOUNT_FIXED',
    label: null,
    description: null,
    amount: null,
    quantityMinLimit: 1,
    quantityMaxLimit: null,
    minPrice: '1.00',
    maxPrice: null,
    currencyCode: currencyName,
    currencyId: CURRENCYCODE(currencyName),
    isRequired: true,
    deleted: false,
    checked: 'checked',
    enableCombo: false,
    isComboBoxRequired: false,
    comboBox: [
      {
        label: null,
        value: null,
        children: [
          {
            label: null,
            value: null,
            additionalPrice: '0.00',
          },
        ],
      },
    ],
  }]);

  const addNewParentComboBox = (event) => {
    event.preventDefault();
    setAddParent([
      ...addParent,
      {
        label: null,
        value: null,
        children: [
          {
            label: null,
            value: null,
          },
        ],
      },
    ]);
  };


  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <Uploadicon />}
      <div className="ant-upload-text mt-2">Click or drag the file here to upload</div>
      <div className="mt-2"><SpText color="rgba(0, 0, 0, 0.45)">Support extension：.pdf </SpText></div>
    </div>
  );

  const beforeUpload = (file) => {
    // const isLt5M = file.size / 1024 / 1024 < 5;
    const PDF = 'application/pdf';
    // const DOCS = 'Doc/*';
    // const pattern = file.type.match(DOCS) || file.type.match(PDF);
    const pattern = file.type.match(PDF);
    if (!pattern) {
      message.error('File is not Doc or pdf');
      return false;
    }

    return true;
  };

  const addNewChildComboBox = (event, i) => {
    event.preventDefault();
    const list = [...addParent];
    const info = list[i].children.push({
      label: null,
    });
    setAddParent(list);
  };

  const onRemoveParentComboBox = (i) => {
    const list = [...addParent];
    list.splice(i, 1);
    setAddParent(list);
  };

  const onRemoveChildComboBox = (q, r) => {
    const list = [...addParent];
    list[q].children.splice(r, 1);
    setAddParent(list);
  };

  const addNewParent = (i) => {
    const list = [...productDetailsArray];
    list[i].comboBox.push({
      label: null,
      value: null,
      children: [
        {
          label: null,
          value: null,
          additionalPrice: '0.00',
        },
      ],
    });
    setProductDetailsArray(list);
  };

  // const handleDescriptionChange = (event) => {
  //   setDescription(event.target.value);
  // };

  const addNewChild = (event, i, q) => {
    event.preventDefault();
    const list = [...productDetailsArray];
    list[i].comboBox[q].children.push({
      label: null,
      value: null,
      additionalPrice: '0.00',
    });
    setProductDetailsArray(list);
  };

  const onRemoveParent = (i, q) => {
    const list = [...productDetailsArray];
    list[i].comboBox.splice(q, 1);
    setProductDetailsArray(list);
  };

  const onRemoveChild = (i, q, r) => {
    const list = [...productDetailsArray];
    list[i].comboBox[q].children.splice(r, 1);
    setProductDetailsArray(list);
  };

  const [customerDetailsArray, setCustomerDetailsArray] = useState([{
    type: 'INPUT_EMAIL',
    label: 'Email',
    isRequired: true,
    checked: 'checked',
    disableRequired: true,
  },
  {
    type: 'INPUT_PHONE',
    label: 'Phone',
    isRequired: true,
    checked: 'checked',
    disableRequired: true,
  },
  ]);

  const addProductDetails = (event) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setShow(true);
    setProductDetailsArray([...productDetailsArray, {
      type: 'PRICE_AMOUNT_FIXED',
      label: null,
      description: null,
      amount: null,
      quantityMinLimit: 1,
      quantityMaxLimit: null,
      minPrice: '1.00',
      maxPrice: null,
      currencyCode: currencyName,
      currencyId: CURRENCYCODE(currencyName),
      isRequired: true,
      deleted: false,
      checked: 'checked',
      enableCombo: false,
      isComboBoxRequired: false,
      comboBox: [
        {
          label: null,
          value: null,
          children: [
            {
              label: null,
              value: null,
              additionalPrice: '0.00',
            },
          ],
        },
      ],
    }]);
  };

  const removeProductDetails = (i) => {
    if (productDetailsArray.length === 2) {
      setShow(false);
    }
    const list = [...productDetailsArray];
    list.splice(i, 1);
    const hasTargetValue = list.some(obj => Object.values(obj).includes('COMBO_BOX'));
    if (hasTargetValue) {
      setShowCombo(false);
    } else {
      setShowCombo(true);
    }
    setProductDetailsArray(list);
  };

  const addCustomerDetails = (event) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setCustomerDetailsArray([...customerDetailsArray, {
      type: '',
      label: '',
      isRequired: true,
      checked: 'checked',
      value: '',
      validation: '',
      disableRequired: false,
      documentUrl: '',
      description: '',
      quantityMinLimit: '',
      quantityMaxLimit: '',

    }]);
  };

  const removeCustomerDetails = (j) => {
    const list = [...customerDetailsArray];
    list.splice(j, 1);
    setCustomerDetailsArray(list);
  };

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.PAYMENTPAGES);
    }
  }, [validator]);

  const getPlaceDetails = async (place) => {
    if (place) {
      const data = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(place)}&key=${GOOGLE_MAPS_API_KEY}`)
        .then(response => response.json())
        .catch(error => console.error(error));
      // eslint-disable-next-line
      const { place_id } = data.results[0];
      setDefaultLocation({
        label: place,
        value: {
          description: place,
          place_id,
          reference: place_id,
        },
      });
    }
  };

  const foundOccuranceOfType = (data) => {
    let emailFound = false;
    let phoneFound = false;

    for (const item of data) {
      if (item.type === 'INPUT_EMAIL' && item.isRequired && !emailFound) {
        item.disableRequired = true;
        emailFound = true; // Set the email flag to true
      } else if (item.type === 'INPUT_PHONE' && item.isRequired && !phoneFound) {
        item.disableRequired = true;
        phoneFound = true; // Set the phone flag to true
      }
    }
    return data;
  };

  useEffect(() => {
    if (gid && (paymentPagesDetails.gid)) {
      setTitle(paymentPagesDetails.title);
      setContent(paymentPagesDetails.content);
      setLogo(paymentPagesDetails.logo);
      setFileName(paymentPagesDetails.logo);
      setEmail(paymentPagesDetails.contactUsEmail);
      const phoneResult = (paymentPagesDetails && (paymentPagesDetails.contactUsPhone))
        ? findPhoneNumbersInText(paymentPagesDetails && paymentPagesDetails.contactUsPhone) : null;
      if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
        setPhoneCode(`+${phoneResult[0].number.countryCallingCode}`);
        setContactPhone(phoneResult[0].number.nationalNumber);
        const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
        const formater = data.substr(data.indexOf(' ') + 1);
        setPhoneFormate(formater);
      }
      setPreview(
        <img
          src={`${paymentPagesDetails.logo}?time=${new Date().getTime()}`}
          alt=""
          onError={event => imageOnError(event)}
          style={{ width: '100%' }}
        />,
      );
      const newArray = paymentPagesDetails.paymentPageMetaFields.map(field => {
        const {
          type, label, isRequired, checked, disableRequired, documentUrl, description,
        } = field;
        return {
          type, label, isRequired, checked, disableRequired, documentUrl, description,
        };
      });

      setCustomerDetailsArray(newArray);
      setPageTerms(paymentPagesDetails.termsAndConditions);
      setColorCode(paymentPagesDetails.primaryColor || '#007bff');
      setAddress(paymentPagesDetails.venueAddress);
      getPlaceDetails(paymentPagesDetails.venueAddress);
      setCoordinates(paymentPagesDetails.coordinate);
      setYoutubeUrl(paymentPagesDetails.ytVideoId && `https://www.youtube.com/watch?v=${paymentPagesDetails.ytVideoId}`);
      setYoutubeHeading(paymentPagesDetails.ytVideoHeading);
      setYoutubeDescription(paymentPagesDetails.ytVideoDescription);
      setytVideoId(paymentPagesDetails.ytVideoId);
      setRedirectUrl(paymentPagesDetails.redirectUrl);
      setPaymentHeading(paymentPagesDetails.paymentHeading);
      setServiceFeeEnabled(paymentPagesDetails.serviceFeeEnabled);
      setReceiptNote(paymentPagesDetails.receiptNote);
      setSupportOfflinePayment(paymentPagesDetails.supportOfflinePayment);
      setServiceFeeRate(paymentPagesDetails.serviceFeeRate
        ? ((paymentPagesDetails.serviceFeeRate)).toFixed(2) : null);
      setServiceFee(paymentPagesDetails.serviceFee
        ? ((paymentPagesDetails.serviceFee).toFixed(2)) : null);
      setTaxPercentage(paymentPagesDetails.taxPercentage
        ? (paymentPagesDetails.taxPercentage).toFixed(2) : null);
      const tipDetails = paymentPagesDetails.paymentPageMetaFields ? paymentPagesDetails.paymentPageMetaFields.filter(res => res.type === 'TIP') : '';
      if (tipDetails && tipDetails.length > 0) {
        setEnableTip(true);
        setIsTipOptional((tipDetails[0].isRequired));
        setFirstOption((tipDetails[0].tipPercentages[0] / 100).toFixed(2));
        setSecondOption((tipDetails[0].tipPercentages[1] / 100).toFixed(2));
        setThirdOption((tipDetails[0].tipPercentages[2] / 100).toFixed(2));
      }
      // eslint-disable-next-line
      const productDetails = paymentPagesDetails.paymentPageMetaFields ? paymentPagesDetails.paymentPageMetaFields.filter(res => res.type === 'PRICE_AMOUNT_FIXED' || res.type === 'PRICE_AMOUNT_CUSTOMER_DECIDED' || res.type === 'PRICE_AMOUNT_QUANTITY_BASED' || res.type === 'COMBO_BOX') : '';
      // eslint-disable-next-line
      const customerDetails = paymentPagesDetails.paymentPageMetaFields ? paymentPagesDetails.paymentPageMetaFields.filter(res => res.type !== 'PRICE_AMOUNT_FIXED' && res.type !== 'PRICE_AMOUNT_CUSTOMER_DECIDED' && res.type !== 'PRICE_AMOUNT_QUANTITY_BASED' && res.type !== 'COMBO_BOX' && res.type !== 'TIP') : '';
      const productResult = productDetails ? productDetails.map((result) => {
        let currencyName1;
        if (result.currency) {
          currencyName1 = result.currency.name;
        }
        let isRequired;
        if (result.isRequired) {
          isRequired = 'checked';
        } else {
          isRequired = '';
        }
        if (result.type === 'COMBO_BOX') {
          setShowCombo(false);
        }
        // eslint-disable-next-line
        let temp = Object.assign({ "currencyCode": currencyName1, "checked": isRequired }, result);
        if (temp.amount) {
          const total = (temp.amount / 100).toFixed(2);
          temp.amount = formatPaymentNumber(total, currencyType);
        }
        if (temp.minPrice) {
          const min = (temp.minPrice / 100).toFixed(2);
          temp.minPrice = formatPaymentNumber(min, currencyType);
        }
        if (temp.maxPrice) {
          const max = (temp.maxPrice / 100).toFixed(2);
          temp.maxPrice = formatPaymentNumber(max, currencyType);
        }
        if (temp.minPrice === '0.00') {
          temp.minPrice = Number(temp.minPrice);
        }
        if (temp.comboBox) {
          setAddParent(temp.comboBox);
        }
        if (temp.comboBox === null) {
          temp.enableCombo = false;
          temp.isComboBoxRequired = false;
          temp.comboBox = [
            {
              label: null,
              value: null,
              children: [
                {
                  label: null,
                  value: null,
                  additionalPrice: '0.00',
                },
              ],
            },
          ];
        } else {
          temp.enableCombo = true;
        }
        // eslint-disable-next-line
        delete temp.currency;
        return temp;
      }) : '';
      setProductDetailsArray(productResult);
      setCustomerDetailsArray(foundOccuranceOfType(customerDetails));
    }
  }, [paymentPagesDetailsStatus]);

  useEffect(() => {
    if (current === 3 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const YouTubeGetID = (url) => {
    const url1 = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    // eslint-disable-next-line no-useless-escape
    return (url1[2] !== undefined) ? url1[2].split(/[^0-9a-z_\-]/i)[0] : url1[0];
  };

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const steps = [
    {
      title: 'Page Details',
    },
    {
      title: 'Product Details',
    },
    {
      title: 'Customer Details',
    },
    {
      title: 'Account Slug',
    },
    {
      title: 'Review',
    },
  ];

  const next = (event) => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      setTitle(valuesArray[0].title);
      setContent(valuesArray[0].content);
      setLogo(valuesArray[0].logo ? valuesArray[0].logo : 'logo_test');
      setPreview(valuesArray[0].preview);
      setFileName(valuesArray[0].fileName);
      setEmail(valuesArray[0].contactUsEmail);
      setContactPhone(valuesArray[0].contactPhone);
      setPhoneFormate(valuesArray[0].phoneFormate);
      setPhoneCode(valuesArray[0].phoneNumberCode);
      setPageTerms(valuesArray[0].termsAndConditions ? valuesArray[0].termsAndConditions : null);
      setAddress(valuesArray[0].venueAddress ? valuesArray[0].venueAddress : null);
      setReceiptNote(valuesArray[0].receiptNote ? valuesArray[0].receiptNote : null);
      setCoordinates(valuesArray[0].coordinate ? valuesArray[0].coordinate : null);
      setYoutubeUrl(valuesArray[0].youtubeUrl ? valuesArray[0].youtubeUrl : null);
      setYoutubeHeading(valuesArray[0].ytVideoHeading ? valuesArray[0].ytVideoHeading : null);
      setRedirectUrl(valuesArray[0].redirectUrl ? valuesArray[0].redirectUrl : null);
      setPaymentHeading(valuesArray[0].paymentHeading ? valuesArray[0].paymentHeading : null);
      setLocation(valuesArray[0].location ? valuesArray[0].location : null);
      setColorCode(valuesArray[0].primaryColor);
      setYoutubeDescription(valuesArray[0].ytVideoDescription ? valuesArray[0].ytVideoDescription : null);
      setServiceFeeEnabled(valuesArray[0].serviceFeeEnabled);
      setSupportOfflinePayment(valuesArray[0].supportOfflinePayment);
      if (valuesArray[0].serviceFeeRate) {
        const feerate = Number((valuesArray[0].serviceFeeRate).replace(/[^\d]/g, ''));
        setServiceFeeRate(feerate);
      }
      if (valuesArray[0].taxPercentage) {
        const taxrate = Number((valuesArray[0].taxPercentage).replace(/[^\d]/g, ''));
        setTaxPercentage(taxrate);
      } else setTaxPercentage(null);
      if (valuesArray[0].serviceFee) {
        const fee = Number((valuesArray[0].serviceFee).replace(/[^\d]/g, ''));
        setServiceFee(fee);
      }
      if (valuesArray[0].serviceFeeEnabled === false) {
        setServiceFeeRate(null);
        setServiceFee(null);
      }
      let ytCode;
      if (valuesArray[0].youtubeUrl) {
        ytCode = YouTubeGetID(valuesArray[0].youtubeUrl);
      }
      setytVideoId(ytCode || null);
      simpleValidator.current.hideMessages();
      forceUpdate();
    } else if (current === 1) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      // eslint-disable-next-line max-len
      const checkAmountValidation = productDetailsArray.filter(res => res.type === 'PRICE_AMOUNT_FIXED' || res.type === 'PRICE_AMOUNT_CUSTOMER_DECIDED' || res.type === 'PRICE_AMOUNT_QUANTITY_BASED');
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      if (checkAmountValidation && checkAmountValidation.length === 0) {
        setShowAmountValidationError(true);
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      simpleValidator.current.hideMessages();
      setShowAmountValidationError(false);
      forceUpdate();
    } else if (current === 2) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
    } else if (current === 3) {
      valuesArray.push(refArray[0].current.getValues());
      setSlugGid(valuesArray[0].slugGid);
      setSlugLink(valuesArray[0].slugLink);
      setCurrent(current + 1);
    } else if (current === 4) {
      setDisableButton(true);
      const contactNumber = `${pageData.phoneNumberCode}${pageData.contactPhone}`;
      const contactList = { contactUsPhone: contactNumber, status: 'ACTIVE' };
      if (serviceFeeEnabled && (serviceFeeRate === null)) {
        pageData.serviceFeeRate = 0;
      }
      if (serviceFeeEnabled && (serviceFee === null)) {
        pageData.serviceFee = 0;
      }
      const { preview: _, ...noPreviewData } = pageData;
      const data = Object.assign(noPreviewData, contactList);
      let payload;
      if (gid) {
        payload = getPaymentCloneData(data,
          productDetailsArray,
          customerDetailsArray,
          addParent,
          enableTip,
          isTipOptional,
          firstOption,
          secondOption,
          thirdOption);
      } else {
        payload = getPaymentMetaData(data,
          productDetailsArray,
          customerDetailsArray,
          addParent,
          enableTip,
          isTipOptional,
          firstOption,
          secondOption,
          thirdOption);
      }
      if (slugFlag === true) {
        payload.accountSlugGid = null;
        payload.slugGid = null;
        payload.slugLink = '';
      } else {
        payload.accountSlugGid = slugGid;
      }
      addPaymentPages(payload);
      setRedirect(true);
    }
  };

  const withoutSlug = () => {
    setSlugFlag(true);
    next();
  };

  const prev = (event) => {
    event.preventDefault();
    setDisableButton(false);
    setSlugFlag(false);
    setCurrent(current - 1);
  };

  const onArrowUp = (fromIndex) => {
    const dragdata = [...customerDetailsArray];
    const item = dragdata.splice(fromIndex, 1)[0];
    const toIndex = fromIndex - 1;
    dragdata.splice(toIndex, 0, item);
    setCustomerDetailsArray(dragdata);
  };

  const onArrowDown = (fromIndex) => {
    const dragdata = [...customerDetailsArray];
    const item = dragdata.splice(fromIndex, 1)[0];
    const toIndex = fromIndex + 1;
    dragdata.splice(toIndex, 0, item);
    setCustomerDetailsArray(dragdata);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = async (info, j) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);
      const file = info.file.originFileObj;
      const keyType = 'WAIVER_DOCUMENT';
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPLOAD_PAYMENTPAGE_WAIVER}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        // eslint-disable-next-line max-len
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.UPLOAD_PAYMENTPAGE_WAIVER}`, { fileUrl: entity, keyType });
        setFileupload(fileUrl);
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview(<img src={imageUrl} alt="..." style={{ width: '115px' }} />);
          });
        } else {
          setPreview(<Previewicon />);
        }
        setFileName(info.file.name);


        const list = [...customerDetailsArray];

        list[j].documentUrl = fileUrl;

        setCustomerDetailsArray(list);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };


  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(customerDetailsArray);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setCustomerDetailsArray(reorderedItems);
  };

  if (loading && gid) {
    return <Loading />;
  }

  return (
    <>
      {
        (gid && paymentPagesDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED) && (
          <Layout style={{ marginLeft: '0px', minHeight: '0px' }}>
            <Header style={{ color: '#fff', fontSize: '20px', background: '#18294c !important' }}>
              <Row type="flex" justify="space-between">
                <Col className="d-flex align-items-center">
                  Clone Payment Pages
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <span style={{ cursor: 'pointer' }} onClick={() => history.push(`${PAGES.PAYMENTPAGES}/${gid}`)}>
                    <PaymentClose />
                  </span>
                </Col>
              </Row>
            </Header>
            <PaymentWrapper style={{ background: current === 3 ? primaryColor : '' }}>
              <Steps current={current} className="mb-4 paymentPage" style={{ width: '100%' }}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <PaymentFormWrapper style={{ width: current === 3 ? '964px' : '800px' }}>
                <div>
                  {current === 0 && (
                    <PageDetails
                      ref={refArray[0]}
                      selectedAccount={selectedAccount}
                      pageData={pageData}
                      defaultLocation={defaultLocation}
                    />
                  )}
                  {current === 1 && (
                    <div>
                      {
                        productDetailsArray.map((item, i) => (
                          <div>
                            <Row>
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Input Field Type</SpH5></label>
                                <div>
                                  {
                                    showCombo ? (
                                      <Select
                                        style={{ width: '50%' }}
                                        value={item.type}
                                        onChange={(e) => {
                                          const list = [...productDetailsArray];
                                          list[i].type = e;
                                          const hasTargetValue = list.some(obj => Object.values(obj).includes('COMBO_BOX'));
                                          if (hasTargetValue) {
                                            setShowCombo(false);
                                          } else {
                                            setShowCombo(true);
                                          }
                                          setProductDetailsArray(list);
                                        }}
                                      >
                                        <Option key={1} value="PRICE_AMOUNT_FIXED">Fixed Amount</Option>
                                        <Option key={2} value="PRICE_AMOUNT_CUSTOMER_DECIDED">Customer Decided Amount</Option>
                                        <Option key={3} value="PRICE_AMOUNT_QUANTITY_BASED">Quantity Based Amount</Option>
                                        <Option key={4} value="COMBO_BOX">Combo Box</Option>
                                      </Select>
                                    ) : (
                                      <Select
                                        style={{ width: '50%' }}
                                        value={item.type}
                                        onChange={(e) => {
                                          const list = [...productDetailsArray];
                                          list[i].type = e;
                                          const hasTargetValue = list.some(obj => Object.values(obj).includes('COMBO_BOX'));
                                          if (hasTargetValue) {
                                            setShowCombo(false);
                                          } else {
                                            setShowCombo(true);
                                          }
                                          setProductDetailsArray(list);
                                        }}
                                      >
                                        <Option key={1} value="PRICE_AMOUNT_FIXED">Fixed Amount</Option>
                                        <Option key={2} value="PRICE_AMOUNT_CUSTOMER_DECIDED">Customer Decided Amount</Option>
                                        <Option key={3} value="PRICE_AMOUNT_QUANTITY_BASED">Quantity Based Amount</Option>
                                      </Select>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                <Input
                                  placeholder="Enter the field label"
                                  value={item.label}
                                  maxlength="100"
                                  onChange={(e) => {
                                    const list = [...productDetailsArray];
                                    list[i].label = e.currentTarget.value;
                                    setProductDetailsArray(list);
                                  }}
                                />
                                <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                <SpError>
                                  {simpleValidator.current.message(`type${i + 1}`, productDetailsArray[i].label, 'required',
                                    { messages: { required: 'The label field is required' } })}
                                </SpError>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Product Description</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(optional)</SpText></label>
                                <TextArea
                                  rows={5}
                                  placeholder="Add the page description you want your customer to see"
                                  value={item.description}
                                  maxlength="300"
                                  onChange={(e) => {
                                    const list = [...productDetailsArray];
                                    list[i].description = e.currentTarget.value;
                                    setProductDetailsArray(list);
                                  }}
                                />
                                <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 300 Characters</SpText>
                              </Col>
                            </Row>
                            {
                              productDetailsArray[i].type === 'PRICE_AMOUNT_FIXED' ? (
                                <Row className="mt-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Amount</SpH5></label>
                                    <div className="d-flex">
                                      <div style={{ width: '15%' }}>
                                        <Select
                                          className="w-100"
                                          defaultValue={item.currencyCode}
                                          onChange={(value) => {
                                            const list = [...productDetailsArray];
                                            list[i].currencyCode = value;
                                            setProductDetailsArray(list);
                                          }}
                                        >
                                          {supportedCurrency.map((currency) => (
                                            <Option key={currency} value={currency}>
                                              {currency}
                                            </Option>
                                          ))}
                                        </Select>
                                      </div>
                                      <div style={{ width: '35%' }}>
                                        <Input
                                          placeholder="0.00"
                                          value={item.amount}
                                          onChange={(e) => {
                                            const list = [...productDetailsArray];
                                            const regex = /^\d*\.?\d*$/;
                                            const { value } = e.currentTarget;
                                            const amount = value.replace(/\s/g, '');
                                            if (regex.test(amount) || amount === '') {
                                              list[i].amount = amount;
                                            } else if (!regex.test(amount)) {
                                              list[i].amount = 0.00;
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                          onKeyPress={e => {
                                            const keyCode = e.charCode || e.which;
                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onBlur={e => {
                                            if (e.currentTarget.value) {
                                              const list = [...productDetailsArray];
                                              list[i].amount = formatAmount(e.currentTarget.value, currencyType);
                                              setProductDetailsArray(list);
                                            }
                                          }}
                                        />
                                        <SpError>
                                          {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].amount, 'required|amount',
                                            { messages: { required: 'The amount field is required' } })}
                                        </SpError>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : productDetailsArray[i].type === 'PRICE_AMOUNT_CUSTOMER_DECIDED' ? (
                                <Row className="mt-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Amount Limits</SpH5></label>
                                    <div className="d-flex">
                                      <SpH5 className="mt-2 mr-2">Min</SpH5>
                                      <div style={{ width: '14%' }}>
                                        <Select
                                          className="w-100"
                                          defaultValue={item.currencyCode}
                                          onChange={(value) => {
                                            const list = [...productDetailsArray];
                                            list[i].currencyCode = value;
                                            setProductDetailsArray(list);
                                          }}
                                        >
                                          {supportedCurrency.map((currency) => (
                                            <Option key={currency} value={currency}>
                                              {currency}
                                            </Option>
                                          ))}
                                        </Select>
                                      </div>
                                      <div style={{ width: '17%' }}>
                                        <Input
                                          value={item.minPrice}
                                          onChange={(e) => {
                                            const list = [...productDetailsArray];
                                            const regex = /^\d*\.?\d*$/;
                                            const { value } = e.currentTarget;
                                            const price = value.replace(/\s/g, '');
                                            if (regex.test(price) || price === '') {
                                              list[i].minPrice = price;
                                            } else if (!regex.test(price)) {
                                              list[i].minPrice = 0.00;
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                          onKeyPress={e => {
                                            const keyCode = e.charCode || e.which;
                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onBlur={e => {
                                            if (e.currentTarget.value) {
                                              const list = [...productDetailsArray];
                                              list[i].minPrice = formatAmount(e.currentTarget.value, currencyType);
                                              setProductDetailsArray(list);
                                            }
                                          }}
                                        />
                                        <SpError>
                                          {
                                            item.isRequired && (
                                              <>
                                                {/* eslint-disable-next-line max-len */}
                                                {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].minPrice, 'required|amount|optionalMinAmount',
                                                  { messages: { required: 'The min amount field is required' } })}
                                              </>
                                            )
                                          }
                                          {
                                            item.isRequired && (
                                              <>
                                                {/* eslint-disable-next-line max-len */}
                                                {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].minPrice, 'required|amount|minAmount',
                                                  { messages: { required: 'The min amount field is required' } })}
                                              </>
                                            )
                                          }
                                        </SpError>
                                      </div>
                                      <SpH5 className="ml-3 mt-2 mr-2">Max</SpH5>
                                      <div style={{ width: '14%' }}>
                                        <Select
                                          className="w-100"
                                          defaultValue={item.currencyCode}
                                          onChange={(value) => {
                                            const list = [...productDetailsArray];
                                            list[i].currencyCode = value;
                                            setProductDetailsArray(list);
                                          }}
                                        >
                                          {supportedCurrency.map((currency) => (
                                            <Option key={currency} value={currency}>
                                              {currency}
                                            </Option>
                                          ))}
                                        </Select>
                                      </div>
                                      <div style={{ width: '17%' }}>
                                        <Input
                                          value={item.maxPrice}
                                          placeholder="No Limit"
                                          onChange={(e) => {
                                            const list = [...productDetailsArray];
                                            const regex = /^\d*\.?\d*$/;
                                            const { value } = e.currentTarget;
                                            const price = value.replace(/\s/g, '');
                                            if (regex.test(price) || price === '') {
                                              list[i].maxPrice = price;
                                            } else if (!regex.test(price)) {
                                              list[i].maxPrice = 0.00;
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                          onKeyPress={e => {
                                            const keyCode = e.charCode || e.which;
                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onBlur={e => {
                                            if (e.currentTarget.value) {
                                              const list = [...productDetailsArray];
                                              list[i].maxPrice = formatAmount(e.currentTarget.value, currencyType);
                                              setProductDetailsArray(list);
                                            }
                                          }}
                                        />
                                        <SpError>
                                          {simpleValidator.current.message(`maxAmount${i + 1}`,
                                            productDetailsArray[i].maxPrice, `minValidAmount|maxAmount: ${productDetailsArray[i].minPrice}`)}
                                        </SpError>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : productDetailsArray[i].type === 'PRICE_AMOUNT_QUANTITY_BASED' ? (
                                <>
                                  <Row className="mt-4">
                                    <Col span={16} push={4}>
                                      <label htmlFor=""><SpH5>Amount</SpH5></label>
                                      <div className="d-flex">
                                        <div style={{ width: '15%' }}>
                                          <Select
                                            className="w-100"
                                            defaultValue={item.currencyCode}
                                            onChange={(value) => {
                                              const list = [...productDetailsArray];
                                              list[i].currencyCode = value;
                                              setProductDetailsArray(list);
                                            }}
                                          >
                                            {supportedCurrency.map((currency) => (
                                              <Option key={currency} value={currency}>
                                                {currency}
                                              </Option>
                                            ))}
                                          </Select>
                                        </div>
                                        <div style={{ width: '35%' }}>
                                          <Input
                                            placeholder="0.00"
                                            value={item.amount}
                                            onChange={(e) => {
                                              const list = [...productDetailsArray];
                                              const regex = /^\d*\.?\d*$/;
                                              const { value } = e.currentTarget;
                                              const amount = value.replace(/\s/g, '');
                                              if (regex.test(amount) || amount === '') {
                                                list[i].amount = amount;
                                              } else if (!regex.test(amount)) {
                                                list[i].amount = 0.00;
                                              }
                                              setProductDetailsArray(list);
                                            }}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                            onBlur={e => {
                                              if (e.currentTarget.value) {
                                                const list = [...productDetailsArray];
                                                list[i].amount = formatAmount(e.currentTarget.value, currencyType);
                                                setProductDetailsArray(list);
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].amount, 'required|amount',
                                              { messages: { required: 'The amount field is required' } })}
                                          </SpError>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="mt-4">
                                    <Col span={16} push={4}>
                                      <label htmlFor=""><SpH5>Product Quantity Limits</SpH5></label>
                                      <div className="d-flex">
                                        <SpH5 className="mt-2 mr-2">Min</SpH5>
                                        <div style={{ width: '17%' }}>
                                          <Input
                                            value={item.quantityMinLimit}
                                            onChange={(e) => {
                                              const list = [...productDetailsArray];
                                              const regex = /^[0-9]+$/;
                                              const { value } = e.currentTarget;
                                              const limit = value.replace(/\s/g, '');
                                              if (regex.test(limit) || limit === '') {
                                                list[i].quantityMinLimit = limit;
                                              } else if (!regex.test(limit)) {
                                                list[i].quantityMinLimit = 1;
                                              }
                                              setProductDetailsArray(list);
                                            }}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {/* eslint-disable-next-line max-len */}
                                            {simpleValidator.current.message(`min limit${i + 1}`, productDetailsArray[i].quantityMinLimit, 'required|minLimit',
                                              { messages: { required: 'The min limit field is required' } })}
                                          </SpError>
                                        </div>
                                        <SpH5 className="ml-3 mt-2 mr-2">Max</SpH5>
                                        <div style={{ width: '17%' }}>
                                          <Input
                                            value={item.quantityMaxLimit}
                                            placeholder="No Limit"
                                            onChange={(e) => {
                                              const list = [...productDetailsArray];
                                              const regex = /^[0-9]+$/;
                                              const { value } = e.currentTarget;
                                              const limit = value.replace(/\s/g, '');
                                              if (regex.test(limit) || limit === '') {
                                                list[i].quantityMaxLimit = limit;
                                              } else if (!regex.test(limit)) {
                                                list[i].quantityMaxLimit = null;
                                              }
                                              setProductDetailsArray(list);
                                            }}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {simpleValidator.current.message(`max limit${i + 1}`,
                                              // eslint-disable-next-line max-len
                                              productDetailsArray[i].quantityMaxLimit, `minValidAmount|maxAmount: ${productDetailsArray[i].quantityMinLimit}`)}
                                          </SpError>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              ) : productDetailsArray[i].type === 'COMBO_BOX' ? (
                                <>
                                  {addParent.map((items, q) => (
                                    <>
                                      <Row className="mt-4">
                                        <Col span={8} push={4}>
                                          <label htmlFor=""><SpH5>{`Parent ${q + 1}`}</SpH5></label>
                                          <Input
                                            value={items.label}
                                            onChange={(e) => {
                                              const list = [...addParent];
                                              list[q].label = e.currentTarget.value;
                                              list[q].value = e.currentTarget.value;
                                              setAddParent(list);
                                            }}
                                            key={`Parent${q}`}
                                            placeholder="Enter parent value"
                                          />
                                          <SpError className="mb-4">
                                            {simpleValidator.current.message('parent', items.label, 'required')}
                                          </SpError>
                                          <Row className="mt-1">
                                            {
                                              q > 0 ? (
                                                <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" span={8} push={10}>
                                                  <SpText
                                                    fontWeight="600"
                                                    color="#279dfe"
                                                    onClick={addNewParentComboBox}
                                                  >
                                                    Add Parent &nbsp;
                                                  </SpText>
                                                  <SpText
                                                    fontWeight="600"
                                                    color="#279dfe"
                                                    onClick={() => onRemoveParentComboBox(q)}
                                                  >
                                                    &nbsp; Remove
                                                  </SpText>
                                                </Col>
                                              ) : (
                                                <Col span={8} push={16}>
                                                  <SpText
                                                    fontWeight="600"
                                                    color="#279dfe"
                                                    onClick={addNewParentComboBox}
                                                    style={{ cursor: 'pointer' }}
                                                  >
                                                    Add Parent
                                                  </SpText>
                                                </Col>
                                              )
                                            }
                                          </Row>
                                        </Col>
                                        <Col span={8} push={4}>
                                          {items.children.map((child, r) => (
                                            <>
                                              <Row className="ml-4">
                                                <Col>
                                                  <label htmlFor=""><SpH5>{`Child ${r + 1}`}</SpH5></label>
                                                  <Input
                                                    value={child.label}
                                                    key={`Child${r}`}
                                                    onChange={(e) => {
                                                      const list = [...addParent];
                                                      list[q].children[r].label = e.currentTarget.value;
                                                      list[q].children[r].value = e.currentTarget.value;
                                                      setAddParent(list);
                                                    }}

                                                    placeholder="Enter child value"
                                                  />
                                                  <SpError className="mb-4">
                                                    {simpleValidator.current.message('child', child.label, 'required')}
                                                  </SpError>
                                                </Col>
                                              </Row>
                                              <Row className="mt-1">
                                                {
                                                  r > 0 ? (
                                                    <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" span={8} push={11}>
                                                      <SpText
                                                        fontWeight="600"
                                                        color="#279dfe"
                                                        onClick={(event) => addNewChildComboBox(event, q, r)}
                                                      >
                                                        Add Child &nbsp;
                                                      </SpText>
                                                      <SpText
                                                        fontWeight="600"
                                                        color="#279dfe"
                                                        onClick={() => onRemoveChildComboBox(q, r)}
                                                      >
                                                        &nbsp; Remove
                                                      </SpText>
                                                    </Col>
                                                  ) : (
                                                    <Col span={8} push={18}>
                                                      <SpText
                                                        fontWeight="600"
                                                        color="#279dfe"
                                                        onClick={(event) => addNewChildComboBox(event, q, r)}
                                                        style={{ cursor: 'pointer' }}
                                                      >
                                                        Add Child
                                                      </SpText>
                                                    </Col>
                                                  )
                                                }
                                              </Row>
                                            </>

                                          ))}
                                        </Col>
                                      </Row>
                                    </>

                                  ))}
                                </>
                              ) : ''
                            }
                            {
                              productDetailsArray[i].type === 'PRICE_AMOUNT_FIXED' && (
                                <>
                                  <Row className="mt-4">
                                    <Col span={16} push={4}>
                                      <Checkbox
                                        className="mr-2"
                                        checked={(item.enableCombo)}
                                        onChange={({ target: { checked } }) => {
                                          const list = [...productDetailsArray];
                                          list[i].enableCombo = checked;
                                          if (checked) {
                                            list[i].enableCombo = 'checked';
                                          } else if (!checked) {
                                            list[i].enableCombo = '';
                                          }
                                          setProductDetailsArray(list);
                                        }}
                                      />
                                      <SpH5>Enable Combo Box</SpH5>
                                    </Col>
                                  </Row>
                                  {
                                    item.enableCombo && (
                                      <>
                                        {item.comboBox.map((items, q) => (
                                          <>
                                            <Row className="mt-4">
                                              <Col span={8} push={4}>
                                                <label htmlFor=""><SpH5>{`Parent ${q + 1}`}</SpH5></label>
                                                <Input
                                                  value={items.label}
                                                  onChange={(e) => {
                                                    const list = [...productDetailsArray];
                                                    list[i].comboBox[q].label = e.currentTarget.value;
                                                    list[i].comboBox[q].value = e.currentTarget.value;
                                                    setProductDetailsArray(list);
                                                  }}
                                                  key={`Parent${q}`}
                                                  placeholder="Enter parent value"
                                                />
                                                <SpError className="mb-4">
                                                  {simpleValidator.current.message('parent', items.label, 'required')}
                                                </SpError>
                                                <Row className="mt-1">
                                                  {
                                                    q > 0 ? (
                                                      <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" span={8} push={10}>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => addNewParent(i)}
                                                        >
                                                          Add Parent &nbsp;
                                                        </SpText>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => onRemoveParent(i, q)}
                                                        >
                                                          &nbsp; Remove
                                                        </SpText>
                                                      </Col>
                                                    ) : (
                                                      <Col span={8} push={16}>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => addNewParent(i)}
                                                          style={{ cursor: 'pointer' }}
                                                        >
                                                          Add Parent
                                                        </SpText>
                                                      </Col>
                                                    )
                                                  }
                                                </Row>
                                              </Col>
                                              <Col span={8} push={4}>
                                                {items.children.map((child, r) => (
                                                  <>
                                                    <Row className="ml-4">
                                                      <Col>
                                                        <label htmlFor=""><SpH5>{`Child ${r + 1}`}</SpH5></label>
                                                        <Input
                                                          value={child.label}
                                                          key={`Child${r}`}
                                                          onChange={(e) => {
                                                            const list = [...productDetailsArray];
                                                            list[i].comboBox[q].children[r].label = e.currentTarget.value;
                                                            list[i].comboBox[q].children[r].value = e.currentTarget.value;
                                                            setProductDetailsArray(list);
                                                          }}

                                                          placeholder="Enter child value"
                                                        />
                                                        <SpError className="mb-4">
                                                          {simpleValidator.current.message('child', child.label, 'required')}
                                                        </SpError>
                                                      </Col>
                                                    </Row>
                                                    <Row className="mt-1">
                                                      {
                                                        r > 0 ? (
                                                          <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" span={8} push={11}>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={(event) => addNewChild(event, i, q, r)}
                                                            >
                                                              Add Child &nbsp;
                                                            </SpText>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={() => onRemoveChild(i, q, r)}
                                                            >
                                                              &nbsp; Remove
                                                            </SpText>
                                                          </Col>
                                                        ) : (
                                                          <Col span={8} push={18}>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={(event) => addNewChild(event, i, q, r)}
                                                              style={{ cursor: 'pointer' }}
                                                            >
                                                              Add Child
                                                            </SpText>
                                                          </Col>
                                                        )
                                                      }
                                                    </Row>
                                                  </>

                                                ))}
                                              </Col>
                                            </Row>
                                          </>

                                        ))}
                                        <Row className="mt-4 mb-4">
                                          <Col span={16} push={4}>
                                            <label htmlFor=""><SpH5>Is ComboBox Required</SpH5></label>
                                            <Checkbox
                                              className="ml-4"
                                              checked={(item.isComboBoxRequired)}
                                              onChange={({ target: { checked } }) => {
                                                const list = [...productDetailsArray];
                                                list[i].isComboBoxRequired = checked;
                                                if (checked) {
                                                  list[i].isComboBoxRequired = true;
                                                } else if (!checked) {
                                                  list[i].isComboBoxRequired = false;
                                                }
                                                setProductDetailsArray(list);
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    )
                                  }
                                </>
                              )
                            }
                            {
                              productDetailsArray[i].type === 'PRICE_AMOUNT_QUANTITY_BASED' && (
                                <>
                                  <Row className="mt-4">
                                    <Col span={16} push={4}>
                                      <Checkbox
                                        className="mr-2"
                                        checked={(item.enableCombo)}
                                        onChange={({ target: { checked } }) => {
                                          const list = [...productDetailsArray];
                                          list[i].enableCombo = checked;
                                          if (checked) {
                                            list[i].enableCombo = 'checked';
                                          } else if (!checked) {
                                            list[i].enableCombo = '';
                                          }
                                          setProductDetailsArray(list);
                                        }}
                                      />
                                      <SpH5>Enable Combo Box</SpH5>
                                    </Col>
                                  </Row>
                                  {
                                    item.enableCombo && (
                                      <>
                                        {item.comboBox.map((items, q) => (
                                          <>
                                            <Row className="mt-4">
                                              <Col span={6} push={4}>
                                                <label htmlFor=""><SpH5>{`Parent ${q + 1}`}</SpH5></label>
                                                <Input
                                                  value={items.label}
                                                  onChange={(e) => {
                                                    const list = [...productDetailsArray];
                                                    list[i].comboBox[q].label = e.currentTarget.value;
                                                    list[i].comboBox[q].value = e.currentTarget.value;
                                                    setProductDetailsArray(list);
                                                  }}
                                                  key={`Parent${q}`}
                                                  placeholder="Enter parent value"
                                                />
                                                <SpError className="mb-4">
                                                  {simpleValidator.current.message('parent', items.label, 'required')}
                                                </SpError>
                                                <Row className="mt-1">
                                                  {
                                                    q > 0 ? (
                                                      <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" push={4}>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => addNewParent(i)}
                                                        >
                                                          Add Parent &nbsp;
                                                        </SpText>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => onRemoveParent(i, q)}
                                                        >
                                                          &nbsp; Remove
                                                        </SpText>
                                                      </Col>
                                                    ) : (
                                                      <Col push={13}>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => addNewParent(i)}
                                                          style={{ cursor: 'pointer' }}
                                                        >
                                                          Add Parent
                                                        </SpText>
                                                      </Col>
                                                    )
                                                  }
                                                </Row>
                                              </Col>
                                              <Col span={14} push={4}>
                                                {items.children.map((child, r) => (
                                                  <>
                                                    <Row className="ml-2">
                                                      <Col span={7}>
                                                        <label htmlFor=""><SpH5>{`Child ${r + 1}`}</SpH5></label>
                                                        <Input
                                                          value={child.label}
                                                          key={`Child${r}`}
                                                          onChange={(e) => {
                                                            const list = [...productDetailsArray];
                                                            list[i].comboBox[q].children[r].label = e.currentTarget.value;
                                                            list[i].comboBox[q].children[r].value = e.currentTarget.value;
                                                            setProductDetailsArray(list);
                                                          }}

                                                          placeholder="Enter child"
                                                        />
                                                        <SpError className="mb-4">
                                                          {simpleValidator.current.message('child', child.label, 'required')}
                                                        </SpError>
                                                      </Col>
                                                      <Col span={7} className="ml-2">
                                                        <label htmlFor=""><SpH5>{`Price ${r + 1}`}</SpH5></label>
                                                        <Input
                                                          value={child.additionalPrice}
                                                          key={`Price${r}`}
                                                          onChange={(e) => {
                                                            const list = [...productDetailsArray];
                                                            const regex = /^\d*\.?\d*$/;
                                                            const { value } = e.currentTarget;
                                                            const amount = value.replace(/\s/g, '');
                                                            if (regex.test(amount) || amount === '') {
                                                              list[i].comboBox[q].children[r].additionalPrice = amount;
                                                            } else if (!regex.test(amount)) {
                                                              list[i].comboBox[q].children[r].additionalPrice = 0.00;
                                                            }
                                                            setProductDetailsArray(list);
                                                          }}
                                                          onKeyPress={e => {
                                                            const keyCode = e.charCode || e.which;
                                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                              e.preventDefault();
                                                            }
                                                          }}
                                                          onBlur={e => {
                                                            if (e.currentTarget.value) {
                                                              const list = [...productDetailsArray];
                                                              // eslint-disable-next-line max-len
                                                              list[i].comboBox[q].children[r].additionalPrice = formatAmount(e.currentTarget.value, currencyType);
                                                              setProductDetailsArray(list);
                                                            }
                                                          }}
                                                        />
                                                        <SpError className="mb-4">
                                                          {simpleValidator.current.message('price', child.additionalPrice, 'required')}
                                                        </SpError>
                                                      </Col>
                                                    </Row>
                                                    <Row className="mt-1">
                                                      {
                                                        r > 0 ? (
                                                          <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" push={7}>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={(event) => addNewChild(event, i, q, r)}
                                                            >
                                                              Add Child &nbsp;
                                                            </SpText>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={() => onRemoveChild(i, q, r)}
                                                            >
                                                              &nbsp; Remove
                                                            </SpText>
                                                          </Col>
                                                        ) : (
                                                          <Col span={8} push={11}>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={(event) => addNewChild(event, i, q, r)}
                                                              style={{ cursor: 'pointer' }}
                                                            >
                                                              Add Child
                                                            </SpText>
                                                          </Col>
                                                        )
                                                      }
                                                    </Row>
                                                  </>

                                                ))}
                                              </Col>
                                            </Row>
                                          </>

                                        ))}
                                        <Row className="mt-4 mb-4">
                                          <Col span={16} push={4}>
                                            <label htmlFor=""><SpH5>Is ComboBox Required</SpH5></label>
                                            <Checkbox
                                              className="ml-4"
                                              checked={(item.isComboBoxRequired)}
                                              onChange={({ target: { checked } }) => {
                                                const list = [...productDetailsArray];
                                                list[i].isComboBoxRequired = checked;
                                                if (checked) {
                                                  list[i].isComboBoxRequired = true;
                                                } else if (!checked) {
                                                  list[i].isComboBoxRequired = false;
                                                }
                                                setProductDetailsArray(list);
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    )
                                  }
                                </>
                              )
                            }

                            <Row className="mt-4 mb-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                <Checkbox
                                  className="ml-4"
                                  checked={!(item.isRequired)}
                                  onChange={({ target: { checked } }) => {
                                    const list = [...productDetailsArray];
                                    list[i].isRequired = !checked;
                                    if (checked) {
                                      list[i].checked = '';
                                    } else if (!checked) {
                                      list[i].checked = 'checked';
                                    }
                                    setProductDetailsArray(list);
                                  }}
                                />
                              </Col>
                            </Row>

                            {show ? (
                              <Row className="mt-4 mb-4">
                                <Col span={16} push={4}>
                                  <SpButton
                                    type="secondary"
                                    style={{ width: '50%' }}
                                    onClick={() => removeProductDetails(i)}
                                  >
                                    {'\u2212'}&nbsp;Remove Input Field
                                  </SpButton>
                                </Col>
                              </Row>

                            ) : ''}
                          </div>
                        ))
                      }
                      <Row className="mt-4">
                        <Col span={16} push={4}>
                          <SpButton
                            type="secondary"
                            style={{ width: '50%' }}
                            onClick={addProductDetails}
                          >
                            {'\u002B'}&nbsp;Add Another Input Field
                          </SpButton>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col span={16} push={4}>
                          <Checkbox
                            className="mr-2"
                            checked={enableTip}
                            onChange={({ target: { checked } }) => {
                              setEnableTip(checked);
                            }}
                          />
                          <SpH5>Enable Tip</SpH5>
                        </Col>
                      </Row>
                      {
                        enableTip && (
                          <>
                            <Row className="mt-4">
                              <Col span={16} push={4}>
                                <div className="d-flex">
                                  <div style={{ width: '25%' }}>
                                    <SpH5>1st Option</SpH5>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <SpH5>2nd Option</SpH5>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <SpH5>3rd Option</SpH5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-1">
                              <Col span={16} push={4}>
                                <div className="d-flex">
                                  <div style={{ width: '25%' }}>
                                    <Input
                                      value={firstOption}
                                      placeholder="0.0 %"
                                      suffix="%"
                                      onChange={(e) => {
                                        const regex = /^[0-9]+$/;
                                        const { value } = e.currentTarget;
                                        if (regex.test(value) || value === '') {
                                          setFirstOption(e.currentTarget.value);
                                        } else if (!regex.test(value)) {
                                          setFirstOption(0.00);
                                        }
                                      }}
                                      onKeyPress={e => {
                                        const keyCode = e.charCode || e.which;
                                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onBlur={e => {
                                        if (e.currentTarget.value) {
                                          const value = formatAmount(e.currentTarget.value, currencyType);
                                          setFirstOption(value);
                                        }
                                      }}
                                    />
                                    <SpError>
                                      {simpleValidator.current.message('1st option', firstOption, 'required|tipAmount')}
                                    </SpError>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <Input
                                      value={secondOption}
                                      placeholder="0.0 %"
                                      suffix="%"
                                      onChange={(e) => {
                                        const regex = /^[0-9]+$/;
                                        const { value } = e.currentTarget;
                                        if (regex.test(value) || value === '') {
                                          setSecondOption(e.currentTarget.value);
                                        } else if (!regex.test(value)) {
                                          setSecondOption(0.00);
                                        }
                                      }}
                                      onKeyPress={e => {
                                        const keyCode = e.charCode || e.which;
                                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onBlur={e => {
                                        if (e.currentTarget.value) {
                                          const value = formatAmount(e.currentTarget.value, currencyType);
                                          setSecondOption(value);
                                        }
                                      }}
                                    />
                                    <SpError>
                                      {simpleValidator.current.message(
                                        '2nd option', secondOption, `required|tipAmount|tipValidation1:${firstOption}`,
                                      )}
                                    </SpError>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <Input
                                      value={thirdOption}
                                      placeholder="0.0 %"
                                      suffix="%"
                                      onChange={(e) => {
                                        const regex = /^[0-9]+$/;
                                        const { value } = e.currentTarget;
                                        if (regex.test(value) || value === '') {
                                          setThirdOption(e.currentTarget.value);
                                        } else if (!regex.test(value)) {
                                          setThirdOption(0.00);
                                        }
                                      }}
                                      onKeyPress={e => {
                                        const keyCode = e.charCode || e.which;
                                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onBlur={e => {
                                        if (e.currentTarget.value) {
                                          const value = formatAmount(e.currentTarget.value, currencyType);
                                          setThirdOption(value);
                                        }
                                      }}
                                    />
                                    <SpError>
                                      {simpleValidator.current.message(
                                        '3rd option', thirdOption, `required|tipAmount|tipValidation:${firstOption}|tipValidation1:${secondOption}`,
                                      )}
                                    </SpError>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-4 mb-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                <Checkbox
                                  className="ml-4"
                                  checked={!isTipOptional}
                                  onChange={({ target: { checked } }) => {
                                    const ischeck = !checked;
                                    setIsTipOptional(ischeck);
                                  }}
                                />
                              </Col>
                            </Row>
                          </>
                        )
                      }
                    </div>
                  )}
                  {current === 2 && (
                    <div>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="item-list">
                          {(provided) => (
                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                              {customerDetailsArray.map((item, j) => (
                                <Draggable key={`${item.type}${j}`} draggableId={`${item.type}${j}`} index={j}>
                                  {(provided) => (
                                    <li
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                    >
                                      <div
                                        style={{
                                          backgroundColor: '#fff',
                                          padding: '10px',
                                          margin: '25px 0',
                                          borderRadius: '5px',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <p>
                                          <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                                            <Col className="d-flex align-items-center">
                                              <div className="ml-2"><img src={reorderIcon} alt="" /></div>
                                            </Col>
                                            <Col className="d-flex align-items-center">
                                              {
                                                j === 0 ? (
                                                  <div className="mr-2">
                                                    <img src={arrowUpDisabled} alt="" />
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="mr-2"
                                                    onClick={() => onArrowUp(j)}
                                                  >
                                                    <img src={arrowUp} alt="" />
                                                  </div>
                                                )
                                              }
                                              {
                                                j === (customerDetailsArray.length - 1) ? (
                                                  <div
                                                    className="mr-2"
                                                  >
                                                    <img src={arrowDownDisabled} alt="" />
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="mr-2"
                                                    onClick={() => onArrowDown(j)}
                                                  >
                                                    <img src={arrowDown} alt="" />
                                                  </div>
                                                )
                                              }

                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col span={16} push={4}>
                                              <label htmlFor=""><SpH5>Input Field Type</SpH5></label>
                                              <div>
                                                <Select
                                                  style={{ width: '50%' }}
                                                  placeholder="Select field type"
                                                  disabled={item.disableRequired ? true : ''}
                                                  value={item.type || undefined}
                                                  onChange={(e) => {
                                                    const list = [...customerDetailsArray];
                                                    list[j].type = e;
                                                    setCustomerDetailsArray(list);
                                                  }}
                                                >
                                                  <Option key={1} value="INPUT_EMAIL">Email</Option>
                                                  <Option key={2} value="INPUT_PHONE">Phone</Option>
                                                  <Option key={3} value="INPUT_NUMBER">Number</Option>
                                                  <Option key={4} value="INPUT_ALPHA">Alpha</Option>
                                                  <Option key={5} value="INPUT_ALPHA_NUMERIC">Alpha Numeric</Option>
                                                  <Option key={6} value="INPUT_SINGLE_LINE_TEXT">Single Line Text</Option>
                                                  <Option key={7} value="INPUT_LARGE_TEXT">Large Text</Option>
                                                  <Option key={8} value="PROMOTIONAL_CODES">Promotional Code</Option>
                                                  <Option key={9} value="REFERENCE_NUMBER">Reference Number</Option>
                                                  <Option key={10} value="CONSENT_FORM">Consent Form</Option>
                                                  <Option key={11} value="FILE_UPLOAD">File Upload</Option>
                                                </Select>
                                              </div>
                                              <SpError>
                                                {simpleValidator.current.message(`type${j + 1}`, customerDetailsArray[j].type, 'required',
                                                  { messages: { required: 'Please select field type' } })}
                                              </SpError>
                                            </Col>
                                          </Row>
                                          <Row className="mt-4 mb-4">
                                            <Col span={16} push={4}>
                                              <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                              <Input
                                                placeholder={getPlaceholder(item.type || '')}
                                                value={item.label}
                                                maxlength="100"
                                                onChange={(e) => {
                                                  const list = [...customerDetailsArray];
                                                  list[j].label = e.currentTarget.value;
                                                  setCustomerDetailsArray(list);
                                                }}
                                              />
                                              <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                              <SpError>
                                                {simpleValidator.current.message(`Label${j + 1}`, customerDetailsArray[j].label, 'required',
                                                  { messages: { required: 'The label field is required' } })}
                                              </SpError>
                                            </Col>
                                          </Row>
                                          {item.type === 'FILE_UPLOAD' ? (
                                            <>
                                              <Row className="mt-4 mb-4">
                                                <Col span={16} push={4}>
                                                  <label htmlFor=""><SpH5>Description</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(optional)</SpText></label>
                                                  <TextArea
                                                    rows={5}
                                                    placeholder="Add the page description you want your customer to see"
                                                    value={item.description}
                                                    maxlength="300"
                                                    onChange={(e) => {
                                                      const list = [...customerDetailsArray];
                                                      list[j].description = e.currentTarget.value;
                                                      setCustomerDetailsArray(list);
                                                    }}
                                                  />
                                                  <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 300 Characters</SpText>
                                                </Col>
                                              </Row>
                                              <Row className="mt-4 mb-4">
                                                <Col span={16} push={4}>
                                                  <label htmlFor=""><SpH5>Document Upload Limits</SpH5></label>
                                                  <div className="d-flex gap-3">
                                                    <Input
                                                      placeholder="Min"
                                                      min={0}
                                                      type="number"
                                                      value={item.quantityMinLimit}
                                                      maxlength="100"
                                                      onChange={(e) => {
                                                        const list = [...customerDetailsArray];
                                                        list[j].quantityMinLimit = e.currentTarget.value;
                                                        setCustomerDetailsArray(list);
                                                      }}
                                                    />
                                                    <SpError className="ml-2">
                                                      {simpleValidator.current.message(
                                                        `uploadLimit${j + 1}`,
                                                        customerDetailsArray[j].quantityMinLimit,
                                                        `required|uploadLimit: ${customerDetailsArray[j].quantityMinLimit}`,
                                                        {
                                                          messages: {
                                                            required: 'The min upload limit is required',
                                                          },
                                                        },
                                                      )}
                                                    </SpError>
                                                    <Input
                                                      placeholder="Max"
                                                      max={100}
                                                      type="number"
                                                      value={item.quantityMaxLimit}
                                                      className="ml-3"
                                                      maxlength="100"
                                                      onChange={(e) => {
                                                        const list = [...customerDetailsArray];
                                                        list[j].quantityMaxLimit = e.currentTarget.value;
                                                        setCustomerDetailsArray(list);
                                                      }}
                                                    />
                                                    <SpError className="ml-2">
                                                      {simpleValidator.current.message(
                                                        `uploadLimit${j + 1}`,
                                                        customerDetailsArray[j].quantityMaxLimit,
                                                        `required|uploadLimit: ${customerDetailsArray[j].quantityMaxLimit}`,
                                                        {
                                                          messages: {
                                                            required: 'The max upload limit is required',
                                                          },
                                                        },
                                                      )}
                                                    </SpError>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </>
                                          ) : null}
                                          {item.type === 'CONSENT_FORM'

                                            ? (
                                              <Row className="mt-4 mb-4">
                                                <Col span={16} push={4}>
                                                  <label htmlFor=""><SpH5>Waiver Description</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(optional)</SpText></label>
                                                  <TextArea
                                                    rows={5}
                                                    placeholder="Add the page description you want your customer to see"
                                                    value={item.description}
                                                    maxlength="300"
                                                    // onChange={handleDescriptionChange}
                                                    onChange={(e) => {
                                                      const list = [...customerDetailsArray];
                                                      list[j].description = e.currentTarget.value;
                                                      setCustomerDetailsArray(list);
                                                    }}
                                                  />
                                                  <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 300 Characters</SpText>
                                                </Col>
                                              </Row>
                                            )


                                            : null}
                                          {item.type === 'CONSENT_FORM'
                                            ? (
                                              <Row className="mt-4 mb-4">
                                                <Col span={16} push={4}>
                                                  <label htmlFor=""><SpH5>Add Waiver Document </SpH5></label>
                                                  <Upload
                                                    name="avatar"
                                                    listType="picture-card upload-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    beforeUpload={beforeUpload}
                                                    onChange={(info) => fileUpload(info, j)}
                                                  >
                                                    {item.documentUrl ? item.documentUrl : uploadButton}
                                                  </Upload>
                                                  <SpError>
                                                    {
                                                      simpleValidator.current.message('FileUpload', item.documentUrl, 'required')
                                                    }
                                                  </SpError>
                                                </Col>
                                              </Row>
                                            )
                                            : null}
                                          <Row className="mt-4 mb-4">
                                            <Col span={16} push={4}>
                                              <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                              <Checkbox
                                                className="ml-4"
                                                checked={!(item.isRequired)}
                                                disabled={item.disableRequired || false}
                                                onChange={({ target: { checked } }) => {
                                                  const list = [...customerDetailsArray];
                                                  list[j].isRequired = !checked;
                                                  if (checked) {
                                                    list[j].checked = '';
                                                  } else if (!checked) {
                                                    list[j].checked = 'checked';
                                                  }
                                                  setCustomerDetailsArray(list);
                                                }}
                                              />
                                            </Col>
                                          </Row>
                                          {
                                            (!item.disableRequired) ? (
                                              <Row className="mt-4 mb-4">
                                                <Col span={16} push={4}>
                                                  <SpButton
                                                    type="secondary"
                                                    style={{ width: '50%' }}
                                                    onClick={() => removeCustomerDetails(j)}
                                                  >
                                                    {'\u2212'}&nbsp;Remove Input Field
                                                  </SpButton>
                                                </Col>
                                              </Row>
                                            ) : ''
                                          }
                                        </p>
                                      </div>
                                    </li>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <Row className="mt-4">
                        <Col span={16} push={8}>
                          <SpButton
                            type="secondary"
                            style={{ width: '50%' }}
                            onClick={addCustomerDetails}
                          >
                            {'\u002B'}&nbsp;Add Another Input Fields
                          </SpButton>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {
                    current === 3 && (
                      <AccontSlug
                        ref={refArray[0]}
                        selectedAccount={selectedAccount}
                        pageData={pageData}
                        isIndia={isIndia}
                        accountSlug={accountSlug}
                        submitting={submitting}
                        newSlug={newSlug}
                        withoutSlug={withoutSlug}
                      />
                    )
                  }
                  {current === 4 && (
                    <ReviewPaymentPage
                      pageData={pageData}
                      addParent={addParent}
                      submitting={submitting}
                      productDetailsArray={productDetailsArray}
                      customerDetailsArray={customerDetailsArray}
                      account={account}
                      enableTip={enableTip}
                      isTipOptional={isTipOptional}
                      firstOption={firstOption}
                      secondOption={secondOption}
                      thirdOption={thirdOption}
                      cloneId={gid}
                    />
                  )}
                </div>
                <div className="d-flex justify-content-end mt-auto mr-4">
                  {current > 0 && (
                    <SpButton type="secondary" className="mr-4" onClick={prev}>
                      Back
                    </SpButton>
                  )}
                  {current < steps.length - 1 && (
                    <SpButton type="primary" onClick={next}>
                      Next
                    </SpButton>
                  )}
                  {current === steps.length - 1 && (
                    <SpButton type="primary" onClick={next} disabled={disableButton}>
                      Clone
                    </SpButton>
                  )}
                </div>
              </PaymentFormWrapper>
            </PaymentWrapper>
          </Layout>
        )
      }
      {
        (!gid) && (
          <Layout style={{ marginLeft: '0px', minHeight: '0px' }}>
            <Header style={{ color: '#fff', fontSize: '20px', background: '#18294c !important' }}>
              <Row type="flex" justify="space-between">
                <Col className="d-flex align-items-center">
                  Create Payment Page
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <span style={{ cursor: 'pointer' }} onClick={() => history.push(PAGES.PAYMENTPAGES)}>
                    <PaymentClose />
                  </span>
                </Col>
              </Row>
            </Header>
            <PaymentWrapper style={{ background: current === 3 ? primaryColor : '' }}>
              <Steps current={current} className="mb-4 paymentPage" style={{ width: '100%' }}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <PaymentFormWrapper style={{ width: current === 3 ? '964px' : '800px' }}>
                <div>
                  {current === 0 && (
                    <PageDetails
                      ref={refArray[0]}
                      selectedAccount={selectedAccount}
                      pageData={pageData}
                      isIndia={isIndia}
                    />
                  )}
                  {current === 1 && (
                    <div>
                      {
                        productDetailsArray.map((item, i) => (
                          <div>
                            <Row>
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Input Field Type</SpH5></label>
                                <div>
                                  {
                                    showCombo ? (
                                      <Select
                                        style={{ width: '50%' }}
                                        value={item.type}
                                        onChange={(e) => {
                                          const list = [...productDetailsArray];
                                          list[i].type = e;
                                          const hasTargetValue = list.some(obj => Object.values(obj).includes('COMBO_BOX'));
                                          if (hasTargetValue) {
                                            setShowCombo(false);
                                          } else {
                                            setShowCombo(true);
                                          }
                                          setProductDetailsArray(list);
                                        }}
                                      >
                                        <Option key={1} value="PRICE_AMOUNT_FIXED">Fixed Amount</Option>
                                        <Option key={2} value="PRICE_AMOUNT_CUSTOMER_DECIDED">Customer Decided Amount</Option>
                                        <Option key={3} value="PRICE_AMOUNT_QUANTITY_BASED">Quantity Based Amount</Option>
                                        <Option key={4} value="COMBO_BOX">Combo Box</Option>
                                      </Select>
                                    ) : (
                                      <Select
                                        style={{ width: '50%' }}
                                        value={item.type}
                                        onChange={(e) => {
                                          const list = [...productDetailsArray];
                                          list[i].type = e;
                                          const hasTargetValue = list.some(obj => Object.values(obj).includes('COMBO_BOX'));
                                          if (hasTargetValue) {
                                            setShowCombo(false);
                                          } else {
                                            setShowCombo(true);
                                          }
                                          setProductDetailsArray(list);
                                        }}
                                      >
                                        <Option key={1} value="PRICE_AMOUNT_FIXED">Fixed Amount</Option>
                                        <Option key={2} value="PRICE_AMOUNT_CUSTOMER_DECIDED">Customer Decided Amount</Option>
                                        <Option key={3} value="PRICE_AMOUNT_QUANTITY_BASED">Quantity Based Amount</Option>
                                      </Select>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                <Input
                                  placeholder="Enter the field label"
                                  value={item.label}
                                  maxlength="100"
                                  onChange={(e) => {
                                    const list = [...productDetailsArray];
                                    list[i].label = e.currentTarget.value;
                                    setProductDetailsArray(list);
                                  }}
                                />
                                <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                <SpError>
                                  {simpleValidator.current.message(`type${i + 1}`, productDetailsArray[i].label, 'required',
                                    { messages: { required: 'The label field is required' } })}
                                </SpError>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Product Description</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(optional)</SpText></label>
                                <TextArea
                                  rows={5}
                                  placeholder="Add the page description you want your customer to see"
                                  value={item.description}
                                  maxlength="300"
                                  onChange={(e) => {
                                    const list = [...productDetailsArray];
                                    list[i].description = e.currentTarget.value;
                                    setProductDetailsArray(list);
                                  }}
                                />
                                <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 300 Characters</SpText>
                              </Col>
                            </Row>
                            {
                              productDetailsArray[i].type === 'PRICE_AMOUNT_FIXED' ? (
                                <Row className="mt-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Amount</SpH5></label>
                                    <div className="d-flex">
                                      <div style={{ width: '15%' }}>
                                        <Select
                                          className="w-100"
                                          defaultValue={item.currencyCode}
                                          onChange={(value) => {
                                            const list = [...productDetailsArray];
                                            list[i].currencyCode = value;
                                            setProductDetailsArray(list);
                                          }}
                                        >
                                          {supportedCurrency.map((currency) => (
                                            <Option key={currency} value={currency}>
                                              {currency}
                                            </Option>
                                          ))}
                                        </Select>
                                      </div>
                                      <div style={{ width: '35%' }}>
                                        <Input
                                          placeholder="0.00"
                                          value={item.amount}
                                          onChange={(e) => {
                                            const list = [...productDetailsArray];
                                            const regex = /^\d*\.?\d*$/;
                                            const { value } = e.currentTarget;
                                            const amount = value.replace(/\s/g, '');
                                            if (regex.test(amount) || amount === '') {
                                              list[i].amount = amount;
                                            } else if (!regex.test(amount)) {
                                              list[i].amount = 0.00;
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                          onKeyPress={e => {
                                            const keyCode = e.charCode || e.which;
                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onBlur={e => {
                                            if (e.currentTarget.value) {
                                              const list = [...productDetailsArray];
                                              list[i].amount = formatAmount(e.currentTarget.value, currencyType);
                                              setProductDetailsArray(list);
                                            }
                                          }}
                                        />
                                        <SpError>
                                          {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].amount, 'required|amount',
                                            { messages: { required: 'The amount field is required' } })}
                                        </SpError>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : productDetailsArray[i].type === 'PRICE_AMOUNT_CUSTOMER_DECIDED' ? (
                                <Row className="mt-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Amount Limits</SpH5></label>
                                    <div className="d-flex">
                                      <SpH5 className="mt-2 mr-2">Min</SpH5>
                                      <div style={{ width: '14%' }}>
                                        <Select
                                          className="w-100"
                                          defaultValue={item.currencyCode}
                                          onChange={(value) => {
                                            const list = [...productDetailsArray];
                                            list[i].currencyCode = value;
                                            setProductDetailsArray(list);
                                          }}
                                        >
                                          {supportedCurrency.map((currency) => (
                                            <Option key={currency} value={currency}>
                                              {currency}
                                            </Option>
                                          ))}
                                        </Select>
                                      </div>
                                      <div style={{ width: '17%' }}>
                                        <Input
                                          value={item.minPrice}
                                          onChange={(e) => {
                                            const list = [...productDetailsArray];
                                            const regex = /^\d*\.?\d*$/;
                                            const { value } = e.currentTarget;
                                            const price = value.replace(/\s/g, '');
                                            if (regex.test(price) || price === '') {
                                              list[i].minPrice = price;
                                            } else if (!regex.test(price)) {
                                              list[i].minPrice = 0.00;
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                          onKeyPress={e => {
                                            const keyCode = e.charCode || e.which;
                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onBlur={e => {
                                            if (e.currentTarget.value) {
                                              const list = [...productDetailsArray];
                                              list[i].minPrice = formatAmount(e.currentTarget.value, currencyType);
                                              setProductDetailsArray(list);
                                            }
                                          }}
                                        />
                                        <SpError>
                                          {
                                            item.isRequired && (
                                              <>
                                                {/* eslint-disable-next-line max-len */}
                                                {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].minPrice, 'required|amount|optionalMinAmount',
                                                  { messages: { required: 'The min amount field is required' } })}
                                              </>
                                            )
                                          }
                                          {
                                            item.isRequired && (
                                              <>
                                                {/* eslint-disable-next-line max-len */}
                                                {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].minPrice, 'required|amount|minAmount',
                                                  { messages: { required: 'The min amount field is required' } })}
                                              </>
                                            )
                                          }
                                        </SpError>
                                      </div>
                                      <SpH5 className="ml-3 mt-2 mr-2">Max</SpH5>
                                      <div style={{ width: '14%' }}>
                                        <Select
                                          className="w-100"
                                          defaultValue={item.currencyCode}
                                          onChange={(value) => {
                                            const list = [...productDetailsArray];
                                            list[i].currencyCode = value;
                                            setProductDetailsArray(list);
                                          }}
                                        >
                                          {supportedCurrency.map((currency) => (
                                            <Option key={currency} value={currency}>
                                              {currency}
                                            </Option>
                                          ))}
                                        </Select>
                                      </div>
                                      <div style={{ width: '17%' }}>
                                        <Input
                                          value={item.maxPrice}
                                          placeholder="No Limit"
                                          onChange={(e) => {
                                            const list = [...productDetailsArray];
                                            const regex = /^\d*\.?\d*$/;
                                            const { value } = e.currentTarget;
                                            const price = value.replace(/\s/g, '');
                                            if (regex.test(price) || price === '') {
                                              list[i].maxPrice = price;
                                            } else if (!regex.test(price)) {
                                              list[i].maxPrice = 0.00;
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                          onKeyPress={e => {
                                            const keyCode = e.charCode || e.which;
                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onBlur={e => {
                                            if (e.currentTarget.value) {
                                              const list = [...productDetailsArray];
                                              list[i].maxPrice = formatAmount(e.currentTarget.value, currencyType);
                                              setProductDetailsArray(list);
                                            }
                                          }}
                                        />
                                        <SpError>
                                          {simpleValidator.current.message(`maxAmount${i + 1}`,
                                            productDetailsArray[i].maxPrice, `minValidAmount|maxAmount: ${productDetailsArray[i].minPrice}`)}
                                        </SpError>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : productDetailsArray[i].type === 'PRICE_AMOUNT_QUANTITY_BASED' ? (
                                <>
                                  <Row className="mt-4">
                                    <Col span={16} push={4}>
                                      <label htmlFor=""><SpH5>Amount</SpH5></label>
                                      <div className="d-flex">
                                        <div style={{ width: '15%' }}>
                                          <Select
                                            className="w-100"
                                            defaultValue={item.currencyCode}
                                            onChange={(value) => {
                                              const list = [...productDetailsArray];
                                              list[i].currencyCode = value;
                                              setProductDetailsArray(list);
                                            }}
                                          >
                                            {supportedCurrency.map((currency) => (
                                              <Option key={currency} value={currency}>
                                                {currency}
                                              </Option>
                                            ))}
                                          </Select>
                                        </div>
                                        <div style={{ width: '35%' }}>
                                          <Input
                                            placeholder="0.00"
                                            value={item.amount}
                                            onChange={(e) => {
                                              const list = [...productDetailsArray];
                                              const regex = /^\d*\.?\d*$/;
                                              const { value } = e.currentTarget;
                                              const amount = value.replace(/\s/g, '');
                                              if (regex.test(amount) || amount === '') {
                                                list[i].amount = amount;
                                              } else if (!regex.test(amount)) {
                                                list[i].amount = 0.00;
                                              }
                                              setProductDetailsArray(list);
                                            }}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                            onBlur={e => {
                                              if (e.currentTarget.value) {
                                                const list = [...productDetailsArray];
                                                list[i].amount = formatAmount(e.currentTarget.value, currencyType);
                                                setProductDetailsArray(list);
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].amount, 'required|amount',
                                              { messages: { required: 'The amount field is required' } })}
                                          </SpError>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="mt-4">
                                    <Col span={16} push={4}>
                                      <label htmlFor=""><SpH5>Product Quantity Limits</SpH5></label>
                                      <div className="d-flex">
                                        <SpH5 className="mt-2 mr-2">Min</SpH5>
                                        <div style={{ width: '17%' }}>
                                          <Input
                                            value={item.quantityMinLimit}
                                            onChange={(e) => {
                                              const list = [...productDetailsArray];
                                              const regex = /^[0-9]+$/;
                                              const { value } = e.currentTarget;
                                              const limit = value.replace(/\s/g, '');
                                              if (regex.test(limit) || limit === '') {
                                                list[i].quantityMinLimit = limit;
                                              } else if (!regex.test(limit)) {
                                                list[i].quantityMinLimit = 1;
                                              }
                                              setProductDetailsArray(list);
                                            }}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {/* eslint-disable-next-line max-len */}
                                            {simpleValidator.current.message(`min limit${i + 1}`, productDetailsArray[i].quantityMinLimit, productDetailsArray[i].isRequired ? 'required|minLimit' : 'required',
                                              { messages: { required: 'The min limit field is required' } })}
                                          </SpError>
                                        </div>
                                        <SpH5 className="ml-3 mt-2 mr-2">Max</SpH5>
                                        <div style={{ width: '17%' }}>
                                          <Input
                                            value={item.quantityMaxLimit}
                                            placeholder="No Limit"
                                            onChange={(e) => {
                                              const list = [...productDetailsArray];
                                              const regex = /^[0-9]+$/;
                                              const { value } = e.currentTarget;
                                              const limit = value.replace(/\s/g, '');
                                              if (regex.test(limit) || limit === '') {
                                                list[i].quantityMaxLimit = limit;
                                              } else if (!regex.test(limit)) {
                                                list[i].quantityMaxLimit = null;
                                              }
                                              setProductDetailsArray(list);
                                            }}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {simpleValidator.current.message(`max limit${i + 1}`,
                                              // eslint-disable-next-line max-len
                                              productDetailsArray[i].quantityMaxLimit, `minValidAmount|maxAmount: ${productDetailsArray[i].quantityMinLimit}`)}
                                          </SpError>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              ) : productDetailsArray[i].type === 'COMBO_BOX' ? (
                                <>
                                  {addParent.map((items, q) => (
                                    <>
                                      <Row className="mt-4">
                                        <Col span={8} push={4}>
                                          <label htmlFor=""><SpH5>{`Parent ${q + 1}`}</SpH5></label>
                                          <Input
                                            value={items.label}
                                            onChange={(e) => {
                                              const list = [...addParent];
                                              list[q].label = e.currentTarget.value;
                                              list[q].value = e.currentTarget.value;
                                              setAddParent(list);
                                            }}
                                            key={`Parent${q}`}
                                            placeholder="Enter parent value"
                                          />
                                          <SpError className="mb-4">
                                            {simpleValidator.current.message('parent', items.label, 'required')}
                                          </SpError>
                                          <Row className="mt-1">
                                            {
                                              q > 0 ? (
                                                <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" span={8} push={10}>
                                                  <SpText
                                                    fontWeight="600"
                                                    color="#279dfe"
                                                    onClick={addNewParentComboBox}
                                                  >
                                                    Add Parent &nbsp;
                                                  </SpText>
                                                  <SpText
                                                    fontWeight="600"
                                                    color="#279dfe"
                                                    onClick={() => onRemoveParentComboBox(q)}
                                                  >
                                                    &nbsp; Remove
                                                  </SpText>
                                                </Col>
                                              ) : (
                                                <Col span={8} push={16}>
                                                  <SpText
                                                    fontWeight="600"
                                                    color="#279dfe"
                                                    onClick={addNewParentComboBox}
                                                    style={{ cursor: 'pointer' }}
                                                  >
                                                    Add Parent
                                                  </SpText>
                                                </Col>
                                              )
                                            }
                                          </Row>
                                        </Col>
                                        <Col span={8} push={4}>
                                          {items.children.map((child, r) => (
                                            <>
                                              <Row className="ml-4">
                                                <Col>
                                                  <label htmlFor=""><SpH5>{`Child ${r + 1}`}</SpH5></label>
                                                  <Input
                                                    value={child.label}
                                                    key={`Child${r}`}
                                                    onChange={(e) => {
                                                      const list = [...addParent];
                                                      list[q].children[r].label = e.currentTarget.value;
                                                      list[q].children[r].value = e.currentTarget.value;
                                                      setAddParent(list);
                                                    }}

                                                    placeholder="Enter child value"
                                                  />
                                                  <SpError className="mb-4">
                                                    {simpleValidator.current.message('child', child.label, 'required')}
                                                  </SpError>
                                                </Col>
                                              </Row>
                                              <Row className="mt-1">
                                                {
                                                  r > 0 ? (
                                                    <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" span={8} push={11}>
                                                      <SpText
                                                        fontWeight="600"
                                                        color="#279dfe"
                                                        onClick={(event) => addNewChildComboBox(event, q, r)}
                                                      >
                                                        Add Child &nbsp;
                                                      </SpText>
                                                      <SpText
                                                        fontWeight="600"
                                                        color="#279dfe"
                                                        onClick={() => onRemoveChildComboBox(q, r)}
                                                      >
                                                        &nbsp; Remove
                                                      </SpText>
                                                    </Col>
                                                  ) : (
                                                    <Col span={8} push={18}>
                                                      <SpText
                                                        fontWeight="600"
                                                        color="#279dfe"
                                                        onClick={(event) => addNewChildComboBox(event, q, r)}
                                                        style={{ cursor: 'pointer' }}
                                                      >
                                                        Add Child
                                                      </SpText>
                                                    </Col>
                                                  )
                                                }
                                              </Row>
                                            </>

                                          ))}
                                        </Col>
                                      </Row>
                                    </>

                                  ))}
                                </>
                              ) : ''
                            }
                            {
                              productDetailsArray[i].type === 'PRICE_AMOUNT_FIXED' && (
                                <>
                                  <Row className="mt-4">
                                    <Col span={16} push={4}>
                                      <Checkbox
                                        className="mr-2"
                                        checked={(item.enableCombo)}
                                        onChange={({ target: { checked } }) => {
                                          const list = [...productDetailsArray];
                                          list[i].enableCombo = checked;
                                          if (checked) {
                                            list[i].enableCombo = 'checked';
                                          } else if (!checked) {
                                            list[i].enableCombo = '';
                                          }
                                          setProductDetailsArray(list);
                                        }}
                                      />
                                      <SpH5>Enable Combo Box</SpH5>
                                    </Col>
                                  </Row>
                                  {
                                    item.enableCombo && (
                                      <>
                                        {item.comboBox.map((items, q) => (
                                          <>
                                            <Row className="mt-4">
                                              <Col span={8} push={4}>
                                                <label htmlFor=""><SpH5>{`Parent ${q + 1}`}</SpH5></label>
                                                <Input
                                                  value={items.label}
                                                  onChange={(e) => {
                                                    const list = [...productDetailsArray];
                                                    list[i].comboBox[q].label = e.currentTarget.value;
                                                    list[i].comboBox[q].value = e.currentTarget.value;
                                                    setProductDetailsArray(list);
                                                  }}
                                                  key={`Parent${q}`}
                                                  placeholder="Enter parent value"
                                                />
                                                <SpError className="mb-4">
                                                  {simpleValidator.current.message('parent', items.label, 'required')}
                                                </SpError>
                                                <Row className="mt-1">
                                                  {
                                                    q > 0 ? (
                                                      <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" span={8} push={10}>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => addNewParent(i)}
                                                        >
                                                          Add Parent &nbsp;
                                                        </SpText>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => onRemoveParent(i, q)}
                                                        >
                                                          &nbsp; Remove
                                                        </SpText>
                                                      </Col>
                                                    ) : (
                                                      <Col span={8} push={16}>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => addNewParent(i)}
                                                          style={{ cursor: 'pointer' }}
                                                        >
                                                          Add Parent
                                                        </SpText>
                                                      </Col>
                                                    )
                                                  }
                                                </Row>
                                              </Col>
                                              <Col span={8} push={4}>
                                                {items.children.map((child, r) => (
                                                  <>
                                                    <Row className="ml-4">
                                                      <Col>
                                                        <label htmlFor=""><SpH5>{`Child ${r + 1}`}</SpH5></label>
                                                        <Input
                                                          value={child.label}
                                                          key={`Child${r}`}
                                                          onChange={(e) => {
                                                            const list = [...productDetailsArray];
                                                            list[i].comboBox[q].children[r].label = e.currentTarget.value;
                                                            list[i].comboBox[q].children[r].value = e.currentTarget.value;
                                                            setProductDetailsArray(list);
                                                          }}

                                                          placeholder="Enter child value"
                                                        />
                                                        <SpError className="mb-4">
                                                          {simpleValidator.current.message('child', child.label, 'required')}
                                                        </SpError>
                                                      </Col>
                                                    </Row>
                                                    <Row className="mt-1">
                                                      {
                                                        r > 0 ? (
                                                          <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" span={8} push={11}>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={(event) => addNewChild(event, i, q, r)}
                                                            >
                                                              Add Child &nbsp;
                                                            </SpText>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={() => onRemoveChild(i, q, r)}
                                                            >
                                                              &nbsp; Remove
                                                            </SpText>
                                                          </Col>
                                                        ) : (
                                                          <Col span={8} push={18}>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={(event) => addNewChild(event, i, q, r)}
                                                              style={{ cursor: 'pointer' }}
                                                            >
                                                              Add Child
                                                            </SpText>
                                                          </Col>
                                                        )
                                                      }
                                                    </Row>
                                                  </>

                                                ))}
                                              </Col>
                                            </Row>
                                          </>

                                        ))}
                                        <Row className="mt-4 mb-4">
                                          <Col span={16} push={4}>
                                            <label htmlFor=""><SpH5>Is ComboBox Required</SpH5></label>
                                            <Checkbox
                                              className="ml-4"
                                              checked={(item.isComboBoxRequired)}
                                              onChange={({ target: { checked } }) => {
                                                const list = [...productDetailsArray];
                                                list[i].isComboBoxRequired = checked;
                                                if (checked) {
                                                  list[i].isComboBoxRequired = true;
                                                } else if (!checked) {
                                                  list[i].isComboBoxRequired = false;
                                                }
                                                setProductDetailsArray(list);
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    )
                                  }
                                </>
                              )
                            }
                            {
                              productDetailsArray[i].type === 'PRICE_AMOUNT_QUANTITY_BASED' && (
                                <>
                                  <Row className="mt-4">
                                    <Col span={16} push={4}>
                                      <Checkbox
                                        className="mr-2"
                                        checked={(item.enableCombo)}
                                        onChange={({ target: { checked } }) => {
                                          const list = [...productDetailsArray];
                                          list[i].enableCombo = checked;
                                          if (checked) {
                                            list[i].enableCombo = 'checked';
                                          } else if (!checked) {
                                            list[i].enableCombo = '';
                                          }
                                          setProductDetailsArray(list);
                                        }}
                                      />
                                      <SpH5>Enable Combo Box</SpH5>
                                    </Col>
                                  </Row>
                                  {
                                    item.enableCombo && (
                                      <>
                                        {item.comboBox.map((items, q) => (
                                          <>
                                            <Row className="mt-4">
                                              <Col span={6} push={4}>
                                                <label htmlFor=""><SpH5>{`Parent ${q + 1}`}</SpH5></label>
                                                <Input
                                                  value={items.label}
                                                  onChange={(e) => {
                                                    const list = [...productDetailsArray];
                                                    list[i].comboBox[q].label = e.currentTarget.value;
                                                    list[i].comboBox[q].value = e.currentTarget.value;
                                                    setProductDetailsArray(list);
                                                  }}
                                                  key={`Parent${q}`}
                                                  placeholder="Enter parent value"
                                                />
                                                <SpError className="mb-4">
                                                  {simpleValidator.current.message('parent', items.label, 'required')}
                                                </SpError>
                                                <Row className="mt-1">
                                                  {
                                                    q > 0 ? (
                                                      <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" push={4}>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => addNewParent(i)}
                                                        >
                                                          Add Parent &nbsp;
                                                        </SpText>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => onRemoveParent(i, q)}
                                                        >
                                                          &nbsp; Remove
                                                        </SpText>
                                                      </Col>
                                                    ) : (
                                                      <Col push={13}>
                                                        <SpText
                                                          fontWeight="600"
                                                          color="#279dfe"
                                                          onClick={() => addNewParent(i)}
                                                          style={{ cursor: 'pointer' }}
                                                        >
                                                          Add Parent
                                                        </SpText>
                                                      </Col>
                                                    )
                                                  }
                                                </Row>
                                              </Col>
                                              <Col span={14} push={4}>
                                                {items.children.map((child, r) => (
                                                  <>
                                                    <Row className="ml-2">
                                                      <Col span={7}>
                                                        <label htmlFor=""><SpH5>{`Child ${r + 1}`}</SpH5></label>
                                                        <Input
                                                          value={child.label}
                                                          key={`Child${r}`}
                                                          onChange={(e) => {
                                                            const list = [...productDetailsArray];
                                                            list[i].comboBox[q].children[r].label = e.currentTarget.value;
                                                            list[i].comboBox[q].children[r].value = e.currentTarget.value;
                                                            setProductDetailsArray(list);
                                                          }}

                                                          placeholder="Enter child"
                                                        />
                                                        <SpError className="mb-4">
                                                          {simpleValidator.current.message('child', child.label, 'required')}
                                                        </SpError>
                                                      </Col>
                                                      <Col span={7} className="ml-2">
                                                        <label htmlFor=""><SpH5>{`Price ${r + 1}`}</SpH5></label>
                                                        <Input
                                                          value={child.additionalPrice}
                                                          key={`Price${r}`}
                                                          onChange={(e) => {
                                                            const list = [...productDetailsArray];
                                                            const regex = /^\d*\.?\d*$/;
                                                            const { value } = e.currentTarget;
                                                            const amount = value.replace(/\s/g, '');
                                                            if (regex.test(amount) || amount === '') {
                                                              list[i].comboBox[q].children[r].additionalPrice = amount;
                                                            } else if (!regex.test(amount)) {
                                                              list[i].comboBox[q].children[r].additionalPrice = 0.00;
                                                            }
                                                            setProductDetailsArray(list);
                                                          }}
                                                          onKeyPress={e => {
                                                            const keyCode = e.charCode || e.which;
                                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                              e.preventDefault();
                                                            }
                                                          }}
                                                          onBlur={e => {
                                                            if (e.currentTarget.value) {
                                                              const list = [...productDetailsArray];
                                                              // eslint-disable-next-line max-len
                                                              list[i].comboBox[q].children[r].additionalPrice = formatAmount(e.currentTarget.value, currencyType);
                                                              setProductDetailsArray(list);
                                                            }
                                                          }}
                                                        />
                                                        <SpError className="mb-4">
                                                          {simpleValidator.current.message('price', child.additionalPrice, 'required')}
                                                        </SpError>
                                                      </Col>
                                                    </Row>
                                                    <Row className="mt-1">
                                                      {
                                                        r > 0 ? (
                                                          <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" push={7}>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={(event) => addNewChild(event, i, q, r)}
                                                            >
                                                              Add Child &nbsp;
                                                            </SpText>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={() => onRemoveChild(i, q, r)}
                                                            >
                                                              &nbsp; Remove
                                                            </SpText>
                                                          </Col>
                                                        ) : (
                                                          <Col span={8} push={11}>
                                                            <SpText
                                                              fontWeight="600"
                                                              color="#279dfe"
                                                              onClick={(event) => addNewChild(event, i, q, r)}
                                                              style={{ cursor: 'pointer' }}
                                                            >
                                                              Add Child
                                                            </SpText>
                                                          </Col>
                                                        )
                                                      }
                                                    </Row>
                                                  </>

                                                ))}
                                              </Col>
                                            </Row>
                                          </>

                                        ))}
                                        <Row className="mt-4 mb-4">
                                          <Col span={16} push={4}>
                                            <label htmlFor=""><SpH5>Is ComboBox Required</SpH5></label>
                                            <Checkbox
                                              className="ml-4"
                                              checked={(item.isComboBoxRequired)}
                                              onChange={({ target: { checked } }) => {
                                                const list = [...productDetailsArray];
                                                list[i].isComboBoxRequired = checked;
                                                if (checked) {
                                                  list[i].isComboBoxRequired = true;
                                                } else if (!checked) {
                                                  list[i].isComboBoxRequired = false;
                                                }
                                                setProductDetailsArray(list);
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    )
                                  }
                                </>
                              )
                            }

                            <Row className="mt-4 mb-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                <Checkbox
                                  className="ml-4"
                                  checked={!(item.isRequired)}
                                  onChange={({ target: { checked } }) => {
                                    const list = [...productDetailsArray];
                                    list[i].isRequired = !checked;
                                    if (checked) {
                                      list[i].checked = '';
                                    } else if (!checked) {
                                      list[i].checked = 'checked';
                                    }
                                    setProductDetailsArray(list);
                                  }}
                                />
                              </Col>
                            </Row>

                            {show ? (
                              <Row className="mt-4 mb-4">
                                <Col span={16} push={4}>
                                  <SpButton
                                    type="secondary"
                                    style={{ width: '50%' }}
                                    onClick={() => removeProductDetails(i)}
                                  >
                                    {'\u2212'}&nbsp;Remove Input Field
                                  </SpButton>
                                </Col>
                              </Row>

                            ) : ''}
                          </div>
                        ))
                      }
                      <Row className="mt-4">
                        <Col span={16} push={4}>
                          <SpButton
                            type="secondary"
                            style={{ width: '50%' }}
                            onClick={addProductDetails}
                          >
                            {'\u002B'}&nbsp;Add Another Input Field
                          </SpButton>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col span={16} push={4}>
                          <Checkbox
                            className="mr-2"
                            checked={enableTip}
                            onChange={({ target: { checked } }) => {
                              setEnableTip(checked);
                            }}
                          />
                          <SpH5>Enable Tip</SpH5>
                        </Col>
                      </Row>
                      {
                        enableTip && (
                          <>
                            <Row className="mt-4">
                              <Col span={16} push={4}>
                                <div className="d-flex">
                                  <div style={{ width: '25%' }}>
                                    <SpH5>1st Option</SpH5>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <SpH5>2nd Option</SpH5>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <SpH5>3rd Option</SpH5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-1">
                              <Col span={16} push={4}>
                                <div className="d-flex">
                                  <div style={{ width: '25%' }}>
                                    <Input
                                      value={firstOption}
                                      placeholder="0.0 %"
                                      suffix="%"
                                      onChange={(e) => {
                                        const regex = /^[0-9]+$/;
                                        const { value } = e.currentTarget;
                                        if (regex.test(value) || value === '') {
                                          setFirstOption(e.currentTarget.value);
                                        } else if (!regex.test(value)) {
                                          setFirstOption(0.00);
                                        }
                                      }}
                                      onKeyPress={e => {
                                        const keyCode = e.charCode || e.which;
                                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onBlur={e => {
                                        if (e.currentTarget.value) {
                                          const value = formatAmount(e.currentTarget.value, currencyType);
                                          setFirstOption(value);
                                        }
                                      }}
                                    />
                                    <SpError>
                                      {simpleValidator.current.message('1st option', firstOption, 'required|tipAmount')}
                                    </SpError>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <Input
                                      value={secondOption}
                                      placeholder="0.0 %"
                                      suffix="%"
                                      onChange={(e) => {
                                        const regex = /^[0-9]+$/;
                                        const { value } = e.currentTarget;
                                        if (regex.test(value) || value === '') {
                                          setSecondOption(e.currentTarget.value);
                                        } else if (!regex.test(value)) {
                                          setSecondOption(0.00);
                                        }
                                      }}
                                      onKeyPress={e => {
                                        const keyCode = e.charCode || e.which;
                                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onBlur={e => {
                                        if (e.currentTarget.value) {
                                          const value = formatAmount(e.currentTarget.value, currencyType);
                                          setSecondOption(value);
                                        }
                                      }}
                                    />
                                    <SpError>
                                      {simpleValidator.current.message('2nd option', secondOption,
                                        `required|tipAmount|tipValidation1:${firstOption}`)}
                                    </SpError>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <Input
                                      value={thirdOption}
                                      placeholder="0.0 %"
                                      suffix="%"
                                      onChange={(e) => {
                                        const regex = /^[0-9]+$/;
                                        const { value } = e.currentTarget;
                                        if (regex.test(value) || value === '') {
                                          setThirdOption(e.currentTarget.value);
                                        } else if (!regex.test(value)) {
                                          setThirdOption(0.00);
                                        }
                                      }}
                                      onKeyPress={e => {
                                        const keyCode = e.charCode || e.which;
                                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onBlur={e => {
                                        if (e.currentTarget.value) {
                                          const value = formatAmount(e.currentTarget.value, currencyType);
                                          setThirdOption(value);
                                        }
                                      }}
                                    />
                                    <SpError>
                                      {/* eslint-disable-next-line max-len */}
                                      {simpleValidator.current.message('3rd option', thirdOption, `required|tipAmount|tipValidation:${firstOption}|tipValidation1:${secondOption}`)}
                                    </SpError>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-4 mb-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                <Checkbox
                                  className="ml-4"
                                  checked={!isTipOptional}
                                  onChange={({ target: { checked } }) => {
                                    setIsTipOptional(!checked);
                                  }}
                                />
                              </Col>
                            </Row>
                          </>
                        )
                      }
                      {
                        showAmountValidationError ? (
                          <Row className="mt-4 mb-4">
                            <Col span={16} push={4}>
                              <SpError className="mb-4">
                                Please select atleast one amount field type.
                              </SpError>
                            </Col>
                          </Row>
                        ) : null
                      }
                    </div>
                  )}
                  {current === 2 && (
                    <div>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="item-list">
                          {(provided) => (

                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                              {customerDetailsArray.map((item, j) => (
                                <Draggable key={`${item.type}${j}`} draggableId={`${item.type}${j}`} index={j}>
                                  {(provided) => (
                                    <li
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                    >
                                      <div
                                        style={{
                                          backgroundColor: '#fff',
                                          padding: '10px',
                                          margin: '25px 0',
                                          borderRadius: '5px',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <p>
                                          <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                                            <Col className="d-flex align-items-center">
                                              <div className="ml-2"><img src={reorderIcon} alt="" /></div>
                                            </Col>
                                            <Col className="d-flex align-items-center">
                                              {
                                                j === 0 ? (
                                                  <div className="mr-2">
                                                    <img src={arrowUpDisabled} alt="" />
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="mr-2"
                                                    onClick={() => onArrowUp(j)}
                                                  >
                                                    <img src={arrowUp} alt="" />
                                                  </div>
                                                )
                                              }
                                              {
                                                j === (customerDetailsArray.length - 1) ? (
                                                  <div
                                                    className="mr-2"
                                                  >
                                                    <img src={arrowDownDisabled} alt="" />
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="mr-2"
                                                    onClick={() => onArrowDown(j)}
                                                  >
                                                    <img src={arrowDown} alt="" />
                                                  </div>
                                                )
                                              }

                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col span={16} push={4}>
                                              <label htmlFor=""><SpH5>Input Field Types</SpH5></label>
                                              <div>
                                                <Select
                                                  style={{ width: '50%' }}
                                                  placeholder="Select field type"
                                                  value={item.type || undefined}
                                                  disabled={item.disableRequired ? true : ''}
                                                  onChange={(e) => {
                                                    const list = [...customerDetailsArray];
                                                    list[j].type = e;
                                                    setCustomerDetailsArray(list);
                                                  }}
                                                >
                                                  <Option key={1} value="INPUT_EMAIL">Email</Option>
                                                  <Option key={2} value="INPUT_PHONE">Phone</Option>
                                                  <Option key={3} value="INPUT_NUMBER">Number</Option>
                                                  <Option key={4} value="INPUT_ALPHA">Alpha</Option>
                                                  <Option key={5} value="INPUT_ALPHA_NUMERIC">Alpha Numeric</Option>
                                                  <Option key={6} value="INPUT_SINGLE_LINE_TEXT">Single Line Text</Option>
                                                  <Option key={7} value="INPUT_LARGE_TEXT">Large Text</Option>
                                                  <Option key={8} value="PROMOTIONAL_CODES">Promotional Code</Option>
                                                  <Option key={9} value="REFERENCE_NUMBER">Reference Number</Option>
                                                  <Option key={10} value="CONSENT_FORM">Consent Form</Option>
                                                  <Option key={11} value="FILE_UPLOAD">File Upload</Option>
                                                </Select>
                                              </div>
                                              <SpError>
                                                {simpleValidator.current.message(`type${j + 1}`, customerDetailsArray[j].type, 'required',
                                                  { messages: { required: 'Please select field type' } })}
                                              </SpError>
                                            </Col>
                                          </Row>
                                          <Row className="mt-4 mb-4">
                                            <Col span={16} push={4}>
                                              <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                              <Input
                                                placeholder={getPlaceholder(item.type || '')}
                                                value={item.label}
                                                maxlength="100"
                                                onChange={(e) => {
                                                  const list = [...customerDetailsArray];
                                                  list[j].label = e.currentTarget.value;
                                                  setCustomerDetailsArray(list);
                                                }}
                                              />
                                              <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                              <SpError>
                                                {simpleValidator.current.message(`Label${j + 1}`, customerDetailsArray[j].label, 'required',
                                                  { messages: { required: 'The label field is required' } })}
                                              </SpError>
                                            </Col>
                                          </Row>

                                          {item.type === 'FILE_UPLOAD' ? (
                                            <>
                                              <Row className="mt-4 mb-4">
                                                <Col span={16} push={4}>
                                                  <label htmlFor=""><SpH5>Description</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(optional)</SpText></label>
                                                  <TextArea
                                                    rows={5}
                                                    placeholder="Add the page description you want your customer to see"
                                                    value={item.description}
                                                    maxlength="300"
                                                    onChange={(e) => {
                                                      const list = [...customerDetailsArray];
                                                      list[j].description = e.currentTarget.value;
                                                      setCustomerDetailsArray(list);
                                                    }}
                                                  />
                                                  <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 300 Characters</SpText>
                                                </Col>
                                              </Row>
                                              <Row className="mt-4 mb-4">
                                                <Col span={16} push={4}>
                                                  <label htmlFor=""><SpH5>Document Upload Limits</SpH5></label>
                                                  <div className="d-flex gap-3">
                                                    <Input
                                                      placeholder="Min"
                                                      min={0}
                                                      type="number"
                                                      value={item.quantityMinLimit}
                                                      maxlength="100"
                                                      onChange={(e) => {
                                                        const list = [...customerDetailsArray];
                                                        list[j].quantityMinLimit = e.currentTarget.value;
                                                        setCustomerDetailsArray(list);
                                                      }}
                                                    />
                                                    <SpError className="ml-2">
                                                      {simpleValidator.current.message(
                                                        `uploadLimit${j + 1}`,
                                                        customerDetailsArray[j].quantityMinLimit,
                                                        `required|uploadLimit: ${customerDetailsArray[j].quantityMinLimit}`,
                                                        {
                                                          messages: {
                                                            required: 'The min upload limit is required',
                                                          },
                                                        },
                                                      )}
                                                    </SpError>
                                                    <Input
                                                      placeholder="Max"
                                                      max={100}
                                                      type="number"
                                                      value={item.quantityMaxLimit}
                                                      className="ml-3"
                                                      maxlength="100"
                                                      onChange={(e) => {
                                                        const list = [...customerDetailsArray];
                                                        list[j].quantityMaxLimit = e.currentTarget.value;
                                                        setCustomerDetailsArray(list);
                                                      }}
                                                    />
                                                    <SpError className="ml-2">
                                                      {simpleValidator.current.message(
                                                        `uploadLimit${j + 1}`,
                                                        customerDetailsArray[j].quantityMaxLimit,
                                                        `required|uploadLimit: ${customerDetailsArray[j].quantityMaxLimit}`,
                                                        {
                                                          messages: {
                                                            required: 'The max upload limit is required',
                                                          },
                                                        },
                                                      )}
                                                    </SpError>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </>
                                          ) : null}

                                          {item.type === 'CONSENT_FORM' ? (
                                            <Row className="mt-4 mb-4">
                                              <Col span={16} push={4}>
                                                <label htmlFor=""><SpH5>Waiver Description</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(optional)</SpText></label>
                                                <TextArea
                                                  rows={5}
                                                  placeholder="Add the page description you want your customer to see"
                                                  value={item.description}
                                                  maxlength="300"
                                                  onChange={(e) => {
                                                    const list = [...customerDetailsArray];
                                                    list[j].description = e.currentTarget.value;
                                                    setCustomerDetailsArray(list);
                                                  }}
                                                />
                                                <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 300 Characters</SpText>
                                              </Col>
                                            </Row>
                                          )


                                            : null}

                                          {item.type === 'CONSENT_FORM' ? (
                                            <Row className="mt-4 mb-4">
                                              <Col span={16} push={4}>
                                                <label htmlFor=""><SpH5>Add Waiasdver Document </SpH5></label>
                                                <Upload
                                                  name="avatar"
                                                  listType="picture-card upload-card"
                                                  className="avatar-uploader"
                                                  showUploadList={false}
                                                  beforeUpload={beforeUpload}
                                                  onChange={(info) => fileUpload(info, j)}

                                                >
                                                  {item.documentUrl ? item.documentUrl : uploadButton}
                                                </Upload>
                                                <SpError>
                                                  {
                                                    simpleValidator.current.message('FileUpload', item.documentUrl, 'required')
                                                  }
                                                </SpError>
                                              </Col>
                                            </Row>
                                          )
                                            : null}


                                          <Row className="mt-4 mb-4">
                                            <Col span={16} push={4}>
                                              <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                              <Checkbox
                                                className="ml-4"
                                                disabled={item.disableRequired || false}
                                                checked={!(item.isRequired)}
                                                onChange={({ target: { checked } }) => {
                                                  const list = [...customerDetailsArray];
                                                  list[j].isRequired = !checked;
                                                  if (checked) {
                                                    list[j].checked = '';
                                                  } else if (!checked) {
                                                    list[j].checked = 'checked';
                                                  }
                                                  setCustomerDetailsArray(list);
                                                }}
                                              />
                                            </Col>
                                          </Row>


                                          {
                                            (!item.disableRequired) ? (
                                              <Row className="mt-4 mb-4">
                                                <Col span={16} push={4}>
                                                  <SpButton
                                                    type="secondary"
                                                    style={{ width: '50%' }}
                                                    onClick={() => removeCustomerDetails(j)}
                                                  >
                                                    {'\u2212'}&nbsp;Remove Input Field
                                                  </SpButton>
                                                </Col>
                                              </Row>
                                            ) : ''
                                          }
                                        </p>
                                      </div>
                                    </li>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>


                      <Row className="mt-4">
                        <Col span={16} push={8}>
                          <SpButton
                            type="secondary"
                            style={{ width: '50%' }}
                            onClick={addCustomerDetails}
                          >
                            {'\u002B'}&nbsp;Add Another Input Fields
                          </SpButton>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {
                    current === 3 && (
                      <AccontSlug
                        ref={refArray[0]}
                        selectedAccount={selectedAccount}
                        pageData={pageData}
                        isIndia={isIndia}
                        accountSlug={accountSlug}
                        submitting={submitting}
                        newSlug={newSlug}
                        withoutSlug={withoutSlug}
                      />
                    )
                  }
                  {
                    current === 4 && (
                      <ReviewPaymentPage
                        pageData={pageData}
                        addParent={addParent}
                        submitting={submitting}
                        productDetailsArray={productDetailsArray}
                        customerDetailsArray={customerDetailsArray}
                        account={account}
                        contactEmail={contactEmail}
                        enableTip={enableTip}
                        isTipOptional={isTipOptional}
                        firstOption={firstOption}
                        secondOption={secondOption}
                        thirdOption={thirdOption}
                      />
                    )
                  }
                </div>
                <div className="d-flex justify-content-end mt-auto mr-4">
                  {current > 0 && (
                    <SpButton type="secondary" className="mr-4" onClick={prev}>
                      Back
                    </SpButton>
                  )}
                  {current < steps.length - 1 && (
                    <SpButton type="primary" onClick={next}>
                      Next
                    </SpButton>
                  )}
                  {current === steps.length - 1 && (
                    <SpButton type="primary" onClick={next} disabled={disableButton}>
                      Create
                    </SpButton>
                  )}
                </div>
              </PaymentFormWrapper>
            </PaymentWrapper>
          </Layout>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  paymentPagesDetails: state.paymentPagesDetails,
  paymentPagesDetailsStatus: state.paymentPagesDetails.paymentPagesDetailsStatus,
  accountSlug: state.accountSlug.content,
});

const mapDispatchToProps = (dispatch) => ({
  addPaymentPages: param => dispatch(paymentPagesActions.addPaymentPages({
    payload: param,
  })),
  fetchPaymentPagesDetails: gid => dispatch(paymentPagesDetailsActions.fetchPaymentPagesDetails({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchAccountSlug: param => dispatch(accountSlugActions.fetchAccountSlug({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentPage);
