// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  couponDetails: Object,
};

const DeleteSelectedCoupon = (props: Props) => {
  const {
    visible,
    close,
    submit,
    couponDetails,
  } = props;

  const deleteSelectedCoupon = () => {
    submit(couponDetails.gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={deleteSelectedCoupon} type="danger">Deactivate</SpButton>,
      ]}
      title="Deactivate Coupon"
    >
      <div className="px-4">
        <h6 className="mt-1 mb-2">Are you sure you want to deactivate this coupon?</h6>
        <SpH5 className="pb-3">
          <div>{couponDetails.name}</div>
        </SpH5>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteSelectedCoupon;
