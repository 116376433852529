import React from 'react';
import { Table } from 'antd';
import moment from 'moment-timezone';
import { withRouter } from 'react-router';

import formatAmount from 'utils/formatAmount';
import { PAGES } from 'appconstants';

type Props = {
  history: {
    push: Function,
  },
  dataSource: Array,
  selectedAccount: Object,
  summaryType: String,
};

const TransactionTable = ({
  history,
  dataSource,
  selectedAccount,
  summaryType,
}: Props) => {
  const currencyType = selectedAccount && selectedAccount.currency;
  const transfersColums = [
    {
      title: 'NAME',
      dataIndex: 'name',
      align: 'left',
      render: (name, record) => (record.contact
        ? record.contact.name
        : <>&#8211;</>
      ),
      sorter: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      align: 'left',
      render: (amount, record) => (amount ? `${record.currency.prefix}${formatAmount(amount / 100, currencyType)}` : <>&#8211;</>),
      sorter: false,
    },
    {
      title: 'DATE',
      dataIndex: 'transferDate',
      align: 'left',
      render: (transferDate) => {
        if (transferDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(transferDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: false,
    },
  ];
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      align: 'left',
      render: (name, record) => (record.customer
        ? record.customer.name
        : <>&#8211;</>
      ),
      sorter: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      align: 'left',
      render: (amount, record) => (amount ? `${record.currency.prefix}${formatAmount(amount / 100, currencyType)}` : <>&#8211;</>),
      sorter: false,
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: false,
    },
  ];

  return (
    <Table
      onRow={(record) => ({
        onClick: () => {
          if (summaryType === 'Payments') {
            history.push(`${PAGES.PAYMENTS}/${record.gid}`);
          } else {
            history.push(`${PAGES.TRANSFERS}/${record.gid}`);
          }
        },
      })}
      rowClassName={() => 'ant-table-clickable-row'}
      columns={summaryType === 'Payments' ? columns : transfersColums}
      dataSource={dataSource}
      rowKey={(record) => record.gid}
      className="mt-2"
      pagination={false}
    />
  );
};

export default withRouter(TransactionTable);
