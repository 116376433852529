import { INTERNAL_MEMBERSHIP } from '../actionTypes';

export const fetchInternalMembership = action => ({
  type: INTERNAL_MEMBERSHIP.FETCH,
  payload: action.payload,
});

export const addInternalMembership = (action) => ({
  type: INTERNAL_MEMBERSHIP.ADD,
  payload: action.payload,
});

export const updateInternalMembership = (action) => ({
  type: INTERNAL_MEMBERSHIP.UPDATE,
  payload: action.payload,
});

export const deleteInternalMembership = (action) => ({
  type: INTERNAL_MEMBERSHIP.DELETE,
  payload: action.payload,
});

export const setInternalSelectedUser = (action) => ({
  type: INTERNAL_MEMBERSHIP.SELECTED,
  payload: action.payload,
});
