import { SPFIELD } from '../actionTypes';

export const fetchSpField = action => ({
  type: SPFIELD.FETCH,
  payload: action.payload,
});

export const addSpField = (action) => ({
  type: SPFIELD.ADD,
  payload: action.payload,
});

export const updateSpField = (action) => ({
  type: SPFIELD.UPDATE,
  payload: action.payload,
});

export const deleteSpField = (action) => ({
  type: SPFIELD.DELETE,
  payload: action.payload,
});

export const setSelectedUser = (action) => ({
  type: SPFIELD.SELECTED,
  payload: action.payload,
});
