import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { spFieldActions, spObjectActions } from 'store/actions';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { PAGES, ROLES } from 'appconstants';
import {
  Card, Col, Icon, Row, Table, Tooltip,
} from 'antd';
import { SpButton } from 'components/DesignKit';
import RBAC from 'components/rbac';
import AddSpField from './components/AddSpField';
import DeleteSpField from './components/DeleteSpField';
import EditSpField from './components/EditSpField';

type Props = {
  spFields: Array<Object>,
  spObjects: Array<Object>,
  fetchSpField: Function,
  fetchAllSpObject: Function,
  addSpField: Function,
  updateSpField: Function,
  deleteSpField: Function,
  totalElements: number,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
}

const SpField = (props: Props) => {
  const {
    spObjects,
    spFields,
    addSpField,
    updateSpField,
    deleteSpField,
    fetchSpField,
    fetchAllSpObject,
    totalElements,
    submitting,
    validator,
    history,
  } = props;

  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const [addSpFieldModal, setAddSpFieldModal] = useState(false);
  const [editSpFieldModal, setEditSpFieldModal] = useState(false);
  const [deleteSpFieldModal, setDeleteSpFieldModal] = useState(false);
  const [selectedSpField, setSelectedSpField] = useState({});

  useEffect(() => {
    if (!validator) {
      setAddSpFieldModal(false);
      setEditSpFieldModal(false);
      setDeleteSpFieldModal(false);
    }
  }, [validator]);

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    fetchSpField({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    fetchAllSpObject({});
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [spFields]);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.SP_FIELD,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.SP_FIELD,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  useEffect(() => {
    getData(location.search);
  }, [location]);


  const addNewSpField = (spField: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const params = { ...spField };
    addSpField({
      params,
      currentPage,
      sortParams,
    });
  };

  useEffect(() => {
    if (!validator) {
      setAddSpFieldModal(false);
      setEditSpFieldModal(false);
      setDeleteSpFieldModal(false);
    }
  }, [validator]);

  const showDeleteSpFieldModal = (value: Object) => {
    setSelectedSpField(value);
    setDeleteSpFieldModal(true);
  };

  const showEditSPFieldModal = (value: Object) => {
    setSelectedSpField(value);
    setEditSpFieldModal(true);
  };

  const deleteSPField = (gid: string) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    deleteSpField({
      currentPage,
      sortParams,
      gid,
    });
  };

  const editSpField = (spField: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const params = { ...spField };
    updateSpField({
      currentPage,
      sortParams,
      gid: selectedSpField.gid,
      params,
    });
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '16%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'FIELD NAME',
      dataIndex: 'fieldName',
      width: '22%',
      align: 'left',
    },
    {
      title: 'SP Object',
      dataIndex: 'spObject.type',
      width: '22%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showDeleteSpFieldModal(record); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            className="ml-3"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showEditSPFieldModal(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.SUPERUSER]}>
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                ghost
                onClick={() => setAddSpFieldModal(true)}
              >
                {'\u002B'}&nbsp;Add New
              </SpButton>
            </Col>
          </RBAC>
        </div>
      </Row>
      {/* ADD SpField MODAL */}
      { addSpFieldModal && (
        <AddSpField
          visible={addSpFieldModal}
          submitting={submitting}
          spObjects={spObjects}
          close={() => { setAddSpFieldModal(false); }}
          submit={addNewSpField}
        />
      )}

      {/* DELETE SpField MODAL */}
      { deleteSpFieldModal && (
        <DeleteSpField
          visible={deleteSpFieldModal}
          selectedSpField={selectedSpField}
          submitting={submitting}
          close={() => setDeleteSpFieldModal(false)}
          submit={deleteSPField}
        />
      )}

      {/* EDIT SpField MODAL */}
      { editSpFieldModal && (
        <EditSpField
          visible={editSpFieldModal}
          selectedSpField={selectedSpField}
          submitting={submitting}
          close={() => setEditSpFieldModal(false)}
          submit={editSpField}
          spObjects={spObjects}
        />
      )}

      <Table
        rowClassName={() => 'ant-table-clickable-row'}
        columns={[...columns, actionColumn]}
        rowKey={(record) => record.gid}
        dataSource={spFields}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => (
  {
    spFields: state.spField.content,
    totalElements: state.spField.totalElements,
    loading: state.loading.loading,
    validator: state.loading.validator,
    submitting: state.loading.submitting,
    test: state.account.test,
    isSuperUser: state.user.superUser,
    spObjects: state.spObject.entityList,
  });

const mapDispatchToProps = (dispatch) => ({
  fetchSpField: param => dispatch(spFieldActions.fetchSpField({
    payload: param,
  })),
  fetchAllSpObject: param => dispatch(spObjectActions.fetchAllSpObject({
    payload: param,
  })),
  addSpField: param => dispatch(spFieldActions.addSpField({
    payload: param,
  })),
  updateSpField: param => dispatch(spFieldActions.updateSpField({
    payload: param,
  })),
  deleteSpField: param => dispatch(spFieldActions.deleteSpField({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpField);
