function removeAdditionalPrice(item) {
  // eslint-disable-next-line no-param-reassign
  item.children.forEach(child => delete child.additionalPrice);
  return item;
}
export default (data, productDetailsArray, customerDetailsArray, addParent,
  enableTip,
  isTipOptional,
  firstOption,
  secondOption,
  thirdOption) => {
  const productDetails = productDetailsArray.map((result, i) => {
    // eslint-disable-next-line
    let temp = Object.assign({ "sequenceNumber": 1 + i, }, result);
    if (temp.description === '') {
      temp.description = null;
    }
    if (temp.amount === '') {
      temp.amount = null;
    }
    if (temp.amount) {
      temp.amount = Number((temp.amount).replace(/[. ,:-]+/g, ''));
    }
    if (temp.quantityMinLimit) {
      temp.quantityMinLimit = Number(temp.quantityMinLimit);
    }
    if (temp.quantityMaxLimit === '') {
      temp.quantityMaxLimit = null;
    }
    if (temp.quantityMaxLimit) {
      temp.quantityMaxLimit = Number(temp.quantityMaxLimit);
    }
    if (temp.minPrice === '0.00') {
      temp.minPrice = Number(temp.minPrice);
    }
    if (temp.minPrice) {
      temp.minPrice = Number((temp.minPrice).replace(/[. ,:-]+/g, ''));
    }
    if (temp.maxPrice === '') {
      temp.maxPrice = null;
    }
    if (temp.maxPrice) {
      temp.maxPrice = Number((temp.maxPrice).replace(/[. ,:-]+/g, ''));
    }
    if (!temp.enableCombo) {
      temp.comboBox = null;
      temp.isComboBoxRequired = false;
    }
    if (temp.type === 'PRICE_AMOUNT_FIXED') {
      if (temp.enableCombo) {
        const info = temp.comboBox;
        const updatedData = info.map(removeAdditionalPrice);
        temp.comboBox = updatedData;
      }
    }
    if (temp.enableCombo && temp.type === 'PRICE_AMOUNT_QUANTITY_BASED') {
      const tempArr = temp.comboBox;
      // eslint-disable-next-line
      const newArr = tempArr.map(obj => {
        return {
          ...obj,
          // eslint-disable-next-line
          children: obj && obj.children && obj.children.map(child => {
            return {
              ...child,
              // eslint-disable-next-line
              additionalPrice: child.additionalPrice && (typeof (child.additionalPrice) !== 'number')
                ? Number((child.additionalPrice).replace(/[. ,:-]+/g, '')) : child.additionalPrice
                  ? child.additionalPrice : null,
            };
          }),
        };
      });
      temp.comboBox = newArr;
    }
    if (temp.type === 'COMBO_BOX') {
      temp.comboBox = addParent;
      temp.quantityMinLimit = null;
      temp.minPrice = null;
    }
    let currency;
    if (temp.currencyCode === 'USD') {
      const currencyObj = {
        id: 1,
        name: 'USD',
        prefix: '$',
        toFixed: 2,
        countryAlpha2: 'US',
      };
      // eslint-disable-next-line
      currency = { "currency": currencyObj, };
    }
    if (temp.currencyCode === 'INR') {
      const currencyObj = {
        id: 2,
        name: 'INR',
        prefix: '₹',
        toFixed: 2,
        countryAlpha2: 'IN',
      };
      // eslint-disable-next-line
      currency = { "currency": currencyObj };
    }
    if (temp.currencyCode === 'CAD') {
      const currencyObj = {
        id: 41,
        name: 'CAD',
        prefix: '₹',
        toFixed: 2,
        countryAlpha2: 'CA',
      };
      // eslint-disable-next-line
      currency = { "currency": currencyObj };
    }
    // eslint-disable-next-line
    const productdata = temp.type === 'COMBO_BOX' ? Object.assign(temp) : Object.assign(currency, temp);
    return productdata;
  });

  const length = productDetailsArray.length + 1;
  const currencyName = productDetailsArray[0].currencyCode;
  const customerDetails = customerDetailsArray.map((result, i) => {
    // eslint-disable-next-line
    let temp = Object.assign({ "sequenceNumber": length + i, currencyCode: currencyName, }, result);
    return temp;
  });
  let tipDetails;
  if (enableTip) {
    tipDetails = [
      {
        sequenceNumber: (productDetailsArray.length + customerDetailsArray.length + 1),
        currencyCode: currencyName,
        type: 'TIP',
        label: 'Tip',
        isRequired: isTipOptional,
        tipPercentages: [
          Number((firstOption).replace(/[^\d]/g, '')),
          Number((secondOption).replace(/[^\d]/g, '')),
          Number((thirdOption).replace(/[^\d]/g, '')),
        ],
      },
    ];
  }
  const newArray = enableTip ? [...productDetails, ...tipDetails, ...customerDetails] : [...productDetails, ...customerDetails];
  // eslint-disable-next-line
  const paymentPageData = Object.assign({ ...data, "paymentPageMetaFields": newArray });
  return paymentPageData;
};
