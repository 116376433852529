import React from 'react';
import moment from 'moment-timezone';
import { Icon } from 'antd';
import {
  SpH5,
} from 'components/DesignKit';

import getPaymentType from 'utils/getPaymentType';
import getTimelineColor from 'utils/getTimelineColor';
import getOrderStatus from 'utils/getOrderStatus';

type Props = {
  paymentTimeline: Array<Object>,
  selectedAccount: Object,
  wooCommerceOrder: Boolean,
}

const PaymentTimeline = (props: Props) => {
  const {
    paymentTimeline,
    selectedAccount,
    wooCommerceOrder,
  } = props;

  const getPaymentDate = (updatedAt) => moment
    .utc(updatedAt)
    .tz(selectedAccount.timezone)
    .format('MMM DD, YYYY, hh:mm a');

  const sortedPaymentTimeline = wooCommerceOrder
    ? paymentTimeline.sort((b, a) => new Date(b.wcOrderEventTimeline) - new Date(a.wcOrderEventTimeline))
    : paymentTimeline.sort((b, a) => new Date(b.paymentPageOrderEventTimeline) - new Date(a.paymentPageOrderEventTimeline));

  return (
    <div className="bar-container">
      {
        sortedPaymentTimeline.map((data, id, arr) => (
          <div className="item" key={id}>
            <div className="left">
              <SpH5>
                {(data.paymentPageOrderEventTimeline && selectedAccount && selectedAccount.timezone)
                  ? getPaymentDate(data.paymentPageOrderEventTimeline)
                  : (data.updatedAt && selectedAccount && selectedAccount.timezone)
                    ? getPaymentDate(data.updatedAt) : ''}
              </SpH5>
            </div>
            <div className="middle">
              <Icon type={getPaymentType(data.type)} style={{ color: getTimelineColor(data.type) }} />
              {id !== arr.length - 1 ? <div className="line" /> : null}
            </div>
            <div className="right">
              <div>
                <SpH5>
                  {data.orderStatus ? getOrderStatus(data.orderStatus) : getOrderStatus(data.spOrderStatus)}
                </SpH5>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default PaymentTimeline;
