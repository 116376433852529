export default value => {
  if (value === 'DAY') {
    return 'Day(s)';
  } if (value === 'WEEK') {
    return 'Week(s)';
  } if (value === 'MONTH') {
    return 'Month(s)';
  }
  return 'Year(s)';
};
