// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  Checkbox,
  Switch,
  Radio,
  DatePicker,
  TimePicker,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';
import formatAmount from 'utils/formatAmount';
import {
  SpH5, SpError, SpButton, SpText,
} from 'components/DesignKit';
import {
  COUPON_DURATION,
  MAX_AMOUNT,
  COUNTRIES,
} from 'appconstants';

const { Option } = Select;
const dateFormat = 'MMM DD, YYYY';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  fetchCustomer: Function,
  allCustomers: Array<Object>,
};

const AddNewTip = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    handleTipType,
    handleTipAmount,
  } = props;

  const [, forceUpdate] = useState();
  const [disableButton, setDisableButton] = useState(false);

  const [percentageOff, setPercentageOff] = useState(null);
  const [percentage, setPercentage] = useState(true);
  const [amountOff, setAmountOff] = useState(null);
  const [amount, setAmount] = useState(false);
  const [validity, setValidity] = useState('');
 
//   const simpleValidator = useRef(new SimpleReactValidator({
//     validators: {
//       percentage: {
//         message: MAX_AMOUNT.MESSAGE_PERCENTAGE,
//         rule: (val) => MAX_AMOUNT.LIMIT_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_MAX,
//       },
//       amount: {
//         message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
//         rule: (val) => (
//           isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
//             : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
//       },
 
//     },
  // }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);






  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    // const formValid = simpleValidator.current.allValid();
    // setDisableButton(formValid);
    // if (!formValid) {
    //   simpleValidator.current.showMessages();
    //   forceUpdate(1);
    //   return;
    // }
    // let expiresAt = null;


    submit({
      percentageOff,
      amountOff,
      validity,
     
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={(percentageOff===null ||percentageOff==="" ) && (amountOff===null ||amountOff ==="")}>Create</SpButton>,
      ]}
      title="Add Tip"
    >
      <div className="px-4">
      

        <div className="pb-3">
          <label className="d-block"><SpH5>Type</SpH5></label>
          <div className="d-flex pb-3">
            <div className="mr-3">
              <Radio
                checked={percentage}
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    handleTipType("PERCENTAGE");
                    setPercentage(true);
                    setAmount(false);
                    setAmountOff(null);
                  } else if (!checked) {
                    setPercentage(false);
                    setAmount(true);
                    handleTipType("AMOUNT");
                  }
                }}
              />
              <SpText>Percentage</SpText>
            </div>
            <div>
              <Radio
                checked={amount}
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    handleTipType("AMOUNT");
                    setPercentage(false);
                    setAmount(true);
                    setPercentageOff(null);
                  } else if (!checked) {
                    handleTipType("PERCENTAGE");
                    setPercentage(true);
                    setAmount(false);
                  }
                }}
              />
              <SpText>Amount</SpText>
            </div>
          </div>
          <div className="w-50 mr-3">
            {
              percentage && (
                <>
                  <Input
                    value={percentageOff}
                    suffix="%"
                    onChange={(e) => {setPercentageOff(e.currentTarget.value);
                      handleTipAmount(e.currentTarget.value);}
                    }
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      if (e.currentTarget.value) {
                        const { value } = e.currentTarget;
                        setPercentageOff(value);
                      }
                    }}
                  />
                  {/* <SpError>
                    {percentage && simpleValidator.current.message('percentage', percentageOff, 'required|percentage')}
                  </SpError>*/ }
                </>
              )
            }
            {
              amount && (
                <>
                  <Input
                    value={amountOff}
                    // prefix={prefix}
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/;
                      const { value } = e.currentTarget;
                      if (regex.test(value) || value === '') {
                        setAmountOff(e.currentTarget.value);
                        handleTipAmount(e.currentTarget.value);
                      } else if (!regex.test(value)) {
                        setAmountOff(null);
                        handleTipAmount(e.currentTarget.value);
                      }
                    }}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                    //   if (e.currentTarget.value) {
                    //     const value = formatAmount(e.currentTarget.value, currencyType);
                    //     setAmountOff(value);
                    //   }
                    }}
                  />
                  {/* <SpError>
                    {amount && simpleValidator.current.message('amount', amountOff, 'required|amount')}
                  </SpError> */}
                </>
              )
            }
          </div>
        </div>


      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddNewTip);
