import { SUBSCRIPTION_STATUSES } from 'appconstants';

export default value => {
  if (value === 'ACTIVE') {
    return SUBSCRIPTION_STATUSES.ACTIVE;
  } if (value === 'PAID') {
    return SUBSCRIPTION_STATUSES.PAID;
  } if (value === 'CANCELLED') {
    return SUBSCRIPTION_STATUSES.CANCELLED;
  } if (value === 'DRAFT') {
    return SUBSCRIPTION_STATUSES.DRAFT;
  } if (value === 'CREATE') {
    return SUBSCRIPTION_STATUSES.CREATE;
  } if (value === 'PROCESSING') {
    return SUBSCRIPTION_STATUSES.PROCESSING;
  } if (value === 'INCOMPLETE') {
    return SUBSCRIPTION_STATUSES.INCOMPLETE;
  } return SUBSCRIPTION_STATUSES.PAST_DUE;
};
