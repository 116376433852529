// @flow
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import {
  SpButton,
  SpStatusTag,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import ExportIcon from 'components/NavIcons/exportIcon';
import FilterIcon from 'components/NavIcons/filterIcon';

import getAffiliateTransactionBackground from 'utils/getAffiliateTransactionBackground';
import getFilterOperators from 'utils/getFilterOperators';
import formatNumber from 'utils/formatNumber';

import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
} from 'appconstants';
import {
  affiliateTransactionActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import RBAC from 'components/rbac';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const TRANSACTIONS_FILTERS_TYPES = FILTERS_TYPES.PARTNER_TRANSACTIONS;

type Props = {
  accounts: {
    content: Array<Object>
  },
  fetchAffiliateTransactions: Function,
  affiliateTransctions: Array<Object>,
  loading: boolean,
  test: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  setFilterData: Function,
  preserveFilters: Object,
};

const TransactionList = (props: Props) => {
  const myRef = useRef();
  const [pagination, setPagination] = useState({});
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [count, setCount] = useState(null);
  const {
    loading,
    fetchAffiliateTransactions,
    totalElements,
    affiliateTransctions,
    test,
    history,
    selectedAccount,
    setFilterData,
    preserveFilters,
  } = props;
  const { pathname } = location;
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [filterTransactions, setFilterTransactions] = useState((preserveFilters && preserveFilters.filterTransactions)
    ? (preserveFilters && preserveFilters.filterTransactions)
    : [{
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [filterTypes, setFilterTypes] = useState(TRANSACTIONS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const currencyType = selectedAccount && selectedAccount.currency;
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterTransactions: [{
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });

  const debouncedFetchAffiliateTransactions = debounce(fetchAffiliateTransactions, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchAffiliateTransactions({ currentPage, sortParams, filterParams });
  };

  const exportData = affiliateTransctions ? affiliateTransctions.map((values) => ({
    name: values.affiliateAccount ? values.affiliateAccount.name : '',
    amount: `${currencyType.prefix}${formatNumber((values.amount / 100).toFixed(2), currencyType)}`,
    total: `${currencyType.prefix}${formatNumber(((values.commission ? values.commission : values.feesCollected) / 100).toFixed(2), currencyType)}`,
    processed: values.processed ? 'Processed' : 'Not Processed',
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
  })) : '';

  const exportHeaders = [
    { label: 'ACCOUNT', key: 'name' },
    { label: 'AMOUNT', key: 'amount' },
    { label: 'APPLICATION FEE', key: 'total' },
    { label: 'Fee collected', key: 'fee' },
    { label: 'STATUS', key: 'processed' },
    { label: 'DATE', key: 'createdAt' },
  ];

  useEffect(() => {
    getData(location.search);
  }, [test, selectedAccount, location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [affiliateTransctions]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      // inside click
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      // Outside click
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterTransactions([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(TRANSACTIONS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.PARTNER_TRANSACTIONS);
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.PARTNER_TRANSACTIONS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.PARTNER_TRANSACTIONS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterTransactions];
    if (filterTransactions.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = TRANSACTIONS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTransactions(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterTransactions];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'commission') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'feesCollected') {
      list[index].filterType = 'Number';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = TRANSACTIONS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTransactions(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterTransactions];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterTransactions(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterTransactions(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterTransactions];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterTransactions(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterTransactions(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterTransactions[index].filterName === 'createdAt') {
      if (filterTransactions[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterTransactions[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterTransactions[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterTransactions];
    list[index].filterValue = selectedValue;
    setFilterTransactions(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTransactions];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterTransactions[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterTransactions(list);
  };

  const handleAddClick = () => {
    const list = [...filterTransactions];
    setFilterTransactions([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const applyFilters = () => {
    const transactinFilters = filterTransactions.filter((item) => item.checked);
    for (let i = 0; i < transactinFilters.length; i += 1) {
      if (!transactinFilters[i].filterName || !transactinFilters[i].filterOperator || !transactinFilters[i].filterValue) {
        return;
      }
    }
    if (transactinFilters[0].filterValue || (transactinFilters[0].filterValueFrom && transactinFilters[0].filterValueTo)) {
      setCount(transactinFilters && transactinFilters.length);
      for (let i = 0; i < transactinFilters.length; i += 1) {
        if (transactinFilters[i].checked) {
          if (transactinFilters[i].filterName === 'createdAt') {
            transactinFilters[i] = transactinFilters[i].filterValue;
          } else if (transactinFilters[i].filterName === 'amount' && transactinFilters[i].filterOperator === 'BT') {
            const name = transactinFilters[i].filterName;
            const from = transactinFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = transactinFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            transactinFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (transactinFilters[i].filterName === 'amount' && transactinFilters[i].filterOperator !== 'BT') {
            const value = transactinFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            transactinFilters[i] = `${transactinFilters[i].filterName}.${transactinFilters[i].filterOperator}=${value}`;
          } else if (transactinFilters[i].filterName === 'commission' && transactinFilters[i].filterOperator === 'BT') {
            const name = transactinFilters[i].filterName;
            const from = transactinFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = transactinFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            transactinFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (transactinFilters[i].filterName === 'commission' && transactinFilters[i].filterOperator !== 'BT') {
            const value = transactinFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            transactinFilters[i] = `${transactinFilters[i].filterName}.${transactinFilters[i].filterOperator}=${value}`;
          } else if (transactinFilters[i].filterName === 'feesCollected' && transactinFilters[i].filterOperator === 'BT') {
            const name = transactinFilters[i].filterName;
            const from = transactinFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = transactinFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            transactinFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (transactinFilters[i].filterName === 'feesCollected' && transactinFilters[i].filterOperator !== 'BT') {
            const value = transactinFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            transactinFilters[i] = `${transactinFilters[i].filterName}.${transactinFilters[i].filterOperator}=${value}`;
          } else {
            transactinFilters[i] = `${transactinFilters[i].filterName}.${transactinFilters[i].filterOperator}=${transactinFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(transactinFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterTransactions,
        });
        history.push({
          pathname: PAGES.PARTNER_TRANSACTIONS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterTransactions,
        });
        history.push({
          pathname: PAGES.PARTNER_TRANSACTIONS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };


  const columns = [
    {
      title: 'ACCOUNT',
      dataIndex: 'affiliateAccount.name',
      width: '14%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '14%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount) => {
        const total = (amount / 100).toFixed(2);
        return (
          <span> {`${currencyType.prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'Application Fee',
      dataIndex: 'total',
      width: '14%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        const total = record.feesCollected;
        if (total < 0) {
          return (
            <span> {`(${currencyType.prefix} ${formatNumber((total / 100).toFixed(2), currencyType).replace(/-/g, '')})`}</span>
          );
        }
        return (
          <span> {`${currencyType.prefix} ${formatNumber((total / 100).toFixed(2), currencyType)}`}</span>
        );
      },
    },
    {
      title: 'Fee collected',
      dataIndex: 'fee',
      width: '14%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        const fee = record.commission;
        if (fee < 0) {
          return (
            <span> {`(${currencyType.prefix} ${formatNumber((fee / 100).toFixed(2), currencyType).replace(/-/g, '')})`}</span>
          );
        }
        return (
          <span> {`${currencyType.prefix} ${formatNumber((fee / 100).toFixed(2), currencyType)}`}</span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'processed',
      width: '12%',
      align: 'left',
      render: (processed) => {
        const status = processed ? 'Processed' : 'Not Processed';
        return (
          <span>
            <SpStatusTag style={{ backgroundColor: getAffiliateTransactionBackground(status) }}>
              {status}
            </SpStatusTag>
          </span>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '14%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC
            permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
          >
            <Col>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Transactions">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Transactions Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Transactions">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-262px' }}
                  >
                    <Filters
                      filtersGrid={filterTransactions}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                      handleFromValue={handleFromValue}
                      handleToValue={handleToValue}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterTransactions.length < TRANSACTIONS_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              {'\u002B'}&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      <Table
        columns={columns}
        rowKey={(record) => record.gid}
        dataSource={affiliateTransctions}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  affiliateTransctions: state.affiliateTransctions.content,
  loading: state.loading.loading,
  totalElements: state.affiliateTransctions.totalElements,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  accounts: state.account.accounts,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAffiliateTransactions: param => dispatch(affiliateTransactionActions.fetchAffiliateTransactions({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
