import { CUSTOM_REPORT } from '../actionTypes';

export const fetchCustomReports = (action) => ({
  type: CUSTOM_REPORT.FETCH,
  payload: action.payload,
});

export const addCustomReport = (action) => ({
  type: CUSTOM_REPORT.ADD,
  payload: action.payload,
});
