// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  selectedSpField: any
};

const DeleteSpField = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedSpField,
  } = props;

  const validate = (event) => {
    event.preventDefault();
    submit(selectedSpField.gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} type="danger">Delete</SpButton>,
      ]}
      title="Delete SP Field"
    >
      <div className="px-4">
        <div className="py-2">Deleting SP Field <SpH5>{selectedSpField && selectedSpField.name} </SpH5>
          will delete all mappings referencing this field effectively. Are you sure you want to proceed?
        </div>
      </div>
    </Modal>
  );
};

export default DeleteSpField;
