import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Icon,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import RBAC from 'components/rbac';
import getFilterOperators from 'utils/getFilterOperators';
import moment from 'moment-timezone';

import {
  documentsActions,
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';

import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
  MESSAGES,
} from 'appconstants';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';


import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddUser from './components/AddNewDocument';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const DOCUMENT_FILTERS_TYPES = FILTERS_TYPES.DOCUMENTS;

type Props = {
  documents: Array<Object>,
  history: {
    push: Function,
  },
  fetchDocuments: Function,
  addDocument: Function,
  selectedAccount: Object,
  totalElements: number,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  test: boolean,
  setNotification: Function,
  isSuperUser: boolean,
};

const Documents = (props: Props) => {
  const {
    selectedAccount,
    documents,
    loading,
    addDocument,
    fetchDocuments,
    totalElements,
    submitting,
    validator,
    test,
    history,
    setNotification,
    isSuperUser,
  } = props;

  const location = useLocation();
  const myRef = useRef();
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [addDocumentModal, setAddDocumentModal] = useState(false);
  const [filterTypes, setFilterTypes] = useState(DOCUMENT_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterTeams, setFilterTeams] = useState([{
    filterType: '',
    filterName: '',
    filterOperator: '',
    filterValue: '',
    filterNameDisplay: '',
    checked: true,
  }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const marginStyle = isSuperUser ? '-262px' : '-40px';

  const exportData = documents ? documents.map((values) => ({
    name: values.name,
    description: values.description,
    updatedAt: values.updatedAt ? moment
      .utc(values.updatedAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a') : '',
  })) : '';

  const exportHeaders = [
    { label: 'NAME', key: 'name' },
    { label: 'DESCRIPTION', key: 'description' },
    { label: 'LAST UPDATED', key: 'updatedAt' },
  ];

  const debouncedFetchDocuments = debounce(fetchDocuments, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'updatedAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    debouncedFetchDocuments({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [test, selectedAccount, location]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (!validator) {
      setAddDocumentModal(false);
    }
  }, [validator]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      current: parseInt(currentPage, 10),
      total: totalElements,
      showTotal: total => `Total ${total} items`,
    }));
  }, [documents]);

  // eslint-disable-next-line no-shadow
  const createDocument = (document: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'updatedAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      name,
      description,
      documentUrl,
    } = document;

    addDocument({
      currentPage,
      sortParams,
      document: {
        name,
        description,
        documentUrl,
      },
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    const fieldName = filters.field;

    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${fieldName}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${fieldName}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.DOCUMENTS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.DOCUMENTS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterTeams];
    if (filterTeams.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = DOCUMENT_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTeams(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterTeams];
    if (e[1] === 'updatedAt') {
      list[index].filterType = 'Date';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = DOCUMENT_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTeams(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterTeams[index].filterName === 'updatedAt') {
      if (filterTeams[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `updatedAt.GT=${dateStart}&updatedAt.LT=${dateEnd}`;
      } else if (filterTeams[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `updatedAt.LT=${dateStart}`;
      } else if (filterTeams[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `updatedAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterTeams];
    list[index].filterValue = selectedValue;
    setFilterTeams(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTeams];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'updatedAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'updatedAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterTeams[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterTeams(list);
  };

  const handleAddClick = () => {
    const list = [...filterTeams];
    setFilterTeams([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterTeams([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(DOCUMENT_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
  };

  const applyFilters = () => {
    const teamsFilters = filterTeams.filter((item) => item.checked);
    for (let i = 0; i < teamsFilters.length; i += 1) {
      if (!teamsFilters[i].filterName || !teamsFilters[i].filterOperator || !teamsFilters[i].filterValue) {
        return;
      }
    }
    if (teamsFilters[0].filterValue) {
      setCount(teamsFilters && teamsFilters.length);
      for (let i = 0; i < teamsFilters.length; i += 1) {
        if (teamsFilters[i].checked) {
          if (teamsFilters[i].filterName === 'updatedAt') {
            teamsFilters[i] = teamsFilters[i].filterValue;
          } else if (teamsFilters[i].filterName === 'email') {
            const email = encodeURIComponent(teamsFilters[i].filterValue);
            teamsFilters[i] = `${teamsFilters[i].filterName}.${teamsFilters[i].filterOperator}=${email}`;
          } else {
            teamsFilters[i] = `${teamsFilters[i].filterName}.${teamsFilters[i].filterOperator}=${teamsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(teamsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'updatedAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        history.push({
          pathname: PAGES.DOCUMENTS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        history.push({
          pathname: PAGES.DOCUMENTS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const downloadDocument = async (record) => {
    const documentGid = record.gid;
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.CUSTOM_DOCUMENT}/${documentGid}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.CUSTOM_DOCUMENT.DOWNLOAD_ERROR,
      });
    }
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '30%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'updatedAt',
      width: '25%',
      align: 'left',
      render: (updatedAt) => {
        if (updatedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(updatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
  ];
  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '20%',
    align: 'center',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="download"
            style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
            onClick={() => downloadDocument(record)}
          />
        </Tooltip>
      </span>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}>
            <Col>
              {
              !isSuperUser && (
                <SpButton
                  type="secondary"
                  shape="round"
                  className="mr-3"
                  onClick={() => setAddDocumentModal(true)}
                >
                  {'\u002B'}&nbsp;New Custom Document
                </SpButton>
              )
            }
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Team">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Team Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                  )}
                  {!open && (
                  <Tooltip placement="left" title="Click here to filter Team">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: marginStyle }}
                  >
                    <Filters
                      filtersGrid={filterTeams}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterTeams.length < DOCUMENT_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD USER MODAL */}
      { addDocumentModal && (
        <AddUser
          visible={addDocumentModal}
          submitting={submitting}
          close={() => setAddDocumentModal(false)}
          submit={createDocument}
        />
      )}

      <RBAC
        fallback={(
          <Table
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={documents}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}
      >
        <Table
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={documents}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  documents: state.documents.content,
  totalElements: state.documents.totalElements,
  roles: state.roles.content,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDocuments: param => dispatch(documentsActions.fetchDocuments({
    payload: param,
  })),
  addDocument: param => dispatch(documentsActions.addDocument({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
