// @flow
import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Row,
  Col,
  Icon,
  Input,
  Table,
  DatePicker,
} from 'antd';

import {
  SpH5,
  SpText,
  SpButton,
  SpError,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  reportTemplatesDetailsActions,
  customReportsActions,
  notificationActions,
} from 'store/actions';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import moment from 'moment-timezone';
import {
  PAGES,
  COUNTRIES,
  MESSAGES,
  MAX_AMOUNT,
} from 'appconstants';
import { NOTIFICATION } from 'store/actionTypes';
import CONSTANTS from 'store/constants';

import queryString from 'query-string';
import formatAmount from 'utils/formatAmount';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  fetchReportTemplateDetails: Function,
  fetchCustomReports: Function,
  setNotification: Function,
  reportTemplatesDetails: Object,
  reports: Array<Object>,
  loading: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  reportTemplatesDetailsStatus: string,
  validator: boolean,
  totalElements: number,
  addCustomReport: Function,
  isMobileView: Boolean,
}

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const dateFormat = 'MMM DD, YYYY';

const CustomReportDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchReportTemplateDetails,
    reportTemplatesDetails,
    setNotification,
    loading,
    reports,
    fetchCustomReports,
    history,
    selectedAccount,
    reportTemplatesDetailsStatus,
    validator,
    totalElements,
    addCustomReport,
    isMobileView,
  } = props;
  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }
  const { gid } = props.match.params;
  const [, forceUpdate] = useState();
  const [redirect, setRedirect] = useState(false);
  const [param1, setParam1] = useState();
  const [param2, setParam2] = useState();
  const [param3, setParam3] = useState();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [utcFromDate, setUtcFromDate] = useState(null);
  const [pagination, setPagination] = useState({});
  const [utcToDate, setUtcToDate] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const currencyType = selectedAccount && selectedAccount.currency;
  const query = (reportTemplatesDetails && reportTemplatesDetails.query)
    ? atob(reportTemplatesDetails && reportTemplatesDetails.query) : '';

  const simpleValidator = useRef(new SimpleReactValidator({
    autoForceUpdate: {
      forceUpdate,
    },
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const getData = (querystr) => {
    fetchReportTemplateDetails(gid);
    const parsed = queryString.parse(querystr);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchCustomReports({ currentPage, sortParams, gid });
  };

  useEffect(() => {
    getData(location.search);
  }, []);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData();
  }, [location, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [reports]);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: `${PAGES.CUSTOMREPORT}/${gid}`,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: `${PAGES.CUSTOMREPORT}/${gid}`,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  useEffect(() => {
    if (!validator && redirect) {
      setDisabled(false);
      setRedirect(false);
      setParam1();
      setParam2();
      setParam3();
      setFromDate();
      setToDate();
      setUtcFromDate(null);
      setUtcToDate(null);
    }
  }, [validator]);

  simpleValidator.current.purgeFields();

  const fromDateChange = (date1) => {
    const StartDate = moment(date1).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(StartDate, selectedAccount.timezone).startOf('day');
    const utcStartDate = selectedDate.utc().format(MOMENT_FORMAT);
    setUtcFromDate(date1);
    setFromDate(utcStartDate);
  };

  const toDateChange = (date2) => {
    const EndDate = moment(date2).format(MOMENT_FORMAT);
    const selectedEndDate = moment.tz(EndDate, selectedAccount.timezone).endOf('day');
    const utcEndDate = selectedEndDate.utc().format(MOMENT_FORMAT);
    setUtcToDate(date2);
    setToDate(utcEndDate);
  };

  const generateReport = async (event) => {
    setDisabled(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisabled(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const paramlabel1 = reportTemplatesDetails.param1Label === 'amount' ? param1.replace(/[^\d]/g, '') : param1;
    const paramlabel2 = reportTemplatesDetails.param2Label === 'amount' ? param2.replace(/[^\d]/g, '') : param2;
    const paramlabel3 = reportTemplatesDetails.param3Label === 'amount' ? param3.replace(/[^\d]/g, '') : param3;
    const params = {
      customReportTemplateGid: gid,
      param1Value: paramlabel1,
      param2Value: paramlabel2,
      param3Value: paramlabel3,
      sendEmail: true,
      startDate: fromDate,
      endDate: toDate,
    };
    simpleValidator.current.hideMessages();
    addCustomReport({
      currentPage,
      sortParams,
      params,
    });
    setRedirect(true);
  };

  const downloadReport = async (record) => {
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.CUSTOMREPORT}/${record.gid}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const columns = [
    {
      title: 'GENERATED ON',
      dataIndex: 'generatedAt',
      width: '30%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (generatedAt, record) => {
        if (record.generatedAt && selectedAccount && selectedAccount.timezone) {
          const generatedat = moment
            .utc(record.generatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{generatedat}</span>);
        } if (record.createdAt && selectedAccount && selectedAccount.timezone) {
          const createdAt = moment
            .utc(record.createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{createdAt}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DURATION',
      dataIndex: 'startDate',
      width: '30%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (startDate, record) => {
        if (record.startDate && record.endDate && selectedAccount && selectedAccount.timezone) {
          const fromDate1 = moment
            .utc(record.startDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          const toDate1 = moment
            .utc(record.endDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{fromDate1} To {toDate1}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DOWNLOAD',
      dataIndex: 'actions',
      width: '20%',
      align: 'center',
      render: (text, record) => (
        <>
          {
            record.status === 'GENERATED' ? (
              <Icon
                type="download"
                style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
                onClick={() => downloadReport(record)}
              />
            ) : (
              <span> {record.status ? record.status.replace('_', ' ') : '-'}</span>
            )
          }
        </>
      ),
    },
  ];


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        reportTemplatesDetails && reportTemplatesDetails.gid && (reportTemplatesDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED) && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-4" fontSize="20px">{reportTemplatesDetails.templateName}</SpText>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Created on</span>
                </Col>
                <Col span={10}>
                  <SpH5>
                    {
                      reportTemplatesDetails.createdAt
                        ? `${moment.utc(reportTemplatesDetails.createdAt).tz(selectedAccount.timezone).format('hh:mm A on MMM DD, YYYY')}`
                        : <>&#8211;</>
                    }
                  </SpH5>
                </Col>
              </Row>
              <hr />
              {
                reportTemplatesDetails.param1Label && (
                  <Row className="mt-4" type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>{reportTemplatesDetails.param1Label}</span>
                    </Col>
                    <Col span={10}>
                      {
                        reportTemplatesDetails.param1Label === 'amount' ? (
                          <>
                            <Input
                              value={param1}
                              onChange={(e) => {
                                const regex = /^\d*\.?\d*$/;
                                const { value } = e.currentTarget;
                                if (regex.test(value) || value === '') {
                                  setParam1(e.currentTarget.value);
                                } else if (!regex.test(value)) {
                                  setParam1(0.00);
                                }
                              }}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                              onBlur={e => {
                                if (e.currentTarget.value) {
                                  const value = formatAmount(e.currentTarget.value, currencyType);
                                  setParam1(value);
                                }
                              }}
                            />
                            <SpError>
                              {simpleValidator.current.message('amount', param1, 'required|amount')}
                            </SpError>
                          </>
                        ) : (
                          <>
                            <Input
                              value={param1}
                              onChange={e => {
                                setParam1(e.currentTarget.value);
                              }}
                            />
                            {
                              reportTemplatesDetails.param1Label === 'email' ? (
                                <SpError>
                                  {simpleValidator.current.message('email', param1, 'required|email|checkLowerCase')}
                                </SpError>
                              ) : (reportTemplatesDetails.param1Label.includes('cycle')) ? (
                                <SpError>
                                  {simpleValidator.current.message('param1Label', param1, 'required|numeric',
                                    { messages: { required: `The ${reportTemplatesDetails.param1Label} field is required` } })}
                                </SpError>
                              ) : (reportTemplatesDetails.param1Label.includes('count')) ? (
                                <SpError>
                                  {simpleValidator.current.message('param1Label', param1, 'required|numeric',
                                    { messages: { required: `The ${reportTemplatesDetails.param1Label} field is required` } })}
                                </SpError>
                              ) : (
                                <SpError>
                                  {simpleValidator.current.message('param1Label', param1, 'required',
                                    { messages: { required: `The ${reportTemplatesDetails.param1Label} field is required` } })}
                                </SpError>
                              )
                            }
                          </>
                        )
                      }
                    </Col>
                  </Row>
                )
              }
              {
                reportTemplatesDetails.param2Label && (
                  <Row className="mt-4" type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>{reportTemplatesDetails.param2Label}</span>
                    </Col>
                    <Col span={10}>
                      {
                        reportTemplatesDetails.param2Label === 'amount' ? (
                          <>
                            <Input
                              value={param2}
                              onChange={(e) => {
                                const regex = /^\d*\.?\d*$/;
                                const { value } = e.currentTarget;
                                if (regex.test(value) || value === '') {
                                  setParam2(e.currentTarget.value);
                                } else if (!regex.test(value)) {
                                  setParam2(0.00);
                                }
                              }}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                              onBlur={e => {
                                if (e.currentTarget.value) {
                                  const value = formatAmount(e.currentTarget.value, currencyType);
                                  setParam2(value);
                                }
                              }}
                            />
                            <SpError>
                              {simpleValidator.current.message('amount', param2, 'required|amount')}
                            </SpError>
                          </>
                        ) : (
                          <>
                            <Input
                              value={param2}
                              onChange={e => {
                                setParam2(e.currentTarget.value);
                              }}
                            />
                            {
                              reportTemplatesDetails.param2Label === 'email' ? (
                                <SpError>
                                  {simpleValidator.current.message('email', param2, 'required|email|checkLowerCase')}
                                </SpError>
                              ) : (reportTemplatesDetails.param2Label.includes('cycle')) ? (
                                <SpError>
                                  {simpleValidator.current.message('param2Label', param2, 'required|numeric',
                                    { messages: { required: `The ${reportTemplatesDetails.param2Label} field is required` } })}
                                </SpError>
                              ) : (reportTemplatesDetails.param2Label.includes('count')) ? (
                                <SpError>
                                  {simpleValidator.current.message('param2Label', param2, 'required|numeric',
                                    { messages: { required: `The ${reportTemplatesDetails.param2Label} field is required` } })}
                                </SpError>
                              ) : (
                                <SpError>
                                  {simpleValidator.current.message('param2Label', param2, 'required',
                                    { messages: { required: `The ${reportTemplatesDetails.param2Label} field is required` } })}
                                </SpError>
                              )
                            }
                          </>
                        )
                      }
                    </Col>
                  </Row>
                )
              }
              {
                reportTemplatesDetails.param3Label && (
                  <Row className="mt-4" type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>{reportTemplatesDetails.param3Label}</span>
                    </Col>
                    <Col span={10}>
                      {
                        reportTemplatesDetails.param3Label === 'amount' ? (
                          <>
                            <Input
                              value={param3}
                              onChange={(e) => {
                                const regex = /^\d*\.?\d*$/;
                                const { value } = e.currentTarget;
                                if (regex.test(value) || value === '') {
                                  setParam3(e.currentTarget.value);
                                } else if (!regex.test(value)) {
                                  setParam3(0.00);
                                }
                              }}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                              onBlur={e => {
                                if (e.currentTarget.value) {
                                  const value = formatAmount(e.currentTarget.value, currencyType);
                                  setParam3(value);
                                }
                              }}
                            />
                            <SpError>
                              {simpleValidator.current.message('amount', param3, 'required|amount')}
                            </SpError>
                          </>
                        ) : (
                          <>
                            <Input
                              value={param3}
                              onChange={e => {
                                setParam3(e.currentTarget.value);
                              }}
                            />
                            {
                              reportTemplatesDetails.param3Label === 'email' ? (
                                <SpError>
                                  {simpleValidator.current.message('email', param3, 'required|email|checkLowerCase')}
                                </SpError>
                              ) : (reportTemplatesDetails.param3Label.includes('cycle')) ? (
                                <SpError>
                                  {simpleValidator.current.message('param3Label', param3, 'required|numeric',
                                    { messages: { required: `The ${reportTemplatesDetails.param3Label} field is required` } })}
                                </SpError>
                              ) : (reportTemplatesDetails.param3Label.includes('count')) ? (
                                <SpError>
                                  {simpleValidator.current.message('param3Label', param3, 'required|numeric',
                                    { messages: { required: `The ${reportTemplatesDetails.param3Label} field is required` } })}
                                </SpError>
                              ) : (
                                <SpError>
                                  {simpleValidator.current.message('param3Label', param3, 'required',
                                    { messages: { required: `The ${reportTemplatesDetails.param3Label} field is required` } })}
                                </SpError>
                              )
                            }
                          </>
                        )
                      }
                    </Col>
                  </Row>
                )
              }
              {
                query && query.includes(':fromDate') ? (
                  <Row className="mt-4 mb-2" type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Start & End Date</span>
                    </Col>
                    <Col span={5} className="mr-1">
                      <DatePicker
                        format={dateFormat}
                        value={utcFromDate ? moment(utcFromDate, dateFormat) : null}
                        allowClear={false}
                        onChange={fromDateChange}
                      />
                      <SpError>
                        {simpleValidator.current.message('start date', fromDate, 'required')}
                      </SpError>
                    </Col>
                    <Col span={5}>
                      <DatePicker
                        format={dateFormat}
                        value={utcToDate ? moment(utcToDate, dateFormat) : null}
                        allowClear={false}
                        onChange={toDateChange}
                      />
                      <SpError>
                        {simpleValidator.current.message('end date', toDate, 'required')}
                      </SpError>
                    </Col>
                  </Row>
                ) : query && query.includes(':toDate') ? (
                  <Row className="mt-4 mb-2" type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Start & End Date</span>
                    </Col>
                    <Col span={5} className="mr-1">
                      <DatePicker
                        format={dateFormat}
                        value={utcFromDate ? moment(utcFromDate, dateFormat) : null}
                        allowClear={false}
                        onChange={fromDateChange}
                      />
                      <SpError>
                        {simpleValidator.current.message('start date', fromDate, 'required')}
                      </SpError>
                    </Col>
                    <Col span={5}>
                      <DatePicker
                        format={dateFormat}
                        value={utcToDate ? moment(utcToDate, dateFormat) : null}
                        allowClear={false}
                        onChange={toDateChange}
                      />
                      <SpError>
                        {simpleValidator.current.message('end date', toDate, 'required')}
                      </SpError>
                    </Col>
                  </Row>
                ) : ''
              }
              <Row className="mt-4 mb-4">
                <Col>
                  <SpButton
                    className="mr-4"
                    type="primary"
                    shape="round"
                    disabled={disabled}
                    onClick={generateReport}
                  >
                    Generate Report
                  </SpButton>
                </Col>
              </Row>
            </Card>
            <Card>
              <Row type="flex" justify="space-between" className="mb-4 mt-5">
                <Col>
                  <h5>REPORT GENERATED</h5>
                </Col>
              </Row>
              <Table
                columns={columns}
                rowKey={(record) => record.gid}
                dataSource={reports}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  reportTemplatesDetails: state.reportTemplatesDetails,
  reportTemplatesDetailsStatus: state.reportTemplatesDetails.reportTemplatesDetailsStatus,
  reports: state.customReports.content,
  totalElements: state.customReports.totalElements,
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchReportTemplateDetails: gid => dispatch(reportTemplatesDetailsActions.fetchReportTemplateDetails({
    payload: gid,
  })),
  fetchCustomReports: param => dispatch(customReportsActions.fetchCustomReports({
    payload: param,
  })),
  addCustomReport: param => dispatch(customReportsActions.addCustomReport({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CustomReportDetails);
