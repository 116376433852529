import { BANK_TRANSFERS } from '../actionTypes';

export const addIcicCustomer = action => ({
  type: BANK_TRANSFERS.REGISTER,
  payload: action.payload,
});

export const fetchAccountByID = action => ({
  type: BANK_TRANSFERS.ACCOUNT_BY_ID,
  payload: action.payload,
});

export const createIciciFundingSource = action => ({
  type: BANK_TRANSFERS.CREATE_FUNDING_SOURCE,
  payload: action.payload,
});
