// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import getSubscriptionBackground from 'utils/getSubscriptionBackground';
import getSubscriptionStatus from 'utils/getSubscriptionStatus';
import getFrequency from 'utils/getFrequency';
import getBillingFrequency from 'utils/getBillingFrequency';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  planv2DetailsActions,
} from 'store/actions';
import { PAGES } from 'appconstants';

type Props = {
  fetchPlanV2Details: Function,
  fetchPlanV2Subscription: Function,
  plansDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  subscription: Array<Object>,
  totalElements: number,
}

const PlanDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchPlanV2Details,
    fetchPlanV2Subscription,
    plansDetails,
    loading,
    history,
    subscription,
    totalElements,
    selectedAccount,
  } = props;
  const { gid } = props.match.params;
  const [pagination, setPagination] = useState({});
  const gidPath = gid.split('-')[1];
  const { prefix } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;

  const getData = (query) => {
    fetchPlanV2Details(gid);
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchPlanV2Subscription({ currentPage, sortParams, gid });
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.SUBSCRIPTION}/${searchParams}`,
      state: location.search,
    });
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.PLAN);
    }
  }, []);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [subscription]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `/subscriptions/plans/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '13%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount) => {
        const total = (amount / 100).toFixed(2);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${prefix}${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'NAME',
      dataIndex: 'customer.name',
      width: '14%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'customer.email',
      width: '14%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (email) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {email || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PHONE',
      dataIndex: 'customer.phoneNumber',
      width: '14%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (phoneNumber) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {phoneNumber || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PLAN',
      dataIndex: 'plan2.name',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (plan) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {plan || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <SpStatusTag style={{ backgroundColor: getSubscriptionBackground(status) }}>
            {getSubscriptionStatus(status)}
          </SpStatusTag>
        </Tooltip>
      ),
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        plansDetails.gid && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  {plansDetails.name
                    ? <SpText className="text-uppercase mr-4" fontSize="20px">{plansDetails.name}</SpText>
                    : ''}
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Billing Frequency</span>
                  </Col>
                  <Col span={6}>
                    {plansDetails.billingPeriod
                      ? <SpH5>{`Every ${plansDetails.billingPeriod} ${getFrequency(plansDetails.billingFrequency)}`}</SpH5>
                      : (
                        <>
                          {
                          plansDetails.billingFrequency && !plansDetails.billingPeriod ? (
                            <SpH5>
                              {getBillingFrequency(plansDetails.billingFrequency)}
                            </SpH5>
                          ) : (
                            <>&#8211;</>
                          )
                        }
                        </>
                      )}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Description</span>
                  </Col>
                  <Col span={6}>
                    {plansDetails.description
                      ? <SpH5>{plansDetails.description}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={12}>
                    <Col span={6}>
                      <span>Internal Note</span>
                    </Col>
                    <Col span={12}>
                      {plansDetails.note
                        ? (
                          <SpH5>{plansDetails.note}</SpH5>
                        )
                        : <>&#8211;</>}
                    </Col>
                  </Col>
                  <Col span={12} className="d-flex justify-content-end">
                    <SpH5 color="#7f7f7f">{plansDetails.gid}</SpH5>
                  </Col>
                </Row>
              </SpForm>
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">SUBSCRIPTIONS WITH THIS PLAN</SpText>
                </Col>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={columns}
                rowKey={(record) => record.gid}
                dataSource={subscription}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  plansDetails: state.planv2Details,
  plansDetailsStatus: state.planv2Details.plansDetailsStatus,
  subscription: state.planv2Details.subscription.content,
  totalElements: state.planv2Details.subscription ? state.planv2Details.subscription.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPlanV2Details: gid => dispatch(planv2DetailsActions.fetchPlanV2Details({
    payload: gid,
  })),
  fetchPlanV2Subscription: param => dispatch(planv2DetailsActions.fetchPlanV2Subscription({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PlanDetails);
