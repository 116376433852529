import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  SpText,
} from 'components/DesignKit';
import TransferCenter from 'imgs/transfers.svg';
import { navigationCardTransferCenter } from 'appconstants/navigationCard';
import '../../styles/_navigationCard.scss';

type Props = {
  history: {
    push: Function,
    replace: Function,
  },
};

const Transfercenter = (props: Props) => {
  const {
    history,
  } = props;


  return (
    <div
      className="mx-auto mt-5"
      style={{ width: 1000, height: 'auto' }}
      bodyStyle={{ height: '100%' }}
    >
      <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
        <img
          style={{ width: '350px' }}
          src={TransferCenter}
          alt="Transfer-img"
        />
        <SpText fontSize="26px" fontWeight="600">TRANSFER CENTER</SpText>
        <SpText fontSize="18px" fontWeight="400">Quickly and easily transfer money between your accounts.</SpText>
      </div>
      <div className="main_div" style={{ maxWidth: '450px' }}>
        {navigationCardTransferCenter.map((item, index) => (
          <div
            key={index}
            className="card"
            onClick={() => history.push(item.path)}
          >
            <div className="mt-2">
              <img src={item.svg} alt={item.name} />
              <p style={{ fontSize: '14px', fontWeight: '400' }} className="mt-2">{item.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Transfercenter);
