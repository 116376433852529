import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import {
  Card,
  Table,
  Switch,
  Icon,
  Tooltip,
} from 'antd';
import Loading from 'components/Loading';
import {
  axiosLoginInstance,
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import {
  TOKENS,
  ROLES,
  MESSAGES,
  PAGES,
} from 'appconstants';
import {
  ACCOUNT,
  PUBLICKEYS,
  SECRETKEYS,
  NOTIFICATION,
} from 'store/actionTypes';

import { SpH6, SpText } from 'components/DesignKit';

import {
  secretkeysActions,
  publickeysActions,
  symmetrickeysActions,
  accountActions,
  notificationActions,
} from 'store/actions';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import Regenerate from './components/Regenerate';

type Props = {
  secretkeys: Array<Object>,
  publickeys: Array<Object>,
  symmetrickeys: Array<Object>,
  fetchSecretkeys: Function,
  fetchPublickeys: Function,
  fetchSymmetrickey: Function,
  updatePublickey: Function,
  updateSecretkey: Function,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  selectedAccount: Object,
  setTestData: Function,
  role: Object,
  history: {
    push: Function,
  },
  setNotification: Function,
  isMobileView: Boolean,
};

const Developer = (props: Props) => {
  const {
    loading,
    fetchSecretkeys,
    fetchPublickeys,
    fetchSymmetrickey,
    updatePublickey,
    updateSecretkey,
    publickeys,
    secretkeys,
    symmetrickeys,
    totalElements,
    submitting,
    selectedAccount,
    test,
    setTestData,
    role,
    history,
    setNotification,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const [pagination, setPagination] = useState({});
  const nameSymmetric = symmetrickeys.map(v => ({ ...v, name: 'Symmetric Key' }));
  const namePublic = publickeys.map(v => ({ ...v, name: 'Publishable Key' }));
  const nameSecret = secretkeys.map(v => ({ ...v, name: 'Secret Key' }));
  const allkeys = [...namePublic, ...nameSecret];
  const sortedKeys = allkeys.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const location = useLocation();
  const [selectedUser, setSelectedUser] = useState({});
  const [editKeyModal, setEditKeyModal] = useState(false);
  const [error, setError] = useState('');

  const debouncedFetchPublickeys = debounce(fetchPublickeys, 2000, { leading: true });
  const debouncedFetchSecretkeys = debounce(fetchSecretkeys, 2000, { leading: true });
  const debouncedFetchSymmetrickey = debounce(fetchSymmetrickey, 2000, { leading: true });

  const getData = (query) => {
    const { type } = role;
    const roleType = type && type.toLowerCase();
    const parsed = queryString.parse(query);
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    debouncedFetchPublickeys({ sortParams });
    debouncedFetchSymmetrickey({ sortParams });
    if ([ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.SUPERUSER].includes(roleType)) {
      debouncedFetchSecretkeys({ sortParams });
    }
  };

  useEffect(() => {
    getData();
  }, [location, test, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [publickeys, symmetrickeys, secretkeys]);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.DEVELOPER,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.DEVELOPER,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  useEffect(() => {
    if (!submitting) {
      setEditKeyModal(false);
      setError('');
    }
  }, [submitting]);

  const editSelectedKey = async (keyvalues: Object) => {
    const {
      activationType,
      password,
      email,
      name,
      rgid,
    } = keyvalues;
    try {
      await axiosLoginInstance.post(API_END_POINTS.AUTHENTICATE, {
        email,
        password,
      });
      if (name === 'Publishable Key') {
        const params = {
          expirationInSeconds: activationType,
        };
        updatePublickey({
          gid: rgid,
          params,
        });
      } else {
        const params = {
          expirationInSeconds: activationType,
        };
        updateSecretkey({
          gid: rgid,
          params,
        });
      }
    } catch (e) {
      setError(401);
    }
  };

  const onSwitch = async (value) => {
    let fetchToken;
    if ((role && role.name) === 'superuser') {
      fetchToken = `/internal${API_END_POINTS.JWE}/${selectedAccount.gid}/switch`;
    } else {
      fetchToken = `${API_END_POINTS.JWE}/${selectedAccount.gid}/switch`;
    }
    if (value) {
      fetchToken = `${fetchToken}?isTest=true`;
    }
    try {
      const { data: { entity: { token: { accessToken } } } } = await axiosAuthInstance.get(fetchToken);
      localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    setTestData(value);
  };

  const showEditKeyModal = (value: Object) => {
    setSelectedUser(value);
    setEditKeyModal(true);
  };

  const onClose = () => {
    setEditKeyModal(false);
    setError('');
  };

  const columns = [
    {
      title: 'KEY NAME',
      dataIndex: 'name',
      width: '15%',
      align: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'KEY TOKEN',
      dataIndex: 'apiKey',
      width: '25%',
      align: 'left',
      sorter: (a, b) => a.apiKey.localeCompare(b.apiKey),
    },
    {
      title: 'CREATED',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: (a, b) => new Date(a.createdAt) < new Date(b.createdAt),
    },
    {
      title: 'LAST USED',
      dataIndex: 'lastUsed',
      width: '15%',
      align: 'left',
      render: (lastUsed) => {
        if (lastUsed && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(lastUsed)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: (a, b) => new Date(a.lastLogin) < new Date(b.lastLogin),
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'pendingExpiration',
    width: '15%',
    align: 'center',
    render: (text, record) => (
      <>
        {
          record.pendingExpiration === true ? (
            <Tooltip placement="top" title="Pending Expiration">
              <Icon
                type="warning"
                style={{ fontSize: '20px', cursor: 'pointer' }}
              />
            </Tooltip>
          ) : (
            record.name !== 'Symmetric Key'
              ? (
                <Tooltip placement="top" title="Roll Key">
                  <Icon type="retweet" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => showEditKeyModal(record)} />
                </Tooltip>
              )
              : <>&#8211;</>
          )
        }
      </>
    ),
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card title="" className="mb-3">
      <SpText fontSize="20px">
        <div className="text-uppercase mb-3 mt-2 ml-3">API KEYS</div>
      </SpText>
      <SpH6>
        <div className="mb-2 ml-3">
          {test === true ? 'Viewing Test API data. Toggle to see Live API data' : 'Viewing Live API data. Toggle to see Test API data'}
        </div>
      </SpH6>
      <div className="d-flex align-items-center ml-3">
        <SpText className="pt-0">Live Keys</SpText>
        <Switch
          className="mx-3"
          checked={test}
          onChange={onSwitch}
        />
        {editKeyModal && (
          <Regenerate
            visible={editKeyModal}
            submitting={submitting}
            selectedUser={selectedUser}
            selectedAccount={selectedAccount}
            email={selectedAccount.contactEmail}
            error={error}
            close={onClose}
            submit={editSelectedKey}
          />
        )}
        <SpText className="pt-0">Test Keys</SpText>
      </div>
      <RBAC
        fallback={(
          <Table
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={[...nameSymmetric, ...sortedKeys]}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.SUPERUSER]}
      >
        <Table
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={[...nameSymmetric, ...sortedKeys]}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  secretkeys: state.secretkeys.content,
  publickeys: state.publickeys.content,
  symmetrickeys: state.symmetrickeys.content,
  totalElements: ((state.secretkeys.totalElements) + (state.publickeys.totalElements) + (state.symmetrickeys.totalElements)),
  loading: state.loading.loading,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  test: state.account.test,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSecretkeys: param => dispatch(secretkeysActions.fetchSecretkeys({
    payload: param,
  })),
  fetchPublickeys: param => dispatch(publickeysActions.fetchPublickeys({
    payload: param,
  })),
  fetchSymmetrickey: param => dispatch(symmetrickeysActions.fetchSymmetrickey({
    payload: param,
  })),
  setTestData: (value) => dispatch(accountActions.setTestData({
    type: ACCOUNT.SET_TEST_DATA,
    payload: value,
  })),
  updatePublickey: param => dispatch(publickeysActions.updatePublickey({
    type: PUBLICKEYS.UPDATE,
    payload: param,
  })),
  updateSecretkey: param => dispatch(secretkeysActions.updateSecretkey({
    type: SECRETKEYS.UPDATE,
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Developer);
