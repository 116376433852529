import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Tooltip,
  Row,
  Col,
  Icon,
} from 'antd';

import ReactFlow, {
  ReactFlowProvider,
  // addEdge,
  useNodesState,
  // useEdgesState,
  Controls,
  Background,
  Position,
  applyNodeChanges,
} from 'reactflow';
import 'reactflow/dist/style.css';

import { SpText, SpButton } from 'components/DesignKit';
import Loading from 'components/Loading';
import {
  PAGES,
} from 'appconstants';
import {
  diningActions,
  notificationActions,
} from 'store/actions';
import Chair from 'assets/imgs/chair.svg';
import Edit from 'assets/imgs/editDining.svg';
import Delete from 'assets/imgs/deleteDining.svg';
// import Clone from 'assets/imgs/cloneDining.svg';
import Inserve from 'assets/imgs/inServe.svg';
import Reserve from 'assets/imgs/reserved.svg';
import Available from 'assets/imgs/available.svg';
import EditFloor from 'assets/imgs/editFloor.svg';
import TableList from 'assets/imgs/tableList.svg';
// eslint-disable-next-line no-unused-vars
import ActionNode from './components/ActionNode';
import Sidebar from './components/sidebar';
// eslint-disable-next-line no-unused-vars
import useWindowDimensions from './components/useWindowDimensions';
import styles from './dining.css';
import TableInfo from './components/TableInfo';
import EditTableInfo from './components/EditTableInfo';
import AddSection from './components/addSection';
import DelecteNode from './components/DelecteNode';
import EditSection from './components/EditSection';
import DeleteSection from './components/DeleteSection';
import ResizeRotateNode from './components/ResizeRotateNode';

const nodeTypes = {
  resizeRotate: ResizeRotateNode,
};

const getId = () => `id${Math.random().toString(16).slice(2)}`;

let initialNodes = [];

type Props = {
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  fetchDiningSections: Function,
  addDiningSections: Function,
  diningSections: Array<Objects>,
  fetchDiningTable: Function,
  addDiningTable: Function,
  diningTable: Array<Objects>,
  // updateDiningTable: Function,
  deleteDiningTable: Function,
  loading: boolean,
  updateSection: Function,
  deleteDiningSection: Function,
  isMobileView: Function,
};

const Dining = (props: Props) => {
  const {
    history,
    fetchDiningSections,
    addDiningSections,
    diningSections,
    fetchDiningTable,
    addDiningTable,
    diningTable,
    // updateDiningTable,
    deleteDiningTable,
    loading,
    updateSection,
    deleteDiningSection,
    isMobileView,
  } = props;
  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const [tableInfoModal, setTableInfoModal] = useState(false);
  const [editTableInfoModal, setEditTableInfoModal] = useState(false);
  const [selectedNode, setSelectedNode] = useState();
  const [selectedData, setSelectedData] = useState();
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [addSectionModal, setAddSectionModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState();
  const [deleteNodeModal, setDeleteNodeModal] = useState(false);
  const [editSection, setEditSection] = useState(false);
  const [isEditSectionModal, setIsEditSectionModal] = useState(false);
  const [sectionSelected, setSelectionSelected] = useState();
  const [isDeleteSectionModal, setIsDeleteSectionModal] = useState(false);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.SHOPS);
    }
    if (gid) {
      fetchDiningSections(gid);
    }
  }, []);

  const getNodeType = (type) => {
    if (type === 'CIRCLE') {
      return 'Circle';
    } if (type === 'SQUARE') {
      return 'Square';
    } if (type === 'RECTANGULAR') {
      return 'Rectangle';
    }
    return '';
  };

  // const cloneTable = useCallback(
  //   (table) => {
  //     const newNode = {
  //       id: getId(),
  //       type: table.type,
  //       name: table.name,
  //       position: table.position,
  //       data: table.data,
  //     };
  //     const listItems = [...initialNodes];
  //     listItems.push(newNode);
  //     initialNodes = listItems;
  //     setNodes(initialNodes);
  //   },
  //   [reactFlowInstance],
  // );

  const deleteNode = (table) => {
    if (table.tableGid) {
      const payload = {
        shopGid: gid,
        sectionGid: selectedSection,
        tableGid: table.tableGid,
      };
      deleteDiningTable(payload);
      setDeleteNodeModal(false);
    } else {
      const listItems = [...initialNodes];
      const index = listItems.findIndex(i => i.id === table.id);
      listItems.splice(index, 1);
      initialNodes = listItems;
      setNodes(listItems);
      setDeleteNodeModal(false);
    }
  };

  const editNode = (table, data) => {
    setSelectedNode(table);
    setSelectedData(data);
    setEditTableInfoModal(true);
  };

  const deleteNodeTemp = (table, data) => {
    setSelectedNode(table);
    setSelectedData(data);
    setDeleteNodeModal(true);
  };

  const getTableTemplate = (data, seletedTable) => (
    <div>
      <div>
        <SpText fontSize="16px" fontWeight="600">{data.name}</SpText>
      </div>
      <div>
        <img src={Chair} alt="dining" width="30" />
      </div>
      <div className="mt-2">
        <SpText fontSize="16px" fontWeight="600" style={{ border: '1px solid', padding: '2px 5px', borderRadius: '5px' }}>{data.number}</SpText>
      </div>
      <div style={{ position: 'absolute', top: '0', left: '100%' }}>
        <Tooltip placement="top" title="Click here to edit details">
          <span
            onClick={() => editNode(seletedTable, data)}
            style={{
              cursor: 'pointer',
            }}
          >
            <img
              src={Edit}
              alt="diningEdit"
              width="40"
            />
          </span>
        </Tooltip>
        <Tooltip placement="top" title="Click here to delete table">
          <span
            onClick={() => deleteNodeTemp(seletedTable, data)}
            style={{
              cursor: 'pointer',
            }}
          >
            <img
              src={Delete}
              alt="diningDelete"
              width="40"
            />
          </span>
        </Tooltip>
        {/* <Tooltip placement="top" title="Click here to clone table">
          <span
            // onClick={() => cloneTable(seletedTable)}
            style={{
              cursor: 'pointer',
            }}
          >
            <img
              src={Clone}
              alt="diningClone"
              width="40"
            />
          </span>
        </Tooltip> */}
      </div>
    </div>
  );

  useEffect(() => {
    if (diningTable && diningTable.length !== 0) {
      const lineItems = [...diningTable];
      const payload = [];
      let tempObject = {};
      for (let i = 0; i < lineItems.length; i += 1) {
        tempObject.id = lineItems[i].gid;
        tempObject.type = 'resizeRotate';
        tempObject.name = getNodeType(lineItems[i].type);
        tempObject.position = {
          x: lineItems[i].topLeftX,
          y: lineItems[i].topLeftY,
        };
        tempObject.data = {
          label: getTableTemplate({
            name: lineItems[i].name,
            number: lineItems[i].guestCapacity,
            index: i,
          }, {}),
          resizable: true,
          rotatable: true,
          name: getNodeType(lineItems[i].type),
        };
        tempObject.targetPosition = Position.Left;
        tempObject.sourcePosition = lineItems[i].rotation;
        tempObject.style = {
          height: `${lineItems[i].height}px`,
          borderRadius: getNodeType(lineItems[i].type) === 'Circle' ? '50%' : '0%',
          width: `${lineItems[i].width}px`,
        };
        tempObject.tempName = lineItems[i].name;
        tempObject.tempNum = lineItems[i].guestCapacity;
        tempObject.tableGid = lineItems[i].gid;
        tempObject.data = {
          label: getTableTemplate({
            name: lineItems[i].name,
            number: lineItems[i].guestCapacity,
            index: i,
          }, tempObject),
          resizable: true,
          rotatable: true,
          name: getNodeType(lineItems[i].type),
        };
        payload.push(tempObject);
        tempObject = {};
      }
      initialNodes = payload;
      setNodes(payload);
    } else {
      initialNodes = [];
      setNodes([]);
    }
  }, [diningTable, isEditable]);

  useEffect(() => {
    if (diningSections && diningSections.length !== 0) {
      setSelectionSelected(diningSections[0]);
      setSelectedSection(diningSections[0].gid);
      const payload = {
        shopGid: gid,
        sectionGid: diningSections[0].gid,
      };
      fetchDiningTable(payload);
    }
  }, [diningSections]);

  const onNodesChange = useCallback((changes) => {
    const tempId = changes[0].id;
    const listItems = [...initialNodes];
    const index = listItems.findIndex(i => i.id === tempId);
    const nodeRotation = document.getElementById(tempId).innerHTML;
    if (changes && changes[0].position && changes[0].position.x && changes[0].position && changes[0].position.y) {
    // listItems[index].position.x = changes[0].position.x;
    // listItems[index].position.y = changes[0].position.y;

      const { x, y } = changes[0].position;
      const minX = 30;
      const maxX = 1000;
      const minY = 174;
      const maxY = 1675;

      // Restrict x and y values
      listItems[index].position.x = Math.max(minX, Math.min(maxX, x));
      listItems[index].position.y = Math.max(minY, Math.min(maxY, y));
    }
    if (changes && changes[0].resizing) {
      listItems[index].style.width = `${changes[0].dimensions.width}px`;
      listItems[index].style.height = `${changes[0].dimensions.height}px`;
    }
    listItems[index].rotation = nodeRotation;
    initialNodes = listItems;
    setNodes((nds) => applyNodeChanges(changes, nds));
    setNodes(listItems);
  }, [setNodes]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      setTableInfoModal(true);

      const node = JSON.parse(
        event.dataTransfer.getData('application/reactflow'),
      );
      const { type, name } = node;

      if (typeof type === 'undefined' || !type) {
        return;
      }
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const minX = 30;
      const maxX = 1000;
      const minY = 174;
      const maxY = 675;

      // Restrict x and y values
      position.x = Math.max(minX, Math.min(maxX, position.x));
      position.y = Math.max(minY, Math.min(maxY, position.y));

      const newNode = {
        id: getId(),
        type: 'resizeRotate',
        name,
        position,
        data: {
          label: name.replace(/\s+/g, '_').toLowerCase(),
          resizable: true,
          rotatable: true,
          name,
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
        style: {
          height: name === 'Rectangle' ? '100px' : '150px',
          borderRadius: name === 'Circle' ? '50%' : '0%',
          width: '150px',
        },
      };
      const listItems = [...initialNodes];
      listItems.push(newNode);
      initialNodes = listItems;
      setNodes(initialNodes);
    },
    [reactFlowInstance],
  );

  const setTableData = (data) => {
    const listItems = [...initialNodes];
    const seletedTable = listItems[data.index];
    listItems[data.index].data.label = getTableTemplate(data, seletedTable);
    listItems[data.index].tempName = data.name;
    listItems[data.index].tempNum = data.number;
    initialNodes = listItems;
    setNodes(initialNodes);
    setTableInfoModal(false);
  };

  const removeNode = () => {
    const index = nodes.length - 1;
    const listItems = [...nodes];
    listItems.splice(index, 1);
    initialNodes = listItems;
    setNodes(initialNodes);
    setTableInfoModal(false);
  };

  const editTableData = (tableInfo, editedInfo) => {
    const listItems = [...initialNodes];
    const index = listItems.findIndex(i => i.id === tableInfo.id);
    listItems[index].data.name = editedInfo.type;
    listItems[index].data.resizable = true;
    listItems[index].data.rotatable = true;
    listItems[index].name = editedInfo.type;
    listItems[index].tempName = editedInfo.name;
    listItems[index].tempNum = editedInfo.number;
    listItems[index].style = {
      height: editedInfo.type === 'Rectangle' ? '150px' : '200px',
      borderRadius: editedInfo.type === 'Circle' ? '50%' : '0%',
      width: '200px',
    };
    listItems[index].data.label = getTableTemplate(editedInfo, listItems[index]);
    initialNodes = listItems;
    setNodes(initialNodes);
    setEditTableInfoModal(false);
  };

  const getTableType = (type) => {
    if (type === 'Circle') {
      return 'CIRCLE';
    } if (type === 'Square') {
      return 'SQUARE';
    } if (type === 'Rectangle') {
      return 'RECTANGULAR';
    }
    return '';
  };

  const saveFloorInfo = () => {
    if (nodes && nodes.length !== 0) {
      const listItems = [...nodes];
      const payload = [];
      let tempObject = {};
      for (let i = 0; i < listItems.length; i += 1) {
        tempObject.shopSectionGid = selectedSection;
        tempObject.shopGid = gid;
        tempObject.name = listItems[i].tempName;
        tempObject.type = getTableType(listItems[i].name);
        tempObject.topLeftX = listItems[i].position.x;
        tempObject.topLeftY = listItems[i].position.y;
        tempObject.rotation = Number(listItems[i].rotation);
        tempObject.height = Number((listItems[i].style.height).split('p')[0]);
        tempObject.width = Number((listItems[i].style.width).split('p')[0]);
        tempObject.guestCapacity = Number(listItems[i].tempNum);
        if (listItems[i].tableGid) {
          tempObject.gid = listItems[i].tableGid;
        }
        payload.push(tempObject);
        tempObject = {};
      }
      addDiningTable(payload);
    }
    setIsEditable(false);
  };

  const addNewSection = (data) => {
    const payload = {
      name: data.name,
      shopGid: gid,
    };
    addDiningSections(payload);
    setAddSectionModal(false);
  };

  const getSelectedSectionData = (selectedId) => {
    setSelectionSelected(selectedId);
    setSelectedSection(selectedId.gid);
    const payload = {
      shopGid: gid,
      sectionGid: selectedId.gid,
    };
    fetchDiningTable(payload);
  };

  /* const getNodeTopPosition = (size) => {
    if (size < 155) {
      return 175;
    }
    if (size > 650) {
      return 670;
    }
    return size;
  };

  const getNodeLeftPosition = (size) => {
    if (size < 30) {
      return 30;
    }
    if (size > 1112) {
      return 1112;
    }
    return size;
  };
  */

  const editSelectedSection = (section) => {
    setIsEditSectionModal(true);
    setSelectionSelected(section);
  };

  const deleteSelectedSection = (section) => {
    setIsDeleteSectionModal(true);
    setSelectionSelected(section);
  };

  const sectionUpdate = (section) => {
    const params = {
      payload: {
        name: section.name,
      },
      sectionGid: section.sectionGid,
      shopGid: gid,
    };
    updateSection(params);
    setIsEditSectionModal(false);
  };

  const sectionDelete = (info) => {
    const payload = {
      sectionGid: info.sectionGid,
      shopGid: info.shopGid,
    };
    deleteDiningSection(payload);
    setIsDeleteSectionModal(false);
  };

  const getDiningTableView = () => {
    history.push(`${PAGES.DINING_LIST}/${selectedSection}?shopGid=${gid}`);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <Row type="flex" justify="space-between" align="middle" className="mt-3">
          <Col>
            <SpText fontSize="16px">Set up and view your floor plan from your Web Dashboard.</SpText>
          </Col>
          <Col>
            {
            isEditable ? (
              <Row type="flex" justify="end" gutter={[16, 16]}>
                <Col>
                  <SpButton
                    type="secondary"
                    shape="round"
                    onClick={() => setIsEditable(false)}
                  >
                    Cancel
                  </SpButton>
                </Col>
                <Col>
                  <SpButton
                    type="primary"
                    shape="round"
                    onClick={() => saveFloorInfo()}
                  >
                    Save
                  </SpButton>
                </Col>
              </Row>
            ) : (
              <Row type="flex" justify="end" gutter={[16, 16]} align="middle">
                <Col align="middle">
                  <img src={Inserve} alt="inserve" /> <SpText>In-serve</SpText>
                </Col>
                <Col align="middle">
                  <img src={Reserve} alt="Reserved" /> <SpText>Reserved</SpText>
                </Col>
                <Col align="middle">
                  <img src={Available} alt="Available" /> <SpText>Available</SpText>
                </Col>
                <Col>
                  <SpButton
                    type="secondary"
                    shape="round"
                    onClick={() => setIsEditable(true)}
                  >
                    <img src={EditFloor} alt="edit" /> &nbsp; Edit Floor Plan
                  </SpButton>
                </Col>
                <Col>
                  <SpButton
                    type="secondary"
                    shape="round"
                    onClick={() => getDiningTableView(selectedSection)}
                  >
                    <img src={TableList} alt="edit" /> &nbsp; Tables
                  </SpButton>
                </Col>
              </Row>
            )
          }
          </Col>
        </Row>
        <Row className="mt-3" type="flex" justify="start" align="middle">
          {
            isEditable && (
            <Col>
              <SpButton type="primary" className="mr-3" onClick={() => setAddSectionModal(true)}><Icon type="plus" /> Add Section</SpButton>
              <SpButton
                type={editSection ? 'secondary' : 'primary'}
                className="mr-3"
                onClick={() => setEditSection(!editSection)}
              >
                <Icon type="edit" /> Edit Section
              </SpButton>
            </Col>
            )
          }
          <Col className="mr-3 ml-3" span={24}>
            {
            diningSections && diningSections.length !== 0 && (
              <div className="d-flex custom-scroll" style={{ overflowX: 'scroll', width: '97%' }}>
                {
                diningSections.map((item) => (
                  <div
                    style={{
                      border: selectedSection === item.gid ? '1px solid #67BBFF' : '1px solid #67BBFF',
                      fontSize: '16px',
                      padding: '5px 10px',
                      fontWeight: '600',
                      backgroundColor: selectedSection === item.gid ? '#67BBFF' : '',
                      color: selectedSection === item.gid ? '#fff' : '',
                      cursor: 'pointer',
                      minWidth: '200px',
                      textAlign: 'center',
                    }}
                    onClick={() => getSelectedSectionData(item)}
                  >
                    {item.name}
                    {
                      isEditable && editSection && (
                        <span className="ml-2">
                          <Icon type="edit" className="mr-2 ml-2" onClick={(e) => { e.stopPropagation(); editSelectedSection(item); }} />
                          {
                            diningSections.length !== 1 && (
                              <Icon type="delete" onClick={(e) => { e.stopPropagation(); deleteSelectedSection(item); }} />
                            )
                          }
                        </span>
                      )
                    }
                  </div>
                ))
              }
              </div>
            )
          }
          </Col>
        </Row>
        {
        isEditable ? (
          <>
            {
            diningSections && diningSections.length !== 0 && (
            <div className="d-flex mt-5">
              <div className={styles.workflow} style={{ width: '80%' }}>
                <ReactFlowProvider>
                  <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                    <ReactFlow
                      nodes={nodes}
                    // edges={edges}
                      onNodesChange={onNodesChange}
                    // onEdgesChange={onEdgesChange}
                    // onConnect={onConnect}
                      onInit={setReactFlowInstance}
                      onDrop={onDrop}
                      onDragOver={onDragOver}
                      nodeTypes={nodeTypes}
                      panOnScroll
                    >
                      <Background />
                      <Controls />
                    </ReactFlow>
                  </div>
                </ReactFlowProvider>
              </div>
              <Sidebar />
              {
              tableInfoModal && (
                <TableInfo
                  close={() => removeNode()}
                  visible={tableInfoModal}
                  submit={setTableData}
                  nodes={nodes}
                />
              )
            }
              {
              editTableInfoModal && (
                <EditTableInfo
                  close={() => setEditTableInfoModal(false)}
                  visible={editTableInfoModal}
                  submit={editTableData}
                  selectedNode={selectedNode}
                  selectedData={selectedData}
                />
              )
            }
            </div>
            )
          }
            {
              addSectionModal && (
                <AddSection
                  close={() => setAddSectionModal(false)}
                  visible={addSectionModal}
                  submit={addNewSection}
                />
              )
            }
            {
              deleteNodeModal && (
                <DelecteNode
                  close={() => setDeleteNodeModal(false)}
                  visible={deleteNodeModal}
                  submit={deleteNode}
                  selectedData={selectedData}
                  selectedNode={selectedNode}
                />
              )
            }
            {
              isEditSectionModal && (
                <EditSection
                  close={() => setIsEditSectionModal(false)}
                  visible={isEditSectionModal}
                  submit={sectionUpdate}
                  sectionSelected={sectionSelected}
                />
              )
            }
            {
              isDeleteSectionModal && (
                <DeleteSection
                  close={() => setIsDeleteSectionModal(false)}
                  visible={isDeleteSectionModal}
                  submit={sectionDelete}
                  shopGid={gid}
                  sectionSelected={sectionSelected}
                />
              )
            }
          </>
        ) : (
          <div className="mt-5" style={{ height: '650px', width: '100%', backgroundColor: '#f7f7f7' }}>
            {
              diningTable && diningTable.length !== 0 && (
                <>
                  {
                  diningTable.map((item) => (
                    <div style={{
                      width: `${item.width}px`,
                      height: `${item.height}px`,
                      borderRadius: item.type === 'CIRCLE' ? '50%' : '0%',
                      border: '1px solid',
                      backgroundColor: '#fff',
                      textAlign: 'center',
                      position: 'absolute',
                      left: item.topLeftX,
                      top: item.topLeftY,
                      // left: (Number(item.topLeftX) - Number(15)),
                      // top: (Number(item.topLeftY) - Number(200)),
                      // left: getNodeLeftPosition(item.topLeftX),
                      // top: getNodeTopPosition(item.topLeftY),
                      alignContent: 'center',
                      display: 'inline-grid',
                      transform: `rotate(${item.rotation}deg)`,
                    }}
                    >
                      <div>
                        <div>
                          <SpText fontSize={item.height < 120 ? '14px' : '16px'} fontWeight="600">{item.name}</SpText>
                        </div>
                        <div>
                          <img src={Chair} alt="dining" width={item.height < 120 ? '20' : '30'} />
                        </div>
                        <div className="mt-2">
                          <SpText
                            fontSize={item.height < 120 ? '14px' : '16px'}
                            fontWeight="600"
                            style={{
                              border: '1px solid',
                              padding: '2px 5px',
                              borderRadius: '5px',
                            }}
                          >
                            {item.guestCapacity}
                          </SpText>
                        </div>
                        <div className="mt-1">
                          {
                            !item.shopTableReservation && (
                              <>
                                <img src={Available} alt="Available" /> <SpText>Available</SpText>
                              </>
                            )
                          }
                          {
                            item.shopTableReservation && item.shopTableReservation.gid && item.shopTableReservation.status === 'OCCUPIED' && (
                              <>
                                <img src={Inserve} alt="inserve" /> <SpText>In-serve</SpText>
                              </>
                            )
                          }
                          {
                            item.shopTableReservation && item.shopTableReservation.gid && item.shopTableReservation.status === 'RESERVED' && (
                              <>
                                <img src={Reserve} alt="Reserved" /> <SpText>Reserved</SpText>
                              </>
                            )
                          }

                        </div>
                      </div>
                    </div>
                  ))
                }
                </>
              )
            }
          </div>
        )
      }
      </Card>

    </>
  );
};

const mapStateToProps = (state) => ({
  diningSections: state.dining.diningSections.content,
  diningTable: state.dining.diningTable.content,
  validator: state.loading.validator,
  loading: state.loading.loading,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDiningSections: gid => dispatch(diningActions.fetchDiningSections({
    payload: gid,
  })),
  addDiningSections: (param) => dispatch(diningActions.addDiningSections({
    payload: param,
  })),
  fetchDiningTable: (param) => dispatch(diningActions.fetchDiningTable({
    payload: param,
  })),
  addDiningTable: (param) => dispatch(diningActions.addDiningTable({
    payload: param,
  })),
  updateDiningTable: (param) => dispatch(diningActions.updateDiningTable({
    payload: param,
  })),
  deleteDiningTable: (param) => dispatch(diningActions.deleteDiningTable({
    payload: param,
  })),
  updateSection: (param) => dispatch(diningActions.updateSection({
    payload: param,
  })),
  deleteDiningSection: (param) => dispatch(diningActions.deleteDiningSection({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Dining);
