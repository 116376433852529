import { ACH_PAYMENT } from '../actionTypes';

export const addAchCustomer = action => ({
  type: ACH_PAYMENT.CUSTOMER_ADD,
  payload: action.payload,
});

export const fetchBeneficiars = (action) => ({
  type: ACH_PAYMENT.FETCH,
  payload: action.payload,
});

export const updateBenficiaryStatus = (action) => ({
  type: ACH_PAYMENT.UPDATE,
  payload: action.payload,
});
