import { EVENTS } from '../actionTypes';

export const fetchEvents = (action) => ({
  type: EVENTS.FETCH,
  payload: action.payload,
});

export const addNewEvents = (action) => ({
  type: EVENTS.ADD_EVENT,
  payload: action.payload,
});

export const updateEvents = (action) => ({
  type: EVENTS.UPDATE,
  payload: action.payload,
});

export const deleteEvents = (action) => ({
  type: EVENTS.DELETE,
  payload: action.payload,
});

export const fetchEventReceipts = (action) => ({
  type: EVENTS.FETCH_RECEIPT,
  payload: action.payload,
});
