// @flow
import React, { useRef, useState } from 'react';
import {
  Input,
  Select,
  Modal,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import formatAmount from 'utils/formatAmount';
import getPrefix from 'utils/getPrefix';
import {
  COUNTRIES,
  MAX_AMOUNT,
} from 'appconstants';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  supportedCurrency: Array,
};

const AddNewProduct = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    supportedCurrency,
  } = props;

  const [, forceUpdate] = useState();
  const [productName, setProductName] = useState('');
  const [productDescription, setDescription] = useState('');
  const [amount, setAmount] = useState();
  const [currency, setCurrency] = useState(selectedAccount.currency && selectedAccount.currency.name);
  const [productNotes, setNotes] = useState('');
  const [upfront, setUpfront] = useState(true);
  const [prefix, setPrefix] = useState(selectedAccount.currency && selectedAccount.currency.prefix);
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
    },
  }));

  const onCurrencyChange = (e) => {
    setCurrency(e);
    setPrefix(getPrefix(e));
  };

  const isUpfrontChange = (value) => {
    setUpfront(value);
  };

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      productName,
      currency,
      productDescription,
      amount,
      productNotes,
      // eslint-disable-next-line
      upfront: upfront === true ? false : true,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Add Product</SpButton>,
      ]}
      title="Add Product"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Product Name"
            value={productName}
            onChange={(e) => setProductName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Product name', productName, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <Input
            placeholder="Description"
            value={productDescription}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Description', productDescription, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Amount</SpH5></label>
          <div className="d-flex">
            <div style={{ width: '85%' }}>
              <Input
                placeholder="0.00"
                value={amount}
                prefix={prefix}
                onChange={(e) => setAmount(e.currentTarget.value)}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setAmount(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('amount', amount, 'required|amount')}
              </SpError>
            </div>
            <div style={{ width: '15%' }}>
              <Select
                className="w-100"
                showSearch
                defaultValue={currency}
                onChange={(e) => onCurrencyChange(e)}
              >
                {supportedCurrency.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div>
          <label htmlFor=""><SpH5>Internal Notes</SpH5></label>
          <Input
            placeholder="Optional notes for internal use"
            value={productNotes}
            onChange={(e) => setNotes(e.currentTarget.value)}
          />
        </div>
        <div className="mt-3">
          <Switch
            className="mr-2"
            checked={upfront}
            onChange={isUpfrontChange}
          /> Is Recurring?
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default AddNewProduct;
