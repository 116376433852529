import { WALLET_DETAILS } from '../actionTypes';

export const fetchWalletDetails = action => ({
  type: WALLET_DETAILS.FETCH,
  payload: action.payload,
});

export const addNewBankAccount = (action) => ({
  type: WALLET_DETAILS.ADD_BANK,
  payload: action.payload,
});

export const loadBalance = (action) => ({
  type: WALLET_DETAILS.LOAD_BALANCE,
  payload: action.payload,
});

export const fetchTransactions = (action) => ({
  type: WALLET_DETAILS.FETCH_TRANSACTIONS,
  payload: action.payload,
});

export const addNewCard = (action) => ({
  type: WALLET_DETAILS.ADD_CARD,
  payload: action.payload,
});

export const getDebitCards = (action) => ({
  type: WALLET_DETAILS.FETCH_CARDS,
  payload: action.payload,
});

export const withdrawAmount = (action) => ({
  type: WALLET_DETAILS.WITHDRAW,
  payload: action.payload,
});

export const deleteCard = (action) => ({
  type: WALLET_DETAILS.DELETE_CARD,
  payload: action.payload,
});

export const updateCard = (action) => ({
  type: WALLET_DETAILS.UPDATE_CARD,
  payload: action.payload,
});
