// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Icon,
  Tooltip,
  Badge,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import getStateList from 'utils/stateList';
import getFilterOperators from 'utils/getFilterOperators';

import Loading from 'components/Loading';
import Filters from 'components/Filters';

import { locationsActions, filterParamsActions } from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';

import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
} from 'appconstants';
import {
  SpButton,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import RBAC from 'components/rbac';

import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddLocation from './component/AddLocation';
import EditLocation from './component/EditLocation';

const LOCATIONS_FILTERS_TYPES = FILTERS_TYPES.LOCATIONS;

type Props = {
  fetchLocations: Function,
  addLocation: Function,
  updateLocation: Function,
  locations: Array<Object>,
  loading: boolean,
  test: boolean,
  totalElements: number,
  submitting: boolean,
  validator: boolean,
  selectedAccount: Object,
  setFilterData: Function,
  preserveFilters: Object,
  history: {
    push: Function,
  },
};

const Locations = (props: Props) => {
  const location = useLocation();
  const [addLocationModal, setAddLocationModal] = useState(false);
  const [editLocationModal, setEditLocationModal] = useState(false);
  const [locationSelected, setLocationSelected] = useState({});
  const {
    loading,
    fetchLocations,
    updateLocation,
    locations,
    totalElements,
    test,
    submitting,
    validator,
    selectedAccount,
    addLocation,
    history,
    setFilterData,
    preserveFilters,
  } = props;

  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [pagination, setPagination] = useState({});
  const [zipTag, setZipTag] = useState('');
  const [count, setCount] = useState(null);
  const [filterTypes, setFilterTypes] = useState(LOCATIONS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterLocations, setFilterLocations] = useState((preserveFilters && preserveFilters.filterLocations)
    ? (preserveFilters && preserveFilters.filterLocations)
    : [{
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterLocations: [{
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });

  const exportData = locations ? locations.map((values) => ({
    name: values.name,
    city: values.address.city,
    noOfTerminals: values.noOfTerminals,
  })) : '';

  const exportHeaders = [
    { label: 'OFFICE NAME', key: 'name' },
    { label: 'CITY', key: 'city' },
    { label: 'NO. OF TERMINALS', key: 'noOfTerminals' },
  ];

  const debouncedFetchLocations = debounce(fetchLocations, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchLocations({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [test, selectedAccount, location]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setAddLocationModal(false);
      setEditLocationModal(false);
    }
  }, [validator]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [locations]);

  const selectedRecord = (setlocation: Object) => {
    const { gid } = setlocation;
    history.push({
      pathname: `${PAGES.LOCATIONS}/${gid}`,
      state: location.search,
    });
  };

  const showEditLocationModal = (value) => {
    setEditLocationModal(true);
    setLocationSelected(value);
  };

  const addNewLocation = (newLocation: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      locationName,
      streetName,
      cityName,
      stateName,
      zipCode,
      countryCode,
    } = newLocation;

    addLocation({
      currentPage,
      sortParams,
      address: {
        city: cityName,
        countryCode,
        postalCode: zipCode,
        state: stateName,
        street: streetName,
      },
      name: locationName,
    });
  };

  const editSelectedLocation = (locationEdit: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      locationName,
      cityName,
      zipCode,
      stateName,
      streetName,
      locationId,
      countryCode,
      gid,
    } = locationEdit;

    const params = {
      currentPage,
      sortParams,
      address: {
        city: cityName,
        countryCode,
        id: locationId,
        postalCode: zipCode,
        state: stateName,
        street: streetName,
      },
      name: locationName,
    };
    updateLocation({
      gid,
      params,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.LOCATIONS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.LOCATIONS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterLocations];
    if (filterLocations.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = LOCATIONS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterLocations(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterLocations];
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = LOCATIONS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterLocations(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (!e.currentTarget) {
      selectedValue = e;
    }
    if (e.currentTarget && e.currentTarget.value) {
      selectedValue = e.currentTarget.value;
    }
    const list = [...filterLocations];
    list[index].filterValue = selectedValue;
    setFilterLocations(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterLocations];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      handleValue(list[index].filterValue, index);
    }
    setFilterLocations(list);
  };

  const handleAddClick = () => {
    const list = [...filterLocations];
    setFilterLocations([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterLocations([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(LOCATIONS_FILTERS_TYPES);
    setCount(null);
    history.push(PAGES.LOCATIONS);
  };

  const applyFilters = () => {
    const locationsFilters = filterLocations.filter((item) => item.checked);
    for (let i = 0; i < locationsFilters.length; i += 1) {
      if (!locationsFilters[i].filterName || !locationsFilters[i].filterOperator || !locationsFilters[i].filterValue) {
        return;
      }
    }
    if (locationsFilters[0].filterValue) {
      setCount(locationsFilters && locationsFilters.length);

      for (let i = 0; i < locationsFilters.length; i += 1) {
        if (locationsFilters[i].checked) {
          if (locationsFilters[i].filterName === 'createdAt') {
            locationsFilters[i] = locationsFilters[i].filterValue;
          } else {
            locationsFilters[i] = `${locationsFilters[i].filterName}.${locationsFilters[i].filterOperator}=${locationsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(locationsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterLocations,
        });
        history.push({
          pathname: PAGES.LOCATIONS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterLocations,
        });
        history.push({
          pathname: PAGES.LOCATIONS,
          search: `?filterParams=${filterParams}`,
        });
      }
    }
  };

  const columns = [
    {
      title: 'OFFICE NAME',
      dataIndex: 'name',
      width: '30%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {name}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'CITY',
      dataIndex: 'address.city',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const { city } = record.address;
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {city}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'NO. OF TERMINALS',
      dataIndex: 'noOfTerminals',
      width: '20%',
      align: 'center',
      sorter: false,
      render: (noOfTerminals) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {noOfTerminals}
          </span>
        </Tooltip>
      ),
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '25%',
    align: 'center',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showEditLocationModal(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <RBAC
              permit={
                [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                  ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.SUPERUSER,
                  ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
              }
            >
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                ghost
                onClick={() => setAddLocationModal(true)}
              >
                {'\u002B'}&nbsp;Add Location
              </SpButton>
            </RBAC>
            <span>
              {
                exportData !== null
                  ? (
                    <Tooltip placement="left" title="Click here to export Locations">
                      <CSVLink
                        target="_blank"
                        className="mr-3"
                        filename="Locations Report.csv"
                        data={exportData}
                        headers={exportHeaders}
                        onMouseEnter={() => setFillColor(true)}
                        onMouseLeave={() => setFillColor(false)}
                      >
                        <ExportIcon
                          fill={fillColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </CSVLink>
                    </Tooltip>
                  ) : null
              }
            </span>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Locations">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && (
                <FilterWrapper
                  style={{ marginLeft: '-115px' }}
                >
                  <Filters
                    filtersGrid={filterLocations}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterLocations.length < LOCATIONS_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
            </span>
          </Col>
        </div>
      </Row>
      {/* EDIT LOCATION MODAL */}
      {editLocationModal && (
        <EditLocation
          visible={editLocationModal}
          submitting={submitting}
          locationSelected={locationSelected}
          close={() => setEditLocationModal(false)}
          submit={editSelectedLocation}
          country={selectedAccount.country.name}
          statesInCountry={statesInCountry}
          zipTag={zipTag}
          selectedAccount={selectedAccount}
        />
      )}
      {/* ADD LOCATION MODAL */}
      {addLocationModal && (
        <AddLocation
          visible={addLocationModal}
          submitting={submitting}
          close={() => setAddLocationModal(false)}
          submit={addNewLocation}
          country={selectedAccount.country.name}
          cntryId={selectedAccount.country.id}
          statesInCountry={statesInCountry}
          zipTag={zipTag}
        />
      )}
      <RBAC
        fallback={
          (
            <Table
              columns={columns}
              rowKey={(record) => record.gid}
              dataSource={locations}
              pagination={pagination}
              onChange={handleTableChange}
            />
          )
        }
        permit={
          [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST,
            ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
        }
      >
        <Table
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={locations}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  locations: state.locations.content,
  loading: state.loading.loading,
  test: state.account.test,
  totalElements: state.locations.totalElements,
  isSuperUser: state.user.superUser,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  preserveFilters: state.filterParams.preserveFilters,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLocations: param => dispatch(locationsActions.fetchLocations({
    payload: param,
  })),
  addLocation: param => dispatch(locationsActions.addLocation({
    payload: param,
  })),
  updateLocation: param => dispatch(locationsActions.updateLocation({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Locations);
