
const calc = (size, unit) => {
  const e = document.getElementById('payment-page');
  let sizeCal = 1024;
  if (e) {
    const width = e.parentElement.clientWidth;
    sizeCal = Math.round(width);
  }
  return `${sizeCal / size}${unit}`;
};

export const normalTextFontSize = (isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(84, 'px');
  }
  return isPreview ? '10px' : '14px';
};

export const smallTextFontSize = (isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(92, 'px');
  }
  return isPreview ? '10px' : '12px';
};

export const h1FontSize = (isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(60, 'px');
  }
  return isPreview ? '16px' : '19px';
};

export const h2FontSize = (isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(72, 'px');
  }
  return isPreview ? '12px' : '16px';
};

export const submitFontSize = (isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(66, 'px');
  }
  return isPreview ? '12px' : '18px';
};

export const titleFontSize = (isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(80, 'px');
  }
  return isPreview ? '11px' : '17px';
};

export const imgWith = (size) => calc(size, 'px');

export const inputHeight = (size, isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(size, 'px');
  }
  return isPreview ? '19px' : '30px';
};

export const selectHeight = (size, isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(size, 'px');
  }
  return isPreview ? '19px' : '35px';
};

export const checkBoxHeight = (size, isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(size, 'px');
  }
  return isPreview ? '8px' : '18px';
};

export const submitHeight = (size, isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(size, 'px');
  }
  return isPreview ? '25px' : '35px';
};

export const logoWidth = (size, isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(size, 'px');
  }
  return isPreview ? '63px' : '108px';
};

export const textAreaHeight = (size, isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(size, 'px');
  }
  return isPreview ? '76px' : '120px';
};

export const quantityTextHeight = (size, isPreview, isAdaptive) => {
  if (isAdaptive) {
    return calc(size, 'px');
  }
  return isPreview ? '22px' : '30px';
};
