// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Layout,
  Row,
  Col,
  Avatar,
  Icon,
  Select,
  Tooltip,
} from 'antd';
import { SpButton } from 'components/DesignKit';
import {
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import type { ContextRouter } from 'react-router-dom';

import Breadcrumb from 'components/Breadcrumb';
import {
  accountActions,
  authActions,
  notificationActions,
  userActions,
  partnerParamsActions,
  userProfileAction,
  paymentsLinkAction,
} from 'store/actions';

import {
  axiosAuthInstance,
  axiosNoAuthInstance,
  API_END_POINTS,
} from 'api';
import Support from 'components/NavIcons/support';
import Logout from 'components/NavIcons/logout';

import {
  PAGES,
  TOKENS,
  MESSAGES,
  ROLES,
} from 'appconstants';

import {
  NOTIFICATION,
  USERPROFILE,
} from 'store/actionTypes';

const { Option } = Select;

// $FlowFixMe
const Header = styled(Layout.Header)({
  backgroundColor: '#fff',
  paddingLeft: '24px',
  paddingRight: '24px',
  height: '70px',
});

// $FlowFixMe
const TestData = styled('div')({
  background: '#F4A261',
  borderRadius: '20px',
  height: '20px',
  width: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: '12px',
});

type Props = ContextRouter & {
  accounts: {
    content: Array<Object>
  },
  user: Object,
  test: boolean,
  selectedAccount: Object,
  setSelectedAccount: Function,
  fetchAccount: Function,
  logout: Function,
  setNotification: Function,
  history: {
    push: Function,
  },
  userProfile: Object,
  fetchUserProfile: Function,
  partnerAffiliate: Function,
  clearPaymentsLink: Function,
  role: Object,
  partner: boolean,
  internalRole: Object,
  isMobileView: Boolean,
};

const AppHeader = (props: Props) => {
  const {
    test,
    setNotification,
    history,
    userProfile,
    user,
    fetchUserProfile,
    fetchAccount,
    partnerAffiliate,
    role,
    clearPaymentsLink,
    partner,
    internalRole,
    isMobileView,
  } = props;
  const userGid = user.gid;
  const [options, setOptions] = useState([]);
  const [profileName, setProfileName] = useState();
  const kdsRole = localStorage.getItem('kdsRole');
  const getInitials = () => {
    const { givenName, familyName } = userProfile;
    const fullName = givenName ? `${givenName.charAt(0)}${familyName.charAt(0)}` : '';
    setProfileName(fullName);
  };

  useEffect(() => {
    if (role && role.name === ROLES.POS) {
      history.replace(PAGES.POS_USER);
    } else {
      fetchUserProfile(userGid);
      const accountParams = '?page=1&size=1000';
      fetchAccount({ accountParams });
    }
  }, []);

  useEffect(() => {
    getInitials();
  }, [userProfile]);

  useEffect(() => {
    if (props.accounts.content) {
      const addNew = { gid: '', name: '+ New Account' };
      const accounts = props.accounts.content.map(
        (c) => ({ gid: c.gid, name: c.name }),
      );
      accounts.push(addNew);
      setOptions(accounts);
    }
  }, [props.accounts]);

  const authLogout = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`,
        'Refresh-Token': `${localStorage.getItem(TOKENS.REFRESH_TOKEN)}`,
      };
      const { data } = await axiosNoAuthInstance.get('/logout', { headers });
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  };

  const logOut = async () => {
    await authLogout();
    history.push(PAGES.DASHBOARD);
    props.logout();
  };

  const handleChange = async (gid: string) => {
    if (gid) {
      try {
        // This call is happennig in Partnerlist component as well.
        // any change you make here please make sure to change in Partnerlist component as well.
        const { data: { entity: { token: { accessToken } } } } = await axiosAuthInstance.get(`${API_END_POINTS.JWE}/${gid}/switch?isTest=${test}`);
        localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
        if (accessToken && (!partner)) {
          const { data: { entity: userEntity } } = await axiosAuthInstance.get(API_END_POINTS.USER_PROFILE);
          const roleType = userEntity.role && userEntity.role.name ? userEntity.role.name : '';
          if (roleType === ROLES.PARTNER) {
            partnerAffiliate(true);
          }
        }
        clearPaymentsLink();
        if (internalRole && internalRole.name) {
          if (internalRole && internalRole.name === ROLES.SUPERUSER) {
            props.history.push(PAGES.DASHBOARD);
          } else {
            props.history.push(PAGES.PAYMENTS);
          }
        } else {
          props.history.push(PAGES.DASHBOARD);
        }
      } catch (e) {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
        });
      }
      // update selectedAccount
      props.setSelectedAccount(props.accounts.content.find(a => a.gid === gid));
    } else {
      history.push(PAGES.NEW_ACCOUNT);
    }
  };

  return (
    <Header className="HEADER mb-2" style={{ height: isMobileView ? '150px' : '' }}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={isMobileView ? 24 : 12} style={{ marginLeft: isMobileView ? '80px' : '' }}>
          <Breadcrumb />
        </Col>
        <Col span={isMobileView ? 24 : 12} className="d-flex justify-content-end align-items-center">
          {
            props.test && (
              <div className="mx-4">
                <TestData>Test Data</TestData>
              </div>
            )
          }
          {
            (kdsRole && kdsRole === ROLES.ASSOCIATE) ? null
              : (kdsRole && kdsRole === ROLES.KDSUSER) ? null
                : (kdsRole && kdsRole === ROLES.KDSADMIN) ? null : (
                  <>
                    {
                      props.internalRole
                        ? (
                          <div className="mx-4">
                            <SpButton
                              type="secondary"
                              shape="round"
                              ghost
                              onClick={() => {
                                props.clearAccountGid();
                                clearPaymentsLink();
                              }}
                            >
                              Switch User Account
                            </SpButton>
                          </div>
                        )
                        : (
                          <Select
                            style={{
                              minWidth: '160px',
                              color: '#1791FF',
                              opacity: 0.8,
                              fontSize: '14px',
                              marginRight: '16px',
                              fontWeight: 600,
                              border: 'none',
                            }}
                            bordered={false}
                            showSearch
                            optionFilterProp="children"
                            value={props.selectedAccount && props.selectedAccount.name}
                            key={options[0] && options[0].gid}
                            onChange={handleChange}
                            loading={!props.selectedAccount}
                            className="ACCOUNT-SELECTION"
                          >
                            {
                              options
                                .map((item) => (
                                  <Option key={item.gid} value={item.gid}>
                                    {item.name}
                                  </Option>
                                ))
                            }
                          </Select>
                        )
                    }
                    <div className="d-flex align-items-center">
                      <Tooltip placement="bottom" title={<span>User Profile</span>}>
                        <Avatar
                          style={{
                            cursor: 'pointer',
                            marginLeft: '20px',
                          }}
                          size="default"
                          onClick={() => props.history.push(PAGES.USERDETAILS)}
                        >
                          {profileName}
                        </Avatar>
                      </Tooltip>
                      <Tooltip placement="bottom" title={<span>Support</span>}>
                        <Icon
                          style={{
                            cursor: 'pointer', marginLeft: '21px',
                          }}
                          component={Support}
                          onClick={() => props.history.push(PAGES.SUPPORT)}
                        />
                      </Tooltip>
                    </div>
                  </>
                )
          }

          <div className="d-flex align-items-center">
            <Tooltip placement="bottom" title={<span>Logout</span>}>
              <Icon
                style={{
                  fontSize: '24px', cursor: 'pointer', marginLeft: '20px', color: '#000',
                }}
                component={Logout}
                onClick={logOut}
              />
            </Tooltip>
          </div>
        </Col>
      </Row>
    </Header>
  );
};

const mapStateToProps = (state) => ({
  accounts: state.account.accounts,
  loading: state.loading.loading,
  selectedAccount: state.account.selectedAccount,
  test: state.account.test,
  user: state.user.user,
  role: state.user.role,
  internalRole: state.user.internalRole,
  userProfile: state.userProfile,
  partner: state.partnerParams.partner,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchAccount: param => dispatch(accountActions.fetchAccount({
    payload: param,
  })),
  setSelectedAccount: account => dispatch(accountActions.setSelectedAccount(account)),
  logout: () => dispatch(authActions.logout()),
  fetchUserProfile: gid => dispatch(userProfileAction.fetchUserProfile({
    type: USERPROFILE.FETCH,
    payload: gid,
  })),
  clearPaymentsLink: param => dispatch(paymentsLinkAction.clearPaymentsLink({
    payload: param,
  })),
  partnerAffiliate: partner => dispatch(partnerParamsActions.partnerAffiliate(partner)),
  clearAccountGid: () => dispatch(userActions.updateUserAccount(null)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppHeader),
);
