import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  BANK_STATEMENT_DETAILS,
  NOTIFICATION,
} from '../actionTypes';


const fetchAccountReceivables = async gid => axiosAuthInstance.get(`${API_END_POINTS.ACCOUNT_RECEIVABLES}?bankAccountStatementGid.EQ=${gid}`);

const fetchAccountPayables = async gid => axiosAuthInstance.get(`${API_END_POINTS.ACCOUNT_PAYABLES}?bankAccountStatementGid.EQ=${gid}`);

export function* getAccountReceivables(action) {
  try {
    const { data: { entity } } = yield call(fetchAccountReceivables, action.payload);
    yield put({ type: BANK_STATEMENT_DETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: BANK_STATEMENT_DETAILS.FAILED, payload: e.response.data });
  }
}

export function* getAccountPayables(action) {
  try {
    const { data: { entity } } = yield call(fetchAccountPayables, action.payload);
    yield put({ type: BANK_STATEMENT_DETAILS.PAYABLES_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: BANK_STATEMENT_DETAILS.FAILED, payload: e.response.data });
  }
}
