import { SUBSCRIPTION_BUTTONV2DETAILS } from '../actionTypes';

export const fetchSubscriptionButtonV2Details = (action) => ({
  type: SUBSCRIPTION_BUTTONV2DETAILS.FETCH,
  payload: action.payload,
});

export const fetchSubscriptionButtonV2Payments = (action) => ({
  type: SUBSCRIPTION_BUTTONV2DETAILS.PAYMENTS_FETCH,
  payload: action.payload,
});

export const DeactiveSubscriptionV2Button = (action) => ({
  type: SUBSCRIPTION_BUTTONV2DETAILS.DELETE,
  payload: action.payload,
});

export const updateSubscriptionButtonV2 = (action) => ({
  type: SUBSCRIPTION_BUTTONV2DETAILS.UPDATE,
  payload: action.payload,
});
