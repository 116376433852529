// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
  Icon,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';
import getBackground from 'utils/getBackground';
import getTerminalStatus from 'utils/getTerminalStatus';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getFilterOperators from 'utils/getFilterOperators';

import {
  notificationActions,
  adminTerminalActions,
  inventoryShopsActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
} from 'appconstants';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddAdminTerminal from './components/AddAdminTerminal';
import EditAdminTerminal from './components/EditAdminTerminal';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const TERMINALS_FILTERS_TYPES = FILTERS_TYPES.ADMIN_TERMINALS;

type Props = {
  adminTerminal: Array<Object>,
  fetchAdminTerminal: Function,
  addAdminTerminal: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  updateAdminTerminal: Function,
  setFilterData: Function,
  preserveFilters: Object,
  isMobileView: Boolean,
};

const AdminTerminal = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    adminTerminal,
    loading,
    addAdminTerminal,
    fetchAdminTerminal,
    totalElements,
    submitting,
    validator,
    test,
    history,
    updateAdminTerminal,
    setFilterData,
    preserveFilters,
    inventoryShops,
    fetchInventoryShops,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }


  const [pagination, setPagination] = useState({});
  const [addAdminTerminalModal, setAddAdminTerminalModal] = useState(false);
  const [editAdminTerminalModal, setEditAdminTerminalModal] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState({});
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(TERMINALS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterTerminals, setFilterTerminals] = useState((preserveFilters && preserveFilters.filterTerminals)
    ? (preserveFilters && preserveFilters.filterTerminals)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterTerminals: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });

  const exportData = adminTerminal ? adminTerminal.map((values) => ({
    deviceName: values.deviceName,
    deviceId: values.deviceId,
    terminalMid: values.terminalMid || '',
    terminalTid: values.terminalTid || '',
    ksn: values.ksn ? values.ksn : '',
    status: getTerminalStatus(values.status),
  })) : '';

  const exportHeaders = [
    { label: 'DEVICE NAME', key: 'deviceName' },
    { label: 'DEVICE ID', key: 'deviceId' },
    { label: 'MID', key: 'terminalMid' },
    { label: 'TID', key: 'terminalTid' },
    { label: 'KSN', key: 'ksn' },
    { label: 'STATUS', key: 'status' },
  ];

  const debouncedFetchAdminTerminal = debounce(fetchAdminTerminal, 2000, { leading: true });
  const debouncedFetchInventoryShops = debounce(fetchInventoryShops, 2000, { leading: true });


  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchAdminTerminal({ currentPage, sortParams, filterParams });
    debouncedFetchInventoryShops({ currentPage, sortParams, filterParams });

  };

  useEffect(() => {
    if (!validator) {
      setAddAdminTerminalModal(false);
      setEditAdminTerminalModal(false);
      fetchInventoryShops('&sortBy=createdAt&direction=DESC');

    }
  }, [validator]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [location, test, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [adminTerminal]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addNewAdminTerminal = (deviceInfo: Object) => {
    const {
      deviceId,
      deviceName,
      appVersion,
      bankName,
      mid,
      tid,
      deviceIP,
      autoSettlement,
      integrationType,
      shopGid

    } = deviceInfo;
    addAdminTerminal({
      deviceId,
      deviceName,
      appVersion,
      bankName,
      integrationType: integrationType || null,
      terminalMid: mid,
      terminalTid: tid,
      deviceIp: deviceIP || null,
      autoSettlementEnabled: autoSettlement,
      shopGid:shopGid || null
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.ADMIN_TERMINAL,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.ADMIN_TERMINAL,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const selectedRecord = (record) => {
    const adminTerminalGid = record.gid;
    history.push({
      pathname: `${PAGES.ADMIN_TERMINAL}/${adminTerminalGid}`,
      state: location.search,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterTerminals];
    if (filterTerminals.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = TERMINALS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTerminals(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterTerminals];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = TERMINALS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTerminals(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterTerminals];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterTerminals(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterTerminals(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterTerminals];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterTerminals(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterTerminals(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterTerminals[index].filterName === 'createdAt') {
      if (filterTerminals[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterTerminals[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterTerminals[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterTerminals[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterTerminals];
    list[index].filterValue = selectedValue;
    setFilterTerminals(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTerminals];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterTerminals[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterTerminals(list);
  };

  const handleAddClick = () => {
    const list = [...filterTerminals];
    setFilterTerminals([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterTerminals([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(TERMINALS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.ADMIN_TERMINAL);
  };

  const applyFilters = () => {
    const terminalsFilters = filterTerminals.filter((item) => item.checked);
    for (let i = 0; i < terminalsFilters.length; i += 1) {
      if (!terminalsFilters[i].filterName || !terminalsFilters[i].filterOperator || !terminalsFilters[i].filterValue) {
        return;
      }
    }
    if (terminalsFilters[0].filterValue || (terminalsFilters[0].filterValueFrom && terminalsFilters[0].filterValueTo)) {
      setCount(terminalsFilters && terminalsFilters.length);
      for (let i = 0; i < terminalsFilters.length; i += 1) {
        if (terminalsFilters[i].checked) {
          if (terminalsFilters[i].filterName === 'createdAt') {
            terminalsFilters[i] = terminalsFilters[i].filterValue;
          } else if (terminalsFilters[i].filterName === 'amount' && terminalsFilters[i].filterOperator === 'BT') {
            const name = terminalsFilters[i].filterName;
            const from = terminalsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = terminalsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            terminalsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (terminalsFilters[i].filterName === 'amount' && terminalsFilters[i].filterOperator !== 'BT') {
            const value = terminalsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            terminalsFilters[i] = `${terminalsFilters[i].filterName}.${terminalsFilters[i].filterOperator}=${value}`;
          } else {
            terminalsFilters[i] = `${terminalsFilters[i].filterName}.${terminalsFilters[i].filterOperator}=${terminalsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(terminalsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterTerminals,
        });
        history.push({
          pathname: PAGES.ADMIN_TERMINAL,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterTerminals,
        });
        history.push({
          pathname: PAGES.ADMIN_TERMINAL,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const showEditTerminalModal = (value: Object) => {
    setEditAdminTerminalModal(true);
    setSelectedTerminal(value);
  };

  const editSelectedTerminal = (terminal: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      deviceId,
      deviceName,
      appVersion,
      mid,
      tid,
      gid,
      autoSettlement,
      bankName,
      deviceIP,
      contactName,
      phoneNumber,
      countryCode,
      serialNumber,
      integrationType,
      shopGid
    } = terminal;

    const params = {
      deviceName,
      appVersion,
      deviceId,
      terminalMid: mid,
      terminalTid: tid,
      deviceIp: deviceIP || ' ',
      bankName,
      contactName,
      serialNumber,
      contactNumber: phoneNumber ? `${countryCode}${phoneNumber}` : null,
      autoSettlementEnabled: autoSettlement,
      integrationType: integrationType || null,
      shopGid: shopGid === "" ? null : shopGid,
      removeShop: shopGid === "" ? true : undefined,
    };

    updateAdminTerminal({
      currentPage,
      sortParams,
      gid,
      params,
    });
  };


  const columns = [
    {
      title: 'DEVICE NAME',
      dataIndex: 'deviceName',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (deviceName) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {deviceName || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DEVICE ID',
      dataIndex: 'deviceId',
      width: '13%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (deviceId) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {deviceId || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'MID',
      dataIndex: 'terminalMid',
      width: '13%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (terminalMid) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {terminalMid || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'TID',
      dataIndex: 'terminalTid',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (terminalTid) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {terminalTid || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'KSN',
      dataIndex: 'ksn',
      width: '17%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (ksn) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {ksn || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '13%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <SpStatusTag style={{ backgroundColor: getBackground(status) }}>
            {getTerminalStatus(status)}
          </SpStatusTag>
        </Tooltip>
      ),
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'center',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showEditTerminalModal(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                ghost
                className="mr-3"
                onClick={() => setAddAdminTerminalModal(true)}
              >
                {'\u002B'}&nbsp;Add Terminal
              </SpButton>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Terminals">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Terminals Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Terminals">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-63px' }}
                  >
                    <Filters
                      filtersGrid={filterTerminals}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                      handleFromValue={handleFromValue}
                      handleToValue={handleToValue}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterTerminals.length < TERMINALS_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD ADMIN TERMINAL MODAL */}
      {addAdminTerminalModal && (
        <AddAdminTerminal
          visible={addAdminTerminalModal}
          submitting={submitting}
          close={() => setAddAdminTerminalModal(false)}
          inventoryShops={inventoryShops}
          submit={addNewAdminTerminal}
        />
      )}

      {/* EDIT TERMINAL MODAL */}
      {editAdminTerminalModal && (
        <EditAdminTerminal
          visible={editAdminTerminalModal}
          submitting={submitting}
          selectedTerminal={selectedTerminal}
          phoneCode={phoneCode}
          close={() => setEditAdminTerminalModal(false)}
          submit={editSelectedTerminal}
          inventoryShops={inventoryShops}

        />
      )}

      <RBAC
        fallback={(
          <Table
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={adminTerminal}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
      >
        <Table
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={adminTerminal}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  inventoryShops: state.inventoryShops.content,
  adminTerminal: state.adminTerminal.content,
  totalElements: state.adminTerminal.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryShops: param => dispatch(inventoryShopsActions.fetchInventoryShops({
    payload: param,
  })),
  fetchAdminTerminal: param => dispatch(adminTerminalActions.fetchAdminTerminal({
    payload: param,
  })),
  addAdminTerminal: param => dispatch(adminTerminalActions.addAdminTerminal({
    payload: param,
  })),
  updateAdminTerminal: param => dispatch(adminTerminalActions.updateAdminTerminal({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AdminTerminal);
