import { CHEQUE_TRANSACTIONS_DETAILS } from '../actionTypes';

export const fetchChequeTransactionDetails = (action) => ({
  type: CHEQUE_TRANSACTIONS_DETAILS.FETCH,
  payload: action.payload,
});

export const notifyChequeTransactionsDetails = (action) => ({
  type: CHEQUE_TRANSACTIONS_DETAILS.NOTIFY,
  payload: action.payload,
});

export const voidChequePayment = (action) => ({
  type: CHEQUE_TRANSACTIONS_DETAILS.VOIDED,
  payload: action.payload,
});
