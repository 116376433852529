import { PAYOUTSDETAILS } from '../actionTypes';

const initialState = {
  payoutDetailsStatus: undefined,
  payoutDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYOUTSDETAILS.FETCH:
      return {
        ...state,
        payoutDetailsStatus: 'STARTED',
      };
    case PAYOUTSDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        payoutDetailsStatus: 'SUCCESS',
      };
    case PAYOUTSDETAILS.FAILED:
      return {
        ...state,
        payoutDetailsStatus: 'FAILED',
        payoutDetailsError: action.payload,
      };
    case PAYOUTSDETAILS.TRANSACTIONS_SUCCESS:
      return {
        ...state,
        payoutTransactions: action.payload,
      };
    default:
      return state;
  }
};
