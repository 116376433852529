import { USER } from '../actionTypes';

export const setUser = (action) => ({
  type: USER.FETCH,
  payload: action.payload,
});

export const updateUserAccount = (action) => ({
  type: USER.UPDATE,
  payload: action,
});
