import { SUBSCRIPTION_BUTTONDETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  payments: [],
  subscriptionDetails: [],
  subscriptionButtonDetailsStatus: undefined,
  subscriptionButtonDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_BUTTONDETAILS.FETCH:
      return {
        ...state,
        subscriptionButtonDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case SUBSCRIPTION_BUTTONDETAILS.SUCCESS:
      return {
        ...state,
        subscriptionDetails: action.payload,
        subscriptionButtonDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case SUBSCRIPTION_BUTTONDETAILS.FAILED:
      return {
        ...state,
        subscriptionButtonDetailsError: action.payload,
        subscriptionButtonDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case SUBSCRIPTION_BUTTONDETAILS.PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
      };
    default:
      return state;
  }
};
