// @flow
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';

import {
  Card,
  Row,
  Col,
  Input,
  Select,
  Icon,
} from 'antd';

import {
  SpH5,
  SpButton,
  SpForm,
  SpError,
  SpText,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  inventoryReservationsDetailsActions,
  inventoryReservationsActions,
  inventoryItemsActions,
  notificationActions,
  inventoryShopsDetailsActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  PAGES,
  MESSAGES,
  COUNTRY_PHONE_CODE,
} from 'appconstants';
// import { toPng } from 'html-to-image';
// import QRCode from 'react-qr-code';
import { QRCode } from 'react-qrcode-logo';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import getReservation from 'utils/getReservation';
import Clipboard from 'components/NavIcons/copyclipboard';
import Qrlogo from '../../../../assets/imgs/SwirepayQrlogo.png';
import DeletePrinterLabel from './components/DeleteOrderType';

const { Option } = Select;

type Props = {
  fetchInventoryReservationsDetails: Function,
  cancelInventoryReservations: Function,
  inventoryPrinterLabelDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  submitting: Boolean,
  validator: Boolean,
  setNotification: Function,
  paymentSlug: Array,
  fetchShopsSlug: Function,
  holdReservations: Function,
}

const PrinterLabelDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchInventoryReservationsDetails,
    cancelInventoryReservations,
    inventoryPrinterLabelDetails,
    loading,
    history,
    submitting,
    validator,
    selectedAccount,
    setNotification,
    paymentSlug,
    fetchShopsSlug,
    holdReservations,
  } = props;
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const qrCodeRef = useRef(null);
  const [redirect, setRedirect] = useState(false);
  const [deletePrinterLabelModal, setDeletePrinterLabelModal] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [selectedPrinterLabel, setSelectedPrinterLabel] = useState({});
  const [name, setName] = useState('');
  const [guest, setGuest] = useState('');
  const [, forceUpdate] = useState();
  const kdsRole = localStorage.getItem('kdsRole');
  const [phoneFormate, setPhoneFormate] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [slugName, setSlugName] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useEffect(() => {
    if (paymentSlug && paymentSlug.length !== 0) {
      setSlugName(paymentSlug[0].slugName);
    }
  }, [paymentSlug]);

  useEffect(() => {
    if (inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.shopTable && inventoryPrinterLabelDetails.shopTable.shop.gid) {
      fetchShopsSlug(inventoryPrinterLabelDetails.shopTable.shop.gid);
    }
  }, [inventoryPrinterLabelDetails]);

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setCustomerPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${customerPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  simpleValidator.current.purgeFields();

  const getData = () => {
    fetchInventoryReservationsDetails(gid);
  };

  /**
  * Get data when selected account changes
  */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setCountryCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, []);

  const getLink = (id) => {
    const url = process.env.REACT_APP_ENV === 'Production' ? 'https://order.swirepay.com' : 'https://staging-order.swirepay.com';
    const selectedSlugName = `${url}/${slugName}?tableGid=${id}`;
    return selectedSlugName;
  };

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.TABLE_RESERVATION);
    }
  }, []);

  useEffect(() => {
    if (redirect && !validator) {
      setEditDetails(false);
      setDeletePrinterLabelModal(false);
      getData(location.search);
      // history.push({
      //   pathname: PAGES.TABLE_RESERVATION,
      // });
    }
  }, [validator]);

  const downloadQRCode = async () => {
    const qrCode = qrCodeRef.current.canvas.current;
    if (!qrCode) {
      console.error('QR code element not found.');
      return;
    }
    try {
      const dataUrl = qrCode.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'qrcode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.QR_Download,
      });
    } catch (error) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Error capturing QR code',
      });
    }
  };

  useEffect(() => {
    const phone = inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.phoneNumber
      ? inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.phoneNumber : '';
    const phoneResult = phone ? findPhoneNumbersInText(phone) : null;
    if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
      setCountryCode(`+${phoneResult[0].number.countryCallingCode}`);
      setCustomerPhone(phoneResult[0].number.nationalNumber);
      const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
      const formater = data.substr(data.indexOf(' ') + 1);
      setPhoneFormate(formater);
    }
  }, [editDetails]);

  useEffect(() => {
    if (inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.gid) {
      setName((inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.name)
        || inventoryPrinterLabelDetails.name);
      setGuest(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.noOfGuest);
    }
    if (inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.customer) {
      const phone = (inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.phoneNumber)
        ? inventoryPrinterLabelDetails.customer.phoneNumber : '';
      const cntryCode = (inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.phoneNumber)
        ? inventoryPrinterLabelDetails.customer.phoneNumber.substring(0, 2) : '';
      if (cntryCode === '+1') {
        setPhoneFormate('+# (###) ###-####');
      } else if (phone.length !== 14 && cntryCode === '+9') {
        setPhoneFormate('+##-##### #####');
      } else if (phone.length === 12 && cntryCode !== '+1') {
        setPhoneFormate('+#-##########');
      } else if (phone.length === 13 && cntryCode !== '+9') {
        setPhoneFormate('+##-##########');
      } else if (phone.length === 14) {
        setPhoneFormate('+###-##########');
      }
    }
  }, [inventoryPrinterLabelDetails]);

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: 'Inventory order link copied sucessfully.',
    });
  };

  const viewOrders = (record) => {
    const itemGid = record && record.gid;
    window.open(`${PAGES.INVENTORY_ORDERS}/${itemGid}`);
  };

  const editSelectedInfo = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      name,
      noOfGuest: guest,
      customer: {
        name,
        email: ((inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.email) || null),
        phoneNumber: customerPhone ? `${countryCode}${customerPhone}` : null,
      },
      customerGid: (inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.gid) || null,
    };
    const shopName = (inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.shopTable && inventoryPrinterLabelDetails.shopTable.shop && inventoryPrinterLabelDetails.shopTable.shop.name) || '';
    const currentPage = 1;
    const shopSearch = `?shopName=${encodeURIComponent(shopName)}&page=${currentPage}`;
    cancelInventoryReservations({
      gid,
      params,
      shopSearch,
    });
    setRedirect(true);
  };

  const holdReservationInfo = (event) => {
    event.preventDefault();
    const inventoryGid = inventoryPrinterLabelDetails.inventoryOrder && inventoryPrinterLabelDetails.inventoryOrder.gid;
    holdReservations({
      gid: inventoryGid,
    });
    setRedirect(true);
  };

  const deleteSelectedOrderType = (data) => {
    const {
      note,
    } = data;
    const params = {
      status: 'CANCELED',
      note,
    };
    // eslint-disable-next-line max-len
    const shopName = (inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.shopTable && inventoryPrinterLabelDetails.shopTable.shop && inventoryPrinterLabelDetails.shopTable.shop.name) || '';
    const currentPage = 1;
    const shopSearch = `?shopName=${encodeURIComponent(shopName)}&page=${currentPage}`;
    cancelInventoryReservations({
      gid,
      params,
      shopSearch,
    });
    setRedirect(true);
  };

  const showDeletePrinterLabelModal = () => {
    setSelectedPrinterLabel(inventoryPrinterLabelDetails);
    setDeletePrinterLabelModal(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        inventoryPrinterLabelDetails.gid && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col />
                {
                  (inventoryPrinterLabelDetails.status === 'RESERVED' || inventoryPrinterLabelDetails.status === 'OCCUPIED') && (
                    <Col>
                      {
                        editDetails ? (
                          <SpButton
                            shape="round"
                            type="secondary"
                            className="mr-3"
                            onClick={() => setEditDetails(false)}
                          >
                            Cancel
                          </SpButton>
                        ) : (
                          <SpButton
                            shape="round"
                            type="danger"
                            className="mr-3"
                            onClick={() => showDeletePrinterLabelModal(true)}
                          >
                            Cancel Reservation
                          </SpButton>
                        )
                      }
                      {
                        (inventoryPrinterLabelDetails.inventoryOrder && inventoryPrinterLabelDetails.inventoryOrder.hold) === true && (
                          <SpButton
                            shape="round"
                            type="primary"
                            className="mr-3"
                            onClick={holdReservationInfo}
                          >
                            Unhold Reservation
                          </SpButton>
                        )
                      }

                      {
                        kdsRole !== 'KDS-USER' && (
                          <>
                            {
                              editDetails ? (
                                <SpButton
                                  shape="round"
                                  type="primary"
                                  onClick={editSelectedInfo}
                                >
                                  Update
                                </SpButton>
                              ) : (
                                <SpButton
                                  shape="round"
                                  type="secondary"
                                  onClick={() => setEditDetails(true)}
                                >
                                  Edit
                                </SpButton>
                              )
                            }
                          </>
                        )
                      }
                    </Col>
                  )
                }
              </Row>
              {/* DELETE ORDER TYPE MODAL */}
              {deletePrinterLabelModal && (
                <DeletePrinterLabel
                  visible={deletePrinterLabelModal}
                  submitting={submitting}
                  inventoryItemDetails={selectedPrinterLabel}
                  selectedAccount={selectedAccount}
                  close={() => setDeletePrinterLabelModal(false)}
                  submit={deleteSelectedOrderType}
                />
              )}
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col span={18}>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Shop</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>
                          {/* eslint-disable-next-line max-len */}
                          {(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.shopTable && inventoryPrinterLabelDetails.shopTable.shop && inventoryPrinterLabelDetails.shopTable.shop.name) || <>&#8211;</>}
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Group ID</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>
                          {(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.reservationCode) || <>&#8211;</>}
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Table</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>
                          {(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.shopTable && inventoryPrinterLabelDetails.shopTable.name)
                            || <>&#8211;</>}
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Table Section</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>
                          {/* eslint-disable-next-line max-len */}
                          {(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.shopTable && inventoryPrinterLabelDetails.shopTable.shopSection && inventoryPrinterLabelDetails.shopTable.shopSection.name)
                            || <>&#8211;</>}
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  {/* <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Guest Name</span>
                      </Col>
                      <Col span={7}>
                        {
                          editDetails ? (
                            <>
                              <Input
                                placeholder="Name"
                                onChange={(e) => setName(e.currentTarget.value)}
                                value={name}
                              />
                              <SpError>
                                {simpleValidator.current.message('name', name, 'required')}
                              </SpError>
                            </>
                          ) : (
                            <SpH5>
                              {(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.name)
                                || ((inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.name) || <>&#8211;</>)}
                            </SpH5>
                          )
                        }
                      </Col>
                    </Row>
                  </SpForm> */}
                  {/* <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Phone number</span>
                      </Col>
                      <Col span={7}>
                        {
                          editDetails ? (
                            <div className="d-flex">
                              <div
                                style={{ width: '25%' }}
                                onKeyPress={e => countryCodeValidation(e)}
                              >
                                <Select
                                  className="w-100"
                                  value={countryCode}
                                  onChange={onCountryCodeChange}
                                >
                                  {COUNTRY_PHONE_CODE.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                              <div style={{ width: '80%' }}>
                                <Input
                                  className="InputnumFormate"
                                  placeholder="Phone Number"
                                  value={phoneFormate}
                                  onChange={onPhoneNumberChange}
                                  onBlur={e => {
                                    if (e.currentTarget.value) {
                                      setCustomerPhone(e.currentTarget.value);
                                      const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                                      const formater = value.substr(value.indexOf(' ') + 1);
                                      setPhoneFormate(formater);
                                    }
                                  }}
                                />
                                <SpError>
                                  {simpleValidator.current.message('phone number', customerPhone, `phoneNumValidation:${countryCode}${customerPhone}`)}
                                </SpError>
                              </div>
                            </div>
                          ) : (
                            <>
                              {
                                inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.phoneNumber ? (
                                  <NumberFormat
                                    className="numFormate"
                                    value={inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.phoneNumber}
                                    format={phoneFormate}
                                    mask="_"
                                    disabled
                                  />
                                ) : <>&#8211;</>
                              }
                            </>
                          )
                        }
                      </Col>
                    </Row>
                  </SpForm> */}
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Guest Count</span>
                      </Col>
                      <Col span={7}>
                        {
                          editDetails ? (
                            <>
                              <Input
                                placeholder="Guest Count"
                                onChange={(e) => setGuest(e.currentTarget.value)}
                                value={guest}
                              />
                              <SpError>
                                {simpleValidator.current.message('guest count', guest, 'required')}
                              </SpError>
                            </>
                          ) : (
                            <SpH5>
                              {(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.noOfGuest)
                                || <>&#8211;</>}
                            </SpH5>
                          )
                        }
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Date Time</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>
                          {
                            inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.createdAt && selectedAccount && selectedAccount.timezone
                              ? (
                                moment
                                  .utc(inventoryPrinterLabelDetails.createdAt)
                                  .tz(selectedAccount.timezone)
                                  .format('MMM DD, YYYY, hh:mm a')
                              )
                              : <>&#8211;</>
                          }
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Orders</span>
                      </Col>
                      <Col span={6}>
                        <SpH5 color="#3E8BFF">
                          {
                            inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.inventoryOrder
                              ? (
                                <div style={{ cursor: 'pointer' }} onClick={() => viewOrders(inventoryPrinterLabelDetails.inventoryOrder)}>
                                  View Orders
                                </div>
                              )
                              : <>&#8211;</>
                          }
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Link</span>
                      </Col>
                      <Col span={10}>
                        {/* eslint-disable-next-line max-len */}
                        {inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.shopTable && inventoryPrinterLabelDetails.shopTable.gid
                          ? (
                            <SpH5>{getLink(inventoryPrinterLabelDetails.shopTable.gid)}
                              <SpButton
                                type="secondary"
                                className="ml-2"
                                borderRadius="50px"
                                // eslint-disable-next-line
                                disabled={inventoryPrinterLabelDetails.status === 'RESERVED' ? false : inventoryPrinterLabelDetails.status === 'OCCUPIED' ? false : true}
                                onClick={() => { copyCode(getLink(inventoryPrinterLabelDetails.shopTable.gid) || ''); }}
                              >
                                <Clipboard />
                              </SpButton>
                            </SpH5>
                          )
                          : <>&#8211;</>}
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Status</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>
                          {
                            inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.status
                              ? getReservation(inventoryPrinterLabelDetails.status) : <>&#8211;</>
                          }
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Note</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>
                          {
                            inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.note
                              ? inventoryPrinterLabelDetails.note : <>&#8211;</>
                          }
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                </Col>
                <Col span={6} style={{ textAlign: 'center' }}>
                  <QRCode
                    size="256"
                    // eslint-disable-next-line max-len
                    value={getLink(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.shopTable && inventoryPrinterLabelDetails.shopTable.gid)}
                    logoImage={Qrlogo}
                    ref={qrCodeRef}
                    logoPadding={5}
                    removeQrCodeBehindLogo
                  />
                  <div className="mt-2">
                    <SpButton
                      type="secondary"
                      shape="round"
                      // eslint-disable-next-line
                      disabled={inventoryPrinterLabelDetails.status === 'RESERVED' ? false : inventoryPrinterLabelDetails.status === 'OCCUPIED' ? false : true}
                      onClick={() => downloadQRCode()}
                    >
                      <Icon type="download" />
                    </SpButton>
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col span={24} className="d-flex justify-content-end">
                  <SpH5 color="#7f7f7f">{inventoryPrinterLabelDetails.gid}</SpH5>
                </Col>
              </Row> */}
              <hr />
              <div
                style={{
                  background: '#F5F5F5',
                  padding: '15px',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={18}>
                    <Row type="flex">
                      <Col span={2} className="mr-4">
                        <SpText fontWeight="600" fontSize="14px" color="#333333">
                          GUEST #
                        </SpText>
                      </Col>
                      <Col span={6}>
                        <SpText fontWeight="600" fontSize="14px" color="#333333">
                          GUEST NAME
                        </SpText>
                      </Col>
                      <Col span={7}>
                        <SpText fontWeight="600" fontSize="14px" color="#333333">
                          PHONE
                        </SpText>
                      </Col>
                    </Row>
                  </Col>
                  <Col />
                </Row>
              </div>
              <div
                style={{
                  padding: '15px',
                  marginBottom: '10px',
                }}
              >
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={18}>
                    <Row type="flex">
                      <Col span={2} className="ml-2 mr-4">
                        <SpText fontWeight="400" fontSize="14px" color="#333333">
                          1
                        </SpText>
                      </Col>
                      <Col span={6} className="ml-2">
                        <SpText fontWeight="400" fontSize="14px" color="#333333">
                          {
                            editDetails ? (
                              <>
                                <Input
                                  placeholder="Name"
                                  onChange={(e) => setName(e.currentTarget.value)}
                                  value={name}
                                />
                                <SpError>
                                  {simpleValidator.current.message('name', name, 'required')}
                                </SpError>
                              </>
                            ) : (
                              <SpH5>
                                {(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.name)
                                  || ((inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.name) || <>&#8211;</>)}
                              </SpH5>
                            )
                          }
                        </SpText>
                      </Col>

                      {
                        editDetails ? (
                          <Col span={7}>
                            <SpText fontWeight="400" fontSize="14px" color="#333333">
                              <div className="d-flex ml-4">
                                <div
                                  style={{ width: '25%' }}
                                  onKeyPress={e => countryCodeValidation(e)}
                                >
                                  <Select
                                    className="w-100"
                                    value={countryCode}
                                    onChange={onCountryCodeChange}
                                  >
                                    {COUNTRY_PHONE_CODE.map((item) => (
                                      <Option key={item} value={item}>
                                        {item}
                                      </Option>
                                    ))}
                                  </Select>
                                </div>
                                <div style={{ width: '80%' }}>
                                  <Input
                                    className="InputnumFormate"
                                    placeholder="Phone Number"
                                    value={phoneFormate}
                                    onChange={onPhoneNumberChange}
                                    onBlur={e => {
                                      if (e.currentTarget.value) {
                                        setCustomerPhone(e.currentTarget.value);
                                        const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                                        const formater = value.substr(value.indexOf(' ') + 1);
                                        setPhoneFormate(formater);
                                      }
                                    }}
                                  />
                                  <SpError>
                                    {simpleValidator.current.message('phone number',
                                      customerPhone,
                                      `phoneNumValidation:${countryCode}${customerPhone}`)}
                                  </SpError>
                                </div>
                              </div>
                            </SpText>
                          </Col>
                        ) : (
                          <>
                            {
                              inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.phoneNumber ? (
                                <Col
                                  span={7}
                                  style={{
                                    marginLeft: '-15px',
                                  }}
                                >
                                  <SpText fontWeight="400" fontSize="14px" color="#333333">
                                    <NumberFormat
                                      className="numFormate"
                                      value={inventoryPrinterLabelDetails.customer && inventoryPrinterLabelDetails.customer.phoneNumber}
                                      format={phoneFormate}
                                      mask="_"
                                      disabled
                                    />
                                  </SpText>
                                </Col>
                              ) : <>&#8211;</>
                            }
                          </>
                        )
                      }
                    </Row>
                  </Col>
                  <Col />
                </Row>
              </div>
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  inventoryPrinterLabelDetails: state.inventoryReservationsDetails,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  profile: state.subscription.profile,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  paymentSlug: state.inventoryShopsDetails.shopsSlug,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryReservationsDetails: gid => dispatch(inventoryReservationsDetailsActions.fetchInventoryReservationsDetails({
    payload: gid,
  })),
  cancelInventoryReservations: param => dispatch(inventoryReservationsDetailsActions.cancelInventoryReservations({
    payload: param,
  })),
  holdReservations: gid => dispatch(inventoryReservationsActions.holdReservations({
    payload: gid,
  })),
  fetchInventorySortItems: param => dispatch(inventoryItemsActions.fetchInventorySortItems({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchShopsSlug: param => dispatch(inventoryShopsDetailsActions.fetchShopsSlug({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PrinterLabelDetails);
