// @flow
import React, { useRef, useState } from 'react';
import { Modal, Input } from 'antd';
import {
  SpButton,
  SpText,
  SpH5,
  SpError,
  SpStatusTag,
  SpTextMute,
} from 'components/DesignKit';

import {
  COUNTRIES,
} from 'appconstants';


import SimpleReactValidator from 'simple-react-validator';
import formatNumber from 'utils/formatNumber';
import getBackgroundColor from 'utils/getBackgroundColor';
import getPayoutStatus from 'utils/getPayoutStatus';
import moment from 'moment-timezone';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  currencyType: string,
  selectedPayout: Object,
  selectedAccount: Object,
};

const UpdatePayout = (props: Props) => {
  const {
    visible,
    close,
    submit,
    currencyType,
    selectedAccount,
    selectedPayout,
  } = props;

  const [, forceUpdate] = useState();
  const [referenceNumber, setReferenceNumber] = useState('');
  const [ref1, setRef1] = useState('');
  const [ref2, setRef2] = useState('');
  const [ref3, setRef3] = useState('');
  const [ref4, setRef4] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const gid = selectedPayout && selectedPayout.gid;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const labelText = isIndia ? 'UTR Number' : 'Confirmation Number';
  const refRgex = /^[a-zA-Z0-9-.,]*$/;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      validateRef: {
        message: 'Please enter a valid Reference Number',
        rule: val => refRgex.test(val),
      },
    },
  }));

  const updatePayout = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      referenceNumber,
      ref1,
      ref2,
      ref3,
      ref4,
      gid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={updatePayout} disabled={disableButton} type="primary">Confirm</SpButton>,
      ]}
      title="Confirm Payout"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to change the status to Paid?</div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>{labelText}</SpH5></label>
          <Input
            placeholder={labelText}
            value={referenceNumber}
            onChange={(e) => setReferenceNumber(e.currentTarget.value)}
          />
          {
            isIndia ? (
              <SpError>
                {simpleValidator.current.message('utr number', referenceNumber, 'required|alpha_num')}
              </SpError>
            ) : (
              <SpError>
                {simpleValidator.current.message('Confirmation Number', referenceNumber, 'required|alpha_num')}
              </SpError>
            )
          }

        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Ref#1</SpH5></label>
          <Input
            placeholder="Reference Number"
            value={ref1}
            onChange={(e) => setRef1(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('reference Number1', ref1, 'validateRef')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Ref#2</SpH5></label>
          <Input
            placeholder="Reference Number"
            value={ref2}
            onChange={(e) => setRef2(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('reference Number2', ref2, 'validateRef')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Ref#3</SpH5></label>
          <Input
            placeholder="Reference Number"
            value={ref3}
            onChange={(e) => setRef3(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('reference Number3', ref3, 'validateRef')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Ref#4</SpH5></label>
          <Input
            placeholder="Reference Number"
            value={ref4}
            onChange={(e) => setRef4(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('reference Number4', ref4, 'validateRef')}
          </SpError>
        </div>
        {
          selectedPayout && selectedPayout.gid && (
            <>
              <div className="mt-2 mb-2">
                <SpText className="mr-3" fontSize="20px" fontWeight="600">
                  {selectedPayout.amount < 0
                    ? `(${selectedPayout.currencyDto.prefix} 
          ${formatNumber((selectedPayout.amount / 100).toFixed(selectedPayout.currencyDto.toFixed).replace(/-/g, ''), currencyType)})`
                    : `${selectedPayout.currencyDto.prefix} 
          ${formatNumber((selectedPayout.amount / 100).toFixed(selectedPayout.currencyDto.toFixed), currencyType)}`}
                </SpText>
                <SpStatusTag style={{ backgroundColor: getBackgroundColor(selectedPayout.status) }}>
                  {getPayoutStatus(selectedPayout.status)}
                </SpStatusTag>
              </div>
              <div className="mt-2 mb-2">
                <SpTextMute>
                  {
                    selectedPayout.payoutDate
                      ? `${moment.utc(selectedPayout.payoutDate).tz(selectedAccount && selectedAccount.timezone).format('MMM DD, YYYY')}`
                      : <>&#8211;</>
                  }
                </SpTextMute>
              </div>
              <div className="mt-2">
                <SpTextMute>
                  {selectedPayout.bankName} ****{selectedPayout.bankAccountLastFour}
                </SpTextMute>
              </div>
            </>
          )
        }
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default UpdatePayout;
