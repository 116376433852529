import { PAYMENTCHECKOUT } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  paymentCheckoutStatus: undefined,
  paymentCheckoutError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENTCHECKOUT.FETCH:
      return {
        ...state,
        paymentCheckoutStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case PAYMENTCHECKOUT.SUCCESS:
      return {
        ...state,
        ...action.payload,
        paymentCheckoutStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case PAYMENTCHECKOUT.FAILED:
      return {
        ...state,
        paymentCheckoutError: action.payload,
        paymentCheckoutStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
