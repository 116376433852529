// @flow
import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  Input,
  AutoComplete,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getVendorType from 'utils/getVendorType';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import getSortedList from 'utils/getSortedList';
import getStateList from 'utils/stateList';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import { SpH5, SpError } from 'components/DesignKit';
import {
  COUNTRY_PHONE_CODE,
  COUNTRY_CODES,
} from 'appconstants';

const { Option } = Select;

type Props = {
  submitting: boolean,
  selectedAccount: Array<Object>,
  contactDetailsData: Object,
  fetchContactDetails: Function,
  country: String,
};

const ContactDetails = (props: Props, ref) => {
  const {
    submitting,
    contactDetailsData,
    selectedAccount,
    fetchContactDetails,
    country,
  } = props;
  const [, forceUpdate] = useState();
  const [contactName, setContactName] = useState(contactDetailsData.contactName);
  const [email, setEmail] = useState(contactDetailsData.email);
  const [contactGid, setContactGid] = useState(contactDetailsData.contactGid);
  const [contactPhone, setContactPhone] = useState(contactDetailsData.contactPhone);
  const [customType, setCustomType] = useState(contactDetailsData.customType || '');
  // eslint-disable-next-line
  const [customSuggestions, setCustomSuggestions] = useState(contactDetailsData.customSuggestions || []);
  // eslint-disable-next-line max-len
  const [phoneNumberCode, setPhoneCode] = useState(contactDetailsData.phoneNumberCode ? contactDetailsData.phoneNumberCode : getCountryPhoneCode((selectedAccount && selectedAccount.country && selectedAccount.country.id)));
  const [phoneFormate, setPhoneFormate] = useState(contactDetailsData.phoneFormate);
  const [contactType, setContactType] = useState(contactDetailsData.contactType || 'CUSTOMER');
  const [contactTypeName, setContactTypeName] = useState(contactDetailsData.contactTypeName || 'Customer');
  const contacts = contactDetailsData.contactResult;
  const [disableContact, setDisableContact] = useState(contactDetailsData.disableContact);
  const [postalCodeRegex, setPostalCodeRegex] = useState(country === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/);
  const nameRegex = /^[ ]*([a-zA-Z'-.]{1,}( [a-zA-z'-. ]{1,})?)$/;
  const [streetName, setStreetName] = useState(contactDetailsData.streetName || '');
  const [cityName, setCityName] = useState(contactDetailsData.cityName || '');
  const [stateName, setStateName] = useState(contactDetailsData.stateName || '');
  const [zipCode, setZipCode] = useState(contactDetailsData.zipCode || '');
  // eslint-disable-next-line max-len
  const [countryName, setCountryName] = useState(contactDetailsData.countryCode ? (contactDetailsData.countryCode === 'IN' ? 'INDIA' : 'USA') : (country === 'India' ? 'INDIA' : 'USA'));
  const [stateList, setSateList] = useState(getStateList(selectedAccount.country.id));
  const [zipTag, setZipTag] = useState('');
  // eslint-disable-next-line max-len
  const [countryCode, setCountryCode] = useState(contactDetailsData.countryCode ? contactDetailsData.countryCode : getCountryAlpha2(selectedAccount.country.id));
  const [location, setLocation] = useState();
  const GOOGLE_MAPS_API_KEY = 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds';

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (country && country === 'India') {
      setCountryName('INDIA');
    } else if (country && country === 'United States of America') {
      setCountryName('USA');
    }
  }, []);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      name: {
        message: validators.bussiness.validContactName,
        rule: val => (nameRegex.test(val)),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      contactName,
      contactGid,
      email,
      phoneNumberCode,
      contactPhone,
      phoneFormate,
      contactType,
      customType,
      customSuggestions,
      contactTypeName,
      disableContact,
      countryCode,
      streetName,
      cityName,
      stateName,
      zipCode,
    }),
  }));

  const searchContact = (value) => {
    const name = value && value.trim();
    fetchContactDetails(name);
    setContactName(name);
    const results = contacts.filter((item) => (item.name === name));
    if (results.length === 0) {
      setDisableContact(false);
      setContactGid('');
    }
  };

  useEffect(() => {
    simpleValidator.current.purgeFields();
  }, [disableContact]);

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setContactPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    setPhoneCode(value);
    const data = formatNumber(`${value}${contactPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const updateContact = (value) => {
    const results = contacts.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      console.log(results[0], 'resultsresultsresultsresultsresults');
      setEmail(results[0].email);
      setContactName(results[0].name);
      setContactGid(results[0].gid);
      setContactType(results[0].contactType);
      setCustomType(results[0].customType);
      setContactTypeName(getVendorType(results[0].contactType));
      setDisableContact(true);
      if (results[0].address) {
        setStateName(results[0].address.state);
        setStreetName(results[0].address.street);
        setCityName(results[0].address.city);
        setZipCode(results[0].address.postalCode);
      }
      if (results[0].phoneNumber) {
        const phoneResult = results[0].phoneNumber ? findPhoneNumbersInText(results[0].phoneNumber) : null;
        if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
          setPhoneCode(`+${phoneResult[0].number.countryCallingCode}`);
          setContactPhone(phoneResult[0].number.nationalNumber);
          const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
          const formater = data.substr(data.indexOf(' ') + 1);
          setPhoneFormate(formater);
        }
      }
    }
  };

  const updateCountry = (value) => {
    setCountryCode(value);
    if (value === 'IN') {
      setSateList(getStateList(2));
      setZipTag('Pin code');
      setPostalCodeRegex(/^[1-9][0-9]{5}$/);
    } else if (value === 'US') {
      setZipTag('Zip code');
      setSateList(getStateList(1));
      setPostalCodeRegex(/^[0-9][0-9]{4}$/);
    } else if (value === 'CA') {
      setZipTag('Zip code');
      setSateList(getStateList(value));
      setPostalCodeRegex(/^[0-9][0-9]{4}$/);
    }
  };

  const updateBillingAddress = (address) => {
    setLocation(address);
    if (address.value && address.value.terms && address.value.terms.length >= 2) {
      if (
        (address.value.terms[address.value.terms.length - 1].value === 'USA')
        || (address.value.terms[address.value.terms.length - 1].value === 'India')
      ) {
        setStreetName(address.value.structured_formatting.main_text);
        setCityName(address.value.terms[address.value.terms.length - 3]?.value);
        if (address.value.terms[address.value.terms.length - 1].value === 'India') {
          setCountryCode('IN');
          setCountryName('INDIA');
          setSateList(getStateList(2));
          const statesInIndia = getStateList(2);
          const slectedState = statesInIndia.filter(state => state.name === address.value.terms[address.value.terms.length - 2].value);
          if (slectedState && slectedState.length > 0) {
            setStateName(slectedState[0].id);
          }
          if (slectedState && slectedState.length === 0) {
            const slectedState1 = statesInIndia.filter(state => state.name === address.value.terms[address.value.terms.length - 3].value);
            if (slectedState1 && slectedState1.length > 0) {
              setStateName(slectedState1[0].id);
            }
          }
          setPostalCodeRegex(/^[1-9][0-9]{5}$/);
        }
        if (address.value.terms[address.value.terms.length - 1].value === 'USA') {
          setCountryCode('US');
          setCountryName('USA');
          const statesInUs = getStateList(1);
          const stateLength = address.value.terms[address.value.terms.length - 2].value.length;
          const slectedState = statesInUs.filter(state => (stateLength === 2
            ? state.id === address.value.terms[address.value.terms.length - 2].value
            : state.name === address.value.terms[address.value.terms.length - 2].value));
          if (slectedState && slectedState.length > 0) {
            setStateName(slectedState[0].id);
          }
          if (slectedState && slectedState.length === 0) {
            const slectedState1 = statesInUs.filter(state => state.name === address.value.terms[address.value.terms.length - 3]?.value);
            if (slectedState1 && slectedState1.length > 0) {
              setStateName(slectedState1[0].id);
            }
          }
          setPostalCodeRegex(/^[1-9][0-9]{4}$/);
          setSateList(getStateList(1));
        }
      }
    }
  };

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2 mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <AutoComplete
            placeholder="Name"
            showSearch
            className="w-100"
            dataSource={getSortedList(contacts).map((item, id) => (
              <Option key={id} value={item.gid}>
                {item.name}
              </Option>
            ))}
            style={{ height: '40px' }}
            onSearch={searchContact}
            defaultValue={contactName}
            onSelect={e => updateContact(e)}
          />
          <SpError>
            {simpleValidator.current.message('name', contactName, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            placeholder="Email"
            value={email}
            disabled={disableContact}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('email', email, 'required|email|checkLowerCase')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                value={phoneNumberCode}
                onChange={onCountryCodeChange}
                disabled={disableContact}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormate}
                disabled={disableContact}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setContactPhone(e.currentTarget.value);
                    const value = formatNumber(`${phoneNumberCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormate(formater);
                  }
                }}
              />
              {
                !disableContact && (
                  <SpError>
                    {simpleValidator.current.message('phone number', contactPhone, `required|phoneNumValidation:${phoneNumberCode}${contactPhone}`)}
                  </SpError>
                )
              }
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Contact Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            value={contactTypeName}
            disabled={disableContact}
            onChange={(value) => {
              setContactType(value[0]);
              setContactTypeName(value[1]);
            }}
          >
            <Option key={1} value={['CUSTOMER', 'Customer']}>Customer</Option>
            <Option key={2} value={['EMPLOYEE', 'Employee']}>Employee</Option>
            <Option key={3} value={['VENDOR', 'Vendor']}>Vendor</Option>
            <Option key={4} value={['SELF', 'Self']}>Self</Option>
            <Option key={5} value={['OTHER', 'Other']}>Other</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('contact Type', contactType, 'required')}
          </SpError>
        </div>
        {contactType && contactType === 'OTHER' && (
          <div className="mb-3">
            <label htmlFor="">
              <SpH5>Custom Type</SpH5>
            </label>
            {customSuggestions.length > 0 ? (
              <>
                <Select
                  showSearch
                  placeholder="(Optional)"
                  className="w-100 mb-2"
                  value={customType}
                  onChange={(value) => setCustomType(value)}
                  onSearch={(value) => setCustomType(value)} // Handle typing a custom type
                  mode="combobox" // Allows users to input a value not in the list
                >
                  {customSuggestions.map((word, index) => (
                    <Select.Option key={index} value={word}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{word}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </>
            ) : (
              <Input
                placeholder="(Optional)"
                value={customType || ''}
                onChange={(e) => setCustomType(e.target.value)}
              />
            )}
          </div>
        )}
        <div className="mb-3">
          <label htmlFor=""><SpH5>Street Address</SpH5></label>
          <div>
            <GooglePlacesAutocomplete
              apiKey={GOOGLE_MAPS_API_KEY}
              selectProps={{
                location,
                onChange: updateBillingAddress,
                defaultValue: streetName,
                placeholder: 'Enter an address',
              }}
              defaultValue={streetName}
              inputStyle={{
                width: '100%',
                height: '32px',
                padding: '4px 11px',
                color: '#272727',
                fontSize: '14px',
                lineHeight: 1.5,
                backgroundColor: '#fff',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
              }}
            />
          </div>
          {/* <Input
            placeholder="Street Address"
            value={streetName}
            disabled={disableContact}
            onChange={(e) => setStreetName(e.currentTarget.value)}
          /> */}
          <SpError>
            {simpleValidator.current.message('street', streetName, 'required')}
          </SpError>
        </div>
        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName}
                disabled={disableContact}
                onChange={(e) => setCityName(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('city', cityName, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                className="w-100"
                placeholder="State"
                showSearch
                optionFilterProp="children"
                disabled={disableContact}
                onChange={(e) => setStateName(e)}
                value={stateName}
              >
                {
                  stateList.map(s => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-capitalize"
                    >
                      {s.name}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('state', stateName, 'required')}
              </SpError>
            </div>
          </div>
        </div>
        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipTag}</SpH5></label>
              <Input
                placeholder={zipTag}
                value={zipCode}
                disabled={disableContact}
                onChange={(e) => setZipCode(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message(zipTag, zipCode, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Country</SpH5></label>
              <Select
                value={countryName}
                disabled={disableContact}
                style={{ width: '100%' }}
                onChange={updateCountry}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ALPHA2}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(ContactDetails);
