// @flow
import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Tooltip,
  Row,
  Col,
  Icon,
} from 'antd';
import {
  SpButton,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';

import {
  ROLES,
} from 'appconstants';
import {
  customdashboardActions,
  notificationActions,
  hoursMasterActions,
} from 'store/actions';
import RBAC from 'components/rbac';
import AddDashboard from './components/AddOperatingHours';
import UpdateDashboard from './components/EditOperatingHours';
import DeleteHours from './components/DeleteOperatingHours';

type Props = {
  fetchHoursOfOperations: Function,
  hoursOfOperations: Array<Object>,
  loading: boolean,
  test: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  addHoursOfOperations: Function,
  validator: boolean,
  updateHoursOfOperations: Function,
  deleteHoursOfOperations: Function,
};

const OperatingHours = (props: Props) => {
  const [pagination, setPagination] = useState({});
  const location = useLocation();
  const {
    loading,
    fetchHoursOfOperations,
    totalElements,
    hoursOfOperations,
    test,
    history,
    selectedAccount,
    addHoursOfOperations,
    validator,
    updateHoursOfOperations,
    deleteHoursOfOperations,
  } = props;
  const [addDashboardModal, setAddDashboardModal] = useState(false);
  const [updateDashboardModal, setUpdateDashboardModal] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState({});
  const { internalRole } = useSelector(state => state.user);
  const [deleteHoursModal, setDeleteHoursModal] = useState(false);

  const debouncedFetchHoursOfOperations = debounce(fetchHoursOfOperations, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    debouncedFetchHoursOfOperations({ currentPage, sortParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [test, selectedAccount, location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [hoursOfOperations]);

  useEffect(() => {
    if (!validator) {
      setAddDashboardModal(false);
      setUpdateDashboardModal(false);
      setDeleteHoursModal(false);
    }
  }, [validator]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: '/operatinghours',
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const addNewDashboard = (referral: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      name,
      description,
      operatingHours,
    } = referral;
    const params = {
      name,
      description,
      hoursOfOperations: operatingHours,
    };
    addHoursOfOperations({
      params,
      currentPage,
      sortParams,
    });
  };

  const showEditDashboard = (value: Object) => {
    setSelectedDashboard(value);
    setUpdateDashboardModal(true);
  };

  const getOperatingTime = (time) => {
    console.log(time, 'time');
    if (time !== 0) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours > 12) {
        const hr = hours - 12;
        return `${hr}:${min} PM`;
      }
      if (hours === 12) {
        return '12:00 PM';
      }
      if (hours === 0) {
        return `${12}:${min} AM`;
      }
      return `${hours}:${min} AM`;
    }
    return '12:00 AM';
  };

  const updateSelectedCustomDashboard = (record: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      gid,
      name,
      description,
      operatingHours,
    } = record;
    const params = {
      name,
      description,
      hoursOfOperations: operatingHours,
    };
    updateHoursOfOperations({
      params,
      gid,
      sortParams,
      currentPage,
    });
  };

  const deleteSelectedMenu = (selectedItem: String) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'sortOrder';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    deleteHoursOfOperations(
      {
        gid: selectedItem,
        currentPage,
        sortParams,
      },
    );
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <span>
          {name || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'OPERATING HOURS',
      dataIndex: 'hours',
      width: '50%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <>
          {
               record && record.hoursOfOperations && record.hoursOfOperations.length !== 0 ? (
                 <>
                   {
                    record.hoursOfOperations.map((item) => (
                      <div className="d-flex">
                        {item.dayOfWeek || <>&#8211;</>} : <>&nbsp;</>
                        <>
                          {
                            item.operatingHours && item.operatingHours.length !== 0 ? (
                              <>
                                {
                                    item.operatingHours.map((items) => (
                                      <>
                                        {
                                          items.open ? (
                                            <div>
                                              <span>{getOperatingTime(items.openingTime)}</span>
                                              <span>&nbsp;To&nbsp;</span>
                                              <span>{getOperatingTime(items.closingTime)}&nbsp;,</span>
                                            </div>
                                          ) : (
                                            <span>Closed</span>
                                          )
                                        }

                                      </>
                                    ))
                                }
                              </>
                            ) : (
                              <span>Closed</span>
                            )
                        }
                        </>
                      </div>
                    ))
                 }
                 </>
               ) : (
                 <>&#8211;</>
               )
            }
        </>
      ),
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '25%',
    align: 'center',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showEditDashboard(record); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            className="ml-3 mr-3"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); setSelectedDashboard(record); setDeleteHoursModal(true); }}
          />
        </Tooltip>
      </span>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <Row className="my-2">
          <div className="d-flex justify-content-end">
            <RBAC
              permit={
                [ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.OWNER, ROLES.ADMINISTRATOR]
              }
            >
              <Col>
                <SpButton
                  type="secondary"
                  shape="round"
                  className="mr-3"
                  ghost
                  onClick={() => setAddDashboardModal(true)}
                >
                  {'\u002B'}&nbsp;Add Operating Hours
                </SpButton>
              </Col>
            </RBAC>
          </div>
        </Row>

        {/* ADD DASHBOARD MODAL */}
        {addDashboardModal && (
          <AddDashboard
            visible={addDashboardModal}
            close={() => setAddDashboardModal(false)}
            submit={addNewDashboard}
          />
        )}

        {/* UPDATE DASHBOARD MODAL */}
        {updateDashboardModal && (
          <UpdateDashboard
            visible={updateDashboardModal}
            selectedDashboard={selectedDashboard}
            close={() => setUpdateDashboardModal(false)}
            submit={updateSelectedCustomDashboard}
          />
        )}

        {deleteHoursModal && (
        <DeleteHours
          visible={deleteHoursModal}
          selectedAccount={selectedAccount}
          close={() => setDeleteHoursModal(false)}
          submit={deleteSelectedMenu}
          selectedDashboard={selectedDashboard}
        />
        )}

        <Table
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={hoursOfOperations}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Card>
    </>

  );
};

const mapStateToProps = (state) => ({
  hoursOfOperations: state.hoursOfOperations.content,
  loading: state.loading.loading,
  totalElements: state.hoursOfOperations.totalElements,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHoursOfOperations: param => dispatch(hoursMasterActions.fetchHoursOfOperations({
    payload: param,
  })),
  addHoursOfOperations: param => dispatch(hoursMasterActions.addHoursOfOperations({
    payload: param,
  })),
  updateHoursOfOperations: param => dispatch(hoursMasterActions.updateHoursOfOperations({
    payload: param,
  })),
  deleteHoursOfOperations: param => dispatch(hoursMasterActions.deleteHoursOfOperations({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OperatingHours);
