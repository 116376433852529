import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  USONBOARDING,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchOnboardingLinks = async () => axiosAuthInstance.get(`${API_END_POINTS.USONBOARDING}`);

const postOnboardingLink = async payload => axiosAuthInstance.post(API_END_POINTS.USONBOARDING, payload);

export function* getOnboardingLinks(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchOnboardingLinks, action.payload);
    yield put({ type: USONBOARDING.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: USONBOARDING.FAILED, payload: e.response.data });
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addOnboardingLink(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(postOnboardingLink, action.payload);
    const { data: { entity } } = yield call(fetchOnboardingLinks);
    yield put({ type: USONBOARDING.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.USONBOARDING.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
