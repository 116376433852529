import { ACQUIRER } from '../actionTypes';

export const fetchAcquirer = (action) => ({
  type: ACQUIRER.FETCH,
  payload: action.payload,
});

export const addAcquirer = (action) => ({
  type: ACQUIRER.ADD,
  payload: action.payload,
});

export const updateAcquirer = (action) => ({
  type: ACQUIRER.UPDATE,
  payload: action.payload,
});

export const deleteAcquirer = (action) => ({
  type: ACQUIRER.DELETE,
  payload: action.payload,
});

export const fetchAcquirerSearch = (action) => ({
  type: ACQUIRER.SEARCH_FETCH,
  payload: action.payload,
});
