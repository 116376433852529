import { REMINDERS } from '../actionTypes';

const initialState = {
  remindersStatus: undefined,
  remindersError: undefined,
  content: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMINDERS.FETCH:
      return {
        ...state,
        remindersStatus: 'STARTED',
      };
    case REMINDERS.FAILED:
      return {
        ...state,
        remindersError: 'FAILED',
      };
    case REMINDERS.SUCCESS:
      return {
        ...state,
        content: [...action.payload],
      };
    default:
      return state;
  }
};
