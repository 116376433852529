import { PLANS } from '../actionTypes';

export const fetchPlans = (action) => ({
  type: PLANS.FETCH,
  payload: action.payload,
});

export const addPlans = (action) => ({
  type: PLANS.ADD,
  payload: action.payload,
});
