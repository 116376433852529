import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance2,
  axiosNoAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  PAYMENTS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchPayments = async (payload) => {
  let parm = '';
  const disputeParam = 'status.IN=DISPUTED_NEEDS_RESPONSE&status.IN=DISPUTE_UNDER_REVIEW&status.IN=DISPUTE_WON&status.IN=DISPUTE_LOST';
  const queryParam = payload.payload.searchParam;
  if (queryParam && queryParam !== 'REFUNDED' && queryParam !== 'ALL_PAYMENTS') {
    parm = `status.IN=${payload.payload.searchParam}`;
  } else if (queryParam && queryParam === 'ALL_PAYMENTS') {
    parm = '';
  } else if (queryParam) {
    parm = 'status.IN=REFUNDED&status.IN=PARTIAL_REFUNDED';
  }
  const query = generateQuery(payload.payload.currentPage);
  const sorting = payload.payload.sortParams ? payload.payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (parm && !payload.payload.filterParams) {
    return axiosAuthInstance2.get(`${API_END_POINTS.GET_PAYMENTS}${query}&${parm}${sorting}`);
  }
  if (payload.payload.filterParams && !parm && !payload.payload.sortParams) {
    if (payload.payload.filterParams === 'status.EQ=DISPUTED') {
      return axiosAuthInstance2.get(`${API_END_POINTS.GET_PAYMENTS}${query}&${disputeParam}`);
    }
    return axiosAuthInstance2.get(`${API_END_POINTS.GET_PAYMENTS}${query}&${payload.payload.filterParams}`);
  }
  if (payload.payload.filterParams && parm && payload.payload.sortParams) {
    if (payload.payload.filterParams === 'status.EQ=DISPUTED') {
      return axiosAuthInstance2.get(`${API_END_POINTS.GET_PAYMENTS}${query}&${disputeParam}`);
    }
    return axiosAuthInstance2.get(`${API_END_POINTS.GET_PAYMENTS}${query}&${parm}&${payload.payload.filterParams}${sorting}`);
  }
  if (payload.payload.filterParams && !parm && payload.payload.sortParams) {
    if (payload.payload.filterParams === 'status.EQ=DISPUTED') {
      return axiosAuthInstance2.get(`${API_END_POINTS.GET_PAYMENTS}${query}&${disputeParam}`);
    }
    return axiosAuthInstance2.get(`${API_END_POINTS.GET_PAYMENTS}${query}&${payload.payload.filterParams}${sorting}`);
  }
  return axiosAuthInstance2.get(`${API_END_POINTS.GET_PAYMENTS}${query}${sorting}`);
};

const postPayments = async (data) => axiosAuthInstance2.post(`${API_END_POINTS.GET_PAYMENTS}/${data.id}/refund`, {
  refund: data.amount,
});

const patchPayment = async data => axiosNoAuthInstance.post(API_END_POINTS.UPDATE_TRANSACTION, data.payload, data.config);

export function* getPayments(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    const { data: { entity } } = yield call(fetchPayments, action.payload);
    yield put({ type: PAYMENTS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: PAYMENTS.FAILED, payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* refundPayments(action) {
  try {
    yield put({ type: LOADING.SET });
    yield call(postPayments, action.payload);
    yield put({ type: LOADING.SUCCESS, payload: MESSAGES.REFUND.SUCCESS });
  } catch (e) {
    yield put({ type: PAYMENTS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updatePayment(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchPayment, action.payload);
    const { data: { entity } } = yield call(fetchPayments, action.payload);
    yield put({ type: PAYMENTS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
