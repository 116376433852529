import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  CLOVER_SYNC,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchCloverSync = async () => axiosAuthInstance.get(API_END_POINTS.CLOVER_SYNC);

const postCloverSync = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.CLOVER_SYNC, params);

const patchCloverSync = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.CLOVER_SYNC}/${gid}`, params);

export function* getCloverSync(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchCloverSync, action.payload);
    yield put({ type: CLOVER_SYNC.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: CLOVER_SYNC.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addCloverSync(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postCloverSync, action.payload);
    const { data: { entity } } = yield call(fetchCloverSync, action.payload);
    yield put({ type: CLOVER_SYNC.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Clover sync added succesfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateCloverSync(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCloverSync, action.payload);
    const { data: { entity } } = yield call(fetchCloverSync, action.payload);
    yield put({ type: CLOVER_SYNC.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Clover sync updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
