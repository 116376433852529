// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  selectedUser: Object,
};

const DeleteUser = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedUser,
  } = props;

  const { user } = selectedUser;

  const validate = (event) => {
    event.preventDefault();
    submit(selectedUser && selectedUser.id);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} type="danger">Delete</SpButton>,
      ]}
      title="Delete Internal User"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to delete this user?</div>
        <SpH5>
          <div>
            {user && user.givenName} {user && user.familyName}
          </div>
        </SpH5>
      </div>
    </Modal>
  );
};

export default DeleteUser;
