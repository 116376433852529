import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  LabelText, SpanText, Text, Textarea,
} from '../../../design';
import { normalTextFontSize, smallTextFontSize, textAreaHeight } from '../../../utils/reseponsive';

type Props = {
  label: string,
  description: string,
  control: any,
  errors: any,
  name: string,
  isRequired: boolean,
  isPreview: boolean,
  isAdaptive: boolean
}

const InputLargeText = (props: Props) => {
  const {
    label, description, control, errors, name, isRequired, isPreview, isAdaptive,
  } = props;
  const [charactersLength, setCharactersLength] = useState(255);
  return (
    <>
      <div className="form-group row mb-0 no-gutters align-items-center">
        <div className="col-sm-5">
          <LabelText
            fontSize={normalTextFontSize(isPreview, isAdaptive)}
            color="#0000000"
            hidden={!label}
            htmlFor="alpha"
            className="col-form-label font-weight-bolder m-0 text-break"
          >{label}
            <SpanText
              className="pl-2"
              hidden={isRequired}
              color="rgba(0, 0, 0, 0.6)"
              fontSize={smallTextFontSize(isPreview, isAdaptive)}
            >(Optional)
            </SpanText>
          </LabelText>
        </div>
        <div className="col-sm-7">
          <Controller
            defaultValue=""
            border="1px solid #29B1CC !important"
            control={control}
            name={name}
            rules={{
              required: isRequired && 'This field is required',
            }}
            as={(
              <Textarea
                type="text"
                onInput={({ target }) => setCharactersLength(255 - target.value.length)}
                maxLength={255}
                width="100%"
                fontSize={smallTextFontSize(isPreview, isAdaptive)}
                height={textAreaHeight(24, isPreview, isAdaptive)}
                className="bg-white mb-0"
                rows="4"
              />
          )}
          />
          <SpanText className="text-mute mb-1" fontSize={smallTextFontSize(isPreview, isAdaptive)}>
            Maximum 255 Characters - Characters left {charactersLength}
          </SpanText>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text fontSize={smallTextFontSize(isPreview, isAdaptive)} marginTop="0" marginBottom="0px" color="#ff1e1e">{message}</Text>
            )}
          />
        </div>
      </div>
      <div hidden={!description} className="col-sm-10 px-0">
        <LabelText className="text-mute mt-1" fontSize={smallTextFontSize(isPreview, isAdaptive)}>{description}</LabelText>
      </div>
    </>
  );
};

export default InputLargeText;
