import { INVENTORY_RESERVATIONS } from '../actionTypes';

const initialState = {
  content: [],
  supportTypes: {},
  searchTypes: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_RESERVATIONS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case INVENTORY_RESERVATIONS.RESERVATIONS_FILTER_SUCCESS:
      return {
        ...state,
        supportTypes: action.payload,
      };
    case INVENTORY_RESERVATIONS.FETCH_SEARCH_RESERVATIONS:
      return {
        ...state,
        searchTypes: action.payload,
      };
    default:
      return state;
  }
};
