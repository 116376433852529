import { SERVICEDETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  serviceTimeLine: [],
  fieldAgent: [],
  serviceDetailsStatus: undefined,
  serviceDetailsError: undefined,
  serviceDetail: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICEDETAILS.FETCH:
      return {
        ...state,
        serviceDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case SERVICEDETAILS.SUCCESS:
      return {
        ...state,
        serviceDetail: action.payload,
        serviceDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case SERVICEDETAILS.SUCCESS_TIMELINE:
      return {
        ...state,
        serviceTimeLine: action.payload,
        serviceDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case SERVICEDETAILS.FIELDAGENT_SUCCESS:
      return {
        ...state,
        fieldAgent: action.payload,
        serviceDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case SERVICEDETAILS.FAILED:
      return {
        ...state,
        serviceDetailsError: action.payload,
        serviceDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
