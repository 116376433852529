import { TERMINALDETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  payments: {},
  terminalDetailsStatus: undefined,
  terminalDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TERMINALDETAILS.FETCH:
      return {
        ...state,
        terminalDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case TERMINALDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        terminalDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case TERMINALDETAILS.FAILED:
      return {
        ...state,
        terminalDetailsError: action.payload,
        terminalDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case TERMINALDETAILS.TERMINALS_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
      };
    default:
      return state;
  }
};
