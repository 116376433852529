import React from 'react';
import {
  Card,
  Icon,
} from 'antd';

const DashboardErr = () => (
  <Card
    className="mx-auto mt-5"
    style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
    bodyStyle={{ height: '100%' }}
  >
    <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
      <Icon
        type="exclamation-circle"
        className="mb-4"
        style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
      />
      <h5 style={{ textAlign: 'center' }}>
        A request for a custom dashboard was made for this
        account and it will be available shortly. Please, use the payments menu options till then.
      </h5>
    </div>
  </Card>
);

export default DashboardErr;
