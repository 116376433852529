// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';

import {
  PAGES,
} from 'appconstants';
import {
  SpButton,
} from 'components/DesignKit';

import { reportTemplatesActions } from 'store/actions';
import moment from 'moment-timezone';

import ExportIcon from 'components/NavIcons/exportIcon';
import AddTemplate from './components/AddTemplate';

type Props = {
  fetchReportTemplate: Function,
  reportTemplates: Array<Object>,
  loading: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  submitting: boolean,
  addReportTemplate: Function,
  validator: boolean,
  isMobileView: Boolean,
};

const CustomReportTemplate = (props: Props) => {
  const {
    loading,
    fetchReportTemplate,
    reportTemplates,
    totalElements,
    history,
    selectedAccount,
    submitting,
    addReportTemplate,
    validator,
    isMobileView,
  } = props;
  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const location = useLocation();
  const [fillColor, setFillColor] = useState(false);
  const [pagination, setPagination] = useState({});
  const [addReportTemplateModal, setAddReportTemplateModal] = useState(false);

  const exportData = reportTemplates ? reportTemplates.map((values) => ({
    name: values.templateName || '',
    createdAt: values && values.createdAt ? moment
      .utc(values && values.createdAt)
      .tz(selectedAccount && selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a') : '',
  })) : '';

  const exportHeaders = [
    { label: 'TEMPLATE NAME', key: 'name' },
    { label: 'CREATED ON', key: 'createdAt' },
  ];

  const debouncedfetchReportTemplate = debounce(fetchReportTemplate, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    debouncedfetchReportTemplate({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [selectedAccount, location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [reportTemplates]);

  useEffect(() => {
    if (!validator) {
      setAddReportTemplateModal(false);
    }
  }, [validator]);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.CUSTOMREPORT_TEMPLATE,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.CUSTOMREPORT_TEMPLATE,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const addNewReportTemplate = (template: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;

    const {
      name,
      query,
      param1,
      param2,
      param3,
    } = template;
    const encodeQuery = btoa(query);
    let param1Label;
    let param2Label;
    let param3Label;
    if (param1) {
      param1Label = param1;
    }
    if (param1 && param2) {
      param2Label = param2;
    }
    if (param1 && param2 && param2) {
      param3Label = param3;
    }
    const params = {
      templateName: name,
      query: encodeQuery,
      param1Label,
      param2Label,
      param3Label,
    };

    addReportTemplate({
      params,
      currentPage,
      sortParams,
    });
  };

  const selectedRecord = (record) => {
    const reportTemplatesGid = record.gid;
    history.push(`${PAGES.CUSTOMREPORT_TEMPLATE}/${reportTemplatesGid}`);
  };

  const columns = [
    {
      title: 'TEMPLATE NAME',
      dataIndex: 'templateName',
      width: '40%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (templateName) => (
        <span style={{ cursor: 'pointer' }}>
          {templateName || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <Row className="my-2">
          <div className="d-flex justify-content-end">
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                ghost
                className="mr-3"
                onClick={() => setAddReportTemplateModal(true)}
              >
                {'\u002B'}&nbsp;New Template
              </SpButton>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Report Template">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Report Template.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
            </Col>
          </div>
        </Row>

        {/* ADD TEMPLATE MODAL */}
        {addReportTemplateModal && (
          <AddTemplate
            visible={addReportTemplateModal}
            submitting={submitting}
            selectedAccount={selectedAccount}
            country={selectedAccount.country.name}
            cntryId={selectedAccount.country.id}
            close={() => setAddReportTemplateModal(false)}
            submit={addNewReportTemplate}
          />
        )}

        <Table
          rowClassName={() => 'ant-table-clickable-row'}
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          columns={columns}
          rowKey={(record) => record.gid}
          dataSource={reportTemplates}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  reportTemplates: state.reportTemplates.content,
  loading: state.loading.loading,
  test: state.account.test,
  totalElements: state.reportTemplates.totalElements,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchReportTemplate: param => dispatch(reportTemplatesActions.fetchReportTemplate({
    payload: param,
  })),
  addReportTemplate: param => dispatch(reportTemplatesActions.addReportTemplate({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CustomReportTemplate);
