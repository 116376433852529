// @flow
import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

type Props = {
    coordinate: Object,
  };

const MapLocation = (props: Props) => {
  const {
    coordinate,
  } = props;
  return (
    <div
      className="mb-4"
      id="location-info"
      style={{
        justifyContent: 'start',
        height: '350px',
        position: 'relative',
      }}
    >
      <Map
        google={props.google}
        zoom={1}
        style={{ height: '350px' }}
        initialCenter={{ lat: coordinate ? coordinate.x : null, lng: coordinate ? coordinate.y : null }}
      >
        <Marker position={{ lat: coordinate ? coordinate.x : null, lng: coordinate ? coordinate.y : null }} />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds',
})(MapLocation);
