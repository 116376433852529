// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import {
  MAX_AMOUNT,
} from 'appconstants';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
};

const AddTaxRate = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const [displayName, setDisplayName] = useState('');
  const [percentage, setPercentage] = useState(null);
  const [description, setDescription] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      taxrate: {
        message: MAX_AMOUNT.MESSAGE_TAX,
        rule: (val) => MAX_AMOUNT.LIMIT_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      displayName,
      percentage,
      description,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add</SpButton>,
      ]}
      title="Add Service Charge"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Enter text"
            value={displayName}
            onChange={(e) => setDisplayName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', displayName, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <Input
            placeholder="Enter text"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('description', description, 'required')}
          </SpError>
        </div>
        <div className="pb-3">
          <label className="d-block"><SpH5>Percentage</SpH5></label>
          <div className="mr-3">
            <Input
              value={percentage}
              placeholder="Enter percentage"
              onChange={(e) => setPercentage(e.currentTarget.value)}
              suffix="%"
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('rate', percentage, 'required|taxrate')}
            </SpError>
          </div>
        </div>

      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddTaxRate);
