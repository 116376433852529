/* eslint-disable */
import React from 'react';

const NoLogoAvailable = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="123" height="45" viewBox="0 0 123 45">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g transform="translate(-48 -1284) translate(49 1285)">
                        <path fill="silver" fillRule="nonzero" d="M36.975 15.775c.367.366.367.96 0 1.325-.366.367-.96.367-1.325 0-.367-.366-.367-.96 0-1.325.366-.366.96-.366 1.325 0" />
                        <path fill="silver" fillRule="nonzero" d="M38.187 11.75H33.34c-.388-1.091-1.43-1.875-2.653-1.875h-9.374c-1.205 0-2.23.765-2.63 1.832l1.509 1.508c.107-.152.183-.327.183-.527 0-.518.42-.938.938-.938h9.374c.518 0 .938.42.938.938s.42.937.937.937h5.625c.518 0 .938.42.938.938v16.875c0 .2-.077.374-.183.527l1.336 1.336c.444-.497.722-1.146.722-1.863V14.563c0-1.551-1.262-2.813-2.813-2.813z" />
                        <path fill="silver" fillRule="nonzero" d="M32.904 25.927c.383-.9.596-1.889.596-2.927 0-4.135-3.365-7.5-7.5-7.5-1.038 0-2.027.213-2.927.596l1.489 1.489c.462-.123.938-.21 1.438-.21 3.102 0 5.625 2.523 5.625 5.625 0 .5-.087.976-.21 1.438l1.489 1.489zM11 9.326l2.46 2.46c-1.383.175-2.46 1.346-2.46 2.777v16.875c0 1.55 1.262 2.812 2.813 2.812h22.111l3.75 3.75L41 36.674 12.326 8 11 9.326zm2.813 23.05c-.518 0-.938-.421-.938-.938V14.563c0-.518.42-.938.938-.938h1.486l4.818 4.818C19.127 19.71 18.5 21.27 18.5 23c0 4.136 3.365 7.5 7.5 7.5 1.729 0 3.295-.623 4.562-1.612l3.487 3.487H13.813zm7.635-12.602l7.784 7.783c-.917.658-2.02 1.068-3.232 1.068-3.102 0-5.625-2.523-5.625-5.625 0-1.212.415-2.31 1.073-3.226z" />
                        <rect width="121" height="43" stroke="silver" strokeWidth="2" rx="4" />
                    </g>
                    <text fill="silver" fontFamily="OpenSans-Bold, Open Sans" fontSize="10" font-weight="bold" transform="translate(-48 -1284) translate(49 1285)">
                        <tspan x="53" y="18">NO LOGO</tspan> <tspan x="53" y="32">AVAILABLE</tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>
);

export default NoLogoAvailable;