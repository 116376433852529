import { SALESFORCE } from '../actionTypes';

const initialState = {
  content: [],
  spObject: [],
  spField: [],
  mappingList: [],
  mappingDetails: [],
  accountSalesforce: {},
  salesforceStatus: undefined,
  salesforceError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SALESFORCE.SUCCESS:
      return {
        ...state,
        accountSalesforce: action.payload,
      };
    case SALESFORCE.FETCH:
      return {
        ...state,
        salesforceStatus: 'STARTED',
      };
    case SALESFORCE.FAILED:
      return {
        ...state,
        salesforceError: action.payload,
        salesforceStatus: 'FAILED',
      };
    case SALESFORCE.SPOBJECT_SUCCESS:
      return {
        ...state,
        spObject: action.payload,
      };
    case SALESFORCE.SPFIELD_SUCCESS:
      return {
        ...state,
        spField: action.payload,
      };
    case SALESFORCE.FETCH_MAPPINGLIST_SUCCESS:
      return {
        ...state,
        mappingList: action.payload,
      };
    case SALESFORCE.FETCH_MAPPINGDETAILS_SUCCESS:
      return {
        ...state,
        mappingDetails: action.payload,
      };
    default:
      return state;
  }
};
