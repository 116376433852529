// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Icon,
} from 'antd';
import {
  SpText,
  SpButton,
  Line,
  SpStatusTag,
  SpFormWrapper,
  SpWrapper,
  SpH5,
} from 'components/DesignKit';
import formatNumber from 'utils/formatNumber';
import getTaxAmount from 'utils/getTaxAmount';
import getDiscountOff from 'utils/getDiscountOff';
import moment from 'moment-timezone';
import {
  INVOICE_TYPES,
  RATE_TYPES,
  COUNTRIES,
} from 'appconstants';
import {
  notificationActions,
} from 'store/actions';
import getInvoiceBackground from 'utils/getInvoiceBackground';
import getInvoiceStatus from 'utils/getInvoiceStatus';
import Download from 'components/NavIcons/downloadIcon';
import swirepaylogo from 'components/NavIcons/swirepay';
import CancelInvoice from './CancelInvoice';
import AddFundingSource from './components/AddFundingSource';

type Props = {
  invoiceDetails: Object,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  sendInvoice: Function,
  cancelInvoice: Function,
  submitting: boolean,
  validator: boolean,
  invoicePay: Function,
  invoiceDownload: Function,
};

const ViewInvoice = (props: Props) => {
  const {
    invoiceDetails,
    selectedAccount,
    sendInvoice,
    cancelInvoice,
    submitting,
    validator,
    invoicePay,
    invoiceDownload,
  } = props;

  const [cancelInvoiceModal, setCancelInvoiceModal] = useState(false);
  const invoiceLineItems = invoiceDetails.invoiceLineItems ? invoiceDetails.invoiceLineItems : [];
  const totalAmount = invoiceDetails && (invoiceDetails.amount / 100);
  const currencyType = selectedAccount && selectedAccount.currency;
  const allSelectedTaxrates = invoiceDetails.taxAmount ? invoiceDetails.taxAmount : [];
  const currencyPrefix = selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix;
  const currencyObj = selectedAccount && selectedAccount.currency;
  const updatedCoupon = invoiceDetails.coupon ? invoiceDetails.coupon : {};
  const [addFundingSourceModal, setAddFundingSourceModal] = useState(false);
  const issuerName = (selectedAccount.issuer && selectedAccount.issuer.name);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;

  useEffect(() => {
    if (!validator) {
      setCancelInvoiceModal(false);
      setAddFundingSourceModal(false);
    }
  }, [validator]);

  const updateInvoice = () => {
    sendInvoice();
  };

  const deleteInvoice = () => {
    cancelInvoice();
  };

  const downloadPdf = () => {
    invoiceDownload();
  };

  const PayInvoice = (payload) => {
    invoicePay(payload);
  };

  const getAmountOff = (value) => {
    let amountOff;
    if (value.percentageOff) {
      const amount = (totalAmount * value.percentageOff) / 100;
      amountOff = formatNumber((amount).toFixed(2), currencyObj);
    } else if (value.amountOff) {
      amountOff = formatNumber((value.amountOff / 100).toFixed(2), currencyObj);
    }
    return amountOff;
  };

  const getTotalAmount = (value) => {
    let finalAmount;
    if (value) {
      const finalValue = parseFloat(value.replace(/,/g, ''));
      finalAmount = formatNumber((totalAmount - finalValue).toFixed(2), currencyObj);
    } else {
      finalAmount = formatNumber((totalAmount).toFixed(2), currencyObj);
    }
    return finalAmount;
  };

  const getFinalAmount = (amount) => {
    let finalAmount;
    const listOfTaxes = [...allSelectedTaxrates];
    const taxLists = listOfTaxes.filter((item) => item.taxRates.inclusive === false);
    if (taxLists.length > 0) {
      const percentageAmount = taxLists.reduce(
        (total, item) => total + ((parseFloat(amount.replace(/,/g, '')) * item.taxRates.percentage) / 100), 0,
      );
      finalAmount = formatNumber((parseFloat(amount.replace(/,/g, '')) + percentageAmount).toFixed(2), currencyObj);
    } else {
      finalAmount = amount;
    }
    return finalAmount;
  };

  return (
    <Card>
      <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
        <Col className="d-flex align-items-center">
          <SpText className="mr-5" fontSize="20px" fontWeight="600">INVOICE # {invoiceDetails.invoiceNumber}</SpText>
          <SpStatusTag style={{ backgroundColor: getInvoiceBackground(invoiceDetails.status), height: '21px' }}>
            {getInvoiceStatus(invoiceDetails.status)}
          </SpStatusTag>
        </Col>
        <Col style={{ marginRight: '21px' }}>
          <span
            style={{
              cursor: 'pointer',
            }}
            onClick={downloadPdf}
          >
            <Icon
              component={Download}
              onClick={downloadPdf}
            /> <SpText color="#279dfe" fontWeight="600">Download</SpText>
          </span>
          {
            invoiceDetails.status === INVOICE_TYPES.ACTIVE && (
              <>
                <SpButton
                  type="secondary"
                  shape="round"
                  className="ml-4 mr-4"
                  ghost
                  onClick={() => setCancelInvoiceModal(true)}
                >
                  Cancel Invoice
                </SpButton>
                <SpButton
                  type="primary"
                  shape="round"
                  className="mr-4"
                  onClick={() => setAddFundingSourceModal(true)}
                >
                  Pay
                </SpButton>
              </>
            )
          }
          {
            invoiceDetails.status === INVOICE_TYPES.PAST_DUE && (
              <>
                <SpButton
                  type="secondary"
                  shape="round"
                  className="mr-4"
                  ghost
                  onClick={() => setCancelInvoiceModal(true)}
                >
                  Cancel Invoice
                </SpButton>
                <SpButton
                  type="primary"
                  shape="round"
                  className="mr-4"
                  onClick={updateInvoice}
                >
                  Resend Invoice
                </SpButton>
              </>
            )
          }
        </Col>
      </Row>
      {/* CANCEL INVOICE MODAL */}
      {cancelInvoiceModal && (
        <CancelInvoice
          visible={cancelInvoiceModal}
          invoiceNo={invoiceDetails.invoiceNumber}
          submitting={submitting}
          close={() => setCancelInvoiceModal(false)}
          submit={deleteInvoice}
        />
      )}
      {/* ADD FUNDING SOURCE MODAL */}
      {addFundingSourceModal && (
        <AddFundingSource
          visible={addFundingSourceModal}
          submitting={submitting}
          selectedAccount={selectedAccount}
          country={selectedAccount.country.name}
          cntryId={selectedAccount.country.id}
          isIndia={isIndia}
          issuerName={issuerName}
          invoiceDetails={invoiceDetails}
          contactGid={invoiceDetails && invoiceDetails.contact && invoiceDetails.contact.gid}
          close={() => setAddFundingSourceModal(false)}
          submit={PayInvoice}
        />
      )}
      <SpWrapper>
        <SpFormWrapper>
          <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
            <Col className="d-flex align-items-center">
              {
                selectedAccount && selectedAccount.largeLogo ? (
                  <img src={selectedAccount.largeLogo} alt={selectedAccount.name} style={{ width: '108px', height: '74px' }} />
                ) : (
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">{selectedAccount.name}</SpText>
                )
              }
            </Col>
            <Col className="d-flex">
              <div className="mr-2 mt-3">
                <SpText color="rgba(0, 0, 0, 0.7)">Powered by</SpText>
              </div>
              <Icon
                style={{
                  fontSize: '24px',
                }}
                component={swirepaylogo}
              />
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col className="d-flex align-items-center" span={12}>
              <SpText className="mr-2" fontSize="18px">INVOICE No.</SpText>
              <SpText fontSize="18px" fontWeight="600">{invoiceDetails.invoiceNumber}</SpText>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Description</SpText></div>
              <div className="pt-1 pb-1 pr-5"><SpText color="#434343">{invoiceDetails.description}</SpText></div>
            </Col>
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Transfer Due Date</SpText></div>
              <div className="pt-1 pb-1">
                <SpText color="#434343">
                  {
                    invoiceDetails.transferDueDate && selectedAccount
                      ? (
                        moment
                          .utc(invoiceDetails.transferDueDate)
                          .tz(selectedAccount.timezone)
                          .format('MMM DD, YYYY')
                      )
                      : <>&#8211;</>
                  }
                </SpText>
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Contact</SpText></div>
              <div className="pt-1 pb-1"><SpText color="#434343">{invoiceDetails.contact.name}</SpText></div>
            </Col>
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Issue Date</SpText></div>
              <div className="pt-1 pb-1">
                <SpText color="#434343">
                  {
                    invoiceDetails.issueDate && selectedAccount
                      ? (
                        moment
                          .utc(invoiceDetails.issueDate)
                          .tz(selectedAccount.timezone)
                          .format('MMM DD, YYYY')
                      )
                      : <>&#8211;</>
                  }
                </SpText>
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Billing Address</SpText></div>
              <div className="pt-1 pb-1 pr-5">
                {
                  invoiceDetails.billingAddress && invoiceDetails.billingAddress.street ? (
                    <SpText color="#434343">
                      {`${invoiceDetails.billingAddress.street}, ${invoiceDetails.billingAddress.city}, 
                      ${invoiceDetails.billingAddress.state} ${invoiceDetails.billingAddress.postalCode}`}
                    </SpText>
                  ) : (
                    <SpText color="#434343">
                      N/A
                    </SpText>
                  )
                }
              </div>
            </Col>
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Shipping Address</SpText></div>
              <div className="pt-1 pb-1 pr-5">
                {
                  invoiceDetails.shippingAddress && invoiceDetails.shippingAddress.street ? (
                    <SpText color="#434343">
                      {`${invoiceDetails.shippingAddress.street}, ${invoiceDetails.shippingAddress.city}, 
                      ${invoiceDetails.shippingAddress.state} ${invoiceDetails.shippingAddress.postalCode}`}
                    </SpText>
                  ) : (
                    <SpText color="#434343">
                      N/A
                    </SpText>
                  )
                }
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2" style={{ background: '#eeeeff', padding: '5px' }}>
            <Col span={12}>
              <div className="pt-1 pb-1 pl-2"><SpText fontWeight="600">Description</SpText></div>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end">
                <Col span={6} className="pt-1 mr-3" align="center">
                  <SpText fontWeight="600" fontSize="14px">Rate</SpText>
                </Col>
                <Col span={6} className="pt-1 mr-3" align="center">
                  <SpText fontWeight="600" fontSize="14px">Quantity</SpText>
                </Col>
                <Col span={6} className="pt-1 mr-3" align="right">
                  <SpText fontWeight="600" fontSize="14px">Total</SpText>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2" style={{ padding: '0 5px' }}>
            {invoiceLineItems.map((item) => (
              <>
                <Col span={12}>
                  <div className="pt-1 pb-1 pl-2"><SpText>{item.name}</SpText></div>
                </Col>
                <Col span={12}>
                  <Row type="flex" justify="end">
                    <Col span={6} className="mr-3" align="center">
                      <SpText fontWeight="600">{invoiceDetails.currency.prefix} {(item.amount / 100).toFixed(2)}</SpText>
                    </Col>
                    <Col span={6} className="mr-3" align="center">
                      <SpText fontWeight="600">{item.quantity}</SpText>
                    </Col>
                    <Col span={6} className="mr-3" align="right">
                      <SpText fontWeight="600">{invoiceDetails.currency.prefix}
                        {formatNumber(((item.amount / 100) * (item.quantity)).toFixed(2), currencyType)}
                      </SpText>
                    </Col>
                  </Row>
                </Col>
              </>
            ))}
          </Row>
          <Row type="flex" justify="end" className="ml-5 mb-2">
            {updatedCoupon.gid && (
              <Col span={14}>
                <Row type="flex" justify="end">
                  <Col span={12} className="pt-1 ml-3" align="left">
                    <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                      <SpText>
                        {updatedCoupon.name}
                      </SpText><br />
                      <SpText fontSize="12px">
                        {getDiscountOff(updatedCoupon)}
                      </SpText>
                    </div>
                  </Col>
                  <Col span={6} className="pt-2 ml-5 mr-3" align="right">
                    <SpText fontWeight="600">({currencyPrefix} {getAmountOff(updatedCoupon)})</SpText>
                  </Col>
                  <Col span={2} className="pt-1" align="right" />
                </Row>
              </Col>
            )}
          </Row>
          <Row type="flex" justify="end" className="ml-5">
            {
              allSelectedTaxrates.map((item) => (
                <Col span={14}>
                  <Row type="flex" justify="end">
                    <Col span={9} className="pt-1 ml-3" align="left">
                      <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                        <SpText>
                          {item.taxRates.displayName}
                        </SpText><br />
                        <SpText fontSize="12px">
                          {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                        </SpText>
                      </div>
                    </Col>
                    <Col span={3} className="pt-1" align="right">{item.taxRates.percentage} %</Col>
                    <Col span={6} className="pt-2 ml-5 mr-3" align="right">
                      <SpText fontWeight="600">
                        {currencyPrefix} {getTaxAmount(item.taxRates, totalAmount, getAmountOff(updatedCoupon), selectedAccount)}
                      </SpText>
                    </Col>
                    <Col span={2} className="pt-1" align="right" />
                  </Row>
                </Col>
              ))
            }
          </Row>
          <Line opacity="0.3" className="mt-1 mb-1" />
          <Row type="flex" justify="end" className="mr-3">
            <Col className="mt-1 pb-1">
              <SpText className="mr-5" fontWeight="500" fontSize="20px">
                Total Amount
              </SpText>
              <SpText fontWeight="600" fontSize="20px">
                {
                  (getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))) < 0 ? (
                    `${currencyPrefix} 0.00`
                  ) : (
                    `${currencyPrefix} ${getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))}`
                  )
                }
              </SpText>
            </Col>
          </Row>
          <Line />
          <Row className="my-2 mb-3 mt-2">
            <Col>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Notes</SpText></div>
              <div className="pt-1 pb-1">
                <SpText color="#434343">
                  {invoiceDetails.vendorNote}
                </SpText>
              </div>
            </Col>
          </Row>
          <Row className="my-2 mb-3 mt-2" style={{ background: '#eeeeff', padding: '5px' }}>
            <Col>
              <SpText fontWeight="600">{selectedAccount.name}</SpText>
            </Col>
          </Row>
        </SpFormWrapper>
      </SpWrapper>
      <Row type="flex" justify="end" gutter={[16, 16]} className="pt-3">
        <Col>
          <SpH5 color="#7f7f7f">{invoiceDetails.gid}</SpH5>
        </Col>
      </Row>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(null, mapDispatchToProps)(ViewInvoice);
