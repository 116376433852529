import React from 'react';
import { connect } from 'react-redux';
import Dashboard from './components/dashboard';
import Notify from './components/notify';

type Props = ContextRouter & {
    customReport: Object,
  };

const CustomDashboard = (props: Props) => {
  const { customReport } = props;
  const dashId = ((customReport && customReport.dashboardId) ? customReport.dashboardId : '');

  return (
    <>
      {
      dashId ? (
        <Dashboard dashId={dashId} />
      ) : (
        <Notify />
      )
    }
    </>
  );
};

const mapStateToProps = (state) => ({
  customReport: state.account.customReport,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(CustomDashboard);
