import { INVENTORY_RESERVATIONS_DETAILS } from '../actionTypes';

export const fetchInventoryReservationsDetails = (action) => ({
  type: INVENTORY_RESERVATIONS_DETAILS.FETCH,
  payload: action.payload,
});

export const cancelInventoryReservations = (action) => ({
  type: INVENTORY_RESERVATIONS_DETAILS.DELETE,
  payload: action.payload,
});
