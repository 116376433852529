import { CUSTOMERSDETAILS } from '../actionTypes';

const initialState = {
  cardDetails: [],
  customerDetailsStatus: undefined,
  customerDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMERSDETAILS.FETCH:
      return {
        ...state,
        customerDetailsStatus: 'STARTED',
      };
    case CUSTOMERSDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        customerDetailsStatus: 'SUCCESS',
      };
    case CUSTOMERSDETAILS.FAILED:
      return {
        ...state,
        customerDetailsError: action.payload,
        customerDetailsStatus: 'FAILED',
      };
    case CUSTOMERSDETAILS.CARDS_SUCCESS:
      return {
        ...state,
        cardDetails: action.payload,
      };
    default:
      return state;
  }
};
