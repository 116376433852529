import { PAYMENTWIDGETSDETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  payments: {},
  subscriptions: {},
  paymentWidgetDetailsStatus: undefined,
  paymentWidgetDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENTWIDGETSDETAILS.FETCH:
      return {
        ...state,
        paymentWidgetDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case PAYMENTWIDGETSDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        paymentWidgetDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case PAYMENTWIDGETSDETAILS.FAILED:
      return {
        ...state,
        paymentWidgetDetailsError: action.payload,
        paymentWidgetDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case PAYMENTWIDGETSDETAILS.SUCCESS_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
      };
    case PAYMENTWIDGETSDETAILS.SUCCESS_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case PAYMENTWIDGETSDETAILS.SLUG_SUCCESS:
      return {
        ...state,
        paymentSlug: action.payload,
      };
    default:
      return state;
  }
};
