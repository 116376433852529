// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Icon,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpButton,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getFilterOperators from 'utils/getFilterOperators';

import { taxRateActions, filterParamsActions } from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
  RATE_TYPES,
} from 'appconstants';

import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddTaxRate from './components/AddTaxRate';
import DeleteTaxRate from './components/DeleteTaxRate';
import active from '../../assets/imgs/Active.png';
import deactive from '../../assets/imgs/Deactive.png';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const TAX_RATES_FILTERS_TYPES = FILTERS_TYPES.TAXRATES;

type Props = {
  taxrates: Array<Object>,
  fetchTaxrates: Function,
  addTaxrate: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  deleteTaxrate: Function,
  fetchTaxes: Function,
  taxes: Array<Object>,
  setFilterData: Function,
  preserveFilters: Object,
};

const TaxRates = (props: Props) => {
  const {
    deleteTaxrate,
    selectedAccount,
    taxrates,
    loading,
    addTaxrate,
    fetchTaxrates,
    totalElements,
    submitting,
    validator,
    test,
    history,
    fetchTaxes,
    taxes,
    setFilterData,
    preserveFilters,
  } = props;

  const [, forceUpdate] = useState();
  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [addTaxRateModal, setAddTaxRateModal] = useState(false);
  const [deleteTaxRateModal, setDeleteTaxRateModal] = useState(false);
  const [selectedTaxrate, setSelectedTaxrate] = useState({});
  const [filterTypes, setFilterTypes] = useState(TAX_RATES_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [filterTaxRates, setFilterTaxRates] = useState((preserveFilters && preserveFilters.filterTaxRates)
    ? (preserveFilters && preserveFilters.filterTaxRates)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [allTaxes, setAllTaxes] = useState(taxes);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterTaxRates: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });

  const debouncedFetchTaxrates = debounce(fetchTaxrates, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchTaxrates({ currentPage, sortParams, filterParams });
  };

  const exportData = taxrates ? taxrates.map((values) => ({
    name: values.displayName,
    jurisdiction: values.jurisdiction,
    description: values.description,
    percentage: values.percentage,
    inclusive: values.inclusive,
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
    // default:values.default,
  })) : '';

  const exportHeaders = [
    { label: 'NAME', key: 'name' },
    { label: 'REGION', key: 'jurisdiction' },
    { label: 'DESCRIPTION', key: 'description' },
    { label: 'RATE', key: 'percentage' },
    { label: 'TYPE', key: 'inclusive' },
    { label: 'DATE', key: 'createdAt' },
    // { label: 'DEFAULT', key: 'default' },
  ];

  useEffect(() => {
    setAllTaxes(taxes);
  }, [taxes]);

  const fetchTaxDetails = (searchParams) => {
    fetchTaxes(searchParams);
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      // inside click
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      // Outside click
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setAddTaxRateModal(false);
      setDeleteTaxRateModal(false);
    }
  }, [validator]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [taxrates]);

  const addNewTaxRate = (taxRate: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      description,
      isDefault,
    } = taxRate;
    const params = {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      description,
      isDefault,
    };
    addTaxrate({
      params,
      currentPage,
      sortParams,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.TAXRATES,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.TAXRATES,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterTaxRates];
    if (filterTaxRates.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = TAX_RATES_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTaxRates(list);
  };

  const handleFromValue = (e, index) => {
    const list = [...filterTaxRates];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterTaxRates(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterTaxRates(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterTaxRates];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterTaxRates(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterTaxRates(list);
    }
  };


  const handleChange = (e, index) => {
    const list = [...filterTaxRates];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'percentage') {
      list[index].filterType = 'Number';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = TAX_RATES_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTaxRates(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterTaxRates[index].filterName === 'createdAt') {
      if (filterTaxRates[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterTaxRates[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterTaxRates[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterTaxRates];
    list[index].filterValue = selectedValue;
    setFilterTaxRates(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTaxRates];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterTaxRates[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterTaxRates(list);
  };

  const handleAddClick = () => {
    const list = [...filterTaxRates];
    setFilterTaxRates([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterTaxRates([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(TAX_RATES_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.TAXRATES);
  };

  const applyFilters = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    const taxrateFilters = filterTaxRates.filter((item) => item.checked);
    if (taxrateFilters[0].filterValue || (taxrateFilters[0].filterValueFrom && taxrateFilters[0].filterValueTo)) {
      setCount(taxrateFilters && taxrateFilters.length);
      for (let i = 0; i < taxrateFilters.length; i += 1) {
        if (taxrateFilters[i].checked) {
          if (taxrateFilters[i].filterName === 'createdAt' && taxrateFilters[i].filterOperator !== 'BT') {
            taxrateFilters[i] = taxrateFilters[i].filterValue;
          } else if (taxrateFilters[i].filterName === 'createdAt'
            && taxrateFilters[i].filterOperator === 'BT'
            && taxrateFilters[0].filterValue.split('=')[1].split('&')[0] !== 'Invalid date'
          ) {
            taxrateFilters[i] = taxrateFilters[i].filterValue;
          } else if (taxrateFilters[i].filterName === 'percentage' && taxrateFilters[i].filterOperator === 'BT') {
            const name = taxrateFilters[i].filterName;
            const from = taxrateFilters[i].filterValueFrom;
            const to = taxrateFilters[i].filterValueTo;
            taxrateFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (taxrateFilters[i].filterName === 'percentage' && taxrateFilters[i].filterOperator !== 'BT') {
            const value = taxrateFilters[i].filterValue;
            taxrateFilters[i] = `${taxrateFilters[i].filterName}.${taxrateFilters[i].filterOperator}=${value}`;
          } else if (taxrateFilters[i].filterName === 'createdAt'
            && taxrateFilters[i].filterOperator === 'BT'
            && taxrateFilters[0].filterValue.split('=')[1].split('&')[0] === 'Invalid date'
          ) {
            taxrateFilters[i] = '';
          } else {
            taxrateFilters[i] = `${taxrateFilters[i].filterName}.${taxrateFilters[i].filterOperator}=${taxrateFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(taxrateFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (filterParams) {
        if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
          setFilterData({
            pathname,
            search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
            filterTaxRates,
          });
          history.push({
            pathname: PAGES.TAXRATES,
            search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          });
        } else {
          setFilterData({
            pathname,
            search: `?filterParams=${filterParams}`,
            filterTaxRates,
          });
          history.push({
            pathname: PAGES.TAXRATES,
            search: `?filterParams=${filterParams}`,
          });
        }
        setOpen(false);
      }
    }
  };

  const showDeleteTaxrateModal = (value: Object) => {
    setSelectedTaxrate(value);
    setDeleteTaxRateModal(true);
  };

  const deleteTaxRate = (gid: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    deleteTaxrate({
      currentPage,
      sortParams,
      gid,
    });
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'displayName',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'REGION',
      dataIndex: 'jurisdiction',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '18%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'RATE',
      dataIndex: 'percentage',
      width: '10%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (percentage) => `${percentage} %`,
    },
    {
      title: 'TYPE',
      dataIndex: 'inclusive',
      width: '12%',
      align: 'left',
      render: (inclusive) => {
        let value = '';
        if (inclusive) {
          value = RATE_TYPES.INCLUSIVE;
        } else {
          value = RATE_TYPES.EXCLUSIVE;
        }
        return value;
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },

    },
    {
      title: 'POS',
      dataIndex: 'isDefault',
      width: '9%',
      align: 'center',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (isDefault) => {
        let value = '';
        if (isDefault) {
          value = active;
        } else {
          value = deactive;
        }
        return <img src={value} height="20px" alt="defaultStatusIcon" />;
      },

    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '15%',
    align: 'center',
    render: (text, record) => (
      <Tooltip placement="top" title="Delete">
        <Icon
          type="delete"
          style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
          onClick={(e) => { e.stopPropagation(); showDeleteTaxrateModal(record); }}
        />
      </Tooltip>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC
            permit={
              [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
            }
          >
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                ghost
                onClick={() => setAddTaxRateModal(true)}
              >
                {'\u002B'}&nbsp;Add Tax Rate
              </SpButton>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Tax Rates">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Tax-Rates Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Tax Rates">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-114px' }}
                  >
                    <Filters
                      filtersGrid={filterTaxRates}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                      handleFromValue={handleFromValue}
                      handleToValue={handleToValue}
                      fiterText="Enter Value"
                    />
                    <div className="px-4 mt-2">
                      {
                        filterTaxRates.length < TAX_RATES_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              {'\u002B'}&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD TAXRATE MODAL */}
      {addTaxRateModal && (
        <AddTaxRate
          visible={addTaxRateModal}
          submitting={submitting}
          close={() => setAddTaxRateModal(false)}
          submit={addNewTaxRate}
          allTaxes={allTaxes}
          fetchTaxDetails={fetchTaxDetails}
        />
      )}

      {/* DELETE TAXRATE MODAL */}
      {deleteTaxRateModal && (
        <DeleteTaxRate
          visible={deleteTaxRateModal}
          selectedTaxrate={selectedTaxrate}
          submitting={submitting}
          close={() => setDeleteTaxRateModal(false)}
          submit={deleteTaxRate}
        />
      )}

      <RBAC
        fallback={(
          <Table
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={taxrates}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
          ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]}
      >
        <Table
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={taxrates}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  taxrates: state.taxrates.content,
  totalElements: state.taxrates.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  taxes: state.taxrates.taxes,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTaxrates: param => dispatch(taxRateActions.fetchTaxrates({
    payload: param,
  })),
  fetchTaxes: param => dispatch(taxRateActions.fetchTaxes({
    payload: param,
  })),
  addTaxrate: param => dispatch(taxRateActions.addTaxrate({
    payload: param,
  })),
  deleteTaxrate: param => dispatch(taxRateActions.deleteTaxrate({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(TaxRates);
