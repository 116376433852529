import { PAYMENTWIDGET_STATUSES } from 'appconstants';

export default value => {
  if (value === 'ACTIVE') {
    return PAYMENTWIDGET_STATUSES.ACTIVE;
  } if (value === 'EXPIRED') {
    return PAYMENTWIDGET_STATUSES.EXPIRED;
  } if (value === 'DEACTIVATED') {
    return PAYMENTWIDGET_STATUSES.DEACTIVATED;
  } return PAYMENTWIDGET_STATUSES.ACTIVE;
};
