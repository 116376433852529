import React from 'react';
import moment from 'moment-timezone';
import { Icon } from 'antd';
import {
  SpH5,
  SpText,
} from 'components/DesignKit';

import getPaymentType from 'utils/getPaymentType';
import formatAmount from 'utils/formatAmount';
import getTimelineColor from 'utils/getTimelineColor';

type Props = {
  paymentTimeline: Array<Object>,
  selectedAccount: Object,
}

const TimelineBar = (props: Props) => {
  const {
    paymentTimeline,
    selectedAccount,
  } = props;
  const currencyType = selectedAccount && selectedAccount.currency;

  const getPaymentDate = (updatedAt) => moment
    .utc(updatedAt)
    .tz(selectedAccount.timezone)
    .format('MMM DD, YYYY, hh:mm a');

  function getTimelineData(text) {
    let currency = '';
    // eslint-disable-next-line no-unused-vars
    let index = 0;

    if (text.indexOf('₹') > -1) {
      index = text.indexOf('₹');
      currency = '₹';
    }
    if (text.indexOf('$') > -1) {
      index = text.indexOf('$');
      currency = '$';
    }
    if (currency !== '') {
      const tempString = text.split(currency);
      const newArray = tempString.map((item, id) => {
        if (id !== 0 && item.length !== 0) {
          const tempArray = item.split(' ');
          tempArray[0] = formatAmount(tempArray[0] / 100, currencyType);
          return tempArray.join(' ');
        }
        return item;
      });
      return newArray.join(currency);
    }

    return text;
  }

  const sortedPaymentTimeline = paymentTimeline.sort((a, b) => new Date(b.paymentEventTimeline) - new Date(a.paymentEventTimeline));

  return (
    <div className="bar-container">
      {
        sortedPaymentTimeline.map((data, id, arr) => (
          <div className="item" key={id}>
            <div className="left">
              <SpH5>
                {(data.paymentEventTimeline && selectedAccount && selectedAccount.timezone)
                  ? getPaymentDate(data.paymentEventTimeline)
                  : (data.updatedAt && selectedAccount && selectedAccount.timezone)
                    ? getPaymentDate(data.updatedAt) : ''}
              </SpH5>
            </div>
            <div className="middle">
              <Icon type={getPaymentType(data.type)} style={{ color: getTimelineColor(data.type) }} />
              {id !== arr.length - 1 ? <div className="line" /> : null}
            </div>
            <div className="right">
              <div>
                <SpH5>
                  {getTimelineData(data.text)}
                </SpH5>
              </div>
              {data.subText
                ? <div className="mt-2"><SpText fontSize="13px" color="#434343">{getTimelineData(data.subText)}</SpText></div>
                : (data.subtext)
                  ? <div className="mt-2"><SpText fontSize="13px" color="#434343">{getTimelineData(data.subtext)}</SpText></div>
                  : (data.paymentSessionStatus)
                    ? <div className="mt-2"><SpText fontSize="13px" color="#434343">{getTimelineData(data.paymentSessionStatus)}</SpText></div>
                    : ''}
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default TimelineBar;
