// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Icon,
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getFilterOperators from 'utils/getFilterOperators';

import {
  partnerSettlementActions,
  filterParamsActions,
  notificationActions,
} from 'store/actions';
import { NOTIFICATION, FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  MESSAGES,
  FILTERS_TYPES,
} from 'appconstants';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import Download from 'components/NavIcons/downloadProof';
import DisableDownload from 'components/NavIcons/downloadNoProof';
import AddNewSettlement from './components/AddNewSettlement';
import DeleteSettlement from './components/DeleteSettlement';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const ACCOUNTSETTLEMENT_FILTERS_TYPES = FILTERS_TYPES.PARTNERSETTLEMENT;

type Props = {
  partnerSettlement: Array<Object>,
  fetchPartnerSettlement: Function,
  addPartnerSettlement: Function,
  deletePartnerSettlement: Function,
  setNotification: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  internalRole: Object,
  setFilterData: Function,
  preserveFilters: Object,
  history: {
    push: Function,
  },
};

const PartnerSettlement = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    partnerSettlement,
    setNotification,
    loading,
    addPartnerSettlement,
    deletePartnerSettlement,
    fetchPartnerSettlement,
    totalElements,
    submitting,
    validator,
    test,
    history,
    internalRole,
    preserveFilters,
    setFilterData,
  } = props;

  const [pagination, setPagination] = useState({});
  const [addSettlementModal, setAddSettlementModal] = useState(false);
  const [deleteSettlementModal, setDeleteSettlementModal] = useState(false);
  const [selectedSettlement, setSelectedSettlement] = useState({});
  const { pathname } = location;
  const myRef = useRef();
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(ACCOUNTSETTLEMENT_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterPayments, setFilterPayments] = useState((preserveFilters && preserveFilters.filterPayments)
    ? (preserveFilters && preserveFilters.filterPayments)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterPayments: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });

  const exportData = partnerSettlement ? partnerSettlement.map((values) => ({
    partnerSettlementFilename: values.partnerSettlementFilename,
    partnerSettlementDate: moment
      .utc(values.partnerSettlementDate)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY'),
  })) : '';

  const exportHeaders = [
    { label: 'FILE NAME', key: 'partnerSettlementFilename' },
    { label: 'DATE', key: 'partnerSettlementDate' },
  ];

  const debouncedFetchPartnerSettlement = debounce(fetchPartnerSettlement, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'partnerSettlementDate';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchPartnerSettlement({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setAddSettlementModal(false);
      setDeleteSettlementModal(false);
    }
  }, [validator]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [partnerSettlement]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addNewPartnerSettlement = (accountInfo: Object) => {
    const {
      name,
      settlementDate,
      documentUrl,
    } = accountInfo;
    addPartnerSettlement({
      partnerSettlementUrl: documentUrl,
      partnerSettlementFilename: name,
      partnerSettlementDate: settlementDate,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.PARTNER_SETTLEMENT,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.PARTNER_SETTLEMENT,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterPayments];
    if (filterPayments.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = ACCOUNTSETTLEMENT_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterPayments(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterPayments];
    if (e[1] === 'partnerSettlementDate') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = ACCOUNTSETTLEMENT_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterPayments(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterPayments(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterPayments(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterPayments[index].filterName === 'partnerSettlementDate') {
      if (filterPayments[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `partnerSettlementDate.GT=${dateStart}&partnerSettlementDate.LT=${dateEnd}`;
      } else if (filterPayments[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `partnerSettlementDate.LT=${dateStart}`;
      } else if (filterPayments[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `partnerSettlementDate.GT=${dateStart}`;
      }
    } else if (filterPayments[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterPayments];
    list[index].filterValue = selectedValue;
    setFilterPayments(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterPayments];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'partnerSettlementDate') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'partnerSettlementDate') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterPayments[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterPayments(list);
  };

  const handleAddClick = () => {
    const list = [...filterPayments];
    setFilterPayments([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterPayments([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(ACCOUNTSETTLEMENT_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.PARTNER_SETTLEMENT);
  };

  const applyFilters = () => {
    const paymentsFilters = filterPayments.filter((item) => item.checked);
    for (let i = 0; i < paymentsFilters.length; i += 1) {
      if (!paymentsFilters[i].filterName || !paymentsFilters[i].filterOperator || !paymentsFilters[i].filterValue) {
        return;
      }
    }
    if (paymentsFilters[0].filterValue || (paymentsFilters[0].filterValueFrom && paymentsFilters[0].filterValueTo)) {
      setCount(paymentsFilters && paymentsFilters.length);
      for (let i = 0; i < paymentsFilters.length; i += 1) {
        if (paymentsFilters[i].checked) {
          if (paymentsFilters[i].filterName === 'partnerSettlementDate') {
            paymentsFilters[i] = paymentsFilters[i].filterValue;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else {
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${paymentsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(paymentsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'partnerSettlementDate';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.PARTNER_SETTLEMENT,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.PARTNER_SETTLEMENT,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const downloadSettlement = async (record) => {
    const { gid } = record;
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.PARTNER_SETTLEMENT}/${gid}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const showDeleteSettlementModal = (value: Object) => {
    setSelectedSettlement(value);
    setDeleteSettlementModal(true);
  };

  const deleteSelectedSettlement = (gid: string) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'partnerSettlementDate';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;

    deletePartnerSettlement({
      gid,
      currentPage,
      sortParams,
    });
  };


  const columns = [
    {
      title: 'FILE NAME',
      dataIndex: 'partnerSettlementFilename',
      width: '60%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (partnerSettlementFilename) => (
        <>
          <span style={{ cursor: 'pointer' }}>
            {partnerSettlementFilename || <>&#8211;</>}
          </span>
        </>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'partnerSettlementDate',
      width: '20%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (partnerSettlementDate) => {
        if (partnerSettlementDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(partnerSettlementDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'actions',
    width: '20%',
    align: 'center',
    render: (text, record) => (
      <>
        {
          record && record.partnerSettlementUrl ? (
            <Icon
              component={Download}
              style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
              onClick={() => downloadSettlement(record)}
            />
          ) : (
            <Icon
              component={DisableDownload}
              style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
            />
          )
        }
        {
          internalRole && (
            <Tooltip placement="top" title="Delete">
              <Icon
                type="delete"
                style={{ fontSize: '20px', marginLeft: '3px', cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); showDeleteSettlementModal(record); }}
              />
            </Tooltip>
          )
        }
      </>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <RBAC permit={[ROLES.SUPERUSER]}>
              <SpButton
                type="secondary"
                shape="round"
                ghost
                className="mr-3"
                onClick={() => setAddSettlementModal(true)}
              >
                {'\u002B'}&nbsp;New Settlement
              </SpButton>
            </RBAC>
            <span>
              {
                exportData !== null
                  ? (
                    <Tooltip placement="left" title="Click here to export Partner Settlement">
                      <CSVLink
                        target="_blank"
                        className="mr-3"
                        filename="Partner Settlement Report.csv"
                        data={exportData}
                        headers={exportHeaders}
                        onMouseEnter={() => setFillColor(true)}
                        onMouseLeave={() => setFillColor(false)}
                      >
                        <ExportIcon
                          fill={fillColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </CSVLink>
                    </Tooltip>
                  ) : null
              }
            </span>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Partner Settlement">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && (
                <FilterWrapper
                  style={{ marginLeft: internalRole ? '-63px' : '-250px' }}
                >
                  <Filters
                    filtersGrid={filterPayments}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                    handleFromValue={handleFromValue}
                    handleToValue={handleToValue}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterPayments.length < ACCOUNTSETTLEMENT_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
            </span>
          </Col>
        </div>
      </Row>

      {/* ADD SETTLEMENT MODAL */}
      {
        addSettlementModal && (
          <AddNewSettlement
            visible={addSettlementModal}
            submitting={submitting}
            selectedAccount={selectedAccount}
            close={() => setAddSettlementModal(false)}
            submit={addNewPartnerSettlement}
          />
        )
      }
      {/* DELETE SETLLEMENT MODAL */}
      {deleteSettlementModal && (
        <DeleteSettlement
          visible={deleteSettlementModal}
          selectedSettlement={selectedSettlement}
          submitting={submitting}
          close={() => setDeleteSettlementModal(false)}
          submit={deleteSelectedSettlement}
        />
      )}
      <RBAC
        fallback={(
          <Table
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={partnerSettlement}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
      >
        <Table
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={partnerSettlement}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  partnerSettlement: state.partnerSettlement.content,
  totalElements: state.partnerSettlement.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  internalRole: state.user.internalRole,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPartnerSettlement: param => dispatch(partnerSettlementActions.fetchPartnerSettlement({
    payload: param,
  })),
  addPartnerSettlement: param => dispatch(partnerSettlementActions.addPartnerSettlement({
    payload: param,
  })),
  deletePartnerSettlement: param => dispatch(partnerSettlementActions.deletePartnerSettlement({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PartnerSettlement);
