import { SUBSCRIPTIONV2 } from '../actionTypes';

export const fetchSubscriptionV2 = (action) => ({
  type: SUBSCRIPTIONV2.FETCH,
  payload: action.payload,
});

export const fetchPlanV2Search = (action) => ({
  type: SUBSCRIPTIONV2.SEARCH_FETCH,
  payload: action.payload,
});

export const addSubscriptionV2 = (action) => ({
  type: SUBSCRIPTIONV2.ADD,
  payload: action.payload,
});
