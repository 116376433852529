import { SHOPS_DETAILS } from '../actionTypes';

export const fetchInventoryShopsDetails = (action) => ({
  type: SHOPS_DETAILS.FETCH,
  payload: action.payload,
});

export const addItemShops = (action) => ({
  type: SHOPS_DETAILS.SHOPS_ITEM_ADD,
  payload: action.payload,
});

export const fetchItemByShops = (action) => ({
  type: SHOPS_DETAILS.SHOPS_ITEM_FETCH,
  payload: action.payload,
});

/* export const fetchTipsByShops = (action) => ({
  type: SHOPS_DETAILS.SHOPS_TIPS_FECTH,
  payload: action.payload,
} ); */

export const fetchShopsSlug = (action) => ({
  type: SHOPS_DETAILS.SHOPS_SLUG_FETCH,
  payload: action.payload,
});

export const fetchPromosByShop = (action) => ({
  type: SHOPS_DETAILS.SHOPS_PROMO_FETCH,
  payload: action.payload,
});

export const addPromosByShop = (action) => ({
  type: SHOPS_DETAILS.SHOPS_PROMO_ADD,
  payload: action.payload,
});

export const addTips = (action) => ({
  type: SHOPS_DETAILS.SHOPS_TIPS_ADD,
  payload: action.payload,
});

export const deleteTipsByShop = (action) => ({
  type: SHOPS_DETAILS.SHOPS_TIPS_DELETE,
  payload: action.payload,
});


export const deletePromosByShop = (action) => ({
  type: SHOPS_DETAILS.SHOPS_PROMO_DELETE,
  payload: action.payload,
});

export const updatePromosByShop = (action) => ({
  type: SHOPS_DETAILS.SHOPS_PROMO_UPDATE,
  payload: action.payload,
});

export const fetchAllPromosByShop = (action) => ({
  type: SHOPS_DETAILS.SHOPS_ALLPROMO_FETCH,
  payload: action.payload,
});

export const fetchItemNameByShops = (action) => ({
  type: SHOPS_DETAILS.SHOPS_ITEM_SEARCH_FETCH,
  payload: action.payload,
});

export const fetchTablesShops = (action) => ({
  type: SHOPS_DETAILS.FETCH_SHOPS_TABLES,
  payload: action.payload,
});
