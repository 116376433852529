// eslint-disable
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { LabelText, SelectBox, SpanText } from '../../../design';
import currencyFormatter from '../../../utils/currencyformatter';
import { AmountDivideByHundred } from '../../../helper';
import { normalTextFontSize, smallTextFontSize, quantityTextHeight } from '../../../utils/reseponsive';

type Props = {
  amount: number,
  currencyName: string,
  minQty: number,
  maxQty: number,
  control: any,
  label: string,
  description: string,
  name: string,
  isPreview: boolean,
  isAdaptive: boolean,
  setValue: any,
  isRequired: boolean,
  comboHide: Function,
  options: any,
  selectedAccount: Function,
}

const InputQuantity = (props: Props) => {
  const {
    amount,
    minQty,
    maxQty,
    currencyName,
    control,
    options,
    label,
    description,
    name,
    isPreview,
    isAdaptive,
    setValue,
    isRequired,
    comboHide,
    selectedAccount,
  } = props;
  const [inputValue, setInputValue] = useState(isRequired ? minQty : 0);
  const [defaultAmount, setDefaultAmount] = useState(amount || 0);
  // eslint-disable-next-line no-unused-vars
  const [minQuantity, setMinQuantity] = useState(minQty || 0);
  // eslint-disable-next-line no-unused-vars
  const [maxQuantity, , setMaxQuantity] = useState(maxQty || Number.MAX_SAFE_INTEGER);

  const gid = selectedAccount && selectedAccount.gid;

  const accGid = 'account-ff1166de992e429c82eb02f86dfdbbab';
  const label1 = 'Family(2 adults + 2 children)(Non-Member)';
  const label2 = 'Family(2 adults + 2 children)(Member)';

  const [comboAddons, setComboAddons] = useState(options);
  const addtionalComboBox = options;

  const updateQuantity = (type) => {
    let updatedInputValue = inputValue;
    if (type === 'ADD') {
      updatedInputValue += 1;
      if ((label === label1 || label === label2) && gid === accGid) {
        if (updatedInputValue > 0) {
          comboHide(false);
        }
      }
      setInputValue(updatedInputValue);
    } else if (type === 'SUB') {
      updatedInputValue -= 1;
      if ((label === label1 || label === label2) && gid === accGid) {
        if (updatedInputValue === 0) {
          comboHide(true);
        }
      }
      setInputValue(updatedInputValue);
    }
  };

  useEffect(() => {
    if (inputValue === 1) {
      setComboAddons(options);
    }
    if (inputValue > 1) {
      const list = [...comboAddons, ...addtionalComboBox];
      setComboAddons(list);
    }
    const updatedAmount = amount || 0;
    if (inputValue > 0) {
      setDefaultAmount(inputValue * updatedAmount);
    }
    setValue(`${name}`, inputValue);
  }, [inputValue]);

  useEffect(() => {
    setDefaultAmount(amount);
    if (isRequired) {
      setDefaultAmount(amount * inputValue);
    }
  }, []);

  useEffect(() => {
    let list = [...comboAddons];
    if ((list.length / options.length) > inputValue) {
      list = list.slice(0, -options.length);
      setComboAddons(list);
    }
  }, [inputValue]);

  // eslint-disable-next-line no-unused-vars
  const additionalAddons = () => {
    const list = [...comboAddons, ...addtionalComboBox];
    setComboAddons(list);
  };

  const updateComboValue = (e, j) => {
    const selectedValue = e.target.value;
    const list = [...comboAddons];
    const obj = {};
    obj.type = selectedValue.split('&&')[0];
    obj.price = Number(selectedValue.split('&&')[1]);
    obj.label = list[j].label;
    obj.value = list[j].value;
    obj.children = list[j].children;
    list[j] = obj;
    setComboAddons(list);
  };

  return (
    <div className="my-3">
      <div className="form-group row mb-1 no-gutters d-flex align-items-center">
        <div className="col-sm-5">
          <LabelText
            fontSize={normalTextFontSize(isPreview, isAdaptive)}
            color="#0000000"
            hidden={!label}
            htmlFor="number"
            className="col-form-label font-weight-bolder m-0 text-break"
          >{label}
            <SpanText
              className="pl-2"
              hidden={isRequired}
              color="rgba(0, 0, 0, 0.6)"
              fontSize={smallTextFontSize(isPreview, isAdaptive)}
            >(Optional)
            </SpanText>
          </LabelText>
        </div>
        <div className="col-sm-7 text-left">
          <div className="input-group d-sm-flex align-items-center justify-content-sm-end">
            <Controller
              defaultValue={inputValue}
              as={(
                <>
                  <span className="input-group-prepend">
                    <button
                      type="button"
                      disabled={isRequired ? (inputValue <= 1 || inputValue <= minQuantity) : inputValue <= 0}
                      onClick={() => updateQuantity('SUB')}
                      className="text-center px-1 bg-white rounded-left"
                      style={{
                        width: '25px',
                        border: 'solid 0.5px #29b1cc',
                        outline: 'none',
                        height: quantityTextHeight(42, isPreview, isAdaptive),
                        fontSize: smallTextFontSize(isPreview, isAdaptive),
                      }}
                    >&#8211;
                    </button>
                  </span>
                  <NumberFormat
                    className="my-0 rounded-0 bg-white text-center"
                    style={{
                      width: '35px',
                      border: 'solid 0.5px #29b1cc',
                      outline: 'none',
                      height: quantityTextHeight(42, isPreview, isAdaptive),
                      fontSize: smallTextFontSize(isPreview, isAdaptive),
                    }}
                    // eslint-disable-next-line radix
                    onChange={({ target }) => setInputValue(parseInt(target.value))}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    value={inputValue}
                  />
                  <span className="input-group-append text-center">
                    <button
                      disabled={inputValue >= maxQuantity}
                      type="button"
                      onClick={() => updateQuantity('ADD')}
                      className="rounded-right text-center px-1 bg-white"
                      style={{
                        width: '25px',
                        border: 'solid 0.5px #29b1cc',
                        outline: 'none',
                        height: quantityTextHeight(42, isPreview, isAdaptive),
                        fontSize: smallTextFontSize(isPreview, isAdaptive),
                      }}
                    >+
                    </button>
                  </span>
                </>
              )}
              name={`${name}`}
              control={control}
            />
            <SpanText fontSize={normalTextFontSize(isPreview, isAdaptive)} className="d-flex pl-2 m-0 align-items-center font-weight-bold">
              {currencyFormatter(AmountDivideByHundred(defaultAmount), currencyName)}
            </SpanText>
          </div>
        </div>
      </div>
      <div hidden={!description} className="col-sm-10 px-0">
        <LabelText className="text-mute mt-0" fontSize={smallTextFontSize(isPreview, isAdaptive)}>{description}</LabelText>
      </div>
      <div className="form-group row mb-0 no-gutters align-items-center">
        <div className="col-sm-5" />
        <div display="flex" className="col-sm-6 mb-2">
          {
            (comboAddons && comboAddons.length > 0) && (
              comboAddons.map((list, j) => (
                <>
                  <LabelText
                    color="#0000000"
                    htmlFor="content"
                    fontSize={normalTextFontSize(isPreview, isAdaptive)}
                    className="col-form-label font-weight-bolder m-0 text-break"
                  >{list.label}
                  </LabelText>
                  <SelectBox
                    style={{
                      width: '175px',
                      padding: '0.1rem',
                      height: '25px',
                    }}
                    onChange={(e) => updateComboValue(e, j)}
                  >
                    {
                      (
                        <option>select</option>)
                    }
                    {
                      list.children.map((items) => (
                        <option key={items.label} value={`${items.value}&&${items.additionalPrice}`}>
                          {`${items.label}: ${currencyFormatter(AmountDivideByHundred(items.additionalPrice), currencyName)}`}
                        </option>
                      ))
                    }
                  </SelectBox>
                </>
              ))
            )
          }
        </div>
      </div>
      {/* {
        (comboAddons.length / options.length) < inputValue && (
          <div className="form-group row mb-0 no-gutters align-items-center">
            <div className="col-sm-5" />
            <div display="flex" className="col-sm-6 mb-2">
              <a
                style={{
                  color: '#007bff',
                  cursor: 'pointer',
                }}
                className="ml-2"
                onClick={() => additionalAddons()}
              >
                Add More
              </a>
            </div>
          </div>
        )
      } */}
    </div>
  );
};

export default InputQuantity;
