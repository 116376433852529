import { ADVANCEFEES, DONATION_PAGES } from "store/actionTypes";

export const AUTH_CHALLENGES = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
};

export const PROPRIETORSHIP_BUSINESS_TYPE = 'PROPRIETORSHIP';

export const PAGES = {
  LOGIN: '/',
  REGISTER: '/register',
  DOCUSIGN: '/docusign',
  DOCUSIGNLATER: '/docusignlater',
  FEEDBACKRATING: '/feedbackrating',
  SUPPORT: '/services-support',
  RESET_PASSWORD: '/resetpassword',
  SET_PASSWORD: '/setpassword',
  FORGOT_PASSWORD: '/forgotpassword',
  DISPUTE_DETAILS: '/disputes/details',
  PAYMENTS: '/payments',
  PAYOUTS: '/payouts',
  DEVELOPER_LOGS: '/developers/logs',
  LOCATIONS: '/locations',
  CUSTOMERS: '/customers',
  NO_MATCH: '/404',
  PUBLICKEYS: '/public-key',
  SECRETKEYS: '/secret-key',
  REPORT: '/reports',
  ADMIN_REPORT: '/report-admin',
  USERDETAILS: '/user',
  RECEIPT: '/receipts',
  ORDER_RECEIPT: '/order-receipt',
  ACCOUNT: '/account/new',
  ACCOUNT_SETUP: '/account/setup',
  ACCOUNT_ERROR: '/account/error',
  DASHBOARD: '/dashboard',
  TEAM: '/team',
  TRANSACTIONS: '/transaction',
  TERMINALS: '/terminals',
  DISPUTES: '/disputes',
  PAYMENTLINK: '/payment-links',
  NEW_ACCOUNT: '/newaccount',
  SUBSCRIPTION: '/subscriptions',
  SUBSCRIPTION_PLAN: '/subscriptions/plans',
  PLAN: '/plans',
  INVOICES: '/invoices',
  NEW_INVOICE: '/invoices/add/new',
  PRODUCTS: '/products',
  PAYMENTPAGES: '/payment-pages',
  ADDPAYMENTPAGE: '/addpaymentpage',
  ADDDONATIONPAGE: '/adddonationpage',
  UPDATE_PAYMENT_PAGE: '/updatepaymentpage',
  PAYMENTBUTTON: '/payment-buttons',
  SUBSCRIPTION_BUTTON: '/subscription-button',
  WEBHOOK: '/webhooks',
  INTEGRATIONS: '/integrations',
  SETTINGS: '/settings',
  REFERRALS: '/settings/referrals',
  CATALOG_PRODUCTS: '/productsterminals',
  RATES: '/rate',
  ACQUIRER: '/acquirer',
  ISSUER: '/acquirer/issuer',
  SETTLEMENTS: '/settlements',
  TAXRATES: '/tax-rates',
  COUPONS: '/coupons',
  OFFERS: '/offers',
  PARTNER: '/partner',
  PARTNER_TRANSACTIONS: '/transactions/partner',
  PARTNER_EARNINGS: '/earnings/partner',
  INTEGRATIONS_CONFIGURE: '/integrations/configure',
  INTEGRATIONS_UPDATE_INVOICE: '/integrations/updateinvoice',
  INTEGRATIONS_INVOICE: '/integrations/invoice',
  INTEGRATIONS_UPDATE_CREDENTIALS: '/integrations/updatecredentials',
  SALESFORCE: '/integrations/salesforce',
  QUICKBOOKS: '/integrations/quickbooks',
  SALESFORCE_DETAILS: '/integrations/salesforcedetails',
  ADMIN_TERMINAL: '/terminal-admin',
  SCHEDULE_REPORTS: '/schedule-reports',
  ADMIN_SCHEDULE_REPORTS: '/schedule-admin-reports',
  DOWNLOAD_SCHEDULE_REPORTS: '/scheduled-report-attempt',
  RELEASE: '/release-status',
  SP_FIELD: '/sp-field',
  POSKEY: '/pos-keys',
  GLOBAL_RESTRICTED_KEY: '/global-restricted-key',
  PAYMENTWIDGETS: '/payment-widgets',
  ADDPAYMENTWIDGET: '/addpaymentwidget',
  LOCKED: '/locked',
  NEW_REGISTER: '/register/new',
  ISSUER_RATE: '/rate/issuerrate',
  POS_USER: '/pos-user',
  CASH_TRANSACTIONS: '/transaction/cash',
  CHEQUE_TRANSACTIONS: '/transaction/cheque',
  CASH_RECEIPT: '/cash-receipt',
  CHEQUE_RECEIPT: '/check-receipt',
  INTERNAL_USER: '/internaluser',
  SERVICES: '/services',
  DOCUMENTS: '/documents',
  TRANSFERS: '/transfers',
  PAGE_RECIPT: '/page-receipts',
  VENDORS: '/funding-sources/contacts',
  FUNDINGSOURCE: '/funding-sources',
  FUNDINGSOURCE_LINK: '/funding-sources/link',
  TRANSFERLINKS: '/transfer-links',
  BULKTRANSFER: '/bulk-transfer',
  TRANSFER_RECEIPT: '/transfer-receipts',
  PAYMENTCHECKOUT: '/checkout-page',
  CUSTOM_DOCUMENT: '/settings/customdocuments',
  TRANSFER_SETTINGS: '/settings/transfer',
  CUSTOM_DOCUMENT_DETAILS: '/customdocuments',
  ADMIN_PAYMENTS: '/payment-admin',
  BUSINESS: '/settings/business',
  ACCOUNT_SETTLEMENT: '/accountsettlement',
  DEVELOPER: '/developer',
  PARTNER_SETTLEMENT: '/settlement/partner',
  OCRREQUEST: '/ocrrequest',
  BANKSTATMENT: '/bankstatement',
  ACCOUNTSLUG: '/accountslug',
  WALLET_TRANSACTION: '/transactionsList',
  WALLET: '/wallet',
  STATICQR: '/staticqr-admin',
  WALLET_TRANSACTION_DETAILS: '/transactionsList',
  CUSTOMREPORT_TEMPLATE: '/reporttemplates',
  CUSTOMREPORT: '/customreport',
  VENDORINVOICES: '/vendorinvoice',
  NEWVENDOR_INVOICE: '/vendorinvoice/add/new',
  BENEFICIARY: '/beneficiary',
  WALLETINVOICES: '/vendorinvoice-wallet',
  NEWWALLET_INVOICE: '/vendorinvoice-wallet/add/new',
  WOOCOMMERCE: '/integrations/woocommerce',
  ADMIN_TRANSFERS: '/transfer-admin',
  CLOVER_RECEIPT: '/us-pos-receipt',
  CATALOG: '/catalog',
  CATALOG_ORDERS: '/catalog/adminorder',
  INVENTORY_ORDERS: '/orders',
  INVENTORY_ITEMS: '/items',
  INVENTORY_CATEGORIES: '/categories',
  INVENTORY_MODIFIERS: '/modifiers',
  EVENTS: '/events',
  ADDSEATEDPAGE: '/addseatedpage',
  INVENTORY_ORDERTYPES: '/ordertypes',
  EVENT_RECIPT: '/event-receipts',
  PRINTER_LABEL: '/printerlabel',
  SHOPS: '/shops',
  CLOVERWEBHOOK: '/integrations/cloverwebhook',
  KDSDASHBOARD: '/vieworders',
  DINING: '/dining',
  DINING_LIST: '/dininglist',
  DINING_VIEW: '/diningdetails',
  TABLE_RESERVATION: '/reservations',
  CUSTOMER_SUPPORT: '/supportcustomer',
  SERVICERATE: '/service-rates',
  DINNINGDETAILS: '/diningdetails',
  MENUS: '/menus',
  PRE_ORDERS: '/preorder',
  FEEDBACK: '/feedback',
  ADVANCEFEES: 'settings/advancefee',
  CONTACTS: '/contacts',
  MANAGE_ACCOUNTS: '/manageaccounts',
  ACCOUNT_SESSIONS: '/accountsessions',
  DONATIONPAGES: '/donation-pages',
};

export const COUNTRIES = {
  USA: {
    id: 1,
    name: 'USA',
    PHONE_CODE: '+1',
  },
  INDIA: {
    id: 2,
    name: 'INDIA',
    PHONE_CODE: '+91',
  },
  CANADA: {
    id: 41,
    name: 'CANADA',
    PHONE_CODE: '+1',
  },
};

export const TOKENS = {
  ACCESS_TOKEN: 'access_token',
  SESSION_TOKEN: 'sp_session',
  REFRESH_TOKEN: 'refresh_token',
  REFERRAL_CODE: 'referral_code',
  AFFILIATE_CODE: 'affiliate_code',
};

export const MESSAGES = {
  OTP_ERROR: 'Incorrect OTP',
  SWITCH_ACCOUNT_ERROR: 'Unable to switch account',
  API_ERROR: 'Unable to process request. Please try again later',
  SESSION_ERROR: 'Unable to process request. Please do login again',
  UPDATEPASSWORD: 'Password has been updated successfully',
  ENABLE_LIVETRANSFER: 'Enabled live transfer successfully',
  FILE_UPLOAD: {
    SUCCESS: 'File uploaded successfully',
    ERROR: 'Failed to upload',
  },
  QR_Download: 'QR downloaded successfully',
  ADD_USER: {
    PHONE: 'Must be a valid phone number',
  },
  AUTH_CHALLENGE: {
    SUCCESS: 'Password has been set',
    MATCH: 'Must match password typed above',
    ERROR: 'Password should have minimum 8 characters including numbers, special characters, uppercase and lowercase letters',
  },
  LOGIN: {
    ERROR: 'Please make sure your email and password are valid',
  },
  REGISTER: {
    SUCCESS: 'Password has been sent to your email',
  },
  RESET_PASSWORD: {
    SUCCESS: 'Your password has been reset',
    ERROR: 'Please make sure your email and confirmation code are correct, and password has minimum'
      + ' 8 characters including numbers, uppercase and lowercase letters',
  },
  MEMBERSHIP: {
    ADD_SUCCESS: 'Member added successfully',
    ADD_ERROR: 'Error while adding member',
    UPDATE_SUCCESS: 'Updated Successfully',
    DELETE_SUCCESS: 'Deleted member successfully',
    INTERNAL_ADD_SUCCESS: 'Internal user added successfully',
    INTERNAL_UPDATE_SUCCESS: 'Internal user updated successfully',
    INTERNAL_DELETE_SUCCESS: 'Internal user deleted successfully',
  },
  SETTINGS: {
    UPDATE_SUCCESS: 'Updated successfully',
    UPDATE_ERROR: 'Error while updating account',
  },
  REFUND: {
    SUCCESS: 'Request processed successfully',
    ERROR: 'Error processing refund',
  },
  CUSTOMERS: {
    ADD_SUCCESS: 'Customer added successfully',
    ADD_ERROR: 'Error while adding customer',
    UPDATE_SUCCESS: 'Updated successfully',
    DELETE_SUCCESS: 'Deleted customer successfully',
  },
  PUBLICKEYS: {
    ADD_SUCCESS: 'Publickey added successfully',
    ADD_ERROR: 'Error while adding Publickey',
    UPDATE_SUCCESS: 'Updated successfully',
    DELETE_SUCCESS: 'Deleted Publickey successfully',
  },
  SECRETKEYS: {
    ADD_SUCCESS: 'Secretkey added successfully',
    ADD_ERROR: 'Error while adding secretkey',
    UPDATE_SUCCESS: 'Updated successfully',
    DELETE_SUCCESS: 'Deleted secretkey successfully',
  },
  BUSINESS: {
    ADD_SUCCESS: 'Business added successfully',
    EDIT_SUCCESS: 'Business edited successfully',
    ADD_FAILED: 'Minimum 2 business representatives needed',
  },
  BANK_DETAILS: {
    MATCH: 'Must match account number typed above',
    VALID: 'Account number must be valid',
    ROUTING: 'Routing number must be valid',
    IFSC: 'IFSC code must be valid',
    UPI: 'UPI Id must be valid',
  },
  BUSINESS_DETAILS: {
    CIN: 'CIN must be valid',
    EIN: 'Employer identification must be valid',
    TAN: 'TAN must be valid',
    GST: 'GST must be valid',
    PAN: 'PAN must be valid',
    LLP: 'LLP ID must be valid',
    SSN: 'SSN must be valid',
  },
  SUPPORT: {
    SUCCESS: 'Your message sent Successfully',
    ERROR: 'Error while sending message',
    UPDATE_SUCCESS: 'Updated Successfully',
  },
  PAPER_ROLL: {
    SUCCESS: 'Your request sent Successfully',
    ERROR: 'Error while sending request',
  },
  REPORT: {
    GENERATE_SUCCESS: 'Report generated successfully',
    GENERATE_ERROR: 'Error while generating report',
    DOWNLOAD_ERROR: 'Error while downloading report',
    NODATA: 'No data available',
    DELETE_SUCCESS: 'Report deleted successfully',
  },
  LOCATIONS: {
    ADD_SUCCESS: 'Location added successfully',
    UPDATE_SUCCESS: 'Updated successfully',
  },
  TERMINAL: {
    UPDATE_SUCCESS: 'Updated successfully',
    DOWNLOAD_ERROR: 'Error while downloading proof',
  },
  LOCATION_DETAILS: {
    UPDATE_SUCCESS: 'Updated successfully',
    DELETE_SUCCESS: 'Deleted location successfully',
    REMOVE_SUCCESS: 'Removed terminal successfully',
  },
  PAYMENTSDETAILS: {
    UPDATE_SUCCESS: 'Updated successfully',
    REFUND_SUCCESS: 'Refunded successfully',
    DELETE_SUCCESS: 'Deleted location successfully',
    DISPUTE_SUCCESS: 'Dispute raised successfully',
    ERROR: 'Unable to process request. Please try again later',
  },
  PAYMENTS: {
    UPDATE_SUCCESS: 'Payment Updated successfully',
  },
  PAYOUT: {
    UPDATE_SUCCESS: 'Payout Updated successfully',
  },
  USERDETAILS: {
    UPDATE_SUCCESS: 'Updated successfully',
    UPDATE_ERROR: 'Error while updating account',
  },
  NOTIFY: {
    SUCCESS: 'Notification sent successfully',
    ERROR: 'Error processing Notification',
  },
  RECEIPT: {
    SUCCESS: 'Request processed successfully',
    ERROR: 'Error processing receipt',
    DOWNLOAD_ERROR: 'Error while downloading receipt',
  },
  ACCOUNT: {
    CREATE_SUCCESS: 'Account created successfully',
    CREATE_ERROR: 'Error while creating account',
    UPDATE_SUCCESS: 'Account updated successfully',
    UPDATE_ERROR: 'Error while updating account',
    MERCHANT_UPDATE_SUCCESS: 'Merchant details updated successfully',
  },
  EVIDENCE: {
    SUCCESS: 'Evidence submitted successfully',
  },
  PAYMENTSLINK: {
    ADD_SUCCESS: 'Payment link added successfully',
    SUCCESS: 'Payment link url copied to clipboard!',
    DELETE_SUCCESS: 'Payment link url cancelled successfully',
    UPDATE_SUCCESS: 'Payment link description updated successfully',
    FUNDINGSOURCE_LINK_COPY: 'Funding source link url copied to clipboard!',
  },
  SUBSCRIPTION: {
    ADD_SUCCESS: 'Subscription added successfully',
    SUCCESS: 'Subscription link url copied to clipboard!',
    DELETE_SUCCESS: 'Subscription cancelled successfully',
  },
  PLANS: {
    ADD_SUCCESS: 'Plan added successfully',
  },
  PRODUCT: {
    ADD_SUCCESS: 'Product added successfully',
    UPDATE_SUCCESS: 'Product updated successfully',
    DELETE_SUCCESS: 'Deleted product successfully',
  },
  BENEFICIARY: {
    ADD_SUCCESS: 'Beneficiary added successfully',
    UPDATE_SUCCESS: 'Beneficiary updated successfully',
    DELETE_SUCCESS: 'Beneficiary product successfully',
  },
  VENDORS: {
    ADD_SUCCESS: 'Contact added successfully',
    DELETE_SUCCESS: 'Contact deleted successfully',
    UPDATE_SUCCESS: 'Contact updated successfully',
  },
  INVOICE: {
    ADD_SUCCESS: 'Invoice added successfully',
    DELETE_SUCCESS: 'Deleted invoice successfully',
    CANCEL_SUCCESS: 'Cancelled invoice successfully',
    UPDATE_SUCCESS: 'Invoice updated successfully',
    REFUND_SUCCESS: 'Invoice refunded successfully',
    PAY_SUCCESS: 'Invoice paid successfully',
    DOWNLOAD_ERROR: 'Error while downloading invoice',
    SUCCESS: 'Invoice link url copied to clipboard!',
  },
  REMINDERS: {
    SUCCESS: 'Reminders sent successfully',
  },
  PAYMENTPAGES: {
    SUCCESS: 'Payment Pages link url copied to clipboard!',
    ADD_SUCCESS: 'Payment page added successfully',
  },
  PAYMENTPAGESDETAILS: {
    UPDATE_SUCCESS: 'Updated successfully',
    DELETE_SUCCESS: 'Deactivated payment page successfully',
    SOLDOUT_SUCCESS: 'Sold out payment page successfully',
    RESUME_SUCCESS: 'Resume sales successfully',
    ERROR: 'Unable to process request. Please try again later',
    OFFLINE_DELETE_SUCCESS: 'Deleted offline payment successfully',
  },
  PAYMENTSBUTTON: {
    ADD_SUCCESS: 'Payment button added successfully',
    SUCCESS: 'Payment button url copied to clipboard!',
    DELETE_SUCCESS: 'Payment button deactivated successfully',
    BUTTON_SUCCESS: 'Payment button copied to clipboard!',
    UPDATE_SUCCESS: 'Payment button description updated successfully',
  },
  PAYMENTCHECKOUT: {
    ADD_SUCCESS: 'Payment checkout added successfully',
    SUCCESS: 'Payment checkout url copied to clipboard!',
  },
  SUBSCRIPTION_BUTTON: {
    ADD_SUCCESS: 'Subscription button added successfully',
    SUCCESS: 'Subscription button url copied to clipboard!',
    DELETE_SUCCESS: 'Subscription button deactivated successfully',
    BUTTON_SUCCESS: 'Subscription button copied to clipboard!',
    UPDATE_SUCCESS: 'Subscription button description updated successfully',
  },
  WEBHOOK: {
    ADD_SUCCESS: 'Webhook added successfully',
    UPDATE_SUCCESS: 'Webhook updated successsfully',
    DELETE_SUCCESS: 'Webhook deleted successfully',
    BUTTON_SUCCESS: 'Webhook secret copied to clipboard!',
  },
  WEBHOOK_EVENT: {
    ERROR: 'Error occured while adding event',
  },
  QUICKBOOKS: {
    ADD_SUCCESS: 'Quickbooks integration completed successfully',
    ADD_ERROR: 'Error while Quickbooks integration',
  },
  REFERRAL: {
    ADD_SUCCESS: 'Referral added successfully',
    NOTIFY_SUCCESS: 'Referral link has been resent to ',
    REFERRAL_SUCCESS: 'Referral link copied to clipboard!',
  },
  RATES: {
    ADD_SUCCESS: 'Rate added successfully',
    DELETE_SUCCESS: 'Deleted rate successfully',
  },
  ISSUER_RATE: {
    ADD_SUCCESS: 'Rate added successfully',
  },
  ACQUIRER: {
    ADD_SUCCESS: 'Acquirer added successfully',
    UPDATE_SUCCESS: 'Acquirer updated successfully',
    DELETE_SUCCESS: 'Acquirer deleted successfully',
  },
  ISSUER: {
    ADD_SUCCESS: 'Issuer added successfully',
    UPDATE_SUCCESS: 'Issuer updated successfully',
    DELETE_SUCCESS: 'Issuer deleted successfully',
  },
  SETTLEMENT: {
    IMS_ADD_SUCCESS: 'IMS batch created successfully',
    STRIPE_ADD_SUCCESS: 'Stripe batch created successfully',
    AFFINIPAY_ADD_SUCCESS: 'Affinipay batch created successfully',
  },
  ACHPAYMENT: {
    CUSTOMER_ADD_SUCCESS: 'Dwolla customer added successfully',
  },
  TAXRATE: {
    ADD_SUCCESS: 'Taxrate added successfully',
    DELETE_SUCCESS: 'Taxrate deleted successfully',
  },
  COUPONS: {
    ADD_SUCCESS: 'Coupon added successfully',
    DELETE_SUCCESS: 'Coupon deactivated successfully',
    PROMOTION_SUCCESS: 'Promotional code added successfully',
    PROMAOTION_DELETE_SUCCESS: 'Promotional code deactivated successfully',
    ADD_FAILED: 'Coupon added successfully. Error occured while adding promotional code.',
    UPDATE_SUCCESS: 'Coupon updated successfully',
  },
  OFFERS: {
    ADD_SUCCESS: 'Offer added successfully',
    DELETE_SUCCESS: 'Offer deactivated successfully',
    UPDATE_SUCCESS: 'Offer updated successfully',
  },
  AFFILIATE: {
    ADD_SUCCESS: 'Affiliate added successfully',
    NOTIFY_SUCCESS: 'Affiliate link has been resent to ',
    AFFILIATE_SUCCESS: 'Affiliate link copied to clipboard!',
    SWITCH_SUCCESS: 'Account switched successfully',
  },
  SALESFORCE: {
    ADD_SUCCESS: 'Salesforce integration completed successfully',
    ADD_ERROR: 'Error while Salesforce integration',
    UPDATE_SUCCESS: 'Credentials updated successfully',
    ADD_MAPPING_SUCCESS: 'Salesforce mapping completed successfully',
    UPDATE_MAPPING_SUCCESS: 'Salesforce mapping updated successfully',
    DELETE_MAPPING_SUCCESS: 'Salesforce mapping deleted successfully',
  },
  SCHEDULE_REPORTS: {
    ADD_SUCCESS: 'Schedule Report added successfully',
    DELETE_SUCCESS: 'Schedule Report deleted successfully',
    DOWNLOAD_ERROR: 'Error while downloading schedule report',
  },
  ADMIN_TERMINAL: {
    ADD_SUCCESS: 'Terminal added successfully',
  },
  ADMIN_TERMINALDETAILS: {
    DELETE_SUCCESS: 'Terminal deleted successfully',
    ERROR: 'Unable to process request. Please try again later',
    TERMINAL_PROOF_ADD: 'Terminal proof added successfully',
    TERMINAL_PROOF_UPDATE: 'Terminal proof updated successfully',
  },
  SP_FIELD: {
    ADD_SUCCESS: 'SP Field added successfully',
    ADD_ERROR: 'Error while adding sp field',
    UPDATE_SUCCESS: 'Updated successfully',
    DELETE_SUCCESS: 'Deleted sp field successfully',
  },
  SP_OBJECT: {
    ADD_SUCCESS: 'SP Object added successfully',
    ADD_ERROR: 'Error while adding sp object',
    UPDATE_SUCCESS: 'Updated successfully',
    DELETE_SUCCESS: 'Deleted sp object successfully',
  },
  POSKEY: {
    UPDATE_SUCCESS: 'POS Key updated successfully',
    UPDATE_ERROR: 'Error while updating account',
  },
  GLOBAL_RESTRICTED_KEY: {
    ADD_SUCCESS: 'GR Key added successfully',
    UPDATE_SUCCESS: 'GR Key updated successsfully',
    DELETE_SUCCESS: 'GR Key deleted successfully',
  },
  PAYMENTSWIDGETS: {
    ADD_SUCCESS: 'Payment widget added successfully',
    SUCCESS: 'Payment widget url copied to clipboard!',
    DELETE_SUCCESS: 'Payment widget deactivated successfully',
    BUTTON_SUCCESS: 'Payment widget copied to clipboard!',
    UPDATE_SUCCESS: 'Payment widget updated successfully',
  },
  CASH_TRANSACTIONS: {
    VOID_SUCCESS: 'Payment voided successfully',
  },
  SERVICES: {
    UPDATE_SUCCESS: 'Service request updated successfully',
  },
  CUSTOM_DOCUMENT: {
    UPDATE_SUCCESS: 'Document uploaded successfully',
    FAILED: 'Error while updating document',
    ADD_SUCCESS: 'Document added successfully',
    DOWNLOAD_ERROR: 'Error while downloading document',
  },
  TRANSFERS: {
    ADD_SUCCESS: 'Transfer added successfully',
    OTP_SUCCESS: 'OTP sent successfully',
    UPDATE_SUCCESS: 'Transfer updated successfully',
    CANCEL_SUCCESS: 'Transfer cancelled successfully',
  },
  BULKTRANSFER: {
    ADD_SUCCESS: 'Bulk Transfer added successfully',
  },
  TRANSFERLINKS: {
    ADD_SUCCESS: 'Transfer link added successfully',
    SUCCESS: 'Transfer link url copied to clipboard!',
  },
  ADMIN_BIN: {
    UPDATE_SUCCESS: 'Bin updated successfully',
    ADD_SUCCESS: 'Bin added successfully',
  },
  FUNDINGSOURCE: {
    ADD_SUCCESS: 'Funding source added successfully',
  },
  FUNDINGSOURCELINK: {
    ADD_SUCCESS: 'Funding source link added successfully',
  },
  ICICI_CUSTOMER: {
    REGISTER_SUCCESS: 'ICICI customer added successfully',
    FUNDING_SOURCE_SUCCESS: 'Funding source added successfully',
    STATUS_SUCCESS: 'Updated successfully',
  },
  PARTNER_SETTLEMENT: {
    ADD_SUCCESS: 'Added partner settlement successfully',
    DELETE_SUCCESS: 'Deleted partner settlement successfully',

  },
  CUSTOM_DOCUMENT_REQUEST: {
    ADD_SUCCESS: 'Added custom document request successfully',
    UPDATE_SUCCESS: 'Updated custom document request successfully',
    DELETE_SUCCESS: 'Deleted custom document request successfully',
  },
  ACCOUNT_SETTLEMENT: {
    ADD_SUCCESS: 'Added account settlement successfully',
    DELETE_SUCCESS: 'Deleted account settlement successfully',
  },
  OCRREQUEST: {
    ADD_SUCCESS: 'OCR REQUEST added successfully',
    ADD_ERROR: 'Error while adding OCR REQUEST',
  },
  ACCOUNTSLUG: {
    ADD_SUCCESS: 'Account slug added successfully',
    LINK_SUCCESS: 'Account slug linked successfully',
    UNLINK_SUCCESS: 'Account slug unlinked successfully',
  },
  WALLET: {
    ADD_SUCCESS: 'Wallet added successfully',
    BANK_ADD_SUCCESS: 'Bank added successfully',
    CARD_ADD_SUCCESS: 'Card added successfully',
    CARD_DELETE_SUCCESS: 'Card deleted successfully',
    CARD_UPDATE_SUCCESS: 'Card updated successfully',
    LOAD_BALANCE: 'Loaded amount successfully',
    WITHDRAW_BALANCE: 'Withdraw amount intiated',
    PIN_SUCCESS: 'PIN added successfully',
    ALIAS_COPIED: 'Alias copied successfully',
    APPLY_CASHBACK: 'Applied cash back successfully',
  },
  STATICQR: {
    ADD_SUCCESS: 'Static QR added successfully',
    DELETE_SUCCESS: 'Static QR deleted successfully',
    UPDATE_SUCCESS: 'Static QR updated successfully',
  },
  REPORTTEMPLATE: {
    ADD_SUCCESS: 'Template added successfully',
    UPDATE_SUCCESS: 'Template updated successfully',
    DELETE_SUCCESS: 'Template deleted successfully',
  },
  USONBOARDING: {
    ADD_SUCCESS: 'Link generated successfully',
  },
  INVENTORY_ORDERS: {
    SUCCESS: 'Order receipt url copied to clipboard!',
    UPDATE_ITEM: 'Item updated successfully',
    UPDATE_CATEGORY: 'Category updated successfully',
    UPDATE_MODIFIER: 'Modifier updated successfully',
    ADD_ITEM: 'Item added successfully',
    ADD_CATEGORY: 'Category added successfully',
    ADD_MODIFIER: 'Modifier added successfully',
    UPDATE_SORT_ITEM: 'Sort items updated successfully',
    UPDATE_SORT_CATEGORY: 'Sort categories updated successfully',
    UPDATE_SORT_MODIFIER: 'Sort modifier group updated successfully',
    DELETE_ITEM: 'Item deleted successfully',
    DELETE_CATEGORY: 'Category deleted successfully',
    DELETE_MODIFIER: 'Modifier deleted successfully',
    DELETE_MODIFIERGROUP: 'Modifier group deleted successfully',
    ADD_ORDERTYPE: 'Order type added successfully',
    UPDATE_ORDERTYPE: 'Order type updated successfully',
    DELETE_ORDERTYPE: 'Order type deleted successfully',
    ADD_MODIFIER_GROUP: 'Modifier group added successfully',
    UPDATE_MODIFIER_GROUP: 'Modifier group updated successfully',
    ADD_PRINTER_LABEL: 'Label added successfully',
    UPDATE_PRINTER_LABEL: 'Label updated successfully',
    DELETE_PRINTER_LABEL: 'Label deleted successfully',
    ADD_SHOPS: 'Shop added successfully',
    UPDATE_SHOPS: 'Shop updated successfully',
    DELETE_SHOPS: 'Shop deleted successfully',
  },
  EVENTS: {
    ADD_SUCCESS: 'Event added successfully',
    DEACTIVATED: 'Event deactivated successfully',
    SOLDOUT: 'Event updated successfully',
    UPDATE_SUCCESS: 'Event updated successfully',
  },
  SERVICERATE: {
    ADD_SUCCESS: 'Servicerate added successfully',
    DELETE_SUCCESS: 'Servicerate deleted successfully',
  },
  ADVANCEFEES: {
    ADD_SUCCESS: 'Advance Fees added successfully',
    UPDATE_SUCCESS: 'Advance Fees updated successfully',
    DELETE_SUCCESS: 'Advance Fees deleted successfully',
  },
};

export const PHONE_LENGTH = {
  MIN: 7,
  MAX: 14,
};

export const BUSINESS_TYPES = {
  1: [
    {
      value: 'LIMITED_LIABILITY_COMPANY',
      display: 'Limited liability company',
    },
    {
      value: 'PARTNERSHIP',
      display: 'Partnership',
    },
    {
      value: 'INDIVIDUAL_OR_SOLE_PROPRIETORSHIP',
      display: 'Individual or sole proprietorship',
    },
    {
      value: 'NONPROFIT_ORGANIZATION',
      display: 'Nonprofit organization',
    },
    {
      value: 'PRIVATE_CORPORATION',
      display: 'Private Corporation',
    },
    {
      value: 'PUBLIC_CORPORATION',
      display: 'Public Corporation',
    },
    {
      value: 'GOVERNMENT',
      display: 'Government',
    },
  ],
  2: [
    {
      value: 'PRIVATE_LIMITED',
      display: 'Private Limited',
    },
    {
      value: 'PUBLIC_LIMITED',
      display: 'Public Limited',
    },
    {
      value: 'LIMITED_LIABILITY_PARTNERSHIP',
      display: 'Limited Liability Partnership',
    },
    {
      value: 'PROPRIETORSHIP',
      display: 'Proprietorship',
    },
    {
      value: 'NONPROFIT_ORGANIZATION',
      display: 'Nonprofit organization',
    },
  ],
};

export const PAYOUT_STATUSES = {
  IN_TRANSIT: 'In Transit',
  PENDING: 'Pending',
  PAID: 'Paid',
  WITHDRAWN: 'Withdrawn',
  FAILED: 'Failed',
};

export const PAYMENT_STATUSES = {
  SUCCEEDED: 'Succeeded',
  CANCELLED: 'Cancelled',
  REFUNDED: 'Refunded',
  PARTIAL_REFUNDED: 'Partially refunded',
  REQUIRE_CAPTURE: 'Uncaptured',
  DISPUTED: 'Disputed',
  DISPUTE_WON: 'Disputed',
  DISPUTE_LOST: 'Disputed',
  REQUIRE_PAYMENT_METHOD: 'Require payment method',
  REQUIRE_CONFIRMATION: 'Require confirmation',
  REQUIRE_ACTION: 'Require action',
  FAILED: 'Failed',
  PAYMENT_PENDING: 'Payment pending',
  DECLINED: 'Declined',
  REFUND_PENDING: 'Refund pending',
  DISPUTE_PENDING: 'Disputed',
  DISPUTE_REVIEWING: 'Disputed',
  DISPUTED_NEEDS_RESPONSE: 'Disputed',
  DISPUTED_UNDER_REVIEW: 'Disputed',
  Passed: 'Passed',
  REFUND_PROCESSING: 'Refund processing',
  Provided: 'Provided',
  PROCESSING: 'Processing',
};

export const RESERVATION_STATUSES = {
  RESERVED: 'Reserved',
  OCCUPIED: 'Occupied',
  CLOSED: 'Closed',
  ABANDONED: 'Abandoned',
  CANCELED: 'Cancelled',
};

export const TRANSFER_STATUSES = {
  SUCCEEDED: 'Succeeded',
  CANCELLED: 'Cancelled',
  REQUIRE_CAPTURE: 'Uncaptured',
  REQUIRE_FUNDING_SOURCE: 'Require Funding Source',
  REQUIRE_CONFIRMATION: 'Require Confirmation',
  REQUIRE_ACTION: 'Require action',
  FAILED: 'Failed',
  PROCESSING: 'Processing',
  TRANSFER_PENDING: 'Transfer Pending',
  DECLINED: 'Declined',
  TRANSFER_SCHEDULED: 'Transfer Scheduled',
};

export const TRANSFERLINKS_STATUSES = {
  ACTIVE: 'Active',
  PAID: 'Paid',
  CANCELLED: 'Cancelled',
  DECLINED: 'Declined',
  EXPIRED: 'Expired',
  READY_TO_FUND: 'Confirm OTP',
  PROCESSING: 'Processing',
};

export const TRANSFERLINK_DISABLE_STATUS = {
  ACTIVE: 'ACTIVE',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED',
  READY_TO_FUND: 'READY_TO_FUND',
  PROCESSING: 'PROCESSING',
};

export const SETTLEMENT_STATUSES = {
  SUCCESS: 'Success',
  FAILURE: 'Failure',
  PROCESSING: 'Processing',
};

export const CUSTOMDOCUMENT_STATUSES = {
  PENDING: 'Pending',
  SUBMITTED: 'Submitted',
};

export const CUSTOMDOCUMENT_CONSTANTS = {
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
};

export const INVOICE_STATUSES = {
  ACTIVE: 'Payment Due',
  DRAFT: 'Draft',
  PAST_DUE: 'Outstanding',
  CANCELLED: 'Cancelled',
  PAID: 'Paid',
  INCOMPLETE: 'Incomplete',
  PROCESSING: 'Processing',
  REFUNDED: 'Refunded',
  PARTIAL_REFUNDED: 'Partially refunded',
  PARTIALLY_REFUNDED: 'Partially refunded',
};

export const INVOICE_TYPES = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  PAST_DUE: 'PAST_DUE',
  CANCELLED: 'CANCELLED',
  PAID: 'PAID',
  INCOMPLETE: 'INCOMPLETE',
  REFUNDED: 'REFUNDED',
  PARTIAL_REFUNDED: 'PARTIAL_REFUNDED',
  PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED',
};

export const PAYMENTLINK_STATUSES = {
  ACTIVE: 'Active',
  PAID: 'Paid',
  EXPIRED: 'Expired',
  CANCELLED: 'Cancelled',
  DECLINED: 'Declined',
  ACTIVECANCEL: 'ACTIVE',
};

export const INVENTORY_ORDERS_STATUSES = {
  CREATED: 'Created',
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  CANCELLED: 'Cancelled',
  CLOSED: 'Closed',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  UPDATED: 'Updated',
};

export const INVENTORY_ORDERS_PAYMENT_STATUSES = {
  UN_PAID: 'Un Paid',
  PAID: 'Paid',
  REFUND_PROCESSING: 'Refund Processing',
  PARTIALLY_REFUNDED: 'Partially Refunded',
  REFUNDED: 'Refunded',
  PARTIALLY_PAID: 'Partially Paid',
};

export const PAYMENTWIDGET_STATUSES = {
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  EXPIRED: 'Expired',
  CANCELLED: 'Cancelled',
  DEACTIVE: 'DEACTIVATED',
};

export const ACHPAYMENT_STATUSES = {
  ACTIVE: 'Activate',
  DISABLED: 'Disabled',
  PENDING: 'Bank Verification Pending',
  VERIFIED: 'Verified',
  VERIFICATION_PENDING: 'Verification Pending',
};

export const BANKTRANSFER_STATUSES = {
  DISABLED: 'Disabled',
  VERIFIED: 'Verified',
  VERIFICATION_PENDING: 'Verification Pending',
  BANK_VERIFICATION_PENDING: 'Bank Verification Pending',
  PENDING_FOR_SELF_APPROVAL: 'Bank Verification Pending',
};

export const BANK_TRANSFER_STATUSES = {
  PENDING_FOR_SELF_APPROVAL: 'PENDING_FOR_SELF_APPROVAL',
  BANK_VERIFICATION_PENDING: 'BANK_VERIFICATION_PENDING',
  VERIFICATION_PENDING: 'VERIFICATION_PENDING',
  VERIFIED: 'VERIFIED',
};

export const CATALOGORDER_STATUSES = {
  PROCESSED: 'Processed',
  PROCESSING: 'Processing',
  SHIPPED: 'Shipped',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
  RETURNED: 'Returned',
};

export const ORDER_MODES = [
  {
    id: 1,
    value: 'PRE_ORDER',
  },
  {
    id: 2,
    value: 'ONLINE_ORDER',
  },
  {
    id: 3,
    value: 'DELIVERY',
  },
  {
    id: 4,
    value: 'DINE_IN',
  },
];


export const STORE_TYPES = [{
  id: 'DINE_IN',
  value: 'DINE_IN',
},
{
  id: 'WEB',
  value: 'WEB',
},
{
  id: 'NON_PROFIT',
  value: 'NON_PROFIT',
},
];
export const ORDER_MODE_DISPLAY = {
  PRE_ORDER: 'PRE ORDER',
  PICKUP: 'PICKUP',
  DELIVERY: 'DELIVERY',
};

export const ISSUER_TYPES = [
  {
    id: 1,
    value: 'Dwolla',
  },
  {
    id: 2,
    value: 'ICICI Bank Issuer',
  },
];

export const ICICI_BANK_STATUS = [
  {
    id: 1,
    name: 'Disabled',
    value: 'DISABLED',
  },
  {
    id: 2,
    name: 'Bank Verification Pending',
    value: 'BANK_VERIFICATION_PENDING',
  },
  {
    id: 3,
    name: 'Verification Pending',
    value: 'VERIFICATION_PENDING',
  },
  {
    id: 4,
    name: 'Verified',
    value: 'VERIFIED',
  },
];

export const DWOLLA_STATUS = [
  {
    id: 1,
    name: 'Disabled',
    value: 'DISABLED',
  },
  {
    id: 2,
    name: 'Bank Verification Pending',
    value: 'BANK_VERIFICATION_PENDING',
  },
  {
    id: 3,
    name: 'Activate',
    value: 'TO_ACTIVATE',
  },
  {
    id: 4,
    name: 'Verification Pending',
    value: 'VERIFICATION_PENDING',
  },
  {
    id: 5,
    name: 'Verified',
    value: 'VERIFIED',
  },
];

export const CASHFREE_STATUS = [
  {
    id: 1,
    name: 'Bank Verification Pending',
    value: 'BANK_VERIFICATION_PENDING',
  },
  {
    id: 2,
    name: 'Verification Pending',
    value: 'VERIFICATION_PENDING',
  },
  {
    id: 3,
    name: 'Verified',
    value: 'VERIFIED',
  },
];

export const BANK_TRANSFER_ACCOUNT_TYPE = [
  {
    id: 1,
    name: 'Current Account',
    value: 'CURRENT',
  },
  {
    id: 2,
    name: 'Savings',
    value: 'SAVINGS',
  },
  {
    id: 3,
    name: 'Checking',
    value: 'CHECKING',
  },
];

export const BENE_TRANSFER_TYPE = [
  {
    id: 1,
    name: 'Bank',
    value: 'BANK',
  },
];

export const FUNDINGSOURCE_STATUSES = {
  VERIFIED: 'Verified',
  NOTVERIFIED: 'Not Verified',
};

export const FUNDINGSOURCE_LINK_STATUSES = {
  CREATED: 'Created',
  SUCCEEDED: 'Succeeded',
  EXPIRED: 'Expired',
};

export const BULKTRANSFER_STATUSES = {
  CREATED: 'Created',
  SUCCEEDED: 'Succeeded',
  PROCESSING: 'Processing',
  FAILED: 'Failed',
  PARTIAL_SUCCEEDED: 'Partially Succeeded',
  NOT_VERIFIED: 'Verification pending',
  VERIFIED: 'Verified',
  VERIFICATION_FAILED: 'Verification failed',
};

export const ACHPAYMENT_TYPES = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  VERIFICATION_PENDING: 'VERIFICATION_PENDING',
};

export const PAYMENTPAGE_STATUSES = {
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  DELETED: 'Deleted',
  CANCELLED: 'Cancelled',
  SOLDOUT: 'Sold Out',
  ACTIVATED: 'ACTIVE',
  ACTIVECANCEL: 'CANCELLED',
  ACTIVEDEACTIVATED: 'DEACTIVATED',
};

export const SUBSCRIPTION_STATUSES = {
  ACTIVE: 'Active',
  DRAFT: 'Draft',
  PAST_DUE: 'Outstanding',
  INCOMPLETE: 'Incomplete',
  CANCELLED: 'Cancelled',
  PAID: 'Paid',
  CREATE: 'Create',
  ACTIVECANCEL: 'CANCELLED',
  ACTIVEPAID: 'PAID',
  ACTIVEDRAFT: 'DRAFT',
  PROCESSING: 'Processing',
};

export const QUICKBOOK_STATUSES = {
  SUCCEEDED: 'Succeeded',
  FAILED: 'Failed',
  PENDING: 'Pending',
};

export const SALESFORCE_STATUSES = {
  SUCCEEDED: 'Succeeded',
  FAILED: 'Failed',
  PENDING: 'Pending',
};

export const PAYMENT_TYPES = {
  SUCCEEDED: 'SUCCEEDED',
  CANCELLED: 'CANCELLED',
  REFUNDED: 'REFUNDED',
  UNCAPTURED: 'UNCAPTURED',
  DISPUTED: 'DISPUTED',
  DISPUTED_RESPONSE: 'DISPUTED_NEEDS_RESPONSE',
  NEEDS_RESPONSE: 'NEEDS_RESPONSE',
  DISPUTED_REVIEW: 'DISPUTE_UNDER_REVIEW',
  PARTIAL_REFUNDED: 'PARTIAL_REFUNDED',
  REQUIRE_CAPTURE: 'REQUIRE_CAPTURE',
  REFUND_PROCESSING: 'REFUND_PROCESSING',
  REQUIRE_PAYMENT_METHOD: 'REQUIRE_PAYMENT_METHOD',
};

export const ROLES = {
  ADMINISTRATOR: 'administrator',
  OWNER: 'owner',
  DEVELOPER: 'developer',
  OPERATIONS: 'operations',
  ANALYST: 'analyst',
  SUPPORT: 'support',
  POS: 'pos',
  DEVICE: 'device',
  SUPERUSER: 'superuser',
  ONBOARDING: 'onboarding',
  PARTNER: 'partner',
  ASSOCIATE: 'associate',
  INTERNALSUPPORT: 'internalsupport',
  KDSUSER: 'KDS-USER',
  KDSADMIN: 'KDS-ADMIN',
  KDSUSER1: 'kdsuser',
  KDSADMIN1: 'kdsadmin',
};

export const SCHEDULE_REPORTS_STATUSES = {
  ACTIVE: 'Active',
  ERROR: 'Error',
  PROCESSING: 'Processing',
};

export const MAX_BUSINESS_REPRESENTATIVES = 4;

export const SUPPORT_PHONE = '1-(800)-675-6486';

export const PRIVACY_STATEMENT = 'https://policies.swirepay.com';

export const CONTACT_US_EMAIL = 'letstalk@swirepay.com';

export const NEED_HELP_EMAIL = 'support@swirepay.com';

export const REPORTS_TYPES = [
  {
    value: 'PAYOUT',
    display: 'Payout',
  },
  {
    value: 'PAYMENT',
    display: 'Payment',
  },
  {
    value: 'REFUND',
    display: 'Refund',
  },
  {
    value: 'PAYOUT_DETAIL',
    display: 'Payout Detail',
  },
  {
    value: 'TERMINAL_PAYOUT',
    display: 'Terminal Payout',
  },
  {
    value: 'TRANSFER_SESSION',
    display: 'Transfer Session',
  },
  {
    value: 'TERMINAL_ACTIVITY',
    display: 'Terminal Activity',
  },
];

export const REPORTS_REQUIRED = {
  ACTIVITY: 'TERMINAL_ACTIVITY',
  CHECKOUT_PAYMENTS: 'DUPLICATE_CHECKOUT_PAYMENTS',
  FETCH_ALL_PAYMENTS: 'FETCH_ALL_PAYMENTS',
  FETCH_ALL_CARD_PAYMENTS: 'FETCH_ALL_CARD_PAYMENTS',
  FETCH_ALL_UPI_PAYMENTS: 'FETCH_ALL_UPI_PAYMENTS',
};

export const REPORTS_PERIOD = {
  yesterday: 'Yesterday',
  daily: 'Daily Report',
  monthly: 'Monthly Report',
  custom: 'Custom Report',
};

export const ADMIN_REPORTS_TYPES = [
  {
    value: 'TERMINAL_ACTIVITY',
    display: 'Terminal Activity',
  },
  {
    value: 'TERMINAL_PENDING_RECONCILIATION_SUMMARY',
    display: 'Terminal Pending Reconciliation Summary',
  },
  {
    value: 'TERMINAL_PENDING_RECONCILIATION_DETAILS',
    display: 'Terminal Pending Reconciliation Details',
  },
  {
    value: 'UNASSIGNED_SERVICE_COMPLAINTS',
    display: 'Unassigned Service Complaints',
  },
  {
    value: 'PAYMENT_SESSION_EXPORT',
    display: 'Payment Session Export',
  },
  {
    value: 'IMS_DATA_EXPORT',
    display: 'IMS Data Export',
  },
  {
    value: 'DUPLICATE_CHECKOUT_PAYMENTS',
    display: 'Duplicate Checkout Payments',
  },
  {
    value: 'FETCH_ALL_PAYMENTS',
    display: 'Fetch all Payments',
  },
  {
    value: 'FETCH_ALL_CARD_PAYMENTS',
    display: 'Fetch all Card Payments',
  },
  {
    value: 'FETCH_ALL_UPI_PAYMENTS',
    display: 'Fetch all UPI Payments',
  },
];

export const ADMIN_INTEGRATION_TYPES = [
  {
    value: 'STANDALONE',
    display: 'STANDALONE',
  },
  {
    value: 'INTEGRATED',
    display: 'INTEGRATED',
  },
  {
    value: 'INTERNET',
    display: 'INTERNET',
  },
];

export const ADMIN_SCHEDULE_REPORTS_TYPES = [
  {
    value: 'TERMINAL_PENDING_RECONCILIATION_SUMMARY',
    display: 'Terminal Pending Reconciliation Summary',
  },
  {
    value: 'TERMINAL_PENDING_RECONCILIATION_DETAILS',
    display: 'Terminal Pending Reconciliation Details',
  },
  {
    value: 'PAYMENT_SESSION_EXPORT',
    display: 'Payment Session Export',
  },
];

export const ADMIN_SCHEDULE_REPORTS_CATEGORY = [
  {
    value: 'ACCOUNT_SPECIFIC',
    display: 'Account Specific',
  },
];

export const ADMIN_REPORTS_CATEGORY = [
  {
    value: 'ACCOUNT_SPECIFIC',
    display: 'Account Specific',
  },
  {
    value: 'ALL',
    display: 'All',
  },
];

export const ACCOUNT_SPECIFIC_CATEGORY = [
  {
    value: 'ACCOUNT_SPECIFIC',
    display: 'Account Specific',
  },
];

export const ALL_REPORTS_TYPES = {
  IMSDATAEXPORT: 'IMS_DATA_EXPORT',
  UNASSIGNEDSERVICECOMPLAINTS: 'UNASSIGNED_SERVICE_COMPLAINTS',
};

export const REPORTS_DATE_OPTIONAL = {
  DUPLICATECHECKOUTPAYMENTS: 'DUPLICATE_CHECKOUT_PAYMENTS',
  UNASSIGNEDSERVICECOMPLAINTS: 'UNASSIGNED_SERVICE_COMPLAINTS',
};

export const SCHEDULE_REPORTS_TYPES = [
  {
    value: 'PAYOUT',
    display: 'Payout',
  },
  {
    value: 'PAYMENT',
    display: 'Payment',
  },
  {
    value: 'REFUND',
    display: 'Refund',
  },
  {
    value: 'TERMINAL_PAYOUT',
    display: 'Terminal Payout',
  },
  {
    value: 'TRANSFER_SESSION',
    display: 'Transfer Session',
  },
];

export const SCHEDULE_REPORTS_PERIOD = [
  {
    value: 'DAILY',
    display: 'Daily Report',
  },
  {
    value: 'MONTHLY',
    display: 'Monthly Report',
  },
];


export const TERMINAL_STATUSES = {
  INVALID_STATE: 'Invalid',
  DEACTIVATED: 'Deactivated',
  ACTIVATION_PENDING: 'Pending',
  ACTIVE: 'Active',
};

export const DISPUTE_TYPES = {
  RESPONSE: 'NEEDS_RESPONSE',
  WON: 'WON',
  LOST: 'LOST',
  REVIEW: 'UNDER_REVIEW',
};

export const DISPUTE_STATUS = {
  NEEDS_RESPONSE: 'Need Response',
  WON: 'Won',
  LOST: 'Lost',
  UNDER_REVIEW: 'Reviewing',
};

export const TAX_STATUS = [
  {
    value: 'taxable',
    display: 'TAXABLE',
  },
  {
    value: 'exempt',
    display: 'EXEMPT',
  },
];

export const CURRENCYCODE = code => Object.freeze({
  USD: 1,
  INR: 2,
  CAD: 41,
})[code];

export const CATALOAG_COUNTRY_CODES = [
  {
    ID: 1,
    PHONE_CODE: '+1',
    NAME: 'USA',
    ORIGINAL_NAME: 'United States of America',
    ALPHA2: 'US',
    CURRENCY: 'USD',
    VALUE: 'US',
  },
];

export const COUNTRY_CODES = [
  {
    ID: 1,
    PHONE_CODE: '+1',
    NAME: 'USA',
    ORIGINAL_NAME: 'United States of America',
    ALPHA2: 'US',
    CURRENCY: 'USD',
    VALUE: 'US',
  },
  {
    ID: 2,
    PHONE_CODE: '+91',
    NAME: 'INDIA',
    ORIGINAL_NAME: 'India',
    ALPHA2: 'IN',
    CURRENCY: 'INR',
    VALUE: 'IN',
  },
  {
    ID: 41,
    PHONE_CODE: '+1',
    NAME: 'CANADA',
    ORIGINAL_NAME: 'Canada',
    ALPHA2: 'CA',
    CURRENCY: 'CAD',
    VALUE: 'CA',
  },
];

export const COUNTRY_CODES_ACCOUNT = [
  {
    ID: 1,
    PHONE_CODE: '+1',
    NAME: 'USA',
    ORIGINAL_NAME: 'United States of America',
    ALPHA2: 'US',
    CURRENCY: 'USD',
    VALUE: 'US',
  },
  {
    ID: 2,
    PHONE_CODE: '+91',
    NAME: 'INDIA',
    ORIGINAL_NAME: 'India',
    ALPHA2: 'IN',
    CURRENCY: 'INR',
    VALUE: 'IN',
  },
];

export const PAYOUT_CURRENCY_CODES = [
  {
    ID: 1,
    NAME: 'USA',
    CURRENCY: 'USD',
    VALUE: 'USD',
  },
  {
    ID: 2,
    NAME: 'INDIA',
    CURRENCY: 'INR',
    VALUE: 'INR',
  },
  {
    ID: 41,
    NAME: 'CANADA',
    CURRENCY: 'CAD',
    VALUE: 'CAD',
  },
  {
    ID: 249,
    NAME: 'CROSS',
    CURRENCY: 'CROSS',
    VALUE: 'CROSS',
  },
];

export const CURRENCY_CODES = [
  {
    ID: 1,
    NAME: 'USA',
    CURRENCY: 'USD',
    VALUE: 'USD',
  },
  {
    ID: 2,
    NAME: 'INDIA',
    CURRENCY: 'INR',
    VALUE: 'INR',
  },
  {
    ID: 41,
    NAME: 'CANADA',
    CURRENCY: 'CAD',
    VALUE: 'CAD',
  },
];

export const ACCOUNT_TYPES = [
  {
    ID: 1,
    VALUE: 'INDIVIDUAL',
  },
  {
    ID: 2,
    VALUE: 'BUSINESS',
  },
];

export const SUPPORTED_CURRENCY = ['CAD', 'INR', 'QAR', 'USD'];

export const SUPPORTED_TRANSFER_TYPES = ['ACH', 'RTP', 'NEFT', 'RTGS', 'IMPS', 'UPI'];

export const CARD_SCHEMAS = ['mastercard', 'master', 'visa', 'amex'];

export const PAYOUT_TIMEZONE = ['UTC-08:00', 'UTC+05:30'];

export const COUNTRY_PHONE_CODE = ['+1', '+7', '+20', '+27', '+30', '+31', '+32', '+33', '+34', '+36', '+39', '+40', '+41', '+43', '+44', '+45',
  '+46', '+47', '+48', '+49', '+51', '+52', '+53', '+54', '+55', '+56', '+57', '+58', '+60', '+61', '+62', '+63', '+64', '+65', '+66', '+81', '+82',
  '+84', '+86', '+90', '+91', '+92', '+93', '+94', '+95', '+98', '+211', '+212', '+213', '+216', '+218', '+220', '+221', '+222', '+223', '+224',
  '+225', '+226', '+227', '+228', '+229', '+230', '+231', '+232', '+233', '+234', '+235', '+236', '+237', '+238', '+239', '+240', '+241', '+242',
  '+243', '+244', '+245', '+246', '+248', '+249', '+250', '+251', '+252', '+253', '+254', '+255', '+256', '+257', '+258', '+260', '+261', '+262',
  '+263', '+264', '+265', '+266', '+267', '+268', '+269', '+290', '+291', '+297', '+298', '+299', '+350', '+351', '+352', '+353', '+354', '+355',
  '+356', '+357', '+358', '+359', '+370', '+371', '+372', '+373', '+374', '+375', '+376', '+377', '+378', '+379', '+380', '+381', '+382', '+383',
  '+385', '+386', '+387', '+389', '+420', '+421', '+423', '+500', '+501', '+502', '+503', '+504', '+505', '+506', '+507', '+508', '+509', '+590',
  '+591', '+592', '+593', '+595', '+597', '+598', '+599', '+670', '+672', '+673', '+674', '+675', '+676', '+677', '+678', '+679', '+680', '+681',
  '+682', '+683', '+685', '+686', '+687', '+688', '+689', '+690', '+691', '+692', '+850', '+852', '+853', '+855', '+856', '+880', '+886', '+960',
  '+961', '+962', '+963', '+964', '+965', '+966', '+967', '+968', '+970', '+971', '+972', '+973', '+974', '+975', '+976', '+977', '+992', '+993',
  '+994', '+995', '+996', '+998'];

export const TAX_TYPES = [
  {
    ID: 1,
    value: 'NONE',
    display: 'NONE',
  },
  {
    ID: 2,
    value: 'EIN',
    display: 'EIN',
  },
  {
    ID: 3,
    value: 'GST',
    display: 'GST',
  },
];

export const TYPES_OF_EVIDENCE = [
  {
    id: 1,
    value: 'customer_communication',
    display: 'Customer communication',
    name: 'customerCommunicationKey',
  },
  {
    id: 2,
    value: 'customer_signature',
    display: 'Customer signature',
    name: 'customerSignatureKey',
  },
  {
    id: 3,
    value: 'duplicate_charge_doc',
    display: 'Duplicate charge',
    name: 'duplicateChargeDocKey',
  },
  {
    id: 4,
    value: 'terms_disclosure',
    display: 'Terms disclosure',
    name: 'termsDisclosureKey',
  },
  {
    id: 5,
    value: 'receipt',
    display: 'Receipt',
    name: 'receiptKey',
  },
  {
    id: 6,
    value: 'refund_policy',
    display: 'Refund Policy',
    name: 'refundPolicyKey',
  },
  {
    id: 7,
    value: 'cancellation_policy',
    display: 'Cancellation Policy',
    name: 'cancellationPolicyKey',
  },
  {
    id: 8,
    value: 'service_documentation',
    display: 'Service documentation',
    name: 'serviceDocumentationKey',
  },
  {
    id: 9,
    value: 'shipping_documentation',
    display: 'Shipping documentation',
    name: 'shippingDocumentationKey',
  },
  {
    id: 10,
    value: 'credit_voucher',
    display: 'Credit voucher',
    name: 'creditVoucherKey',
  },
  {
    id: 11,
    value: 'government_order',
    display: 'Government order',
    name: 'governmentOrderKey',
  },
];

export const TYPES_OF_PRODUCTS = [
  {
    id: 1,
    display: 'Physical product',
    value: 'PHYSICAL_PRODUCT',
  },
  {
    id: 2,
    display: 'Digital product',
    value: 'DIGITAL_PRODUCT',
  },
  {
    id: 3,
    display: 'Offline services',
    value: 'OFFLINE_SERVICES',
  },
  {
    id: 4,
    display: 'Event',
    value: 'EVENT',
  },
  {
    id: 5,
    display: 'Reservation or Booking',
    value: 'RESERVATION',
  },
  {
    id: 6,
    display: 'Others',
    value: 'OTHER',
  },
];

export const FILE_FORMATES = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  PDF: 'application/pdf',
};

export const ACQUIRER_TYPES = {
  IMS: 'IMS',
  IMSPOS: 'IMS-POS',
  IMSSWIREPAY: 'IMS-SWIREPAY',
  CLOVERPOS: 'CLOVER-POS',
  STRIPE: 'STRIPE',
  STRIPEINDIA: 'STRIPE-INDIA',
  AFFINIPAY: 'AFFINIPAY',
  CASHFREE: 'CASHFREE',
  SWIREPAYPOOL: 'SWIREPAY-POOL',
  CASHFREEUSPOOL: 'CASHFREE-US-POOL',
  COMMERCECONNECT: 'COMMERCE-CONNECT',
  COMMERCECONNECTV2: 'COMMERCE-CONNECT-V2',
  IPGCOMMERCECONNECT: 'IPG_COMMERCE-CONNECT',
  CARDPOINTE: 'CARDPOINTE',
  ATOM: 'ATOM',
  BILLDESK: 'BILLDESK',
  INCCFUCF: 'IN-CCF-UCF_SP-NCF_SP',
  IPG: 'IPG',
  WORLDLINE: 'WORLDLINE',
  INCWLCSTNWLUWL: 'IN-CWL-CST-NWL-UWL',
  CCAVENUE: 'CCAVENUE',
  CLOVER_ECOM_POS: 'CLOVER-ECOM-POS',
};

export const SUPPORTED_PAYMENT_TYPES = {
  ACH: 'ACH',
  ACH_LEGACY: 'ACH_LEGACY',
  CARD: 'CARD',
  UPI: 'UPI',
  WIRE: 'WIRE',
  WALLET: 'WALLET',
  NET_BANKING: 'NET_BANKING',
  GOOGLE_PAY_US: 'GOOGLE_PAY_US',
  INSTANT_PAYMENT: 'INSTANT_PAYMENT',
  APPLE_PAYMENT: 'APPLE_PAY_US',
  SWIREPAY_US: 'SWIREPAY_US',
  CASH: 'CASH',
};

export const SUPPORTED_PAYMENT_LABEL = {
  ACH: 'ACH',
  BANK: 'BANK',
  NETBANKING: 'NET BANKING',
  GOOGLE_PAY_US: 'WALLET',
  INSTANT_PAYMENT: 'INSTANT PAYMENT',
  SWIREPAY_US: 'SWIREPAY_US',
};

export const POS_PAYMENT_TYPES = {
  DEBITCARDPRESENT: 'DEBIT_CARD_PRESENT',
  CREDITCARDPRESENT: 'CREDIT_CARD_PRESENT',
  RUPAYPRESENT: 'RUPAY_PRESENT',
  UPIPRESENT: 'UPI_PRESENT',
};

export const CHECKOUT_PAYMENT_TYPES = [
  {
    label: 'CARD',
    value: 'CARD',
  },
  {
    label: 'UPI',
    value: 'UPI',
  },
  {
    label: 'NET BANKING',
    value: 'NET_BANKING',
  },
];

export const OFFLINE_PAYMENT_TYPES = [
  {
    label: 'CASH',
    value: 'CASH',
  },
  {
    label: 'CHEQUE',
    value: 'CHECK',
  },
];

export const US_CHECKOUT_PAYMENT_TYPES = [
  {
    label: 'CARD',
    value: 'CARD',
  },
];
export const DATE_FORMATS =[
  "dd/MM/YYYY",
  "YYYY/dd/MM",
  "MM/dd/YYYY",
  "YYYY/MM/dd"
]

export const TIME_ZONES = [
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Nuuk',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay',
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg',
  'America/Antigua',
  'America/Anguilla',
  'Africa/Luanda',
  'Antarctica/McMurdo',
  'America/Aruba',
  'Europe/Mariehamn',
  'Europe/Sarajevo',
  'Africa/Ouagadougou',
  'Asia/Bahrain',
  'Africa/Bujumbura',
  'Africa/Porto-Novo',
  'America/St_Barthelemy',
  'America/Kralendijk',
  'Africa/Gaborone',
  'Africa/Kinshasa',
  'Africa/Lubumbashi',
  'Africa/Bangui',
  'Africa/Brazzaville',
  'Africa/Douala',
  'Europe/Busingen',
  'Africa/Djibouti',
  'America/Dominica',
  'Africa/Asmara',
  'Africa/Addis_Ababa',
  'Africa/Libreville',
  'America/Grenada',
  'Europe/Guernsey',
  'Africa/Banjul',
  'Africa/Conakry',
  'America/Guadeloupe',
  'Africa/Malabo',
  'Europe/Zagreb',
  'Europe/Isle_of_Man',
  'Europe/Jersey',
  'Asia/Phnom_Penh',
  'Indian/Comoro',
  'America/St_Kitts',
  'Asia/Kuwait',
  'America/Cayman',
  'Asia/Vientiane',
  'America/St_Lucia',
  'Europe/Vaduz',
  'Africa/Maseru',
  'Europe/Podgorica',
  'America/Marigot',
  'Indian/Antananarivo',
  'Europe/Skopje',
  'Africa/Bamako',
  'Pacific/Saipan',
  'Africa/Nouakchott',
  'America/Montserrat',
  'Africa/Blantyre',
  'Africa/Niamey',
  'Asia/Muscat',
  'Africa/Kigali',
  'Atlantic/St_Helena',
  'Europe/Ljubljana',
  'Arctic/Longyearbyen',
  'Europe/Bratislava',
  'Africa/Freetown',
  'Europe/San_Marino',
  'Africa/Dakar',
  'Africa/Mogadishu',
  'America/Lower_Princes',
  'Africa/Mbabane',
  'Africa/Lome',
  'Africa/Dar_es_Salaam',
  'Africa/Kampala',
  'Pacific/Midway',
  'Europe/Vatican',
  'America/St_Vincent',
  'America/Tortola',
  'America/St_Thomas',
  'Asia/Aden',
  'Indian/Mayotte',
  'Africa/Lusaka',
  'Africa/Harare',
];

export const API_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  STARTED: 'STARTED',
  SUCCEEDED: 'SUCCEEDED',
};

export const ALIAS_TIMEZONES = {
  'Africa/Addis_Ababa': 'Africa/Nairobi',
  'Africa/Asmara': 'Africa/Nairobi',
  'Africa/Bamako': 'Africa/Abidjan',
  'Africa/Bangui': 'Africa/Lagos',
  'Africa/Banjul': 'Africa/Abidjan',
  'Africa/Blantyre': 'Africa/Maputo',
  'Africa/Brazzaville': 'Africa/Lagos',
  'Africa/Bujumbura': 'Africa/Maputo',
  'Africa/Conakry': 'Africa/Abidjan',
  'Africa/Dakar': 'Africa/Abidjan',
  'Africa/Dar_es_Salaam': 'Africa/Nairobi',
  'Africa/Djibouti': 'Africa/Nairobi',
  'Africa/Douala': 'Africa/Lagos',
  'Africa/Freetown': 'Africa/Abidjan',
  'Africa/Gaborone': 'Africa/Maputo',
  'Africa/Harare': 'Africa/Maputo',
  'Africa/Kampala': 'Africa/Nairobi',
  'Africa/Kigali': 'Africa/Maputo',
  'Africa/Kinshasa': 'Africa/Lagos',
  'Africa/Libreville': 'Africa/Lagos',
  'Africa/Lome': 'Africa/Abidjan',
  'Africa/Luanda': 'Africa/Lagos',
  'Africa/Lubumbashi': 'Africa/Maputo',
  'Africa/Lusaka': 'Africa/Maputo',
  'Africa/Malabo': 'Africa/Lagos',
  'Africa/Maseru': 'Africa/Johannesburg',
  'Africa/Mbabane': 'Africa/Johannesburg',
  'Africa/Mogadishu': 'Africa/Nairobi',
  'Africa/Niamey': 'Africa/Lagos',
  'Africa/Nouakchott': 'Africa/Abidjan',
  'Africa/Ouagadougou': 'Africa/Abidjan',
  'Africa/Porto-Novo': 'Africa/Lagos',
  'Africa/Sao_Tome': 'Africa/Lagos',
  'Africa/Timbuktu': 'Africa/Abidjan',
  'America/Anguilla': 'America/Port_of_Spain',
  'America/Antigua': 'America/Port_of_Spain',
  'America/Argentina/ComodRivadavia': 'America/Argentina/Catamarca',
  'America/Aruba': 'America/Curacao',
  'America/Atka': 'America/Adak',
  'America/Buenos_Aires': 'America/Argentina/Buenos_Aires',
  'America/Catamarca': 'America/Argentina/Catamarca',
  'America/Cayman': 'America/Panama',
  'America/Coral_Harbour': 'America/Atikokan',
  'America/Cordoba': 'America/Argentina/Cordoba',
  'America/Dominica': 'America/Port_of_Spain',
  'America/Ensenada': 'America/Tijuana',
  'America/Fort_Wayne': 'America/Indiana/Indianapolis',
  'America/Grenada': 'America/Port_of_Spain',
  'America/Guadeloupe': 'America/Port_of_Spain',
  'America/Indianapolis': 'America/Indiana/Indianapolis',
  'America/Jujuy': 'America/Argentina/Jujuy',
  'America/Knox_IN': 'America/Indiana/Knox',
  'America/Kralendijk': 'America/Curacao',
  'America/Louisville': 'America/Kentucky/Louisville',
  'America/Lower_Princes': 'America/Curacao',
  'America/Marigot': 'America/Port_of_Spain',
  'America/Mendoza': 'America/Argentina/Mendoza',
  'America/Montreal': 'America/Toronto',
  'America/Montserrat': 'America/Port_of_Spain',
  'America/Porto_Acre': 'America/Rio_Branco',
  'America/Rosario': 'America/Argentina/Cordoba',
  'America/Santa_Isabel': 'America/Tijuana',
  'America/Shiprock': 'America/Denver',
  'America/St_Barthelemy': 'America/Port_of_Spain',
  'America/St_Kitts': 'America/Port_of_Spain',
  'America/St_Lucia': 'America/Port_of_Spain',
  'America/St_Thomas': 'America/Port_of_Spain',
  'America/St_Vincent': 'America/Port_of_Spain',
  'America/Tortola': 'America/Port_of_Spain',
  'America/Virgin': 'America/Port_of_Spain',
  'Antarctica/McMurdo': 'Pacific/Auckland',
  'Antarctica/South_Pole': 'Pacific/Auckland',
  'Arctic/Longyearbyen': 'Europe/Oslo',
  'Asia/Aden': 'Asia/Riyadh',
  'Asia/Ashkhabad': 'Asia/Ashgabat',
  'Asia/Bahrain': 'Asia/Qatar',
  'Asia/Calcutta': 'Asia/Kolkata',
  'Asia/Chongqing': 'Asia/Shanghai',
  'Asia/Chungking': 'Asia/Shanghai',
  'Asia/Dacca': 'Asia/Dhaka',
  'Asia/Harbin': 'Asia/Shanghai',
  'Asia/Istanbul': 'Europe/Istanbul',
  'Asia/Kashgar': 'Asia/Urumqi',
  'Asia/Katmandu': 'Asia/Kathmandu',
  'Asia/Kuwait': 'Asia/Riyadh',
  'Asia/Macao': 'Asia/Macau',
  'Asia/Muscat': 'Asia/Dubai',
  'Asia/Phnom_Penh': 'Asia/Bangkok',
  'Asia/Rangoon': 'Asia/Yangon',
  'Asia/Saigon': 'Asia/Ho_Chi_Minh',
  'Asia/Tel_Aviv': 'Asia/Jerusalem',
  'Asia/Thimbu': 'Asia/Thimphu',
  'Asia/Ujung_Pandang': 'Asia/Makassar',
  'Asia/Ulan_Bator': 'Asia/Ulaanbaatar',
  'Asia/Vientiane': 'Asia/Bangkok',
  'Atlantic/Faeroe': 'Atlantic/Faroe',
  'Atlantic/Jan_Mayen': 'Europe/Oslo',
  'Atlantic/St_Helena': 'Africa/Abidjan',
  'Australia/Canberra': 'Australia/Sydney',
  'Australia/Yancowinna': 'Australia/Broken_Hill',
  'Europe/Belfast': 'Europe/London',
  'Europe/Bratislava': 'Europe/Prague',
  'Europe/Busingen': 'Europe/Zurich',
  'Europe/Guernsey': 'Europe/London',
  'Europe/Isle_of_Man': 'Europe/London',
  'Europe/Jersey': 'Europe/London',
  'Europe/Ljubljana': 'Europe/Belgrade',
  'Europe/Mariehamn': 'Europe/Helsinki',
  'Europe/Podgorica': 'Europe/Belgrade',
  'Europe/San_Marino': 'Europe/Rome',
  'Europe/Sarajevo': 'Europe/Belgrade',
  'Europe/Skopje': 'Europe/Belgrade',
  'Europe/Tiraspol': 'Europe/Chisinau',
  'Europe/Vaduz': 'Europe/Zurich',
  'Europe/Vatican': 'Europe/Rome',
  'Europe/Zagreb': 'Europe/Belgrade',
  'Indian/Antananarivo': 'Africa/Nairobi',
  'Indian/Comoro': 'Africa/Nairobi',
  'Indian/Mayotte': 'Africa/Nairobi',
  'Pacific/Johnston': 'Pacific/Honolulu',
  'Pacific/Midway': 'Pacific/Pago_Pago',
  'Pacific/Ponape': 'Pacific/Pohnpei',
  'Pacific/Saipan': 'Pacific/Guam',
  'Pacific/Samoa': 'Pacific/Pago_Pago',
  'Pacific/Truk': 'Pacific/Chuuk',
  'Pacific/Yap': 'Pacific/Chuuk',
};

export const CURRENCY = id => Object.freeze({
  1: 'USD',
  2: 'INR',
  41: 'CAD',
})[id];

export const COUNTRYCODE = id => Object.freeze({
  1: 'US',
  2: 'IN',
  41: 'CA',
})[id];

export const LINKS_WITH_EXPIRY = [
  {
    id: 1,
    display: '1 day before expiry',
    value: 'PAYMENT_LINK_1_DAY_BEFORE_EXPIRY',
  },
  {
    id: 2,
    display: '3 days before expiry',
    value: 'PAYMENT_LINK_3_DAYS_BEFORE_EXPIRY',
  },
  {
    id: 3,
    display: '5 days before expiry',
    value: 'PAYMENT_LINK_5_DAYS_BEFORE_EXPIRY',
  },
  {
    id: 4,
    display: '7 days before expiry',
    value: 'PAYMENT_LINK_7_DAYS_BEFORE_EXPIRY',
  },
  {
    id: 5,
    display: '10 days before expiry',
    value: 'PAYMENT_LINK_10_DAYS_BEFORE_EXPIRY',
  },
  {
    id: 6,
    display: '14 days before expiry',
    value: 'PAYMENT_LINK_14_DAYS_BEFORE_EXPIRY',
  },
  {
    id: 7,
    display: '21 days before expiry',
    value: 'PAYMENT_LINK_21_DAYS_BEFORE_EXPIRY',
  },
];

export const LINKS_WITHOUT_EXPIRY = [
  {
    id: 1,
    display: '1 day after issue date',
    value: 'PAYMENT_LINK_1_DAY_BEFORE_NO_EXPIRY',
  },
  {
    id: 2,
    display: '3 days after issue date',
    value: 'PAYMENT_LINK_3_DAYS_BEFORE_NO_EXPIRY',
  },
  {
    id: 3,
    display: '5 days after issue date',
    value: 'PAYMENT_LINK_5_DAYS_BEFORE_NO_EXPIRY',
  },
  {
    id: 4,
    display: '7 days after issue date',
    value: 'PAYMENT_LINK_7_DAYS_BEFORE_NO_EXPIRY',
  },
  {
    id: 5,
    display: '10 days after issue date',
    value: 'PAYMENT_LINK_10_DAYS_BEFORE_NO_EXPIRY',
  },
  {
    id: 6,
    display: '14 days after issue date',
    value: 'PAYMENT_LINK_14_DAYS_BEFORE_NO_EXPIRY',
  },
  {
    id: 7,
    display: '21 days after issue date',
    value: 'PAYMENT_LINK_21_DAYS_BEFORE_NO_EXPIRY',
  },
];

export const INVOICE_REMINDERS = [
  {
    id: 1,
    display: '10 days before due',
    value: 'INVOICE_10_DAYS_BEFORE_DUE',
  },
  {
    id: 2,
    display: '2 days before due',
    value: 'INVOICE_2_DAYS_BEFORE_DUE',
  },
  {
    id: 3,
    display: 'On due date',
    value: 'INVOICE_ON_DUE_DATE',
  },
  {
    id: 4,
    display: '3 days after it was due',
    value: 'INVOICE_3_DAYS_AFTER_DUE',
  },
  {
    id: 5,
    display: '7 days after it was due',
    value: 'INVOICE_7_DAYS_AFTER_DUE',
  },
  {
    id: 6,
    display: '15 days after it was due',
    value: 'INVOICE_15_DAYS_AFTER_DUE',
  },
  {
    id: 7,
    display: '30 days after it was due',
    value: 'INVOICE_30_DAYS_AFTER_DUE',
  },
  {
    id: 8,
    display: '60 days after it was due',
    value: 'INVOICE_60_DAYS_AFTER_DUE',
  },
];

export const SUBSCRIPTION_EXPIRY = {
  id: 1,
  value: 'SUBSCRIPTION_EXPIRING_CARDS',
};

export const FILTERS_TYPES = {
  CATALOG_ORDERS: [
    {
      id: 1,
      display: 'ID',
      value: 'poNumber',
    },
    {
      id: 2,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  CATALOG: [
    {
      id: 1,
      display: 'NAME',
      value: 'name',
    },
    {
      id: 2,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  ADMIN_CATALOG: [
    {
      id: 1,
      display: 'NAME',
      value: 'name',
    },
    {
      id: 2,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  CUSTOMERS: [
    {
      id: 1,
      display: 'Name',
      value: 'name',
    },
    {
      id: 2,
      display: 'Email',
      value: 'email',
    },
    {
      id: 3,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  EVENTS: [
    {
      id: 1,
      display: 'Title',
      value: 'title',
    },
    {
      id: 3,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  LOCATIONS: [
    {
      id: 1,
      display: 'Office Name',
      value: 'name',
    },
    {
      id: 2,
      display: 'City',
      value: 'city',
    },
  ],
  TEAM: [
    {
      id: 1,
      display: 'Name',
      value: 'fullName',
    },
    {
      id: 2,
      display: 'Email',
      value: 'email',
    },
    {
      id: 3,
      display: 'Role',
      value: 'roleType',
    },
  ],
  INTERNAL_USER: [
    {
      id: 1,
      display: 'Name',
      value: 'fullName',
    },
    {
      id: 2,
      display: 'Email',
      value: 'email',
    },
    {
      id: 3,
      display: 'Role',
      value: 'internalRole.type',
    },
  ],
  PRODUCT: [
    {
      id: 1,
      display: 'Amount',
      value: 'amount',
    },
    {
      id: 2,
      display: 'Name',
      value: 'name',
    },
    {
      id: 3,
      display: 'Created On',
      value: 'createdAt',
    },
    {
      id: 4,
      display: 'Description',
      value: 'description',
    },
  ],
  PAYOUTS: [
    {
      id: 1,
      display: 'Amount',
      value: 'amount',
    },
    {
      id: 2,
      display: 'Date',
      value: 'payoutDate',
    },
  ],
  TRANSACTIONS: [
    {
      id: 1,
      display: 'Type',
      value: 'type',
    },
    {
      id: 2,
      display: 'Description',
      value: 'description',
    },
    {
      id: 3,
      display: 'Date',
      value: 'createdAt',
    },
  ],
  PAYMENTLINKS: [
    {
      id: 1,
      display: 'Name',
      value: 'name',
    },
    {
      id: 2,
      display: 'Email',
      value: 'email',
    },
    {
      id: 3,
      display: 'Phone',
      value: 'phoneNumber',
    },
    {
      id: 4,
      display: 'Amount',
      value: 'amount',
    },
    {
      id: 5,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  PARTNER_PAYMENTLINKS: [
    {
      id: 1,
      display: 'Name',
      value: 'name',
    },
    {
      id: 2,
      display: 'Email',
      value: 'email',
    },
    {
      id: 3,
      display: 'Phone',
      value: 'phoneNumber',
    },
    {
      id: 4,
      display: 'Amount',
      value: 'amount',
    },
    {
      id: 5,
      display: 'Created On',
      value: 'createdAt',
    },
    {
      id: 6,
      display: 'Application Fee',
      value: 'applicationFee',
    },
  ],
  PAYMENTBUTTONS: [
    {
      id: 1,
      display: 'Amount',
      value: 'amount',
    },
    {
      id: 2,
      display: 'Description',
      value: 'description',
    },
    {
      id: 3,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  BENEFICIARY: [
    {
      id: 1,
      display: 'Name',
      value: 'name',
    },
    {
      id: 2,
      display: 'Email',
      value: 'email',
    },
    {
      id: 3,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  SUBSCRIPTION_BUTTON: [
    {
      id: 1,
      display: 'Name',
      value: 'plan.name',
    },
    {
      id: 2,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  TERMINALS: [
    {
      id: 1,
      display: 'Terminal Id',
      value: 'gid',
    },
    {
      id: 2,
      display: 'MID',
      value: 'terminalMid',
    },
    {
      id: 3,
      display: 'TID',
      value: 'terminalTid',
    },
    {
      id: 4,
      display: 'Location',
      value: 'terminalLocation.name',
    },
    {
      id: 5,
      display: 'Name',
      value: 'deviceName',
    },
    {
      id: 6,
      display: 'City',
      value: 'terminalLocation.address.city',
    },
  ],
  PAYMENTS: [
    {
      id: 1,
      display: 'Net',
      value: 'net',
    },
    {
      id: 2,
      display: 'Payment Date',
      value: 'paymentDate',
    },
    {
      id: 3,
      display: 'Receipt Number',
      value: 'receiptNumber',
    },
    {
      id: 4,
      display: 'MRN',
      value: 'mrn',
    },
    {
      id: 5,
      display: 'Authorization Id',
      value: 'authorizationId',
    },
  ],
  ALLPAYMENTS: [
    {
      id: 1,
      display: 'Net',
      value: 'net',
    },
    {
      id: 2,
      display: 'Payment Date',
      value: 'paymentDate',
    },
    {
      id: 3,
      display: 'Status',
      value: 'status',
    },
    {
      id: 4,
      display: 'Receipt Number',
      value: 'receiptNumber',
    },
    {
      id: 5,
      display: 'MRN',
      value: 'mrn',
    },
    {
      id: 6,
      display: 'Authorization Id',
      value: 'authorizationId',
    },
  ],
  CLOVERPAYMENTS: [
    {
      id: 1,
      display: 'Net',
      value: 'net',
    },
    {
      id: 2,
      display: 'Payment Date',
      value: 'paymentDate',
    },
    {
      id: 3,
      display: 'Receipt Number',
      value: 'receiptNumber',
    },
    {
      id: 4,
      display: 'Order ID',
      value: 'mrn',
    },
  ],
  CLOVERALLPAYMENTS: [
    {
      id: 1,
      display: 'Net',
      value: 'net',
    },
    {
      id: 2,
      display: 'Payment Date',
      value: 'paymentDate',
    },
    {
      id: 3,
      display: 'Status',
      value: 'status',
    },
    {
      id: 4,
      display: 'Receipt Number',
      value: 'receiptNumber',
    },
    {
      id: 5,
      display: 'Order ID',
      value: 'mrn',
    },
  ],
  ADMINPAYMENTS: [
    {
      id: 1,
      display: 'Net',
      value: 'net',
    },
    {
      id: 2,
      display: 'Payment Date',
      value: 'paymentDate',
    },
    {
      id: 3,
      display: 'Status',
      value: 'status',
    },
    {
      id: 4,
      display: 'Receipt Number',
      value: 'receiptNumber',
    },
    {
      id: 5,
      display: 'Terminal Name',
      value: 'terminal.deviceName',
    },
    {
      id: 6,
      display: 'Auth Code',
      value: 'authCode',
    },
  ],
  DISPUTES: [
    {
      id: 1,
      display: 'Amount',
      value: 'amountDisputed',
    },
    {
      id: 2,
      display: 'Customer',
      value: 'customer',
    },
    {
      id: 3,
      display: 'Disputed On',
      value: 'createdAt',
    },
    {
      id: 4,
      display: 'Respond By',
      value: 'respondBy',
    },
  ],
  INVOICES: [
    {
      id: 1,
      display: 'Name',
      value: 'customer.name',
    },
    {
      id: 2,
      display: 'Invoice Number',
      value: 'invoiceNumber',
    },
    {
      id: 3,
      display: 'Due Date',
      value: 'paymentDueDate',
    },
    {
      id: 5,
      display: 'Created At',
      value: 'createdAt',
    },
  ],
  VENDORINVOICES: [
    {
      id: 1,
      display: 'Name',
      value: 'contact.name',
    },
    {
      id: 2,
      display: 'Invoice Number',
      value: 'invoiceNumber',
    },
    {
      id: 3,
      display: 'Due Date',
      value: 'transferDueDate',
    },
    {
      id: 4,
      display: 'issueDate',
      value: 'issueDate',
    },
  ],
  WALLETINVOICES: [
    {
      id: 1,
      display: 'Name',
      value: 'walletBeneficiary.name',
    },
    {
      id: 2,
      display: 'Invoice Number',
      value: 'invoiceNumber',
    },
    {
      id: 3,
      display: 'issueDate',
      value: 'issueDate',
    },
  ],
  SUBSCRIPTION: [
    {
      id: 1,
      display: 'Name',
      value: 'customer.name',
    },
    {
      id: 2,
      display: 'Email',
      value: 'customer.email',
    },
    {
      id: 3,
      display: 'Plan',
      value: 'plan.name',
    },
    {
      id: 4,
      display: 'Phone',
      value: 'customer.phoneNumber',
    },
    {
      id: 5,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  PARTNER_SUBSCRIPTION: [
    {
      id: 1,
      display: 'Name',
      value: 'customer.name',
    },
    {
      id: 2,
      display: 'Email',
      value: 'customer.email',
    },
    {
      id: 3,
      display: 'Plan',
      value: 'plan.name',
    },
    {
      id: 4,
      display: 'Phone',
      value: 'customer.phoneNumber',
    },
    {
      id: 5,
      display: 'Created On',
      value: 'createdAt',
    },
    {
      id: 6,
      display: 'Application Fee',
      value: 'applicationFeePercentage',
    },
  ],
  SUB_PLAN: [
    {
      id: 1,
      display: 'Name',
      value: 'name',
    },
    {
      id: 2,
      display: 'Date',
      value: 'createdAt',
    },
  ],
  REPORTS: [
    {
      id: 1,
      display: 'Report type',
      value: 'type',
    },
    {
      id: 2,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  PAYMENT_PAGES: [
    {
      id: 1,
      display: 'Title',
      value: 'title',
    },
    {
      id: 2,
      display: 'Status',
      value: 'status',
    },
    {
      id: 3,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  WEBHOOKS: [
    {
      id: 1,
      display: 'Url',
      value: 'url',
    },
    {
      id: 2,
      display: 'Last Pushed',
      value: 'createdAt',
    },
  ],
  TAXRATES: [
    {
      id: 1,
      display: 'Name',
      value: 'displayName',
    },
    {
      id: 2,
      display: 'Region',
      value: 'jurisdiction',
    },
    {
      id: 3,
      display: 'Description',
      value: 'description',
    },
    {
      id: 4,
      display: 'Rate',
      value: 'percentage',
    },
    {
      id: 5,
      display: 'Date',
      value: 'createdAt',
    },
  ],
  RATES: [
    {
      id: 1,
      display: 'Acquirer Name',
      value: 'acquirer.name',
    },
    {
      id: 2,
      display: 'Category Name',
      value: 'rate.category.name',
    },
  ],
  ISSUERRATE: [
    {
      id: 1,
      display: 'Issuer Name',
      value: 'issuer.name',
    },
  ],
  ACQUIRER: [
    {
      id: 1,
      display: 'Acquirer Name',
      value: 'name',
    },
    {
      id: 2,
      display: 'Acquirer Type',
      value: 'type',
    },
  ],
  ISSUER: [
    {
      id: 1,
      display: 'Issuer Name',
      value: 'name',
    },
    {
      id: 2,
      display: 'Issuer Type',
      value: 'type',
    },
  ],
  COUPONS: [
    {
      id: 1,
      display: 'Coupon',
      value: 'name',
    },
    {
      id: 2,
      display: 'Expires',
      value: 'expiresAt',
    },
  ],
  OFFERS: [
    {
      id: 1,
      display: 'Offer',
      value: 'code',
    },
    {
      id: 2,
      display: 'Expires',
      value: 'expiresAt',
    },
  ],
  PARTNER_TRANSACTIONS: [
    {
      id: 1,
      display: 'Account',
      value: 'affiliateAccount.name',
    },
    {
      id: 2,
      display: 'Amount',
      value: 'amount',
    },
    {
      id: 3,
      display: 'Commission',
      value: 'commission',
    },
    {
      id: 4,
      display: 'Date',
      value: 'createdAt',
    },
  ],
  PARTNER_EARNINGS: [
    {
      id: 1,
      display: 'Total Transactions',
      value: 'partnerEarnings.totalTransactions',
    },
    {
      id: 2,
      display: 'Transaction Amount',
      value: 'partnerEarnings.totalTransactionAmount',
    },
    {
      id: 3,
      display: 'Date',
      value: 'createdAt',
    },
  ],
  SCHEDULE_REPORTS: [
    {
      id: 1,
      display: 'Report type',
      value: 'type',
    },
    {
      id: 2,
      display: 'Period',
      value: 'frequency',
    },
  ],
  ADMIN_TERMINALS: [
    {
      id: 1,
      display: 'Device Name',
      value: 'deviceName',
    },
    {
      id: 2,
      display: 'Device Id',
      value: 'deviceId',
    },
    {
      id: 3,
      display: 'MID',
      value: 'terminalMid',
    },
    {
      id: 4,
      display: 'TID',
      value: 'terminalTid',
    },
  ],
  ADMIN_BINS: [
    {
      id: 1,
      display: 'Bin',
      value: 'bin',
    },
    {
      id: 2,
      display: 'Scheme',
      value: 'scheme',
    },
    {
      id: 3,
      display: 'Bank',
      value: 'bankName',
    },
  ],
  PAYMENTWIDGETS: [
    {
      id: 1,
      display: 'Title',
      value: 'title',
    },
    {
      id: 2,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  CASH_TRANSACTIONS: [
    {
      id: 1,
      display: 'Type',
      value: 'status',
    },
    {
      id: 2,
      display: 'Description',
      value: 'description',
    },
    {
      id: 3,
      display: 'Date',
      value: 'createdAt',
    },
  ],
  SERVICES: [
    {
      id: 1,
      display: 'Type',
      value: 'serviceType',
    },
  ],
  DOCUMENTS: [
    {
      id: 1,
      display: 'Name',
      value: 'name',
    },
    {
      id: 2,
      display: 'Last Updated',
      value: 'updatedAt',
    },
  ],
  CUSTOMDOCUMENTS: [
    {
      id: 1,
      display: 'Name',
      value: 'name',
    },
  ],
  TRANSFERS: [
    {
      id: 1,
      display: 'Amount',
      value: 'amount',
    },
    {
      id: 2,
      display: 'Transfer Date',
      value: 'transferDate',
    },
  ],
  VENDORS: [
    {
      id: 1,
      display: 'Name',
      value: 'name',
    },
    {
      id: 2,
      display: 'Email',
      value: 'email',
    },
    {
      id: 3,
      display: 'Type',
      value: 'contactType',

    },
    {
      id: 3,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  FUNDINGSOURCE: [
    {
      id: 1,
      display: 'Contact',
      value: 'contact.name',
    },
    {
      id: 2,
      display: 'Bank Name',
      value: 'issuerBank.bankName',
    },
    {
      id: 3,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  FUNDINGSOURCELINK: [
    {
      id: 1,
      display: 'Contact',
      value: 'contact.name',
    },
    {
      id: 2,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  TRANSFERLINKS: [
    {
      id: 1,
      display: 'Contact',
      value: 'name',
    },
    {
      id: 2,
      display: 'Status',
      value: 'status',
    },
    {
      id: 3,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  BULKTRANSFER: [
    {
      id: 1,
      display: 'File Number',
      value: 'fileNumber',
    },
    {
      id: 2,
      display: 'Total Transfer',
      value: 'transferCount',
    },
    {
      id: 3,
      display: 'Status',
      value: 'status',
    },
  ],
  PAYMENTCHECKOUT: [
    {
      id: 1,
      display: 'Amount',
      value: 'amount',
    },
    {
      id: 2,
      display: 'Status',
      value: 'status',
    },
  ],
  ACCOUNTSETTLEMENT: [
    {
      id: 1,
      display: 'File Name',
      value: 'settlementFilename',
    },
    {
      id: 2,
      display: 'Date',
      value: 'settlementDate',
    },
  ],
  PARTNERSETTLEMENT: [
    {
      id: 1,
      display: 'File Name',
      value: 'partnerSettlementFilename',
    },
    {
      id: 2,
      display: 'Date',
      value: 'partnerSettlementDate',
    },
  ],
  WALLET_TRANSACTION: [
    {
      id: 1,
      display: 'Amount',
      value: 'amount',
    },
    {
      id: 2,
      display: 'Created On',
      value: 'createdAt',
    },
  ],
  PARTNER_FILTERS: [
    {
      id: 1,
      display: 'Name',
      value: 'name',
    },
    {
      id: 2,
      display: 'Email',
      value: 'email',
    },
    {
      id: 3,
      display: 'Phone',
      value: 'phoneNumber',
    },
  ],
  WOOCOMMERCE: [
    {
      id: 1,
      display: 'Order Id',
      value: 'wcOrderId',
    },
    {
      id: 2,
      display: 'Status',
      value: 'spOrderStatus',
    },
  ],
  INVENTORY_ITEMS: [
    {
      id: 1,
      display: 'Price',
      value: 'price',
    },
    {
      id: 2,
      display: 'Cost',
      value: 'cost',
    },
    {
      id: 3,
      display: 'On POS',
      value: 'hidden',
    },
    {
      id: 4,
      display: 'Available',
      value: 'available',
    },
  ],
  INVENTORY_CATEGORY: [
    {
      id: 1,
      display: 'Category',
      value: 'name',
    },
  ],
  INVENTORY_ORDERS: [
    {
      id: 1,
      display: 'ID',
      value: 'cloverPosOrderId',
    },
    {
      id: 2,
      display: 'Order Number',
      value: 'orderNumber',
    },
    {
      id: 3,
      display: 'Status',
      value: 'paymentStatus',
    },
  ],
  INVENTORY_ORDERTYPES: [
    {
      id: 1,
      display: 'Name',
      value: 'label',
    },
  ],
  PAYMENT_LABEL: [
    {
      id: 1,
      display: 'Name',
      value: 'name',
    },
  ],
  RESERVATION: [
    {
      id: 1,
      display: 'Shop',
      value: 'shopName',
    },
    {
      id: 2,
      display: 'Table',
      value: 'shopTableName',
    },
    {
      id: 3,
      display: 'Group ID',
      value: 'reservationCode',
    },
    {
      id: 4,
      display: 'Guest Name',
      value: 'customerName',
    },
    {
      id: 5,
      display: 'Guest Count',
      value: 'noOfGuest',
    },
    {
      id: 6,
      display: 'Status',
      value: 'status',
    },
  ],
};


export const FILTER_STATUSES = {
  CATALOG: [
    {
      id: 1,
      display: 'Terminal',
      value: 'TERMINAL',
    },
    {
      id: 2,
      display: 'Service',
      value: 'SERVICE',
    },
    {
      id: 3,
      display: 'Other',
      value: 'OTHER',
    },
  ],
  REPORTS: [
    {
      id: 1,
      display: 'PAYOUT',
      value: 'PAYOUT',
    },
    {
      id: 2,
      display: 'PAYMENT',
      value: 'PAYMENT',
    },
    {
      id: 3,
      display: 'REFUND',
      value: 'REFUND',
    },
    {
      id: 4,
      display: 'PAYOUT_DETAIL',
      value: 'PAYOUT_DETAIL',
    },
  ],
  ADMIN_REPORTS: [
    {
      id: 1,
      value: 'TERMINAL_ACTIVITY',
      display: 'Terminal Activity',
    },
    {
      id: 2,
      value: 'TERMINAL_PENDING_RECONCILIATION_SUMMARY',
      display: 'Terminal Pending Reconciliation Summary',
    },
    {
      id: 3,
      value: 'TERMINAL_PENDING_RECONCILIATION_DETAILS',
      display: 'Terminal Pending Reconciliation Details',
    },
    {
      id: 4,
      value: 'PAYMENT_SESSION_EXPORT',
      display: 'Payment Session Export',
    },
    {
      id: 5,
      value: 'UNASSIGNED_SERVICE_COMPLAINTS',
      display: 'Unassigned Service Complaints',
    },
    {
      id: 6,
      value: 'IMS_DATA_EXPORT',
      display: 'IMS Data Export',
    },
    {
      id: 7,
      value: 'DUPLICATE_CHECKOUT_PAYMENTS',
      display: 'Duplicate Checkout Payments',
    },
    {
      id: 8,
      value: 'FETCH_ALL_PAYMENTS',
      display: 'Fetch all Payments',
    },
  ],
  ADMIN_SCHEDULE_REPORTS: [
    {
      id: 1,
      value: 'TERMINAL_PENDING_RECONCILIATION_SUMMARY',
      display: 'Terminal Pending Reconciliation Summary',
    },
    {
      id: 2,
      value: 'TERMINAL_PENDING_RECONCILIATION_DETAILS',
      display: 'Terminal Pending Reconciliation Details',
    },
    {
      id: 3,
      value: 'PAYMENT_SESSION_EXPORT',
      display: 'Payment Session Export',
    },
  ],
  PAYMENT_PAGES: [
    {
      id: 1,
      display: 'Active',
      value: 'ACTIVE',
    },
    {
      id: 2,
      display: 'Deactivated',
      value: 'DEACTIVATED',
    },
    {
      id: 3,
      display: 'Deleted',
      value: 'DELETED',
    },
    {
      id: 4,
      display: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      id: 5,
      display: 'Sold Out',
      value: 'SOLD_OUT',
    },
  ],
  TRANSACTIONS: [
    {
      id: 1,
      display: 'CHARGE',
      value: 'CHARGE',
    },
    {
      id: 2,
      display: 'PAYOUT',
      value: 'PAYOUT',
    },
    {
      id: 3,
      display: 'REFUND',
      value: 'REFUND',
    },
    {
      id: 4,
      display: 'ADJUSTMENT',
      value: 'ADJUSTMENT',
    },
  ],
  ONPOS: [
    {
      id: 1,
      display: 'Yes',
      value: 'false',
    },
    {
      id: 2,
      display: 'No',
      value: 'true',
    },
  ],
  AVAILABLE: [
    {
      id: 1,
      display: 'Yes',
      value: 'true',
    },
    {
      id: 2,
      display: 'No',
      value: 'false',
    },
  ],
  WEBHOOK: [
    {
      id: 1,
      display: 'Active',
      value: 'ACTIVE',
    },
    {
      id: 2,
      display: 'Deactivated',
      value: 'DEACTIVE',
    },
    {
      id: 3,
      display: 'Deleted',
      value: 'DELETED',
    },
    {
      id: 4,
      display: 'Failed',
      value: 'FAILED',
    },
  ],
  ACQUIRER: [
    {
      id: 1,
      display: 'BANK',
      value: 'BANK',
    },
    {
      id: 2,
      display: 'GATEWAY',
      value: 'GATEWAY',
    },
  ],
  ISSUER: [
    {
      id: 1,
      display: 'BANK',
      value: 'BANK',
    },
    {
      id: 2,
      display: 'GATEWAY',
      value: 'GATEWAY',
    },
  ],
  CASH_TRANSACTIONS: [
    {
      id: 1,
      display: 'PAID',
      value: 'PAID',
    },
    {
      id: 2,
      display: 'VOID',
      value: 'VOID',
    },
  ],
  CHEQUE_TRANSACTIONS: [
    {
      id: 1,
      display: 'PAID',
      value: 'PAID',
    },
    {
      id: 2,
      display: 'VOID',
      value: 'VOID',
    },
    {
      id: 3,
      display: 'PENDING',
      value: 'PENDING',
    },
    {
      id: 4,
      display: 'CANCELLED',
      value: 'CANCELLED',
    },
  ],
  SERVICES: [
    {
      id: 1,
      display: 'Terminal Related Query',
      value: 'TERMINAL_RELATED_QUERY',
    },
    {
      id: 2,
      display: 'New Terminal Request',
      value: 'NEW_TERMINAL',
    },
    {
      id: 3,
      display: 'FIRS Request',
      value: 'FIRS',
    },
    {
      id: 4,
      display: 'Paper Roll',
      value: 'PAPER_ROLL',
    },
  ],
  VENDORS: [
    {
      id: 1,
      display: 'Customer',
      value: 'CUSTOMER',
    },
    {
      id: 2,
      display: 'Employee',
      value: 'EMPLOYEE',
    },
    {
      id: 3,
      display: 'Vendor',
      value: 'VENDOR',
    },
    {
      id: 4,
      display: 'Self',
      value: 'SELF',
    },
    {
      id: 5,
      display: 'Other',
      value: 'OTHER',
    },
  ],
  ALLPAYMENTS: [
    {
      id: 1,
      display: 'Succeeded',
      value: 'SUCCEEDED',
    },
    {
      id: 2,
      display: 'Refunded',
      value: 'REFUNDED',
    },
    {
      id: 3,
      display: 'Partially Refunded',
      value: 'PARTIAL_REFUNDED',
    },
    {
      id: 4,
      display: 'Refund Processing',
      value: 'REFUND_PROCESSING',
    },
    {
      id: 5,
      display: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      id: 6,
      display: 'Failed',
      value: 'FAILED',
    },
    {
      id: 7,
      display: 'Declined',
      value: 'DECLINED',
    },
    {
      id: 8,
      display: 'Disputed',
      value: 'DISPUTED',
    },
    {
      id: 9,
      display: 'Uncaptured',
      value: 'REQUIRE_CAPTURE',
    },
    {
      id: 10,
      display: 'Require Payment Method',
      value: 'REQUIRE_PAYMENT_METHOD',
    },
    {
      id: 11,
      display: 'Require Confirmation',
      value: 'REQUIRE_CONFIRMATION',
    },
    {
      id: 12,
      display: 'Require Action',
      value: 'REQUIRE_ACTION',
    },
    {
      id: 13,
      display: 'Processing',
      value: 'PROCESSING',
    },
  ],
  TRANSFERLINKS: [
    {
      id: 1,
      display: 'Active',
      value: 'ACTIVE',
    },
    {
      id: 2,
      display: 'Paid',
      value: 'PAID',
    },
    {
      id: 3,
      display: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      id: 4,
      display: 'Declined',
      value: 'DECLINED',
    },
    {
      id: 5,
      display: 'Expired',
      value: 'EXPIRED',
    },
  ],
  BULKTRANSFER: [
    {
      id: 1,
      display: 'Created',
      value: 'CREATED',
    },
    {
      id: 2,
      display: 'Processing',
      value: 'PROCESSING',
    },
    {
      id: 3,
      display: 'Succeeded',
      value: 'SUCCEEDED',
    },
    {
      id: 4,
      display: 'Partially Succeeded',
      value: 'PARTIAL_SUCCEEDED',
    },
    {
      id: 5,
      display: 'Failed',
      value: 'FAILED',
    },
  ],
  PAYMENT_CHECKOUT: [
    {
      id: 1,
      display: 'Active',
      value: 'ACTIVE',
    },
    {
      id: 2,
      display: 'Expired',
      value: 'EXPIRED',
    },
    {
      id: 3,
      display: 'Succeeded',
      value: 'SUCCEEDED',
    },
    {
      id: 4,
      display: 'Cancelled',
      value: 'CANCELLED',
    },
  ],
  WOOCOMMERCE: [
    {
      id: 1,
      display: 'Open',
      value: 'OPEN',
    },
    {
      id: 2,
      display: 'In Process',
      value: 'IN_PROGRESS',
    },
    {
      id: 3,
      display: 'Completed',
      value: 'CLOSED',
    },
    {
      id: 4,
      display: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      id: 5,
      display: 'Created',
      value: 'CREATED',
    },
  ],
  INVENTORY_ORDERS_STATUSES: [
    {
      id: 1,
      display: 'Created',
      value: 'CREATED',
    },
    {
      id: 2,
      display: 'Open',
      value: 'OPEN',
    },
    {
      id: 3,
      display: 'In Progress',
      value: 'IN_PROGRESS',
    },
    {
      id: 4,
      display: 'Out for Delivery',
      value: 'OUT_FOR_DELIVERY',
    },
    {
      id: 5,
      display: 'Closed',
      value: 'CLOSED',
    },
    {
      id: 6,
      display: 'Cancelled',
      value: 'CANCELLED',
    },
  ],
  INVENTORY_ORDERS_PAYMENT_STATUSES: [
    {
      id: 1,
      display: 'Un Paid',
      value: 'UN_PAID',
    },
    {
      id: 2,
      display: 'Paid',
      value: 'PAID',
    },
    {
      id: 3,
      display: 'Refunded',
      value: 'REFUNDED',
    },
    {
      id: 4,
      display: 'Refund Processing',
      value: 'REFUND_PROCESSING',
    },
    {
      id: 5,
      display: 'Partially Refunded',
      value: 'PARTIALLY_REFUNDED',
    },
  ],
  TABLE_RESERVATION: [
    {
      id: 1,
      display: 'Reserved',
      value: 'RESERVED',
    },
    {
      id: 2,
      display: 'Occupied',
      value: 'OCCUPIED',
    },
    {
      id: 3,
      display: 'Closed',
      value: 'CLOSED',
    },
    {
      id: 4,
      display: 'Abandoned',
      value: 'ABANDONED',
    },
    {
      id: 5,
      display: 'Canceled',
      value: 'CANCELED',
    },
  ],
};

export const PAYMENTBUTTON_STATUSES = {
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  DEACTIVE: 'Deactivated',
  DELETED: 'Deleted',
};

export const OCR_REQUEST_STATUSES = {
  PROCESSING: 'Processing',
  SUCCESSFUL: 'Successful',
  FAILED: 'Failed',
};

export const ORDERS_STATUSES = {
  OPEN: 'Open',
  IN_PROGRESS: 'In Process',
  CLOSED: 'Completed',
  CANCELLED: 'Cancelled',
  CREATED: 'Created',
  NO_STATUS: '',
};

export const ORDERS_STATUSES_ENUM = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  CLOSED: 'CLOSED',
  CANCELLED: 'CANCELLED',
  CREATED: 'CREATED',
};

export const PAYMENTSBUTTON_STATUS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  DEACTIVE: 'DEACTIVATED',
  DELETED: 'DELETED',
};

export const SUBSCRIPTION_BUTTON_DISPLAY_STATUS = {
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  DEACTIVE: 'Deactivated',
};

export const SUBSCRIPTION_BUTTON_STATUS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  DEACTIVE: 'DEACTIVATED',
};

export const PAYMENTCHECKOUT_DISPLAY_STATUS = {
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  SUCCEEDED: 'Succeeded',
  CANCELLED: 'Cancelled',
};

export const PAYMENTCHECKOUT_STATUS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  SUCCEEDED: 'SUCCEEDED',
  CANCELLED: 'CANCELLED',
};

export const FILTER_TYPES = {
  DATE: 'Date',
  NUMBER: 'Number',
  DROPDOWN: 'Dropdown',
  STRING: 'String',
};

export const WEBHOOK_STATUSES = {
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  DELETED: 'Deleted',
  FAILED: 'Failed',
};

export const WEBHOOK_STATUS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  DEACTIVATED: 'DEACTIVATED',
  DELETED: 'DELETED',
  FAILED: 'FAILED',
};

export const TERMINALS_STATUS = {
  ACTIVE: 'ACTIVE',
  ACTIVATION_PENDING: 'ACTIVATION_PENDING',
};

export const WALLET_STATUSES = {
  LOAD: 'Load',
  PAYOUT: 'Payout',
  TRANSACTION_CREDIT: 'Transaction Credit',
  TRANSACTION_DEBIT: 'Transaction Debit',
  CASHBACK: 'Cash Back',
  REFUNDED: 'Refunded',
  REFUND: 'Refund',
  PARTIAL_REFUNDED: 'Partially refunded',
};

export const WEBHOOK_EVENTS = {
  PAYMENT: [
    {
      id: 1,
      display: 'Authorized',
      value: 'PAYMENT_AUTHORIZED',
      checked: false,
    },
    {
      id: 2,
      display: 'Failed',
      value: 'PAYMENT_FAILED',
      checked: false,
    },
    {
      id: 3,
      display: 'Captured',
      value: 'PAYMENT_CAPTURED',
      checked: false,
    },
    {
      id: 4,
      display: 'Refund',
      value: 'PAYMENT_REFUND',
      checked: false,
    },
    {
      id: 5,
      display: 'Dispute Created',
      value: 'PAYMENT_DISPUTE_CREATED',
      checked: false,
    },
    {
      id: 6,
      display: 'Dispute Won',
      value: 'PAYMENT_DISPUTE_WON',
      checked: false,
    },
    {
      id: 7,
      display: 'Dispute Lost',
      value: 'PAYMENT_DISPUTE_LOST',
      checked: false,
    },
    {
      id: 8,
      display: 'Dispute Closed',
      value: 'PAYMENT_DISPUTE_CLOSED',
      checked: false,
    },
    {
      id: 20,
      display: 'Succeeded',
      value: 'PAYMENT_SUCCEEDED',
      checked: false,
    },
  ],
  PAYOUT: [
    {
      id: 9,
      display: 'Processed',
      value: 'PAYOUT_PROCESSED',
      checked: false,
    },
  ],
  SUBSCRIPTION: [
    {
      id: 10,
      display: 'Processed',
      value: 'SUBSCRIPTION_INCOMPLETE',
      checked: false,
    },
    {
      id: 11,
      display: 'Paid',
      value: 'SUBSCRIPTION_PAID',
      checked: false,
    },
    {
      id: 12,
      display: 'Active',
      value: 'SUBSCRIPTION_ACTIVE',
      checked: false,
    },
    {
      id: 13,
      display: 'Failed',
      value: 'SUBSCRIPTION_FAILED',
      checked: false,
    },
    {
      id: 14,
      display: 'Updated',
      value: 'SUBSCRIPTION_UPDATED',
      checked: false,
    },
  ],
  INVOICE: [
    {
      id: 15,
      display: 'Paid',
      value: 'INVOICE_PAID',
      checked: false,
    },
    {
      id: 16,
      display: 'Create',
      value: 'INVOICE_CREATE',
      checked: false,
    },
  ],
  INVENTORY: [
    {
      id: 17,
      display: 'Item Add',
      value: 'INVENTORY_ITEM_ADD',
      checked: false,
    },
    {
      id: 18,
      display: 'Item Update',
      value: 'INVENTORY_ITEM_UPDATE',
      checked: false,
    },
    {
      id: 19,
      display: 'Item Delete',
      value: 'INVENTORY_ITEM_DELETE',
      checked: false,
    },
    {
      id: 20,
      display: 'Order Paid',
      value: 'INVENTORY_ORDER_PAID',
      checked: false,
    },
    {
      id: 21,
      display: 'Order Refund',
      value: 'INVENTORY_ORDER_REFUND',
      checked: false,
    },

  ],
  CONTACT: [
    {
      id: 22,
      display: 'Contact Add',
      value: 'CONTACT_CREATE',
      checked: false,
    },
    {
      id: 23,
      display: 'Contact Update',
      value: 'CONTACT_UPDATE',
      checked: false,
    },
    {
      id: 24,
      display: 'Contact Delete',
      value: 'CONTACT_DELETE',
      checked: false,
    },
  ],
  FUNDING_SOURCE: [
    {
      id: 25,
      display: 'Funding Add',
      value: 'FUNDING_SOURCE_CREATE',
      checked: false,
    },
    {
      id: 26,
      display: 'Funding Update',
      value: 'FUNDING_SOURCE_UPDATE',
      checked: false,
    },
    {
      id: 27,
      display: 'Funding Delete',
      value: 'FUNDING_SOURCE_DELETE',
      checked: false,
    },
  ],
  TRANSFER: [
    {
      id: 28,
      display: 'Transfer Scheduled',
      value: 'TRANSFER_SCHEDULED',
      checked: false,
    },
    {
      id: 29,
      display: 'Transfer Processing',
      value: 'TRANSFER_PROCESSING',
      checked: false,
    },
    {
      id: 30,
      display: 'Transfer Succeeded',
      value: 'TRANSFER_SUCCEEDED',
      checked: false,
    },
    {
      id: 31,
      display: 'Transfer Cancelled',
      value: 'TRANSFER_CANCELLED',
      checked: false,
    },
    {
      id: 32,
      display: 'Transfer Declined',
      value: 'TRANSFER_DECLINED',
      checked: false,
    },

  ],
};

export const NOTIFICATION_TYPES = [
  {
    id: 1,
    display: 'Email',
    value: 'EMAIL',
  },
  {
    id: 2,
    display: 'SMS',
    value: 'SMS',
  },
  {
    id: 3,
    display: 'All',
    value: 'ALL',
  },
];

export const REFERRAL_STATUSES = {
  PENDING: 'Pending',
  ACCOUNT_CREATED: 'Created',
  ACCOUNT_VERIFIED: 'Verified',
  ACCOUNT_LIVE: 'Live',
};

export const AFFILIATE_STATUSES = {
  PENDING: 'To Activate',
  ACCOUNT_CREATED: 'Active',
  ACCOUNT_VERIFIED: 'Verified',
  ACCOUNT_LIVE: 'Live',
  REGISTRATION_PENDING: 'Pending',
  TO_ACTIVATE: 'To Activate',
  ACTIVE: 'Active',
  CLOSED: 'Closed',
};

export const CASH_STATUSES = {
  PAID: 'Paid',
  VOID: 'Voided',
  REFUNDED: 'Refunded',
  PARTIAL_REFUNDED: 'Partially refunded',
};

export const CHEQUE_STATUSES = {
  PAID: 'Paid',
  VOID: 'Voided',
  PENDING: 'Pending',
  CANCELLED: 'Cancelled',
};

export const ACCOUNTSLUG_STATUSES = {
  LINKED: 'Linked',
  UNLINKED: 'Unlinked',
};

export const MAX_AMOUNT = {
  MESSAGE_REDEMEED: 'Minimum redemption should be 1',
  MESSAGE_PERCENTAGE: 'Percentage should be between 0.01 - 100',
  MESSAGE_TAX: 'Percentage should be min 0.01',
  MESSAGE_USD: 'Amount should be between $1 - $100,000.00',
  MESSAGE_INR: 'Amount should be between ₹1 - ₹100,00,00,000.00',
  MESSAGE_POSINR: 'Amount should be between ₹0 - ₹100,00,00,000.00',
  MESSAGE_POSUSD: 'Amount should be between $0 - $100,000.00',
  MESSAGE_ITEM_INR: 'Amount should be between ₹0 - ₹100,00,00,000.00',
  MESSAGE_ITEM_USD: 'Amount should be between $0 - $100,000.00',
  FEE_USD: 'Account fee cannot exceed $5',
  FEE_INR: 'Account fee cannot exceed ₹5.00',
  FEE_LIMIT_USD: 5,
  FEE_LIMIT_INR: 5,
  LIMIT_USD: 100000,
  LIMIT_USD_MIN: 1,
  LIMIT_INR: 1000000000,
  LIMIT_INR_MIN: 1,
  LIMIT_MIN: 0.01,
  MIN_AMOUNT: 0,
  LIMIT_MAX: 100,
  IMPS_MIN_AMOUNT: 1,
  IMPS_MAX_AMOUNT: 200000,
  RTGS_MIN_AMOUNT: 200000,
  RTGS_MAX_AMOUNT: 1000000,
  NEFT_MIN_AMOUNT: 1,
  NEFT_MAX_AMOUNT: 1000000,
  MESSAGE_IMPS: 'Amount should be between ₹1 - ₹200,000.00',
  MESSAGE_RTGS: 'Amount should be between ₹200,000.00 - ₹10,00,000.00',
  MESSAGE_NEFT: 'Amount should be between ₹1 - ₹10,00,000.00',
};

export const IMEI = {
  MESSAGE: 'Device ID must be valid',
};

export const DEVICEIP = {
  MESSAGE: 'Device IP must be valid',
};

export const TAX_RATE_TYPES = [
  {
    ID: 1,
    value: false,
    display: 'Exclusive',
  },
  {
    ID: 2,
    value: true,
    display: 'Inclusive',
  },
];

export const RATE_TYPES = {
  EXCLUSIVE: 'Exclusive',
  INCLUSIVE: 'Inclusive',
};

export const TAX_RATE_REGION = [
  {
    ID: 1,
    value: 'INDIA',
    display: 'INDIA',
  },
  {
    ID: 2,
    value: 'USA',
    display: 'USA',
  },
];

export const COUPON_DURATION = [
  {
    id: 1,
    display: 'Once',
    value: 'ONCE',
  },
  {
    id: 2,
    display: 'Forever',
    value: 'FOREVER',
  },
  {
    id: 3,
    display: 'Multiple months',
    value: 'REPEATING',
  },
];

export const COUPON_STATUS = [
  {
    id: 1,
    display: 'Active',
    value: true,
  },
  {
    id: 2,
    display: 'Deactive',
    value: false,
  },
];

export const USAGE_MODES = [
  {
    id: 1,
    display: 'To receive money',
    value: 'RECEIVE_PAYMENTS',
  },
  {
    id: 2,
    display: 'To send money',
    value: 'SEND_PAYMENTS',
  },
  {
    id: 3,
    display: 'To send and receive money',
    value: 'SEND_AND_RECEIVE_PAYMENTS',
  },
  {
    id: 4,
    display: 'Not sure yet',
    value: 'NOT_SURE',
  },
];

export const PAYMENT_OPTIONS = [
  {
    id: 1,
    display: 'One-Time',
    value: 'ONETIME',
  },
  {
    id: 2,
    display: 'Weekly',
    value: 'WEEK',
  },
  {
    id: 3,
    display: 'Monthly',
    value: 'MONTH',
  },
  {
    id: 4,
    display: 'Yearly',
    value: 'YEAR',
  },
];

export const SP_OBJECT_TYPES = [
  {
    ID: 1,
    value: 'INVOICE',
    display: 'INVOICE',
  },
];

export const REFUND_STATUS = ['REFUNDED', 'PARTIAL_REFUNDED', 'REFUND_PROCESSING', 'PARTIALLY_REFUNDED'];

export const SERVICE_ISSUE_TYPES = [
  {
    id: 1,
    value: 'CARD_NOT_ACCEPTING',
    display: 'Card not accepting',
  },
  {
    id: 2,
    value: 'NO_POWER',
    display: 'No Power',
  },
  {
    id: 3,
    value: 'UNABLE_TO_SETTLE',
    display: 'Unable to settle',
  },
  {
    id: 4,
    value: 'ERROR_MESSAGE',
    display: 'Error message',
  },
  {
    id: 5,
    value: 'PRINTER_NOT_WORKING',
    display: 'Printer not working',
  },
  {
    id: 6,
    value: 'NETWORK_NOT_WORKING',
    display: 'Network not working',
  },
  {
    id: 7,
    value: 'OTHERS',
    display: 'Others',
  },
];

export const SERVICES_STATUS = {
  OPEN: 'Open',
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  RESOLVED: 'Resolved',
  DUPLICATE: 'Duplicate',
};

export const SERVICES_TYPES = [
  {
    id: 1,
    display: 'Open',
    value: 'OPEN',
  },
  {
    id: 2,
    display: 'Pending',
    value: 'PENDING',
  },
  {
    id: 3,
    display: 'In Progress',
    value: 'IN_PROGRESS',
  },
  {
    id: 4,
    display: 'Resolved',
    value: 'RESOLVED',
  },
];

export const TYPE_SCHEME = [
  {
    id: 1,
    value: 'CREDIT',
  },
  {
    id: 2,
    value: 'DEBIT',
  },
];

export const TRANSFER_TYPES = ['NEFT', 'RTGS', 'IMPS'];

export const CASHFREE_TRANSFER_TYPES = ['NEFT', 'RTGS', 'IMPS', 'UPI'];

export const BENE_TYPES = {
  VPA: 'VPA',
  BANK: 'BANK',
  UPI: 'UPI',
  NEFT: 'NEFT',
  RTGS: 'RTGS',
  IMPS: 'IMPS',
};

export const LARGE_VOLUME_MERCHANTS = [
  // 'account-72ec92f48ff64712b95f47bfda89934c',
  // 'account-74bb8ed65db84428aad47d71fcaa32db',
];

export const TANGEDCO_GID = [
  'account-4a9b67d5d711424597a0dac9e95fc5b9',
  'account-74bb8ed65db84428aad47d71fcaa32db',
];

export const CardNames = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  AMERICAN_EXPRESS: 'american-express',
  DINERS_CLUB: 'diners-club',
  DISCOVER: 'discover',
  JCB: 'jcb',
  UNIONPAY: 'unionpay',
  MAESTRO: 'maestro',
  ELO: 'elo',
  MIR: 'mir',
  HIPER: 'hiper',
  HIPERCARD: 'hipercard',
};

export const SWIREPAY_URL = 'https://www.swirepay.com';
export const SWIREPAY_SUPPORT_EMAIL = 'support@swirepay.com';
export const SWIREPAY_SUPPORT_PHONE = '408-791-3551';
export const CURRENCY_LOCALES = Object.freeze({
  USD: 'en-US',
  INR: 'en-IN',
});

export const OfflinePaymentTypeIn = [
  {
    key: 'Cash',
    value: 'CASH',
  },
  {
    key: 'Cheque',
    value: 'CHECK',
  },
  {
    key: 'Custom',
    value: 'CUSTOM',
  },
];

export const whenIsCardCharged = {
  IN_ADVANCE: 'IN_ADVANCE',
  WHEN_SERVICE_PROVIDED: 'WHEN_SERVICE_PROVIDED',
};

export const servicesDeliveredIn = {
  ZERO_TO_SEVEN_DAYS: '0-7 Days',
  EIGHT_TO_FOURTEEN_DAYS: '8-14 Days',
  FIFTEEN_TO_THIRTY_DAYS: '15-30 Days',
  OVER_THIRTY_DAYS: 'Over 30 Days',
};

export const refundPolicy = {
  EXCHANGE_ONLY: 'Exchange Only',
  NO_REFUND_OR_EXCHANGE: 'No Refund or Exchange',
  MORE_THAN_THIRTY_DAYS: 'More than 30 Days',
  THIRTY_DAYS_OR_LESS: '30 Days or Less',
};

export const booleanToText = {
  true: 'Yes',
  false: 'No',
};

export const OfflinePaymentTypeUs = [
  {
    key: 'Cash',
    value: 'CASH',
  },
  {
    key: 'Check',
    value: 'CHECK',
  },
  {
    key: 'Custom',
    value: 'CUSTOM',
  },
];

export const TASTECLUB_GID = [
  'account-72ec92f48ff64712b95f47bfda89934c',
  'account-4e5ec9046a25488299b909a62d7e0a53',
];
