/* import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import configureStore from 'store/configure';

import './styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

const store = configureStore();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister(); */

import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './styles/index.scss';

import {
  store,
  persistor,
  sagaMiddleware,
} from 'store/configure';
import rootSaga from 'store/sagas';

import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import App from './App';


sagaMiddleware.run(rootSaga);

const env = process.env.REACT_APP_ENV;

if (env === 'Production' || env === 'Staging') {
  Sentry.init({
    dsn: 'https://e50767c1103f486d9dbdff92e7bea413@o487755.ingest.sentry.io/5546859',
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
