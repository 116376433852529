import { INVENTORY_CATEGORIES_DETAILS } from '../actionTypes';


export const fetchAllShop = (action) => ({
  type: INVENTORY_CATEGORIES_DETAILS.FETCH_ALL_SHOP,
  payload: action.payload,
});


export const fetchInventoryCategorieDetails = (action) => ({
  type: INVENTORY_CATEGORIES_DETAILS.FETCH,
  payload: action.payload,
});

export const fetchItemByCategory = (action) => ({
  type: INVENTORY_CATEGORIES_DETAILS.CATEGORY_ITEM_FETCH,
  payload: action.payload,
});

export const getItemsBySelectedCategory = (action) => ({
  type: INVENTORY_CATEGORIES_DETAILS.GET_SELECTED_CATEOGARY_ITEM,
  payload: action.payload,
});

export const getAllItemsByCategory = (action) => ({
  type: INVENTORY_CATEGORIES_DETAILS.FETCH_ALLITEM_CATEGORY,
  payload: action.payload,
});

export const addItemCategory = (action) => ({
  type: INVENTORY_CATEGORIES_DETAILS.CATEGORY_ITEM_ADD,
  payload: action.payload,
});


export const fetchInventoryOperatingHours = (action) => ({
  type: INVENTORY_CATEGORIES_DETAILS.FETCH_OPERATING_HOURS,
  payload: action.payload,
});
