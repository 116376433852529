import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
} from 'antd';
import { SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  visible: boolean,
  close: boolean,
  submit: boolean,
  submitting: boolean,
}

const AcceptDispute = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
  } = props;
  const [, forceUpdate] = useState();
  const status = 'LOST';
  const [disableButton, setDisableButton] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      status,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      title="Accept Dispute"
      footer={[
        <SpButton onClick={() => close()} type="secondary">No</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Yes</SpButton>,
      ]}
    >
      <div className="px-4">
        <div className="mt-1">Are you sure you want to accept this dispute?</div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AcceptDispute);
