import { AFFILIATE_STATUSES } from 'appconstants';

export default value => {
  if (value === 'PENDING') {
    return AFFILIATE_STATUSES.PENDING;
  } if (value === 'ACCOUNT_CREATED') {
    return AFFILIATE_STATUSES.ACCOUNT_CREATED;
  } if (value === 'ACCOUNT_VERIFIED') {
    return AFFILIATE_STATUSES.ACCOUNT_VERIFIED;
  } if (value === 'ACCOUNT_LIVE') {
    return AFFILIATE_STATUSES.ACCOUNT_LIVE;
  } if (value === 'TO_ACTIVATE') {
    return AFFILIATE_STATUSES.TO_ACTIVATE;
  } if (value === 'REGISTRATION_PENDING') {
    return AFFILIATE_STATUSES.REGISTRATION_PENDING;
  } if (value === 'ACTIVE') {
    return AFFILIATE_STATUSES.ACTIVE;
  } if (value === 'CLOSED') {
    return AFFILIATE_STATUSES.CLOSED;
  } return AFFILIATE_STATUSES.PENDING;
};
