import { INVENTORY_ORDERS_DETAILS } from '../actionTypes';

export const fetchInventoryOrderDetails = (action) => ({
  type: INVENTORY_ORDERS_DETAILS.FETCH,
  payload: action.payload,
});

export const refundOrderDetails = (action) => ({
  type: INVENTORY_ORDERS_DETAILS.REFUND,
  payload: action.payload,
});

export const deleteOrderLineItem = (action) => ({
  type: INVENTORY_ORDERS_DETAILS.DELETE_ITEM,
  payload: action.payload,
});

export const refundOrderLineItem = (action) => ({
  type: INVENTORY_ORDERS_DETAILS.REFUND_ITEM,
  payload: action.payload,
});

export const deleteOrderDetails = (action) => ({
  type: INVENTORY_ORDERS_DETAILS.DELETE,
  payload: action.payload,
});

export const fetchPayments = (action) => ({
  type: INVENTORY_ORDERS_DETAILS.PAYMENTS_FETCH,
  payload: action.payload,
});

export const addItemOrder = (action) => ({
  type: INVENTORY_ORDERS_DETAILS.ADDITEMS_ORDER,
  payload: action.payload,
});
