import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  CHEQUE_TRANSACTIONS,
  NOTIFICATION,
} from '../actionTypes';

const fetchChequeTransactions = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CHEQUE_TRANSACTION}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CHEQUE_TRANSACTION}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CHEQUE_TRANSACTION}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_CHEQUE_TRANSACTION}${query}${sorting}`);
};

export function* getChequeTransactions(action) {
  try {
    const { data: { entity } } = yield call(fetchChequeTransactions, action.payload);
    yield put({ type: CHEQUE_TRANSACTIONS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: CHEQUE_TRANSACTIONS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
