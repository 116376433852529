export default value => {
  if (value === 'DAY') {
    return 'Day';
  } if (value === 'WEEK') {
    return 'Weekly';
  } if (value === 'MONTH') {
    return 'Monthly';
  } if (value === 'YEAR') {
    return 'Yearly';
  } if (value === 'ONETIME') {
    return 'One-time';
  }
  return 'Yearly';
};
