import React, { useState, useEffect } from 'react';
import { Upload, Icon, message } from 'antd';

import { axiosAuthInstance, axiosEmptyInstance } from 'api';

import imageOnError from 'utils/imageOnError';
import Previewicon from '../NavIcons/previewIcon';

type Props = {
  setUrl: Function,
  endPoint: string,
  url: string,
  name: string,
  accept: string,
  type: string,
  keyType: string,
}

const KYCFileUpload = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [url, setImageURL] = useState(props.url);
  const [preview, setPreview] = useState();
  const [fileName, setFileName] = useState('');
  const {
    accept, type, keyType,
  } = props;

  useEffect(() => {
    props.setUrl && props.setUrl(url);
  }, [url]);

  useEffect(() => {
    if (props.url) {
      setPreview(<img
        src={`${props.url}?ts=${new Date().getTime()}`}
        onError={event => imageOnError(event)}
        alt=""
        title={props.name}
        style={{ width: '100%' }}
      />);
    }
  }, []);

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    const PDF = 'application/pdf';
    const IMAGE = 'image/*';
    const pattern = file.type.match(IMAGE) || file.type.match(PDF);
    if (!pattern) {
      message.error('Only image or pdf');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 5MB!');
      return false;
    }
    return true;
  };

  const uploadButton = (
    <div>
      <Icon type={loading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = async info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setImageURL('');
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(props.endPoint);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        // eslint-disable-next-line max-len
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(props.endPoint, keyType ? { fileUrl: entity, keyType } : { fileUrl: entity });
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview(<img src={imageUrl} alt={props.name} onError={event => imageOnError(event)} title={props.name} style={{ width: '100%' }} />);
          });
          setFileName('');
        } else {
          setPreview(<Previewicon />);
          if (type) {
            setFileName(info.file.name);
          }
        }
        setImageURL(fileUrl);
      }
    } catch (error) {
      setImageURL(props.url);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      accept={accept || ''}
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {url ? preview : uploadButton}
      {
        fileName && (
          <span>
            <br />{fileName}
          </span>
        )
      }
    </Upload>
  );
};

export default KYCFileUpload;
