import {
  call,
  put,
} from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  LOADING,
  WALLET,
  NOTIFICATION,
} from '../actionTypes';

const fetchWallet = async () => axiosAuthInstance.get(API_END_POINTS.GET_WALLET);

const postWallet = async (params) => axiosAuthInstance.post(API_END_POINTS.SET_UP_WALLET, params);

const confirmWallet = async (params) => axiosAuthInstance.post(`${API_END_POINTS.SET_UP_WALLET}/confirm`, params);

const getTransactions = async () => axiosAuthInstance.get(`${API_END_POINTS.WALLET_HISTORY_LOG}?size=5&sortBy=createdAt&direction=DESC`);

const restPin = async (params) => axiosAuthInstance.post(`${API_END_POINTS.WALLET_PIN}`, params);

const verifyRestPin = async ({ gid, params }) => axiosAuthInstance.post(`${API_END_POINTS.WALLET_PIN}/${gid}/verify`, params);

const deleteAlias = async (gid) => axiosAuthInstance.delete(`${API_END_POINTS.WALLET_ALIAS}/${gid}`);

export function* getWallet(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchWallet, action.payload);
    yield put({ type: WALLET.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: WALLET.FAILED, payload: e.response.data });
    if (e.response.status !== 401 && e.response.data.message !== 'No Session Wallet found!') {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addWallet(action) {
  try {
    if (action.payload.alias) {
      yield call(postWallet, action.payload);
      yield put({ type: WALLET.WALLET_SETUP, payload: true });
      yield put({ type: WALLET.CONFIRM_WALLET, payload: true });
    } else if (!action.payload.alias) {
      yield put({ type: WALLET.CONFIRM_WALLET, payload: false });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addConfirmWallet(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(confirmWallet, action.payload);
    const { data: { entity } } = yield call(fetchWallet);
    yield put({ type: WALLET.SUCCESS, payload: entity });
    yield put({ type: WALLET.WALLET_AUTH, payload: true });
    if (action.payload.walletGid) {
      yield call(deleteAlias, action.payload.walletGid);
    }
    yield put({ type: WALLET.CONFIRM_WALLET, payload: false });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WALLET.ADD_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401 && e.response.message !== 'Invalid Verification code or Alias') {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    } else if (e.response.status === 401 && e.response.data.message === 'Invalid Verification code or Alias') {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: WALLET.CONFIRM_WALLET, payload: true });
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: WALLET.WALLET_AUTH, payload: false });
  }
}

export function* fetchTransactions(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(getTransactions, action.payload);
    yield put({ type: WALLET.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: WALLET.FETCH_TRANSACTIONS_SUCCESS, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* pinSetUp(action) {
  try {
    if (action.payload.pin) {
      yield put({ type: LOADING.SUBMITTING });
      yield put({ type: LOADING.VALIDATING });
      const { data: { entity } } = yield call(restPin, action.payload);
      yield put({ type: WALLET.REST_PIN_SUCCESS, payload: entity });
      yield put({ type: WALLET.WALLET_PIN, payload: false });
      yield put({ type: WALLET.RESET_WALLET_PIN, payload: true });
      yield put({ type: LOADING.VALIDATED });
      yield put({ type: WALLET.CONFIRM_PIN, payload: true });
    } else if (!action.payload.pin) {
      yield put({ type: WALLET.CONFIRM_PIN, payload: false });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* verifyPin(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(verifyRestPin, action.payload);
    yield put({ type: WALLET.REST_PIN_SUCCESS, payload: entity });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: WALLET.WALLET_PIN, payload: true });
    yield put({ type: WALLET.RESET_WALLET_PIN, payload: false });
    yield put({ type: WALLET.CONFIRM_PIN, payload: false });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WALLET.PIN_SUCCESS,
    });
    const { data } = yield call(fetchWallet, action.payload);
    yield put({ type: WALLET.SUCCESS, payload: data.entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: WALLET.CONFIRM_PIN, payload: true });
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
