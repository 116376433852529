import { SETTLEMENT } from '../actionTypes';

const initialState = {
  currecyCode: [],
  content: [],
  settlementRun: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETTLEMENT.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SETTLEMENT.IMS_ADD:
      return {
        ...state,
        ...action.payload,
      };
    case SETTLEMENT.STRIPE_ADD:
      return {
        ...state,
        ...action.payload,
      };
    case SETTLEMENT.AFFINIPAY_ADD:
      return {
        ...state,
        ...action.payload,
      };
    case SETTLEMENT.FETCH_CURRENCY_SUCCESS:
      return {
        ...state,
        currecyCode: action.payload,
      };
    case SETTLEMENT.FETCH_RUN_SUCCESS:
      return {
        ...state,
        settlementRun: action.payload,
      };
    default:
      return state;
  }
};
