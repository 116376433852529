import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  ACCOUNT_SLUG_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchAccountSlugDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_ACCOUNTSLUG_DETAILS}/${gid}`);

export function* getAccountSlugDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchAccountSlugDetails, action.payload);
    yield put({ type: ACCOUNT_SLUG_DETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: ACCOUNT_SLUG_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
