// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  selectedSettlement: Object,
};

const DeleteSettlement = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedSettlement,
  } = props;

  const deleteSettlement = () => {
    submit(selectedSettlement.gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={deleteSettlement} type="danger">Delete</SpButton>,
      ]}
      title="Delete Settlement"
    >
      <div className="px-4">
        <div className="mt-1">Are you sure you want to delete this settlement?</div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteSettlement;
