import { PAYMENTWIDGETS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  paymentWidgetStatus: undefined,
  paymentWidgetError: undefined,
  currentWidget: 0,
  sampleWidget: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENTWIDGETS.FETCH:
      return {
        ...state,
        paymentWidgetStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case PAYMENTWIDGETS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        paymentWidgetStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case PAYMENTWIDGETS.FAILED:
      return {
        ...state,
        paymentWidgetError: action.payload,
        paymentWidgetStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case PAYMENTWIDGETS.CURRENT_WIDGET:
      return {
        ...state,
        currentWidget: action.payload,
      };
    case PAYMENTWIDGETS.SAMPLE_WIDGET:
      return {
        ...state,
        sampleWidget: action.payload,
      };
    default:
      return state;
  }
};
