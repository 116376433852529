import { DINING } from '../actionTypes';

const initialState = {
  content: [],
  diningSections: {},
  diningTable: {},
  diningDetails: {},
  assignTables: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DINING.SECTION_SUCCESS:
      return {
        ...state,
        diningSections: action.payload,
      };
    case DINING.TABLE_SUCCESS:
      return {
        ...state,
        diningTable: action.payload,
      };
    case DINING.TABLE_DETAILS:
      return {
        ...state,
        diningDetails: action.payload,
      };
    case DINING.ASSIGNTABLE_SUCCESS:
      return {
        ...state,
        assignTables: action.payload,
      };
    default:
      return state;
  }
};
