import React from 'react';
import VisaIcon from '../../../assets/imgs/visa.png';
import MasterIcon from '../../../assets/imgs/master.png';
import AmexIcon from '../../../assets/imgs/ae.png';
import DiscoverIcon from '../../../assets/imgs/discover.png';
import RupayIcon from '../../../assets/imgs/rupay.png';

const CardIconList = () => {
  const cardIcons = [
    VisaIcon, MasterIcon, AmexIcon, DiscoverIcon, RupayIcon,
  ];

  return (
    <div className="mt-4 d-flex justify-content-around px-5">
      {cardIcons && cardIcons.map((value, index) => <img key={index} src={value} height="20px" alt="" />)}
    </div>
  );
};

export default CardIconList;
