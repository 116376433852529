/* eslint-disable */
import React from 'react';

const logout = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
        <g fill="#515967">
            <path d="M21.792 0H2.444C1.094 0 0 1.094 0 2.444v4.89h2.444v-4.89h19.348v19.112H2.444v-4.89H0v4.89C0 22.906 1.094 24 2.444 24h19.348c1.35 0 2.445-1.094 2.445-2.444V2.444c0-1.35-1.094-2.444-2.445-2.444z" />
            <path d="M8.922 16.302L10.704 18 17 12 10.704 6 8.922 7.698 12.177 10.8 0 10.8 0 13.2 12.177 13.2z" />
        </g>
    </svg>
);

export default logout;
