// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Row,
  Col,
  Switch,
} from 'antd';

import {
  SpForm,
  SpH5,
  SpButton,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  staticqrDetailsActions,
} from 'store/actions';

import moment from 'moment-timezone';
import {
  PAGES,
} from 'appconstants';
import CONSTANTS from 'store/constants';

import { QRCode } from 'react-qrcode-logo';

import DeleteStaticQR from './components/DeleteStaticQR';

import Qrlogo from '../../../../assets/imgs/SwirepayQrlogo.png';

type Props = {
  fetchStaticQRDetails: Function,
  staticqrDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  staticqrDetailsStatus: string,
  preserveStatus: Object,
  validator: boolean,
  updateStaticQR: Function,
  deleteStaticQR: Function,
}

const StaticQrDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchStaticQRDetails,
    staticqrDetails,
    loading,
    submitting,
    history,
    selectedAccount,
    staticqrDetailsStatus,
    validator,
    preserveStatus,
    updateStaticQR,
    deleteStaticQR,
  } = props;
  const { gid } = props.match.params;
  const [deleteStaticQrModal, setDeleteStaticQrModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [tipEnabled, setTipEnabled] = useState();

  const getData = () => {
    fetchStaticQRDetails(gid);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      const prevStatus = preserveStatus && preserveStatus.status;
      history.push(`${PAGES.STATICQR}?status.EQ=${prevStatus}`);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  const showDeleteStaticQrModal = () => {
    setDeleteStaticQrModal(true);
  };

  const isEnableChange = (value) => {
    setTipEnabled(value);
    setDisabled(false);
  };

  const deleteSelectedStaticQr = async (qrGid: string) => {
    deleteStaticQR(qrGid);
    setRedirect(true);
  };

  const updateSelectedStaticQr = async (info) => {
    const infoGid = info && info.gid;
    const params = {
      tipEnabled,
    };
    updateStaticQR({
      infoGid,
      params,
    });
    setRedirect(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        staticqrDetails.gid && staticqrDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row className="d-flex justify-content-end my-2 mb-3">
                <Col>
                  <SpButton
                    type="primary"
                    shape="round"
                    className="mr-2"
                    disabled={disabled}
                    onClick={() => updateSelectedStaticQr(staticqrDetails.staticQrAccount)}
                  >
                    Update
                  </SpButton>
                </Col>
                <Col>
                  <SpButton
                    type="danger"
                    shape="round"
                    onClick={showDeleteStaticQrModal}
                  >
                    Delete
                  </SpButton>
                </Col>
              </Row>
              <SpForm>
                <Row className="mt-2" type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Account</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {(staticqrDetails.staticQrAccount && staticqrDetails.staticQrAccount.account && staticqrDetails.staticQrAccount.account.name)
                        || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Created on</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {
                        staticqrDetails.createdAt
                          ? `${moment.utc(staticqrDetails.createdAt).tz(selectedAccount.timezone).format('hh:mm A on MMM DD, YYYY')}` : <>&#8211;</>
                      }
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Code</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {(staticqrDetails && staticqrDetails.code)
                        || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <hr />
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col>
                    <Switch
                      defaultChecked={staticqrDetails.staticQrAccount && staticqrDetails.staticQrAccount.tipEnabled}
                      onChange={isEnableChange}
                    /> Tip Enabled
                  </Col>
                </Row>
              </SpForm>
              {/* DELETE STATIC QR MODAL */}
              {deleteStaticQrModal && (
                <DeleteStaticQR
                  visible={deleteStaticQrModal}
                  staticqrDetails={staticqrDetails}
                  submitting={submitting}
                  close={() => setDeleteStaticQrModal(false)}
                  submit={deleteSelectedStaticQr}
                />
              )}
            </Card>
            <Card className="mt-3">
              <div className="mt-3">
                <SpForm>
                  <QRCode
                    id="qrCodeEl"
                    size={256}
                    value={(staticqrDetails.type === 'NON_LINKED')
                      ? staticqrDetails.merchantQrLink
                      : staticqrDetails.url}
                    logoImage={Qrlogo}
                    // ref={qrCodeRef}
                    // fgColor={primaryColor}
                    logoPadding={5}
                    removeQrCodeBehindLogo
                  />
                  {/* <QRCode
                    id="qrCodeEl"
                    size={160}
                    value={(staticqrDetails.type === 'NON_LINKED')
                      ? staticqrDetails.merchantQrLink
                      : staticqrDetails.url}
                  /> */}
                </SpForm>
              </div>
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  staticqrDetails: state.staticqrDetails,
  staticqrDetailsStatus: state.staticqrDetails.staticqrDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  preserveStatus: state.statusParams.preserveStatus,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStaticQRDetails: gid => dispatch(staticqrDetailsActions.fetchStaticQRDetails({
    payload: gid,
  })),
  updateStaticQR: param => dispatch(staticqrDetailsActions.updateStaticQR({
    payload: param,
  })),
  deleteStaticQR: param => dispatch(staticqrDetailsActions.deleteStaticQR({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(StaticQrDetails);
