import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  ROLES,
  NOTIFICATION,
} from '../actionTypes';

const fetchRoles = async () => axiosAuthInstance.get(`${API_END_POINTS.ROLES}?page=1&size=100&service=false`);

const fetchInternalRoles = async () => axiosAuthInstance.get(API_END_POINTS.INTERNAL_ROLE);

export function* getRoles() {
  try {
    const { data: { entity } } = yield call(fetchRoles);
    yield put({ type: ROLES.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: ROLES.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getInternalRoles() {
  try {
    const { data: { entity } } = yield call(fetchInternalRoles);
    yield put({ type: ROLES.INTERNAL_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: ROLES.INTERNAL_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
