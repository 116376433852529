import { PAYOUT_STATUSES } from 'appconstants';

export default value => {
  if (value === 'IN_TRANSIT') {
    return PAYOUT_STATUSES.IN_TRANSIT;
  } if (value === 'PAID') {
    return PAYOUT_STATUSES.PAID;
  } if (value === 'FAILED') {
    return PAYOUT_STATUSES.FAILED;
  } if (value === 'WITHDRAWN') {
    return PAYOUT_STATUSES.WITHDRAWN;
  }
  return PAYOUT_STATUSES.PENDING;
};
