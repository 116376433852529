// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Button,
} from 'antd';
import { useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import _get from 'lodash.get';

import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';

import getChequeBackground from 'utils/getChequeBackground';
import getChequeStatus from 'utils/getChequeStatus';
import {
  SpText,
  SpH5,
  SpButton,
  SpForm,
  SpStatusTag,
} from 'components/DesignKit';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import Loading from 'components/Loading';
import {
  chequetransactionsDetailsActions,
  notificationActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
  API_STATUS,
  MESSAGES,
} from 'appconstants';
import { NOTIFICATION } from 'store/actionTypes';
import RBAC from 'components/rbac';
import VoidPayment from './component/VoidPayment';

type Props = {
  fetchChequeTransactionDetails: Function,
  notifyChequeTransactionsDetails: Function,
  voidChequePayment: Function,
  setNotification: Function,
  chequetransactionsDetails: Object,
  loading: boolean,
  test: boolean,
  validator: boolean,
  submitting: boolean,
  match: Object,
  params: Object,
  selectedAccount: Object,
  history: {
    push: Function,
  };
  chequetransactionsDetailsStatus: string,
  displayAmount: Object,
  internalRole: Object,
}

const amountFilter = (chequetransactionsDetails) => chequetransactionsDetails;

const displayAmountSelector = createSelector(
  [amountFilter],
  (chequetransactionsDetails) => ({
    prefix: _get(chequetransactionsDetails, 'currency.prefix', null),
    amountDisplay:
      formatNumber(chequetransactionsDetails.amount
        && (chequetransactionsDetails.amount / 100).toFixed(chequetransactionsDetails.currency.toFixed), chequetransactionsDetails.currency),
    feeDisplay: formatNumber(chequetransactionsDetails.fee && (chequetransactionsDetails.fee / 100).toFixed(2), chequetransactionsDetails.currency),
    amountVoided: formatNumber(chequetransactionsDetails.amountVoided
      && (chequetransactionsDetails.amountVoided / 100).toFixed(2), chequetransactionsDetails.currency),
  }),
);

const ChequeDetails = (props: Props) => {
  const {
    fetchChequeTransactionDetails,
    notifyChequeTransactionsDetails,
    voidChequePayment,
    setNotification,
    chequetransactionsDetails,
    loading,
    test,
    validator,
    submitting,
    history,
    selectedAccount,
    chequetransactionsDetailsStatus,
    displayAmount,
    internalRole,
  } = props;

  const location = useLocation();
  const [voided, setVoided] = useState(false);
  const [notify, setNotify] = useState(false);
  const [voidPaymentModal, setVoidPaymentModal] = useState(false);
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.PAYMENTS);
    }
  }, []);

  useEffect(() => {
    fetchChequeTransactionDetails(gid);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setVoidPaymentModal(false);
    }
  }, [validator]);

  useEffect(() => {
    if (chequetransactionsDetails.status === 'PAID') {
      setVoided(true);
    } else {
      setVoided(false);
    }
    if (chequetransactionsDetails.receiptEmail || chequetransactionsDetails.receiptSms) {
      setNotify(true);
    } else {
      setNotify(false);
    }
  }, [chequetransactionsDetails]);

  const selectedReceipts = (record) => {
    if (chequetransactionsDetails && chequetransactionsDetails.spObjectType === 'INVENTORY_ORDER' && chequetransactionsDetails?.spObjectGid) {
      window.open(`${PAGES.ORDER_RECEIPT}?orderGid=${chequetransactionsDetails.spObjectGid}`);
    } else {
      const id = record;
      window.open(`${PAGES.CHEQUE_RECEIPT}/${id}`);
    }
  };
  const selectedNotify = () => {
    notifyChequeTransactionsDetails(gid);
  };

  const voidSelectedPayment = (data: string) => {
    const {
      status,
    } = data;
    const params = {
      status,
    };
    voidChequePayment({
      gid,
      params,
    });
  };

  const downloadCheckImage = async (record) => {
    const filePath = record.split('/account')[0];
    const fileUrl = record.replace(`${filePath}/`, '');
    const DOWNLOAD_API = internalRole ? API_END_POINTS.DOWNLOAD_KYC : API_END_POINTS.DOWNLOAD_CHECK;
    try {
      const { data: { entity: { outputFileUrl } } } = await axiosAuthInstance
        .post(`${DOWNLOAD_API}`, {
          inputFileUrl: fileUrl,
          documentType: 'CHECK_PROOF',
        });
      window.open(outputFileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const showVoidPaymentModal = () => {
    setVoidPaymentModal(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        chequetransactionsDetails.gid && chequetransactionsDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card>
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">
                    {`${displayAmount.prefix}${displayAmount.amountDisplay}`}
                  </SpText>
                  <SpStatusTag style={{ backgroundColor: getChequeBackground(chequetransactionsDetails.status) }}>
                    {getChequeStatus(chequetransactionsDetails.status)}
                  </SpStatusTag>
                </Col>
                <Col>
                  {
                    voided && (
                      // eslint-disable-next-line max-len
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.POS, ROLES.SUPERUSER]}>
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-4"
                          ghost
                          onClick={(e) => { e.stopPropagation(); showVoidPaymentModal(gid); }}
                        >
                          Void
                        </SpButton>
                      </RBAC>
                    )
                  }
                </Col>

              </Row>
              <SpForm>
                <Row type="flex">
                  <Col span={8}>
                    <div>
                      <span className="mr-3">Date</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        {
                          chequetransactionsDetails.transactionDate && selectedAccount && selectedAccount.timezone
                            ? (
                              moment
                                .utc(chequetransactionsDetails.transactionDate)
                                .tz(selectedAccount.timezone)
                                .format('MMM DD, YYYY, hh:mm a')
                            )
                            : <>&#8211;</>
                        }
                      </SpText>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div>
                      <span>Payment Method</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">Cheque</SpText>
                    </div>
                  </Col>
                  {chequetransactionsDetails && chequetransactionsDetails.customer
                    ? (
                      <Col span={8}>
                        <div>
                          <span>Customer</span>
                        </div>
                        <div>
                          <SpText fontSize="18px" fontWeight="600">
                            {chequetransactionsDetails.customer && chequetransactionsDetails.customer.name}
                          </SpText>
                        </div>
                      </Col>
                    ) : ''}
                </Row>
              </SpForm>
              <hr />
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Amount</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.prefix} ${displayAmount.amountDisplay}`}
                    </SpH5>
                  </Col>
                </Row>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Fee</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {
                        chequetransactionsDetails.fee
                          ? `${displayAmount.prefix} ${displayAmount.feeDisplay}`
                          : <>&#8211;</>
                      }
                    </SpH5>
                  </Col>
                </Row>
                {chequetransactionsDetails && chequetransactionsDetails.amountVoided
                  ? (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Amount Voided</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>
                          {
                            chequetransactionsDetails.amountVoided
                              ? `(${displayAmount.prefix} ${(displayAmount.amountVoided).replace('-', '')})`
                              : <>&#8211;</>
                          }
                        </SpH5>
                      </Col>
                    </Row>
                  ) : ''}
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Description</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{(chequetransactionsDetails && chequetransactionsDetails.description)
                      ? (chequetransactionsDetails.description === ' ' ? <>&#8211;</> : chequetransactionsDetails.description) : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <hr />
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Email</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {(chequetransactionsDetails.customer && chequetransactionsDetails.customer.email)
                        ? chequetransactionsDetails.customer.email : <>&#8211;</>}
                    </SpH5>
                  </Col>
                  <Col span={3}>
                    <span>Phone</span>
                  </Col>
                  <Col span={6} className="ml-3">
                    <SpH5>
                      {(chequetransactionsDetails.customer && chequetransactionsDetails.customer.phoneNumber)
                        ? chequetransactionsDetails.customer.phoneNumber : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Cheque Date</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {
                        chequetransactionsDetails.checkDate && selectedAccount && selectedAccount.timezone
                          ? (
                            moment
                              .utc(chequetransactionsDetails.checkDate)
                              .tz(selectedAccount.timezone)
                              .format('YYYY-MM-DD')
                          )
                          : <>&#8211;</>
                      }
                    </SpH5>
                  </Col>
                  <Col span={3}>
                    <span>Proof</span>
                  </Col>
                  <Col span={6}>
                    {(chequetransactionsDetails && chequetransactionsDetails.checkProof)
                      ? (
                        <Button
                          type="link"
                          style={{
                            fontWeight: '600',
                          }}
                          onClick={() => downloadCheckImage(chequetransactionsDetails.checkProof)}
                        >
                          Cheque Image
                        </Button>
                      ) : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              {/* VOID PAYMENT MODAL */}
              {voidPaymentModal && (
                <VoidPayment
                  visible={voidPaymentModal}
                  submitting={submitting}
                  description={(chequetransactionsDetails && chequetransactionsDetails.description)}
                  close={() => setVoidPaymentModal(false)}
                  submit={voidSelectedPayment}
                />
              )}
            </Card>
            {
              chequetransactionsDetails.receiptNumber
                ? (
                  <Card className="mb-3">
                    <Row type="flex" justify="space-between" className="my-2">
                      <Col>
                        <SpText className="text-uppercase mb-3" fontSize="20px">Receipt</SpText>
                      </Col>
                      <Col>
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-4"
                          onClick={() => selectedReceipts(chequetransactionsDetails.receiptNumber)}
                        >
                          View Receipt
                        </SpButton>
                        {
                          notify && (
                            // eslint-disable-next-line max-len
                            <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.POS, ROLES.SUPERUSER]}>
                              <SpButton
                                type="secondary"
                                shape="round"
                                className="mr-4"
                                ghost
                                onClick={selectedNotify}
                              >
                                Send Receipt
                              </SpButton>
                            </RBAC>
                          )
                        }
                      </Col>
                    </Row>
                    <SpForm>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <span>
                            {
                              chequetransactionsDetails.transactionDate && selectedAccount && selectedAccount.timezone
                                ? (
                                  moment
                                    .utc(chequetransactionsDetails.transactionDate)
                                    .tz(selectedAccount.timezone)
                                    .format('MMM DD, YYYY, hh:mm a')
                                )
                                : <>&#8211;</>
                            }
                          </span>
                        </Col>
                        <Col span={4}>
                          <span>
                            {`Receipt ${(chequetransactionsDetails && chequetransactionsDetails.receiptNumber)
                              ? chequetransactionsDetails.receiptNumber
                              : <>&#8211;</>}`}
                          </span>
                        </Col>
                      </Row>
                    </SpForm>
                  </Card>
                ) : ''
            }
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  chequetransactionsDetails: state.chequetransactionsDetails,
  chequetransactionsDetailsStatus: state.chequetransactionsDetails.chequetransactionsDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  internalRole: state.user.internalRole,
  displayAmount: displayAmountSelector(state.chequetransactionsDetails),
});

const mapDispatchToProps = (dispatch) => ({
  fetchChequeTransactionDetails: gid => dispatch(chequetransactionsDetailsActions.fetchChequeTransactionDetails({
    payload: gid,
  })),
  notifyChequeTransactionsDetails: gid => dispatch(chequetransactionsDetailsActions.notifyChequeTransactionsDetails({
    payload: gid,
  })),
  voidChequePayment: param => dispatch(chequetransactionsDetailsActions.voidChequePayment({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ChequeDetails);
