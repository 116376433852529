import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Row,
  Col,
  Tooltip,
  Badge,
  Pagination,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { catalogActions, filterParamsActions } from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import SimpleReactValidator from 'simple-react-validator';
import { SpButton, SpText, FilterWrapper } from 'components/DesignKit';

import moment from 'moment-timezone';
import Filters from 'components/Filters';
import getFilterOperators from 'utils/getFilterOperators';
import Loading from 'components/Loading';
import { PAGES, FILTERS_TYPES, FILTER_STATUSES } from 'appconstants';
import formatAmount from 'utils/formatAmount';
import FilterIcon from 'components/NavIcons/filterIcon';
import CreateOrder from './components/CreateOrder';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const CUSTOMERS_FILTERS_TYPES = FILTERS_TYPES.CATALOG;
const TransactionTypes = FILTER_STATUSES.CATALOG;
const placeholder = 'Select type';

type Props = {
  loading: Boolean,
  fetchCatalogs: Function,
  catalogs: Array<objects>,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  totalElements: Number,
  test: Boolean,
  orderCatalog: Function,
  submitting: Boolean,
  setFilterData: Function,
  preserveFilters: Object,
  disableOption: Boolean,
};

const Catalog = (props: Props) => {
  const {
    loading,
    fetchCatalogs,
    catalogs,
    selectedAccount,
    history,
    totalElements,
    test,
    orderCatalog,
    submitting,
    setFilterData,
    preserveFilters,
    disableOption,
  } = props;
  const location = useLocation();
  const [, forceUpdate] = useState();
  const myRef = useRef();
  const { pathname } = location;
  const currencyType = selectedAccount && selectedAccount.currency;
  const [currentPage, setCurrentPage] = useState(1);
  const [createOrderModal, setCreateOrderModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [seletedRecord, setSelectedRecord] = useState();
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(CUSTOMERS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterCustomers, setFilterCustomers] = useState((preserveFilters && preserveFilters.filterCustomers)
    ? (preserveFilters && preserveFilters.filterCustomers)
    : [{
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [filterColor, setFilterColor] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());

  const debouncedfetchCatalogs = debounce(fetchCatalogs, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    debouncedfetchCatalogs({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!submitting) {
      setCreateOrderModal(false);
    }
  }, [submitting]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      // inside click
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      // Outside click
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page;
    setCurrentPage(page);
    let tempParams = '';
    if (filters && filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters && filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: PAGES.CATALOG_PRODUCTS,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterCustomers];
    if (filterCustomers.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = CUSTOMERS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterCustomers(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterCustomers];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'type') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = CUSTOMERS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterCustomers(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterCustomers[index].filterName === 'createdAt') {
      if (filterCustomers[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterCustomers[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterCustomers[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterCustomers];
    list[index].filterValue = selectedValue;
    setFilterCustomers(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterCustomers];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterCustomers[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterCustomers(list);
  };

  const handleAddClick = () => {
    const list = [...filterCustomers];
    setFilterCustomers([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterCustomers([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(CUSTOMERS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.CATALOG_PRODUCTS);
  };

  const applyFilters = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    const customersFilters = filterCustomers.filter((item) => item.checked);
    if (customersFilters[0].filterValue) {
      setCount(customersFilters && customersFilters.length);
      for (let i = 0; i < customersFilters.length; i += 1) {
        if (customersFilters[i].checked) {
          if (customersFilters[i].filterName === 'createdAt') {
            customersFilters[i] = customersFilters[i].filterValue;
          } else if (customersFilters[i].filterName === 'email') {
            const email = encodeURIComponent(customersFilters[i].filterValue);
            customersFilters[i] = `${customersFilters[i].filterName}.${customersFilters[i].filterOperator}=${email}`;
          } else {
            customersFilters[i] = `${customersFilters[i].filterName}.${customersFilters[i].filterOperator}=${customersFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(customersFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPages = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPages}${sortParams}`,
          filterCustomers,
        });
        history.push({
          pathname: PAGES.CATALOG_PRODUCTS,
          search: `?filterParams=${filterParams}&pageNum=${currentPages}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterCustomers,
        });
        history.push({
          pathname: PAGES.CATALOG_PRODUCTS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const createOrder = (record) => {
    setSelectedId(record.gid);
    setSelectedName(record.name);
    setSelectedStatus('PROCESSED');
    setSelectedType(record.type);
    setCreateOrderModal(true);
    setSelectedRecord(record);
  };

  const orderCreate = (data) => {
    const payload = {
      quantity: data.quantity,
      poNumber: data.poNumber,
      catalogOrderStatus: selectedStatus,
      catalogOrderType: selectedType,
      billingFrequency: data.billingFrequency,
      notes: data.notes,
      adminInternalNotes: data.notes,
      adminExternalNotes: data.notes,
      subTotal: data.subTotal,
      shippingCost: data.shippingCost,
      promotionalCredits: data.promotionalCredits,
      totalAmountDue: data.totalAmountDue,
      confirmationEmail: data.confirmationEmail,
      contactPhone: data.contactPhone,
      catalogGid: selectedId,
      shippingAddress: {
        city: data.city,
        countryCode: data.countryCode === 1 ? 'US' : (data.countryCode === 2 ? 'IND' : 'CANADA'),
        deleted: false,
        postalCode: data.postalCode,
        state: data.state,
        street: data.street,
      },
    };
    orderCatalog(payload);
  };

  if (loading) {
    return <Loading />;
  }


  return (
    <>
      <div>
        <Row
          style={{
            justifyContent: 'end',
            display: 'flex',
            top: '-25px',
          }}
        >
          <Col>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Orders">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && (
                <FilterWrapper
                  style={{ marginLeft: '-262px' }}
                >
                  <Filters
                    filtersGrid={filterCustomers}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                    stuses={TransactionTypes}
                    placeholder={placeholder}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterCustomers.length < CUSTOMERS_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            {'\u002B'}&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
            </span>
          </Col>
        </Row>
      </div>
      <div>
        <Row className="catalog-list">
          {
            catalogs && catalogs.length !== 0 && (
              <>
                {
                  catalogs.map((item) => (
                    item.isAvailable === true ? (
                      <Col span={8}>
                        <div className="py-2 pl-2 pr-2">
                          <Card
                            className="catalog-list"
                            style={{
                              borderRadius: '15px', height: '430px', overflowY: 'auto',
                            }}
                          >
                            <Row>
                              <Col span={12}>
                                <img src={item.imageUrl} alt="prodct" width="100" height="100" />
                              </Col>
                              <Col span={12}>
                                <SpText fontSize="18px" fontWeight="400">{item.name}</SpText>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <span className="text-muted" style={{ fontSize: '12px' }}>Description</span>
                              </Col>
                              <Col span={12}>
                                <SpText fontSize="14px" fontWeight="400" style={{ wordBreak: 'break-word' }}>{item.description}</SpText>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <span className="text-muted" style={{ fontSize: '12px' }}>Type</span>
                              </Col>
                              <Col span={12}>
                                <SpText fontSize="14px" fontWeight="400">{item.type}</SpText>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <span className="text-muted" style={{ fontSize: '12px' }}>Amount</span>
                              </Col>
                              <Col span={12}>
                                <SpText fontSize="14px" fontWeight="400">
                                  {currencyType.prefix} {formatAmount((item.price / 100), currencyType)}
                                </SpText>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col span={24}>
                                <span className="text-muted" style={{ fontSize: '12px' }}>&nbsp;</span>
                              </Col>
                            </Row>
                          </Card>
                          <Row className="mt-2" style={{ position: 'absolute', bottom: '20px', paddingLeft: '25%' }}>
                            <Col span={24}>
                              <SpButton
                                type="primary"
                                shape="round"
                                onClick={() => createOrder(item)}
                                disabled={disableOption}
                              >
                                Order now
                              </SpButton>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    ) : (
                      <>
                        <SpText>&#8211;</SpText>
                      </>
                    )
                  ))
                }
              </>
            )
          }
        </Row>
        {
          createOrderModal && (
            <CreateOrder
              visible={createOrderModal}
              submitting={false}
              phoneCode={+1}
              id={selectedId}
              selectedAccount={selectedAccount}
              close={() => setCreateOrderModal(false)}
              submit={orderCreate}
              selectedName={selectedName}
              seletedRecord={seletedRecord}
            />
          )
        }
        <Row type="flex" justify="end">
          <Col className="mr-2">
            <div
              style={{
                paddingTop: '6px',
              }}
            >
              {`Total ${totalElements} items`}
            </div>
          </Col>
          <Col>
            <Pagination
              current={currentPage}
              pageSize={10}
              total={totalElements}
              onChange={handleTableChange}
            // showSizeChanger
            />
          </Col>
        </Row>

      </div>
    </>
  );
};

const mapStateToProps = state => ({
  loading: state.loading.loading,
  catalogs: state.catalog.content,
  selectedAccount: state.account.selectedAccount,
  totalElements: state.catalog.totalElements,
  test: state.account.test,
  preserveFilters: state.filterParams.preserveFilters,
  submitting: state.loading.submitting,
});

const mapDispatchToProps = dispatch => ({
  fetchCatalogs: param => dispatch(catalogActions.fetchCatalogs({
    payload: param,
  })),
  orderCatalog: param => dispatch(catalogActions.orderCatalog({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Catalog);
