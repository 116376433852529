import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  SUPPORT,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchSupport = async () => axiosAuthInstance.get(`${API_END_POINTS.GET_SUPPORT}`);

const postSupport = async payload => axiosAuthInstance.post(API_END_POINTS.GET_SUPPORT, payload);

const patchSupport = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_SUPPORT}/${gid}`, params);

export function* getSupport(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchSupport, action.payload);
    yield put({ type: SUPPORT.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: SUPPORT.FAILED, payload: e.response.data });
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addSupport(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(postSupport, action.payload);
    const { data: { entity } } = yield call(fetchSupport);
    yield put({ type: SUPPORT.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUPPORT.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateSupport(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchSupport, action.payload);
    const { data: { entity } } = yield call(fetchSupport);
    yield put({ type: SUPPORT.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUPPORT.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
