// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/Loading';
import {
  Spin,
} from 'antd';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import {
  invoiceDetailsAction,
  notificationActions,
  walletInvoiceDetailsActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  PAGES,
  API_STATUS,
  INVOICE_TYPES,
  MESSAGES,
} from 'appconstants';
import View from './components/ViewInvoice';
import Edit from './components/EditInvoice';

type Props = {
  fetchWalletInvoiceDetails: Function,
  invoiceDetails: Object,
  invoiceDetailsStatus: String,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  loading: boolean,
  updateWalletInvoice: Function,
  getBeneficiaryList: Function,
  beneficiaryList: Array<Object>,
  submitting: boolean,
  validator: boolean,
  addedNewBeneficiary: Object,
  addWalletBeneficiary: Function,
  deleteWalletInvoice: Function,
  getProductsList: Function,
  productsList: Array<Object>,
  addNewProcut: Function,
  setNotification: Function,
  notifyWalletInvoice: Function,
  addedNewProduct: Object,
  addNewCoupon: Function,
  getTaxes: Function,
  taxList: Array<Object>,
  addNewTaxRate: Function,
  newCoupon: Object,
  newTaxrate: Object,
  payWalletInvoice: Function,
  cancelWalletInvoices: Function,
};

const InvoiceDetails = (props: Props) => {
  const {
    fetchWalletInvoiceDetails,
    invoiceDetails,
    invoiceDetailsStatus,
    selectedAccount,
    history,
    loading,
    updateWalletInvoice,
    getBeneficiaryList,
    beneficiaryList,
    submitting,
    validator,
    addedNewBeneficiary,
    addWalletBeneficiary,
    deleteWalletInvoice,
    getProductsList,
    productsList,
    addNewProcut,
    setNotification,
    notifyWalletInvoice,
    addedNewProduct,
    addNewCoupon,
    getTaxes,
    taxList,
    addNewTaxRate,
    newCoupon,
    newTaxrate,
    payWalletInvoice,
    cancelWalletInvoices,
  } = props;

  // eslint-disable-next-line react/prop-types
  const { gid } = props.match.params;
  // eslint-disable-next-line react/prop-types
  const gidPath = gid.split('-')[1];
  const [zipTag, setZipTag] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [loader, setLoader] = useState(false);

  const getData = () => {
    fetchWalletInvoiceDetails(gid);
    getBeneficiaryList();
    getProductsList();
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.WALLETINVOICES);
    }
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.WALLETINVOICES);
    }
  }, [validator]);

  const getProducts = (searchText: string) => {
    getProductsList(searchText);
  };

  const sendInvoice = () => {
    const params = {
      type: 'EMAIL',
    };
    notifyWalletInvoice({
      gid,
      params,
    });
  };

  const invoiceUpdate = (payload: Object) => {
    let endPoint;
    const { params } = payload;
    const { status } = params;

    if (status === 'DRAFT') {
      endPoint = 'draft';
    } else if (status === 'ACTIVE') {
      endPoint = 'active';
    }

    updateWalletInvoice({
      gid,
      endPoint,
      params,
    });
  };

  const invoicePay = () => {
    payWalletInvoice({
      gid,
    });
  };

  const createNewInvoice = () => {
    history.push(`${PAGES.NEWWALLET_INVOICE}?INVOICE.ID=${gid}`);
  };

  const cancelInvoice = () => {
    const params = {
      status: 'CANCELLED',
    };
    cancelWalletInvoices({
      gid,
      params,
    });
    setRedirect(true);
  };

  const deleteInvoices = () => {
    const params = {
      status: 'CANCELLED',
    };
    deleteWalletInvoice({
      gid,
      params,
    });
    setRedirect(true);
  };

  const searchCustomers = (searchParams: string) => {
    getBeneficiaryList(searchParams);
  };

  // const customerAdd = (params: Object) => {
  //   addWalletBeneficiary(params);
  // };

  const productAdd = (params: Object) => {
    addNewProcut(params);
  };

  const invoiceDownload = async () => {
    const pdfKey = invoiceDetails.invoicePdfKey;
    setLoader(true);
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.VENDORINVOICE_API}/${pdfKey}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.INVOICE.DOWNLOAD_ERROR,
      });
    } finally {
      setLoader(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={loader ? 'OVERLAY' : ''}>
        {
          loader && (
            <Spin style={{ width: '100%', height: '100%', transform: 'translateY(50%)' }} />
          )
        }
      </div>
      {
        invoiceDetails.gid && invoiceDetailsStatus === API_STATUS.SUCCESS && (
          <>
            {
              invoiceDetails.status === INVOICE_TYPES.DRAFT ? (
                <Edit
                  gid={gid}
                  invoiceDetails={invoiceDetails}
                  selectedAccount={selectedAccount}
                  invoiceUpdate={invoiceUpdate}
                  deleteInvoices={deleteInvoices}
                  customersList={beneficiaryList}
                  searchCustomers={searchCustomers}
                  submitting={submitting}
                  zipTag={zipTag}
                  validator={validator}
                  addedNewCustomer={addedNewBeneficiary}
                  addWalletBeneficiary={addWalletBeneficiary}
                  getProducts={getProducts}
                  productsList={productsList}
                  productAdd={productAdd}
                  addedNewProduct={addedNewProduct}
                  createNewInvoice={createNewInvoice}
                  addNewCoupon={addNewCoupon}
                  getTaxes={getTaxes}
                  taxList={taxList}
                  addNewTaxRate={addNewTaxRate}
                  newCoupon={newCoupon}
                  newTaxrate={newTaxrate}
                />
              ) : (
                <View
                  gid={gid}
                  invoiceDetails={invoiceDetails}
                  selectedAccount={selectedAccount}
                  sendInvoice={sendInvoice}
                  cancelInvoice={cancelInvoice}
                  validator={validator}
                  submitting={submitting}
                  invoicePay={invoicePay}
                  invoiceDownload={invoiceDownload}
                  createNewInvoice={createNewInvoice}
                />
              )
            }
          </>

        )
      }
    </>

  );
};

const mapStateToProps = (state) => ({
  invoiceDetails: state.walletInvoiceDetails.invoice,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  loading: state.loading.loading,
  invoiceDetailsStatus: state.walletInvoiceDetails.invoiceDetailsStatus,
  beneficiaryList: state.walletInvoiceDetails.beneficiaryList,
  addedNewBeneficiary: state.walletInvoiceDetails.newBeneficiary,
  productsList: state.invoiceDetails.productsList,
  addedNewProduct: state.invoiceDetails.newProduct,
  taxList: state.invoiceDetails.taxList,
  newCoupon: state.invoiceDetails.newCoupon,
  newTaxrate: state.invoiceDetails.newTaxrate,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWalletInvoiceDetails: gid => dispatch(walletInvoiceDetailsActions.fetchWalletInvoiceDetails({
    payload: gid,
  })),
  updateWalletInvoice: param => dispatch(walletInvoiceDetailsActions.updateWalletInvoice({
    payload: param,
  })),
  getBeneficiaryList: param => dispatch(walletInvoiceDetailsActions.getBeneficiaryList({
    payload: param,
  })),
  addWalletBeneficiary: param => dispatch(walletInvoiceDetailsActions.addWalletBeneficiary({
    payload: param,
  })),
  deleteWalletInvoice: param => dispatch(walletInvoiceDetailsActions.deleteWalletInvoice({
    payload: param,
  })),
  cancelWalletInvoices: param => dispatch(walletInvoiceDetailsActions.cancelWalletInvoices({
    payload: param,
  })),
  getProductsList: param => dispatch(invoiceDetailsAction.getProductsList({
    payload: param,
  })),
  addNewProcut: param => dispatch(invoiceDetailsAction.addNewProcut({
    payload: param,
  })),
  notifyWalletInvoice: param => dispatch(walletInvoiceDetailsActions.notifyWalletInvoice({
    payload: param,
  })),
  addNewCoupon: param => dispatch(invoiceDetailsAction.addNewCoupon({
    payload: param,
  })),
  getTaxes: param => dispatch(invoiceDetailsAction.getTaxes({
    payload: param,
  })),
  addNewTaxRate: param => dispatch(invoiceDetailsAction.addNewTaxRate({
    payload: param,
  })),
  payWalletInvoice: param => dispatch(walletInvoiceDetailsActions.payWalletInvoice({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
