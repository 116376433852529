import { ISSUER_RATES } from '../actionTypes';

export const fetchIssuerRates = (action) => ({
  type: ISSUER_RATES.FETCH,
  payload: action.payload,
});

export const addIssuerRate = (action) => ({
  type: ISSUER_RATES.ADD,
  payload: action.payload,
});

export const fetchTransferType = (action) => ({
  type: ISSUER_RATES.TRANSFERTYPE_FETCH,
  payload: action.payload,
});
