import { FUNDINGSOURCE } from '../actionTypes';

export const fetchFundingSourceList = (payload) => ({
  type: FUNDINGSOURCE.FETCH,
  payload,
});

export const createFundingSource = (action) => ({
  type: FUNDINGSOURCE.CREATE,
  payload: action.payload,
});
