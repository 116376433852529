// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
} from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedGlobalRestrictedKey: Object,
};

const DeleteGlobalRestrictedKey = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedGlobalRestrictedKey,
  } = props;

  const gid = selectedGlobalRestrictedKey ? selectedGlobalRestrictedKey.gid : '';
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const deleteGRKey = () => {
    submit(gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={deleteGRKey} disabled={disableButton} type="danger">Delete</SpButton>,
      ]}
      title="Delete GR Key"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-1 mb-2"><SpH5>Are you sure you want to delete this global restricted key?</SpH5></div>
        <div className="pb-3">
          <span>{gid}</span>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(DeleteGlobalRestrictedKey);
