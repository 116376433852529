// @flow
import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Row,
  Col,
  Input,
} from 'antd';

import {
  SpForm,
  SpH5,
  SpButton,
  SpError,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  reportTemplatesDetailsActions,
} from 'store/actions';

import moment from 'moment-timezone';
import {
  PAGES,
} from 'appconstants';
import CONSTANTS from 'store/constants';
import SimpleReactValidator from 'simple-react-validator';

import DeleteTemplate from './components/DeleteTemplate';

const { TextArea } = Input;

type Props = {
  fetchReportTemplateDetails: Function,
  reportTemplatesDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  reportTemplatesDetailsStatus: string,
  validator: boolean,
  updateReportTemplate: Function,
  deleteReportTemplate: Function,
}

const CustomReportTemplateDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchReportTemplateDetails,
    reportTemplatesDetails,
    loading,
    submitting,
    history,
    selectedAccount,
    reportTemplatesDetailsStatus,
    validator,
    updateReportTemplate,
    deleteReportTemplate,
  } = props;
  const { gid } = props.match.params;
  const [, forceUpdate] = useState();
  const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [name, setName] = useState();
  const [query, setQuery] = useState();
  const [param1, setParam1] = useState();
  const [param2, setParam2] = useState();
  const [param3, setParam3] = useState();
  const [disabled, setDisabled] = useState(true);

  const simpleValidator = useRef(new SimpleReactValidator());

  const getData = () => {
    fetchReportTemplateDetails(gid);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.CUSTOMREPORT_TEMPLATE);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData();
  }, [location, selectedAccount]);

  useEffect(() => {
    if (reportTemplatesDetails) {
      setName(reportTemplatesDetails.templateName);
      const reportQuery = (reportTemplatesDetails.query) ? atob(reportTemplatesDetails && reportTemplatesDetails.query) : '';
      setQuery(reportQuery);
      setParam1(reportTemplatesDetails.param1Label || '');
      setParam2(reportTemplatesDetails.param2Label || '');
      setParam3(reportTemplatesDetails.param3Label || '');
    }
  }, [reportTemplatesDetails]);

  const showDeleteStaticQrModal = () => {
    setDeleteTemplateModal(true);
  };

  const deleteTemplate = async () => {
    deleteReportTemplate(gid);
    setRedirect(true);
  };

  const updateSelectedStaticQr = async (event) => {
    setDisabled(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisabled(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const encodeQuery = btoa(query);
    let param1Label;
    let param2Label;
    let param3Label;
    if (param1) {
      param1Label = param1;
    }
    if (param1 && param2) {
      param2Label = param2;
    }
    if (param1 && param2 && param3) {
      param3Label = param3;
    }
    const params = {
      templateName: name,
      query: encodeQuery,
      param1Label,
      param2Label,
      param3Label,
    };
    updateReportTemplate({
      gid,
      params,
    });
    setRedirect(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        reportTemplatesDetails && reportTemplatesDetails.gid && (reportTemplatesDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED) && (
          <>
            <Card className="mb-3">
              <Row className="d-flex justify-content-end my-2 mb-3">
                <Col>
                  <SpButton
                    type="primary"
                    shape="round"
                    className="mr-2"
                    disabled={disabled}
                    onClick={updateSelectedStaticQr}
                  >
                    Update
                  </SpButton>
                </Col>
                <Col>
                  <SpButton
                    type="danger"
                    shape="round"
                    onClick={showDeleteStaticQrModal}
                  >
                    Delete
                  </SpButton>
                </Col>
              </Row>
              <SpForm>
                <Row className="mt-2" type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Template Name</span>
                  </Col>
                  <Col span={9}>
                    <Input
                      placeholder="Enter a name"
                      value={name}
                      onChange={e => {
                        setName(e.currentTarget.value);
                        setDisabled(false);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('name', name, 'required')}
                    </SpError>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row className="mt-2" type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Query</span>
                  </Col>
                  <Col span={9}>
                    <TextArea
                      rows={10}
                      placeholder="Enter Query"
                      value={query}
                      onChange={e => {
                        setQuery(e.currentTarget.value);
                        setDisabled(false);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('query', query, 'required')}
                    </SpError>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row className="mt-2" type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Custom Report Param 1</span>
                  </Col>
                  <Col span={9}>
                    <Input
                      placeholder="Enter a input name"
                      value={param1}
                      onChange={e => {
                        setParam1(e.currentTarget.value);
                        setDisabled(false);
                      }}
                    />
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row className="mt-2" type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Custom Report Param 2</span>
                  </Col>
                  <Col span={9}>
                    <Input
                      placeholder="Enter a input name"
                      value={param2}
                      onChange={e => {
                        setParam2(e.currentTarget.value);
                        setDisabled(false);
                      }}
                    />
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row className="mt-2" type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Custom Report Param 3</span>
                  </Col>
                  <Col span={9}>
                    <Input
                      placeholder="Enter a input name"
                      value={param3}
                      onChange={e => {
                        setParam3(e.currentTarget.value);
                        setDisabled(false);
                      }}
                    />
                  </Col>
                </Row>
              </SpForm>
              <hr />
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Created on</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {
                        reportTemplatesDetails.createdAt
                          ? `${moment.utc(reportTemplatesDetails.createdAt).tz(selectedAccount.timezone).format('hh:mm A on MMM DD, YYYY')}`
                          : <>&#8211;</>
                      }
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              {/* DELETE TEMPLATE MODAL */}
              {deleteTemplateModal && (
                <DeleteTemplate
                  visible={deleteTemplateModal}
                  reportTemplatesDetails={reportTemplatesDetails}
                  submitting={submitting}
                  close={() => setDeleteTemplateModal(false)}
                  submit={deleteTemplate}
                />
              )}
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  reportTemplatesDetails: state.reportTemplatesDetails,
  reportTemplatesDetailsStatus: state.reportTemplatesDetails.reportTemplatesDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchReportTemplateDetails: gid => dispatch(reportTemplatesDetailsActions.fetchReportTemplateDetails({
    payload: gid,
  })),
  updateReportTemplate: param => dispatch(reportTemplatesDetailsActions.updateReportTemplate({
    payload: param,
  })),
  deleteReportTemplate: param => dispatch(reportTemplatesDetailsActions.deleteReportTemplate({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CustomReportTemplateDetails);
