import { ADMIN_ORDERS } from '../actionTypes';

export const fetchAdminOrders = (action) => ({
  type: ADMIN_ORDERS.FETCH,
  payload: action.payload,
});

export const updateAdminOrder = (action) => ({
  type: ADMIN_ORDERS.UPDATE,
  payload: action.payload,
});
