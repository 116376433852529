import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
} from 'antd';
import {
  SpH5,
  SpText,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';
import { useLocation } from 'react-router-dom';
import { NOTIFICATION } from 'store/actionTypes';
import Loading from 'components/Loading';
import {
  notificationActions,
  usonboardingActions,
} from 'store/actions';
import Clipboard from 'components/NavIcons/copyclipboard';
import getOnboardBackground from 'utils/getOnboardBackground';
import getOnboardStatus from 'utils/getOnboardStatus';

type Props = {
  loading: boolean,
  test: boolean,
  usonboarding: Array,
  fetchOnboardingLinks: Function,
  addOnboardingLink: Function,
  setNotification: Function,
};

const USOnboarding = (props: Props) => {
  const location = useLocation();
  const {
    loading,
    test,
    usonboarding,
    fetchOnboardingLinks,
    addOnboardingLink,
    setNotification,
  } = props;

  const [showLink, setShowLink] = useState(false);

  const getData = () => {
    fetchOnboardingLinks(location.search);
  };

  useEffect(() => {
    getData();
  }, [location, test]);

  useEffect(() => {
    if ((usonboarding && usonboarding.length) > 0) {
      setShowLink(true);
    }
  }, [usonboarding]);

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: 'Link Copied Successfully.',
    });
  };

  const generateLink = () => {
    addOnboardingLink({});
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="mt-4" type="flex" justify="space-between">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">Onboarding Link</SpText>
        </Col>
      </Row>
      {
        !showLink && (
          <Row>
            <Col className="mt-4">
              <SpButton
                type="secondary"
                shape="round"
                onClick={() => generateLink()}
              >
                Generate Onboarding Link
              </SpButton>
            </Col>
          </Row>
        )
      }

      {
        showLink && (
          <>
            <Row>
              <Col className="mt-4">
                <SpH5>{usonboarding && usonboarding[0].link}
                  <SpButton
                    type="secondary"
                    className="ml-2"
                    borderRadius="50px"
                    onClick={() => { copyCode(usonboarding && usonboarding[0].link); }}
                  >
                    <Clipboard />
                  </SpButton>
                </SpH5>
              </Col>
            </Row>
            <Row className="mt-4">
              <SpStatusTag style={{ backgroundColor: getOnboardBackground(usonboarding && usonboarding[0].status) }}>
                {getOnboardStatus(usonboarding && usonboarding[0].status)}
              </SpStatusTag>
            </Row>
          </>
        )
      }
    </Card>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  test: state.account.test,
  usonboarding: state.usonboarding.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOnboardingLinks: gid => dispatch(usonboardingActions.fetchOnboardingLinks({
    payload: gid,
  })),
  addOnboardingLink: payload => dispatch(usonboardingActions.addOnboardingLink({
    payload,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(USOnboarding);
