export default status => {
    if (status === 'CREATED') {
      return '#099075';
    } if (status === 'RESERVED') {
      return '#090075';
    } if (status === 'MOVED') {
      return '#D17621';
    } if (status === 'CANCELLED') {
      return '#EB464B';
    } if (status === 'EXPIRED') {
      return '#EB464B';
    } return '#ffe0b1';
  };
  