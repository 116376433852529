// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  selectedTaxrate: Object,
};

const DeleteTaxRate = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedTaxrate,
  } = props;

  const deleteTaxRate = () => {
    submit(selectedTaxrate.gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={deleteTaxRate} type="danger">Delete</SpButton>,
      ]}
      title="Delete Tax Rate"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to delete this Taxrate?</div>
        <SpH5 className="pb-3">
          <div>{selectedTaxrate.displayName}</div>
        </SpH5>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteTaxRate;
