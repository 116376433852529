import { call, put } from 'redux-saga/effects';
import { MESSAGES } from 'appconstants';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  SECRETKEYS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchSecretkeys = async (payload) => {
  const query = '?page=1&size=100';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.SECRETKEYS}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.SECRETKEYS}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.SECRETKEYS}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.SECRETKEYS}${query}${sorting}`);
};

const postSecretkey = async ({ gid, params }) => axiosAuthInstance.post(`${API_END_POINTS.SECRETKEYS}/${gid}/roll`, params);

export function* getSecretkeys(action) {
  try {
    const { data: { entity } } = yield call(fetchSecretkeys, action.payload);
    yield put({ type: SECRETKEYS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: SECRETKEYS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* updateSecretkey(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(postSecretkey, action.payload);
    const { data: { entity } } = yield call(fetchSecretkeys, action.payload);
    yield put({ type: SECRETKEYS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SECRETKEYS.UPDATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
