import { TERMINAL } from '../actionTypes';

export const fetchTerminals = (action) => ({
  type: TERMINAL.FETCH,
  payload: action.payload,
});

export const updateTerminal = (action) => ({
  type: TERMINAL.UPDATE,
  payload: action.payload,
});

export const getLocationsList = (action) => ({
  type: TERMINAL.LOCATIONS_FETCH,
  payload: action.payload,
});

export const setLocationData = action => ({
  type: TERMINAL.SEARCH_LOCATION,
  payload: action.payload,
});
