import styled from 'styled-components';

// $FlowFixMe
export const SpWidget = styled.div`
  width: 100%;
  min-height: 85vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// $FlowFixMe
export const SpWidgetWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 38px',
  width: '500px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#fff',
  textAlign: 'center',
});

export const SpWidgetButton = styled.span`
  font-size: ${(props) => (props.fontSize)} !important;
  color: ${(props) => (props.color)} !important;
  font-weight: ${(props) => (props.fontWeight)} !important;
  font-style: ${(props) => (props.fontStyle)} !important;
  background-color: ${(props) => (props.backgroundColor)} !important;
  border-radius: ${(props) => (props.borderRadius)} !important;
  padding: 8px 36% 8px 40% !important;
`;

SpWidgetButton.defaultProps = {
  color: '#fff',
  fontWeight: 'normal',
  fontSize: '20px',
  backgroundColor: '#00C6BA',
  borderRadius: '10px',
};

export const SpText = styled.span`
  font-size: ${(props) => (props.fontSize)} !important;
  color: ${(props) => (props.color)} !important;
  font-weight: ${(props) => (props.fontWeight)} !important;
  font-style: ${(props) => (props.fontStyle)} !important;
  background-color: ${(props) => (props.backgroundColor)} !important;
  padding: ${(props) => (props.padding)} !important;
  border-radius: ${(props) => (props.borderRadius)} !important;
`;

SpText.defaultProps = {
  color: '#000',
  fontWeight: 'normal',
  fontSize: '14px',
  backgroundColor: '',
  padding: '0px 0px',
  borderRadius: '0px',
};

// $FlowFixMe
export const SpWidgetDetails = styled.div`
  overflow-y: scroll;
  height: 80vh;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.123); 
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.274);
  }
`;

// $FlowFixMe
export const SpAmountWidget = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  padding: 0 0 0 13% !important;
`;

// $FlowFixMe
export const SpAmountWidgetItem = styled.div`
  background-color: #f2f2f6;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  min-width: 25%;
`;

// $FlowFixMe
export const SpTermsAndServices = styled.div`
  min-height: 50px;
  max-height: 120px;
  overflow-y: auto;
  word-break: break-word;
  padding: 0% 13%;
  line-height: 18px !important;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.123); 
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.274); 
  }
`;

// $FlowFixMe
export const SpWatch = styled.div`
  padding: 5px;
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
`;

// $FlowFixMe
export const SpPopover = styled.div`
  position: absolute;
  z-index: 2;
  top: 84px !important;
`;

// $FlowFixMe
export const SpCover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

// $FlowFixMe
export const SpColor = styled.div`
  width: 36px;
  height: 21px;
  border-radius: 2px;
`;

// $FlowFixMe
export const SpMessageText = styled.div`
  word-break: break-word;
`;

// $FlowFixMe
export const SpThanksMessgae = styled.div`
  max-height: 150px;
  overflow-y: auto;
  padding: 0% 14%;
  word-break: break-word;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.123); 
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.274); 
  }
`;
