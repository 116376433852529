/* eslint-disable */
import React from 'react';

const admin = ({ fill = "silver" }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={fill} clip-path="url(#clip0)">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="2" width="9" height="12">
        <path d="M8.9192 7.83781C8.3673 6.65939 6.88675 5.85502 6.21546 5.60013L8.33286 4.33094L10.56 2.98962L12.9893 4.99003V11.1993L11.3618 12.7045L10.3922 13.0401H8.78862L7.25961 13.2452L5.30173 12.7045L4.6864 11.045L6.55105 10.7093C7.24097 10.7155 8.76625 10.6683 9.34802 10.4296C10.0752 10.1313 9.60907 9.31083 8.9192 7.83781Z" fill="black" />
      </mask>
      <g fill={fill} mask="url(#mask0)">
        <path d="M6.17972 4.6246L7.25884 4.15138L9.37738 8.15726L10.8608 12.3652L9.78166 12.8384L7.69126 8.89667L6.17972 4.6246Z" fill={fill} />
        <path d="M12.3472 6.0555L12.907 7.33224L8.92554 9.50636L4.69327 10.9342L4.13339 9.65743L8.05072 7.51145L12.3472 6.0555Z" fill={fill} />
        <path d="M9.4919 4.06021L10.8099 4.57463L9.53728 8.92944L7.5488 12.9296L6.23083 12.4152L7.47794 8.12565L9.4919 4.06021Z" fill={fill} />
        <path d="M12.9161 9.56568L12.4792 10.685L8.14615 9.3566L4.12418 7.42398L4.56109 6.30461L8.82882 7.60758L12.9161 9.56568Z" fill={fill} />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.4185 3.69382C6.02976 3.69382 6.52528 3.1983 6.52528 2.58705C6.52528 1.97579 6.02976 1.48027 5.4185 1.48027C4.80725 1.48027 4.31173 1.97579 4.31173 2.58705C4.31173 3.1983 4.80725 3.69382 5.4185 3.69382ZM5.4185 4.89382C6.6925 4.89382 7.72528 3.86105 7.72528 2.58705C7.72528 1.31305 6.6925 0.280273 5.4185 0.280273C4.14451 0.280273 3.11173 1.31305 3.11173 2.58705C3.11173 3.86105 4.14451 4.89382 5.4185 4.89382ZM6.15631 6.75286L5.66626 7.65019L5.95821 9.70701H9.4642C9.0156 8.19148 7.73722 7.03185 6.15631 6.75286ZM4.87902 9.70701L5.17267 7.65019L4.73012 6.74445C3.12648 7.00777 1.82609 8.17572 1.37283 9.70701H4.87902ZM0.0911531 9.91222C0.55828 7.3948 2.76578 5.48853 5.41852 5.48853C8.07125 5.48853 10.2788 7.3948 10.7459 9.91222C10.8466 10.4552 10.3893 10.907 9.837 10.907H1.00004C0.447751 10.907 -0.00960776 10.4552 0.0911531 9.91222Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="13" height="13" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default admin;
