import React, { useEffect } from 'react';
import {
  Card, Col, Icon, Row, Skeleton,
} from 'antd';
import { SpText, SpButton, SpStatusTag } from 'components/DesignKit';
import qucikBook from 'imgs/quickbook.png';
import salesForce from 'imgs/salesforce.png';
import sageIntacct from 'imgs/sageintacct.png';
import WooCommerceLogo from 'imgs/WooCommerceLogo.png';
import CloverLogo from 'imgs/clover.png';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  PAGES,
} from 'appconstants';
import arrow from 'imgs/arrow.png';
import integrationIcon from 'components/NavIcons/IntegrationIcon';
import {
  quickbooksActions,
  salesforceActions,
  woocommerceActions,
  cloverWebhookActions,
} from 'store/actions';
import { ROOT_QUICKBOOKS_BASE_URL } from 'api';
import {
  QUICKBOOKS,
} from 'store/actionTypes';

type Props = {
  accountQuickBooks: Object,
  accountSalesforce: Object,
  accountWoocommerce: Array,
  selectedAccount: Object,
  fetchQuickBooks: Function,
  fetchSalesforce: Function,
  fetchWoocommerce: Function,
  fetchCloverWebhook: Function,
  setQuickBook: Function,
  loader: boolean,
  test: boolean,
  cloverWebhook: Array<Object>,
};

const Integrations = (props: Props) => {
  const history = useHistory();

  const {
    accountQuickBooks,
    accountSalesforce,
    accountWoocommerce,
    fetchQuickBooks,
    fetchSalesforce,
    fetchWoocommerce,
    fetchCloverWebhook,
    setQuickBook,
    selectedAccount,
    loader,
    test,
    cloverWebhook,
  } = props;

  useEffect(() => {
    if (!test) {
      fetchQuickBooks();
      fetchSalesforce();
      fetchWoocommerce();
      fetchCloverWebhook();
    }
  }, [test]);

  const disConnectQuickBooks = (path) => {
    window.open(path, '_self', '');
  };

  const connectQuickBooks = (path) => {
    window.open(path, '_self', '');
  };

  const isEmpty = (obj) => Object.keys(obj).length === 0;
  const isSalesforceEmpty = (data) => data.length === 0;
  const isWoocommerceEmpty = (data) => data.length === 0;
  const isActive = selectedAccount && selectedAccount.active;

  console.log(accountWoocommerce, 'accountWoocommerce');

  console.log(cloverWebhook, 'cloverWebhook');

  const handleClick = () => {
    history && history.push(PAGES.QUICKBOOKS);
  };

  const handleSalesforceClick = () => {
    if (!accountSalesforce || isSalesforceEmpty(accountSalesforce)) {
      history && history.push('/integrations/salesforce');
    } else {
      history && history.push('/integrations/salesforcedetails');
    }
  };

  const handleWoocommerceClick = () => {
    history && history.push(PAGES.WOOCOMMERCE);
  };

  const handleWebhookClick = () => {
    history && history.push(PAGES.CLOVERWEBHOOK);
  };

  useEffect(() => {
    if (isEmpty(accountQuickBooks || '')) {
      setQuickBook(false);
    } else setQuickBook(true);
  }, [accountQuickBooks]);

  return (
    <>
      {
        test
          ? (
            <Card
              hoverable
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <h5>Integrations are only available for live data</h5>
              </div>
            </Card>
          ) : !isActive
            ? (
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-50">
                <Icon
                  component={integrationIcon}
                  className={test ? '' : 'mb-4'}
                  style={{ fontSize: '32px', textAlign: 'center' }}
                />
                <SpText
                  fontSize="36px"
                  color="#65668C"
                >
                  Oops!
                </SpText>
                <SpText
                  fontSize="20px"
                  color="#65668C"
                >
                  Seems not an Activated Account.
                </SpText>
                <SpText
                  fontSize="20px"
                  color="#65668C"
                >
                  Please Activate and Try again.
                </SpText>
                <SpButton
                  className="mt-4"
                  onClick={() => { history.push(PAGES.SETTINGS); }}
                >
                  Account Activation <img className="ml-2" src={arrow} alt="arrow" />
                </SpButton>
              </div>
            ) : (
              <div className="site-card-wrapper pr-4 pl-4 pt-4">
                <Row gutter={16}>
                  <Col span={8}>
                    <Card
                      title="INTUIT QUICKBOOKS"
                      hoverable
                      extra={<img src={arrow} alt="" />}
                      headStyle={{
                        backgroundImage: 'linear-gradient(98deg, #04c8fb, #007ee5 100%)',
                        borderRadius: '4px',
                        color: '#ffffff',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                      style={{
                        height: '14rem',
                      }}
                      onClick={handleClick}
                    >
                      <Row gutter={16} className="mt-2">
                        <Col span={8} className="mr-2">
                          <img src={qucikBook} alt="qucikBook" />
                        </Col>
                        <Col span={12} className="mt-1">
                          <SpText>Small business GST accounting software</SpText>
                        </Col>
                      </Row>
                      <Row gutter={16} className="mt-2">
                        <Col span={8}>
                          {(accountQuickBooks && accountQuickBooks.realmId)
                            ? (
                              <SpText
                                color="#898989"
                                fontSize="12px"
                                fontWeight="600"
                              >
                                Company ID
                              </SpText>
                            ) : ''}
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col>
                          {(accountQuickBooks && accountQuickBooks.realmId)
                            ? (
                              <>
                                <SpText
                                  color="#323232"
                                  fontSize="12px"
                                >{accountQuickBooks.realmId}
                                </SpText>
                                {
                                  accountQuickBooks.errorMessage ? (
                                    <SpText
                                      fontWeight="600"
                                      color="#0090ff"
                                      className="cursor-pointer mr-4"
                                      onClick={(e) => { e.stopPropagation(); connectQuickBooks(`${ROOT_QUICKBOOKS_BASE_URL}/connect`); }}
                                    > Reconnect
                                    </SpText>
                                  ) : (
                                    <SpText
                                      fontWeight="600"
                                      color="#0090ff"
                                      className="cursor-pointer mr-4"
                                      onClick={(e) => { e.stopPropagation(); disConnectQuickBooks(`${ROOT_QUICKBOOKS_BASE_URL}/disconnect`); }}
                                    > Disconnect
                                    </SpText>
                                  )
                                }

                              </>
                            )
                            : ''}
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={6} />
                        <Col span={10}>
                          <Skeleton
                            title
                            paragraph={false}
                            active
                            loading={loader}
                          >
                            {
                              (!accountQuickBooks || isEmpty(accountQuickBooks)) ? (
                                <SpText
                                  className="cursor-pointer"
                                  fontWeight="600"
                                  color="#B2B2B2"
                                  onClick={handleClick}
                                >
                                  Connect
                                </SpText>
                              )
                                : ''
                            }
                          </Skeleton>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card
                      title="SALESFORCE"
                      hoverable
                      extra={<img src={arrow} alt="" />}
                      headStyle={{
                        backgroundImage: 'linear-gradient(98deg, #04c8fb, #007ee5 100%)',
                        borderRadius: '4px',
                        color: '#ffffff',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                      style={{
                        height: '14rem',
                      }}
                      onClick={handleSalesforceClick}
                    >
                      <Row gutter={16} className="mt-2">
                        <Col span={8}>
                          <img src={salesForce} alt="salesForce" />
                        </Col>
                        <Col span={12} className="mt-1 ml-4">
                          <SpText>Customer relationship management (CRM) platform</SpText>
                        </Col>
                      </Row>
                      <Row gutter={16} className="mt-2">
                        <Col span={8} className="mr-4" />
                        <Col span={10}>
                          <Skeleton
                            title
                            paragraph={false}
                            active
                            className="col-6"
                            loading={loader}
                          >
                            {
                              (!accountSalesforce || isSalesforceEmpty(accountSalesforce)) ? (
                                <SpText
                                  fontWeight="600"
                                  color="#B2B2B2"
                                  className="cursor-pointer"
                                  onClick={() => history && history.push('/integrations/salesforce')}
                                >
                                  Connect
                                </SpText>
                              )
                                : ''
                            }
                          </Skeleton>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card
                      title="SAGE INTACCT"
                      hoverable
                      extra={<img src={arrow} alt="" />}
                      headStyle={{
                        backgroundImage: 'linear-gradient(98deg, #04c8fb, #007ee5 100%)',
                        borderRadius: '4px',
                        color: '#ffffff',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                      style={{
                        height: '14rem',
                      }}
                    >
                      <Row gutter={16} className="mt-2">
                        <Col span={8} className="mr-4">
                          <img src={sageIntacct} alt="sageIntacct" />
                        </Col>
                        <Col span={12} className="mt-1">
                          <SpText>ERP system for complete financial and accounting functionality</SpText>
                        </Col>
                      </Row>
                      <Row type="flex" justify="end" gutter={16}>
                        <Col span={10} className="mt-4">
                          <SpStatusTag style={{ backgroundColor: '#EAEBFF', color: '#4C51C2' }}>
                            Coming Soon
                          </SpStatusTag>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-4 mb-4" gutter={16}>
                  <Col span={8}>
                    <Card
                      title="WOOCOMMERCE"
                      hoverable
                      extra={<img src={arrow} alt="" />}
                      headStyle={{
                        backgroundImage: 'linear-gradient(98deg, #04c8fb, #007ee5 100%)',
                        borderRadius: '4px',
                        color: '#ffffff',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                      style={{
                        height: '14rem',
                      }}
                      onClick={handleWoocommerceClick}
                    >
                      <Row gutter={16} className="mt-2">
                        <Col span={8}>
                          <img style={{ width: '100px' }} src={WooCommerceLogo} alt="WooCommerce" />
                        </Col>
                        <Col span={12} className="mt-1 ml-4">
                          <SpText>The ecommerce platform that grows with you</SpText>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8} className="mr-4" />
                        <Col span={10}>
                          <Skeleton
                            title
                            paragraph={false}
                            active
                            loading={loader}
                          >
                            {
                              (!accountWoocommerce || isWoocommerceEmpty(accountWoocommerce)) ? (
                                <SpText
                                  className="cursor-pointer"
                                  fontWeight="600"
                                  color="#B2B2B2"
                                  onClick={handleWoocommerceClick}
                                >
                                  Connect
                                </SpText>
                              )
                                : (
                                  <SpText
                                    fontWeight="600"
                                    color="#0090ff"
                                    className="cursor-pointer mr-4"
                                    onClick={handleWoocommerceClick}
                                  > Connected
                                  </SpText>
                                )
                            }
                          </Skeleton>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card
                      title="CLOVER WEBHOOK"
                      hoverable
                      extra={<img src={arrow} alt="" />}
                      headStyle={{
                        backgroundImage: 'linear-gradient(98deg, #04c8fb, #007ee5 100%)',
                        borderRadius: '4px',
                        color: '#ffffff',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                      style={{
                        height: '14rem',
                      }}
                      onClick={handleWebhookClick}
                    >
                      <Row gutter={16} className="mt-2">
                        <Col span={8}>
                          <img style={{ width: '100px' }} src={CloverLogo} alt="Clover" />
                        </Col>
                        <Col span={12} className="mt-1 ml-4">
                          <SpText>Webhooks allow your application to receive notifications when merchants</SpText>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8} className="mr-4" />
                        <Col span={10}>
                          <Skeleton
                            title
                            paragraph={false}
                            active
                            loading={loader}
                          >
                            {
                              (cloverWebhook && cloverWebhook.gid) ? (
                                <SpStatusTag
                                  style={{
                                    backgroundColor: '#EBEBEB',
                                    color: '#595959',
                                    marginTop: '10px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={handleWebhookClick}
                                >
                                  Connected
                                </SpStatusTag>
                              )
                                : (
                                  <SpStatusTag
                                    style={{
                                      backgroundColor: '#EBEBEB',
                                      color: '#595959',
                                      marginTop: '10px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={handleWebhookClick}
                                  >
                                    Connect
                                  </SpStatusTag>
                                )
                            }
                          </Skeleton>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
            )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  accountQuickBooks: state.quickbooks.accountQuickBooks,
  accountSalesforce: state.salesforce.accountSalesforce.content,
  accountWoocommerce: state.woocommerce.accountWoocommerce.content,
  cloverWebhook: state.cloverWebhook,
  selectedAccount: state.account.selectedAccount,
  loader: state.loading.loading,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  fetchQuickBooks: param => dispatch(quickbooksActions.fetchQuickBooks({
    payload: param,
  })),
  setQuickBook: (value) => dispatch(quickbooksActions.setQuickBook({
    type: QUICKBOOKS.SET_QUICKBOOK,
    payload: value,
  })),
  fetchSalesforce: param => dispatch(salesforceActions.fetchSalesforce({
    payload: param,
  })),
  fetchWoocommerce: param => dispatch(woocommerceActions.fetchWoocommerce({
    payload: param,
  })),
  fetchCloverWebhook: param => dispatch(cloverWebhookActions.fetchCloverWebhook({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
