import { PAYMENTBUTTON_STATUSES } from 'appconstants';

export default value => {
  if (value === 'ACTIVE') {
    return PAYMENTBUTTON_STATUSES.ACTIVE;
  } if (value === 'EXPIRED') {
    return PAYMENTBUTTON_STATUSES.EXPIRED;
  } if (value === 'DEACTIVATED') {
    return PAYMENTBUTTON_STATUSES.DEACTIVE;
  } if (value === 'DELETED') {
    return PAYMENTBUTTON_STATUSES.DELETED;
  } return PAYMENTBUTTON_STATUSES.ACTIVE;
};
