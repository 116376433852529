// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import {
  Input,
  Checkbox,
  DatePicker,
  Row,
  Col,
} from 'antd';
import {
  MAX_AMOUNT,
} from 'appconstants';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import { validators } from 'utils/validationMessages';
import { SpH5, SpError } from 'components/DesignKit';
import { SpText } from 'components/PaymentWidgetDesignKit';

type Props = {
  submitting: boolean,
  selectedAccount: Object,
  editOffer: Boolean,
  createData: Object,
};

const Create = (props: Props, ref) => {
  const {
    submitting,
    selectedAccount,
    editOffer,
    createData,
  } = props;
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const { timezone } = selectedAccount;

  const [, forceUpdate] = useState();
  const [code, setCode] = useState(createData && createData.code);
  const [limitByDate, setLimitByDate] = useState(createData && (createData.startDate || createData.expiryDate));
  const [startDate, setStartDate] = useState(createData && createData.startDate);
  const [expiryDate, setExpiryDate] = useState(createData && createData.expiryDate);
  const [valueQuantity, setValueQuantity] = useState(createData && createData.valueQuantity);
  const [claimType, setClaimType] = useState(createData && createData.claimType);
  const [claimQuantity, setClaimQuantity] = useState(createData && createData.claimQuantity);
  const [claimPercentageOff, setClaimPercentageOff] = useState(createData && createData.claimPercentageOff);
  const [editStartDate, setEditStartDate] = useState(false);
  const STRING_LENGTH = 1;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      percentage: {
        message: MAX_AMOUNT.MESSAGE_PERCENTAGE,
        rule: (val) => {
          const numericValue = typeof val === 'string' ? val.replace(/,/g, '') : val;
          const numberValue = Number(numericValue);
          return MAX_AMOUNT.LIMIT_MIN <= numberValue && numberValue <= MAX_AMOUNT.LIMIT_MAX;
        },
      },
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      startDate: {
        message: 'Start Date cannot be in the past',
        rule: (val) => {
          const selectedDate = moment.tz(moment.utc(), timezone).startOf('day');
          const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
          console.log('startDate ', val, ' edited date ', editStartDate, ' is it in past ', moment(val).isSameOrAfter(utcDate));
          return editStartDate ? val && moment(val).isSameOrAfter(utcDate) : !editStartDate;
        },
      },
      expiryDate: {
        message: `Expiry Date cannot be before ${startDate ? 'startDate' : 'today'}`,
        rule: (val) => {
          if (startDate) {
            return val && moment(val).isSameOrAfter(moment(startDate).startOf('day'));
          }
          const selectedDate = moment.tz(moment.utc(), timezone).startOf('day');
          const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
          return val && moment(val).isSameOrAfter(utcDate);
        },
      },
    },
  }));

  useEffect(() => {
    if (limitByDate) {
      setStartDate(startDate);
      setExpiryDate(expiryDate);
    } else {
      setStartDate(null);
      setExpiryDate(null);
    }
  }, [limitByDate]);

  const onStartDateChange = (date) => {
    if (date) {
      setStartDate(moment(date).startOf('day').format(MOMENT_FORMAT));
      console.log('editing startDate');
      setEditStartDate(true);
    } else {
      setStartDate(null);
    }
  };

  const disableStartDate = (value) => {
    const selectedDate = moment.tz(moment.utc(), timezone).startOf('day');
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    return value && !editStartDate && moment(value).isBefore(utcDate);
  };

  const onExpiryDateChange = (date) => {
    if (date) {
      setExpiryDate(moment(date).endOf('day').format(MOMENT_FORMAT));
    } else {
      setExpiryDate(null);
    }
  };

  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      code,
      startDate,
      expiryDate,
      valueQuantity,
      claimType,
      claimQuantity,
      claimPercentageOff,
      editStartDate,
    }),
  }));

  return (
    <>
      <div className="pb-2">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="px-5 mt-4 mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            onChange={(e) => setCode((e.currentTarget.value).toUpperCase().replace(' ', ''))}
            value={code}
          />
          <SpError>
            {simpleValidator.current.message('name', code, 'required|stringLength')}
          </SpError>
        </div>
        <div className="px-5 mt-4 mb-2">
          <Checkbox
            className="mr-2"
            checked={limitByDate}
            disabled={editOffer && createData && (createData.startDate || createData.expiryDate)}
            onChange={({ target: { checked } }) => setLimitByDate(checked)}
          />
          <SpText>Limit the date by when this offer can be redeemed</SpText>
        </div>
        <div className="d-flex align-items-center px-5 mb-3">
          <Row type="flex" justify="space-between" className="my-2" gutter={16}>
            <Col span={12}>
              <div className="w-30">
                <label htmlFor=""><SpH5>Start Date</SpH5></label>
              </div>
              <div className="w-30">
                <DatePicker
                  format={dateFormat}
                  defaultValue={startDate ? moment(startDate, MOMENT_FORMAT) : ''}
                  allowClear={false}
                  disabled={!limitByDate || (editOffer && disableStartDate(startDate))}
                  disabledDate={current => current && current < moment().subtract(1, 'days')}
                  onChange={onStartDateChange}
                />
                <SpError>
                  {startDate && simpleValidator.current.message('date', startDate, 'required|startDate')}
                </SpError>
              </div>
            </Col>
            <Col span={12}>
              <div className="w-30">
                <label htmlFor=""><SpH5>Expiry Date</SpH5></label>
              </div>
              <div className="w-30">
                <DatePicker
                  format={dateFormat}
                  defaultValue={expiryDate ? moment(expiryDate, MOMENT_FORMAT) : ''}
                  allowClear={false}
                  disabled={!limitByDate}
                  disabledDate={current => current && current < moment().subtract(1, 'days')}
                  onChange={onExpiryDateChange}
                />
                <SpError>
                  {expiryDate && simpleValidator.current.message('date', expiryDate, 'required|expiryDate')}
                </SpError>
              </div>
            </Col>
          </Row>
        </div>
        <div className="px-5 w-30">
          <label htmlFor=""><SpH5>Buy Details</SpH5></label>
        </div>
        <div style={{
          backgroundColor: '#eeeeee',
          marginBottom: '25px',
          height: '98px',
        }}
        >
          <div className="px-5 mt-2 mb-3 ml-3">
            <label className="mt-2" htmlFor=""><SpH5>Quantity</SpH5></label>
            <Input
              type="number"
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                const { value } = e.currentTarget;
                if (regex.test(value) || value === '') {
                  setValueQuantity(e.currentTarget.value);
                }
              }}
              value={valueQuantity}
            />
          </div>
        </div>
        <div>
          <div className="px-5 w-30">
            <label htmlFor=""><SpH5>Get Details</SpH5></label>
          </div>
          <div className="px-5 ml-3 mb-3">
            <div style={{ marginBottom: '30px' }}>
              <Row type="flex" justify="space-between" className="my-2" gutter={16}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="radio"
                    id="quantity"
                    name="claimType"
                    value="QUANTITY"
                    checked={claimType === 'QUANTITY'}
                    onChange={(e) => setClaimType(e.currentTarget.value)}
                  />
                  <label htmlFor="quantity" style={{ marginLeft: 8, marginTop: 6, display: 'flex', alignItems: 'center' }}>
                    <SpH5>Get Quantity</SpH5>
                  </label>
                </Col>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <Input
                    style={{ height: 32, width: 92 }}
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/;
                      const { value } = e.currentTarget;
                      if (regex.test(value) || value === '') {
                        setClaimQuantity(value);
                        setClaimType('QUANTITY');
                      }
                    }}
                    value={claimType === 'QUANTITY' ? claimQuantity : ''}
                  />
                </Col>
              </Row>
            </div>
            <Row type="flex" justify="space-between" className="my-2" gutter={16}>
              <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="radio"
                  id="percentageOff"
                  name="claimType"
                  value="PERCENTAGE_OFF"
                  checked={claimType === 'PERCENTAGE_OFF'}
                  onChange={(e) => setClaimType(e.currentTarget.value)}
                />
                <label htmlFor="percentageOff" style={{ marginLeft: 8, marginTop: 8, display: 'flex', alignItems: 'center' }}>
                  <SpH5>Get % Off</SpH5>
                </label>
              </Col>
              <Col span={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Input
                  style={{ height: 32, width: 92 }}
                  suffix="%"
                  onChange={(e) => {
                    const regex = /^\d*\.?\d*$/;
                    const { value } = e.currentTarget;
                    if (regex.test(value) || value === '') {
                      setClaimPercentageOff(value);
                      setClaimType('PERCENTAGE_OFF');
                    }
                  }}
                  value={claimType === 'PERCENTAGE_OFF' ? claimPercentageOff : ''}
                />
                {claimType === 'PERCENTAGE_OFF' && (
                  <SpError style={{ marginTop: '4px' }}>
                    {simpleValidator.current.message('percentageOff', claimPercentageOff, 'required|percentage')}
                  </SpError>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(Create);
