import React,
{
  useState,
  useEffect,
} from 'react';
import {
  Collapse,
  Table,
  Card,
  Row,
  Col,
  Select,
} from 'antd';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  notificationActions,
  customerSupportActions,
  quickSupportSummaryActions,
} from 'store/actions';
import moment from 'moment-timezone';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  PAGES,
} from 'appconstants';
import { NOTIFICATION } from 'store/actionTypes';
import { CaretRightOutlined } from '@ant-design/icons';
import { SpText } from 'components/DesignKit';
import CustomIcon from 'components/NavIcons/customersupport';

const { Option } = Select;

type Props = {
  history: {
    push: Function,
  },
  selectedAccount: Object,
  fetchQuickSupportSummary: Function,
  fetchQuickSupportSection: Function,
  fetchShops: Function,
  shops: Function,
  supports: Array,
  sectionList: Array,
  updateQuickSupportSummary: Function,
  sendFcmToken: Function,
  setNotification: Function,
  role: Object,
  isMobileView: Boolean,
};

const firebaseConfig = {
  apiKey: 'AIzaSyB7XtYo08EXBb9e7CTX4QiPgFZNVn8pkVc',
  authDomain: 'swirepay-fe.firebaseapp.com',
  projectId: 'swirepay-fe',
  storageBucket: 'swirepay-fe.appspot.com',
  messagingSenderId: '436076744344',
  appId: '1:436076744344:web:a1ad170a26ae61562b0aed',
  measurementId: 'G-ZP7NX358P9',
};

const fapp = initializeApp(firebaseConfig);
const messaging = getMessaging(fapp);

const CustomerSupport = (props: Props) => {
  const location = useLocation();
  const {
    fetchQuickSupportSummary,
    fetchQuickSupportSection,
    fetchShops,
    shops,
    supports,
    sectionList,
    selectedAccount,
    updateQuickSupportSummary,
    sendFcmToken,
    setNotification,
    role,
    history,
    isMobileView,
  } = props;
  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }
  const [activePanel, setActivePanel] = useState(null);
  const [shopsList, setShopsList] = useState([]);
  const [firstTimefetch, setFirstTimefetch] = useState(true);
  const [selectedShop, setSelectedShop] = useState('');
  const [selectedShopGid, setSelectedShopGid] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedSectionGid, setSelectedSectionGid] = useState('');
  const colorArray = ['#1AB58B', '#FF9B28', '#FF4B51', '#5B61E9'];
  const [messageRecevied, setMessageRecevied] = useState(false);
  console.log(selectedShopGid);
  const todayDate = moment.utc(new Date()).tz(selectedAccount.timezone).format('MM/DD/YYYY');

  const debouncedFetchSupport = debounce(fetchQuickSupportSummary, 2000, { leading: true });

  const debouncedFetchShops = debounce(fetchShops, 2000, { leading: true });

  useEffect(() => {
    if (firstTimefetch && (shops && shops.length !== 0)) {
      setFirstTimefetch(false);
      setShopsList(shops);
      debouncedFetchSupport('');
      fetchQuickSupportSection('');
    }
  }, [shops]);

  const getInfo = (shopname) => {
    const shopName = shopname || '';
    const sectionName = selectedSectionGid || '';
    const filterParams = `?shopName.EQ=${decodeURIComponent(shopName)}&shopSectionGid.EQ=${sectionName}`;
    fetchQuickSupportSummary({ filterParams });
  };

  const getSectionInfo = (shopid) => {
    const shopName = selectedShop || '';
    const sectionName = shopid || '';
    const filterParams = `?shopName.EQ=${decodeURIComponent(shopName)}&shopSectionGid.EQ=${sectionName}`;
    fetchQuickSupportSummary({ filterParams });
  };

  const getData = () => {
    debouncedFetchShops('');
  };

  const updateSelectedShop = (shop) => {
    setSelectedShopGid(shop[1]);
    setSelectedShop(shop[0]);
    setSelectedSection('');
    setSelectedSectionGid('');
    fetchQuickSupportSection({ shopGid: shop[1] });
    getInfo(shop[0]);
  };

  const updateSelectedSection = (shop) => {
    setSelectedSectionGid(shop[1]);
    setSelectedSection(shop[0]);
    getSectionInfo(shop[1]);
  };


  useEffect(() => {
    getData();
  }, []);

  const generateDeviceId = () => {
    const navigatorInfo = navigator.userAgent;
    const timestamp = new Date().getTime();
    const randomValue = Math.random().toString(36).substring(7);
    return `${navigatorInfo}-${timestamp}-${randomValue}`;
  };

  useEffect(() => {
    if (messageRecevied === true) {
      setMessageRecevied(false);
      const shopName = selectedShop || '';
      const sectionName = selectedSectionGid || '';
      const filterParams = `?shopName.EQ=${decodeURIComponent(shopName)}&shopSectionGid.EQ=${sectionName}`;
      fetchQuickSupportSummary({ filterParams });
    }
  }, [messageRecevied]);

  useEffect(() => {
    if (role && role.name !== 'superuser') {
      getToken(messaging, {
        vapidKey:
          'BHc-PdMJkGh6Xc0pptrGU5QNJYyLNtFP4JylwMXXD_hkw1CLiFQmfEB-2_eZU3UboFjTdgIBAeSpWhu4k2wvY3k',
      })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem('fcmToken', currentToken);
            const payload = {
              fcmToken: currentToken,
              platformType: 'WEB',
              type: 'KDS',
              deviceId: generateDeviceId(),
            };
            sendFcmToken(payload);
          } else {
            console.log(
              'No registration token available. Request permission to generate one.',
            );
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });

      onMessage(messaging, (payload) => {
        if (payload && payload.data && payload.data.title === 'AWAITING_RESPONSE') {
          setMessageRecevied(true);
          const notificationMessage = payload && payload.data && payload.data.message;
          const jsonObject = JSON.parse(notificationMessage);
          const notificationBody = jsonObject && jsonObject.message;
          setNotification({
            type: NOTIFICATION.SUCCESS,
            payload: (notificationBody) || 'Order Updated',
          });
        }
        // ...
      });
    }
  }, [messaging]);

  const handlePanelChange = (key) => {
    setActivePanel(key === activePanel ? null : key);
  };

  const selectedTableRecord = (record) => {
    const itemGid = record && record.shopTable && record.shopTable.gid;
    history.push({
      pathname: `${PAGES.DINNINGDETAILS}/${itemGid}`,
      state: location.search,
    });
  };

  const columns = [
    {
      title: 'SHOP',
      dataIndex: 'shop',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (shop, record) => (
        <span style={{ cursor: 'pointer' }}>
          {(record && record.quickSupportRequest && record.quickSupportRequest.shop && record.quickSupportRequest.shop.name) || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'SECTION',
      dataIndex: 'section',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <span style={{ cursor: 'pointer' }}>
          {(record && record.quickSupportRequest
            && record.quickSupportRequest.shopTable
            && record.quickSupportRequest.shopTable.shopSection
            && record.quickSupportRequest.shopTable.shopSection.name) || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'TABLE',
      dataIndex: 'table',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (table, record) => (
        <div
          onClick={(e) => { e.stopPropagation(); selectedTableRecord(record.quickSupportRequest); }}
          style={{
            color: '#3E8BFF',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {(record && record.quickSupportRequest
            && record.quickSupportRequest.shopTable && record.quickSupportRequest.shopTable.name) || <>&#8211;</>}
        </div>
      ),
    },
    {
      title: 'STAFF ASSIGNED',
      dataIndex: '',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <span style={{ cursor: 'pointer' }}>
          {(record && record.quickSupportRequest && record.quickSupportRequest.user && record.quickSupportRequest.user.fullName) || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'REQUESTED AT',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt, record) => {
        const requestDate = record && record.quickSupportRequest && record.quickSupportRequest.createdAt;
        if (requestDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(requestDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <div
        onClick={(e) => {
          e.stopPropagation();
          updateQuickSupportSummary({
            filterParams: `?shopName.EQ=${decodeURIComponent(selectedShop || '')}&shopSectionGid.EQ=${selectedSectionGid || ''}`,
            shopId: selectedShop.gid,
            supportGid: record.quickSupportRequest && record.quickSupportRequest.gid,
            params: {
              status: 'REQUEST_FULFILLED',
            },
          });
        }}
      >
        <CustomIcon count={(record && record.awaitingResponse) || 0} />
      </div>
    ),
  };

  return (
    <Card>
      <Row type="flex" justify="space-between" className="my-2 pb-2" align="middle">
        <Col>
          {
            (shopsList && shopsList.length) !== 0 ? (
              <Select
                className="mr-4 mt-3"
                value={selectedShop || 'All Shops'}
                style={{ width: '350px' }}
                bordered
                showSearch
                onChange={(value) => updateSelectedShop(value)}
              >
                {
                  shopsList && shopsList.map((option) => (
                    <Option
                      key={option.gid}
                      value={[
                        option.name,
                        option.gid,
                      ]}
                    >
                      {option.name}
                    </Option>
                  ))
                }
              </Select>
            ) : null
          }
          {
            (sectionList && sectionList.length) !== 0 ? (
              <Select
                className="mr-4 mt-3"
                value={selectedSection || 'All Sections'}
                style={{ width: 160 }}
                bordered
                showSearch
                onChange={(value) => updateSelectedSection(value)}
              >
                {
                  sectionList && sectionList.map((option) => (
                    <Option
                      key={option.gid}
                      value={[
                        option.name,
                        option.gid,
                      ]}
                    >
                      {option.name}
                    </Option>
                  ))
                }
              </Select>
            ) : null
          }
        </Col>
        <Col>
          <SpText fontWeight="600" fontSize="16px" color="#333333">
            {todayDate}
          </SpText>
        </Col>
      </Row>
      <div
        style={{
          background: '#F5F5F5',
          padding: '16px',
          marginLeft: '-15px',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <Row type="flex" justify="space-between" align="middle">
          <Col span={18}>
            <Row type="flex">
              <Col span={6} className="mr-4">
                <SpText fontWeight="600" fontSize="14px" color="#333333">
                  SUPPORT TYPE
                </SpText>
              </Col>
              <Col span={6}>
                <SpText fontWeight="600" fontSize="14px" color="#333333">
                  TOTAL COUNT
                </SpText>
              </Col>
            </Row>
          </Col>
          <Col />
        </Row>
      </div>

      {
        (supports && supports.length) !== 0 ? (
          <Collapse accordion onChange={handlePanelChange}>
            {supports.map((item, index) => (
              <Collapse.Panel
                key={item.quickSupport && item.quickSupport.gid}
                showArrow={false}
                style={{ borderLeft: `6px solid ${colorArray[index % colorArray.length]}`, borderRadius: '6px' }}
                header={(
                  // eslint-disable-next-line max-len
                  <Row type="flex" justify="space-between" align="middle" style={{ paddingLeft: 8 }}>
                    <Col span={18}>
                      <Row type="flex">
                        <Col span={6} className="mr-4">
                          <SpText fontWeight="700" fontSize="12px" color="#000">
                            {(item.quickSupport && item.quickSupport.name) || '-'}
                          </SpText>
                        </Col>
                        <Col span={2}>
                          <div
                            style={{
                              border: '1px solid #FFEDCC',
                              borderRadius: '4px',
                              padding: '6px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <SpText fontWeight="600" fontSize="16px" color="#D17621">
                              {item.totalCount || 0}
                            </SpText>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <CaretRightOutlined rotate={activePanel === item.quickSupport.gid ? 90 : 0} />
                    </Col>
                  </Row>
                )}
              >
                <Table
                  className="my-custom-table"
                  dataSource={item.quickSupportRequestSummaryDtos}
                  columns={[...columns, actionColumn]}
                  pagination={false}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : null
      }


    </Card>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  shops: state.customerSupport.shops,
  supports: state.quickSupportSummary.content,
  sectionList: state.quickSupportSummary.sectionList.content,
  totalElements: state.customerSupport.support.totalElements,
  quickSupportList: state.customerSupport.quickSupport.content,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchShops: param => dispatch(customerSupportActions.fetchShops({
    payload: param,
  })),
  fetchQuickSupportSummary: param => dispatch(quickSupportSummaryActions.fetchQuickSupportSummary({
    payload: param,
  })),
  fetchQuickSupportSection: param => dispatch(quickSupportSummaryActions.fetchQuickSupportSection({
    payload: param,
  })),
  updateQuickSupportSummary: param => dispatch(quickSupportSummaryActions.updateQuickSupportSummary({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupport);
