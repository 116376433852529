// @flow
import React from 'react';
import { Modal } from 'antd';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
};

const ConfirmSubmission = (props: Props) => {
  const {
    visible,
    close,
    submit,
  } = props;

  const ConfirmSubmit = () => {
    submit();
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      onOk={ConfirmSubmit}
      onCancel={() => close()}
      okText="Submit"
      okButtonProps={{ shape: 'round', type: 'danger' }}
      cancelButtonProps={{ shape: 'round', ghost: true, type: 'primary' }}
      title="Submit Evidence"
    >
      <div className="px-4">
        <div className="mt-1">Are you sure you want to submit evidence?</div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default ConfirmSubmission;
