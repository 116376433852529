// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import formatNumber from 'utils/formatNumber';
import { useLocation } from 'react-router-dom';

import moment from 'moment-timezone';
import {
  Card,
  Table,
  Row,
  Col,
  Menu,
  Switch,
  Tooltip,
  Icon,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpTab,
  SpButton,
} from 'components/DesignKit';

import {
  SpColor,
} from 'components/PaymentWidgetDesignKit';

import Loading from 'components/Loading';
import {
  inventoryItemDetailsActions,
  inventoryItemsActions,
  shopDetailsActions,
  inventoryPrinterLabelActions,
  notificationActions,
} from 'store/actions';
import CONSTANTS from 'store/constants';
import {
  PAGES,
} from 'appconstants';
import getPriceType from 'utils/getPriceType';
import EditItems from './components/AddNewItem';
import DeleteItem from './components/DeleteItem';

type Props = {
  fetchInventoryItemDetails: Function,
  inventoryItemDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  updateInventoryItems: Function,
  deleteInventoryItems: Function,
  fetchItemsList: Function,
  fetchModifierItemsList: Function,
  itemsList: Array,
  modifierItemsList: Array,
  itemDetailsStatus: string,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  submitting: Boolean,
  validator: Boolean,
  fetchAllShopDetails: Function,
  fetchPrinterLabelList: Function,
  shopsList: Array,
  labelList: Array,
  fetchInventoryItemsSearch: Function,
}

const ItemDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchInventoryItemDetails,
    fetchItemsList,
    fetchModifierItemsList,
    inventoryItemDetails,
    updateInventoryItems,
    deleteInventoryItems,
    itemsList,
    modifierItemsList,
    itemDetailsStatus,
    loading,
    history,
    submitting,
    validator,
    selectedAccount,
    fetchAllShopDetails,
    fetchPrinterLabelList,
    shopsList,
    labelList,
    fetchInventoryItemsSearch,
  } = props;
  const { gid } = props.match.params;
  const path = props.match.url;
  const gidPath = gid.split('-')[1];
  const currencyType = selectedAccount && selectedAccount.currency;
  const [editItemsModal, setEditItemsModal] = useState(false);
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [current, setCurrent] = useState('modifers');
  const kdsRole = localStorage.getItem('kdsRole');

  const getData = () => {
    fetchInventoryItemDetails(gid);
    fetchItemsList('&sortBy=createdAt&direction=DESC');
    fetchModifierItemsList('&sortBy=createdAt&direction=DESC');
    fetchAllShopDetails('&sortBy=createdAt&direction=DESC');
    fetchPrinterLabelList('&sortBy=createdAt&direction=DESC');
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.INVENTORY_ITEMS);
    }
  }, []);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    if (redirect && !submitting) {
      setDeleteItemModal(false);
      fetchInventoryItemsSearch('');
      history.push({
        pathname: PAGES.INVENTORY_ITEMS,
      });
    }
  }, [submitting]);

  useEffect(() => {
    if (redirect && !validator) {
      setEditItemsModal(false);
      getData(location.search);
    }
  }, [validator]);

  const balanceUpdate = (val) => {
    setCurrent(val);
  };
  const editSelectedItem = (customer: Object) => {
    const {
      name,
      alternateName,
      onlineName,
      onlineEnabled,
      sku,
      price,
      isPos,
      priceType,
      primaryColor,
      categories,
      order,
      modifierGroups,
      isItemAvailable,
      isRecurring,
      collectLineItemDate,
      cost,
      logoUrl,
      description,
      shopDetails,
      tagDetails,
      addCuponGids,
      enableStock,
      enableDynamicAvailability,
      stockQuantity,
      onlineAvailability,
      posAvailability,
    } = customer;
    const totalPrice = price ? price.replace(/[^\d]/g, '') : 0;
    const totalCost = cost ? cost.replace(/[^\d]/g, '') : 0;

    let params;

    if (addCuponGids) {
      params = {
        // eslint-disable-next-line no-control-regex
        name: name.replace(/[^\x00-\x7F]/g, ''),
        alternateName,
        onlineName,
        onlineEnabled,
        sku,
        price: Number(totalPrice),
        hidden: isPos,
        priceType,
        sortOrder: Number(order),
        colorCode: primaryColor,
        categoryGids: categories || null,
        modifierGroupGids: modifierGroups || null,
        available: isItemAvailable,
        recurring: isRecurring,
        collectLineItemDate,
        cost: Number(totalCost),
        imageUrl: logoUrl,
        // eslint-disable-next-line no-control-regex
        description: description.replace(/[^\x00-\x7F]/g, ''),
        shopGids: shopDetails || [],
        tagGids: tagDetails || [],
        couponGid: addCuponGids || null,
        enableStock,
        enableDynamicAvailability,
        stockQuantity: Number(stockQuantity),
        onlineAvailability: Number(onlineAvailability * 100),
        posAvailability: Number(posAvailability * 100),
      };
    } else {
      params = {
        // eslint-disable-next-line no-control-regex
        name: name.replace(/[^\x00-\x7F]/g, ''),
        alternateName,
        onlineName,
        onlineEnabled,
        sku,
        price: Number(totalPrice),
        hidden: isPos,
        priceType,
        sortOrder: Number(order),
        colorCode: primaryColor,
        categoryGids: categories || null,
        modifierGroupGids: modifierGroups || null,
        available: isItemAvailable,
        recurring: isRecurring,
        collectLineItemDate,
        cost: Number(totalCost),
        imageUrl: logoUrl,
        // eslint-disable-next-line no-control-regex
        description: description.replace(/[^\x00-\x7F]/g, ''),
        shopGids: shopDetails || [],
        tagGids: tagDetails || [],
        removeCoupon: true,
        enableStock,
        enableDynamicAvailability,
        stockQuantity: Number(stockQuantity),
        onlineAvailability: Number(onlineAvailability * 100),
        posAvailability: Number(posAvailability * 100),
      };
    }
    updateInventoryItems({
      gid,
      params,
    });
    setRedirect(true);
  };

  const deleteSelectedItem = (customerGid: Object) => {
    deleteInventoryItems({
      gid: customerGid,
    });
    setRedirect(true);
  };

  const selectedModifierRecord = (record) => {
    const itemGid = record.gid;
    history.push({
      pathname: `${PAGES.INVENTORY_MODIFIERS}/${itemGid}`,
      state: location.search,
    });
  };

  const selectedDiscountRecord = (record) => {
    const itemGid = record.coupon.gid;
    history.push({
      pathname: `${PAGES.COUPONS}/${itemGid}`,
      state: location.search,
    });
  };

  const selectedShop = (record) => {
    const itemGid = record.gid;
    history.push({
      pathname: `${PAGES.SHOPS}/${itemGid}`,
      state: location.search,
    });
  };

  const selectedRecord = (record) => {
    const itemGid = record.gid;
    history.push({
      pathname: `${PAGES.INVENTORY_CATEGORIES}/${itemGid}`,
      state: location.search,
    });
  };
  const selectedTableRecord = (record) => {
    const tagGid = record.gid;
    history.push({
      pathname: `${PAGES.PRINTER_LABEL}/${tagGid}`,
      state: location.search,
    });
  };

  const deleteDiscountItem = () => {
    const record = inventoryItemDetails;
    const shopIds = (record && record.shops && record.shops.length !== 0) ? record.shops.map((option) => option.gid) : [];
    const tagIds = (record && record.tags && record.tags.length !== 0) ? record.tags.map((option) => option.gid) : [];
    const categoryIds = (record && record.categories && record.categories.length !== 0) ? record.categories.map((option) => option.gid) : [];
    // eslint-disable-next-line max-len
    const modifierIds = (record && record.modifierGroups && record.modifierGroups.length !== 0) ? record.modifierGroups.map((option) => option.gid) : [];
    const params = {
      name: record.name,
      alternateName: record.alternateName,
      sku: record.sku,
      price: (record && record.price) || 0,
      hidden: record.hidden,
      priceType: record.priceType,
      sortOrder: record.sortOrder,
      colorCode: record.colorCode,
      categoryGids: categoryIds || [],
      modifierGroupGids: modifierIds || [],
      available: record.available,
      cost: (record && record.cost) || 0,
      imageUrl: record.imageUrl,
      description: record.description,
      shopGids: shopIds || [],
      tagGids: tagIds || [],
      removeCoupon: true,
    };
    updateInventoryItems({
      gid,
      params,
    });
    setRedirect(true);
  };


  const columns = [
    {
      title: 'CATEGORY',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'COLOR CODE',
      dataIndex: 'colorCode',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (colorCode) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {colorCode || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'SORT ORDER',
      dataIndex: 'sortOrder',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (sortOrder) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {sortOrder || 0}
          </span>
        </Tooltip>
      ),
    },
  ];

  const modiferColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'NUMBER OF MODIFIERS',
      dataIndex: 'sortOrder',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (employee, record) => {
        const numofModifiers = record && record.modifiers ? record.modifiers.length : null;
        return (
          <span>
            {
              numofModifiers || <>&#8211;</>
            }
          </span>
        );
      },
    },
  ];

  const shopColumn = [
    {
      title: 'SHOP NAME',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <span>
          {
            name || <>&#8211;</>
          }
        </span>
      ),
    },
    {
      title: 'ORDER TYPE',
      dataIndex: 'orderMode',
      width: '15%',
      align: 'left',
      render: (orderMode) => (<span>{[orderMode] || <>&#8211;</>}</span>),
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Address',
      dataIndex: 'address',
      width: '15%',
      align: 'left',
      render: (address) => (<span>{`${address.street},${address.city},${address.state},${address.postalCode}`}</span>),
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];


  const discountColumns = [
    {
      title: 'DISCOUNTED PRICE',
      dataIndex: 'discountedPrice',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (discountedPrice) => {
        const total = (discountedPrice) ? ((discountedPrice) / 100).toFixed(2) : 0;
        return (
          <span> {`${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'PERCENTAGE DISCOUNT',
      dataIndex: 'coupon.percentageOff',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => {
        const numofModifiers = record ? `${record} %` : null;
        return (
          <span>
            {
              numofModifiers || <>&#8211;</>
            }
          </span>
        );
      },
    },
    {
      title: 'DISCOUNT NAME',
      dataIndex: 'coupon.name',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <span>
          {
            name || <>&#8211;</>
          }
        </span>
      ),
    },
    {
      title: 'SHOP NAME',
      dataIndex: 'shop.name',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <span>
          {
            name || <>&#8211;</>
          }
        </span>
      ),
    },
    {
      title: 'DISCOUNT LEVEL',
      dataIndex: 'discountLevel',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (discountLevel) => (
        <span>
          {
            (discountLevel && discountLevel === 'ITEM_LEVEL' ? 'ITEM' : (discountLevel === 'CATEGORY_LEVEL' ? 'CATEGORY' : 'MENU')) || <>&#8211;</>
          }
        </span>
      ),
    },
    {
      title: 'DURATION',
      dataIndex: 'coupon.validity',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (validity) => (
        <span>
          {
            validity || <>&#8211;</>
          }
        </span>
      ),
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      render: (text, record) => {
        if (record.coupon && record.coupon.createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(record.coupon.createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const printerLabelColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name, record) => (
        <div
          onClick={(e) => { e.stopPropagation(); selectedTableRecord(record); }}
          style={{
            color: '#3E8BFF',
            textDecoration: 'underline',
          }}
        >
          {
            name || <>&#8211;</>
          }
        </div>
      ),

    },

  ];

  // const actionColumn = {
  //   title: 'ACTION',
  //   dataIndex: 'action',
  //   width: '10%',
  //   align: 'left',
  //   render: (text, record) => (
  //     <span>
  //       <Tooltip placement="top" title="Delete">
  //         <Icon
  //           type="delete"
  //           style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
  //           onClick={(e) => { e.stopPropagation(); deleteDiscountItem(record); }}
  //         />
  //       </Tooltip>
  //     </span>
  //   ),
  // };

  const advanceFeeColumns = [
    {
      title: 'ITEM NAME',
      dataIndex: 'name',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <span>
          {
            name || <>&#8211;</>
          }
        </span>
      ),
    },
    {
      title: 'AMOUT/DISCOUNT',
      dataIndex: 'type',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (type, record) => {
        const amnt = record && record.value ? ((record.value) / 100).toFixed(2) : 0;
        const amount = `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(amnt, currencyType)}`;
        const discount = `${amnt} %`;
        return (
          <span>
            {
              record && record.type === 'AMOUNT' ? amount : discount
            }
          </span>
        );
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        inventoryItemDetails.gid && itemDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-3" fontWeight="600" fontSize="20px">
                    {(inventoryItemDetails && inventoryItemDetails.name) || <>&#8211;</>}
                  </SpText>
                  <Switch
                    // eslint-disable-next-line
                    checked={((inventoryItemDetails && inventoryItemDetails.hidden === false) ? true : false)}
                    disabled
                    className="mr-2"
                  />
                  <SpText fontWeight="400" fontSize="16px">Show on POS</SpText>
                </Col>
                {
                  kdsRole !== 'KDS-USER' && (
                    <Col>
                      <SpButton
                        shape="round"
                        className="mr-2"
                        type="danger"
                        onClick={() => setDeleteItemModal(true)}
                      >
                        Delete Item
                      </SpButton>
                      <SpButton
                        shape="round"
                        type="secondary"
                        onClick={() => setEditItemsModal(true)}
                      >
                        Edit Item
                      </SpButton>
                    </Col>
                  )
                }
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Price</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {inventoryItemDetails.price < 0
                        ? `(${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                          (inventoryItemDetails.price / 100).toFixed(2), currencyType,
                        )})`
                        : inventoryItemDetails.price
                          ? `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber((inventoryItemDetails.price / 100).toFixed(2), currencyType)}`
                          : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Pricing Type</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {getPriceType(inventoryItemDetails && inventoryItemDetails.priceType) || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Item Color</span>
                  </Col>
                  <Col span={6}>
                    <div className="d-flex">
                      <SpColor className="color" style={{ backgroundColor: `${inventoryItemDetails.colorCode}` }} />
                      <SpH5 className="ml-1">
                        {(inventoryItemDetails && inventoryItemDetails.colorCode) || <>&#8211;</>}
                      </SpH5>
                    </div>
                  </Col>
                </Row>
              </SpForm>

              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Sort Order</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryItemDetails && inventoryItemDetails.sortOrder) || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Stock Quantity</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryItemDetails && inventoryItemDetails.stockQuantity) || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Online Stock</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryItemDetails && inventoryItemDetails.onlineStock) || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>POS Stock</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryItemDetails && inventoryItemDetails.posStock) || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Recurring</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryItemDetails && inventoryItemDetails.recurring ? 'Yes' : 'No') || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Image</span>
                  </Col>
                  <Col span={6}>
                    {inventoryItemDetails && inventoryItemDetails.imageUrl
                      ? <img src={inventoryItemDetails && inventoryItemDetails.imageUrl} alt="item" width="100" height="100" />
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Description</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryItemDetails && inventoryItemDetails.description) || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <hr />

              <Row type="flex" justify="start" align="middle">

                <Col className="d-flex align-items-center">
                  <SpText className="mr-3" fontWeight="600" fontSize="20px">
                    ONLINE
                  </SpText>
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle" className="mt-3">
                  <Col span={3}>
                    <span>Name</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryItemDetails && inventoryItemDetails.onlineName) || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>

              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Enabled</span>
                  </Col>
                  <Col span={6}>
                    <Switch
                      // eslint-disable-next-line
                      checked={(inventoryItemDetails && inventoryItemDetails.onlineEnabled)}
                      disabled
                      className="mr-2"
                    />
                  </Col>
                </Row>

              </SpForm>

              <Row>
                <Col span={24} className="d-flex justify-content-end">
                  <SpH5 color="#7f7f7f">{inventoryItemDetails.gid}</SpH5>
                </Col>
              </Row>
            </Card>
            <Card className="pl-2">
              <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
                <Menu.Item key="modifers" className="pl-2">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'modifers'}
                    onClick={() => balanceUpdate('modifers')}
                  >
                    MODIFIER GROUPS
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="category">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'category'}
                    onClick={() => balanceUpdate('category')}
                  >
                    CATEGORIES
                  </SpTab>
                </Menu.Item>
                {inventoryItemDetails && inventoryItemDetails.discounts && inventoryItemDetails.discounts.length !== 0 && (
                  <Menu.Item key="discounts">
                    <SpTab
                      to={`${path}`}
                      selected={current === 'discounts'}
                      onClick={() => balanceUpdate('discounts')}
                    >
                      DISCOUNTS
                    </SpTab>
                  </Menu.Item>
                )}
                <Menu.Item key="shops">
                  <SpTab
                    selected={current === 'shops'}
                    onClick={() => balanceUpdate('shops')}
                  >
                    SHOPS
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="printerlabels">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'printerlabels'}
                    onClick={() => balanceUpdate('printerlabels')}
                  >
                    PRINTER LABELS
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="advancefee">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'advancefee'}
                    onClick={() => balanceUpdate('advancefee')}
                  >
                    ADVANCED FEES
                  </SpTab>
                </Menu.Item>
              </Menu>
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">&nbsp;</SpText>
                </Col>
              </Row>
              {/* DELETE ITEM MODAL */}
              {deleteItemModal && (
                <DeleteItem
                  visible={deleteItemModal}
                  submitting={submitting}
                  inventoryItemDetails={inventoryItemDetails}
                  selectedAccount={selectedAccount}
                  close={() => setDeleteItemModal(false)}
                  submit={deleteSelectedItem}
                />
              )}
              {/* UPDATE ITEMS MODAL */}
              {editItemsModal && (
                <EditItems
                  visible={editItemsModal}
                  submitting={submitting}
                  selectedItemList={inventoryItemDetails}
                  selectedAccount={selectedAccount}
                  itemsList={itemsList}
                  shopsList={shopsList}
                  labelList={labelList}
                  modifierItemsList={modifierItemsList}
                  close={() => setEditItemsModal(false)}
                  submit={editSelectedItem}
                />
              )}
              {
                current === 'modifers' ? (
                  <Table
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={modiferColumns}
                    onRow={(record) => ({
                      onClick: () => {
                        selectedModifierRecord(record);
                      },
                    })}
                    rowKey={(record) => record.gid}
                    className="custom-table"
                    dataSource={inventoryItemDetails && inventoryItemDetails.modifierGroups}
                  />
                ) : current === 'discounts' ? (
                  <Table
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={discountColumns}
                    onRow={(record) => ({
                      onClick: () => {
                        selectedDiscountRecord(record);
                      },
                    })}
                    rowKey={(record) => record.gid}
                    className="custom-table"
                    dataSource={inventoryItemDetails && inventoryItemDetails.discounts}
                  />
                ) : current === 'printerlabels' ? (
                  <Table
                    // eslint-disable-next-line react/jsx-indent-props
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={printerLabelColumns}
                    // onRow={(record) => ({
                    //   onClick: () => {
                    //     selectedModifierRecord(record);
                    //   },
                    // })}
                    rowKey={(record) => record.gid}
                    className="custom-table"
                    dataSource={inventoryItemDetails && inventoryItemDetails.tags ? inventoryItemDetails && inventoryItemDetails.tags : []}
                  />
                ) : current === 'shops' ? (

                  <Table
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={[...shopColumn]}
                    onRow={(record) => ({
                      onClick: () => {
                        selectedShop(record);
                      },
                    })}

                    rowKey={(record) => record.gid}
                    className="custom-table"
                    dataSource={inventoryItemDetails && inventoryItemDetails.shops ? inventoryItemDetails && inventoryItemDetails.shops : []}
                  />
                ) : current === 'advancefee' ? (

                  <Table
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={[...advanceFeeColumns]}
                    // onRow={(record) => ({
                    //   onClick: () => {
                    //     selectedShop(record);
                    //   },
                    // })}
                    rowKey={(record) => record.gid}
                    className="custom-table"
                    dataSource={inventoryItemDetails && inventoryItemDetails.advancedFees ? inventoryItemDetails && inventoryItemDetails.advancedFees : []}
                  />
                ) : (
                  <Table
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={columns}
                    onRow={(record) => ({
                      onClick: () => {
                        selectedRecord(record);
                      },
                    })}
                    rowKey={(record) => record.gid}
                    className="custom-table"
                    dataSource={inventoryItemDetails && inventoryItemDetails.categories}
                  />
                )
              }
            </Card>
          </>
        )

      }
    </>
  );
};

const mapStateToProps = (state) => ({
  inventoryItemDetails: state.inventoryItemDetails,
  lineItems: state.inventoryItemDetails.inventoryOrderLineItems,
  itemDetailsStatus: state.inventoryItemDetails.itemDetailsStatus,
  totalElements: state.inventoryItemDetails.inventoryOrderLineItems ? state.inventoryItemDetails.inventoryOrderLineItems.length + 1 : '',
  loading: state.loading.loading,
  itemsList: state.inventoryItems.itemsList.content,
  modifierItemsList: state.inventoryItems.modifierItemsList.content,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  profile: state.subscription.profile,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  shopsList: state.shopDetails.shopsList.content,
  labelList: state.inventoryPrinterLabel.labelList.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryItemDetails: gid => dispatch(inventoryItemDetailsActions.fetchInventoryItemDetails({
    payload: gid,
  })),
  updateInventoryItems: param => dispatch(inventoryItemsActions.updateInventoryItems({
    payload: param,
  })),
  deleteInventoryItems: param => dispatch(inventoryItemsActions.deleteInventoryItems({
    payload: param,
  })),
  fetchItemsList: param => dispatch(inventoryItemsActions.fetchItemsList({
    payload: param,
  })),
  fetchModifierItemsList: param => dispatch(inventoryItemsActions.fetchModifierItemsList({
    payload: param,
  })),
  fetchAllShopDetails: (payload) => dispatch(shopDetailsActions.fetchAllShopDetails({
    payload,
  })),
  fetchPrinterLabelList: (payload) => dispatch(inventoryPrinterLabelActions.fetchPrinterLabelList({
    payload,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchInventoryItemsSearch: param => dispatch(inventoryItemsActions.fetchInventoryItemsSearch({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ItemDetails);
