import { SUBSCRIPTION } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  plans: [],
  customers: [],
  products: [],
  profile: [],
  productsearch: [],
  subscriptionStatus: undefined,
  subscriptionError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION.FETCH:
      return {
        ...state,
        subscriptionStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        ...action.payload,
        subscriptionStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case SUBSCRIPTION.FAILED:
      return {
        ...state,
        subscriptionError: action.payload,
        subscriptionStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case SUBSCRIPTION.SEARCH_SUCCESS:
      return {
        ...state,
        plans: action.payload,
      };
    case SUBSCRIPTION.CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      };
    case SUBSCRIPTION.PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload,
      };
    case SUBSCRIPTION.PRODUCT_SEARCH_SUCCESS:
      return {
        ...state,
        productsearch: action.payload,
      };
    case SUBSCRIPTION.PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};
