import { VENDORS } from '../actionTypes';

const initialState = {
  content: [],
  customSuggestions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VENDORS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case VENDORS.CUSTOM_TYPE_SUCCESS:
      return {
        ...state,
        customSuggestions: action.payload.customSuggestions,
      };
    default:
      return state;
  }
};
