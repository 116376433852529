// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
} from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  sectionSelected: Object,
  shopGid: string,
};

const DeleteSection = (props: Props) => {
  const {
    visible,
    close,
    submit,
    sectionSelected,
    shopGid,
  } = props;

  console.log(sectionSelected.name, 'sectionSelectedsectionSelectedsectionSelected');

  const validate = (event) => {
    event.preventDefault();
    submit({
      shopGid,
      sectionGid: sectionSelected.gid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} type="danger">Yes</SpButton>,
      ]}
      title="Delete Section"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to delete this Section?</div>
        <SpH5 className="pb-3">
          <div>{sectionSelected.name}</div>
        </SpH5>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(DeleteSection);
