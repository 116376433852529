export default value => {
  if (value === 'CREATED') {
    return 'Created';
  } if (value === 'MOVED') {
    return 'Moved';
  } if (value === 'RESERVED') {
    return 'Reserved';
  } if (value === 'CANCELLED') {
    return 'Cancelled';
  } if (value === 'EXPIRED') {
    return 'Expired';
  } return null;
};
