import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  PAYOUTS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchPayouts = async (payload) => {
  let parms = '';
  const queryParam = payload.searchParam;
  if (queryParam && queryParam === 'ALL') {
    parms = '';
  } else if (queryParam && queryParam === 'SETTLED') {
    parms = 'status.EQ=PAID';
  } else if (queryParam && queryParam === 'PENDING') {
    parms = 'status.EQ=PENDING';
  }
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=payoutDate&direction=DESC';
  if (parms && !payload.filterParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYOUT}${query}&${parms}${sorting}`);
  }
  if (payload.filterParams && !parms && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYOUT}${query}&${payload.filterParams}`);
  }
  if (payload.filterParams && parms && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYOUT}${query}&${parms}&${payload.filterParams}${sorting}`);
  }
  if (payload.filterParams && !parms && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYOUT}${query}&${payload.filterParams}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYOUT}${query}${sorting}`);
};

const patchPayoutDetails = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INTERNAL_PAYOUT}/${gid}/paid`, params);

export function* getPayouts(action) {
  try {
    const { data: { entity } } = yield call(fetchPayouts, action.payload);
    yield put({ type: PAYOUTS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: PAYOUTS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* updatePayout(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchPayoutDetails, action.payload);
    const { data: { entity } } = yield call(fetchPayouts, action.payload);
    yield put({ type: PAYOUTS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYOUT.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
