import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  INTERNAL_MEMBERSHIP,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInternalMembership = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=user.givenName&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.INTERNAL_MEMBERSHIP}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.INTERNAL_MEMBERSHIP}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.INTERNAL_MEMBERSHIP}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.INTERNAL_MEMBERSHIP}${query}${sorting}`);
};
const postInternalMembership = async payload => axiosAuthInstance.post(API_END_POINTS.INTERNAL_MEMBERSHIP, payload);

const patchInternalMembership = async ({ memberId, params }) => axiosAuthInstance.patch(`${API_END_POINTS.INTERNAL_MEMBERSHIP}/${memberId}`, params);

const deleteInternalMember = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.INTERNAL_MEMBERSHIP}/${gid}`);

export function* getInternalMembership(action) {
  try {
    const { data: { entity } } = yield call(fetchInternalMembership, action.payload);
    yield put({ type: INTERNAL_MEMBERSHIP.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: INTERNAL_MEMBERSHIP.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addInternalMembership(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postInternalMembership, action.payload);
    const { data: { entity } } = yield call(fetchInternalMembership, action.payload);
    yield put({ type: INTERNAL_MEMBERSHIP.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.MEMBERSHIP.INTERNAL_ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateInternalMembership(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInternalMembership, action.payload);
    const { data: { entity } } = yield call(fetchInternalMembership, action.payload);
    yield put({ type: INTERNAL_MEMBERSHIP.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.MEMBERSHIP.INTERNAL_UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteInternalMembership(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteInternalMember, action.payload);
    const { data: { entity } } = yield call(fetchInternalMembership, action.payload);
    yield put({ type: INTERNAL_MEMBERSHIP.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.MEMBERSHIP.INTERNAL_DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
