// @flow
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import {
  Input,
  Select,
  Modal,
  Alert,
} from 'antd';
import { MESSAGES } from 'appconstants';
import moment from 'moment-timezone';

import {
  SpH5,
  SpText,
  SpCaption,
  SpError,
  SpButton,
} from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  selectedUser: Object,
  selectedAccount: Object,
  email: String,
  error: String,
  submitting: boolean,
};

const Regenerate = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedUser,
    selectedAccount,
    email,
    error,
    submitting,
  } = props;
  const [, forceUpdate] = useState();
  const [password, setPassword] = useState('');
  const [activationType, setActivationType] = useState('86400');
  const [apiKey] = useState(selectedUser.apiKey);
  const [lastUsed] = useState(selectedUser.lastUsed);
  const [name] = useState(selectedUser.name);
  const [rgid] = useState(selectedUser.gid);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (error === 401) {
      setDisableButton(false);
    }
  }, [error, disableButton]);


  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      activationType,
      password,
      email,
      apiKey,
      name,
      rgid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Regenerate</SpButton>,
      ]}
      title="Regenerate API Key"
    >
      <div className="px-4">
        <SpH5 className="mr-4 mb-2">Key Name</SpH5>
        <SpText><div className="mb-3 mt-2">{apiKey}</div></SpText>

        <SpH5 className="mr-4 mb-2">Last Used</SpH5>
        <SpText>
          <div className="mb-3 mt-2">
            {
              lastUsed
                ? moment
                  .utc(lastUsed)
                  .tz(selectedAccount.timezone)
                  .format('MMM DD, YYYY')
                : <>&#8211;</>
            }
          </div>
        </SpText>

        <div className="d-flex mt-2">
          <div className="flex-grow-1 mr-3 w-100">
            <SpH5>Key Deactivation</SpH5>
            <Select
              style={{ width: '100%' }}
              defaultValue="86400"
              onChange={e => setActivationType(e)}
            >
              <Option key={1} value="86400">Deactivate in 24 hours</Option>
              <Option key={2} value="0">Deactivate immediately</Option>
              <Option key={3} value="3600">Deactivate in 1 hour</Option>
              <Option key={4} value="259200">Deactivate in 3 days</Option>
              <Option key={5} value="604800">Deactivate in 7 days</Option>
            </Select>
          </div>
          <SpError>
            {simpleValidator.current.message('activationType', activationType, 'required')}
          </SpError>
          <SpCaption className="flex-grow-1 mt-3 figure-caption">
            Note: After the key deactivates, you &#39;ll be unable to perform any actions with the old key.
          </SpCaption>
        </div>

        <SpText><div className="mt-2 mb-2">To continue, please enter your password</div></SpText>

        <SpH5 className="mr-4 mb-2">Email</SpH5>
        <SpText><div className="mb-3 mt-2">{email}</div></SpText>
        <div className="mt-3">
          <SpH5>Password</SpH5>
          <Input.Password
            autoComplete="new-password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('password', password, 'required')}
          </SpError>
          {
            error === 401 && (
              <Alert
                message={MESSAGES.LOGIN.ERROR}
                type="error"
              />
            )
          }
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(Regenerate);
