import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { spObjectActions } from 'store/actions';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { PAGES, ROLES } from 'appconstants';
import {
  Card, Col, Icon, Row, Table, Tooltip,
} from 'antd';
import { SpButton } from 'components/DesignKit';
import RBAC from 'components/rbac';
import AddSpObject from './components/AddSpObject';
import EditSpObject from './components/EditSpObject';
import DeleteSpObject from './components/DeleteSpObject';

type Props = {
  spObjects: Array<Object>,
  fetchSpObject: Function,
  addSpObject: Function,
  updateSpObject: Function,
  deleteSpObject: Function,
  totalElements: number,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
}

const SpObject = (props: Props) => {
  const {
    spObjects,
    addSpObject,
    updateSpObject,
    deleteSpObject,
    fetchSpObject,
    totalElements,
    submitting,
    validator,
    history,
  } = props;

  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const [addSpObjectModal, setAddSpObjectModal] = useState(false);
  const [editSpObjectModal, setEditSpObjectModal] = useState(false);
  const [deleteSpObjectModal, setDeleteSpObjectModal] = useState(false);
  const [selectedSpObject, setSelectedSpObject] = useState({});

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [spObjects]);

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 0;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    fetchSpObject({ currentPage, sortParams, filterParams });
  };
  useEffect(() => {
    getData(location.search);
  }, [location]);

  useEffect(() => {
    if (!validator) {
      setAddSpObjectModal(false);
      setEditSpObjectModal(false);
      setDeleteSpObjectModal(false);
    }
  }, [validator]);

  const addNewSpObject = (spObject: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const params = { ...spObject };
    addSpObject({
      params,
      currentPage,
      sortParams,
    });
  };

  const editSpObject = (spObject: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const params = { ...spObject };
    updateSpObject({
      currentPage,
      sortParams,
      gid: selectedSpObject.gid,
      params,
    });
  };

  const deleteSPObject = (gid: string) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    deleteSpObject({
      currentPage,
      sortParams,
      gid,
    });
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '16%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '22%',
      align: 'left',
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); setSelectedSpObject(record); setDeleteSpObjectModal(true); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            className="ml-3"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); setSelectedSpObject(record); setEditSpObjectModal(true); }}
          />
        </Tooltip>
      </span>
    ),
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.SP_OBJECT,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.SP_OBJECT,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.SUPERUSER]}>
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                ghost
                onClick={() => setAddSpObjectModal(true)}
              >
                {'\u002B'}&nbsp;Add New
              </SpButton>
            </Col>
          </RBAC>
        </div>
      </Row>
      {/* ADD CUSTOMER MODAL */}
      { addSpObjectModal && (
        <AddSpObject
          visible={addSpObjectModal}
          submitting={submitting}
          close={() => setAddSpObjectModal(false)}
          submit={addNewSpObject}
        />
      )}
      {/* EDIT USER MODAL */}
      { editSpObjectModal && (
        <EditSpObject
          visible={editSpObjectModal}
          selectedSpObject={selectedSpObject}
          submitting={submitting}
          close={() => setEditSpObjectModal(false)}
          submit={editSpObject}
        />
      )}
      {/* DELETE USER MODAL */}
      { deleteSpObjectModal && (
        <DeleteSpObject
          visible={deleteSpObjectModal}
          selectedSpObject={selectedSpObject}
          submitting={submitting}
          close={() => setDeleteSpObjectModal(false)}
          submit={deleteSPObject}
        />
      )}
      <Table
        rowClassName={() => 'ant-table-clickable-row'}
        columns={[...columns, actionColumn]}
        rowKey={(record) => record.gid}
        dataSource={spObjects}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => (
  {
    spObjects: state.spObject.content,
    totalElements: state.spObject.totalElements,
    validator: state.loading.validator,
    submitting: state.loading.submitting,
    isSuperUser: state.user.superUser,
  }
);

const mapDispatchToProps = (dispatch) => ({
  fetchSpObject: param => dispatch(spObjectActions.fetchSpObject({
    payload: param,
  })),
  addSpObject: param => dispatch(spObjectActions.addSpObject({
    payload: param,
  })),
  updateSpObject: param => dispatch(spObjectActions.updateSpObject({
    payload: param,
  })),
  deleteSpObject: param => dispatch(spObjectActions.deleteSpObject({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpObject);
