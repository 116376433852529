import { BENEFICIARY } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  beneficiaryStatus: undefined,
  beneficiaryError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BENEFICIARY.FETCH:
      return {
        ...state,
        beneficiaryStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case BENEFICIARY.SUCCESS:
      return {
        ...state,
        ...action.payload,
        beneficiaryStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case BENEFICIARY.FAILED:
      return {
        ...state,
        beneficiaryError: action.payload,
        beneficiaryStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
