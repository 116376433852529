// @flow
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Input, Modal, Select } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpButton, SpError, SpH5 } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedSpField: Object,
  spObjects: Array<Object>
};

const EditSpField = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedSpField,
    spObjects,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [fieldName, setFieldName] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [spObjectGid, setSpObjectGid] = useState();

  simpleValidator.current.purgeFields();

  useEffect(() => {
    if (selectedSpField) {
      setName(selectedSpField.name);
      setFieldName(selectedSpField.fieldName);
      setSpObjectGid(selectedSpField.spObject && selectedSpField.spObject.gid);
    }
  }, [selectedSpField]);

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      fieldName,
      spObjectGid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={submitting}>Update</SpButton>,
      ]}
      title="Update SP Field"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>SP Field Name</SpH5></label>
          <Input
            placeholder="SP Field Name"
            value={fieldName}
            onChange={(e) => setFieldName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('spFieldName', name, 'required')}
          </SpError>
        </div>
        <div className="pb-3">
          <label className="d-block"><SpH5>SP Object</SpH5></label>
          <Select
            placeholder="Please select"
            className="text-capitalize w-100"
            onChange={value => setSpObjectGid(value)}
            value={spObjectGid}
            key={1}
          >
            {
              spObjects && spObjects.map((value, index) => (
                <Option
                  key={index}
                  value={value.gid}
                  className="text-capitalize"
                >
                  { value.name }
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('SpObject', spObjectGid, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditSpField);
