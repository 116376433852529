import { OCR_REQUEST } from '../actionTypes';

export const fetchOcrRequest = (action) => ({
  type: OCR_REQUEST.FETCH,
  payload: action.payload,
});

export const addOcrRequest = (action) => ({
  type: OCR_REQUEST.ADD,
  payload: action.payload,
});
