import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  CUSTOM_DOCUMENT_REQUEST,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchCustomDocumentRequest = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.CUSTOM_DOCUMENT_REQUEST}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.CUSTOM_DOCUMENT_REQUEST}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.CUSTOM_DOCUMENT_REQUEST}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.CUSTOM_DOCUMENT_REQUEST}${query}${sorting}`);
};
const postCustomDocumentRequest = async payload => axiosAuthInstance.post(API_END_POINTS.ADD_CUSTOM_DOCUMENT_REQUEST, payload);

// eslint-disable-next-line max-len
const patchCustomDocumentRequest = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.ADD_CUSTOM_DOCUMENT_RESPONSE}/${gid}/response`, params);

export function* getCustomDocumentRequest(action) {
  try {
    const { data: { entity } } = yield call(fetchCustomDocumentRequest, action.payload);
    yield put({ type: CUSTOM_DOCUMENT_REQUEST.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: CUSTOM_DOCUMENT_REQUEST.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addCustomDocumentRequest(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postCustomDocumentRequest, action.payload);
    const { data: { entity } } = yield call(fetchCustomDocumentRequest, action.payload);
    yield put({ type: CUSTOM_DOCUMENT_REQUEST.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOM_DOCUMENT_REQUEST.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateCustomDocumentRequest(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCustomDocumentRequest, action.payload);
    const { data: { entity } } = yield call(fetchCustomDocumentRequest, action.payload);
    yield put({ type: CUSTOM_DOCUMENT_REQUEST.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOM_DOCUMENT_REQUEST.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
