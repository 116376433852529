import React, { useState, useRef } from 'react';
import {
  Row,
  Col,
  Switch,
  Button,
  Select,
  Input,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpButton,
  SpH5,
  SpError,
} from 'components/DesignKit';

import {
  CURRENCY_CODES,
} from 'appconstants';
import formatAmount from 'utils/formatAmount';

const { Option } = Select;

type Props = {
  internalAccount: Object,
  submit: Function,
  openModal: Function,
  prefix: String,
  currencyType: Object,
};

const ServerCallBack = (props: Props) => {
  const {
    internalAccount,
    submit,
    openModal,
    prefix,
    currencyType,
  } = props;

  const [, forceUpdate] = useState();
  const [fundingType, setFundingType] = useState(internalAccount && internalAccount.fundingType);
  const [settlementCurrency, setSettlementCurrency] = useState(internalAccount && internalAccount.settlementCurrency
    ? (internalAccount.settlementCurrency && internalAccount.settlementCurrency.name)
    : (internalAccount.country && internalAccount.country.alpha3));
  const [encryptServerCallBack, setEncryptServerCallBack] = useState(internalAccount && internalAccount.encryptServerCallBack
    ? internalAccount.encryptServerCallBack
    : false);
  const [reconciliationEnabled, setReconciliationEnabled] = useState(internalAccount && internalAccount.reconciliationEnabled
    ? internalAccount.reconciliationEnabled
    : false);
  const [isWhitelabel, setIsWhitelabel] = useState(internalAccount && internalAccount.whiteLabel
    ? internalAccount.whiteLabel
    : false);
  const [enablePaymentPageOrder, setEnablePaymentPageOrder] = useState(internalAccount && internalAccount.enablePaymentPageOrder
    ? internalAccount.enablePaymentPageOrder
    : false);
  const [aggregatorName, setAggregatorName] = useState(internalAccount && internalAccount.aggregatorName);
  const [transactionLimit, setTransactionLimit] = useState((internalAccount && (internalAccount.maxTransactionLimit))
    ? (internalAccount.maxTransactionLimit / 100).toFixed(2)
    : '');
  const [tipEnabled, setTipEnabled] = useState(internalAccount && internalAccount.tipEnabled
    ? internalAccount.tipEnabled
    : false);
  const [legacySubscription, setLegacySubscription] = useState(internalAccount && internalAccount.legacySubscription
    ? internalAccount.legacySubscription
    : false);
  const [plaidStatus, setPlaidStatus] = useState(internalAccount && internalAccount.plaidStatus
    ? internalAccount.plaidStatus
    : null);
  const [businessType, setBusinessType] = useState(internalAccount && internalAccount.businessType
    ? internalAccount.businessType
    : null);
  const [organisationType, setOrganizationType] = useState(internalAccount && internalAccount.organisationType
    ? internalAccount.organisationType
    : null);
  const [walletReferenceId, setWalletReferenceId] = useState(internalAccount && internalAccount.walletReferenceId
    ? internalAccount.walletReferenceId
    : null);
  const [walletAcquirer, setWalletAcquirer] = useState(internalAccount && internalAccount.walletAcquirer
    ? internalAccount.walletAcquirer
    : null);
  const [collectCustomerAddress, setCollectCustomerAddress] = useState(internalAccount && internalAccount.collectCustomerAddress
    ? internalAccount.collectCustomerAddress
    : false);
  const [collectBillingAddress, setCollectBillingAddress] = useState(internalAccount && internalAccount.collectBillingAddress
    ? internalAccount.collectBillingAddress
    : false);
  const [collectShippingAddress, setCollectShippingAddress] = useState(internalAccount && internalAccount.collectShippingAddress
    ? internalAccount.collectShippingAddress
    : false);

  const [disabled, setDisabled] = useState(true);
  const [enableWoocommerceOrder, setEnableWoocommerceOrder] = useState(internalAccount && internalAccount.enableWoocommerceOrder
    ? internalAccount.enableWoocommerceOrder
    : false);
  const [paymentPageOrderFcmType, setPaymentPageOrderFcmType] = useState(internalAccount && internalAccount.paymentPageOrderFcmType
    ? internalAccount.paymentPageOrderFcmType
    : null);
  const isUsa = !!(internalAccount && internalAccount.country.alpha3 === 'USA');
  const simpleValidator = useRef(new SimpleReactValidator());

  const updateServerCallBack = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    submit({
      encryptServerCallBack,
      reconciliationEnabled,
      fundingType,
      isWhitelabel,
      aggregatorName,
      tipEnabled,
      legacySubscription,
      plaidStatus,
      businessType,
      organisationType,
      settlementCurrency,
      transactionLimit,
      walletAcquirer,
      walletReferenceId,
      enableWoocommerceOrder,
      enablePaymentPageOrder,
      collectCustomerAddress,
      collectBillingAddress,
      collectShippingAddress,
      paymentPageOrderFcmType,
    });
  };

  const reset = () => {
    setEncryptServerCallBack(internalAccount && internalAccount.encryptServerCallBack);
    setFundingType(internalAccount && internalAccount.fundingType);
    setReconciliationEnabled(internalAccount && internalAccount.reconciliationEnabled
      ? internalAccount.reconciliationEnabled
      : false);
    setIsWhitelabel(internalAccount && internalAccount.whiteLabel
      ? internalAccount.whiteLabel
      : false);
    setEnablePaymentPageOrder(internalAccount && internalAccount.enablePaymentPageOrder
      ? internalAccount.enablePaymentPageOrder
      : false);
    setEnableWoocommerceOrder(internalAccount && internalAccount.enableWoocommerceOrder
      ? internalAccount.enableWoocommerceOrder
      : false);
    setTipEnabled(internalAccount && internalAccount.tipEnabled
      ? internalAccount.tipEnabled
      : false);
    setLegacySubscription(internalAccount && internalAccount.legacySubscription
      ? internalAccount.legacySubscription
      : false);
    setCollectCustomerAddress(internalAccount && internalAccount.collectCustomerAddress
      ? internalAccount.collectCustomerAddress
      : false);
    setCollectBillingAddress(internalAccount && internalAccount.collectBillingAddress
      ? internalAccount.collectBillingAddress
      : false);
    setCollectShippingAddress(internalAccount && internalAccount.collectShippingAddress
      ? internalAccount.collectShippingAddress
      : false);
    setPlaidStatus(internalAccount && internalAccount.plaidStatus
      ? internalAccount.plaidStatus
      : null);
    setBusinessType(internalAccount && internalAccount.businessType
      ? internalAccount.businessType
      : null);
    setOrganizationType(internalAccount && internalAccount.organisationType
      ? internalAccount.organisationType
      : null);
    setPaymentPageOrderFcmType(internalAccount && internalAccount.paymentPageOrderFcmType
      ? internalAccount.paymentPageOrderFcmType
      : null);
    setWalletReferenceId(internalAccount && internalAccount.walletReferenceId
      ? internalAccount.walletReferenceId
      : null);
    setWalletAcquirer(internalAccount && internalAccount.walletAcquirer
      ? internalAccount.walletAcquirer
      : null);
    setSettlementCurrency(internalAccount && internalAccount.settlementCurrency
      ? (internalAccount.settlementCurrency && internalAccount.settlementCurrency.name)
      : (internalAccount.country && internalAccount.country.alpha3));
    setAggregatorName(internalAccount && internalAccount.aggregatorName);
    setDisabled(true);
    openModal(false);
  };

  return (
    <>
      <div>
        <Row>
          <Col span={6} className="mb-2 mt-4">
            <SpH5>Settlement Currency</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              className="w-100"
              showSearch
              value={settlementCurrency}
              onChange={value => {
                setSettlementCurrency(value);
                setDisabled(false);
                openModal(true);
              }}
            >
              {CURRENCY_CODES.map((item) => (
                <Option
                  key={item.ID}
                  value={item.VALUE}
                >
                  {item.VALUE}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        {
          isUsa && (
            <>
              <Row>
                <Col span={6} className="mb-2 mt-4">
                  <SpH5>Instant payment Reference ID</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Input
                    placeholder="Enter a Reference ID"
                    value={walletReferenceId}
                    onChange={e => {
                      setWalletReferenceId(e.currentTarget.value);
                      setDisabled(false);
                      openModal(true);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6} className="mb-2 mt-4">
                  <SpH5>Instant Payment Acquirer</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Select
                    defaultValue={walletAcquirer || 'Select Type'}
                    className="w-100"
                    onChange={value => {
                      setWalletAcquirer(value);
                      setDisabled(false);
                      openModal(true);
                    }}
                    showSearch
                  >
                    <Option key={1} value="STRIPE">STRIPE</Option>
                  </Select>
                </Col>
              </Row>
            </>
          )
        }
        <Row>
          <Col span={3} className="mb-2 mt-4">
            <SpH5>Funding Type</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              value={fundingType}
              className="w-100"
              onChange={value => {
                setFundingType(value);
                setDisabled(false);
                openModal(true);
              }}
              showSearch
            >
              <Option key={1} value="GROSS">GROSS</Option>
              <Option key={2} value="NET">NET</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={3} className="mb-2 mt-4">
            <SpH5>Plaid Status</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              defaultValue={plaidStatus || 'Select Status'}
              className="w-100"
              onChange={value => {
                setPlaidStatus(value);
                setDisabled(false);
                openModal(true);
              }}
              showSearch
            >
              <Option key={1} value="ACTIVE">Active</Option>
              <Option key={2} value="SUCCESS">Success</Option>
              <Option key={3} value="FAILED">Failed</Option>
              <Option key={4} value="EXPIRED">Expired</Option>
              <Option key={5} value="CANCELED">Canceled</Option>
              <Option key={6} value="PENDING_REVIEW">Pending Review</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={3} className="mb-2 mt-4">
            <SpH5>Business Type</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              defaultValue={businessType || 'Select Type'}
              className="w-100"
              onChange={value => {
                setBusinessType(value);
                setDisabled(false);
                openModal(true);
              }}
              showSearch
            >
              <Option key={1} value="B2B">B2B</Option>
              <Option key={2} value="B2C">B2C</Option>
              <Option key={3} value="UNSELECTED">UNSELECTED</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={3} className="mb-2 mt-4">
            <SpH5>Organization Type</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              defaultValue={organisationType || 'Select Type'}
              className="w-100"
              onChange={value => {
                setOrganizationType(value);
                setDisabled(false);
                openModal(true);
              }}
              showSearch
            >
              <Option key={1} value="CORPORATE">CORPORATE</Option>
              <Option key={2} value="RESTAURANT">RESTAURANT</Option>
              <Option key={3} value="NON_PROFIT">NON PROFIT</Option>
              <Option key={4} value="DISTRIBUTORS">DISTRIBUTORS</Option>
              <Option key={5} value="UNSELECTED">UNSELECTED</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={3} className="mb-2 mt-4">
            <SpH5>Payment Page Order</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              defaultValue={paymentPageOrderFcmType || 'Select Type'}
              className="w-100"
              onChange={value => {
                setPaymentPageOrderFcmType(value);
                setDisabled(false);
                openModal(true);
              }}
              showSearch
            >
              <Option key={1} value="FIREBASE_FCM">FIREBASE FCM</Option>
              <Option key={2} value="FIREBASE_DATA">FIREBASE DATA</Option>
              <Option key={3} value="NONE">NONE</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={3} className="mb-2 mt-4">
            <SpH5>Aggregator Name</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Input
              placeholder="Enter Aggregator Name"
              value={aggregatorName}
              onChange={e => {
                setAggregatorName(e.currentTarget.value);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpError>
              {simpleValidator.current.message('aggregator name', aggregatorName, 'alpha_num')}
            </SpError>
          </Col>
        </Row>
        <Row>
          <Col span={5} className="mb-2 mt-4">
            <SpH5>Maximum Transaction Limit</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Input
              value={transactionLimit}
              prefix={prefix}
              placeholder="0.00"
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                const { value } = e.currentTarget;
                if (regex.test(value) || value === '') {
                  setTransactionLimit(e.currentTarget.value);
                } else if (!regex.test(value)) {
                  setTransactionLimit(0.00);
                }
                setDisabled(false);
                openModal(true);
              }}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
              onBlur={e => {
                if (e.currentTarget.value) {
                  const value = formatAmount(e.currentTarget.value, currencyType);
                  setTransactionLimit(value);
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6} className="mb-2 mt-4">
            <Switch
              checked={enableWoocommerceOrder}
              className="mr-2"
              onChange={val => {
                setEnableWoocommerceOrder(val);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpH5>Enable Woocommerce Order</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="mb-2 mt-4">
            <Switch
              checked={isWhitelabel}
              className="mr-2"
              onChange={val => {
                setIsWhitelabel(val);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpH5>Is Whitelabel</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="mb-2 mt-4">
            <Switch
              checked={collectBillingAddress}
              className="mr-2"
              onChange={val => {
                setCollectBillingAddress(val);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpH5>Collect Billing Address</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="mb-2 mt-4">
            <Switch
              checked={collectShippingAddress}
              className="mr-2"
              onChange={val => {
                setCollectShippingAddress(val);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpH5>Collect Shipping Address</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="mb-2 mt-4">
            <Switch
              checked={collectCustomerAddress}
              className="mr-2"
              onChange={val => {
                setCollectCustomerAddress(val);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpH5>Collect Customer Address</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="mb-2 mt-4">
            <Switch
              checked={reconciliationEnabled}
              className="mr-2"
              onChange={val => {
                setReconciliationEnabled(val);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpH5>Reconciliation Enabled</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="mb-2 mt-4">
            <Switch
              checked={encryptServerCallBack}
              className="mr-2"
              onChange={val => {
                setEncryptServerCallBack(val);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpH5>Encrypt Server Call Back</SpH5>
          </Col>
        </Row>
        {
          isUsa && (
            <Row>
              <Col span={6} className="mb-2 mt-4">
                <Switch
                  checked={tipEnabled}
                  className="mr-2"
                  onChange={val => {
                    setTipEnabled(val);
                    setDisabled(false);
                    openModal(true);
                  }}
                />
                <SpH5>Tip Enabled</SpH5>
              </Col>
            </Row>
          )
        }
        <Row>
          <Col span={6} className="mb-2 mt-4">
            <Switch
              checked={legacySubscription}
              className="mr-2"
              onChange={val => {
                setLegacySubscription(val);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpH5>Enable Legacy Subscription</SpH5>
          </Col>
        </Row>
        <Row className="mt-4" style={{ marginLeft: '-15px' }}>
          <Col span={2}>
            <Button
              type="link"
              onClick={reset}
              disabled={disabled}
            >
              Reset
            </Button>
          </Col>
          <Col span={2}>
            <SpButton
              type="primary"
              shape="round"
              disabled={disabled}
              onClick={updateServerCallBack}
            >
              Update
            </SpButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(ServerCallBack);
