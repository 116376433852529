import { WOOCOMMERCE_DETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  orderTimeline: [],
  wooCommerceDetailsStatus: undefined,
  wooCommerceDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WOOCOMMERCE_DETAILS.FETCH:
      return {
        ...state,
        wooCommerceDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case WOOCOMMERCE_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        wooCommerceDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case WOOCOMMERCE_DETAILS.FAILED:
      return {
        ...state,
        wooCommerceDetailsError: action.payload,
        wooCommerceDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case WOOCOMMERCE_DETAILS.SUCCESS_TIMELINE:
      return {
        ...state,
        orderTimeline: action.payload,
      };
    default:
      return state;
  }
};
