import React from 'react';
import { Table } from 'antd';
import formatAmount from 'utils/formatAmount';
import { withRouter } from 'react-router';

import { PAGES } from 'appconstants';

type Props = {
  history: {
    push: Function,
  },
  dataSource: Array,
  selectedAccount: Object,
  summaryType: String,
};

const ClientsTable = ({
  history,
  dataSource,
  selectedAccount,
  summaryType,
}: Props) => {
  const currencyType = selectedAccount && selectedAccount.currency;
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      align: 'left',
      sorter: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'totalVolume',
      align: 'left',
      render: (totalVolume) => ((totalVolume
        && selectedAccount
        && selectedAccount.currency)
        ? `${selectedAccount.currency.prefix}${formatAmount(totalVolume / 100, currencyType)}`
        : <>&#8211;</>),
      sorter: false,
    },
    {
      title: 'COUNT',
      dataIndex: 'count',
      align: 'left',
      sorter: false,
    },
  ];

  return (
    <Table
      onRow={(record) => ({
        onClick: () => {
          if (summaryType === 'Payments') {
            history.push(`${PAGES.CUSTOMERS}/${record.customerGid}`);
          } else {
            history.push(`${PAGES.VENDORS}/${record.contactGid}`);
          }
        },
      })}
      rowClassName={() => 'ant-table-clickable-row'}
      columns={columns}
      rowKey={(record) => record.customerGid}
      dataSource={dataSource}
      className="mt-2"
      pagination={false}
    />
  );
};

export default withRouter(ClientsTable);
