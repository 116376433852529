import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Row,
  Col,
} from 'antd';
import {
  SpForm,
  SpH5,
} from 'components/DesignKit';

type Props = {
    support: Object,
};

const View = (props: Props) => {
  const {
    email,
    phone,
    website,
  } = props.support;
  const [phoneFormate, setPhoneFormate] = useState('');

  useEffect(() => {
    const cntryCode = phone.substring(0, 2);
    if (cntryCode === '+1') {
      setPhoneFormate('+# (###) ###-####');
    } else if (cntryCode === '+9') {
      setPhoneFormate('+##-##### #####');
    }
  }, [phone]);

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Support Email</span>
          </Col>
          <Col span={9}>
            <SpH5>{email}</SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Phone Number</span>
          </Col>
          <Col span={9}>
            <NumberFormat
              className="numFormate"
              value={phone}
              format={phoneFormate}
              mask="_"
              disabled
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Support Website</span>
          </Col>
          <Col span={9}>
            {
              website ? <SpH5>{website}</SpH5> : <span className="ml-5">&#8211;</span>
           }
          </Col>
        </Row>
      </SpForm>
    </>
  );
};

export default React.memo(View);
