import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Row,
  Col,
  DatePicker,
  Select,
  Upload,
  message,
  Switch,
  Input,
  Icon,
  Tooltip,
  Table,
} from 'antd';

import moment from 'moment-timezone';
import queryString from 'query-string';

import {
  SpButton,
  SpForm,
  SpError,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';

import SimpleReactValidator from 'simple-react-validator';

import {
  MESSAGES,
  ROLES,
  PAGES,
} from 'appconstants';

import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';

import {
  settlementActions,
  accountActions,
  notificationActions,
} from 'store/actions';
import {
  ACCOUNT,
  NOTIFICATION,
} from 'store/actionTypes';
import { useLocation } from 'react-router-dom';
import formatAmount from 'utils/formatAmount';

import { CurrencyList } from 'utils/currencyList';

import Loading from 'components/Loading';
import RBAC from 'components/rbac';
import CsvIcon from 'components/NavIcons/csvIcon';
import getSettlementBackground from 'utils/getSettlementBackground';
import getSettlementStatus from 'utils/getSettlementStatus';

const { Option } = Select;

const dateFormat = 'MMM DD, YYYY';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  addImsSettlement: Function,
  addStripeSettlement: Function,
  addAffinipaySettlement: Function,
  fetchAccountByInternalId: Function,
  setNotification: Function,
  fetchSettlementRun: Function,
  loading: boolean,
  test: boolean,
  validator: boolean,
  submitting: boolean,
  internalAccount: Object,
  selectedAccount: Object,
  totalElements: number,
  settlementRun: Array,
  history: {
    push: Function,
  },
};

const Settlements = (props: Props) => {
  const {
    loading,
    validator,
    test,
    submitting,
    setNotification,
    internalAccount,
    addImsSettlement,
    addStripeSettlement,
    addAffinipaySettlement,
    fetchAccountByInternalId,
    fetchSettlementRun,
    selectedAccount,
    settlementRun,
    totalElements,
    history,
  } = props;

  const [, forceUpdate] = useState();
  const location = useLocation();
  const gid = selectedAccount && selectedAccount.gid;
  const [dateDisplay, setDateDisplay] = useState(null);
  const [settlementDate, setSettlementDate] = useState(moment.utc().tz(selectedAccount && selectedAccount.timezone).startOf('day').utc()
    .format(MOMENT_FORMAT));
  const [settlementFile, setSettlementFile] = useState();
  const [preview, setPreview] = useState();
  const [fileName, setFileName] = useState();
  const [notify, setNotify] = useState(false);
  const [exchangeRate, setExchangeRate] = useState();
  const [pagination, setPagination] = useState({});
  const [settlementAmount, setSettlementAmount] = useState();
  const [countryName, setCountryName] = useState((selectedAccount && selectedAccount.country) ? selectedAccount.country.name : '');
  const [currencyCode, setCurrencyCode] = useState((selectedAccount && selectedAccount.country) ? selectedAccount.currency.name : '');
  const [disableButton, setDisableButton] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const name = (internalAccount && internalAccount.acquirer && internalAccount.acquirer.name);
  const [loader, setLoader] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());

  const debouncedFetchSettlementRun = debounce(fetchSettlementRun, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchAccountByInternalId(gid);
    debouncedFetchSettlementRun({ currentPage, sortParams });
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [settlementRun]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    console.log(filters, 'filters');
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: PAGES.SETTLEMENTS,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };


  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <SpButton type="secondary">Upload</SpButton>}
    </div>
  );

  useEffect(() => {
    if (selectedAccount && selectedAccount.gid) {
      getData(location.search);
      setDateDisplay(moment().utc().tz(selectedAccount.timezone).startOf('day')
        .format(dateFormat));
    }
  }, [selectedAccount, location]);

  useEffect(() => {
    setFileName('');
    setDateDisplay(moment().utc().tz(selectedAccount.timezone).startOf('day')
      .format(dateFormat));
    simpleValidator.current.hideMessages();
    forceUpdate();
    setSettlementFile();
    setExchangeRate();
    setSettlementAmount();
    setCountryName((selectedAccount && selectedAccount.country) ? selectedAccount.country.name : '');
    setCurrencyCode((selectedAccount && selectedAccount.country) ? selectedAccount.currency.name : '');
    setNotify(false);
  }, [selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setFileName('');
      setDateDisplay(moment().utc().tz(selectedAccount.timezone).startOf('day')
        .format(dateFormat));
      simpleValidator.current.hideMessages();
      forceUpdate();
      setSettlementFile();
      setExchangeRate();
      setSettlementAmount();
      setNotify(false);
    }
  }, [validator]);

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 10;
    const fileType = (file.name).substring((file.name).length - 4);
    const CSV = '.csv';
    const textPlain = 'text/plain';
    const textCSV = 'text/csv';
    let pattern = false;
    if ((fileType === CSV) && (file.type.match(textPlain) || file.type.match(textCSV))) {
      pattern = true;
    } else if (fileType === CSV) {
      pattern = true;
    }
    if (!pattern) {
      message.error('File format is not csv');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 10MB!');
      return false;
    } return true;
  };

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = async (info) => {
    const { file } = info;
    try {
      setLoader(true);
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPLOAD_SETTLEMENT}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        setSettlementFile(entity);
        setFileName(file.name);
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: MESSAGES.FILE_UPLOAD.SUCCESS,
        });
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleChange = ({ file }) => {
    if (file.status === 'uploading') {
      setLoader(true);
    }
    const fileType = (file.name).substring((file.name).length - 4);
    const CSV = '.csv';
    const textPlain = 'text/plain';
    const textCSV = 'text/csv';
    let pattern = false;

    if ((fileType === CSV) && (file.type.match(textPlain) || file.type.match(textCSV))) {
      pattern = true;
    } else if (fileType === CSV) {
      pattern = true;
    }
    if (pattern) {
      setPreview(<CsvIcon />);
    }
  };

  const selectCurrencyCode = (e) => {
    setCountryName(e[0]);
    setCurrencyCode(e[1]);
  };

  const onDateChange = (date, dateString) => {
    setDateDisplay(dateString);
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    setSettlementDate(utcDate);
  };

  const changeNotifyType = (event) => {
    setNotify(event);
    if (!event) {
      simpleValidator.current.purgeFields();
    }
  };

  const processSettlement = async (event) => {
    event.preventDefault();
    setDisableButton(true);
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    let payload;
    const amount = settlementAmount ? settlementAmount.replace(/[. ,:-]+/g, '') : null;
    if (notify) {
      payload = {
        fileUrl: settlementFile,
        payoutDate: settlementDate,
        settlementAmount: Number(amount),
        exchangeRate: parseFloat(exchangeRate),
        currencyCode,
      };
    } else {
      payload = {
        fileUrl: settlementFile,
        payoutDate: settlementDate,
      };
    }
    switch (name) {
      case 'IMS': {
        addImsSettlement(payload);
        break;
      }
      case 'STRIPE-CANADA': {
        addStripeSettlement(payload);
        break;
      }
      case 'IMS-POS': {
        addImsSettlement(payload);
        break;
      }
      case 'STRIPE': {
        addStripeSettlement(payload);
        break;
      }
      case 'STRIPE-INDIA': {
        addStripeSettlement(payload);
        break;
      }
      case 'AFFINIPAY': {
        addAffinipaySettlement(payload);
        break;
      }
      default:
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: `Settlements for Acquirer ${name} is not supported.`,
        });
    }
  };

  const columns = [
    {
      title: 'TYPE',
      dataIndex: 'type',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (type) => (
        <span>
          {type || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => {
        if (status) {
          return (
            <SpStatusTag style={{ backgroundColor: getSettlementBackground(status) }}>
              {getSettlementStatus(status)}
            </SpStatusTag>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      {
        test
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <SpText fontSize="20px">Settlements are only available for live data</SpText>
              </div>
            </Card>
          )
          : (
            <>
              <Card>
                <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.ANALYST, ROLES.OPERATIONS, ROLES.DEVELOPER, ROLES.SUPERUSER]}>
                  <SpForm className="px-0">
                    <Row type="flex" justify="space-between">
                      <Col>
                        <SpText className="mb-3 mt-2" fontSize="20px">Process Settlement</SpText>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle" className="pl-2">
                      <Col span={3}>
                        <span>Account</span>
                      </Col>
                      <Col span={6}>
                        <div className="w-100">
                          <Select
                            className="w-100"
                            value={selectedAccount && selectedAccount.gid}
                            disabled
                          >
                            <Option key={1} value={selectedAccount && selectedAccount.gid}>
                              {`${selectedAccount && selectedAccount.gid}`}
                            </Option>
                          </Select>
                        </div>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle" className="pl-2">
                      <Col span={3}>
                        <span>Period</span>
                      </Col>
                      <Col span={6}>
                        <div className="w-100">
                          <DatePicker
                            value={moment(dateDisplay, dateFormat)}
                            format={dateFormat}
                            allowClear={false}
                            onChange={onDateChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle" className="pl-2">
                      <Col span={3}>
                        <span>Settlement File</span>
                      </Col>
                      <Col span={6}>
                        <div className="w-100">
                          <Upload
                            name="avatar"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            customRequest={fileUpload}
                            onChange={handleChange}
                          >
                            {fileName ? preview : uploadButton}
                          </Upload>
                          <SpError>
                            {simpleValidator.current.message('settlement File', settlementFile, 'required')}
                          </SpError>
                        </div>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row>
                      <Col className="mb-3">
                        <Switch
                          className="mr-2"
                          checked={notify}
                          onChange={changeNotifyType}
                        /> This payout includes an exchange rate
                      </Col>
                    </Row>
                  </SpForm>
                  {
                    notify && (
                      <>
                        <SpForm>
                          <Row type="flex" justify="start" align="middle" className="pl-2">
                            <Col span={3}>
                              <span>Exchange Rate</span>
                            </Col>
                            <Col span={6}>
                              <div className="w-100">
                                <Input
                                  value={exchangeRate}
                                  placeholder="1.00"
                                  onChange={(e) => {
                                    const regex = /^[0-9]*(\.[0-9]{0,5})?$/;
                                    const { value } = e.currentTarget;
                                    const amount = value.replace(/\s/g, '');
                                    if (regex.test(amount) || amount === '') {
                                      setExchangeRate(amount);
                                    } else if (!regex.test(amount)) {
                                      setExchangeRate();
                                    }
                                  }}
                                  onKeyPress={e => {
                                    const keyCode = e.charCode || e.which;
                                    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onBlur={e => {
                                    if (e.currentTarget.value) {
                                      const { value } = e.currentTarget;
                                      setExchangeRate(value);
                                    }
                                  }}
                                />
                                <SpError>
                                  {simpleValidator.current.message('exchange rate', exchangeRate, 'required')}
                                </SpError>
                              </div>
                            </Col>
                          </Row>
                        </SpForm>
                        <SpForm>
                          <Row type="flex" justify="start" align="middle" className="pl-2">
                            <Col span={3}>
                              <span>Select Country</span>
                            </Col>
                            <Col span={6}>
                              <div className="w-100">
                                <Select
                                  className="w-100 mt-4"
                                  showSearch
                                  value={countryName || 'Select a country'}
                                  onSelect={(e) => selectCurrencyCode(e)}
                                >
                                  {CurrencyList.map(item => (
                                    <Option
                                      key={item.countryCode}
                                      value={[
                                        item.countryName,
                                        item.currencyCode,
                                      ]}
                                    >
                                      {item.countryName}
                                    </Option>
                                  ))}
                                </Select>
                                <div className="mt-2">
                                  <span>Exchange currency is {currencyCode}</span>
                                </div>
                                <SpError>
                                  {simpleValidator.current.message('currency', countryName, 'required')}
                                </SpError>
                              </div>
                            </Col>
                          </Row>
                        </SpForm>
                        <SpForm>
                          <Row type="flex" justify="start" align="middle" className="pl-2">
                            <Col span={3}>
                              <span>Settlement Amount</span>
                            </Col>
                            <Col span={6}>
                              <div className="w-100">
                                <Input
                                  value={settlementAmount}
                                  placeholder="1.00"
                                  onChange={(e) => {
                                    const regex = /^\d*\.?\d*$/;
                                    const { value } = e.currentTarget;
                                    if (regex.test(value) || value === '') {
                                      setSettlementAmount(e.currentTarget.value);
                                    } else if (!regex.test(value)) {
                                      setSettlementAmount(0.00);
                                    }
                                  }}
                                  onKeyPress={e => {
                                    const keyCode = e.charCode || e.which;
                                    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onBlur={e => {
                                    if (e.currentTarget.value) {
                                      const value = formatAmount(e.currentTarget.value, currencyType);
                                      setSettlementAmount(value);
                                    }
                                  }}
                                />
                                <SpError>
                                  {simpleValidator.current.message('settlement amount', settlementAmount, 'required')}
                                </SpError>
                              </div>
                            </Col>
                          </Row>
                        </SpForm>
                      </>
                    )
                  }
                  <SpForm>
                    <Row gutter={[32, 32]} className="pl-4">
                      <Col span={10}>
                        {
                          name ? (
                            settlementFile
                              ? (
                                <SpButton
                                  type="primary"
                                  shape="round"
                                  disabled={disableButton}
                                  onClick={processSettlement}
                                >
                                  Process Settlement
                                </SpButton>
                              ) : (
                                <SpButton
                                  type="primary"
                                  shape="round"
                                  disabled
                                >
                                  Process Settlement
                                </SpButton>
                              )
                          ) : (
                            <Tooltip placement="top" title="No Acquirer for this account">
                              <SpButton
                                type="primary"
                                shape="round"
                                disabled
                              >
                                Process Settlement
                              </SpButton>
                            </Tooltip>
                          )
                        }
                      </Col>
                    </Row>
                  </SpForm>
                </RBAC>
                <Row type="flex" justify="space-between" className="mb-4 mt-5">
                  <Col className="d-flex">
                    <SpText color="#474747" fontSize="18px">
                      SETTLEMENT RUN TABLE
                    </SpText>
                  </Col>
                </Row>
                <Table
                  columns={columns}
                  dataSource={settlementRun}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </Card>
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  settlementRun: state.settlement.settlementRun.content,
  totalElements: state.settlement.settlementRun.totalElements,
  user: state.user,
  test: state.account.test,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  selectedAccount: state.account.selectedAccount,
  internalAccount: state.account.internalAccount,
});

const mapDispatchToProps = (dispatch) => ({
  addImsSettlement: param => dispatch(settlementActions.addImsSettlement({
    payload: param,
  })),
  addStripeSettlement: param => dispatch(settlementActions.addStripeSettlement({
    payload: param,
  })),
  addAffinipaySettlement: param => dispatch(settlementActions.addAffinipaySettlement({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchAccountByInternalId: gid => dispatch(accountActions.fetchAccountByInternalId({
    type: ACCOUNT.FETCH_BY_INTERNAL_ID,
    payload: gid,
  })),
  fetchSettlementRun: param => dispatch(settlementActions.fetchSettlementRun({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settlements);
