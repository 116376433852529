/* eslint-disable */
import React from 'react';

const filterIcon = ({ fill = "silver" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22">
    <g fill={fill}>
      <path d="M7.874 11.998c.116.122.185.28.185.45v9.545l4.1-3.505v-6.04c0-.17.067-.328.184-.45l6.934-7.557H.94l6.935 7.557zM.065 0H20.152V3.075H.065z"/>
    </g>
  </svg>
);

export default filterIcon;
