export default {
  API_STATUS: {
    STARTED: 'STARTED',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
  },
  DASHBOARD: {
    LAST_1_DAYS: 'LAST_1_DAYS',
    LAST_7_DAYS: 'LAST_7_DAYS',
    LAST_30_DAYS: 'LAST_30_DAYS',
    LAST_1_MONTHS: 'LAST_1_MONTHS',
    LAST_3_MONTHS: 'LAST_3_MONTHS',
    LAST_12_MONTHS: 'LAST_12_MONTHS',
    YEAR_TO_DATE: 'YEAR_TO_DATE',
  },
};
