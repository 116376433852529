import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  WEBHOOKS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchWebhooks = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.WEBHOOK_API}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.WEBHOOK_API}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.WEBHOOK_API}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.WEBHOOK_API}${query}${sorting}`);
};

const fetchWebhookevents = async () => axiosAuthInstance.get(API_END_POINTS.WEBHOOK_EVENT);

const postWebhook = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.WEBHOOK_API, params);

const patchWebhook = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.WEBHOOK_API}/${gid}`, params);

const deleteWebhook = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.WEBHOOK_API}/${gid}`);

export function* getWebhooks(action) {
  try {
    const { data: { entity } } = yield call(fetchWebhooks, action.payload);
    yield put({ type: WEBHOOKS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: WEBHOOKS.FAILED, payload: e.response.data });
  }
}

export function* addWebhook(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postWebhook, action.payload);
    const { data: { entity } } = yield call(fetchWebhooks, action.payload);
    yield put({ type: WEBHOOKS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WEBHOOK.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateWebhook(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchWebhook, action.payload);
    const { data: { entity } } = yield call(fetchWebhooks, action.payload);
    yield put({ type: WEBHOOKS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WEBHOOK.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteWebhooks(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteWebhook, action.payload);
    const { data: { entity } } = yield call(fetchWebhooks, action.payload);
    yield put({ type: WEBHOOKS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WEBHOOK.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getWebhookEvents(action) {
  try {
    const { data: { entity } } = yield call(fetchWebhookevents, action.payload);
    yield put({ type: WEBHOOKS.EVENTS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: WEBHOOKS.FAILED, payload: e.response.data });
  }
}
