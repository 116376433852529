// @flow
import React from 'react';
import UpdatePaymentPage from 'routes/paymentpage/components/UpdatePaymentPage';
import getPaymentMetaData from 'utils/getPaymentMetaData';

type Props = {
  pageData: Object,
  account: Object,
  addParent: Array,
  productDetailsArray: Array,
  customerDetailsArray: Array,
  enableTip: Boolean,
  isTipOptional: Boolean,
  firstOption: String,
  secondOption: String,
  thirdOption: String,
};

const UpdateReviewPaymentPage = (props: Props) => {
  const {
    pageData,
    account,
    addParent,
    productDetailsArray,
    customerDetailsArray,
    enableTip,
    isTipOptional,
    firstOption,
    secondOption,
    thirdOption,
  } = props;
  const {
    contactPhone,
    phoneNumberCode,
  } = pageData;
  const contactNumber = `${phoneNumberCode}${contactPhone}`;
  const contactList = { contactUsPhone: contactNumber, status: 'ACTIVE' };
  const data = Object.assign(pageData, contactList);
  const paymentPageMeta = getPaymentMetaData(data,
    productDetailsArray,
    customerDetailsArray,
    addParent,
    enableTip,
    isTipOptional,
    firstOption,
    secondOption,
    thirdOption);

  return (
    <>
      <div className="mb-3">
        {/* eslint-disable */}
        <UpdatePaymentPage
          paymentPageMeta={paymentPageMeta}
          onSubmit={null}
          phoneCode={phoneNumberCode}
          account={account}
          isPreview={true}
          isAdaptive={true}
        />
      </div>
    </>
  );
};

// $FlowFixMe
export default UpdateReviewPaymentPage;
