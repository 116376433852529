import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  DOCUMENTS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchDocuments = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.CUSTOM_DOCUMENT}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.CUSTOM_DOCUMENT}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.CUSTOM_DOCUMENT}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.CUSTOM_DOCUMENT}${query}${sorting}`);
};
const postDocument = async payload => axiosAuthInstance.post(API_END_POINTS.ADD_CUSTOM_DOCUMENT, payload);

export function* getDocuments(action) {
  try {
    const { data: { entity } } = yield call(fetchDocuments, action.payload);
    yield put({ type: DOCUMENTS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: DOCUMENTS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addDocument(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postDocument, action.payload.document);
    const { data: { entity } } = yield call(fetchDocuments, action.payload);
    yield put({ type: DOCUMENTS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOM_DOCUMENT.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
