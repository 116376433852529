import { call, put } from 'redux-saga/effects';

import generateDetailsQuery from 'utils/generateDetailsQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  PAYOUTSDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchPayoutDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_PAYOUT}/${gid}`);

const fetchPayoutTransactions = async (payload) => {
  const { gid } = payload;
  const { currentPage } = payload;
  const query = generateDetailsQuery(currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYOUT_TRANSACTIONS}=${gid}&${query}${sorting}`);
};

export function* getPayoutDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchPayoutDetails, action.payload);
      yield put({ type: PAYOUTSDETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchPayoutDetails, action);
      yield put({ type: PAYOUTSDETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: PAYOUTSDETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getPayoutTransactions(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(fetchPayoutTransactions, action.payload);
      yield put({ type: PAYOUTSDETAILS.TRANSACTIONS_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchPayoutTransactions, action);
      yield put({ type: PAYOUTSDETAILS.TRANSACTIONS_SUCCESS, payload: entity });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
