import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Row,
  Col,
  Table,
  Tooltip,
  Icon,
} from 'antd';
import {
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';
import RBAC from 'components/rbac';
import Loading from 'components/Loading';

import {
  advanceFeesActions,
} from 'store/actions';
import formatNumber from 'utils/formatNumber';
import {
  PAGES,
  ROLES,
} from 'appconstants';
import DeleteAdvanceFee from './components/DeleteAdvanceFee';
import AddAdvanceFees from './components/AddAdvanceFee';
import EditAdvanceFees from './components/EditAdvanceFee';

type Props = {
  loading: boolean,
  selectedAccount: Object,
  validator: boolean,
  submitting: Boolean,
  test: boolean,
  history: {
    push: Function,
  },
  fetchAdvanceFee: Function,
  advanceFees: Array<Objects>,
  totalElements: number,
  deleteAdvanceFee: Function,
  addAdvanceFee: Function,
  updateAdvanceFee: Function,
  fetchAddedItemsList: Function,
};

const AdvanceFee = (props: Props) => {
  const {
    loading,
    selectedAccount,
    validator,
    submitting,
    history,
    test,
    fetchAdvanceFee,
    advanceFees,
    totalElements,
    deleteAdvanceFee,
    addAdvanceFee,
    updateAdvanceFee,
    fetchAddedItemsList,
  } = props;

  const [pagination, setPagination] = useState({});
  const [selectedAdvanceFee, setSelectedAdvanceFee] = useState({});
  const [addAdvanceFees, setAddAdvanceFee] = useState(false);
  const [editAdvanceFees, setEditAdvanceFees] = useState(false);
  const [deleteAdvanceFeeModal, setDeleteAdvanceFeeModal] = useState(false);

  const location = useLocation();
  const currencyType = selectedAccount && selectedAccount.currency;

  const debouncedFetchCustomers = debounce(fetchAdvanceFee, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    debouncedFetchCustomers({ currentPage, sortParams, filterParam });
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setDeleteAdvanceFeeModal(false);
    }
  }, [validator]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [advanceFees]);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.ADVANCEFEES,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.ADVANCEFEES,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const showDeleteAdvanceFeeModal = (value: Object) => {
    setSelectedAdvanceFee(value);
    setDeleteAdvanceFeeModal(true);
  };

  const showEditAdvanceFee = (value: Object) => {
    setSelectedAdvanceFee(value);
    setEditAdvanceFees(true);
  };

  const deleteSelectedAdvanceFee = (gid: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    deleteAdvanceFee({
      currentPage,
      sortParams,
      gid,
    });
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <span style={{ cursor: 'pointer' }}>
          {name || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'AMOUNT/PERCENTAGE',
      dataIndex: 'value',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const total = (record.value / 100).toFixed(2);
        if (record.type === 'AMOUNT') {
          return (
            <span>
              {`${currencyType.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          );
        }
        return (
          <span>
            {`${record.value} %`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'enabled',
      width: '25%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        if (record.enabled) {
          return (
            <SpStatusTag style={{ backgroundColor: '#C9FFEF', color: '#099075' }}>
              Fee Enabled
            </SpStatusTag>
          );
        }
        return (
          <SpStatusTag style={{ backgroundColor: '#FFE9E9', color: '#EB464B' }}>
            Fee Disabled
          </SpStatusTag>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showDeleteAdvanceFeeModal(record); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            className="ml-3"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showEditAdvanceFee(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  const addNewAdvanceFees = (params) => {
    setAddAdvanceFee(false);
    addAdvanceFee(params);
  };

  const EditSelectedAdvanceFees = (params) => {
    const payload = {
      gid: params.gid,
      params: {
        ADDED: params.params.itemGids,
        REMOVED: params.params.REMOVED,
      },
    };
    setEditAdvanceFees(false);
    updateAdvanceFee(params);
    fetchAddedItemsList(payload);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      {
        addAdvanceFees ? (
          <>
            <AddAdvanceFees
              currencyType={currencyType}
              close={() => setAddAdvanceFee(false)}
              submit={addNewAdvanceFees}
              selectedAccount={selectedAccount}
            />
          </>
        ) : (
          <>
            {
            editAdvanceFees ? (
              <>
                <EditAdvanceFees
                  currencyType={currencyType}
                  close={() => setEditAdvanceFees(false)}
                  submit={EditSelectedAdvanceFees}
                  selectedAccount={selectedAccount}
                  selectedAdvanceFee={selectedAdvanceFee}
                />
              </>
            ) : (
              <>
                <Row className="my-2">
                  <div className="d-flex justify-content-end">
                    <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                      <Col>
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-3"
                          ghost
                          onClick={() => setAddAdvanceFee(true)}
                        >
                          {'\u002B'}&nbsp;Add Fee
                        </SpButton>
                      </Col>
                    </RBAC>
                  </div>
                </Row>
                {
                deleteAdvanceFeeModal && (
                  <DeleteAdvanceFee
                    visible={deleteAdvanceFeeModal}
                    selectedAdvanceFee={selectedAdvanceFee}
                    submitting={submitting}
                    close={() => setDeleteAdvanceFeeModal(false)}
                    submit={deleteSelectedAdvanceFee}
                  />
                )
              }
                <RBAC
                  fallback={(
                    <Table
                      columns={columns}
                      rowKey={(record) => record.gid}
                      dataSource={advanceFees}
                      pagination={pagination}
                      onChange={handleTableChange}
                    />
                )}
                  permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
                >
                  <Table
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={[...columns, actionColumn]}
                    rowKey={(record) => record.gid}
                    dataSource={advanceFees}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </RBAC>
              </>
            )
          }
          </>
        )
      }
    </Card>
  );
};

const mapStateToProps = state => ({
  loading: state.loading.loading,
  validator: state.loading.validator,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  test: state.account.test,
  advanceFees: state.advanceFees.content,
  totalElements: state.advanceFees.totalElements,
});

const mapDispatchToProps = dispatch => ({
  fetchAdvanceFee: param => dispatch(advanceFeesActions.fetchAdvanceFee({
    payload: param,
  })),
  addAdvanceFee: param => dispatch(advanceFeesActions.addAdvanceFee({
    payload: param,
  })),
  updateAdvanceFee: param => dispatch(advanceFeesActions.updateAdvanceFee({
    payload: param,
  })),
  deleteAdvanceFee: param => dispatch(advanceFeesActions.deleteAdvanceFee({
    payload: param,
  })),
  fetchAddedItemsList: param => dispatch(advanceFeesActions.fetchAddedItemsList({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvanceFee);
