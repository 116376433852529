import React, { useState, useRef, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import {
  Row,
  Col,
  Input,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpButton,
  SpForm,
  SpError,
  SpText,
} from 'components/DesignKit';

import {
  COUNTRY_CODES,
} from 'appconstants';

const { Option } = Select;

type Props = {
  userProfile: Object,
  updateProfile: Function,
  setEdit: Function,
};

const Edit = (props: Props) => {
  const {
    givenName,
    familyName,
    email,
    phoneNumber,
  } = props.userProfile;

  const { userProfile } = props;

  const [, forceUpdate] = useState();
  const [userGivenName, setUserGivenName] = useState(givenName);
  const [userFamilyName, setUserFamilyName] = useState(familyName);
  const [accountPhone, setAccountPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneFormate, setPhoneFormate] = useState('');

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    if (userProfile && userProfile.phoneNumber) {
      const cntryCode = phoneNumber.substring(0, 2);
      if (cntryCode === '+1') {
        setPhoneFormate('(###) ###-####');
        setCountryCode(phoneNumber.substring(0, 2));
        setAccountPhone(phoneNumber.slice(2));
      } else if (cntryCode === '+9') {
        setPhoneFormate('##### #####');
        setCountryCode(phoneNumber.substring(0, 3));
        setAccountPhone(phoneNumber.slice(3));
      }
    }
  }, [userProfile]);

  const onCountryCodeChange = (value) => {
    if (value === '+1') {
      setPhoneFormate('(###) ###-####');
      setCountryCode(value);
    } else if (value === '+91') {
      setPhoneFormate('##### #####');
      setCountryCode(value);
    }
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setAccountPhone(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const updateProfile = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    props.updateProfile({
      givenName: userGivenName,
      familyName: userFamilyName,
      phoneNumber: `${countryCode}${accountPhone}`,
    });
  };

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Given Name</SpText>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={userGivenName}
              onChange={e => setUserGivenName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Given Name', userGivenName, 'required')}
            </SpError>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Family Name</SpText>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={userFamilyName}
              onChange={e => setUserFamilyName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Family Name', userFamilyName, 'required')}
            </SpError>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Email</SpText>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={email}
              disabled
            />
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Phone</SpText>
          </Col>
          <Col span={6}>
            <div className="d-flex">
              <Select
                value={countryCode}
                onChange={onCountryCodeChange}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.PHONE_CODE}>
                    {item.PHONE_CODE}
                  </Option>
                ))}
              </Select>
              <div className="w-100">
                <NumberFormat
                  className="InputnumFormate"
                  value={accountPhone}
                  format={phoneFormate}
                  mask="_"
                  allowEmptyFormatting
                  onChange={onPhoneNumberChange}
                />
                <SpError>
                  {simpleValidator.current.message('Phone', accountPhone, 'required|phone')}
                </SpError>
              </div>
            </div>
          </Col>
        </Row>
      </SpForm>
      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="secondary"
            shape="round"
            onClick={() => props.setEdit(false)}
          >
            Cancel
          </SpButton>
        </Col>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={updateProfile}
          >
            Save
          </SpButton>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Edit);
