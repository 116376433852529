export default status => {
  if (status === 'PENDING') {
    return '#e2e2e2';
  } if (status === 'ACCOUNT_CREATED') {
    return '#e2e2e2';
  } if (status === 'ACCOUNT_VERIFIED') {
    return '#ececff';
  } if (status === 'ACCOUNT_LIVE') {
    return '#ffe0b1';
  } return '#e2e2e2';
};
