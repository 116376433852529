// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Icon,
  Tooltip,
  Badge,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getFilterOperators from 'utils/getFilterOperators';

import {
  beneficiaryActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
} from 'appconstants';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import getStateList from 'utils/stateList';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddBeneficiary from './components/AddBeneficiary';
import EditBeneficiary from './components/EditBeneficiary';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const BENEFICIARY_FILTERS_TYPES = FILTERS_TYPES.BENEFICIARY;

type Props = {
  beneficiary: Array<Object>,
  fetchBeneficiary: Function,
  addBeneficiary: Function,
  updateBeneficiary: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  setFilterData: Function,
  preserveFilters: Object,
  isMobileView: Boolean,
};

const Beneficiary = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    beneficiary,
    loading,
    addBeneficiary,
    updateBeneficiary,
    fetchBeneficiary,
    totalElements,
    submitting,
    validator,
    test,
    history,
    setFilterData,
    preserveFilters,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const [pagination, setPagination] = useState({});
  const [addBeneficiaryModal, setAddBeneficiaryModal] = useState(false);
  const [editBeneficiaryModal, setEditBeneficiaryModal] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [phoneCode, setPhoneCode] = useState('');
  const [id, setId] = useState('');
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [zipTag, setZipTag] = useState('');
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(BENEFICIARY_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterPayments, setFilterPayments] = useState((preserveFilters && preserveFilters.filterPayments)
    ? (preserveFilters && preserveFilters.filterPayments)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterPayments: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });

  const exportData = beneficiary ? beneficiary.map((values) => ({
    name: values.name,
    email: (values.email),
    alias: (values.beneficiaryWalletAlias),
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
  })) : '';

  const exportHeaders = [
    { label: 'NAME', key: 'name' },
    { label: 'EMAIL', key: 'email' },
    { label: 'ALIAS', key: 'alias' },
    { label: 'CREATED ON', key: 'createdAt' },
  ];

  const debouncedFetchBeneficiary = debounce(fetchBeneficiary, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchBeneficiary({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData(location.search);
    if (selectedAccount && selectedAccount.country) {
      setId(selectedAccount.currency.id);
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
      setstatesInCountry(getStateList(selectedAccount.country.id));
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setAddBeneficiaryModal(false);
      setEditBeneficiaryModal(false);
    }
  }, [validator]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [beneficiary]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addNewBeneficiary = (Info: Object) => {
    const {
      name,
      email,
      phoneNumber,
      countryCode,
      streetName,
      cityName,
      stateName,
      zipCode,
      term,
      walletAlias,
    } = Info;
    addBeneficiary({
      name,
      email,
      phoneNumber: phoneNumber ? `${countryCode}${phoneNumber}` : null,
      paymentTermsDays: Number(term),
      beneficiaryWalletAlias: walletAlias || null,
      address: {
        street: streetName,
        city: cityName,
        state: stateName,
        postalCode: zipCode,
        countryCode: 'US',
      },
    });
  };

  const editSelectedBeneficiary = (benef: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      name,
      email,
      phoneNumber,
      countryCode,
      streetName,
      cityName,
      stateName,
      zipCode,
      term,
      walletAlias,
      gid,
    } = benef;

    const params = {
      name,
      email,
      phoneNumber: phoneNumber ? `${countryCode}${phoneNumber}` : null,
      paymentTermsDays: Number(term),
      beneficiaryWalletAlias: walletAlias || null,
      address: {
        street: streetName,
        city: cityName,
        state: stateName,
        postalCode: zipCode,
        countryCode: 'US',
      },
    };
    updateBeneficiary({
      currentPage,
      sortParams,
      gid,
      params,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.BENEFICIARY,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.BENEFICIARY,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const selectedRecord = (record) => {
    const beneGid = record.gid;
    history.push({
      pathname: `${PAGES.BENEFICIARY}/${beneGid}`,
      state: location.search,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterPayments];
    if (filterPayments.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = BENEFICIARY_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterPayments(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterPayments];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = BENEFICIARY_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterPayments(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterPayments(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterPayments(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterPayments[index].filterName === 'createdAt') {
      if (filterPayments[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterPayments[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterPayments[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterPayments[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterPayments];
    list[index].filterValue = selectedValue;
    setFilterPayments(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterPayments];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterPayments[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterPayments(list);
  };

  const handleAddClick = () => {
    const list = [...filterPayments];
    setFilterPayments([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterPayments([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(BENEFICIARY_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.BENEFICIARY);
  };

  const applyFilters = () => {
    const paymentsFilters = filterPayments.filter((item) => item.checked);
    for (let i = 0; i < paymentsFilters.length; i += 1) {
      if (!paymentsFilters[i].filterName || !paymentsFilters[i].filterOperator || !paymentsFilters[i].filterValue) {
        return;
      }
    }
    if (paymentsFilters[0].filterValue || (paymentsFilters[0].filterValueFrom && paymentsFilters[0].filterValueTo)) {
      setCount(paymentsFilters && paymentsFilters.length);
      for (let i = 0; i < paymentsFilters.length; i += 1) {
        if (paymentsFilters[i].checked) {
          if (paymentsFilters[i].filterName === 'createdAt') {
            paymentsFilters[i] = paymentsFilters[i].filterValue;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else {
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${paymentsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(paymentsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.BENEFICIARY,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.BENEFICIARY,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const showEditBeneficiaryModal = (value: Object) => {
    setSelectedBeneficiary(value);
    setEditBeneficiaryModal(true);
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (email) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {email || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ALIAS',
      dataIndex: 'beneficiaryWalletAlias',
      width: '20%',
      align: 'left',
      render: (beneficiaryWalletAlias) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {beneficiaryWalletAlias || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            className="ml-3"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showEditBeneficiaryModal(record); }}
          />
        </Tooltip>
      </span>
    ),
  };


  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                ghost
                className="mr-3"
                onClick={() => setAddBeneficiaryModal(true)}
              >
                {'\u002B'}&nbsp;New Beneficiary
              </SpButton>
              <span>
                {
                exportData !== null
                  ? (
                    <Tooltip placement="left" title="Click here to export Beneficiary">
                      <CSVLink
                        target="_blank"
                        className="mr-3"
                        filename="Beneficiary Report.csv"
                        data={exportData}
                        headers={exportHeaders}
                        onMouseEnter={() => setFillColor(true)}
                        onMouseLeave={() => setFillColor(false)}
                      >
                        <ExportIcon
                          fill={fillColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </CSVLink>
                    </Tooltip>
                  ) : null
              }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                  )}
                  {!open && (
                  <Tooltip placement="left" title="Click here to filter Beneficiary">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                  )}
                </span>
                {open && (
                <FilterWrapper
                  style={{ marginLeft: '-63px' }}
                >
                  <Filters
                    filtersGrid={filterPayments}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                    handleFromValue={handleFromValue}
                    handleToValue={handleToValue}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterPayments.length < BENEFICIARY_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD BENEFICIARY MODAL */}
      {addBeneficiaryModal && (
      <AddBeneficiary
        visible={addBeneficiaryModal}
        submitting={submitting}
        phoneCode={phoneCode}
        id={id}
        country={selectedAccount.country.name}
        cntryId={selectedAccount.country.id}
        statesInCountry={statesInCountry}
        zipTag={zipTag}
        close={() => setAddBeneficiaryModal(false)}
        submit={addNewBeneficiary}
      />
      )}
      {/* EDIT BENEFICIARY MODAL */}
      {editBeneficiaryModal && (
      <EditBeneficiary
        visible={editBeneficiaryModal}
        submitting={submitting}
        phoneCode={phoneCode}
        id={id}
        country={selectedAccount.country.name}
        cntryId={selectedAccount.country.id}
        statesInCountry={statesInCountry}
        selectedBeneficiary={selectedBeneficiary}
        zipTag={zipTag}
        close={() => setEditBeneficiaryModal(false)}
        submit={editSelectedBeneficiary}
      />
      )}
      <RBAC
        fallback={(
          <Table
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={beneficiary}
            pagination={pagination}
            onChange={handleTableChange}
          />
      )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
      >
        <Table
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={beneficiary}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  beneficiary: state.beneficiary.content,
  totalElements: state.beneficiary.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBeneficiary: param => dispatch(beneficiaryActions.fetchBeneficiary({
    payload: param,
  })),
  addBeneficiary: param => dispatch(beneficiaryActions.addBeneficiary({
    payload: param,
  })),
  updateBeneficiary: param => dispatch(beneficiaryActions.updateBeneficiary({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Beneficiary);
