export default status => {
  if (status === 'TO_ACTIVATE') {
    return '#ffe0b1';
  } if (status === 'DISABLED') {
    return '#e2e2e2';
  } if (status === 'BANK_VERIFICATION_PENDING') {
    return '#ffe0b1';
  } if (status === 'VERIFICATION_PENDING') {
    return '#ffe0b1';
  } if (status === 'VERIFIED') {
    return '#ececff';
  } return '#e2e2e2';
};
