/* eslint-disable */
import React from 'react';

const SponserICICI = () => (
    <svg width="250" height="48" viewBox="0 0 488 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3186 40.9291C12.3186 42.8958 11.6453 44.4107 10.2987 45.4738C8.96099 46.528 7.04299 47.0552 4.54472 47.0552H2.25907V54.6961H0V35.2681H5.03641C9.8912 35.2681 12.3186 37.1551 12.3186 40.9291ZM2.25907 45.115H4.29224C6.2944 45.115 7.74286 44.7917 8.63763 44.1449C9.5324 43.4982 9.97979 42.4617 9.97979 41.0354C9.97979 39.7508 9.55898 38.794 8.71737 38.165C7.87575 37.536 6.5646 37.2216 4.78392 37.2216H2.25907V45.115Z" fill="#292929" />
        <path d="M25.0226 54.6961L24.584 52.6231H24.4777C23.7513 53.5356 23.0248 54.1557 22.2984 54.4835C21.5808 54.8025 20.6816 54.9619 19.6008 54.9619C18.1567 54.9619 17.0228 54.5898 16.1989 53.8457C15.3838 53.1015 14.9763 52.0428 14.9763 50.6697C14.9763 47.7285 17.3284 46.187 22.0326 46.0452L24.5043 45.9655V45.0619C24.5043 43.919 24.2562 43.0774 23.7601 42.537C23.2729 41.9878 22.4889 41.7131 21.408 41.7131C20.1943 41.7131 18.8212 42.0852 17.2886 42.8294L16.6108 41.1417C17.3284 40.7519 18.1125 40.4463 18.9629 40.2248C19.8223 40.0033 20.6816 39.8926 21.5409 39.8926C23.2773 39.8926 24.5619 40.2779 25.3946 41.0487C26.2363 41.8194 26.6571 43.0553 26.6571 44.7562V54.6961H25.0226ZM20.0393 53.1414C21.4125 53.1414 22.4889 52.7649 23.2685 52.0118C24.0569 51.2588 24.4511 50.2046 24.4511 48.8491V47.5336L22.2452 47.6266C20.4911 47.6886 19.2243 47.9632 18.4447 48.4505C17.6739 48.9289 17.2886 49.6775 17.2886 50.6963C17.2886 51.4936 17.5278 52.1004 18.0061 52.5168C18.4934 52.9332 19.1711 53.1414 20.0393 53.1414Z" fill="#292929" />
        <path d="M37.8594 39.866C38.5061 39.866 39.0864 39.9192 39.6002 40.0255L39.2946 42.0719C38.6922 41.939 38.1606 41.8726 37.7 41.8726C36.5217 41.8726 35.5118 42.351 34.6701 43.3078C33.8374 44.2645 33.421 45.4561 33.421 46.8824V54.6961H31.2151V40.1318H33.0356L33.2881 42.8294H33.3944C33.9348 41.8814 34.586 41.1506 35.3479 40.6367C36.1097 40.1229 36.9469 39.866 37.8594 39.866Z" fill="#292929" />
        <path d="M47.0286 53.1414C47.4184 53.1414 47.7949 53.1148 48.1581 53.0616C48.5214 52.9996 48.8093 52.9376 49.0219 52.8756V54.5633C48.7827 54.6784 48.4283 54.7715 47.9588 54.8423C47.4981 54.9221 47.0818 54.9619 46.7097 54.9619C43.8925 54.9619 42.4839 53.478 42.4839 50.5102V41.846H40.3976V40.7829L42.4839 39.866L43.4141 36.7564H44.6898V40.1318H48.9156V41.846H44.6898V50.4172C44.6898 51.2943 44.898 51.9675 45.3144 52.4371C45.7307 52.9066 46.3021 53.1414 47.0286 53.1414Z" fill="#292929" />
        <path d="M61.9119 54.6961V45.2745C61.9119 44.0874 61.6417 43.2015 61.1013 42.6168C60.5609 42.032 59.7148 41.7397 58.5632 41.7397C57.0394 41.7397 55.9231 42.1516 55.2144 42.9755C54.5057 43.7994 54.1513 45.1593 54.1513 47.0552V54.6961H51.9454V40.1318H53.7394L54.0982 42.1251H54.2045C54.6563 41.4075 55.2897 40.8538 56.1048 40.464C56.9198 40.0653 57.8279 39.866 58.8289 39.866C60.583 39.866 61.903 40.2912 62.789 41.1417C63.6749 41.9833 64.1178 43.3343 64.1178 45.1948V54.6961H61.9119Z" fill="#292929" />
        <path d="M74.8152 54.9619C72.6624 54.9619 70.9615 54.3063 69.7124 52.9952C68.4721 51.6841 67.8519 49.8635 67.8519 47.5336C67.8519 45.1859 68.4278 43.321 69.5795 41.939C70.74 40.557 72.2948 39.866 74.2438 39.866C76.0688 39.866 77.5128 40.4684 78.5759 41.6733C79.639 42.8692 80.1705 44.4506 80.1705 46.4173V47.8126H70.1376C70.1819 49.5224 70.6116 50.8203 71.4266 51.7062C72.2505 52.5921 73.4066 53.0351 74.8949 53.0351C76.463 53.0351 78.0133 52.7073 79.546 52.0517V54.0184C78.7664 54.3551 78.0266 54.5943 77.3268 54.736C76.6357 54.8866 75.7986 54.9619 74.8152 54.9619ZM74.2172 41.7131C73.0478 41.7131 72.1132 42.0941 71.4133 42.8559C70.7223 43.6178 70.3148 44.6721 70.1907 46.0186H77.8052C77.8052 44.6278 77.4951 43.5647 76.8749 42.8294C76.2548 42.0852 75.3689 41.7131 74.2172 41.7131Z" fill="#292929" />
        <path d="M90.5888 39.866C91.2356 39.866 91.8158 39.9192 92.3297 40.0255L92.024 42.0719C91.4216 41.939 90.8901 41.8726 90.4294 41.8726C89.2511 41.8726 88.2412 42.351 87.3996 43.3078C86.5668 44.2645 86.1504 45.4561 86.1504 46.8824V54.6961H83.9445V40.1318H85.7651L86.0175 42.8294H86.1239C86.6643 41.8814 87.3154 41.1506 88.0773 40.6367C88.8392 40.1229 89.6764 39.866 90.5888 39.866Z" fill="#292929" />
        <path d="M101.206 54.9619C99.0537 54.9619 97.3528 54.3063 96.1036 52.9952C94.8634 51.6841 94.2432 49.8635 94.2432 47.5336C94.2432 45.1859 94.8191 43.321 95.9708 41.939C97.1313 40.557 98.6861 39.866 100.635 39.866C102.46 39.866 103.904 40.4684 104.967 41.6733C106.03 42.8692 106.562 44.4506 106.562 46.4173V47.8126H96.5289C96.5732 49.5224 97.0028 50.8203 97.8179 51.7062C98.6418 52.5921 99.7979 53.0351 101.286 53.0351C102.854 53.0351 104.405 52.7073 105.937 52.0517V54.0184C105.158 54.3551 104.418 54.5943 103.718 54.736C103.027 54.8866 102.19 54.9619 101.206 54.9619ZM100.609 41.7131C99.4391 41.7131 98.5045 42.0941 97.8046 42.8559C97.1136 43.6178 96.7061 44.6721 96.582 46.0186H104.196C104.196 44.6278 103.886 43.5647 103.266 42.8294C102.646 42.0852 101.76 41.7131 100.609 41.7131Z" fill="#292929" />
        <path d="M120.249 52.7427H120.13C119.111 54.2222 117.587 54.9619 115.558 54.9619C113.654 54.9619 112.17 54.3108 111.107 53.0085C110.052 51.7062 109.525 49.8546 109.525 47.4538C109.525 45.053 110.057 43.1882 111.12 41.8593C112.183 40.5304 113.662 39.866 115.558 39.866C117.534 39.866 119.049 40.5836 120.103 42.0188H120.276L120.183 40.969L120.13 39.9457V34.019H122.335V54.6961H120.542L120.249 52.7427ZM115.837 53.1148C117.343 53.1148 118.433 52.7073 119.106 51.8922C119.788 51.0683 120.13 49.7439 120.13 47.9189V47.4538C120.13 45.3897 119.784 43.919 119.093 43.042C118.411 42.1561 117.317 41.7131 115.811 41.7131C114.517 41.7131 113.525 42.2181 112.834 43.228C112.152 44.2291 111.811 45.6466 111.811 47.4804C111.811 49.3408 112.152 50.745 112.834 51.6929C113.516 52.6408 114.517 53.1148 115.837 53.1148Z" fill="#292929" />
        <path d="M145.989 54.6961L143.318 46.1515C143.15 45.6288 142.835 44.4417 142.375 42.5902H142.268C141.914 44.1405 141.604 45.3365 141.338 46.1781L138.588 54.6961H136.036L132.063 40.1318H134.375C135.314 43.7906 136.027 46.5768 136.514 48.4903C137.011 50.4039 137.294 51.6929 137.365 52.3573H137.471C137.569 51.8524 137.724 51.2012 137.936 50.4039C138.158 49.5977 138.348 48.9599 138.508 48.4903L141.179 40.1318H143.571L146.175 48.4903C146.671 50.0141 147.008 51.2943 147.185 52.3308H147.292C147.327 52.0118 147.42 51.5202 147.571 50.8557C147.73 50.1913 148.656 46.6166 150.348 40.1318H152.634L148.607 54.6961H145.989Z" fill="#292929" />
        <path d="M157.484 54.6961H155.278V40.1318H157.484V54.6961ZM155.092 36.185C155.092 35.6801 155.216 35.3124 155.464 35.0821C155.712 34.8429 156.022 34.7233 156.394 34.7233C156.749 34.7233 157.054 34.8429 157.311 35.0821C157.568 35.3213 157.697 35.6889 157.697 36.185C157.697 36.6811 157.568 37.0532 157.311 37.3013C157.054 37.5405 156.749 37.6601 156.394 37.6601C156.022 37.6601 155.712 37.5405 155.464 37.3013C155.216 37.0532 155.092 36.6811 155.092 36.185Z" fill="#292929" />
        <path d="M166.866 53.1414C167.256 53.1414 167.632 53.1148 167.995 53.0616C168.359 52.9996 168.646 52.9376 168.859 52.8756V54.5633C168.62 54.6784 168.266 54.7715 167.796 54.8423C167.335 54.9221 166.919 54.9619 166.547 54.9619C163.73 54.9619 162.321 53.478 162.321 50.5102V41.846H160.235V40.7829L162.321 39.866L163.251 36.7564H164.527V40.1318H168.753V41.846H164.527V50.4172C164.527 51.2943 164.735 51.9675 165.152 52.4371C165.568 52.9066 166.139 53.1414 166.866 53.1414Z" fill="#292929" />
        <path d="M181.749 54.6961V45.2745C181.749 44.0874 181.479 43.2015 180.939 42.6168C180.398 42.032 179.552 41.7397 178.4 41.7397C176.868 41.7397 175.747 42.1561 175.038 42.9888C174.338 43.8216 173.989 45.1859 173.989 47.0817V54.6961H171.783V34.019H173.989V40.2779C173.989 41.031 173.953 41.6555 173.882 42.1516H174.015C174.449 41.4518 175.065 40.9025 175.862 40.5039C176.668 40.0963 177.585 39.8926 178.613 39.8926C180.394 39.8926 181.727 40.3178 182.613 41.1683C183.508 42.0099 183.955 43.3521 183.955 45.1948V54.6961H181.749Z" fill="#292929" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M248.298 39.3518C245.092 43.2865 241.529 46.5744 237.887 49.1103C237.864 49.1164 237.838 49.1249 237.809 49.136C236.108 49.7738 235.328 49.5613 234.761 48.64C234.194 47.7186 234.159 45.7342 234.584 42.8993C235.271 38.3159 236.782 33.413 238.482 28.1577C238.86 26.9883 239.285 25.8773 239.679 24.845L239.679 24.8449C240.909 21.6228 241.847 19.1671 240.183 18.0936C237.986 16.6761 235.232 17.3731 233.167 18.6608C230.059 20.5985 227.532 22.2753 223.563 25.7479C221.157 27.853 221.756 29.1498 222.571 29.1498C223.176 29.1498 223.799 28.8247 224.481 28.4688C225.078 28.1576 225.72 27.8228 226.434 27.6617C227.963 27.3163 230.296 27.1656 229.942 30.8864C229.911 32.5768 229.296 36.42 228.595 39.2848C227.218 44.9121 225.973 48.073 224.237 51.156C222.696 53.8906 221.173 54.7538 219.961 55C217.527 54.7358 215.34 53.8949 213.537 52.4258C205.533 45.9055 208.12 29.4804 219.315 15.7395C230.509 1.99856 246.072 -3.85487 254.076 2.66549C262.079 9.18586 259.493 25.6109 248.298 39.3518ZM236.757 14.1328C238.159 15.6611 241.425 14.9464 244.052 12.5365C246.679 10.1266 247.672 6.93403 246.269 5.40573C244.867 3.87743 241.601 4.59213 238.974 7.00205C236.348 9.41198 235.355 12.6045 236.757 14.1328Z" fill="#F06321" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M248.685 34.8184C244.292 39.6298 239.229 43.1093 234.341 45.012C234.387 44.373 234.469 43.6673 234.584 42.8992C235.271 38.3159 236.782 33.4129 238.482 28.1577C238.86 26.9883 239.285 25.8773 239.679 24.845L239.679 24.8449C240.909 21.6228 241.847 19.1671 240.183 18.0936C237.986 16.6761 235.232 17.3731 233.166 18.6608C230.058 20.5984 227.532 22.2753 223.563 25.7479C221.157 27.853 221.756 29.1498 222.571 29.1498C223.176 29.1498 223.799 28.8247 224.481 28.4688C225.078 28.1576 225.72 27.8228 226.434 27.6617C227.963 27.3163 230.296 27.1656 229.942 30.8864C229.911 32.5768 229.296 36.42 228.595 39.2848C227.843 42.3562 227.131 44.6929 226.357 46.6831C223.026 46.7113 220.069 45.7637 217.847 43.7355C211.011 37.494 213.734 23.3822 223.929 12.216C234.124 1.04974 247.93 -2.94257 254.766 3.29892C261.603 9.5404 258.88 23.6522 248.685 34.8184ZM236.757 14.1328C238.159 15.6611 241.425 14.9464 244.052 12.5365C246.679 10.1266 247.672 6.93403 246.269 5.40573C244.867 3.87743 241.601 4.59213 238.974 7.00205C236.348 9.41197 235.355 12.6045 236.757 14.1328Z" fill="#AE282E" />
        <path d="M260.054 54.4415L267.566 26.801H276.709L269.409 54.4415H260.054Z" fill="#004A7F" />
        <path d="M308.247 54.4415L315.76 26.801H324.902L317.602 54.4415H308.247Z" fill="#004A7F" />
        <path d="M356.512 54.4415L364.024 26.801H373.167L365.867 54.4415H356.512Z" fill="#004A7F" />
        <path d="M308.956 36.3687H299.53C299.53 32.5417 297.616 32.5415 295.207 32.4707C292.797 32.3998 288.474 34.1364 286.631 39.2036C284.823 44.1745 284.859 48.4879 290.529 48.9841C295.065 49.3809 297.475 46.031 298.112 44.3064H307.397C304.42 55.7878 287.836 55.5752 281.457 53.2364C275.079 50.8976 274.724 42.3929 278.622 36.3687C282.52 30.3446 287.615 27.2298 296.199 27.0135C304.633 26.801 306.958 29.1357 307.964 30.309C309.584 32.2002 309.192 35.0458 308.956 36.3687Z" fill="#004A7F" />
        <path d="M357.199 36.3687H347.773C347.773 32.5417 345.859 32.5415 343.449 32.4707C341.04 32.3998 336.717 34.1364 334.874 39.2036C333.066 44.1745 333.102 48.4879 338.772 48.9841C343.308 49.3809 345.717 46.031 346.355 44.3064H355.64C352.663 55.7878 336.079 55.5752 329.7 53.2364C323.322 50.8976 322.967 42.3929 326.865 36.3687C330.763 30.3446 335.858 27.2298 344.442 27.0135C352.876 26.801 355.201 29.1357 356.207 30.309C357.827 32.2002 357.435 35.0458 357.199 36.3687Z" fill="#004A7F" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M387.696 27.0845L380.467 54.4415H397.476C402.296 54.4415 406.69 51.2522 407.824 47.9212C408.958 44.5901 409.241 40.6921 403.359 39.9125C405.438 39.6527 409.851 37.9706 410.871 33.3213C412.147 27.5098 406.123 27.0845 405.06 27.0845H387.696ZM390.743 37.9281L392.657 31.1243H398.965C401.729 31.1243 404.847 31.4786 404.138 34.3136C403.43 37.1485 400.736 37.9281 397.901 37.9281H390.743ZM387.412 50.1182L389.574 42.145H395.882C398.646 42.145 402.331 42.2513 401.906 45.4406C401.481 48.6299 399.39 50.1182 394.57 50.1182H387.412Z" fill="#004A7F" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M413.387 40.5503H418.951C419.175 39.7117 420.411 38.0344 422.99 38.0344C426.215 38.0344 427.774 38.3533 426.782 42.3576H420.014C416.647 42.3576 410.552 43.7396 409.489 48.9842C408.426 54.2288 413.635 55.1856 416.825 54.973C420.014 54.7604 422.955 53.1657 424.231 51.89L423.77 54.5123H429.334C429.617 53.1303 430.347 49.7851 430.999 47.4605C431.462 45.8113 431.787 44.5502 432.028 43.6189C432.211 42.9093 432.345 42.3911 432.452 42.0387C432.766 41.0065 434.366 34.7388 424.231 34.7388C417.285 34.7388 413.387 38.8966 413.387 40.5503ZM425.861 45.6887H420.049C418.384 45.6887 415.691 46.6809 415.372 48.8071C415.053 50.9332 416.045 51.9255 419.695 51.5357C423.345 51.1459 425.577 47.9212 425.861 45.6887Z" fill="#004A7F" />
        <path d="M438.073 40.3939C438.512 38.6722 438.875 36.3289 439.001 35.3724H444.614L444.023 38.6638C445.88 36.5962 449.244 34.7817 452.969 34.7817C460.564 34.7816 460.395 39.9298 459.762 42.5882C459.256 44.715 457.442 51.4356 456.598 54.5301H450.943C451.576 52.1248 452.977 46.8248 453.517 44.8669C454.192 42.4194 454.825 38.706 450.479 38.6638C446.133 38.6216 443.348 41.7443 442.546 44.8669C441.905 47.365 440.591 52.3499 440.014 54.5301H434.36C435.415 50.5354 437.634 42.1156 438.073 40.3939Z" fill="#004A7F" />
        <path d="M461.619 54.488L468.919 27.0596H474.489L470.312 42.8837L480.735 35.3725H487.908L476.557 43.6432L484.954 54.488H477.317L469.848 44.3184L467.105 54.488H461.619Z" fill="#004A7F" />
    </svg>
);

export default SponserICICI;
