// @flow
import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import Vendorinvoices from './components';
import NewInvoice from './components/NewInvoice';

const VendorInvoice = () => (
  <Switch>
    <Route path="/vendorinvoice" component={Vendorinvoices} />
    <Route path="/vendorinvoice/add/new" component={NewInvoice} />
  </Switch>
);
export default VendorInvoice;
