import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Icon,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import RBAC from 'components/rbac';
import getFilterOperators from 'utils/getFilterOperators';
import moment from 'moment-timezone';

import {
  customDocumentRequestActions,
  documentsActions,
  notificationActions,
  filterParamsActions,
} from 'store/actions';
import { NOTIFICATION, FILTERS_SPECS } from 'store/actionTypes';

import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
  MESSAGES,
  CUSTOMDOCUMENT_CONSTANTS,
} from 'appconstants';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';


import getCustomDocumentBackground from 'utils/getCustomDocumentBackground';
import getCustomDocumentStatus from 'utils/getCustomDocumentStatus';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import Download from 'components/NavIcons/downloadProof';
import DisableDownload from 'components/NavIcons/downloadDisable';
import AddNewDocument from './components/AddNewDocument';
import AddNewCustomDocument from './components/AddNewCustomDocument';
import UploadProof from './components/UploadProof';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const DOCUMENT_FILTERS_TYPES = FILTERS_TYPES.CUSTOMDOCUMENTS;

type Props = {
  documents: Array<Object>,
  customDocuments: Array<Object>,
  user: Array<Object>,
  history: {
    push: Function,
  },
  fetchCustomDocumentRequest: Function,
  addCustomDocumentRequest: Function,
  updateCustomDocumentRequest: Function,
  fetchDocuments: Function,
  addDocument: Function,
  selectedAccount: Object,
  totalElements: number,
  totalDocumentElements: number,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  test: boolean,
  setNotification: Function,
  setFilterData: Function,
  preserveFilters: Object,
  isMobileView: Boolean,
};

const CustomDocuments = (props: Props) => {
  const {
    selectedAccount,
    documents,
    customDocuments,
    loading,
    addCustomDocumentRequest,
    updateCustomDocumentRequest,
    fetchCustomDocumentRequest,
    fetchDocuments,
    addDocument,
    totalElements,
    totalDocumentElements,
    submitting,
    validator,
    user,
    test,
    history,
    setNotification,
    setFilterData,
    preserveFilters,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [addDocumentModal, setAddDocumentModal] = useState(false);
  const [addCustomDocumentModal, setAddCustomDocumentModal] = useState(false);
  const [uploadCustomDocumentModal, setUploadCustomDocumentModal] = useState(false);
  const [uploadCustomDocument, setUploadCustomDocument] = useState(false);
  const [filterTypes, setFilterTypes] = useState(DOCUMENT_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterTeams, setFilterTeams] = useState((preserveFilters && preserveFilters.filterTeams)
    ? (preserveFilters && preserveFilters.filterTeams)
    : [{
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const marginStyle = '-304px';
  const { internalRole } = user;
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterTeams: [{
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });

  const exportData = documents ? documents.map((values) => ({
    type: values.type,
    name: values.name,
    status: getCustomDocumentStatus(values.status),
  })) : '';

  const exportHeaders = [
    { label: 'TYPE', key: 'type' },
    { label: 'NAME', key: 'name' },
    { label: 'STATUS', key: 'status' },
  ];

  const debouncedFetchDocuments = debounce(fetchCustomDocumentRequest, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'updatedAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    const customsortParams = '&sortBy=updatedAt&direction=DESC';
    debouncedFetchDocuments({ currentPage, sortParams, filterParams });
    fetchDocuments({ customsortParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [test, selectedAccount, location]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && (e.toElement && e.toElement.className !== 'ant-calendar-date')
      && (e.toElement && e.toElement.className !== 'ant-calendar-prev-month-btn')
      && (e.toElement && e.toElement.className !== 'ant-calendar-next-month-btn')) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (!validator) {
      setAddDocumentModal(false);
      setAddCustomDocumentModal(false);
      setUploadCustomDocumentModal(false);
    }
  }, [validator]);

  const selectedRecord = (record) => {
    const customDocumentGid = record.gid;
    history.push({
      pathname: `${PAGES.CUSTOM_DOCUMENT_DETAILS}/${customDocumentGid}`,
      state: location.search,
    });
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      current: parseInt(currentPage, 10),
      total: totalElements,
      showTotal: total => `Total ${total} items`,
    }));
  }, [documents]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      current: parseInt(currentPage, 10),
      total: totalDocumentElements,
      showTotal: total => `Total ${total} items`,
    }));
  }, [customDocuments]);

  // eslint-disable-next-line no-shadow
  const createDocument = (document: Object) => {
    const {
      name,
      description,
      documentUrl,
      type,
    } = document;
    addCustomDocumentRequest({
      name,
      type,
      description,
      documentUrl,
    });
  };

  // eslint-disable-next-line no-shadow
  const createCustomDocument = (document: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'updatedAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      name,
      description,
      documentUrl,
    } = document;

    addDocument({
      currentPage,
      sortParams,
      document: {
        name,
        description,
        documentUrl,
      },
    });
  };

  // eslint-disable-next-line no-shadow
  const updateCustomDocument = (document: Object) => {
    const {
      gid,
      documentUrl,
    } = document;

    const params = {
      responseDocumentUrl: documentUrl,
    };

    updateCustomDocumentRequest({
      gid,
      params,
    });
  };

  const showUploadProofModal = (record: Object) => {
    setUploadCustomDocumentModal(true);
    setUploadCustomDocument(record);
  };


  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    const fieldName = filters.field;

    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${fieldName}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${fieldName}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.CUSTOM_DOCUMENT,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.CUSTOM_DOCUMENT,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterTeams];
    if (filterTeams.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = DOCUMENT_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTeams(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterTeams];
    if (e[1] === 'updatedAt') {
      list[index].filterType = 'Date';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = DOCUMENT_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTeams(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterTeams[index].filterName === 'updatedAt') {
      if (filterTeams[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `updatedAt.GT=${dateStart}&updatedAt.LT=${dateEnd}`;
      } else if (filterTeams[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `updatedAt.LT=${dateStart}`;
      } else if (filterTeams[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `updatedAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterTeams];
    list[index].filterValue = selectedValue;
    setFilterTeams(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTeams];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'updatedAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'updatedAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterTeams[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterTeams(list);
  };

  const handleAddClick = () => {
    const list = [...filterTeams];
    setFilterTeams([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterTeams([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(DOCUMENT_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.CUSTOM_DOCUMENT);
  };

  const applyFilters = () => {
    const teamsFilters = filterTeams.filter((item) => item.checked);
    for (let i = 0; i < teamsFilters.length; i += 1) {
      if (!teamsFilters[i].filterName || !teamsFilters[i].filterOperator || !teamsFilters[i].filterValue) {
        return;
      }
    }
    if (teamsFilters[0].filterValue) {
      setCount(teamsFilters && teamsFilters.length);
      for (let i = 0; i < teamsFilters.length; i += 1) {
        if (teamsFilters[i].checked) {
          if (teamsFilters[i].filterName === 'updatedAt') {
            teamsFilters[i] = teamsFilters[i].filterValue;
          } else if (teamsFilters[i].filterName === 'email') {
            const email = encodeURIComponent(teamsFilters[i].filterValue);
            teamsFilters[i] = `${teamsFilters[i].filterName}.${teamsFilters[i].filterOperator}=${email}`;
          } else {
            teamsFilters[i] = `${teamsFilters[i].filterName}.${teamsFilters[i].filterOperator}=${teamsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(teamsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'updatedAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterTeams,
        });
        history.push({
          pathname: PAGES.CUSTOM_DOCUMENT,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterTeams,
        });
        history.push({
          pathname: PAGES.CUSTOM_DOCUMENT,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const downloadDocument = async (record) => {
    const documentGid = record.gid;
    const downloadPath = record.responseDocumentUrl ? 'response/download' : 'request/download';
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.CUSTOM_DOCUMENT_REQUEST}/${documentGid}/${downloadPath}`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.CUSTOM_DOCUMENT.DOWNLOAD_ERROR,
      });
    }
  };

  const downloadCustomDocument = async (record) => {
    const documentGid = record.gid;
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.CUSTOM_DOCUMENT}/${documentGid}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.CUSTOM_DOCUMENT.DOWNLOAD_ERROR,
      });
    }
  };

  const columns = [
    {
      title: 'TYPE',
      dataIndex: 'type',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '25%',
      align: 'left',
      sorter: false,
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <SpStatusTag style={{ backgroundColor: getCustomDocumentBackground(status) }}>
            {getCustomDocumentStatus(status)}
          </SpStatusTag>
        </Tooltip>
      ),
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '25%',
    align: 'center',
    render: (text, record) => {
      const { responseDocumentUrl, documentUrl, status } = record;
      if (internalRole) {
        if (!responseDocumentUrl) {
          return (
            <>
              <Icon
                component={DisableDownload}
                onClick={(e) => { e.stopPropagation(); }}
              />
            </>
          );
        }
        return (
          <>
            <Icon
              component={Download}
              onClick={(e) => { e.stopPropagation(); downloadDocument(record); }}
              style={{ cursor: 'pointer' }}
            />
          </>
        );
      }
      if (status === CUSTOMDOCUMENT_CONSTANTS.PENDING) {
        if (!documentUrl) {
          return (
            <>
              <Icon
                component={DisableDownload}
                onClick={(e) => { e.stopPropagation(); }}
              />
              <SpButton
                type="secondary"
                style={{ borderColor: '#B3B3B3' }}
                onClick={(e) => { e.stopPropagation(); showUploadProofModal(record); }}
                className="ml-3"
              >
                Upload
              </SpButton>
            </>
          );
        }
        return (
          <>
            <Icon
              component={Download}
              onClick={(e) => { e.stopPropagation(); downloadDocument(record); }}
            />
            <SpButton
              type="secondary"
              style={{ borderColor: '#B3B3B3' }}
              onClick={(e) => { e.stopPropagation(); showUploadProofModal(record); }}
              className="ml-3"
            >
              Upload
            </SpButton>
          </>
        );
      }
      if (!documentUrl) {
        return (
          <>
            <Icon
              component={DisableDownload}
              onClick={(e) => { e.stopPropagation(); }}
            />
            <SpButton
              type="secondary"
              disabled
              onClick={(e) => { e.stopPropagation(); }}
              className="ml-3"
            >
              Upload
            </SpButton>
          </>
        );
      }
      return (
        <>
          <Icon
            component={Download}
            onClick={(e) => { e.stopPropagation(); downloadDocument(record); }}
            style={{ cursor: 'pointer' }}
          />
          <SpButton
            type="secondary"
            disabled
            onClick={(e) => { e.stopPropagation(); }}
            className="ml-3"
          >
            Upload
          </SpButton>
        </>
      );
    },
  };

  const customColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '30%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'updatedAt',
      width: '25%',
      align: 'left',
      render: (updatedAt) => {
        if (updatedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(updatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
  ];
  const customActionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '20%',
    align: 'center',
    render: (text, record) => (
      <span>
        <Icon
          component={Download}
          style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
          onClick={() => downloadCustomDocument(record)}
        />
      </span>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      {
        test
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <h5>Documents are only available for live data</h5>
              </div>
            </Card>
          )
          : (
            <>
              <Card>
                <Row type="flex" className="my-2">
                  <Col span={6}>
                    <SpText className="mr-4" fontSize="12px" color="#999999" fontWeight="700">
                      DOCUMENT REQUESTS
                    </SpText>
                  </Col>
                  <Col span={18} className="d-flex justify-content-end">
                    <RBAC permit={[ROLES.SUPERUSER]}>
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-3"
                        onClick={() => setAddDocumentModal(true)}
                      >
                        {'\u002B'}&nbsp;New Document Request
                      </SpButton>
                    </RBAC>
                    <span>
                      {
                        exportData !== null
                          ? (
                            <Tooltip placement="left" title="Click here to export Team">
                              <CSVLink
                                target="_blank"
                                className="mr-3"
                                filename="Team Report.csv"
                                data={exportData}
                                headers={exportHeaders}
                                onMouseEnter={() => setFillColor(true)}
                                onMouseLeave={() => setFillColor(false)}
                              >
                                <ExportIcon
                                  fill={fillColor
                                    ? '#279dfe'
                                    : '#C0C0C0'}
                                />
                              </CSVLink>
                            </Tooltip>
                          ) : null
                      }
                    </span>
                    <span ref={myRef}>
                      <span
                        onClick={() => setOpen(!open)}
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={() => setFilterColor(true)}
                        onMouseLeave={() => setFilterColor(false)}
                      >
                        {open && (
                          <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                            <FilterIcon
                              fill={filterColor
                                ? '#279dfe'
                                : '#C0C0C0'}
                            />
                          </Badge>
                        )}
                        {!open && (
                          <Tooltip placement="left" title="Click here to filter Team">
                            <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                              <FilterIcon
                                fill={filterColor
                                  ? '#279dfe'
                                  : '#C0C0C0'}
                              />
                            </Badge>
                          </Tooltip>
                        )}
                      </span>
                      {open && (
                        <FilterWrapper
                          style={{ marginLeft: marginStyle }}
                        >
                          <Filters
                            filtersGrid={filterTeams}
                            filterTypes={filterTypes}
                            handleChange={handleChange}
                            onCheck={onCheck}
                            fiterOperators={fiterOperators}
                            handleOperator={handleOperator}
                            handleValue={handleValue}
                            startDate={startDate}
                            endDate={endDate}
                            currentDate={currentDate}
                          />
                          <div className="px-4 mt-2">
                            {
                              filterTeams.length < DOCUMENT_FILTERS_TYPES.length && (
                                <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                                  <SpText
                                    fontWeight="600"
                                    color="#279dfe"
                                    onClick={handleAddClick}
                                  >
                                    +&nbsp; Add another filter
                                  </SpText>
                                </div>
                              )
                            }
                          </div>
                          <div className="d-flex mb-2 justify-content-end mr-3">
                            <SpButton
                              type="secondary"
                              shape="round"
                              className="mr-2"
                              ghost
                              onClick={resetFilters}
                            >
                              Clear
                            </SpButton>
                            <SpButton
                              type="primary"
                              shape="round"
                              onClick={applyFilters}
                            >
                              Save
                            </SpButton>
                          </div>
                        </FilterWrapper>
                      )}
                    </span>
                  </Col>
                </Row>

                {/* ADD USER MODAL */}
                {addDocumentModal && (
                  <AddNewDocument
                    visible={addDocumentModal}
                    submitting={submitting}
                    close={() => setAddDocumentModal(false)}
                    submit={createDocument}
                  />
                )}

                <RBAC
                  fallback={(
                    <Table
                      onRow={(record) => ({
                        onClick: () => {
                          selectedRecord(record);
                        },
                      })}
                      columns={columns}
                      rowKey={(record) => record.gid}
                      dataSource={documents}
                      pagination={pagination}
                      onChange={handleTableChange}
                    />
                  )}
                  permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}
                >
                  <Table
                    columns={[...columns, actionColumn]}
                    onRow={(record) => ({
                      onClick: () => {
                        selectedRecord(record);
                      },
                    })}
                    rowKey={(record) => record.gid}
                    dataSource={documents}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </RBAC>
              </Card>
              <Card className="my-2 mt-4">
                <Row type="flex" className="my-2">
                  <Col span={6}>
                    <SpText className="mr-4" fontSize="12px" color="#999999" fontWeight="700">
                      DOCUMENTS
                    </SpText>
                  </Col>
                  <Col span={18} className="d-flex justify-content-end">
                    <RBAC permit={[ROLES.SUPERUSER]}>
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-3"
                        onClick={() => setAddCustomDocumentModal(true)}
                      >
                        {'\u002B'}&nbsp;New Custom Document
                      </SpButton>
                    </RBAC>
                  </Col>
                </Row>

                {/* ADD CUSTOM DOCUMENT MODAL */}
                {addCustomDocumentModal && (
                  <AddNewCustomDocument
                    visible={addCustomDocumentModal}
                    submitting={submitting}
                    close={() => setAddCustomDocumentModal(false)}
                    submit={createCustomDocument}
                  />
                )}

                {/* UPLOAD CUSTOM DOCUMENT MODAL */}
                {uploadCustomDocumentModal && (
                  <UploadProof
                    visible={uploadCustomDocumentModal}
                    uploadCustomDocument={uploadCustomDocument}
                    submitting={submitting}
                    close={() => setUploadCustomDocumentModal(false)}
                    submit={updateCustomDocument}
                  />
                )}

                <RBAC
                  fallback={(
                    <Table
                      columns={customColumns}
                      rowKey={(record) => record.gid}
                      dataSource={customDocuments}
                      pagination={pagination}
                      onChange={handleTableChange}
                    />
                  )}
                  permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}
                >
                  <Table
                    columns={[...customColumns, customActionColumn]}
                    rowKey={(record) => record.gid}
                    dataSource={customDocuments}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </RBAC>
              </Card>
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  documents: state.customDocumentRequest.content,
  totalElements: state.customDocumentRequest.totalElements,
  customDocuments: state.documents.content,
  totalDocumentElements: state.documents.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  user: state.user,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomDocumentRequest: param => dispatch(customDocumentRequestActions.fetchCustomDocumentRequest({
    payload: param,
  })),
  addCustomDocumentRequest: param => dispatch(customDocumentRequestActions.addCustomDocumentRequest({
    payload: param,
  })),
  updateCustomDocumentRequest: param => dispatch(customDocumentRequestActions.updateCustomDocumentRequest({
    payload: param,
  })),
  fetchDocuments: param => dispatch(documentsActions.fetchDocuments({
    payload: param,
  })),
  addDocument: param => dispatch(documentsActions.addDocument({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDocuments);
