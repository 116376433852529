// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Tabs,
  Card,
} from 'antd';

import {
  SpText,
} from 'components/DesignKit';

import {
  terminalActions,
} from 'store/actions';

import {
  ROLES,
} from 'appconstants';

import queryString from 'query-string';
import MessageSupport from './components/MessageSupport';
import NewPaperRoll from './components/NewPaperRoll';
import Firs from './components/Firs';
import AdditionalTerminal from './components/AdditionalTerminal';
import TerminalIssue from './components/TerminalIssue';

const { TabPane } = Tabs;

type Props = {
  fetchTerminals: Function,
  terminals: Array<Object>,
  selectedAccount: Object,
  role: Object,
};

const Support = (props: Props) => {
  const {
    fetchTerminals,
    terminals,
    selectedAccount,
    role,
  } = props;

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    fetchTerminals({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData();
  }, []);

  const getTerminalMid = (searchText) => {
    const searchParam = `&terminalMid.LIKE=${searchText}`;
    fetchTerminals({ searchParam });
  };

  const paperRoll = (
    <div style={{ textAlign: 'left', padding: '8px 24px 8px 0px' }}>
      <SpText fontSize="18px" color="#2E2E2E">New Paper Roll</SpText><br />
      <SpText fontSize="13px" color="#818181">Request additional paper rolls</SpText>
    </div>
  );

  const firs = (
    <div style={{ textAlign: 'left', padding: '8px 24px 8px 0px' }}>
      <SpText fontSize="18px" color="#2E2E2E">FIRS</SpText><br />
      <SpText fontSize="13px" color="#818181">Foreign inward remittance statement</SpText>
    </div>
  );

  const newTerminal = (
    <div style={{ textAlign: 'left', padding: '8px 24px 8px 0px' }}>
      <SpText fontSize="18px" color="#2E2E2E">Additional Terminal</SpText><br />
      <SpText fontSize="13px" color="#818181">Request for a new terminal</SpText>
    </div>
  );

  const terminalIssue = (
    <div style={{ textAlign: 'left', padding: '8px 24px 8px 0px' }}>
      <SpText fontSize="18px" color="#2E2E2E">Terminal Issue</SpText><br />
      <SpText fontSize="13px" color="#818181">Seek help on issues with your terminal</SpText>
    </div>
  );

  const messageSupport = (
    <div style={{ textAlign: 'left', padding: '8px 24px 8px 0px' }}>
      <SpText fontSize="18px" color="#2E2E2E">Message Support</SpText><br />
      <SpText fontSize="13px" color="#818181">Reach us for any other help</SpText>
    </div>
  );

  return (
    <Card>
      <SpText
        fontSize="15px"
        color="#7B7B7B"
        fontWeight="700"
        style={{
          paddingLeft: '10px',
        }}
      >
        ALL SUPPORTS
      </SpText>
      <Tabs defaultActiveKey="1" tabPosition="left">
        <TabPane tab={paperRoll} key="1">
          <NewPaperRoll
            terminals={terminals}
            countryName={selectedAccount && selectedAccount.country && selectedAccount.country.name}
          />
        </TabPane>
        <TabPane tab={firs} key="2">
          <Firs
            terminals={terminals}
            countryName={selectedAccount && selectedAccount.country && selectedAccount.country.name}
          />
        </TabPane>
        <TabPane tab={newTerminal} key="3">
          <AdditionalTerminal
            getTerminalMid={getTerminalMid}
            terminalList={terminals}
            countryName={selectedAccount && selectedAccount.country && selectedAccount.country.name}
          />
        </TabPane>
        <TabPane tab={terminalIssue} key="4">
          <TerminalIssue
            terminals={terminals}
            countryName={selectedAccount && selectedAccount.country && selectedAccount.country.name}
          />
        </TabPane>
        {
          ((role && role.name) !== ROLES.SUPERUSER) && (
            <TabPane tab={messageSupport} key="5">
              <MessageSupport />
            </TabPane>
          )
        }
      </Tabs>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  terminals: state.terminals.content,
  loading: state.loading.loading,
  submitting: state.loading.submitting,
  test: state.account.test,
  totalElements: state.terminals.totalElements,
  selectedAccount: state.account.selectedAccount,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
});

const mapDispatchToProps = (dispatch) => ({
  fetchTerminals: param => dispatch(terminalActions.fetchTerminals({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Support);
