// @flow
import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import Payments from './PaymentList';
import PaymentDetails from './PaymentList/components/PaymentDetails';
import DisputeEvidence from './PaymentList/components/DisputeEvidence';
import Transactions from '../Balance/Transactions';
import Disputes from '../Balance/Disputes';
import Payouts from '../Payouts';
import PaymentPagesEntries from './PaymentList/components/PaymentDetails/component/PaymentPagesEntries';

const Payment = () => (
  <Switch>
    <Route exact path="/disputes" component={Disputes} />
    <Route exact path="/transaction" component={Transactions} />
    <Route exact path="/payments/:gid/dispute" component={DisputeEvidence} />
    <Route exact path="/payments/:gid" component={PaymentDetails} />
    <Route exact path="/payment-page/:gid" component={PaymentPagesEntries} />
    <Route path="/payments" component={Payments} />
    <Route path="/payouts" component={Payouts} />
  </Switch>
);
export default Payment;
