import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  PRINTER_LABEL,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryPrinterLabel = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=name&direction=ASC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_TAG}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_TAG}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_TAG}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_TAG}${query}${sorting}`);
};

const fetchPrinterLabelList = async (payload) => {
  const queryParam = '?page=1&size=100';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=ASC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_TAG}${queryParam}${sorting}`);
};

const postInventoryPrinterLabel = async ({ params }) => axiosAuthInstance.post(`${API_END_POINTS.GET_INVENTORY_TAG}`, params);

const patchInventoryPrinterLabel = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_TAG}/${gid}`, params);

const deleteSelectedPrinterLabel = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INVENTORY_TAG}/${gid}`);

export function* getInventoryPrinterLabel(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchInventoryPrinterLabel, action.payload);
    yield put({ type: PRINTER_LABEL.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PRINTER_LABEL.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addInventoryPrinterLabel(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postInventoryPrinterLabel, action.payload);
    const { data: { entity } } = yield call(fetchInventoryPrinterLabel, action.payload);
    yield put({ type: PRINTER_LABEL.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.ADD_PRINTER_LABEL,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateInventoryPrinterLabel(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventoryPrinterLabel, action.payload);
    const { data: { entity } } = yield call(fetchInventoryPrinterLabel, action.payload);
    yield put({ type: PRINTER_LABEL.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.UPDATE_PRINTER_LABEL,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* deleteInventoryPrinterLabel(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteSelectedPrinterLabel, action.payload);
    const { data: { entity } } = yield call(fetchInventoryPrinterLabel, action.payload);
    yield put({ type: PRINTER_LABEL.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.DELETE_PRINTER_LABEL,
    });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getPrinterLabelList(action) {
  try {
    const { data: { entity } } = yield call(fetchPrinterLabelList, action.payload);
    yield put({ type: PRINTER_LABEL.SUCCESS_LIST, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PRINTER_LABEL.FAILED_LIST, payload: e.response.data });
  }
}
