import { REPORTS } from '../actionTypes';

export const fetchReports = (action) => ({
  type: REPORTS.FETCH,
  payload: action.payload,
});

export const addReport = (action) => ({
  type: REPORTS.ADD,
  payload: action.payload,
});
