import { SHOPS } from '../actionTypes';

const initialState = {
  content: [],
  ratesList: [],
  promosList: [],
  assosiateList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOPS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SHOPS.RATES_SUCCESS:
      return {
        ...state,
        ratesList: action.payload,
      };
    case SHOPS.PROMOS_SUCCESS:
      return {
        ...state,
        promosList: action.payload,
      };
    case SHOPS.ASSOCIATE_SUCCESS:
      return {
        ...state,
        assosiateList: action.payload,
      };
    case SHOPS.ASSOCIATE_FAILED:
      return {
        ...state,
        assosiateList: [],
      };
    default:
      return state;
  }
};
