import React from 'react';
import { connect } from 'react-redux';
import {
  SpText,
} from 'components/DesignKit';
import TransferCenter from 'imgs/transfers.svg';
import { navigationCardPayLinks } from 'appconstants/navigationCard';
import '../../styles/_navigationCard.scss';

type Props = {
  history: {
    push: Function,
    replace: Function,
  },
};

const PayLinksAndPages = (props: Props) => {
  const {
    history,
  } = props;

  return (
    <>
      <div
        className="mx-auto mt-5"
        style={{ width: 1000, height: 'auto' }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
          <img
            style={{ width: '350px' }}
            src={TransferCenter}
            alt="IMG"
          />
          <SpText fontSize="26px" fontWeight="600">PAY LINKS & PAGES</SpText>
          <SpText fontSize="16px" fontWeight="400">Create and manage secure, customizable payment links, pages and unique account URL for
            seamless transactions.
          </SpText>
        </div>
      </div>
      <div className="main_div">
        {navigationCardPayLinks.map((item, index) => (
          <div
            key={index}
            className="card"
            onClick={() => history.push(item.path)}
          >
            <div className="mt-2">
              <img src={item.svg} alt={item.name} />
              <p style={{ fontSize: '14px', fontWeight: '400' }} className="mt-2">{item.name}</p>
            </div>
          </div>
        ))}
      </div>
    </>

  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
});


export default connect(mapStateToProps)(PayLinksAndPages);
