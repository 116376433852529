// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';

import formatNumber from 'utils/formatNumber';
import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getFilterOperators from 'utils/getFilterOperators';

import { couponActions, filterParamsActions } from 'store/actions';
import getCouponBackground from 'utils/getCouponBackground';
import getDiscountOff from 'utils/getDiscountOff';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
} from 'appconstants';

import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddCoupon from './components/AddCoupon';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const COUPONS_FILTERS_TYPES = FILTERS_TYPES.COUPONS;

type Props = {
  coupons: Array<Object>,
  fetchCoupons: Function,
  addCoupons: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  fetchCustomers: Function,
  customers: Array<Object>,
  setFilterData: Function,
  preserveFilters: Object,
  isMobileView: Boolean,
};

const CouponsList = (props: Props) => {
  const {
    selectedAccount,
    coupons,
    loading,
    addCoupons,
    fetchCoupons,
    totalElements,
    submitting,
    validator,
    test,
    history,
    fetchCustomers,
    customers,
    setFilterData,
    preserveFilters,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const [, forceUpdate] = useState();
  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [addCouponModal, setAddCouponModal] = useState(false);
  const [filterTypes, setFilterTypes] = useState(COUPONS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [allCustomers, setAllCustomers] = useState(customers);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [filterCoupons, setFilterCoupons] = useState((preserveFilters && preserveFilters.filterCoupons)
    ? (preserveFilters && preserveFilters.filterCoupons)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const typeOfCurrency = selectedAccount && selectedAccount.currency.name;
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterCoupons: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });

  const debouncedFetchCoupons = debounce(fetchCoupons, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchCoupons({ currentPage, sortParams, filterParams });
  };

  const getStatus = (value) => {
    const status = value.active ? 'Active' : 'Deactivated';
    return status;
  };

  const exportData = coupons ? coupons.map((values) => ({
    name: values.name,
    discountOff: getDiscountOff(values),
    status: getStatus(values),
    maximumRedemption: values.maximumRedemption,
    expiresAt: moment
      .utc(values.expiresAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
  })) : '';

  const exportHeaders = [
    { label: 'COUPON', key: 'name' },
    { label: 'TERMS', key: 'discountOff' },
    { label: 'STATUS', key: 'status' },
    { label: 'REDEMPTIONS', key: 'maximumRedemption' },
    { label: 'EXPIRES', key: 'expiresAt' },
  ];

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      // inside click
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      // Outside click
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    getData(location.search);
    fetchCustomers();
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setAddCouponModal(false);
    }
  }, [validator]);

  useEffect(() => {
    setAllCustomers(customers);
  }, [customers]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [coupons]);

  const fetchCustomer = (searchParams) => {
    fetchCustomers(searchParams);
  };

  const addNewCoupon = (coupon: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      name,
      percentageOff,
      amountOff,
      validity,
      expiresAt,
      maximumRedemption,
      code,
      minimumAmount,
      promotionalRedeemed,
      promoExpiresAt,
      promotional,
      customerGid,
      validiyInMonths,
    } = coupon;
    const params = {
      active: true,
      name,
      percentageOff: (percentageOff ? parseFloat(percentageOff) : null),
      amountOff: (amountOff ? parseInt((amountOff).replace(/[_\W]+/g, ''), 10) : null),
      validity,
      expiresAt,
      maximumRedemption: (maximumRedemption ? parseInt(maximumRedemption, 10) : null),
      validiyInMonths: (validiyInMonths ? parseInt(validiyInMonths, 10) : null),
      currencyCode: typeOfCurrency,
    };
    const payload = {
      active: true,
      code,
      enabledForCheckout: true,
      expiresAt: promoExpiresAt,
      maximumRedemption: promotionalRedeemed ? parseInt(promotionalRedeemed, 10) : null,
      minimumAmount: minimumAmount ? parseInt((minimumAmount).replace(/[_\W]+/g, ''), 10) : null,
      minimumAmountCurrency: typeOfCurrency,
      restrictedToCustomers: [
        customerGid,
      ],
    };
    if (promotional) {
      addCoupons({
        params,
        payload,
        currentPage,
        sortParams,
      });
    } else if (!promotional) {
      addCoupons({
        params,
        currentPage,
        sortParams,
      });
    }
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = '&sortBy=createdAt&direction=ASC';
    } else if (filters.order === 'descend') {
      tempParams = '&sortBy=createdAt&direction=DESC';
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.COUPONS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.COUPONS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const selectedRecord = (record) => {
    const couponGid = record.gid;
    history.push({
      pathname: `${PAGES.COUPONS}/${couponGid}`,
      state: location.search,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterCoupons];
    if (filterCoupons.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = COUPONS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterCoupons(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterCoupons];
    if (e[1] === 'expiresAt') {
      list[index].filterType = 'Date';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = COUPONS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterCoupons(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterCoupons[index].filterName === 'expiresAt') {
      if (filterCoupons[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `expiresAt.GT=${dateStart}&expiresAt.LT=${dateEnd}`;
      } else if (filterCoupons[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `expiresAt.LT=${dateStart}`;
      } else if (filterCoupons[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `expiresAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterCoupons];
    list[index].filterValue = selectedValue;
    setFilterCoupons(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterCoupons];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'expiresAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'expiresAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterCoupons[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterCoupons(list);
  };

  const handleAddClick = () => {
    const list = [...filterCoupons];
    setFilterCoupons([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterCoupons([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(COUPONS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.COUPONS);
  };

  const applyFilters = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    const couponsFilters = filterCoupons.filter((item) => item.checked);
    if (couponsFilters[0].filterValue) {
      setCount(couponsFilters && couponsFilters.length);
      for (let i = 0; i < couponsFilters.length; i += 1) {
        if (couponsFilters[i].checked) {
          if (couponsFilters[i].filterName === 'expiresAt') {
            couponsFilters[i] = couponsFilters[i].filterValue;
          } else if (couponsFilters[i].filterName === 'email') {
            const email = encodeURIComponent(couponsFilters[i].filterValue);
            couponsFilters[i] = `${couponsFilters[i].filterName}.${couponsFilters[i].filterOperator}=${email}`;
          } else {
            couponsFilters[i] = `${couponsFilters[i].filterName}.${couponsFilters[i].filterOperator}=${couponsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(couponsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'expiresAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterCoupons,
        });
        history.push({
          pathname: PAGES.COUPONS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterCoupons,
        });
        history.push({
          pathname: PAGES.COUPONS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const columns = [
    {
      title: 'COUPON',
      dataIndex: 'name',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {name}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'TERMS',
      dataIndex: 'discountOff',
      width: '20%',
      align: 'left',
      render: (text, record) => {
        let discountOff;
        const months = record && record.validiyInMonths;
        if (months && record.percentageOff) {
          discountOff = `${record.percentageOff}% off for ${months} months`;
        } else if (!months && record.percentageOff) {
          discountOff = `${record.percentageOff}% off ${record.validity}`;
        } else if (months && record.amountOff) {
          const total = (record.amountOff / 100).toFixed(record.currency.toFixed);
          const formatAmount = `${record.currency.prefix} ${formatNumber(total, currencyType)}`;
          discountOff = `${formatAmount} off for ${months} months`;
        } else if (!months && record.amountOff) {
          const total = (record.amountOff / 100).toFixed(record.currency.toFixed);
          const formatAmount = `${record.currency.prefix} ${formatNumber(total, currencyType)}`;
          discountOff = `${formatAmount} off ${record.validity}`;
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>{discountOff}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'active',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        const active = record.active ? 'Active' : 'Deactivated';
        return (
          <Tooltip placement="top" title="Click here to view details">
            <SpStatusTag style={{ backgroundColor: getCouponBackground(active) }}>{active}</SpStatusTag>
          </Tooltip>
        );
      },
    },
    {
      title: 'REDEMPTIONS',
      dataIndex: 'maximumRedemption',
      width: '20%',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        if (record.maximumRedemption) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {record.redemptionCount}/{record.maximumRedemption}
              </span>
            </Tooltip>
          );
        } if (!record.maximumRedemption) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {record.redemptionCount}/0
              </span>
            </Tooltip>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'EXPIRES',
      dataIndex: 'expiresAt',
      width: '20%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (expiresAt) => {
        if (expiresAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(expiresAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
            ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]}
          >
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                ghost
                onClick={() => setAddCouponModal(true)}
              >
                {'\u002B'}&nbsp;Add Coupon
              </SpButton>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Coupons">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Coupons Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Coupons">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-117px' }}
                  >
                    <Filters
                      filtersGrid={filterCoupons}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterCoupons.length < COUPONS_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              {'\u002B'}&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD COUPON MODAL */}
      {addCouponModal && (
        <AddCoupon
          visible={addCouponModal}
          submitting={submitting}
          close={() => setAddCouponModal(false)}
          submit={addNewCoupon}
          selectedAccount={selectedAccount}
          fetchCustomer={fetchCustomer}
          allCustomers={allCustomers}
        />
      )}

      <RBAC
        fallback={(
          <Table
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={coupons}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
          ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]}
      >
        <Table
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns]}
          rowKey={(record) => record.gid}
          dataSource={coupons}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  coupons: state.coupons.content,
  totalElements: state.coupons.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  customers: state.coupons.customers,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCoupons: param => dispatch(couponActions.fetchCoupons({
    payload: param,
  })),
  addCoupons: param => dispatch(couponActions.addCoupons({
    payload: param,
  })),
  fetchCustomers: param => dispatch(couponActions.fetchCustomers({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CouponsList);
