import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  CUSTOM_DASHBOARD,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchCustomDashboard = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.SUPERSET_DASHBOARD_LIST}${query}${sorting}`);
};

const postCustomDashboard = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.SUPERSET_DASHBOARD, params);

const patchCustomDashboard = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.SUPERSET_DASHBOARD}/${gid}`, params);

export function* getCustomDashboard(action) {
  try {
    const { data: { entity } } = yield call(fetchCustomDashboard, action.payload);
    yield put({ type: CUSTOM_DASHBOARD.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: CUSTOM_DASHBOARD.FAILED, payload: e.response.data });
  }
}

export function* addCustomDashboard(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postCustomDashboard, action.payload);
    const { data: { entity } } = yield call(fetchCustomDashboard, action.payload);
    yield put({ type: CUSTOM_DASHBOARD.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Custom dashboard added succesfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateCustomDashboard(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCustomDashboard, action.payload);
    const { data: { entity } } = yield call(fetchCustomDashboard, action.payload);
    yield put({ type: CUSTOM_DASHBOARD.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Custom dashboard updated succesfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
