// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Checkbox,
  Row,
  Col,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import {
  MAX_AMOUNT,
  SUPPORTED_PAYMENT_TYPES,
  SUPPORTED_PAYMENT_LABEL,
} from 'appconstants';
import getPrefix from 'utils/getPrefix';
import { validators } from 'utils/validationMessages';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  paymentCheckoutDetails: Object,
  selectedAccount: Object,
  countryId: string,
  paymentMethods: Array,
  supportedCurrency: Array,
};

const AddPaymentCheckout = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    supportedCurrency,
    paymentMethods,
    paymentCheckoutDetails,
    countryId,
  } = props;

  const [, forceUpdate] = useState();
  const [amount, setAmount] = useState(paymentCheckoutDetails ? (paymentCheckoutDetails.amount / 100).toFixed(2) : '');
  const [sessionTime, setSessionTime] = useState(3);
  const [redirectUrl, setRedirectUrl] = useState(paymentCheckoutDetails ? paymentCheckoutDetails.redirectUri : '');
  const [paymentMethodData, setPaymentMethodData] = useState(paymentCheckoutDetails ? (paymentCheckoutDetails.paymentMethodType) : paymentMethods);
  const [disableButton, setDisableButton] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [currencyCode, setCurrencyCode] = useState(paymentCheckoutDetails
    ? (paymentCheckoutDetails.currency && paymentCheckoutDetails.currency.name)
    : (selectedAccount.currency && selectedAccount.currency.name));
  const [prefix, setPrefix] = useState(paymentCheckoutDetails
    ? (paymentCheckoutDetails.currency && paymentCheckoutDetails.currency.prefix)
    : (selectedAccount.currency && selectedAccount.currency.prefix));
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: (countryId === 2) ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          (countryId === 2)
            ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      timer: {
        message: validators.checkout.timer,
        rule: (val) => val >= 3,
      },
    },
  }));

  const onCurrencyChange = (e) => {
    setCurrencyCode(e);
    setPrefix(getPrefix(e));
  };

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const onCheckChange = (checkedValues) => {
    setPaymentMethodData(checkedValues);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const gpayIndex = paymentMethodData.indexOf('GOOGLE_PAY_US');
    const applepayIndex = paymentMethodData.indexOf('APPLE_PAY_US');
    const swirepayUsIndex = paymentMethodData.indexOf('SWIREPAY_US');
    if (gpayIndex !== -1) {
      paymentMethodData[gpayIndex] = 'WALLET';
    }
    if (applepayIndex !== -1) {
      paymentMethodData[applepayIndex] = 'WALLET';
    }
    if (swirepayUsIndex !== -1) {
      paymentMethodData[swirepayUsIndex] = 'WALLET';
    }
    submit({
      amount,
      sessionTime,
      redirectUrl,
      paymentMethodData,
      currencyCode,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Payment Checkout"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Amount</SpH5></label>
          <div className="d-flex">
            <div style={{ width: '85%' }}>
              <Input
                value={amount}
                prefix={prefix}
                placeholder="0.00"
                onChange={(e) => {
                  const regex = /^\d*\.?\d*$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setAmount(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setAmount(0.00);
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setAmount(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('amount', amount, 'required|amount')}
              </SpError>
            </div>
            <div
              style={{ width: '15%' }}
            >
              <Select
                className="w-100"
                showSearch
                value={currencyCode}
                onChange={(e) => onCurrencyChange(e)}
              >
                {supportedCurrency.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Session Timeout (in minutes)</SpH5></label>
          <Input
            placeholder="Session Timeout"
            value={sessionTime}
            onChange={(e) => setSessionTime(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('session Timeout', sessionTime, 'required|numeric|timer')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Acceptable Payment Methods</SpH5></label>
          <Checkbox.Group style={{ width: '100%' }} value={paymentMethodData} onChange={onCheckChange}>
            <Row>
              {
                paymentMethods.map(item => (
                  <Col span={item === SUPPORTED_PAYMENT_TYPES.NET_BANKING
                    ? 8 : item === SUPPORTED_PAYMENT_TYPES.INSTANT_PAYMENT
                      ? 8 : item === SUPPORTED_PAYMENT_TYPES.SWIREPAY_US
                        ? 8 : 6}
                  >
                    <Checkbox value={item}>
                      {(item === SUPPORTED_PAYMENT_TYPES.ACH_LEGACY)
                        ? SUPPORTED_PAYMENT_LABEL.ACH : (item === SUPPORTED_PAYMENT_TYPES.ACH)
                          ? SUPPORTED_PAYMENT_LABEL.BANK : (item === SUPPORTED_PAYMENT_TYPES.NET_BANKING)
                            ? SUPPORTED_PAYMENT_LABEL.NETBANKING : (item === SUPPORTED_PAYMENT_TYPES.GOOGLE_PAY_US)
                              ? SUPPORTED_PAYMENT_LABEL.GOOGLE_PAY_US : (item === SUPPORTED_PAYMENT_TYPES.INSTANT_PAYMENT)
                                ? SUPPORTED_PAYMENT_LABEL.INSTANT_PAYMENT : (item === SUPPORTED_PAYMENT_TYPES.SWIREPAY_US)
                                  ? SUPPORTED_PAYMENT_LABEL.SWIREPAY_US : (item === SUPPORTED_PAYMENT_TYPES.APPLE_PAYMENT)
                                    ? SUPPORTED_PAYMENT_LABEL.GOOGLE_PAY_US : item}

                    </Checkbox>
                  </Col>
                ))
              }
            </Row>
          </Checkbox.Group>
          <SpError>
            {simpleValidator.current.message('checkbox', paymentMethodData, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Redirect Url</SpH5></label>
          <Input
            placeholder="http://www.example.com"
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('redirectUrl', redirectUrl, 'required|url')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddPaymentCheckout);
