import { GLOBAL_RESTRICTED_KEY } from '../actionTypes';

export const fetchGlobalRestrictedKey = (action) => ({
  type: GLOBAL_RESTRICTED_KEY.FETCH,
  payload: action.payload,
});

export const addGlobalRestrictedKey = (action) => ({
  type: GLOBAL_RESTRICTED_KEY.ADD,
  payload: action.payload,
});

export const updateGlobalRestrictedKey = (action) => ({
  type: GLOBAL_RESTRICTED_KEY.UPDATE,
  payload: action.payload,
});

export const deleteGlobalRestrictedKey = (action) => ({
  type: GLOBAL_RESTRICTED_KEY.DELETE,
  payload: action.payload,
});
