import { INVENTORY_ITEMS_DETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  itemDetailsStatus: undefined,
  itemDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_ITEMS_DETAILS.FETCH:
      return {
        ...state,
        itemDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case INVENTORY_ITEMS_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        itemDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case INVENTORY_ITEMS_DETAILS.FAILED:
      return {
        ...state,
        itemDetailsError: action.payload,
        itemDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
