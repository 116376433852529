import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import AmexIcon from 'imgs/american.png';
import DiscoverIcon from 'imgs/discover.png';
import MasterIcon from 'imgs/master.png';
import VisaIcon from 'imgs/visa.png';
import RupayIcon from 'imgs/rupay.png';
import CardIcon from 'imgs/pay.png';
import React from 'react';
import account from 'imgs/account.svg';

export const AuthWrapper = styled.div`
  background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

// $FlowFixMe
export const AuthPanel = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '72px 48px',
  alignItems: 'center',
  width: '511px',
  minHeight: (props) => props.minHeight,
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: (props) => props.backgroundColor,
});

AuthPanel.defaultProps = {
  minHeight: '100%',
  backgroundColor: '#F7F7F7',
};

const ButtonColors = {
  secondary: {
    default: {
      backgroundColor: '#FFF',
      color: '#595959',
      borderColor: '#40A9FF',
    },
    hover: {
      backgroundColor: '#FFF',
      color: '#1791FF',
      borderColor: '#1791FF',
    },
    active: {
      backgroundColor: '#FFF',
      color: '#1F87DC',
      borderColor: '#1F87DC',
    },
  },
  primary: {
    default: {
      backgroundColor: '#40ADFF',
      color: '#FFF',
      borderColor: '#40A9FF',
    },
    hover: {
      backgroundColor: '#67BBFF',
      color: '#FFF',
      borderColor: '#67BFF',
    },
    active: {
      backgroundColor: '#1F87DC',
      color: '#FFF',
      borderColor: '#1F87DC',
    },
  },
  danger: {
    default: {
      backgroundColor: '#FF4D50',
      color: '#FFF',
      borderColor: '#FF4D50',
    },
    hover: {
      backgroundColor: '#FF8A89',
      color: '#FFF',
      borderColor: '#FF8A89',
    },
    active: {
      backgroundColor: '#E2373A',
      color: '#FFF',
      borderColor: '#E2373A',
    },
  },
};

export const SpButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  border-radius: ${(props) => (props.borderRadius)} !important;
  background-color: ${(props) => ButtonColors[props.type].default.backgroundColor};
  color: ${(props) => ButtonColors[props.type].default.color}; 
  border-color: ${(props) => ButtonColors[props.type].default.borderColor}; 
  &:hover {
    background-color: ${(props) => ButtonColors[props.type].hover.backgroundColor}; 
    color: ${(props) => ButtonColors[props.type].hover.color}; 
    border-color: ${(props) => ButtonColors[props.type].hover.borderColor}; 
  }
  &:active {
    background-color: ${(props) => ButtonColors[props.type].active.backgroundColor}; 
    color: ${(props) => ButtonColors[props.type].active.color}; 
    border-color: ${(props) => ButtonColors[props.type].active.borderColor}; 
  }
`;

SpButton.defaultProps = {
  type: 'primary',
  borderRadius: '32px',
};

export const SpForm = styled.div`
  padding: 0px 16px;
  & > div {
    margin-bottom: 24px;
  }
`;

export const SpError = styled.span`
  color: #FF4D50;
  font-size: 12px !important;
  font-weight: 600 !important;
`;

export const SpBcLink = styled(Link)`
  font-size: 20px;
  color: #414141;
  text-decoration: none !important;
`;

export const SpBcText = styled('span')`
  font-size: 20px;
  color: #414141;
`;

export const SpBcSpan = styled.span`
  font-size: ${(props) => (props.fontSize)} !important;
  color: ${(props) => (props.color)} !important;
  font-weight: ${(props) => (props.fontWeight)} !important;
`;

SpBcSpan.defaultProps = {
  color: '#414141',
  fontWeight: '700',
  fontSize: '20px',
};

export const SpTab = styled(Link)`
  font-size: 20px !important;
  color: ${(props) => (props.selected ? '#0090FF' : '#000')} !important;
  font-weight: ${(props) => (props.selected ? '600' : 'normal')} !important;
  text-decoration: none !important;
`;

export const SpTextMute = styled.span`
  font-size: 12px !important;
  color: '#858585',
  font-weight: ${(props) => (props.fontWeight)} !important;
`;

SpTextMute.defaultProps = {
  color: '#000',
  fontWeight: 'normal',
};
export const SpH6 = styled.span`
  font-size: 18px !important;
  color: ${(props) => (props.color)} !important;
  font-weight: ${(props) => (props.fontWeight)} !important;
`;

SpH6.defaultProps = {
  color: '#000',
  fontWeight: 'normal',
};

export const SpH5 = styled.span`
  font-size: 14px !important;
  color: ${(props) => (props.color)} !important;
  font-weight: 600 !important;
`;

SpH5.defaultProps = {
  color: '#000',
};

export const SpText = styled.span`
  font-size: ${(props) => (props.fontSize)} !important;
  color: ${(props) => (props.color)} !important;
  font-weight: ${(props) => (props.fontWeight)} !important;
  font-style: ${(props) => (props.fontStyle)} !important;
`;

SpText.defaultProps = {
  color: '#000',
  fontWeight: 'normal',
  fontSize: '14px',
};

export const SpCaption = styled.span`
  font-size: 12px !important;
  color: ${(props) => (props.color)} !important;
  font-weight: ${(props) => (props.fontWeight)} !important;
`;

SpCaption.defaultProps = {
  color: '#000',
  fontWeight: 'normal',
};

export const SpHead = styled.span`
  font-size: 20px !important;
  color: ${(props) => (props.color)} !important;
  font-weight: ${(props) => (props.fontWeight)} !important;
`;

SpHead.defaultProps = {
  color: '#000000',
  fontWeight: 'normal',
};

export const Line = styled.div`
 width: 100%;
 height: 1px;
 opacity: ${(props) => (props.opacity)} !important;
 border: solid 1px #979797;
  `;

Line.defaultProps = {
  opacity: '0.1',
};

const schemes = {
  mastercard: MasterIcon,
  master: MasterIcon,
  visa: VisaIcon,
  discover: DiscoverIcon,
  americanexpress: AmexIcon,
  amex: AmexIcon,
  rupay: RupayIcon,
};

export const CardSchemeIcon = ({ scheme, className, style }: Props) => {
  const schemeName = scheme && scheme.toString().replace(/[^a-zA-Z ]/g, '')
    .toLowerCase();
  let icon = CardIcon;
  const iconName = schemes[schemeName];
  if (iconName) {
    icon = iconName;
  }
  return (
    <img
      style={style}
      className={className}
      alt=""
      src={icon}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = CardIcon;
      }}
    />
  );
};

export const SpFormText = styled.span`
  font-size: ${(props) => (props.fontSize)} !important;
  color: ${(props) => (props.color)} !important;
  font-weight: ${(props) => (props.fontWeight)} !important;
  text-transform: uppercase;
  display: block;
  text-align: center;
  padding: 0px 0px 20px 0px !important;
`;

SpFormText.defaultProps = {
  color: '#156eb0',
  fontWeight: '300',
  fontSize: '18px',
};

// $FlowFixMe
export const SpStatusTag = styled.div({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: '#434343',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

// $FlowFixMe
export const SpFormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  padding: '37px 38px',
  width: '800px',
  minHeight: '520px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#fff',
});

// $FlowFixMe
export const SpWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// $FlowFixMe
export const SpWrapper1 = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;

// $FlowFixMe
export const SpFormWrapper1 = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  padding: '37px 38px',
  minHeight: '520px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#fff',
});

// $FlowFixMe
export const SpFormWrapper2 = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  padding: '37px 38px',
  marginLeft: '15px',
  minHeight: '720px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#fff',
});

// $FlowFixMe
export const LoginFormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '24px',
  paddingBottom: '24px',
  alignItems: 'center',
  width: '511px',
  minHeight: '520px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

// $FlowFixMe
export const LoginWrapper = styled.div`
  background-image: url(${account});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
`;

// $FlowFixMe
export const PaymentWrapper = styled.div`
    background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
    width: 100%;
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `;

// $FlowFixMe
export const PaymentFormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  minHeight: '760px',
  borderRadius: '5px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
  marginBottom: '50px',
});

export const SpItalicTextMute = styled.div`
  font-size: 14px !important;
  font-weight: ${(props) => (props.fontWeight)} !important;
`;

SpItalicTextMute.defaultProps = {
  fontWeight: 'normal',
  fontStyle: 'italic',
};

export const FilterWrapper = styled.div`
  position: absolute;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #40a9ff;
  width: 300px;
  margin-left: -140px;
  border-radius: 3px;
  margin-top: -26px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.12);
  max-height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
   }
   &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #c1c1c1;
   } 
`;
export const WrapperOrderSeq = styled.div`
  position: absolute;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #40a9ff;
  width: 490px;
  margin-left: -140px;
  border-radius: 3px;
  margin-top: -26px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.12);
  max-height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
   }
   &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #c1c1c1;
   } 
`;

export const FlexContainer = styled.div`
  display: ${props => props.display};
  width: ${props => props.width};
  height: ${props => props.height};
  min-width: ${props => props.minWidth};
  min-height: ${props => props.minHeight};
  flex-direction: ${props => props.flexDirection};
  flex-wrap: ${props => props.flexWrap};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  align-content: ${props => props.alignContent};
  border-radius: ${props => props.borderRadius};
`;

FlexContainer.defaultProps = {
  flexDirection: 'row',
  width: 'auto',
  height: 'auto',
  minWidth: 'auto',
  minHeight: 'auto',
  backgroundColor: 'inherit',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  display: 'flex',
};

export const Page = styled.div`
  min-width: 100vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;

export const SpStyledMenu = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 67vh;
  scrollbar-color: rgba(0, 0, 0, 0) #65748C;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 0.5rem;
    margin-left: 5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.123); 
    border-radius: 10px;
    background: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.274);
    background: #65748C;
  }
`;

export const SpwarningBadge = styled.div({
  backgroundColor: (props) => props.backgroundColor,
  borderRadius: '20px',
  height: '32px',
  width: '171px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '15px',
  cursor: 'pointer',
  color: '#FFF',
});

SpwarningBadge.defaultProps = {
  backgroundColor: '#e7800f',
};

// $FlowFixMe
export const SpDragInput = styled.div({
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '10px',
  width: '326px',
  height: '36px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #B3B3B3',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.11)',
});
