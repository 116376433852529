import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  REPORT_TEMPLATE_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchReportTemplateDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMREPORT_TEMPLATE}/${gid}`);

const reportTemplateDelete = async gid => axiosAuthInstance.delete(`${API_END_POINTS.ADD_CUSTOMREPORT_TEMPLATE}/${gid}`);

const patchReportTemplate = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.ADD_CUSTOMREPORT_TEMPLATE}/${gid}`, params);

export function* getReportTemplateDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchReportTemplateDetails, action.payload);
    yield put({ type: REPORT_TEMPLATE_DETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: REPORT_TEMPLATE_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateReportTemplate(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(patchReportTemplate, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.REPORTTEMPLATE.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteReportTemplate(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(reportTemplateDelete, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.REPORTTEMPLATE.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
