import React from 'react';

/* eslint-disable */
const loginError = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="53" height="48" viewBox="0 0 53 48">
    <g fill="none">
      <path fill="#3B4145" d="M33.369 4.002L51.928 36.02C52.608 37.195 53 38.556 53 40.01 53 44.422 49.408 48 44.977 48H26.418L20 24l6.418-24c2.972 0 5.564 1.61 6.95 4.002z" />
      <path fill="#525A61" d="M19.94 4.002L1.089 36.02C.398 37.195 0 38.556 0 40.01 0 44.422 3.648 48 8.149 48H27V0c-3.019 0-5.652 1.61-7.06 4.002z" />
      <path fill="#FFB751" d="M49.365 37.92L30.993 6.366C30.165 4.944 28.644 4.047 27 4l15.713 41h2.522C47.862 45 50 42.88 50 40.275c0-.827-.22-1.642-.635-2.356z" />
      <path fill="#FFD764" d="M46.453 37.92c.358.714.547 1.528.547 2.356C47 42.88 45.16 45 42.899 45H8.754C6.134 45 4 42.88 4 40.276c0-.828.22-1.642.634-2.356l18.33-31.55c.85-1.462 2.428-2.37 4.12-2.37.046 0 .092.001.137.003 1.408.055 2.709.95 3.418 2.367l15.814 31.55z" />
      <path fill="#3B4145" d="M26 35v5c1.657 0 3-1.119 3-2.5S27.657 35 26 35z" />
      <path fill="#525A61" d="M26.5 35c.276 0 .5 1.119.5 2.5s-.224 2.5-.5 2.5c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5z" />
      <path fill="#3B4145" d="M26 11v20c1.657 0 3-1.21 3-2.703V13.703C29 12.21 27.657 11 26 11z" />
      <path fill="#525A61" d="M26.5 11c.276 0 .5 1.21.5 2.703v14.594C27 29.79 26.776 31 26.5 31c-1.381 0-2.5-1.21-2.5-2.703V13.703C24 12.21 25.119 11 26.5 11z" />
    </g>
  </svg>

);

export default loginError;
