// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Radio,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpButton, SpError, SpText,
} from 'components/DesignKit';
import formatAmount from 'utils/formatAmount';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  loadedAmount: Number,
  displayAmount: boolean,
  bankDisplayName: String,
  bankLast: String,
};

const LoadAmount = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    loadedAmount,
    displayAmount,
    bankDisplayName,
    bankLast,
  } = props;

  const currencyType = selectedAccount && selectedAccount.currency;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const [bankType, setBankType] = useState('ZELLE');

  const [, forceUpdate] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator({}));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);


  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit(bankType);
  };

  const selectedOption = (e) => {
    setBankType(e.target.value);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Continue</SpButton>,
      ]}
      title="Load Balance"
    >
      <div className="px-4 mt-2">
        <div className="mb-3">
          <label htmlFor=""><SpH5>Amount</SpH5></label>
          <div>
            <SpText
              fontWeight="400"
              fontSize="14px"
            >
              {prefix} {displayAmount ? formatAmount(loadedAmount / 100, currencyType) : formatAmount(loadedAmount, currencyType)}
            </SpText>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Account</SpH5></label>
          <div>
            <SpText fontWeight="400" fontSize="14px">{bankDisplayName || `A/C XX ${bankLast}`}</SpText>
          </div>
        </div>
        <div>
          <label htmlFor=""><SpH5>Method</SpH5></label>
          <div className="d-flex">
            <Radio.Group defaultValue={bankType} onChange={selectedOption}>
              <Radio value="ZELLE">Load using Zelle</Radio>
              <Radio value="ACH">Load using ACH</Radio>
            </Radio.Group>
          </div>
          <SpError>
            {simpleValidator.current.message('Method', bankType, 'required')}
          </SpError>
        </div>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(LoadAmount);
