// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  inventoryFeedback: Array,
};

const DeleteFeedback = (props: Props) => {
  const {
    visible,
    close,
    submit,
    inventoryFeedback,
  } = props;

  const DeleteSelectedItem = () => {
   
    submit(inventoryFeedback.gid);
  };
  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={DeleteSelectedItem} type="danger">Delete</SpButton>,
      ]}
      title="Delete Feedback"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure want  to  delete this feedback ?</div>
        <div>
            {
              (inventoryFeedback && inventoryFeedback.length !== 0)
                ? (inventoryFeedback && inventoryFeedback.label)
                : <>&#8211;</>
            }
          </div>
        <SpH5>
            <div>
            {
              (inventoryFeedback && inventoryFeedback.length !== 0)
                ? (inventoryFeedback && inventoryFeedback.question)
                : <>&#8211;</>
            }
          </div>
        </SpH5>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteFeedback;
