import { call, put } from 'redux-saga/effects';

import {
  axiosNoAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  RECEIPTS,
  LOADING,
} from '../actionTypes';

const fetchReceipts = async gid => axiosNoAuthInstance.get(`${API_END_POINTS.GET_RECEIPTS}/${gid}`);

export function* getReceipts(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchReceipts, action.payload);
    yield put({ type: RECEIPTS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: RECEIPTS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
