// @flow
import React, { useState, useRef } from 'react';
import { Modal, Select } from 'antd';
import { SpH5, SpButton, SpError } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import Minus from 'imgs/Minus.png';
import Plus from 'imgs/Plus.png';
import SeperateLine from 'imgs/SeperateLine.png';

const { Option } = Select;

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  inventoryItemDetails: Object,
};

const DeleteLineItem = (props: Props) => {
  const {
    visible,
    close,
    submit,
    inventoryItemDetails,
  } = props;
  const [, forceUpdate] = useState();
  const [quantity, setQuantity] = useState((inventoryItemDetails && inventoryItemDetails.quantity) || 1);
  const [note, setNote] = useState((inventoryItemDetails && inventoryItemDetails.deleteNote) || '');
  const [disableButton, setDisableButton] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      quantity,
      note,
      lineItemGid: inventoryItemDetails.gid,
    });
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    if (quantity < (inventoryItemDetails && inventoryItemDetails.quantity)) {
      setQuantity(quantity + 1);
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Do not remove</SpButton>,
        <SpButton disabled={disableButton} onClick={validate} type="danger">Yes, remove</SpButton>,
      ]}
      title="Remove Item"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to remove this item?</div>
        <SpH5 className="pb-3">
          <div>{inventoryItemDetails && inventoryItemDetails.itemName ? inventoryItemDetails.itemName : <>&#8211;</>}</div>
        </SpH5>
        <div
          className="mt-3"
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #1A5244',
            padding: '8px',
            borderRadius: '8px',
            fontSize: '16px',
            userSelect: 'none',
            width: '140px',
            height: '38px',
            gap: '16px',
          }}
        >
          <div style={{ cursor: 'pointer' }} onClick={decreaseQuantity}>
            <img src={Minus} alt="cancel" />
          </div>
          <div><img src={SeperateLine} alt="cancel" /></div>
          <div><SpH5>{quantity}</SpH5></div>
          <div><img src={SeperateLine} alt="cancel" /></div>
          <div style={{ cursor: 'pointer' }} onClick={increaseQuantity}>
            {
              (quantity < (inventoryItemDetails && inventoryItemDetails.quantity)) ? (
                <div style={{ cursor: 'pointer', fontSize: '2em' }}>+</div>
              ) : (
                <>
                  <img src={Plus} alt="cancel" />
                </>
              )
            }
          </div>
        </div>
        <div className="mt-3">
          <label htmlFor=""><SpH5>Note</SpH5></label>
          <Select
            className="w-100"
            defaultValue="Note"
            onChange={(e) => setNote(e)}
          >
            <Option key={1} value="Appease Guest">Appease Guest</Option>
            <Option key={2} value="POS TEST">POS TEST</Option>
            <Option key={3} value="Walked Out">Walked Out</Option>
            <Option key={4} value="Item Not made">Item Not made</Option>
            <Option key={5} value="Credit Card Refund">Credit Card Refund</Option>
            <Option key={6} value="Took too Long">Took too Long</Option>
            <Option key={7} value="Comp">Comp</Option>
            <Option key={8} value="Poor Quality">Poor Quality</Option>
            <Option key={9} value="Double Punch">Double Punch</Option>
          </Select>
          {/* <Input
            placeholder="Note"
            onChange={(e) => setNote(e.currentTarget.value)}
            value={note}
          /> */}
          <SpError>
            {simpleValidator.current.message('note', note, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteLineItem;
