export default status => {
  if (status === 'LINKED') {
    return {
      color: '#4C51C2',
      background: '#EAEBFF',
    };
  } if (status === 'UNLINKED') {
    return {
      color: '#D17621',
      background: '#FFEDCC',
    };
  } return {
    color: '#D17621',
    background: '#FFEDCC',
  };
};
