import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
  Icon,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import formatNumber from 'utils/formatNumber';
import RBAC from 'components/rbac';
import getFilterOperators from 'utils/getFilterOperators';
import moment from 'moment-timezone';

import {
  bankStatementActions,
  notificationActions,
  filterParamsActions,
} from 'store/actions';
import { NOTIFICATION, FILTERS_SPECS } from 'store/actionTypes';

import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
  MESSAGES,
} from 'appconstants';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';


import getOcrRequestBackground from 'utils/getOcrRequestBackground';
import getOcrRequestStatus from 'utils/getOcrRequestStatus';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import Download from 'components/NavIcons/downloadProof';
import DisableDownload from 'components/NavIcons/downloadNoProof';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const DOCUMENT_FILTERS_TYPES = FILTERS_TYPES.CUSTOMDOCUMENTS;

type Props = {
  bankstatement: Array<Object>,
  history: {
    push: Function,
  },
  fetchBankStatement: Function,
  setNotification: Function,
  selectedAccount: Object,
  totalElements: number,
  loading: boolean,
  test: boolean,
  setFilterData: Function,
  preserveFilters: Object,
  isMobileView: Boolean,
};

const BankStatementList = (props: Props) => {
  const {
    selectedAccount,
    bankstatement,
    loading,
    fetchBankStatement,
    setNotification,
    totalElements,
    test,
    history,
    setFilterData,
    preserveFilters,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [filterTypes, setFilterTypes] = useState(DOCUMENT_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterTeams, setFilterTeams] = useState([{
    filterType: '',
    filterName: '',
    filterOperator: '',
    filterValue: '',
    filterNameDisplay: '',
    checked: true,
  }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const marginStyle = '-304px';
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
    });

  const exportData = bankstatement ? bankstatement.map((values) => ({
    type: values.type,
    name: values.name,
    status: getOcrRequestStatus(values.status),
  })) : '';

  const exportHeaders = [
    { label: 'TYPE', key: 'type' },
    { label: 'NAME', key: 'name' },
    { label: 'STATUS', key: 'status' },
  ];

  const debouncedFetchDocuments = debounce(fetchBankStatement, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'updatedAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchDocuments({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [test, selectedAccount, location]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      current: parseInt(currentPage, 10),
      total: totalElements,
      showTotal: total => `Total ${total} items`,
    }));
  }, [bankstatement]);


  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    const fieldName = filters.field;

    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${fieldName}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${fieldName}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.BANKSTATMENT,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.BANKSTATMENT,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const selectedRecord = (record) => {
    const { gid } = record;
    history.push({
      pathname: `${PAGES.BANKSTATMENT}/${gid}`,
      state: location.search,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterTeams];
    if (filterTeams.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = DOCUMENT_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTeams(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterTeams];
    if (e[1] === 'updatedAt') {
      list[index].filterType = 'Date';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = DOCUMENT_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTeams(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterTeams[index].filterName === 'updatedAt') {
      if (filterTeams[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `updatedAt.GT=${dateStart}&updatedAt.LT=${dateEnd}`;
      } else if (filterTeams[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `updatedAt.LT=${dateStart}`;
      } else if (filterTeams[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `updatedAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterTeams];
    list[index].filterValue = selectedValue;
    setFilterTeams(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTeams];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'updatedAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'updatedAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterTeams[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterTeams(list);
  };

  const handleAddClick = () => {
    const list = [...filterTeams];
    setFilterTeams([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterTeams([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(DOCUMENT_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
  };

  const applyFilters = () => {
    const teamsFilters = filterTeams.filter((item) => item.checked);
    for (let i = 0; i < teamsFilters.length; i += 1) {
      if (!teamsFilters[i].filterName || !teamsFilters[i].filterOperator || !teamsFilters[i].filterValue) {
        return;
      }
    }
    if (teamsFilters[0].filterValue) {
      setCount(teamsFilters && teamsFilters.length);
      for (let i = 0; i < teamsFilters.length; i += 1) {
        if (teamsFilters[i].checked) {
          if (teamsFilters[i].filterName === 'updatedAt') {
            teamsFilters[i] = teamsFilters[i].filterValue;
          } else if (teamsFilters[i].filterName === 'email') {
            const email = encodeURIComponent(teamsFilters[i].filterValue);
            teamsFilters[i] = `${teamsFilters[i].filterName}.${teamsFilters[i].filterOperator}=${email}`;
          } else {
            teamsFilters[i] = `${teamsFilters[i].filterName}.${teamsFilters[i].filterOperator}=${teamsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(teamsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'updatedAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
        history.push({
          pathname: PAGES.BANKSTATMENT,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
        });
        history.push({
          pathname: PAGES.BANKSTATMENT,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const downloadBankStatement = async (record) => {
    const { gid } = record;
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.GET_BANK_STATEMENT}/${gid}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };


  const columns = [
    {
      title: 'FILE NAME',
      dataIndex: 'fileName',
      width: '13%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '13%',
      align: 'left',
      sorter: false,
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <SpStatusTag style={{ backgroundColor: getOcrRequestBackground(status) }}>
            {getOcrRequestStatus(status)}
          </SpStatusTag>
        </Tooltip>
      ),
    },
    {
      title: 'TOTAL RECEIVABLE',
      dataIndex: 'totalReceivable',
      width: '18%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (totalReceivable) => {
        const total = (totalReceivable / 100);
        return (
          <span> {`${prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: '% MATCHED',
      dataIndex: 'receivableMatchedPercent',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const { receivableMatchedPercent } = record;
        if (receivableMatchedPercent) {
          return (
            <span> {`${receivableMatchedPercent} %`}</span>
          );
        }
        return (
          <span> &#8211;</span>
        );
      },
    },
    {
      title: 'TOTAL PAYABLE',
      dataIndex: 'totalPayable',
      width: '16%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (totalPayable) => {
        const total = (totalPayable / 100);
        return (
          <span> {`${prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: '% MATCHED',
      dataIndex: 'payableMatchedPercent',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const { payableMatchedPercent } = record;
        if (payableMatchedPercent) {
          return (
            <span> {`${payableMatchedPercent} %`}</span>
          );
        }
        return (
          <span> &#8211;</span>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'actions',
    width: '18%',
    align: 'center',
    render: (text, record) => (
      <>
        {
          record && record.fileUrl ? (
            <Icon
              component={Download}
              onClick={(e) => { e.stopPropagation(); downloadBankStatement(record); }}
              style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
            />
          ) : (
            <Icon
              component={DisableDownload}
              style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
            />
          )
        }
      </>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <Row type="flex" className="my-2">
          <Col span={6}>
            <SpText className="mr-4" fontSize="12px" color="#999999" fontWeight="700">
              Bank Account Statement
            </SpText>
          </Col>
          <Col span={18} className="d-flex justify-content-end">
            <span>
              {
                exportData !== null
                  ? (
                    <Tooltip placement="left" title="Click here to export Team">
                      <CSVLink
                        target="_blank"
                        className="mr-3"
                        filename="Team Report.csv"
                        data={exportData}
                        headers={exportHeaders}
                        onMouseEnter={() => setFillColor(true)}
                        onMouseLeave={() => setFillColor(false)}
                      >
                        <ExportIcon
                          fill={fillColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </CSVLink>
                    </Tooltip>
                  ) : null
              }
            </span>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Team">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && (
                <FilterWrapper
                  style={{ marginLeft: marginStyle }}
                >
                  <Filters
                    filtersGrid={filterTeams}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterTeams.length < DOCUMENT_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
            </span>
          </Col>
        </Row>
        <RBAC
          fallback={(
            <Table
              columns={columns}
              onRow={(record) => ({
                onClick: () => {
                  selectedRecord(record);
                },
              })}
              rowKey={(record) => record.gid}
              dataSource={[]}
              pagination={pagination}
              onChange={handleTableChange}
            />
          )}
          permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}
        >
          <Table
            columns={[...columns, actionColumn]}
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            rowKey={(record) => record.gid}
            dataSource={bankstatement}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </RBAC>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  bankstatement: state.bankStatement.content,
  totalDocumentElements: state.bankStatement.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  user: state.user,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBankStatement: param => dispatch(bankStatementActions.fetchBankStatement({
    payload: param,
  })),
  addBankStatement: param => dispatch(bankStatementActions.addBankStatement({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementList);
