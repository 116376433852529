import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  TERMINAL,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchTerminals = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const searchParams = payload.searchParam ? `${payload.searchParam}&sortBy=createdAt&direction=DESC` : '&sortBy=createdAt&direction=DESC';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_TERMINALS}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_TERMINALS}${query}${sorting}&${payload.filterParams}`);
  }
  if (payload.searchParam) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_TERMINALS}${query}${searchParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_TERMINALS}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_TERMINALS}${query}${sorting}`);
};

const getLocationsList = async (payload) => {
  if (payload) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_LOCATIONS}?page=1&size=100&name.LIKE=${payload}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_LOCATIONS}?page=1&size=100`);
};

const patchTerminal = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_TERMINALS}/${gid}`, params);

export function* getTerminals(action) {
  try {
    const { data: { entity } } = yield call(fetchTerminals, action.payload);
    yield put({ type: TERMINAL.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: TERMINAL.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* fetchLocationsList(action) {
  try {
    const { data: { entity } } = yield call(getLocationsList, action.payload);
    yield put({ type: TERMINAL.LOCATIONS_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: TERMINAL.LOCATIONS_FAILED, payload: e.response.data });
  }
}

export function* updateTerminal(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(patchTerminal, action.payload);
    const { data: { entity } } = yield call(fetchTerminals, action.payload);
    yield put({ type: TERMINAL.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TERMINAL.UPDATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
