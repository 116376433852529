import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';

import {
  Row,
  Col,
  Empty,
} from 'antd';
import styled from 'styled-components';
import {
  SpText,
  SpH5,
  SpButton,
  SpHead,
  SpH6,
  Line,
  CardSchemeIcon,
} from 'components/DesignKit';

import {
  MESSAGES,
  POS_PAYMENT_TYPES,
} from 'appconstants';

import {
  axiosNoAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  receiptsAction,
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import Loading from 'components/Loading';
import moment from 'moment-timezone';
import PosLine from 'imgs/line.svg';
import FooterLogo from 'imgs/dark-logo.svg';

// $FlowFixMe
const Wrapper = styled.div`
    background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
    width: 100%;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `;

// $FlowFixMe
const PosWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  width: '305px',
  minHeight: '560px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#ffffff',
});

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  width: '598px',
  minHeight: '520px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});


type Props = ContextRouter & {
  fetchReceipts: Function,
  setNotification: Function,
  loading: boolean,
  receipts: Object,
  history: {
    replace: Function,
  },
};

const Receipt = (props: Props) => {
  const {
    fetchReceipts,
    receipts,
    setNotification,
    loading,
    match,
  } = props;

  const [description, setDescription] = useState('');
  const [isPosUser, setIsPosUser] = useState(false);

  const receiptID = match.params.receiptid;

  useEffect(() => {
    fetchReceipts(receiptID);
  }, []);

  useEffect(() => {
    if (receipts && receipts.paymentDetails && receipts.paymentDetails.description) {
      const trimDescription = (receipts.paymentDetails.description).trim();
      setDescription(trimDescription);
    }
  }, [receipts]);

  useEffect(() => {
    if (receipts && receipts.paymentDetails && receipts.paymentDetails.paymentType) {
      const paymentType = (receipts.paymentDetails.paymentType.name);
      if (paymentType === POS_PAYMENT_TYPES.DEBITCARDPRESENT
        || paymentType === POS_PAYMENT_TYPES.CREDITCARDPRESENT
        || paymentType === POS_PAYMENT_TYPES.RUPAYPRESENT
        || paymentType === POS_PAYMENT_TYPES.UPIPRESENT) {
        setIsPosUser(true);
      }
    }
  }, [receipts]);

  const formatDate = (date, timezone) => {
    if (date && timezone) {
      if (isPosUser) {
        return moment.utc(date).tz(timezone).format('DD MMM, YYYY, hh:mm a');
      }
      return moment.utc(date).tz(timezone).format('MMM DD, YYYY');
    }
    return date && moment.utc(date).format('MMM DD, YYYY');
  };

  const downloadReport = async (record) => {
    try {
      const { data: { entity: { fileUrl } } } = await axiosNoAuthInstance.get(`${API_END_POINTS.GET_RECEIPTS}/${record}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.RECEIPT.DOWNLOAD_ERROR,
      });
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {(receipts && !(Object.keys(receipts).length === 0)) ? (
        <>
          {
            isPosUser ? (
              <Wrapper>
                <PosWrapper>
                  <Row className="mt-1">
                    <Col type="flex" align="middle">
                      <SpHead className="text-uppercase" fontWeight="400">
                        {receipts.accountName}
                      </SpHead>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col type="flex" align="middle">
                      <SpHead fontWeight="400">
                        {receipts.paymentDetails && receipts.paymentDetails.terminalLocation}
                      </SpHead>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col type="flex" align="middle">
                      <SpHead fontWeight="400">
                        {
                          (receipts.paymentDetails && receipts.paymentDetails.status) === 'SUCCEEDED'
                            ? 'SALE' : (receipts.paymentDetails && receipts.paymentDetails.status) === 'REFUNDED' ? 'REFUNDED' : 'VOID'
                        }
                      </SpHead>
                    </Col>
                  </Row>
                  <Row className="mt-1 mr-4">
                    <Col>
                      <SpText fontSize="13px" fontWeight="400" color="#000000">
                        MERCHANT NO: {receipts.paymentDetails && receipts.paymentDetails.terminalMid}
                      </SpText>
                    </Col>
                  </Row>
                  <Row className="mt-1 mr-4">
                    <Col>
                      <SpText fontSize="13px" fontWeight="400" color="#000000">
                        TERMINAL NO: {receipts.paymentDetails && receipts.paymentDetails.terminalTid}
                      </SpText>
                    </Col>
                  </Row>
                  <Row className="mt-1 mr-4">
                    <Col>
                      <SpText fontSize="13px" fontWeight="400" color="#000000">
                        AUTH CODE: {receipts.paymentDetails && receipts.paymentDetails.authCode}
                      </SpText>
                    </Col>
                  </Row>
                  <Row className="mt-1 mr-4">
                    <Col>
                      <SpText fontSize="13px" fontWeight="400" color="#000000">
                        RRN: {receipts.paymentDetails && receipts.paymentDetails.authorizationId}
                      </SpText>
                    </Col>
                  </Row>
                  {
                    (receipts.paymentDetails) && (receipts.paymentDetails.batchNumber) && (
                      <Row className="mt-1 mr-4">
                        <Col>
                          <SpText fontSize="13px" fontWeight="400" color="#000000">
                            Batch Number: {receipts.paymentDetails && receipts.paymentDetails.batchNumber}
                          </SpText>
                        </Col>
                      </Row>
                    )
                  }
                  <Row className="mt-1 mr-4">
                    <Col>
                      <SpText fontSize="13px" fontWeight="400">DATE: {
                        (receipts.paymentDetails
                          && receipts.paymentDetails.paymentDate
                          && formatDate(receipts.paymentDetails.status === 'SUCCEEDED'
                            ? receipts.paymentDetails.paymentDate : receipts.paymentDetails.createdAt, receipts.timezone))
                          || <>&#8211;</>
                      }
                      </SpText>
                    </Col>
                  </Row>
                  <Row className="mt-1 mr-4">
                    <Col>
                      <SpText fontSize="13px" fontWeight="400">
                        RECEIPT NO: {receipts.receiptNumber}
                      </SpText>
                    </Col>
                  </Row>
                  <Row className="mt-1 mr-4">
                    <Col>
                      <SpText fontSize="13px" fontWeight="400">
                        {
                          receipts.cardDetails ? (
                            `CARD NO: **** **** **** ${receipts.cardDetails.lastFour || ''}`
                          ) : receipts.upiDetails ? (
                            `UPI NO: **** **** **** ${receipts.upiDetails.vpa || ''}`
                          ) : (
                            <>&#8211;</>
                          )
                        }
                      </SpText>
                    </Col>
                  </Row>
                  <Row className="mt-1 mr-4">
                    <Col>
                      <SpText fontSize="13px" fontWeight="400">
                        {
                          receipts.cardDetails ? (
                            `CARD TYPE: ${receipts.cardDetails.cardScheme || ''}`
                          ) : receipts.upiDetails ? 'CARD TYPE: UPI'
                            : (
                              <>&#8211;</>
                            )
                        }
                      </SpText>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col type="flex" align="middle">
                      <SpHead fontWeight="400" fontSize="16px">
                        {`AMOUNT: ${receipts.paymentDetails.currencySymbol} ${receipts.paymentDetails.amount}`}
                      </SpHead>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col type="flex" align="middle">
                      <SpText fontWeight="400" fontSize="13px">
                        PIN VERIFIED OK
                      </SpText>
                    </Col>
                  </Row>
                  <Row>
                    <Col type="flex" align="middle">
                      <SpText fontWeight="400" fontSize="13px">
                        SIGNATURE NOT REQUIRED
                      </SpText>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col type="flex" align="middle">
                      <img src={PosLine} alt="" />
                    </Col>
                  </Row>
                  <Row className="mt-2 ml-4" align="middle">
                    <Col type="flex">
                      <SpText fontWeight="400" fontSize="16px">
                        I AGREE TO AS PER CARD
                      </SpText>
                    </Col>
                  </Row>
                  <Row className="ml-4" align="middle">
                    <Col type="flex" className="ml-3">
                      <SpText fontWeight="400" fontSize="16px">
                        ISSUER AGREEMENT
                      </SpText>
                    </Col>
                  </Row>
                  <Row className="mt-4 mb-4 ml-4" align="middle">
                    <Col type="flex">
                      <SpText fontWeight="400" fontSize="17px">
                        **** Merchant Copy****
                      </SpText>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col type="flex" align="middle">
                      <img src={FooterLogo} alt="" />
                    </Col>
                  </Row>
                </PosWrapper>
              </Wrapper>
            ) : (
              <Wrapper>
                <FormWrapper>
                  <Row>
                    <Col type="flex" align="middle">
                      <SpHead className="text-uppercase" fontWeight="600">
                        Receipt For {receipts.accountName}
                      </SpHead>
                    </Col>
                  </Row>
                  <Row>
                    <Col type="flex" align="middle" className="mt-3 mb-4"><SpH6 fontWeight="600">Receipt # {receipts.receiptNumber}</SpH6></Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col span={8}>
                      <div className="pt-1 pb-1"><SpText color="#434343">Amount Paid</SpText></div>
                      <div className="pt-1 pb-1">
                        <SpH6 fontWeight="600">
                          {receipts.paymentDetails.currencySymbol} {receipts.paymentDetails.paidAmount}
                        </SpH6>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="pt-1 pb-1"><SpText color="#434343">Date Paid</SpText></div>
                      <div className="pt-1 pb-1">
                        <SpH6 fontWeight="600">
                          {
                            (receipts.paymentDetails
                              && receipts.paymentDetails.paymentDate
                              && formatDate(receipts.paymentDetails.paymentDate, receipts.timezone)) || <>&#8211;</>
                          }
                        </SpH6>
                      </div>
                    </Col>
                    <Col span={8}>
                      {
                        receipts.cardDetails ? (
                          <>
                            <div className="pt-1 pb-1"><SpText color="#434343">Payment Method</SpText></div>
                            <div className="pt-1 pb-1 text-wrap">
                              <SpH6 fontWeight="600">
                                <CardSchemeIcon scheme={receipts.cardDetails.cardScheme} />
                                &nbsp;&nbsp;****{receipts.cardDetails.lastFour}
                              </SpH6>
                            </div>
                          </>
                        ) : receipts.googlePayUS ? (
                          <>
                            <div className="pt-1 pb-1"><SpText color="#434343">Payment Method</SpText></div>
                            <div className="pt-1 pb-1 text-wrap">
                              <SpH6 fontWeight="600">
                                <CardSchemeIcon scheme={receipts.googlePayUS.cardScheme} />
                                &nbsp;&nbsp;****{receipts.googlePayUS.lastFour}
                              </SpH6>
                            </div>
                          </>
                        ) : receipts.walletDetails ? (
                          <>
                            <div className="pt-1 pb-1"><SpText color="#434343">Payment Method</SpText></div>
                            <div className="pt-1 pb-1 text-wrap">
                              <SpH6 fontWeight="600">
                                <CardSchemeIcon scheme={receipts.walletDetails.cardScheme} />
                                &nbsp;&nbsp;****{receipts.walletDetails.lastFour}
                              </SpH6>
                            </div>
                          </>
                        ) : receipts.upiDetails ? (
                          <>
                            <div className="pt-1 pb-1"><SpText color="#434343">Payment Method</SpText></div>
                            <div className="pt-1 pb-1 text-wrap">
                              <SpH6 fontWeight="600">
                                {`${receipts.upiDetails.vpa} (UPI)`}
                              </SpH6>
                            </div>
                          </>
                        ) : receipts.instantPayment ? (
                          <>
                            <div className="pt-1 pb-1"><SpText color="#434343">Payment Method</SpText></div>
                            <div className="pt-1 pb-1 text-wrap">
                              <SpH6 fontWeight="600">
                                {receipts.instantPayment ? 'INSTANT PAYMENT' : <>&#8211;</>}
                              </SpH6>
                            </div>
                          </>
                        ) : receipts.bankDetails ? (
                          <>
                            <div className="pt-1 pb-1"><SpText color="#434343">Payment Method</SpText></div>
                            <div className="pt-1 pb-1 text-wrap">
                              <SpH6 fontWeight="600">
                                {`${receipts.bankDetails && receipts.bankDetails.bankName} ${(receipts.bankDetails && receipts.bankDetails.lastFour)
                                  ? receipts.bankDetails.lastFour : ''}`}
                              </SpH6>
                            </div>
                          </>
                        ) : (receipts.paymentDetails && receipts.paymentDetails.paymentType) ? (
                          <>
                            <div className="pt-1 pb-1"><SpText color="#434343">Payment Method</SpText></div>
                            <div className="pt-1 pb-1 text-wrap">
                              <SpH6 fontWeight="600">
                                {(receipts.paymentDetails.paymentType && receipts.paymentDetails.paymentType.name) === 'QR'
                                  ? 'QR' : <>&#8211;</>}
                              </SpH6>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="pt-1 pb-1"><SpText color="#434343">Payment Method</SpText></div>
                            <div className="pt-1 pb-1">
                              <>&#8211;</>
                            </div>
                          </>
                        )
                      }
                    </Col>
                  </Row>
                  <Line opacity="0.3" className="mt-1 mb-1" />
                  <Row>
                    <Col type="flex" align="middle" className="mt-3 pb-1"><SpText fontSize="18px">Transaction Details</SpText></Col>
                  </Row>
                  <Line />
                  <Row type="flex" className="pt-1 pb-1" justify="space-between">
                    <Col span={8} className="ml-4">
                      <div>
                        {(description) || `Payment for ${receipts.accountName}`}
                      </div>
                    </Col>
                    <Col span={5} className="ml-4">
                      <div>{receipts.paymentDetails.currencySymbol} {receipts.paymentDetails.paidAmount}</div>
                    </Col>
                  </Row>
                  <Line />
                  <Row type="flex" className="pt-1 pb-1" justify="space-between">
                    <Col span={8} className="ml-4">
                      <div>
                        <SpH5>Total</SpH5>
                      </div>
                    </Col>
                    <Col span={5} className="ml-4">
                      <div>
                        <SpH5>{receipts.paymentDetails.currencySymbol} {receipts.paymentDetails.paidAmount}</SpH5>
                      </div>
                    </Col>
                  </Row>
                  <Line />
                  {
                    receipts.paymentDetails.tax && (
                      <>
                        <Row type="flex" className="pt-1 pb-1" justify="space-between">
                          <Col span={8} className="ml-4">
                            <div>
                              <SpH5>Tax</SpH5>
                            </div>
                          </Col>
                          <Col span={5} className="ml-4">
                            <div>
                              <SpH5>{receipts.paymentDetails.currencySymbol} {receipts.paymentDetails.tax}</SpH5>
                            </div>
                          </Col>
                        </Row>
                        <Line />
                      </>
                    )
                  }
                  {
                    receipts.paymentDetails.fees && (
                      <>
                        <Row type="flex" className="pt-1 pb-1" justify="space-between">
                          <Col span={8} className="ml-4">
                            <div>
                              <SpH5>Fees</SpH5>
                            </div>
                          </Col>
                          <Col span={5} className="ml-4">
                            <div>
                              <SpH5>{receipts.paymentDetails.currencySymbol} {receipts.paymentDetails.fees}</SpH5>
                            </div>
                          </Col>
                        </Row>
                        <Line />
                      </>
                    )
                  }
                  {
                    receipts.paymentDetails.refunds && (
                      receipts.paymentDetails.refunds.map((data, id) => (
                        <div key={id}>
                          <Row type="flex" className="pt-1 pb-1" justify="space-between">
                            <Col span={8} className="ml-4">
                              <div>
                                <SpH5>Refund on {
                                  (data && data.createdAt && formatDate(data.createdAt, receipts.timezone)) || <>&#8211;</>
                                }
                                </SpH5>
                              </div>
                            </Col>
                            <Col span={5} className="ml-4">
                              <div>
                                <SpH5>{`(${receipts.paymentDetails.currencySymbol} ${data.amountRefunded})`}</SpH5>
                              </div>
                            </Col>
                          </Row>
                          <Line />
                        </div>
                      ))
                    )
                  }
                  {
                    // eslint-disable-next-line
                    receipts.paymentDetails && (receipts.paymentDetails.amountRefunded || receipts.paymentDetails.tax || receipts.paymentDetails.fee) && (
                      <>
                        <Row type="flex" className="pt-1 pb-1" justify="space-between">
                          <Col span={8} className="ml-4">
                            <div>
                              <SpH5>Adjusted Total</SpH5>
                            </div>
                          </Col>
                          <Col span={5} className="ml-4">
                            <div>
                              <SpH5>{receipts.paymentDetails.currencySymbol} {receipts.adjustedTotal}</SpH5>
                            </div>
                          </Col>
                        </Row>
                        <Line className="mb-4" />
                      </>
                    )
                  }
                  {
                    receipts.supportDto && (
                      <Row>
                        <Col type="flex" align="middle" className="mt-3">
                          <div>For any questions and concerns, please contact</div>
                        </Col>
                        <Col type="flex" align="middle">
                          <div><SpText fontWeight="600" color="#1d9cff">{receipts.supportDto.email ? receipts.supportDto.email : ''}</SpText></div>
                        </Col>
                      </Row>
                    )
                  }
                  <Row type="flex" align="middle" className="mt-auto">
                    <Col span={24} push={9}>
                      <SpButton
                        type="primary"
                        shape="round"
                        className="mt-4"
                        onClick={() => downloadReport(receiptID)}
                      >
                        Download PDF
                      </SpButton>
                    </Col>
                  </Row>
                </FormWrapper>
              </Wrapper>
            )
          }
        </>
      ) : <Empty style={{ height: '100vh', paddingTop: '40px' }} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  receipts: state.receipts,
});

const mapDispatchToProps = (dispatch) => ({
  fetchReceipts: gid => dispatch(receiptsAction.fetchReceipts({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Receipt);
