import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  QUICKSUPPORT,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

// const fetchInventoryQuickSupport = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_QUICKSUPPORT}?shopGid.EQ=${gid}`);

const fetchInventoryQuickSupport = async (payload) => {
  const query = generateQuery(payload.currentPage) || 1;
  const queryParam = payload.gid ? `&shopGid.EQ=${payload.gid}` : `&shopGid.EQ=${payload.shopGid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_QUICKSUPPORT}${query}${queryParam}${sorting}`);
};

const postInventoryQuickSupport = async ({ params }) => axiosAuthInstance.post(`${API_END_POINTS.GET_INVENTORY_QUICKSUPPORT}`, params);
// eslint-disable-next-line
const patchInventoryQuickSupport = async ({ quickGid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_QUICKSUPPORT}/${quickGid}`, params);

const deleteSelectedQuickSupport = async ({ quickGid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INVENTORY_QUICKSUPPORT}/${quickGid}`);

export function* getInventoryQuickSupport(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryQuickSupport, action.payload);
      yield put({ type: QUICKSUPPORT.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchInventoryQuickSupport, action);
      yield put({ type: QUICKSUPPORT.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: QUICKSUPPORT.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addInventoryQuickSupport(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postInventoryQuickSupport, action.payload);
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryQuickSupport, action.payload);
      yield put({ type: QUICKSUPPORT.SUCCESS, payload: entity });
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Quick Support added sucessfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateInventoryQuickSupport(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventoryQuickSupport, action.payload);
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryQuickSupport, action.payload);
      yield put({ type: QUICKSUPPORT.SUCCESS, payload: entity });
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Quick Support updated sucessfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteInventoryQuickSupport(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteSelectedQuickSupport, action.payload);
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryQuickSupport, action.payload);
      yield put({ type: QUICKSUPPORT.SUCCESS, payload: entity });
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Quick Support deleted sucessfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
