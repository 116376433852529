// @flow
import React, {
  useState, useRef, useEffect, useImperativeHandle, forwardRef,
} from 'react';
import NumberFormat from 'react-number-format';
import {
  Row,
  Col,
  Select,
  Input,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpForm,
  SpError,
  SpText,
} from 'components/DesignKit';

import {
  COUNTRY_CODES,
} from 'appconstants';

import getStateList from 'utils/stateList';

const { Option } = Select;

type Props = {
  selectedAccount: Object,
  disputeEvidence: Object,
  zipTag: String,
  disableFields: Boolean,
}

const CustomerInfo = (props: Props, ref) => {
  const {
    selectedAccount,
    disputeEvidence,
    zipTag,
    disableFields,
  } = props;
  const [, forceUpdate] = useState();
  const [customerName, setCustomerName] = useState((disputeEvidence && disputeEvidence.customerName) ? disputeEvidence.customerName : null);
  const [customerEmail, setCustomerEmail] = useState((disputeEvidence && disputeEvidence.customerEmail) ? disputeEvidence.customerEmail : null);
  // eslint-disable-next-line max-len
  const [address, setAddress] = useState((disputeEvidence && disputeEvidence.billingAddress) ? (disputeEvidence.billingAddress && disputeEvidence.billingAddress.street) : null);
  // eslint-disable-next-line max-len
  const [cityName, setCityName] = useState((disputeEvidence && disputeEvidence.billingAddress) ? (disputeEvidence.billingAddress && disputeEvidence.billingAddress.city) : null);
  // eslint-disable-next-line max-len
  const [stateName, setStateName] = useState((disputeEvidence && disputeEvidence.billingAddress) ? (disputeEvidence.billingAddress && disputeEvidence.billingAddress.state) : null);
  // eslint-disable-next-line max-len
  const [pinCode, setPinCode] = useState((disputeEvidence && disputeEvidence.billingAddress) ? (disputeEvidence.billingAddress && disputeEvidence.billingAddress.postalCode) : null);
  const [statesInCountry, setStatesInCountry] = useState(getStateList((selectedAccount && selectedAccount.country && selectedAccount.country.id)));
  // eslint-disable-next-line max-len
  const [customerCountry, setCustomerCountry] = useState((disputeEvidence && disputeEvidence.billingAddress) ? (disputeEvidence.billingAddress.country && disputeEvidence.billingAddress.country.id) : selectedAccount.country.id);
  const [phoneFormate, setPhoneFormate] = useState('');
  const [accountPhone, setAccountPhone] = useState();
  const [phoneCode, setPhoneCode] = useState();

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useEffect(() => {
    if (customerCountry === 1) {
      setPhoneFormate('(###) ###-####');
      setPhoneCode('+1');
    } else if (customerCountry === 2) {
      setPhoneFormate('##### #####');
      setPhoneCode('+91');
    }
  }, [customerCountry]);

  useEffect(() => {
    if (disputeEvidence && disputeEvidence.customerPhone) {
      const cntryCode = disputeEvidence.customerPhone.substring(0, 2);
      const phone = disputeEvidence.customerPhone;
      if (phone.length === 12 && cntryCode === '+1') {
        setPhoneCode(disputeEvidence.customerPhone.substring(0, 2));
        setAccountPhone(disputeEvidence.customerPhone.slice(2));
        setPhoneFormate('(###) ###-####');
      } else if (phone.length === 13 && cntryCode === '+9') {
        setPhoneCode(disputeEvidence.customerPhone.substring(0, 3));
        setAccountPhone(disputeEvidence.customerPhone.slice(3));
        setPhoneFormate('##### #####');
      } else if (phone.length === 12 && cntryCode !== '+1') {
        setPhoneCode(disputeEvidence.customerPhone.substring(0, 2));
        setAccountPhone(disputeEvidence.customerPhone.slice(2));
        setPhoneFormate('##########');
      } else if (phone.length === 13 && cntryCode !== '+9') {
        setPhoneCode(disputeEvidence.customerPhone.substring(0, 3));
        setAccountPhone(disputeEvidence.customerPhone.slice(3));
        setPhoneFormate('##########');
      } else if (phone.length === 14) {
        setPhoneCode(disputeEvidence.customerPhone.substring(0, 4));
        setAccountPhone(disputeEvidence.customerPhone.slice(4));
        setPhoneFormate('##########');
      }
    }
  }, []);


  const onCountryCodeChange = (value) => {
    if (value === '+1') {
      setPhoneFormate('(###) ###-####');
      setPhoneCode(value);
    } else if (value === '+91') {
      setPhoneFormate('##### #####');
      setPhoneCode(value);
    }
  };

  const onPhoneUpdate = (e) => {
    const phone = e.currentTarget.value;
    setAccountPhone(phone.replace(/[^0-9+]/g, ''));
  };

  const onCountryChange = (country) => {
    setStatesInCountry(getStateList(country));
    setCustomerCountry(country);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      customerName,
      customerEmail,
      address,
      cityName,
      stateName,
      pinCode,
      customerCountry,
      phoneCode,
      accountPhone,
    }),
  }));

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Name</SpText>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Name"
              defaultValue={customerName}
              disabled={disableFields}
              onChange={e => setCustomerName(e.currentTarget.value)}
            />
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Email</SpText>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Email"
              defaultValue={customerEmail}
              disabled={disableFields}
              onChange={e => setCustomerEmail(e.currentTarget.value)}
            />
            <SpError>
              {customerEmail && simpleValidator.current.message('Email', customerEmail, 'required|email|checkLowerCase')}
            </SpError>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Phone</span>
          </Col>
          <Col span={6}>
            <div className="d-flex">
              <Select
                value={phoneCode}
                disabled={disableFields}
                onChange={onCountryCodeChange}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.PHONE_CODE}>
                    {item.PHONE_CODE}
                  </Option>
                ))}
              </Select>
              <div className="w-100">
                <NumberFormat
                  className="InputnumFormate"
                  value={accountPhone}
                  format={phoneFormate}
                  mask="_"
                  allowEmptyFormatting
                  onChange={onPhoneUpdate}
                  disabled={disableFields}
                />
              </div>
            </div>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Billing address</SpText>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Address"
              defaultValue={address}
              disabled={disableFields}
              onChange={e => setAddress(e.currentTarget.value)}
            />
          </Col>
        </Row>
      </SpForm>
      <Row type="flex">
        <Col span={11}>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={6} className="mr-3">
                <SpText>City</SpText>
              </Col>
              <Col span={14} className="pr-2">
                <Input
                  placeholder="City Name"
                  defaultValue={cityName}
                  disabled={disableFields}
                  onChange={e => setCityName(e.currentTarget.value)}
                />
              </Col>
            </Row>
          </SpForm>
        </Col>
        <Col span={11}>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={7}>
                <SpText>State</SpText>
              </Col>
              <Col span={13}>
                <div className="w-100">
                  <Select
                    placeholder="Select State"
                    value={stateName || 'Select State'}
                    className="w-100"
                    disabled={disableFields}
                    onChange={e => setStateName(e)}
                  >
                    {statesInCountry.map((item) => (
                      <Option
                        className="text-capitalize"
                        key={1}
                        value={item.id}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
          </SpForm>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={11}>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={6} className="mr-3">
                <SpText>{zipTag}</SpText>
              </Col>
              <Col span={14} className="pr-2">
                <Input
                  placeholder={zipTag}
                  defaultValue={pinCode}
                  disabled={disableFields}
                  onChange={e => setPinCode(e.currentTarget.value)}
                />
                <SpError>
                  {pinCode && simpleValidator.current.message(zipTag, pinCode, 'required|numeric')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
        </Col>
        <Col span={11}>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={7}>
                <SpText>Country</SpText>
              </Col>
              <Col span={13}>
                <div className="w-100">
                  <Select
                    className="w-100"
                    placeholder="Select Country"
                    defaultValue={customerCountry}
                    onChange={onCountryChange}
                    disabled={disableFields}
                  >
                    {COUNTRY_CODES.map((item) => (
                      <Option
                        className="text-capitalize"
                        key={1}
                        value={item.ID}
                      >
                        {item.NAME}
                      </Option>
                    ))}
                  </Select>
                </div>
                <SpError>
                  {simpleValidator.current.message('Country', customerCountry, 'required')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
        </Col>
      </Row>
    </>
  );
};

// $FlowFixMe
export default forwardRef(CustomerInfo);
