import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  BULKTRANSFER_DETAILS,
  NOTIFICATION,
  LOADING,
} from '../actionTypes';

const fetchBulkTransferDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.MULTI_TRANSFER}/${gid}`);

const fetchTransfers = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const queryParam = `&bulkTransfer.gid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_TRANSFERS}${query}${queryParam}${sorting}`);
};

export function* getBulkTransferDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchBulkTransferDetails, action.payload);
      yield put({ type: BULKTRANSFER_DETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchBulkTransferDetails, action);
      yield put({ type: BULKTRANSFER_DETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: BULKTRANSFER_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getBulkTransfersList(action) {
  try {
    const { data: { entity } } = yield call(fetchTransfers, action.payload);
    yield put({ type: BULKTRANSFER_DETAILS.TRANSFERS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: BULKTRANSFER_DETAILS.FAILED, payload: e.response.data });
  }
}
