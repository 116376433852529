import React, { useState, useRef, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  Input,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpText, SpButton, SpError, SpH5,
} from 'components/DesignKit';
import {
  PAGES,
} from 'appconstants';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { cloverWebhookActions, notificationActions } from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import Clipboard from 'components/NavIcons/copyclipboard';

type Props = {
  loader: boolean,
  validator: boolean,
  cloverWebhook: Object,
  setNotification: Function,
  fetchInternalCloverWebhook: Function,
  updateInternalCloverWebhook: Function,
};

const CloverWebHook = (props: Props) => {
  const {
    loader,
    cloverWebhook,
    setNotification,
    fetchInternalCloverWebhook,
    updateInternalCloverWebhook,
    validator,
  } = props;
  const history = useHistory();
  const [, forceUpdate] = useState();
  const [token, setToken] = useState((cloverWebhook && cloverWebhook.cloverAuth) || '');
  const [redirect, setRedirect] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const env = process.env.REACT_APP_ENV;
  // eslint-disable-next-line
  const urlPath = (env === 'Production' ? 'https://callback-api.swirepay.com/v1/clover-webhook/callback-v2' : 'https://staging-callback-api.swirepay.com/v1/clover-webhook/callback-v2');

  const addNewWebhook = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      cloverAuth: token,
    };
    updateInternalCloverWebhook({
      gid: cloverWebhook && cloverWebhook.gid,
      params,
    });
    setRedirect(true);
  };

  useEffect(() => {
    fetchInternalCloverWebhook();
  }, []);

  useEffect(() => {
    if (cloverWebhook && cloverWebhook.gid) {
      setToken(cloverWebhook && cloverWebhook.cloverAuth);
    }
    if (!validator && redirect) {
      history && history.push(PAGES.SETTINGS);
    }
  }, [validator, cloverWebhook]);

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: 'LINK Copied Successfully',
    });
  };

  if (loader) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <>
          <Row className="mt-3" type="flex" justify="end" gutter={[16, 16]}>
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                onClick={() => history && history.push(PAGES.SETTINGS)}
              >
                Cancel
              </SpButton>
              {
                cloverWebhook && cloverWebhook.cloverAuth ? (
                  <SpButton
                    type="primary"
                    shape="round"
                    onClick={() => addNewWebhook()}
                  >
                    Update
                  </SpButton>
                ) : (
                  <SpButton
                    type="primary"
                    shape="round"
                    onClick={() => addNewWebhook()}
                  >
                    Save
                  </SpButton>
                )
              }
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col span={24}>
              <Col span={5}>
                <span>Call back URL</span>
              </Col>
              <Col span={15}>
                {cloverWebhook && cloverWebhook.gid
                  ? (
                    <SpH5>
                      {urlPath}
                      <SpButton
                        type="secondary"
                        className="ml-2"
                        borderRadius="50px"
                        onClick={() => { copyCode(urlPath); }}
                      >
                        <Clipboard />
                      </SpButton>
                    </SpH5>
                  )
                  : (
                    <>&#8211;</>
                  )}
              </Col>
            </Col>
          </Row>
          <Row className="mt-3" type="flex" justify="start">
            <Col span={24}>
              <Col span={5}>
                <span>Verification code</span>
              </Col>
              <Col span={13}>
                {cloverWebhook && cloverWebhook.verificationCode
                  ? (
                    <SpH5>{cloverWebhook.verificationCode}
                      <SpButton
                        type="secondary"
                        className="ml-2"
                        borderRadius="50px"
                        onClick={() => { copyCode(cloverWebhook.verificationCode); }}
                      >
                        <Clipboard />
                      </SpButton>
                    </SpH5>
                  )
                  : (
                    <>&#8211;</>
                  )}
              </Col>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mt-3 mb-3">
            <Col span={8}>
              <SpText fontWeight="600">x-clover-auth</SpText>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-3">
            <Col span={8}>
              <Input
                placeholder="Enter Auth Code"
                value={token}
                onChange={(e) => setToken(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('clover auth', token, 'required')}
              </SpError>
            </Col>
          </Row>
        </>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  cloverWebhook: state.cloverWebhook.cloverInternalCredentials,
  salesforceError: state.salesforce.salesforceError,
  loader: state.loading.loading,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  updateInternalCloverWebhook: param => dispatch(cloverWebhookActions.updateInternalCloverWebhook({
    payload: param,
  })),
  fetchInternalCloverWebhook: param => dispatch(cloverWebhookActions.fetchInternalCloverWebhook({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});


export default connect(mapStateToProps, mapDispatchToProps)(CloverWebHook);
