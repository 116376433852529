import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  VENDORSDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchVendorDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}/${gid}`);

const fetchVendors = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}${query}${sorting}`);
};


const vendorDelete = async gid => axiosAuthInstance.delete(`${API_END_POINTS.GET_CONTACTS}/${gid}`);

const fetchTransfers = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const queryParam = `&contact.gid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_TRANSFERS}${query}${queryParam}${sorting}`);
};

export function* getVendorDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchVendorDetails, action.payload);
    yield put({ type: VENDORSDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: VENDORSDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getTransfersList(action) {
  try {
    const { data: { entity } } = yield call(fetchTransfers, action.payload);
    yield put({ type: VENDORSDETAILS.TRANSFERS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: VENDORSDETAILS.FAILED, payload: e.response.data });
  }
}

export function* DeleteVendor(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(vendorDelete, action.payload);
    const { data: { entity } } = yield call(fetchVendors, action.payload);
    yield put({ type: VENDORSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.VENDORS.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
