// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import getVendorType from 'utils/getVendorType';
import getTransferBackground from 'utils/getTransferBackground';
import getTransferStatus from 'utils/getTransferStatus';
import {
  Card,
  Table,
  Row,
  Col,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  vendorsDetailsActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
  COUNTRIES,
} from 'appconstants';
import RBAC from 'components/rbac';
import CONSTANTS from 'store/constants';

import Deletevendor from './components/DeleteVendor';

type Props = {
  fetchVendorDetails: Function,
  fetchTransfers: Function,
  vendorsDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  transfers: Array<Object>,
  totalElements: number,
  vendorsDetailsStatus: string,
  validator: boolean,
  DeleteVendor: Function,
}

const VendorsDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchVendorDetails,
    fetchTransfers,
    vendorsDetails,
    loading,
    submitting,
    history,
    transfers,
    totalElements,
    selectedAccount,
    vendorsDetailsStatus,
    validator,
    DeleteVendor,
  } = props;
  const { gid } = props.match.params;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [pagination, setPagination] = useState({});
  const [deleteVendorModal, setDeleteVendorModal] = useState(false);
  const [redirect, SetRedirect] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [phoneFormate, setPhoneFormate] = useState('');

  const getData = (query) => {
    fetchVendorDetails(gid);
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchTransfers({ currentPage, sortParams, gid });
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push(`${PAGES.TRANSFERS}/${searchParams}`);
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.VENDORS);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    if (vendorsDetails.gid) {
      const phone = vendorsDetails.phoneNumber ? vendorsDetails.phoneNumber : '';
      const cntryCode = vendorsDetails.phoneNumber ? vendorsDetails.phoneNumber.substring(0, 2) : '';
      if (cntryCode === '+1') {
        setPhoneFormate('+# (###) ###-####');
      } else if (phone.length !== 14 && cntryCode === '+9') {
        setPhoneFormate('+##-##### #####');
      } else if (phone.length === 12 && cntryCode !== '+1') {
        setPhoneFormate('+#-##########');
      } else if (phone.length === 13 && cntryCode !== '+9') {
        setPhoneFormate('+##-##########');
      } else if (phone.length === 14) {
        setPhoneFormate('+###-##########');
      }
    }
  }, [vendorsDetails]);


  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [transfers]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.VENDORS}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const showDeleteVendorModal = () => {
    setDeleteVendorModal(true);
  };

  const deleteSelectedVendor = async (vendorGid: string) => {
    DeleteVendor(vendorGid);
    SetRedirect(true);
  };

  const utrColumns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <span>
            {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getTransferBackground(status) }}>
            {getTransferStatus(status)}
          </SpStatusTag>
        </span>
      ),
    },
    {
      title: 'FUNDING SOURCE',
      dataIndex: 'fundingSource',
      width: '15%',
      align: 'left',
      render: (fundingSource, record) => {
        const bankName = ((record.fundingSource) && (record.fundingSource.issuerBank) && (record.fundingSource.issuerBank.bankName));
        const lastFour = ((record.fundingSource) && (record.fundingSource.issuerBank) && (record.fundingSource.issuerBank.lastFour));
        if ((bankName) && (lastFour)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {`${bankName} ****${lastFour}`}
            </span>
          );
        } if (lastFour) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {`****${lastFour}`}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'UTR',
      dataIndex: 'uniqueReferenceNumber',
      width: '15%',
      align: 'left',
      render: (uniqueReferenceNumber, record) => {
        if ((record.neftTransfer) && (record.neftTransfer.utrNumber)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(record.neftTransfer.utrNumber)}
            </span>
          );
        } if ((record.rtgsTransfer) && (record.rtgsTransfer.utrNumber)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(record.rtgsTransfer.utrNumber)}
            </span>
          );
        } if ((record.impsTransfer) && (record.impsTransfer.utrNumber)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(record.impsTransfer.utrNumber)}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'TRANSFER DATE',
      dataIndex: 'transferDate',
      width: '15%',
      align: 'left',
      render: (transferDate) => {
        if (transferDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(transferDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <span>
            {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getTransferBackground(status) }}>
            {getTransferStatus(status)}
          </SpStatusTag>
        </span>
      ),
    },
    {
      title: 'FUNDING SOURCE',
      dataIndex: 'fundingSource',
      width: '15%',
      align: 'left',
      render: (fundingSource, record) => {
        const bankName = ((record.fundingSource) && (record.fundingSource.issuerBank) && (record.fundingSource.issuerBank.bankName));
        const lastFour = ((record.fundingSource) && (record.fundingSource.issuerBank) && (record.fundingSource.issuerBank.lastFour));
        if ((bankName) && (lastFour)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {`${bankName} ****${lastFour}`}
            </span>
          );
        } if (lastFour) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {`****${lastFour}`}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'TRANSFER DATE',
      dataIndex: 'transferDate',
      width: '20%',
      align: 'left',
      render: (transferDate) => {
        if (transferDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(transferDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        vendorsDetails.gid && vendorsDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    {vendorsDetails.name || <>&#8211;</>}
                  </SpText>
                </Col>
                <>
                  <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                    <Col>
                      <SpButton
                        type="danger"
                        shape="round"
                        onClick={showDeleteVendorModal}
                      >
                        Delete Contact
                      </SpButton>
                    </Col>
                  </RBAC>
                </>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Type</span>
                  </Col>
                  <Col span={9}>
                    {vendorsDetails.contactType
                      ? <SpH5>{getVendorType(vendorsDetails.contactType)}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              {vendorsDetails.customType && (
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Custom Type</span>
                    </Col>
                    <Col span={9}>
                      {vendorsDetails.customType
                        ? <SpH5>{vendorsDetails.customType}</SpH5>
                        : <>&#8211;</>}
                    </Col>
                  </Row>
                </SpForm>
              )}
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Email</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{vendorsDetails.email || <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Phone</span>
                  </Col>
                  <Col span={9}>
                    {
                      vendorsDetails.phoneNumber ? (
                        <NumberFormat
                          className="numFormate"
                          value={vendorsDetails.phoneNumber}
                          format={phoneFormate}
                          mask="_"
                          disabled
                        />
                      ) : <>&#8211;</>
                    }
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Payment Terms</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{vendorsDetails.paymentTermsDays || <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>TDS Category</span>
                  </Col>
                  <Col span={9}>
                    <SpH5><>&#8211;</></SpH5>
                  </Col>
                </Row>
              </SpForm>
              {(vendorsDetails && vendorsDetails.address)
                ? (
                  <>
                    <hr />
                    <SpForm>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <span>Street</span>
                        </Col>
                        <Col span={9}>
                          <SpH5>
                            {(vendorsDetails.address && vendorsDetails.address.street) || <>&#8211;</>}
                          </SpH5>
                        </Col>
                        <Col span={3}>
                          <span>City</span>
                        </Col>
                        <Col span={8}>
                          <SpH5>
                            {(vendorsDetails.address && vendorsDetails.address.city) || <>&#8211;</>}
                          </SpH5>
                        </Col>
                      </Row>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <span>State</span>
                        </Col>
                        <Col span={9}>
                          <SpH5>
                            {(vendorsDetails.address && vendorsDetails.address.state) || <>&#8211;</>}
                          </SpH5>
                        </Col>
                        <Col span={3}>
                          <span>Country</span>
                        </Col>
                        <Col span={8}>
                          <SpH5>
                            {(vendorsDetails.address && vendorsDetails.address.country) ? (vendorsDetails.address.country.name) : <>&#8211;</>}
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                )
                : ''}
              <Row className="d-flex justify-content-end">
                <Col>
                  <SpH5 color="#7f7f7f">{gid}</SpH5>
                </Col>
              </Row>
              {/* DELETE VENDOR MODAL */}
              {deleteVendorModal && (
                <Deletevendor
                  visible={deleteVendorModal}
                  vendorsDetails={vendorsDetails}
                  submitting={submitting}
                  close={() => setDeleteVendorModal(false)}
                  submit={deleteSelectedVendor}
                />
              )}
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">Transfer Sessions</SpText>
                </Col>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={isIndia ? utrColumns : columns}
                rowKey={(record) => record.gid}
                dataSource={transfers}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  vendorsDetails: state.vendorsDetails,
  vendorsDetailsStatus: state.vendorsDetails.vendorsDetailsStatus,
  transfers: state.vendorsDetails.transfers.content,
  totalElements: state.vendorsDetails.transfers ? state.vendorsDetails.transfers.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVendorDetails: gid => dispatch(vendorsDetailsActions.fetchVendorDetails({
    payload: gid,
  })),
  fetchTransfers: param => dispatch(vendorsDetailsActions.fetchTransfers({
    payload: param,
  })),
  DeleteVendor: param => dispatch(vendorsDetailsActions.DeleteVendor({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(VendorsDetails);
