export default value => {
  if (value === 'INPUT_EMAIL') {
    return 'Email';
  } if (value === 'INPUT_PHONE') {
    return 'Phone Number';
  } if (value === 'INPUT_NUMBER') {
    return 'Number';
  } if (value === 'INPUT_ALPHA') {
    return 'Alpha';
  } if (value === 'INPUT_ALPHA_NUMERIC') {
    return 'Alpha Numeric';
  } if (value === 'INPUT_SINGLE_LINE_TEXT') {
    return 'Single Line Text';
  } if (value === 'INPUT_LARGE_TEXT') {
    return 'Large Text';
  } return 'Enter the field label';
};
