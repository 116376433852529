import { SECRETKEYS } from '../actionTypes';

export const fetchSecretkeys = (action) => ({
  type: SECRETKEYS.FETCH,
  payload: action.payload,
});

export const updateSecretkey = (action) => ({
  type: SECRETKEYS.UPDATE,
  payload: action.payload,
});
