import { BANK_STATEMENT_DETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  payables: [],
  adminTerminalDetailsStatus: undefined,
  adminTerminalDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BANK_STATEMENT_DETAILS.FETCH:
      return {
        ...state,
        adminTerminalDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case BANK_STATEMENT_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        adminTerminalDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case BANK_STATEMENT_DETAILS.FAILED:
      return {
        ...state,
        adminTerminalDetailsError: action.payload,
        adminTerminalDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case BANK_STATEMENT_DETAILS.PAYABLES_SUCCESS:
      return {
        ...state,
        payables: action.payload,
      };
    default:
      return state;
  }
};
