import { PRINTER_LABEL } from '../actionTypes';

export const fetchInventoryPrinterLabel = (action) => ({
  type: PRINTER_LABEL.FETCH,
  payload: action.payload,
});

export const addInventoryPrinterLabel = (action) => ({
  type: PRINTER_LABEL.ADD,
  payload: action.payload,
});

export const updateInventoryPrinterLabel = (action) => ({
  type: PRINTER_LABEL.UPDATE,
  payload: action.payload,
});

export const deleteInventoryPrinterLabel = (action) => ({
  type: PRINTER_LABEL.DELETE,
  payload: action.payload,
});

export const fetchPrinterLabelList = (action) => ({
  type: PRINTER_LABEL.FETCH_LIST,
  payload: action.payload,
});
