import { DISPUTE_STATUS } from 'appconstants';

export default value => {
  if (value === 'NEEDS_RESPONSE') {
    return DISPUTE_STATUS.NEEDS_RESPONSE;
  } if (value === 'WON') {
    return DISPUTE_STATUS.WON;
  } if (value === 'LOST') {
    return DISPUTE_STATUS.LOST;
  } if (value === 'UNDER_REVIEW') {
    return DISPUTE_STATUS.UNDER_REVIEW;
  }
  return null;
};
