// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Select,
} from 'antd';

import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError } from 'components/DesignKit';
import {
  COUNTRY_CODES,
  PAYOUT_CURRENCY_CODES,
} from 'appconstants';

const { Option } = Select;

type Props = {
  submitting: boolean,
  searchRateData: Object,
  paymenttype: Array<Object>,
  acquirer: Array<Object>,
  category: Array<Object>,
};

const SearchRate = (props: Props, ref) => {
  const {
    submitting,
    searchRateData,
    paymenttype,
    acquirer,
    category,
  } = props;
  const [, forceUpdate] = useState();
  const [acquirerId, setAcquirerId] = useState(searchRateData ? searchRateData.acquirerId : '');
  const [categoryId, setCategoryId] = useState(searchRateData ? searchRateData.categoryId : '');
  const [paymentTypeId, setPaymentTypeId] = useState(searchRateData ? searchRateData.paymentTypeId : '');
  const [countryId, setCountryId] = useState(searchRateData ? searchRateData.countryId : 'Select a country');
  const [acquirerName, setAcquirerName] = useState(searchRateData ? searchRateData.acquirerName : '');
  const [categoryName, setCategoryName] = useState(searchRateData ? searchRateData.categoryName : '');
  const [paymentTypeName, setPaymentTypeName] = useState(searchRateData ? searchRateData.paymentTypeName : '');
  const [countryName, setCountryName] = useState(searchRateData ? searchRateData.countryName : '');
  const [currencyId, setCurrencyId] = useState(searchRateData ? searchRateData.currencyId : '');
  const [currencyName, setCurrencyName] = useState(searchRateData ? searchRateData.currencyName : '');

  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      acquirerId,
      categoryId,
      paymentTypeId,
      countryId,
      acquirerName,
      categoryName,
      paymentTypeName,
      countryName,
      currencyId,
      currencyName,
    }),
  }));

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Acquirer Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            defaultValue={acquirerName || 'Select Acquirer Type'}
            onChange={(value) => {
              setAcquirerName(value[0]);
              setAcquirerId(value[1]);
            }}
          >
            {
              acquirer.map(acquire => (
                <Option
                  key={1}
                  value={[acquire.name, acquire.id]}
                >
                  {acquire.name}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('Acquirer Type', acquirerId, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Category Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            defaultValue={categoryName || 'Select Category Type'}
            onChange={value => {
              setCategoryName(value[0]);
              setCategoryId(value[1]);
            }}
          >
            {
              category.map(categories => (
                <Option
                  key={1}
                  value={[categories.name, categories.id]}
                >
                  {categories.name}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('Category Type', categoryId, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Payment Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            defaultValue={paymentTypeName || 'Select Payment Type'}
            onChange={value => {
              setPaymentTypeName(value[0]);
              setPaymentTypeId(value[1]);
            }}
          >
            {
              paymenttype.map(payment => (
                <Option
                  key={1}
                  value={[payment.name, payment.id]}
                >
                  {payment.name}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('Payment Type', paymentTypeId, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Select Country</SpH5></label>
          <Select
            className="w-100"
            showSearch
            defaultValue={countryName || 'Select a country'}
            onChange={value => {
              setCountryName(value[0]);
              setCountryId(value[1]);
            }}
          >
            {COUNTRY_CODES.map((item) => (
              <Option
                key={item.ID}
                value={[item.NAME, item.ID]}
              >
                {item.NAME}
              </Option>
            ))}
          </Select>
          <SpError>
            {simpleValidator.current.message('country', countryId, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Select Currency</SpH5></label>
          <Select
            className="w-100"
            showSearch
            defaultValue={currencyName || 'Select a currency'}
            onChange={value => {
              setCurrencyName(value[0]);
              setCurrencyId(value[1]);
            }}
          >
            {PAYOUT_CURRENCY_CODES.map((item) => (
              <Option
                key={item.ID}
                value={[item.VALUE, item.ID]}
              >
                {item.VALUE}
              </Option>
            ))}
          </Select>
          <SpError>
            {simpleValidator.current.message('currency', currencyId, 'required')}
          </SpError>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(SearchRate);
