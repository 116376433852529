// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Switch,
  Checkbox,
  Row,
  Col,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5,
  SpError,
  SpButton,
  SpText,
  SpTextMute,
} from 'components/DesignKit';
import {
  WEBHOOK_EVENTS,
} from 'appconstants';
import HyperLinkIcon from 'components/NavIcons/hyperlinkIcon';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedWebhook: Object,
  deactive: Function,
  webhookEvents: Object,
};

const EditWebhook = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedWebhook,
    deactive,
    webhookEvents,
  } = props;

  const [, forceUpdate] = useState();
  const [url, setUrl] = useState(selectedWebhook.url);
  const [email, setEmail] = useState(selectedWebhook.email);
  const [secret, setSecret] = useState(selectedWebhook.secret);
  const [events, setEvents] = useState([]);
  const [testMode, setTestMode] = useState(selectedWebhook.isTest);
  const [testModeText, setTestModeText] = useState(selectedWebhook.isTest ? 'Test' : 'Live');
  const [webhookEnabled, setWebhookEnabled] = useState(false);
  const [paymentsList, setPaymentsList] = useState(WEBHOOK_EVENTS.PAYMENT);
  const [payoutsList, setPayoutsList] = useState(WEBHOOK_EVENTS.PAYOUT);
  const [subscriptionsList, setSubscriptionsList] = useState(WEBHOOK_EVENTS.SUBSCRIPTION);
  const [invoicesList, setInvoicesList] = useState(WEBHOOK_EVENTS.INVOICE);
  const [inventoryList, setInventoryList] = useState(WEBHOOK_EVENTS.INVENTORY);
  const { gid } = selectedWebhook;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));
  const [checkPayments, setCheckPayments] = useState(false);
  const [paymentIndeterminate, setPaymentIndeterminate] = useState(false);
  const [checkPayouts, setCheckPayouts] = useState(false);
  const [payoutsIndeterminate, setpayoutsIndeterminate] = useState(false);
  const [checkSubscriptions, setCheckSubscriptions] = useState(false);
  const [subscriptionsIndeterminate, setSubscriptionsIndeterminate] = useState(false);
  const [checkInvoice, setCheckInvoice] = useState(false);
  const [checkInventory, setCheckInventory] = useState(false);
  const [invoiceIndeterminate, setInvoiceIndeterminate] = useState(false);
  const [inventoryIndeterminate, setInventoryIndeterminate] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [contactList, setContactList] = useState(WEBHOOK_EVENTS.CONTACT);
  const [contactIndeterminate, setContactIndeterminate] = useState(false);
  const [checkContact, setCheckContact] = useState(false);
  const [fundingSourceList, setFundingSourceList] = useState(WEBHOOK_EVENTS.FUNDING_SOURCE);
  const [fundingSourceIndeterminate, setFundingSourceIndeterminate] = useState(false);
  const [checkFundingSource, setCheckFundingSource] = useState(false);
  const [transferList, setTransferList] = useState(WEBHOOK_EVENTS.TRANSFER);
  const [transferIndeterminate, setTransferIndeterminate] = useState(false);
  const [checkTransfer, setCheckTransfer] = useState(false);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    for (let i = 0; i < WEBHOOK_EVENTS.PAYMENT.length; i += 1) {
      WEBHOOK_EVENTS.PAYMENT[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.PAYOUT.length; i += 1) {
      WEBHOOK_EVENTS.PAYOUT[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.SUBSCRIPTION.length; i += 1) {
      WEBHOOK_EVENTS.SUBSCRIPTION[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.INVOICE.length; i += 1) {
      WEBHOOK_EVENTS.INVOICE[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.INVENTORY.length; i += 1) {
      WEBHOOK_EVENTS.INVENTORY[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.CONTACT.length; i += 1) {
      WEBHOOK_EVENTS.CONTACT[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.FUNDING_SOURCE.length; i += 1) {
      WEBHOOK_EVENTS.FUNDING_SOURCE[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.TRANSFER.length; i += 1) {
      WEBHOOK_EVENTS.TRANSFER[i].checked = false;
    }
    if (selectedWebhook.status === 'ACTIVE') {
      setWebhookEnabled(true);
    } else {
      setWebhookEnabled(false);
    }
    const listEvents = selectedWebhook.webhookEvent;
    if (selectedWebhook.webhookEvent && selectedWebhook.webhookEvent.length) {
      const paymentList = listEvents.filter(item => item.code.split('_')[0] === 'PAYMENT');
      const payment = paymentList.filter(({ code: id1 }) => WEBHOOK_EVENTS.PAYMENT.some(({ value: id2 }) => id2 === id1));
      const payoutList = listEvents.filter(item => item.code.split('_')[0] === 'PAYOUT');
      const payout = payoutList.filter(({ code: id1 }) => WEBHOOK_EVENTS.PAYOUT.some(({ value: id2 }) => id2 === id1));
      const subscriptionList = listEvents.filter(item => item.code.split('_')[0] === 'SUBSCRIPTION');
      const subscription = subscriptionList.filter(({ value: id1 }) => WEBHOOK_EVENTS.SUBSCRIPTION.some(({ code: id2 }) => id2 === id1));
      const invoiceList = listEvents.filter(item => item.code.split('_')[0] === 'INVOICE');
      const invoice = invoiceList.filter(({ code: id1 }) => WEBHOOK_EVENTS.INVOICE.some(({ value: id2 }) => id2 === id1));
      const inventoryLists = listEvents.filter(item => item.code.split('_')[0] === 'INVENTORY');
      const inventory = inventoryLists.filter(({ code: id1 }) => WEBHOOK_EVENTS.INVENTORY.some(({ value: id2 }) => id2 === id1));

      const contactLists = listEvents.filter(item => item.code.split('_')[0] === 'CONTACT');
      const fundingsourceLists = listEvents.filter(item => item.code.split('_')[0] === 'FUNDING');
      const transferLists = listEvents.filter(item => item.code.split('_')[0] === 'TRANSFER');
      const contact = contactLists.filter(({ code: id1 }) => WEBHOOK_EVENTS.CONTACT.some(({ value: id2 }) => id2 === id1));
      const fundingSource = fundingsourceLists.filter(({ code: id1 }) => WEBHOOK_EVENTS.FUNDING_SOURCE.some(({ value: id2 }) => id2 === id1));
      const transfer = transferLists.filter(({ code: id1 }) => WEBHOOK_EVENTS.TRANSFER.some(({ value: id2 }) => id2 === id1));
      if (payment && payment.length) {
        for (let i = 0; i < payment.length; i += 1) {
          const tempValue = paymentsList.filter((item) => item.value === payment[i].code);
          payment[i].checked = true;
          payment[i].value = payment[i].code;
          payment[i].id = tempValue[0].id;
          payment[i].display = tempValue[0].display;
        }
        const listPayments = [...paymentsList, ...payment];
        const listPayment = [...new Map(listPayments.map(item => [item.value, item])).values()];
        const list = listPayment.filter((item) => item.checked === true);
        setPaymentIndeterminate(!!list.length && list.length < paymentsList.length);
        setCheckPayments(list.length === paymentsList.length);
        setPaymentsList(listPayment);
      }
      if (payout && payout.length) {
        for (let i = 0; i < payout.length; i += 1) {
          const tempValue = payoutsList.filter((item) => item.value === payout[i].code);
          payout[i].checked = true;
          payout[i].value = payout[i].code;
          payout[i].id = tempValue[0].id;
          payout[i].display = tempValue[0].display;
        }
        const listPayouts = [...payoutsList, ...payout];
        const listPayout = [...new Map(listPayouts.map(item => [item.value, item])).values()];
        const list = listPayout.filter((item) => item.checked === true);
        setpayoutsIndeterminate(!!list.length && list.length < payoutsList.length);
        setCheckPayouts(list.length === payoutsList.length);
        setPayoutsList(listPayout);
      }
      if (subscription && subscription.length) {
        for (let i = 0; i < subscription.length; i += 1) {
          const tempValue = subscriptionsList.filter((item) => item.value === subscription[i].code);
          subscription[i].checked = true;
          subscription[i].value = subscription[i].code;
          subscription[i].id = tempValue[0].id;
          subscription[i].display = tempValue[0].display;
        }
        const listSubscriptions = [...subscriptionsList, ...subscription];
        const listSubscription = [...new Map(listSubscriptions.map(item => [item.value, item])).values()];
        const list = listSubscription.filter((item) => item.checked === true);
        setSubscriptionsIndeterminate(!!list.length && list.length < subscriptionsList.length);
        setCheckSubscriptions(list.length === subscriptionsList.length);
        setSubscriptionsList(listSubscription);
      }
      if (invoice && invoice.length) {
        for (let i = 0; i < invoice.length; i += 1) {
          const tempValue = invoicesList.filter((item) => item.value === invoice[i].code);
          invoice[i].checked = true;
          invoice[i].value = invoice[i].code;
          invoice[i].id = tempValue[0].id;
          invoice[i].display = tempValue[0].display;
        }
        const listInvoices = [...invoicesList, ...invoice];
        const listInvoice = [...new Map(listInvoices.map(item => [item.value, item])).values()];
        const list = listInvoice.filter((item) => item.checked === true);
        setInvoiceIndeterminate(!!list.length && list.length < invoicesList.length);
        setCheckInvoice(list.length === invoicesList.length);
        setInvoicesList(listInvoice);
      }
      if (inventory && inventory.length) {
        for (let i = 0; i < inventory.length; i += 1) {
          const tempValue = inventoryList.filter((item) => item.value === inventory[i].code);
          inventory[i].checked = true;
          inventory[i].value = inventory[i].code;
          inventory[i].id = tempValue[0].id;
          inventory[i].display = tempValue[0].display;
        }
        const listInventorys = [...inventoryList, ...inventory];
        const listInventory = [...new Map(listInventorys.map(item => [item.value, item])).values()];
        const list = listInventory.filter((item) => item.checked === true);
        setInventoryIndeterminate(!!list.length && list.length < inventoryList.length);
        setCheckInventory(list.length === inventoryList.length);
        setInventoryList(listInventory);
      }
      if (contact && contact.length) {
        for (let i = 0; i < contact.length; i += 1) {
          const tempValue = contactList.filter((item) => item.value === contact[i].code);
          contact[i].checked = true;
          contact[i].value = contact[i].code;
          contact[i].id = tempValue[0].id;
          contact[i].display = tempValue[0].display;
        }
        const listContacts = [...contactList, ...contact];
        const listConatct = [...new Map(listContacts.map(item => [item.value, item])).values()];
        const list = listConatct.filter((item) => item.checked === true);
        setContactIndeterminate(!!list.length && list.length < contactList.length);
        setCheckContact(list.length === contactList.length);
        setContactList(listConatct);
      }
      if (transfer && transfer.length) {
        for (let i = 0; i < transfer.length; i += 1) {
          const tempValue = transferList.filter((item) => item.value === transfer[i].code);
          transfer[i].checked = true;
          transfer[i].value = transfer[i].code;
          transfer[i].id = tempValue[0].id;
          transfer[i].display = tempValue[0].display;
        }
        const listTransfers = [...transferList, ...transfer];
        const listTransfer = [...new Map(listTransfers.map(item => [item.value, item])).values()];
        const list = listTransfer.filter((item) => item.checked === true);
        setTransferIndeterminate(!!list.length && list.length < transferList.length);
        setCheckTransfer(list.length === transferList.length);
        setTransferList(listTransfer);
      }
      if (fundingSource && fundingSource.length) {
        for (let i = 0; i < fundingSource.length; i += 1) {
          const tempValue = fundingSourceList.filter((item) => item.value === fundingSource[i].code);
          fundingSource[i].checked = true;
          fundingSource[i].value = fundingSource[i].code;
          fundingSource[i].id = tempValue[0].id;
          fundingSource[i].display = tempValue[0].display;
        }
        const listFundingSources = [...fundingSourceList, ...fundingSource];
        const listFundingSource = [...new Map(listFundingSources.map(item => [item.value, item])).values()];
        const list = listFundingSource.filter((item) => item.checked === true);
        setFundingSourceIndeterminate(!!list.length && list.length < fundingSourceList.length);
        setCheckFundingSource(list.length === fundingSourceList.length);
        setFundingSourceList(listFundingSource);
      }
      const allEvents = [...payment, ...payout, ...subscription, ...invoice, ...inventory, ...contact, ...fundingSource, ...transfer];
      setEvents(allEvents);
    }
  }, []);

  const eventChanges = (e, name) => {
    if (name === 'PAYMENT' && e.target.checked) {
      setCheckPayments(e.target.checked);
      setPaymentIndeterminate(false);
      const listItems = [...events];
      const list = [...paymentsList];
      const paymentList = webhookEvents.PAYMENT;
      const eventList = listItems.filter((item) => item.category !== name);
      const allListEvents = eventList.concat(paymentList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setPaymentsList(list);
      setEvents(allListEvents);
    } else if (name === 'PAYMENT' && !e.target.checked) {
      setCheckPayments(e.target.checked);
      setPaymentIndeterminate(false);
      const listItems = [...events];
      const list = [...paymentsList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setPaymentsList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'PAYOUT' && e.target.checked) {
      setCheckPayouts(e.target.checked);
      setpayoutsIndeterminate(false);
      const listItems = [...events];
      const list = [...payoutsList];
      const payoutList = webhookEvents.PAYOUT;
      const eventList = listItems.filter((item) => item.category !== name);
      const allListEvents = eventList.concat(payoutList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setPayoutsList(list);
      setEvents(allListEvents);
    } else if (name === 'PAYOUT' && !e.target.checked) {
      setCheckPayouts(e.target.checked);
      setCheckPayouts(false);
      const listItems = [...events];
      const list = [...payoutsList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setPayoutsList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'SUBSCRIPTION' && e.target.checked) {
      setCheckSubscriptions(e.target.checked);
      setSubscriptionsIndeterminate(false);
      const listItems = [...events];
      const list = [...subscriptionsList];
      const subscriptionList = webhookEvents.SUBSCRIPTION;
      const eventList = listItems.filter((item) => item.category !== name);
      const allListEvents = eventList.concat(subscriptionList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setSubscriptionsList(list);
      setEvents(allListEvents);
    } else if (name === 'SUBSCRIPTION' && !e.target.checked) {
      setCheckSubscriptions(e.target.checked);
      setSubscriptionsIndeterminate(false);
      const listItems = [...events];
      const list = [...subscriptionsList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setSubscriptionsList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'INVOICE' && e.target.checked) {
      setCheckInvoice(e.target.checked);
      setInvoiceIndeterminate(false);
      const listItems = [...events];
      const list = [...invoicesList];
      const invoiceList = webhookEvents.INVOICE;
      const eventList = listItems.filter((item) => item.category !== name);
      const allListEvents = eventList.concat(invoiceList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setInvoicesList(list);
      setEvents(allListEvents);
    } else if (name === 'INVOICE' && !e.target.checked) {
      setCheckInvoice(e.target.checked);
      setInvoiceIndeterminate(false);
      const listItems = [...events];
      const list = [...invoicesList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setInvoicesList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'INVENTORY' && e.target.checked) {
      setCheckInventory(e.target.checked);
      setInventoryIndeterminate(false);
      const listItems = [...events];
      const list = [...inventoryList];
      const invoiceList = webhookEvents.INVENTORY;
      const eventList = listItems.filter((item) => item.category !== name);
      const allListEvents = eventList.concat(invoiceList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setInventoryList(list);
      setEvents(allListEvents);
    } else if (name === 'INVENTORY' && !e.target.checked) {
      setCheckInventory(e.target.checked);
      setInventoryIndeterminate(false);
      const listItems = [...events];
      const list = [...inventoryList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setInventoryList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'CONTACT' && e.target.checked) {
      setCheckContact(e.target.checked);
      setContactIndeterminate(false);
      const listItems = [...events];
      const list = [...contactList];
      const invoiceList = webhookEvents.CONTACT;
      const eventList = listItems.filter((item) => item.category !== name);
      const allListEvents = eventList.concat(invoiceList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setContactList(list);
      setEvents(allListEvents);
    } else if (name === 'CONTACT' && !e.target.checked) {
      setCheckContact(e.target.checked);
      setContactIndeterminate(false);
      const listItems = [...events];
      const list = [...contactList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setContactList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'FUNDING_SOURCE' && e.target.checked) {
      setCheckFundingSource(e.target.checked);
      setFundingSourceIndeterminate(false);
      const listItems = [...events];
      const list = [...fundingSourceList];
      const invoiceList = webhookEvents.FUNDING_SOURCE;
      const eventList = listItems.filter((item) => item.category !== name);
      const allListEvents = eventList.concat(invoiceList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setFundingSourceList(list);
      setEvents(allListEvents);
    } else if (name === 'FUNDING_SOURCE' && !e.target.checked) {
      setCheckFundingSource(e.target.checked);
      setFundingSourceIndeterminate(false);
      const listItems = [...events];
      const list = [...fundingSourceList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setFundingSourceList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    }
    else if (name === 'TRANSFER' && e.target.checked) {
      setCheckTransfer(e.target.checked);
      setTransferIndeterminate(false);
      const listItems = [...events];
      const list = [...transferList];
      const invoiceList = webhookEvents.TRANSFER;
      const eventList = listItems.filter((item) => item.category !== name);
      const allListEvents = eventList.concat(invoiceList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setTransferList(list);
      setEvents(allListEvents);
    } else if (name === 'TRANSFER' && !e.target.checked) {
      setCheckTransfer(e.target.checked);
      setTransferIndeterminate(false);
      const listItems = [...events];
      const list = [...transferList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setTransferList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    }
  };

  const onCheck = (checked, category, code, index) => {
    const eventList = [...events];
    if (checked) {
      if (category === 'PAYMENT') {
        const listItems = [...paymentsList];
        const lineItem = webhookEvents.PAYMENT.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setPaymentIndeterminate(!!list.length && list.length < listItems.length);
        setCheckPayments(list.length === listItems.length);
        setPaymentsList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'PAYOUT') {
        const listItems = [...payoutsList];
        const lineItem = webhookEvents.PAYOUT.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setpayoutsIndeterminate(!!list.length && list.length < listItems.length);
        setCheckPayouts(list.length === listItems.length);
        setPayoutsList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'SUBSCRIPTION') {
        const listItems = [...subscriptionsList];
        const lineItem = webhookEvents.SUBSCRIPTION.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setSubscriptionsIndeterminate(!!list.length && list.length < listItems.length);
        setCheckSubscriptions(list.length === listItems.length);
        setSubscriptionsList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'INVOICE') {
        const listItems = [...invoicesList];
        const lineItem = webhookEvents.INVOICE.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setInvoiceIndeterminate(!!list.length && list.length < listItems.length);
        setCheckInvoice(list.length === listItems.length);
        setInvoicesList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'INVENTORY') {
        const listItems = [...inventoryList];
        const lineItem = webhookEvents.INVENTORY.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setInventoryIndeterminate(!!list.length && list.length < listItems.length);
        setCheckInventory(list.length === listItems.length);
        setInventoryList(listItems);
        setEvents(eventList.concat(lineItem));
      }
      else if (category === 'CONTACT') {
        const listItems = [...contactList];
        const lineItem = webhookEvents.CONTACT.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setContactIndeterminate(!!list.length && list.length < listItems.length);
        setCheckContact(list.length === listItems.length);
        setContactList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'FUNDING_SOURCE') {
        const listItems = [...fundingSourceList];
        const lineItem = webhookEvents.FUNDING_SOURCE.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setFundingSourceIndeterminate(!!list.length && list.length < listItems.length);
        setCheckFundingSource(list.length === listItems.length);
        setFundingSourceList(listItems);
        setEvents(eventList.concat(lineItem));
      }
      else if (category === 'TRANSFER') {
        const listItems = [...transferList];
        const lineItem = webhookEvents.TRANSFER.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setTransferIndeterminate(!!list.length && list.length < listItems.length);
        setCheckTransfer(list.length === listItems.length);
        setTransferList(listItems);
        setEvents(eventList.concat(lineItem));
      }
    } else if (!checked) {
      if (category === 'PAYMENT') {
        const listItems = [...paymentsList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setPaymentIndeterminate(!!list.length && list.length < listItems.length);
        setCheckPayments(list.length === listItems.length);
        setPaymentsList(listItems);
        setEvents(lineItem);
      } else if (category === 'PAYOUT') {
        const listItems = [...payoutsList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setpayoutsIndeterminate(!!list.length && list.length < listItems.length);
        setCheckPayouts(list.length === listItems.length);
        setPayoutsList(listItems);
        setEvents(lineItem);
      } else if (category === 'SUBSCRIPTION') {
        const listItems = [...subscriptionsList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setSubscriptionsIndeterminate(!!list.length && list.length < listItems.length);
        setCheckSubscriptions(list.length === listItems.length);
        setSubscriptionsList(listItems);
        setEvents(lineItem);
      } else if (category === 'INVOICE') {
        const listItems = [...invoicesList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setInvoiceIndeterminate(!!list.length && list.length < listItems.length);
        setCheckInvoice(list.length === listItems.length);
        setInvoicesList(listItems);
        setEvents(lineItem);
      } else if (category === 'INVENTORY') {
        const listItems = [...inventoryList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setInventoryIndeterminate(!!list.length && list.length < listItems.length);
        setCheckInventory(list.length === listItems.length);
        setInventoryList(listItems);
        setEvents(lineItem);
      } else if (category === 'CONTACT') {
        const listItems = [...contactList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setContactIndeterminate(!!list.length && list.length < listItems.length);
        setCheckContact(list.length === listItems.length);
        setContactList(listItems);
        setEvents(lineItem);
      } else if (category === 'FUNDING_SOURCE') {
        const listItems = [...fundingSourceList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setFundingSourceIndeterminate(!!list.length && list.length < listItems.length);
        setCheckFundingSource(list.length === listItems.length);
        setFundingSourceList(listItems);
        setEvents(lineItem);
      } else if (category === 'TRANSFER') {
        const listItems = [...transferList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setTransferIndeterminate(!!list.length && list.length < listItems.length);
        setCheckTransfer(list.length === listItems.length);
        setTransferList(listItems);
        setEvents(lineItem);
      }
    }
  };

  const validate = (event) => {
    setDisableButton(true);
    let status;
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    if (webhookEnabled) {
      status = 'ACTIVE';
    } else if (!webhookEnabled) {
      status = 'DEACTIVATED';
    }
    const eventList = [...events];
    for (let i = 0; i < eventList.length; i += 1) {
      eventList[i] = eventList[i].gid;
    }
    submit({
      gid,
      url,
      email,
      eventList,
      testMode,
      status,
      secret,
    });
  };

  const deleteWebhook = () => {
    deactive(gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <SpButton onClick={deleteWebhook} type="danger">Delete</SpButton>
          </Col>
          <Col>
            <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>
            <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>
          </Col>
        </Row>,
      ]}
      title="Webhook Details"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Webhook URL</SpH5></label>
          <Input
            placeholder="HTTPS URL is recommended"
            value={url}
            onChange={(e) => setUrl(e.currentTarget.value.trim())}
          />
          <SpError>
            {simpleValidator.current.message('webhook url', url, 'required|url')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Alert Email</SpH5></label>
          <Input
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value.trim())}
          />
          <SpError>
            {simpleValidator.current.message('Alert email', email, 'required|email|checkLowerCase')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Secret</SpH5></label>
          <Input.Password
            autoComplete="new-password"
            value={secret}
            onChange={(e) => setSecret(e.currentTarget.value)}
          />
          <div style={{ textAlign: 'end' }}>
            <SpTextMute className="mr-2">Recommended to add secrets for security</SpTextMute>
            <HyperLinkIcon />
          </div>
          <SpError>
            {simpleValidator.current.message('secret', secret, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Active Events</SpH5></label>
          <div
            style={{
              background: '#fff',
              padding: '5px',
              overflow: 'auto',
              height: '180px',
            }}
          >
            <div>
              <Checkbox
                className="border-light mr-2"
                indeterminate={paymentIndeterminate}
                onChange={(e) => eventChanges(e, 'PAYMENT')}
                checked={checkPayments}
              />
              <SpText fontSize="14px">Payments</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {paymentsList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'PAYMENT', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={payoutsIndeterminate}
                onChange={(e) => eventChanges(e, 'PAYOUT')}
                checked={checkPayouts}
              />
              <SpText fontSize="14px">Payout</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {payoutsList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    code={item.value}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'PAYOUT', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={subscriptionsIndeterminate}
                onChange={(e) => eventChanges(e, 'SUBSCRIPTION')}
                checked={checkSubscriptions}
              />
              <SpText fontSize="14px">Subscription</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {subscriptionsList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'SUBSCRIPTION', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={invoiceIndeterminate}
                onChange={(e) => eventChanges(e, 'INVOICE')}
                checked={checkInvoice}
              />
              <SpText fontSize="14px">Invoice</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {invoicesList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'INVOICE', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={inventoryIndeterminate}
                onChange={(e) => eventChanges(e, 'INVENTORY')}
                checked={checkInventory}
              />
              <SpText fontSize="14px">Inventory</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {inventoryList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'INVENTORY', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            {/* --------------------------contact------------------------------------- */}
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={contactIndeterminate}
                onChange={(e) => eventChanges(e, 'CONTACT')}
                checked={checkContact}
              />
              <SpText fontSize="14px">Contact</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {contactList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'CONTACT', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>

            {/* --------------------------funding-source------------------------------------- */}
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={fundingSourceIndeterminate}
                onChange={(e) => eventChanges(e, 'FUNDING_SOURCE')}
                checked={checkFundingSource}
              />
              <SpText fontSize="14px">Funding Source</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {fundingSourceList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'FUNDING_SOURCE', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            {/* --------------------------Transfer------------------------------------- */}
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={transferIndeterminate}
                onChange={(e) => eventChanges(e, 'TRANSFER')}
                checked={checkTransfer}
              />
              <SpText fontSize="14px">Transfer</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {transferList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'TRANSFER', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Mode</SpH5></label>
          <div className="d-flex">
            <Switch
              checked={testMode}
              onChange={(checked) => {
                setTestMode(!testMode);
                if (checked) {
                  setTestModeText('Test');
                } else if (!checked) {
                  setTestModeText('Live');
                }
              }}
              className="border-light mr-3"
            />
            <SpText>Webhook for {testModeText} Mode</SpText>
          </div>
        </div>
        <div>
          <label htmlFor=""><SpH5>Enable</SpH5></label>
          <div className="d-flex">
            <Switch
              checked={webhookEnabled}
              onChange={() => setWebhookEnabled(!webhookEnabled)}
              className="border-light mr-3"
            />
            <SpText>Webhook is Enabled</SpText>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.loading.submitting,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditWebhook);
