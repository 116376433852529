// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  panelSelected: Array,
};

const UpdateChanges = (props: Props) => {
  const {
    visible,
    close,
    submit,
    panelSelected,
  } = props;

  const notNow = () => {
    submit(panelSelected);
  };

  const updatePanel = () => {
    close(panelSelected);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={notNow} type="secondary">Not Now</SpButton>,
        <SpButton onClick={updatePanel}>Update</SpButton>,
      ]}
      title="Update Changes"
    >
      <div className="px-4">
        <div className="mt-1">Do you want to update your changes?</div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default UpdateChanges;
