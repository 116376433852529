import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Card,
  Row,
  Col,
} from 'antd';
import {
  SpText,
  SpButton,
} from 'components/DesignKit';
import {
  PAGES,
  ROLES,
  API_STATUS,
} from 'appconstants';
import debounce from 'lodash.debounce';
import { serviceDetailsActions } from 'store/actions';
import getBackground from 'utils/getServiceBackground';
import queryString from 'query-string';
import getServiceStatus from 'utils/getServiceStatus';

import TimelineBar from 'components/TimelineBar';
import Firs from './components/Firs';
import PaperRoll from './components/PaperRoll';
import NewTerminal from './components/NewTerminal';
import TerminalIssue from './components/TerminalIssue';
import EditService from './components/EditService';

const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: '#434343',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  history: {
    push: Function,
  },
  location: {
    pathname: string,
    search: string,
  },
  fetchServiceDetails: Function,
  serviceDetails: Object,
  selectedAccount: Object,
  test: Boolean,
  submitting: Boolean,
  isSuperUser: Boolean,
  updateServiceDetails: Function,
  serviceDetail: Object,
  fetchServiceTimeLine: Function,
  fetchFieldAgent: Function,
  fieldAgentList: Array<Object>,
  serviceTimeLine: Array<Object>,
  role: Object,
};

const ServiceDetails = (props: Props) => {
  const {
    history,
    fetchServiceDetails,
    serviceDetails,
    selectedAccount,
    fetchFieldAgent,
    test,
    submitting,
    isSuperUser,
    updateServiceDetails,
    serviceDetail,
    fetchServiceTimeLine,
    serviceTimeLine,
    fieldAgentList,
    role,
  } = props;

  const [editServiceModal, setEditServiceModal] = useState(false);
  // eslint-disable-next-line react/prop-types
  const { gid } = props.match.params;
  // eslint-disable-next-line react/prop-types
  const gidPath = gid.split('-')[1];
  const debouncedFetchFieldAgent = debounce(fetchFieldAgent, 2000, { leading: true });

  const getData = (query) => {
    if ((role && role.name) === ROLES.SUPERUSER) {
      const parsed = queryString.parse(query);
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      const filterParams = parsed.filterParams || '';
      debouncedFetchFieldAgent({ sortParams, filterParams });
    }
  };

  useEffect(() => {
    getData();
    fetchServiceDetails(gid);
    fetchServiceTimeLine(gid);
  }, [test, selectedAccount]);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.SERVICES);
    }
  }, []);

  useEffect(() => {
    if (!submitting) {
      setEditServiceModal(false);
    }
  }, [submitting]);

  const updateDetails = (service: object) => {
    const params = {
      status: service.status,
      fieldAgentLookupId: service.fieldAgentLookupId,
      internalNotes: service.content,
    };
    updateServiceDetails({
      gid,
      params,
    });
  };

  return (
    <>
      {
        serviceDetails && serviceDetails.serviceDetailsStatus === API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">
                    {serviceDetail.caseNumber || <>&#8211;</>}
                  </SpText>
                  <StatusTag style={{ backgroundColor: getBackground(serviceDetail.status) }}>
                    {getServiceStatus(serviceDetail.status)}
                  </StatusTag>
                </Col>
                <Col>
                  {
                    !isSuperUser && (
                      <SpButton
                        type="secondary"
                        shape="round"
                        ghost
                        onClick={() => setEditServiceModal(true)}
                      >
                        Update
                      </SpButton>
                    )
                  }
                </Col>
              </Row>
              <hr />
              {
                serviceDetail.serviceType === 'PAPER_ROLL' && (
                  <PaperRoll
                    serviceDetails={serviceDetail}
                    selectedAccount={selectedAccount}
                  />
                )
              }
              {
                serviceDetail.serviceType === 'FIRS' && (
                  <Firs
                    serviceDetails={serviceDetail}
                    selectedAccount={selectedAccount}
                  />
                )
              }
              {
                serviceDetail.serviceType === 'NEW_TERMINAL' && (
                  <NewTerminal
                    serviceDetails={serviceDetail}
                    selectedAccount={selectedAccount}
                  />
                )
              }
              {
                serviceDetail.serviceType === 'TERMINAL_RELATED_QUERY' && (
                  <TerminalIssue
                    serviceDetails={serviceDetail}
                    selectedAccount={selectedAccount}
                  />
                )
              }
            </Card>
            <Card>
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">
                    TIMELINE
                  </SpText>
                </Col>
              </Row>
              <Row className="pl-3">
                <TimelineBar
                  paymentTimeline={serviceTimeLine}
                  selectedAccount={selectedAccount}
                />
              </Row>
            </Card>
            {/* EDIT SERVICE MODAL */}
            {editServiceModal && (
              <EditService
                visible={editServiceModal}
                submitting={submitting}
                serviceDetails={serviceDetail}
                fieldAgentList={fieldAgentList}
                close={() => setEditServiceModal(false)}
                submit={updateDetails}
              />
            )}
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  serviceDetails: state.serviceDetails,
  serviceDetail: state.serviceDetails.serviceDetail,
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  isSuperUser: state.user.superUser,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
  serviceTimeLine: state.serviceDetails.serviceTimeLine,
  fieldAgentList: state.serviceDetails.fieldAgent.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchServiceDetails: param => dispatch(serviceDetailsActions.fetchServiceDetails({
    payload: param,
  })),
  updateServiceDetails: param => dispatch(serviceDetailsActions.updateServiceDetails({
    payload: param,
  })),
  fetchServiceTimeLine: param => dispatch(serviceDetailsActions.fetchServiceTimeLine({
    payload: param,
  })),
  fetchFieldAgent: param => dispatch(serviceDetailsActions.fetchFieldAgent({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails);
