import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  PAYMENTSBUTTONDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchPaymentsButtonDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.PAYMENTSBUTTON}/${gid}`);

const DeactivePaymentButton = async gid => axiosAuthInstance.patch(`${API_END_POINTS.PAYMENTSBUTTON}/${gid}/deactivate`);

const patchPaymentsButton = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.PAYMENTSBUTTON}/${gid}`, params);

const fetchPayments = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const queryParam = `&paymentButtonGid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYMENTS}${query}${queryParam}${sorting}`);
};

export function* getPaymentsButtonDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchPaymentsButtonDetails, action.payload);
    yield put({ type: PAYMENTSBUTTONDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTSBUTTONDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getPaymentsButtonList(action) {
  try {
    const { data: { entity } } = yield call(fetchPayments, action.payload);
    yield put({ type: PAYMENTSBUTTONDETAILS.PAYMENTS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTSBUTTONDETAILS.FAILED, payload: e.response.data });
  }
}

export function* deactivePayments(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(DeactivePaymentButton, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSBUTTON.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updatePaymentsButton(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchPaymentsButton, action.payload);
    const { data: { entity } } = yield call(fetchPaymentsButtonDetails, action.payload.gid);
    yield put({ type: PAYMENTSBUTTONDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSBUTTON.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
