import React, {
  useEffect, useState, useRef, createRef,
} from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Input,
  Select,
} from 'antd';
import { useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';
import getCountryAlpha2 from 'utils/getCountryAlpha2';

import {
  SpHead,
  SpForm,
  SpError,
  SpText,
  SpButton,
  SpTextMute,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  paymentDetailsAction,
  disputeEvidenceAction,
} from 'store/actions';
import {
  TYPES_OF_PRODUCTS,
  TYPES_OF_EVIDENCE,
  PAGES,
  API_STATUS,
} from 'appconstants';

import SupportingEvidence from './component/SupportingEvidence';
import CustomerInfo from './component/CustomerInfo';
import PhysicalProduct from './component/PhysicalProduct';
import DigitalProduct from './component/DigitalProduct';
import OfflineProduct from './component/OfflineProduct';
import ConfirmSubmission from './component/ConfirmSubmission';
import DownloadEvidence from './component/DownloadEvidence';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  fetchPaymentDetails: Function,
  fetchPaymentDisputeDetails: Function,
  paymentDetails: Object,
  paymentDisputeDetails: Object,
  loading: boolean,
  test: boolean,
  submitting: boolean,
  selectedAccount: Object,
  match: Object,
  params: Object,
  setEvidenceSelected: Function,
  submitDisputeEvidence: Function,
  validator: boolean,
  history: {
    push: Function,
  };
  paymentDetailsStatus: string,
  role: Object,
}

const DisputeEvidence = (props: Props) => {
  const {
    fetchPaymentDetails,
    fetchPaymentDisputeDetails,
    paymentDetails,
    paymentDisputeDetails,
    loading,
    test,
    selectedAccount,
    setEvidenceSelected,
    submitDisputeEvidence,
    validator,
    history,
    paymentDetailsStatus,
    submitting,
    role,
  } = props;
  const location = useLocation();
  const [, forceUpdate] = useState();
  const customerInfoRef = createRef();
  const physicalProductRef = createRef();
  const digitalProductRef = createRef();
  const offlineProductRef = createRef();
  const [refArray, setRefArray] = useState([customerInfoRef, physicalProductRef, digitalProductRef, offlineProductRef]);
  const [supportEvidence, setSupportEvidence] = useState([]);
  const [productPhysical, setProductPhysical] = useState([]);
  const [productDigital, setProductDigital] = useState([]);
  const [productOffline, setProductOffline] = useState([]);
  const [productType, setProductType] = useState('');
  const [description, setDescription] = useState(null);
  const { gid } = props.match.params;
  const [redirect, setRedirect] = useState(false);
  const [zipTag, setZipTag] = useState('');
  const [payload, setPayload] = useState({});
  const [confirmSubmitModal, setConfirmSubmitModal] = useState(false);
  const dateFormat = 'MMM DD, YYYY';

  const disableFields = useState(!!((paymentDisputeDetails && paymentDisputeDetails.disputeEvidenceDto)));

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    fetchPaymentDetails(gid);
    if ((role && role.name) === 'superuser') {
      fetchPaymentDisputeDetails(gid);
    }
    setEvidenceSelected(TYPES_OF_EVIDENCE);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if ((role && role.name) === 'superuser') {
      setProductType(paymentDisputeDetails && paymentDisputeDetails.disputeEvidenceDto && paymentDisputeDetails.disputeEvidenceDto.productType);
      // eslint-disable-next-line max-len
      setDescription(paymentDisputeDetails && paymentDisputeDetails.disputeEvidenceDto && paymentDisputeDetails.disputeEvidenceDto.productDescription);
      switch (paymentDisputeDetails && paymentDisputeDetails.disputeEvidenceDto && paymentDisputeDetails.disputeEvidenceDto.productType) {
        case ('PHYSICAL_PRODUCT'):
          setProductPhysical(existingElements => (
            [...existingElements, PhysicalProduct]
          ));
          setProductDigital(() => ([
            ...productDigital.slice(0, productDigital.length - 1),
            ...productDigital.slice(productDigital.length),
          ]));
          setProductOffline(() => ([
            ...productOffline.slice(0, productOffline.length - 1),
            ...productOffline.slice(productOffline.length),
          ]));
          break;
        case ('DIGITAL_PRODUCT'):
          setProductPhysical(() => ([
            ...productPhysical.slice(0, productPhysical.length - 1),
            ...productPhysical.slice(productPhysical.length),
          ]));
          setProductDigital(existingElements => (
            [...existingElements, DigitalProduct]
          ));
          setProductOffline(() => ([
            ...productOffline.slice(0, productOffline.length - 1),
            ...productOffline.slice(productOffline.length),
          ]));
          break;
        case ('OFFLINE_SERVICES'):
          setProductOffline(existingElements => (
            [...existingElements, OfflineProduct]
          ));
          setProductType(productType);
          setProductPhysical(() => ([
            ...productPhysical.slice(0, productPhysical.length - 1),
            ...productPhysical.slice(productPhysical.length),
          ]));
          setProductDigital(() => ([
            ...productDigital.slice(0, productDigital.length - 1),
            ...productDigital.slice(productDigital.length),
          ]));
          break;
        default:
          setProductOffline(() => ([
            ...productOffline.slice(0, productOffline.length - 1),
            ...productOffline.slice(productOffline.length),
          ]));
          setProductPhysical(() => ([
            ...productPhysical.slice(0, productPhysical.length - 1),
            ...productPhysical.slice(productPhysical.length),
          ]));
          setProductDigital(() => ([
            ...productDigital.slice(0, productDigital.length - 1),
            ...productDigital.slice(productDigital.length),
          ]));
      }
    }
  }, [location, paymentDisputeDetails]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      setSupportEvidence([]);
      setProductPhysical([]);
      setProductDigital([]);
      setProductOffline([]);
      setProductType('');
      history.push(`${PAGES.PAYMENTS}/${gid}`);
    }
  }, [validator]);

  useEffect(() => {
    const supportEvidenceRef = createRef();
    setRefArray(existingElements => (
      [...existingElements, supportEvidenceRef]
    ));
    setSupportEvidence(existingElements => (
      [...existingElements, SupportingEvidence]
    ));
  }, []);

  const addSupportEvidence = () => {
    const supportEvidenceRef = createRef();
    setRefArray(existingElements => (
      [...existingElements, supportEvidenceRef]
    ));
    setSupportEvidence(existingElements => (
      [...existingElements, SupportingEvidence]
    ));
  };

  const showSubmitEvidenceModal = () => {
    setConfirmSubmitModal(true);
  };

  // Based on selected product type creating the ref arrays.
  const setProduct = (value) => {
    switch (value) {
      case ('PHYSICAL_PRODUCT'):
        setProductPhysical(existingElements => (
          [...existingElements, PhysicalProduct]
        ));
        setProductDigital(() => ([
          ...productDigital.slice(0, productDigital.length - 1),
          ...productDigital.slice(productDigital.length),
        ]));
        setProductOffline(() => ([
          ...productOffline.slice(0, productOffline.length - 1),
          ...productOffline.slice(productOffline.length),
        ]));
        setProductType(value);
        break;
      case ('DIGITAL_PRODUCT'):
        setProductPhysical(() => ([
          ...productPhysical.slice(0, productPhysical.length - 1),
          ...productPhysical.slice(productPhysical.length),
        ]));
        setProductDigital(existingElements => (
          [...existingElements, DigitalProduct]
        ));
        setProductOffline(() => ([
          ...productOffline.slice(0, productOffline.length - 1),
          ...productOffline.slice(productOffline.length),
        ]));
        setProductType(value);
        break;
      case ('OFFLINE_SERVICES'):
        setProductOffline(existingElements => (
          [...existingElements, OfflineProduct]
        ));
        setProductType(value);
        setProductPhysical(() => ([
          ...productPhysical.slice(0, productPhysical.length - 1),
          ...productPhysical.slice(productPhysical.length),
        ]));
        setProductDigital(() => ([
          ...productDigital.slice(0, productDigital.length - 1),
          ...productDigital.slice(productDigital.length),
        ]));
        break;
      default:
        setProductOffline(() => ([
          ...productOffline.slice(0, productOffline.length - 1),
          ...productOffline.slice(productOffline.length),
        ]));
        setProductPhysical(() => ([
          ...productPhysical.slice(0, productPhysical.length - 1),
          ...productPhysical.slice(productPhysical.length),
        ]));
        setProductDigital(() => ([
          ...productDigital.slice(0, productDigital.length - 1),
          ...productDigital.slice(productDigital.length),
        ]));
        setProductType(value);
    }
  };

  const submitEvidence = async (event) => {
    let i;
    event.preventDefault();
    let physicalProductType;
    let digitalProductType;
    let offlineProductType;
    let evidenceDocuments;
    let physicalAddress;
    const documents = {};
    const documentsList = {};
    const shippingAddress = {};
    const formValidation = simpleValidator.current.allValid();
    const validationArray = [];
    // validating all required support evidences and creating a document list object to create a payload to submit evidence.
    for (i = 4; i < 4 + supportEvidence.length; i += 1) {
      evidenceDocuments = refArray[i].current.getValues();
      if (evidenceDocuments.evidenceType || evidenceDocuments.evidenceKey) {
        documents[evidenceDocuments.evidenceType] = evidenceDocuments.evidenceKey;
        Object.assign(documentsList, documents);
      }
    }

    validationArray.push(refArray[0].current.validate());
    // based on the product type we are validating and getting values from required components.
    if (productType === 'PHYSICAL_PRODUCT') {
      physicalProductType = refArray[1].current.getValues();
      if (physicalProductType.fieldsRequired) {
        validationArray.push(refArray[1].current.validate());
        shippingAddress.city = physicalProductType.shippingCity;
        shippingAddress.countryCode = getCountryAlpha2(physicalProductType.shippingCountryId);
        shippingAddress.postalCode = physicalProductType.shippingPinCode;
        shippingAddress.state = physicalProductType.shippingState;
        shippingAddress.street = physicalProductType.shippingAddress;
      }
    } else if (productType === 'DIGITAL_PRODUCT') {
      digitalProductType = refArray[2].current.getValues();
    } else if (productType === 'OFFLINE_SERVICES') {
      offlineProductType = refArray[3].current.getValues();
    }
    const formValid = validationArray.every(elem => elem === true);
    if (!formValidation || !formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const customerInfoDetails = refArray[0].current.getValues();

    const shippingDate = physicalProductType ? physicalProductType.shippingDate : null;
    const serviceDate = offlineProductType ? offlineProductType.serviceDate : null;

    if (physicalProductType && physicalProductType.shippingCity) {
      physicalAddress = shippingAddress;
    } else if (customerInfoDetails && customerInfoDetails.address
      && customerInfoDetails.cityName && customerInfoDetails.stateName
      && customerInfoDetails.pinCode && customerInfoDetails.customerCountry) {
      const address = {
        city: customerInfoDetails.cityName,
        countryCode: getCountryAlpha2(customerInfoDetails.customerCountry),
        postalCode: customerInfoDetails.pinCode,
        state: customerInfoDetails.stateName,
        street: customerInfoDetails.address,
      };
      physicalAddress = address;
    } else {
      physicalAddress = null;
    }

    // creating a payload after getting the values from the componets.
    const payloadData = {
      accessActivityLog: digitalProductType ? digitalProductType.activityLog : null,
      billingAddress: physicalAddress,
      carrierName: physicalProductType ? physicalProductType.courierName : null,
      customerEmail: customerInfoDetails ? customerInfoDetails.customerEmail : null,
      customerName: customerInfoDetails ? customerInfoDetails.customerName : null,
      customerPhone: customerInfoDetails.accountPhone ? `${customerInfoDetails.phoneCode}${customerInfoDetails.accountPhone}` : null,
      productDescription: description,
      productType,
      serviceDate: serviceDate ? moment.utc(serviceDate).toDate() : null,
      shippingDate: shippingDate ? moment.utc(shippingDate).toDate() : null,
      trackingNumber: physicalProductType ? physicalProductType.trackingNumber : null,
    };

    // adding uploaded documet keys to payload
    Object.assign(payloadData, documentsList);
    setPayload(payloadData);
    showSubmitEvidenceModal();
  };

  const submitDisputedEvidence = () => {
    setRedirect(true);
    submitDisputeEvidence({
      gid,
      payload,
    });
  };

  const cancelSubmitEvidence = () => {
    history.push(`${PAGES.PAYMENTS}/${gid}`);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        ((paymentDisputeDetails) && (paymentDisputeDetails.disputeEvidenceDto)) ? (
          <>
            <Card>
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpHead>SUBMIT DISPUTE EVIDENCE</SpHead>
                </Col>
              </Row>
              <Row className="my-2 mb-3">
                <Col>
                  <SpHead>Customer Details</SpHead>
                </Col>
              </Row>
              <CustomerInfo
                ref={refArray[0]}
                selectedAccount={selectedAccount}
                paymentDetails={paymentDetails}
                disableFields={disableFields}
                disputeEvidence={paymentDisputeDetails && paymentDisputeDetails.disputeEvidenceDto}
                zipTag={zipTag}
              />
              <Row className="my-2 mb-3">
                <Col>
                  <SpHead>Product or Service details</SpHead>
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <SpText>Type of product or Service</SpText>
                  </Col>
                  <Col span={6}>
                    <div className="w-100">
                      <Select
                        placeholder="Select Product type"
                        value={productType}
                        className="w-100"
                        onChange={setProduct}
                        disabled
                      >
                        {TYPES_OF_PRODUCTS.map((item) => (
                          <Option
                            className="text-capitalize"
                            key={item.id}
                            value={item.value}
                          >
                            {item.display}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <SpError>
                      {simpleValidator.current.message('Product type', productType, 'required')}
                    </SpError>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <SpText>Description</SpText>
                  </Col>
                  <Col span={6}>
                    <TextArea
                      rows={2}
                      placeholder="Description"
                      value={description}
                      disabled
                      onChange={e => setDescription(e.currentTarget.value)}
                    />
                  </Col>
                </Row>
              </SpForm>
              {
                productType === 'PHYSICAL_PRODUCT' && (
                  <PhysicalProduct
                    ref={refArray[1]}
                    selectedAccount={selectedAccount}
                    disableFields={disableFields}
                    // eslint-disable-next-line max-len
                    displayFormat={(paymentDisputeDetails.disputeEvidenceDto && paymentDisputeDetails.disputeEvidenceDto.shippingDate ? moment(paymentDisputeDetails.disputeEvidenceDto.shippingDate).format(dateFormat) : '')}
                    disputeEvidence={paymentDisputeDetails && paymentDisputeDetails.disputeEvidenceDto}
                    zipTag={zipTag}
                  />
                )
              }
              {
                productDigital.map((Component, index) => (
                  <DigitalProduct
                    ref={refArray[2]}
                    key={index}
                    disableFields={disableFields}
                    disputeEvidence={paymentDisputeDetails && paymentDisputeDetails.disputeEvidenceDto}
                  />
                ))
              }
              {
                productOffline.map((Component, index) => (
                  <OfflineProduct
                    ref={refArray[3]}
                    key={index}
                    disableFields={disableFields}
                    // eslint-disable-next-line max-len
                    displayFormat={(paymentDisputeDetails.disputeEvidenceDto && paymentDisputeDetails.disputeEvidenceDto.serviceDate ? moment(paymentDisputeDetails.disputeEvidenceDto.serviceDate).format(dateFormat) : '')}
                  />
                ))
              }
              <Row className="my-2 mb-3">
                <Col>
                  <SpHead>Supporting Evidence</SpHead>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SpTextMute>
                    Submit below recommended documents to support
                    your case
                  </SpTextMute>
                </Col>
              </Row>
              <DownloadEvidence
                disputeEvidence={paymentDisputeDetails && paymentDisputeDetails.disputeEvidenceDto}
              />
              <SpForm>
                <Row type="flex" justify="start" align="middle" className="my-2 mb-3">
                  <Col span={3}>
                    <SpText>Additional Information (Optional)</SpText>
                  </Col>
                  <Col span={6}>
                    <TextArea
                      rows={2}
                      disabled
                      placeholder="Additional Information"
                    />
                  </Col>
                </Row>
              </SpForm>
            </Card>
          </>
        ) : (
          <>
            {
              paymentDetails.gid && paymentDetailsStatus === API_STATUS.SUCCESS && (
                <>
                  <Card>
                    <Row type="flex" justify="space-between" className="my-2 mb-3">
                      <Col>
                        <SpHead>SUBMIT DISPUTE EVIDENCE</SpHead>
                      </Col>
                    </Row>
                    <Row className="my-2 mb-3">
                      <Col>
                        <SpHead>Customer Details</SpHead>
                      </Col>
                    </Row>
                    <CustomerInfo
                      ref={refArray[0]}
                      selectedAccount={selectedAccount}
                      paymentDetails={paymentDetails}
                      zipTag={zipTag}
                    />
                    <Row className="my-2 mb-3">
                      <Col>
                        <SpHead>Product or Service details</SpHead>
                      </Col>
                    </Row>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={3}>
                          <SpText>Type of product or Service</SpText>
                        </Col>
                        <Col span={6}>
                          <div className="w-100">
                            <Select
                              placeholder="Select Product type"
                              className="w-100"
                              onChange={setProduct}
                            >
                              {TYPES_OF_PRODUCTS.map((item) => (
                                <Option
                                  className="text-capitalize"
                                  key={item.id}
                                  value={item.value}
                                >
                                  {item.display}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <SpError>
                            {simpleValidator.current.message('Product type', productType, 'required')}
                          </SpError>
                        </Col>
                      </Row>
                    </SpForm>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={3}>
                          <SpText>Description</SpText>
                        </Col>
                        <Col span={6}>
                          <TextArea
                            rows={2}
                            placeholder="Description"
                            onChange={e => setDescription(e.currentTarget.value)}
                          />
                        </Col>
                      </Row>
                    </SpForm>
                    {
                      productPhysical.map((Component, index) => (
                        <PhysicalProduct
                          ref={refArray[1]}
                          key={index}
                          selectedAccount={selectedAccount}
                          zipTag={zipTag}
                        />
                      ))
                    }
                    {
                      productDigital.map((Component, index) => (
                        <DigitalProduct
                          ref={refArray[2]}
                          key={index}
                        />
                      ))
                    }
                    {
                      productOffline.map((Component, index) => (
                        <OfflineProduct
                          ref={refArray[3]}
                          key={index}
                        />
                      ))
                    }
                    <Row className="my-2 mb-3">
                      <Col>
                        <SpHead>Supporting Evidence</SpHead>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SpTextMute>
                          Submit below recommended documents to support
                          your case
                        </SpTextMute>
                      </Col>
                    </Row>
                    {
                      supportEvidence.map((Component, index) => (
                        <Component
                          ref={refArray[4 + index]}
                          key={index}
                        />
                      ))
                    }
                    <Row>
                      <Col>
                        <SpButton
                          type="secondary"
                          shape="round"
                          disabled={supportEvidence.length === TYPES_OF_EVIDENCE.length}
                          onClick={addSupportEvidence}
                        >
                          Add another evidence
                        </SpButton>
                      </Col>
                    </Row>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle" className="my-2 mb-3">
                        <Col span={3}>
                          <SpText>Additional Information (Optional)</SpText>
                        </Col>
                        <Col span={6}>
                          <TextArea
                            rows={2}
                            placeholder="Additional Information"
                          />
                        </Col>
                      </Row>
                    </SpForm>
                    <Row type="flex" justify="end" gutter={[16, 16]}>
                      <Col>
                        <SpButton
                          type="secondary"
                          shape="round"
                          onClick={cancelSubmitEvidence}
                        >
                          Cancel
                        </SpButton>
                      </Col>
                      <Col>
                        <SpButton
                          type="primary"
                          shape="round"
                          onClick={submitEvidence}
                        >
                          Submit
                        </SpButton>
                      </Col>
                    </Row>
                  </Card>
                  {/* SUBMIT EVIDENCE MODAL */}
                  {confirmSubmitModal && (
                    <ConfirmSubmission
                      visible={confirmSubmitModal}
                      submitting={submitting}
                      close={() => setConfirmSubmitModal(false)}
                      submit={submitDisputedEvidence}
                    />
                  )}
                </>
              )
            }
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  paymentDetails: state.paymentDetails,
  paymentDisputeDetails: state.paymentDetails.paymentDisputeDetails,
  paymentDetailsStatus: state.paymentDetails.paymentDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentDetails: gid => dispatch(paymentDetailsAction.fetchPaymentDetails({
    payload: gid,
  })),
  setEvidenceSelected: evidences => dispatch(disputeEvidenceAction.setEvidenceSelected({
    payload: evidences,
  })),
  submitDisputeEvidence: param => dispatch(disputeEvidenceAction.submitDisputeEvidence({
    payload: param,
  })),
  fetchPaymentDisputeDetails: gid => dispatch(paymentDetailsAction.fetchPaymentDisputeDetails({
    payload: gid,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisputeEvidence);
