// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Select,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  MAX_AMOUNT,
  COUNTRIES,
} from 'appconstants';
import {
  validators,
} from 'utils/validationMessages';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  inventoryCategories: Array,
  menuOperatingHours: Array,
  selectedMenu: Object,
};

const EditMenu = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    inventoryCategories,
    menuOperatingHours,
    selectedMenu,
  } = props;

  const [, forceUpdate] = useState();
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [name, setName] = useState(selectedMenu && selectedMenu.name ? selectedMenu.name : '');
  const [disableButton, setDisableButton] = useState(false);
  const [categories, setCategories] = useState((selectedMenu && selectedMenu.categories && selectedMenu.categories.length !== 0)
    ? selectedMenu.categories.map((option) => option.gid) : []);
  const [operatingHours, setOperatingHours] = useState((selectedMenu.operatingHoursMaster && selectedMenu.operatingHoursMaster.gid) || '');
  // eslint-disable-next-line no-unused-vars
  const [availableHours, setAvailableHours] = useState(menuOperatingHours || []);
  const [isItemAvailable, setIsItemAvailable] = useState(selectedMenu.available);
  const STRING_LENGTH = 1;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      minLimit: {
        message: 'Sort order must be valid',
        rule: val => val >= 0 && val < 999999,
      },
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      numericValidation: {
        message: 'Sort order must be valid',
        rule: (val) => (val ? !val.toString().includes('.') : true),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const handleSelectChange = (values) => {
    setCategories(values);
  };

  const handleSelectChangeHours = (values) => {
    setOperatingHours(values);
  };

  const isAvailableChange = (value) => {
    setIsItemAvailable(value);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      selectedGid: selectedMenu.gid,
      name,
      categories,
      operatingHours,
      isItemAvailable,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update Menu</SpButton>,
      ]}
      title="Update Menu"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            onChange={(e) => setName(e.currentTarget.value)}
            value={name}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required|stringLength')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Add Category</SpH5></label>
          <Select
            className="w-100"
            mode="multiple"
            allowClear
            placeholder="Select item"
            onChange={handleSelectChange}
            value={categories}
            showSearch
            optionFilterProp="name"
            style={{ width: '100%' }}
          >
            <Option
              key={1}
              name="defautInfo"
              value="defautInfo"
              disabled
              style={{ color: '#272727', borderBottom: '1px solid #8c8a8a' }}
            >
              {categories.length} selected | (Out of {inventoryCategories.length})
            </Option>
            {inventoryCategories.map((item) => (
              <Option key={item.gid} name={item.name} value={item.gid}>
                {item.name} ({item.itemCount} items)
              </Option>
            ))}
          </Select>
          <SpError>
            {simpleValidator.current.message('category', categories, 'required')}
          </SpError>
        </div>
        <div style={{ display: 'none' }}>
          <label htmlFor=""><SpH5>Select Operating Hours</SpH5></label>
          <Select
            className="w-100"
            allowClear
            placeholder="Select item"
            onChange={handleSelectChangeHours}
            value={operatingHours}
            showSearch
            // optionFilterProp="name"
            style={{ width: '100%' }}
          >
            {availableHours.map((item) => (
              <Option key={item.gid} name={item.name} value={item.gid}>
                {item.name}
              </Option>
            ))}
          </Select>
          {/* <SpError>
            {simpleValidator.current.message('operating hours', operatingHours, 'required')}
          </SpError> */}
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Select Operating Hours</SpH5></label>
          <Select
            className="w-100"
            allowClear
            placeholder="Select item"
            onChange={handleSelectChangeHours}
            value={operatingHours}
            showSearch
            optionFilterProp="name"
            style={{ width: '100%' }}
          >
            {availableHours.map((item) => (
              <Option key={item.gid} name={item.name} value={item.gid}>
                {item.name}
              </Option>
            ))}
          </Select>
          <SpError>
            {simpleValidator.current.message('operating hours', operatingHours, 'required')}
          </SpError>
        </div>
        <div>
          <Switch
            className="mr-2"
            checked={isItemAvailable}
            onChange={isAvailableChange}
            // disabled
          /> AVAILABLE
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditMenu);
