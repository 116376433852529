import { AFFILIATE } from '../actionTypes';

export const fetchAffiliate = (action) => ({
  type: AFFILIATE.FETCH,
  payload: action.payload,
});

export const addAffiliate = (action) => ({
  type: AFFILIATE.ADD,
  payload: action.payload,
});

export const notifyAffiliate = (action) => ({
  type: AFFILIATE.NOTIFY,
  payload: action.payload,
});
