import { ISSUER_RATESDETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  issuerrateDetailsStatus: undefined,
  issuerrateDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ISSUER_RATESDETAILS.FETCH:
      return {
        ...state,
        issuerrateDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case ISSUER_RATESDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        issuerrateDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case ISSUER_RATESDETAILS.FAILED:
      return {
        ...state,
        issuerrateDetailsError: action.payload,
        issuerrateStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
