import { QUICK_SUPPORT_SUMMARY } from '../actionTypes';

export const fetchQuickSupportSummary = (action) => ({
  type: QUICK_SUPPORT_SUMMARY.FETCH,
  payload: action.payload,
});

export const updateQuickSupportSummary = (action) => ({
  type: QUICK_SUPPORT_SUMMARY.UPDATE,
  payload: action.payload,
});

export const fetchQuickSupportSection = (action) => ({
  type: QUICK_SUPPORT_SUMMARY.FETCH_SECTION,
  payload: action.payload,
});
