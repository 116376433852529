import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';
import generateQuery from 'utils/generateQuery';

import {
  INVENTORY_ORDERS_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryOrderDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ORDER}/${gid}`);

const fetchPayments = async (payload) => {
  const query = generateQuery((payload.currentPage) || 1);
  const queryParam = `&inventoryOrderGid=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_PAYMENTS}${query}${queryParam}${sorting}`);
};

const postrefundOrder = async ({ gid, params }) => axiosAuthInstance.post(`${API_END_POINTS.GET_INVENTORY_ORDER}/${gid}/full-refund`, params);

// eslint-disable-next-line max-len
const deleteOrderItem = ({ gid, params }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INVENTORY_ORDER}/${gid}/line-items`, { data: params });

const lineitemrefund = ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_ORDER}/${gid}/refund-line-items`, params);

const deleteInventoryOrder = ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INVENTORY_ORDER}/${gid}`);

const postOrderItem = ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_ORDER}/${gid}/line-items`, params);

export function* getInventoryOrderDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryOrderDetails, action.payload);
      yield put({ type: INVENTORY_ORDERS_DETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchInventoryOrderDetails, action);
      yield put({ type: INVENTORY_ORDERS_DETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: INVENTORY_ORDERS_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* refundOrderDetails(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postrefundOrder, action.payload);
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryOrderDetails, action.payload && action.payload.gid);
      yield put({ type: INVENTORY_ORDERS_DETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchInventoryOrderDetails, action);
      yield put({ type: INVENTORY_ORDERS_DETAILS.SUCCESS, payload: entity });
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSDETAILS.REFUND_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteOrderLineItem(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteOrderItem, action.payload);
    const { data: { entity } } = yield call(fetchInventoryOrderDetails, action.payload && action.payload.gid);
    yield put({ type: INVENTORY_ORDERS_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Line item removed successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}


export function* refundOrderLineItem(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(lineitemrefund, action.payload);
    const { data: { entity } } = yield call(fetchInventoryOrderDetails, action.payload && action.payload.gid);
    yield put({ type: INVENTORY_ORDERS_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Line item Refund successfully updated',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* deleteOrderDetails(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteInventoryOrder, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Order deleted successfully',
    });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getInventoryPayments(action) {
  try {
    const { data: { entity } } = yield call(fetchPayments, action.payload);
    yield put({ type: INVENTORY_ORDERS_DETAILS.PAYMENTS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_ORDERS_DETAILS.FAILED, payload: e.response.data });
  }
}

export function* addItemOrder(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(postOrderItem, action.payload);
    const { data: { entity } } = yield call(fetchInventoryOrderDetails, action.payload && action.payload.gid);
    yield put({ type: INVENTORY_ORDERS_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Item added successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
