import { INVENTORY_CATEGORIES_DETAILS } from '../actionTypes';

const initialState = {
  content: [],
  categoryItemsList: [],
  shopList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_CATEGORIES_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case INVENTORY_CATEGORIES_DETAILS.CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        categoryItemsList: action.payload,
      };
    case INVENTORY_CATEGORIES_DETAILS.FETCH_ALL_SHOP_SUCCESS:
      return {
        ...state,
        shopList: action.payload,
      };
    default:
      return state;
  }
};
