import { ADVANCEFEES } from '../actionTypes';

export const fetchAdvanceFee = (action) => ({
  type: ADVANCEFEES.FETCH,
  payload: action.payload,
});

export const addAdvanceFee = (action) => ({
  type: ADVANCEFEES.ADD,
  payload: action.payload,
});

export const updateAdvanceFee = (action) => ({
  type: ADVANCEFEES.UPDATE,
  payload: action.payload,
});

export const deleteAdvanceFee = (action) => ({
  type: ADVANCEFEES.DELETE,
  payload: action.payload,
});

export const fetchAdvanceFeeById = (action) => ({
  type: ADVANCEFEES.FETCH_BY_ID,
  payload: action.payload,
});

export const fetchAddedItemsList = (action) => ({
  type: ADVANCEFEES.FETCH_ADD_ITEMS,
  payload: action.payload,
});
