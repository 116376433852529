import { ADMIN_BIN } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  adminBinStatus: undefined,
  adminBinError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_BIN.FETCH:
      return {
        ...state,
        adminBinStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case ADMIN_BIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
        adminBinStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case ADMIN_BIN.FAILED:
      return {
        ...state,
        adminBinError: action.payload,
        adminBinStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
