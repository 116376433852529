// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';

import {
  PAGES,
} from 'appconstants';
import {
  SpButton,
} from 'components/DesignKit';

import { staticqrActions, statusParamsActions } from 'store/actions';
import { STATUS_SPECS } from 'store/actionTypes';
import moment from 'moment-timezone';

import ExportIcon from 'components/NavIcons/exportIcon';
import AddStaticQR from './components/AddStaticQr';

type Props = {
  fetchStaticQR: Function,
  staticqr: Array<Object>,
  loading: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  submitting: boolean,
  test: boolean,
  addStaticQR: Function,
  addBulkStaticQR: Function,
  setStatusData: Function,
  validator: boolean,
};

const StaticQrList = (props: Props) => {
  const {
    loading,
    fetchStaticQR,
    staticqr,
    totalElements,
    history,
    test,
    selectedAccount,
    submitting,
    addStaticQR,
    validator,
    setStatusData,
    addBulkStaticQR,
  } = props;

  const location = useLocation();
  const [fillColor, setFillColor] = useState(false);
  const [pagination, setPagination] = useState({});
  const [addStaticQRModal, setAddStaticQRModal] = useState(false);
  const parsedQrType = queryString.parse(location.search);
  const qrTypes = parsedQrType['status.EQ'];

  const exportData = staticqr ? staticqr.map((values, id) => ({
    name: `QR Code ${id + 1}`,
    url: (values && values.url),
  })) : '';

  const exportHeaders = [
    { label: 'Filename', key: 'name' },
    { label: 'URL', key: 'url' },
  ];

  const debouncedfetchStaticQR = debounce(fetchStaticQR, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const searchParam = parsed['status.EQ'];
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    debouncedfetchStaticQR({
      searchParam,
      currentPage,
      sortParams,
      filterParams,
    });
  };

  useEffect(() => {
    getData(location.search);
  }, [selectedAccount, location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [staticqr]);

  useEffect(() => {
    if (!validator) {
      setAddStaticQRModal(false);
    }
  }, [validator]);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const searchParam = parsed['status.EQ'];
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.STATICQR,
        search: `?status.EQ=${searchParam}&filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.STATICQR,
        search: `?status.EQ=${searchParam}&pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const addNewStaticQR = (qrstatic: Object) => {
    const parsed = queryString.parse(location.search);
    const searchParam = parsed['status.EQ'];
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      tipAvailable,
      accountGid,
      qrType,
      count,
    } = qrstatic;

    let tip;
    if (tipAvailable === 'Yes') {
      tip = true;
    } else {
      tip = false;
    }
    let isTest;
    if (test) {
      isTest = test;
    } else {
      isTest = false;
    }
    const params = {
      tipEnabled: tip,
      accountGid,
      test: isTest,
    };
    if (qrType === 'Linked') {
      addStaticQR({
        params,
        searchParam,
        currentPage,
        sortParams,
      });
    } else {
      addBulkStaticQR({
        count,
        searchParam,
        currentPage,
        sortParams,
      });
    }
  };

  const selectedRecord = (record) => {
    setStatusData({
      status: qrTypes,
    });
    const staticqrGid = record.gid;
    history.push(`${PAGES.STATICQR}/${staticqrGid}`);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'code',
      width: '40%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (code) => (
        <span>
          {code || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const nonLinkedColumns = [
    {
      title: 'ACCOUNT',
      dataIndex: 'accountname',
      width: '40%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (accountname, record) => {
        if (record && record.staticQrAccount && record.staticQrAccount.account) {
          const acntName = record.staticQrAccount.account.name;
          if (acntName) {
            return (<span>{acntName}</span>);
          } return (<span>&#8211;</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'PORTAL',
      dataIndex: 'portal',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (portal, record) => {
        if (record && record.staticQrAccount && record.staticQrAccount.portal) {
          const portalName = record.staticQrAccount.portal.test;
          if (portalName === true) {
            return (<span>test</span>);
          } return (<span>live</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <Row className="my-2">
          <div className="d-flex justify-content-end">
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                ghost
                className="mr-3"
                onClick={() => setAddStaticQRModal(true)}
              >
                {'\u002B'}&nbsp;Static QR
              </SpButton>
              <span>
                {
                  (qrTypes === 'NONLINKED') && (
                    exportData !== null
                      ? (
                        <Tooltip placement="left" title="Click here to export Static QR">
                          <CSVLink
                            target="_blank"
                            className="mr-3"
                            filename="Static QR Report.csv"
                            data={exportData}
                            headers={exportHeaders}
                            onMouseEnter={() => setFillColor(true)}
                            onMouseLeave={() => setFillColor(false)}
                          >
                            <ExportIcon
                              fill={fillColor
                                ? '#279dfe'
                                : '#C0C0C0'}
                            />
                          </CSVLink>
                        </Tooltip>
                      ) : null
                  )
                }
              </span>
            </Col>
          </div>
        </Row>

        {/* ADD VENDOR MODAL */}
        {addStaticQRModal && (
          <AddStaticQR
            visible={addStaticQRModal}
            submitting={submitting}
            selectedAccount={selectedAccount}
            country={selectedAccount.country.name}
            cntryId={selectedAccount.country.id}
            close={() => setAddStaticQRModal(false)}
            submit={addNewStaticQR}
          />
        )}

        <Table
          rowClassName={() => 'ant-table-clickable-row'}
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          columns={qrTypes === 'LINKED' ? nonLinkedColumns : columns}
          rowKey={(record) => record.gid}
          dataSource={staticqr}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  staticqr: state.staticqr.content,
  loading: state.loading.loading,
  test: state.account.test,
  totalElements: state.staticqr.totalElements,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStaticQR: param => dispatch(staticqrActions.fetchStaticQR({
    payload: param,
  })),
  addStaticQR: param => dispatch(staticqrActions.addStaticQR({
    payload: param,
  })),
  addBulkStaticQR: param => dispatch(staticqrActions.addBulkStaticQR({
    payload: param,
  })),
  setStatusData: (value) => dispatch(statusParamsActions.setStatusData({
    type: STATUS_SPECS.SET_STATUS_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(StaticQrList);
