import React from 'react';
import {
  SpText, SpCaption, AuthWrapper, AuthPanel,
} from 'components/DesignKit';

import swireLogo from 'imgs/swirepay.png';


const DocusignLater = () => (
  <AuthWrapper>
    <AuthPanel>
      <img src={swireLogo} alt="" style={{ width: '270px', margin: '32px 0px' }} />
      <div
        className="mb-4"
      >
        <SpText
          fontSize="18px"
          color="#000"
        >
          Please review and sign for us to activate your account.
        </SpText>
      </div>
      <div className="mb-5 text-center">
        <SpCaption>Please click on either OK or Cancel to return to your Swirepay dashboard
        </SpCaption>
      </div>
    </AuthPanel>
  </AuthWrapper>
);

export default DocusignLater;
