import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Button,
} from 'antd';
import {
  authActions,
} from 'store/actions';
import {
  PAGES,
} from 'appconstants';

// $FlowFixMe
const Wrapper = styled.div`
  background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '24px 15px',
  alignItems: 'center',
  width: '511px',
  minHeight: '250px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

type Props = {
  logout: Function,
  history: {
    push: Function,
  },
}

const Posuser = (props: Props) => {
  const { logout, history } = props;

  const logOut = () => {
    history.push(PAGES.LOGIN);
    logout();
  };

  return (
    <Wrapper>
      <FormWrapper>
        <h6 className="mb-3"><span className="font-weight-bold">Please login using Terminal</span></h6>
        <div className="d-flex justify-content-center mt-3">
          <Button
            type="primary"
            shape="round"
            className="w-100"
            htmlType="submit"
            onClick={logOut}
          >
            Logout
          </Button>
        </div>
      </FormWrapper>
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.logout()),
});

export default connect(null, mapDispatchToProps)(Posuser);
