import { SERVICEDETAILS } from '../actionTypes';

export const fetchServiceDetails = (action) => ({
  type: SERVICEDETAILS.FETCH,
  payload: action.payload,
});

export const updateServiceDetails = (action) => ({
  type: SERVICEDETAILS.UPDATE,
  payload: action.payload,
});

export const fetchServiceTimeLine = (action) => ({
  type: SERVICEDETAILS.FETCH_TIMELINE,
  payload: action.payload,
});

export const fetchFieldAgent = (action) => ({
  type: SERVICEDETAILS.FIELDAGENT_FETCH,
  payload: action.payload,
});
