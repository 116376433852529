import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import {
  Row,
  Col,
  Select,
} from 'antd';
import {
  SpForm,
  SpText,
  SpError,
} from 'components/DesignKit';
import NewBankAccount from './NewBankAccount';
import AddNewAccount from './AddNewAccount';
import PlaidAccount from './PlaidAccount';

const { Option } = Select;

type Props = {
  history: {
    push: Function,
  },
  walletBanks: Array<Object>,
  submitting: boolean,
  newAccount: Function,
};

const WithdrawBalance = (props: Props) => {
  const {
    walletBanks,
    submitting,
    newAccount,
  } = props;
  const [addNewAccountModal, setAddNewAccountModal] = useState(false);
  const [plaidAccountModal, setPlaidAccountModal] = useState(false);
  const [accountModal, setAccountModal] = useState(false);
  const [bankDisplayName, setBankDisplayName] = useState('');
  const [bankGid, setBankGid] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator({}));

  useEffect(() => {
    if (!submitting) {
      setBankDisplayName('');
      setBankGid('');
      setAccountModal(false);
      simpleValidator.current.hideMessages();
    }
  }, [submitting]);

  const addNewDebitCard = () => {
    console.log(bankGid, 'test');
  };

  const newAccountType = (e) => {
    const val = e ? e.target.value : 'Plaid';
    if (val === 'Plaid') {
      setAddNewAccountModal(false);
      setPlaidAccountModal(true);
    } else if (val === 'Account') {
      setAddNewAccountModal(false);
      setAccountModal(true);
    }
  };

  const addNewBankAccount = (data) => {
    newAccount(data);
  };

  const handleBank = (e) => {
    setBankDisplayName(e[0]);
    setBankGid(e[1]);
  };

  return (
    <div className="my-2 mt-4">
      <SpForm>
        <Row>
          <Col span={8}>
            <>
              <label htmlFor="">
                <SpText fontWeight="600" fontSize="14px" color="#292929">Select Account</SpText>
              </label>
              <Select
                style={{ width: '100%' }}
                value={bankDisplayName || 'Select account'}
                onChange={handleBank}
              >
                {
                  walletBanks.map((option) => (
                    <Option
                      key={option.gid}
                      value={[
                        option.bankName,
                        option.gid,
                      ]}
                    >
                      {option.bankName}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('Bank account', bankDisplayName, 'required')}
              </SpError>
              <SpText
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'end',
                  paddingTop: '5px',
                }}
                fontSize="14px"
                fontWeight="600"
                color="#3E8BFF"
                onClick={() => setAddNewAccountModal(true)}
              >Manage Accounts
              </SpText>
            </>
          </Col>
        </Row>
      </SpForm>
      {/* ADD PAYMENTCHECKOUT MODAL */}
      {addNewAccountModal && (
        <AddNewAccount
          visible={addNewAccountModal}
          submitting={false}
          countryId={1}
          selectedAccount={{}}
          supportedCurrency={{}}
          paymentCheckoutDetails={{}}
          close={() => setAddNewAccountModal(false)}
          submit={addNewDebitCard}
          newAccountType={newAccountType}
          walletBanks={walletBanks}
        />
      )}
      {/* ADD PAYMENTCHECKOUT MODAL */}
      {accountModal && (
        <NewBankAccount
          visible={accountModal}
          submitting={false}
          countryId={1}
          selectedAccount={{}}
          supportedCurrency={{}}
          paymentCheckoutDetails={{}}
          close={() => setAccountModal(false)}
          submit={addNewBankAccount}
        />
      )}
      {/* ADD PAYMENTCHECKOUT MODAL */}
      {plaidAccountModal && (
        <PlaidAccount
          visible={plaidAccountModal}
          submitting={false}
          countryId={1}
          selectedAccount={{}}
          supportedCurrency={{}}
          paymentCheckoutDetails={{}}
          close={() => setPlaidAccountModal(false)}
          submit={addNewDebitCard}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  selectedAccount: state.account.selectedAccount,
});

export default connect(mapStateToProps, null)(WithdrawBalance);
