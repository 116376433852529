export default status => {
  if (status === 'INVALID_STATE') {
    return '#FFDDC3';
  } if (status === 'DEACTIVATED') {
    return '#ffe0b1';
  } if (status === 'ACTIVATION_PENDING') {
    return '#e2e2e2';
  } if (status === 'ACTIVE') {
    return '#ececff';
  }
  return null;
};
