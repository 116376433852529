/* eslint-disable */
import React from 'react';

const CollapseArr = () => (
    <svg width="30" height="53" viewBox="0 0 20 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1_1716)">
            <path d="M4 0H6C11.5228 0 16 4.47715 16 10V24C16 29.5228 11.5228 34 6 34H4V0Z" fill="#12284A" />
            <path d="M12.7712 16.2853L7.13623 11.884C7.1215 11.8725 7.10381 11.8653 7.08517 11.8633C7.06654 11.8613 7.04772 11.8646 7.03088 11.8728C7.01404 11.881 6.99986 11.8938 6.98998 11.9098C6.98009 11.9257 6.97489 11.9441 6.97498 11.9628V12.929C6.97498 12.9903 7.00373 13.049 7.05123 13.0865L11.5512 16.6003L7.05123 20.114C7.00248 20.1515 6.97498 20.2103 6.97498 20.2715V21.2378C6.97498 21.3215 7.07123 21.3678 7.13623 21.3165L12.7712 16.9153C12.8191 16.8779 12.8579 16.8302 12.8845 16.7756C12.9112 16.721 12.925 16.661 12.925 16.6003C12.925 16.5396 12.9112 16.4796 12.8845 16.425C12.8579 16.3704 12.8191 16.3227 12.7712 16.2853Z" fill="white" />
        </g>
        <defs>
            <filter id="filter0_d_1_1716" x="0" y="0" width="20" height="42" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_1716" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_1716" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default CollapseArr;