// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Select,
  Card,
  Row,
  Col,
  Button,
  Checkbox,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpError, SpButton, SpText, CardSchemeIcon,
} from 'components/DesignKit';
import {
  CARD_SCHEMAS,
} from 'appconstants';
import { walletDetailsActions } from 'store/actions';
import getValidSchema from 'utils/getValidSchema';
import DeleteCard from './DeleteCard';
import UpdateCard from './UpdateCard';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  deleteCard: Function,
  updateCard: Function,
  walletCards: Array<object>,
};

const AddNewDebitCard = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    walletCards,
    deleteCard,
    updateCard,
  } = props;

  const [, forceUpdate] = useState();
  const [cardNumber, setCardNumber] = useState('');
  const [cardType, setCardType] = useState('');
  const [cardLength, setCardLength] = useState(16);
  const [hasCardErrors, setHasCardErrors] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [expiry, setExpiry] = useState('');
  const [hasExpiryErrors, setHasExpiryErrors] = useState(false);
  const [cvv, setCvv] = useState('');
  const [hasCvvError, setCvvHasError] = useState(false);
  const [name, setName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('United States');
  const [defaultCard, setDefaultCard] = useState(false);
  const [defaultCard1, setDefaultCard1] = useState(false);
  const [deleteCardModal, setDeleteCardModal] = useState(false);
  const [updateCardModal, setUpdateCardModal] = useState(false);
  const [debitCardInfo, setDebitCardInfo] = useState('');
  const [debitCardInfo1, setDebitCardInfo1] = useState('');
  const countryId = selectedAccount && selectedAccount.country.alpha2;
  const postalCodeRegex = countryId === 'US' ? /^[0-9][0-9]{4}$/ : /^[1-9][0-9]{5}$/;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      postalcode: {
        message: 'Invalid zip code',
        rule: val => (postalCodeRegex.test(val)),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  console.log(walletCards, 'walletCards');

  const validateCardType = () => {
    const maxLength = cardNumber.substring(0, cardLength);
    const visaCard = ['4'];
    const masterCard = ['51', '52', '53', '54', '55'];
    const dinersCard = ['36', '38', '54', '55'];
    const cartCard = ['300', '301', '302', '303', '304', '305'];
    const amexCard = ['34', '37'];
    const discoverCard = ['60', '62', '64', '65'];
    const jcbCard = ['35'];
    const enRouteCard = ['2014', '2149'];
    const soloCard = ['6334', '6767'];
    const masteroCard = [
      '5018',
      '5020',
      '5038',
      '6304',
      '6759',
      '6761',
      '6762',
      '6763',
    ];
    const visaElectronCard = ['4026', '417500', '4508', '4844', '4913', '4917'];
    const laserCard = ['6304', '6706', '6771', '6709'];

    if (
      (maxLength.length === 13 || maxLength.length === 16)
      && visaCard.includes(maxLength.substring(0, 1))
    ) {
      setCardType('VISA');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
      && masterCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('MASTER');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 14 || maxLength.length === 16)
      && dinersCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('DINERS CLUB');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 14
      && cartCard.includes(maxLength.substring(0, 3))
    ) {
      setCardType('CARTE BLANCHE');
      setCardLength(14);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 15
      && amexCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('AMEX');
      setCardLength(15);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
      && discoverCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('DISCOVER');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
      && jcbCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('JCB');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 15
      && enRouteCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('ENROUTE');
      setCardLength(15);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 16
        || maxLength.length === 18
        || maxLength.length === 19)
      && soloCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('SOLO');
      setCardLength(19);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 12
        || maxLength.length === 13
        || maxLength.length === 14
        || maxLength.length === 15
        || maxLength.length === 16
        || maxLength.length === 18
        || maxLength.length === 19)
      && masteroCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('MAESTRO');
      setCardLength(19);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
      && visaElectronCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('VISA ELECTRON');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 16
        || maxLength.length === 18
        || maxLength.length === 19)
      && laserCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('LASER CARD');
      setCardLength(19);
      setHasCardErrors(false);
    } else if (maxLength.length > 1) {
      setCardType('');
      setHasCardErrors(true);
    }
  };

  const validateCard = (text) => {
    const sample = text.replace(/[^\d]/g, '');
    const maxLength = sample.substring(0, cardLength);
    if (maxLength.length > 0) {
      const joy = maxLength.match(/.{1,4}/g);
      setCardNumber(joy.join(' '));
    } else {
      setCardNumber('');
      setCardType('');
      setHasCardErrors(false);
    }
  };

  const formatCardDate = input => {
    const text = input
      .replace(
        /[^0-9]/g,
        '', // To allow only numbers
      )
      .replace(
        /^([2-9])$/g,
        '0$1', // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        '0$1/$2', // 13 > 01/3
      )
      .replace(
        /^0{1,}/g,
        '0', // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/g,
        '$1/$2', // To handle 113 > 11/3
      );
    setExpiry(text);
    if (text.length === 5) {
      setHasExpiryErrors(false);
    } else if (text.length === 7) {
      setHasExpiryErrors(false);
    } else if (text.length !== 5) {
      setHasExpiryErrors(true);
    }
  };

  const validateCvv = text => {
    const sample = text.replace(/[^\d]/g, '');
    const maxLength = sample.substring(0, 4);
    setCvv(maxLength);
    if (maxLength.length === 3 || maxLength.length === 4) {
      setCvvHasError(false);
    } else if (maxLength.length !== 3 || maxLength.length !== 4) {
      setCvvHasError(true);
    }
  };

  const validate = (event) => {
    let validation = false;
    const today = new Date();
    const exMonth = expiry.split('/')[0];
    const yearOfExpiry = expiry.split('/')[1];
    let expiryYear = '';
    if (yearOfExpiry && yearOfExpiry.length === 2) {
      expiryYear = `20${yearOfExpiry}`;
    } else if (yearOfExpiry && yearOfExpiry.length === 4) {
      expiryYear = yearOfExpiry;
    }
    const exYear = expiryYear;
    const someday = new Date();
    someday.setFullYear(exYear, exMonth, 1);

    if (someday < today) {
      setHasExpiryErrors(true);
      validation = true;
    }
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid || hasCardErrors || hasCvvError || hasExpiryErrors || validation) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      cardNumber,
      expiry,
      cvv,
      name,
      country,
      zipCode,
      defaultCard,
    });
  };

  const removeCard = (data: Object) => {
    setDebitCardInfo(data.gid);
    setDeleteCardModal(true);
  };

  const deleteSelectedCard = () => {
    deleteCard({
      gid: debitCardInfo,
    });
  };

  const updateSelectedCard = () => {
    const params = {
      default: defaultCard1,
    };
    updateCard({
      gid: debitCardInfo1,
      params,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Done</SpButton>,
      ]}
      title="Manage Cards"
    >
      <Card>
        <SpText fontWeight="600" fontSize="14px" color="#292929">Added Cards</SpText>
        <div className="mt-2 bank-accounts">
          {
            walletCards.length !== 0 ? (
              <div>
                {
                  walletCards.map((option) => (
                    <div className="mt-2">
                      <Row type="flex" justify="start" align="middle">
                        <Col span={12}>
                          <div className="mr-1">
                            <SpText fontSize="18px" fontWeight="600">
                              {
                                CARD_SCHEMAS.includes(getValidSchema(option.card.scheme)) ? (
                                  <>
                                    <CardSchemeIcon scheme={option.card.scheme} />
                                  </>
                                ) : (
                                  <>
                                    &nbsp;
                                  </>
                                )
                              }
                            </SpText>
                          </div>
                          <div>
                            <div>
                              <SpText fontWeight="400" fontSize="14px" color="#292929">XXXX-XXXX-XXXX-{option.card.lastFour}</SpText>
                            </div>
                            <div>
                              <SpText fontWeight="400" fontSize="14px" color="#292929">{option.card.bankName}</SpText>
                            </div>
                          </div>
                        </Col>
                        <Col span={6}>
                          <Checkbox
                            checked={option.default}
                            className="mr-2"
                            onChange={({ target: { checked } }) => {
                              setDefaultCard1(checked);
                              setDebitCardInfo1(option.gid);
                              setUpdateCardModal(true);
                            }}
                          /> Default
                        </Col>
                        <Col span={6}>
                          <Button
                            type="link"
                            onClick={(e) => { e.stopPropagation(); removeCard(option); }}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))
                }
              </div>
            ) : (
              <SpText fontWeight="400" fontSize="14px" color="#292929">No Debit Cards added</SpText>
            )
          }
        </div>
      </Card>
      <Card className="my-2">
        <SpText fontWeight="600" fontSize="14px" color="#292929">Add Debit Card</SpText>
        <div className="px-4 mt-2">
          <div className="mb-3">
            <label htmlFor=""><SpH5>Card Information</SpH5></label>
            <Input
              placeholder="Enter Your Card Number"
              onChange={(e) => validateCard(e.currentTarget.value)}
              value={cardNumber}
              suffix={cardType}
              onBlur={validateCardType}
            />
            {
              hasCardErrors ? (
                <SpError>
                  Invalid Card Number
                </SpError>
              ) : (
                <SpError>
                  {simpleValidator.current.message('card number', cardNumber, 'required')}
                </SpError>
              )
            }
            <div className="d-flex">
              <Input
                placeholder="MM/YYYY"
                onChange={(e) => formatCardDate(e.currentTarget.value)}
                value={expiry}
                autocomplete="false"
              />
              <Input
                placeholder="Cvv"
                onChange={(e) => validateCvv(e.currentTarget.value)}
                value={cvv}
                autoComplete="new-password"
                style={{ webkitTextSecurity: 'disc' }}
              />
            </div>
            <div>
              {
                hasExpiryErrors ? (
                  <SpError>
                    Invalid Expiry Date
                  </SpError>
                ) : (
                  <SpError>
                    {simpleValidator.current.message('expiry date', expiry, 'required')}
                  </SpError>
                )
              }
            </div>
            <div>
              {
                hasCvvError ? (
                  <SpError>
                    Invalid Cvv
                  </SpError>
                ) : (
                  <SpError>
                    {simpleValidator.current.message('cvv number', cvv, 'required')}
                  </SpError>
                )
              }
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor=""><SpH5>Name on card</SpH5></label>
            <Input
              placeholder="Name on card"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Name', name, 'required|alpha_space')}
            </SpError>
          </div>
          <div className="pb-3">

            <div className="d-flex">
              <div className="w-50 mr-3">
                <label className="d-block"><SpH5>Country</SpH5></label>
                <Select
                  className="w-100"
                  value={country || 'Select country'}
                  onChange={(e) => setCountry(e)}
                >
                  <Option
                    key={1}
                    value="United States"
                  >
                    United States
                  </Option>

                </Select>
              </div>
              <div className="w-50">
                <label className="d-block"><SpH5>Zip code</SpH5></label>
                <Input
                  className="w-100"
                  placeholder="Enter zip code"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('Zip code', zipCode, 'required|postalcode')}
                </SpError>
              </div>
            </div>
          </div>
          {
            (walletCards && walletCards.length === 0) ? (
              <div>
                <Checkbox
                  className="mr-2"
                  checked
                  disabled
                  onChange={({ target: { checked } }) => {
                    setDefaultCard(checked);
                  }}
                /> Set as default card
              </div>
            ) : (
              <div>
                <Checkbox
                  className="mr-2"
                  checked={defaultCard}
                  onChange={({ target: { checked } }) => {
                    setDefaultCard(checked);
                  }}
                /> Set as default card
              </div>
            )
          }
        </div>
        {/* DELETE CARD MODAL */}
        {deleteCardModal && (
          <DeleteCard
            visible={deleteCardModal}
            submitting={submitting}
            submit={deleteSelectedCard}
            close={() => setDeleteCardModal(false)}
          />
        )}
        {/* UPDATE CARD MODAL */}
        {updateCardModal && (
          <UpdateCard
            visible={updateCardModal}
            submitting={submitting}
            submit={updateSelectedCard}
            close={() => setUpdateCardModal(false)}
          />
        )}
      </Card>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  deleteCard: param => dispatch(walletDetailsActions.deleteCard({
    payload: param,
  })),
  updateCard: param => dispatch(walletDetailsActions.updateCard({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddNewDebitCard);
