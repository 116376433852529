import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  SUBSCRIPTION,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchSubscription = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.SUBSCRIPTION}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.SUBSCRIPTION}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.SUBSCRIPTION}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.SUBSCRIPTION}${query}${sorting}`);
};

const fetchProfile = async () => axiosAuthInstance.get(`${API_END_POINTS.USER_PROFILE}`);

const fetchPlanSearch = async (payload) => {
  const queryParam = `?page=1&size=100&name.LIKE=${payload}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=ASC';
  const planFilters = '&isInternal.EQ=false';
  return axiosAuthInstance.get(`${API_END_POINTS.PLAN}${queryParam}${sorting}${planFilters}`);
};

const fetchCustomerSearch = async (payload) => {
  const queryParam = `?name.LIKE=${payload}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=ASC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMERS}${queryParam}${sorting}`);
};

const fetchSearchProduct = async (payload) => {
  const queryParam = `?name.LIKE=${payload}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=ASC';
  return axiosAuthInstance.get(`${API_END_POINTS.PRODUCT}${queryParam}${sorting}`);
};

const fetchProductList = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.PRODUCT}${query}${sorting}`);
};

const postSubscription = async payload => axiosAuthInstance.post(API_END_POINTS.SUBSCRIPTION, payload);

export function* getSubscription(action) {
  try {
    const { data: { entity } } = yield call(fetchSubscription, action.payload);
    yield put({ type: SUBSCRIPTION.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SUBSCRIPTION.FAILED, payload: e.response.data });
  }
}

export function* getPlanSearch(action) {
  try {
    const { data: { entity } } = yield call(fetchPlanSearch, action.payload);
    yield put({ type: SUBSCRIPTION.SEARCH_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SUBSCRIPTION.FAILED, payload: e.response.data });
  }
}

export function* getCustomerSearch(action) {
  try {
    const { data: { entity } } = yield call(fetchCustomerSearch, action.payload);
    yield put({ type: SUBSCRIPTION.CUSTOMER_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SUBSCRIPTION.FAILED, payload: e.response.data });
  }
}

export function* getProductSearch(action) {
  try {
    const { data: { entity } } = yield call(fetchProductList, action.payload);
    yield put({ type: SUBSCRIPTION.PRODUCT_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SUBSCRIPTION.FAILED, payload: e.response.data });
  }
}

export function* getSearchProduct(action) {
  try {
    const { data: { entity } } = yield call(fetchSearchProduct, action.payload);
    yield put({ type: SUBSCRIPTION.PRODUCT_SEARCH_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SUBSCRIPTION.FAILED, payload: e.response.data });
  }
}

export function* addSubscription(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postSubscription, action.payload);
    const { data: { entity } } = yield call(fetchSubscription, action.payload);
    yield put({ type: SUBSCRIPTION.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUBSCRIPTION.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getProfile(action) {
  try {
    const { data: { entity } } = yield call(fetchProfile, action.payload);
    yield put({ type: SUBSCRIPTION.PROFILE_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: SUBSCRIPTION.FAILED, payload: e.response.data });
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  }
}
