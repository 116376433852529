// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Card,
  Row,
  Col,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  accountSlugActions,
  accountSlugDetailsActions,
} from 'store/actions';
import {
  ROLES,
} from 'appconstants';
import RBAC from 'components/rbac';
import CONSTANTS from 'store/constants';
import getAccountSlugStatus from 'utils/getAccountSlugStatus';
import getAccountSlugDetailsBackground from 'utils/getAccountSlugDetailsBackground';
import getAccountSlugDetailsColor from 'utils/getAccountSlugDetailsColor';

type Props = {
  fetchAccountSlugDetails: Function,
  unLinkAccountSlug: Function,
  accountSlugDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  accountSlugDetailsStatus: string,
  validator: boolean,
}

const AccountSlugDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchAccountSlugDetails,
    unLinkAccountSlug,
    accountSlugDetails,
    loading,
    selectedAccount,
    accountSlugDetailsStatus,
    validator,
  } = props;
  const { gid } = props.match.params;
  const [redirect, SetRedirect] = useState(false);

  const getData = () => {
    fetchAccountSlugDetails(gid);
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      getData();
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);


  const unlinkSelectedSlug = async () => {
    unLinkAccountSlug(gid);
    SetRedirect(true);
  };


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        accountSlugDetails.accountSlug && accountSlugDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col className="ml-3">
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">
                    {accountSlugDetails.alias || <>&#8211;</>}
                  </SpText>
                  <SpStatusTag
                    style={{
                      color: getAccountSlugDetailsColor(accountSlugDetails.status),
                      backgroundColor: getAccountSlugDetailsBackground(accountSlugDetails.status),
                    }}
                  >
                    {getAccountSlugStatus(accountSlugDetails.status)}
                  </SpStatusTag>
                </Col>
                <>
                  {
                    ((accountSlugDetails.status) === 'LINKED') && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <Col>
                          <SpButton
                            type="secondary"
                            shape="round"
                            onClick={unlinkSelectedSlug}
                          >
                            Unlink
                          </SpButton>
                        </Col>
                      </RBAC>
                    )
                  }
                </>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Alias</span>
                  </Col>
                  <Col span={9}>
                    {accountSlugDetails.alias
                      ? <SpH5>{accountSlugDetails.alias}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Slug Name</span>
                  </Col>
                  <Col span={9}>
                    {accountSlugDetails.slugName
                      ? <SpH5>{accountSlugDetails.slugName}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Linked to</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{accountSlugDetails.accountSlug || <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
              </SpForm>
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  accountSlugDetails: state.accountSlugDetails,
  accountSlugDetailsStatus: state.accountSlugDetails.accountSlugDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountSlugDetails: gid => dispatch(accountSlugDetailsActions.fetchAccountSlugDetails({
    payload: gid,
  })),
  unLinkAccountSlug: param => dispatch(accountSlugActions.unLinkAccountSlug({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AccountSlugDetails);
