import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import Terminals from './components/TerminalList';
import TerminalDetails from './components/TerminalDetails';

const TerminalList = () => (
  <Switch>
    <Route exact path="/terminals" component={Terminals} />
    <Route exact path="/terminals/:gid" component={TerminalDetails} />
  </Switch>
);

export default TerminalList;
