// @flow
import React, {
  useRef,
  useState,
  createRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Steps,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  notificationActions,
} from 'store/actions';
import { SpButton } from 'components/DesignKit';
import { NOTIFICATION } from 'store/actionTypes';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import imageOnError from 'utils/imageOnError';

import EditStocks from './EditStocks';
import Basics from './Basics';
import Details from './Details';

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  test: boolean,
  selectedAccount: Object,
  phoneCode: string,
  submit: Function,
  itemsList: Array,
  modifierItemsList: Array,
  labelList: Array,
  setNotification: Function,
  selectedItemList: Object,
  shopsList: Array,
};

const { Step } = Steps;

const AddNewItem = (props: Props) => {
  const {
    visible,
    close,
    submitting,
    selectedAccount,
    phoneCode,
    submit,
    test,
    itemsList,
    modifierItemsList,
    labelList,
    selectedItemList,
    shopsList,
  } = props;

  const [, forceUpdate] = useState();
  const basicsRef = createRef();
  const detailsRef = createRef();
  const discountRef = createRef();
  const currencyType = selectedAccount && selectedAccount.currency;
  const [refArray, setRefArray] = useState([basicsRef, detailsRef, discountRef]);
  const [current, setCurrent] = useState(0);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const [name, setName] = useState((selectedItemList && selectedItemList.name) || '');
  const [alternateName, setAlternateName] = useState((selectedItemList && selectedItemList.alternateName) || '');
  const [onlineName, setOnlineName] = useState((selectedItemList && selectedItemList.onlineName) || null);
  const [onlineEnabled, setOnlineEnabled] = useState(selectedItemList && selectedItemList.onlineEnabled);
  const [sku, setSku] = useState((selectedItemList && selectedItemList.sku) || '');
  const [price, setPrice] = useState((selectedItemList && selectedItemList.price) ? (selectedItemList.price / 100).toFixed(2) : null);
  const [cost, setCost] = useState((selectedItemList && selectedItemList.cost) ? (selectedItemList.cost / 100).toFixed(2) : null);
  // eslint-disable-next-line
  const [isPos, setIsPos] = useState((selectedItemList && (selectedItemList.hidden === false)) ? true : false);
  const [priceType, setPriceType] = useState(selectedItemList && selectedItemList.priceType);
  const [order, setOrder] = useState(selectedItemList && selectedItemList.sortOrder);
  const [primaryColor, setColorCode] = useState((selectedItemList && selectedItemList.colorCode) || '#007bff');
  const [logoUrl, setLogo] = useState('');
  const [preview, setPreview] = useState('');
  const [fileName, setFileName] = useState('');
  const [description, setDescription] = useState((selectedItemList && selectedItemList.description) || '');
  const [categories, setCategories] = useState((selectedItemList && selectedItemList.categories.map((option) => option.gid)) || []);
  const [isItemAvailable, setIsItemAvailable] = useState((selectedItemList && selectedItemList.available) || false);
  const [isRecurring, setIsRecurring] = useState((selectedItemList && selectedItemList.recurring) || false);
  const [collectLineItemDate, setCollectLineItemDate] = useState(selectedItemList ? selectedItemList.collectLineItemDate : false);
  const [modifierGroups, setModifierGroups] = useState((selectedItemList && selectedItemList.modifierGroups.map((option) => option.gid)) || []);
  const [tagDetails, setTagDetails] = useState((selectedItemList && selectedItemList.tags.map((option) => option.gid)) || []);
  // eslint-disable-next-line max-len
  const [enableStock, setEnableStock] = useState(selectedItemList && (selectedItemList.enableStock || false));
  const [enableDynamicAvailability, setEnableDynamicAvailability] = useState(selectedItemList && (selectedItemList.enableDynamicAvailability || false));
  const [stockQuantity, setStockQuantity] = useState(selectedItemList && (selectedItemList.stockQuantity || null));
  const [onlineAvailability, setOnlineAvailability] = useState(selectedItemList && (selectedItemList.onlineAvailability / 100 || null));
  const [posAvailability, setPosAvailability] = useState(selectedItemList && (selectedItemList.posAvailability / 100 || null));
  const [shopDetails, setShopDetails] = useState((selectedItemList && selectedItemList.shops.map((option) => option.gid)) || []);
  // const typeOfCurrency = selectedAccount && selectedAccount.currency.name;
  const basicsData = {
    name,
    alternateName,
    onlineName,
    onlineEnabled,
    sku,
    price,
    cost,
    priceType,
    primaryColor,
  };

  const detailsData = {
    logoUrl,
    preview,
    fileName,
    description,
    tagDetails,
    order,
    categories,
    isItemAvailable,
    isRecurring,
    collectLineItemDate,
    modifierGroups,
    shopDetails,
    isPos,
  };

  const stockDetails = {
    enableStock,
    enableDynamicAvailability,
    stockQuantity,
    onlineAvailability,
    posAvailability,
  };

  useEffect(() => {
    if (current === 2 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  useEffect(() => {
    setLogo(selectedItemList.imageUrl);
    setFileName(selectedItemList.imageUrl);
    setPreview(
      <img
        src={`${selectedItemList.imageUrl}?time=${new Date().getTime()}`}
        alt=""
        onError={event => imageOnError(event)}
        style={{ width: '80%' }}
      />,
    );
  }, []);

  const next = async () => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setName(valuesArray[0].name);
      setAlternateName(valuesArray[0].alternateName);
      setOnlineName(valuesArray[0].onlineName);
      setOnlineEnabled(valuesArray[0].onlineEnabled);
      setPrice(valuesArray[0].price);
      setSku(valuesArray[0].sku);
      setCost(valuesArray[0].cost);
      setPriceType(valuesArray[0].priceType);
      setColorCode(valuesArray[0].primaryColor);
      setCurrent(current + 1);
    } else if (current === 1) {
      validationArray.push(refArray[1].current.validate());
      const details = refArray[1].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setLogo(details.logoUrl);
      setPreview(details.preview);
      setFileName(details.fileName);
      setDescription(details.description);
      setTagDetails(details.tagDetails);
      setOrder(details.order);
      setCategories(details.categories);
      setIsPos(details.isPos);
      setIsItemAvailable(details.isItemAvailable);
      setIsRecurring(details.isRecurring);
      setCollectLineItemDate(details.collectLineItemDate);
      setModifierGroups(details.modifierGroups);
      setShopDetails(details.shopDetails);
      setCurrent(current + 1);
    } else if (current === 2) {
      setDisableButton(true);
      validationArray.push(refArray[2].current.validate());

      setDisableButton(true);

      const stocksData = refArray[2].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setEnableStock(stocksData.enableStock);
      setEnableDynamicAvailability(stocksData.enableDynamicAvailability);
      setStockQuantity(stocksData.stockQuantity);
      setOnlineAvailability(stocksData.onlineAvailability);
      setPosAvailability(stocksData.posAvailability);
      const payload = Object.assign(basicsData, detailsData, stocksData);
      submit(
        payload,
      );
    }
  };

  const prev = () => {
    setDisableButton(false);
    if (current === 0) {
      close();
    } else if (current === 1) {
      setCurrent(current - 1);
    } else if (current === 2) {
      setIsPos(!isPos);
      setCurrent(current - 1);
    } else if (current === 3) {
      setCurrent(current - 1);
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="540px"
      footer={current === 0
        ? [
          <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
          <SpButton key="2" onClick={next}>Next</SpButton>,
        ]
        : current === 1
          ? [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next}>Next</SpButton>,
          ]
          : [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next} disabled={disableButton}>Update</SpButton>,
          ]}
      title="Edit Item"
    >
      <>
        <div style={{ marginTop: '-8px' }}>
          <Steps current={current} className="subscriptions">
            <Step key="1" title="Basics" />
            <Step key="2" title="Details" />
            <Step key="3" title="Stocks" />
          </Steps>
        </div>
        <div className="px-5 mt-2">
          <div className={submitting ? 'OVERLAY' : ''} />
          {current === 0 && (
            <Basics
              ref={refArray[0]}
              selectedAccount={selectedAccount}
              basicsData={basicsData}
              currencyType={currencyType}
            />
          )}
          {current === 1 && (
            <Details
              ref={refArray[1]}
              selectedAccount={selectedAccount}
              currencyType={currencyType}
              phoneCode={phoneCode}
              test={test}
              basicsData={basicsData}
              detailsData={detailsData}
              itemsList={itemsList}
              modifierItemsList={modifierItemsList}
              shopsList={shopsList}
              labelList={labelList}
            />
          )}
          {current === 2 && (
            <EditStocks
              ref={refArray[2]}
              selectedAccount={selectedAccount}
              basicsData={basicsData}
              detailsData={detailsData}
              stockDetails={stockDetails}
            />
          )}
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddNewItem);
