import { REFERRAL_STATUSES } from 'appconstants';

export default value => {
  if (value === 'PENDING') {
    return REFERRAL_STATUSES.PENDING;
  } if (value === 'ACCOUNT_CREATED') {
    return REFERRAL_STATUSES.ACCOUNT_CREATED;
  } if (value === 'ACCOUNT_VERIFIED') {
    return REFERRAL_STATUSES.ACCOUNT_VERIFIED;
  } if (value === 'ACCOUNT_LIVE') {
    return REFERRAL_STATUSES.ACCOUNT_LIVE;
  } return REFERRAL_STATUSES.PENDING;
};
