import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import ServiceList from './components/ServiceList';
import ServiceDetails from './components/ServiceDetails';

const Services = () => (
  <Switch>
    <Route exact path="/services" component={ServiceList} />
    <Route exact path="/services/:gid" component={ServiceDetails} />
  </Switch>
);

export default Services;
