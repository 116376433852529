import { call, put } from 'redux-saga/effects';

// import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  QUICK_SUPPORT_SUMMARY,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchQuickSupportSummary = async (payload) => {
  const filterParams = payload ? payload.filterParams : '';
  if (payload && payload.filterParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_ALL_SUPPORTSUMMARY}${filterParams}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_ALL_SUPPORTSUMMARY}`);
};

const fetchQuickSupportSection = async (payload) => {
  const queryParam = '?page=1&size=100';
  const sorting = '&sortBy=createdAt&direction=DESC';
  console.log(payload, 'payload');
  if (payload && payload.shopGid) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_SHOP_SECTION}${queryParam}${sorting}&shopGid=${payload.shopGid}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_SHOP_SECTION}${queryParam}${sorting}`);
};

// eslint-disable-next-line max-len
const patchQuickSupportSummary = async (payload) => axiosAuthInstance.patch(`${API_END_POINTS.GET_ALL_SUPPORTSUMMARY}/${payload.supportGid}`, payload.params);

export function* getQuickSupportSummary(action) {
  try {
    const { data: { entity } } = yield call(fetchQuickSupportSummary, action.payload);
    yield put({ type: QUICK_SUPPORT_SUMMARY.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: QUICK_SUPPORT_SUMMARY.FAILED, payload: e.response.data });
  }
}

export function* getQuickSupportSection(action) {
  try {
    const { data: { entity } } = yield call(fetchQuickSupportSection, action.payload);
    yield put({ type: QUICK_SUPPORT_SUMMARY.SUCCESS_SECTION, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: QUICK_SUPPORT_SUMMARY.FAILED, payload: e.response.data });
  }
}

export function* updateQuickSupportSummary(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchQuickSupportSummary, action.payload);
    const { data: { entity } } = yield call(fetchQuickSupportSummary, action.payload);
    yield put({ type: QUICK_SUPPORT_SUMMARY.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Request updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
