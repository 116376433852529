import { INVENTORY_PREORDERS } from '../actionTypes';

export const fetchInventoryPreorders = (action) => ({
  type: INVENTORY_PREORDERS.FETCH,
  payload: action.payload,
});

export const fetchFilterPreorders = (action) => ({
  type: INVENTORY_PREORDERS.FETCH_FILTER_PREORDERS,
  payload: action.payload,
});
