// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Switch,
  Select,
  AutoComplete,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  validationRegex,
  validators,
} from 'utils/validationMessages';
import {
  IMEI,
  DEVICEIP,
  ADMIN_INTEGRATION_TYPES,
} from 'appconstants';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  inventoryShops:Array
};

const AddAdminTerminal = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    inventoryShops
  } = props;

  const [, forceUpdate] = useState();
  const [deviceName, setDeviceName] = useState('');
  const [appVersion, setAppVersion] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [bankName, setBankName] = useState('');
  const [mid, setMid] = useState('');
  const [tid, setTid] = useState('');
  const [deviceIP, setDeviceIP] = useState(null);
  const [integrationType, setIntegrationType] = useState('');
  const [shopGid , setShopGid] = useState("")
  const [autoSettlement, setAutosettlement] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const imeiRegex = /^[1-9][0-9]{14}$/;
  // eslint-disable-next-line max-len
  const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      imei: {
        message: IMEI.MESSAGE,
        rule: val => imeiRegex.test(val),
      },
      ip: {
        message: DEVICEIP.MESSAGE,
        rule: val => ipRegex.test(val),
      },
      bankName: {
        message: validators.bussiness.validBankName,
        rule: val => validationRegex.nameRegex.test(val),
      },
      maxLength50: {
        message: 'The appversion name must be less than or equal to 50 characters.',
        rule: val => val.length <= 50,
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      deviceId,
      deviceName,
      appVersion,
      bankName,
      mid,
      tid,
      deviceIP,
      autoSettlement,
      integrationType,
      shopGid
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Terminal"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Device Name</SpH5></label>
          <Input
            placeholder="Device Name"
            value={deviceName}
            onChange={(e) => setDeviceName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('device name', deviceName, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Device ID (IMEI etc.)</SpH5></label>
          <Input
            placeholder="Device ID"
            value={deviceId}
            onChange={(e) => setDeviceId(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('device id', deviceId, 'required|alpha_num')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Device IP</SpH5></label>
          <Input
            placeholder="Device IP"
            value={deviceIP}
            onChange={(e) => setDeviceIP(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('device ip', deviceIP, 'ip')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Merchant No.</SpH5></label>
          <Input
            placeholder="MID"
            value={mid}
            onChange={(e) => setMid(e.currentTarget.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Terminal No.</SpH5></label>
          <Input
            placeholder="TID"
            value={tid}
            onChange={(e) => setTid(e.currentTarget.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Bank Name</SpH5></label>
          <Input
            placeholder="Bank Name"
            value={bankName}
            onChange={(e) => setBankName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('bank name', bankName, 'required|bankName')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Integration Type</SpH5></label>
          <div className="d-flex">
            <div className="w-100">
              <Select
                className="w-100"
                defaultValue="Select Type"
                onChange={value => setIntegrationType(value)}
              >
                {
                  ADMIN_INTEGRATION_TYPES.map(type => (
                    <Option
                      key={1}
                      value={type.value}
                    >
                      {type.display}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('Integration Type', integrationType, 'required')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Shop</SpH5></label>
          <div className="d-flex">
            <div className="w-100">
              <AutoComplete
                placeholder="Shop"
                showSearch
                className="w-100"
                dataSource={
                  inventoryShops.map(type => (
                    <Option
                      key={1}
                      value={type.gid}
                    >
                      {type.name}
                    </Option>
                  ))
                }
                style={{ height: '40px' }}
                defaultValue="Select Shop"
                onSelect={value => {
                  setShopGid(value)
                }} />
          
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>App Version</SpH5></label>
          <Input
            placeholder="Version Name"
            value={appVersion}
            onChange={(e) => setAppVersion(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('appVersion name', appVersion, 'maxLength50')}
          </SpError>
        </div>

        <div>
          <div className="d-flex">
            <label className="d-block font-weight-bold">Auto Settlement</label>
            <Switch
              className="mx-2"
              checked={autoSettlement}
              onChange={e => setAutosettlement(e)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddAdminTerminal);
