import { RATESDETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  ratesDetailsStatus: undefined,
  ratesDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RATESDETAILS.FETCH:
      return {
        ...state,
        ratesDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case RATESDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        ratesDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case RATESDETAILS.FAILED:
      return {
        ...state,
        ratesDetailsError: action.payload,
        ratesStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
