import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  ACCOUNT,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const getAccount = async (payload) => {
  const query = payload ? (payload.accountParams) : '';
  return axiosAuthInstance.get(`${API_END_POINTS.USER_ACCOUNT}${query}`);
};
const getAccountById = async gid => axiosAuthInstance.get(`${API_END_POINTS.USER_ACCOUNT}/${gid}`);
const getFilteredAccounts = async filter => {
  if (filter && filter.value) {
    return axiosAuthInstance.get(`internal/${API_END_POINTS.USER_ACCOUNT}?page=1&size=100&${filter.selectOption}.LIKE=${filter.value}`);
  }
  return axiosAuthInstance.get(`internal/${API_END_POINTS.USER_ACCOUNT}?page=1&size=100`);
};
const createAccount = async payload => axiosAuthInstance.post(API_END_POINTS.USER_ACCOUNT, payload);
const getAccountByInternalId = async gid => axiosAuthInstance.get(`internal/${API_END_POINTS.USER_ACCOUNT}/${gid}`);
const patchInternalAccountInfo = async ({ gid, params }) => axiosAuthInstance.patch(`internal/${API_END_POINTS.USER_ACCOUNT}/${gid}`, params);

const getSelectedAccount = state => state.account && state.account.selectedAccount;

export function* getUserAccount(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(getAccount, action.payload);
    yield put({ type: ACCOUNT.SUCCESS, payload: entity });
    const account = yield select(getSelectedAccount);
    if (!account || !account.gid) {
      yield put({ type: ACCOUNT.SET_SELECTED_ACCOUNT, payload: entity.content[0] });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
      yield put({ type: LOADING.VALIDATING });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getUserAccountById(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(getAccountById, action.payload);
    yield put({ type: ACCOUNT.SUCCESS_BY_ID, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
      yield put({ type: LOADING.VALIDATING });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* filterUserAccount(action) {
  try {
    const { data: { entity } } = yield call(getFilteredAccounts, action.payload);
    yield put({ type: ACCOUNT.SUCCESS_BY_FILTER, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
      yield put({ type: LOADING.VALIDATING });
    }
  }
}

export function* updateUserAccount(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(createAccount, action.payload);
    yield put({ type: ACCOUNT.UPDATED, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACCOUNT.CREATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
      yield put({ type: LOADING.VALIDATING });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addNewAccount(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(createAccount, action.payload.params);
    const { data } = yield call(getAccount);
    yield put({ type: ACCOUNT.SUCCESS, payload: data.entity });
    yield put({ type: ACCOUNT.SET_SELECTED_ACCOUNT, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACCOUNT.CREATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
      yield put({ type: LOADING.VALIDATING });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getUserAccountByInternalId(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(getAccountByInternalId, action.payload);
    yield put({ type: ACCOUNT.SUCCESS_BY_INTERNAL_ID, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
      yield put({ type: LOADING.VALIDATING });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateInternalUserAccount(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(patchInternalAccountInfo, action.payload);
    yield put({ type: ACCOUNT.INTERNAL_UPDATED, payload: entity });
    const { data } = yield call(getAccountById, action.payload.gid);
    yield put({ type: ACCOUNT.SUCCESS_BY_ID, payload: data.entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACCOUNT.MERCHANT_UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
      yield put({ type: LOADING.VALIDATING });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
