import { PARTNER_SPECS } from '../actionTypes';

const initialState = {
  partner: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PARTNER_SPECS.SET_PARTNER_DATA:
      return {
        ...state,
        partner: action.payload,
      };
    default:
      return state;
  }
};
