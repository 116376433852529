// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Icon,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpButton,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getFilterOperators from 'utils/getFilterOperators';

import { customersActions, filterParamsActions } from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
} from 'appconstants';

import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import getStateList from 'utils/stateList';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddCustomer from './components/AddCustomer';
import EditCustomer from './components/EditCustomer';
import DeleteCustomer from './components/DeleteCustomer';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const CUSTOMERS_FILTERS_TYPES = FILTERS_TYPES.CUSTOMERS;

type Props = {
  customers: Array<Object>,
  fetchCustomers: Function,
  addCustomers: Function,
  updateCustomer: Function,
  deleteCustomer: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  setFilterData: Function,
  preserveFilters: Object,
  isMobileView: Boolean,
};

const CustomersList = (props: Props) => {
  const {
    selectedAccount,
    customers,
    loading,
    addCustomers,
    updateCustomer,
    deleteCustomer,
    fetchCustomers,
    totalElements,
    submitting,
    validator,
    test,
    history,
    setFilterData,
    preserveFilters,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const [, forceUpdate] = useState();
  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [deleteCustomerModal, setDeleteCustomerModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [filterTypes, setFilterTypes] = useState(CUSTOMERS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [filterCustomers, setFilterCustomers] = useState((preserveFilters && preserveFilters.filterCustomers)
    ? (preserveFilters && preserveFilters.filterCustomers)
    : [{
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [zipTag, setZipTag] = useState('');
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterCustomers: [{
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });

  const debouncedFetchCustomers = debounce(fetchCustomers, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchCustomers({ currentPage, sortParams, filterParams });
  };

  const exportData = customers ? customers.map((values) => ({
    name: values && values.name,
    email: values && values.email,
    createdAt: moment
      .utc(values && values.createdAt)
      .tz(selectedAccount && selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
  })) : '';

  const exportHeaders = [
    { label: 'NAME', key: 'name' },
    { label: 'EMAIL', key: 'email' },
    { label: 'CREATED ON', key: 'createdAt' },
  ];

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      // inside click
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      // Outside click
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    getData(location.search);
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
      setstatesInCountry(getStateList(selectedAccount.country.id));
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setAddCustomerModal(false);
      setEditCustomerModal(false);
      setDeleteCustomerModal(false);
    }
  }, [validator]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [customers]);

  const addNewCustomer = (customer: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      name,
      email,
      phoneNumber,
      taxStatus,
      taxValue,
      taxId,
      countryCode,
      billingAddress,
      shippingAddress,
    } = customer;
    const params = {
      name,
      phoneNumber: phoneNumber ? `${countryCode}${phoneNumber}` : null,
      email,
      taxId,
      taxStatus,
      taxValue,
      billingAddress,
      shippingAddress: shippingAddress || null,
    };
    addCustomers({
      params,
      currentPage,
      sortParams,
    });
  };

  const editSelectedCustomer = (customer: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      customerName,
      customerEmail,
      customerPhone,
      customerTaxId,
      customerTaxValue,
      customerTaxStatus,
      gid,
      countryCode,
      billingAddress,
      shippingAddress,
      removeBillingAddress,
      removeShippingAddress,
    } = customer;

    const params = {
      name: customerName,
      phoneNumber: customerPhone ? `${countryCode}${customerPhone}` : null,
      email: customerEmail,
      taxId: customerTaxId,
      taxStatus: customerTaxStatus,
      taxValue: customerTaxValue,
      billingAddress,
      shippingAddress: shippingAddress || null,
      removeBillingAddress,
      removeShippingAddress,
    };
    updateCustomer({
      currentPage,
      sortParams,
      gid,
      params,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.CUSTOMERS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.CUSTOMERS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const deleteSelectedCustomer = (gid: string) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    deleteCustomer({
      currentPage,
      sortParams,
      gid,
    });
  };

  const showDeleteCustomerrModal = (value: Object) => {
    setSelectedCustomer(value);
    setDeleteCustomerModal(true);
  };

  const showEditCustomerModal = (value: Object) => {
    setSelectedCustomer(value);
    setEditCustomerModal(true);
  };

  const selectedRecord = (record) => {
    const customerGid = record.gid;
    history.push({
      pathname: `${PAGES.CUSTOMERS}/${customerGid}`,
      state: location.search,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterCustomers];
    if (filterCustomers.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = CUSTOMERS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterCustomers(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterCustomers];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = CUSTOMERS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterCustomers(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterCustomers[index].filterName === 'createdAt') {
      if (filterCustomers[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterCustomers[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterCustomers[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterCustomers];
    list[index].filterValue = selectedValue;
    setFilterCustomers(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterCustomers];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterCustomers[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterCustomers(list);
  };

  const handleAddClick = () => {
    const list = [...filterCustomers];
    setFilterCustomers([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterCustomers([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(CUSTOMERS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.CUSTOMERS);
  };

  const applyFilters = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    const customersFilters = filterCustomers.filter((item) => item.checked);
    if (customersFilters[0].filterValue) {
      setCount(customersFilters && customersFilters.length);
      for (let i = 0; i < customersFilters.length; i += 1) {
        if (customersFilters[i].checked) {
          if (customersFilters[i].filterName === 'createdAt') {
            customersFilters[i] = customersFilters[i].filterValue;
          } else if (customersFilters[i].filterName === 'email') {
            const email = encodeURIComponent(customersFilters[i].filterValue);
            customersFilters[i] = `${customersFilters[i].filterName}.${customersFilters[i].filterOperator}=${email}`;
          } else {
            customersFilters[i] = `${customersFilters[i].filterName}.${customersFilters[i].filterOperator}=${customersFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(customersFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterCustomers,
        });
        history.push({
          pathname: PAGES.CUSTOMERS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterCustomers,
        });
        history.push({
          pathname: PAGES.CUSTOMERS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (email) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {email || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '25%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showDeleteCustomerrModal(record); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            className="ml-3"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showEditCustomerModal(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                ghost
                onClick={() => setAddCustomerModal(true)}
              >
                {'\u002B'}&nbsp;Add New
              </SpButton>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Customers">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Customers Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                  )}
                  {!open && (
                  <Tooltip placement="left" title="Click here to filter Customers">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper>
                    <Filters
                      filtersGrid={filterCustomers}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterCustomers.length < CUSTOMERS_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              {'\u002B'}&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD CUSTOMER MODAL */}
      { addCustomerModal && (
        <AddCustomer
          visible={addCustomerModal}
          submitting={submitting}
          phoneCode={phoneCode}
          selectedAccount={selectedAccount}
          zipTag={zipTag}
          statesInCountry={statesInCountry}
          close={() => setAddCustomerModal(false)}
          submit={addNewCustomer}
        />
      )}

      {/* EDIT CUSTOMER MODAL */}
      { editCustomerModal && (
        <EditCustomer
          visible={editCustomerModal}
          selectedCustomer={selectedCustomer}
          phoneCode={phoneCode}
          submitting={submitting}
          selectedAccount={selectedAccount}
          zipTag={zipTag}
          statesInCountry={statesInCountry}
          close={() => setEditCustomerModal(false)}
          submit={editSelectedCustomer}
        />
      )}

      {/* DELETE CUSTOMER MODAL */}
      { deleteCustomerModal && (
        <DeleteCustomer
          visible={deleteCustomerModal}
          selectedCustomer={selectedCustomer}
          submitting={submitting}
          close={() => setDeleteCustomerModal(false)}
          submit={deleteSelectedCustomer}
        />
      )}
      <RBAC
        fallback={(
          <Table
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={customers}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
      >
        <Table
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={customers}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers.content,
  totalElements: state.customers.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomers: param => dispatch(customersActions.fetchCustomers({
    payload: param,
  })),
  addCustomers: param => dispatch(customersActions.addCustomers({
    payload: param,
  })),
  updateCustomer: param => dispatch(customersActions.updateCustomer({
    payload: param,
  })),
  deleteCustomer: param => dispatch(customersActions.deleteCustomer({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CustomersList);
