import { WOOCOMMERCE_DETAILS } from '../actionTypes';

export const fetchWooCommerceOrderDetails = (action) => ({
  type: WOOCOMMERCE_DETAILS.FETCH,
  payload: action.payload,
});

export const fetchWooCommerceOrderTimeline = (action) => ({
  type: WOOCOMMERCE_DETAILS.FETCH_TIMELINE,
  payload: action.payload,
});

export const updateWooCommerceOrderStatus = (action) => ({
  type: WOOCOMMERCE_DETAILS.UPDATE_ORDER_STATUS,
  payload: action.payload,
});
