// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Icon,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import Clipboard from 'components/NavIcons/copyclipboard';
import Filters from 'components/Filters';
import getStateList from 'utils/stateList';
import getFilterOperators from 'utils/getFilterOperators';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';

import {
  PAGES,
  ROLES,
  COUNTRIES,
  CURRENCY,
  MESSAGES,
  FILTERS_TYPES,
  FILTER_STATUSES,
  TRANSFERLINK_DISABLE_STATUS,
} from 'appconstants';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';

import { NOTIFICATION, FILTERS_SPECS } from 'store/actionTypes';
import RBAC from 'components/rbac';

import {
  transferLinksActions,
  transfersActions,
  filterParamsActions,
  notificationActions,
} from 'store/actions';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import getTransferLinksStatus from 'utils/getTransferLinksStatus';
import getTransferLinksBackground from 'utils/getTransferLinksBackground';

import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddTransferLink from './components/AddTransferLink';
import ConfirmOTP from './components/ConfirmOTP';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const TRANSFERLINKS_FILTERS_TYPES = FILTERS_TYPES.TRANSFERLINKS;
const transferLinkTypes = FILTER_STATUSES.TRANSFERLINKS;

type Props = {
  fetchTransferLinks: Function,
  getContactList: Function,
  createTransferLinks: Function,
  setNotification: Function,
  transferlinks: Array<Object>,
  contactList: Array,
  loading: boolean,
  test: boolean,
  validator: boolean,
  submitting: boolean,
  totalElements: number,
  setFilterData: Function,
  preserveFilters: Object,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  confirmTransferLinkOtp: Function,
};

const TransferLinks = (props: Props) => {
  const {
    loading,
    fetchTransferLinks,
    getContactList,
    createTransferLinks,
    setNotification,
    transferlinks,
    contactList,
    totalElements,
    test,
    history,
    validator,
    submitting,
    selectedAccount,
    setFilterData,
    preserveFilters,
    confirmTransferLinkOtp,
  } = props;

  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const { id } = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [pagination, setPagination] = useState({});
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [zipTag, setZipTag] = useState('');
  const [filterTypes, setFilterTypes] = useState(TRANSFERLINKS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [otpRequires, setOtpRequires] = useState('');
  const [filterTransferLinks, setFilterTransferLinks] = useState((preserveFilters && preserveFilters.filterTransferLinks)
    ? (preserveFilters && preserveFilters.filterTransferLinks)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [addTransferLinkModal, setAddTransferLinkModal] = useState(false);
  const supportsSchedule = (selectedAccount.issuer && selectedAccount.issuer.supportsSchedule);
  const [transferOtpModal, setTransferOtpModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [lastFour, setLastFour] = useState('');
  const [selectedTransferLink, setSelectedTransferLink] = useState({});
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterTransferLinks: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });

  const exportData = transferlinks ? transferlinks.map((values) => ({
    amount: `${values.currency.prefix}${formatNumber((values.amount / 100).toFixed(values.currency.toFixed), currencyType)}`,
    status: getTransferLinksStatus(values.status),
    contact: values.contact && values.contact.name,
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY'),
  })) : '';

  const exportHeaders = [
    { label: 'AMOUNT', key: 'amount' },
    { label: 'CONTACT', key: 'contact' },
    { label: 'STATUS', key: 'status' },
    { label: 'DATE', key: 'createdAt' },
  ];
  const debouncedFetchTransferLinks = debounce(fetchTransferLinks, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchTransferLinks({ currentPage, sortParams, filterParams });
    getContactList();
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (!validator) {
      getData();
      setAddTransferLinkModal(false);
      setTransferOtpModal(false);
    }
  }, [validator]);
  useEffect(() => {
    if (selectedAccount && selectedAccount.issuer) {
      setOtpRequires(selectedAccount.issuer.requiresOtp);
    }
  }, [selectedAccount]);

  useEffect(() => {
    // This call is happennig for test mode and if it was live we should have issuer.
    if (test) {
      getData(location.search);
    } else if (selectedAccount && selectedAccount.issuer) {
      getData(location.search);
    }
  }, [test, selectedAccount, location]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
    if (selectedAccount && selectedAccount.contactNumber) {
      setLastFour((selectedAccount.contactNumber).slice(-4));
    }
  }, [selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [transferlinks]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addNewTransferLink = (transferInfo: Object) => {
    const {
      contactGid,
      contactAddGid,
      description,
      transferAmount,
      transferType,
      transferApiDate,
      fundingSourceGid,
      fundingSourceAddGid,
      notifyType,
      redirectUrl,
    } = transferInfo;
    const amount = transferAmount.replace(/[^\d]/g, '');
    createTransferLinks({
      amount: Number(amount),
      currencyCode: CURRENCY(id),
      contactGid: contactAddGid || contactGid,
      fundingSourceGid: fundingSourceAddGid || fundingSourceGid,
      description,
      transferTypeCode: transferType,
      transferScheduleDate: transferApiDate,
      notificationType: notifyType,
      redirectUri: redirectUrl,
    });
  };

  const copyCode = (record) => {
    const el = record.link;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TRANSFERLINKS.SUCCESS,
    });
  };

  const showOTPConfirmationModal = (value: Object) => {
    setSelectedTransferLink(value);
    setTransferOtpModal(true);
  };

  const otpTransfer = (otpInfo: Object) => {
    const {
      gid,
      otpCode,
    } = otpInfo;

    const params = { otp: otpCode };

    confirmTransferLinkOtp({
      gid,
      params,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.TRANSFERLINKS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.TRANSFERLINKS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterTransferLinks];
    if (filterTransferLinks.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = TRANSFERLINKS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTransferLinks(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterTransferLinks];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = TRANSFERLINKS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTransferLinks(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterTransferLinks];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterTransferLinks(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterTransferLinks(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterTransferLinks];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterTransferLinks(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterTransferLinks(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterTransferLinks[index].filterName === 'createdAt') {
      if (filterTransferLinks[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterTransferLinks[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterTransferLinks[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterTransferLinks];
    list[index].filterValue = selectedValue;
    setFilterTransferLinks(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTransferLinks];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterTransferLinks[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterTransferLinks(list);
  };

  const handleAddClick = () => {
    const list = [...filterTransferLinks];
    setFilterTransferLinks([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterTransferLinks([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(TRANSFERLINKS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.TRANSFERLINKS);
  };

  const applyFilters = () => {
    const transferlinksFilters = filterTransferLinks.filter((item) => item.checked);
    for (let i = 0; i < transferlinksFilters.length; i += 1) {
      if (!transferlinksFilters[i].filterName || !transferlinksFilters[i].filterOperator || !transferlinksFilters[i].filterValue) {
        return;
      }
    }
    if (transferlinksFilters[0].filterValue || (transferlinksFilters[0].filterValueFrom && transferlinksFilters[0].filterValueTo)) {
      setCount(transferlinksFilters && transferlinksFilters.length);
      for (let i = 0; i < transferlinksFilters.length; i += 1) {
        if (transferlinksFilters[i].checked) {
          if (transferlinksFilters[i].filterName === 'createdAt') {
            transferlinksFilters[i] = transferlinksFilters[i].filterValue;
          } else if (transferlinksFilters[i].filterName === 'amount' && transferlinksFilters[i].filterOperator === 'BT') {
            const name = transferlinksFilters[i].filterName;
            const from = transferlinksFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = transferlinksFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            transferlinksFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (transferlinksFilters[i].filterName === 'amount' && transferlinksFilters[i].filterOperator !== 'BT') {
            const value = transferlinksFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            transferlinksFilters[i] = `${transferlinksFilters[i].filterName}.${transferlinksFilters[i].filterOperator}=${value}`;
          } else {
            // eslint-disable-next-line max-len
            transferlinksFilters[i] = `${transferlinksFilters[i].filterName}.${transferlinksFilters[i].filterOperator}=${transferlinksFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(transferlinksFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterTransferLinks,
        });
        history.push({
          pathname: PAGES.TRANSFERLINKS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterTransferLinks,
        });
        history.push({
          pathname: PAGES.TRANSFERLINKS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <span>
            {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'CONTACT',
      dataIndex: 'name',
      width: '15%',
      align: 'left',
      render: (cname) => (
        <span style={{ cursor: 'pointer' }}>
          {cname || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '18%',
      align: 'left',
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getTransferLinksBackground(status) }}>
            {(otpRequires ? getTransferLinksStatus(status)
              : ((status === 'READY_TO_FUND') ? 'Ready to fund'
                : getTransferLinksStatus(status)))}
          </SpStatusTag>
        </span>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '15%',
    align: 'center',
    render: (text, record) => {
      const { status } = record;
      if (status !== TRANSFERLINK_DISABLE_STATUS.CANCELLED
        && status !== TRANSFERLINK_DISABLE_STATUS.READY_TO_FUND
        && status !== TRANSFERLINK_DISABLE_STATUS.PAID
        && status !== TRANSFERLINK_DISABLE_STATUS.DECLINED) {
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              onClick={(e) => { e.stopPropagation(); copyCode(record); }}
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      }
      if (status !== 'CANCELLED' && status === 'READY_TO_FUND' && otpRequires) {
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              onClick={(e) => { e.stopPropagation(); showOTPConfirmationModal(record); }}
            >
              <Icon type="lock" /> Confirm OTP
            </SpButton>
          </span>
        );
      }
      return (
        <span>
          <SpButton
            type="secondary"
            borderRadius="50px"
            disabled
          >
            <Clipboard />
          </SpButton>
        </span>
      );
    },
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      {
        ((!test) && (selectedAccount && (!selectedAccount.issuer)))
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <h5>Please go to settings and provide additional business information to enable live transfers</h5>
              </div>
            </Card>
          )
          : (
            <Card>
              <Row className="my-2">
                <div className="d-flex justify-content-end">
                  <Col>
                    <RBAC
                      permit={
                        [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.SUPERUSER]
                      }
                    >
                      <SpButton
                        type="secondary"
                        shape="round"
                        ghost
                        className="mr-3"
                        onClick={() => setAddTransferLinkModal(true)}
                      >
                        {'\u002B'}&nbsp;Transfer Link
                      </SpButton>
                    </RBAC>
                    <span>
                      {
                        exportData !== null
                          ? (
                            <Tooltip placement="left" title="Click here to export Transfer Links">
                              <CSVLink
                                target="_blank"
                                className="mr-3"
                                filename="Transfer Links Report.csv"
                                data={exportData}
                                headers={exportHeaders}
                                onMouseEnter={() => setFillColor(true)}
                                onMouseLeave={() => setFillColor(false)}
                              >
                                <ExportIcon
                                  fill={fillColor
                                    ? '#279dfe'
                                    : '#C0C0C0'}
                                />
                              </CSVLink>
                            </Tooltip>
                          ) : null
                      }
                    </span>
                    <span ref={myRef}>
                      <span
                        onClick={() => setOpen(!open)}
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={() => setFilterColor(true)}
                        onMouseLeave={() => setFilterColor(false)}
                      >
                        {open && (
                          <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                            <FilterIcon
                              fill={filterColor
                                ? '#279dfe'
                                : '#C0C0C0'}
                            />
                          </Badge>
                        )}
                        {!open && (
                          <Tooltip placement="left" title="Click here to filter Transfer Links">
                            <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                              <FilterIcon
                                fill={filterColor
                                  ? '#279dfe'
                                  : '#C0C0C0'}
                              />
                            </Badge>
                          </Tooltip>
                        )}
                      </span>
                      {open && (
                        <FilterWrapper
                          style={{ marginLeft: '-120px' }}
                        >
                          <Filters
                            filtersGrid={filterTransferLinks}
                            filterTypes={filterTypes}
                            handleChange={handleChange}
                            onCheck={onCheck}
                            fiterOperators={fiterOperators}
                            handleOperator={handleOperator}
                            handleValue={handleValue}
                            startDate={startDate}
                            endDate={endDate}
                            currentDate={currentDate}
                            handleFromValue={handleFromValue}
                            handleToValue={handleToValue}
                            stuses={transferLinkTypes}
                          />
                          <div className="px-4 mt-2">
                            {
                              filterTransferLinks.length < TRANSFERLINKS_FILTERS_TYPES.length && (
                                <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                                  <SpText
                                    fontWeight="600"
                                    color="#279dfe"
                                    onClick={handleAddClick}
                                  >
                                    +&nbsp; Add another filter
                                  </SpText>
                                </div>
                              )
                            }
                          </div>
                          <div className="d-flex mb-2 justify-content-end mr-3">
                            <SpButton
                              type="secondary"
                              shape="round"
                              className="mr-2"
                              ghost
                              onClick={resetFilters}
                            >
                              Clear
                            </SpButton>
                            <SpButton
                              type="primary"
                              shape="round"
                              onClick={applyFilters}
                            >
                              Save
                            </SpButton>
                          </div>
                        </FilterWrapper>
                      )}
                    </span>
                  </Col>
                </div>
              </Row>
              {/* ADD TRANSFER LINK MODAL */}
              {addTransferLinkModal && (
                <AddTransferLink
                  visible={addTransferLinkModal}
                  submitting={submitting}
                  selectedAccount={selectedAccount}
                  contactList={contactList}
                  statesInCountry={statesInCountry}
                  country={selectedAccount.country.name}
                  cntryId={selectedAccount.country.id}
                  supportsSchedule={supportsSchedule}
                  isIndia={isIndia}
                  zipTag={zipTag}
                  close={() => setAddTransferLinkModal(false)}
                  submit={addNewTransferLink}
                />
              )}

              {/* ADD OTP MODAL */}
              {transferOtpModal && (
                <ConfirmOTP
                  visible={transferOtpModal}
                  submitting={submitting}
                  lastFour={lastFour}
                  phoneCode={phoneCode}
                  selectedTransferLink={selectedTransferLink}
                  close={() => setTransferOtpModal(false)}
                  selectedAccount={selectedAccount}
                  submit={otpTransfer}
                />
              )}

              <Table
                rowClassName={() => 'ant-table-clickable-row'}
                columns={[...columns, actionColumn]}
                rowKey={(record) => record.gid}
                dataSource={transferlinks}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  transferlinks: state.transferlinks.content,
  contactList: state.transfers.contactList,
  loading: state.loading.loading,
  test: state.account.test,
  totalElements: state.transferlinks.totalElements,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTransferLinks: param => dispatch(transferLinksActions.fetchTransferLinks({
    payload: param,
  })),
  createTransferLinks: param => dispatch(transferLinksActions.createTransferLinks({
    payload: param,
  })),
  getContactList: param => dispatch(transfersActions.getContactList({
    payload: param,
  })),
  confirmTransferLinkOtp: param => dispatch(transferLinksActions.confirmTransferLinkOtp({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(TransferLinks);
