import { ROLES } from 'appconstants';
import { USER } from '../actionTypes';

const initialState = {
  user: {},
  role: {},
  superUser: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER.FETCH:
      return {
        ...state,
        ...action.payload,
        superUser: Boolean([ROLES.ADMINISTRATOR, ROLES.OWNER].find(role => action.payload.role && role === action.payload.role.name)),
      };
    case USER.UPDATE:
      return {
        ...state,
        accountGid: action.payload,
      };
    default:
      return state;
  }
};
