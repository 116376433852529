import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Checkbox,
  Select,
  Pagination,
} from 'antd';
import {
  notificationActions,
  shopDetailsActions,
} from 'store/actions';
import {
  ROLES,
  PAGES,
} from 'appconstants';
import { API_END_POINTS, axiosAuthInstance } from 'api';
import { NOTIFICATION } from 'store/actionTypes';
import moment from 'moment';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import debounce from 'lodash.debounce';
import { useLocation } from 'react-router-dom';
import Loading from 'components/Loading';
import {
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import swireLogo from 'imgs/Logo_header.png';
import KdsSettings from 'imgs/SettingsKDS.svg';
import KdsTimer from 'imgs/timerKds.svg';
import CompleteButton from 'imgs/CompleteButton.png';
import ModalOpen from 'imgs/Modalopen.png';
import ModelOpenLight from 'imgs/ModelOpenLight.png';
import SelectedOrder from './components/SelectedOrder';
import TimerComponent from './components/TimerComponent';
import UpdateItemStatus from './components/updateItemStatus';
import OutForDelivery from './components/outForDelivery';

const { Option } = Select;

type Props = {
  submitting: boolean,
  validator: boolean,
  shopData: Array,
  fetchShopDetails: Function,
  fetchShopOrderDetails: Function,
  orderDetails: Array,
  updateLineItems: Function,
  updateFullFill: Function,
  sendFcmToken: Function,
  loading: boolean,
  setNotification: Function,
  selectedAccount: Array,
  totalElements: Number,
  role: Object,
  fetchAllShopDetails: Function,
  fetchFullfillDetails: Function,
  fullFillDetails: Array,
  shopsList: Array,
  history: {
    push: Function,
  },
};

const firebaseConfig = {
  apiKey: 'AIzaSyB7XtYo08EXBb9e7CTX4QiPgFZNVn8pkVc',
  authDomain: 'swirepay-fe.firebaseapp.com',
  projectId: 'swirepay-fe',
  storageBucket: 'swirepay-fe.appspot.com',
  messagingSenderId: '436076744344',
  appId: '1:436076744344:web:a1ad170a26ae61562b0aed',
  measurementId: 'G-ZP7NX358P9',
};

const fapp = initializeApp(firebaseConfig);
const messaging = getMessaging(fapp);

const OrdersView = (props: Props) => {
  const location = useLocation();
  const {
    submitting,
    validator,
    shopData,
    orderDetails,
    fetchShopDetails,
    fetchShopOrderDetails,
    updateLineItems,
    updateFullFill,
    sendFcmToken,
    loading,
    setNotification,
    totalElements,
    history,
    selectedAccount,
    role,
    fetchAllShopDetails,
    fetchFullfillDetails,
    fullFillDetails,
    shopsList,
  } = props;
  const [orderModal, setOrderModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [redirect, setRedirect] = useState(false);
  const [messageRecevied, setMessageRecevied] = useState(false);
  const [firstTimeFetch, setFirstTimeFetch] = useState(true);
  const [selectedOrderItems, setSelectedOrderItems] = useState();
  const [lineItems, setLineItems] = useState([]);
  const [orderList, setorderList] = useState([]);
  const [internalShopGid, setInternalShopGid] = useState('');
  const [shopName, setShopName] = useState('');
  const email = localStorage.getItem('email');
  const kdsRole = localStorage.getItem('kdsRole');
  const [shopGid, setShopGid] = useState(kdsRole === ROLES.ASSOCIATE ? (shopData && shopData[0] && shopData[0].shop && shopData[0].shop.gid) : null);
  const [selectedTab, setSelectedTab] = useState('Inprogress');
  const kdsFilterTimeInMinutes = (kdsRole !== 'associate') ? (selectedAccount && selectedAccount.kdsFilterTimeInMinutes) : null;
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const newTime = kdsFilterTimeInMinutes ? moment().subtract((kdsFilterTimeInMinutes || 60), 'minutes').utc().format(MOMENT_FORMAT) : null;
  const debouncedFetchShopOrderDetails = debounce(fetchShopOrderDetails, 2000, { leading: true });
  const [lineItemModal, setLineItemModal] = useState(false);
  const [selectedLineItem, setSelectedLineItem] = useState();
  const [outforDeliveryModal, setOutforDeliveryModal] = useState(false);
  const [selectFullfillId, setSelectFullfillId] = useState('');
  const [open, setOpen] = useState(false);
  const myRef = useRef();
  const [timer, setTimer] = useState(0);
  const [accountConfig, setAccountConfig] = useState('');

  const getData = () => {
    if (shopGid) {
      debouncedFetchShopOrderDetails({
        currentPage,
        shopGid,
        selectedTab,
        newTime,
      });
    } else if (internalShopGid) {
      debouncedFetchShopOrderDetails({
        currentPage,
        shopGid: internalShopGid,
        selectedTab,
        newTime,
      });
    } else {
      debouncedFetchShopOrderDetails({ currentPage, selectedTab, newTime });
    }
  };

  const getShopOrders = (shopId) => {
    debouncedFetchShopOrderDetails({
      currentPage,
      shopGid: shopId,
      selectedTab,
      newTime,
    });
  };

  const updatePrepTime = async () => {
    const payload = {
      orderPrepTime: timer,
    };
    try {
      if (accountConfig) {
        const { data: { entity } } = await axiosAuthInstance.patch(`${API_END_POINTS.PREP_TIME}/${accountConfig}`, payload);
        if (entity && entity.gid) {
          setAccountConfig(entity.gid);
          setTimer(entity.orderPrepTime);
        }
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: 'Prepation time updated successfully',
        });
        setOpen(false);
      } else {
        const { data: { entity } } = await axiosAuthInstance.post(`${API_END_POINTS.PREP_TIME}`, payload);
        if (entity && entity.gid) {
          setAccountConfig(entity.gid);
          setTimer(entity.orderPrepTime);
        }
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: 'Prepation time updated successfully',
        });
        setOpen(false);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : '',
      });
    }
  };

  const getAccountConfig = async () => {
    try {
      const { data: { entity: { content } } } = await axiosAuthInstance.get(`${API_END_POINTS.PREP_TIME}`);
      if (content && content.length !== 0) {
        setAccountConfig(content[0].gid);
        setTimer(content[0].orderPrepTime);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : '',
      });
    }
  };

  useEffect(() => {
    if (kdsRole !== ROLES.ASSOCIATE) {
      getAccountConfig();
    }
  }, [selectedAccount]);

  // useEffect(() => {
  //   if (role && role.name === 'associate') {
  //     setSelectedTab('OutforDelivery');
  //     setCurrentPage(1);
  //   }
  // }, [role]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      // inside click
      return;
    }
    if (e.target.value === undefined) {
      // Outside click
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const fetchShopAssociate = async (payload) => {
    try {
      const { data: { entity: { content } } } = await axiosAuthInstance.get(`shop-associate?email.EQ=${payload}`);
      if (content && content.length > 0) {
        getShopOrders(content[0].shop.gid);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : '',
      });
    }
  };

  useEffect(() => {
    if (firstTimeFetch) {
      fetchFullfillDetails('');
    }
    if ((kdsRole === ROLES.ASSOCIATE) && !shopGid) {
      const payloadEmail = email.replace(/\+/g, '%2B');
      fetchShopDetails(payloadEmail);
      fetchShopAssociate(payloadEmail);
      setFirstTimeFetch(false);
    }
    if (firstTimeFetch) {
      setFirstTimeFetch(false);
      fetchAllShopDetails('shops');
    } else {
      setFirstTimeFetch(false);
    }
    if ((kdsRole !== ROLES.ASSOCIATE)) {
      getData(location.search);
    }
  }, [location, shopGid, kdsRole]);

  useEffect(() => {
    if (!firstTimeFetch) {
      getData(location.search);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!validator && redirect) {
      setOrderModal(false);
      setLineItemModal(false);
      setOutforDeliveryModal(false);
      setLineItems([]);
      getData();
    }
  }, [validator]);

  useEffect(() => {
    if (messageRecevied === true) {
      setMessageRecevied(false);
      getData();
    }
  }, [messageRecevied]);

  const handleTableChange = (page) => {
    setCurrentPage(page);
    history.push({
      pathname: '/vieworders',
    });
  };

  const selectedOrders = (record) => {
    const orderGid = record.gid;
    history.push({
      pathname: `${PAGES.INVENTORY_ORDERS}/${orderGid}`,
    });
  };

  const selectedRecord = (record) => {
    const orderGid = record.customer ? record.customer.gid : '';
    if (orderGid) {
      if (kdsRole !== 'KDS-ADMIN') {
        history.push({
          pathname: `${PAGES.CUSTOMERS}/${orderGid}`,
        });
      }
    }
  };

  const selectShop = (e) => {
    const gid = e[1];
    const selectedName = e[0];
    setCurrentPage(1);
    setInternalShopGid(gid);
    setShopName(selectedName);
    setShopGid(gid);
    if ((kdsRole === ROLES.ASSOCIATE)) {
      getShopOrders(gid);
    } else {
      history.push({
        pathname: '/vieworders',
      });
    }
  };

  const generateDeviceId = () => {
    const navigatorInfo = navigator.userAgent;
    const timestamp = new Date().getTime();
    const randomValue = Math.random().toString(36).substring(7);
    return `${navigatorInfo}-${timestamp}-${randomValue}`;
  };

  useEffect(() => {
    if (role && role.name !== 'superuser') {
      getToken(messaging, {
        vapidKey:
          'BHc-PdMJkGh6Xc0pptrGU5QNJYyLNtFP4JylwMXXD_hkw1CLiFQmfEB-2_eZU3UboFjTdgIBAeSpWhu4k2wvY3k',
      })
        .then((currentToken) => {
          console.log(currentToken, 'fcmToken');
          if (currentToken) {
            localStorage.setItem('fcmToken', currentToken);
            const payload = {
              fcmToken: currentToken,
              platformType: 'WEB',
              type: 'KDS',
              deviceId: generateDeviceId(),
            };
            sendFcmToken(payload);
          } else {
            console.log(
              'No registration token available. Request permission to generate one.',
            );
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });

      onMessage(messaging, (payload) => {
        setMessageRecevied(true);
        const notificationMessage = payload && payload.data && payload.data.message;
        const jsonObject = JSON.parse(notificationMessage);
        const notificationBody = jsonObject && jsonObject.message;
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: (notificationBody) || 'Order Updated',
        });
        // ...
      });
    }
  }, [messaging]);

  const getItemInfo = (info) => {
    const filterCompleted = info.filter(data => data.status === 'COMPLETED');
    if (filterCompleted && filterCompleted.length !== 0) {
      return `(${filterCompleted.length} - ${info.length} items)`;
    } return `(${info.length} items)`;
  };

  const handleCheckboxChange = (id, status) => {
    if (status === 'COMPLETED') {
      setSelectedLineItem([id]);
      setLineItemModal(true);
    } else {
      const isSelected = lineItems.includes(id);
      const updatedSelection = isSelected
        ? lineItems.filter((itemId) => itemId !== id)
        : [...lineItems, id];
      setLineItems(updatedSelection);
    }
  };

  const handleOrderCheckboxChange = (id) => {
    const isSelected = orderList.includes(id.gid);
    const updatedSelection = isSelected
      ? orderList.filter((itemId) => itemId !== id.gid)
      : [...orderList, id.gid];
    setorderList(updatedSelection);

    if (id.inventoryOrderLineItems && id.inventoryOrderLineItems.length > 0) {
      let updatedAllSelection = [...lineItems];
      if (isSelected) {
        // If the item is selected, remove its gid from updatedAllSelection
        id.inventoryOrderLineItems.forEach((item) => {
          updatedAllSelection = updatedAllSelection.filter(
            (itemId) => itemId !== item.gid,
          );
        });
      } else {
        // If the item is not selected, add its gid to updatedAllSelection
        id.inventoryOrderLineItems.forEach((item) => {
          if (!updatedAllSelection.includes(item.gid)) {
            updatedAllSelection.push(item.gid);
          }
        });
      }

      setLineItems(updatedAllSelection);
    }
  };


  const handleFullfillChange = (id) => {
    setSelectFullfillId(id);
    setOutforDeliveryModal(true);
  };

  const updateNewOrderItems = async () => {
    const updateItems = lineItems.map((gid) => ({
      gid,
      status: 'COMPLETED',
    }));
    let selectShopGid;
    if ((role && role.name === 'superuser')) {
      selectShopGid = internalShopGid;
    } else {
      selectShopGid = shopGid;
    }
    const params = {
      gid: selectShopGid,
      newTime,
      updateItems,
    };
    updateLineItems({ params });
    setRedirect(true);
  };

  const updateDeliveryOrder = async (info) => {
    const updateItems = {
      fulfillmentStatus: 'OUT_FOR_DELIVERY',
    };
    let selectShopGid;
    if ((role && role.name === 'superuser')) {
      selectShopGid = internalShopGid;
    } else {
      selectShopGid = shopGid;
    }
    const params = {
      gid: selectShopGid,
      newTime,
      updateItems,
      infoID: info || selectFullfillId,
      selectedTab,
    };
    updateFullFill({ params });
    setRedirect(true);
  };

  const updateSelectedItems = async (info: Array) => {
    let selectShopGid;
    if ((role && role.name === 'superuser')) {
      selectShopGid = internalShopGid;
    } else {
      selectShopGid = shopGid;
    }
    const params = {
      gid: selectShopGid,
      newTime,
      updateItems: info,
    };
    updateLineItems({ params });
    setRedirect(true);
  };

  const updateItemsStatus = async (info: Array) => {
    const updateItems = info.map((gid) => ({
      gid,
      status: 'CREATED',
    }));
    let selectShopGid;
    if ((role && role.name === 'superuser')) {
      selectShopGid = internalShopGid;
    } else {
      selectShopGid = shopGid;
    }
    const params = {
      gid: selectShopGid,
      newTime,
      updateItems,
    };
    updateLineItems({ params });
    setRedirect(true);
  };

  // eslint-disable-next-line consistent-return
  const getBackgroundColor = (status) => {
    if (status === 'OUT_FOR_DELIVERY' && selectedTab === 'OutforDelivery') {
      return '#FD5D5D';
    }
    if (status === 'OUT_FOR_DELIVERY') {
      return '#FFFFFF';
    }
    if (status === 'ACCEPTED_BY_AFFILIATE' && selectedTab === 'AcceptedAffiliate') {
      return '#2CADD6';
    }
    if (status === 'ACCEPTED_BY_AFFILIATE') {
      return '#FFFFFF';
    }
    if (status === 'DELIVERED' && selectedTab === 'Delivered') {
      return '#045762';
    }
    if (status === 'DELIVERED') {
      return '#FFFFFF';
    }
  };


  // eslint-disable-next-line consistent-return
  const getBorderColor = (status) => {
    if (status === 'OUT_FOR_DELIVERY' && selectedTab === 'OutforDelivery') {
      return '2px';
    }
    if (status === 'OUT_FOR_DELIVERY') {
      return '2px solid #FD5D5D';
    }
    if (status === 'ACCEPTED_BY_AFFILIATE' && selectedTab === 'AcceptedAffiliate') {
      return '2px';
    }
    if (status === 'ACCEPTED_BY_AFFILIATE') {
      return '2px solid #2CADD6';
    }
    if (status === 'DELIVERED' && selectedTab === 'Delivered') {
      return '2px';
    }
    if (status === 'DELIVERED') {
      return '2px solid #045762';
    }
  };

  // eslint-disable-next-line consistent-return
  const getColor = (status) => {
    if (status === 'OUT_FOR_DELIVERY' && selectedTab === 'OutforDelivery') {
      return '#FFFFFF';
    }
    if (status === 'OUT_FOR_DELIVERY') {
      return '#000000';
    }
    if (status === 'ACCEPTED_BY_AFFILIATE' && selectedTab === 'AcceptedAffiliate') {
      return '#FFFFFF';
    }
    if (status === 'ACCEPTED_BY_AFFILIATE') {
      return '#000000';
    }
    if (status === 'DELIVERED' && selectedTab === 'Delivered') {
      return '#FFFFFF';
    }
    if (status === 'DELIVERED') {
      return '#000000';
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{
      display: 'flex', background: '#FFFFFF', flexDirection: 'column', minHeight: '100%',
    }}
    >
      <header style={{
        justifyContent: 'space-between',
        borderBottom: '0.5px solid #8E9CB2',
      }}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <img
              src={swireLogo}
              alt="Logo"
              style={{ maxWidth: '245px' }}
            />
          </Col>
          <Col
            style={{
              marginRight: '5px',
              marginTop: '20px',
              marginBottom: '5px',
            }}
          >
            <Row
              type="flex"
            >
              {
                kdsRole === ROLES.ASSOCIATE ? (
                  <>
                    {
                      (shopData && shopData.length > 1) && (
                        <Col>
                          <Select
                            style={{
                              width: '350px',
                              marginRight: '5px',
                            }}
                            placeholder="select shop"
                            onSelect={(e) => selectShop(e)}
                            showSearch
                            defaultValue={shopName || 'Select shop'}
                          >
                            {shopData
                              .filter(item => !item.shop.deleted) // Filter out the deleted shops
                              .map(item => (
                                <Option
                                  key={item.shop.gid}
                                  value={[item.shop.name, item.shop.gid]}
                                >
                                  {!item.shop.deleted && item.shop.name}
                                </Option>
                              ))
                            }
                          </Select>
                        </Col>
                      )
                    }
                  </>
                ) : (
                  <>
                    {
                      (shopsList && shopsList.length > 1) && (
                        <Col>
                          <Select
                            style={{
                              width: '350px',
                              marginRight: '5px',
                            }}
                            placeholder="select shop"
                            onSelect={(e) => selectShop(e)}
                            showSearch
                            defaultValue={shopName || 'Select shop'}
                          >
                            {
                              shopsList.map(item => (
                                <Option
                                  key={item.gid}
                                  value={[
                                    item.name,
                                    item.gid,
                                  ]}
                                >
                                  {item.name}
                                </Option>
                              ))
                            }
                          </Select>
                        </Col>
                      )
                    }
                  </>
                )
              }
              <Col>
                <Button
                  style={{
                    marginRight: '5px',
                    borderRadius: '6px',
                    border: selectedTab === 'Inprogress' ? '2px' : '2px solid #F9DC5C',
                    background: selectedTab === 'Inprogress' ? '#F9DC5C' : '#FFFFFF',
                    color: '#000000',
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                    fontSize: '20px',
                  }}
                  onClick={() => {
                    setSelectedTab('Inprogress');
                    setCurrentPage(1);
                  }}
                >
                  In Process
                </Button>
              </Col>
              <Col>
                <Button
                  style={{
                    marginRight: '5px',
                    borderRadius: '6px',
                    border: selectedTab === 'Completed' ? '2px' : '2px solid #00C289',
                    background: selectedTab === 'Completed' ? '#00C289' : '#FFFFFF',
                    color: '#000000',
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                    fontSize: '20px',
                  }}
                  onClick={() => {
                    setSelectedTab('Completed');
                    setCurrentPage(1);
                  }}
                >
                  Completed
                </Button>
              </Col>
              {
                fullFillDetails && fullFillDetails.length !== 0 && (
                  <>
                    {
                      fullFillDetails.map(item => (
                        <Col>
                          <Button
                            style={{
                              marginRight: '5px',
                              borderRadius: '6px',
                              border: getBorderColor(item.status),
                              background: getBackgroundColor(item.status),
                              color: getColor(item.status),
                              fontFamily: 'Roboto',
                              fontWeight: '500',
                              fontSize: '20px',
                            }}
                            onClick={() => {
                              setSelectedTab(item.status === 'OUT_FOR_DELIVERY'
                                ? 'OutforDelivery' : item.status === 'ACCEPTED_BY_AFFILIATE'
                                  ? 'AcceptedAffiliate' : item.status === 'DELIVERED' ? 'Delivered' : '');
                              setCurrentPage(1);
                            }}
                          >
                            {item.status === 'OUT_FOR_DELIVERY'
                              ? 'Out for Delivery' : item.status === 'ACCEPTED_BY_AFFILIATE'
                                ? 'Accepted by Affiliate' : item.status === 'DELIVERED' ? 'Delivered' : ''}
                          </Button>
                        </Col>
                      ))
                    }
                  </>
                )
              }
              <Col>
                <span ref={myRef}>
                  <span
                    onClick={() => setOpen(!open)}
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      src={KdsSettings}
                      alt="settings"
                      className="ml-3"
                    />
                  </span>
                  {open && (
                  <FilterWrapper
                    style={{
                      marginLeft: '-405px',
                      marginTop: '15px',
                      width: '450px',
                    }}
                  >
                    <div className="my-3 mx-3">
                      <Row type="flex" justify="space-between">
                        <Col span={18} className="d-flex justify-content-start">
                          <SpText fontSize="16px" fontWeight="700">
                            KDS Settings
                          </SpText>
                        </Col>
                        <Col span={6} className="d-flex justify-content-end">
                          <SpText fontSize="16px" fontWeight="700" style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
                            X
                          </SpText>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between" className="mt-2">
                        <Col span={24} className="d-flex justify-content-start">
                          <SpText fontSize="14px" fontWeight="700">
                            Prep.Time
                          </SpText>
                        </Col>
                      </Row>
                      <hr />
                      <Row type="flex" justify="space-between" className="mt-2">
                        <Col span={24} className="d-flex justify-content-start align-items-center">
                          <SpText fontSize="14px" fontWeight="600">
                            <img
                              src={KdsTimer}
                              alt="timer"
                            /> &nbsp;Set Prep.Time (In Minutes)
                          </SpText>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between" className="mt-2">
                        <Col span={24} className="d-flex justify-content-start align-items-center">
                          <SpText
                            fontSize="20px"
                            fontWeight="600"
                            style={{ backgroundColor: '#F5CC16', padding: '0 12px', cursor: 'pointer' }}
                            onClick={() => (timer !== 0 ? setTimer(timer - 1) : '')}
                          >
                            &#8211;
                          </SpText>
                          <SpText fontSize="14px" fontWeight="600" style={{ backgroundColor: '#F2F5F8', padding: '4px 12px' }}>
                            {timer}
                          </SpText>
                          <SpText
                            fontSize="20px"
                            fontWeight="600"
                            style={{ backgroundColor: '#F5CC16', padding: '0 12px', cursor: 'pointer' }}
                            onClick={() => setTimer(timer + 1)}
                          >
                            +
                          </SpText>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between" className="mt-2">
                        <Col span={24} className="d-flex justify-content-end">
                          <Button
                            type="secondary"
                            shape="round"
                            className="align-items-center justify-content-center"
                            onClick={() => setOpen(!open)}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="primary"
                            shape="round"
                            className="align-items-center justify-content-center ml-2"
                            onClick={() => updatePrepTime()}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </FilterWrapper>
                  )}
                </span>
              </Col>
              {/* {
                role && role.name !== 'associate' ? (
                  <>
                    <Col>
                      <Button
                        style={{
                          marginRight: '5px',
                          borderRadius: '6px',
                          border: selectedTab === 'Inprogress' ? '2px' : '2px solid #F9DC5C',
                          background: selectedTab === 'Inprogress' ? '#F9DC5C' : '#FFFFFF',
                          color: '#000000',
                          fontFamily: 'Roboto',
                          fontWeight: '500',
                          fontSize: '20px',
                        }}
                        onClick={() => {
                          setSelectedTab('Inprogress');
                          setCurrentPage(1);
                        }}
                      >
                        In Process
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        style={{
                          marginRight: '5px',
                          borderRadius: '6px',
                          border: selectedTab === 'Completed' ? '2px' : '2px solid #00C289',
                          background: selectedTab === 'Completed' ? '#00C289' : '#FFFFFF',
                          color: '#000000',
                          fontFamily: 'Roboto',
                          fontWeight: '500',
                          fontSize: '20px',
                        }}
                        onClick={() => {
                          setSelectedTab('Completed');
                          setCurrentPage(1);
                        }}
                      >
                        Completed
                      </Button>
                    </Col>
                    {
                fullFillDetails && fullFillDetails.length !== 0 && (
                  <>
                    {
                      fullFillDetails.map(item => (
                        <Col>
                          <Button
                            style={{
                              marginRight: '5px',
                              borderRadius: '6px',
                              border: getBorderColor(item.status),
                              background: getBackgroundColor(item.status),
                              color: getColor(item.status),
                              fontFamily: 'Roboto',
                              fontWeight: '500',
                              fontSize: '20px',
                            }}
                            onClick={() => {
                              setSelectedTab(item.status === 'OUT_FOR_DELIVERY'
                                ? 'OutforDelivery' : item.status === 'ACCEPTED_BY_AFFILIATE'
                                  ? 'AcceptedAffiliate' : item.status === 'DELIVERED' ? 'Delivered' : '');
                              setCurrentPage(1);
                            }}
                          >
                            {item.status === 'OUT_FOR_DELIVERY'
                              ? 'Out for Delivery' : item.status === 'ACCEPTED_BY_AFFILIATE'
                                ? 'Accepted by Affiliate' : item.status === 'DELIVERED' ? 'Delivered' : ''}
                          </Button>
                        </Col>
                      ))
                    }
                  </>
                )
              }
                  </>
                ) : (
                  <>
                    {
                      fullFillDetails && fullFillDetails.length !== 0 && (
                        <>
                          {
                            fullFillDetails.map(item => (
                              <Col>
                                <Button
                                  style={{
                                    marginRight: '5px',
                                    borderRadius: '6px',
                                    border: getBorderColor(item.status),
                                    background: getBackgroundColor(item.status),
                                    color: getColor(item.status),
                                    fontFamily: 'Roboto',
                                    fontWeight: '500',
                                    fontSize: '20px',
                                  }}
                                  onClick={() => {
                                    setSelectedTab(item.status === 'OUT_FOR_DELIVERY'
                                      ? 'OutforDelivery' : item.status === 'ACCEPTED_BY_AFFILIATE'
                                        ? 'AcceptedAffiliate' : item.status === 'DELIVERED' ? 'Delivered' : '');
                                    setCurrentPage(1);
                                  }}
                                >
                                  {item.status === 'OUT_FOR_DELIVERY'
                                    ? 'Out for Delivery' : item.status === 'ACCEPTED_BY_AFFILIATE'
                                      ? 'Accepted by Affiliate' : item.status === 'DELIVERED' ? 'Delivered' : ''}
                                </Button>
                              </Col>
                            ))
                          }
                        </>
                      )
                    }
                  </>
                )
              } */}
            </Row>
          </Col>
        </Row>
      </header>

      <div style={{ overflow: 'auto', flex: 1, padding: '20px' }}>
        <Row
          gutter={[16, 16]}
          justify="space-between"
          style={{ paddingBottom: '45px' }}
        >
          {/* SELECTED ORDER MODAL */}
          {
            orderModal && (
              <SelectedOrder
                visible={orderModal}
                submitting={submitting}
                selectedTab={selectedTab}
                close={() => setOrderModal(false)}
                selectedAccount={selectedAccount}
                selectedOrderItems={selectedOrderItems}

                updateItemsStatus={updateItemsStatus}
                updateDeliveryOrder={updateDeliveryOrder}
                submit={updateSelectedItems}
                history={history}
                role={role}
              />
            )
          }
          {/* SELECTED ITEM MODAL */}
          {
            lineItemModal && (
              <UpdateItemStatus
                visible={lineItemModal}
                submitting={submitting}
                selectedTab={selectedTab}
                close={() => setLineItemModal(false)}
                selectedAccount={selectedAccount}
                selectedLineItem={selectedLineItem}
                submit={updateItemsStatus}
              />
            )
          }
          {/* SELECTED ORDER FULL FILL MODAL */}
          {
            outforDeliveryModal && (
              <OutForDelivery
                visible={outforDeliveryModal}
                submitting={submitting}
                selectedTab={selectedTab}
                close={() => setOutforDeliveryModal(false)}
                selectedAccount={selectedAccount}
                selectFullfillId={selectFullfillId}
                submit={updateDeliveryOrder}
              />
            )
          }
          {
            selectedTab === 'Inprogress' ? (
              (orderDetails && orderDetails.length) > 0 ? (
                orderDetails && orderDetails.map((item) => (
                  (!item?.shop?.deleted)
                  && (
                  <Col key={item.id} xs={24} sm={12} md={8} lg={6} style={{ marginBottom: '20px' }}>
                    <div>
                      <div
                        style={{
                          background: '#F9DC5C',
                          color: '#000000',
                        }}
                      >
                        <Row
                          type="flex"
                          justify="space-between"
                          className="pt-2"
                        >
                          <Col
                            className="ml-3"
                            style={{
                              fontFamily: 'Roboto',
                              fontWeight: '500',
                              fontSize: '22px',
                            }}
                          >

                            <span onClick={() => selectedOrders(item)} style={{ cursor: 'pointer' }}>
                              {(item.cloverPosOrderId) || (item.orderNumber)}
                            </span>

                          </Col>
                          <Col
                            className="mr-3"
                            onClick={() => {
                              setOrderModal(true);
                              setSelectedOrderItems(item);
                            }}
                          >
                            <img
                              src={ModalOpen}
                              alt=""
                            />
                          </Col>
                        </Row>
                        {(item.orderNumber) ? (
                          <Row
                            type="flex"
                            justify="space-between"
                          >
                            <Col
                              className="ml-3"
                              style={{
                                fontFamily: 'italic',
                                fontWeight: '400',
                                fontSize: '22px',
                              }}
                            >
                              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(item)}>
                                {item.orderNumber}
                              </span>

                            </Col>
                          </Row>
                        ) : (
                          <></>
                        )}
                        <Row
                          type="flex"
                          justify="space-between"
                        >
                          <Col
                            className="ml-3"
                            style={{
                              fontFamily: 'italic',
                              fontWeight: '400',
                              fontSize: '22px',
                            }}
                          >
                            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(item)}>
                              {item.customer
                                ? ((item.customer && item.customer.name) || '')
                                : (item.employeeName || '')}
                            </span>

                          </Col>
                          <Col
                            className="mr-3"
                            style={{
                              fontFamily: 'Roboto',
                              fontWeight: '500',
                              fontSize: '22px',
                            }}
                          >
                            <TimerComponent
                              startTime={item.createdAt}
                              selectedAccount={selectedAccount}
                            />
                          </Col>
                        </Row>
                      </div>
                      {
                        (item.shopTableReservationDto && item.shopTableReservationDto.shopTable) ? (
                          <div
                            style={{
                              background: '#FF0054',
                              color: '#FFFFFF',
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '12px 16px',
                            }}
                          >
                            <div
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '22px',
                              }}
                            >

                              <Checkbox
                                className="mr-2"
                                onChange={() => handleOrderCheckboxChange(item)}
                                checked={(item.status === 'COMPLETED') || orderList.includes(item.gid)}
                              />
                              {(item.shopTableReservationDto && item.shopTableReservationDto.shopTable && item.shopTableReservationDto.shopTable.name)
                                || ''}
                            </div>
                            <div
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '22px',
                              }}
                            >
                              {getItemInfo(item.inventoryOrderLineItems)}
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              background: '#0085A3',
                              color: '#FFFFFF',
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '12px 16px',
                            }}
                          >
                            <div
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '22px',
                              }}
                            >
                              <Checkbox
                                className="mr-2"
                                onChange={() => handleOrderCheckboxChange(item)}
                                checked={(item.status === 'COMPLETED') || orderList.includes(item.gid)}
                              />
                              {(item.inventoryOrderType && item.inventoryOrderType.label) || ''}
                            </div>
                            <div
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '22px',
                              }}
                            >
                              {getItemInfo(item.inventoryOrderLineItems)}
                            </div>
                          </div>
                        )
                      }

                      <div
                        style={{
                          background: '#F3F3F3',
                          color: '#000000',
                          overflowY: 'auto',
                          maxHeight: '20vh',
                          minHeight: '20vh',
                        }}
                      >
                        {
                          (item.inventoryOrderLineItems && item.inventoryOrderLineItems.length > 0) ? (
                            item.inventoryOrderLineItems.map((items) => (
                              <Row
                                type="flex"
                                className="pt-2"
                                style={{
                                  fontFamily: 'Roboto',
                                  fontWeight: '500',
                                  fontSize: '20px',
                                }}
                              >
                                <Col
                                  className="ml-3"
                                >
                                  <Checkbox
                                    className="mr-2"
                                    onChange={() => handleCheckboxChange(items.gid, items.status)}
                                    checked={(items.status === 'COMPLETED') || lineItems.includes(items.gid)}
                                    // disabled={items.status === 'COMPLETED'}
                                    disabled={orderList.includes(item.gid) || (role && role.name === 'associate') || false}
                                  />
                                  {items.quantity} X {items.itemName || (items.itemData && items.itemData.name)}
                                  {
                                    (items.lineItemModifiers && items.lineItemModifiers.length > 0) ? (
                                      items.lineItemModifiers.map(modifier => (
                                        <Row
                                          className="ml-4"
                                          style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '16px',
                                            color: '#000000',
                                          }}
                                        >
                                          <Col className="ml-3">
                                            {modifier.quantity} x {modifier.modifierName}
                                          </Col>
                                        </Row>
                                      ))
                                    ) : ''
                                  }
                                  {
                                    items.note && (
                                      <Row
                                        className="ml-4"
                                        style={{
                                          fontFamily: 'Roboto',
                                          fontWeight: '500',
                                          fontSize: '14px',
                                          color: '#000000',
                                        }}
                                      >
                                        <Col>{`(${items.note})`}</Col>
                                      </Row>
                                    )
                                  }
                                </Col>
                              </Row>
                            ))
                          ) : (
                            <div
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '20px',
                                marginLeft: '20px',
                                marginTop: '10px',
                              }}
                            >
                              No Items
                            </div>
                          )
                        }

                      </div>
                    </div>
                  </Col>
                  )
                ))
              ) : (
                <div style={{
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                  fontSize: '22px',
                }}
                >There are 0 Inprocess Orders.
                </div>
              )
            ) : (
              (orderDetails && orderDetails.length) > 0 ? (
                orderDetails && orderDetails.map((item) => (
                  <Col key={item.id} xs={24} sm={12} md={8} lg={6} style={{ marginBottom: '20px' }}>
                    <div>

                      <div
                        style={{
                          background: selectedTab === 'Completed'
                            ? '#1AB58B' : selectedTab === 'OutforDelivery'
                              ? '#FD5D5D' : selectedTab === 'AcceptedAffiliate'
                                ? '#2CADD6' : '#045762',
                          color: '#FFFFFF',
                        }}
                      >
                        <Row
                          type="flex"
                          justify="space-between"
                          className="pt-2"
                        >
                          <Col
                            className="ml-3"
                            style={{
                              fontFamily: 'Roboto',
                              fontWeight: '500',
                              fontSize: '22px',
                            }}
                          >
                            <span onClick={() => selectedOrders(item)} style={{ cursor: 'pointer' }}>
                              {(item.cloverPosOrderId) || (item.orderNumber)}
                            </span>
                            {
                              selectedTab === 'Completed' && (
                                <Checkbox
                                  className="ml-3"
                                  onChange={() => handleFullfillChange(item.gid)}
                                  checked={(item.gid === selectFullfillId) ? outforDeliveryModal : false}
                                  disabled={(role && role.name === 'associate') || false}
                                />
                              )
                            }
                          </Col>
                          <Col
                            className="mr-3"
                            onClick={() => {
                              setOrderModal(true);
                              setSelectedOrderItems(item);
                            }}
                          >
                            <img
                              src={ModelOpenLight}
                              alt=""
                            />
                          </Col>
                        </Row>
                        <Row
                          type="flex"
                          justify="space-between"
                        >
                          <Col
                            className="ml-3"
                            style={{
                              fontFamily: 'italic',
                              fontWeight: '400',
                              fontSize: '22px',
                            }}
                          >
                            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(item)}>
                              {item.customer
                                ? ((item.customer && item.customer.name) || '')
                                : (item.employeeName || '')}
                            </span>
                          </Col>
                          <Col
                            className="mr-3"
                            style={{
                              fontFamily: 'Roboto',
                              fontWeight: '500',
                              fontSize: '22px',
                            }}
                          >
                            <TimerComponent
                              startTime={item.createdAt}
                              selectedAccount={selectedAccount}
                            />
                          </Col>
                        </Row>
                      </div>
                      {
                        (item.shopTableReservationDto && item.shopTableReservationDto.shopTable) ? (
                          <div
                            style={{
                              background: '#FF0054',
                              color: '#FFFFFF',
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '12px 16px',
                            }}
                          >
                            <div
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '22px',
                              }}
                            >
                              {(item.shopTableReservationDto && item.shopTableReservationDto.shopTable && item.shopTableReservationDto.shopTable.name)
                                || ''}
                            </div>
                            <div
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '22px',
                              }}
                            >
                              {`(${(item.inventoryOrderLineItems && item.inventoryOrderLineItems.length)} Items)`}
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              background: '#858585',
                              color: '#FFFFFF',
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '12px 16px',
                            }}
                          >
                            <div
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '22px',
                              }}
                            >
                              {(item.inventoryOrderType && item.inventoryOrderType.label) || ''}
                            </div>
                            <div
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '22px',
                              }}
                            >
                              {`(${(item.inventoryOrderLineItems && item.inventoryOrderLineItems.length)} Items)`}
                            </div>
                          </div>
                        )
                      }
                      <div
                        style={{
                          background: '#F3F3F3',
                          color: '#000000',
                          overflowY: 'auto',
                          maxHeight: '20vh',
                          minHeight: '20vh',
                        }}
                      >
                        {
                          (item.inventoryOrderLineItems && item.inventoryOrderLineItems.length > 0) ? (
                            item.inventoryOrderLineItems.map((items) => (
                              <Row
                                type="flex"
                                className="pt-2"
                                style={{
                                  fontFamily: 'Roboto',
                                  fontWeight: '500',
                                  fontSize: '20px',
                                }}
                              >
                                <Col
                                  className="ml-3"
                                >
                                  <Checkbox
                                    className="mr-2"
                                    checked
                                    disabled
                                  />
                                  {items.quantity} X {items.itemName || (items.itemData && items.itemData.name)}
                                  {
                                    (items.lineItemModifiers && items.lineItemModifiers.length > 0) ? (
                                      items.lineItemModifiers.map(modifier => (
                                        <Row
                                          className="ml-4"
                                          style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '16px',
                                            color: 'rgba(0, 0, 0, 0.5)',
                                          }}
                                        >
                                          <Col>
                                            {modifier.quantity} x {modifier.modifierName}
                                          </Col>
                                        </Row>
                                      ))
                                    ) : ''
                                  }
                                  {
                                    items.note && (
                                      <Row
                                        className="ml-4"
                                        style={{
                                          fontFamily: 'Roboto',
                                          fontWeight: '500',
                                          fontSize: '14px',
                                          color: '#000000',
                                        }}
                                      >
                                        <Col>{`(${items.note})`}</Col>
                                      </Row>
                                    )
                                  }
                                </Col>
                              </Row>
                            ))
                          ) : (
                            <div
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '20px',
                                marginLeft: '20px',
                                marginTop: '10px',
                              }}
                            >
                              No Items
                            </div>
                          )
                        }

                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <div style={{
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                  fontSize: '22px',
                }}
                >There are 0 Completed Orders.
                </div>
              )
            )
          }
        </Row>
      </div>
      {/* Footer */}
      {
        role && role.name !== 'associate' ? (
          <footer
            style={{
              padding: '8px',
              borderTop: '1px solid #ccc',
              marginTop: 'auto',
              position: 'fixed',
              bottom: '0px',
              background: '#fff',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {
              selectedTab === 'Inprogress' ? (
                <div
                  disabled={(lineItems.length === 0) || false}
                  style={{
                    opacity: lineItems.length === 0 ? 0.5 : '',
                    cursor: lineItems.length === 0 ? 'not-allowed' : 'pointer',
                  }}
                  onClick={(lineItems.length === 0)
                    ? null
                    : updateNewOrderItems}
                >
                  <img
                    style={{
                      height: '55px',
                    }}
                    src={CompleteButton}
                    alt=""
                  />
                </div>
              ) : (
                <div
                  disabled
                  style={{
                    opacity: 0.5,
                    cursor: 'not-allowed',
                  }}
                >
                  <img
                    style={{
                      height: '55px',
                    }}
                    src={CompleteButton}
                    alt=""
                  />
                </div>
              )
            }
            {
              totalElements && totalElements > 0 ? (
                <Row
                  className="mt-2"
                  type="flex"
                  style={{
                    marginRight: '20%',
                  }}
                >
                  <Col className="mr-2">
                    <div
                      style={{
                        paddingTop: '6px',
                      }}
                    >
                      {`Total ${totalElements} items`}
                    </div>
                  </Col>
                  <Col>
                    <Pagination
                      current={currentPage}
                      pageSize={10}
                      total={totalElements}
                      onChange={handleTableChange}
                    // showSizeChanger
                    />
                  </Col>
                </Row>

              ) : null
            }
          </footer>
        ) : null
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  shopData: state.shopDetails.content,
  loading: state.loading.loading,
  selectedAccount: state.account.selectedAccount,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
  orderDetails: state.shopDetails.orderDetails.content,
  totalElements: state.shopDetails.orderDetails.totalElements,
  shopsList: state.shopDetails.shopsList.content,
  fullFillDetails: state.shopDetails.fullFillDetails.content,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchShopDetails: (payload) => dispatch(shopDetailsActions.fetchShopDetails({
    payload,
  })),
  fetchShopOrderDetails: (payload) => dispatch(shopDetailsActions.fetchShopOrderDetails({
    payload,
  })),
  updateLineItems: (payload) => dispatch(shopDetailsActions.updateLineItems({
    payload,
  })),
  sendFcmToken: (payload) => dispatch(shopDetailsActions.sendFcmToken({
    payload,
  })),
  fetchAllShopDetails: (payload) => dispatch(shopDetailsActions.fetchAllShopDetails({
    payload,
  })),
  fetchFullfillDetails: (payload) => dispatch(shopDetailsActions.fetchFullfillDetails({
    payload,
  })),
  updateFullFill: (payload) => dispatch(shopDetailsActions.updateFullFill({
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OrdersView);
