import { CHEQUE_STATUSES } from 'appconstants';

export default value => {
  if (value === 'VOID') {
    return CHEQUE_STATUSES.VOID;
  } if (value === 'PAID') {
    return CHEQUE_STATUSES.PAID;
  } if (value === 'PENDING') {
    return CHEQUE_STATUSES.PENDING;
  } if (value === 'CANCELLED') {
    return CHEQUE_STATUSES.CANCELLED;
  } return CHEQUE_STATUSES.PAID;
};
