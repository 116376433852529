// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  Checkbox,
  DatePicker,
  AutoComplete,
  Row,
  Col,
  Icon,
  Divider,
} from 'antd';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import getDiscountOff from 'utils/getDiscountOff';
import getTaxAmount from 'utils/getTaxAmount';
import formatNumber from 'utils/formatNumber';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import {
  SpH5,
  SpError,
  SpButton,
  SpText,
} from 'components/DesignKit';
import {
  COUNTRIES,
  MAX_AMOUNT,
  RATE_TYPES,
} from 'appconstants';
import {
  invoiceDetailsAction,
  inventoryItemsActions,
  inventoryPrinterLabelActions
} from 'store/actions';
import getSortedList from 'utils/getSortedList';
import AddNewProduct from './AddNewProduct';
import SelectCoupon from './SelectCoupon';
import AddCoupon from './AddNewCoupon';
import SelectTaxrate from './SelectTaxrate';
import AddTaxrate from './AddNewTax';
import AddItem from '../../../InventoryOrders/components/ItemsList/components/AddNewItem'

const { Option } = Select;
type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  validator: boolean,
  selectedAccount: Object,
  fetchPlanDetails: Function,
  planData: Object,
  getProductsList: Function,
  productsList: Array<Object>,
  addNewProcut: Function,
  addedNewProduct: Object,
  subScriptionModal: Function,
  newCoupon: Object,
  getCustomersList: Function,
  addNewCoupon: Function,
  taxList: Array<Object>,
  getTaxes: Function,
  addNewTaxRate: Function,
  newTaxrate: Object,
  customerResult: Array,
  internalAccount: Object,
  fetchInventoryItems: Function,
  fetchItemsList: Function,
  fetchPrinterLabelList: Function,
  fetchModifierItemsList: Function,
  itemsList: Array<Object>,
  modifierItemsList: Array<Object>,
  labelList: Array,
  addInventoryItems: Function,
  inventoryItems: Array<Object>,
  addedNewItem:Object,
  hanldleItemModalRedirect: Function,
  hanldleAddInventoryRedirect: Function,


};


const AddSubscriptionButton = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    fetchPlanDetails,
    planData,
    getProductsList,
    productsList,
    validator,
    addNewProcut,
    addedNewProduct,
    subScriptionModal,
    newCoupon,
    getCustomersList,
    addNewCoupon,
    taxList,
    getTaxes,
    addNewTaxRate,
    newTaxrate,
    customerResult,
    internalAccount,
    fetchInventoryItems,
    itemsList,
    modifierItemsList,
    labelList,
    addInventoryItems,
    fetchItemsList,
    fetchModifierItemsList,
    fetchPrinterLabelList,
    inventoryItems,
    addedNewItem,
    hanldleItemModalRedirect,
    hanldleAddInventoryRedirect,
  } = props;

  const [, forceUpdate] = useState();
  const location = useLocation();
  const [planName, setName] = useState('');
  const [description, setDescription] = useState('');
  const [period, setPeriod] = useState(1);
  const [frequency, setFrequency] = useState('MONTH');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [dop, setDop] = useState(null);
  const [startDate, setStartDate] = useState(false);
  const [apiPlanDate, setApiPlanDate] = useState(null);
  const [totalCount, setCount] = useState(null);
  const [dateDisable, setDateDisable] = useState(false);
  const [planId, setPlanId] = useState('');
  const [addedItem, setAddedItem] = useState(false);
  // const currencyType = selectedAccount && selectedAccount.currency;
  const supportedCurrency = (selectedAccount && selectedAccount.supportedCurrencies);
  const currencyObj = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const plans = getSortedList(planData.planList);
  const [disablePlanDetails, setDisablePlanDetails] = useState(false);
  const [couponMsg, setCouponMsg] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const currencyCode = (selectedAccount.currency && selectedAccount.currency.name);
  const prefix = (selectedAccount.currency && selectedAccount.currency.prefix);
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const disableDate = moment().utc().tz(selectedAccount.timezone).startOf('day')
    .format(dateFormat);
  const [lineItemIndex, setLineItemIndex] = useState(null);
  const [addProductModal, setAddProductModal] = useState(false);
  const [addedProduct, setAddedProduct] = useState(false);
  const [updatedCoupon, setUpdatedCoupon] = useState({});
  const [couponRedirect, setCouponRedirect] = useState(false);
  const [addCouponModal, setAddCouponModal] = useState(false);
  const [selectCouponModal, setSelectCouponModal] = useState(false);
  const [selectTaxrateModal, setSelectTaxrateModal] = useState(false);
  const [addTaxModal, setAddTaxModal] = useState(false);
  const [allTaxes, setAllTaxes] = useState(taxList);
  const [allSelectedTaxrates, setAllSelectedTaxrates] = useState([]);
  const [taxRedirect, setTaxRedirect] = useState(false);
  const [upFrontError, setUpFrontError] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [addItemModal, setAddItemModal] = useState(false);
  const [lineItemDtoList, setLineItemDtosList] = useState([{
    amount: 0,
    rate: 0,
    productGid: '',
    currencyCode: '',
    description: '',
    name: '',
    note: '',
    quantity: '1',
    upfront: false,
  }]);
  const legacySubscriber = internalAccount && internalAccount.legacySubscription;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const searchPlan = (value) => {
    fetchPlanDetails(encodeURIComponent(value));
    setName(value);
    setDisablePlanDetails(false);
    const results = plans.filter((item) => (item.name === value));
    if (results && !results[0]) {
      setPlanId('');
    }
  };

  const updatePlan = (value) => {
    const results = plans.filter((item) => (item.name === value));
    if (results && results[0].name) {
      setPlanId(results[0].gid);
      setName(results[0].name);
      setDescription(results[0].description);
      setPeriod(results[0].billingPeriod);
      setFrequency(results[0].billingFrequency);
      setDisablePlanDetails(true);
    }
  };

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (!validator) {
      setAddProductModal(false);
      setAddCouponModal(false);
      setAddTaxModal(false);
      setAddItemModal(false);

    }
  }, [validator]);

  useEffect(() => {
    setAllTaxes(taxList);
  }, [taxList]);

  useEffect(() => {
    setCustomerList(customerResult);
  }, [customerResult]);

  useEffect(() => {
    getProductsList();
    getCustomersList();
  }, [selectedAccount]);

  const onProductSearch = (productSearch) => {
    getProductsList(productSearch);
  };

  const fetchTaxDetails = (searchParams) => {
    getTaxes(searchParams);
  };

  const updateCoupon = (Coupon: Object) => {
    setUpdatedCoupon(Coupon);
    setSelectCouponModal(false);
  };

  const addCoupon = (coupon: Object) => {
    const {
      name,
      percentageOff,
      amountOff,
      validity,
      expiresAt,
      maximumRedemption,
      code,
      minimumAmount,
      promotionalRedeemed,
      promoExpiresAt,
      promotional,
      customerGid,
      validiyInMonths,
    } = coupon;
    const params = {
      active: true,
      name,
      percentageOff: (percentageOff ? parseFloat(percentageOff) : null),
      amountOff: (amountOff ? parseInt((amountOff).replace(/[_\W]+/g, ''), 10) : null),
      validity,
      expiresAt,
      maximumRedemption: (maximumRedemption ? parseInt(maximumRedemption, 10) : null),
      validiyInMonths: (validiyInMonths ? parseInt(validiyInMonths, 10) : null),
      currencyCode,
    };
    const payload = {
      active: true,
      code,
      enabledForCheckout: true,
      expiresAt: promoExpiresAt,
      maximumRedemption: promotionalRedeemed ? parseInt(promotionalRedeemed, 10) : null,
      minimumAmount: minimumAmount ? parseInt((minimumAmount).replace(/[_\W]+/g, ''), 10) : null,
      minimumAmountCurrency: currencyCode,
      restrictedToCustomers: [
        customerGid,
      ],
    };
    if (promotional) {
      addNewCoupon({
        params,
        payload,
      });
    } else if (!promotional) {
      addNewCoupon({
        params,
      });
    }
    subScriptionModal(true);
    setCouponRedirect(true);
  };

  const newCouponAdd = () => {
    setSelectCouponModal(false);
    setAddCouponModal(true);
  };

  const updateTaxrate = (Taxrate: Object) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.push(Taxrate);
    setAllSelectedTaxrates(listOfTaxes);
    setSelectTaxrateModal(false);
  };

  const removeTax = (i) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.splice(i, 1);
    setAllSelectedTaxrates(listOfTaxes);
  };

  const addTaxrate = (taxRate: Object) => {
    const {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      taxDescription,
    } = taxRate;
    const params = {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      description: taxDescription,
    };
    addNewTaxRate({
      params,
    });
    subScriptionModal(true);
    setTaxRedirect(true);
  };

  const getListProducts = () => {
    getProductsList();
  };

  const debouncedFetchInventoryItems = debounce(fetchInventoryItems, 2000, { leading: true });

  const getDataItems = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    // const sortParam = '&sortBy=sortOrder&direction=ASC';
    // const filterParam = parsed.filterParams || '';
    // const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    // const nameSearch = searchText ? (searchText === '' ? null : `&name.LIKE=${searchText}`) : null;
    debouncedFetchInventoryItems({
      currentPage, sortParams,
    });
  };


  useEffect(() => {
    getDataItems(location.search);
  }, [selectedAccount]);
  useEffect(() => {
    if (newCoupon.gid && couponRedirect) {
      setUpdatedCoupon(newCoupon);
    }
  }, [newCoupon]);

  useEffect(() => {
    if (newTaxrate.gid && taxRedirect) {
      const listOfTaxes = [...allSelectedTaxrates];
      const taxRates = {};
      Object.assign(taxRates, newTaxrate);
      const taxRate = {
        taxRates,
      };
      listOfTaxes.push(taxRate);
      setAllSelectedTaxrates(listOfTaxes);
    }
  }, [newTaxrate]);

  const newTaxAdd = () => {
    setSelectTaxrateModal(false);
    setAddTaxModal(true);
  };

  useEffect(() => {
    if (addedProduct && addedNewProduct && addedNewProduct.gid) {
      const list = [...lineItemDtoList];
      const { amount } = addedNewProduct;
      list[lineItemIndex].name = addedNewProduct.name;
      list[lineItemIndex].description = addedNewProduct.name;
      list[lineItemIndex].note = addedNewProduct.note;
      list[lineItemIndex].rate = (amount / 100).toFixed(2);
      list[lineItemIndex].productGid = addedNewProduct.gid;
      list[lineItemIndex].upfront = addedNewProduct.upfront;
      list[lineItemIndex].currencyCode = addedNewProduct.currency && addedNewProduct.currency.name;
      if (list[lineItemIndex].quantity) {
        list[lineItemIndex].amount = amount * parseInt(list[lineItemIndex].quantity, 10);
      }
      const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
      setTotalAmount(amountTotal);
      setLineItemDtosList(list);
    }
  }, [addedNewProduct]);

  useEffect(() => {
    if (addedItem && addedNewItem && addedNewItem.gid) {
      const list = [...lineItemDtoList];
      const { price } = addedNewItem;
      list[lineItemIndex].name = addedNewItem.name;
      list[lineItemIndex].description = addedNewItem.name;
      list[lineItemIndex].note = addedNewItem.note;
      list[lineItemIndex].rate = (price / 100).toFixed(2);
      list[lineItemIndex].itemGid = addedNewItem.gid;
      list[lineItemIndex].upfront = !addedNewItem.recurring;
      list[lineItemIndex].recurring = addedNewItem.recurring;
      list[lineItemIndex].currencyCode = currencyCode;
      if (list[lineItemIndex].quantity) {
        list[lineItemIndex].price = price * parseInt(list[lineItemIndex].quantity, 10);
      }
      const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
      setTotalAmount(amountTotal);
      setLineItemDtosList(list);
    }
  }, [addedNewItem]);

  const getFinalAmount = (amount) => {
    let finalAmount;
    const listOfTaxes = [...allSelectedTaxrates];
    const taxLists = listOfTaxes.filter((item) => item.taxRates.inclusive === false);
    if (taxLists.length > 0) {
      const percentageAmount = taxLists.reduce(
        (total, item) => total + ((parseFloat(amount.replace(/,/g, '')) * item.taxRates.percentage) / 100), 0,
      );
      finalAmount = formatNumber((parseFloat(amount.replace(/,/g, '')) + percentageAmount).toFixed(2), currencyObj);
    } else {
      finalAmount = amount;
    }
    return finalAmount;
  };

  const getTotalAmount = (value) => {
    let finalAmount;
    if (value) {
      const finalValue = parseFloat(value.replace(/,/g, ''));
      finalAmount = formatNumber((totalAmount - finalValue).toFixed(2), currencyObj);
    } else {
      finalAmount = formatNumber((totalAmount).toFixed(2), currencyObj);
    }
    return finalAmount;
  };

  const getAmountOff = (value) => {
    let amountOff;
    if (value.percentageOff) {
      const amount = (totalAmount * value.percentageOff) / 100;
      amountOff = formatNumber((amount).toFixed(2), currencyObj);
    } else if (value.amountOff) {
      amountOff = formatNumber((value.amountOff / 100).toFixed(2), currencyObj);
    }
    return amountOff;
  };

  const fetchCustomer = (searchText) => {
    getCustomersList(searchText);
  };

  const removeCoupon = () => {
    setUpdatedCoupon({});
    setCouponMsg('');
    simpleValidator.current.purgeFields();
  };

  const onDateChange = (date, dateString) => {
    setDop(dateString);
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    setApiPlanDate(utcDate);
  };

  const handleChangeProduct = (e, index) => {
    const gid = e[1];
    const productInfo = productsList.filter((item) => (item.gid === gid));
    const list = [...lineItemDtoList];
    const { amount } = productInfo[0];
    list[index].productGid = productInfo[0].gid;
    list[index].upfront = productInfo[0].upfront;
    list[index].currencyCode = productInfo[0].currency && productInfo[0].currency.name;
    list[index].name = productInfo[0].name;
    list[index].description = productInfo[0].description;
    list[index].note = productInfo[0].note;
    list[index].rate = (amount / 100).toFixed(2);
    if (list[index].quantity) {
      list[index].amount = amount * parseInt(list[index].quantity, 10);
    }
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };

  const handleChangeItem = (e, index) => {
    const gid = e[1];
    const productInfo = inventoryItems.filter((item) => (item.gid === gid));
    const list = [...lineItemDtoList];
    const { price } = productInfo[0];
    // list[index].productGid = productInfo[0].gid;
    list[index].upfront = !productInfo[0].recurring;
    list[index].recurring = productInfo[0].recurring;
    list[index].currencyCode = currencyCode;
    list[index].name = productInfo[0].name;
    list[index].itemGid = gid;
    list[index].description = productInfo[0].description;
    list[index].note = productInfo[0].note;
    list[index].rate = (price / 100).toFixed(2);
    if (list[index].quantity) {
      list[index].price = price * parseInt(list[index].quantity, 10);
    }
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };
  const handleRemoveClick = index => {
    const list = [...lineItemDtoList];
    list.splice(index, 1);
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };

  const addNewProduct = (newProduct: Object) => {
    const {
      productName,
      currency,
      productDescription,
      amount,
      productNotes,
      upfront,
    } = newProduct;

    const productAmount = amount.replace(/[. ,:-]+/g, '');

    const params = {
      name: productName,
      currencyCode: currency,
      description: productDescription,
      amount: Number(productAmount),
      note: productNotes,
      upfront,
      paymentMethodType: [
        'CARD',
      ],
      statementDescription: '',
    };

    addNewProcut({
      params,
    });
    subScriptionModal(true);
    setAddedProduct(true);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.currentTarget;
    let regex;
    if (name === 'quantity') {
      regex = /^[0-9\b]+$/;
    } else if (name === 'rate') {
      regex = /^\d*\.?\d*$/;
    }
    const list = [...lineItemDtoList];
    if (regex.test(value) || value === '') {
      list[index][name] = value;
      if (name === 'quantity' && value && list[index].rate) {
        const sum = list[index].rate * 100;
        list[index].amount = sum * parseInt(list[index][name], 10);
      } else if (name === 'rate' && value && list[index].quantity) {
        const sum = list[index].rate * 100;
        list[index].amount = sum * parseInt(list[index].quantity, 10);
      }
    } else if (!regex.test(value) && name === 'quantity') {
      list[index][name] = '1';
    } else if (!regex.test(value) && name === 'rate') {
      list[index][name] = 0;
    }
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };
  const handleAddClick = (Type) => {
    if (Type === 'product') {
      getListProducts();
    } else {
      getDataItems(location.search);
    }

    setLineItemDtosList([...lineItemDtoList, {
      amount: 0,
      rate: 0,
      productGid: '',
      currencyCode: '',
      description: '',
      name: '',
      note: '',
      quantity: '1',
      recurring: '',
      itemGid: '',
    }]);
  };


  const onCheckChange = (e) => {
    const dateChecked = e.target.checked;
    setStartDate(dateChecked);
    const displayFormat = moment().utc().tz(selectedAccount.timezone).startOf('day')
      .format(dateFormat);
    // eslint-disable-next-line
    const selectedDate = moment.utc().tz(selectedAccount.timezone).startOf('day').utc().format(MOMENT_FORMAT);
    setApiPlanDate(selectedDate);
    if (dateChecked) {
      setDateDisable(true);
      setDop(displayFormat);
    } else {
      setDateDisable(false);
      setDop(null);
    }
  };
  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    let upfrontValidate = true;
    if (legacySubscriber) {
      const filterUpfronts = lineItemDtoList.filter(item => item.upfront === false);
      if (filterUpfronts.length === 0) {
        upfrontValidate = false;
        setUpFrontError('Please select at least one recurring product');
      }
    } else {
      const filterRecurring = lineItemDtoList.filter(item => item.recurring === true);
      if (filterRecurring.length === 0) {
        upfrontValidate = false;
        setUpFrontError('Please select at least one recurring item');
      }
    }

    if (upfrontValidate) {
      setUpFrontError(null);
    }
    setDisableButton(formValid && upfrontValidate);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    } if (!upfrontValidate) {
      forceUpdate(1);
      return;
    }
    submit({
      name: planName,
      description: description || '',
      period,
      totalCount,
      dop,
      frequency,
      startDate,
      apiPlanDate,
      redirectUrl,
      planId,
      currencyCode,
      lineItemDtoList,
      allSelectedTaxrates,
      updatedCoupon,
    });
  };

  const onItemListSearch = (itemSearch) => {
    const sortParams = '&sortBy=name&direction=ASC';
    const currentPage = 1;
    const filterParams = '';
    debouncedFetchInventoryItems({
      currentPage, sortParams, filterParams, nameSearch: itemSearch ? (itemSearch === '' ? null : `&name.LIKE=${itemSearch}`) : null,
    });
  };

  const addNewItemforPlan = () => {
    setAddItemModal(true);
    fetchItemsList('&sortBy=name&direction=DESC');
    fetchModifierItemsList('&sortBy=name&direction=DESC');
    fetchPrinterLabelList('&sortBy=createdAt&direction=DESC');
    hanldleItemModalRedirect(true);
  };
  const addSelectedItem = (customer: Object) => {
    const {
      name,
      price,
      alternateName,
      onlineName,
      onlineEnabled,
      sku,
      isPos,
      priceType,
      primaryColor,
      categories,
      order,
      modifierGroups,
      isItemAvailable,
      cost,
      logoUrl,
      // eslint-disable-next-line no-shadow
      description,
      tagDetails,
      enableStock,
      enableDynamicAvailability,
      stockQuantity,
      onlineAvailability,
      posAvailability,
      shopDetails,
      isRecurring,

    } = customer;
    const totalPrice = price ? price.replace(/[^\d]/g, '') : 0;
    const totalCost = cost ? cost.replace(/[^\d]/g, '') : 0;

    const params = {
      name,
      alternateName,
      onlineName,
      onlineEnabled,
      sku,
      price: Number(totalPrice),
      hidden: !isPos,
      priceType,
      sortOrder: Number(order),
      colorCode: primaryColor,
      categoryGids: categories || null,
      shopGids: shopDetails || [],
      modifierGroupGids: modifierGroups || null,
      available: isItemAvailable,
      cost: Number(totalCost),
      imageUrl: logoUrl,
      description,
      tagGids: tagDetails || [],
      enableStock,
      enableDynamicAvailability,
      stockQuantity: Number(stockQuantity),
      onlineAvailability: Number(onlineAvailability * 100),
      posAvailability: Number(posAvailability * 100),
      recurring: isRecurring,
    };
    addInventoryItems({
      params,
    });
    setAddedItem(true);
    hanldleAddInventoryRedirect(true);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="640px"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={(event) => validate(event)} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Subscription Button"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Plan Name</SpH5></label>
          <AutoComplete
            placeholder="Enter a Plan name"
            showSearch
            className="w-100"
            dataSource={plans.map((item, idx) => (
              <Option key={idx} value={item.name}>
                {item.name}
              </Option>
            ))}
            onSearch={searchPlan}
            defaultValue={planName}
            onSelect={e => updatePlan(e)}
          >
            <Input.Search enterButton />
          </AutoComplete>
          <SpError>
            {simpleValidator.current.message('plan name', planName, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Plan Description</SpH5></label>
          <Input
            placeholder="Optional"
            value={description}
            disabled={disablePlanDetails}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </div>
        <div className="mt-2 mb-1">
          <label htmlFor="">
            {legacySubscriber ? <SpH5>Select Product</SpH5> : <SpH5>Select Item</SpH5>}
            <div className="pt-1">
              {
                upFrontError && (
                  <SpError>{upFrontError}</SpError>
                )
              }
            </div>
          </label>
        </div>
        {legacySubscriber
          ? (

            <div
              className="mb-4"
              style={{
                border: '1px solid #C4C4C4',
                background: '#ffffff',
                borderRadius: '6px',
                paddingLeft: '5px',
              }}
            >
              <Row type="flex" className="my-2 mb-3 mt-2">
                <Col span={8}>
                  <div className="pt-1 pb-1 pl-1"><SpText fontWeight="600">Description</SpText></div>
                </Col>
                <Col span={16}>
                  <Row type="flex" justify="end">
                    <Col span={5} className="pt-1 mr-3" align="left">
                      <SpText fontWeight="600" fontSize="14px">Recurring?</SpText>
                    </Col>
                    <Col span={4} className="pt-1 mr-3" align="left">
                      <SpText fontWeight="600" fontSize="14px">Rate</SpText>
                    </Col>
                    <Col span={4} className="pt-1 mr-3" align="center">
                      <SpText fontWeight="600" fontSize="14px">Quantity</SpText>
                    </Col>
                    <Col span={4} className="pt-1 mr-3" align="right">
                      <SpText fontWeight="600" fontSize="14px">Total</SpText>
                    </Col>
                    <Col span={2} className="pt-1" align="right" />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" className="my-2 mb-3 mt-2" style={{ padding: '0 0 0 5px' }}>
                {lineItemDtoList.map((item, i) => (
                  <>
                    <Col span={8} className="pb-1">
                      <div className="w-100 pr-3">
                        <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                          <Select
                            value={item.name || 'Select a product'}
                            style={{ width: '100%' }}
                            showSearch
                            onSelect={e => handleChangeProduct(e, i)}
                            onSearch={onProductSearch}
                            dropdownRender={menu => (
                              <div>
                                {menu}
                                <Divider style={{ margin: '2px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                                  <span
                                    style={{
                                      flex: 'none',
                                      padding: '8px',
                                      display: 'block',
                                      cursor: 'pointer',
                                      color: '#279dfe',
                                    }}
                                    onClick={() => {
                                      setAddProductModal(true);
                                      setLineItemIndex(i);
                                    }}
                                  >
                                    <Icon type="plus-circle" />&nbsp;Create New Product
                                  </span>
                                </div>
                              </div>
                            )}
                          >
                            {productsList.map((product) => (
                              <Option
                                key={product.gid}
                                value={[
                                  product.name,
                                  product.gid,
                                ]}
                              >
                                {product.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <SpError>
                          {simpleValidator.current.message('product', lineItemDtoList[i].name, 'required')}
                        </SpError>
                      </div>
                    </Col>
                    <Col span={16}>
                      <Row type="flex" justify="end">
                        <Col span={4} className="pt-1 mr-2">
                          {item.upfront === true ? 'No' : 'Yes'}
                        </Col>
                        <Col span={4} className="pt-1 mr-4">
                          <SpText fontWeight="600">{prefix} {item.rate || '0.00'}</SpText>
                        </Col>
                        <Col span={4} className="mr-3">
                          <Input
                            name="quantity"
                            value={item.quantity}
                            maxlength="3"
                            onChange={e => handleInputChange(e, i)}
                            onKeyPress={e => {
                              const keyCode = e.charCode || e.which;
                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <SpError>
                            {simpleValidator.current.message('Quantity', lineItemDtoList[i].quantity, 'required|numeric')}
                          </SpError>
                        </Col>
                        <Col span={4} className="pt-1 mr-3" align="right">
                          <SpText fontWeight="600">{prefix} {formatNumber((item.rate * item.quantity).toFixed(2), currencyObj)}</SpText>
                        </Col>
                        <Col span={2} className="pt-1" align="right">
                          {i !== 0 ? (
                            <Icon
                              type="minus-circle"
                              className="mr-2 mt-1"
                              style={{ cursor: 'pointer', color: '#ff4d50' }}
                              onClick={() => handleRemoveClick(i)}
                            />
                          ) : (
                            <span className="mr-2 mt-2">&nbsp;&nbsp;&nbsp;</span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </>
                ))}
              </Row>

              <Row type="flex" className="mt-2">
                <Col span={10}>
                  <div className="pt-1 pb-1 ml-2" style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => handleAddClick('product')}
                    >
                      {'\u002B'}&nbsp; Add New Product
                    </SpText>
                  </div>
                </Col>
                <Col span={14}>
                  <Row type="flex" justify="end" className="ml-1">
                    <Col span={12} className="pt-1 ml-5" align="left">
                      <SpText fontWeight="600" fontSize="14px">Subtotal</SpText>
                    </Col>
                    <Col span={6} className="pt-1 pr-3" align="right">
                      <SpText fontWeight="600">
                        {`${prefix} ${formatNumber((totalAmount).toFixed(2), currencyObj)}`}
                      </SpText>
                    </Col>
                    <Col span={2} className="pt-1" align="right" />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="end" className="ml-2 mb-2">
                {updatedCoupon.gid ? (
                  <Col span={14}>
                    <Row type="flex" justify="end">
                      <Col span={12} className="pt-1 ml-3" align="left">
                        <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                          <SpText>
                            {updatedCoupon.name}
                          </SpText><br />
                          <SpText fontSize="12px">
                            {getDiscountOff(updatedCoupon)}
                          </SpText>
                        </div>
                        <div>
                          <SpError>
                            {couponMsg}
                          </SpError>
                        </div>
                      </Col>
                      <Col span={6} className="pt-2 mr-3" align="right">
                        <SpText fontWeight="600">({prefix} {getAmountOff(updatedCoupon)})</SpText>
                      </Col>
                      <Col span={2} className="pt-1" align="right">
                        <Icon
                          type="minus-circle"
                          className="mr-2 mt-1"
                          style={{ cursor: 'pointer', color: '#ff4d50' }}
                          onClick={removeCoupon}
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={14}>
                    <Row type="flex" justify="end">
                      <Col span={12} className="pt-1 ml-3" align="left">
                        <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={() => setSelectCouponModal(true)}
                          >
                            Apply Coupon
                          </SpText>
                        </div>
                      </Col>
                      <Col span={8} className="pt-1 mr-3" align="right" />
                    </Row>
                  </Col>
                )}
              </Row>
              <Row type="flex" justify="end" className="ml-2">
                {
                  allSelectedTaxrates.map((item, i) => (
                    <Col span={14}>
                      <Row type="flex" justify="end">
                        <Col span={9} className="pt-1 ml-3" align="left">
                          <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                            <SpText>
                              {item.taxRates.displayName}
                            </SpText><br />
                            <SpText fontSize="12px">
                              {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                            </SpText>
                          </div>
                        </Col>
                        <Col span={3} className="pt-1" align="right">{item.taxRates.percentage} %</Col>
                        <Col span={6} className="pt-2 mr-3" align="right">
                          <SpText fontWeight="600">
                            {prefix} {getTaxAmount(item.taxRates, totalAmount, getAmountOff(updatedCoupon), selectedAccount)}
                          </SpText>
                        </Col>
                        <Col span={2} className="pt-1" align="right">
                          <Icon
                            type="minus-circle"
                            className="mr-2 mt-1"
                            style={{ cursor: 'pointer', color: '#ff4d50' }}
                            onClick={() => removeTax(i)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))
                }
              </Row>
              <Row type="flex" justify="end" className="ml-2 mb-2">
                <Col span={14}>
                  <Row type="flex" justify="end">
                    <Col span={12} className="pt-1 ml-3" align="left">
                      <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                        <SpText
                          fontWeight="600"
                          color="#279dfe"
                          onClick={() => setSelectTaxrateModal(true)}
                        >
                          {
                            allSelectedTaxrates.length !== 0 && (
                              <span>{'\u002B'}&nbsp;</span>
                            )
                          }
                          Add Tax Rates
                        </SpText>
                      </div>
                    </Col>
                    <Col span={8} className="pt-1 mr-3" align="right" />
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row type="flex" justify="end" className="mr-3">
                <Col className="mt-1 pb-1 mr-3 pr-1">
                  <SpText className="mr-5" fontWeight="500" fontSize="20px">
                    Total Amount
                  </SpText>
                  <SpText fontWeight="600" fontSize="20px">
                    {
                      (getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))) < 0 ? (
                        `${prefix} 0.00`
                      ) : (
                        `${prefix} ${getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))}`
                      )
                    }
                  </SpText>
                </Col>
              </Row>
            </div>
          )
          : (
            <div
              className="mb-4"
              style={{
                border: '1px solid #C4C4C4',
                background: '#ffffff',
                borderRadius: '6px',
                paddingLeft: '5px',
              }}
            >
              <Row type="flex" className="my-2 mb-3 mt-2">
                <Col span={8}>
                  <div className="pt-1 pb-1 pl-1"><SpText fontWeight="600">Description</SpText></div>
                </Col>
                <Col span={16}>
                  <Row type="flex" justify="end">
                    <Col span={5} className="pt-1 mr-3" align="left">
                      <SpText fontWeight="600" fontSize="14px">Recurring?</SpText>
                    </Col>
                    <Col span={4} className="pt-1 mr-3" align="left">
                      <SpText fontWeight="600" fontSize="14px">Rate</SpText>
                    </Col>
                    <Col span={4} className="pt-1 mr-3" align="center">
                      <SpText fontWeight="600" fontSize="14px">Quantity</SpText>
                    </Col>
                    <Col span={4} className="pt-1 mr-3" align="right">
                      <SpText fontWeight="600" fontSize="14px">Total</SpText>
                    </Col>
                    <Col span={2} className="pt-1" align="right" />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" className="my-2 mb-3 mt-2" style={{ padding: '0 0 0 5px' }}>
                {lineItemDtoList.map((item, i) => (
                  <>
                    <Col span={8} className="pb-1">
                      <div className="w-100 pr-3">
                        <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                          <Select
                            value={item.name || 'Select a Item'}
                            style={{ width: '100%' }}
                            showSearch
                            onSearch={onItemListSearch}
                            onSelect={e => handleChangeItem(e, i)}
                            dropdownRender={menu => (
                              <div>
                                {menu}
                                <Divider style={{ margin: '2px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                                  <span
                                    style={{
                                      flex: 'none',
                                      padding: '8px',
                                      display: 'block',
                                      cursor: 'pointer',
                                      color: '#279dfe',
                                    }}
                                    onClick={() => {
                                      addNewItemforPlan(i);
                                      setLineItemIndex(i);
                                    }}
                                  >
                                    <Icon type="plus-circle" />&nbsp;Create New Item
                                  </span>
                                </div>
                              </div>
                            )}
                          >
                            {inventoryItems && inventoryItems.map((inventoryitem) => (
                              <Option
                                key={inventoryitem.gid}
                                value={[
                                  inventoryitem.name,
                                  inventoryitem.gid,
                                  inventoryitem.price,
                                ]}
                              >
                                {inventoryitem.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <SpError>
                          {simpleValidator.current.message('product', lineItemDtoList[i].name, 'required')}
                        </SpError>
                      </div>
                    </Col>
                    <Col span={16}>
                      <Row type="flex" justify="end">
                        <Col span={4} className="pt-1 mr-2">
                          {(item.recurring === true) ? 'Yes' : 'No'}
                        </Col>
                        <Col span={4} className="pt-1 mr-4">
                          <SpText fontWeight="600">{prefix} {item.rate || '0.00'}</SpText>
                        </Col>
                        <Col span={4} className="mr-3">
                          <Input
                            name="quantity"
                            value={item.quantity}
                            maxlength="3"
                            onChange={e => handleInputChange(e, i)}
                            onKeyPress={e => {
                              const keyCode = e.charCode || e.which;
                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <SpError>
                            {simpleValidator.current.message('Quantity', lineItemDtoList[i].quantity, 'required|numeric')}
                          </SpError>
                        </Col>
                        <Col span={4} className="pt-1 mr-3" align="right">
                          <SpText fontWeight="600">{prefix} {formatNumber((item.rate * item.quantity).toFixed(2), currencyObj)}</SpText>
                        </Col>
                        <Col span={2} className="pt-1" align="right">
                          {i !== 0 ? (
                            <Icon
                              type="minus-circle"
                              className="mr-2 mt-1"
                              style={{ cursor: 'pointer', color: '#ff4d50' }}
                              onClick={() => handleRemoveClick(i)}
                            />
                          ) : (
                            <span className="mr-2 mt-2">&nbsp;&nbsp;&nbsp;</span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </>
                ))}
              </Row>
              <Row type="flex" className="mt-2">
                <Col span={10}>
                  <div className="pt-1 pb-1 ml-2" style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => handleAddClick('items')}
                    >
                      {'\u002B'}&nbsp; Add New Item
                    </SpText>
                  </div>
                </Col>
                <Col span={14}>
                  <Row type="flex" justify="end" className="ml-1">
                    <Col span={12} className="pt-1 ml-5" align="left">
                      <SpText fontWeight="600" fontSize="14px">Subtotal</SpText>
                    </Col>
                    <Col span={6} className="pt-1 pr-3" align="right">
                      <SpText fontWeight="600">
                        {`${prefix} ${formatNumber((totalAmount).toFixed(2), currencyObj)}`}
                      </SpText>
                    </Col>
                    <Col span={2} className="pt-1" align="right" />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="end" className="ml-2 mb-2">
                {updatedCoupon.gid ? (
                  <Col span={14}>
                    <Row type="flex" justify="end">
                      <Col span={12} className="pt-1 ml-3" align="left">
                        <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                          <SpText>
                            {updatedCoupon.name}
                          </SpText><br />
                          <SpText fontSize="12px">
                            {getDiscountOff(updatedCoupon)}
                          </SpText>
                        </div>
                        <div>
                          <SpError>
                            {couponMsg}
                          </SpError>
                        </div>
                      </Col>
                      <Col span={6} className="pt-2 mr-3" align="right">
                        <SpText fontWeight="600">({prefix} {getAmountOff(updatedCoupon)})</SpText>
                      </Col>
                      <Col span={2} className="pt-1" align="right">
                        <Icon
                          type="minus-circle"
                          className="mr-2 mt-1"
                          style={{ cursor: 'pointer', color: '#ff4d50' }}
                          onClick={removeCoupon}
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={14}>
                    <Row type="flex" justify="end">
                      <Col span={12} className="pt-1 ml-3" align="left">
                        <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={() => setSelectCouponModal(true)}
                          >
                            Apply Coupon
                          </SpText>
                        </div>
                      </Col>
                      <Col span={8} className="pt-1 mr-3" align="right" />
                    </Row>
                  </Col>
                )}
              </Row>
              <Row type="flex" justify="end" className="ml-2">
                {
                  allSelectedTaxrates.map((item, i) => (
                    <Col span={14}>
                      <Row type="flex" justify="end">
                        <Col span={9} className="pt-1 ml-3" align="left">
                          <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                            <SpText>
                              {item.taxRates.displayName}
                            </SpText><br />
                            <SpText fontSize="12px">
                              {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                            </SpText>
                          </div>
                        </Col>
                        <Col span={3} className="pt-1" align="right">{item.taxRates.percentage} %</Col>
                        <Col span={6} className="pt-2 mr-3" align="right">
                          <SpText fontWeight="600">
                            {prefix} {getTaxAmount(item.taxRates, totalAmount, getAmountOff(updatedCoupon), selectedAccount)}
                          </SpText>
                        </Col>
                        <Col span={2} className="pt-1" align="right">
                          <Icon
                            type="minus-circle"
                            className="mr-2 mt-1"
                            style={{ cursor: 'pointer', color: '#ff4d50' }}
                            onClick={() => removeTax(i)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))
                }
              </Row>
              <Row type="flex" justify="end" className="ml-2 mb-2">
                <Col span={14}>
                  <Row type="flex" justify="end">
                    <Col span={12} className="pt-1 ml-3" align="left">
                      <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                        <SpText
                          fontWeight="600"
                          color="#279dfe"
                          onClick={() => setSelectTaxrateModal(true)}
                        >
                          {
                            allSelectedTaxrates.length !== 0 && (
                              <span>{'\u002B'}&nbsp;</span>
                            )
                          }
                          Add Tax Rates
                        </SpText>
                      </div>
                    </Col>
                    <Col span={8} className="pt-1 mr-3" align="right" />
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row type="flex" justify="end" className="mr-3 mb-1">
                <Col className="mt-1 pb-1 mr-3 pr-1">
                  <SpText className="mr-5" fontWeight="500" fontSize="20px">
                    Total Amount
                  </SpText>
                  <SpText fontWeight="600" fontSize="20px">
                    {
                      (getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))) < 0 ? (
                        `${prefix} 0.00`
                      ) : (
                        `${prefix} ${getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))}`
                      )
                    }
                  </SpText>
                </Col>
              </Row>
            </div>
          )}
        {/* ADD PRODUCT MODAL */}
        {addProductModal && (
          <AddNewProduct
            visible={addProductModal}
            submitting={submitting}
            supportedCurrency={supportedCurrency}
            close={() => setAddProductModal(false)}
            submit={addNewProduct}
            selectedAccount={selectedAccount}
          />
        )}
        {/* ADD ITEM MODAL */}

        {addItemModal && (
          <AddItem
            visible={addItemModal}
            submitting={submitting}
            itemsList={itemsList && itemsList}
            modifierItemsList={modifierItemsList && modifierItemsList}
            labelList={labelList && labelList}
            selectedAccount={selectedAccount}
            close={() => setAddItemModal(false)}
            submit={addSelectedItem}
          />
        )}
        {/* SELECT COUPON MODAL */}
        {selectCouponModal && (
          <SelectCoupon
            visible={selectCouponModal}
            submitting={submitting}
            close={() => setSelectCouponModal(false)}
            submit={updateCoupon}
            newCouponAdd={newCouponAdd}
          />
        )}

        {/* ADD COUPON MODAL */}
        {addCouponModal && (
          <AddCoupon
            visible={addCouponModal}
            submitting={submitting}
            close={() => setAddCouponModal(false)}
            submit={addCoupon}
            selectedAccount={selectedAccount}
            allCustomers={customerList}
            fetchCustomer={fetchCustomer}
          />
        )}
        {/* SELECT TAXRATE MODAL */}
        {selectTaxrateModal && (
          <SelectTaxrate
            visible={selectTaxrateModal}
            submitting={submitting}
            close={() => setSelectTaxrateModal(false)}
            submit={updateTaxrate}
            newTaxAdd={newTaxAdd}
          />
        )}

        {/* ADD TAX MODAL */}
        {addTaxModal && (
          <AddTaxrate
            visible={addTaxModal}
            submitting={submitting}
            close={() => setAddTaxModal(false)}
            submit={addTaxrate}
            selectedAccount={selectedAccount}
            allTaxes={allTaxes}
            fetchTaxDetails={fetchTaxDetails}
          />
        )}
        <div className="mb-3">
          <label htmlFor=""><SpH5>Billing Frequency</SpH5></label>
          <div className="d-flex align-items-center" style={{ width: '50%' }}>
            <span>Every</span>
            <div style={{ width: '18%', minWidth: '35px' }}>
              <Input
                className="ml-1"
                value={period}
                maxlength="3"
                disabled={disablePlanDetails}
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setPeriod(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setPeriod();
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div>
              <Select
                className="ml-3"
                style={{ width: '125%' }}
                value={frequency}
                disabled={disablePlanDetails}
                onChange={(e) => setFrequency(e)}
              >
                <Option key={1} value="DAY">Day(s)</Option>
                <Option key={2} value="WEEK">Week(s)</Option>
                <Option key={3} value="MONTH">Month(s)</Option>
                <Option key={4} value="YEAR">Year(s)</Option>
              </Select>
            </div>
          </div>
          <SpError>
            {simpleValidator.current.message('period', period, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <div className="d-flex">
            <div style={{ width: '50%' }}>
              <label htmlFor=""><SpH5>Subscription Start Date (Optional)</SpH5></label>
              <div>
                <DatePicker
                  value={dop ? moment(dop) : ''}
                  disabled={dateDisable}
                  format={dateFormat}
                  allowClear={false}
                  onChange={onDateChange}
                  disabledDate={current => current && current < moment(disableDate)}
                />
              </div>
            </div>
            <div className="ml-4 mt-3">
              <label htmlFor=""> </label>
              <div>
                <Checkbox
                  checked={startDate}
                  onChange={onCheckChange}
                /> Start Today
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3" style={{ width: '50%' }}>
          <label htmlFor=""><SpH5>Total Count (Optional)</SpH5></label>
          <Input
            placeholder="No. of billing cycles to be charged"
            value={totalCount}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              const { value } = e.currentTarget;
              if (regex.test(value) || value === '') {
                setCount(e.currentTarget.value);
              } else if (!regex.test(value)) {
                setCount(null);
              }
            }}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
          />
          <SpError>
            {simpleValidator.current.message('count', totalCount, 'numeric')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Redirect Url (Optional)</SpH5></label>
          <Input
            placeholder="https://www.example.com"
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('redirectUrl', redirectUrl, 'url')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  productsList: state.invoiceDetails.productsList,
  addedNewProduct: state.invoiceDetails.newProduct,
  newCoupon: state.invoiceDetails.newCoupon,
  taxList: state.invoiceDetails.taxList,
  newTaxrate: state.invoiceDetails.newTaxrate,
  customerResult: state.invoiceDetails.customersList,
  internalAccount: state.account.internalAccount,
  modifierItemsList: state.inventoryItems.modifierItemsList.content,
  itemsList: state.inventoryItems.itemsList.content,
  labelList: state.inventoryPrinterLabel.labelList.content,
  inventoryItems: state.inventoryItems.content,
  addedNewItem: state.inventoryItems.addedItem.entity,


});

const mapDispatchToProps = (dispatch) => ({
  getProductsList: param => dispatch(invoiceDetailsAction.getProductsList({
    payload: param,
  })),
  addNewProcut: param => dispatch(invoiceDetailsAction.addNewProcut({
    payload: param,
  })),
  getCustomersList: param => dispatch(invoiceDetailsAction.getCustomersList({
    payload: param,
  })),
  addNewCoupon: param => dispatch(invoiceDetailsAction.addNewCoupon({
    payload: param,
  })),
  getTaxes: param => dispatch(invoiceDetailsAction.getTaxes({
    payload: param,
  })),
  addNewTaxRate: param => dispatch(invoiceDetailsAction.addNewTaxRate({
    payload: param,
  })),
  fetchInventoryItems: param => dispatch(inventoryItemsActions.fetchInventoryItems({
    payload: param,
  })),
  fetchItemsList: param => dispatch(inventoryItemsActions.fetchItemsList({
    payload: param,
  })),
  fetchPrinterLabelList: (payload) => dispatch(inventoryPrinterLabelActions.fetchPrinterLabelList({
    payload,
  })),
  fetchModifierItemsList: param => dispatch(inventoryItemsActions.fetchModifierItemsList({
    payload: param,
  })),
  addInventoryItems: param => dispatch(inventoryItemsActions.addInventoryItems({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddSubscriptionButton);
