// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Icon,
  Tooltip,
  Badge,
  Row,
  Col,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Filters from 'components/Filters';
import styled from 'styled-components';

import Loading from 'components/Loading';

import getBackground from 'utils/getBackground';
import getTerminalStatus from 'utils/getTerminalStatus';
import getFilterOperators from 'utils/getFilterOperators';

import {
  terminalActions,
  notificationActions,
  filterParamsActions,
} from 'store/actions';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import RBAC from 'components/rbac';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import {
  ROLES,
  PAGES,
  MESSAGES,
  FILTERS_TYPES,
} from 'appconstants';
import { NOTIFICATION, TERMINAL, FILTERS_SPECS } from 'store/actionTypes';
import Download from 'components/NavIcons/downloadProof';
import NoProof from 'components/NavIcons/noProof';
import EditTerminal from './component/EditTerminal';

const TERMINALS_FILTERS_TYPES = FILTERS_TYPES.TERMINALS;

// $FlowFixMe
const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: '#434343',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  fetchTerminals: Function,
  getLocationsList: Function,
  updateTerminal: Function,
  terminals: Array<Object>,
  locationList: Array<Object>,
  loading: boolean,
  submitting: boolean,
  test: boolean,
  totalElements: number,
  selectedAccount: Object,
  setNotification: Function,
  history: {
    push: Function,
  },
  setFilterData: Function,
  preserveFilters: Object,
  setLocationData: Function,
  searchLocation: Object,
};

const Terminal = (props: Props) => {
  const {
    loading,
    submitting,
    fetchTerminals,
    updateTerminal,
    terminals,
    totalElements,
    test,
    getLocationsList,
    locationList,
    selectedAccount,
    setNotification,
    history,
    setFilterData,
    preserveFilters,
    setLocationData,
    searchLocation,
  } = props;

  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const [editTerminalModal, setEditTerminalModal] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState({});
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(TERMINALS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterTerminals, setFilterTerminals] = useState((preserveFilters && preserveFilters.filterTerminals)
    ? (preserveFilters && preserveFilters.filterTerminals)
    : [{
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterTerminals: [{
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });
  const searchParam = searchLocation.search || '';

  const exportData = terminals ? terminals.map((values) => ({
    deviceName: values.deviceName || '',
    gid: values.gid,
    terminalMid: values.terminalMid || '',
    terminalTid: values.terminalTid || '',
    city: values.terminalLocation ? values.terminalLocation.address.city : '',
    locationName: values.terminalLocation ? values.terminalLocation.name : '',
    status: getTerminalStatus(values.status),
  })) : '';

  const exportHeaders = [
    { label: 'NAME', key: 'deviceName' },
    { label: 'TERMINAL ID', key: 'gid' },
    { label: 'MID', key: 'terminalMid' },
    { label: 'TID', key: 'terminalTid' },
    { label: 'CITY', key: 'city' },
    { label: 'LOCATION', key: 'locationName' },
    { label: 'STATUS', key: 'status' },
  ];

  const debouncedFetchTerminals = debounce(fetchTerminals, 2000, { leading: true });
  const debouncedFetchLocations = debounce(getLocationsList, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchTerminals({ currentPage, sortParams, filterParams });
    debouncedFetchLocations();
  };

  useEffect(() => {
    const param = location.search ? location.search : searchParam;
    getData(param);
  }, [test, selectedAccount, location]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (!submitting) {
      setEditTerminalModal(false);
    }
  }, [submitting]);

  const getLocations = (searchText) => {
    getLocationsList(searchText);
  };

  useEffect(() => {
    const param = location.search ? location.search : searchParam;
    const parsed = queryString.parse(param);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [terminals]);

  const selectedRecord = (selecteTerminal: Object) => {
    const { gid } = selecteTerminal;
    setLocationData({
      search: location.search,
    });
    history.push({
      pathname: `${PAGES.TERMINALS}/${gid}`,
      state: location.search,
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const editSelectedTerminal = (terminal: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      terminalName,
      locationId,
      isActive,
      gid,
      mid,
      tid,
    } = terminal;

    const params = {
      deviceName: terminalName,
      terminalMid: mid,
      terminalTid: tid,
      isActive,
      meta: {
      },
      terminalLocationGid: locationId,
    };

    updateTerminal({
      currentPage,
      sortParams,
      gid,
      params,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.TERMINALS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.TERMINALS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const showEditTerminalModal = (value: Object) => {
    setEditTerminalModal(true);
    setSelectedTerminal(value);
  };

  const downloadProof = async (record) => {
    const proofGid = record.gid;
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.GET_TERMINALS}/${proofGid}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.TERMINAL.DOWNLOAD_ERROR,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterTerminals];
    if (filterTerminals.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = TERMINALS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTerminals(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterTerminals];
    if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = TERMINALS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTerminals(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (!e.currentTarget) {
      selectedValue = e;
    }
    if (e.currentTarget && e.currentTarget.value) {
      selectedValue = e.currentTarget.value;
    }
    const list = [...filterTerminals];
    list[index].filterValue = selectedValue;
    setFilterTerminals(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTerminals];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      handleValue(list[index].filterValue, index);
    }
    setFilterTerminals(list);
  };

  const handleAddClick = () => {
    const list = [...filterTerminals];
    setFilterTerminals([...list, {
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterTerminals([{
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(TERMINALS_FILTERS_TYPES);
    setCount(null);
    history.push(PAGES.TERMINALS);
  };

  const applyFilters = () => {
    const locationsFilters = filterTerminals.filter((item) => item.checked);
    for (let i = 0; i < locationsFilters.length; i += 1) {
      if (!locationsFilters[i].filterName || !locationsFilters[i].filterOperator || !locationsFilters[i].filterValue) {
        return;
      }
    }
    if (locationsFilters[0].filterValue) {
      setCount(locationsFilters && locationsFilters.length);
      for (let i = 0; i < locationsFilters.length; i += 1) {
        if (locationsFilters[i].checked) {
          if (locationsFilters[i].filterName === 'createdAt') {
            locationsFilters[i] = locationsFilters[i].filterValue;
          } else {
            locationsFilters[i] = `${locationsFilters[i].filterName}.${locationsFilters[i].filterOperator}=${locationsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(locationsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterTerminals,
        });
        history.push({
          pathname: PAGES.TERMINALS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterTerminals,
        });
        history.push({
          pathname: PAGES.TERMINALS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'deviceName',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (deviceName) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {deviceName}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'TERMINAL ID',
      dataIndex: 'gid',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (gid) => {
        const trunc = gid.slice(0, 16);
        return (
          <Tooltip placement="top" title={gid}>
            <span>{trunc}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'MID',
      dataIndex: 'terminalMid',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (terminalMid) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {terminalMid || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'TID',
      dataIndex: 'terminalTid',
      width: '10%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (terminalTid) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {terminalTid || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'CITY',
      dataIndex: 'terminalLocation.address.city',
      width: '12%',
      align: 'left',
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {record.terminalLocation ? record.terminalLocation.address.city : <>&#8211;</>}
          </span>
        </Tooltip>
      ),
      sorter: false,
    },
    {
      title: 'LOCATION',
      dataIndex: 'terminalLocation.name',
      width: '12%',
      align: 'left',
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {record.terminalLocation ? record.terminalLocation.name : <>&#8211;</>}
          </span>
        </Tooltip>
      ),
      sorter: false,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '12%',
      align: 'left',
      sorter: false,
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            <StatusTag style={{ backgroundColor: getBackground(status) }}>{getTerminalStatus(status)}</StatusTag>
          </span>
        </Tooltip>
      ),
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '20%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showEditTerminalModal(record); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Download">
          {
            record.proofUrl ? (
              <>
                <Icon
                  component={Download}
                  className="ml-3"
                  onClick={(e) => { e.stopPropagation(); downloadProof(record); }}
                  style={{ fontSize: '20px', cursor: 'pointer' }}
                />
              </>
            ) : (
              <>
                <Icon
                  component={NoProof}
                  className="ml-3"
                  disabled
                  onClick={(e) => { e.stopPropagation(); }}
                  style={{ fontSize: '20px' }}
                />
              </>
            )
          }
        </Tooltip>
      </span>
    ),
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <span>
              {
                exportData !== null
                  ? (
                    <Tooltip placement="left" title="Click here to export Terminals">
                      <CSVLink
                        target="_blank"
                        className="mr-3"
                        filename="Terminals Reports.csv"
                        data={exportData}
                        headers={exportHeaders}
                        onMouseEnter={() => setFillColor(true)}
                        onMouseLeave={() => setFillColor(false)}
                      >
                        <ExportIcon
                          fill={fillColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </CSVLink>
                    </Tooltip>
                  ) : null
              }
            </span>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Terminals">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && (
                <FilterWrapper
                  style={{ marginLeft: '-264px' }}
                >
                  <Filters
                    filtersGrid={filterTerminals}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterTerminals.length < TERMINALS_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
            </span>
          </Col>
        </div>
      </Row>
      {/* EDIT TERMINAL MODAL */}
      {editTerminalModal && (
        <EditTerminal
          visible={editTerminalModal}
          submitting={submitting}
          selectedTerminal={selectedTerminal}
          locationList={locationList}
          getLocations={getLocations}
          close={() => setEditTerminalModal(false)}
          submit={editSelectedTerminal}
        />
      )}

      <RBAC
        fallback={(
          <Table
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={terminals}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
      >
        <Table
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          rowClassName={() => 'ant-table-clickable-row'}
          dataSource={terminals}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  terminals: state.terminals.content,
  locationList: state.terminals.locationList,
  loading: state.loading.loading,
  submitting: state.loading.submitting,
  test: state.account.test,
  totalElements: state.terminals.totalElements,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  preserveFilters: state.filterParams.preserveFilters,
  searchLocation: state.terminals.searchLocation,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTerminals: param => dispatch(terminalActions.fetchTerminals({
    payload: param,
  })),
  updateTerminal: param => dispatch(terminalActions.updateTerminal({
    payload: param,
  })),
  getLocationsList: param => dispatch(terminalActions.getLocationsList({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
  setLocationData: (value) => dispatch(terminalActions.setLocationData({
    type: TERMINAL.SEARCH_LOCATION,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Terminal);
