import { USONBOARDING } from '../actionTypes';

export const fetchOnboardingLinks = (action) => ({
  type: USONBOARDING.FETCH,
  payload: action.payload,
});

export const addOnboardingLink = (action) => ({
  type: USONBOARDING.ADD,
  payload: action.payload,
});
