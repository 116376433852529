import { INVOICE_STATUSES } from 'appconstants';

export default value => {
  if (value === 'DRAFT') {
    return INVOICE_STATUSES.DRAFT;
  } if (value === 'ACTIVE') {
    return 'Active';
  } if (value === 'ACTIVE_SEND') {
    return 'Active';
  } if (value === 'ACTIVE_OPEN') {
    return 'Active';
  } if (value === 'ACTIVE_RENDERING_FAILURE') {
    return 'Active';
  } if (value === 'ACTIVE_REJECT') {
    return 'Active';
  } if (value === 'ACTIVE_DELIVERY') {
    return 'Active';
  } if (value === 'ACTIVE_BOUNCE') {
    return 'Active';
  } if (value === 'ACTIVE_COMPLAINT') {
    return 'Active';
  } if (value === 'ACTIVE_DELIVERY_DELAY') {
    return 'Active';
  } if (value === 'ACTIVE_SUBSCRIPTION') {
    return 'Active';
  } if (value === 'ACTIVE_CLICK') {
    return 'Active';
  } if (value === 'PAST_DUE') {
    return 'Past Due';
  } if (value === 'PAST_DUE_SEND') {
    return 'Past Due';
  } if (value === 'PAST_DUE_OPEN') {
    return 'Past Due';
  } if (value === 'PAST_DUE_RENDERING_FAILURE') {
    return 'Past Due';
  } if (value === 'PAST_DUE_REJECT') {
    return 'Past Due';
  } if (value === 'PAST_DUE_DELIVERY') {
    return 'Past Due';
  } if (value === 'PAST_DUE_BOUNCE') {
    return 'Past Due';
  } if (value === 'PAST_DUE_COMPLAINT') {
    return 'Past Due';
  } if (value === 'PAST_DUE_DELIVERY_DELAY') {
    return 'Past Due';
  } if (value === 'PAST_DUE_SUBSCRIPTION') {
    return 'Past Due';
  } if (value === 'PAST_DUE_CLICK') {
    return 'Past Due';
  } if (value === 'CANCELLED') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'CANCELLED_SEND') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'CANCELLED_OPEN') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'CANCELLED_RENDERING_FAILURE') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'CANCELLED_REJECT') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'CANCELLED_DELIVERY') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'CANCELLED_BOUNCE') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'CANCELLED_COMPLAINT') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'CANCELLED_DELIVERY_DELAY') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'CANCELLED_SUBSCRIPTION') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'CANCELLED_CLICK') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'INCOMPLETE') {
    return 'Incomplete';
  } if (value === 'INCOMPLETE_SEND') {
    return 'Incomplete';
  } if (value === 'INCOMPLETE_OPEN') {
    return 'Incomplete';
  } if (value === 'INCOMPLETE_RENDERING_FAILURE') {
    return 'Incomplete';
  } if (value === 'INCOMPLETE_REJECT') {
    return 'Incomplete';
  } if (value === 'INCOMPLETE_DELIVERY') {
    return 'Incomplete';
  } if (value === 'INCOMPLETE_BOUNCE') {
    return 'Incomplete';
  } if (value === 'INCOMPLETE_COMPLAINT') {
    return 'Incomplete';
  } if (value === 'INCOMPLETE_DELIVERY_DELAY') {
    return 'Incomplete';
  } if (value === 'INCOMPLETE_SUBSCRIPTION') {
    return 'Incomplete';
  } if (value === 'INCOMPLETE_CLICK') {
    return 'Incomplete';
  } if (value === 'FAILED') {
    return 'Failed';
  } if (value === 'FAILED_SEND') {
    return 'Failed';
  } if (value === 'FAILED_OPEN') {
    return 'Failed';
  } if (value === 'FAILED_RENDERING_FAILURE') {
    return 'Failed';
  } if (value === 'FAILED_REJECT') {
    return 'Failed';
  } if (value === 'FAILED_DELIVERY') {
    return 'Failed';
  } if (value === 'FAILED_BOUNCE') {
    return 'Failed';
  } if (value === 'FAILED_COMPLAINT') {
    return 'Failed';
  } if (value === 'FAILED_DELIVERY_DELAY') {
    return 'Failed';
  } if (value === 'FAILED_SUBSCRIPTION') {
    return 'Failed';
  } if (value === 'FAILED_CLICK') {
    return 'Failed';
  } if (value === 'PAID') {
    return INVOICE_STATUSES.PAID;
  } if (value === 'PARTIALLY_REFUNDED') {
    return 'Partially refunded';
  } if (value === 'REFUNDED') {
    return 'Refunded';
  } return null;
};
