/* eslint-disable */
import React from 'react';

const ButtonCollapse = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#D1D1D1" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2427 13.7626L12.3536 17.6517L11.6465 16.9446L16.2427 12.3484L20.8389 16.9446L20.1318 17.6517L16.2427 13.7626Z" fill="black" />
    </svg>
);

export default ButtonCollapse;