import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Checkbox,
  Button,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpButton,
  SpError,
} from 'components/DesignKit';


type Props = {
  internalAccount: Object,
  paymentType: Object,
  isRecurringPaymentValidate: boolean,
  submit: Function,
  openModal: Function,
};

const SupportedRecurringPayments = (props: Props) => {
  const {
    internalAccount,
    paymentType,
    isRecurringPaymentValidate,
    submit,
    openModal,
  } = props;

  const [recuringPaymentData, setRecuringPaymentData] = useState((internalAccount && internalAccount.supportedRecurringPaymentTypes));
  const recuringPayments = (paymentType) && paymentType.filter((data) => data.recurringPaymentType === true);
  const [disabled, setDisabled] = useState(true);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());

  const updateRecurringPaymentType = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      recuringPaymentData,
    });
  };

  const onRecurringChange = (checkedValues) => {
    setRecuringPaymentData(checkedValues);
    setDisabled(false);
    openModal(true);
  };

  function renderPaymentRows(data) {
    const content = [];
    data && data.forEach((result, i) => {
      if ((i + 1) % 3 === 0) {
        content.push(
          <Row className="mb-2">
            <Col span={6}>
              <Checkbox value={result.name}>
                {result.name}
              </Checkbox>
            </Col>
          </Row>,
        );
      } else {
        content.push(
          <Col span={6}>
            <Checkbox value={result.name}>
              {result.name}
            </Checkbox>
          </Col>,
        );
      }
    });
    return content;
  }

  useEffect(() => {
    setRecuringPaymentData(internalAccount && internalAccount.supportedRecurringPaymentTypes);
    setDisabled(true);
  }, [openModal]);

  const reset = () => {
    setRecuringPaymentData(internalAccount && internalAccount.supportedRecurringPaymentTypes);
    setDisabled(true);
    openModal(false);
  };

  return (
    <>
      <div>
        <Row className="mb-2">
          <Checkbox.Group style={{ width: '100%' }} value={recuringPaymentData} onChange={onRecurringChange}>
            <Row>
              {renderPaymentRows(recuringPayments)}
            </Row>
          </Checkbox.Group>
          {
            (isRecurringPaymentValidate) && (
              <SpError>
                {simpleValidator.current.message('recurring payment type', recuringPaymentData, 'required')}
              </SpError>
            )
          }
        </Row>
        <Row className="mt-4" style={{ marginLeft: '-15px' }}>
          <Col span={2}>
            <Button
              type="link"
              onClick={reset}
              disabled={disabled}
            >
              Reset
            </Button>
          </Col>
          <Col span={2}>
            <SpButton
              type="primary"
              shape="round"
              disabled={disabled}
              onClick={updateRecurringPaymentType}
            >
              Update
            </SpButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(SupportedRecurringPayments);
