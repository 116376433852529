import { DOCUMENTS } from '../actionTypes';

export const fetchDocuments = action => ({
  type: DOCUMENTS.FETCH,
  payload: action.payload,
});

export const addDocument = (action) => ({
  type: DOCUMENTS.ADD,
  payload: action.payload,
});
