import { CUSTOM_DASHBOARD } from '../actionTypes';

export const fetchCustomDashboard = (action) => ({
  type: CUSTOM_DASHBOARD.FETCH,
  payload: action.payload,
});

export const addCustomDashboard = (action) => ({
  type: CUSTOM_DASHBOARD.ADD,
  payload: action.payload,
});

export const updateCustomDashboard = (action) => ({
  type: CUSTOM_DASHBOARD.UPDATE,
  payload: action.payload,
});
