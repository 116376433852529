import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  ADMIN_CATALOG,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchAdminCatalogs = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CATALOAG}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CATALOAG}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CATALOAG}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_CATALOAG}${query}${sorting}`);
};

const postCatalog = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.GET_INTERNAL_CATALOAG, params);

const patchCatalog = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INTERNAL_CATALOAG}/${gid}`, params);

const deleteCatalog = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INTERNAL_CATALOAG}/${gid}`);

export function* getAdminCatalogs(action) {
  try {
    const { data: { entity } } = yield call(fetchAdminCatalogs, action.payload);
    yield put({ type: ADMIN_CATALOG.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: ADMIN_CATALOG.FAILED, payload: e.response.data });
  }
}

export function* addCatalog(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postCatalog, action.payload);
    const { data: { entity } } = yield call(fetchAdminCatalogs, action.payload);
    yield put({ type: ADMIN_CATALOG.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Item added successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateCatalog(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCatalog, action.payload);
    const { data: { entity } } = yield call(fetchAdminCatalogs, action.payload);
    yield put({ type: ADMIN_CATALOG.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Item updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteCatalogs(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteCatalog, action.payload);
    const { data: { entity } } = yield call(fetchAdminCatalogs, action.payload);
    yield put({ type: ADMIN_CATALOG.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOMERS.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
