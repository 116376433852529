import { TRANSFER_STATUSES } from 'appconstants';

export default value => {
  if (value === 'SUCCEEDED') {
    return TRANSFER_STATUSES.SUCCEEDED;
  } if (value === 'CANCELLED') {
    return TRANSFER_STATUSES.CANCELLED;
  } if (value === 'FAILED') {
    return TRANSFER_STATUSES.FAILED;
  } if (value === 'REQUIRE_FUNDING_SOURCE') {
    return TRANSFER_STATUSES.REQUIRE_FUNDING_SOURCE;
  } if (value === 'REQUIRES_CONFIRMATION') {
    return TRANSFER_STATUSES.REQUIRE_CONFIRMATION;
  } if (value === 'DECLINED') {
    return TRANSFER_STATUSES.DECLINED;
  } if (value === 'TRANSFER_PENDING') {
    return TRANSFER_STATUSES.TRANSFER_PENDING;
  } if (value === 'PROCESSING') {
    return TRANSFER_STATUSES.PROCESSING;
  } if (value === 'TRANSFER_SCHEDULED') {
    return TRANSFER_STATUSES.TRANSFER_SCHEDULED;
  } return null;
};
