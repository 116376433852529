/* eslint-disable */
import React from 'react';

const integrationIcon = ({ fill = "silver" }) => (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="70" cy="70" r="70" fill="#F5F5F5" />
        <path d="M31.3 51.96C31.3 46.7354 35.5354 42.5 40.76 42.5H99.2401C104.465 42.5 108.7 46.7354 108.7 51.96V90.66C108.7 95.8846 104.465 100.12 99.2401 100.12H40.7601C35.5354 100.12 31.3 95.8846 31.3 90.66V51.96Z" fill="#DADADA" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M99.2401 49.38H40.76C39.3352 49.38 38.18 50.5351 38.18 51.96V90.66C38.18 92.0849 39.3352 93.24 40.7601 93.24H99.2401C100.665 93.24 101.82 92.0849 101.82 90.66V51.96C101.82 50.5351 100.665 49.38 99.2401 49.38ZM40.76 42.5C35.5354 42.5 31.3 46.7354 31.3 51.96V90.66C31.3 95.8846 35.5354 100.12 40.7601 100.12H99.2401C104.465 100.12 108.7 95.8846 108.7 90.66V51.96C108.7 46.7354 104.465 42.5 99.2401 42.5H40.76Z" fill="#C5C5C5" />
        <rect x="27" y="100.12" width="86" height="6.88" rx="3.44" fill="#C5C5C5" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M65.8563 21H72.4236L72.8759 25.6642C74.6254 26.0564 76.2597 26.7531 77.7204 27.6959L81.3452 24.7602L86.376 29.791L83.1257 33.8042C83.6373 34.8616 84.0288 35.988 84.2824 37.1656L88.92 37.6527V44.7673L83.7846 45.3067C83.4037 46.3992 82.9004 47.4344 82.2911 48.3957L85.2703 52.0147L80.6265 56.6585L76.8323 53.535C75.6077 54.211 74.2788 54.7213 72.8759 55.0358L72.4236 59.7H76.88L69.14 71.31L60.97 59.7H65.8563L65.3821 54.8097C64.5654 54.5735 63.7773 54.2701 63.0242 53.9058L59.0109 57.156L53.9802 52.1253L56.9158 48.5004C56.2764 47.5097 55.7501 46.439 55.3553 45.3067L50.22 44.7673V37.6527L54.8575 37.1656C55.1694 35.7179 55.6895 34.3476 56.3849 33.0877L53.2615 29.2934L57.9053 24.6497L61.5243 27.6288C62.7085 26.8783 64.0047 26.2886 65.3821 25.8903L65.8563 21Z" fill="#E4E4E4" />
        <circle cx="69.57" cy="40.35" r="5.59" fill="#C5C5C5" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M72.8759 25.6642L72.4236 21H65.8563L65.3821 25.8903C64.0047 26.2886 62.7085 26.8783 61.5243 27.6288L57.9053 24.6497L53.2615 29.2934L56.3849 33.0877C55.6895 34.3476 55.1694 35.7179 54.8575 37.1656L50.22 37.6527V44.7673L55.3553 45.3067C55.7501 46.439 56.2764 47.5097 56.9158 48.5004L53.9802 52.1253L59.0109 57.156L63.0242 53.9058C63.7773 54.2701 64.5654 54.5735 65.3821 54.8097L65.8563 59.7H60.97L69.14 71.31L76.88 59.7H72.4236L72.8759 55.0358C74.2788 54.7213 75.6077 54.211 76.8323 53.535L80.6265 56.6585L85.2703 52.0147L82.2911 48.3957C82.9004 47.4344 83.4037 46.3992 83.7846 45.3067L88.92 44.7673V37.6527L84.2824 37.1656C84.0288 35.988 83.6373 34.8616 83.1257 33.8042L86.376 29.791L81.3452 24.7602L77.7204 27.6959C76.2597 26.7531 74.6254 26.0564 72.8759 25.6642ZM81.1636 28.2273L77.8917 30.8771L76.3213 29.8636C75.111 29.0824 73.7584 28.5061 72.3116 28.1818L70.4883 27.7731L70.0817 23.58H68.1983L67.781 27.8824L66.0987 28.3688C64.9596 28.6982 63.8867 29.1861 62.9054 29.808L61.3272 30.8083L58.0736 28.13L56.7418 29.4618L59.4904 32.8006L58.6437 34.3344C58.0678 35.3777 57.6376 36.5114 57.3797 37.7089L56.986 39.5361L52.8 39.9759V42.4441L57.2526 42.9119L57.7915 44.4573C58.118 45.3939 58.5536 46.2803 59.0835 47.1014L60.0971 48.6717L57.4473 51.9436L59.1925 53.6889L62.6733 50.87L64.1477 51.5833C64.7712 51.8849 65.4232 52.1359 66.0987 52.3312L67.781 52.8176L68.6986 62.28H65.9403L69.0824 66.7451L72.0592 62.28H69.5813L70.4883 52.9269L72.3116 52.5182C73.472 52.2581 74.5714 51.836 75.5855 51.2763L77.1194 50.4296L80.4582 53.1781L81.79 51.8463L79.1116 48.5928L80.1119 47.0145C80.6168 46.2179 81.0334 45.361 81.3485 44.4573L81.8873 42.9119L86.34 42.4441V39.9759L82.1539 39.5361L81.7603 37.7089C81.5504 36.7347 81.2266 35.803 80.8032 34.9278L80.09 33.4533L82.9089 29.9726L81.1636 28.2273Z" fill="#C5C5C5" />
    </svg>
);

export default integrationIcon;
