import { GETSHOPDETAILS } from '../actionTypes';

export const fetchShopDetails = (action) => ({
  type: GETSHOPDETAILS.FETCH,
  payload: action.payload,
});

export const fetchShopOrderDetails = (action) => ({
  type: GETSHOPDETAILS.ORDER_FETCH,
  payload: action.payload,
});

export const updateLineItems = (action) => ({
  type: GETSHOPDETAILS.UPDATE,
  payload: action.payload,
});

export const sendFcmToken = (action) => ({
  type: GETSHOPDETAILS.SENDTOKEN,
  payload: action.payload,
});

export const fetchAllShopDetails = (action) => ({
  type: GETSHOPDETAILS.SHOP_FETCH,
  payload: action.payload,
});

export const fetchFullfillDetails = (action) => ({
  type: GETSHOPDETAILS.FULLFILL_FETCH,
  payload: action.payload,
});

export const updateFullFill = (action) => ({
  type: GETSHOPDETAILS.UPDATE_FULLFILL,
  payload: action.payload,
});
