const global = {
  email: 'Email required',
  validEmail: 'Email must be valid',
  password: 'Password required',
  firstName: 'First name required',
  lastName: 'Last name required',
  validFirstName: 'First name may only contain letters & spaces',
  validLastName: 'Last name may only contain letters & spaces',
  phone: 'Phone number required',
  validPhone: 'Phone number must be valid',
  newPassword: 'New password required',
  validPassword: 'Password should have minimum 8 characters including numbers, uppercase and lowercase letters',
  confirmPassword: 'Confirm password required',
  validConfirmPassword: 'Must match password typed above',
  accountName: 'Account name required',
  validAccountName: 'Account name should have minimum 5 characters',
  country: 'Country required',
  codeMsg: 'If your email id is registered with us, please check your email for verification code',
  verificationCode: 'Verification code required',
  validMsg: 'Please enter the verification code sent in your email along with the new password',
  couponInvalid: 'Invalid coupon',
  couponMaxRedemptions: 'Coupon exceeded maximum redemptions.',
  couponExpired: 'Coupon expired.',
  OTP: 'OTP required',
  widgetDefaulInterval: 'The default interval is required',
  widgetRecommandedInterval: 'The recommended interval is required',
  widgetInterval: 'The default & recommended intervals are required',
  validName: 'The name should have minimum 5 characters.',
  bankName: 'Bank name must be valid',
  bankAccountName: 'Account holder name must be valid',
  integratorsName: 'The name must be alpha',
  postalCode: 'The postal code must be valid',
  timer: 'The minimum session timeout is 3 mins',
  validContactName: 'The name must be valid',
  captcha: 'Captcha required',
};

export const validators = {
  login: {
    email: global.email,
    validEmail: global.validEmail,
    password: global.password,
  },
  register: {
    firstName: global.firstName,
    validFirstName: global.validFirstName,
    lastName: global.lastName,
    validLastName: global.validLastName,
    email: global.email,
    validEmail: global.validEmail,
    phone: global.phone,
    validPhone: global.validPhone,
  },
  setupPassword: {
    newPassword: global.newPassword,
    validPassword: global.validPassword,
    confirmPassword: global.confirmPassword,
    validConfirmPassword: global.validConfirmPassword,
  },
  account: {
    accountName: global.accountName,
    country: global.country,
    validAccountName: global.validAccountName,
  },
  forgotPassword: {
    email: global.email,
    validEmail: global.validEmail,
    codeMsg: global.codeMsg,
  },
  resetPassword: {
    newPassword: global.newPassword,
    validPassword: global.validPassword,
    confirmPassword: global.confirmPassword,
    validConfirmPassword: global.validConfirmPassword,
    verificationCode: global.verificationCode,
    validMsg: global.validMsg,
  },
  coupon: {
    validCoupon: global.couponInvalid,
    redemptionCoupon: global.couponMaxRedemptions,
    expiredCoupon: global.couponExpired,
  },
  newRegister: {
    otpRequired: global.OTP,
  },
  paymentWidget: {
    default: global.widgetDefaulInterval,
    recommended: global.widgetRecommandedInterval,
    intervals: global.widgetInterval,
  },
  paymentLink: {
    validName: global.validName,
    maxValidName: 'The maximum allowed length for the name is 127 characters.',
  },
  bussiness: {
    validBankName: global.bankName,
    validContactName: global.validContactName,
    validBankAccountName: global.bankAccountName,
  },
  integrators: {
    validName: global.integratorsName,
  },
  address: {
    pincode: global.postalCode,
  },
  checkout: {
    timer: global.timer,
  },
};

export const validationRegex = {
  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
  accountName: /^[^<>'"\\\\`*]{5,}$/g,
  amountRegex: /^\d*\.?\d*$/,
  nameRegex: /^[a-zA-Z ]*$/,
  // eslint-disable-next-line
  email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // eslint-disable-next-line
  new_email: /^[^%$\/#~]+@[^@]+$/,
};

export const countryCodeValidation = (e) => {
  const keyCode = (e.which) ? e.which : e.keyCode;
  if (keyCode !== 43 && keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
    e.preventDefault();
  }
};

export const isVariableNullOrUndefined = (param) => (typeof (param) !== 'undefined' && param !== null);
