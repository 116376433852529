import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  INVENTORY_CATEGORIES_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryCategorieDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}/${gid}`);

const fetchItemByCategory = async gid => axiosAuthInstance.get(`/inventory/category/${gid}/items`);

const fetchAllItemByCategory = async () => axiosAuthInstance.get('/inventory/category/item-count');

// eslint-disable-next-line max-len
const getItemForSelectedCategory = async ({ gid, categoryGid }) => axiosAuthInstance.get(`/inventory/item/selected-shop/${gid}${categoryGid !== null ? `?inventoryCategoryGid=${categoryGid}` : ''}`);

const patchItemCategory = async ({ gid, params }) => axiosAuthInstance.patch(`/inventory/category/${gid}/items`, params);

const fetchInventoryOperatingHours = async () => axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS_OPERATING_HOURS}?size=100000&sortBy=createdAt`);

const fetchAllShop = async gid => axiosAuthInstance.get(`/shop/category/${gid}`);


export function* getAllShop(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: entity } = yield call(fetchAllShop, action.payload);
    yield put({ type: INVENTORY_CATEGORIES_DETAILS.FETCH_ALL_SHOP_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: INVENTORY_CATEGORIES_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}


export function* getInventoryCategorieDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryCategorieDetails, action.payload);
      yield put({ type: INVENTORY_CATEGORIES_DETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchInventoryCategorieDetails, action);
      yield put({ type: INVENTORY_CATEGORIES_DETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: INVENTORY_CATEGORIES_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getAllItemsByCategory(action) {
  try {
    // yield put({ type: LOADING.SET });
    const { data: content } = yield call(fetchAllItemByCategory, action.payload);
    yield put({ type: INVENTORY_CATEGORIES_DETAILS.SUCCESS, payload: content });
  } catch (e) {
    yield put({ type: INVENTORY_CATEGORIES_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}


export function* getInventoryOperatingHours(action) {
  try {
    const { data: { entity } } = yield call(fetchInventoryOperatingHours, action.payload);
    yield put({ type: INVENTORY_CATEGORIES_DETAILS.FETCH_OPERATING_HOURS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_CATEGORIES_DETAILS.FAILED, payload: e.response.data });
  }
}

export function* getInventoryItemByCategory(action) {
  try {
    // yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchItemByCategory, action.payload);
      yield put({ type: INVENTORY_CATEGORIES_DETAILS.CATEGORY_ITEM_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchItemByCategory, action);
      yield put({ type: INVENTORY_CATEGORIES_DETAILS.CATEGORY_ITEM_SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: INVENTORY_CATEGORIES_DETAILS.CATEGORY_ITEM_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addItemCategory(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(patchItemCategory, action.payload);
    const { data: { entity } } = yield call(fetchItemByCategory, action.payload && action.payload.gid);
    yield put({ type: INVENTORY_CATEGORIES_DETAILS.CATEGORY_ITEM_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Items Updated successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getItemsBySelectedCategory(action) {
  try {
    const { data: content } = yield call(getItemForSelectedCategory, action.payload);
    yield put({ type: INVENTORY_CATEGORIES_DETAILS.CATEGORY_ITEM_SUCCESS, payload: content });
  } catch (e) {
    yield put({ type: INVENTORY_CATEGORIES_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
