import { PLANV2 } from '../actionTypes';

export const fetchPlanV2 = (action) => ({
  type: PLANV2.FETCH,
  payload: action.payload,
});

export const addPlanV2 = (action) => ({
  type: PLANV2.ADD,
  payload: action.payload,
});
