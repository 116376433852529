import { INVOICEDETAILS } from '../actionTypes';

export const fetchInvoiceDetails = (action) => ({
  type: INVOICEDETAILS.FETCH,
  payload: action.payload,
});

export const updateInvoice = (action) => ({
  type: INVOICEDETAILS.UPDATE,
  payload: action.payload,
});

export const getCustomersList = (action) => ({
  type: INVOICEDETAILS.CUSTOMERS_FETCH,
  payload: action.payload,
});

export const addNewInvoice = (action) => ({
  type: INVOICEDETAILS.INVOICE_ADD,
  payload: action.payload,
});

export const addCustomer = (action) => ({
  type: INVOICEDETAILS.CUSTOMER_ADD,
  payload: action.payload,
});

export const deleteInvoice = (action) => ({
  type: INVOICEDETAILS.DELETE,
  payload: action.payload,
});

export const cancelInvoices = (action) => ({
  type: INVOICEDETAILS.CANCEL,
  payload: action.payload,
});

export const getProductsList = (action) => ({
  type: INVOICEDETAILS.PRODUCTS_FETCH,
  payload: action.payload,
});

export const addNewProcut = (action) => ({
  type: INVOICEDETAILS.PRODUCT_ADD,
  payload: action.payload,
});

export const notifyInvoice = (action) => ({
  type: INVOICEDETAILS.NOTIFY_SUCCESS,
  payload: action.payload,
});

export const getCouponsList = (action) => ({
  type: INVOICEDETAILS.COUPON_FETCH,
  payload: action.payload,
});

export const getItemCouponsList = (action) => ({
  type: INVOICEDETAILS.ITEMCOUPON_FETCH,
  payload: action.payload,
});

export const addNewCoupon = (action) => ({
  type: INVOICEDETAILS.COUPON_ADD,
  payload: action.payload,
});

export const getTaxRateList = (action) => ({
  type: INVOICEDETAILS.TAX_RATE_FETCH,
  payload: action.payload,
});

export const addNewTaxRate = (action) => ({
  type: INVOICEDETAILS.TAX_RATE_ADD,
  payload: action.payload,
});

export const getTaxes = (action) => ({
  type: INVOICEDETAILS.TAXES_FETCH,
  payload: action.payload,
});

export const refundInvoices = (action) => ({
  type: INVOICEDETAILS.REFUND,
  payload: action.payload,
});
