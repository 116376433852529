import React, { useState, useRef } from 'react';
import {
  Row,
  Col,
  Select,
  Button,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  DWOLLA_STATUS,
  ICICI_BANK_STATUS,
  CASHFREE_STATUS,
} from 'appconstants';

import {
  SpButton,
  SpH5,
} from 'components/DesignKit';

const { Option } = Select;

type Props = {
  internalAccount: Object,
  submit: Function,
  openModal: Function,
  issuer: Object,
};

const Issuer = (props: Props) => {
  const {
    internalAccount,
    submit,
    openModal,
    issuer,
  } = props;

  const [, forceUpdate] = useState();
  const [activeIssuer, setActiveIssuer] = useState(internalAccount && internalAccount.issuerActive ? internalAccount.issuerActive : false);
  const [issuerType, setIssuerType] = useState(internalAccount.iciciCustomerStatus
    ? 'ICICIBank' : internalAccount.dwollaCustomerStatus
      ? 'Dwolla' : internalAccount.issuer
        ? internalAccount.issuer && internalAccount.issuer.name : 'Select issuer');
  const [cashFreeStatus, setCashFreeStatus] = useState(internalAccount.cashfreeCustomerStatus ? internalAccount.cashfreeCustomerStatus : '');
  const [issuerStatus, setIssuerStatus] = useState(internalAccount.iciciCustomerStatus ? internalAccount.iciciCustomerStatus : '');
  const [dwollaStatus, setDwollaStatus] = useState(internalAccount.dwollaCustomerStatus ? internalAccount.dwollaCustomerStatus : '');
  const [disabled, setDisabled] = useState(true);
  const [issuerID, setIssuerID] = useState(internalAccount.issuer && internalAccount.issuer.id);
  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  const updateIssuer = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      issuerType,
      activeIssuer,
      issuerStatus,
      dwollaStatus,
      cashFreeStatus,
      issuerID,
    });
  };

  const onIssuerChange = (value) => {
    setIssuerType(value[0]);
    setIssuerID(value[1]);
    setDisabled(false);
    openModal(true);
  };

  const reset = () => {
    setActiveIssuer(internalAccount && internalAccount.issuerActive ? internalAccount.issuerActive : false);
    setIssuerType(internalAccount.iciciCustomerStatus
      ? 'ICICIBank' : internalAccount.dwollaCustomerStatus
        ? 'Dwolla' : internalAccount.issuer
          ? internalAccount.issuer && internalAccount.issuer.name : 'Select issuer');
    setIssuerID(internalAccount.issuer && internalAccount.issuer.id);
    setIssuerStatus(internalAccount.iciciCustomerStatus || '');
    setDwollaStatus(internalAccount.dwollaCustomerStatus || '');
    setCashFreeStatus(internalAccount.cashfreeCustomerStatus || '');
    setDisabled(true);
    openModal(false);
  };

  return (
    <>
      <div>
        <Row>
          <Col span={3} className="mb-2">
            <SpH5>Issuer</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              className="w-100"
              value={issuerType || 'Select Issuer'}
              showSearch
              onChange={onIssuerChange}
            >
              {
                issuer.map((item) => (
                  <Option
                    key={item.id}
                    value={[item.name, item.id]}
                  >
                    {item.name}
                  </Option>
                ))
              }
            </Select>
          </Col>
        </Row>
        {
          (issuerType === 'Dwolla') && (
            <>
              <Row>
                <Col span={3} className="mb-2 mt-4">
                  <SpH5>Dwolla Status</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Select
                    className="w-100"
                    value={dwollaStatus || 'Select Status'}
                    onChange={val => setDwollaStatus(val)}
                  >
                    {
                      DWOLLA_STATUS.map((item) => (
                        <Option key={item.id} value={item.value}>{item.name}</Option>
                      ))
                    }
                  </Select>
                </Col>
              </Row>
            </>
          )
        }
        {
          (issuerType === 'ICICIBank') && (
            <>
              <Row>
                <Col span={3} className="mb-2 mt-4">
                  <SpH5>ICICI Bank Status</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Select
                    className="w-100"
                    value={issuerStatus || 'Select Status'}
                    onChange={val => setIssuerStatus(val)}
                  >
                    {
                      ICICI_BANK_STATUS.map((item) => (
                        <Option key={item.id} value={item.value}>{item.name}</Option>
                      ))
                    }
                  </Select>
                </Col>
              </Row>
            </>
          )
        }
        {
          (issuerType === 'Cashfree') && (
            <>
              <Row>
                <Col span={3} className="mb-2 mt-4">
                  <SpH5>Cashfree Status</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Select
                    className="w-100"
                    value={cashFreeStatus || 'Select Status'}
                    onChange={val => setCashFreeStatus(val)}
                  >
                    {
                      CASHFREE_STATUS.map((item) => (
                        <Option key={item.id} value={item.value}>{item.name}</Option>
                      ))
                    }
                  </Select>
                </Col>
              </Row>
            </>
          )
        }
        <Row>
          <Col span={6} className="mb-2 mt-4">
            <Switch
              checked={activeIssuer}
              className="mr-2"
              onChange={val => {
                setActiveIssuer(val);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpH5>Issuer Active</SpH5>
          </Col>
        </Row>
        <Row className="mt-4" style={{ marginLeft: '-15px' }}>
          <Col span={2}>
            <Button
              type="link"
              onClick={reset}
              disabled={disabled}
            >
              Reset
            </Button>
          </Col>
          <Col span={2}>
            <SpButton
              type="primary"
              shape="round"
              disabled={disabled}
              onClick={updateIssuer}
            >
              Update
            </SpButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(Issuer);
