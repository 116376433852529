// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Table,
  Row,
  Col,
  Icon,
  Tooltip,
} from 'antd';
import queryString from 'query-string';
import styled from 'styled-components';
import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
} from 'components/DesignKit';
import { useLocation } from 'react-router-dom';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import getBackground from 'utils/getBackground';
import getTerminalStatus from 'utils/getTerminalStatus';
import Loading from 'components/Loading';
import {
  locactionDetailsAction,
  notificationActions,
  locationsActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
  PAGES,
  ROLES,
  API_STATUS,
} from 'appconstants';
import getStateList from 'utils/stateList';
import RBAC from 'components/rbac';

import DeleteLocation from './component/DeleteLocation';
import EditTerminal from './component/EditTerminal';
import EditLocation from '../LocationList/component/EditLocation';
import RemoveTerminal from './component/RemoveTerminal';

// $FlowFixMe
const StatusTag = styled('div')({
  display: 'inline-block',
  paddingLeft: '10px',
  paddingRight: '10px',
  borderRadius: '10px',
  height: '21px',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  fetchLocationDetails: Function,
  fetchTerminalDetails: Function,
  updateTerminals: Function,
  updateLocation: Function,
  setNotification: Function,
  locationDetails: Object,
  loading: boolean,
  test: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  totalElements: number,
  terminalDetails: Array<Object>,
  locations: Array<Object>,
  selectedAccount: Object,
  locationDetailsStatus: string,
  removeTerminal: Function,
}

const LocationDetail = (props: Props) => {
  const {
    fetchLocationDetails,
    fetchTerminalDetails,
    updateTerminals,
    updateLocation,
    setNotification,
    locationDetails,
    loading,
    test,
    submitting,
    validator,
    history,
    totalElements,
    terminalDetails,
    locations,
    selectedAccount,
    locationDetailsStatus,
    removeTerminal,
  } = props;
  const { gid } = props.match.params;
  const [statesInCountry, setstatesInCountry] = useState([]);
  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const [deleteLocationModal, setDeleteLocationModal] = useState(false);
  const [editTerminalModal, setEditTerminalModal] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState({});
  const [editLocationModal, setEditLocationModal] = useState(false);
  const [locationSelected, setLocationSelected] = useState({});
  const [redirect, setRedirect] = useState(false);
  const gidPath = gid.split('-')[1];
  const [zipTag, setZipTag] = useState('');
  const [removeTerminalModal, setRemoveTerminalModal] = useState(false);

  const getData = () => {
    fetchLocationDetails(gid);
  };

  const getTerminalData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchTerminalDetails({ gid, currentPage, sortParams });
  };

  useEffect(() => {
    getData();
    getTerminalData(location.search);
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
    }
  }, []);

  useEffect(() => {
    getData();
    getTerminalData(location.search);
  }, [test, selectedAccount]);

  useEffect(() => {
    getTerminalData(location.search);
  }, [location]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.LOCATIONS);
    }
  }, []);

  useEffect(() => {
    if (!validator) {
      fetchLocationDetails(gid);
      setEditTerminalModal(false);
      setEditLocationModal(false);
      setRemoveTerminalModal(false);
    }
  }, [validator]);

  useEffect(() => {
    if (!validator && redirect) {
      getTerminalData(location.search);
    }
  }, [validator]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [terminalDetails]);

  const showDeleteLocationModal = () => {
    setDeleteLocationModal(true);
  };

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.LOCATIONS}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const editSelectedLocation = (locationEdit: Object) => {
    const {
      locationName,
      cityName,
      zipCode,
      stateName,
      streetName,
      locationId,
      countryCode,
    } = locationEdit;

    const params = {
      address: {
        city: cityName,
        countryCode,
        id: locationId,
        postalCode: zipCode,
        state: stateName,
        street: streetName,
      },
      name: locationName,
    };
    updateLocation({
      gid,
      params,
    });
  };

  const showEditLocationModal = (value) => {
    setEditLocationModal(true);
    setLocationSelected(value);
  };

  const showRemoveTerminalModal = (value) => {
    setRemoveTerminalModal(true);
    setSelectedTerminal(value);
  };

  const deleteSelectedTerminal = (terminalGid: string) => {
    removeTerminal({
      terminalGid,
      gid,
    });
  };

  const deleteSelectedLocation = async (locationGid: string) => {
    try {
      await axiosAuthInstance.delete(`${API_END_POINTS.GET_LOCATIONS}/${locationGid}`);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.LOCATION_DETAILS.DELETE_SUCCESS,
      });
      history.push(PAGES.LOCATIONS);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data && e.response.data.message,
      });
    } finally {
      setDeleteLocationModal(false);
    }
  };

  const editSelectedTerminal = (terminal: Object) => {
    const {
      terminalName,
      locationId,
      isActive,
      terminalGid,
    } = terminal;

    const params = {
      deviceName: terminalName,
      isActive,
      meta: {
      },
      terminalLocationGid: locationId,
    };

    updateTerminals({
      gid: terminalGid,
      params,
    });
    setRedirect(true);
  };

  const showEditTerminalModal = (value: Object) => {
    setEditTerminalModal(true);
    setSelectedTerminal(value);
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'deviceName',
      width: '30%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'TERMINAL ID',
      dataIndex: 'gid',
      width: '30%',
      align: 'left',
      render: (terminalId) => {
        const trunc = terminalId.slice(0, 16);
        return (
          <Tooltip placement="top" title={gid}>
            <span>{trunc}</span>
          </Tooltip>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '25%',
      align: 'left',
      sorter: false,
      render:
        status => <StatusTag style={{ backgroundColor: getBackground(status) }}>{getTerminalStatus(status)}</StatusTag>,
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '15%',
    align: 'center',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Remove" className="mr-3">
          <Icon
            type="delete"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showRemoveTerminalModal(record); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={() => showEditTerminalModal(record)}
          />
        </Tooltip>
      </span>
    ),
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        locationDetails.address && locationDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="text-uppercase mr-4" fontSize="20px">{locationDetails.name}</SpText>
                </Col>
                <Col>
                  <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                    <SpButton
                      type="danger"
                      shape="round"
                      onClick={showDeleteLocationModal}
                    >
                      Delete Location
                    </SpButton>
                  </RBAC>
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Street Address</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{locationDetails.address.street}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>City</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{locationDetails.address.city}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>State</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{locationDetails.address.state}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>{zipTag}</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{locationDetails.address.postalCode}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Country</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{locationDetails.address.country.name}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row>
                  <Col>
                    <RBAC
                      permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.SUPERUSER]}
                    >
                      <SpButton
                        className="mr-2"
                        type="secondary"
                        shape="round"
                        onClick={() => showEditLocationModal(locationDetails)}
                      >
                        Update Details
                      </SpButton>
                    </RBAC>
                  </Col>
                </Row>
              </SpForm>

              {/* EDIT LOCATION MODAL */}
              {editLocationModal && (
                <EditLocation
                  visible={editLocationModal}
                  submitting={submitting}
                  locationSelected={locationSelected}
                  close={() => setEditLocationModal(false)}
                  submit={editSelectedLocation}
                  country={selectedAccount.country.name}
                  statesInCountry={statesInCountry}
                  selectedAccount={selectedAccount}
                />
              )}

              {/* EDIT TERMINAL MODAL */}
              {editTerminalModal && (
                <EditTerminal
                  visible={editTerminalModal}
                  submitting={submitting}
                  selectedTerminal={selectedTerminal}
                  close={() => setEditTerminalModal(false)}
                  submit={editSelectedTerminal}
                  locations={locations}
                />
              )}
              {/* DELETE LOCATION MODAL */}
              {deleteLocationModal && (
                <DeleteLocation
                  visible={deleteLocationModal}
                  locationDetails={locationDetails}
                  submitting={submitting}
                  close={() => setDeleteLocationModal(false)}
                  submit={deleteSelectedLocation}
                />
              )}
              {/* REMOVE TERMINAL MODAL */}
              {removeTerminalModal && (
                <RemoveTerminal
                  visible={removeTerminalModal}
                  selectedTerminal={selectedTerminal}
                  submitting={submitting}
                  close={() => setRemoveTerminalModal(false)}
                  submit={deleteSelectedTerminal}
                />
              )}
            </Card>
            <Card>
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">TERMINALS AT THIS LOCATION</SpText>
                </Col>
              </Row>
              <RBAC
                fallback={(
                  <Table
                    columns={[...columns, actionColumn]}
                    rowKey={(record) => record.gid}
                    dataSource={terminalDetails}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                )}
                permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.SUPERUSER]}
              >
                <Table
                  columns={[...columns, actionColumn]}
                  rowKey={(record) => record.gid}
                  dataSource={terminalDetails}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </RBAC>
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  locationDetails: state.locationDetails,
  locationDetailsStatus: state.locationDetails.locationDetailsStatus,
  terminalDetails: state.locationDetails.terminalDetails ? state.locationDetails.terminalDetails.content : [],
  totalElements: state.locationDetails.terminalDetails ? state.locationDetails.terminalDetails.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  locations: state.locations.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLocationDetails: gid => dispatch(locactionDetailsAction.fetchLocationDetails({
    payload: gid,
  })),
  fetchTerminalDetails: param => dispatch(locactionDetailsAction.fetchTerminalDetails({
    payload: param,
  })),
  updateTerminals: param => dispatch(locactionDetailsAction.updateTerminals({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  updateLocation: param => dispatch(locationsActions.updateLocation({
    payload: param,
  })),
  removeTerminal: param => dispatch(locactionDetailsAction.removeTerminal({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(LocationDetail);
