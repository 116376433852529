import { OCR_REQUEST_STATUSES } from 'appconstants';

export default value => {
  if (value === 'PROCESSING') {
    return OCR_REQUEST_STATUSES.PROCESSING;
  } if (value === 'SUCCESSFUL') {
    return OCR_REQUEST_STATUSES.SUCCESSFUL;
  } if (value === 'FAILED') {
    return OCR_REQUEST_STATUSES.FAILED;
  } return OCR_REQUEST_STATUSES.PROCESSING;
};
