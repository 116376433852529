/* eslint-disable */
import React from 'react';

const defaultTrue = ({ fill = "silver" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22.5002C6.21 22.5002 1.5 17.7902 1.5 12.0002C1.5 6.21018 6.21 1.50018 12 1.50018C17.79 1.50018 22.5 6.21018 22.5 12.0002C22.5 17.7902 17.79 22.5002 12 22.5002ZM12 3.00018C7.035 3.00018 3 7.03518 3 12.0002C3 16.9652 7.035 21.0002 12 21.0002C16.965 21.0002 21 16.9652 21 12.0002C21 7.03518 16.965 3.00018 12 3.00018Z" fill="#292929" />
    <path d="M12 18.0002C15.3137 18.0002 18 15.3139 18 12.0002C18 8.68647 15.3137 6.00018 12 6.00018C8.68629 6.00018 6 8.68647 6 12.0002C6 15.3139 8.68629 18.0002 12 18.0002Z" fill="#292929" />
  </svg>
);

export default defaultTrue;
