// @flow
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  Radio,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';

import {
  TAX_STATUS,
  TAX_TYPES,
  COUNTRY_PHONE_CODE,
  COUNTRY_CODES,
} from 'appconstants';

const { Option } = Select;

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  submitting: Function,
  customerDetails: Object,
  phoneCode: string,
  selectedAccount: Object,
  zipTag: string,
  statesInCountry: Any,
};

const UpdateCustomer = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    phoneCode,
    customerDetails,
    selectedAccount,
    zipTag,
    statesInCountry,
  } = props;

  const [, forceUpdate] = useState();
  const [customerName, setCustomerName] = useState(customerDetails.name);
  const [customerEmail, setCustomerEmail] = useState(customerDetails.email);
  const [customerPhone, setCustomerPhone] = useState('');
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [customerTaxValue, setCustomerTaxValue] = useState();
  const [customerTaxStatus, setTaxStatus] = useState(customerDetails.taxStatus);
  const [customerTaxId, setTaxId] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [taxId, setCustomerTaxId] = useState('');
  const [phoneFormate, setPhoneFormate] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const customerGid = customerDetails && customerDetails.gid;
  const countryName = selectedAccount && selectedAccount.country && selectedAccount.country.name;
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [streetName1, setStreetName1] = useState('');
  const [cityName1, setCityName1] = useState('');
  const [stateName1, setStateName1] = useState('');
  const [zipCode1, setZipCode1] = useState('');
  const postalCodeRegex = countryName === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;
  const cntryId = selectedAccount && selectedAccount.country.id;
  const countryCodes = (getCountryAlpha2(cntryId));
  const [removeBillingAddress, setRemoveBillAddress] = useState(customerDetails?.removeBillingAddress ? 'remove' : 'add');
  const [removeShippingAddress, setRemoveShippingAddress] = useState(customerDetails?.removeBillingAddress ? 'remove' : 'add');

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
    },
  }));

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setCustomerPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  useEffect(() => {
    if (customerDetails.tax.type === 'NONE') {
      setCustomerTaxValue(null);
    } else {
      setCustomerTaxValue(customerDetails.taxValue);
    }
  }, []);

  useEffect(() => {
    const phone = customerDetails.phoneNumber ? customerDetails.phoneNumber : '';
    const phoneResult = phone ? findPhoneNumbersInText(phone) : null;
    if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
      setCountryCode(`+${phoneResult[0].number.countryCallingCode}`);
      setCustomerPhone(phoneResult[0].number.nationalNumber);
      const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
      const formater = data.substr(data.indexOf(' ') + 1);
      setPhoneFormate(formater);
    }
    if (customerDetails.billingAddress) {
      setStreetName(customerDetails.billingAddress && customerDetails.billingAddress.street);
      setCityName(customerDetails.billingAddress && customerDetails.billingAddress.city);
      setStateName(customerDetails.billingAddress && customerDetails.billingAddress.state);
      setZipCode(customerDetails.billingAddress && customerDetails.billingAddress.postalCode);
    }
    if (customerDetails.shippingAddress) {
      setStreetName1(customerDetails.shippingAddress && customerDetails.shippingAddress.street);
      setCityName1(customerDetails.shippingAddress && customerDetails.shippingAddress.city);
      setStateName1(customerDetails.shippingAddress && customerDetails.shippingAddress.state);
      setZipCode1(customerDetails.shippingAddress && customerDetails.shippingAddress.postalCode);
    }
  }, [customerDetails]);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (customerDetails.tax) {
      const tax = TAX_TYPES.find(value => value.ID === customerDetails.tax.id);
      setTaxId(tax && tax.value);
      setCustomerTaxId(customerDetails.tax.type);
    }
  }, [customerDetails]);

  const updateTaxVale = (value) => {
    // value 1 is None.
    if (value === 'NONE') {
      setDisabled(true);
      setShow(false);
      setCustomerTaxValue(null);
      setTaxId(value);
    } else {
      setTaxId(value);
      setDisabled(false);
      setShow(true);
    }
  };

  useEffect(() => {
    if (taxId === 'NONE') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [taxId]);

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${customerPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const handleRadioChange = (e) => {
    setRemoveBillAddress(e.target.value);
  };
  const handleShippingRadioChange = (e) => {
    setRemoveShippingAddress(e.target.value);
  };

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    let shippingAddress = null;
    if (streetName1 && cityName1 && stateName1 && zipCode1) {
      shippingAddress = {
        street: streetName1,
        city: cityName1,
        state: stateName1,
        postalCode: zipCode1,
        countryCode: countryCodes,
      };
    }
    submit({
      customerName,
      customerEmail,
      customerPhone,
      customerTaxId,
      customerTaxValue,
      customerTaxStatus,
      customerGid,
      countryCode,
      removeBillingAddress: removeBillingAddress === 'add' ? null : true,
      billingAddress: removeBillingAddress === 'add' ? {
        street: streetName,
        city: cityName,
        state: stateName,
        postalCode: zipCode,
        countryCode: countryCodes,
      } : null,
      removeShippingAddress: removeShippingAddress === 'add' ? null : true,
      shippingAddress: removeShippingAddress === 'add' ? (shippingAddress || null) : null,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Customer"
    >
      <div className="px-4">
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            defaultValue={customerName}
            onChange={(e) => setCustomerName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', customerName, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            defaultValue={customerEmail}
            onChange={(e) => setCustomerEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('email', customerEmail, 'required|email|checkLowerCase')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone Number</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                value={countryCode}
                onChange={onCountryCodeChange}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormate}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setCustomerPhone(e.currentTarget.value);
                    const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormate(formater);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('phone number', customerPhone, `phoneNumValidation:${countryCode}${customerPhone}`)}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <label className="d-block"><SpH5>Tax Status</SpH5></label>
          <div className="d-flex">
            <div className="w-50 mr-3">
              <Select
                className="w-100 mr-4"
                placeholder="Select ID Type"
                defaultValue={customerTaxStatus}
                onChange={(e) => setTaxStatus(e)}
              >
                {
                  TAX_STATUS.map(s => (
                    <Option
                      key={1}
                      value={s.value}
                      className="text-capitalize"
                    >
                      {s.display}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('Id Type', customerTaxStatus, 'required')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <label className="d-block"><SpH5>Tax Id</SpH5></label>
          <div className="d-flex">
            <div className="w-50 mr-3">
              <Select
                className="w-100"
                defaultValue={taxId}
                onChange={updateTaxVale}
              >
                {
                  TAX_TYPES.map(j => (
                    <Option
                      key={1}
                      value={j.value}
                      className="text-capitalize"
                    >
                      {j.display}
                    </Option>
                  ))
                }
              </Select>
            </div>
            <div className="w-50">
              <Input
                value={customerTaxValue}
                disabled={disabled}
                onChange={(e) => setCustomerTaxValue(e.currentTarget.value)}
              />
              <SpError>
                {show
                  ? simpleValidator.current.message('Tax value', customerTaxValue, 'required')
                  : ''}
              </SpError>
            </div>
          </div>
        </div>
        <div className="d-flex mb-3">
          <div className="mr-4">
            <label htmlFor=""><SpH5>Billing Address</SpH5></label>
          </div>
          <div>
            <Radio.Group onChange={handleRadioChange} value={removeBillingAddress}>
              <Radio value="add">Add</Radio>
              <Radio value="remove">Remove</Radio>
            </Radio.Group>
          </div>
        </div>
        {
          removeBillingAddress === 'add' && (
            <>
              <div className="mb-3">
                <label htmlFor=""><SpH5>Street</SpH5></label>
                <Input
                  placeholder="Street Address"
                  value={streetName}
                  onChange={(e) => setStreetName(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('street', streetName, 'required')}
                </SpError>
              </div>

              <div className="pb-3">
                <div className="d-flex">
                  <div className="w-50 mr-3">
                    <label className="d-block"><SpH5>City</SpH5></label>
                    <Input
                      placeholder="City"
                      value={cityName}
                      onChange={(e) => setCityName(e.currentTarget.value)}
                    />
                    <SpError>
                      {simpleValidator.current.message('city', cityName, 'required')}
                    </SpError>
                  </div>
                  <div className="w-50">
                    <label className="d-block"><SpH5>State</SpH5></label>
                    <Select
                      className="w-100"
                      placeholder="State"
                      showSearch
                      optionFilterProp="children"
                      value={stateName}
                      onChange={(e) => setStateName(e)}
                    >
                      {
                        statesInCountry.map(s => (
                          <Option
                            key={s.id}
                            value={s.id}
                            className="text-capitalize"
                          >
                            {s.name}
                          </Option>
                        ))
                      }
                    </Select>
                    <SpError>
                      {simpleValidator.current.message('state', stateName, 'required')}
                    </SpError>
                  </div>
                </div>
              </div>

              <div className="pb-3">
                <div className="d-flex">
                  <div className="w-50 mr-3">
                    <label className="d-block"><SpH5>{zipTag}</SpH5></label>
                    <Input
                      placeholder={zipTag}
                      value={zipCode}
                      onChange={(e) => setZipCode(e.currentTarget.value)}
                    />
                    <SpError>
                      {simpleValidator.current.message(zipTag, zipCode, 'required|postalcode')}
                    </SpError>
                  </div>
                  <div className="w-50">
                    <label className="d-block"><SpH5>Country</SpH5></label>
                    <Select
                      defaultValue={countryName}
                      style={{ width: '100%' }}
                      disabled
                    >
                      {COUNTRY_CODES.map((item) => (
                        <Option key={item.ID} value={item.ALPHA2}>
                          {item.NAME}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </>
          )
        }
        <div className={removeShippingAddress === 'add' ? 'd-flex mb-2' : 'd-flex'}>
          <div className="mr-3">
            <label htmlFor=""><SpH5>Shipping Address</SpH5></label>
          </div>
          <div>
            <Radio.Group onChange={handleShippingRadioChange} value={removeShippingAddress}>
              <Radio value="add">Add</Radio>
              <Radio value="remove">Remove</Radio>
            </Radio.Group>
          </div>
        </div>
        {
          removeShippingAddress === 'add' && (
            <>
              <div className="mb-3">
                <label htmlFor=""><SpH5>Street</SpH5></label>
                <Input
                  placeholder="Street Address"
                  value={streetName1}
                  onChange={(e) => setStreetName1(e.currentTarget.value)}
                />
              </div>

              <div className="pb-3">
                <div className="d-flex">
                  <div className="w-50 mr-3">
                    <label className="d-block"><SpH5>City</SpH5></label>
                    <Input
                      placeholder="City"
                      value={cityName1}
                      onChange={(e) => setCityName1(e.currentTarget.value)}
                    />
                  </div>
                  <div className="w-50">
                    <label className="d-block"><SpH5>State</SpH5></label>
                    <Select
                      className="w-100"
                      placeholder="State"
                      showSearch
                      optionFilterProp="children"
                      value={stateName1}
                      onChange={(e) => setStateName1(e)}
                    >
                      {
                        statesInCountry.map(s => (
                          <Option
                            key={s.id}
                            value={s.id}
                            className="text-capitalize"
                          >
                            {s.name}
                          </Option>
                        ))
                      }
                    </Select>
                  </div>
                </div>
              </div>

              <div className="pb-3">
                <div className="d-flex">
                  <div className="w-50 mr-3">
                    <label className="d-block"><SpH5>{zipTag}</SpH5></label>
                    <Input
                      placeholder={zipTag}
                      value={zipCode1}
                      onChange={(e) => setZipCode1(e.currentTarget.value)}
                    />
                  </div>
                  <div className="w-50">
                    <label className="d-block"><SpH5>Country</SpH5></label>
                    <Select
                      defaultValue={countryName}
                      style={{ width: '100%' }}
                      disabled
                    >
                      {COUNTRY_CODES.map((item) => (
                        <Option key={item.ID} value={item.ALPHA2}>
                          {item.NAME}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(UpdateCustomer);
