// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Input,
  Select,
  Icon,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  MESSAGES,
  BENE_TYPES,
} from 'appconstants';
import {
  validationRegex,
  validators,
} from 'utils/validationMessages';
import { SpH5, SpError } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  submitting: boolean,
  isIndia: boolean,
  fundingSourceDetailsData: Object,
  transferDetailsData: Object,
  fundingResult: Array<object>,
  selectedAccount: Object,
};

const FundingSource = (props: Props, ref) => {
  const {
    submitting,
    fundingSourceDetailsData,
    isIndia,
    fundingResult,
    transferDetailsData,
    selectedAccount,
  } = props;
  const [, forceUpdate] = useState();
  const [fundingSourceName, setFundingSourceName] = useState(fundingSourceDetailsData.fundingSourceName || 'New Funding Source');
  const [fundingSourceGid, setFundingSourceGid] = useState(fundingSourceDetailsData.fundingSourceGid);
  const [accountNumber, setAccountNumber] = useState(fundingSourceDetailsData.accountNumber);
  const [routingNumber, setRoutingNumber] = useState(fundingSourceDetailsData.routingNumber);
  const [accountType, setAccountType] = useState((fundingSourceDetailsData.accountType) || (isIndia ? 'CURRENT' : 'CHECKING'));
  const [bankName, setBankName] = useState(fundingSourceDetailsData.bankName);
  const [upiId, setUpiId] = useState(fundingSourceDetailsData.upiId);
  const [beneType, setBeneType] = useState(fundingSourceDetailsData.beneType);
  const { beneValue } = fundingSourceDetailsData;
  const fundings = fundingResult;
  const [disableFunding, setDisableFunding] = useState(fundingSourceDetailsData.disableFunding);
  const accountRegex = /[0-9]{6,17}/;
  const routingRegex = /[0-9]{9}/;
  const upiRegex = /^\w.+@\w+$/;
  const countryCode = selectedAccount.country.alpha2;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      accountNumber: {
        message: MESSAGES.BANK_DETAILS.VALID,
        rule: val => accountRegex.test(val),
      },
      bankRoutingNumber: {
        message: MESSAGES.BANK_DETAILS.ROUTING,
        rule: val => routingRegex.test(val),
      },
      bankName: {
        message: validators.bussiness.validBankName,
        rule: val => validationRegex.nameRegex.test(val),
      },
      upiNumber: {
        message: MESSAGES.BANK_DETAILS.UPI,
        rule: val => upiRegex.test(val),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  console.log(fundingResult, 'fundingResult');

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      disableFunding,
      fundingSourceName,
      fundingSourceGid,
      accountNumber,
      routingNumber,
      accountType,
      bankName,
      upiId,
      beneType,
      beneValue,
    }),
  }));

  const updateFunding = (value) => {
    if (value === 'New Funding Source') {
      setDisableFunding(false);
      setFundingSourceName('New Funding Source');
      setAccountNumber('');
      setRoutingNumber('');
      setAccountType(countryCode === 'IN' ? 'CURRENT' : 'CHECKING');
      setBankName('');
      setUpiId('');
    } else if (value) {
      setFundingSourceName(value);
      const results = fundings.filter((item) => (item.gid === value));
      if (results && results[0].gid) {
        setFundingSourceGid(results && results[0].gid);
        if (results && results[0].issuerBank) {
          let fundName;
          if (results && results[0].issuerBank) {
            if ((results[0].issuerBank.bankName) && (results[0].issuerBank.lastFour)) {
              fundName = `${results[0].issuerBank.bankName} ****${results[0].issuerBank.lastFour}`;
            } else if ((results[0].issuerBank.vpa)) {
              fundName = results[0].issuerBank.vpa;
            } else if ((results[0].issuerBank.bankName)) {
              fundName = results[0].issuerBank.bankName;
            } else if ((results[0].issuerBank.lastFour)) {
              fundName = `****${results[0].issuerBank.lastFour}`;
            }
          }
          setFundingSourceName(fundName);
          setUpiId(results[0].issuerBank && results[0].issuerBank.vpa);
          setAccountNumber(results[0].issuerBank && results[0].issuerBank.lastFour);
          setRoutingNumber(results[0].issuerBank && results[0].issuerBank.routingNumber);
          setAccountType(results[0].issuerBank && results[0].issuerBank.accountType);
          setBankName(results[0].issuerBank && results[0].issuerBank.bankName);
          setBeneType(results[0].issuerBank && results[0].issuerBank.beneType);
        }
        setDisableFunding(true);
      }
    }
  };

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2 mb-3">
          <label htmlFor=""><SpH5>Funding Source</SpH5></label>

          <Select
            placeholder="Select Funding Source"
            className="w-100"
            style={{ height: '40px' }}
            value={fundingSourceName || 'Select Funding Source'}
            onSelect={e => updateFunding(e)}
          >
            <Option key="1" value="New Funding Source">
              <Icon type="plus-circle" />&nbsp;New Funding Source
            </Option>
            {fundings && fundings.map((item) => (
              <Option key={item.gid} value={item.gid}>
                {(item.issuerBank && item.issuerBank.vpa) ? item.issuerBank.vpa
                  : (item.issuerBank && (item.issuerBank.bankName) && (item.issuerBank.lastFour))
                    ? `${item.issuerBank.bankName} ****${item.issuerBank.lastFour}` : item.issuerBank && item.issuerBank.bankName
                      ? (item.issuerBank && item.issuerBank.bankName) : `****${item.issuerBank.lastFour}`}
              </Option>
            ))}
          </Select>
          <SpError>
            {simpleValidator.current.message('funding Source Name', fundingSourceName, 'required')}
          </SpError>
        </div>

        {
          (transferDetailsData && (transferDetailsData.transferType === BENE_TYPES.UPI)) ? (
            <>
              <div>
                <label htmlFor=""><SpH5>UPI Id</SpH5></label>
                <Input
                  placeholder="Enter UPI Id"
                  value={upiId}
                  disabled={disableFunding}
                  onChange={(e) => setUpiId(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('upi id', upiId, 'required|upiNumber')}
                </SpError>
              </div>
            </>
          ) : (
            <>
              <div className="mb-3">
                <label htmlFor=""><SpH5>Account Number</SpH5></label>
                <Input
                  placeholder="Account Number"
                  value={disableFunding ? `****** ${accountNumber}` : accountNumber}
                  disabled={disableFunding}
                  onBlur={() => setAccountNumber(accountNumber.trim())}
                  onChange={(e) => setAccountNumber(e.currentTarget.value)}
                />
                {
                  disableFunding ? (
                    <SpError>
                      {simpleValidator.current.message('account number', accountNumber, 'required')}
                    </SpError>
                  ) : (
                    <SpError>
                      {simpleValidator.current.message('account number', accountNumber, 'required|accountNumber')}
                    </SpError>
                  )
                }
              </div>
              {
                !isIndia && (
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>Routing Number</SpH5></label>
                    <Input
                      placeholder="Routing Number"
                      disabled={disableFunding}
                      value={routingNumber}
                      onBlur={() => setRoutingNumber(routingNumber.trim())}
                      onChange={(e) => setRoutingNumber(e.currentTarget.value)}
                    />
                    <SpError>
                      {simpleValidator.current.message('routing number', routingNumber, 'required|bankRoutingNumber')}
                    </SpError>
                  </div>
                )
              }
              {
                isIndia && (
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>IFSC Number</SpH5></label>
                    <Input
                      placeholder="IFSC Number"
                      disabled={disableFunding}
                      value={routingNumber}
                      onBlur={() => setRoutingNumber(routingNumber.trim())}
                      onChange={(e) => setRoutingNumber((e.currentTarget.value).toUpperCase())}
                    />
                    <SpError>
                      {simpleValidator.current.message('ifsc number', routingNumber, 'required')}
                    </SpError>
                  </div>
                )
              }
              <div className="mb-3">
                <label htmlFor=""><SpH5>Bank Name</SpH5></label>
                <Input
                  placeholder="Bank Name"
                  disabled={disableFunding}
                  value={bankName}
                  onChange={(e) => setBankName(e.currentTarget.value)}
                />
                {
                  !disableFunding && (
                    <SpError>
                      {simpleValidator.current.message('bank name', bankName, 'required|bankName')}
                    </SpError>
                  )
                }
              </div>
              <div>
                <label htmlFor=""><SpH5>Account Type</SpH5></label>
                {
                  isIndia && (
                    <Select
                      className="w-100"
                      showSearch
                      disabled={disableFunding}
                      value={accountType}
                      onChange={(value) => {
                        setAccountType(value);
                      }}
                    >
                      <Option key={1} value="CURRENT">CURRENT ACCOUNT</Option>
                      <Option key={2} value="SAVINGS">SAVINGS</Option>
                    </Select>
                  )
                }
                {
                  !isIndia && (
                    <Select
                      className="w-100"
                      showSearch
                      disabled={disableFunding}
                      value={accountType}
                      onChange={(value) => {
                        setAccountType(value);
                      }}
                    >
                      <Option key={1} value="CHECKING">CHECKING</Option>
                      <Option key={2} value="SAVINGS">SAVINGS</Option>
                    </Select>
                  )
                }
                <SpError>
                  {simpleValidator.current.message('account type', accountType, 'required')}
                </SpError>
              </div>
            </>
          )
        }
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(FundingSource);
