import { CUSTOM_DOCUMENT_REQUEST_DETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  customDocumentRequestDetailsStatus: undefined,
  customDocumentRequestDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CUSTOM_DOCUMENT_REQUEST_DETAILS.FETCH:
      return {
        ...state,
        customDocumentRequestDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case CUSTOM_DOCUMENT_REQUEST_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        customDocumentRequestDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case CUSTOM_DOCUMENT_REQUEST_DETAILS.FAILED:
      return {
        ...state,
        customDocumentRequestDetailsError: action.payload,
        customDocumentRequestDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
