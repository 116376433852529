import { WALLET_TRANSACTION_DETAILS } from '../actionTypes';

const initialState = {
  content: [],
  walletTransactionDetailsStatus: undefined,
  walletDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WALLET_TRANSACTION_DETAILS.FETCH:
      return {
        ...state,
        walletTransactionDetailsStatus: 'STARTED',
      };
    case WALLET_TRANSACTION_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        walletTransactionDetailsStatus: 'SUCCESS',
      };
    case WALLET_TRANSACTION_DETAILS.FAILED:
      return {
        ...state,
        walletDetailsError: action.payload,
        walletTransactionDetailsStatus: 'FAILED',
      };
    default:
      return state;
  }
};
