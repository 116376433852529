import { ADMIN_TERMINAL } from '../actionTypes';

export const fetchAdminTerminal = (action) => ({
  type: ADMIN_TERMINAL.FETCH,
  payload: action.payload,
});

export const addAdminTerminal = (action) => ({
  type: ADMIN_TERMINAL.ADD,
  payload: action.payload,
});

export const updateAdminTerminal = (action) => ({
  type: ADMIN_TERMINAL.UPDATE,
  payload: action.payload,
});
