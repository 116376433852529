import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  ADMIN_REPORTS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchAdminReports = async (payload) => {
  if (payload.filterParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_ADMIN_REPORTS}?${payload.filterParams}`);
  }
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_ADMIN_REPORTS}${query}${sorting}`);
};

const addAdminReport = async payload => axiosAuthInstance.post(API_END_POINTS.GET_ADMIN_REPORTS, payload);

export function* getAdminReports(action) {
  try {
    const { data: { entity } } = yield call(fetchAdminReports, action.payload);
    yield put({ type: ADMIN_REPORTS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: ADMIN_REPORTS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addAdminReports(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(addAdminReport, action.payload);
    const { data: { entity } } = yield call(fetchAdminReports);
    yield put({ type: ADMIN_REPORTS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.REPORT.GENERATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
