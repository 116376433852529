import React, { useState, useRef, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  Input,
  Button,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpText, SpButton, SpError,
} from 'components/DesignKit';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { salesforceActions } from 'store/actions';
import {
  PAGES,
} from 'appconstants';
import AceEditor from 'react-ace';
import 'brace/mode/python';
import 'brace/theme/kuroir';
import ButtonCollapse from 'components/NavIcons/ButtonCollapse';
import ButtonExpand from 'components/NavIcons/ButtonExpand';
import ButtonDelete from 'components/NavIcons/ButtonDelete';

const { Option } = Select;

type Props = {
  location: {
    pathname: string,
    search: string,
  },
  loader: boolean,
  validator: boolean,
  fetchSpField: Function,
  fetchSalesforce: Function,
  addSalesforceMapping: Function,
  spField: Array,
  credentials: Object,
};

const NewInvoice = (props: Props) => {
  const {
    location,
    loader,
    validator,
    fetchSpField,
    fetchSalesforce,
    addSalesforceMapping,
    spField,
    credentials,
  } = props;
  const history = useHistory();
  const [, forceUpdate] = useState();
  const [step, setStep] = useState(1);
  const [sfObject, setSfObject] = useState('');
  const [spFieldList, setSpFieldList] = useState('');
  const [credentialsGid, setCredentialsGid] = useState('');
  const [redirect, setRedirect] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator({
    autoForceUpdate: {
      forceUpdate,
    },
  }));

  const [mappingsArray, setMappingsArray] = useState([{
    spFieldGid: '',
    spFieldName: '',
    sfField: '',
    spObjectGid: '',
    sfMacro: '',
    sfOpen: false,
  }]);

  const getData = () => {
    fetchSpField('INVOICE');
    fetchSalesforce();
  };

  useEffect(() => {
    getData(location.search);
  }, [location]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setSpFieldList(spField);
  }, [spField]);

  useEffect(() => {
    setCredentialsGid(credentials ? credentials[0].gid : '');
  }, [credentials]);

  useEffect(() => {
    if (!validator && redirect) {
      history && history.push(PAGES.SALESFORCE_DETAILS);
    }
  }, [validator]);

  const addMappingDetails = (event) => {
    event.preventDefault();
    setMappingsArray([...mappingsArray, {
      spFieldGid: '',
      spFieldName: '',
      sfField: '',
      spObjectGid: '',
      sfMacro: '',
      sfOpen: false,
    }]);
  };

  const removeMappingDetails = (i) => {
    const list = [...mappingsArray];
    list.splice(i, 1);
    setMappingsArray(list);
    const hasNoFields = (list.length === 0);
    if (hasNoFields) {
      simpleValidator.current.purgeFields();
    }
  };

  const next = () => {
    if (step === 1) {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setStep(step + 1);
    }
    if (step === 2) {
      let payload;
      if (mappingsArray.length > 0) {
        const newMappings = mappingsArray.map(({ sfOpen, spFieldName, ...value }) => value);
        payload = newMappings.map(v => ({ ...v, sfObject, credentialsGid }));
      } else payload = { sfObject, credentialsGid };

      const payData = {
        mappings: Object.assign(payload),
      };
      addSalesforceMapping(payData);
      setRedirect(true);
    }
  };

  const prev = () => {
    setStep(step - 1);
  };


  if (loader) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        {
          step === 1
          && (
            <>
              <Row className="mt-4 mb-4">
                <Col span={5}>
                  <SpText>Salesforce Object</SpText>
                </Col>
                <Col span={8} className="ml-4">
                  <Input
                    placeholder="Object Name"
                    value={sfObject}
                    onChange={(e) => setSfObject(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('object name', sfObject, 'required')}
                  </SpError>
                </Col>
              </Row>
              {
                spFieldList && mappingsArray.map((item, i) => (
                  <div>
                    <Row className="mt-4 mb-4">
                      <Col span={5}>
                        <Select
                          className="w-100"
                          showSearch
                          value={item.spFieldName || 'Please Select Field'}
                          onSelect={(e) => {
                            const list = [...mappingsArray];
                            list[i].spFieldGid = e[0];
                            list[i].spFieldName = e[1];
                            list[i].spObjectGid = e[2];
                            setMappingsArray(list);
                          }}
                        >
                          {spFieldList.map((fieldList) => (
                            <Option
                              key={fieldList.gid}
                              value={[
                                fieldList.gid,
                                fieldList.name,
                                fieldList.spObject && fieldList.spObject.gid,
                              ]}
                            >
                              {fieldList.name}
                            </Option>
                          ))}
                        </Select>
                        <SpError>
                          {simpleValidator.current.message('select', mappingsArray[i].spFieldName, 'required')}
                        </SpError>
                      </Col>
                      <Col
                        span={8}
                        className="ml-4"
                      >
                        <Input
                          placeholder="Field Name"
                          value={item.sfField}
                          onChange={(e) => {
                            const list = [...mappingsArray];
                            list[i].sfField = e.currentTarget.value;
                            setMappingsArray(list);
                          }}
                        />
                        <SpError>
                          {simpleValidator.current.message('field name', mappingsArray[i].sfField, 'required',
                            { messages: { required: 'The field name is required' } })}
                        </SpError>
                      </Col>
                      <Col
                        span={2}
                        style={{
                          marginLeft: '40px',
                        }}
                      >
                        {
                          item.sfOpen && (
                            <span onClick={() => {
                              const list = [...mappingsArray];
                              list[i].sfOpen = false;
                              setMappingsArray(list);
                            }}
                            >
                              <ButtonCollapse />
                            </span>
                          )
                        }
                        {
                          !item.sfOpen && (
                            <span onClick={() => {
                              const list = [...mappingsArray];
                              list[i].sfOpen = true;
                              setMappingsArray(list);
                            }}
                            >
                              <ButtonExpand />
                            </span>
                          )
                        }
                      </Col>
                      {
                        mappingsArray.length > 1 && (
                          <Col
                            span={2}
                            style={{
                              marginLeft: '-40px',
                            }}
                          >
                            <span onClick={() => removeMappingDetails(i)}>
                              <ButtonDelete />
                            </span>
                          </Col>
                        )
                      }
                    </Row>
                    {
                      item.sfOpen && (
                        <Row type="flex" justify="start" align="middle" className="mt-4 mb-4">
                          <Col
                            span={5}
                            className="ml-4 mt-4"
                          >
                            <SpText>Modifier</SpText>
                          </Col>
                          <Col span={8}>
                            <AceEditor
                              mode="python"
                              theme="kuroir"
                              style={{
                                height: '100px',
                                width: 'auto',
                              }}
                              value={item.sfMacro}
                              onChange={(value) => {
                                const list = [...mappingsArray];
                                list[i].sfMacro = value;
                                setMappingsArray(list);
                              }}
                              editorProps={{
                                $blockScrolling: true,
                              }}
                              fontSize={14}
                              showPrintMargin
                              showGutter
                              highlightActiveLinesetOptions={{
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: false,
                                enableSnippets: false,
                                showLineNumbers: true,
                                tabSize: 2,
                              }}
                            />
                          </Col>
                        </Row>
                      )
                    }
                  </div>
                ))
              }
              <Row type="flex" justify="start" align="middle" className="mt-3 mb-3">
                <Col span={6}>
                  <Button
                    type="secondary"
                    onClick={addMappingDetails}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <Row type="flex" justify="end" gutter={[16, 16]}>
                <Col>
                  <SpButton
                    type="secondary"
                    shape="round"
                    className="mr-4"
                    onClick={() => history && history.push(PAGES.INTEGRATIONS)}
                  >
                    Cancel
                  </SpButton>
                  <SpButton
                    type="primary"
                    shape="round"
                    onClick={() => next()}
                  >
                    Next
                  </SpButton>
                </Col>
              </Row>
            </>
          )
        }
        {
          step === 2
          && (
            <>
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">Review Configuration</SpText>
                </Col>
              </Row>
              <Row className="mt-4 mb-4">
                <Col span={4}>
                  <SpText>Salesforce Object:</SpText>
                </Col>
                <Col span={8} className="ml-4">
                  {sfObject}
                </Col>
              </Row>
              {
                mappingsArray.map((data) => (
                  <>
                    <Row className="mt-4 mb-4">
                      <Col span={4}>
                        <SpText>{data.spFieldName}:</SpText>
                      </Col>
                      <Col span={8} className="ml-4">
                        {data.sfField}
                      </Col>
                    </Row>
                    {
                      data.sfMacro && (
                        <Row className="mt-4 mb-4">
                          <Col span={4} className="ml-4">
                            <SpText>Modifier:</SpText>
                          </Col>
                          <Col span={8}>
                            <AceEditor
                              mode="python"
                              theme="github"
                              style={{
                                height: '100px',
                                width: 'auto',
                              }}
                              value={data.sfMacro}
                              editorProps={{
                                $blockScrolling: true,
                              }}
                              readOnly
                              fontSize={14}
                              showPrintMargin
                              showGutter
                            />
                          </Col>
                        </Row>
                      )
                    }
                  </>
                ))
              }
              <Row type="flex" justify="end" gutter={[16, 16]}>
                <Col>
                  <SpButton
                    type="secondary"
                    shape="round"
                    className="mr-4"
                    onClick={() => prev()}
                  >
                    Back
                  </SpButton>
                  <SpButton
                    type="primary"
                    shape="round"
                    onClick={() => next()}
                  >
                    Finish
                  </SpButton>
                </Col>
              </Row>
            </>
          )
        }
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  spField: state.salesforce.spField,
  credentials: state.salesforce.accountSalesforce.content,
  salesforceError: state.salesforce.salesforceError,
  loader: state.loading.loading,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSpField: param => dispatch(salesforceActions.fetchSpField({
    payload: param,
  })),
  fetchSalesforce: param => dispatch(salesforceActions.fetchSalesforce({
    payload: param,
  })),
  addSalesforceMapping: param => dispatch(salesforceActions.addSalesforceMapping({
    payload: param,
  })),
});


export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice);
