// @flow
import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  SpWidget,
  SpWidgetWrapper,
} from 'components/PaymentWidgetDesignKit';

import WidgetDetails from './widgetInfo';
import AmountDetails from './amountInfo';
import EmploymentDetails from './employmentInfo';
import ThanksDetails from './thanksInfo';

type Props = {
  selectedAccount: Object,
  sampleWidget: Object,
  currentWidget: Number,
  history: {
    replace: Function,
  },
};

const ReviewDetails = (props: Props) => {
  const {
    selectedAccount,
    currentWidget,
    sampleWidget,
  } = props;

  const [widgetDetails, setWidgetDetails] = useState(sampleWidget);

  useEffect(() => {
    setWidgetDetails(sampleWidget);
  }, [sampleWidget]);

  return (
    <SpWidget>
      <SpWidgetWrapper
        style={{ height: currentWidget === 3 && '300px', padding: currentWidget === 3 && '0' }}
      >
        {currentWidget === 0 && (
          <WidgetDetails
            selectedAccount={selectedAccount}
            widgetDetails={widgetDetails}
          />
        )}
        {currentWidget === 1 && (
          <AmountDetails
            selectedAccount={selectedAccount}
            widgetDetails={widgetDetails}
          />
        )}
        {currentWidget === 2 && (
          <EmploymentDetails
            selectedAccount={selectedAccount}
            widgetDetails={widgetDetails}
          />
        )}
        {currentWidget === 3 && (
          <ThanksDetails
            selectedAccount={selectedAccount}
            widgetDetails={widgetDetails}
          />
        )}
      </SpWidgetWrapper>
    </SpWidget>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  currentWidget: state.paymentWidgets.currentWidget,
  sampleWidget: state.paymentWidgets.sampleWidget,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(ReviewDetails);
