import React from 'react';
import {
  Card,
  Row,
  Col,
  Skeleton,
} from 'antd';
import moment from 'moment-timezone';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { SpText } from 'components/DesignKit';
import CONSTANTS from 'store/constants';

import formatAmount from 'utils/formatAmount';

const capitalize = (x) => x[0].toUpperCase() + x.slice(1).toLowerCase();

const monthlyAxis = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const hourlyAxis = [];

for (let i = 0; i < 24; i += 1) {
  const fmt = i
    .toLocaleString(undefined, { minimumIntegerDigits: 2 });
  hourlyAxis.push(`${fmt}:00`);
}

type Props = {
  statusSummary: Object,
  totalVolume: Object,
  isPaymentSourcesLoading: Bool,
  interval: String,
  monthlySummary: Array,
  dailySummary: Array,
  hourlySummary: Array,
  selectedAccount: Object,
  duration: String,
  yearToDateSummary: Array,
  paymentsOverTime: String,
  breakup: String,
  summaryType: String,
  isMobileView: Boolean,
};

const TransactionsCharts = ({
  statusSummary,
  totalVolume,
  isPaymentSourcesLoading,
  interval,
  monthlySummary,
  dailySummary,
  hourlySummary,
  selectedAccount,
  duration,
  yearToDateSummary,
  paymentsOverTime,
  breakup,
  summaryType,
  isMobileView,
}: Props) => {
  let chartType;
  const currencyType = selectedAccount && selectedAccount.currency && selectedAccount.currency;
  const prefix = selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix;
  const paymentType = (summaryType === 'Payments' ? 'Total Payments' : 'Total Transfers');
  let dataAxis;
  let seriesValue;
  if (interval === 'DAILY') {
    chartType = 'line';
    const dailyAxis = [];
    const today = new Date();
    const currentDate = today.toLocaleString('en-US', { timeZone: selectedAccount.timezone });
    if (duration === CONSTANTS.DASHBOARD.LAST_30_DAYS) {
      for (let i = 1; i <= 30; i += 1) {
        const priorDate = new Date().setDate(new Date(currentDate).getDate() - i);
        const date = moment.utc(priorDate).format('MMM DD');
        dailyAxis.push(date);
      }
      dataAxis = dailyAxis.reverse();
    } else if (duration !== CONSTANTS.DASHBOARD.LAST_30_DAYS) {
      for (let i = 0; i <= 6; i += 1) {
        const priorDate = new Date().setDate(new Date(currentDate).getDate() - i);
        const date = moment.utc(priorDate).format('MMM DD');
        dailyAxis.push(date);
      }
      dataAxis = dailyAxis.reverse();
    }
    seriesValue = dailySummary;
  }
  if (interval === 'HOURLY') {
    chartType = 'bar';
    dataAxis = hourlyAxis;
    seriesValue = hourlySummary;
  }
  if (interval === 'MONTHLY') {
    chartType = 'bar';
    const months = [];
    const monthlyValues = [];
    const d = new Date();
    d.setDate(1);
    for (let i = 0; i <= 11; i += 1) {
      months.push(monthlyAxis[d.getMonth()]);
      monthlyValues.push(monthlySummary && monthlySummary[d.getMonth()]);
      d.setMonth(d.getMonth() - 1);
    }
    dataAxis = months.reverse();
    seriesValue = monthlyValues.reverse();
  }
  if (interval === 'YEAR_TO_DATE') {
    chartType = 'bar';
    const months = [];
    const monthlyValues = [];
    const d = new Date();
    const n = d.getMonth();
    for (let i = 0; i <= n; i += 1) {
      months.push(monthlyAxis[i]);
      monthlyValues.push(yearToDateSummary[i]);
    }
    dataAxis = months;
    seriesValue = monthlyValues;
  }
  const hasData = seriesValue
    && seriesValue
      .find(x => x !== 0);
  const mandatoryMinimums = !hasData
    ? {
      min: 0,
      max: 100,
    }
    : {};
  return (
    <Card>
      <Row type="flex">
        <Col span={isMobileView ? 24 : 12} className={isMobileView ? 'mb-2' : ''}>
          <SpText
            fontSize="20px"
            color="rgba(0, 0, 0, 0.87)"
          >
            {paymentsOverTime}
          </SpText>
          <ReactEcharts
            option={{
              textStyle: {
                fontFamily: 'Open Sans',
              },
              tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                  if (currencyType) {
                    if (interval === 'DAILY') {
                      return `${params[0].name}: ${prefix} ${formatAmount(params[0].value, currencyType)}`;
                    }
                    return `${prefix} ${formatAmount(params[0].value, currencyType)}`;
                  }
                  return null;
                },
              },
              xAxis: {
                data: dataAxis,
                axisLabel: {
                  inside: false,
                  textStyle: {
                    fontSize: '10',
                    color: 'rgba(53, 64, 82, 0.6)',
                  },
                },
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                z: 10,
              },
              yAxis: {
                ...mandatoryMinimums,
                minInterval: 1,
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  textStyle: {
                    fontSize: '10',
                    color: 'rgba(53, 64, 82, 1)',
                  },
                  formatter: (value) => {
                    if (currencyType) {
                      const tempValue = formatAmount(value, currencyType);
                      const parts = tempValue.split('.');
                      return parts[0];
                    }
                    return null;
                  },
                },
              },
              dataZoom: [
                {
                  type: 'inside',
                },
              ],
              series: [
                {
                  type: chartType,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(
                      0, 0, 0, 1,
                      [
                        { offset: 0, color: '#83bff6' },
                        { offset: 0.5, color: '#188df0' },
                        { offset: 1, color: '#188df0' },
                      ],
                    ),
                  },
                  emphasis: {
                    itemStyle: {
                      color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [
                          { offset: 0, color: '#83BFF6' },
                          { offset: 0.7, color: '#188DF0' },
                          { offset: 1, color: '#188DF0' },
                        ],
                      ),
                    },
                  },
                  data: seriesValue,
                },
              ],
            }}
            notMerge
            lazyUpdate
          />
        </Col>
        <Col span={isMobileView ? 24 : 12} className={isMobileView ? 'mb-2' : ''}>
          <Row type="flex" align="middle" justify="center">
            <Col xs={24}>
              <div className="d-flex justify-content-center">
                <SpText fontSize="20px" color="rgba(0, 0, 0, 0.87)">
                  {breakup}
                </SpText>
              </div>
            </Col>
          </Row>
          <Row type="flex" align="middle" justify="center">
            <Col xs={6}>
              <div className="d-flex flex-column justify-content-center">
                <div className="mb-4 d-flex flex-column justify-content-center text-center">
                  <SpText
                    fontSize="14px"
                    color="rgba(0, 0, 0, 0.8)"
                    fontWeight="600"
                    className="mb-2"
                  >
                    Total Volume
                  </SpText>
                  <Skeleton
                    paragraph={false}
                    title
                    active
                    loading={isPaymentSourcesLoading}
                  >
                    <SpText
                      fontSize="14px"
                      color="rgba(0, 0, 0, 1)"
                      fontWeight="600"
                      className="mb-2"
                    >
                      {
                        currencyType ? (
                          <>
                            {totalVolume.prefix}
                            {formatAmount(totalVolume.value, currencyType)}
                          </>
                        ) : (
                          <>&#8211;</>
                        )
                      }
                    </SpText>
                  </Skeleton>
                </div>
                <div className="mb-4 d-flex flex-column justify-content-center text-center">
                  <SpText
                    fontSize="14px"
                    color="rgba(0, 0, 0, 0.8)"
                    fontWeight="600"
                    className="mb-2"
                  >
                    Average Transaction
                  </SpText>
                  <Skeleton
                    paragraph={false}
                    title
                    active
                    loading={isPaymentSourcesLoading}
                  >
                    <SpText
                      fontSize="14px"
                      color="rgba(0, 0, 0, 1)"
                      fontWeight="600"
                      className="mb-2"
                    >
                      {
                        currencyType ? (
                          <>
                            {totalVolume.prefix}
                            {formatAmount(totalVolume.average, currencyType)}
                          </>
                        ) : (
                          <>&#8211;</>
                        )
                      }
                    </SpText>
                  </Skeleton>
                </div>
              </div>
            </Col>
            <Col xs={18}>
              {
                statusSummary.length > 0
                  ? (
                    <ReactEcharts
                      option={{
                        textStyle: {
                          fontFamily: 'Open Sans',
                        },
                        tooltip: {
                          trigger: 'item',
                        },
                        title: {
                          text: `${statusSummary.map(({ count }) => count).reduce((accum, value) => accum + value, 0)}\nTransactions`,
                          top: 'center',
                          left: 'center',
                          textStyle: {
                            fontSize: 14,
                          },
                        },
                        legend: {
                          orient: 'horizontal',
                          top: 'bottom',
                          left: 'center',
                          icon: 'circle',
                        },
                        series: [
                          {
                            name: paymentType,
                            type: 'pie',
                            radius: ['35%', '65%'],
                            avoidLabelOverlap: false,
                            label: {
                              normal: {
                                show: false,
                              },
                              emphasis: {
                                show: false,
                              },
                            },
                            labelLine: {
                              normal: {
                                show: false,
                              },
                            },
                            color: [
                              '#2c82be',
                              '#76ddfb',
                              '#dbecf8',
                              '#2d98d8',
                              '#8cdcf8',
                              '#c9e5f6',
                              '#3dabe3',
                              '#a2ddf7',
                              '#b6e0f6',
                              '#4ebded',
                              '#61cef5',
                              '#c1e3f6',
                              '#cee7f7',
                            ],
                            data: statusSummary
                              .map(({ paymentSessionStatus, count, status }) => ({
                                value: count,
                                name: paymentSessionStatus || status
                                  .split('_')
                                  .map(x => capitalize(x))
                                  .reduce((accum, value) => `${accum} ${value}`, ''),
                              })),
                          },
                        ],
                      }}
                      notMerge
                      lazyUpdate
                    />

                  )
                  : (
                    <ReactEcharts
                      option={{
                        textStyle: {
                          fontFamily: 'Open Sans',
                        },
                        tooltip: {
                          show: false,
                          trigger: 'item',
                        },
                        title: {
                          text: 'No\nTransactions',
                          top: 'center',
                          left: 'center',
                          textStyle: {
                            fontSize: 14,
                          },
                        },
                        legend: {
                          show: false,
                          orient: 'horizontal',
                          top: 'bottom',
                          left: 'center',
                          icon: 'circle',
                        },
                        series: [
                          {
                            animation: false,
                            name: 'No Transactions',
                            type: 'pie',
                            radius: ['35%', '65%'],
                            avoidLabelOverlap: false,
                            hoverAnimation: false,
                            silent: true,
                            label: {
                              normal: {
                                show: false,
                              },
                              emphasis: {
                                show: false,
                              },
                            },
                            labelLine: {
                              normal: {
                                show: false,
                              },
                            },
                            color: ['#F1F1F1'],
                            data: [{ name: 'No Data', value: 0 }],
                          },
                        ],
                      }}
                      notMerge
                      lazyUpdate
                    />

                  )
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default TransactionsCharts;
