/* eslint-disable */
import React from 'react';

const ButtonDelete = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1515 0.969697H4.84848C2.70629 0.969697 0.969697 2.70629 0.969697 4.84848V27.1515C0.969697 29.2937 2.70629 31.0303 4.84848 31.0303H27.1515C29.2937 31.0303 31.0303 29.2937 31.0303 27.1515V4.84848C31.0303 2.70629 29.2937 0.969697 27.1515 0.969697ZM4.84848 0C2.17074 0 0 2.17074 0 4.84848V27.1515C0 29.8293 2.17074 32 4.84848 32H27.1515C29.8293 32 32 29.8293 32 27.1515V4.84848C32 2.17074 29.8293 0 27.1515 0H4.84848Z" fill="#EF5050" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 16C9 15.4477 9.48215 15 10.0769 15H21.9231C22.5178 15 23 15.4477 23 16C23 16.5523 22.5178 17 21.9231 17H10.0769C9.48215 17 9 16.5523 9 16Z" fill="#EF5050" />
    </svg>
);

export default ButtonDelete;