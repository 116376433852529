import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  TRANSFERSDETAILS,
  NOTIFICATION,
  LOADING,
} from '../actionTypes';

const fetchTransferDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_TRANSFERS}/${gid}`);

const fetchTransferTimeline = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_TRANSFERS}/${gid}/transfer-session-timeline`);

const notifyTransferDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_TRANSFERS}/${gid}/notify`);

const resendOtp = async () => axiosAuthInstance.post(`${API_END_POINTS.GET_TRANSFERS}/account-otp`);

export function* getTransferDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchTransferDetails, action.payload);
      yield put({ type: TRANSFERSDETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchTransferDetails, action);
      yield put({ type: TRANSFERSDETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: TRANSFERSDETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getTransferTimeline(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchTransferTimeline, action.payload);
      yield put({ type: TRANSFERSDETAILS.TIMELINE_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchTransferTimeline, action);
      yield put({ type: TRANSFERSDETAILS.TIMELINE_SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: TRANSFERSDETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getTransferOtp(action) {
  try {
    const { data: { entity } } = yield call(resendOtp, action.payload);
    if (entity) {
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.TRANSFERS.OTP_SUCCESS,
      });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getTransferNotifyDetails(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(notifyTransferDetails, action.payload);
      yield put({ type: TRANSFERSDETAILS.SUCCESS, payload: entity });
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.NOTIFY.SUCCESS,
      });
    } else {
      const { data: { entity } } = yield call(notifyTransferDetails, action);
      yield put({ type: TRANSFERSDETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
