import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Row,
  Col,
  Switch,
} from 'antd';

import { SpForm, SpText, SpH5 } from 'components/DesignKit';
import imageOnError from 'utils/imageOnError';
import NologoAvailable from 'components/NavIcons/NoLogoAvailable ';
import { ROLES } from 'appconstants';

type Props = {
  selectedAccount: Object,
  allSequence : Object,
};

const View = (props: Props) => {
  const {
    selectedAccount,
    allSequence
  } = props;

  const {
    name,
    contactGivenName,
    contactFamilyName,
    contactEmail,
    contactNumber,
    largeLogo,
    smallLogo,
    timezone,
    banner,
    shouldNotify,
    businessActive,
    allowCrossBorderTransfer,
    kdsFilterTimeInMinutes,
  } = selectedAccount;

  const gid = selectedAccount && selectedAccount.gid;
  const { hoursOfOperation } = selectedAccount;
  const { enableHoursOfOperation } = selectedAccount;

  const [phoneFormate, setPhoneFormate] = useState('');

  useEffect(() => {
    if (selectedAccount && selectedAccount.contactNumber) {
      const phone = selectedAccount.contactNumber;
      const cntryCode = contactNumber.substring(0, 2);
      if (phone.length === 12 && cntryCode === '+1') {
        setPhoneFormate('+# (###) ###-####');
      } else if (phone.length === 13 && cntryCode === '+9') {
        setPhoneFormate('+##-##### #####');
      } else if (phone.length === 12 && cntryCode === '+4') {
        setPhoneFormate('+##-#########');
      } else if (phone.length === 12 && cntryCode !== '+1') {
        setPhoneFormate('+##-##########');
      } else if (phone.length === 12 && cntryCode !== '+4') {
        setPhoneFormate('+##-##########');
      } else if (phone.length === 13 && cntryCode !== '+9') {
        setPhoneFormate('+##-##########');
      } else if (phone.length === 14) {
        setPhoneFormate('+###-##########');
      } else {
        setPhoneFormate('##########');
      }
    }
  }, [selectedAccount]);

  const getOperatingTime = (time) => {
    if (time !== 0) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours > 12) {
        const hr = hours - 12;
        return `${hr}:${min} PM`;
      }
      if (hours === 0) {
        return `${12}:${min} AM`;
      }
      return `${hours}:${min} AM`;
    }
    return '12:00 AM';
  };

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Account Name</span>
          </Col>
          <Col span={9}>
            <SpH5>{name}</SpH5>
          </Col>
        </Row>
        {
          selectedAccount && selectedAccount.accountType && (
            <Row type="flex" justify="start" align="middle">
              <Col span={3}>
                <span>Account Type</span>
              </Col>
              <Col span={9}>
                <SpH5>{selectedAccount.accountType}</SpH5>
              </Col>
            </Row>
          )
        }
      </SpForm>
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Primary Contact Information
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start">
          <Col span={3}>
            <span>Given Name</span>
          </Col>
          <Col span={9}>
            <SpH5>{contactGivenName}</SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={3}>
            <span>Family Name</span>
          </Col>
          <Col span={9}>
            <SpH5>{contactFamilyName}</SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={3}>
            <span>Email</span>
          </Col>
          <Col span={9}>
            <SpH5>{contactEmail}</SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={3}>
            <span>Phone</span>
          </Col>
          <Col span={6}>
            <NumberFormat
              className="numFormate"
              value={contactNumber}
              format={phoneFormate}
              mask="_"
              disabled
            />
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Branding Images
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" gutter={[32, 16]}>
          <Col>
            <div>
              <div style={{ fontSize: '12px' }}>Company Logo</div>
              <div
                style={{
                  height: '80px',
                  width: '120px',
                  padding: '5px',
                  borderRadius: '5px',
                  border: 'solid 2px #979797',
                  alignItems: 'center',
                  objectFit: 'contain',
                  verticalAlign: 'middle',
                  display: 'flex',
                }}
                className="mt-2"
              >
                {
                  largeLogo
                    ? (
                      <img
                        style={{ objectFit: 'contain', height: '100%' }}
                        src={`${largeLogo}?t=${new Date().getTime()}`}
                        onError={event => imageOnError(event)}
                        alt=""
                        width="100%"
                      />
                    )
                    : <NologoAvailable />
                }
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <div style={{ fontSize: '12px' }}>Company Icon</div>
              <div
                style={{
                  height: '80px',
                  width: '120px',
                  padding: '5px',
                  borderRadius: '5px',
                  border: 'solid 2px #979797',
                  alignItems: 'center',
                  objectFit: 'contain',
                  verticalAlign: 'middle',
                  display: 'flex',
                }}
                className="mt-2"
              >
                {
                  smallLogo
                    ? (
                      <img
                        onError={event => imageOnError(event)}
                        style={{ objectFit: 'contain', height: '100%' }}
                        src={`${smallLogo}?t=${new Date().getTime()}`}
                        alt=""
                        width="100%"
                      />
                    )
                    : <NologoAvailable />
                }
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <div style={{ fontSize: '12px' }}>Company Banner</div>
              <div
                style={{
                  height: '80px',
                  width: '120px',
                  padding: '5px',
                  borderRadius: '5px',
                  border: 'solid 2px #979797',
                  alignItems: 'center',
                  objectFit: 'contain',
                  verticalAlign: 'middle',
                  display: 'flex',
                }}
                className="mt-2"
              >
                {
                  banner
                    ? (
                      <img
                        onError={event => imageOnError(event)}
                        style={{ objectFit: 'contain', height: '100%' }}
                        src={`${banner}?t=${new Date().getTime()}`}
                        alt=""
                        width="100%"
                      />
                    )
                    : <NologoAvailable />
                }
              </div>
            </div>
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Timezone
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Timezone</span>
          </Col>
          <Col span={6}>
            <SpH5>{timezone && timezone.replace(/_/g, ' ')}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Customer Emails
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" className="my-2 mb-3">
          <Col>
            <div className="flex">
              <span>Email Customers</span>
              <Switch
                className="mx-3"
                disabled
                defaultChecked={shouldNotify}
              />{shouldNotify ? ' Successful Payments' : 'Disabled'}
            </div>
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            BUSINESS STATUS
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="space-between" className="my-2 mb-3">
          <Col>
            <Switch
              className="mx-3"
              disabled
              defaultChecked={businessActive}
            /> {businessActive ? 'Active' : 'Disabled'}
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            ALLOW CROSS BORDER TRANSFER
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="space-between" className="my-2 mb-3">
          <Col>
            <Switch
              className="mx-3"
              disabled
              defaultChecked={allowCrossBorderTransfer}
            /> {allowCrossBorderTransfer ? 'Active' : 'Disabled'}
          </Col>
        </Row>
      </SpForm>
      <Row type="flex"  justify='start'>

          <Col span={3}>
          <SpText className="text-uppercase" fontSize="20px">
          KDS FILTER TIME
          </SpText>
        </Col>
          <Col span={9} className='ml-2 mt-1'>
          <SpH5>{kdsFilterTimeInMinutes && kdsFilterTimeInMinutes}</SpH5>
          </Col>
        </Row>
  
{/* ----------------------------------------------------------------ENABLE HOURS OF OPERATION (VIEW)---------------------------------------------------------- */}
      {
        enableHoursOfOperation ? (
          <SpForm>
            {hoursOfOperation && hoursOfOperation.length !== 0 ? (
              <>
                <Row className="my-2 mb-3" type="flex" justify="start">
                  <Col>
                    <SpText className="text-uppercase" fontSize="20px">
                      OPERATING HOURS
                    </SpText>
                  </Col>
                </Row>
                <Row className="my-2 mb-3" type="flex" justify="start">
                  <Col span={3}>
                    <span className="text-muted">Days</span>
                  </Col>
                  <Col span={3}>
                    <span className="text-muted">From</span>
                  </Col>
                  <Col span={3}>
                    <span className="text-muted">To</span>
                  </Col>
                </Row>
                {hoursOfOperation.map((item) => (
                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={3}>
                      <span>{item.dayOfWeek}</span>
                    </Col>
                    <Col span={3}>
                      {getOperatingTime(item.openingTime) ? getOperatingTime(item.openingTime) : <>&#8211;</>}
                    </Col>
                    <Col span={3}>
                      {getOperatingTime(item.closingTime) ? getOperatingTime(item.closingTime) : <>&#8211;</>}
                    </Col>
                    <Col span={3}>
                      <Switch
                        className="mx-3"
                        disabled
                        defaultChecked={item.open}
                      /> Open
                    </Col>
                  </Row>
                ))}
              </>
            ) : (
              <></>
            )}
          </SpForm>) :
          (<><Row className="my-2 mb-3" type="flex" justify="start">
            <Col>
              <SpText className="text-uppercase" fontSize="20px">
                ENABLE HOURS OF OPERATION
              </SpText>
            </Col>
          </Row>
            <SpForm>
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <Switch
                    className="mx-3"
                    disabled
                    defaultChecked={enableHoursOfOperation}
                  /> Disabled
                </Col>
              </Row>
            </SpForm></>)
            }

   {/* ----------------------------------------------------------------GENERATE ORDER NO (VIEW)---------------------------------------------------------- */}
         
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Generate Order No
          </SpText>
        </Col>
      </Row>
      {allSequence && allSequence.length > 0 ? (
        <div className='d-flex ml-2'>
          <div >
            <span>
              Prefix
            </span>
            <br />
            <SpH5>{allSequence[0].prefix}</SpH5>

          </div>
          <div className='ml-2 mr-2'>
            <span>
              Date Format
            </span>
            <br />
            <SpH5>{allSequence[0].dateFormat}</SpH5>

          </div>
          <div >
            <span>
              Order sequence
            </span>
            <br />
            <SpH5>{allSequence[0].currentSequence}</SpH5>
          </div>
        </div>
      ) : <SpForm>
        <Row type="flex" justify="space-between" className="my-2 mb-3">
          <Col>
            <Switch
              className="mx-3"
              disabled
            /> Disabled
          </Col>
        </Row>
      </SpForm>
      }
         {/* ----------------------------------------------------------------ACCOUNT GID (VIEW)---------------------------------------------------------- */}

      <SpForm>
        <Row type="flex" justify="space-between" className="my-2 mb-3">
          <Col>
            &nbsp;
          </Col>
          <Col>{gid || <>&#8211;</>}</Col>
        </Row>
      </SpForm>
     
    </>
  );
};

export default React.memo(View);
