import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  Input, LabelText, SpanText, Text,
} from '../../../design';
import {
  normalTextFontSize, smallTextFontSize,
} from '../../../utils/reseponsive';

type Props = {
  label: string,
  description: string,
  control: any,
  errors: any,
  name: string,
  isRequired: boolean,
  isPreview: boolean,
  setValue: any,
  tipPercentages: Array,
  isAdaptive: boolean
}

const InputTip = (props: Props) => {
  const {
    label, description, errors, control, name, tipPercentages, isRequired, isPreview, setValue, isAdaptive,
  } = props;
  const [inputValue, setInputValue] = useState();
  const [showCustom, setShowCustom] = useState(false);
  const [selectOption1, setSelectOption1] = useState(false);
  const [selectOption2, setSelectOption2] = useState(false);
  const [selectOption3, setSelectOption3] = useState(false);
  const [showType, setShowType] = useState('');
  const [customType, setCustomType] = useState(false);

  const updateTip = (type, percent) => {
    setShowType(type);
    if (type === 'Option1') {
      setSelectOption1(true);
      setSelectOption2(false);
      setSelectOption3(false);
    } if (type === 'Option2') {
      setSelectOption2(true);
      setSelectOption1(false);
      setSelectOption3(false);
    } if (type === 'Option3') {
      setSelectOption3(true);
      setSelectOption2(false);
      setSelectOption1(false);
    } if (type === 'Custom') {
      setSelectOption1(false);
      setSelectOption2(false);
      setSelectOption3(false);
      setShowCustom(true);
    } if (type === 'Other') {
      setShowCustom(false);
    }
    setInputValue(percent);
  };

  const OnInput = event => {
    const val = event.target.value && event.target.value.replace(/[^0-9]/g, '');
    setCustomType(true);
    setInputValue(val);
  };

  useEffect(() => {
    if ((showType === 'Option1') || (showType === 'Option2') || (showType === 'Option3')) {
      setValue(`${name}`, Number(inputValue));
    } else if (customType === true) {
      console.log(inputValue, 'inputValue');
      setValue(`${name}`, Number(inputValue));
    } else {
      setValue(`${name}`, null);
    }
  }, [inputValue]);

  return (
    <>
      <div className="form-group row mb-0 no-gutters align-items-center">
        <LabelText
          fontSize={normalTextFontSize(isPreview, isAdaptive)}
          color="#0000000"
          hidden={!label}
          htmlFor="number"
          className="col-sm-5 col-form-label font-weight-bolder m-0 text-break"
        >{label}
          <SpanText
            className="pl-2"
            hidden={isRequired}
            color="rgba(0, 0, 0, 0.6)"
            fontSize={smallTextFontSize(isPreview, isAdaptive)}
          >(Optional)
          </SpanText>
        </LabelText>
      </div>
      <div className="form-group row mb-0 no-gutters align-items-center">
        {
          showCustom ? (
            <div className="col-sm-7">
              <Controller
                defaultValue={inputValue}
                as={(
                  <>
                    <div className="d-flex">
                      <Input
                        onInput={OnInput}
                        className="text-center mt-4 ml-2 rounded"
                        style={{
                          width: '100%',
                          height: '30px',
                          outline: 'none',
                          border: '1px solid #ccc',
                          fontSize: smallTextFontSize(isPreview, isAdaptive),
                        }}
                        type="text"
                      />
                      <button
                        type="button"
                        onClick={() => updateTip('Other')}
                        className="text-center mt-4 ml-2 rounded"
                        style={{
                          width: '100%',
                          height: '30px',
                          outline: 'none',
                          background: '#fff',
                          border: '1px solid #ccc',
                          fontSize: smallTextFontSize(isPreview, isAdaptive),
                        }}
                      >Other
                      </button>
                    </div>
                  </>
                )}
                name={`${name}`}
                control={control}
              />
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <Text fontSize={smallTextFontSize(isPreview, isAdaptive)} marginTop="0" marginBottom="0px" color="#ff1e1e">{message}</Text>
                )}
              />
            </div>
          ) : (
            <div className="col-sm-12">
              <Controller
                defaultValue={inputValue}
                as={(
                  <>
                    <div className="d-flex">
                      <button
                        type="button"
                        onClick={() => updateTip('Option1', `${tipPercentages[0] / 100}`)}
                        className="text-center mt-4 ml-2 rounded"
                        style={{
                          width: '100%',
                          height: '30px',
                          outline: 'none',
                          border: '1px solid #ccc',
                          background: (selectOption1 === true) ? '#ccc' : '#fff',
                          fontSize: smallTextFontSize(isPreview, isAdaptive),
                        }}
                      >{`${tipPercentages[0] / 100}%`}
                      </button>
                      <button
                        type="button"
                        onClick={() => updateTip('Option2', `${tipPercentages[1] / 100}`)}
                        className="text-center mt-4 ml-2 rounded"
                        style={{
                          width: '100%',
                          height: '30px',
                          outline: 'none',
                          border: '1px solid #ccc',
                          background: (selectOption2 === true) ? '#ccc' : '#fff',
                          fontSize: smallTextFontSize(isPreview, isAdaptive),
                        }}
                      >{`${tipPercentages[1] / 100}%`}
                      </button>
                      <button
                        type="button"
                        onClick={() => updateTip('Option3', `${tipPercentages[2] / 100}`)}
                        className="text-center mt-4 ml-2 rounded"
                        style={{
                          width: '100%',
                          height: '30px',
                          outline: 'none',
                          border: '1px solid #ccc',
                          background: (selectOption3 === true) ? '#ccc' : '#fff',
                          fontSize: smallTextFontSize(isPreview, isAdaptive),
                        }}
                      >{`${tipPercentages[2] / 100}%`}
                      </button>
                      <button
                        type="button"
                        onClick={() => updateTip('Custom')}
                        className="text-center mt-4 ml-2 rounded"
                        style={{
                          width: '100%',
                          height: '30px',
                          outline: 'none',
                          border: '1px solid #ccc',
                          background: '#fff',
                          fontSize: smallTextFontSize(isPreview, isAdaptive),
                        }}
                      >Custom
                      </button>
                    </div>
                  </>
                )}
                name={`${name}`}
                control={control}
              />
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <Text fontSize={smallTextFontSize(isPreview, isAdaptive)} marginTop="0" marginBottom="0px" color="#ff1e1e">{message}</Text>
                )}
              />
            </div>
          )
        }
      </div>
      <div hidden={!description} className="col-sm-10 px-0">
        <LabelText className="text-mute mt-1" fontSize={smallTextFontSize(isPreview, isAdaptive)}>{description}</LabelText>
      </div>
    </>
  );
};

export default InputTip;
