import { WOOCOMMERCE } from '../actionTypes';

export const fetchWoocommerce = (action) => ({
  type: WOOCOMMERCE.FETCH,
  payload: action.payload,
});

export const addWoocommerce = (action) => ({
  type: WOOCOMMERCE.ADD,
  payload: action.payload,
});

export const updateWoocommerce = (action) => ({
  type: WOOCOMMERCE.UPDATE,
  payload: action.payload,
});

export const fetchWoocommerceList = (action) => ({
  type: WOOCOMMERCE.LIST_FETCH,
  payload: action.payload,
});
