import { SETTLEMENT_STATUSES } from 'appconstants';

export default value => {
  if (value === 'PROCESSING') {
    return SETTLEMENT_STATUSES.PROCESSING;
  } if (value === 'SUCCESS') {
    return SETTLEMENT_STATUSES.SUCCESS;
  } if (value === 'FAILURE') {
    return SETTLEMENT_STATUSES.FAILURE;
  } return null;
};
