// @flow
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';

import {
  Row,
  Col,
  Input,
  Select,
  DatePicker,
} from 'antd';

import {
  SpButton,
  SpForm,
  SpText,
  SpError,
} from 'components/DesignKit';

import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';

import {
  MESSAGES,
  COUNTRY_PHONE_CODE,
} from 'appconstants';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { notificationActions } from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import ButtonDelete from 'components/NavIcons/ButtonDelete';

const { Option } = Select;
const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const dateFormat = 'MMM DD, YYYY';

type Props = {
  setNotification: Function,
  selectedAccount: Object,
};

const Firs = (props: Props) => {
  const {
    selectedAccount,
  } = props;
  const [, forceUpdate] = useState();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [cntryCode, setCntryCode] = useState(getCountryPhoneCode((selectedAccount && selectedAccount.country && selectedAccount.country.id)));
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneFormate, setPhoneFormate] = useState();
  const [utcFromDate, setUtcFromDate] = useState(null);
  const [utcToDate, setUtcToDate] = useState(null);
  const [fixErrors, setFixErrors] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [addRecipient, setAddRecipient] = useState([]);

  const simpleValidator = useRef(new SimpleReactValidator({
    autoForceUpdate: {
      forceUpdate,
    },
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));


  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhoneNumber(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    setCntryCode(value);
    const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const fromDateChange = (date) => {
    const utcDate = moment(date);
    setUtcFromDate(date);
    setFromDate(moment(utcDate).format(MOMENT_FORMAT));
  };

  const toDateChange = (date) => {
    const utcDate = moment(date);
    setUtcToDate(date);
    setToDate(moment(utcDate).format(MOMENT_FORMAT));
  };

  const addNewRecipient = (event) => {
    event.preventDefault();
    setAddRecipient([...addRecipient, '']);
  };
  const handleRemoveEmail = (i) => {
    const list = [...addRecipient];
    list.splice(i, 1);
    setAddRecipient(list);
    const hasNoEmails = (list.length === 0);
    if (hasNoEmails) {
      simpleValidator.current.purgeFields();
    }
  };

  const handleClick = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setFixErrors(true);
      return;
    }
    setDisableBtn(true);
    const params = {
      email,
      name,
      contactNumber: `${cntryCode}${phoneNumber}`,
      firstFromDate: fromDate,
      firstToDate: toDate,
      emailRecipientList: addRecipient,
    };

    try {
      await axiosAuthInstance.post(API_END_POINTS.FIRS, params);
      props.setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.PAPER_ROLL.SUCCESS,
      });
      simpleValidator.current.hideMessages();
      setUtcFromDate(null);
      setUtcToDate(null);
      setFromDate('');
      setToDate('');
      setPhoneNumber('');
      setPhoneFormate('');
      setName('');
      setEmail('');
      setAddRecipient([]);
    } catch (e) {
      props.setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.PAPER_ROLL.ERROR,
      });
    } finally {
      setFixErrors(false);
      setDisableBtn(false);
    }
  };

  return (
    <>
      <Row className="my-2">
        <Col>
          <SpText color="#7B7B7B" fontSize="18px">FIRS</SpText>
        </Col>
      </Row>
      <hr />
      {
        fixErrors && (
          <Row>
            <Col className="ml-3 mb-3">
              <SpError>
                Please fix one or more errors below.
              </SpError>
            </Col>
          </Row>
        )
      }
      <Row type="flex" justify="space-between" className="my-2">
        <Col span={18}>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>Enter &quot;from&quot; date</SpText>
              </Col>
              <Col span={18}>
                <DatePicker
                  format={dateFormat}
                  value={utcFromDate ? moment(utcFromDate, dateFormat) : null}
                  allowClear={false}
                  disabledDate={current => current && current < moment().subtract(1, 'days')}
                  onChange={fromDateChange}
                />
                <SpError>
                  {simpleValidator.current.message('from date', fromDate, 'required')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>Enter &quot;to&quot; date</SpText>
              </Col>
              <Col span={18}>
                <DatePicker
                  format={dateFormat}
                  value={utcToDate ? moment(utcToDate, dateFormat) : null}
                  allowClear={false}
                  disabledDate={current => current && current < moment().subtract(1, 'days')}
                  onChange={toDateChange}
                />
                <SpError>
                  {simpleValidator.current.message('to date', toDate, 'required')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>Contact person</SpText>
              </Col>
              <Col span={18}>
                <Input
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('Contact person', name, 'required')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>Contact No.</SpText>
              </Col>
              <Col span={18}>
                <div className="d-flex">
                  <div
                    style={{ width: '20%' }}
                    onKeyPress={e => countryCodeValidation(e)}
                  >
                    <Select
                      className="w-100"
                      showSearch
                      defaultValue={cntryCode}
                      onChange={onCountryCodeChange}
                    >
                      {COUNTRY_PHONE_CODE.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div style={{ width: '80%' }}>
                    <Input
                      className="InputnumFormate"
                      placeholder="Phone Number"
                      value={phoneFormate}
                      onChange={onPhoneNumberChange}
                      onBlur={e => {
                        if (e.currentTarget.value) {
                          setPhoneNumber(e.currentTarget.value);
                          const value = formatNumber(`${cntryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                          const formater = value.substr(value.indexOf(' ') + 1);
                          setPhoneFormate(formater);
                        }
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('phone number', phoneNumber, `phoneNumValidation:${cntryCode}${phoneNumber}`)}
                    </SpError>
                  </div>
                </div>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>Email ID</SpText>
              </Col>
              <Col span={18}>
                <Input
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('Email', email, 'required|email|checkLowerCase')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          {
            ((addRecipient.length) === 0) ? (
              <>
                <SpForm>
                  <Row type="flex" justify="start" align="top">
                    <Col span={6}>
                      <SpText>Recipient List</SpText>
                    </Col>
                    <Col span={18} style={{ cursor: 'pointer' }}>
                      <SpText
                        fontWeight="600"
                        color="#279dfe"
                        onClick={addNewRecipient}
                      >
                        + Add New Recipient
                      </SpText>
                    </Col>
                  </Row>
                </SpForm>
              </>
            ) : (
              <>
                <SpForm>
                  {addRecipient.map((item, i) => (
                    <>
                      <Row type="flex" justify="start" align="top" className="mb-3">
                        <Col span={6}>
                          <SpText>{i === 0 ? 'Recipient List' : ' '}</SpText>
                        </Col>
                        <Col span={16}>
                          <Input
                            value={item}
                            key={`Email${i}`}
                            onChange={(e) => {
                              const list = [...addRecipient];
                              list[i] = e.currentTarget.value;
                              setAddRecipient(list);
                            }}
                            placeholder="Enter recipient name"
                          />
                          <SpError className="mb-4">
                            {simpleValidator.current.message('email', item, 'required|email|checkLowerCase',
                              { messages: { email: 'The email must be valid' } })}
                          </SpError>
                        </Col>
                        <Col className="ml-3" span={1}>
                          <span onClick={() => handleRemoveEmail(i)}>
                            <ButtonDelete />
                          </span>
                        </Col>
                      </Row>
                    </>
                  ))}
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="top">
                    <Col span={6}>
                      <SpText>&nbsp;</SpText>
                    </Col>
                    <Col span={18} style={{ cursor: 'pointer' }}>
                      <SpText
                        fontWeight="600"
                        color="#279dfe"
                        onClick={addNewRecipient}
                      >
                        + Add New Recipient
                      </SpText>
                    </Col>
                  </Row>
                </SpForm>
              </>
            )
          }
        </Col>
      </Row>
      <div className="service-btn">
        <SpButton
          type="primary"
          shape="round"
          onClick={handleClick}
          disabled={disableBtn}
          style={{
            width: '339px',
            height: '40px',
            fontSize: '16px',
          }}
        >
          Submit Request
        </SpButton>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Firs);
