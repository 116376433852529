// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import getStateList from 'utils/stateList';
import {
  validators,
  countryCodeValidation,
  validationRegex,
} from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';

import {
  COUNTRY_CODES,
  COUNTRY_PHONE_CODE,
} from 'appconstants';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  country: String,
  statesInCountry: Array<Object>,
  selectedBeneficiary: Object,
  zipTag: String,
  phoneCode: String,
};

const EditBeneficiary = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    country,
    statesInCountry,
    zipTag,
    phoneCode,
    selectedBeneficiary,
  } = props;

  const [, forceUpdate] = useState();
  const [email, setEmail] = useState(selectedBeneficiary && selectedBeneficiary.email);
  const [phoneNumber, setPhone] = useState('');
  const [phoneFormat, setPhoneFormat] = useState();
  const [name, setName] = useState(selectedBeneficiary && selectedBeneficiary.name);
  const [walletAlias, setWalletAlias] = useState(selectedBeneficiary && selectedBeneficiary.beneficiaryWalletAlias);
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [countryName, setCountryName] = useState('');
  const [stateList, setSateList] = useState(statesInCountry);
  const [disableButton, setDisableButton] = useState(false);
  const [term, setTerm] = useState(selectedBeneficiary && selectedBeneficiary.paymentTermsDays);
  const postalCodeRegex = country === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.email.test(val),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useEffect(() => {
    if (country && country === 'India') {
      setCountryName('INDIA');
    } else if (country && country === 'United States of America') {
      setCountryName('USA');
    }
  }, []);

  useEffect(() => {
    const phone = selectedBeneficiary.phoneNumber ? selectedBeneficiary.phoneNumber : '';
    const phoneResult = phone ? findPhoneNumbersInText(phone) : null;
    if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
      setCountryCode(`+${phoneResult[0].number.countryCallingCode}`);
      setPhone(phoneResult[0].number.nationalNumber);
      const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
      const formater = data.substr(data.indexOf(' ') + 1);
      setPhoneFormat(formater);
    }
    if (selectedBeneficiary && selectedBeneficiary.address) {
      setStreetName(selectedBeneficiary.address && selectedBeneficiary.address.street);
      setCityName(selectedBeneficiary.address && selectedBeneficiary.address.city);
      setZipCode(selectedBeneficiary.address && selectedBeneficiary.address.postalCode);
      setStateName(selectedBeneficiary.address && selectedBeneficiary.address.state);
    }
  }, [selectedBeneficiary]);

  const updateCountry = (value) => {
    setCountryCode(value);
    setSateList(getStateList(value));
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormat(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormat(formater);
  };

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      email,
      phoneNumber,
      countryCode,
      streetName,
      cityName,
      stateName,
      zipCode,
      term,
      walletAlias,
      gid: selectedBeneficiary && selectedBeneficiary.gid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Edit</SpButton>,
      ]}
      title="Edit Beneficiary"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Enter beneficiary name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('email', email, 'required|emailId|checkLowerCase')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Wallet Alias</SpH5></label>
          <Input
            placeholder="Enter wallet alias"
            value={walletAlias}
            onChange={(e) => setWalletAlias(e.currentTarget.value)}
          />
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone Number</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                value={countryCode}
                onChange={onCountryCodeChange}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormat}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setPhone(e.currentTarget.value);
                    const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormat(formater);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('phone number', phoneNumber, `phoneNumValidation:${countryCode}${phoneNumber}`)}
              </SpError>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Street Address</SpH5></label>
          <Input
            placeholder="Street Address"
            value={streetName}
            onChange={(e) => setStreetName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('street', streetName, 'required')}
          </SpError>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName}
                onChange={(e) => setCityName(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('city', cityName, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                className="w-100"
                placeholder="State"
                showSearch
                optionFilterProp="children"
                onChange={(e) => setStateName(e)}
              >
                {
                  stateList.map(s => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-capitalize"
                    >
                      {s.name}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('state', stateName, 'required')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipTag}</SpH5></label>
              <Input
                placeholder={zipTag}
                value={zipCode}
                onChange={(e) => setZipCode(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message(zipTag, zipCode, 'required|postalcode')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Country</SpH5></label>
              <Select
                defaultValue={countryName}
                style={{ width: '100%' }}
                disabled
                onChange={updateCountry}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ALPHA2}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>Payment Term Days</SpH5></label>
              <Input
                placeholder=""
                value={term}
                onChange={(e) => setTerm(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('payment term', term, 'required|numeric')}
              </SpError>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditBeneficiary);
