import { COUNTRIES } from 'appconstants';
import getCountryAlpha2 from 'utils/getCountryAlpha2';

export default ({ refArray, countryId }) => {
  const businessInfo = refArray[0].current.getValues();
  const bankDetailsInfo = refArray[1].current.getValues();
  const businessRepresentativesInfo = refArray.slice(2).map(ref => {
    const info = ref.current.getValues();
    return {
      ssn: info.ssn,
      pan: info.pan,
      givenName: info.givenName,
      familyName: info.familyName,
      title: info.title,
      phone: `${info.countryCode}${info.phoneNumber}`,
      email: info.contactEmail,
      address: {
        countryCode: getCountryAlpha2(countryId),
        city: info.city,
        postalCode: info.zipCode,
        state: info.state,
        street: info.legalAddress,
      },
      kycAddressProofUrl: info.bankStatementUrl,
      businessRepPanUrl: info.businessRepPanUrl,
      dateofbirth: info.dob,
      hasControl: info.hasControl,
      ownerShipPercentage: info.ownerShipPercentage ? info.ownerShipPercentage.replace('%', '') : null,
      governmentIdUrl: info.driverLicenseUrl || null,
    };
  });

  let payload;

  switch (countryId) {
    case COUNTRIES.USA.id:
      payload = {
        usBusiness: {
          name: businessInfo.name,
          url: businessInfo.businessUrl,
          statementDescriptor: businessInfo.statementDescriptor,
          categoryId: businessInfo.businessCategory,
          address: {
            countryCode: getCountryAlpha2(countryId),
            city: businessInfo.city,
            postalCode: businessInfo.zipCode,
            state: businessInfo.state,
            street: businessInfo.streetAddress,
          },
          type: businessInfo.businessType,
          ein: businessInfo.employerIdNumber,
          bankName: bankDetailsInfo.bankName,
          bankAccountType: bankDetailsInfo.accountType,
          bankRoutingNumber: bankDetailsInfo.bankRoutingNumber,
          bankAccountNumber: bankDetailsInfo.accountNumber,
          bankBeneficiaryName: bankDetailsInfo.accountHolderName,
          businessPhoneNumber: `${businessInfo.countryCode}${businessInfo.businessPhoneNumber}`,
          averageMonthlyVolume: Math.trunc(Number(bankDetailsInfo.averageMonthlyVolume) * 100),
          averageTicketAmount: Math.trunc(Number(bankDetailsInfo.averageTicketAmount) * 100),
          whenIsCardCharged: bankDetailsInfo.whenIsCardCharged,
          servicesDeliveredIn: bankDetailsInfo.servicesDeliveredIn,
          companyStartDate: businessInfo.companyStartDate,
          refundPolicy: bankDetailsInfo.refundPolicy,
          seasonalBusiness: bankDetailsInfo.seasonalBusiness,
          modeOfTransactionOnline: bankDetailsInfo.modeOfTransactionPerson === 100
            ? '0' : bankDetailsInfo.modeOfTransactionOnline
              ? (bankDetailsInfo.modeOfTransactionOnline).toString() : '',
          modeOfTransactionPerson: bankDetailsInfo.modeOfTransactionOnline === 100
            ? '0' : bankDetailsInfo.modeOfTransactionPerson
              ? (bankDetailsInfo.modeOfTransactionPerson).toString() : '',
          isEbt: bankDetailsInfo.EBTCheckBox,
          isTaxExemptOrganisation: businessInfo.isTaxExemptOrganisation,
          requestedServiceDate: bankDetailsInfo.requestedServiceDate || null,
          isServiceDateFlexible: bankDetailsInfo.isServiceDateFlexible || null,
          voidCheckUrl: bankDetailsInfo.voidCheckUrl || null,
        },
        usBusinessReps: businessRepresentativesInfo,
      };
      break;
    case COUNTRIES.INDIA.id:
      // eslint-disable-next-line no-case-declarations
      const pin = {};
      businessInfo.cin = businessInfo.cin !== '' ? businessInfo.cin : '';
      if (businessInfo.isLLP) {
        pin.llpin = businessInfo.cin;
      } else {
        pin.cin = businessInfo.cin;
      }

      payload = {
        inBusiness: {
          name: businessInfo.name,
          url: businessInfo.businessUrl,
          statementDescriptor: businessInfo.statementDescriptor,
          categoryId: businessInfo.businessCategory,
          address: {
            countryCode: getCountryAlpha2(countryId),
            city: businessInfo.city,
            postalCode: businessInfo.zipCode,
            state: businessInfo.state,
            street: businessInfo.streetAddress,
          },
          businessPan: businessInfo.businessPan,
          type: businessInfo.businessType,
          tan: businessInfo.tan,
          gst: businessInfo.gst,
          kycCompanyPanUrl: businessInfo.panUrl,
          bankIfscCode: bankDetailsInfo.ifscNumber,
          bankName: bankDetailsInfo.bankName,
          bankRoutingNumber: bankDetailsInfo.bankRoutingNumber,
          bankAccountNumber: bankDetailsInfo.accountNumber,
          bankBeneficiaryName: bankDetailsInfo.accountHolderName,
          kycBankStatementUrl: bankDetailsInfo.bankStatementUrl,
          kycRegistrationProofUrl: businessInfo.registrationProofUrl,
          accountType: businessInfo.accountType,
          annualTurnOver: Number(businessInfo.annualTurnOver),
          annualCardVolume: Number(businessInfo.annualCardVolume),
          annualUpiVolume: Number(businessInfo.annualUpiVolume),
          kycStoreFront: businessInfo.frontUrl,
          kycStoreInside: businessInfo.insideUrl,
          currentAddressProofUrl: businessInfo.currentAddressProofUrl,
          boardResolutionUrl: businessInfo.boardResolutionUrl,
          gstCertificateUrl: businessInfo.gstCertificateUrl,
          eightyGAndTwelveACertificate: businessInfo.eightyGAndTwelveACertificate,
          ...pin,
        },
        inBusinessReps: businessRepresentativesInfo,
      };
      break;
    default:
      break;
  }

  return payload;
};
