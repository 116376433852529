import React from 'react';
import { SpanText } from '../../../design';
import { logoWidth, titleFontSize } from '../../../utils/reseponsive';

type Props = {
  name: string,
  logo: string,
  isPreview: boolean,
  isAdaptive: boolean,
  primaryColor: String,
}
const Account = (props: Props) => {
  const {
    name, logo, isPreview, isAdaptive, primaryColor,
  } = props;
  return (
    <div className="my-1 d-flex">
      <div
        style={{
          height: '9px', width: '9px', marginLeft: '-1.0rem', backgroundColor: (primaryColor || '#40A9FF'), marginTop: '0.8rem',
        }}
        className="d-flex align-items-center"
      >&nbsp;
      </div>
      <div>
        <span style={{ marginLeft: '0.5rem' }} className="d-flex align-items-center col-12 p-0">
          <span hidden={!logo} className="pr-2">
            <img src={logo} style={{ maxWidth: logoWidth(13, isPreview, isAdaptive), width: 'fit-content' }} alt="" />
          </span>
          <SpanText className="pr-2 text-break" fontWeight={600} fontSize={titleFontSize(isPreview, isAdaptive)}>
            {name}
          </SpanText>
        </span>
      </div>
    </div>
  );
};

export default Account;
