import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  axiosNoAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  PAYMENTPAGESDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';


const fetchPaymentPagesDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.PAYMENTPAGES_API}/${gid}`);

const DeactivePaymentPage = async gid => axiosAuthInstance.patch(`${API_END_POINTS.PAYMENTPAGES_API}/${gid}/deactivate`);

const patchPaymentPageEntries = async (gid, fieldInfo) => axiosAuthInstance.patch(`${API_END_POINTS.PAYMENTPAGES_API}/${gid}`, fieldInfo);

// eslint-disable-next-line arrow-body-style
const patchPaymentPage = async (gid, params) => {
  return Promise.all(
    params.map(param => axiosAuthInstance.patch(`/payment-page-meta-field/${param.gid}`, param)),
  );
};

const soldOutSelectedPaymentPage = async gid => axiosAuthInstance.patch(`${API_END_POINTS.PAYMENTPAGES_API}/${gid}/sold-out`);

const fetchPaymentPageSlug = async gid => axiosAuthInstance.get(`${API_END_POINTS.PAYMENTPAGE_SLUG_API}/${gid}`);
const resumeSelectedPaymentPage = async gid => axiosAuthInstance.patch(`${API_END_POINTS.PAYMENTPAGES_API}/${gid}/resume-sale`);

const fetchPayments = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const queryParam = `&paymentPageMetaGid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYMENTS}${query}${queryParam}${sorting}`);
};

const fetchPaymentsOrders = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const queryParam = `&paymentPageMetaGid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYMENT_ORDERS}${query}${queryParam}${sorting}`);
};

const fetchOfflinePayments = async (payload) => {
  const defaultPage = 1;
  const defaultSize = 10;
  let query;
  if (payload.currentPage) {
    query = `&page=${payload.currentPage}&size=${defaultSize}`;
  } else {
    query = `&page=${defaultPage}&size=${defaultSize}`;
  }
  const queryParam = `?paymentPage.paymentPageMetaGid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  const offline = '&paymentPage.offlinePaymentType.NULL=false';
  return axiosAuthInstance.get(`${API_END_POINTS.PAGE_RECEIPTS}${queryParam}${offline}${sorting}${query}`);
};

const fetchPaymentsOrderDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_PAYMENT_ORDERS}/${gid}`);

const fetchPaymentsOrderTimeline = async gid => (
  axiosAuthInstance.get(`${API_END_POINTS.GET_PAYMENT_ORDERS}/${gid}/payment-page-order-timeline`)
);

const notifyPaymentPage = async payload => axiosAuthInstance.post(`${API_END_POINTS.PAYMENTPAGES_API}/notify`, payload);

const fetchPaymentsOrderSplitUp = async gid => axiosNoAuthInstance.get(`${API_END_POINTS.PAGE_RECEIPTS}/${gid}`);

const patchOrder = async (payload) => axiosAuthInstance.patch(`${API_END_POINTS.GET_PAYMENT_ORDERS}/${payload.gid}`, payload.params);

const patchCashPayment = async (payload) => axiosAuthInstance.post(`${API_END_POINTS.CASH_PAYMENT}/${payload.gid}/offline-payment`, payload.params);

const deleteOfflinePayments = async ({ offlineGid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_OFFLINE}/${offlineGid}`);

const fetchPaymentReports = async (payload) => {
  const defaultPage = 1;
  const defaultSize = 10;
  let query;
  if (payload.currentPage) {
    query = `&page=${payload.currentPage}&size=${defaultSize}`;
  } else {
    query = `&page=${defaultPage}&size=${defaultSize}`;
  }
  const queryParam = `?paymentPageMetaGid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_REPORTS}${queryParam}${sorting}${query}`);
};
const fetchPaymentPagesEntries = async (payload) => {
  const defaultPage = 1;
  const defaultSize = 10;

  // Determine the page and size query parameters
  const query = payload.currentPage
    ? `&page=${payload.currentPage}&size=${defaultSize}`
    : `&page=${defaultPage}&size=${defaultSize}`;

  // Construct queryParam based on the presence of 'paymentpagemeta' in gid
  const queryParam = payload.gid.includes('paymentpagemeta')
    ? `?paymentPageMetaGid.EQ=${payload.gid}`
    : `/${payload.gid}`;

  // Construct the sorting parameter
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';

  // Construct the full URL based on the condition
  const baseUrl = `${API_END_POINTS.GET_OFFLINE}${queryParam}`;
  const url = payload.gid.includes('paymentpagemeta')
    ? `${baseUrl}${sorting}${query}`
    : baseUrl;

  // Make the API request
  return axiosAuthInstance.get(url);
};


export function* getPaymentPagesDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchPaymentPagesDetails, action.payload);
    yield put({ type: PAYMENTPAGESDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTPAGESDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getPaymentPagesList(action) {
  try {
    const { data: { entity } } = yield call(fetchPayments, action.payload);
    yield put({ type: PAYMENTPAGESDETAILS.PAYMENTS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTPAGESDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getOfflinePaymentsList(action) {
  try {
    const { data: { entity } } = yield call(fetchOfflinePayments, action.payload);
    yield put({ type: PAYMENTPAGESDETAILS.SUCCESS_OFFLINE_PAYMENTS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTPAGESDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getPaymentOrdersList(action) {
  try {
    const { data: { entity } } = yield call(fetchPaymentsOrders, action.payload);
    yield put({ type: PAYMENTPAGESDETAILS.ORDERS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTPAGESDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getPaymentOrderDetails(action) {
  try {
    const { data: { entity } } = yield call(fetchPaymentsOrderDetails, action.payload);
    yield put({ type: PAYMENTPAGESDETAILS.ORDER_SUCCESS_BY_ID, payload: entity });
    if (entity && entity.paymentSession.receiptNumber) {
      const { receiptNumber } = entity.paymentSession;
      const { data: { entity: { splitUp } } } = yield call(fetchPaymentsOrderSplitUp, receiptNumber);
      yield put({ type: PAYMENTPAGESDETAILS.SPLIT_UP_SUCCESS, payload: splitUp });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTPAGESDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getPaymentOrderTimeline(action) {
  try {
    const { data: { entity } } = yield call(fetchPaymentsOrderTimeline, action.payload);
    yield put({ type: PAYMENTPAGESDETAILS.SUCCESS_TIMELINE, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTPAGESDETAILS.FAILED, payload: e.response.data });
  }
}

export function* updateOrderStatus(action) {
  try {
    yield call(patchOrder, action.payload);
    const { data: { entity } } = yield call(fetchPaymentsOrderDetails, action.payload.gid);
    yield put({ type: PAYMENTPAGESDETAILS.ORDER_SUCCESS_BY_ID, payload: entity });
    const { data } = yield call(fetchPaymentsOrderTimeline, action.payload.gid);
    yield put({ type: PAYMENTPAGESDETAILS.SUCCESS_TIMELINE, payload: data.entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTPAGESDETAILS.FAILED, payload: e.response.data });
  }
}

export function* updateCashPayment(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCashPayment, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Payment updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getPaymentPageSlug(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchPaymentPageSlug, action.payload);
    yield put({ type: PAYMENTPAGESDETAILS.SLUG_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTPAGESDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* deactivePaymentpage(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(DeactivePaymentPage, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGESDETAILS.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getNotifyPaymentPage(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(notifyPaymentPage, action.payload);
    yield put({ type: LOADING.VALIDATED });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.NOTIFY.SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updatePaymentPages(action) {
  const {
    gid,
    params,
    fieldInfo,
    isPageDataUpdated,
  } = action.payload;
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    if (isPageDataUpdated) {
      yield call(patchPaymentPageEntries, gid, fieldInfo);
    }
    if (params.length > 0) {
      yield call(patchPaymentPage, gid, params);
    }
    const { data: { entity } } = yield call(fetchPaymentPagesDetails, action.payload.gid);
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: PAYMENTPAGESDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGESDETAILS.UPDATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* soldOutPaymentPage(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(soldOutSelectedPaymentPage, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGESDETAILS.SOLDOUT_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* resumeSalePaymentPage(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(resumeSelectedPaymentPage, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGESDETAILS.RESUME_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteOfflinePayment(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteOfflinePayments, action.payload);
    const { data: { entity } } = yield call(fetchOfflinePayments, action.payload);
    yield put({ type: PAYMENTPAGESDETAILS.SUCCESS_OFFLINE_PAYMENTS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGESDETAILS.OFFLINE_DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getPaymentPageReports(action) {
  try {
    const { data: { entity } } = yield call(fetchPaymentReports, action.payload);
    yield put({ type: PAYMENTPAGESDETAILS.SUCCESS_PAYMENTS_REPORT, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTPAGESDETAILS.FAILED, payload: e.response.data });
  }
}
export function* getPaymentPagesEntries(action) {
  try {
    const { data: { entity } } = yield call(fetchPaymentPagesEntries, action.payload);
    yield put({ type: PAYMENTPAGESDETAILS.SUCCESS_PAYMENTS_PAGE_ENTRIES, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTPAGESDETAILS.FAILED, payload: e.response.data });
  }
}
