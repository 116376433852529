// @flow
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Button,
  Icon,
  Alert,
} from 'antd';
import {
  SpFormText,
} from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import type { ContextRouter } from 'react-router-dom';

import { PAGES } from 'appconstants';
import { validators } from 'utils/validationMessages';
import {
  axiosLoginInstance,
  API_END_POINTS,
} from 'api';

import swireLogo from 'imgs/swirepay.png';
import styled from 'styled-components';
import account from 'imgs/account.svg';

// $FlowFixMe
const Wrapper = styled.div`
  background-image: url(${account});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
`;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom: '24px',
  alignItems: 'center',
  width: '511px',
  minHeight: '450px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

type Props = ContextRouter & {
  history: {
    replace: Function,
  },
  isMobileView: Boolean,
};

const ForgotPassword = (props: Props) => {
  const {
    isMobileView,
  } = props;
  const [, forceUpdate] = useState();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState('');

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setLoading(true);

    try {
      const { data } = await axiosLoginInstance.post(API_END_POINTS.FORGOT_PASSWORD, {
        email,
      });
      setSuccess(true);
      setErrorDisplay('');
      sessionStorage.setItem('email', email);
      if ((data && data.message) === 'FORCE_CHANGE_PASSWORD') {
        props.history.replace({
          pathname: PAGES.NEW_REGISTER,
          search: `?email=${email.trim()}`,
        });
      } else {
        props.history.replace(PAGES.RESET_PASSWORD);
      }
    } catch (e) {
      setErrorDisplay(e.response.data && e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      <Wrapper>
        <FormWrapper
          onSubmit={handleSubmit}
          style={{
            width: isMobileView ? '900px' : '',
            height: isMobileView ? '900px' : '',
            paddingTop: isMobileView ? '0px' : '',
            paddingBottom: isMobileView ? '0px' : '',
          }}
        >
          <div style={{ maxWidth: isMobileView ? '800px' : '350px' }}>
            <div className="d-flex justify-content-center align-items-center">
              <img src={swireLogo} alt="home-logo" className="mb-3" style={{ width: isMobileView ? '420px' : '270px', margin: '32px 0px' }} />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <SpFormText fontSize={isMobileView ? '26px' : '18px'}>Reset Your Password</SpFormText>
            </div>
            <div className="LOGIN">
              <div className="mb-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Email</label>
                </div>
                <Input
                  style={{ width: isMobileView ? '600px' : '', height: isMobileView ? '50px' : '' }}
                  placeholder="Your Email"
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  value={email}
                  onBlur={() => setEmail(email.trim())}
                  onChange={(e) => setEmail(e.currentTarget.value && e.currentTarget.value.toLowerCase())}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('email', email, 'required|email',
                    { messages: { required: validators.forgotPassword.email, email: validators.forgotPassword.validEmail } })}
                </div>
                {
                  errorDisplay && (
                    <div className="text-danger">
                      {errorDisplay}
                    </div>
                  )
                }
                <div
                  className="mt-1 mb-1"
                  style={{ fontSize: isMobileView ? '18px' : '12px' }}
                >
                  Please provide your registered email id, verification code will be sent to it.
                </div>
              </div>

              {
                success && (
                  <>
                    <Alert
                      message={validators.forgotPassword.codeMsg}
                      type="success"
                      className="mb-2"
                    />

                    <div className="d-flex justify-content-center">
                      <Button
                        type="link"
                        block
                        onClick={() => props.history.replace(PAGES.RESET_PASSWORD)}
                        style={{ fontSize: isMobileView ? '18px' : '' }}
                      >
                        Reset Password Here
                      </Button>
                    </div>
                  </>
                )
              }
              <div className="d-flex justify-content-center mt-3">
                <Button
                  type="primary"
                  shape="round"
                  className="w-50"
                  htmlType="submit"
                  loading={loading}
                  onClick={handleSubmit}
                  style={{ fontSize: isMobileView ? '18px' : '' }}
                >
                  Submit
                </Button>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <Button
                  type="link"
                  block
                  onClick={() => props.history.replace(PAGES.LOGIN)}
                  style={{ fontSize: isMobileView ? '18px' : '' }}
                >
                  Go to Homepage
                </Button>
              </div>
            </div>
          </div>
        </FormWrapper>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobileView: state.mobileView.isMobileView,
});

export default connect(
  mapStateToProps,
  null,
)(ForgotPassword);
