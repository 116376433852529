import { GETSHOPDETAILS } from '../actionTypes';

const initialState = {
  content: [],
  orderDetails: [],
  shopsList: [],
  fullFillDetails: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETSHOPDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GETSHOPDETAILS.ORDER_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
      };
    case GETSHOPDETAILS.SHOP_SUCCESS:
      return {
        ...state,
        shopsList: action.payload,
      };
    case GETSHOPDETAILS.FULLFILL_SUCCESS:
      return {
        ...state,
        fullFillDetails: action.payload,
      };
    default:
      return state;
  }
};
