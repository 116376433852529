// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpButton, SpError,
} from 'components/DesignKit';
import formatAmount from 'utils/formatAmount';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  loadedAmount: Number,
  displayAmount: boolean,
  bankAccountType: String,
};

const ConfirmLoad = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    loadedAmount,
    displayAmount,
    bankAccountType,
  } = props;

  const [confirmationCode, setConfirmationCode] = useState('');
  const currencyType = selectedAccount && selectedAccount.currency;
  const { prefix } = selectedAccount && selectedAccount.currency;

  const [amount, setAmount] = useState(displayAmount ? formatAmount(loadedAmount / 100, currencyType) : formatAmount(loadedAmount, currencyType));

  const [, forceUpdate] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator({}));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);


  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      amount,
      confirmationCode,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Load {prefix} {amount}</SpButton>,
      ]}
      title="Confirm Load"
    >
      <div className="px-4 mt-2">
        <div className={bankAccountType === 'ZELLE' ? 'mb-3' : ''}>
          <label htmlFor=""><SpH5>Amount</SpH5></label>
          <Input
            value={amount}
            prefix={prefix}
            placeholder="0.00"
            onChange={(e) => {
              const regex = /^\d*\.?\d*$/;
              const { value } = e.currentTarget;
              if (regex.test(value) || value === '') {
                setAmount(e.currentTarget.value);
              } else if (!regex.test(value)) {
                setAmount(0.00);
              }
            }}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
            onBlur={e => {
              if (e.currentTarget.value) {
                const value = formatAmount(e.currentTarget.value, currencyType);
                setAmount(value);
              }
            }}
            readOnly
          />
          <SpError>
            {simpleValidator.current.message('amount', amount, 'required|amount')}
          </SpError>
        </div>
        {
          bankAccountType === 'ZELLE' && (
          <div>
            <label htmlFor=""><SpH5>Confirmation Code</SpH5></label>
            <Input
              placeholder="Enter code"
              onChange={(e) => setConfirmationCode(e.currentTarget.value)}
              value={confirmationCode}
            />
            <SpError>
              {simpleValidator.current.message('Confirmation code', confirmationCode, 'required')}
            </SpError>
          </div>
          )
        }

      </div>

    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(ConfirmLoad);
