import { CUSTOMER_SUPPORT } from '../actionTypes';

const initialState = {
  content: [],
  shops: [],
  support: {},
  supportTypes: {},
  quickSupport: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_SUPPORT.SHOPS_SUCCESS:
      return {
        ...state,
        shops: action.payload.content,
      };
    case CUSTOMER_SUPPORT.SUPPORTS_SUCCESS:
      return {
        ...state,
        support: action.payload,
      };
    case CUSTOMER_SUPPORT.SUPPORTS_FILTER_SUCCESS:
      return {
        ...state,
        supportTypes: action.payload,
      };
    case CUSTOMER_SUPPORT.QUICKSUPPORT_SUCCESS:
      return {
        ...state,
        quickSupport: action.payload,
      };
    default:
      return state;
  }
};
