// @flow
import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import Invoices from './components';
import InvoiceDetails from './components/InvoicesDetails';
import NewInvoice from './components/NewInvoice';
import Products from '../Products';

const Invoice = () => (
  <Switch>
    <Route path="/invoices" component={Invoices} />
    <Route path="/invoices/:gid" component={InvoiceDetails} />
    <Route path="/invoices" component={Products} />
    <Route path="/invoices/add/new" component={NewInvoice} />
  </Switch>
);
export default Invoice;
