// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import { validators } from 'utils/validationMessages';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
};

const AddAcquirer = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [isTest, setTest] = useState(false);
  const [isOnline, setOnline] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const nameRegex = /^[ ]*([a-zA-Z'-.]{1,}( [a-zA-z'-. ]{1,})?)$/;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      name: {
        message: validators.integrators.validName,
        rule: val => nameRegex.test(val),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      type,
      isTest,
      isOnline,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Acquirer"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required|name')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Type</SpH5></label>
          <Select
            className="w-100"
            defaultValue="Select Type"
            onChange={(e) => setType(e)}
          >
            <Option key={1} value="Gateway">Gateway</Option>
            <Option key={2} value="Bank">Bank</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('Type', type, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <Switch
            className="mr-2"
            checked={isTest}
            onChange={() => setTest(!isTest)}
          />
          <SpH5>Is Test</SpH5>
        </div>
        <div>
          <Switch
            className="mr-2"
            checked={isOnline}
            onChange={() => setOnline(!isOnline)}
          />
          <SpH5>Is Online</SpH5>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddAcquirer);
