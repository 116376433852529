import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Row,
  Col,
  Empty,
} from 'antd';
import styled from 'styled-components';
import {
  SpText,
} from 'components/DesignKit';

import {
  orderReceiptActions,
  notificationActions,
} from 'store/actions';
import Loading from 'components/Loading';
import WhiteLogo from 'imgs/swirepay-logowhite.svg';
import CloverHeader from 'imgs/cloverHeader.png';
import Dollorimg from 'imgs/dollorimg.svg';
import { Hr } from 'design';
import { BrandLogo } from 'helper';

// $FlowFixMe
const Wrapper = styled.div`
      background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
      width: 100%;
      min-height: 100vh;
      min-height: 100vh;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    `;

// $FlowFixMe
const PosWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  width: '395px',
  minHeight: '370px',
  marginBottom: '24px',
  boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
  backgroundColor: '#FFFFFF',
  fontFamily: 'Roboto',
  fontStyle: 'none',
});

// $FlowFixMe
const PosHeadWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  width: '395px',
  minHeight: '240px',
  backgroundColor: '#242F40',
});

// $FlowFixMe
// const FormCircle = styled.div`
//     width: 80px;
//     height: 80px;
//     background: #DDDFFF;
//     border-radius: 50%;
//     border: 3px solid #3C40A3;
//     margin-top: 20px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   `;


  type Props = {
    fetchOrderReceipt: Function,
    loading: boolean,
    orderReceipt: Object,
    history: {
      replace: Function,
    },
  };

const OrderReceipt = (props: Props) => {
  const {
    fetchOrderReceipt,
    orderReceipt,
    loading,
  } = props;

  const { search } = useLocation();
  const [isIframed, setIsIframed] = useState(true);

  const orderGid = new URLSearchParams(search).get('orderGid');

  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  const redirectUrl = <a target="_blank" rel="noopener noreferrer" href={window.location.href}>See detailed receipt</a>;

  useEffect(() => {
    setIsIframed(window.self === window.top);
  }, []);

  useEffect(() => {
    if (orderGid) { fetchOrderReceipt(orderGid); }
  }, []);


  const getDiscountAmount = (info) => {
    let amount;
    if (info) {
      const amnt = Math.abs(info);
      amount = (amnt / 100).toFixed(2);
    }
    return `-$${amount}`;
  };


  const getAmountDisplay = (amt) => {
    if (amt) {
      const divided = (amt / 100).toFixed(2);
      const integerPart = Math.floor(divided);
      return integerPart;
    } return 0;
  };

  const getDecimalDisplay = (amt) => {
    if (amt === 0) {
      return '00';
    }
    if (amt) {
      const divided = (amt / 100).toFixed(2);
      const decimalPart = divided.toString().split('.')[1];
      return decimalPart;
    }
    return '00';
  };


  const openMap = (mapInfo) => {
    const address = `${mapInfo && mapInfo.street}, ${mapInfo && mapInfo.city}, ${mapInfo && mapInfo.state} ${mapInfo && mapInfo.postalCode}`;
    const mapsource = 'http://maps.google.com/maps?q=';
    const mapURL = `${mapsource}${address}`;
    window.open(mapURL, '_blank');
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {
          isIframed ? (
            <>
              {(orderReceipt && !(Object.keys(orderReceipt).length === 0)) ? (
                <>
                  <Wrapper>
                    <PosHeadWrapper>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '10px',
                          paddingBotom: '10px',
                        }}
                      >
                        <>
                          {
                            orderReceipt.account && orderReceipt.account.largeLogo ? (
                              <img
                                style={{
                                  height: '60px',
                                }}
                                src={(orderReceipt.account && orderReceipt.account.largeLogo)}
                                alt=""
                              />
                            ) : (
                              <img
                                style={{ height: '40px' }}
                                src={CloverHeader}
                                alt=""
                              />
                            )
                          }
                        </>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '5px',
                        }}
                      >
                        <img src={WhiteLogo} alt="" />
                      </div>
                      {
                        (orderReceipt.account && orderReceipt.account.name) ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: '10px',
                              cursor: 'pointer',
                            }}
                          >
                            <SpText
                              fontSize="16px"
                              fontWeight="400"
                              color="#FFFFFF"
                            >
                              {(orderReceipt.account.name) || <>&#8211;</>}
                            </SpText>
                          </div>
                        ) : null
                      }
                      {
                        (orderReceipt.account && orderReceipt.account.address && orderReceipt.account.address.street) ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: '10px',
                              cursor: 'pointer',
                            }}
                            onClick={() => openMap(orderReceipt.account.address)}
                          >
                            <SpText
                              fontSize="16px"
                              fontWeight="400"
                              color="#3A81ED"
                              style={{
                                textDecorationLine: 'underline',
                              }}
                            >
                              {(orderReceipt.account.address && orderReceipt.account.address.street) || <>&#8211;</>}
                            </SpText>
                          </div>
                        ) : null
                      }
                      {
                        (orderReceipt.account && orderReceipt.account.address) ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            onClick={() => openMap(orderReceipt.account.address)}
                          >
                            <SpText
                              fontSize="16px"
                              fontWeight="400"
                              color="#3A81ED"
                              style={{
                                textDecorationLine: 'underline',
                              }}
                            >
                              {
                                `${orderReceipt.account.address && orderReceipt.account.address.city
                                }, ${orderReceipt.account.address && orderReceipt.account.address.state
                                }
                          ${orderReceipt.account.address && orderReceipt.account.address.postalCode} `
                              }
                            </SpText>
                          </div>
                        ) : null
                      }

                    </PosHeadWrapper>
                    <PosWrapper>
                      {
                        orderReceipt.inventoryOrder && orderReceipt.inventoryOrder.orderNumber && (
                          <div
                            style={{
                              justifyContent: 'center',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <SpText fontSize="20px" fontWeight="400" color="#000000">
                              Order# &nbsp;
                            </SpText>
                            <SpText fontSize="20px" fontWeight="700" color="#000000">
                              {
                                orderReceipt.inventoryOrder && orderReceipt.inventoryOrder.orderNumber
                              }
                            </SpText>
                          </div>
                        )
                      }
                      {
                        orderReceipt.inventoryOrder && orderReceipt.inventoryOrder.inventoryOrderType && (
                          <div
                            style={{
                              justifyContent: 'center',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <SpText fontSize="36px" fontWeight="400" color="#4C51C2">
                              {
                                (orderReceipt.inventoryOrder && orderReceipt.inventoryOrder.inventoryOrderType
                                  ? (orderReceipt.inventoryOrder.inventoryOrderType && orderReceipt.inventoryOrder.inventoryOrderType.label)
                                  : <>&#8211;</>
                                )
                              }
                            </SpText>
                          </div>
                        )
                      }

                      {
                        orderReceipt.inventoryOrder && orderReceipt.inventoryOrder.inventoryOrderLineItems && (
                          orderReceipt.inventoryOrder.inventoryOrderLineItems.map((data, id) => (
                            <>
                              <div key={id}>
                                {
                                  data && data.itemData && data.itemData.name !== 'Item Tax' ? (
                                    <Row type="flex" className="mt-3" justify="space-between">
                                      <Col>
                                        <SpText
                                          fontSize="16px"
                                          fontWeight="500"
                                          color="#000000"
                                        >
                                          {
                                            (data && data.quantity) ? `${data && data.quantity} ` : ''
                                          }
                                          {
                                            (data && data.itemData && data.itemData.name)
                                              ? (data && data.itemData && data.itemData.name)
                                              : (data && data.itemData && data.itemData.alternateName)
                                          }
                                        </SpText>
                                      </Col>
                                      <Col>
                                        <SpText
                                          fontSize="16px"
                                          fontWeight="500"
                                          color="#000000"
                                        >
                                          {
                                            (data && data.amount && data.quantity) ? (
                                              `$${(((data && data.amount) / 100) * data.quantity)
                                                .toFixed(2)
                                              } `
                                            ) : <>&#8211;</>
                                          }
                                        </SpText>
                                      </Col>
                                    </Row>
                                  ) : data && data.itemName && data.itemName !== 'Item Tax' ? (
                                    <Row type="flex" className="mt-3" justify="space-between">
                                      <Col>
                                        <SpText
                                          fontSize="16px"
                                          fontWeight="500"
                                          color="#000000"
                                        >
                                          {
                                            (data && data.quantity) ? `${data && data.quantity} ` : ''
                                          }
                                          {
                                            (data && data.itemName)
                                              ? (data && data.itemName)
                                              : ''
                                          }
                                        </SpText>
                                      </Col>
                                      <Col>
                                        <SpText
                                          fontSize="16px"
                                          fontWeight="500"
                                          color="#000000"
                                        >
                                          {
                                            (data && data.amount && data.quantity) ? (
                                              `$${(((data && data.amount) / 100) * data.quantity)
                                                .toFixed(2)
                                              } `
                                            ) : <>&#8211;</>
                                          }
                                        </SpText>
                                      </Col>
                                    </Row>
                                  ) : null
                                }
                                {
                                  (data.modifierData && data.modifierData.length !== 0) ? data.modifierData.map((info, idx) => (
                                    <>
                                      <div key={idx}>
                                        <Row type="flex" className="mt-1" justify="space-between">
                                          <Col className="ml-2">
                                            <SpText
                                              fontSize="16px"
                                              fontWeight="400"
                                              color="#6E6E6E"
                                            >
                                              {
                                                (info && info.name)
                                                  ? (info && info.name)
                                                  : (info && info.alternateName)
                                              }
                                            </SpText>
                                          </Col>
                                          <Col>
                                            <SpText
                                              fontSize="16px"
                                              fontWeight="400"
                                              color="#6E6E6E"
                                            >
                                              {
                                                (info && info.price && data.quantity) ? (
                                                  `$${(((info && info.price) / 100) * data.quantity)
                                                    .toFixed(2)
                                                  } `
                                                ) : (info && info.price) ? (
                                                  `$${(((info && info.price) / 100))
                                                    .toFixed(2)
                                                  } `
                                                ) : <>&#8211;</>
                                              }
                                            </SpText>
                                          </Col>
                                        </Row>
                                      </div>
                                    </>
                                  )) : (data.lineItemModifiers && data.lineItemModifiers.length !== 0) ? data.lineItemModifiers.map((info, idx) => (
                                    <>
                                      <div key={idx}>
                                        <Row type="flex" className="mt-1" justify="space-between">
                                          <Col className="ml-2">
                                            <SpText
                                              fontSize="16px"
                                              fontWeight="400"
                                              color="#6E6E6E"
                                            >
                                              {
                                                (info && info.modifierName)
                                                  ? (info && info.modifierName)
                                                  : (info && info.alternateName)
                                              }
                                            </SpText>
                                          </Col>
                                          <Col>
                                            <SpText
                                              fontSize="16px"
                                              fontWeight="400"
                                              color="#6E6E6E"
                                            >
                                              {
                                                (info && info.price && info.quantity && data.quantity) ? (
                                                  `$${(((info && info.price) / 100) * info.quantity * data.quantity)
                                                    .toFixed(2)
                                                  } `
                                                ) : (info && info.price && info.quantity) ? (
                                                  `$${(((info && info.price) / 100) * info.quantity)
                                                    .toFixed(2)
                                                  } `
                                                ) : (info && info.price) ? (
                                                  `$${(((info && info.price) / 100))
                                                    .toFixed(2)
                                                  } `
                                                ) : <>$0.00</>
                                              }
                                            </SpText>
                                          </Col>
                                        </Row>
                                      </div>
                                    </>
                                  )) : ''
                                }
                                {
                                  data.note && (
                                    <Row type="flex" className="mt-1" justify="space-between">
                                      <Col className="ml-2">
                                        <SpText
                                          fontSize="16px"
                                          fontWeight="400"
                                          color="#6E6E6E"
                                        >
                                          {data.note}
                                        </SpText>
                                      </Col>
                                    </Row>
                                  )
                                }
                                {data.refunded && (
                                <Row type="flex" className="mt-3" justify="space-between">
                                  <Col>
                                    <SpText
                                      fontSize="16px"
                                      fontWeight="400"
                                      color="#14A482"
                                    >
                                      Refunded
                                    </SpText>
                                  </Col>
                                  <Col>
                                    <SpText
                                      fontSize="16px"
                                      fontWeight="400"
                                      color="#14A482"
                                    >
                                      {
                                        (data && data.totalAmount) ? (
                                          `-$${(data && data.totalAmount / 100)
                                            .toFixed(2)
                                          } `
                                        ) : <>&#8211;</>
                                      }
                                    </SpText>
                                  </Col>
                                </Row>
                                )}
                                {
                                  (data.taxData && data.taxData.length !== 0) ? (
                                    <>
                                      <div>
                                        <Row type="flex" className="mt-1" justify="space-between">
                                          <Col className="ml-2">
                                            <SpText
                                              fontSize="16px"
                                              fontWeight="400"
                                              color="#6E6E6E"
                                            >
                                              Taxes
                                            </SpText>
                                          </Col>
                                          <Col>
                                            <SpText
                                              fontSize="16px"
                                              fontWeight="400"
                                              color="#6E6E6E"
                                            >
                                              {
                                                (data.taxData && data.taxData[0].percentage) ? (
                                                  `${data.taxData && data.taxData[0].percentage}% `
                                                ) : <>&#8211;</>
                                              }
                                            </SpText>
                                          </Col>
                                          <Col>
                                            <SpText
                                              fontSize="16px"
                                              fontWeight="400"
                                              color="#6E6E6E"
                                            >
                                              {
                                                (data && data.taxAmount) ? (
                                                  `$${((data.taxAmount) / 100)} `
                                                ) : <>&#8211;</>
                                              }
                                            </SpText>
                                          </Col>
                                        </Row>
                                      </div>
                                    </>
                                  ) : ''
                                }
                                {
                                  (data.lineItemDiscounts && data.lineItemDiscounts.length !== 0) ? (
                                    <>
                                      <div>
                                        <Row type="flex" className="mt-1" justify="space-between">
                                          <Col className="ml-2">
                                            <SpText
                                              fontSize="16px"
                                              fontWeight="700"
                                              color="#FF4B51"
                                            >
                                              {
                                                data.discountData && data.discountData[0].name
                                                  ? data.discountData && data.discountData[0].name
                                                  : 'Discount'
                                              }
                                            </SpText>
                                          </Col>
                                          <Col>
                                            <SpText
                                              fontSize="16px"
                                              fontWeight="700"
                                              color="#FF4B51"
                                            >
                                              {getDiscountAmount((Number(data.discountAmount) || '0') * (Number(data.quantity) || '0'))}
                                            </SpText>
                                          </Col>
                                        </Row>
                                      </div>
                                    </>
                                  ) : ''
                                }
                              </div>
                            </>
                          ))
                        )
                      }
                      <Hr />
                      {
                        orderReceipt && orderReceipt.subTotal && (
                          <Row type="flex" className="mt-3" justify="space-between">
                            <Col>
                              <SpText fontSize="16px" fontWeight="500" color="#000000">
                                Subtotal
                              </SpText>
                            </Col>
                            <Col>
                              <SpText fontSize="16px" fontWeight="500" color="#000000">
                                {
                                  (orderReceipt && orderReceipt.subTotal) ? (
                                    `$${((orderReceipt.subTotal) / 100)
                                      .toFixed(2)
                                    } `
                                  ) : <>&#8211;</>
                                }
                              </SpText>
                            </Col>
                          </Row>
                        )
                      }
                      {
                        (orderReceipt && orderReceipt.inventoryOrder && (orderReceipt.inventoryOrder.totalServiceFee > 0)) ? (
                          <Row type="flex" justify="space-between">
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                Service Charge
                              </SpText>
                            </Col>
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                {
                                  (orderReceipt && orderReceipt.inventoryOrder && orderReceipt.inventoryOrder.totalServiceFee) ? (
                                    `$${((orderReceipt.inventoryOrder.totalServiceFee) / 100)
                                      .toFixed(2)
                                    } `
                                  ) : <>&#8211;</>
                                }
                              </SpText>
                            </Col>
                          </Row>
                        ) : (
                          null
                        )
                      }
                      {
                        (orderReceipt && (orderReceipt.totalTax > 0)) ? (
                          <Row type="flex" justify="space-between">
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                Taxes
                              </SpText>
                            </Col>
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                {
                                  (orderReceipt && orderReceipt.totalTax) ? (
                                    `$${((orderReceipt.totalTax) / 100)
                                      .toFixed(2)
                                    } `
                                  ) : <>&#8211;</>
                                }
                              </SpText>
                            </Col>
                          </Row>
                        ) : (
                          null
                        )
                      }
                      {
                        (orderReceipt && (orderReceipt.totalTip > 0)) ? (
                          <Row type="flex" justify="space-between">
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                Tip
                              </SpText>
                            </Col>
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                {
                                  (orderReceipt && orderReceipt.totalTip) ? (
                                    `$${((orderReceipt.totalTip) / 100)
                                      .toFixed(2)
                                    } `
                                  ) : <>&#8211;</>
                                }
                              </SpText>
                            </Col>
                          </Row>
                        ) : (
                          null
                        )
                      }
                      {
                        (orderReceipt && (orderReceipt.total !== 0)) ? (
                          <Row type="flex" justify="space-between">
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                Order Total
                              </SpText>
                            </Col>
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                {
                                  (orderReceipt && orderReceipt.total) ? (
                                    `$${((orderReceipt.total) / 100)
                                      .toFixed(2)
                                    } `
                                  ) : <>&#8211;</>
                                }
                              </SpText>
                            </Col>
                          </Row>
                        ) : (
                          <Row type="flex" justify="space-between">
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                Order Total
                              </SpText>
                            </Col>
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                $0.00
                              </SpText>
                            </Col>
                          </Row>
                        )
                      }
                      {
                        (orderReceipt && (orderReceipt.originalTotal !== 0)) && (orderReceipt?.originalTotal !== orderReceipt?.total) ? (
                          <Row type="flex" justify="space-between">
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                Initial Total
                              </SpText>
                            </Col>
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                {
                                  (orderReceipt && orderReceipt.originalTotal) ? (
                                    `$${((orderReceipt.originalTotal) / 100)
                                      .toFixed(2)
                                    } `
                                  ) : <>&#8211;</>
                                }
                              </SpText>
                            </Col>
                          </Row>
                        ) : null
                      }
                      {
                        (orderReceipt && orderReceipt.inventoryOrder && (orderReceipt.inventoryOrder.orderDiscountAmount)) ? (
                          <Row type="flex" justify="space-between">
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#FF4B51">
                                Order Discount
                              </SpText>
                            </Col>
                            <Col>
                              <SpText fontSize="16px" fontWeight="400" color="#FF4B51">
                                {getDiscountAmount(orderReceipt && orderReceipt.inventoryOrder && (orderReceipt.inventoryOrder.orderDiscountAmount))}
                              </SpText>
                            </Col>
                          </Row>
                        ) : null
                      }
                      {
                        orderReceipt && (
                          <Row type="flex" className="mt-3" justify="space-between">
                            <Col>
                              <SpText fontSize="24px" fontWeight="600" color="#4C51C2">
                                <div
                                  style={{
                                    lineHeight: '60px',
                                  }}
                                >
                                  Total paid
                                </div>
                              </SpText>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <img
                                  src={Dollorimg}
                                  alt=""
                                  style={{
                                    marginTop: '-5px',
                                    paddingRight: '10px',
                                  }}
                                />
                                <div
                                  style={{
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '36px',
                                    lineHeight: '70px',
                                    color: '#4C51C2',
                                    paddingRight: '5px',
                                  }}
                                >
                                  {getAmountDisplay(orderReceipt && orderReceipt?.totalPaid)}
                                </div>
                                <div
                                  style={{
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '24px',
                                    lineHeight: '65px',
                                    color: '#4C51C2',
                                  }}
                                >
                                  {getDecimalDisplay(orderReceipt && orderReceipt?.totalPaid)}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )
                      }
                      <Hr />
                      {/* ---------------------------------------------------------------PAYMENTS----------------------------------------- */}
                      {orderReceipt.payments && orderReceipt.payments.length > 0 && orderReceipt?.payments.map((payment) => (
                        <>
                          <Row className="mt-2">
                            <SpText fontSize="16px" fontWeight="500" color="#000000">
                              {orderReceipt?.payments && orderReceipt?.payments.length > 1 ? 'Split Payment' : 'Payment' }
                            </SpText>
                          </Row>
                          <Row type="flex" className="mt-3" justify="space-between">
                            <Col>
                              <span className="mr-2">
                                {payment?.paymentType === 'CARD'
                                  ? BrandLogo(payment?.schema?.toLowerCase())
                                  : BrandLogo(payment?.paymentType)}
                              </span>
                              <SpText fontSize="16px" fontWeight="500" color="#000000">
                                {payment?.paymentType === 'CARD' ? (
                                  <>
                                    {payment?.schema?.replace(/_/g, ' ')} {payment?.cardLastFour}
                                  </>
                                ) : (
                                  payment?.paymentType === 'CASH' ? 'CASH GIVEN' : payment?.paymentType?.replace(/_/g, ' ')
                                  )}
                              </SpText>
                            </Col>
                            <Col>
                              <SpText fontSize="16px" fontWeight="500" color="#000000">
                                {payment && payment.orderAmount ? (
                                  `$${(payment.orderAmount / 100).toFixed(2)} `
                                ) : (
                                  <>&#8211;</>
                                )}
                              </SpText>
                            </Col>
                          </Row>
                          {payment.amountRefunded ? (
                            <Row type="flex" justify="space-between mt-1">
                              <Col>
                                <SpText fontSize="16px" fontWeight="400" color="#14A482">
                                  Refund Amount
                                </SpText>
                              </Col>
                              <Col>
                                <SpText fontSize="16px" fontWeight="400" color="#14A482">
                                  {payment && payment.amountRefunded ? (
                                    `-$${(payment && payment.amountRefunded / 100).toFixed(2)} `
                                  ) : (
                                    <>&#8211;</>
                                  )}
                                </SpText>
                              </Col>
                            </Row>
                          ) : null}
                          <Hr />

                        </>
                      ))}
                    </PosWrapper>
                  </Wrapper>
                </>
              ) : <Empty style={{ height: '100vh', paddingTop: '40px' }} />}
            </>
          ) : (
            <SpText fontSize="16px" fontWeight="500" color="#000000" style={{ padding: '10px' }}>
              {redirectUrl}
            </SpText>
          )
        }
    </>

  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  orderReceipt: state.orderReceipt,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrderReceipt: gid => dispatch(orderReceiptActions.fetchOrderReceipt({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderReceipt);
