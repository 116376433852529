// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Upload,
  Icon,
  DatePicker,
} from 'antd';
import {
  axiosAuthInstance,
  API_END_POINTS,
  axiosEmptyInstance,
} from 'api';
import {
  MESSAGES,
} from 'appconstants';
import { notificationActions } from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  SpH5,
  SpError,
  SpButton,
} from 'components/DesignKit';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';

const MOMENT_FORMAT = 'YYYY-MM-DD';
const dateFormat = 'MMM DD, YYYY';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  setNotification: Function,
};

const AddNewSettlement = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    setNotification,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [settlementDate, setSettlementDate] = useState(null);
  const [dateDisplay, setDateDisplaye] = useState(null);
  const [documentUrl, setDocumentUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const currentDate = moment().format(MOMENT_FORMAT);
  const disableDate = moment().tz(currentDate, selectedAccount && selectedAccount.timezone).endOf('day');

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.gid) {
      setSettlementDate(moment.tz(selectedAccount.timezone).format(MOMENT_FORMAT));
      setDateDisplaye(moment().tz(selectedAccount.timezone).format(dateFormat));
    }
  }, [selectedAccount]);

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const handleChange = async info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setDocumentUrl('');
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(API_END_POINTS.UPLOAD_PARTNER_SETTLEMENT);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const documentDetails = {
          keyType: 'PARTNER_SETTLEMENT',
          fileUrl: entity,
        };
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(API_END_POINTS.UPLOAD_PARTNER_SETTLEMENT, documentDetails);
        setDocumentUrl(fileUrl);
        setFileName(info.file.name);
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: MESSAGES.CUSTOM_DOCUMENT.UPDATE_SUCCESS,
        });
      }
    } catch (error) {
      setDocumentUrl('');
      setFileName('');
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.CUSTOM_DOCUMENT.FAILED,
      });
    } finally {
      setLoading(false);
    }
  };

  const settlementDateChange = (date, dateString) => {
    setDateDisplaye(dateString);
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.format(MOMENT_FORMAT);
    setSettlementDate(utcDate);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      settlementDate,
      documentUrl,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="New Settlement"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>File Name</SpH5></label>
          <Input
            placeholder="Enter a file name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div className="d-flex">
          <div className="w-30 mr-3">
            <label htmlFor=""><SpH5>Date</SpH5></label>
            <DatePicker
              format={dateFormat}
              value={moment(dateDisplay)}
              allowClear={false}
              disabledDate={current => current && current > disableDate}
              onChange={settlementDateChange}
            />
          </div>
          <div className="">
            <label htmlFor=""><SpH5>Upload Settlement File</SpH5></label>
            <Input
              placeholder="Upload a file"
              value={fileName}
              suffix={(
                <>
                  <Upload
                    name="avatar"
                    className="avatar-uploader"
                    onChange={handleChange}
                    showUploadList={false}
                  >
                    <Icon type={loading ? 'loading' : 'upload'} />
                  </Upload>
                </>
              )}
            />
            <SpError>
              {simpleValidator.current.message('upload file', documentUrl, 'required')}
            </SpError>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddNewSettlement);
