import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import formatNumber from 'utils/formatNumber';
import styled from 'styled-components';
import {
  Card,
  Row,
  Col,
  Menu,
  Button,
  Icon,
  Input,
} from 'antd';
import {
  SpText,
  SpTab,
  SpButton,
} from 'components/DesignKit';
import { NOTIFICATION } from 'store/actionTypes';
import {
  API_STATUS,
  MESSAGES,
} from 'appconstants';
import {
  walletDetailsActions,
  walletActions,
  notificationActions,
} from 'store/actions';
import Loading from 'components/Loading';
import Clipboard from 'components/NavIcons/copyclipboard';
import LoadBalance from './components/LoadBalance';
import WithdrawBalance from './components/WithdrawBalance';
import WalletAlias from './components/AliasSetup';
import ResetPin from './components/ResetPin';

const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  history: {
    push: Function,
  },
  walletDetails: Object,
  wallet: Object,
  selectedAccount: Object,
  test: Boolean,
  fetchWalletDetails: Function,
  fetchWallet: Function,
  addNewBankAccount: Function,
  loadBalance: Function,
  addNewCard: Function,
  getDebitCards: Function,
  cardDetails: Array<object>,
  withdrawAmount: Function,
  addConfirmWallet: Function,
  addWallet: Function,
  loading: Boolean,
  submitting: boolean,
  pinSetUp: Function,
  verifyPin: Function,
  pinWallet: Object,
  updatePin: Boolean,
  walletAuth: Boolean,
  confirmWalletAlias: Boolean,
  consfirmWalletPin: Boolean,
  setNotification: Function,
};

const WalletDetails = (props: Props) => {
  const {
    walletDetails,
    wallet,
    selectedAccount,
    test,
    fetchWalletDetails,
    fetchWallet,
    addNewBankAccount,
    loadBalance,
    addNewCard,
    getDebitCards,
    cardDetails,
    withdrawAmount,
    addConfirmWallet,
    addWallet,
    submitting,
    loading,
    pinSetUp,
    verifyPin,
    pinWallet,
    updatePin,
    walletAuth,
    confirmWalletAlias,
    consfirmWalletPin,
    setNotification,
  } = props;
  const [gid, setGid] = useState('');
  const [current, setCurrent] = useState('loadBalance');
  const currencyType = selectedAccount && selectedAccount.currency;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const { toFixed } = selectedAccount && selectedAccount.currency;
  const [walletAlias, setWalletAlias] = useState('');
  const [walletGid, setWalletGid] = useState('');
  const [disableStatus, setDisableStatus] = useState(true);
  const countryCode = selectedAccount && selectedAccount.country && selectedAccount.country.alpha2;

  const [aliasModal, setAliasModal] = useState(false);
  const [walletPinModal, setWalletPinModal] = useState(false);
  const [resetPinFlag, setResetPinFlag] = useState(true);
  const [bankType, setBankType] = useState('ACH');

  useEffect(() => {
    fetchWallet();
    fetchWalletDetails();
    getDebitCards();
  }, [test, selectedAccount]);

  useEffect(() => {
    if (wallet && wallet.gid) {
      setWalletAlias(wallet.aliases && wallet.aliases[0] && wallet.aliases[wallet.aliases.length - 1].alias);
      setWalletGid(wallet.aliases && wallet.aliases[0] && wallet.aliases[wallet.aliases.length - 1].gid);
      setResetPinFlag(false);
      setGid(wallet && wallet.gid);
    }
  }, [wallet]);

  useEffect(() => {
    if (!submitting) {
      if (wallet && wallet.gid && walletAuth) {
        setWalletAlias(wallet.aliases && wallet.aliases[0] && wallet.aliases[wallet.aliases.length - 1].alias);
        setWalletGid(wallet.aliases && wallet.aliases[0] && wallet.aliases[wallet.aliases.length - 1].gid);
        setResetPinFlag(false);
        setGid(wallet && wallet.gid);
      }
      setDisableStatus(true);
      if (!confirmWalletAlias) {
        setAliasModal(false);
      }
    }
  }, [submitting]);

  useEffect(() => {
    if (updatePin) {
      setWalletPinModal(false);
    }
  }, [updatePin]);

  const setUpWallet = (payload) => {
    let params;
    if (!confirmWalletAlias) {
      if (payload.type === 'EMAIL') {
        params = {
          alias: payload.alias,
          type: payload.type,
        };
      } else if (payload.type === 'PHONE') {
        params = {
          alias: payload.phone,
          type: payload.type,
        };
      }
      addWallet(params);
    } else if (confirmWalletAlias) {
      if (payload.type === 'EMAIL') {
        params = {
          alias: payload.alias,
          code: payload.code,
          walletGid,
        };
      } else if (payload.type === 'PHONE') {
        params = {
          alias: payload.phone,
          code: payload.code,
          walletGid,
        };
      }
      addConfirmWallet(params);
    }
  };

  const setUpWalletPin = (payload) => {
    let params;
    if (!consfirmWalletPin) {
      const encodedString = Buffer.from(payload.walletPin).toString('base64');
      params = {
        pin: encodedString,
      };
      pinSetUp(params);
    } else if (consfirmWalletPin) {
      const id = pinWallet && pinWallet.gid;
      params = {
        code: payload.code,
      };
      verifyPin({
        gid: id,
        params,
      });
    }
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WALLET.ALIAS_COPIED,
    });
  };


  const balanceUpdate = (val) => {
    setCurrent(val);
  };

  const newAccount = (bankDetails) => {
    const payload = {
      accountNumber: bankDetails.accountNumber,
      routingNumber: bankDetails.routingNumber,
      accountType: 'CHECKING',
      name: bankDetails.accountName,
      bankName: bankDetails.accountName,
      walletGid: gid,
    };
    addNewBankAccount(payload);
  };

  const typeOfBank = (val) => {
    setBankType(val);
  };

  const addBalance = (balance) => {
    let payload;
    const balanceAmount = balance.amount.replaceAll(',', '');
    if (balance.bankAccount) {
      if (balance.bankAccountType === 'ZELLE') {
        payload = {
          walletGid: gid,
          amount: Number(balanceAmount) * 100,
          currencyCode: 'USD',
          confirmationCode: balance.confirmationCode,
          walletBankGid: balance.walletBankGid,
          type: balance.bankAccountType,
          bankType,
        };
      } else if (balance.bankAccountType === 'ACH') {
        payload = {
          walletGid: gid,
          currencyCode: 'USD',
          amount: Number(balanceAmount) * 100,
          walletBankGid: balance.walletBankGid,
          type: balance.bankAccountType,
        };
      }
    } else if (balance.debitCard) {
      payload = {
        walletGid: gid,
        currencyCode: 'USD',
        amount: Number(balanceAmount) * 100,
        walletCardGid: balance.walletCardGid,
        postalCode: '99501',
        type: 'CARD',
      };
    }
    loadBalance(payload);
  };

  const newCard = (card) => {
    const payload = {
      card,
      walletGid: gid,
    };
    addNewCard(payload);
  };

  const withdrawBalance = (info) => {
    const payload = {
      walletGid: gid,
      amount: Number(info.amount.replaceAll(',', '')) * 100,
      bankGid: info.bankGid,
    };
    withdrawAmount(payload);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        walletDetails && walletDetails.walletDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card className="mb-1">
              <Row className="my-2 mb-3">
                <Col>
                  <SpText className="mr-4" color="#999999" fontWeight="700" fontSize="14px">AVAILABLE BALANCE</SpText>
                </Col>
                <Col>
                  <SpText className="mr-4 mt-4" color="#333333" fontWeight="600" fontSize="20px">
                    {`${prefix} ${formatNumber((walletDetails.walletDetails.balance / 100).toFixed(toFixed), currencyType)}`}
                  </SpText>
                </Col>
              </Row>
            </Card>
            <Card className="mb-3">
              <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
                <Menu.Item key="loadBalance">
                  <SpTab
                    selected={current === 'loadBalance'}
                    onClick={() => balanceUpdate('loadBalance')}
                  >
                    LINKED CARDS
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="withdrawBalance">
                  <SpTab
                    selected={current === 'withdrawBalance'}
                    onClick={() => balanceUpdate('withdrawBalance')}
                  >
                    LINKED ACCOUNTS
                  </SpTab>
                </Menu.Item>
              </Menu>
              {
                current === 'loadBalance' ? (
                  <LoadBalance
                    walletBanks={walletDetails.walletDetails.walletBanks}
                    walletCards={cardDetails}
                    newAccount={newAccount}
                    addBalance={addBalance}
                    typeOfBank={typeOfBank}
                    newCard={newCard}
                  />
                ) : (
                  <WithdrawBalance
                    walletBanks={walletDetails.walletDetails.walletBanks}
                    withdrawBalance={withdrawBalance}
                    typeOfBank={typeOfBank}
                    newAccount={newAccount}
                  />
                )
              }
            </Card>
            {
              (countryCode && countryCode !== 'US')
                ? (
                  <Card
                    className="mx-auto mt-5"
                    style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
                    bodyStyle={{ height: '100%' }}
                  >
                    <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                      <Icon
                        type="exclamation-circle"
                        className="mb-4"
                        style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                      />
                      <h5>Setup is only available for the country USA.</h5>
                    </div>
                  </Card>
                ) : (
                  <>
                    <Card>
                      <Row type="flex" justify="space-between" className="my-2 mb-3">
                        <Col span={10}>
                          <SpText className="mr-4" fontSize="20px">Wallet Alias</SpText>
                          {
                            wallet && wallet.gid && wallet.aliases && wallet.aliases[0] && disableStatus && (
                              <StatusTag style={{ backgroundColor: '#4c51c23b' }}>
                                Verified
                              </StatusTag>
                            )
                          }
                          <div className="d-flex mt-1">
                            <Input
                              className="mr-2"
                              placeholder="Enter alias"
                              value={walletAlias}
                              onChange={(e) => setWalletAlias(e.currentTarget.value)}
                              disabled
                            />
                            <Button
                              type="primary"
                              shape="round"
                              className="align-items-center justify-content-center"
                              onClick={() => setAliasModal(true)}
                            >
                              Update Alias
                            </Button>
                            {
                              walletAlias && (
                                <SpButton
                                  type="secondary"
                                  className="ml-2"
                                  borderRadius="50px"
                                  onClick={() => { copyCode(walletAlias); }}
                                >
                                  <Clipboard />
                                </SpButton>
                              )
                            }
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row type="flex" justify="space-between" className="my-2 mb-3">
                        <Col span={8}>
                          <SpText className="mr-4" fontSize="20px">Wallet Pin</SpText>
                          <div className="mt-1">
                            <Button
                              type="primary"
                              shape="round"
                              className="align-items-center justify-content-center"
                              onClick={() => setWalletPinModal(true)}
                              disabled={resetPinFlag}
                            >
                              Reset Pin
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                    {/* VALIDATE ALIAS MODAL */}
                    {aliasModal && (
                      <WalletAlias
                        visible={aliasModal}
                        close={() => { setAliasModal(false); addWallet({}); }}
                        submit={setUpWallet}
                        selectedAccount={selectedAccount}
                        submitting={submitting}
                        confirmWalletAlias={confirmWalletAlias}
                      />
                    )}

                    {/* VALIDATE RESET PIN MODAL */}
                    {walletPinModal && (
                      <ResetPin
                        visible={walletPinModal}
                        close={() => { setWalletPinModal(false); pinSetUp({}); }}
                        submit={setUpWalletPin}
                        consfirmWalletPin={consfirmWalletPin}
                      />
                    )}
                  </>
                )
            }
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  walletDetails: state.walletDetails,
  wallet: state.wallet,
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  isSuperUser: state.user.superUser,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
  transactions: state.wallet.content,
  pinWallet: state.wallet.walletPin,
  updatePin: state.wallet.updatePin,
  walletAuth: state.wallet.walletAuth,
  confirmWalletAlias: state.wallet.confirmWalletAlias,
  consfirmWalletPin: state.wallet.consfirmWalletPin,
  cardDetails: state.walletDetails.cardDetails.content ? state.walletDetails.cardDetails.content : [],
});

const mapDispatchToProps = (dispatch) => ({
  fetchWallet: param => dispatch(walletActions.fetchWallet({
    payload: param,
  })),
  fetchWalletDetails: param => dispatch(walletDetailsActions.fetchWalletDetails({
    payload: param,
  })),
  addNewBankAccount: param => dispatch(walletDetailsActions.addNewBankAccount({
    payload: param,
  })),
  loadBalance: param => dispatch(walletDetailsActions.loadBalance({
    payload: param,
  })),
  addNewCard: param => dispatch(walletDetailsActions.addNewCard({
    payload: param,
  })),
  getDebitCards: param => dispatch(walletDetailsActions.getDebitCards({
    payload: param,
  })),
  withdrawAmount: param => dispatch(walletDetailsActions.withdrawAmount({
    payload: param,
  })),
  addWallet: param => dispatch(walletActions.addWallet({
    payload: param,
  })),
  addConfirmWallet: param => dispatch(walletActions.addConfirmWallet({
    payload: param,
  })),
  pinSetUp: param => dispatch(walletActions.pinSetUp({
    payload: param,
  })),
  verifyPin: param => dispatch(walletActions.verifyPin({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletDetails);
