import { USERPROFILE } from '../actionTypes';

export const fetchUserProfile = action => ({
  type: USERPROFILE.FETCH,
  payload: action.payload,
});

export const updateUserProfile = action => ({
  type: USERPROFILE.UPDATE,
  payload: action.payload,
});
