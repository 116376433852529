import { PAYMENTPAGESDETAILS } from '../actionTypes';

export const fetchPaymentPagesDetails = (action) => ({
  type: PAYMENTPAGESDETAILS.FETCH,
  payload: action.payload,
});

export const fetchPayments = (action) => ({
  type: PAYMENTPAGESDETAILS.PAYMENTS_FETCH,
  payload: action.payload,
});

export const DeactivePaymentPage = (action) => ({
  type: PAYMENTPAGESDETAILS.DELETE,
  payload: action.payload,
});

export const notifyPaymentPage = (action) => ({
  type: PAYMENTPAGESDETAILS.NOTIFY,
  payload: action.payload,
});

export const updatePaymentPages = (action) => ({
  type: PAYMENTPAGESDETAILS.UPDATE,
  payload: action.payload,
});

export const soldOutPaymentPage = (action) => ({
  type: PAYMENTPAGESDETAILS.SOLDOUT,
  payload: action.payload,
});

export const fetchPaymentPageSlug = (action) => ({
  type: PAYMENTPAGESDETAILS.SLUG_FETCH,
  payload: action.payload,
});

export const resumeSalePaymentPage = (action) => ({
  type: PAYMENTPAGESDETAILS.RESUMESALE,
  payload: action.payload,
});

export const fetchPaymentsOrders = (action) => ({
  type: PAYMENTPAGESDETAILS.FETCH_ORDERS,
  payload: action.payload,
});

export const fetchPaymentOrderById = (action) => ({
  type: PAYMENTPAGESDETAILS.FETCH_ORDER_BY_ID,
  payload: action.payload,
});

export const fetchOrderTimeline = (action) => ({
  type: PAYMENTPAGESDETAILS.FETCH_TIMELINE,
  payload: action.payload,
});

export const updateOrderStatus = (action) => ({
  type: PAYMENTPAGESDETAILS.UPDATE_ORDER_STATUS,
  payload: action.payload,
});

export const updateCashPayment = (action) => ({
  type: PAYMENTPAGESDETAILS.UPDATE_CASH_PAYMENT,
  payload: action.payload,
});

export const fetchOfflinePayments = (action) => ({
  type: PAYMENTPAGESDETAILS.FETCH_OFFLINE_PAYMENTS,
  payload: action.payload,
});

export const deleteOfflinePayments = (action) => ({
  type: PAYMENTPAGESDETAILS.OFFLINE_DELETE,
  payload: action.payload,
});

export const fetchPaymentReports = (action) => ({
  type: PAYMENTPAGESDETAILS.FETCH_PAYMENTS_REPORT,
  payload: action.payload,
});
export const fetchPaymentPagesEntries = (action) => ({
  type: PAYMENTPAGESDETAILS.FETCH_PAYMENTS_PAGE_ENTRIES,
  payload: action.payload,
});
