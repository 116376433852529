/* eslint-disable */
import React from 'react';

const customers = ({ fill = "silver" }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.55 3.9C4.5717 3.9 4.59326 3.89947 4.6147 3.89842C4.70617 4.35021 4.89135 4.76801 5.14909 5.13064C4.95676 5.176 4.75618 5.2 4.55 5.2C3.11406 5.2 1.95 4.03594 1.95 2.6C1.95 1.16406 3.11406 0 4.55 0C5.12006 0 5.64727 0.18346 6.07581 0.494558C5.69269 0.734796 5.36325 1.05272 5.10955 1.42625C4.94008 1.34531 4.75033 1.3 4.55 1.3C3.83203 1.3 3.25 1.88203 3.25 2.6C3.25 3.31797 3.83203 3.9 4.55 3.9Z" fill={fill} />
    <path d="M0.0404571 9.49721C0.464541 7.41691 2.32079 5.85 4.55 5.85C5.08615 5.85 5.60073 5.94064 6.07899 6.10726C5.34069 6.33418 4.66465 6.70327 4.08281 7.1826C2.70442 7.37665 1.60157 8.4167 1.32892 9.75415C1.25718 10.1061 1.55806 10.4 1.92101 10.4H2.09496C2.00009 10.8181 1.95 11.2532 1.95 11.7H1.92101C0.922308 11.7 -0.231737 10.8324 0.0404571 9.49721Z" fill={fill} />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80001 5.85C9.23595 5.85 10.4 4.68594 10.4 3.25C10.4 1.81406 9.23595 0.65 7.80001 0.65C6.36407 0.65 5.2 1.81406 5.2 3.25C5.2 4.68594 6.36407 5.85 7.80001 5.85ZM7.80001 4.55C8.51798 4.55 9.10001 3.96797 9.10001 3.25C9.10001 2.53203 8.51798 1.95 7.80001 1.95C7.08204 1.95 6.50001 2.53203 6.50001 3.25C6.50001 3.96797 7.08204 4.55 7.80001 4.55Z" fill={fill} />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.64623 10.5525C3.13089 8.24101 5.25233 6.5 7.79999 6.5C10.3477 6.5 12.4691 8.24101 12.9538 10.5525C13.2648 12.036 11.9459 13 10.8046 13H4.79543C3.65406 13 2.33515 12.036 2.64623 10.5525ZM10.9726 11.7C11.4106 11.7 11.7737 11.3473 11.6871 10.925C11.3217 9.14235 9.72023 7.8 7.8 7.8C5.87976 7.8 4.2783 9.14235 3.91285 10.925C3.82628 11.3473 4.18937 11.7 4.62738 11.7H10.9726Z" fill={fill} />
  </svg>
);

export default customers;
