/* eslint-disable */
import React from 'react';

const addIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" stroke="#999999" />
        <line x1="24" y1="16" x2="8" y2="16" stroke="#292929" stroke-width="2" />
        <line x1="16" y1="24" x2="16" y2="8" stroke="#292929" stroke-width="2" />
    </svg>

);

export default addIcon;
