import { MENUS } from '../actionTypes';

export const fetchInventoryMenus = (action) => ({
  type: MENUS.FETCH,
  payload: action.payload,
});

export const fetchMenusOperatingHours = (action) => ({
  type: MENUS.FETCH_OPERATING_HOURS,
  payload: action.payload,
});

export const addInventoryMenu = (action) => ({
  type: MENUS.ADD_MENUS,
  payload: action.payload,
});

export const updateInventoryMenus = (action) => ({
  type: MENUS.UPDATE_MENUS,
  payload: action.payload,
});

export const deleteInventoryMenus = (action) => ({
  type: MENUS.DELETE,
  payload: action.payload,
});
