import { LOCATIONS } from '../actionTypes';

export const fetchLocations = (action) => ({
  type: LOCATIONS.FETCH,
  payload: action.payload,
});

export const addLocation = (action) => ({
  type: LOCATIONS.ADD,
  payload: action.payload,
});

export const updateLocation = (action) => ({
  type: LOCATIONS.UPDATE,
  payload: action.payload,
});
