import { SHOPS_DETAILS } from '../actionTypes';

const initialState = {
  content: [],
  shopsItemsList: [],
  shopsSlug: [],
  shopPromosList: [],
  allShopPromosList: [],
  shopsItemNamesList: [],
  shopTables: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOPS_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SHOPS_DETAILS.SHOPS_ITEM_SUCCESS:
      return {
        ...state,
        shopsItemsList: action.payload,
      };
    case SHOPS_DETAILS.SHOPS_TIPS_SUCCESS:
      return {
        ...state,
        shopsTipsList: action.payload,
      };
    case SHOPS_DETAILS.SHOPS_ITEM_SEARCH_SUCCESS:
      return {
        ...state,
        shopsItemNamesList: action.payload,
      };
    case SHOPS_DETAILS.SHOPS_SLUG_SUCCESS:
      return {
        ...state,
        shopsSlug: action.payload,
      };
    case SHOPS_DETAILS.SHOPS_PROMO_SUCCESS:
      return {
        ...state,
        shopPromosList: action.payload,
      };
    case SHOPS_DETAILS.SHOPS_ALLPROMO_SUCCESS:
      return {
        ...state,
        allShopPromosList: action.payload,
      };
    case SHOPS_DETAILS.SUCCESS_SHOP_TABLES:
      return {
        ...state,
        shopTables: action.payload,
      };
    default:
      return state;
  }
};
