import { CUSTOMERS } from '../actionTypes';

export const fetchCustomers = (action) => ({
  type: CUSTOMERS.FETCH,
  payload: action.payload,
});

export const addCustomers = (action) => ({
  type: CUSTOMERS.ADD,
  payload: action.payload,
});

export const updateCustomer = (action) => ({
  type: CUSTOMERS.UPDATE,
  payload: action.payload,
});

export const deleteCustomer = (action) => ({
  type: CUSTOMERS.DELETE,
  payload: action.payload,
});
