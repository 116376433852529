// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Switch,
  Checkbox,
} from 'antd';
import { SketchPicker } from 'react-color';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import {
  MAX_AMOUNT,
  COUNTRIES,
} from 'appconstants';
import {
  validators,
} from 'utils/validationMessages';
import {
  notificationActions,
} from 'store/actions';
import {
  SpH5,
  SpError,
  SpButton,
} from 'components/DesignKit';
import {
  SpColor,
  SpCover,
  SpPopover,
  SpWatch,
} from 'components/PaymentWidgetDesignKit';
import Minus from 'imgs/Minus.png';
import SeperateLine from 'imgs/SeperateLine.png';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
};

const AddItem = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
  } = props;

  const [, forceUpdate] = useState();
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const prefix = (selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [primaryColor, setColorCode] = useState('#007bff');
  const STRING_LENGTH = 1;
  const [isItemAvailable, setIsItemAvailable] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [enableStack, setEnableStack] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_POSINR : MAX_AMOUNT.MESSAGE_POSUSD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      minLimit: {
        message: 'Sort order must be valid',
        rule: val => val >= 0 && val < 999999,
      },
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      numericValidation: {
        message: 'Sort order must be valid',
        rule: (val) => (val ? !val.toString().includes('.') : true),
      },
    },
  }));

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (e) => {
    setColorCode(e.hex);
  };

  const updateColor = (e) => {
    setColorCode(e.currentTarget.value);
  };

  const isAvailableChange = (value) => {
    setIsItemAvailable(value);
  };

  const isStackChange = (value) => {
    const { target: { checked } } = value;
    if (checked === true) {
      setQuantity(1);
    }
    if (checked === false) {
      setQuantity(0);
    }
    setEnableStack(checked);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      price,
      primaryColor,
      isItemAvailable,
      quantity,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add Modifier</SpButton>,
      ]}
      title="Add Modifier"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            onChange={(e) => setName(e.currentTarget.value)}
            value={name}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required|stringLength')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Price</SpH5></label>
          <div>
            <Input
              value={price}
              prefix={prefix}
              placeholder="Amount"
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                const { value } = e.currentTarget;
                if (regex.test(value) || value === '') {
                  setPrice(e.currentTarget.value);
                } else if (!regex.test(value)) {
                  setPrice(0.00);
                }
              }}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
              onBlur={e => {
                if (e.currentTarget.value) {
                  const value = formatAmount(e.currentTarget.value, currencyType);
                  setPrice(value);
                }
              }}
            />
          </div>
          <SpError>
            {simpleValidator.current.message('price', price, 'required|amount')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Item Color</SpH5></label>
          <div className="d-flex ml-1">
            <div>
              <SpWatch onClick={handleClick}>
                <SpColor className="color" style={{ backgroundColor: `${primaryColor}` }} />
              </SpWatch>
              {displayColorPicker ? (
                <>
                  <SpPopover>
                    <SpCover onClick={handleClose} />
                    <SketchPicker color={primaryColor} onChange={handleChange} />
                  </SpPopover>
                </>
              ) : null}
            </div>
            <Input
              className="ml-3"
              value={primaryColor}
              placeholder="Item Color"
              onChange={updateColor}
            />
          </div>
          <SpError>
            {simpleValidator.current.message('color code', primaryColor, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <div>
            <Checkbox
              className="mr-2"
              checked={enableStack}
              onChange={isStackChange}
            />
            <SpH5>Max Stackable Quantity</SpH5>
          </div>
        </div>
        {
          enableStack && (
            <div
              className="mt-3 mb-3"
              style={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #1A5244',
                padding: '8px',
                borderRadius: '8px',
                fontSize: '16px',
                userSelect: 'none',
                width: '140px',
                height: '38px',
                gap: '16px',
              }}
            >
              <div style={{ cursor: 'pointer' }} onClick={decreaseQuantity}>
                <img src={Minus} alt="cancel" />
              </div>
              <div><img src={SeperateLine} alt="cancel" /></div>
              <div><SpH5>{quantity}</SpH5></div>
              <div><img src={SeperateLine} alt="cancel" /></div>
              <div style={{ cursor: 'pointer', fontSize: '2em' }} onClick={increaseQuantity}>+</div>
            </div>
          )
        }
        <div>
          <Switch
            className="mr-2"
            checked={isItemAvailable}
            onChange={isAvailableChange}
            // disabled
          /> AVAILABLE
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddItem);
