import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Select,
  Modal,
  AutoComplete,
  Input,
  Radio,
} from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import {
  PAGES,
  TOKENS,
  MESSAGES,
  ROLES,
} from 'appconstants';

import {
  NOTIFICATION,
} from 'store/actionTypes';

import {
  accountActions,
  notificationActions,
  userActions,
  partnerParamsActions,
} from 'store/actions';

const { Option } = Select;
let timeout;

type Props = {
  history: {
    push: Function,
  },
};

const AccountSelector = (props: Props) => {
  const {
    history,
  } = props;
  const dispatch = useDispatch();

  const {
    filteredList, test,
  } = useSelector(state => state.account);
  const { internalRole, accountGid } = useSelector(state => state.user);
  const [options, setOptions] = useState([]);
  const [selectedGid, setSelectedGid] = useState();
  const [selectOption, setSelectOption] = useState('name');
  const [accountLabel, setAccountLabel] = useState('Account Name');

  useEffect(() => {
    if (internalRole
      && ((internalRole.name === ROLES.SUPERUSER) || (internalRole.name === ROLES.ONBOARDING) || (internalRole.name === ROLES.INTERNALSUPPORT))) {
      dispatch(accountActions.fetchFilteredAccounts());
    }
  }, []);

  useEffect(() => {
    if (filteredList.content) {
      const list = filteredList.content.map(
        (c) => ({ gid: c.gid, name: c.name }),
      );
      setOptions(list);
    }
  }, [filteredList]);

  const handleChange = (gid) => {
    dispatch(partnerParamsActions.partnerAffiliate(false));
    setSelectedGid(gid);
  };

  const selectedOption = (e) => {
    setSelectOption(e.target.value);
    if (e.target.value === 'name') {
      setAccountLabel('Account Name');
    } else if (e.target.value === 'gid') {
      setAccountLabel('Account Gid');
    }
  };

  const handleSearch = value => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(dispatch(accountActions.fetchFilteredAccounts({ selectOption, value })), 300);
  };

  const handleSubmit = async () => {
    if (selectedGid && selectedGid !== '') {
      try {
        const API_END_POINT = `/internal${API_END_POINTS.JWE}/${selectedGid}/switch?isTest=${test || false}`;
        const { data: { entity: { token: { accessToken } } } } = await axiosAuthInstance.get(API_END_POINT);
        localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
        if (internalRole && internalRole.name) {
          if (internalRole && internalRole.name === ROLES.SUPERUSER) {
            history.push(PAGES.DASHBOARD);
          } else {
            history.push(PAGES.PAYMENTS);
          }
        } else {
          history.push(PAGES.DASHBOARD);
        }
      } catch (e) {
        dispatch(notificationActions.setNotification({
          type: NOTIFICATION.ERROR,
          payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
        }));
      }
      // update selectedAccount
      dispatch(accountActions.setSelectedAccount(filteredList.content.find(a => a.gid === selectedGid)));
      dispatch(userActions.updateUserAccount(selectedGid));
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={(internalRole && accountGid === null)}
      footer={[
        <SpButton disabled={!selectedGid} onClick={handleSubmit}>Select</SpButton>,
      ]}
      title="Select Account"
    >
      <div className="px-4">
        <div className="mb-2">
          <Radio.Group defaultValue="name" onChange={selectedOption}>
            <Radio.Button value="name">Account Name</Radio.Button>
            <Radio.Button value="gid">Account Gid</Radio.Button>
          </Radio.Group>
        </div>
        <div>
          <label><SpH5>{accountLabel}</SpH5></label>
          <AutoComplete
            placeholder={accountLabel}
            showSearch
            className="w-100"
            dataSource={options.map((item) => (
              <Option key={item.gid} value={item.gid}>
                {`${item.name} : ${item.gid}`}
              </Option>
            ))}
            onSearch={handleSearch}
            defaultValue={selectedGid}
            onSelect={e => handleChange(e)}
          >
            <Input.Search enterButton />
          </AutoComplete>
        </div>
      </div>
    </Modal>
  );
};

export default AccountSelector;
