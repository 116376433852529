// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
} from 'antd';
import {
  SpButton, SpH5,
} from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  selectedData: Object,
  selectedNode: Object,
};

const DeleteNode = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedData,
    selectedNode,
  } = props;

  const validate = () => {
    submit(selectedNode);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} type="danger">Delete</SpButton>,
      ]}
      title="Delete Table"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to delete this Table?</div>
        <SpH5 className="pb-3">
          <div>{selectedData.name}</div>
        </SpH5>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(DeleteNode);
