// @flow
import React from 'react';
import {
  Modal,
  Row,
  Col,
} from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';
import Clipboard from 'components/NavIcons/copyclipboard';

type Props = {
  visible: boolean,
  close: Function,
  subscriptionButtonDetails: Object,
  copyHtml: Function,
  payButton: string,
};

const copySubscriptionButton = (props: Props) => {
  const {
    visible,
    close,
    subscriptionButtonDetails,
    copyHtml,
    payButton,
  } = props;

  const copyCode = (id) => {
    copyHtml(id);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">OK</SpButton>,
      ]}
      title="HTML Button Code"
    >
      <div className="px-4">
        <Row type="flex" justify="space-between" align="middle" className="pb-3">
          <Col>
            <SpH5>
              Copy &amp; Paste this HTML in your code
            </SpH5>
          </Col>
          <Col>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => { copyCode('paynow-button'); }}
            >
              <Clipboard />
            </span>
          </Col>
        </Row>
        <div
          style={{
            background: '#fff',
            padding: '5px',
            overflow: 'auto',
            height: '180px',
          }}
        >
          {`<a href=${subscriptionButtonDetails.link}
          rel="noopener noreferrer"
          target="_blank">
          <img src=${payButton} alt="payButton" width="150" height="35" />
          </a>`}
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default copySubscriptionButton;
