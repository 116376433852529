export default status => {
  if (status === 'PROCESSED') {
    return '#C9FFEF';
  } if (status === 'PROCESSING') {
    return '#C9FFEF';
  } if (status === 'DELIVERED') {
    return '#ffe0b1';
  } if (status === 'RETURNED') {
    return '#ffe0b1';
  } if (status === 'CANCELLED') {
    return '#ececff';
  } if (status === 'SHIPPED') {
    return '#ffe0b1';
  } return '#e2e2e2';
};
