import { PAYMENTSDETAILS } from '../actionTypes';

const initialState = {
  paymentTimeline: [],
  paymentDisputeDetails: [],
  paymentDetailsStatus: undefined,
  paymentDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENTSDETAILS.FETCH:
      return {
        ...state,
        paymentDetailsStatus: 'STARTED',
      };
    case PAYMENTSDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        paymentDetailsStatus: 'SUCCESS',
      };
    case PAYMENTSDETAILS.FAILED:
      return {
        ...state,
        paymentDetailsError: action.payload,
        paymentDetailsStatus: 'FAILED',
      };
    case PAYMENTSDETAILS.TIMELINE_SUCCESS:
      return {
        ...state,
        paymentTimeline: action.payload,
      };
    case PAYMENTSDETAILS.SELECTED:
      return {
        ...state,
        selectedReceipt: action.payload,
      };
    case PAYMENTSDETAILS.DISPUTE_SUCCESS:
      return {
        ...state,
        paymentDisputeDetails: action.payload,
      };
    default:
      return state;
  }
};
