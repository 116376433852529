import { INVOICE_STATUSES } from 'appconstants';

export default value => {
  if (value === 'ACTIVE') {
    return INVOICE_STATUSES.ACTIVE;
  } if (value === 'CANCELLED') {
    return INVOICE_STATUSES.CANCELLED;
  } if (value === 'DRAFT') {
    return INVOICE_STATUSES.DRAFT;
  } if (value === 'PAST_DUE') {
    return INVOICE_STATUSES.PAST_DUE;
  } if (value === 'PAID') {
    return INVOICE_STATUSES.PAID;
  } if (value === 'INCOMPLETE') {
    return INVOICE_STATUSES.INCOMPLETE;
  } if (value === 'PROCESSING') {
    return INVOICE_STATUSES.PROCESSING;
  } if (value === 'REFUNDED') {
    return INVOICE_STATUSES.REFUNDED;
  } if (value === 'PARTIAL_REFUNDED') {
    return INVOICE_STATUSES.PARTIAL_REFUNDED;
  } if (value === 'PARTIALLY_REFUNDED') {
    return INVOICE_STATUSES.PARTIALLY_REFUNDED;
  } return null;
};
