// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import OtpInput from 'react-otp-input';
import SimpleReactValidator from 'simple-react-validator';
import {
  Input,
  Row,
} from 'antd';
import { SpH5, SpError, SpText } from 'components/DesignKit';

  type Props = {
    pinDetailsData: Object,
    consfirmWalletPin: Boolean,
  };

const PinDetails = (props: Props, ref) => {
  const {
    pinDetailsData,
    consfirmWalletPin,
  } = props;
  const [, forceUpdate] = useState();
  const [walletPin, setWalletPin] = useState(pinDetailsData.walletPin);
  const [confirmWalletPin, setConfirmWalletPin] = useState(pinDetailsData.walletPin);
  const [code, setCode] = useState('');
  const [showPin, setShowPin] = useState(true);
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      walletPinData: {
        message: 'Incorrect Wallet PIN',
        rule: (val) => val.length === 6,
      },
      confirmPin: {
        message: 'Confirm Wallet PIN is not Matching with the Wallet PIN',
        rule: (val, params) => val === params[0],
      },
    },
  }));

  const handleChange = (val) => {
    setWalletPin(val);
  };

  const handleChangeConfirm = (val) => {
    setConfirmWalletPin(val);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      let formValid;
      if (!consfirmWalletPin) {
        formValid = simpleValidator.current.fieldValid('WalletPin');
      } else if (consfirmWalletPin) {
        formValid = simpleValidator.current.fieldValid('Verification code');
      }
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      walletPin,
      code,
    }),
  }));

  return (
    <>
      {
      !consfirmWalletPin ? (
        <div>
          <div className="mt-2 mb-3">
            <Row type="flex" justify="space-between">
              <div><label htmlFor=""><SpH5>New Wallet PIN</SpH5></label></div>
              <div>
                <label htmlFor=""><SpH5 color="#3E8BFF" style={{ cursor: 'pointer' }} onClick={() => setShowPin(!showPin)}>Show</SpH5></label>
              </div>
            </Row>
            <OtpInput
              className="pinSet"
              value={walletPin}
              onChange={handleChange}
              numInputs={6}
              isInputNum
              isInputSecure={showPin}
            />
            <SpError>
              {simpleValidator.current.message('WalletPin', walletPin, 'required|walletPinData')}
            </SpError>
          </div>
          <div>
            <label htmlFor=""><SpH5>Confirm Wallet PIN</SpH5></label>
            <OtpInput
              className="pinSet"
              value={confirmWalletPin}
              onChange={handleChangeConfirm}
              numInputs={6}
              isInputNum
              isInputSecure={showPin}
            />
            <SpError>
              {simpleValidator.current.message('WalletPin', confirmWalletPin, `required|confirmPin:${walletPin}`)}
            </SpError>
          </div>
        </div>
      ) : (
        <div className="px-4">
          <div>
            <label htmlFor=""><SpH5>Verification Code</SpH5></label>
            <Input
              placeholder="Enter code"
              value={code}
              onChange={(e) => setCode(e.currentTarget.value)}
            />
            <SpText fontSize="10px" color="#333333" fontWeight="400">Please use verification code sent to your email</SpText>
            <SpError>
              {simpleValidator.current.message('Verification code', code, 'required')}
            </SpError>
          </div>
        </div>
      )
    }

    </>
  );
};

// $FlowFixMe
export default forwardRef(PinDetails);
