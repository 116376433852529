import { INVENTORY_ORDERS } from '../actionTypes';

export const fetchInventoryOrders = (action) => ({
  type: INVENTORY_ORDERS.FETCH,
  payload: action.payload,
});

export const fetchFilterOrders = (action) => ({
  type: INVENTORY_ORDERS.FETCH_FILTER_ORDERS,
  payload: action.payload,
});

export const fetchSearchOrders = (action) => ({
  type: INVENTORY_ORDERS.ORDERS_SEARCH_SUCCESS,
  payload: action.payload,
});
