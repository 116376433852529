// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import {
  TAX_STATUS,
  TAX_TYPES,
  COUNTRY_PHONE_CODE,
  COUNTRY_CODES,
} from 'appconstants';

const { Option } = Select;

type Props = {
  close: Function,
  phoneCode: string,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  zipTag: string,
  statesInCountry: Any,
};

const AddCustomer = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    phoneCode,
    selectedAccount,
    zipTag,
    statesInCountry,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [taxStatus, setTaxStatus] = useState('');
  const [taxValue, setTaxValue] = useState(null);
  const [taxId, setTaxId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [phoneFormate, setPhoneFormate] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const countryName = selectedAccount && selectedAccount.country && selectedAccount.country.name;
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [streetName1, setStreetName1] = useState('');
  const [cityName1, setCityName1] = useState('');
  const [stateName1, setStateName1] = useState('');
  const [zipCode1, setZipCode1] = useState('');
  const postalCodeRegex = countryName === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;
  const cntryId = selectedAccount && selectedAccount.country.id;
  const countryCodes = (getCountryAlpha2(cntryId));

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const updateTaxVale = (value) => {
    if (value === 'NONE') {
      setDisabled(true);
      setShow(false);
      setTaxId(value);
      setTaxValue(null);
    } else {
      setTaxId(value);
      setDisabled(false);
      setShow(true);
    }
  };

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    setDisableButton(true);
    setPhone(phoneNumber.replace(/[^0-9+]/g, ''));
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    let shippingAddress = null;
    if (streetName1 && cityName1 && stateName1 && zipCode1) {
      shippingAddress = {
        street: streetName1,
        city: cityName1,
        state: stateName1,
        postalCode: zipCode1,
        countryCode: countryCodes,
      };
    }
    submit({
      name,
      email,
      phoneNumber,
      countryCode,
      taxStatus,
      taxValue,
      taxId,
      billingAddress: {
        street: streetName,
        city: cityName,
        state: stateName,
        postalCode: zipCode,
        countryCode: countryCodes,
      },
      shippingAddress: shippingAddress || null,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add Customer</SpButton>,
      ]}
      title="Add New Customer"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Customer Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            placeholder="Customer email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('email', email, 'required|email|checkLowerCase')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone Number</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                defaultValue={countryCode}
                onChange={onCountryCodeChange}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormate}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setPhone(e.currentTarget.value);
                    const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormate(formater);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('phone number', phoneNumber, `phoneNumValidation:${countryCode}${phoneNumber}`)}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <label className="d-block"><SpH5>Tax Status</SpH5></label>
          <div className="w-50 mr-3">
            <Select
              className="w-100"
              placeholder="Select Tax Status"
              onChange={(e) => setTaxStatus(e)}
            >
              {
                TAX_STATUS.map(s => (
                  <Option
                    key={1}
                    value={s.value}
                    className="text-capitalize"
                  >
                    {s.display}
                  </Option>
                ))
              }
            </Select>

            <SpError>
              {simpleValidator.current.message('tax status', taxStatus, 'required')}
            </SpError>
          </div>
        </div>

        <div className="pb-3">
          <label className="d-block"><SpH5>Tax Id</SpH5></label>
          <div className="d-flex">
            <div className="w-50 mr-3">
              <Select
                className="w-100"
                placeholder="Select Tax Id"
                onChange={updateTaxVale}
              >
                {
                  TAX_TYPES.map(j => (
                    <Option
                      key={1}
                      value={j.value}
                      className="text-capitalize"
                    >
                      {j.display}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('tax Id', taxId, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <Input
                placeholder="Enter Tax value"
                value={taxValue}
                disabled={disabled}
                onChange={(e) => setTaxValue(e.currentTarget.value)}
              />
              <SpError>
                {show
                  ? simpleValidator.current.message('Tax value', taxValue, 'required')
                  : ''}
              </SpError>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Billing Address</SpH5></label>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Street</SpH5></label>
          <Input
            placeholder="Street Address"
            value={streetName}
            onChange={(e) => setStreetName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('street', streetName, 'required')}
          </SpError>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName}
                onChange={(e) => setCityName(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('city', cityName, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                className="w-100"
                placeholder="State"
                showSearch
                optionFilterProp="children"
                value={stateName}
                onChange={(e) => setStateName(e)}
              >
                {
                  statesInCountry.map(s => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-capitalize"
                    >
                      {s.name}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('state', stateName, 'required')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipTag}</SpH5></label>
              <Input
                placeholder={zipTag}
                value={zipCode}
                onChange={(e) => setZipCode(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message(zipTag, zipCode, 'required|postalcode')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Country</SpH5></label>
              <Select
                defaultValue={countryName}
                style={{ width: '100%' }}
                disabled
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ALPHA2}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="mb-1">
          <label htmlFor=""><SpH5>Shipping Address</SpH5></label>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Street</SpH5></label>
          <Input
            placeholder="Street Address"
            value={streetName1}
            onChange={(e) => setStreetName1(e.currentTarget.value)}
          />
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName1}
                onChange={(e) => setCityName1(e.currentTarget.value)}
              />
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                className="w-100"
                placeholder="State"
                showSearch
                optionFilterProp="children"
                value={stateName1}
                onChange={(e) => setStateName1(e)}
              >
                {
                  statesInCountry.map(s => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-capitalize"
                    >
                      {s.name}
                    </Option>
                  ))
                }
              </Select>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipTag}</SpH5></label>
              <Input
                placeholder={zipTag}
                value={zipCode1}
                onChange={(e) => setZipCode1(e.currentTarget.value)}
              />
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Country</SpH5></label>
              <Select
                defaultValue={countryName}
                style={{ width: '100%' }}
                disabled
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ALPHA2}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddCustomer);
