// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Badge,
  Tooltip,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';

import getDisputeBackground from 'utils/getDisputeBackground';
import getDisputeStatus from 'utils/getDisputeStatus';
import formatNumber from 'utils/formatNumber';
import getFilterOperators from 'utils/getFilterOperators';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import moment from 'moment-timezone';

import { disputesActions, filterParamsActions } from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import {
  PAGES,
  FILTERS_TYPES,
} from 'appconstants';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const DISPUTES_FILTERS_TYPES = FILTERS_TYPES.DISPUTES;

type Props = {
  fetchDisputes: Function,
  disputes: Array<Object>,
  loading: boolean,
  totalElements: number,
  test: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  };
  setFilterData: Function,
  preserveFilters: Object,
  isMobileView: Boolean,
};

// $FlowFixMe
const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

const DisputesList = (props: Props) => {
  const {
    loading,
    disputes,
    totalElements,
    test,
    fetchDisputes,
    selectedAccount,
    history,
    setFilterData,
    preserveFilters,
    isMobileView,
  } = props;
  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const myRef = useRef();
  const { pathname } = location;
  const parsedDisputeType = queryString.parse(location.search);
  const disputeListType = parsedDisputeType['status.EQ'];
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(DISPUTES_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterDisputes, setFilterDisputes] = useState((preserveFilters && preserveFilters.filterDisputes)
    ? (preserveFilters && preserveFilters.filterDisputes)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? (preserveFilters && preserveFilters.prevSearchParam)
      ? (((preserveFilters && preserveFilters.prevSearchParam) === disputeListType)
        ? queryString.parse(preserveFilters.search)
        : setFilterData({
          pathname,
          search: '',
          filterPayments: [{
            filterType: '',
            filterName: '',
            filterOperator: '',
            filterValue: '',
            filterNameDisplay: '',
            filterValueFrom: '',
            filterValueTo: '',
            checked: true,
          }],
        }))
      : queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterDisputes: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const exportData = disputes ? disputes.map((values) => ({
    amountDisputed:
      `${values.payment.currency.prefix}${formatNumber((values.amountDisputed / 100).toFixed(values.payment.currency.toFixed), currencyType)}`,
    status: getDisputeStatus(values.status),
    type: values.type,
    customer: values.paymentSession.paymentMethod.customer ? values.paymentSession.paymentMethod.customer.name : '',
    method: values.payment.paymentMethod && values.payment.paymentMethod.card && values.payment.paymentMethod.card.bankName
      ? `${values.payment.paymentMethod.card.bankName} **** ${values.payment.paymentMethod.card.lastFour}`
      : values.payment.paymentMethod && values.payment.paymentMethod.card
        ? `**** ${values.payment.paymentMethod.card.lastFour}` : '',
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY'),
    respondBy: moment
      .utc(values.respondBy)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm:ss a'),
  })) : '';

  const exportHeaders = [
    { label: 'AMOUNT', key: 'amountDisputed' },
    { label: 'STATUS', key: 'status' },
    { label: 'REASON', key: 'type' },
    { label: 'CUSTOMER', key: 'customer' },
    { label: 'SOURCE', key: 'method' },
    { label: 'DISPUTED ON', key: 'createdAt' },
    { label: 'RESPONDED BY', key: 'respondBy' },
  ];

  const debouncedFetchDisputes = debounce(fetchDisputes, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const searchParam = parsed['status.EQ'];
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchDisputes({
      searchParam,
      currentPage,
      sortParams,
      filterParams,
    });
  };

  const clearFilters = () => {
    setFilterDisputes([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(DISPUTES_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    if (location.search) {
      getData(location.search);
    }
    if (!filterParams) {
      clearFilters();
    }
  }, [test, location, selectedAccount]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [disputes]);

  const selectedRecord = (value) => {
    if (value && value.paymentSession && value.paymentSession.gid) {
      const paymentGid = value.paymentSession.gid;
      history.push({
        pathname: `${PAGES.PAYMENTS}/${paymentGid}`,
        state: location.search,
      });
    }
  };

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    const parsed = queryString.parse(location.search);
    const searchParam = parsed['status.EQ'];
    const filterParams = parsed.filterParams || '';
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && searchParam && !filterParams) {
      history.push({
        pathname: PAGES.DISPUTES,
        search: `?status.EQ=${searchParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else if (!searchParam && !filterParams) {
      history.push({
        pathname: PAGES.DISPUTES,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    } else if (searchParam && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.DISPUTES,
        search: `?status.EQ=${searchParam}&filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterDisputes];
    if (filterDisputes.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = DISPUTES_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterDisputes(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterDisputes];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amountDisputed') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else if (e[1] === 'respondBy') {
      list[index].filterType = 'Date';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = DISPUTES_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterDisputes(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterDisputes];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterDisputes(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterDisputes(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterDisputes];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterDisputes(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterDisputes(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    const filter = filterDisputes[index].filterName;
    if (filterDisputes[index].filterType === 'Date') {
      if (filterDisputes[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `${filter}.GT=${dateStart}&${filter}.LT=${dateEnd}`;
      } else if (filterDisputes[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `${filter}.LT=${dateStart}`;
      } else if (filterDisputes[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `${filter}.GT=${dateStart}`;
      }
    } else if (filterDisputes[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterDisputes];
    list[index].filterValue = selectedValue;
    setFilterDisputes(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterDisputes];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterType === 'Date') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterType === 'Date') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterDisputes[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterDisputes(list);
  };

  const handleAddClick = () => {
    const list = [...filterDisputes];
    setFilterDisputes([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterDisputes([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(DISPUTES_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.DISPUTES);
  };

  const applyFilters = () => {
    const disputesFilters = filterDisputes.filter((item) => item.checked);
    for (let i = 0; i < disputesFilters.length; i += 1) {
      if (!disputesFilters[i].filterName || !disputesFilters[i].filterOperator || !disputesFilters[i].filterValue) {
        return;
      }
    }
    if (disputesFilters[0].filterValue || (disputesFilters[0].filterValueFrom && disputesFilters[0].filterValueTo)) {
      setCount(disputesFilters && disputesFilters.length);
      for (let i = 0; i < disputesFilters.length; i += 1) {
        if (disputesFilters[i].checked) {
          if (disputesFilters[i].filterType === 'Date') {
            disputesFilters[i] = disputesFilters[i].filterValue;
          } else if (disputesFilters[i].filterName === 'amountDisputed' && disputesFilters[i].filterOperator === 'BT') {
            const name = disputesFilters[i].filterName;
            const from = disputesFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = disputesFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            disputesFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (disputesFilters[i].filterName === 'amountDisputed' && disputesFilters[i].filterOperator !== 'BT') {
            const value = disputesFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            disputesFilters[i] = `${disputesFilters[i].filterName}.${disputesFilters[i].filterOperator}=${value}`;
          } else {
            disputesFilters[i] = `${disputesFilters[i].filterName}.${disputesFilters[i].filterOperator}=${disputesFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(disputesFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const searchParam = parsed['status.EQ'];
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && searchParam && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?status.EQ=${searchParam}&pageNum=${currentPage}${sortParams}&filterParams=${filterParams}`,
          filterDisputes,
          prevSearchParam: searchParam,
        });
        history.push({
          pathname: PAGES.DISPUTES,
          search: `?status.EQ=${searchParam}&pageNum=${currentPage}${sortParams}&filterParams=${filterParams}`,
        });
      } else if (location.search && searchParam && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?status.EQ=${searchParam}&filterParams=${filterParams}`,
          filterDisputes,
          prevSearchParam: searchParam,
        });
        history.push({
          pathname: PAGES.DISPUTES,
          search: `?status.EQ=${searchParam}&filterParams=${filterParams}`,
        });
      } else if (location.search && !searchParam && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterDisputes,
          prevSearchParam: searchParam,
        });
        history.push({
          pathname: PAGES.DISPUTES,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amountDisputed',
      width: '12%',
      align: 'left',
      render: (amountDisputed, record) => {
        const total = (amountDisputed / 100).toFixed(record.payment.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${record.payment.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '15%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <StatusTag style={{ backgroundColor: getDisputeBackground(status) }}>{getDisputeStatus(status)}</StatusTag>
        </Tooltip>
      ),
    },
    {
      title: 'REASON',
      dataIndex: 'type',
      width: '13%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (type) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {type || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'CUSTOMER',
      dataIndex: 'customer',
      width: '14%',
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          {record.paymentSession.paymentMethod.customer
            ? <span>{record.paymentSession.paymentMethod.customer.name}</span>
            : <>&#8211;</>}
        </Tooltip>
      ),
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'SOURCE',
      dataIndex: 'source',
      width: '16%',
      align: 'left',
      render: (text, record) => {
        const bank = record.payment.paymentMethod && record.payment.paymentMethod.card && record.payment.paymentMethod.card.bankName
          ? record.payment.paymentMethod.card.bankName : '';
        const number = record.payment.paymentMethod && record.payment.paymentMethod.card
          ? record.payment.paymentMethod.card.lastFour : '';
        const source = (bank && number) ? `${bank} **** ${number}` : bank || (number ? `**** ${number}` : '');
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>{source || <>&#8211;</>}</span>
          </Tooltip>
        );
      },
      sorter: false,
    },
    {
      title: 'DISPUTED ON',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'RESPOND BY',
      dataIndex: 'respondBy',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (respondBy) => {
        if (respondBy && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(respondBy)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm:ss a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <span>
              {
                exportData !== null
                  ? (
                    <Tooltip placement="left" title="Click here to export Disputes">
                      <CSVLink
                        target="_blank"
                        className="mr-3"
                        filename="Disputes Report.csv"
                        data={exportData}
                        headers={exportHeaders}
                        onMouseEnter={() => setFillColor(true)}
                        onMouseLeave={() => setFillColor(false)}
                      >
                        <ExportIcon
                          fill={fillColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </CSVLink>
                    </Tooltip>
                  ) : null
              }
            </span>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Disputes">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && (
                <FilterWrapper
                  style={{ marginLeft: '-265px' }}
                >
                  <Filters
                    filtersGrid={filterDisputes}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                    handleFromValue={handleFromValue}
                    handleToValue={handleToValue}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterDisputes.length < DISPUTES_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
            </span>
          </Col>
        </div>
      </Row>
      <Table
        onRow={(record) => ({
          onClick: () => {
            selectedRecord(record);
          },
        })}
        rowClassName={() => 'ant-table-clickable-row'}
        columns={columns}
        rowKey={(record) => record.gid}
        dataSource={disputes}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  disputes: state.disputes.content,
  loading: state.loading.loading,
  totalElements: state.disputes.totalElements,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDisputes: param => dispatch(disputesActions.fetchDisputes({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(DisputesList);
