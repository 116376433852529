// @flow
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  selectedNode: Object,
  selectedData: Object,
};

const EditTableInfo = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedNode,
    selectedData,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState(selectedData && selectedData.name);
  const [number, setNumber] = useState(selectedData && selectedData.number);
  const [type, setType] = useState(selectedNode && selectedNode.name);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const editedInfo = {
      name,
      number,
      type,
    };
    submit(selectedNode, editedInfo);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Update</SpButton>,
      ]}
      title="Update Table Information"
    >
      <div className="px-4">
        <div className="mb-3">
          <label htmlFor=""><SpH5>Table Name</SpH5></label>
          <Input
            placeholder="Table Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>No of Guest</SpH5></label>
          <Input
            placeholder="No of Guest"
            value={number}
            onChange={(e) => setNumber(e.currentTarget.value)}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
          />
          <SpError>
            {simpleValidator.current.message('No of guests', number, 'required|numeric')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Type</SpH5></label>
          <div className="d-flex" style={{ alignItems: 'center' }}>
            <div style={{ width: '33.3%', textAlign: 'center', alignItems: 'center' }}>
              <div
                style={{
                  width: '100px',
                  height: '100px',
                  background: '#fff',
                  paddingTop: '25%',
                  border: type === 'Square' ? '2px solid #40ADFF' : '1px solid #000',
                  cursor: 'pointer',
                }}
                onClick={() => setType('Square')}
              >
                Square
              </div>
            </div>
            <div style={{ width: '33.3%', textAlign: 'center' }}>
              <div
                style={{
                  width: '100px',
                  height: '100px',
                  background: '#fff',
                  paddingTop: '25%',
                  borderRadius: '50%',
                  border: type === 'Circle' ? '2px solid #40ADFF' : '1px solid #000',
                  cursor: 'pointer',
                }}
                onClick={() => setType('Circle')}
              >
                Circle
              </div>
            </div>
            <div style={{ width: '33.3%', textAlign: 'center' }}>
              <div
                style={{
                  width: '100px',
                  height: '70px',
                  background: '#fff',
                  paddingTop: '17%',
                  border: type === 'Rectangle' ? '2px solid #40ADFF' : '1px solid #000',
                  cursor: 'pointer',
                }}
                onClick={() => setType('Rectangle')}
              >
                Rectangle
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditTableInfo);
