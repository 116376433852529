/* eslint-disable */
import React from 'react';

const ExpandArr = () => (
    <svg width="31" height="53" viewBox="0 0 21 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1_1647)">
            <path d="M5 0H7C12.5228 0 17 4.47715 17 10V24C17 29.5228 12.5228 34 7 34H5V0Z" fill="#12284A" />
            <g clip-path="url(#clip0_1_1647)">
                <path d="M6.42876 16.9147L12.0638 21.316C12.0785 21.3276 12.0962 21.3348 12.1148 21.3367C12.1334 21.3387 12.1523 21.3354 12.1691 21.3272C12.1859 21.319 12.2001 21.3062 12.21 21.2903C12.2199 21.2743 12.2251 21.256 12.225 21.2372V20.271C12.225 20.2097 12.1963 20.151 12.1488 20.1135L7.64876 16.5997L12.1488 13.086C12.1975 13.0485 12.225 12.9897 12.225 12.9285V11.9622C12.225 11.8785 12.1288 11.8322 12.0638 11.8835L6.42876 16.2847C6.38086 16.3221 6.34212 16.3699 6.31547 16.4244C6.28882 16.479 6.27497 16.539 6.27497 16.5997C6.27497 16.6605 6.28882 16.7204 6.31547 16.775C6.34212 16.8296 6.38086 16.8774 6.42876 16.9147Z" fill="white" />
            </g>
        </g>
        <defs>
            <filter id="filter0_d_1_1647" x="0" y="0" width="21" height="42" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_1647" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_1647" result="shape" />
            </filter>
            <clipPath id="clip0_1_1647">
                <rect width="11.2" height="11.2" fill="white" transform="matrix(-1 0 0 -1 15.2 22.2)" />
            </clipPath>
        </defs>
    </svg>
);

export default ExpandArr;