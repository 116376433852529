import { OFFER } from '../actionTypes';

export const fetchOffers = (action) => ({
  type: OFFER.FETCH,
  payload: action.payload,
});

export const addOffer = (action) => ({
  type: OFFER.ADD,
  payload: action.payload,
});
