import React, { useState, useEffect } from 'react';
import {
  Modal, Steps, Button, Table, Typography, Input,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Minus from 'imgs/Minus.png';
import SeperateLine from 'imgs/SeperateLine.png';

import formatNumber from 'utils/formatNumber';
import { SpH5 } from 'components/DesignKit';

const { Step } = Steps;
const { Title } = Typography;

type Props = {
  close: Function,
  lineItemSubmit: Function,
}

const MultiStepModal = (props: Props) => {
  const {
    data, selectedAccount, close, lineItemSubmit,
  } = props;

  const currencyType = selectedAccount && selectedAccount.currency;
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState(data);
  const [filteredDataSource, setFilteredDataSource] = useState(data);
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemStock, setItemStock] = useState([]);
  const [selectedRowKeysRefund, setSelectedRowKeysRefund] = useState([]);
  const [selectedRowBeforeRefund, setSelectedRowBeforeRefund] = useState([]);
  const [selectedItemsRefund, setSelectedItemsRefund] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [hasStockEnabledItems, setHasStockEnabledItems] = useState(true);

  const getTable = (datas) => {
    const accumulatedItems = {};

    datas.forEach(item => {
      if (item) {
        const { gid, quantity, totalAmount } = item;

        if (!accumulatedItems[gid]) {
          accumulatedItems[gid] = {
            gid,
            quantity,
            maxQuantity: quantity,
            qtyPerItem: Number(totalAmount) / Number(quantity),
          };
        } else {
          accumulatedItems[gid].quantity += quantity;
          accumulatedItems[gid].maxQuantity += quantity;
        }
      }
    });

    setItemStock(Object.values(accumulatedItems));
  };

  useEffect(() => {
    getTable(dataSource);
  }, []);

  useEffect(() => {
    const filteredData = dataSource.filter(item => item.itemName.toLowerCase().includes(searchQuery.toLowerCase()));
    setFilteredDataSource(filteredData);
  }, [searchQuery, dataSource]);

  useEffect(() => {
    const stockEnabledItems = selectedItems.filter(item => item.stockEnabled);

    setHasStockEnabledItems(stockEnabledItems.length > 0);
  }, [selectedItems]);

  const decreaseQuantity = (item) => {
    const itemInStock = itemStock.find(stockItem => stockItem.gid === item.gid);
    if (currentStep === 0) {
      const newData = dataSource.map(i => {
        if (i.gid === item.gid && item.quantity > 1) {
          return { ...i, quantity: i.quantity - 1, totalAmount: Number(itemInStock.qtyPerItem) * Number(i.quantity - 1) };
        }
        return i;
      });

      setDataSource(newData);
      if (selectedItems.length > 0) {
        const newData2 = selectedItems.map(i => {
          if (i.gid === item.gid && item.quantity > 1) {
            return { ...i, quantity: i.quantity - 1, totalAmount: Number(itemInStock.qtyPerItem) * Number(i.quantity - 1) };
          }
          return i;
        });
        setSelectedItems(newData2);
      }
    } else if (currentStep === 1) {
      const newData = selectedItems.map(i => {
        if (i.gid === item.gid && item.quantity > 1) {
          return { ...i, quantity: i.quantity - 1, totalAmount: Number(itemInStock.qtyPerItem) * Number(i.quantity - 1) };
        }
        return i;
      });
      setSelectedItems(newData);
    } else {
      if (selectedRowBeforeRefund.length > 0) {
        const newData = selectedRowBeforeRefund.map(i => {
          if (i.gid === item.gid && item.quantity > 1) {
            return { ...i, quantity: i.quantity - 1, totalAmount: Number(itemInStock.qtyPerItem) * Number(i.quantity - 1) };
          }
          return i;
        });
        setSelectedRowBeforeRefund(newData);
      }

      if (selectedItemsRefund.length > 0) {
        const newData4 = selectedItemsRefund.map(i => {
          if (i.gid === item.gid && item.quantity > 1) {
            return { ...i, quantity: i.quantity - 1, totalAmount: Number(itemInStock.qtyPerItem) * Number(i.quantity - 1) };
          }
          return i;
        });
        setSelectedItemsRefund(newData4);
      }
    }
  };

  const increaseQuantity = (item) => {
    const itemInStock = itemStock.find(stockItem => stockItem.gid === item.gid);
    const items = selectedItems.find(stockItem => stockItem.gid === item.gid);
    const refund = selectedRowBeforeRefund.find(stockItem => stockItem.gid === item.gid);
    const finalrefund= selectedItemsRefund.find(stockItem => stockItem.gid === item.gid);
    if (currentStep === 0) {
      const newData = dataSource.map(i => {
        if (i.gid === item.gid && i.quantity < itemInStock.quantity) {
          return { ...i, quantity: i.quantity + 1, totalAmount: Number(itemInStock.qtyPerItem) * Number(i.quantity + 1) };
        }
        return i;
      });
      if (selectedItems.length > 0) {
        const newData2 = selectedItems.map(i => {
          if (i.gid === item.gid && i.quantity < itemInStock.quantity) {
            return { ...i, quantity: i.quantity + 1, totalAmount: Number(itemInStock.qtyPerItem) * Number(i.quantity + 1) };
          }
          return i;
        });
        setSelectedItems(newData2);
      }
      setDataSource(newData);
    } else if (currentStep === 1) {
      const newData = dataSource.map(i => {
        if (i.gid === item.gid && i.quantity < itemInStock.quantity) {
          return { ...i, quantity: i.quantity + 1, totalAmount: Number(itemInStock.qtyPerItem) * Number(i.quantity + 1) };
        }
        return i;
      });
      setSelectedItems(newData);
      if (selectedItems.length > 0) {
        const newData2 = selectedItems.map(i => {
          if (i.gid === item.gid && i.quantity < itemInStock.quantity) {
            return { ...i, quantity: i.quantity + 1, totalAmount: Number(itemInStock.qtyPerItem) * Number(i.quantity + 1) };
          }
          return i;
        });
        setSelectedItems(newData2);
      }
    } else {
      if (selectedRowBeforeRefund.length > 0) {
        const newData2 = selectedRowBeforeRefund.map(i => {
          if (i.gid === item.gid && i.quantity < itemInStock.quantity) {
            return { ...i, quantity: i.quantity + 1, totalAmount: Number(itemInStock.qtyPerItem) * Number(i.quantity + 1) };
          }
          return i;
        });
        setSelectedRowBeforeRefund(newData2);
      }

      if (selectedItemsRefund.length > 0) {
        const newData4 = selectedItemsRefund.map(i => {
          if (i.gid === item.gid && i.quantity < itemInStock.quantity) {
            return { ...i, quantity: i.quantity + 1, totalAmount: Number(itemInStock.qtyPerItem) * Number(i.quantity + 1) };
          }
          return i;
        });
        setSelectedItemsRefund(newData4);
      }
    }
  };

  const handleSelectChange = (selectedRowk, selectedRows) => {
    setSelectedRowKeys(selectedRowk);
    setSelectedItems(selectedRows);
    setSelectedRowKeysRefund([]);
    setSelectedItemsRefund([]);
  };

  const handleSelectChangeRefund = (selectedRowKeysr, selectedRows) => {
    setSelectedRowKeysRefund(selectedRowKeysr);
    setSelectedItemsRefund(selectedRows);
  };

  const columns = [
    {
      title: 'ITEM',
      dataIndex: 'itemName',
      key: 'itemName',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'QTY',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, item) => (
        <div
          className="mt-3 mb-3"
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #1A5244',
            padding: '10px',
            background: '#FFFFFF',
            borderRadius: '8px',
            fontSize: '14px',
            userSelect: 'none',
            width: '130px',
            height: '34px',
            gap: '16px',
          }}
        >
          <div style={{ cursor: 'pointer' }} onClick={() => decreaseQuantity(item)}>
            <img src={Minus} alt="decrease" />
          </div>
          <div><img src={SeperateLine} alt="separator" /></div>
          <div><SpH5>{item.quantity}</SpH5></div>
          <div><img src={SeperateLine} alt="separator" /></div>
          <div style={{ cursor: 'pointer', fontSize: '2em' }} onClick={() => increaseQuantity(item)}>+</div>
        </div>
      ),
    },
    {
      title: 'PRICE',
      dataIndex: 'totalAmount',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (totalAmount) => {
        const total = totalAmount ? (totalAmount / 100).toFixed(2) : '';
        return (
          <span>
            {total
              ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`
              : <>&#8211;</>}
          </span>
        );
      },
    },
  ];

  const steps = [
    {
      title: 'Choose Item',
      content: (
        <>
          <Input
            placeholder="Search items"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ marginBottom: '16px' }}
          />
          <Table
            dataSource={filteredDataSource}
            columns={columns}
            pagination={false}
            rowSelection={{
              selectedRowKeys,
              onChange: handleSelectChange,
            }}
            bordered={false}
            style={{ border: 'none', background: 'white' }}
          />
        </>
      ),
    },
    {
      title: 'Refund',
      content: (
        <>
          <Title level={5}>Refund</Title>
          <Table
            dataSource={selectedItems}
            columns={columns}
            pagination={false}
            bordered={false}
            style={{ border: 'none', background: 'white' }}
          />
        </>
      ),
    },
    ...(hasStockEnabledItems ? [{
      title: 'Restock',
      content: (
        <>
          <Title level={5}>Restock</Title>
          <Table
            dataSource={selectedRowBeforeRefund}
            columns={columns}
            pagination={false}
            bordered={false}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedRowKeysRefund,
              onChange: handleSelectChangeRefund,
            }}
            style={{ border: 'none', background: 'white' }}
          />
        </>
      ),
    }] : []),
  ];

  const handleNext = () => {
    if (currentStep === 1 && !hasStockEnabledItems) {
      lineItemSubmit(selectedItems, selectedItemsRefund, itemStock);
    } else if (currentStep === 1) {
      const stockEnabledItems = selectedItems.filter(item => item.stockEnabled === true);
      if (stockEnabledItems.length === 0) {
        lineItemSubmit(selectedItems, selectedItemsRefund, itemStock);
      } else {
        setSelectedRowBeforeRefund(stockEnabledItems);
        setCurrentStep(currentStep + 1);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Modal
      visible
      onCancel={() => close()}
      footer={null}
      closeIcon={<CloseOutlined style={{ position: 'absolute', left: '16px', top: '16px' }} />}
      bodyStyle={{ borderRadius: '10px' }}
      title="Refund Item & Stock"
      closable
      style={{ borderRadius: '10px', top: 20 }}
      width={800}
    >
      <div style={{ padding: '14px' }}>
        <Steps current={currentStep} style={{ marginBottom: '24px' }}>
          {steps.map(step => (
            <Step key={step.title} title={step.title} />
          ))}
        </Steps>
        <div>
          {steps[currentStep].content}
        </div>
        <div style={{ marginTop: '24px', textAlign: 'right' }}>
          <div className="text-left"> { currentStep === 2 ? 'To restock the item along with the refund, please check the box. If you leave it unchecked, only the refund will be processed' : ''}<br /></div>
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={handlePrev}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" disabled={(currentStep === 0 && !selectedItems.length > 0)} onClick={handleNext}>
              Next
            </Button>


          )}

          {currentStep === steps.length - 1 && (

            <Button type="primary" onClick={() => lineItemSubmit(selectedItems, selectedItemsRefund, itemStock)}>
              {!hasStockEnabledItems ? 'Finish' : currentStep === 2 ? 'Finish' : 'Next'}


            </Button>
          )}

        </div>
      </div>
    </Modal>
  );
};

export default MultiStepModal;
