// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getFilterOperators from 'utils/getFilterOperators';

import { offerActions, inventoryItemsActions, filterParamsActions } from 'store/actions';
import getCouponBackground from 'utils/getCouponBackground';
import getOfferTerms from 'utils/getOfferTerms';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
} from 'appconstants';

import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddOffer from './components/AddOffer';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const OFFER_FILTERS_TYPES = FILTERS_TYPES.OFFERS;

type Props = {
  offers: Array<Object>,
  fetchOffers: Function,
  addOffer: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  setFilterData: Function,
  preserveFilters: Object,
  itemsList: Array,
  fetchInventorySortItems: Function,
};

const OffersList = (props: Props) => {
  const {
    selectedAccount,
    offers,
    loading,
    addOffer,
    fetchOffers,
    totalElements,
    submitting,
    validator,
    test,
    history,
    setFilterData,
    preserveFilters,
    itemsList,
    fetchInventorySortItems,
  } = props;

  const [, forceUpdate] = useState();
  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [addOfferModal, setAddOfferModal] = useState(false);
  const [filterTypes, setFilterTypes] = useState(OFFER_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [filterOffers, setFilterOffers] = useState((preserveFilters && preserveFilters.filterOffers)
    ? (preserveFilters && preserveFilters.filterOffers)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterOffers: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });

  const debouncedFetchOffers = debounce(fetchOffers, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchOffers({ currentPage, sortParams, filterParams });
  };

  const getStatus = (value) => {
    const status = value.isActive ? 'Active' : 'Deactivated';
    return status;
  };

  const exportData = offers ? offers.map((values) => ({
    name: values.code,
    offerTerm: getOfferTerms(values),
    status: getStatus(values),
    maximumRedemption: values.maximumRedemption,
    expiresAt: moment
      .utc(values.expiresAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
  })) : '';

  const exportHeaders = [
    { label: 'OFFER', key: 'name' },
    { label: 'TERMS', key: 'offerTerm' },
    { label: 'STATUS', key: 'status' },
    { label: 'REDEMPTIONS', key: 'maximumRedemption' },
    { label: 'EXPIRES', key: 'expiresAt' },
  ];

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      // inside click
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      // Outside click
      setOpen(false);
    }
  };

  useEffect(() => {
    fetchInventorySortItems('&sortBy=name&direction=ASC');
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setAddOfferModal(false);
    }
  }, [validator]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [offers]);

  const addNewOffer = (offer: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      code,
      startsAt,
      expiresAt,
      valueQuantity,
      claimType,
      claimQuantity,
      claimPercentageOff,
      valueItemGids,
      claimableItemGids,
    } = offer;
    const params = {
      code,
      startsAt,
      expiresAt,
      valueQuantity,
      claimType,
      claimQuantity,
      claimPercentageOff,
      valueItemGids,
      claimableItemGids,
    };
    addOffer({
      params,
      currentPage,
      sortParams,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.OFFERS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.OFFERS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const selectedRecord = (record) => {
    const offerGid = record.gid;
    history.push({
      pathname: `${PAGES.OFFERS}/${offerGid}`,
      state: location.search,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterOffers];
    if (filterOffers.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = OFFER_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterOffers(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterOffers];
    if (e[1] === 'expiresAt') {
      list[index].filterType = 'Date';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = OFFER_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterOffers(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterOffers[index].filterName === 'expiresAt') {
      if (filterOffers[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `expiresAt.GT=${dateStart}&expiresAt.LT=${dateEnd}`;
      } else if (filterOffers[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `expiresAt.LT=${dateStart}`;
      } else if (filterOffers[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `expiresAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterOffers];
    list[index].filterValue = selectedValue;
    setFilterOffers(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterOffers];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'expiresAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'expiresAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterOffers[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterOffers(list);
  };

  const handleAddClick = () => {
    const list = [...filterOffers];
    setFilterOffers([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterOffers([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(OFFER_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.OFFERS);
  };

  const applyFilters = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    const offersFilters = filterOffers.filter((item) => item.checked);
    if (offersFilters[0].filterValue) {
      setCount(offersFilters && offersFilters.length);
      for (let i = 0; i < offersFilters.length; i += 1) {
        if (offersFilters[i].checked) {
          if (offersFilters[i].filterName === 'expiresAt') {
            offersFilters[i] = offersFilters[i].filterValue;
          } else if (offersFilters[i].filterName === 'email') {
            const email = encodeURIComponent(offersFilters[i].filterValue);
            offersFilters[i] = `${offersFilters[i].filterName}.${offersFilters[i].filterOperator}=${email}`;
          } else {
            offersFilters[i] = `${offersFilters[i].filterName}.${offersFilters[i].filterOperator}=${offersFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(offersFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'expiresAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterOffers,
        });
        history.push({
          pathname: PAGES.OFFERS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterOffers,
        });
        history.push({
          pathname: PAGES.OFFERS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const columns = [
    {
      title: 'OFFER',
      dataIndex: 'code',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (code) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {code}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'TERMS',
      dataIndex: 'offerTerm',
      width: '20%',
      align: 'left',
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>{getOfferTerms(record)}</span>
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        const active = record.isActive ? 'Active' : 'Deactivated';
        return (
          <Tooltip placement="top" title="Click here to view details">
            <SpStatusTag style={{ backgroundColor: getCouponBackground(active) }}>{active}</SpStatusTag>
          </Tooltip>
        );
      },
    },
    {
      title: 'REDEMPTIONS',
      dataIndex: 'maximumRedemption',
      width: '20%',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        if (record.redemptionCount) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {record.redemptionCount}
              </span>
            </Tooltip>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'EXPIRES',
      dataIndex: 'expiresAt',
      width: '20%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (expiresAt) => {
        if (expiresAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(expiresAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
            ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]}
          >
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                ghost
                onClick={() => setAddOfferModal(true)}
              >
                {'\u002B'}&nbsp;Add Offer
              </SpButton>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Offers">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Offers Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Offers">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-117px' }}
                  >
                    <Filters
                      filtersGrid={filterOffers}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterOffers.length < OFFER_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              {'\u002B'}&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD OFFER MODAL */}
      {addOfferModal && (
        <AddOffer
          visible={addOfferModal}
          submitting={submitting}
          close={() => setAddOfferModal(false)}
          submit={addNewOffer}
          selectedAccount={selectedAccount}
          itemsList={itemsList}
        />
      )}

      <RBAC
        fallback={(
          <Table
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={offers}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
          ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]}
      >
        <Table
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns]}
          rowKey={(record) => record.gid}
          dataSource={offers}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  offers: state.offers.content,
  totalElements: state.offers.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  itemsList: state.inventoryItems.sortItemsList.content,

});

const mapDispatchToProps = (dispatch) => ({
  fetchOffers: param => dispatch(offerActions.fetchOffers({
    payload: param,
  })),
  addOffer: param => dispatch(offerActions.addOffer({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
  fetchInventorySortItems: param => dispatch(inventoryItemsActions.fetchInventorySortItems({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OffersList);
