// @flow
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
  Icon,
} from 'antd';
import {
  FilterWrapper,
  SpText,
  SpButton,
} from 'components/DesignKit';
import queryString from 'query-string';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import formatNumber from 'utils/formatNumber';
import getFilterOperators from 'utils/getFilterOperators';

import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import getBackgroundColor from 'utils/getBackgroundColor';
import getPayoutStatus from 'utils/getPayoutStatus';
import PaidPayout from 'components/NavIcons/payoutPaid';
import DisablePayout from 'components/NavIcons/disablePayoutPaid';

import {
  PAGES,
  FILTERS_TYPES,
} from 'appconstants';
import { payoutsActions, filterParamsActions } from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import moment from 'moment-timezone';

import UpdatePayout from './UpdatePayout';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const PAYOUTS_FILTERS_TYPES = FILTERS_TYPES.PAYOUTS;

// $FlowFixMe
const StatusTag = styled('div')({
  display: 'inline-block',
  paddingLeft: '10px',
  paddingRight: '10px',
  borderRadius: '10px',
  height: '21px',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  fetchPayouts: Function,
  payouts: Array<Object>,
  loading: boolean,
  test: boolean,
  validator: boolean,
  submitting: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  setFilterData: Function,
  updatePayout: Function,
  preserveFilters: Object,
  role: Object,
};

const PayoutList = (props: Props) => {
  const [pagination, setPagination] = useState({});
  const location = useLocation();
  const {
    loading,
    fetchPayouts,
    totalElements,
    payouts,
    test,
    validator,
    submitting,
    history,
    selectedAccount,
    setFilterData,
    preserveFilters,
    updatePayout,
    role,
  } = props;
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const parsedPayoutType = queryString.parse(location.search);
  const payoutListType = parsedPayoutType['status.EQ'];
  const allPayouts = payoutListType === 'ALL';
  const superUser = ((role && role.name) === 'superuser') || ((role && role.name) === 'internalsupport');
  const [filterTypes, setFilterTypes] = useState(PAYOUTS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterPayouts, setFilterPayouts] = useState((preserveFilters && preserveFilters.filterPayouts)
    ? (preserveFilters && preserveFilters.filterPayouts)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [updatePayoutModal, setUpdatePayoutModal] = useState(false);
  const [selectedPayout, setSelectedPayout] = useState({});
  const currencyType = selectedAccount && selectedAccount.currency;
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? (preserveFilters && preserveFilters.prevSearchParam)
      ? (((preserveFilters && preserveFilters.prevSearchParam) === payoutListType)
        ? queryString.parse(preserveFilters.search)
        : setFilterData({
          pathname,
          search: '',
          filterPayouts: [{
            filterType: '',
            filterName: '',
            filterOperator: '',
            filterValue: '',
            filterNameDisplay: '',
            filterValueFrom: '',
            filterValueTo: '',
            checked: true,
          }],
        }))
      : queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterPayouts: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });

  const exportData = payouts ? payouts.map((values) => ({
    amount: `${values.currencyDto.prefix}${formatNumber((values.amount / 100).toFixed(values.currencyDto.toFixed), currencyType)}`,
    status: getPayoutStatus(values.status),
    bankName: `${values.bankName ? values.bankName : ''} ****${values.bankAccountLastFour}`,
    payoutDate: values.payoutDate ? moment
      .utc(values.payoutDate)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY') : null,
  })) : '';

  exportData.sort((a, b) => {
    if (!a.payoutDate) return -1;
    if (!b.payoutDate) return 1;
    return -1 * (Date.parse(a.payoutDate) - Date.parse(b.payoutDate));
  });

  const exportHeaders = [
    { label: 'AMOUNT', key: 'amount' },
    { label: 'STATUS', key: 'status' },
    { label: 'DESTINATION ', key: 'bankName' },
    { label: 'DATE', key: 'payoutDate' },
  ];

  const debouncedFetchPayouts = debounce(fetchPayouts, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const searchParam = parsed['status.EQ'];
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'payoutDate';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchPayouts({
      searchParam,
      currentPage,
      sortParams,
      filterParams,
    });
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    if (location.search) {
      getData(location.search);
    }
    if (!filterParams) {
      setCount(null);
      setFilterPayouts([{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }]);
    }
  }, [test, selectedAccount, location]);

  const selectedRecord = (record) => {
    const { gid } = record;
    history.push({
      pathname: `${PAGES.PAYOUTS}/${gid}`,
      state: location.search,
    });
  };

  useEffect(() => {
    if (!validator) {
      setUpdatePayoutModal(false);
    }
  }, [validator]);

  const showUpdatePayoutModal = (value: Object) => {
    setSelectedPayout(value);
    setUpdatePayoutModal(true);
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payouts]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const searchParam = parsed['status.EQ'];
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && searchParam && !filterParams) {
      history.push({
        pathname: PAGES.PAYOUTS,
        search: `?status.EQ=${searchParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else if (!searchParam && !filterParams) {
      history.push({
        pathname: PAGES.PAYOUTS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    } else if (searchParam && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.PAYOUTS,
        search: `?status.EQ=${searchParam}&filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterPayouts];
    if (filterPayouts.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = PAYOUTS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterPayouts(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterPayouts];
    if (e[1] === 'payoutDate') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = PAYOUTS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterPayouts(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterPayouts];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterPayouts(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterPayouts(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterPayouts];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterPayouts(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterPayouts(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterPayouts[index].filterName === 'payoutDate') {
      if (filterPayouts[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `payoutDate.GT=${dateStart}&payoutDate.LT=${dateEnd}`;
      } else if (filterPayouts[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `payoutDate.LT=${dateStart}`;
      } else if (filterPayouts[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `payoutDate.GT=${dateStart}`;
      }
    } else if (filterPayouts[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterPayouts];
    list[index].filterValue = selectedValue;
    setFilterPayouts(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterPayouts];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'payoutDate') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'payoutDate') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterPayouts[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterPayouts(list);
  };

  const handleAddClick = () => {
    const list = [...filterPayouts];
    setFilterPayouts([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterPayouts([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(PAYOUTS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.PAYOUTS);
  };

  const applyFilters = () => {
    const payoutsFilters = filterPayouts.filter((item) => item.checked);
    for (let i = 0; i < payoutsFilters.length; i += 1) {
      if (!payoutsFilters[i].filterName || !payoutsFilters[i].filterOperator || !payoutsFilters[i].filterValue) {
        return;
      }
    }
    if (payoutsFilters[0].filterValue || (payoutsFilters[0].filterValueFrom && payoutsFilters[0].filterValueTo)) {
      setCount(payoutsFilters && payoutsFilters.length);
      for (let i = 0; i < payoutsFilters.length; i += 1) {
        if (payoutsFilters[i].checked) {
          if (payoutsFilters[i].filterName === 'payoutDate') {
            payoutsFilters[i] = payoutsFilters[i].filterValue;
          } else if (payoutsFilters[i].filterName === 'amount' && payoutsFilters[i].filterOperator === 'BT') {
            const name = payoutsFilters[i].filterName;
            const from = payoutsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = payoutsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            payoutsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (payoutsFilters[i].filterName === 'amount' && payoutsFilters[i].filterOperator !== 'BT') {
            const value = payoutsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            payoutsFilters[i] = `${payoutsFilters[i].filterName}.${payoutsFilters[i].filterOperator}=${value}`;
          } else {
            payoutsFilters[i] = `${payoutsFilters[i].filterName}.${payoutsFilters[i].filterOperator}=${payoutsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(payoutsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const searchParam = parsed['status.EQ'];
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'payoutDate';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && searchParam && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?status.EQ=${searchParam}&pageNum=${currentPage}${sortParams}&filterParams=${filterParams}`,
          filterPayouts,
          prevSearchParam: searchParam,
        });
        history.push({
          pathname: PAGES.PAYOUTS,
          search: `?status.EQ=${searchParam}&pageNum=${currentPage}${sortParams}&filterParams=${filterParams}`,
        });
      } else if (location.search && searchParam && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?status.EQ=${searchParam}&filterParams=${filterParams}`,
          filterPayouts,
          prevSearchParam: searchParam,
        });
        history.push({
          pathname: PAGES.PAYOUTS,
          search: `?status.EQ=${searchParam}&filterParams=${filterParams}`,
        });
      } else if (location.search && !searchParam && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterPayouts,
          prevSearchParam: searchParam,
        });
        history.push({
          pathname: PAGES.PAYOUTS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const updatePayoutStatus = (payoutData: Object) => {
    const {
      gid,
      referenceNumber,
      ref1,
      ref2,
      ref3,
      ref4,
    } = payoutData;
    const params = {
      referenceNumber,
      ref1: ref1 || null,
      ref2: ref2 || null,
      ref3: ref3 || null,
      ref4: ref4 || null,
    };
    updatePayout({
      params,
      gid,
    });
  };

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '18%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currencyDto.toFixed);
        if (total < 0) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span>
                {`(${record.currencyDto.prefix} ${formatNumber(total, currencyType).replace(/-/g, '')})`}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span> {`${record.currencyDto.prefix} ${formatNumber(total, currencyType)}`}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '18%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <StatusTag style={{ backgroundColor: getBackgroundColor(status) }}>
              {getPayoutStatus(status)}
            </StatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (type) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {type || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DESTINATION',
      dataIndex: 'bankAccountLastFour',
      width: '26%',
      align: 'left',
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            {`${record.bankName ? record.bankName : ''} ****${text}`}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'payoutDate',
      width: '15%',
      align: 'left',
      sorter: (a, b) => {
        if (!a.payoutDate) return 1;
        if (!b.payoutDate) return -1;
        return (Date.parse(a.payoutDate) - Date.parse(b.payoutDate));
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (payoutDate) => {
        if (payoutDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(payoutDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '15%',
    align: 'center',
    render: (text, record) => {
      const { status, type } = record;
      if ((status === 'IN_TRANSIT') && (type !== 'WALLET')) {
        return (
          <span>
            <Icon
              component={PaidPayout}
              style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); showUpdatePayoutModal(record); }}
            />
          </span>
        );
      }
      return (
        <span>
          <Icon
            component={DisablePayout}
            style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); }}
          />
        </span>
      );
    },
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <span>
              {
                exportData !== null
                  ? (
                    <Tooltip placement="left" title="Click here to export Payouts">
                      <CSVLink
                        target="_blank"
                        className="mr-3"
                        filename="Payouts Report.csv"
                        data={exportData}
                        headers={exportHeaders}
                        onMouseEnter={() => setFillColor(true)}
                        onMouseLeave={() => setFillColor(false)}
                      >
                        <ExportIcon
                          fill={fillColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </CSVLink>
                    </Tooltip>
                  ) : null
              }
            </span>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Payouts">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && (
                <FilterWrapper
                  style={{ marginLeft: '-265px' }}
                >
                  <Filters
                    filtersGrid={filterPayouts}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                    handleFromValue={handleFromValue}
                    handleToValue={handleToValue}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterPayouts.length < PAYOUTS_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
            </span>
          </Col>
        </div>
      </Row>
      {/* UPDATE PAYOUT MODAL */}
      {updatePayoutModal && (
        <UpdatePayout
          visible={updatePayoutModal}
          selectedPayout={selectedPayout}
          selectedAccount={selectedAccount}
          submitting={submitting}
          currencyType={currencyType}
          close={() => setUpdatePayoutModal(false)}
          submit={updatePayoutStatus}
        />
      )}
      <Table
        onRow={(record) => ({
          onClick: () => {
            selectedRecord(record);
          },
        })}
        rowClassName={() => 'ant-table-clickable-row'}
        columns={(allPayouts && superUser) ? [...columns, actionColumn] : columns}
        rowKey={(record) => record.gid}
        dataSource={payouts}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  payouts: state.payouts.content,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  totalElements: state.payouts.totalElements,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  preserveFilters: state.filterParams.preserveFilters,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
});

const mapDispatchToProps = (dispatch) => ({
  fetchPayouts: param => dispatch(payoutsActions.fetchPayouts({
    payload: param,
  })),
  updatePayout: param => dispatch(payoutsActions.updatePayout({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PayoutList);
