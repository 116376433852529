import React, { useState, useRef, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  Input,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpText, SpButton, SpError, Page,
} from 'components/DesignKit';
import {
  PAGES,
} from 'appconstants';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { salesforceActions } from 'store/actions';
import LockClose from 'components/NavIcons/lockClose';
import LockOpen from 'components/NavIcons/lockOpen';
import SuccessModal from 'components/SuccessPage';

const { Option } = Select;

type Props = {
  loader: boolean,
  validator: boolean,
  addSalesforce: Function,
};

const Salesforce = (props: Props) => {
  const {
    loader,
    addSalesforce,
    validator,
  } = props;
  const history = useHistory();
  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [hide, setHide] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [environment, setEnvironment] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator({
    autoForceUpdate: {
      forceUpdate,
    },
    validators: {
      validUrl: {
        message: 'The url must be valid',
        // eslint-disable-next-line max-len
        rule: val => (new RegExp('^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$').exec(val) !== null),
      },
    },
  }));

  const addNewSalesforce = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      name,
      url,
      username: userName,
      secureToken: token,
      password,
      environment,
    };
    addSalesforce(params);
    setRedirect(true);
  };

  useEffect(() => {
    if (!validator && redirect) {
      setSuccessModalOpen(true);
      setTimeout(() => {
        history && history.push(PAGES.INTEGRATIONS);
      }, 5000);
    }
  }, [validator]);

  if (loader) {
    return <Loading />;
  }

  return (
    <>
      {
        isSuccessModalOpen ? (
          <Page style={{
            position: 'fixed',
            left: 0,
            top: 0,
            backdropFilter: 'blur(2px)',
            backgroundColor: '',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%',
            height: '100%',
            zIndex: 1024,
            transition: 'opacity .15s linear',
          }}
          >
            <Col>
              <SuccessModal isOpen={isSuccessModalOpen} />
            </Col>
          </Page>
        ) : (
          <Card>
            <>
              <Row type="flex" justify="start" align="middle" className="mt-3 mb-3">
                <Col span={4}>
                  <SpText>Name</SpText>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('name', name, 'required')}
                  </SpError>
                  <div style={{ textAlign: 'left' }}>
                    <SpText color=" #7C7C7C" fontSize="12px" className="mr-2">A name to identify your connection</SpText>
                  </div>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle" className="mt-3 mb-3">
                <Col span={4}>
                  <SpText>Url</SpText>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="URL"
                    value={url}
                    onChange={(e) => setUrl(e.currentTarget.value.trim())}
                  />
                  <SpError>
                    {simpleValidator.current.message('url', url, 'required|validUrl',
                      { messages: { required: 'The url field is required' } })}
                  </SpError>
                  <div style={{ textAlign: 'left' }}>
                    <SpText color=" #7C7C7C" fontSize="12px" className="mr-2">Url of your Salesforce instance</SpText>
                  </div>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle" className="mt-3 mb-3">
                <Col span={4}>
                  <SpText>Username</SpText>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="Username"
                    value={userName}
                    onChange={(e) => setUserName(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('Username', userName, 'required')}
                  </SpError>
                  <div style={{ textAlign: 'left' }}>
                    <SpText color=" #7C7C7C" fontSize="12px" className="mr-2">Username of your salesforce instance</SpText>
                  </div>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle" className="mt-3 mb-3">
                <Col span={4}>
                  <SpText>Secure Token</SpText>
                </Col>
                <Col span={6}>
                  <div className="d-flex">
                    <Input.Password
                      placeholder="Token"
                      autoComplete="new-password"
                      value={token}
                      disabled={hide}
                      onChange={(e) => setToken(e.currentTarget.value)}
                    />
                    <div
                      className="w-35 ml-2"
                      style={{ border: '1px solid #BFC9CD', borderRadius: '5px' }}
                    >
                      <span
                        onClick={() => setHide(!hide)}
                        style={{ cursor: 'pointer' }}
                      >
                        {hide
                          ? (<LockClose />)
                          : (<LockOpen />)}
                      </span>
                    </div>
                  </div>
                  <SpError>
                    {simpleValidator.current.message('secure token', token, 'required')}
                  </SpError>
                  <div style={{ textAlign: 'left' }}>
                    <SpText color=" #7C7C7C" fontSize="12px" className="mr-2">Secure token to authenticate with your connection</SpText>
                  </div>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle" className="mt-3 mb-3">
                <Col span={4}>
                  <SpText>Password</SpText>
                </Col>
                <Col span={6}>
                  <Input.Password
                    placeholder="Password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('password', password, 'required')}
                  </SpError>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle" className="mt-3 mb-3">
                <Col span={4}>
                  <SpText>Environment</SpText>
                </Col>
                <Col span={6}>
                  <Select
                    className="w-100"
                    placeholder="Select Environment"
                    onChange={(e) => setEnvironment(e)}
                  >
                    <Option key={1} value="TEST">TEST</Option>
                    <Option key={2} value="LOGIN">LOGIN</Option>
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('environment', environment, 'required')}
                  </SpError>
                </Col>
              </Row>
              <Row type="flex" justify="end" gutter={[16, 16]}>
                <Col>
                  <SpButton
                    type="secondary"
                    shape="round"
                    className="mr-4"
                    onClick={() => history && history.push(PAGES.INTEGRATIONS)}
                  >
                    Cancel
                  </SpButton>
                  <SpButton
                    type="primary"
                    shape="round"
                    onClick={() => addNewSalesforce()}
                  >
                    Add Integration
                  </SpButton>
                </Col>
              </Row>
            </>
          </Card>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  accountSalesforce: state.salesforce.accountSalesforce,
  salesforceError: state.salesforce.salesforceError,
  loader: state.loading.loading,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  addSalesforce: param => dispatch(salesforceActions.addSalesforce({
    payload: param,
  })),
});


export default connect(mapStateToProps, mapDispatchToProps)(Salesforce);
