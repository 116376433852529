import { ADMIN_REPORTS } from '../actionTypes';

export const fetchAdminReports = (action) => ({
  type: ADMIN_REPORTS.FETCH,
  payload: action.payload,
});

export const addAdminReport = (action) => ({
  type: ADMIN_REPORTS.ADD,
  payload: action.payload,
});
