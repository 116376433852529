import { BANK_STATEMENT } from '../actionTypes';

export const fetchBankStatement = (action) => ({
  type: BANK_STATEMENT.FETCH,
  payload: action.payload,
});

export const addBankStatement = (action) => ({
  type: BANK_STATEMENT.ADD,
  payload: action.payload,
});
