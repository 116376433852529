/* eslint-disable */
import React from 'react';

const kdsdashboard = ({ fill = "silver" }) => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.45 0C1.37304 0 0.5 0.873045 0.5 1.95V8.45C0.5 9.52696 1.37304 10.4 2.45 10.4H11.55C12.627 10.4 13.5 9.52696 13.5 8.45V1.95C13.5 0.873045 12.627 0 11.55 0H2.45ZM11.55 1.3H2.45C2.09101 1.3 1.8 1.59102 1.8 1.95V8.45C1.8 8.80899 2.09101 9.1 2.45 9.1H11.55C11.909 9.1 12.2 8.80898 12.2 8.45V1.95C12.2 1.59101 11.909 1.3 11.55 1.3Z" fill="white" />
    <path d="M2.45 12.35C2.45 11.991 2.74101 11.7 3.1 11.7H10.9C11.259 11.7 11.55 11.991 11.55 12.35C11.55 12.709 11.259 13 10.9 13H3.1C2.74101 13 2.45 12.709 2.45 12.35Z" fill="white" />
    <path d="M4.66666 8L4.2 7.53333L7.61666 4.11667C7.51666 3.88333 7.50277 3.61944 7.575 3.325C7.64722 3.03056 7.80555 2.76667 8.05 2.53333C8.34444 2.23889 8.67222 2.06667 9.03333 2.01667C9.39444 1.96667 9.68889 2.05556 9.91666 2.28333C10.1444 2.51111 10.2333 2.80556 10.1833 3.16667C10.1333 3.52778 9.96111 3.85556 9.66666 4.15C9.43333 4.39444 9.16944 4.55278 8.875 4.625C8.58055 4.69722 8.31666 4.68333 8.08333 4.58333L7.66666 5L10.2 7.53333L9.73333 8L7.2 5.48333L4.66666 8ZM5.65 5.15L4.65 4.15C4.35 3.85 4.2 3.49167 4.2 3.075C4.2 2.65833 4.35 2.3 4.65 2L6.71666 4.08333L5.65 5.15Z" fill="white" />
  </svg>

);

export default kdsdashboard;
