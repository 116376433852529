// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import getPaymentLinkBackground from 'utils/getPaymentLinkBackground';
import getPaymentlinkStatus from 'utils/getPaymentlinkStatus';
import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';
import getUniquePaymentTypes from 'utils/getUniquePaymentTypes';
import getSortedList from 'utils/getSortedList';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Icon,
  Input,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpError,
} from 'components/DesignKit';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import Loading from 'components/Loading';
import {
  paymentsLinkDetailsAction,
  notificationActions,
  paymentsLinkAction,
  invoiceDetailsAction,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
  PAGES,
  ROLES,
  PAYMENTLINK_STATUSES,
  CURRENCY,
  ACQUIRER_TYPES,
} from 'appconstants';
import SimpleReactValidator from 'simple-react-validator';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import RBAC from 'components/rbac';
import Clipboard from 'components/NavIcons/copyclipboard';
import CancelIcon from 'components/NavIcons/CanceledIcon';
import UpdateIcon from 'components/NavIcons/UpdatedIcon';
import CONSTANTS from 'store/constants';

import DeleteSelectedCustomer from './components/deleteSelectedPaymentLink';
import AddPaymentLink from './components/AddPaymentLink';

type Props = {
  fetchPaymentsLinkDetails: Function,
  fetchPayments: Function,
  setNotification: Function,
  updatePaymentsLink: Function,
  paymentsLinkDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  payments: Array<Object>,
  profile: Array<Object>,
  totalElements: number,
  paymentsLinkDetailsStatus: string,
  validator: boolean,
  addPaymentsLink: Function,
  getCustomersList: Function,
  customersList: Array<Object>,
}

const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '75px',
  textAlign: 'center',
});

const PaymentsLinkDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchPaymentsLinkDetails,
    fetchPayments,
    setNotification,
    updatePaymentsLink,
    paymentsLinkDetails,
    loading,
    submitting,
    history,
    payments,
    totalElements,
    selectedAccount,
    paymentsLinkDetailsStatus,
    validator,
    addPaymentsLink,
    getCustomersList,
    customersList,
    profile,
  } = props;
  const { gid } = props.match.params;
  const [, forceUpdate] = useState();
  const { role } = profile;
  const [pagination, setPagination] = useState({});
  const [deletePaymentLinkModal, setDeletePaymentLinkModal] = useState(false);
  const [phoneFormate, setPhoneFormate] = useState('');
  const gidPath = gid.split('-')[1];
  const [addPaymentLinkModal, setAddPaymentLinkModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { id } = selectedAccount && selectedAccount.currency;
  const [phoneCode, setPhoneCode] = useState('');
  const currencyType = selectedAccount && selectedAccount.currency;
  const [showEdit, setShowEdit] = useState(false);
  const [Description, setDescription] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const supportedCurrency = (selectedAccount && selectedAccount.supportedCurrencies);
  const paymentMethods = getUniquePaymentTypes(selectedAccount && selectedAccount.supportedPaymentTypes);
  const acquirerName = (selectedAccount && selectedAccount.acquirer && selectedAccount.acquirer.name);

  simpleValidator.current.purgeFields();

  const getData = (query) => {
    fetchPaymentsLinkDetails(gid);
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchPayments({ currentPage, sortParams, gid });
    getCustomersList();
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSLINK.SUCCESS,
    });
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.PAYMENTS}/${searchParams}`,
      state: location.search,
    });
  };

  const getCustomers = (searchText) => {
    getCustomersList(searchText);
  };

  useEffect(() => {
    if (paymentsLinkDetails.gid && paymentsLinkDetails.phoneNumber) {
      const phone = paymentsLinkDetails.phoneNumber;
      const cntryCode = paymentsLinkDetails.phoneNumber.substring(0, 2);
      if (cntryCode === '+1') {
        setPhoneFormate('+# (###) ###-####');
      } else if (phone.length !== 14 && cntryCode === '+9') {
        setPhoneFormate('+##-##### #####');
      } else if (phone.length === 12 && cntryCode.startsWith('+4')) {
        setPhoneFormate('+##-#########');
      } else if (phone.length === 12 && cntryCode !== '+1') {
        setPhoneFormate('+#-##########');
      } else if (phone.length === 13 && cntryCode !== '+9') {
        setPhoneFormate('+##-##########');
      } else if (phone.length === 14) {
        setPhoneFormate('+###-##########');
      }
    }
    if (paymentsLinkDetails.gid && paymentsLinkDetails.description) {
      setDescription(paymentsLinkDetails.description);
    }
  }, [paymentsLinkDetails]);

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, []);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.PAYMENTLINK);
    }
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.PAYMENTLINK);
    } else if (!validator) {
      setAddPaymentLinkModal(false);
    }
  }, [validator]);

  useEffect(() => {
    if (!validator) {
      setShowEdit(false);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments]);

  const addNewPaymentLink = (payment: Object) => {
    const {
      name,
      email,
      phoneNumber,
      countryCode,
      amount,
      notify,
      description,
      redirectUrl,
      paymentMethodData,
      expiryDate,
      customerGid,
      applicationFee,
      serviceFee,
      serviceFeeEnabled,
      currencyCode,
      canDecline,
    } = payment;
    const currency = amount.replace(/,/g, '');
    const payloadApplicationFee = applicationFee ? applicationFee.replace(/[^\d]/g, '') : '';
    const payloadserviceFee = serviceFee ? serviceFee.replace(/[^\d]/g, '') : '';
    const data = {
      amount: Number(currency * 100),
      notificationType: notify,
      redirectUri: redirectUrl,
      description,
      canDecline,
      currencyCode: currencyCode || CURRENCY(id),
      paymentMethodType: paymentMethodData,
      expiresAt: expiryDate,
      applicationFee: payloadApplicationFee ? Number(payloadApplicationFee) : null,
      serviceFee: payloadserviceFee ? Number(payloadserviceFee) : null,
      serviceFeeEnabled,
    };
    setRedirect(true);
    if (customerGid) {
      data.customerGid = customerGid;
    } else {
      data.customer = {
        email,
        name,
        phoneNumber: phoneNumber ? `${countryCode}${phoneNumber}` : null,
        taxId: 'NONE',
        taxStatus: 'exempt',
        taxValue: null,
      };
    }
    addPaymentsLink({ ...data });
  };

  const editNewPaymentLink = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      description: Description,
    };
    updatePaymentsLink({
      gid,
      params,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.PAYMENTLINK}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const showDeletePaymentLinkModal = () => {
    setDeletePaymentLinkModal(true);
  };

  const deleteSelectedPaymentLink = async (paymentLinkGid: string) => {
    try {
      await axiosAuthInstance.patch(`${API_END_POINTS.PAYMENTLINK}/${paymentLinkGid}/cancel`);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.PAYMENTSLINK.DELETE_SUCCESS,
      });
      history.push(PAGES.PAYMENTLINK);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data && e.response.data.message,
      });
    } finally {
      setDeletePaymentLinkModal(false);
    }
  };

  const columns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net, record) => {
        const total = (net / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix}${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '25%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <StatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </StatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '25%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? (record.paymentMethod.upi.vpa || 'UPI')
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.googlePayUS) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.googlePayUS.paymentMethodInfo && record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails)
                  ? (`**** ${record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails}`)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.instantPayment && record.paymentMethod.instantPayment.walletAlias) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.instantPayment.walletAlias.alias ? (record.paymentMethod.instantPayment.walletAlias.alias)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType) {
          const QrName = (record.paymentMethod.paymentType && record.paymentMethod.paymentType.name);
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(QrName === 'QR') ? QrName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'paymentDate',
      width: '25%',
      align: 'left',
      render: (paymentDate) => {
        if (paymentDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(paymentDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const terminalColumns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net, record) => {
        const total = (net / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <StatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </StatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? (record.paymentMethod.upi.vpa || 'UPI')
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.googlePayUS) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.googlePayUS.paymentMethodInfo && record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails)
                  ? (`**** ${record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails}`)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.instantPayment && record.paymentMethod.instantPayment.walletAlias) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.instantPayment.walletAlias.alias ? (record.paymentMethod.instantPayment.walletAlias.alias)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType) {
          const QrName = (record.paymentMethod.paymentType && record.paymentMethod.paymentType.name);
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(QrName === 'QR') ? QrName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'TERMINAL NAME',
      dataIndex: 'terminal',
      width: '20%',
      align: 'left',
      render: (terminal) => {
        if (terminal && terminal.deviceName) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {terminal && terminal.deviceName}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        paymentsLinkDetails.gid && paymentsLinkDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  {paymentsLinkDetails.name
                    ? <SpText className="text-uppercase mr-4" fontSize="20px">{paymentsLinkDetails.name}</SpText>
                    : <>&#8211;</>}
                </Col>
                {
                  paymentsLinkDetails.status === PAYMENTLINK_STATUSES.ACTIVECANCEL ? (
                    <>
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <Col>
                          <SpButton
                            type="secondary"
                            className="mr-4"
                            shape="round"
                            ghost
                            onClick={() => setAddPaymentLinkModal(true)}
                          >
                            Duplicate Payment Link
                          </SpButton>
                          <SpButton
                            type="danger"
                            shape="round"
                            onClick={showDeletePaymentLinkModal}
                          >
                            Cancel Payment Link
                          </SpButton>
                        </Col>
                      </RBAC>
                    </>
                  ) : (
                    <>
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <Col>
                          <SpButton
                            type="secondary"
                            className="mr-4"
                            shape="round"
                            ghost
                            onClick={() => setAddPaymentLinkModal(true)}
                          >
                            Duplicate Payment Link
                          </SpButton>
                        </Col>
                      </RBAC>
                    </>
                  )
                }
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Amount</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {paymentsLinkDetails.amount < 0
                        ? `(${paymentsLinkDetails.currency.prefix} 
                      ${formatNumber(
                          (paymentsLinkDetails.amount / 100).toFixed(paymentsLinkDetails.currency.toFixed).replace(/-/g, ''), currencyType,
                        )})`
                        : paymentsLinkDetails.amount
                          ? `${paymentsLinkDetails.currency.prefix} 
                      ${formatNumber((paymentsLinkDetails.amount / 100).toFixed(paymentsLinkDetails.currency.toFixed), currencyType)}`
                          : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              {
                (paymentsLinkDetails && paymentsLinkDetails.serviceFeeEnabled) === true ? (
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={3}>
                        <span>Service Fee</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>
                          {paymentsLinkDetails.serviceFee < 0
                            ? `(${paymentsLinkDetails.currency.prefix} 
                      ${formatNumber(
                              (paymentsLinkDetails.serviceFee / 100).toFixed(paymentsLinkDetails.currency.toFixed).replace(/-/g, ''), currencyType,
                            )})`
                            : paymentsLinkDetails.serviceFee
                              ? `${paymentsLinkDetails.currency.prefix} 
                      ${formatNumber((paymentsLinkDetails.serviceFee / 100).toFixed(paymentsLinkDetails.currency.toFixed), currencyType)}`
                              : <>&#8211;</>}
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                ) : null
              }
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Description</span>
                  </Col>
                  {!showEdit && (
                    <Col span={6}>
                      {paymentsLinkDetails.description
                        ? <SpH5>{paymentsLinkDetails.description}</SpH5>
                        : <>&#8211;</>}
                      <Tooltip placement="top" title="Edit">
                        <Icon
                          type="edit"
                          className="ml-3"
                          style={{ fontSize: '20px', cursor: 'pointer' }}
                          onClick={() => { setShowEdit(!showEdit); }}
                        />
                      </Tooltip>
                    </Col>
                  )}
                  {showEdit && (
                    <Col span={6}>
                      <div className="w-85 mt-2 d-flex">
                        <Input
                          placeholder="Description"
                          value={Description}
                          onChange={(e) => setDescription(e.currentTarget.value)}
                        />
                        <SpButton
                          className="ml-2 pl-2 pr-2"
                          type="primary"
                          borderRadius="none"
                          onClick={editNewPaymentLink}
                        >
                          <UpdateIcon />
                        </SpButton>
                        <SpButton
                          className="ml-2 pl-2 pr-2"
                          type="secondary"
                          borderRadius="none"
                          style={{ borderColor: '#C4C4C4' }}
                          onClick={() => {
                            setShowEdit(!showEdit);
                            setDescription(Description);
                            simpleValidator.current.hideMessages();
                            forceUpdate();
                          }}
                        >
                          <CancelIcon />
                        </SpButton>
                      </div>
                      <SpError>
                        {simpleValidator.current.message('description', Description, 'required')}
                      </SpError>
                    </Col>
                  )}
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Email</span>
                  </Col>
                  <Col span={9}>
                    {paymentsLinkDetails.email
                      ? <SpH5>{paymentsLinkDetails.email}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Phone</span>
                  </Col>
                  <Col span={6}>
                    {paymentsLinkDetails.phoneNumber
                      ? (
                        <NumberFormat
                          className="numFormate"
                          value={paymentsLinkDetails.phoneNumber}
                          format={phoneFormate}
                          mask="_"
                          disabled
                        />
                      )
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Due Date</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {
                        paymentsLinkDetails.expiresAt && selectedAccount && selectedAccount.timezone
                          ? (
                            moment
                              .utc(paymentsLinkDetails.expiresAt)
                              .tz(selectedAccount.timezone)
                              .format('MMM DD, YYYY')
                          )
                          : <>&#8211;</>
                      }
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Redirect Url</span>
                  </Col>
                  <Col span={6}>
                    {paymentsLinkDetails.redirectUri
                      ? <SpH5>{paymentsLinkDetails.redirectUri}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              {
                (role && role.name === ROLES.PARTNER) ? (
                  <>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={24}>
                          <Col span={3}>
                            <span>Status</span>
                          </Col>
                          <Col span={6}>
                            {paymentsLinkDetails.status
                              ? (
                                <StatusTag style={{ backgroundColor: getPaymentLinkBackground(paymentsLinkDetails.status) }}>
                                  {getPaymentlinkStatus(paymentsLinkDetails.status)}
                                </StatusTag>
                              )
                              : <>&#8211;</>}
                          </Col>
                        </Col>
                        <Col span={24} className="d-flex justify-content-end">
                          <SpH5 color="#7f7f7f">{paymentsLinkDetails.gid}</SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                ) : (
                  <>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={3}>
                          <span>Status</span>
                        </Col>
                        <Col span={6}>
                          {paymentsLinkDetails.status
                            ? (
                              <StatusTag style={{ backgroundColor: getPaymentLinkBackground(paymentsLinkDetails.status) }}>
                                {getPaymentlinkStatus(paymentsLinkDetails.status)}
                              </StatusTag>
                            )
                            : <>&#8211;</>}
                        </Col>
                      </Row>
                    </SpForm>
                    <SpForm>
                      <Row type="flex" justify="start">
                        <Col span={24}>
                          <Col span={3}>
                            <span>Link</span>
                          </Col>
                          <Col span={12}>
                            {paymentsLinkDetails.link && paymentsLinkDetails.status === 'ACTIVE'
                              ? (
                                <SpH5>{paymentsLinkDetails.link}
                                  <SpButton
                                    type="secondary"
                                    className="ml-2"
                                    borderRadius="50px"
                                    onClick={() => { copyCode(paymentsLinkDetails.link); }}
                                  >
                                    <Clipboard />
                                  </SpButton>
                                </SpH5>
                              )
                              : (
                                <>&#8211;</>
                              )}
                          </Col>
                        </Col>
                        <Col span={24} className="d-flex justify-content-end">
                          <SpH5 color="#7f7f7f">{paymentsLinkDetails.gid}</SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                )
              }
              {/* ADD PAYMENTLINK MODAL */}
              {addPaymentLinkModal && (
                <AddPaymentLink
                  visible={addPaymentLinkModal}
                  submitting={submitting}
                  phoneCode={phoneCode}
                  id={id}
                  selectedAccount={selectedAccount}
                  paymentsLinkDetails={paymentsLinkDetails}
                  close={() => setAddPaymentLinkModal(false)}
                  submit={addNewPaymentLink}
                  customersList={getSortedList(customersList)}
                  role={profile && profile.role}
                  paymentMethods={paymentMethods}
                  supportedCurrency={supportedCurrency}
                  getCustomers={getCustomers}
                />
              )}
              {/* DELETE LOCATION MODAL */}
              {deletePaymentLinkModal && (
                <DeleteSelectedCustomer
                  visible={deletePaymentLinkModal}
                  paymentsLinkDetails={paymentsLinkDetails}
                  submitting={submitting}
                  close={() => setDeletePaymentLinkModal(false)}
                  submit={deleteSelectedPaymentLink}
                />
              )}
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">Payment Sessions</SpText>
                </Col>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={
                  (acquirerName && acquirerName === ACQUIRER_TYPES.IMSPOS) ? terminalColumns : columns
                }
                rowKey={(record) => record.gid}
                dataSource={payments}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  paymentsLinkDetails: state.paymentsLinkDetails,
  paymentsLinkDetailsStatus: state.paymentsLinkDetails.paymentsLinkDetailsStatus,
  payments: state.paymentsLinkDetails.payments.content,
  totalElements: state.paymentsLinkDetails.payments ? state.paymentsLinkDetails.payments.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  profile: state.subscription.profile,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  customersList: state.invoiceDetails.customersList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentsLinkDetails: gid => dispatch(paymentsLinkDetailsAction.fetchPaymentsLinkDetails({
    payload: gid,
  })),
  fetchPayments: param => dispatch(paymentsLinkDetailsAction.fetchPayments({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  addPaymentsLink: param => dispatch(paymentsLinkAction.addPaymentsLink({
    payload: param,
  })),
  updatePaymentsLink: param => dispatch(paymentsLinkDetailsAction.updatePaymentsLink({
    payload: param,
  })),
  getCustomersList: param => dispatch(invoiceDetailsAction.getCustomersList({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsLinkDetails);
