import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Input,
  Select,
} from 'antd';
import {
  SpText,
  SpForm,
  SpButton,
  SpError,
  SpStatusTag,
} from 'components/DesignKit';
import { useHistory } from 'react-router-dom';
import SponserICICI from 'components/NavIcons/SponserICICI';
import SimpleReactValidator from 'simple-react-validator';
import Loading from 'components/Loading';
import {
  PAGES,
  MESSAGES,
  BANK_TRANSFER_STATUSES,
  BENE_TRANSFER_TYPE,
  BANK_TRANSFER_ACCOUNT_TYPE,
} from 'appconstants';
import {
  bankTransfersActions,
} from 'store/actions';
import getBankTransferBackground from 'utils/getBankTransferBackground';
import getBankTransferStatus from 'utils/getBankTransferStatus';

const { Option } = Select;

type Props = {
  loading: boolean,
  addIcicCustomer: Function,
  selectedAccount: Object,
  accounts: object,
  fetchAccountByID: Function,
  createIciciFundingSource: Function,
};

const BankTransfer = (props: Props) => {
  const {
    loading,
    addIcicCustomer,
    selectedAccount,
    accounts,
    fetchAccountByID,
    createIciciFundingSource,
  } = props;

  const [, forceUpdate] = useState();
  const [status, setStatus] = useState('');
  const history = useHistory();
  const [existingBank, setExistingBank] = useState(false);
  const [newBank, setNewBank] = useState(false);
  const [corpId, setCorpId] = useState('');
  const [userId, setUserId] = useState('');
  const [aliasId, setAliasId] = useState('');
  const [accountTypeName, setAccountTypeName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankName, setBankName] = useState('ICICI Bank');
  const [beneType, setBeneType] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const accountRegex = /[0-9]{6,17}/;
  const { gid } = selectedAccount;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      accountNumber: {
        message: MESSAGES.BANK_DETAILS.VALID,
        rule: val => accountRegex.test(val),
      },
    },
  }));

  useEffect(() => {
    fetchAccountByID(gid);
  }, []);

  useEffect(() => {
    if (accounts && accounts.iciciCustomer && accounts.iciciCustomer.gid) {
      setStatus(accounts.iciciCustomerStatus);
      setCorpId(accounts.iciciCustomer.corpId);
      setUserId(accounts.iciciCustomer.userId);
    }
  }, [accounts]);

  useEffect(() => {
    simpleValidator.current.hideMessages();
    forceUpdate();
  }, [accounts]);

  useEffect(() => {
    if (accounts && accounts.fundingSource && accounts.fundingSource.gid) {
      setAccountNumber(`*****${accounts.fundingSource.issuerBank.lastFour}`);
      setIfscCode(accounts.fundingSource.issuerBank.routingNumber);
      setAccountType(accounts.fundingSource.issuerBank.accountType);
      const typeOfAccount = BANK_TRANSFER_ACCOUNT_TYPE.filter((item) => item.value === accounts.fundingSource.issuerBank.accountType);
      setAccountTypeName(typeOfAccount[0].name);
      setBankName(accounts.fundingSource.issuerBank.bankName);
    }
  }, [accounts]);

  const verifyBankTranfer = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      corpId,
      userId,
      aliasId,
    };
    addIcicCustomer({
      gid,
      params,
    });
  };

  const addFundingSource = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      issuerBank: {
        countryCode: selectedAccount.country.alpha2,
        routingNumber: ifscCode,
        accountNumber,
        accountType,
        bankName,
        beneType,
      },
    };
    createIciciFundingSource({
      gid,
      params,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card style={{ height: '800px' }}>
      {
        !status && !existingBank && !newBank && (
          <>
            <Row type="flex" justify="space-between">
              <Col>
                <SpText className="mr-4" fontSize="20px">Bank Transfers</SpText>
                <SpStatusTag style={{ backgroundColor: getBankTransferBackground(status) }}>
                  {getBankTransferStatus(status)}
                </SpStatusTag>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4">
                <SpText className="mr-4" fontSize="14px" fontWeight="600">Do you have an ICICI Bank account?</SpText>
              </Col>
              <Col className="mt-4">
                <SpButton
                  type="secondary"
                  shape="round"
                  className="mr-4"
                  onClick={() => setNewBank(true)}
                >
                  No
                </SpButton>
                <SpButton
                  type="primary"
                  shape="round"
                  onClick={() => setExistingBank(true)}
                >
                  Yes
                </SpButton>
              </Col>
            </Row>
          </>
        )
      }

      {
        !status && existingBank && (
          <>
            <Row type="flex" justify="space-between">
              <Col>
                <SpText className="mr-4" fontSize="20px">Bank Transfers</SpText>
                <SpStatusTag style={{ backgroundColor: getBankTransferBackground(status) }}>
                  {getBankTransferStatus(status)}
                </SpStatusTag>
              </Col>
            </Row>
            <SpForm>
              <Row className="mr-4 mt-4">
                <Col>Corp ID</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="Corp ID"
                    defaultValue={corpId}
                    onChange={e => setCorpId(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('corp Id', corpId, 'required')}
                  </SpError>
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>User ID</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="User ID"
                    defaultValue={userId}
                    onChange={e => setUserId(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('user Id', userId, 'required')}
                  </SpError>
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>Alias ID</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="Alias ID"
                    defaultValue={aliasId}
                    onChange={e => setAliasId(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('alias Id', aliasId, 'required')}
                  </SpError>
                </Col>
              </Row>
            </SpForm>
            <Row type="flex" justify="end" gutter={[16, 16]}>
              <Col>
                <SpButton
                  type="secondary"
                  shape="round"
                  onClick={() => setExistingBank(false)}
                >
                  Cancel
                </SpButton>
              </Col>
              <Col>
                <SpButton
                  type="primary"
                  shape="round"
                  onClick={verifyBankTranfer}
                >
                  Save
                </SpButton>
              </Col>
            </Row>
          </>
        )
      }

      {
        newBank && (
          <>
            <Row type="flex" justify="space-between">
              <Col>
                <SpText className="mr-4" fontSize="20px">Bank Transfers</SpText>
                <SpStatusTag style={{ backgroundColor: getBankTransferBackground(status) }}>
                  {getBankTransferStatus(status)}
                </SpStatusTag>
              </Col>
            </Row>
            <SpForm>
              <Row className="mt-4">
                <Col>
                  <SpText fontSize="14px" fontWeight="600">
                    Currently, Swirepay only supports bank transfers using ICICI Bank accounts.
                    New Feature to create an ICICI Bank current account using Swirepay dashboard is coming soon.
                  </SpText>
                </Col>
              </Row>
            </SpForm>
            <Row type="flex" gutter={[16, 16]}>
              <Col>
                <SpButton
                  type="secondary"
                  shape="round"
                  onClick={() => setNewBank(false)}
                >
                  Back
                </SpButton>
              </Col>
            </Row>
          </>
        )
      }

      {
        status && status === BANK_TRANSFER_STATUSES.BANK_VERIFICATION_PENDING && (
          <>
            <Row type="flex" justify="space-between">
              <Col>
                <SpText className="mr-4" fontSize="20px">Bank Transfers</SpText>
                <SpStatusTag style={{ backgroundColor: getBankTransferBackground(status) }}>
                  {getBankTransferStatus(status)}
                </SpStatusTag>
              </Col>
            </Row>
            <SpForm>
              <Row className="mr-4 mt-4">
                <Col>Corp ID</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="Corp ID"
                    defaultValue={corpId}
                    disabled
                    onChange={e => setCorpId(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('corp Id', corpId, 'required')}
                  </SpError>
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>User ID</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="User ID"
                    defaultValue={userId}
                    disabled
                    onChange={e => setUserId(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('user Id', userId, 'required')}
                  </SpError>
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>Bene Type</Col>
                <Col className="mt-2" span={6}>
                  <Select
                    className="w-100"
                    showSearch
                    value={beneType || 'Select Bene Type'}
                    onChange={(value) => {
                      setBeneType(value[0]);
                    }}
                  >
                    {
                      BENE_TRANSFER_TYPE.map((item) => (
                        <Option key={item.id} value={[item.value, item.name]}>{item.name}</Option>
                      ))
                    }
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('bene type', beneType, 'required')}
                  </SpError>
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>Account Type</Col>
                <Col className="mt-2" span={6}>
                  <Select
                    className="w-100"
                    showSearch
                    value={accountTypeName || 'Select Account Type'}
                    onChange={(value) => {
                      setAccountType(value[0]);
                      setAccountTypeName(value[1]);
                    }}
                  >
                    {
                      BANK_TRANSFER_ACCOUNT_TYPE.map((item) => (
                        <Option key={item.id} value={[item.value, item.name]}>{item.name}</Option>
                      ))
                    }
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('account type', accountType, 'required')}
                  </SpError>
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>Account Number</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="Account Number"
                    defaultValue={accountNumber}
                    onChange={e => setAccountNumber(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('account Number', accountNumber, 'required|accountNumber')}
                  </SpError>
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>Bank Name</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="Bank Name"
                    defaultValue={bankName}
                    disabled
                    onChange={e => setBankName(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('Bank Name', bankName, 'required')}
                  </SpError>
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>IFSC Code</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="IFSC Code"
                    defaultValue={ifscCode}
                    onChange={e => setIfscCode(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('ifsc code', ifscCode, 'required')}
                  </SpError>
                </Col>
              </Row>
            </SpForm>
            <Row type="flex" justify="end" gutter={[16, 16]}>
              <Col>
                <SpButton
                  type="secondary"
                  shape="round"
                  onClick={() => history.push(PAGES.SETTINGS)}
                >
                  Cancel
                </SpButton>
              </Col>
              <Col>
                <SpButton
                  type="primary"
                  shape="round"
                  onClick={addFundingSource}
                >
                  Save
                </SpButton>
              </Col>
            </Row>
          </>
        )
      }

      {
        status
        && (status === BANK_TRANSFER_STATUSES.VERIFICATION_PENDING || status === BANK_TRANSFER_STATUSES.VERIFIED)
        && (
          <>
            <Row type="flex" justify="space-between">
              <Col>
                <SpText className="mr-4" fontSize="20px">Bank Transfers</SpText>
                <SpStatusTag style={{ backgroundColor: getBankTransferBackground(status) }}>
                  {getBankTransferStatus(status)}
                </SpStatusTag>
              </Col>
            </Row>
            <SpForm>
              <Row className="mr-4 mt-4">
                <Col>Corp ID</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="Corp ID"
                    defaultValue={corpId}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>User ID</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="User ID"
                    defaultValue={userId}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>Account Type</Col>
                <Col className="mt-2" span={6}>
                  <Select
                    className="w-100"
                    showSearch
                    value={accountTypeName}
                    disabled
                  >
                    <Option key={1} value={['CURRENTACCOUNT', 'Current Account']}>Current Account</Option>
                    <Option key={2} value={['SAVINGS', 'Savings']}>Savings</Option>
                  </Select>
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>Account Number</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="Account Number"
                    disabled
                    defaultValue={accountNumber}
                  />
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>Bank Name</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="Bank Name"
                    defaultValue={bankName}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mr-4 mt-4">
                <Col>IFSC Code</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    placeholder="IFSC Code"
                    disabled
                    defaultValue={ifscCode}
                  />
                </Col>
              </Row>
            </SpForm>
          </>
        )
      }
      <Row type="flex" justify="center">
        <Col
          style={{
            position: 'absolute',
            bottom: '10px',
          }}
        >
          <SponserICICI />
        </Col>
      </Row>

    </Card>
  );
};

const mapStateToProps = state => ({
  loading: state.loading.loading,
  validator: state.loading.validator,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  bankTransfers: state.bankTransfers,
  accounts: state.bankTransfers.account,
});

const mapDispatchToProps = dispatch => ({
  addIcicCustomer: payload => dispatch(bankTransfersActions.addIcicCustomer({
    payload,
  })),
  fetchAccountByID: payload => dispatch(bankTransfersActions.fetchAccountByID({
    payload,
  })),
  createIciciFundingSource: payload => dispatch(bankTransfersActions.createIciciFundingSource({
    payload,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BankTransfer);
