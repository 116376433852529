import { REPORT_TEMPLATE_DETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  reportTemplatesDetailsStatus: undefined,
  reportTemplatesDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REPORT_TEMPLATE_DETAILS.FETCH:
      return {
        ...state,
        reportTemplatesDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case REPORT_TEMPLATE_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        reportTemplatesDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case REPORT_TEMPLATE_DETAILS.FAILED:
      return {
        ...state,
        reportTemplatesDetailsError: action.payload,
        reportTemplatesDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
