// @flow
import React, { useState } from 'react';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

import { ROLES } from 'appconstants';

const { Option } = Select;

type Props = {
  roles: Array<Object>,
  close: Function,
  submit: Function,
  visible: boolean,
  selectedUser: Object,
};

const EditUser = (props: Props) => {
  const {
    roles,
    visible,
    close,
    submit,
    selectedUser,
  } = props;

  const { internalRole, user } = selectedUser;

  const [role, setRole] = useState(internalRole && internalRole.id);

  const validate = (event) => {
    event.preventDefault();
    submit({
      role,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Update</SpButton>,
      ]}
      title="Update Internal User"
    >
      <div className="px-4">
        <div className="d-flex">
          <div className="mb-3 mr-3">
            <label htmlFor=""><SpH5>First Name</SpH5></label>
            <Input
              placeholder="First name"
              value={user && user.givenName}
              disabled
            />
          </div>

          <div className="mb-3">
            <label htmlFor=""><SpH5>Last Name</SpH5></label>
            <Input
              placeholder="Last name"
              value={user && user.familyName}
              disabled
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Account Email</SpH5></label>
          <Input
            placeholder="User Email"
            value={user && user.email}
            disabled
          />
        </div>

        <div className="pb-3">
          <label className="d-block"><SpH5>Assign Role</SpH5></label>
          <Select
            style={{ width: '50%' }}
            placeholder="Please select"
            className="text-capitalize"
            defaultValue={(internalRole && internalRole.name)}
            onChange={(value) => setRole(value)}
          >
            {
              roles.filter(allRoles => allRoles.name !== ROLES.SUPERUSER).map(r => (
                <Option
                  key={1}
                  value={r.id}
                  className="text-capitalize"
                >
                  { r.name }
                </Option>
              ))
            }
          </Select>
        </div>
      </div>
    </Modal>
  );
};

export default EditUser;
