import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import DonationPagesList from './components/DonationPagesList';
// import PaymentPagesDetails from '../PaymentPages/components/PaymentPagesDetails';

const DonationPages = () => (
  <Switch>
    <Route exact path="/donation-pages" component={DonationPagesList} />
    {/* <Route exact path="/donation-links/:gid" component={PaymentPagesDetails} /> */}
  </Switch>
);

export default DonationPages;
