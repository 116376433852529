import { PAYMENTWIDGETSDETAILS } from '../actionTypes';

export const fetchPaymentWidgetDetails = (action) => ({
  type: PAYMENTWIDGETSDETAILS.FETCH,
  payload: action.payload,
});

export const fetchPayments = (action) => ({
  type: PAYMENTWIDGETSDETAILS.FETCH_PAYMENTS,
  payload: action.payload,
});

export const fetchSubscriptions = (action) => ({
  type: PAYMENTWIDGETSDETAILS.FETCH_SUBSCRIPTIONS,
  payload: action.payload,
});

export const deactivePaymentWidget = (action) => ({
  type: PAYMENTWIDGETSDETAILS.DELETE,
  payload: action.payload,
});

export const updatePaymentWidget = (action) => ({
  type: PAYMENTWIDGETSDETAILS.UPDATE,
  payload: action.payload,
});

export const fetchPaymentWidgetSlug = (action) => ({
  type: PAYMENTWIDGETSDETAILS.SLUG_FETCH,
  payload: action.payload,
});
