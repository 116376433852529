// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';


import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getSubscriptionButtonBackground from 'utils/getSubscriptionButtonBackground';
import getSubscriptionButtonStatus from 'utils/getSubscriptionButtonStatus';
import getSubscriptionBackground from 'utils/getSubscriptionBackground';
import getSubscriptionStatus from 'utils/getSubscriptionStatus';
import getFrequency from 'utils/getFrequency';
import getBillingFrequency from 'utils/getBillingFrequency';
import getDiscountOff from 'utils/getDiscountOff';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Icon,
  Input,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
  SpError,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  subscriptionButtonV2DetailsActions,
  notificationActions,
  subscriptionAction,
  invoiceDetailsAction,
  subscriptionButtonActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
  PAGES,
  ROLES,
  SUBSCRIPTION_BUTTON_STATUS,
  RATE_TYPES,
} from 'appconstants';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import SimpleReactValidator from 'simple-react-validator';
import RBAC from 'components/rbac';
import Clipboard from 'components/NavIcons/copyclipboard';
import CancelIcon from 'components/NavIcons/CanceledIcon';
import UpdateIcon from 'components/NavIcons/UpdatedIcon';
import CONSTANTS from 'store/constants';

import DeleteSubscriptionButton from './components/deleteSubscriptionButton';
import CopySubscriptionButton from './components/copySubscriptionButton';
import AddSubscriptionButton from './components/AddSubscriptionButton';
import SelectCoupon from './components/SelectCoupon';
import AddCoupon from './components/AddNewCoupon';
import SelectTaxrate from './components/SelectTaxrate';
import AddTaxrate from './components/AddNewTax';


const payButton = 'https://s3.ap-south-1.amazonaws.com/assets.swirepay.com/img/pay-button.png';

type Props = {
  fetchSubscriptionButtonV2Details: Function,
  fetchSubscriptionButtonV2Payments: Function,
  setNotification: Function,
  fetchPlanSearch: Function,
  addSubscriptionButton: Function,
  updateSubscriptionButtonV2: Function,
  subscriptionButtonDetails: Object,
  taxList: Array<Object>,
  loading: boolean,
  selectedAccount: Object,
  addNewTaxRate: Function,
  getTaxes: Function,
  planResult: Object,
  submitting: boolean,
  plans: Array<Object>,
  getCustomersList: Function,
  customersList: Array<Object>,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  payments: Array<Object>,
  newTaxrate: Object,
  totalElements: number,
  newCoupon: Object,
  addNewCoupon: Function,
  subscriptionButtonDetailsStatus: string,
  validator: boolean,
  DeactiveSubscriptionV2Button: Function,
}

const SubscriptionButtonDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchSubscriptionButtonV2Details,
    fetchSubscriptionButtonV2Payments,
    addSubscriptionButton,
    getCustomersList,
    fetchPlanSearch,
    setNotification,
    updateSubscriptionButtonV2,
    subscriptionButtonDetails,
    loading,
    submitting,
    addNewTaxRate,
    customersList,
    getTaxes,
    history,
    payments,
    taxList,
    plans,
    planResult,
    addNewCoupon,
    newCoupon,
    newTaxrate,
    totalElements,
    selectedAccount,
    subscriptionButtonDetailsStatus,
    validator,
    DeactiveSubscriptionV2Button,
  } = props;
  const { gid } = props.match.params;
  const [, forceUpdate] = useState();
  const [pagination, setPagination] = useState({});
  const [deleteSubscriptionButtonModal, setDeleteSubscriptionButtonModal] = useState(false);
  const [addSubscriptionButtonModal, setAddSubscriptionButtonModal] = useState(false);
  const [redirect, SetRedirect] = useState(false);
  const [copySubscriptionButtonModal, setCopySubscriptionButtonModal] = useState(false);
  const { prefix } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;
  const { id } = selectedAccount && selectedAccount.currency;
  const [showEdit, setShowEdit] = useState(false);
  const [Description, setDescription] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [planList, setPlanList] = useState([]);
  const [updatingCoupon, setUpdatedCoupon] = useState({});
  const [selectCouponModal, setSelectCouponModal] = useState(false);
  const [addCouponModal, setAddCouponModal] = useState(false);
  const [couponRedirect, setCouponRedirect] = useState(false);
  const [selectTaxrateModal, setSelectTaxrateModal] = useState(false);
  const [allSelectedTaxrates, setAllSelectedTaxrates] = useState([]);
  const [addTaxModal, setAddTaxModal] = useState(false);
  const [taxRedirect, setTaxRedirect] = useState(false);
  const [allTaxes, setAllTaxes] = useState(taxList);
  const dateFormat = 'MMM DD, YYYY';
  const displayDate = (subscriptionButtonDetails.planStartDate) ? moment(subscriptionButtonDetails.planStartDate).format(dateFormat) : null;
  const planData = {
    planList,
  };
  const typeOfCurrency = selectedAccount && selectedAccount.currency.name;
  const allTaxrates = subscriptionButtonDetails.taxAmount ? subscriptionButtonDetails.taxAmount : [];
  const updatedCoupon = subscriptionButtonDetails.coupon ? subscriptionButtonDetails.coupon : {};
  const supportedCurrency = (selectedAccount && selectedAccount.supportedCurrencies);
  const discountAmount = (subscriptionButtonDetails && subscriptionButtonDetails.discount) ? (subscriptionButtonDetails.discount / 100) : 0;
  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  const getData = (query) => {
    fetchSubscriptionButtonV2Details(gid);
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchSubscriptionButtonV2Payments({ currentPage, sortParams, gid });
    getCustomersList();
  };

  const copyHtml = (code) => {
    const payButtonHtml = document.getElementById(code).innerHTML;
    navigator.clipboard.writeText(payButtonHtml);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUBSCRIPTION_BUTTON.SUCCESS,
    });
  };

  const getAmountOff = () => {
    let amountOff;
    // const productAmount = totalAmount - (discountAmount);
    // if (value.percentageOff) {
    //   const amount = (productAmount * value.percentageOff) / 100;
    //   amountOff = formatNumber((amount).toFixed(2), currencyType);
    // } else if (value.amountOff) {
    //   amountOff = formatNumber((value.amountOff / 100).toFixed(2), currencyType);
    // }
    if (discountAmount) {
      const discount = Math.abs(discountAmount);
      amountOff = formatNumber((discount).toFixed(2), currencyType);
    }
    return amountOff;
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUBSCRIPTION_BUTTON.SUCCESS,
    });
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.SUBSCRIPTION}/${searchParams}`,
      state: location.search,
    });
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setAllTaxes(taxList);
  }, [taxList]);

  useEffect(() => {
    getData(location.search);
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [location, selectedAccount]);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.SUBSCRIPTION_BUTTON);
    } else if (!validator && !couponRedirect && !taxRedirect) {
      setAddSubscriptionButtonModal(false);
    }
  }, [validator]);

  useEffect(() => {
    if (plans && plans.length !== 0) {
      setPlanList(plans);
    }
  }, [plans]);

  const addTaxrate = (taxRate: Object) => {
    const {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      taxDescription,
    } = taxRate;
    const params = {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      description: taxDescription,
    };
    addNewTaxRate({
      params,
    });
    setTaxRedirect(true);
  };

  const updateTaxrate = (Taxrate: Object) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.push(Taxrate);
    setAllSelectedTaxrates(listOfTaxes);
    setSelectTaxrateModal(false);
  };

  const newTaxAdd = () => {
    setSelectTaxrateModal(false);
    setAddTaxModal(true);
  };

  const updateCoupon = (Coupon: Object) => {
    setUpdatedCoupon(Coupon);
    setSelectCouponModal(false);
  };

  const newCouponAdd = () => {
    setSelectCouponModal(false);
    setAddCouponModal(true);
  };

  const applyCoupon = () => {
    setSelectCouponModal(true);
  };

  const removeCoupon = () => {
    setUpdatedCoupon({});
  };

  const applyTaxRate = () => {
    setSelectTaxrateModal(true);
  };

  const removeTax = (i) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.splice(i, 1);
    setAllSelectedTaxrates(listOfTaxes);
  };

  const cancelAddSubscription = () => {
    setAddSubscriptionButtonModal(false);
    setUpdatedCoupon({});
    setAllSelectedTaxrates([]);
  };

  const fetchTaxDetails = (searchParams) => {
    getTaxes(searchParams);
  };

  useEffect(() => {
    if (!validator && !couponRedirect && !taxRedirect) {
      setAddSubscriptionButtonModal(false);
      setUpdatedCoupon({});
      setAllSelectedTaxrates([]);
    } else if (!validator && couponRedirect) {
      setAddCouponModal(false);
      setCouponRedirect(false);
    } else if (!validator && taxRedirect) {
      setAddTaxModal(false);
      setTaxRedirect(false);
    }
  }, [validator]);

  useEffect(() => {
    if (newCoupon.gid && couponRedirect) {
      setUpdatedCoupon(newCoupon);
    }
  }, [newCoupon]);

  useEffect(() => {
    if (newTaxrate.gid && taxRedirect) {
      const listOfTaxes = [...allSelectedTaxrates];
      const taxRates = {};
      Object.assign(taxRates, newTaxrate);
      const taxRate = {
        taxRates,
      };
      listOfTaxes.push(taxRate);
      setAllSelectedTaxrates(listOfTaxes);
    }
  }, [newTaxrate]);

  const fetchPlanDetails = (search) => {
    fetchPlanSearch(search);
  };

  const addCoupon = (coupon: Object) => {
    const {
      name,
      percentageOff,
      amountOff,
      validity,
      expiresAt,
      maximumRedemption,
      code,
      minimumAmount,
      promotionalRedeemed,
      promoExpiresAt,
      promotional,
      customerGid,
      validiyInMonths,
    } = coupon;
    const params = {
      active: true,
      name,
      percentageOff: (percentageOff ? parseFloat(percentageOff) : null),
      amountOff: (amountOff ? parseInt((amountOff).replace(/[_\W]+/g, ''), 10) : null),
      validity,
      expiresAt,
      maximumRedemption: (maximumRedemption ? parseInt(maximumRedemption, 10) : null),
      validiyInMonths: (validiyInMonths ? parseInt(validiyInMonths, 10) : null),
      currencyCode: typeOfCurrency,
    };
    const payload = {
      active: true,
      code,
      enabledForCheckout: true,
      expiresAt: promoExpiresAt,
      maximumRedemption: promotionalRedeemed ? parseInt(promotionalRedeemed, 10) : 0,
      minimumAmount: minimumAmount ? parseInt((minimumAmount).replace(/[_\W]+/g, ''), 10) : 0,
      minimumAmountCurrency: typeOfCurrency,
      restrictedToCustomers: [
        customerGid,
      ],
    };
    if (promotional) {
      addNewCoupon({
        params,
        payload,
      });
    } else if (!promotional) {
      addNewCoupon({
        params,
      });
    }
    setCouponRedirect(true);
  };

  const fetchCustomer = (searchText) => {
    getCustomersList(searchText);
  };

  useEffect(() => {
    setPlanList(planResult || planList);
  }, [planResult]);

  const addNewSubscriptionButton = async (planInfo: Object) => {
    const {
      name,
      amount,
      description,
      period,
      totalCount,
      qty,
      frequency,
      apiPlanDate,
      redirectUrl,
      currencyCode,
    } = planInfo;
    let { planId } = planInfo;
    const totalamount = amount.replace(/[. ,:-]+/g, '');
    const couponId = updatingCoupon.gid ? updatingCoupon.gid : null;
    const taxesList = [];
    const listOfTaxes = [...allSelectedTaxrates];
    for (let i = 0; i < listOfTaxes.length; i += 1) {
      taxesList.push(listOfTaxes[i].taxRates.gid);
    }
    if (!planId) {
      const planObject = {
        name,
        amount: Number(totalamount),
        description,
        currencyCode,
        billingFrequency: frequency,
        billingPeriod: period,
      };
      try {
        const { data: { entity } } = await axiosAuthInstance.post(API_END_POINTS.PLAN, planObject);
        planId = entity.gid;
      } catch (error) {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: error.response.data && error.response.data.message,
        });
      }
    }
    SetRedirect(true);
    addSubscriptionButton({
      currencyCode,
      planGid: planId,
      couponGid: couponId,
      description,
      planAmount: Number(totalamount),
      planQuantity: qty,
      taxRates: taxesList,
      planStartDate: apiPlanDate,
      planTotalPayments: totalCount,
      plan2BillingPeriod: period,
      plan2BillingFrequency: frequency,
      redirectUri: redirectUrl,
      status: 'ACTIVE',
    });
  };

  useEffect(() => {
    if (subscriptionButtonDetails.gid && subscriptionButtonDetails.description) {
      setDescription(subscriptionButtonDetails.description);
    } else setDescription(null);
  }, [subscriptionButtonDetails, showEdit]);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.SUBSCRIPTION_BUTTON);
    }
  }, [validator]);

  useEffect(() => {
    if (!validator) {
      setShowEdit(false);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `/subscription-button/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const showDeleteSubscriptionButtonModal = () => {
    setDeleteSubscriptionButtonModal(true);
  };

  const deleteSelectedSubscriptionButton = async (subscriptionButtonGid: string) => {
    DeactiveSubscriptionV2Button(subscriptionButtonGid);
    SetRedirect(true);
  };

  const editNewSubscriptionButton = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      description: Description,
    };
    updateSubscriptionButtonV2({
      gid,
      params,
    });
  };

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '13%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount) => {
        const total = (amount / 100).toFixed(2);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${prefix}${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'NAME',
      dataIndex: 'customer.name',
      width: '14%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'customer.email',
      width: '14%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (email) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {email || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PHONE',
      dataIndex: 'customer.phoneNumber',
      width: '14%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (phoneNumber) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {phoneNumber || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PLAN',
      dataIndex: 'plan2.name',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (plan) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {plan || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <SpStatusTag style={{ backgroundColor: getSubscriptionBackground(status) }}>
            {getSubscriptionStatus(status)}
          </SpStatusTag>
        </Tooltip>
      ),
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        subscriptionButtonDetails.gid && subscriptionButtonDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    {subscriptionButtonDetails.amount < 0
                      ? `(
                      ${subscriptionButtonDetails.currency.prefix} 
                      ${formatNumber((subscriptionButtonDetails.amount / 100)
                        .toFixed(subscriptionButtonDetails.currency.toFixed).replace(/-/g, ''), currencyType)}
                      )`
                      : `${subscriptionButtonDetails.currency.prefix} 
                      ${formatNumber(
                        (subscriptionButtonDetails.amount / 100).toFixed(subscriptionButtonDetails.currency.toFixed),
                        currencyType,
                      )}`}
                  </SpText>
                </Col>
                {
                  subscriptionButtonDetails.status === SUBSCRIPTION_BUTTON_STATUS.ACTIVE && (
                    <>
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <Col>
                          <SpButton
                            type="danger"
                            shape="round"
                            onClick={showDeleteSubscriptionButtonModal}
                          >
                            Deactivate Subscription Button
                          </SpButton>
                        </Col>
                      </RBAC>
                    </>
                  )
                }
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Description</span>
                  </Col>
                  {!showEdit && (
                    <Col span={6}>
                      {subscriptionButtonDetails.description
                        ? <SpH5>{subscriptionButtonDetails.description}</SpH5>
                        : ''}
                      <Tooltip placement="top" title="Edit">
                        <Icon
                          type="edit"
                          className="ml-3"
                          style={{ fontSize: '20px', cursor: 'pointer' }}
                          onClick={() => { setShowEdit(!showEdit); }}
                        />
                      </Tooltip>
                    </Col>
                  )}
                  {showEdit && (
                    <Col span={6}>
                      <div className="w-85 mt-2 d-flex">
                        <Input
                          placeholder="Description"
                          value={Description}
                          onChange={(e) => setDescription(e.currentTarget.value)}
                        />
                        <SpButton
                          className="ml-2 pl-2 pr-2"
                          type="primary"
                          borderRadius="none"
                          onClick={editNewSubscriptionButton}
                        >
                          <UpdateIcon />
                        </SpButton>
                        <SpButton
                          className="ml-2 pl-2 pr-2"
                          type="secondary"
                          borderRadius="none"
                          style={{ borderColor: '#C4C4C4' }}
                          onClick={() => {
                            setShowEdit(!showEdit);
                            setDescription(Description);
                            simpleValidator.current.hideMessages();
                            forceUpdate();
                          }}
                        >
                          <CancelIcon />
                        </SpButton>
                      </div>
                      <SpError>
                        {simpleValidator.current.message('description', Description, 'required')}
                      </SpError>
                    </Col>
                  )}
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Status</span>
                  </Col>
                  <Col span={6}>
                    {subscriptionButtonDetails.status
                      ? (
                        <SpStatusTag style={{ backgroundColor: getSubscriptionButtonBackground(subscriptionButtonDetails.status) }}>
                          {getSubscriptionButtonStatus(subscriptionButtonDetails.status)}
                        </SpStatusTag>
                      )
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Billing Frequency</span>
                  </Col>
                  <Col span={6}>
                    {subscriptionButtonDetails.plan2BillingPeriod
                      ? (
                        <SpH5>
                          {`Every ${subscriptionButtonDetails.plan2BillingPeriod} ${getFrequency(subscriptionButtonDetails.plan2BillingFrequency)}`}
                        </SpH5>
                      )
                      : (
                        <>
                          {
                            subscriptionButtonDetails.plan2BillingPeriod && !subscriptionButtonDetails.plan2BillingPeriod ? (
                              <SpH5>
                                {getBillingFrequency(subscriptionButtonDetails.plan2BillingFrequency)}
                              </SpH5>
                            ) : (
                              <>&#8211;</>
                            )
                          }
                        </>
                      )}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Subscription Start Date</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {subscriptionButtonDetails.plan2StartDate
                        ? (
                          moment
                            .utc(subscriptionButtonDetails.plan2StartDate)
                            .tz(selectedAccount && selectedAccount.timezone)
                            .format('MMM DD, YYYY')
                        )
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Total Count</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {subscriptionButtonDetails.plan2TotalPayments
                        ? subscriptionButtonDetails.plan2TotalPayments
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Redirect Url</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {subscriptionButtonDetails.redirectUri
                        ? subscriptionButtonDetails.redirectUri
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Link</span>
                  </Col>
                  <Col span={12}>
                    {subscriptionButtonDetails.link && subscriptionButtonDetails.status !== SUBSCRIPTION_BUTTON_STATUS.DEACTIVE
                      ? (
                        <SpH5>{subscriptionButtonDetails.link}
                          <SpButton
                            type="secondary"
                            className="ml-2"
                            borderRadius="50px"
                            onClick={() => { copyCode(subscriptionButtonDetails.link); }}
                          >
                            <Clipboard />
                          </SpButton>
                        </SpH5>
                      )
                      : (
                        <>&#8211;</>
                      )}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <Col span={4}>
                      <span>Button</span>
                    </Col>
                    <Col span={12}>
                      {subscriptionButtonDetails.link && subscriptionButtonDetails.status !== SUBSCRIPTION_BUTTON_STATUS.DEACTIVE
                        ? (
                          <div className="d-flex align-items-center">
                            <span id="paynow-button">
                              <a
                                href={subscriptionButtonDetails.link}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <img src={payButton} alt="payButton" width="150" height="35" />
                              </a>
                            </span>
                            <SpButton
                              type="secondary"
                              className="ml-2"
                              borderRadius="50px"
                              onClick={() => setCopySubscriptionButtonModal(true)}
                            >
                              <Clipboard />
                            </SpButton>
                          </div>
                        )
                        : (
                          <div className="d-flex align-items-center">
                            <span>
                              <a
                                href={subscriptionButtonDetails.link}
                                rel="noopener noreferrer"
                                target="_blank"
                                disabled
                              >
                                <img src={payButton} alt="payButton" width="150" height="35" />
                              </a>
                            </span>
                            <SpButton
                              type="secondary"
                              className="ml-2"
                              borderRadius="50px"
                              disabled
                            >
                              <Clipboard />
                            </SpButton>
                          </div>
                        )}
                    </Col>
                  </Col>
                </Row>
              </SpForm>
              {updatedCoupon.gid && (
                <>
                  <Row type="flex" justify="space-between" className="my-2 mb-3">
                    <Col>
                      <SpText className="mr-3" fontSize="20px" fontWeight="600">
                        Coupon
                      </SpText>
                    </Col>
                  </Row>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={4}>
                        <span>
                          {updatedCoupon.name}
                        </span><br />
                        <span fontSize="12px">
                          {getDiscountOff(updatedCoupon)}
                        </span>
                      </Col>
                      <Col span={6}>
                        <SpH5>({prefix} {getAmountOff(updatedCoupon)})</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                </>
              )}
              {
                (subscriptionButtonDetails) && (subscriptionButtonDetails.taxAmount) && (
                  <>
                    <Row type="flex" justify="space-between" className="my-2 mb-3">
                      <Col>
                        <SpText className="mr-3" fontSize="20px" fontWeight="600">
                          Rates
                        </SpText>
                      </Col>
                    </Row>
                    <SpForm>
                      <>
                        {
                          allTaxrates.map((item) => (
                            <Row type="flex" justify="start" align="middle">
                              <Col span={4}>
                                <span>
                                  {item.taxRates.displayName}
                                </span><br />
                                <span fontSize="12px">
                                  {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                                </span>
                              </Col>
                              <Col span={6}>
                                <SpH5>
                                  {item.taxRates.percentage} %
                                </SpH5>
                              </Col>
                            </Row>
                          ))
                        }
                      </>
                    </SpForm>
                  </>
                )
              }
              <Row className="mb-4">
                <Col
                  span={24}
                  className="d-flex justify-content-end"
                >
                  <SpH5 color="#7f7f7f">{subscriptionButtonDetails.gid}</SpH5>
                </Col>
              </Row>
              {/* DELETE SUBSCRIPTION MODAL */}
              {deleteSubscriptionButtonModal && (
                <DeleteSubscriptionButton
                  visible={deleteSubscriptionButtonModal}
                  subscriptionButtonDetails={subscriptionButtonDetails}
                  submitting={submitting}
                  close={() => setDeleteSubscriptionButtonModal(false)}
                  submit={deleteSelectedSubscriptionButton}
                />
              )}
              {/* COPY SUBSCRIPTION MODAL */}
              {copySubscriptionButtonModal && (
                <CopySubscriptionButton
                  visible={copySubscriptionButtonModal}
                  subscriptionButtonDetails={subscriptionButtonDetails}
                  submitting={submitting}
                  close={() => setCopySubscriptionButtonModal(false)}
                  copyHtml={copyHtml}
                  payButton={payButton}
                />
              )}
              {/* SELECT TAXRATE MODAL */}
              {selectTaxrateModal && (
                <SelectTaxrate
                  visible={selectTaxrateModal}
                  submitting={submitting}
                  close={() => setSelectTaxrateModal(false)}
                  submit={updateTaxrate}
                  newTaxAdd={newTaxAdd}
                />
              )}

              {/* ADD TAX MODAL */}
              {addTaxModal && (
                <AddTaxrate
                  visible={addTaxModal}
                  submitting={submitting}
                  close={() => setAddTaxModal(false)}
                  submit={addTaxrate}
                  selectedAccount={selectedAccount}
                  allTaxes={allTaxes}
                  fetchTaxDetails={fetchTaxDetails}
                />
              )}

              {/* ADD COUPON MODAL */}
              {addCouponModal && (
                <AddCoupon
                  visible={addCouponModal}
                  submitting={submitting}
                  close={() => setAddCouponModal(false)}
                  submit={addCoupon}
                  selectedAccount={selectedAccount}
                  allCustomers={customersList}
                  fetchCustomer={fetchCustomer}
                />
              )}

              {/* SELECT COUPON MODAL */}
              {selectCouponModal && (
                <SelectCoupon
                  visible={selectCouponModal}
                  submitting={submitting}
                  close={() => setSelectCouponModal(false)}
                  submit={updateCoupon}
                  newCouponAdd={newCouponAdd}
                />
              )}

              {/* ADD SUBSCRIPTIONBUTTON MODAL */}
              {addSubscriptionButtonModal && (
                <AddSubscriptionButton
                  visible={addSubscriptionButtonModal}
                  subscriptionButtonDetails={subscriptionButtonDetails}
                  fetchPlanDetails={fetchPlanDetails}
                  planData={planData}
                  submitting={submitting}
                  phoneCode={phoneCode}
                  displayDate={displayDate}
                  id={id}
                  selectedAccount={selectedAccount}
                  close={cancelAddSubscription}
                  submit={addNewSubscriptionButton}
                  updatedCoupon={updatingCoupon}
                  applyCoupon={applyCoupon}
                  removeCoupon={removeCoupon}
                  allSelectedTaxrates={allSelectedTaxrates}
                  supportedCurrency={supportedCurrency}
                  applyTaxRate={applyTaxRate}
                  removeTax={removeTax}
                />
              )}
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">SUBSCRIPTIONS</SpText>
                </Col>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={columns}
                rowKey={(record) => record.gid}
                dataSource={payments}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  subscriptionButtonDetails: state.subscriptionButtonV2Details.subscriptionDetails,
  subscriptionButtonDetailsStatus: state.subscriptionButtonV2Details.subscriptionButtonDetailsStatus,
  payments: state.subscriptionButtonV2Details.payments.content,
  totalElements: state.subscriptionButtonV2Details.payments ? state.subscriptionButtonV2Details.payments.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  plans: state.plans.content,
  planResult: state.subscription.plans.content,
  customersList: state.invoiceDetails.customersList,
  taxList: state.invoiceDetails.taxList,
  newCoupon: state.invoiceDetails.newCoupon,
  newTaxrate: state.invoiceDetails.newTaxrate,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptionButtonV2Details: gid => dispatch(subscriptionButtonV2DetailsActions.fetchSubscriptionButtonV2Details({
    payload: gid,
  })),
  fetchSubscriptionButtonV2Payments: param => dispatch(subscriptionButtonV2DetailsActions.fetchSubscriptionButtonV2Payments({
    payload: param,
  })),
  DeactiveSubscriptionV2Button: param => dispatch(subscriptionButtonV2DetailsActions.DeactiveSubscriptionV2Button({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  addSubscriptionButton: param => dispatch(subscriptionButtonActions.addSubscriptionButton({
    payload: param,
  })),
  updateSubscriptionButtonV2: param => dispatch(subscriptionButtonV2DetailsActions.updateSubscriptionButtonV2({
    payload: param,
  })),
  fetchPlanSearch: param => dispatch(subscriptionAction.fetchPlanSearch({
    payload: param,
  })),
  addNewTaxRate: param => dispatch(invoiceDetailsAction.addNewTaxRate({
    payload: param,
  })),
  addNewCoupon: param => dispatch(invoiceDetailsAction.addNewCoupon({
    payload: param,
  })),
  getTaxes: param => dispatch(invoiceDetailsAction.getTaxes({
    payload: param,
  })),
  getCustomersList: param => dispatch(invoiceDetailsAction.getCustomersList({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionButtonDetails);
