import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  DONATION_PAGES,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchDonationPages = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.DONATIONPAGES_API}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.DONATIONPAGES_API}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.DONATIONPAGES_API}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.DONATIONPAGES_API}${query}${sorting}`);
};
const postDonationPage = async payload => axiosAuthInstance.post(API_END_POINTS.DONATIONPAGES_API, payload);

export function* getDonationPages(action) {
  try {
    const { data: { entity } } = yield call(fetchDonationPages, action.payload);
    yield put({ type: DONATION_PAGES.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: DONATION_PAGES.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addDonationPage(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postDonationPage, action.payload);
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
