// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import formatNumber from 'utils/formatNumber';
import { useLocation } from 'react-router-dom';

import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Icon,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  inventoryModifierDetailsActions,
  inventoryModifiersActions,
  notificationActions,
} from 'store/actions';
import {
  PAGES,
} from 'appconstants';
import EditItems from './components/EditItems';
import AddItem from './components/AddItem';
import DeleteModifierGroup from './components/DeleteModifierGroup';
import DeleteModifier from './components/DeleteModifier';
import UpdateModifier from './components/UpdateModifier';

type Props = {
  fetchInventoryModifierDetails: Function,
  inventoryModifierDetails: Object,
  updateInventoryModifer: Function,
  deleteInventoryModifer: Function,
  addInventoryModifier: Function,
  deleteInventoryModifier: Function,
  updateInventoryModifier: Function,
  sortModifierGroupList: Array,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
}

const ModifierDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchInventoryModifierDetails,
    inventoryModifierDetails,
    updateInventoryModifer,
    deleteInventoryModifer,
    addInventoryModifier,
    deleteInventoryModifier,
    updateInventoryModifier,
    sortModifierGroupList,
    loading,
    history,
    submitting,
    validator,
    selectedAccount,
  } = props;
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const currencyType = selectedAccount && selectedAccount.currency;
  const [editItemsModal, setEditItemsModal] = useState(false);
  const [addModifierModal, setAddModifierModal] = useState(false);
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [deleteModifierModal, setDeleteModifierModal] = useState(false);
  const [updateModifierModal, setUpdateModifierModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectModifier, setRedirectModifier] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const kdsRole = localStorage.getItem('kdsRole');

  const getData = () => {
    fetchInventoryModifierDetails(gid);
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.INVENTORY_MODIFIERS);
    }
  }, []);

  useEffect(() => {
    if (redirect && !submitting) {
      setDeleteItemModal(false);
      history.push({
        pathname: PAGES.INVENTORY_MODIFIERS,
      });
    }
  }, [submitting]);

  useEffect(() => {
    if (redirect && !validator) {
      setEditItemsModal(false);
      getData(location.search);
    }
    if (redirectModifier && !validator) {
      setAddModifierModal(false);
      setDeleteModifierModal(false);
      setUpdateModifierModal(false);
      getData(location.search);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  const addSelectedItem = (customer: Object) => {
    const {
      name,
      price,
      primaryColor,
      isItemAvailable,
      quantity,
    } = customer;
    const totalPrice = price ? price.replace(/[^\d]/g, '') : 0;

    const params = {
      name,
      price: Number(totalPrice),
      colorCode: primaryColor,
      available: isItemAvailable,
      maxStackableQuantity: quantity ? Number(quantity) : 0,
      modifierGroupGid: gid,
    };
    addInventoryModifier({
      params,
    });
    setRedirectModifier(true);
  };

  const editSelectedItem = (customer: Object) => {
    const {
      name,
      order,
      entryPOS,
      minRequired,
      maxAllowed,
    } = customer;

    const params = {
      name,
      sortOrder: Number(order),
      minRequired,
      maxAllowed,
      showByDefault: entryPOS,
    };
    updateInventoryModifer({
      gid,
      params,
    });
    setRedirect(true);
  };

  const deleteSelectedItem = (customerGid: Object) => {
    deleteInventoryModifer({
      gid: customerGid,
    });
    setRedirect(true);
  };

  const deleteSelectedModifier = (customerGid: Object) => {
    deleteInventoryModifier({
      gid: customerGid,
      mGid: gid,
    });
    setRedirectModifier(true);
  };

  const updateSelectedItem = (customer: Object) => {
    const {
      custGid,
      name,
      price,
      primaryColor,
      isItemAvailable,
      quantity,
    } = customer;
    const totalPrice = price ? price.replace(/[^\d]/g, '') : 0;

    const params = {
      name,
      price: Number(totalPrice),
      colorCode: primaryColor,
      available: isItemAvailable,
      modifierGroupGid: gid,
      maxStackableQuantity: quantity ? Number(quantity) : 0,
    };
    updateInventoryModifier({
      gid: custGid,
      mGid: gid,
      params,
    });
    setRedirectModifier(true);
  };

  const showDeleteModifierModal = (value: Object) => {
    setSelectedCustomer(value);
    setDeleteModifierModal(true);
  };

  const showUpdateModifierModal = (value: Object) => {
    setSelectedCustomer(value);
    setUpdateModifierModal(true);
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <span>
          {name || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (price) => {
        const total = price ? (price / 100).toFixed(2) : '';
        return (
          <span>
            {total
              ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`
              : `${selectedAccount.currency && selectedAccount.currency.prefix} 0.00`}
          </span>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '10%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Update">
          <Icon
            type="edit"
            className="mr-4"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showUpdateModifierModal(record); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showDeleteModifierModal(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        inventoryModifierDetails.gid && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-3" fontWeight="600" fontSize="20px">
                    {(inventoryModifierDetails && inventoryModifierDetails.name) || <>&#8211;</>}
                  </SpText>
                </Col>
                {
                  kdsRole !== 'KDS-USER' && (
                    <>
                      <Col>
                        <SpButton
                          shape="round"
                          className="mr-2"
                          type="danger"
                          onClick={() => setDeleteItemModal(true)}
                        >
                          Delete Modifier Group
                        </SpButton>
                        <SpButton
                          shape="round"
                          type="secondary"
                          onClick={() => setEditItemsModal(true)}
                        >
                          Edit Modifier Group
                        </SpButton>
                      </Col>
                    </>
                  )
                }
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Min Required</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryModifierDetails && inventoryModifierDetails.minRequired) || <>None</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Max Allowed</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryModifierDetails && inventoryModifierDetails.maxAllowed) || <>None</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Show Default</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryModifierDetails && inventoryModifierDetails.showByDefault) ? 'Yes' : 'No'}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Sort Order</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryModifierDetails && inventoryModifierDetails.sortOrder) || 0}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <Row>
                <Col span={24} className="d-flex justify-content-end">
                  <SpH5 color="#7f7f7f">{inventoryModifierDetails.gid}</SpH5>
                </Col>
              </Row>
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">MODIFIERS</SpText>
                </Col>
                {
                  kdsRole !== 'KDS-USER' && (
                    <Col>
                      <SpButton
                        type="secondary"
                        shape="round"
                        ghost
                        className="mr-3"
                        onClick={() => setAddModifierModal(true)}
                      >
                        {'\u002B'}&nbsp;Add Modifier
                      </SpButton>
                    </Col>
                  )
                }
              </Row>
              {/* ADD MODIFIER MODAL */}
              {addModifierModal && (
                <AddItem
                  visible={addModifierModal}
                  submitting={submitting}
                  selectedAccount={selectedAccount}
                  close={() => setAddModifierModal(false)}
                  submit={addSelectedItem}
                />
              )}
              {/* DELETE MODIFIER MODAL */}
              {deleteModifierModal && (
                <DeleteModifier
                  visible={deleteModifierModal}
                  submitting={submitting}
                  inventoryItemDetails={selectedCustomer}
                  selectedAccount={selectedAccount}
                  close={() => setDeleteModifierModal(false)}
                  submit={deleteSelectedModifier}
                />
              )}
              {/* UPDATE MODIFIER MODAL */}
              {updateModifierModal && (
                <UpdateModifier
                  visible={updateModifierModal}
                  submitting={submitting}
                  inventoryItemDetails={selectedCustomer}
                  selectedAccount={selectedAccount}
                  close={() => setUpdateModifierModal(false)}
                  submit={updateSelectedItem}
                />
              )}
              {/* DELETE ITEM MODAL */}
              {deleteItemModal && (
                <DeleteModifierGroup
                  visible={deleteItemModal}
                  submitting={submitting}
                  inventoryItemDetails={inventoryModifierDetails}
                  selectedAccount={selectedAccount}
                  close={() => setDeleteItemModal(false)}
                  submit={deleteSelectedItem}
                />
              )}
              {/* UPDATE ITEMS MODAL */}
              {editItemsModal && (
                <EditItems
                  visible={editItemsModal}
                  submitting={submitting}
                  selectedItemList={inventoryModifierDetails}
                  selectedAccount={selectedAccount}
                  close={() => setEditItemsModal(false)}
                  submit={editSelectedItem}
                />
              )}
              <Table
                rowClassName={() => 'ant-table-clickable-row'}
                columns={kdsRole !== 'KDS-USER' ? [...columns, actionColumn] : columns}
                rowKey={(record) => record.gid}
                className="custom-table"
                dataSource={sortModifierGroupList}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  inventoryModifierDetails: state.inventoryModifierDetails,
  sortModifierGroupList: state.inventoryModifierDetails.sortModifierGroupList,
  totalElements: state.inventoryModifierDetails.inventoryOrderLineItems ? state.inventoryModifierDetails.inventoryOrderLineItems.length + 1 : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  profile: state.subscription.profile,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryModifierDetails: gid => dispatch(inventoryModifierDetailsActions.fetchInventoryModifierDetails({
    payload: gid,
  })),
  addInventoryModifier: param => dispatch(inventoryModifierDetailsActions.addInventoryModifier({
    payload: param,
  })),
  deleteInventoryModifier: param => dispatch(inventoryModifierDetailsActions.deleteInventoryModifier({
    payload: param,
  })),
  updateInventoryModifier: param => dispatch(inventoryModifierDetailsActions.updateInventoryModifier({
    payload: param,
  })),
  updateInventoryModifer: param => dispatch(inventoryModifiersActions.updateInventoryModifer({
    payload: param,
  })),
  deleteInventoryModifer: param => dispatch(inventoryModifiersActions.deleteInventoryModifer({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ModifierDetails);
