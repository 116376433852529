/* eslint-disable */
import React from 'react';

const downloadProof = () => (
    <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.3 23.2424H3C1.89543 23.2424 1 22.347 1 21.2424V3C1 1.89543 1.89543 1 3 1H13.7846C14.5914 1 15.3642 1.32498 15.9286 1.90158L18.444 4.4716C18.9927 5.03225 19.3 5.78553 19.3 6.57002V20.2424C19.3 21.8993 17.9569 23.2424 16.3 23.2424Z" stroke="#454545" stroke-width="2" />
        <line x1="3.86664" y1="4.81818" x2="14.5" y2="4.81818" stroke="#454545" stroke-width="2" />
        <line x1="3.86664" y1="8.69697" x2="16.4333" y2="8.69697" stroke="#454545" stroke-width="2" />
        <path d="M4.83325 14.2222L6.12214 15.5152L8.69992 12.6061" stroke="#454545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <rect x="13.5333" y="12.6061" width="12.5667" height="17.4545" fill="white" />
        <path d="M28 21.3333C28 26.675 23.6841 31 18.3667 31C13.0493 31 8.73334 26.675 8.73334 21.3333C8.73334 15.9916 13.0493 11.6667 18.3667 11.6667C23.6841 11.6667 28 15.9916 28 21.3333Z" fill="white" stroke="#454545" stroke-width="2" />
        <path d="M13.5333 20.3636V24.404H23.2V20.3636" stroke="#454545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18.3667 16.3232V20.7677M18.3667 20.7677L19.9778 19.1515M18.3667 20.7677L16.7556 19.1515" stroke="#454545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export default downloadProof;