import React, {
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';

import {
  Row,
  Col,
  Empty,
} from 'antd';
import styled from 'styled-components';
import {
  SpText,
  SpH5,
  SpButton,
  SpHead,
  SpH6,
  Line,
} from 'components/DesignKit';

import {
  MESSAGES,
} from 'appconstants';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  chequereceiptsAction,
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import Loading from 'components/Loading';
import moment from 'moment-timezone';

// $FlowFixMe
const Wrapper = styled.div`
    background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
    width: 100%;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  width: '560px',
  minHeight: '520px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

type Props = ContextRouter & {
  fetchChequeReceipts: Function,
  setNotification: Function,
  loading: boolean,
  receipts: Object,
  history: {
    replace: Function,
  },
};

const ChequeReceipt = (props: Props) => {
  const {
    fetchChequeReceipts,
    receipts,
    setNotification,
    loading,
    match,
  } = props;

  const receiptID = match.params.receiptid;

  useEffect(() => {
    fetchChequeReceipts(receiptID);
  }, []);

  const formatDate = (date, timezone) => {
    if (date && timezone) {
      return moment.utc(date).tz(timezone).format('MMM DD, YYYY');
    }
    return date && moment.utc(date).format('MMM DD, YYYY');
  };

  const downloadReceipt = async (record) => {
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance.get(`${API_END_POINTS.CHEQUE_RECEIPTS}/${record}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.RECEIPT.DOWNLOAD_ERROR,
      });
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {(receipts && !(Object.keys(receipts).length === 0)) ? (
        <Wrapper>
          <FormWrapper>
            <Row>
              <Col type="flex" align="middle"> <SpHead className="text-uppercase" fontWeight="600">Receipt For {receipts.accountName}</SpHead></Col>
            </Row>
            <Row>
              <Col type="flex" align="middle" className="mt-3 mb-4"><SpH6 fontWeight="600">Receipt # {receipts.receiptNumber}</SpH6></Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={8}>
                <div className="pt-1 pb-1"><SpText color="#434343">Amount Paid</SpText></div>
                <div className="pt-1 pb-1">
                  <SpH6 fontWeight="600">
                    {receipts.checkDetails.currencySymbol} {receipts.checkDetails.amount}
                  </SpH6>
                </div>
              </Col>
              <Col span={8}>
                <div className="pt-1 pb-1"><SpText color="#434343">Date Paid</SpText></div>
                <div className="pt-1 pb-1">
                  <SpH6 fontWeight="600">
                    {
                      (receipts.checkDetails
                        && receipts.checkDetails.transactionDate
                        && formatDate(receipts.checkDetails.transactionDate, receipts.timezone)) || <>&#8211;</>
                    }
                  </SpH6>
                </div>
              </Col>
              <Col span={8}>
                <div className="pt-1 pb-1"><SpText color="#434343">Payment Method</SpText></div>
                <div className="pt-1 pb-1">
                  <SpH6 fontWeight="600">Cheque</SpH6>
                </div>
              </Col>
            </Row>
            <Line opacity="0.3" className="mt-1 mb-1" />
            <Row>
              <Col type="flex" align="middle" className="mt-3 pb-1"><SpText fontSize="18px">Transaction Details</SpText></Col>
            </Row>
            <Line />
            <Row type="flex" className="pt-1 pb-1" justify="space-between">
              <Col className="ml-4">
                <div>
                  Payment for {receipts.checkDetails.description ? receipts.checkDetails.description : receipts.accountName}
                </div>
              </Col>
              <Col className="mr-4">
                <div className="mr-4">{receipts.checkDetails.currencySymbol} {receipts.checkDetails.amount}</div>
              </Col>
            </Row>
            <Line />
            <Row type="flex" className="pt-1 pb-1" justify="space-between">
              <Col className="ml-4">
                <div>
                  <SpH5>Total</SpH5>
                </div>
              </Col>
              <Col className="mr-4">
                <div className="mr-4">
                  <SpH5>{receipts.checkDetails.currencySymbol} {receipts.checkDetails.amount}</SpH5>
                </div>
              </Col>
            </Row>
            <Line />
            {
              receipts.checkDetails && receipts.checkDetails.amountVoided && (
                <>
                  <Row type="flex" className="pt-1 pb-1" justify="space-between">
                    <Col className="ml-4">
                      <div>
                        {
                          `Voided on  ${receipts.checkDetails.voidTransactionDate ? receipts.checkDetails.voidTransactionDate
                            && formatDate(receipts.checkDetails.voidTransactionDate, receipts.timezone) : ''}`
                        }
                      </div>
                    </Col>
                    <Col className="mr-4">
                      <div className="mr-3">
                        {
                          `(${receipts.checkDetails.currencySymbol} ${(receipts.checkDetails.amountVoided).replace('-', '')})`
                        }
                      </div>
                    </Col>
                  </Row>
                  <Line />
                </>
              )
            }
            {
              receipts.adjustedTotal && (
                <>
                  <Row type="flex" className="pt-1 pb-1" justify="space-between">
                    <Col className="ml-4">
                      <div>
                        <SpH5>Adjusted Total</SpH5>
                      </div>
                    </Col>
                    <Col className="mr-4">
                      <div className="mr-4">
                        <SpH5>{receipts.checkDetails.currencySymbol} {receipts.adjustedTotal}</SpH5>
                      </div>
                    </Col>
                  </Row>
                  <Line className="mb-4" />
                </>
              )
            }
            {
              receipts.supportDto && (
                <Row>
                  <Col type="flex" align="middle" className="mt-3">
                    <div>For any questions and concerns, please contact</div>
                  </Col>
                  <Col type="flex" align="middle">
                    <div><SpText fontWeight="600" color="#1d9cff">{receipts.supportDto.email ? receipts.supportDto.email : ''}</SpText></div>
                  </Col>
                </Row>
              )
            }
            <Row type="flex" align="middle" className="mt-auto">
              <Col span={24} push={9}>
                <SpButton
                  type="primary"
                  shape="round"
                  className="mt-4"
                  onClick={() => downloadReceipt(receiptID)}
                >
                  Download PDF
                </SpButton>
              </Col>
            </Row>
          </FormWrapper>
        </Wrapper>
      ) : <Empty style={{ height: '100vh', paddingTop: '40px' }} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  receipts: state.chequereceipts,
});

const mapDispatchToProps = (dispatch) => ({
  fetchChequeReceipts: gid => dispatch(chequereceiptsAction.fetchChequeReceipts({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChequeReceipt);
