// @flow
import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import {
  Card,
  Row,
  Col,
  Icon,
  Input,
  Select,
  DatePicker,
  Divider,
  Checkbox,
} from 'antd';
import {
  SpText,
  SpButton,
  Line,
  SpError,
  SpFormWrapper,
  SpWrapper,
} from 'components/DesignKit';
import formatNumber from 'utils/formatNumber';
import getTaxAmount from 'utils/getTaxAmount';
import getDiscountOff from 'utils/getDiscountOff';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';
import {
  invoiceDetailsAction,
  inventoryItemsActions,
  inventoryPrinterLabelActions,
  customersActions,
  sequenceGeneratorsActions,
} from 'store/actions';
import {
  PAGES,
  MAX_AMOUNT,
  COUNTRIES,
  RATE_TYPES,
} from 'appconstants';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import { validators } from 'utils/validationMessages';
import getSortedList from 'utils/getSortedList';
import getPrefix from 'utils/getPrefix';
import Loading from 'components/Loading';
import swirepaylogo from 'components/NavIcons/swirepay';
import AddNewAddress from './AddNewAddress';
import AddNewCustomer from './AddNewCustomer';
import SelectCoupon from './SelectCoupon';
import AddCoupon from './AddNewCoupon';
import SelectTaxrate from './SelectTaxrate';
import SelectShippingFee from './SelectShippingFee';
import AddTaxrate from './AddNewTax';
import AddNewProduct from './AddNewProduct';
import AddItem from '../../../InventoryOrders/components/ItemsList/components/AddNewItem';

const { Option } = Select;

const dateFormat = 'MMM DD, YYYY';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  selectedAccount: Object,
  history: {
    push: Function,
  },
  customersList: Array<Object>,
  getCustomersList: Function,
  submitting: boolean,
  addNewInvoice: Function,
  validator: boolean,
  loading: boolean,
  addCustomer: Function,
  addedNewCustomer: Object,
  fetchInvoiceDetails: Function,
  invoiceDetails: Object,
  addNewCoupon: Function,
  getTaxes: Function,
  taxList: Array<Object>,
  addNewTaxRate: Function,
  newCoupon: Object,
  newTaxrate: Object,
  fetchInventoryItems: Function,
  inventoryItems: Array<Object>,
  itemsList: Array<Object>,
  modifierItemsList: Array<Object>,
  labelList: Array,
  fetchItemsList: Function,
  fetchModifierItemsList: Function,
  fetchPrinterLabelList: Function,
  addInventoryItems: Function,
  getProductsList: Function,
  productsList: Array<Object>,
  addNewProcut: Function,
  addedNewProduct: Object,
  internalAccount: Object,
  addedNewItem:Object,
  updateCustomer: Function,
  addInvoiceSequence:Function,
  fetchAllInvoiceSequence:Function,
  fetchCurrentSeqInvoice:Function,
  invoiceSeqNo:Array,
  currentInvoiceSeqNo:Object,
};

const NewInvoice = (props: Props) => {
  const {
    selectedAccount,
    customersList,
    getCustomersList,
    submitting,
    addNewInvoice,
    validator,
    history,
    loading,
    addCustomer,
    addedNewCustomer,
    fetchInvoiceDetails,
    invoiceDetails,
    addNewCoupon,
    getTaxes,
    taxList,
    addNewTaxRate,
    newCoupon,
    newTaxrate,
    fetchInventoryItems,
    inventoryItems,
    itemsList,
    modifierItemsList,
    labelList,
    fetchItemsList,
    fetchModifierItemsList,
    fetchPrinterLabelList,
    addInventoryItems,
    getProductsList,
    productsList,
    addNewProcut,
    addedNewProduct,
    internalAccount,
    addedNewItem,
    updateCustomer,
    fetchAllInvoiceSequence,
    fetchCurrentSeqInvoice,
    addInvoiceSequence,
    invoiceSeqNo,
    currentInvoiceSeqNo,
} = props;

  const [, forceUpdate] = useState();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [description, setDescription] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [customerName, setCustomerName] = useState();
  const [customerId, setCustomerId] = useState();
  const [paymentDueDate, setPaymentDueDate] = useState();
  const [shippingAddress, setShippingAddress] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [address, setAddress] = useState({});
  const [notes, setNotes] = useState('');
  const [addressType, setAddressType] = useState('');
  const [addressMethod, setAddressMethod] = useState('');
  const [currencyType, setCurrencyType] = useState('');
  const [zipTag, setZipTag] = useState('');
  const [addAddressModal, setAddAddressModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [addItemModal, setAddItemModal] = useState(false);
  const [addedItem, setAddedItem] = useState(false);
  const [addedProduct, setAddedProduct] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  const [selectCouponModal, setSelectCouponModal] = useState(false);
  const [selectTaxrateModal, setSelectTaxrateModal] = useState(false);
  const [selectShippingFeeModal, setSelectedShippingFeeModal] = useState(false);
  const [updatedCoupon, setUpdatedCoupon] = useState({});
  const [addCouponModal, setAddCouponModal] = useState(false);
  const [addTaxModal, setAddTaxModal] = useState(false);
  const [allTaxes, setAllTaxes] = useState(taxList);
  const [allSelectedTaxrates, setAllSelectedTaxrates] = useState([]);
  const [shippingAmount, setShippingAmount] = useState(null);
  const [couponRedirect, setCouponRedirect] = useState(false);
  const [taxRedirect, setTaxRedirect] = useState(false);
  const [fixErrors, setFixErrors] = useState(false);
  const [lineItemDtoList, setLineItemDtosList] = useState([{
    amount: 0,
    name: '',
    description: '',
    quantity: '1',
    note: '',
    rate: null,
  }]);
  const legacySubscriber = internalAccount && internalAccount.legacySubscription;
  const [totalAmount, setTotalAmount] = useState(0);
  const [daysLeft, setDaysLeft] = useState(null);
  const [sameAddress, setSameAddress] = useState(false);
  const [dateSelected, setDateSelected] = useState();
  const [addedCustomer, setAddedCustomer] = useState(false);
  const [lineItemIndex, setLineItemIndex] = useState(null);
  const [issueDateDisplay, setIssueDateDisplay] = useState(selectedAccount
    && selectedAccount.timezone && moment().utc().tz(selectedAccount.timezone).startOf('day')
    .format(dateFormat));
  const [lineItemAdd, setLineItemAdd] = useState(true);
  const [shippingCntryId, setShippingCntryId] = useState('');
  const [billingCntryId, setBillingCntryId] = useState('');
  const [invoiceGid, setInvoiceGid] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [couponMsg, setCouponMsg] = useState('');
  const supportedCurrency = (selectedAccount && selectedAccount.supportedCurrencies);
  const [currencyCode, setCurrencyCode] = useState(selectedAccount.currency && selectedAccount.currency.name);
  const [prefix, setPrefix] = useState(selectedAccount.currency && selectedAccount.currency.prefix);
  const currencyObj = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [addRecipient, setAddRecipient] = useState([]);
  const [isEnableGenerationInvoiceNo, setIsEnableGenerationInvoiceNo] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));
  const debouncedFetchInventoryItems = debounce(fetchInventoryItems, 2000, { leading: true });

  const getData = (query) => {
    // eslint-disable-next-line no-shadow
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    // const sortParam = '&sortBy=sortOrder&direction=ASC';
    // const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    // const nameSearch = searchText ? (searchText === '' ? null : `&name.LIKE=${searchText}`) : null;
    debouncedFetchInventoryItems({
      currentPage, sortParams,
    });
    if (addItemModal) {
      fetchItemsList('&sortBy=name&direction=DESC');
      fetchModifierItemsList('&sortBy=name&direction=DESC');
      fetchPrinterLabelList('&sortBy=createdAt&direction=DESC');}
  };


  useEffect(() => {
    getData(location.search);
    fetchAllInvoiceSequence('INVOICE');
  }, [location, addItemModal]);

  useEffect(() => {
    if (invoiceSeqNo && invoiceSeqNo.length === 0) {
      const params = {
        prefix: selectedAccount?.name?.slice(0, 4),
        type: 'INVOICE',
        valueSeparator: '-',
      };
      addInvoiceSequence(params);
    } else if (invoiceSeqNo && invoiceSeqNo.length > 0) {
      fetchCurrentSeqInvoice('INVOICE');
      setIsEnableGenerationInvoiceNo(invoiceSeqNo[0]?.enableGeneration);
    }
  }, [invoiceSeqNo]);
  useEffect(() => {
    if (currentInvoiceSeqNo) {
      setInvoiceNo(currentInvoiceSeqNo?.sequence);
    }
  }, [currentInvoiceSeqNo]);


  useEffect(() => {
    setAllTaxes(taxList);
  }, [taxList]);

  useEffect(() => {
    if (newCoupon.gid && couponRedirect) {
      setUpdatedCoupon(newCoupon);
    }
  }, [newCoupon]);

  useEffect(() => {
    if (newTaxrate.gid && taxRedirect) {
      const listOfTaxes = [...allSelectedTaxrates];
      const taxRates = {};
      Object.assign(taxRates, newTaxrate);
      const taxRate = {
        taxRates,
      };
      listOfTaxes.push(taxRate);
      setAllSelectedTaxrates(listOfTaxes);
    }
  }, [newTaxrate]);

  useEffect(() => {
    const invoiceId = parsed['INVOICE.ID'];
    setInvoiceGid(invoiceId);
    if (invoiceId) {
      fetchInvoiceDetails(invoiceId);
    }
    getCustomersList();
    getProductsList();
    setCurrencyType(selectedAccount.currency.name);
    const countryId = (selectedAccount && selectedAccount.country && selectedAccount.country.id);
    if (countryId === 1) {
      setZipTag('Zip code');
    } else if (countryId === 2) {
      setZipTag('Pin code');
    }
  }, []);

  useEffect(() => {
    if (addedProduct && addedNewProduct && addedNewProduct.gid) {
      const list = [...lineItemDtoList];
      const { amount } = addedNewProduct;
      list[lineItemIndex].name = addedNewProduct.name;
      list[lineItemIndex].description = addedNewProduct.name;
      list[lineItemIndex].note = addedNewProduct.note;
      list[lineItemIndex].rate = (amount / 100).toFixed(2);
      if (list[lineItemIndex].quantity) {
        list[lineItemIndex].amount = amount * parseInt(list[lineItemIndex].quantity, 10);
      }
      const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
      setTotalAmount(amountTotal);
      setLineItemDtosList(list);
    }
  }, [addedNewProduct]);

  useEffect(() => {
    if (addedItem && addedNewItem && addedNewItem.gid) {
      const list = [...lineItemDtoList];
      const { price } = addedNewItem;
      list[lineItemIndex].name = addedNewItem.name;
      list[lineItemIndex].description = addedNewItem.name;
      list[lineItemIndex].note = addedNewItem.note;
      list[lineItemIndex].rate = (price / 100).toFixed(2);
      list[lineItemIndex].itemGid = addedNewItem.gid;
      list[lineItemIndex].upfront = !addedNewItem.recurring;
      list[lineItemIndex].recurring = addedNewItem.recurring;
      list[lineItemIndex].currencyCode = currencyCode;
      if (list[lineItemIndex].quantity) {
        list[lineItemIndex].price = price * parseInt(list[lineItemIndex].quantity, 10);
      }
      const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
      setTotalAmount(amountTotal);
      setLineItemDtosList(list);
    }
  }, [addedNewItem]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setCurrencyType(selectedAccount.currency.name);
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [selectedAccount]);

  const onCurrencyChange = (e) => {
    setCurrencyCode(e);
    setPrefix(getPrefix(e));
  };

  useEffect(() => {
    if (lineItemAdd && invoiceGid && invoiceDetails && invoiceDetails.gid) {
      const list = [...invoiceDetails.invoiceLineItems];
      for (let i = 0; i < list.length; i += 1) {
        list[i].rate = list[i].amount && (list[i].amount / 100).toFixed(2);
      }
      setLineItemDtosList([...list]);
      setUpdatedCoupon(invoiceDetails.coupon ? invoiceDetails.coupon : {});
      setAllSelectedTaxrates(invoiceDetails.taxAmount ? invoiceDetails.taxAmount : []);
      setShippingAmount(invoiceDetails.shippingAmount > 0 ? (invoiceDetails.shippingAmount / 100) : null);
      setDescription(invoiceDetails.description);
      setInvoiceNo(invoiceDetails.invoiceNumber);
      setDaysLeft(invoiceDetails.daysUntilDue);
      setCustomerName(invoiceDetails.customer.name);
      setAddRecipient(invoiceDetails.recipientsEmail ? invoiceDetails.recipientsEmail : []);
      setCustomerId(invoiceDetails.customer && invoiceDetails.customer.gid);
      setCurrencyCode(invoiceDetails.currency && invoiceDetails.currency.name);
      setPrefix(invoiceDetails.currency && invoiceDetails.currency.prefix);
      setIssueDateDisplay(moment.utc(invoiceDetails.issueDate).tz(selectedAccount.timezone).format(dateFormat));
      setIssueDate(invoiceDetails.issueDate);
      setTotalAmount(invoiceDetails.amount / 100);
      if (invoiceDetails.shippingAddress && invoiceDetails.shippingAddress.street) {
        setShippingAddress(invoiceDetails.shippingAddress);
        setShippingCntryId(getCountryAlpha2(
          invoiceDetails.shippingAddress.country ? invoiceDetails.shippingAddress.country.id : selectedAccount.country.id,
        ));
      }
      if (invoiceDetails.billingAddress && invoiceDetails.billingAddress.street) {
        setBillingAddress(invoiceDetails.billingAddress);
        setBillingCntryId(getCountryAlpha2(
          invoiceDetails.billingAddress.country ? invoiceDetails.billingAddress.country.id : selectedAccount.country.id,
        ));
      }
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.INVOICES);
    } else if (!validator) {
      setAddCustomerModal(false);
      setAddItemModal(false);
      setAddProductModal(false);
      setAddCouponModal(false);
      setAddTaxModal(false);
    }
  }, [validator]);

  useEffect(() => {
    if (!submitting) {
      setDisabled(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (addedCustomer && addedNewCustomer && addedNewCustomer.gid) {
      setCustomerName(addedNewCustomer.name);
      setCustomerId(addedNewCustomer.gid);
    }
  }, [addedNewCustomer]);


  const getCustomers = (customer) => {
    setCustomerName(customer[0]);
    setCustomerId(customer[1]);
  };

  const onSearch = (searchText) => {
    getCustomersList(searchText);
  };

  const fetchCustomer = (searchText) => {
    getCustomersList(searchText);
  };

  simpleValidator.current.purgeFields();

  const getDueDate = (value) => {
    const regex = /^[0-9\b]+$/;
    if (regex.test(value)) {
      setDaysLeft(value);
      const day = moment().utc().tz(selectedAccount.timezone).startOf('day')
        .format(MOMENT_FORMAT);
      const dayIssued = moment().utc().tz(selectedAccount.timezone).startOf('day')
        .utc()
        .format(MOMENT_FORMAT);
      let dueDate;
      if (dateSelected && dateSelected !== day) {
        dueDate = moment(dateSelected).add(value, 'days');
      } else {
        dueDate = moment(day).add(value, 'days');
        setIssueDate(dayIssued);
      }
      // eslint-disable-next-line
      setPaymentDueDate(moment.utc(dueDate).tz(selectedAccount.timezone).startOf('day').utc().format(MOMENT_FORMAT));
    } else {
      setDaysLeft(null);
    }
  };

  const onExpiryDateChange = (date) => {
    // eslint-disable-next-line
    const Date = moment(date).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    const dueDate = moment(utcDate).add(daysLeft, 'days');
    setIssueDate(utcDate);
    setDateSelected(Date);
    const dateDue = moment.tz(dueDate, selectedAccount.timezone);
    setPaymentDueDate(dateDue.utc().format(MOMENT_FORMAT));
  };

  const onItemSearch = (itemSearch) => {

    const sortParams = '&sortBy=name&direction=ASC';
    const currentPage = 1;
    const filterParams = '';
    debouncedFetchInventoryItems({
      currentPage, sortParams, filterParams, nameSearch: itemSearch ? (itemSearch === '' ? null : `&name.LIKE=${itemSearch}`) : null,
    });
  };

  const onProductSearch = (productSearch) => {
    getProductsList(productSearch);
  };

  const addNewProduct = (newProduct: Object) => {
    const {
      productName,
      currency,
      productDescription,
      amount,
      productNotes,
    } = newProduct;
    const productAmount = amount.replace(/[. ,:-]+/g, '');
    const params = {
      name: productName,
      currencyCode: currency,
      description: productDescription,
      amount: Number(productAmount),
      note: productNotes,
      paymentMethodType: [
        'CARD',
      ],
      statementDescription: '',
    };
    addNewProcut({
      params,
    });
    setAddedProduct(true);
    setLineItemAdd(false);
  };


  const addNewCustomer = (newCustomer: Object) => {
    const {
      name,
      email,
      phoneNumber,
      taxStatus,
      taxValue,
      taxId,
      countryCode,
    } = newCustomer;
    const params = {
      name,
      phoneNumber: phoneNumber ? `${countryCode}${phoneNumber}` : null,
      email,
      taxId,
      taxStatus,
      taxValue,
    };
    addCustomer({
      params,
    });
    setAddedCustomer(true);
    setLineItemAdd(false);
  };


  const addNewAddress = (newAddress: Object) => {
    const newAddressAdded = {
      street: newAddress.streetName,
      city: newAddress.cityName,
      state: newAddress.stateName,
      postalCode: newAddress.zipCode,
      countryId: getCountryAlpha2(newAddress.countryId),
    };
    if (newAddress.addressType === 'Billing Address') {
      setBillingAddress(newAddressAdded);
    } else if (newAddress.addressType === 'Shipping Address') {
      setShippingAddress(newAddressAdded);
    }
    setAddAddressModal(false);
  };

  const handleChange = (e, index) => {
    const gid = e[1];
    const itemInfo = inventoryItems.filter((item) => (item.gid === gid));
    const list = [...lineItemDtoList];
    const { price, priceType} = itemInfo[0];
    list[index].name = itemInfo[0].name;
    list[index].description = itemInfo[0].name;
    list[index].note = itemInfo[0].note;
    list[index].rate = (price / 100).toFixed(2);
    list[index].itemGid = gid;
    list[index].priceType = priceType;
    if (list[index].quantity) {
      list[index].price = price * parseInt(list[index].quantity, 10);
    }
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };

  const handleChangeProduct = (e, index) => {
    const gid = e[1];
    const productInfo = productsList.filter((item) => (item.gid === gid));
    const list = [...lineItemDtoList];
    const { amount } = productInfo[0];
    list[index].name = productInfo[0].name;
    list[index].description = productInfo[0].name;
    list[index].note = productInfo[0].note;
    list[index].rate = (amount / 100).toFixed(2);
    if (list[index].quantity) {
      list[index].amount = amount * parseInt(list[index].quantity, 10);
    }
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
  };

  const handleRemoveClick = index => {
    const list = [...lineItemDtoList];
    list.splice(index, 1);
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.currentTarget;
    let regex;
    if (name === 'quantity') {
      regex = /^[0-9\b]+$/;
    } else if (name === 'rate') {
      regex = /^\d*\.?\d*$/;
    }
    const list = [...lineItemDtoList];
    if (regex.test(value) || value === '') {
      list[index][name] = value;
      if (name === 'quantity' && value && list[index].rate) {
        const sum = list[index].rate * 100;
        list[index].amount = sum * parseInt(list[index][name], 10);
      } else if (name === 'rate' && value && list[index].quantity) {
        const sum = list[index].rate * 100;
        list[index].amount = sum * parseInt(list[index].quantity, 10);
      }
    } else if (!regex.test(value) && name === 'quantity') {
      list[index][name] = '1';
    } else if (!regex.test(value) && name === 'rate') {
      list[index][name] = 0;
    }
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };

  const formatInputChange = (e, index) => {
    const { name, value } = e.currentTarget;
    const list = [...lineItemDtoList];
    const rateValue = parseFloat(value);
    if (Number.isInteger(rateValue)) {
      list[index][name] = parseInt(value, 10).toFixed(2);
    }
    setLineItemDtosList(list);
  };

  const handleAddClick = () => {
    const sortParams = '&sortBy=name&direction=ASC';
    const currentPage = 1;
    const filterParams = '';
    debouncedFetchInventoryItems({
      currentPage, sortParams, filterParams,
    });
    setLineItemAdd(false);
    setLineItemDtosList([...lineItemDtoList, {
      amount: 0,
      name: '',
      description: '',
      quantity: '1',
      note: '',
      rate: null,
    }]);
  };


  const getAmountWithShippingFee = (amount) => {
    if (shippingAmount) {
      const numericAmount = parseFloat(amount.replace(/,/g, '')) || 0;
      const numericShippingAmount = parseFloat(shippingAmount) || 0;
      const addedShippingAmount = numericAmount + numericShippingAmount;
      return addedShippingAmount.toFixed(2);
    }
    return amount;
  };
  const getFinalAmount = (amount) => {
    let finalAmount;
    const listOfTaxes = [...allSelectedTaxrates];
    const taxLists = listOfTaxes.filter((item) => item.taxRates.inclusive === false);
    if (taxLists.length > 0) {
      const percentageAmount = taxLists.reduce(
        (total, item) => total + ((parseFloat(amount.replace(/,/g, '')) * item.taxRates.percentage) / 100), 0,
      );
      finalAmount = formatNumber((parseFloat(amount.replace(/,/g, '')) + percentageAmount).toFixed(2), currencyObj);
    } else {
      finalAmount = amount;
    }
    return finalAmount;
  };

  const getTotalAmount = (value) => {
    let finalAmount;
    if (value) {
      const finalValue = parseFloat(value.replace(/,/g, ''));
      finalAmount = formatNumber((totalAmount - finalValue).toFixed(2), currencyObj);
    } else {
      finalAmount = formatNumber((totalAmount).toFixed(2), currencyObj);
    }
    return finalAmount;
  };

  const getAmountOff = (value) => {
    let amountOff;
    if (value.percentageOff) {
      const amount = (totalAmount * value.percentageOff) / 100;
      amountOff = formatNumber((amount).toFixed(2), currencyObj);
    } else if (value.amountOff) {
      amountOff = formatNumber((value.amountOff / 100).toFixed(2), currencyObj);
    }
    return amountOff;
  };

  const addCoupon = (coupon: Object) => {
    const {
      name,
      percentageOff,
      amountOff,
      validity,
      expiresAt,
      maximumRedemption,
      code,
      minimumAmount,
      promotionalRedeemed,
      promoExpiresAt,
      promotional,
      customerGid,
      validiyInMonths,
    } = coupon;
    const params = {
      active: true,
      name,
      percentageOff: (percentageOff ? parseFloat(percentageOff) : null),
      amountOff: (amountOff ? parseInt((amountOff).replace(/[_\W]+/g, ''), 10) : null),
      validity,
      expiresAt,
      maximumRedemption: (maximumRedemption ? parseInt(maximumRedemption, 10) : null),
      validiyInMonths: (validiyInMonths ? parseInt(validiyInMonths, 10) : null),
      currencyCode,
    };
    const payload = {
      active: true,
      code,
      enabledForCheckout: true,
      expiresAt: promoExpiresAt,
      maximumRedemption: promotionalRedeemed ? parseInt(promotionalRedeemed, 10) : null,
      minimumAmount: minimumAmount ? parseInt((minimumAmount).replace(/[_\W]+/g, ''), 10) : null,
      minimumAmountCurrency: currencyCode,
      restrictedToCustomers: [
        customerGid,
      ],
    };
    if (promotional) {
      addNewCoupon({
        params,
        payload,
      });
    } else if (!promotional) {
      addNewCoupon({
        params,
      });
    }
    setCouponRedirect(true);
    setRedirect(false);
  };

  const newCouponAdd = () => {
    setSelectCouponModal(false);
    setAddCouponModal(true);
  };

  const newTaxAdd = () => {
    setSelectTaxrateModal(false);
    setAddTaxModal(true);
  };

  const updateCoupon = (Coupon: Object) => {
    setUpdatedCoupon(Coupon);
    setSelectCouponModal(false);
  };

  const removeCoupon = () => {
    setUpdatedCoupon({});
    setCouponMsg('');
    simpleValidator.current.purgeFields();
  };

  const removeShippingFee = () => {
    setShippingAmount(null);
    simpleValidator.current.purgeFields();
  };

  const fetchTaxDetails = (searchParams) => {
    getTaxes(searchParams);
  };

  const updateTaxrate = (Taxrate: Object) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.push(Taxrate);
    setAllSelectedTaxrates(listOfTaxes);
    setSelectTaxrateModal(false);
  };

  const updateShippingFee = (shippingFee: Number) => {
    setShippingAmount(shippingFee);
    setSelectedShippingFeeModal(false);
  };

  const removeTax = (i) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.splice(i, 1);
    setAllSelectedTaxrates(listOfTaxes);
  };

  const addTaxrate = (taxRate: Object) => {
    const {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      taxDescription,
    } = taxRate;
    const params = {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      description: taxDescription,
    };
    addNewTaxRate({
      params,
    });
    setTaxRedirect(true);
  };

  const addNewRecipient = (event) => {
    event.preventDefault();
    setAddRecipient([...addRecipient, '']);
  };
  const handleRemoveEmail = (i) => {
    const list = [...addRecipient];
    list.splice(i, 1);
    setAddRecipient(list);
  };

  const newInvoice = (event, value) => {
    let billAddress = null;
    let shipAddress = null;
    const taxesList = [];
    const couponId = updatedCoupon.gid ? updatedCoupon.gid : null;
    const listOfTaxes = [...allSelectedTaxrates];
    for (let i = 0; i < listOfTaxes.length; i += 1) {
      taxesList.push(listOfTaxes[i].taxRates.gid);
    }

    if (billingAddress && billingAddress.street) {
      billAddress = {
        street: billingAddress.street,
        city: billingAddress.city,
        state: billingAddress.state,
        postalCode: billingAddress.postalCode,
        countryCode: billingAddress.countryId ? billingAddress.countryId : billingAddress?.country ? billingAddress?.country?.alpha2 : billingCntryId,
      };
    }
    if (shippingAddress && shippingAddress.street) {
      shipAddress = {
        street: shippingAddress.street,
        city: shippingAddress.city,
        state: shippingAddress.state,
        postalCode: shippingAddress.postalCode,
        countryCode: shippingAddress.countryId ? shippingAddress.countryId : shippingAddress?.country ? shippingAddress?.country?.alpha2 : billingCntryId,
      };
    }

    const finalAmount = getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)));
    if (finalAmount <= 0) {
      simpleValidator.current.fields.coupon = false;
      setCouponMsg(validators.coupon.validCoupon);
    }

    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      setFixErrors(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      forceUpdate(1);
      return;
    }
    if (value === 'ACTIVE') {
      setDisabled(true);
    }

    const list = [...lineItemDtoList];
    for (let i = 0; i < list.length; i += 1) {
      list[i].amount = list[i].rate && (list[i].rate).replace(/[. ,:-]+/g, '');
    }
    setLineItemDtosList([...list]);

    // eslint-disable-next-line
    const invoiceLineItems = lineItemDtoList.map((invoicesLine) => {
      return { ...invoicesLine, currencyCode: currencyType };
    });

    const invoiceStatus = value;

    let filterRecipient = null;
    if (addRecipient && addRecipient.length !== 0) {
      filterRecipient = addRecipient.filter(val => val !== '');
    }

    const invoiceParams = {
      currencyCode,
      customerGid: customerId,
      couponGid: couponId,
      daysUntilDue: daysLeft,
      paymentDueDate,
      issueDate,
      amount: totalAmount * 100,
      status: invoiceStatus,
      description,
      recipientsEmail: filterRecipient && filterRecipient.length !== 0 ? filterRecipient : null,
      taxRates: taxesList,
      customerNote: notes,
      billingAddress: billAddress,
      shippingAddress: shipAddress,
      subscriptionGid: null,
      invoiceLineItems,
      invoiceLineItemDtos: invoiceLineItems,
      shippingAmount: shippingAmount * 100,
    };

    const params = isEnableGenerationInvoiceNo
      ? invoiceParams
      : { invoiceNumber: invoiceNo, ...invoiceParams };

    addNewInvoice({ params });
    updateCustomer({
      gid: customerId,
      params,
    });
    setRedirect(true);
  };

  const addSelectedItem = (customer: Object) => {
    const {
      name,
      price,
      alternateName,
      onlineName,
      onlineEnabled,
      sku,
      isPos,
      priceType,
      primaryColor,
      categories,
      order,
      modifierGroups,
      isItemAvailable,
      cost,
      logoUrl,
      // eslint-disable-next-line no-shadow
      description,
      tagDetails,
      enableStock,
      enableDynamicAvailability,
      stockQuantity,
      onlineAvailability,
      posAvailability,
      shopDetails,
      isRecurring,

    } = customer;
    const totalPrice = price ? price.replace(/[^\d]/g, '') : 0;
    const totalCost = cost ? cost.replace(/[^\d]/g, '') : 0;

    const params = {
      name,
      alternateName,
      onlineName,
      onlineEnabled,
      sku,
      price: Number(totalPrice),
      hidden: !isPos,
      priceType,
      sortOrder: Number(order),
      colorCode: primaryColor,
      categoryGids: categories || null,
      shopGids: shopDetails || [],
      modifierGroupGids: modifierGroups || null,
      available: isItemAvailable,
      cost: Number(totalCost),
      imageUrl: logoUrl,
      description,
      tagGids: tagDetails || [],
      enableStock,
      enableDynamicAvailability,
      stockQuantity: Number(stockQuantity),
      onlineAvailability: Number(onlineAvailability * 100),
      posAvailability: Number(posAvailability * 100),
      recurring: isRecurring,
    };
    addInventoryItems({
      params,
    });
    setAddedItem(true);
  };


  const selectedCustomer = (value) => {
    const customer = customersList.find((item) => item.gid === value[1]);
    setBillingAddress(customer?.billingAddress || {});
    setShippingAddress(customer?.shippingAddress || {});
  };


  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
        <Col>
          <SpButton
            type="secondary"
            shape="round"
            className="mr-4"
            ghost
            onClick={(e) => newInvoice(e, 'DRAFT')}
          >
            Save Draft
          </SpButton>
          <SpButton
            type="primary"
            shape="round"
            className="mr-4"
            disabled={disabled}
            onClick={(e) => newInvoice(e, 'ACTIVE')}
          >
            Send Invoice
          </SpButton>
        </Col>
      </Row>

      {/* ADD CUSTOMER MODAL */}
      {addCustomerModal && (
        <AddNewCustomer
          visible={addCustomerModal}
          submitting={submitting}
          close={() => setAddCustomerModal(false)}
          submit={addNewCustomer}
          selectedAccount={selectedAccount}
        />
      )}

      {/* ADD ADDRESS MODAL */}
      {addAddressModal && (
        <AddNewAddress
          visible={addAddressModal}
          submitting={submitting}
          close={() => setAddAddressModal(false)}
          submit={addNewAddress}
          zipTag={zipTag}
          selectedAccount={selectedAccount}
          addressType={addressType}
          addressMethod={addressMethod}
          address={address}
        />
      )}

      {/* ADD ITEM MODAL */}

      {addItemModal && (
        <AddItem
          visible={addItemModal}
          submitting={submitting}
          itemsList={itemsList}
          modifierItemsList={modifierItemsList}
          labelList={labelList}
          selectedAccount={selectedAccount}
          close={() => setAddItemModal(false)}
          submit={addSelectedItem}
        />
      )}

      {/* ADD PRODUCT MODAL */}
      {addProductModal && (
        <AddNewProduct
          visible={addProductModal}
          submitting={submitting}
          supportedCurrency={supportedCurrency}
          close={() => setAddProductModal(false)}
          submit={addNewProduct}
          selectedAccount={selectedAccount}
        />
      )}

      {/* SELECT COUPON MODAL */}
      {selectCouponModal && (
        <SelectCoupon
          visible={selectCouponModal}
          submitting={submitting}
          close={() => setSelectCouponModal(false)}
          submit={updateCoupon}
          newCouponAdd={newCouponAdd}
        />
      )}

      {/* ADD COUPON MODAL */}
      {addCouponModal && (
        <AddCoupon
          visible={addCouponModal}
          submitting={submitting}
          close={() => setAddCouponModal(false)}
          submit={addCoupon}
          selectedAccount={selectedAccount}
          allCustomers={customersList}
          fetchCustomer={fetchCustomer}
        />
      )}

      {/* SELECT TAXRATE MODAL */}
      {selectTaxrateModal && (
        <SelectTaxrate
          visible={selectTaxrateModal}
          submitting={submitting}
          close={() => setSelectTaxrateModal(false)}
          submit={updateTaxrate}
          newTaxAdd={newTaxAdd}
        />
      )}
      {/* SELECT SHIPPINFEE MODAL */}
      {selectShippingFeeModal && (
        <SelectShippingFee
          visible={selectShippingFeeModal}
          submitting={submitting}
          close={() => setSelectedShippingFeeModal(false)}
          submit={updateShippingFee}
          newTaxAdd={newTaxAdd}
          prefix={prefix}
        />
      )}

      {/* ADD TAX MODAL */}
      {addTaxModal && (
        <AddTaxrate
          visible={addTaxModal}
          submitting={submitting}
          close={() => setAddTaxModal(false)}
          submit={addTaxrate}
          selectedAccount={selectedAccount}
          allTaxes={allTaxes}
          fetchTaxDetails={fetchTaxDetails}
        />
      )}

      <SpWrapper>
        <SpFormWrapper>
          <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
            <Col className="d-flex align-items-center">
              {
                selectedAccount && selectedAccount.largeLogo ? (
                  <img src={selectedAccount.largeLogo} alt={selectedAccount.name} style={{ width: '108px', height: '74px' }} />
                ) : (
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">{selectedAccount.name}</SpText>
                )
              }
            </Col>
            <Col className="d-flex">
              <div className="mr-2 mt-3">
                <SpText color="rgba(0, 0, 0, 0.7)">Powered by</SpText>
              </div>
              <Icon
                style={{
                  fontSize: '24px',
                }}
                component={swirepaylogo}
              />
            </Col>
          </Row>
          {
            fixErrors && (
              <Row>
                <Col className="ml-3 mb-3">
                  <SpError>
                    Please fix one or more errors below.
                  </SpError>
                </Col>
              </Row>
            )
          }
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col className="d-flex" span={12}>
              <SpText fontSize="18px" style={{ width: '40%' }}>INVOICE No.</SpText>
              <div className="mr-5">
                <Input
                  value={invoiceNo}
                  disabled={isEnableGenerationInvoiceNo}
                  onChange={(e) => setInvoiceNo(e.currentTarget.value)}
                  placeholder="Enter invoice number"
                />
              </div>
            </Col>
            <Col span={6} style={{ marginTop: '-25px' }}>
              <Row>
                <Col>
                  <SpText fontWeight="600">Due Date</SpText>
                  <div className="d-flex pt-1 pb-1 mr-2">
                    <Input
                      style={{ width: '60%' }}
                      className="pt-1 mr-2"
                      value={daysLeft}
                      maxlength="3"
                      onChange={(e) => getDueDate(e.currentTarget.value)}
                      onKeyPress={e => {
                        const keyCode = e.charCode || e.which;
                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col>
                  <SpText className="pt-1">days after issue date</SpText>
                </Col>
              </Row>
              <SpError>
                {simpleValidator.current.message('Due date', daysLeft, 'required|numeric')}
              </SpError>
            </Col>
            <Col span={5} style={{ marginTop: '-25px', marginLeft: '-25px' }}>
              <SpText fontWeight="600">Currency</SpText>
              <div className="d-flex pt-1 pb-1 mr-5">
                <Select
                  className="w-100"
                  showSearch
                  defaultValue={currencyCode}
                  onChange={(e) => onCurrencyChange(e)}
                >
                  {supportedCurrency.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Description</SpText></div>
              <div className="pt-1 pb-1 mr-5">
                <Input
                  value={description}
                  onChange={(e) => setDescription(e.currentTarget.value)}
                  placeholder="Enter brief description of invoice"
                />
                <SpError>
                  {simpleValidator.current.message('Description', description, 'required')}
                </SpError>
              </div>
            </Col>
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Issue Date</SpText></div>
              <div className="pt-1 pb-1 mr-5">
                <DatePicker
                  defaultValue={moment(issueDateDisplay, dateFormat)}
                  format={dateFormat}
                  allowClear={false}
                  disabledDate={current => current && current < moment().subtract(1, 'days')}
                  onChange={onExpiryDateChange}
                />
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Bill to</SpText></div>
              <div className="pt-1 pb-1 mr-5">
                <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                  <Select
                    value={customerName || 'Select Customer'}
                    style={{ width: '100%' }}
                    showSearch
                    onSearch={onSearch}
                    onChange={(e) => getCustomers(e)}
                    onSelect={e => selectedCustomer(e)}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '2px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                          <span
                            style={{
                              flex: 'none',
                              padding: '8px',
                              display: 'block',
                              cursor: 'pointer',
                              color: '#279dfe',
                            }}
                            onClick={() => setAddCustomerModal(true)}
                          >
                            <Icon type="plus-circle" />&nbsp;Add New Customer
                          </span>
                        </div>
                      </div>
                    )}
                  >
                    {getSortedList(customersList).map((item) => (
                      <Option key={item.gid} value={[item.name, item.gid]}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <SpError>
                  {simpleValidator.current.message('Customer', customerName, 'required')}
                </SpError>
              </div>
            </Col>
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Expiry Date</SpText></div>
              <div className="pt-1 pb-1 mr-5">
                <DatePicker
                  format={dateFormat}
                  allowClear={false}
                  disabledDate={current => current && current < moment().subtract(1, 'days')}
                />
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="d-flex pt-1 pb-1">
                <div className="mr-3"><SpText fontWeight="600">Billing Address</SpText></div>
                <div className="d-flex ml-1">
                  {billingAddress && billingAddress.street && (
                    <>
                      <SpText
                        color="#279dfe"
                        fontWeight="600"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setAddress(billingAddress);
                          setAddAddressModal(true);
                          setAddressType('Billing Address');
                          setAddressMethod('Update');
                        }}
                      >
                        Update
                      </SpText>
                      <span className="mr-2 ml-2" style={{ fontWeight: '600' }}>|</span>
                      <SpText
                        color="#279dfe"
                        fontWeight="600"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setBillingAddress({});
                        }}
                      >
                        Remove
                      </SpText>
                    </>
                  )}
                </div>
              </div>
              <div className="pt-1 pb-1 pr-5">
                {
                  billingAddress.street ? (
                    <SpText color="#434343">
                      {`${billingAddress.street}, ${billingAddress.city},
                        ${billingAddress.state} ${billingAddress.postalCode}`}
                    </SpText>
                  ) : (
                    <SpText
                      color="#279dfe"
                      fontWeight="600"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setAddAddressModal(true);
                        setAddress(billingAddress);
                        setAddressType('Billing Address');
                        setAddressMethod('Add');
                      }}
                    >
                      {'\u002B'}&nbsp;Add billing address
                    </SpText>
                  )
                }
              </div>
            </Col>
            <Col span={12}>
              <div className="d-flex pt-1 pb-1">
                <div className="mr-3"><SpText fontWeight="600">Shipping Address</SpText></div>
                <div className="d-flex ml-1">
                  {shippingAddress && shippingAddress.street && (
                    <>
                      <SpText
                        color="#279dfe"
                        fontWeight="600"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setAddress(shippingAddress);
                          setAddAddressModal(true);
                          setAddressType('Shipping Address');
                          setAddressMethod('Update');
                        }}
                      >
                        Update
                      </SpText>
                      <span className="mr-2 ml-2" style={{ fontWeight: '600' }}>|</span>
                      <SpText
                        color="#279dfe"
                        fontWeight="600"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setShippingAddress({});
                          setSameAddress(false);
                        }}
                      >
                        Remove
                      </SpText>
                    </>
                  )}
                </div>
              </div>
              <div className="pt-1 pb-1 pr-5">
                {
                  shippingAddress.street ? (
                    <SpText color="#434343">
                      {`${shippingAddress.street}, ${shippingAddress.city},
                  ${shippingAddress.state} ${shippingAddress.postalCode}`}
                    </SpText>
                  ) : (
                    <>
                      <SpText
                        color="#279dfe"
                        fontWeight="600"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setAddress(shippingAddress);
                          setAddAddressModal(true);
                          setAddressType('Shipping Address');
                          setAddressMethod('Add');
                        }}
                      >
                        {'\u002B'}&nbsp;Add shipping address
                      </SpText>
                      <div className="d-flex mt-1">
                        <Checkbox
                          checked={sameAddress}
                          className="mb-1 mr-2"
                          onChange={({ target: { checked } }) => {
                            setSameAddress(checked);
                            setShippingAddress(billingAddress);
                            setShippingCntryId(billingCntryId);
                          }}
                        />
                        Same as the billing address
                      </div>
                    </>
                  )
                }
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mt-2">
            <Col span={12}>
              <div className="mr-3">
                <SpText fontWeight="600">Recipient List</SpText>
              </div>
            </Col>
          </Row>
          {addRecipient && addRecipient.length !== 0 ? addRecipient.map((item, i) => (
            <>
              <Row type="flex" className="my-2 mt-2">
                <Col span={10}>
                  <Input
                    value={item}
                    key={`Email${i}`}
                    onChange={(e) => {
                      const list = [...addRecipient];
                      list[i] = e.currentTarget.value;
                      setAddRecipient(list);
                    }}
                    placeholder="Email"
                  />
                </Col>
                <Col span={2} className="mt-1">
                  <Icon
                    type="minus-circle"
                    style={{
                      cursor: 'pointer', color: '#ff4d50', marginLeft: '10px',
                    }}
                    onClick={() => handleRemoveEmail(i)}
                  />
                </Col>
              </Row>
              <SpError className="mb-2">
                {simpleValidator.current.message('email', item, 'email|checkLowerCase',
                  { messages: { email: 'The email must be valid' } })}
              </SpError>
            </>
          )) : null}
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="mr-3">
                <SpText
                  color="#279dfe"
                  fontWeight="600"
                  style={{ cursor: 'pointer' }}
                  onClick={addNewRecipient}
                >
                  {'\u002B'}&nbsp;Add New Recipient
                </SpText>
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2" style={{ background: '#eeeeff', padding: '5px' }}>
            <Col span={10}>
              <div className="pt-1 pb-1 pl-1"><SpText fontWeight="600">Description</SpText></div>
            </Col>
            <Col span={14}>
              <Row type="flex" justify="end">
                <Col span={6} className="pt-1 mr-3" align="center">
                  <SpText fontWeight="600" fontSize="14px">Rate</SpText>
                </Col>
                <Col span={6} className="pt-1 mr-3" align="center">
                  <SpText fontWeight="600" fontSize="14px">Quantity</SpText>
                </Col>
                <Col span={6} className="pt-1 mr-3" align="right">
                  <SpText fontWeight="600" fontSize="14px">Total</SpText>
                </Col>
                <Col span={2} className="pt-1" align="right" />
              </Row>
            </Col>
          </Row>
          {!legacySubscriber
            ? (
              <>

                <Row type="flex" className="my-2 mb-3 mt-2" style={{ padding: '0 0 0 5px' }}>
                  {lineItemDtoList.map((item, i) => (
                    <>
                      <Col span={10} className="pb-1">
                        <div className="w-100 pr-3">
                          <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                            <Select
                              value={item.name || 'Select an item'}
                              style={{ width: '100%' }}
                              showSearch
                              onSearch={onItemSearch}
                              onSelect={e => handleChange(e, i)}
                              dropdownRender={menu => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: '2px 0' }} />
                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                                    <span
                                      style={{
                                        flex: 'none',
                                        padding: '8px',
                                        display: 'block',
                                        cursor: 'pointer',
                                        color: '#279dfe',
                                      }}
                                      onClick={() => {
                                        setAddItemModal(true);
                                        setLineItemIndex(i);
                                      }}
                                    >
                                      <Icon type="plus-circle" />&nbsp;Add New Item
                                    </span>
                                  </div>
                                </div>
                              )}
                            >
                              {inventoryItems && inventoryItems.map((inventoryitem) => (
                                <Option
                                  key={inventoryitem.gid}
                                  value={[
                                    inventoryitem.name,
                                    inventoryitem.gid,
                                    inventoryitem.price,
                                  ]}

                                >
                                  {inventoryitem.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <SpError>
                            {simpleValidator.current.message('item', lineItemDtoList[i].name, 'required')}
                          </SpError>
                        </div>
                      </Col>
                      <Col span={14}>
                        <Row type="flex" justify="end">
                          <Col span={6} className="mr-3">
                            <Input
                              name="rate"
                              value={item.rate && item.rate}
                              disabled={item.priceType !== 'VARIABLE'}

                              placeholder="0.00"
                              prefix={prefix}
                              onChange={e => handleInputChange(e, i)}
                              onBlur={e => formatInputChange(e, i)}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <SpError>
                              {simpleValidator.current.message('Rate', lineItemDtoList[i].rate, 'required|amount')}
                            </SpError>
                          </Col>
                          <Col span={6} className="mr-3">
                            <Input
                              name="quantity"
                              value={item.quantity}
                              onChange={e => handleInputChange(e, i)}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <SpError>
                              {simpleValidator.current.message('Quantity', lineItemDtoList[i].quantity, 'required|numeric')}
                            </SpError>
                          </Col>
                          <Col span={6} className="pt-2 mr-3" align="right">
                            <SpText fontWeight="600">{prefix} {formatNumber((item.rate * item.quantity).toFixed(2), currencyObj)}</SpText>
                          </Col>
                          <Col span={2} className="pt-1" align="right">
                            {i !== 0 ? (
                              <Icon
                                type="minus-circle"
                                className="mr-2 mt-1"
                                style={{ cursor: 'pointer', color: '#ff4d50' }}
                                onClick={() => handleRemoveClick(i)}
                              />
                            ) : (
                              <span className="mr-2 mt-2">&nbsp;&nbsp;&nbsp;</span>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ))}
                </Row>
                <Row type="flex" className="mt-2">
                  <Col span={10}>
                    <div className="pt-1 pb-1 ml-2" style={{ cursor: 'pointer' }}>
                      <SpText
                        fontWeight="600"
                        color="#279dfe"
                        onClick={handleAddClick}
                      >
                        {'\u002B'}&nbsp; Add another item
                      </SpText>
                    </div>
                  </Col>
                  <Col span={14}>
                    <Row type="flex" justify="end" className="ml-1">
                      <Col span={12} className="pt-1 ml-5" align="left">
                        <SpText fontWeight="600" fontSize="14px">Subtotal</SpText>
                      </Col>
                      <Col span={6} className="pt-1 mr-3" align="right">
                        <SpText fontWeight="600">
                          {`${prefix} ${formatNumber((totalAmount).toFixed(2), currencyObj)}`}
                        </SpText>
                      </Col>
                      <Col span={2} className="pt-1" align="right" />
                    </Row>
                  </Col>
                </Row>

              </>
            ) : (
              <>

                <Row type="flex" className="my-2 mb-3 mt-2" style={{ padding: '0 0 0 5px' }}>
                  {lineItemDtoList.map((item, i) => (
                    <>
                      <Col span={10} className="pb-1">
                        <div className="w-100 pr-3">
                          <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                            <Select
                              value={item.name || 'Select an Product'}
                              style={{ width: '100%' }}
                              showSearch
                              onSearch={onProductSearch}
                              onSelect={e => handleChangeProduct(e, i)}
                              dropdownRender={menu => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: '2px 0' }} />
                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                                    <span
                                      style={{
                                        flex: 'none',
                                        padding: '8px',
                                        display: 'block',
                                        cursor: 'pointer',
                                        color: '#279dfe',
                                      }}
                                      onClick={() => {
                                        setAddProductModal(true);
                                        setLineItemIndex(i);
                                      }}
                                    >
                                      <Icon type="plus-circle" />&nbsp;Add New Product
                                    </span>
                                  </div>
                                </div>
                              )}
                            >
                              {productsList.map((product) => (
                                <Option
                                  key={product.gid}
                                  value={[
                                    product.name,
                                    product.gid,
                                  ]}

                                >
                                  {product.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <SpError>
                            {simpleValidator.current.message('product', lineItemDtoList[i].name, 'required')}
                          </SpError>
                        </div>
                      </Col>
                      <Col span={14}>
                        <Row type="flex" justify="end">
                          <Col span={6} className="mr-3">
                            <Input
                              name="rate"
                              value={item.rate && item.rate}
                              placeholder="0.00"
                              prefix={prefix}
                              onChange={e => handleInputChange(e, i)}
                              onBlur={e => formatInputChange(e, i)}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <SpError>
                              {simpleValidator.current.message('Rate', lineItemDtoList[i].rate, 'required|amount')}
                            </SpError>
                          </Col>
                          <Col span={6} className="mr-3">
                            <Input
                              name="quantity"
                              value={item.quantity}
                              onChange={e => handleInputChange(e, i)}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <SpError>
                              {simpleValidator.current.message('Quantity', lineItemDtoList[i].quantity, 'required|numeric')}
                            </SpError>
                          </Col>
                          <Col span={6} className="pt-2 mr-3" align="right">
                            <SpText fontWeight="600">{prefix} {formatNumber((item.rate * item.quantity).toFixed(2), currencyObj)}</SpText>
                          </Col>
                          <Col span={2} className="pt-1" align="right">
                            {i !== 0 ? (
                              <Icon
                                type="minus-circle"
                                className="mr-2 mt-1"
                                style={{ cursor: 'pointer', color: '#ff4d50' }}
                                onClick={() => handleRemoveClick(i)}
                              />
                            ) : (
                              <span className="mr-2 mt-2">&nbsp;&nbsp;&nbsp;</span>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ))}
                </Row>
                <Row type="flex" className="mt-2">
                  <Col span={10}>
                    <div className="pt-1 pb-1 ml-2" style={{ cursor: 'pointer' }}>
                      <SpText
                        fontWeight="600"
                        color="#279dfe"
                        onClick={handleAddClick}
                      >
                        {'\u002B'}&nbsp; Add another Product
                      </SpText>
                    </div>
                  </Col>
                  <Col span={14}>
                    <Row type="flex" justify="end" className="ml-1">
                      <Col span={12} className="pt-1 ml-5" align="left">
                        <SpText fontWeight="600" fontSize="14px">Subtotal</SpText>
                      </Col>
                      <Col span={6} className="pt-1 mr-3" align="right">
                        <SpText fontWeight="600">
                          {`${prefix} ${formatNumber((totalAmount).toFixed(2), currencyObj)}`}
                        </SpText>
                      </Col>
                      <Col span={2} className="pt-1" align="right" />
                    </Row>
                  </Col>
                </Row>

              </>
            )}

          <Row type="flex" justify="end" className="ml-2 mb-2">
            {updatedCoupon.gid ? (
              <Col span={14}>
                <Row type="flex" justify="end">
                  <Col span={12} className="pt-1 ml-3" align="left">
                    <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                      <SpText>
                        {updatedCoupon.name}
                      </SpText><br />
                      <SpText fontSize="12px">
                        {getDiscountOff(updatedCoupon)}
                      </SpText>
                    </div>
                    <div>
                      <SpError>
                        {couponMsg}
                      </SpError>
                    </div>
                  </Col>
                  <Col span={6} className="pt-2 mr-3" align="right">
                    <SpText fontWeight="600">({prefix} {getAmountOff(updatedCoupon)})</SpText>
                  </Col>
                  <Col span={2} className="pt-1" align="right">
                    <Icon
                      type="minus-circle"
                      className="mr-2 mt-1"
                      style={{ cursor: 'pointer', color: '#ff4d50' }}
                      onClick={removeCoupon}
                    />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col span={14}>
                <Row type="flex" justify="end">
                  <Col span={12} className="pt-1 ml-3" align="left">
                    <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                      <SpText
                        fontWeight="600"
                        color="#279dfe"
                        onClick={() => setSelectCouponModal(true)}
                      >
                        Apply Coupon
                      </SpText>
                    </div>
                  </Col>
                  <Col span={8} className="pt-1 mr-3" align="right" />
                </Row>
              </Col>
            )}
          </Row>
          <Row type="flex" justify="end" className="ml-2">
            {
              allSelectedTaxrates.map((item, i) => (
                <Col span={14}>
                  <Row type="flex" justify="end">
                    <Col span={9} className="pt-1 ml-3" align="left">
                      <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                        <SpText>
                          {item.taxRates.displayName}
                        </SpText><br />
                        <SpText fontSize="12px">
                          {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                        </SpText>
                      </div>
                    </Col>
                    <Col span={3} className="pt-1" align="right">{item.taxRates.percentage} %</Col>
                    <Col span={6} className="pt-2 mr-3" align="right">
                      <SpText fontWeight="600">
                        {prefix} {getTaxAmount(item.taxRates, totalAmount, getAmountOff(updatedCoupon), selectedAccount)}
                      </SpText>
                    </Col>
                    <Col span={2} className="pt-1" align="right">
                      <Icon
                        type="minus-circle"
                        className="mr-2 mt-1"
                        style={{ cursor: 'pointer', color: '#ff4d50' }}
                        onClick={() => removeTax(i)}
                      />
                    </Col>
                  </Row>
                </Col>
              ))
            }
          </Row>

          <Row type="flex" justify="end" className="ml-2 mb-2">
            <Col span={14}>
              <Row type="flex" justify="end">
                <Col span={12} className="pt-1 ml-3" align="left">
                  <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => setSelectTaxrateModal(true)}
                    >
                      {
                        allSelectedTaxrates.length !== 0 && (
                          <span>{'\u002B'}&nbsp;</span>
                        )
                      }
                      Add Tax Rates
                    </SpText>
                  </div>
                </Col>
                <Col span={8} className="pt-1 mr-3" align="right" />
              </Row>
            </Col>
          </Row>
          {/* ----------------------------------------------------------ADD SHIPPING FEE--------------------------------------------------------- */}
          { shippingAddress.street && (
          <Row type="flex" className="mt-2">
            <Col span={10} />
            <Col span={14}>
              <Row type="flex" justify="end" className="ml-1">
                <Col span={12} className="pt-1 ml-5" align="left">
                  <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>

                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => setSelectedShippingFeeModal(true)}
                    >
                      {
                        allSelectedTaxrates.length !== 0 && (
                          <span>{'\u002B'}&nbsp;</span>
                        )
                      }
                      Add Shipping Fee
                    </SpText>
                  </div>
                </Col>
                {shippingAmount
                  ? (
                    <>
                      <Col span={6} className="pt-1 mr-3" align="right">
                        <SpText fontWeight="600">
                          {prefix} {shippingAmount}
                        </SpText>
                      </Col>
                      <Col span={2} className="pt-1" align="right">
                        <Icon
                          type="minus-circle"
                          className="mr-2 mt-1"
                          style={{ cursor: 'pointer', color: '#ff4d50' }}
                          onClick={removeShippingFee}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={6} className="pt-1 mr-3" align="right" />
                    </>
                  )}
                <Col span={2} className="pt-1" align="right" />
              </Row>
            </Col>
          </Row>
          )}
          <Line opacity="0.3" className="mt-1 mb-1" />
          <Row type="flex" justify="end" className="mr-3">
            <Col className="mt-1 pb-1 mr-3 pr-1">
              <SpText className="mr-5" fontWeight="500" fontSize="20px">
                Total Amount
              </SpText>
              <SpText fontWeight="600" fontSize="20px">
                {
                  (getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))) < 0 ? (
                    `${prefix} 0.00`
                  ) : (
                    `${prefix} ${getAmountWithShippingFee(getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon))))}`
                  )
                }
              </SpText>
            </Col>
          </Row>

          <Line />


          {/* --------------------------------------------------------------------NOTES--------------------------------------------------- */}
          <Row className="my-2 mb-3 mt-2">
            <Col>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Notes</SpText></div>
              <div className="pt-1 pb-1 mr-5">
                <Input
                  defaultValue={notes}
                  onChange={(e) => setNotes(e.currentTarget.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="my-2 mb-3 mt-2" style={{ background: '#eeeeff', padding: '5px' }}>
            <Col>
              <SpText fontWeight="600">{selectedAccount.name}</SpText>
            </Col>
          </Row>
        </SpFormWrapper>
      </SpWrapper>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  inventoryItems: state.inventoryItems.content,
  itemsList: state.inventoryItems.itemsList.content,
  modifierItemsList: state.inventoryItems.modifierItemsList.content,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  loading: state.loading.loading,
  customersList: state.invoiceDetails.customersList,
  addedNewCustomer: state.invoiceDetails.newCustomer,
  invoiceDetails: state.invoiceDetails.invoice,
  taxList: state.invoiceDetails.taxList,
  newCoupon: state.invoiceDetails.newCoupon,
  newTaxrate: state.invoiceDetails.newTaxrate,
  labelList: state.inventoryPrinterLabel.labelList.content,
  productsList: state.invoiceDetails.productsList,
  addedNewProduct: state.invoiceDetails.newProduct,
  internalAccount: state.account.internalAccount,
  addedNewItem: state.inventoryItems.addedItem.entity,
  invoiceSeqNo: state.sequenceGenerators.invoiceSequence.content,
  currentInvoiceSeqNo: state.sequenceGenerators.currentSequence,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryItems: param => dispatch(inventoryItemsActions.fetchInventoryItems({
    payload: param,
  })),
  fetchInventoryItemsSearch: param => dispatch(inventoryItemsActions.fetchInventoryItemsSearch({
    payload: param,
  })),
  addNewInvoice: param => dispatch(invoiceDetailsAction.addNewInvoice({
    payload: param,
  })),
  getCustomersList: param => dispatch(invoiceDetailsAction.getCustomersList({
    payload: param,
  })),
  addCustomer: param => dispatch(invoiceDetailsAction.addCustomer({
    payload: param,
  })),
  fetchInvoiceDetails: gid => dispatch(invoiceDetailsAction.fetchInvoiceDetails({
    payload: gid,
  })),
  addNewCoupon: param => dispatch(invoiceDetailsAction.addNewCoupon({
    payload: param,
  })),
  getTaxes: param => dispatch(invoiceDetailsAction.getTaxes({
    payload: param,
  })),
  addNewTaxRate: param => dispatch(invoiceDetailsAction.addNewTaxRate({
    payload: param,
  })),
  fetchItemsList: param => dispatch(inventoryItemsActions.fetchItemsList({
    payload: param,
  })),
  fetchModifierItemsList: param => dispatch(inventoryItemsActions.fetchModifierItemsList({
    payload: param,
  })),
  fetchPrinterLabelList: (payload) => dispatch(inventoryPrinterLabelActions.fetchPrinterLabelList({
    payload,
  })),
  getProductsList: param => dispatch(invoiceDetailsAction.getProductsList({
    payload: param,
  })),
  addNewProcut: param => dispatch(invoiceDetailsAction.addNewProcut({
    payload: param,
  })),
  addInventoryItems: param => dispatch(inventoryItemsActions.addInventoryItems({
    payload: param,
  })),
  updateCustomer: param => dispatch(customersActions.updateCustomer({
    payload: param,
  })),
  fetchAllInvoiceSequence: param => dispatch(sequenceGeneratorsActions.fetchAllInvoiceSequence({
    payload: param,
  })),
  fetchCurrentSeqInvoice: param => dispatch(sequenceGeneratorsActions.fetchCurrentSeqInvoice({
    payload: param,
  })),
  addInvoiceSequence: param => dispatch(sequenceGeneratorsActions.addInvoiceSequence({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice);
