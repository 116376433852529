import { SUBSCRIPTION_BUTTON } from '../actionTypes';

export const fetchSubscriptionButton = (action) => ({
  type: SUBSCRIPTION_BUTTON.FETCH,
  payload: action.payload,
});

export const addSubscriptionButton = (action) => ({
  type: SUBSCRIPTION_BUTTON.ADD,
  payload: action.payload,
});
