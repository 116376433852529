import React, { useEffect } from 'react';
import {
  Card, Col, Row,
} from 'antd';
import { SpText } from 'components/DesignKit';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  PAGES,
} from 'appconstants';
import Loading from 'components/Loading';
import { salesforceActions } from 'store/actions';

type Props = {
  spObject: Array,
  mappingList: Array,
  fetchSpObjectList: Function,
  fetchMappingList: Function,
  loader: boolean,
};

const Configure = (props: Props) => {
  const history = useHistory();
  const {
    spObject,
    mappingList,
    fetchSpObjectList,
    fetchMappingList,
    loader,
  } = props;

  const getData = () => {
    fetchSpObjectList();
    fetchMappingList();
  };

  useEffect(() => {
    getData();
  }, []);

  const isMappingsEmpty = (data) => data.length === 0;

  if (loader) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <Row>
          <Col>
            <SpText fontSize="18px">CONFIGURE</SpText>
          </Col>
        </Row>
        <div className="site-card-wrapper pr-4 pl-4 pt-4">
          {spObject && spObject.map((item) => (
            <Row gutter={16}>
              <Col span={8}>
                {
                  (!mappingList || isMappingsEmpty(mappingList)) ? (
                    <Card
                      style={{ borderRadius: '10px' }}
                    >
                      <Row gutter={16} className="mt-2">
                        <Col span={6} className="mr-2">
                          <SpText fontWeight="600" fontSize="18px">{item.name}</SpText>
                        </Col>
                      </Row>
                      <Row gutter={16} className="mt-2">
                        <Col className="mt-1">
                          <SpText fontSize="13px" color="#5C5C5C">Configure {item.name} to update data in salesforce.</SpText>
                        </Col>
                      </Row>
                      <Row gutter={16} className="mt-2">
                        <Col className="d-flex justify-content-end align-items-center">
                          <SpText
                            fontWeight="600"
                            color="#0090ff"
                            className="cursor-pointer"
                            onClick={() => history && history.push(PAGES.INTEGRATIONS_INVOICE)}
                          > Configure &gt;
                          </SpText>
                        </Col>
                      </Row>
                    </Card>
                  ) : (
                    <Card
                      style={{ borderRadius: '10px' }}
                      disabled
                    >
                      <Row gutter={16} className="mt-2">
                        <Col span={6} className="mr-2">
                          <SpText fontWeight="600" fontSize="18px" color="#d7d7d7">{item.name}</SpText>
                        </Col>
                      </Row>
                      <Row gutter={16} className="mt-2">
                        <Col className="mt-1">
                          <SpText fontSize="13px" color="#d7d7d7">Configure {item.name} to update data in salesforce.</SpText>
                        </Col>
                      </Row>
                      <Row gutter={16} className="mt-2">
                        <Col className="d-flex justify-content-end align-items-center">
                          <SpText
                            fontWeight="600"
                            color="#d7d7d7"
                          > Already Configured
                          </SpText>
                        </Col>
                      </Row>
                    </Card>
                  )
                }
              </Col>
            </Row>
          ))}
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  spObject: state.salesforce.spObject,
  mappingList: state.salesforce.mappingList,
  loader: state.loading.loading,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSpObjectList: param => dispatch(salesforceActions.fetchSpObjectList({
    payload: param,
  })),
  fetchMappingList: param => dispatch(salesforceActions.fetchMappingList({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Configure);
