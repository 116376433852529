// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedTerminal: Object,
};

const EditTerminalPin = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedTerminal,
  } = props;

  const [, forceUpdate] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [pin, setPin] = useState(selectedTerminal.terminalPins && selectedTerminal.terminalPins[0].pin);

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit(pin);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Terminal Pin"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div>
          <label htmlFor=""><SpH5>Terminal Pin</SpH5></label>
          <Input
            placeholder="Terminal Pin"
            value={pin}
            onChange={(e) => setPin(e.currentTarget.value)}
            maxLength={4}
          />
          <SpError>
            {simpleValidator.current.message('terminal pin', pin, 'required|numeric')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditTerminalPin);
