import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import styled from 'styled-components';
import {
  Card,
  Icon,
  Row,
  Col,
  Table,
} from 'antd';
import {
  SpText,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';
import Loading from 'components/Loading';
import ReactEcharts from 'echarts-for-react';
import formatNumber from 'utils/formatNumber';
import { walletActions } from 'store/actions';
import arrow from 'imgs/arrow.png';
import walletImg from 'imgs/wallet.png';
import cashbackImg from 'imgs/cashback.png';
import walletIcon from 'components/NavIcons/WalletIcon';
import rightArrow from 'components/NavIcons/rightArrow';
import getWalletStatus from 'utils/getWalletStatuses';
import getWalletBackground from 'utils/getWalletBackground';
import star from 'imgs/StarIcon.svg';
import AddWallet from './components/AddWallet';

// $FlowFixMe
const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  fetchWallet: Function,
  test: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  wallet: Object,
  addConfirmWallet: Function,
  addWallet: Function,
  fetchTransactions: Function,
  transactions: Array<Object>,
  pinSetUp: Function,
  verifyPin: Function,
  pinWallet: Object,
  loading: Boolean,
  confirmWalletAlias: Boolean,
  consfirmWalletPin: Boolean,
};

const WalletList = (props: Props) => {
  const {
    test,
    selectedAccount,
    fetchWallet,
    wallet,
    addConfirmWallet,
    addWallet,
    fetchTransactions,
    transactions,
    pinSetUp,
    verifyPin,
    pinWallet,
    loading,
    confirmWalletAlias,
    consfirmWalletPin,
  } = props;

  const [addWalletModal, setAddWalletModal] = useState(false);
  const [displayBoard, setDisplayBoard] = useState(false);

  const { prefix } = selectedAccount && selectedAccount.currency;
  const { toFixed } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;
  const countryCode = selectedAccount && selectedAccount.country && selectedAccount.country.alpha2;

  const debouncedFetchWallet = debounce(fetchWallet, 2000, { leading: true });

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    debouncedFetchWallet();
    fetchTransactions();
  }, [test, selectedAccount]);

  const backToAlias = () => {
    addWallet({});
  };

  const backToPin = () => {
    pinSetUp({});
  };

  useEffect(() => {
    if (wallet && wallet.gid) {
      if ((wallet.aliases && wallet.aliases && wallet.aliases[0] && wallet.aliases[wallet.aliases.length - 1].alias)
        && !(wallet.walletPin && wallet.walletPin.gid)) {
        setCurrent(1);
        setDisplayBoard(true);
      } else if (!(wallet.aliases && wallet.aliases && wallet.aliases[0] && wallet.aliases[wallet.aliases.length - 1].alias)) {
        setCurrent(0);
        setDisplayBoard(true);
      } else if ((wallet.aliases && wallet.aliases && wallet.aliases[0] && wallet.aliases[wallet.aliases.length - 1].alias)
        && (wallet.walletPin && wallet.walletPin.gid) && (wallet.walletPin && wallet.walletPin.status !== 'PENDING')) {
        setAddWalletModal(false);
        setDisplayBoard(false);
      }
    } else if (wallet && !wallet.gid) {
      setDisplayBoard(true);
    }
  }, [wallet]);

  const transactionsColumns = [
    {
      title: 'NAME',
      dataIndex: 'transactingAccount.name',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <span>
          {name || <>&#8211;</>}

        </span>
      ),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, walletTransactionLog) => {
        const total = (amount / 100).toFixed(toFixed);
        const reqAmount = (walletTransactionLog.amount - walletTransactionLog.cashbackRedeemed);
        const finalReedemAmount = (reqAmount / 100).toFixed(toFixed);

        if (walletTransactionLog.cashbackRedeemed !== null && walletTransactionLog.type === 'TRANSACTION_DEBIT') {
          return (
            <span style={{ color: '#FF4B51' }}> {`${prefix} ${formatNumber(finalReedemAmount, currencyType)}`}&nbsp;&nbsp;
              {<s style={{ color: '#858585' }}>{`${prefix} ${formatNumber(total, currencyType)}`}</s>}
            </span>
          );
        }
        if ((walletTransactionLog.type === 'TRANSACTION_DEBIT') || (walletTransactionLog.type === 'PAYOUT')) {
          return (
            <span style={{ color: '#FF4B51' }}> {`${prefix} ${formatNumber(total, currencyType)}`} </span>
          );
        }
        if (walletTransactionLog.type === 'REFUND') {
          return (
            <span style={{ color: '#FF4B51' }}> {`${prefix} ${formatNumber(total, currencyType)}`} </span>
          );
        }
        return (
          <span style={{ color: '#00C289' }}> {`${prefix} ${formatNumber(total, currencyType)}`} </span>
        );
      },

    },
    {
      title: 'STATUS',
      dataIndex: 'type',
      width: '18%',
      align: 'left',
      render: (status) => (
        <SpStatusTag style={{ backgroundColor: getWalletBackground(status) }}>
          {getWalletStatus(status) ? getWalletStatus(status) : <>&#8211;</>}
        </SpStatusTag>
      ),
    },
    {
      title: 'TRANSACTION ID',
      dataIndex: 'gid',
      width: '20%',
      align: 'left',
      render: (gid) => {
        const trnsId = gid.split('-')[1];
        return (
          <>
            {
              trnsId
                ? trnsId.slice(-8) : <>&#8211;</>
            }
          </>
        );
      },
      sorter: false,
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '25%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }

        return (
          <>&#8211;</>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'REDEMPTION',
      dataIndex: 'walletTransactionLog',
      align: 'center',
      render: (walletTransactionLog) => {
        if ((walletTransactionLog && walletTransactionLog.canRedeem === true)
          && (walletTransactionLog && walletTransactionLog.cashbackRedeemed === 0)
        ) {
          return (
            <div style={{
              height: '25%', width: '25%', display: 'inline-block', backgroundColor: '#FFB55B', borderRadius: '50%',
            }}
            >
              <img src={star} alt="" style={{ width: '15px', height: '15px' }} />
            </div>
          );
        }
        return (
          <div style={{
            height: '25%', width: '25%', display: 'inline-block', backgroundColor: '#C7C7C7', borderRadius: '50%',
          }}
          >
            <img src={star} alt="" style={{ width: '15px', height: '15px', marginBottom: '2px' }} />
          </div>
        );
      },
    },
  ];

  const addNewWallet = (values) => {
    let params;
    if (!consfirmWalletPin) {
      params = {
        pin: values.payload.pin,
      };
      pinSetUp(params);
    } else if (consfirmWalletPin) {
      const { gid } = pinWallet;
      params = {
        code: values.payload.code,
      };
      verifyPin({
        gid,
        params,
      });
    }
  };

  const setUpWallet = (payload) => {
    let params;
    if (!confirmWalletAlias) {
      if (payload.payload.type === 'EMAIL') {
        params = {
          alias: payload.payload.alias,
          type: payload.payload.type,
        };
      } else if (payload.payload.type === 'PHONE') {
        params = {
          alias: payload.payload.phone,
          type: payload.payload.type,
        };
      }
      console.log(params, 'paramsparamsparams');
      addWallet(params);
    } else if (confirmWalletAlias) {
      if (payload.payload.type === 'EMAIL') {
        params = {
          alias: payload.payload.alias,
          code: payload.payload.code,
        };
      } else if (payload.payload.type === 'PHONE') {
        params = {
          alias: payload.payload.phone,
          code: payload.payload.code,
        };
      }
      addConfirmWallet(params);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        (countryCode && countryCode !== 'US')
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <h5>Wallet is only available for the country USA.</h5>
              </div>
            </Card>
          )
          : !displayBoard ? (
            <>
              <Row gutter={[16, 24]}>
                <Col xs={25} lg={12}>
                  <Card bodyStyle={{ minHeight: '150px', backgroundColor: '#474973' }}>
                    <SpText fontSize="16px" color="#FFFFFF" fontWeight="700">AVAILABLE BALANCE</SpText>
                    <div className="d-flex">
                      <Col xs={8} lg={6}>
                        <img src={walletImg} alt="step" className="mr-2" />
                      </Col>
                      <Col xs={16} lg={18}>
                        <h3 className="font-weight-light my-0 text-center my-3" style={{ color: '#FFFFFF' }}>
                          {prefix} {formatNumber((wallet.balance / 100).toFixed(2), currencyType)}
                        </h3>
                      </Col>
                    </div>

                    <div className="d-flex justify-content-end" style={{ color: '#FFFFFF' }}>
                      &nbsp;
                    </div>
                  </Card>
                </Col>
                <Col xs={24} lg={12}>
                  <Card bodyStyle={{ minHeight: '150px', backgroundColor: '#3E8BFF' }}>
                    <SpText fontSize="16px" color="#FFFFFF" fontWeight="700">AVAILABLE CASHBACK POINTS</SpText>
                    <div className="d-flex">
                      <Col xs={8} lg={6}>
                        <img src={cashbackImg} alt="step" className="mr-2" />
                      </Col>
                      <Col xs={16} lg={18}>
                        <h3 className="font-weight-light mr-2 my-0 text-center my-3" style={{ color: '#FFFFFF' }}>
                          {
                            wallet.availableCashback ? (wallet.availableCashback) : 0
                          }
                        </h3>
                      </Col>
                    </div>
                    <div className="d-flex justify-content-end">
                      <SpText fontSize="14px" fontWeight="700" color="#FFFFFF" style={{ cursor: 'pointer' }}>See Details <Icon
                        component={rightArrow}
                        className="ml-2"
                      />
                      </SpText>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Card className="my-2">
                <Row gutter={[16, 24]}>
                  <Col xs={25} lg={12}>
                    <SpText fontSize="20px" fontWeight="600" color="#999999">LIFETIME STATISTICS</SpText>
                    <Card bodyStyle={{ minHeight: '100px', backgroundColor: '#E0ECFF' }} className="mt-2">
                      <SpText fontSize="16px" color="#3D3D3D" fontWeight="600">Cashback Points collected</SpText>
                      <h3 className="font-weight-light mr-2 my-0 text-left my-3" style={{ color: '#262866' }}>
                        {
                          wallet.totalRewardsEarned ? (wallet.totalRewardsEarned) : 0
                        }
                      </h3>
                    </Card>
                    <Row gutter={[16, 24]} className="my-2">
                      <Col xs={25} lg={12}>
                        <Card bodyStyle={{ minHeight: '100px', backgroundColor: '#E0ECFF' }}>
                          <SpText fontSize="16px" color="#3D3D3D" fontWeight="600">Earned</SpText>
                          <h3 className="font-weight-light mr-2 my-0 text-left my-3" style={{ color: '#262866' }}>
                            {prefix} {
                              wallet.totalRewardsRedeemed ? formatNumber((wallet.totalRewardsRedeemed / 100).toFixed(2), currencyType) : 0.00
                            }
                          </h3>
                        </Card>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Card bodyStyle={{ minHeight: '100px', backgroundColor: '#E0ECFF' }}>
                          <SpText fontSize="16px" color="#3D3D3D" fontWeight="600">Saved local Busineess</SpText>
                          <h3 className="font-weight-light mr-2 my-0 text-left my-3" style={{ color: '#262866' }}>
                            {prefix} {
                              wallet.totalRewardsRedeemed ? formatNumber((wallet.totalRewardsRedeemed / 100).toFixed(2), currencyType) : 0.00
                            }
                          </h3>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} lg={12}>
                    <SpText fontSize="20px" fontWeight="600" color="#999999">BREAKUP OF TRANSACTIONS</SpText>
                    <ReactEcharts
                      option={{
                        textStyle: {
                          fontFamily: 'Open Sans',
                        },
                        tooltip: {
                          show: false,
                          trigger: 'item',
                        },
                        title: {
                          text: 'No\nTransactions',
                          top: 'center',
                          left: 'center',
                          textStyle: {
                            fontSize: 14,
                          },
                        },
                        legend: {
                          show: false,
                          orient: 'horizontal',
                          top: 'bottom',
                          left: 'center',
                          icon: 'circle',
                        },
                        series: [
                          {
                            animation: false,
                            name: 'No Transactions',
                            type: 'pie',
                            radius: ['35%', '65%'],
                            avoidLabelOverlap: false,
                            hoverAnimation: false,
                            silent: true,
                            label: {
                              normal: {
                                show: false,
                              },
                              emphasis: {
                                show: false,
                              },
                            },
                            labelLine: {
                              normal: {
                                show: false,
                              },
                            },
                            color: ['#F1F1F1'],
                            data: [{ name: 'No Data', value: 0 }],
                          },
                        ],
                      }}
                      notMerge
                      lazyUpdate
                    />
                  </Col>
                </Row>
              </Card>
              <Card className="my-2">
                <SpText fontSize="20px" fontWeight="600" color="#999999">RECENT PAYMENTS</SpText>
                <Table
                  rowClassName={() => 'ant-table-clickable-row'}
                  className="mt-2"
                  rowKey={(record) => record.gid}
                  dataSource={transactions}
                  columns={transactionsColumns}
                  pagination={false}
                />
              </Card>
            </>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-50">
              <Icon
                component={walletIcon}
                className={test ? '' : 'mb-4'}
                style={{ fontSize: '32px', textAlign: 'center' }}
              />
              {
                test && (
                  <div>
                    <StatusTag style={{ backgroundColor: '#F4A261' }}>TEST DATA</StatusTag>
                  </div>
                )
              }
              <SpText
                fontSize="36px"
                color="#65668C"
              >
                Welcome
              </SpText>
              <SpText
                fontSize="20px"
                color="#65668C"
              >
                Setup your {test ? 'test ' : ''}wallet and start earning rewards today.
              </SpText>
              <SpButton
                onClick={() => setAddWalletModal(true)}
                className="mt-4"
              >
                Setup Wallet <img className="ml-2" src={arrow} alt="arrow" />
              </SpButton>
            </div>
          )
      }
      {/* ADD WALLET MODAL */}
      {addWalletModal && (
        <AddWallet
          visible={addWalletModal}
          submitting
          selectedAccount={selectedAccount}
          contactList={[]}
          statesInCountry={[]}
          country={selectedAccount.country.name}
          cntryId={selectedAccount.country.id}
          supportsSchedule
          isIndia
          zipTag="Zipcode"
          close={() => setAddWalletModal(false)}
          submit={addNewWallet}
          setUpWallet={setUpWallet}
          confirmWalletAlias={confirmWalletAlias}
          current={current}
          backToAlias={backToAlias}
          consfirmWalletPin={consfirmWalletPin}
          backToPin={backToPin}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  user: state.user,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
  wallet: state.wallet,
  transactions: state.wallet.content,
  submitting: state.loading.submitting,
  pinWallet: state.wallet.walletPin,
  confirmWalletAlias: state.wallet.confirmWalletAlias,
  consfirmWalletPin: state.wallet.consfirmWalletPin,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWallet: param => dispatch(walletActions.fetchWallet({
    payload: param,
  })),
  addWallet: param => dispatch(walletActions.addWallet({
    payload: param,
  })),
  addConfirmWallet: param => dispatch(walletActions.addConfirmWallet({
    payload: param,
  })),
  fetchTransactions: param => dispatch(walletActions.fetchTransactions({
    payload: param,
  })),
  pinSetUp: param => dispatch(walletActions.pinSetUp({
    payload: param,
  })),
  verifyPin: param => dispatch(walletActions.verifyPin({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletList);
