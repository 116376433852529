/* eslint-disable */
import React from 'react';

const noProof = () => (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.3 23.2424H3C1.89543 23.2424 1 22.347 1 21.2424V3C1 1.89543 1.89543 1 3 1H13.7846C14.5914 1 15.3642 1.32498 15.9286 1.90158L18.444 4.4716C18.9927 5.03225 19.3 5.78553 19.3 6.57002V20.2424C19.3 21.8993 17.9569 23.2424 16.3 23.2424Z" stroke="#AEAEAE" stroke-width="2" />
        <line x1="3.86664" y1="4.81818" x2="14.5" y2="4.81818" stroke="#AEAEAE" stroke-width="2" />
        <line x1="3.86664" y1="8.69697" x2="16.4333" y2="8.69697" stroke="#AEAEAE" stroke-width="2" />
        <path d="M4.83325 14.2222L6.12214 15.5152L8.69992 12.6061" stroke="#AEAEAE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <rect x="14" y="17" width="12" height="13" fill="white" />
        <path d="M17.5 11.082L27.3123 29L7.68774 29L17.5 11.082Z" fill="#FFC700" stroke="#454545" stroke-width="2" />
        <path d="M18.7715 17.4688L18.5195 23.2988H16.9141L16.6621 17.4688H18.7715ZM16.6094 25.1152C16.6094 24.834 16.7109 24.5996 16.9141 24.4121C17.1172 24.2207 17.3809 24.125 17.7051 24.125C18.0293 24.125 18.291 24.2207 18.4902 24.4121C18.6934 24.5996 18.7949 24.834 18.7949 25.1152C18.7949 25.3965 18.6934 25.6328 18.4902 25.8242C18.291 26.0117 18.0293 26.1055 17.7051 26.1055C17.3809 26.1055 17.1172 26.0117 16.9141 25.8242C16.7109 25.6328 16.6094 25.3965 16.6094 25.1152Z" fill="#454545" />
    </svg>
);

export default noProof;