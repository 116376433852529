import React from 'react';

/* eslint-disable */

const developers = ({ fill = "silver" }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M7.92578 -0.0371094C7.3735 -0.0371094 6.92578 0.410606 6.92578 0.962891C6.92578 1.51518 7.3735 1.96289 7.92578 1.96289L11.9999 1.96289C12.5522 1.96289 12.9999 1.51518 12.9999 0.962891C12.9999 0.410606 12.5522 -0.0371094 11.9999 -0.0371094H7.92578Z" fill={fill} />
      <path d="M1.00124 2.00248C1.55421 2.00248 2.00248 1.55421 2.00248 1.00124C2.00248 0.44827 1.55421 -8.56817e-08 1.00124 -8.56817e-08C0.44827 -8.56817e-08 0 0.44827 0 1.00124C0 1.55421 0.44827 2.00248 1.00124 2.00248Z" fill={fill} />
      <path d="M8.09957 3.52266C8.43621 3.64734 8.60803 4.02131 8.48335 4.35795L5.46497 12.5077C5.34029 12.8443 4.96632 13.0162 4.62968 12.8915C4.29304 12.7668 4.12121 12.3928 4.24589 12.0562L7.26428 3.90645C7.38896 3.56981 7.76293 3.39798 8.09957 3.52266Z" fill={fill} />
      <path d="M0.240918 7.52168C-0.0129231 7.77552 -0.0129231 8.18708 0.240918 8.44092L2.76729 10.9673C3.02113 11.2211 3.43269 11.2211 3.68653 10.9673C3.94037 10.7135 3.94037 10.3019 3.68653 10.0481L1.61978 7.9813L3.68653 5.91455C3.94037 5.66071 3.94037 5.24915 3.68653 4.99531C3.43269 4.74147 3.02113 4.74147 2.76729 4.99531L0.240918 7.52168Z" fill={fill} />
      <path d="M10.2699 10.9673L12.7963 8.44092C13.0501 8.18708 13.0501 7.77552 12.7963 7.52168L10.2699 4.99531C10.0161 4.74147 9.6045 4.74147 9.35066 4.99531C9.09682 5.24915 9.09682 5.66071 9.35066 5.91455L11.4174 7.9813L9.35066 10.0481C9.09682 10.3019 9.09682 10.7135 9.35066 10.9673C9.6045 11.2211 10.0161 11.2211 10.2699 10.9673Z" fill={fill} />
      <path d="M5.47868 1.00124C5.47868 1.55421 5.03041 2.00248 4.47744 2.00248C3.92447 2.00248 3.4762 1.55421 3.4762 1.00124C3.4762 0.44827 3.92447 -8.56817e-08 4.47744 -8.56817e-08C5.03041 -8.56817e-08 5.47868 0.44827 5.47868 1.00124Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="13" height="13" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default developers;
