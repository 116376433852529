// @flow
import React, {
  useRef,
  useState,
  createRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Steps,
} from 'antd';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import {
  notificationActions,
  transfersActions,
  vendorsActions,
} from 'store/actions';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { NOTIFICATION } from 'store/actionTypes';
import { SpButton } from 'components/DesignKit';

import TransferDetails from './TransferDetails';
import ContactDetails from './ContactDetails';
import ConfirmTransfer from './ConfirmTransfer';

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  contactList: Array<Object>,
  setNotification: Function,
  getContactList: Function,
  statesInCountry: Array<Object>,
  zipTag: String,
  phoneCode: string,
  submit: Function,
  isIndia: boolean,
  supportsSchedule: boolean,
  fetchCustomTypes: Function,
  customSuggestions: Array<Object>,
};

const { Step } = Steps;

const AddTransferLink = (props: Props) => {
  const {
    visible,
    close,
    submitting,
    selectedAccount,
    phoneCode,
    submit,
    setNotification,
    getContactList,
    contactList,
    statesInCountry,
    zipTag,
    isIndia,
    supportsSchedule,
    fetchCustomTypes,
    customSuggestions,
  } = props;

  const [, forceUpdate] = useState();
  const transferDetailsRef = createRef();
  const contactDetailsRef = createRef();
  const [refArray, setRefArray] = useState([transferDetailsRef, contactDetailsRef]);
  const [current, setCurrent] = useState(0);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const [transferAmount, setTransferAmount] = useState('');
  const [description, setDescription] = useState('');
  const [transferType, setTransferType] = useState(isIndia ? 'IMPS' : 'ACH');
  const [transferDate, setTransferDate] = useState('');
  const [transferApiDate, setTransferApiDate] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactGid, setContactGid] = useState('');
  const [email, setEmail] = useState('');
  const [contactResult, setContactResult] = useState(contactList);
  const [phoneNumberCode, setPhoneCode] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [phoneFormate, setPhoneFormate] = useState();
  const [contactType, setContactType] = useState('');
  const [customType, setCustomType] = useState('');
  const [contactTypeName, setContactTypeName] = useState('');
  const [notifyType, setNotifyType] = useState('');
  const [notifyTypeName, setNotifyTypeName] = useState('');
  const [disableContact, setDisableContact] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const currencyType = selectedAccount && selectedAccount.currency;
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

  const transferDetailsData = {
    transferAmount,
    description,
    transferType,
    transferDate,
    transferApiDate,
    redirectUrl,
  };

  const contactDetailsData = {
    contactName,
    contactGid,
    contactResult,
    email,
    phoneNumberCode,
    disableContact,
    contactPhone,
    phoneFormate,
    contactType,
    customType,
    contactTypeName,
    customSuggestions,
    notifyType,
    notifyTypeName,
    countryCode,
  };

  useEffect(() => {
    setContactResult(contactList);
    const displayFormat = moment().utc().tz(selectedAccount.timezone).startOf('day')
      .format(dateFormat);
    // eslint-disable-next-line
    const selectedDate = moment.utc().tz(selectedAccount.timezone).endOf('day').utc().format(MOMENT_FORMAT);
    if (selectedAccount && selectedAccount.timezone) {
      setTransferDate(displayFormat);
      setTransferApiDate(selectedDate);
    }
  }, []);

  useEffect(() => {
    setContactResult(contactList);
  }, [contactList]);

  useEffect(() => {
    if (current === 2 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const fetchContactDetails = (search) => {
    getContactList(search);
  };

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
    fetchCustomTypes({ type: 'CONTACT_TYPE' });
  }, []);

  const next = async () => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setTransferAmount(valuesArray[0].transferAmount);
      setDescription(valuesArray[0].description);
      setTransferType(valuesArray[0].transferType);
      setTransferDate(valuesArray[0].transferDate);
      setTransferApiDate(valuesArray[0].transferApiDate);
      setRedirectUrl(valuesArray[0].redirectUrl);
      setCurrent(current + 1);
    } else if (current === 1) {
      validationArray.push(refArray[1].current.validate());
      const contactDetails = refArray[1].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setContactName(contactDetails.contactName);
      setContactGid(contactDetails.contactGid);
      setEmail(contactDetails.email);
      setContactPhone(contactDetails.contactPhone);
      setPhoneCode(contactDetails.phoneNumberCode);
      setPhoneFormate(contactDetails.phoneFormate);
      setContactType(contactDetails.contactType);
      setCustomType(contactDetails.customType);
      setContactTypeName(contactDetails.contactTypeName);
      setNotifyType(contactDetails.notifyType);
      setNotifyTypeName(contactDetails.notifyTypeName);
      setDisableContact(contactDetails.disableContact);
      setCountryCode(contactDetails.countryCode);
      setCurrent(current + 1);
    } else if (current === 2) {
      setDisableButton(true);
      let contactAddGid;
      if (!contactDetailsData.contactGid) {
        const contactObject = {
          name: contactDetailsData.contactName,
          contactType: contactDetailsData.contactType,
          customType: contactDetailsData.customType,
          email: contactDetailsData.email,
          phoneNumber: `${contactDetailsData.phoneNumberCode}${contactDetailsData.contactPhone}`,
        };
        try {
          const { data: { entity: { gid } } } = await axiosAuthInstance.post(API_END_POINTS.GET_CONTACTS, contactObject);
          contactAddGid = gid;
        } catch (error) {
          setNotification({
            type: NOTIFICATION.ERROR,
            payload: error.response.data && error.response.data.message,
          });
        }
      }
      let contactDataGid;
      if (contactAddGid) {
        contactDataGid = {
          contactAddGid,
        };
      }
      const payload = Object.assign(transferDetailsData, contactDetailsData, contactDataGid);
      submit(
        payload,
      );
    }
  };

  const prev = () => {
    setDisableButton(false);
    if (current === 0) {
      close();
    } else if (current === 1) {
      setCurrent(current - 1);
    } else if (current === 2) {
      setCurrent(current - 1);
    } else if (current === 3) {
      setCurrent(current - 1);
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="625px"
      footer={current === 0
        ? [
          <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
          <SpButton key="2" onClick={next}>Next</SpButton>,
        ]
        : current === 1
          ? [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next}>Next</SpButton>,
          ]
          : [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next} disabled={disableButton}>Create</SpButton>,
          ]}
      title="Create Transfer Link"
    >
      <>
        <div style={{ marginTop: '-8px' }}>
          <Steps current={current} className="subscriptions">
            <Step key="1" title="Transfer Link" />
            <Step key="2" title="Contact Details" />
            <Step key="3" title="Review" />
          </Steps>
        </div>
        <div className="px-5 mt-2">
          <div className={submitting ? 'OVERLAY' : ''} />
          {current === 0 && (
            <TransferDetails
              ref={refArray[0]}
              selectedAccount={selectedAccount}
              supportsSchedule={supportsSchedule}
              transferDetailsData={transferDetailsData}
              currencyType={currencyType}
            />
          )}
          {current === 1 && (
            <ContactDetails
              ref={refArray[1]}
              selectedAccount={selectedAccount}
              currencyType={currencyType}
              phoneCode={phoneCode}
              transferDetailsData={transferDetailsData}
              contactDetailsData={contactDetailsData}
              statesInCountry={statesInCountry}
              country={selectedAccount.country.name}
              cntryId={selectedAccount.country.id}
              zipTag={zipTag}
              fetchContactDetails={fetchContactDetails}
            />
          )}
          {current === 2 && (
            <ConfirmTransfer
              isIndia={isIndia}
              selectedAccount={selectedAccount}
              transferDetailsData={transferDetailsData}
              contactDetailsData={contactDetailsData}
            />
          )}
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  contactList: state.transfers.contactList,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  customSuggestions: state.vendors.customSuggestions,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  getContactList: param => dispatch(transfersActions.getContactList({
    payload: param,
  })),
  fetchCustomTypes: param => dispatch(vendorsActions.fetchCustomTypes({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddTransferLink);
