// @flow
import React from 'react';
import {
  Input,
  Icon,
  Checkbox,
  Radio,
} from 'antd';
import {
  SpText,
  SpWidgetButton,
  SpTermsAndServices,
} from 'components/PaymentWidgetDesignKit';
import swirepaylogo from 'components/NavIcons/swirepay';
import step1 from 'imgs/step1.png';
import step2 from 'imgs/step2.png';
import arrow from 'imgs/arrow.png';

type Props = {
  widgetDetails: Object,
};

const EmploymentInfo = (props: Props) => {
  const {
    widgetDetails,
  } = props;

  return (
    <>
      <SpText fontSize="17px">
        Information
      </SpText>
      <div style={{ textAlign: 'center' }}>
        <img src={step2} alt="step" className="mr-2" />
        <img src={step1} alt="step" className="mr-2" />
        <img src={step2} alt="step" />
      </div>
      <div className="mt-2" style={{ padding: '0% 13%' }}>
        <div style={{ textAlign: 'left' }}>
          <label htmlFor=""><SpText fontSize="14px">First Name</SpText></label>
        </div>
        <Input />
      </div>
      <div className="mt-2" style={{ padding: '0% 13%' }}>
        <div style={{ textAlign: 'left' }}>
          <label htmlFor=""><SpText fontSize="14px">Last Name</SpText></label>
        </div>
        <Input />
      </div>
      <div className="mt-2" style={{ padding: '0% 13%' }}>
        <div style={{ textAlign: 'left' }}>
          <label htmlFor=""><SpText fontSize="14px">Email</SpText></label>
        </div>
        <Input />
      </div>
      <div className="mt-3" style={{ padding: '0% 13%' }}>
        {
          widgetDetails.companyDonation && (
            <div style={{ textAlign: 'left' }}>
              <Checkbox className="mr-2" /> <SpText fontSize="14px">This payment is on behalf of a company</SpText>
            </div>
          )
        }
      </div>
      {
        widgetDetails.needEmploymentStatus && (
          <>
            <div className="mt-3" style={{ padding: '0% 13%' }}>
              <div style={{ textAlign: 'left' }}>
                <SpText fontSize="14px">Are you currently employed?</SpText>
              </div>
            </div>
            <div className="mt-3" style={{ padding: '0% 13%' }}>
              <div style={{ textAlign: 'left' }}>
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </Radio.Group>
              </div>
            </div>
          </>
        )
      }
      <div className="mt-3" style={{ padding: '0% 12%' }}>
        <SpWidgetButton
          backgroundColor={widgetDetails.primaryColor}
        >
          Next <img src={arrow} alt="arrow" />
        </SpWidgetButton>
      </div>
      <div className="mt-4" style={{ padding: '0% 13%' }}>
        <SpText className="mr-2" fontSize="10px" color="rgba(0, 0, 0, 0.7)">Powered by</SpText>
        <Icon
          style={{
            fontSize: '20px',
            paddingBottom: '10px',
          }}
          component={swirepaylogo}
        />
      </div>
      <SpTermsAndServices className="mt-3" style={{ padding: '0% 12%' }}>
        <SpText fontSize="11px" color="rgba(0, 0, 0, 0.7)">
          By paying with this form you agree to our
        </SpText><br />
        <SpText fontSize="13px" color="#3DA8FE">
          Terms of Services
        </SpText>
      </SpTermsAndServices>
    </>
  );
};

// $FlowFixMe
export default EmploymentInfo;
