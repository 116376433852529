import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import generateQuery from 'utils/generateQuery';

import {
  TABLE_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchTableDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_SHOP_TABLE}/${gid}`);

const fetchStaffDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.TABLE_USER}?shopTableGid.EQ=${gid}`);

//const fetchGuestDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.SHOP_RESERVATION}?shopTableName.EQ=${gid}`);

const fetchGuestDetails = async (payload) => {
  const query = generateQuery(payload.currentPage) || 1;
  const queryParam = payload.gid ? `&shopTableName.EQ=${payload.gid}` : '';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.SHOP_RESERVATION}${query}${queryParam}${sorting}`);
};

export function* getTableDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchTableDetails, action.payload);
      yield put({ type: TABLE_DETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchTableDetails, action);
      yield put({ type: TABLE_DETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: TABLE_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getStaffDetails(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(fetchStaffDetails, action.payload);
      yield put({ type: TABLE_DETAILS.SUCCESS_STAFF, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchStaffDetails, action);
      yield put({ type: TABLE_DETAILS.SUCCESS_STAFF, payload: entity });
    }
  } catch (e) {
    yield put({ type: TABLE_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getGuestDetails(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(fetchGuestDetails, action.payload);
      yield put({ type: TABLE_DETAILS.SUCCESS_GUEST, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchGuestDetails, action);
      yield put({ type: TABLE_DETAILS.SUCCESS_GUEST, payload: entity });
    }
  } catch (e) {
    yield put({ type: TABLE_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
