// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { createSelector } from 'reselect';
import _get from 'lodash.get';

import {
  Card,
  Row,
  Col,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  ratesDetailsActions,
  notificationActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
} from 'appconstants';
import formatNumber from 'utils/formatNumber';
import RBAC from 'components/rbac';
import CONSTANTS from 'store/constants';

import DeleteSelectedRate from './components/deleteRates';

type Props = {
  fetchRatesDetails: Function,
  DeleteRate: Function,
  ratesDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  ratesDetailsStatus: string,
  displayAmount: Object,
  submitting: boolean,
  validator: boolean,
}

const amountFilter = (ratesDetails) => ratesDetails;

const displayAmountSelector = createSelector(
  [amountFilter],
  (ratesDetails) => ({
    prefix: _get(ratesDetails, 'rate.currency.prefix', null),
    // eslint-disable-next-line max-len
    maxDisplay: formatNumber(ratesDetails.processingMax && (ratesDetails.processingMax / 100).toFixed(ratesDetails.rate.currency.toFixed), ratesDetails.rate && ratesDetails.rate.currency),
    // eslint-disable-next-line max-len
    minDisplay: formatNumber(ratesDetails.processingMin && (ratesDetails.processingMin / 100).toFixed(ratesDetails.rate.currency.toFixed), ratesDetails.rate && ratesDetails.rate.currency),
    // eslint-disable-next-line max-len
    rateDisplay: formatNumber(ratesDetails.accountRate && (ratesDetails.accountRate / 100).toFixed(ratesDetails.rate.currency.toFixed), ratesDetails.rate && ratesDetails.rate.currency),
    // eslint-disable-next-line max-len
    feeDisplay: formatNumber(ratesDetails.accountFee && (ratesDetails.accountFee / 100).toFixed(ratesDetails.rate.currency.toFixed), ratesDetails.rate && ratesDetails.rate.currency),
    // eslint-disable-next-line max-len
    taxDisplay: formatNumber(ratesDetails.accountTax && (ratesDetails.accountTax / 100).toFixed(ratesDetails.rate.currency.toFixed), ratesDetails.rate && ratesDetails.rate.currency),
    // eslint-disable-next-line max-len
    rewardRateDisplay: formatNumber(ratesDetails.rewardRate && (ratesDetails.rewardRate / 100).toFixed(ratesDetails.rate.currency.toFixed), ratesDetails.rate && ratesDetails.rate.currency),
    // eslint-disable-next-line max-len
    rewardCapDisplay: formatNumber(ratesDetails.rewardCap && (ratesDetails.rewardCap / 100).toFixed(ratesDetails.rate.currency.toFixed), ratesDetails.rate && ratesDetails.rate.currency),
  }),
);

const RatesDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchRatesDetails,
    DeleteRate,
    ratesDetails,
    loading,
    selectedAccount,
    ratesDetailsStatus,
    displayAmount,
    submitting,
    history,
    validator,
  } = props;
  const { gid } = props.match.params;
  const [deleteRateModal, setDeleteRateModal] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const getData = () => {
    fetchRatesDetails(gid);
  };

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  const deleteSelectedRate = async (rateGid: string) => {
    DeleteRate(rateGid);
    setRedirect(true);
  };

  const showDeleteRateModal = () => {
    setDeleteRateModal(true);
  };

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.RATES);
    } else if (!validator) {
      setDeleteRateModal(false);
    }
  }, [validator]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        ratesDetails.gid && ratesDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  {ratesDetails.acquirer && ratesDetails.acquirer.name
                    ? <SpText className="text-uppercase mr-4" fontSize="20px">{ratesDetails.acquirer.name}</SpText>
                    : ''}
                </Col>
                <RBAC permit={[ROLES.SUPERUSER]}>
                  <Col>
                    <SpButton
                      type="danger"
                      shape="round"
                      onClick={showDeleteRateModal}
                    >
                      Delete Rate
                    </SpButton>
                  </Col>
                </RBAC>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Category</span>
                  </Col>
                  <Col span={9}>
                    {ratesDetails.rate && ratesDetails.rate.category && ratesDetails.rate.category.name
                      ? <SpH5>{ratesDetails.rate.category.name}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Payment Type</span>
                  </Col>
                  <Col span={9}>
                    {ratesDetails.rate && ratesDetails.rate.paymentType && ratesDetails.rate.paymentType.name
                      ? <SpH5>{ratesDetails.rate.paymentType.name}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Country</span>
                  </Col>
                  <Col span={9}>
                    {ratesDetails.rate && ratesDetails.rate.country && ratesDetails.rate.country.name
                      ? <SpH5>{ratesDetails.rate.country.name}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Currency</span>
                  </Col>
                  <Col span={9}>
                    {ratesDetails.rate && ratesDetails.rate.currency && ratesDetails.rate.currency.name
                      ? <SpH5>{ratesDetails.rate.currency.name}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Sales Person</span>
                  </Col>
                  <Col span={9}>
                    {ratesDetails.salesPerson
                      ? <SpH5>{ratesDetails.salesPerson}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Rate</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.rateDisplay} %`}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Fee</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.prefix} ${displayAmount.feeDisplay}`}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Tax</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.taxDisplay} %`}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Maximum Processing</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.prefix} ${displayAmount.maxDisplay}`}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              {
                (ratesDetails.applyReward === true) ? (
                  <>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={4}>
                          <span>Minimum Processing</span>
                        </Col>
                        <Col span={9}>
                          <SpH5>
                            {`${displayAmount.prefix} ${displayAmount.minDisplay}`}
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={4}>
                          <span>Reward Rate</span>
                        </Col>
                        <Col span={9}>
                          <SpH5>
                            {`${displayAmount.rewardRateDisplay} %`}
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={16}>
                          <Col span={6}>
                            <span>Reward Cap</span>
                          </Col>
                          <Col span={10}>
                            <SpH5>
                              {`${displayAmount.prefix} ${displayAmount.rewardCapDisplay}`}
                            </SpH5>
                          </Col>
                        </Col>
                        <Col span={24} className="d-flex justify-content-end">
                          <SpH5 color="#7f7f7f">{ratesDetails.gid}</SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                ) : (
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={16}>
                        <Col span={6}>
                          <span>Minimum Processing</span>
                        </Col>
                        <Col span={10}>
                          <SpH5>
                            {`${displayAmount.prefix} ${displayAmount.minDisplay}`}
                          </SpH5>
                        </Col>
                      </Col>
                      <Col span={24} className="d-flex justify-content-end">
                        <SpH5 color="#7f7f7f">{ratesDetails.gid}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                )
              }

              {/* DELETE RATE MODAL */}
              {
                deleteRateModal && (
                  <DeleteSelectedRate
                    visible={deleteRateModal}
                    ratesDetails={ratesDetails}
                    submitting={submitting}
                    close={() => setDeleteRateModal(false)}
                    submit={deleteSelectedRate}
                  />
                )
              }
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  ratesDetails: state.ratesDetails,
  ratesDetailsStatus: state.ratesDetails.ratesDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  displayAmount: displayAmountSelector(state.ratesDetails),
});

const mapDispatchToProps = (dispatch) => ({
  fetchRatesDetails: gid => dispatch(ratesDetailsActions.fetchRatesDetails({
    payload: gid,
  })),
  DeleteRate: param => dispatch(ratesDetailsActions.DeleteRate({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(RatesDetails);
