import { ADMIN_CATALOG } from '../actionTypes';

export const fetchAdminCatalogs = (action) => ({
  type: ADMIN_CATALOG.FETCH,
  payload: action.payload,
});

export const addCatalog = (action) => ({
  type: ADMIN_CATALOG.ADD,
  payload: action.payload,
});

export const updateCatalog = (action) => ({
  type: ADMIN_CATALOG.UPDATE,
  payload: action.payload,
});

export const deleteCatalog = (action) => ({
  type: ADMIN_CATALOG.DELETE,
  payload: action.payload,
});
