import React, { useState, useRef, useEffect } from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  Row,
  Col,
  Button,
} from 'antd';
import {
  SpButton,
  SpDragInput,
  SpText,
  SpH5,
} from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import generateOrderSuffix from 'utils/generateOrderSuffix';
import DragIcon from 'components/NavIcons/dragIcon';

type Props = {
  submit: Function,
  paymentTypes: Array,
  paymentOrder: Array,
  openModal: Function,
};

const PaymentOrder = (props: Props) => {
  const {
    submit,
    openModal,
    paymentOrder,
    paymentTypes,
  } = props;
  const [response, setResponse] = useState(paymentTypes);
  const [, forceUpdate] = useState();
  const [disabled, setDisabled] = useState(true);
  const simpleValidator = useRef(new SimpleReactValidator());

  const updatePaymentOrder = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      response,
    });
  };

  useEffect(() => {
    if (paymentOrder) {
      const result = paymentOrder.map((res) => res.category);
      setResponse(result);
    }
  }, []);

  const reset = () => {
    if (paymentOrder) {
      const result = paymentOrder.map((res) => res.category);
      setResponse(result);
    } else {
      setResponse(paymentTypes);
    }
    setDisabled(true);
    openModal(false);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const dragdata = [...response];
      const item = dragdata.splice(fromIndex, 1)[0];
      dragdata.splice(toIndex, 0, item);
      setResponse(dragdata);
      setDisabled(false);
    },
    nodeSelector: 'div',
    handleSelector: 'p',
    lineClassName: 'global-drag-line',
  };

  return (
    <ReactDragListView {...dragProps}>
      <>
        {response.map((item, index) => (
          <div>
            <p>
              <Row>
                <Col span={1} className="mt-2">
                  <SpH5>
                    {generateOrderSuffix(index + 1)}
                  </SpH5>
                </Col>
                <Col span={7} className="mr-2">
                  <SpDragInput>
                    <DragIcon /> &nbsp;&nbsp; {item}
                  </SpDragInput>
                </Col>
              </Row>
            </p>
          </div>
        ))}
        <SpText
          color="#595959"
          fontSize="11px"
        >
          Drag one and drop over another to resequence all payment types.
        </SpText>
        <Row className="mt-4" style={{ marginLeft: '-15px' }}>
          <Col span={2}>
            <Button
              type="link"
              onClick={reset}
              disabled={disabled}
            >
              Reset
            </Button>
          </Col>
          <Col span={2}>
            <SpButton
              type="primary"
              shape="round"
              disabled={disabled}
              onClick={updatePaymentOrder}
            >
              Update
            </SpButton>
          </Col>
        </Row>
      </>
    </ReactDragListView>
  );
};

export default React.memo(PaymentOrder);
