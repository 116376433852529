// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Input,
} from 'antd';

import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError } from 'components/DesignKit';

const { TextArea } = Input;

type Props = {
  submitting: boolean,
  searchRateData: Object,
  seletedRecord: Object,
};

const Details = (props: Props, ref) => {
  const {
    submitting,
    searchRateData,
    seletedRecord,
  } = props;
  const [, forceUpdate] = useState();
  const [quantity, setQuantity] = useState(searchRateData ? searchRateData.quantity : 1);
  const [poNumber, setPoNumber] = useState(searchRateData ? searchRateData.poNumber : '');
  const [notes, setNotes] = useState(searchRateData ? searchRateData.notes : '');

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      quantityVal: {
        message: `Quantity should be in between ${seletedRecord.minimumQuantity} to ${seletedRecord.maximumQuantity}`,
        rule: (val) => (val >= seletedRecord.minimumQuantity && seletedRecord.maximumQuantity >= val),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      quantity,
      poNumber,
      notes,
    }),
  }));

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>Quantity</SpH5></label>
              <Input
                placeholder="Enter Tax value"
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('quantity', quantity, 'required|quantityVal')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>PO Number</SpH5></label>
              <Input
                placeholder="Optional PO number"
                value={poNumber}
                onChange={(e) => setPoNumber(e.currentTarget.value)}
              />
              {/* <SpError>
                {simpleValidator.current.message('PO Number', poNumber, 'required|numeric')}
              </SpError> */}
            </div>
          </div>
        </div>
        <div>
          <label htmlFor=""><SpH5>Notes</SpH5></label>
          <TextArea
            rows={5}
            placeholder="Enter comments"
            maxlength="300"
            value={notes}
            onChange={(e) => setNotes(e.currentTarget.value)}
          />
          {/* <SpError>
            {simpleValidator.current.message('notes', notes, 'required')}
          </SpError> */}
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(Details);
