import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  CUSTOM_DOCUMENT_REQUEST_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchCustomDocumentRequestDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.CUSTOM_DOCUMENT_REQUEST}/${gid}`);

const documentRequestDelete = async (gid) => axiosAuthInstance.delete(`${API_END_POINTS.ADD_CUSTOM_DOCUMENT_REQUEST}/${gid}`);

export function* getCustomDocumentRequestDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchCustomDocumentRequestDetails, action.payload);
    yield put({ type: CUSTOM_DOCUMENT_REQUEST_DETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: CUSTOM_DOCUMENT_REQUEST_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* deleteCustomDocumentRequest(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(documentRequestDelete, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOM_DOCUMENT_REQUEST.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
