import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  TRANSFERLINKS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchTransferLinks = async (payload) => {
  const searchParams = payload.payload;
  const query = generateQuery(searchParams.currentPage);
  const sorting = searchParams.sortParams ? searchParams.sortParams : '&sortBy=createdAt&direction=DESC';
  if (searchParams.filterParams && !searchParams.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.TRANSFERLINKS}?${searchParams.filterParams}`);
  }
  if (searchParams.filterParams && searchParams.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.TRANSFERLINKS}${query}${sorting}&${searchParams.filterParams}`);
  }
  if (!searchParams.filterParams && searchParams.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.TRANSFERLINKS}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.TRANSFERLINKS}${query}${sorting}`);
};

const postTransferLink = async payload => axiosAuthInstance.post(API_END_POINTS.TRANSFERLINKS, payload);

const patchTransferLink = async ({ gid, params }) => axiosAuthInstance.post(`${API_END_POINTS.TRANSFERLINKS}/${gid}/charge`, params);


export function* getTransferLinks(action) {
  try {
    const { data: { entity } } = yield call(fetchTransferLinks, action.payload);
    yield put({ type: TRANSFERLINKS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: TRANSFERLINKS.FAILED, payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* createTransferLinks(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postTransferLink, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TRANSFERLINKS.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* confirmTransferLinkOtp(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchTransferLink, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TRANSFERLINKS.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
