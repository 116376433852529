import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import { Menu } from 'antd';
import { SpTab } from 'components/DesignKit';

import SubscriptionList from './components/SubscriptionList';
import PlanList from './components/PlanList';
import SubscriptionDetails from './components/SubscriptionDetails';
import PlanDetails from './components/PlanDetails';

type Props = {
  location: {
    pathname: string
  },
};

const SubscriptionsV2 = (props: Props) => {
  const [current, setCurrent] = useState('subscriptions');
  const {
    location,
  } = props;

  const setMenu = (query) => {
    switch (query) {
      case '/subscriptions': {
        setCurrent('subscriptions');
        break;
      }
      case '/subscriptions/plans': {
        setCurrent('plans');
        break;
      }
      default:
        setCurrent('subscriptions');
    }
  };

  useEffect(() => {
    setMenu(location.pathname);
  }, [location]);

  return (
    <div>
      {
        (location.pathname === '/subscriptions' || location.pathname === '/subscriptions/plans') && (
          <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
            <Menu.Item key="subscriptions">
              <SpTab
                to="/subscriptions"
                selected={current === 'subscriptions'}
              >
                Subscriptions
              </SpTab>
            </Menu.Item>
            <Menu.Item key="plans">
              <SpTab
                to="/subscriptions/plans"
                selected={current === 'plans'}
              >
                Plans
              </SpTab>
            </Menu.Item>
          </Menu>
        )
      }
      <Switch>
        <Route exact path="/subscriptions" component={SubscriptionList} />
        <Route exact path="/subscriptions/plans" component={PlanList} />
        <Route exact path="/subscriptions/:gid" component={SubscriptionDetails} />
        <Route exact path="/subscriptions/plans/:gid" component={PlanDetails} />
      </Switch>
    </div>
  );
};

export default withRouter(SubscriptionsV2);
