import { PAYMENTSLINKDETAILS } from '../actionTypes';

export const fetchPaymentsLinkDetails = (action) => ({
  type: PAYMENTSLINKDETAILS.FETCH,
  payload: action.payload,
});

export const fetchPayments = (action) => ({
  type: PAYMENTSLINKDETAILS.PAYMENTS_FETCH,
  payload: action.payload,
});

export const updatePaymentsLink = (action) => ({
  type: PAYMENTSLINKDETAILS.UPDATE,
  payload: action.payload,
});
