// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  validators,
} from 'utils/validationMessages';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  inventoryItemDetails: object,
};

const EditOrderType = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    inventoryItemDetails,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState((inventoryItemDetails && inventoryItemDetails.label) || '');
  const [taxable, setTaxable] = useState((inventoryItemDetails && inventoryItemDetails.taxable) || '');
  const [disableButton, setDisableButton] = useState(false);
  const STRING_LENGTH = 1;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      defaultOrderType: (inventoryItemDetails && inventoryItemDetails.default),
      gid: inventoryItemDetails && inventoryItemDetails.gid,
      taxable,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Custom Order Type"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <label htmlFor=""><SpH5>Name</SpH5></label>
        <Input
          placeholder="Name"
          onChange={(e) => setName(e.currentTarget.value)}
          value={name}
        />
        <div className="pb-3 mt-3">
          <SpH5>Taxable</SpH5>
          <Switch
            className="mr-2 ml-2"
            checked={taxable}
            onChange={() => setTaxable(!taxable)}
          />
        </div>
        <SpError>
          {simpleValidator.current.message('name', name, 'required|stringLength')}
        </SpError>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditOrderType);
