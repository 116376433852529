import { CURRENCY_LOCALES } from '../appconstants';

function isNumeric(n) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export default (value, currency) => {
  if (isNumeric(value) && currency) {
    return parseFloat(value).toLocaleString(CURRENCY_LOCALES[currency], {
      maximumFractionDigits: 2,
      style: 'currency',
      currency,
    });
  }
  return value;
};
