import React, { useEffect, useRef, useState } from 'react';
import { Input, Switch } from 'antd';
import {
  SpButton,
  SpError,
  WrapperOrderSeq,
} from 'components/DesignKit';
import { sequenceGeneratorsActions } from 'store/actions';
import { connect } from 'react-redux';
import Loading from 'components/Loading';
import SimpleReactValidator from 'simple-react-validator';


type Props = {
    loading:Boolean,
    invoiceSeqNo:Array,
    fetchAllInvoiceSequence:Function,
    selectedAccount: Object,
    visibe:Function,
    addInvoiceSequence:Function,
    type:String,
    updateInvoiceSequence:Function,

};

const InvoiceSeqGenerator = (props: Props) => {
  const {
    loading,
    invoiceSeqNo,
    fetchAllInvoiceSequence,
    addInvoiceSequence,
    selectedAccount,
    visibe,
    type,
    updateInvoiceSequence,
  } = props;

  const [prefix, setPrefix] = useState('');
  const [, forceUpdate] = useState();
  const [currentSequence, setCurrentSequence] = useState(0);
  const [enableGeneration, setEnableGeneration] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      charLengthValidation: {
        message: 'The field should contain exactly 4 characters.',
        rule: (val) => val.length === 4,
      },
      orderSequenceValidation: {
        message: 'Please enter a valid number.',
        rule: (val) => val >= 0 && val <= 999999,
      },

    },
  }));

  simpleValidator.current.purgeFields();
  useEffect(() => {
    fetchAllInvoiceSequence(type);
  }, []);

  useEffect(() => {
    if (invoiceSeqNo && invoiceSeqNo.length === 0) {
      const params = {
        prefix,
        type,
        valueSeparator: '-',
      };
      addInvoiceSequence(params);
    } else if (invoiceSeqNo && invoiceSeqNo.length > 0) {
      const invoiceSeqDetails = invoiceSeqNo[0];
      setPrefix(invoiceSeqDetails?.prefix);
      // adding six zeros manually
      const formatedSeqNo = invoiceSeqDetails?.currentSequence?.toString().padStart(6, 0);
      setCurrentSequence(formatedSeqNo);
      setEnableGeneration(invoiceSeqDetails?.enableGeneration);
    }
  }, [invoiceSeqNo]);

  const updateInvoiceSeq = () => {
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    // Ensure invoiceSeqNo, type, prefix, and currentSequence are defined
    if (invoiceSeqNo && invoiceSeqNo.length > 0) {
      const invoiceTypeGid = invoiceSeqNo[0].gid;

      const params = {
        prefix,
        type,
        valueSeparator: '-',
        currentSequence,
        enableGeneration,
      };
      updateInvoiceSequence({ invoiceTypeGid, ...params });
    }
    visibe();
  };


  const handleReset = () => {
    setCurrentSequence(0);
  };


  if (loading || !selectedAccount) {
    return <Loading />;
  }
  return (
    <WrapperOrderSeq style={{
      marginLeft: type === 'INVOICE' ? '-285px' : '-255px',
    }}
    >
      <div className="d-flex mt-1">
        {/* Prefix Field */}
        <div className="col-md-4 mb-3">
          <label className="form-label" style={{ fontWeight: 600 }}>
            Prefix
          </label>
          <Input
            className="form-control"
            placeholder="A2BPL"
            value={prefix}
            onChange={e => setPrefix(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('prefix', prefix, 'required|charLengthValidation')}
          </SpError>
        </div>

        <div className="col-md-6 mb-3">
          <label className="form-label" style={{ fontWeight: 600 }}>
            Order Sequence
          </label>
          <Input
            type="number"
            className="form-control"
            placeholder="1000"
            min={0}
            max={999999}
            value={currentSequence}
            onChange={e => setCurrentSequence(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('currentSequence', currentSequence, 'required|orderSequenceValidation')}
          </SpError>
        </div>
        <div
          className="col-md-2"
          style={{ marginTop: '27px', marginLeft: '-16px' }}
        >
          <SpButton
            type="primary"
            shape="round"
            onClick={handleReset}
          >
            Reset
          </SpButton>
        </div>
      </div>
      <p className="mt-2 ml-3">e.g., {prefix}-{currentSequence}</p>
      <div className="d-flex">
        <Switch
          className="mx-3 mt-2"
          checked={enableGeneration}
          onChange={() => setEnableGeneration(!enableGeneration)}
        />
        <p>
          Enable or disable the configuration for generating invoice numbers
        </p>
      </div>

      <div className="d-flex mb-2 justify-content-end mr-3">
        <SpButton
          type="secondary"
          shape="round"
          className="mr-2"
          ghost
          onClick={() => visibe()}
        >
          Cancel
        </SpButton>
        <SpButton type="primary" shape="round" onClick={updateInvoiceSeq}>
          Update
        </SpButton>
      </div>
    </WrapperOrderSeq>
  );
};
const mapStateToProps = (state) => ({
  // invoices: state.invoices.content,
  loading: state.loading.loading,
  // totalElements: state.invoices.totalElements,
  // test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  // submitting: state.loading.submitting,
  // preserveFilters: state.filterParams.preserveFilters,
  invoiceSeqNo: state.sequenceGenerators.invoiceSequence.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllInvoiceSequence: param => dispatch(sequenceGeneratorsActions.fetchAllInvoiceSequence({
    payload: param,
  })),
  addInvoiceSequence: param => dispatch(sequenceGeneratorsActions.addInvoiceSequence({
    payload: param,
  })),
  updateInvoiceSequence: param => dispatch(sequenceGeneratorsActions.updateInvoiceSequence({
    payload: param,
  })),

});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSeqGenerator);
