import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  walletActions,
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  Icon,
} from 'antd';
import {
  SpText,
  SpButton,
} from 'components/DesignKit';
import {
  MESSAGES,
} from 'appconstants';
import Clipboard from 'components/NavIcons/copyclipboard';
import WalletAlias from './components/AliasSetup';
import ResetPin from './components/ResetPin';

const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  fetchWallet: Function,
  test: boolean,
  selectedAccount: Object,
  wallet: Object,
  addConfirmWallet: Function,
  addWallet: Function,
  submitting: boolean,
  pinSetUp: Function,
  verifyPin: Function,
  pinWallet: Object,
  updatePin: Boolean,
  walletAuth: Boolean,
  confirmWalletAlias: Boolean,
  consfirmWalletPin: Boolean,
  setNotification: Function,
};


const Setup = (props: Props) => {
  const {
    test,
    selectedAccount,
    fetchWallet,
    wallet,
    addConfirmWallet,
    addWallet,
    submitting,
    pinSetUp,
    verifyPin,
    pinWallet,
    updatePin,
    walletAuth,
    confirmWalletAlias,
    consfirmWalletPin,
    setNotification,
  } = props;

  const [walletAlias, setWalletAlias] = useState('');
  const [walletGid, setWalletGid] = useState('');
  const [disableStatus, setDisableStatus] = useState(true);
  const countryCode = selectedAccount && selectedAccount.country && selectedAccount.country.alpha2;

  const [aliasModal, setAliasModal] = useState(false);
  const [walletPinModal, setWalletPinModal] = useState(false);
  const [resetPinFlag, setResetPinFlag] = useState(true);

  useEffect(() => {
    fetchWallet();
  }, [selectedAccount, test]);

  useEffect(() => {
    if (wallet && wallet.gid) {
      setWalletAlias(wallet.aliases && wallet.aliases[0] && wallet.aliases[wallet.aliases.length - 1].alias);
      setWalletGid(wallet.aliases && wallet.aliases[0] && wallet.aliases[wallet.aliases.length - 1].gid);
      setResetPinFlag(false);
    }
  }, [wallet]);

  useEffect(() => {
    if (!submitting) {
      if (wallet && wallet.gid && walletAuth) {
        setWalletAlias(wallet.aliases && wallet.aliases[0] && wallet.aliases[wallet.aliases.length - 1].alias);
        setWalletGid(wallet.aliases && wallet.aliases[0] && wallet.aliases[wallet.aliases.length - 1].gid);
        setResetPinFlag(false);
      }
      setDisableStatus(true);
      if (!confirmWalletAlias) {
        setAliasModal(false);
      }
    }
  }, [submitting]);

  useEffect(() => {
    if (updatePin) {
      setWalletPinModal(false);
    }
  }, [updatePin]);

  const setUpWallet = (payload) => {
    let params;
    if (!confirmWalletAlias) {
      if (payload.type === 'EMAIL') {
        params = {
          alias: payload.alias,
          type: payload.type,
        };
      } else if (payload.type === 'PHONE') {
        params = {
          alias: payload.phone,
          type: payload.type,
        };
      }
      addWallet(params);
    } else if (confirmWalletAlias) {
      if (payload.type === 'EMAIL') {
        params = {
          alias: payload.alias,
          code: payload.code,
          walletGid,
        };
      } else if (payload.type === 'PHONE') {
        params = {
          alias: payload.phone,
          code: payload.code,
          walletGid,
        };
      }
      addConfirmWallet(params);
    }
  };

  const setUpWalletPin = (payload) => {
    let params;
    if (!consfirmWalletPin) {
      const encodedString = Buffer.from(payload.walletPin).toString('base64');
      params = {
        pin: encodedString,
      };
      pinSetUp(params);
    } else if (consfirmWalletPin) {
      const { gid } = pinWallet;
      params = {
        code: payload.code,
      };
      verifyPin({
        gid,
        params,
      });
    }
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WALLET.ALIAS_COPIED,
    });
  };

  return (
    <>
      {
      (countryCode && countryCode !== 'US')
        ? (
          <Card
            className="mx-auto mt-5"
            style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
            bodyStyle={{ height: '100%' }}
          >
            <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
              <Icon
                type="exclamation-circle"
                className="mb-4"
                style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
              />
              <h5>Setup is only available for the country USA.</h5>
            </div>
          </Card>
        ) : (
          <>
            <Card>
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col span={10}>
                  <SpText className="mr-4" fontSize="20px">Wallet Alias</SpText>
                  {
            wallet && wallet.gid && wallet.aliases && wallet.aliases[0] && disableStatus && (
            <StatusTag style={{ backgroundColor: '#4c51c23b' }}>
              Verified
            </StatusTag>
            )
          }
                  <div className="d-flex mt-1">
                    <Input
                      className="mr-2"
                      placeholder="Enter alias"
                      value={walletAlias}
                      onChange={(e) => setWalletAlias(e.currentTarget.value)}
                      disabled
                    />
                    <Button
                      type="primary"
                      shape="round"
                      className="align-items-center justify-content-center"
                      onClick={() => setAliasModal(true)}
                    >
                      Update Alias
                    </Button>
                    {
                      walletAlias && (
                      <SpButton
                        type="secondary"
                        className="ml-2"
                        borderRadius="50px"
                        onClick={() => { copyCode(walletAlias); }}
                      >
                        <Clipboard />
                      </SpButton>
                      )
                    }
                  </div>
                </Col>
              </Row>
              <hr />
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col span={8}>
                  <SpText className="mr-4" fontSize="20px">Wallet Pin</SpText>
                  <div className="mt-1">
                    <Button
                      type="primary"
                      shape="round"
                      className="align-items-center justify-content-center"
                      onClick={() => setWalletPinModal(true)}
                      disabled={resetPinFlag}
                    >
                      Reset Pin
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>
            {/* VALIDATE ALIAS MODAL */}
            { aliasModal && (
            <WalletAlias
              visible={aliasModal}
              close={() => { setAliasModal(false); addWallet({}); }}
              submit={setUpWallet}
              selectedAccount={selectedAccount}
              submitting={submitting}
              confirmWalletAlias={confirmWalletAlias}
            />
            )}

            {/* VALIDATE RESET PIN MODAL */}
            { walletPinModal && (
            <ResetPin
              visible={walletPinModal}
              close={() => { setWalletPinModal(false); pinSetUp({}); }}
              submit={setUpWalletPin}
              consfirmWalletPin={consfirmWalletPin}
            />
            )}
          </>
        )
    }
    </>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  loading: state.loading.loading,
  user: state.user,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
  wallet: state.wallet,
  transactions: state.wallet.content,
  submitting: state.loading.submitting,
  pinWallet: state.wallet.walletPin,
  updatePin: state.wallet.updatePin,
  walletAuth: state.wallet.walletAuth,
  confirmWalletAlias: state.wallet.confirmWalletAlias,
  consfirmWalletPin: state.wallet.consfirmWalletPin,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWallet: param => dispatch(walletActions.fetchWallet({
    payload: param,
  })),
  addWallet: param => dispatch(walletActions.addWallet({
    payload: param,
  })),
  addConfirmWallet: param => dispatch(walletActions.addConfirmWallet({
    payload: param,
  })),
  pinSetUp: param => dispatch(walletActions.pinSetUp({
    payload: param,
  })),
  verifyPin: param => dispatch(walletActions.verifyPin({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
