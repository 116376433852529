import { SHOPS } from '../actionTypes';

export const fetchInventoryShops = (action) => ({
  type: SHOPS.FETCH,
  payload: action.payload,
});

export const addInventoryShops = (action) => ({
  type: SHOPS.ADD,
  payload: action.payload,
});

export const updateInventoryShops = (action) => ({
  type: SHOPS.UPDATE,
  payload: action.payload,
});

export const deleteInventoryShops = (action) => ({
  type: SHOPS.DELETE,
  payload: action.payload,
});

export const fetchShopRates = (action) => ({
  type: SHOPS.RATES_FETCH,
  payload: action.payload,
});

export const fetchPromosList = (action) => ({
  type: SHOPS.PROMOS_FETCH,
  payload: action.payload,
});

export const addInventoryShopAssociate = (action) => ({
  type: SHOPS.ASSOCIATE_ADD,
  payload: action.payload,
});

export const updateInventoryShopAssociate = (action) => ({
  type: SHOPS.ASSOCIATE_UPDATE,
  payload: action.payload,
});

export const fetchInventoryShopAssociate = (action) => ({
  type: SHOPS.ASSOCIATE_FETCH,
  payload: action.payload,
});

export const deleteInventoryShopAssociate = (action) => ({
  type: SHOPS.ASSOCIATE_DELETE,
  payload: action.payload,
});
