// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  locationDetails: Object,
  submitting: boolean,
};

const DeleteLocation = (props: Props) => {
  const {
    visible,
    close,
    submit,
    locationDetails,
    submitting,
  } = props;

  const deleteLocation = () => {
    submit(locationDetails.gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={deleteLocation} type="danger">Delete</SpButton>,
      ]}
      title="Delete Location"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-1 mb-2">Are you sure you want to delete this location?</div>
        <div className="pb-3">
          <SpH5>{locationDetails.name}</SpH5>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteLocation;
