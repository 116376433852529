// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';
import SimpleReactValidator from 'simple-react-validator';
import {
  COUNTRY_PHONE_CODE,
} from 'appconstants';

const { Option } = Select;

type Props = {
  roles: Array<Object>,
  close: Function,
  phoneCode: string,
  submit: Function,
  visible: boolean,
  submitting: boolean,
};

const AddUser = (props: Props) => {
  const {
    roles,
    visible,
    close,
    phoneCode,
    submit,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [phoneFormate, setPhoneFormate] = useState();
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [disableButton, setDisableButton] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${phone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      firstName,
      lastName,
      email,
      phone,
      countryCode,
      role,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add User</SpButton>,
      ]}
      title="Add New Internal User"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="d-flex">
          <div className="mb-3 mr-3">
            <label htmlFor=""><SpH5>First Name</SpH5></label>
            <Input
              placeholder="First name"
              value={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />

            <SpError>
              {simpleValidator.current.message('first name', firstName, 'required')}
            </SpError>
          </div>

          <div className="mb-3">
            <label htmlFor=""><SpH5>Last Name</SpH5></label>
            <Input
              placeholder="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('last name', lastName, 'required')}
            </SpError>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Account Email</SpH5></label>
          <Input
            placeholder="User email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('email', email, 'required|email|checkLowerCase')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone Number</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '15%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                defaultValue={countryCode}
                onChange={onCountryCodeChange}
                showSearch
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '85%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormate}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setPhone(e.currentTarget.value);
                    const value = formatNumber(`${phoneCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormate(formater);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('phone number', phone, `required|phoneNumValidation:${countryCode}${phone}`)}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <label className="d-block"><SpH5>Assign Role</SpH5></label>
          <Select
            style={{ width: '50%' }}
            placeholder="Please select"
            className="text-capitalize"
            onChange={value => setRole(value)}
          >
            {
              roles.map(r => (
                <Option
                  key={1}
                  value={r.id}
                  className="text-capitalize"
                >
                  {r.name}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('role', role, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddUser);
