/* eslint-disable */
import React from 'react';

const dashboard = ({ fill = "silver" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
    <path d="M5.20746 5.11133L3.10265 3.66805C3.57706 3.02049 4.34315 2.6 5.20738 2.6C6.07169 2.6 6.83777 3.02051 7.31218 3.66809L5.20746 5.11133Z" fill={fill} />
    <path d="M4.88246 7.79474C3.59579 7.6348 2.6 6.53739 2.6 5.2074C2.6 4.86516 2.66586 4.53836 2.78575 4.23892L4.88246 5.67661V7.79474Z" fill={fill} />
    <path d="M5.53246 7.79472C6.81913 7.63472 7.81484 6.53733 7.81484 5.2074C7.81484 4.86518 7.7489 4.53834 7.62901 4.23892L5.53246 5.67661V7.79472Z" fill={fill} />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.95 0C0.873045 0 0 0.873045 0 1.95V8.45C0 9.52696 0.873044 10.4 1.95 10.4H11.05C12.127 10.4 13 9.52696 13 8.45V1.95C13 0.873045 12.127 0 11.05 0H1.95ZM11.05 1.3H1.95C1.59101 1.3 1.3 1.59101 1.3 1.95V8.45C1.3 8.80898 1.59101 9.1 1.95 9.1H11.05C11.409 9.1 11.7 8.80898 11.7 8.45V1.95C11.7 1.59101 11.409 1.3 11.05 1.3Z" fill={fill} />
    <path d="M1.95 12.35C1.95 11.991 2.24101 11.7 2.6 11.7H10.4C10.759 11.7 11.05 11.991 11.05 12.35C11.05 12.709 10.759 13 10.4 13H2.6C2.24101 13 1.95 12.709 1.95 12.35Z" fill={fill} />
  </svg>
);

export default dashboard;
