// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Row,
  Col,
  Table,
  Badge,
  Tooltip,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import formatNumber from 'utils/formatNumber';
import getFilterOperators from 'utils/getFilterOperators';

import {
  PAGES,
  FILTERS_TYPES,
  FILTER_STATUSES,
  COUNTRIES,
} from 'appconstants';

import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import { transactionsActions, filterParamsActions } from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import moment from 'moment-timezone';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const TRANSACTIONS_FILTERS_TYPES = FILTERS_TYPES.TRANSACTIONS;
const TransactionTypes = FILTER_STATUSES.TRANSACTIONS;
const placeholder = 'Select type';

type Props = {
  fetchTransactions: Function,
  transactions: Array<Object>,
  loading: boolean,
  test: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  setFilterData: Function,
  preserveFilters: Object,
  isMobileView: Boolean,
};

const Transactions = (props: Props) => {
  const {
    loading,
    fetchTransactions,
    transactions,
    totalElements,
    test,
    history,
    selectedAccount,
    setFilterData,
    preserveFilters,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(TRANSACTIONS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterTransactions, setFilterTransactions] = useState([{
    filterType: '',
    filterName: '',
    filterOperator: '',
    filterValue: '',
    filterNameDisplay: '',
    filterValueFrom: '',
    filterValueTo: '',
    checked: true,
  }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
    });

  const exportData = transactions ? transactions.map((values) => ({
    type: values.type,
    net: `${values.currency.prefix}${formatNumber((values.net / 100).toFixed(values.currency.toFixed), currencyType)}`,
    amount: `${values.currency.prefix}${formatNumber((values.amount / 100).toFixed(values.currency.toFixed), currencyType)}`,
    fee: `${values.currency.prefix}${formatNumber((values.fee / 100 + values.tax / 100).toFixed(values.currency.toFixed), currencyType)}`,
    description: values.description,
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY'),
  })) : '';

  const exportHeaders = [
    { label: 'TYPE', key: 'type' },
    { label: 'NET', key: 'net' },
    { label: 'AMOUNT', key: 'amount' },
    { label: 'FEE', key: 'fee' },
    { label: 'DESCRIPTION', key: 'description' },
    { label: 'DATE', key: 'createdAt' },
  ];

  const debouncedFetchTransactions = debounce(fetchTransactions, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchTransactions({ currentPage, sortParams, filterParams });
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    getData(location.search);
  }, [test, selectedAccount, location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [transactions]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const selectedRecord = (value) => {
    if (value.payoutGid) {
      const { payoutGid } = value;
      history.push({
        pathname: `${PAGES.PAYOUTS}/${payoutGid}`,
        state: location.search,
      });
    } else if (value.paymentSession) {
      const paymentGid = value.paymentSession.gid;
      history.push({
        pathname: `${PAGES.PAYMENTS}/${paymentGid}`,
        state: location.search,
      });
    }
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.TRANSACTIONS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.TRANSACTIONS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterTransactions];
    if (filterTransactions.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = TRANSACTIONS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTransactions(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterTransactions];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'type') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = TRANSACTIONS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTransactions(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterTransactions];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterTransactions(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterTransactions(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterTransactions];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterTransactions(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterTransactions(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterTransactions[index].filterName === 'createdAt') {
      if (filterTransactions[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).endOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterTransactions[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterTransactions[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterTransactions];
    list[index].filterValue = selectedValue;
    setFilterTransactions(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTransactions];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterTransactions[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterTransactions(list);
  };

  const handleAddClick = () => {
    const list = [...filterTransactions];
    setFilterTransactions([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterTransactions([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(TRANSACTIONS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.TRANSACTIONS);
  };

  const applyFilters = () => {
    const transactionsFilters = filterTransactions.filter((item) => item.checked);
    for (let i = 0; i < transactionsFilters.length; i += 1) {
      if (!transactionsFilters[i].filterName || !transactionsFilters[i].filterOperator || !transactionsFilters[i].filterValue) {
        return;
      }
    }
    if (transactionsFilters[0].filterValue || (transactionsFilters[0].filterValueFrom && transactionsFilters[0].filterValueTo)) {
      setCount(transactionsFilters && transactionsFilters.length);
      for (let i = 0; i < transactionsFilters.length; i += 1) {
        if (transactionsFilters[i].checked) {
          if (transactionsFilters[i].filterName === 'createdAt') {
            transactionsFilters[i] = transactionsFilters[i].filterValue;
          } else if (transactionsFilters[i].filterType === 'Number' && transactionsFilters[i].filterOperator === 'BT') {
            const name = transactionsFilters[i].filterName;
            const from = transactionsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = transactionsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            transactionsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (transactionsFilters[i].filterType === 'Number' && transactionsFilters[i].filterOperator !== 'BT') {
            const value = transactionsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            transactionsFilters[i] = `${transactionsFilters[i].filterName}.${transactionsFilters[i].filterOperator}=${value}`;
          } else {
            const name = transactionsFilters[i].filterName;
            transactionsFilters[i] = `${name}.${transactionsFilters[i].filterOperator}=${transactionsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(transactionsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
        history.push({
          pathname: PAGES.TRANSACTIONS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
        });
        history.push({
          pathname: PAGES.TRANSACTIONS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const inColumns = [
    {
      title: 'TYPE',
      dataIndex: 'type',
      width: '20%',
      align: 'left',
      render: (type) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {type || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '20%',
      align: 'left',
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        if (total < 0) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {`(${record.currency.prefix} ${formatNumber(total, currencyType).replace(/-/g, '')})`}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (description) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {!description ? <>&#8211;</> : description === ' ' ? <>&#8211;</> : description}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const columns = [
    {
      title: 'TYPE',
      dataIndex: 'type',
      width: '15%',
      align: 'left',
      render: (type) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {type || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'NET',
      dataIndex: 'net',
      width: '15%',
      align: 'left',
      render: (net, record) => {
        const total = (net / 100).toFixed(record.currency.toFixed);
        if (total < 0) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {`(${record.currency.prefix} ${formatNumber(total, currencyType).replace(/-/g, '')})`}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (description) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {!description ? <>&#8211;</> : description === ' ' ? <>&#8211;</> : description}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <span>
              {
                exportData !== null
                  ? (
                    <Tooltip placement="left" title="Click here to export Transactions">
                      <CSVLink
                        target="_blank"
                        className="mr-3"
                        filename="Transactions Report.csv"
                        data={exportData}
                        headers={exportHeaders}
                        onMouseEnter={() => setFillColor(true)}
                        onMouseLeave={() => setFillColor(false)}
                      >
                        <ExportIcon
                          fill={fillColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </CSVLink>
                    </Tooltip>
                  ) : null
              }
            </span>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Transactions">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && (
                <FilterWrapper
                  style={{ marginLeft: '-265px' }}
                >
                  <Filters
                    filtersGrid={filterTransactions}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                    handleFromValue={handleFromValue}
                    handleToValue={handleToValue}
                    stuses={TransactionTypes}
                    placeholder={placeholder}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterTransactions.length < TRANSACTIONS_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
            </span>
          </Col>
        </div>
      </Row>
      <Table
        onRow={(record) => ({
          onClick: () => {
            selectedRecord(record);
          },
        })}
        rowClassName={() => 'ant-table-clickable-row'}
        columns={isIndia ? inColumns : columns}
        rowKey={(record) => record.gid}
        dataSource={transactions}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  transactions: state.transactions.content,
  loading: state.loading.loading,
  test: state.account.test,
  totalElements: state.transactions.totalElements,
  selectedAccount: state.account.selectedAccount,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTransactions: param => dispatch(transactionsActions.fetchTransactions({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
