import { SUBSCRIPTIONV2DETAILS } from '../actionTypes';

export const fetchSubscriptionV2Details = (action) => ({
  type: SUBSCRIPTIONV2DETAILS.FETCH,
  payload: action.payload,
});

export const fetchSubscriptionV2Payments = (action) => ({
  type: SUBSCRIPTIONV2DETAILS.PAYMENTS_FETCH,
  payload: action.payload,
});
