import { SUBSCRIPTION_BUTTONDETAILS } from '../actionTypes';

export const fetchSubscriptionButtonDetails = (action) => ({
  type: SUBSCRIPTION_BUTTONDETAILS.FETCH,
  payload: action.payload,
});

export const fetchPayments = (action) => ({
  type: SUBSCRIPTION_BUTTONDETAILS.PAYMENTS_FETCH,
  payload: action.payload,
});

export const DeactiveSubscriptionButton = (action) => ({
  type: SUBSCRIPTION_BUTTONDETAILS.DELETE,
  payload: action.payload,
});

export const updateSubscriptionButton = (action) => ({
  type: SUBSCRIPTION_BUTTONDETAILS.UPDATE,
  payload: action.payload,
});
