import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  CUSTOMERS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchCustomers = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMERS}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMERS}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMERS}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMERS}${query}${sorting}`);
};

const postCustomer = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.ADD_CUSTOMERS, params);

const patchCustomer = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.UPDATE_CUSTOMERS}/${gid}`, params);

const deleteCustomer = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_CUSTOMERS}/${gid}`);

export function* getCustomers(action) {
  try {
    const { data: { entity } } = yield call(fetchCustomers, action.payload);
    yield put({ type: CUSTOMERS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: CUSTOMERS.FAILED, payload: e.response.data });
  }
}

export function* addCustomers(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postCustomer, action.payload);
    const { data: { entity } } = yield call(fetchCustomers, action.payload);
    yield put({ type: CUSTOMERS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOMERS.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateCustomer(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCustomer, action.payload);
    const { data: { entity } } = yield call(fetchCustomers, action.payload);
    yield put({ type: CUSTOMERS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOMERS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteCustomers(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteCustomer, action.payload);
    const { data: { entity } } = yield call(fetchCustomers, action.payload);
    yield put({ type: CUSTOMERS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOMERS.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
