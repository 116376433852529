// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Select,
  Modal,
  AutoComplete,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5,
  SpError,
  SpButton,
  SpText,
} from 'components/DesignKit';
import {
  invoiceDetailsAction,
} from 'store/actions';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  taxrateList: Array<Object>,
  getTaxRateList: Function,
  newTaxAdd: Function,
};

const SelectTaxrate = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    taxrateList,
    getTaxRateList,
    newTaxAdd,
  } = props;

  const [, forceUpdate] = useState();
  const [taxName, setTaxName] = useState('');
  const [taxRates, setTaxSelected] = useState({});

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    getTaxRateList();
  }, []);

  const onSearch = (searchText) => {
    getTaxRateList(searchText);
  };

  const updateTaxType = (e) => {
    const selectedTaxrate = taxrateList.filter((item) => item.gid === e);
    setTaxName(selectedTaxrate[0].displayName);
    setTaxSelected(selectedTaxrate[0]);
  };

  const addNewTax = () => {
    newTaxAdd();
  };


  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const taxRate = {
      taxRates,
    };
    submit(taxRate);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <span className="mr-5" style={{ cursor: 'pointer' }}>
          <SpText
            fontWeight="600"
            color="#279dfe"
            onClick={addNewTax}
          >
            Create new Tax
          </SpText>
        </span>,
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Add Tax</SpButton>,
      ]}
      title="Add Tax Rates"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div>
          <label htmlFor=""><SpH5>Tax</SpH5></label>
          <AutoComplete
            placeholder="Select tax name"
            showSearch
            className="w-100"
            dataSource={taxrateList.map((item) => (
              <Option key={item.gid} value={item.gid}>
                {`${item.displayName} (${item.percentage}%)`}
              </Option>
            ))}
            style={{ height: '40px' }}
            onSearch={onSearch}
            defaultValue={taxName}
            onSelect={(e) => updateTaxType(e)}
          />
          <SpError>
            {simpleValidator.current.message('Tax', taxName, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  taxrateList: state.invoiceDetails.taxrateList,
});

const mapDispatchToProps = (dispatch) => ({
  getTaxRateList: param => dispatch(invoiceDetailsAction.getTaxRateList({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(SelectTaxrate);
