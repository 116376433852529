// @flow
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  RATE_TYPES,
} from 'appconstants';
import { SpH5, SpText } from 'components/DesignKit';
import NumberFormat from 'react-number-format';
import formatNumber from 'utils/formatNumber';
import getTaxAmount from 'utils/getTaxAmount';
import getDiscountOff from 'utils/getDiscountOff';

import getFrequency from 'utils/getFrequency';

type Props = {
  planData: Object,
  linkData: Object,
  lineItemDtoList: Array,
  prefix: string,
  selectedAccount: Object,
  totalAmount: any,
  updatedCoupon: Object,
  allSelectedTaxrates: Array,
  shippingAmount: Number,
};

const ReviewSubscription = (props: Props) => {
  const {
    planData,
    linkData,
    lineItemDtoList,
    prefix,
    selectedAccount,
    totalAmount,
    updatedCoupon,
    allSelectedTaxrates,
    shippingAmount,
  } = props;
  const countryCode = linkData && linkData.countryCodes;
  const currencyObj = selectedAccount && selectedAccount.currency;
  const [phoneFormate, setPhoneFormate] = useState('');
  const getAmountWithShippingFee = (amount) => {
    if (shippingAmount) {
      const numericAmount = parseFloat(amount.replace(/,/g, '')) || 0;
      const numericShippingAmount = parseFloat(shippingAmount) || 0;
      const addedShippingAmount = numericAmount + numericShippingAmount;
      return addedShippingAmount.toFixed(2);
    }
    return amount;
  };
  const getAmountOff = (value) => {
    let amountOff;
    if (value.percentageOff) {
      const amount = (totalAmount * value.percentageOff) / 100;
      amountOff = formatNumber((amount).toFixed(2), currencyObj);
    } else if (value.amountOff) {
      amountOff = formatNumber((value.amountOff / 100).toFixed(2), currencyObj);
    }
    return amountOff;
  };

  const getFinalAmount = (amount) => {
    let finalAmount;
    const listOfTaxes = [...allSelectedTaxrates];
    const taxLists = listOfTaxes.filter((item) => item.taxRates.inclusive === false);
    if (taxLists.length > 0) {
      const percentageAmount = taxLists.reduce(
        (total, item) => total + ((parseFloat(amount.replace(/,/g, '')) * item.taxRates.percentage) / 100), 0,
      );
      finalAmount = formatNumber((parseFloat(amount.replace(/,/g, '')) + percentageAmount).toFixed(2), currencyObj);
    } else {
      finalAmount = amount;
    }
    return finalAmount;
  };

  const getTotalAmount = (value) => {
    let finalAmount;
    if (value) {
      const finalValue = parseFloat(value.replace(/,/g, ''));
      finalAmount = formatNumber((totalAmount - finalValue).toFixed(2), currencyObj);
    } else {
      finalAmount = formatNumber((totalAmount).toFixed(2), currencyObj);
    }
    return finalAmount;
  };

  useEffect(() => {
    if (countryCode === '+1') {
      setPhoneFormate('+# (###) ###-####');
    } else if (countryCode.length !== 4 && countryCode === '+91') {
      setPhoneFormate('+##-##### #####');
    } else if (countryCode.length === 3 && countryCode === '+48') {
      setPhoneFormate('+##-#########');
    } else if (countryCode.length === 2 && countryCode !== '+1') {
      setPhoneFormate('+#-##########');
    } else if (countryCode.length === 3 && countryCode !== '+91') {
      setPhoneFormate('+##-##########');
    } else if (countryCode.length === 4) {
      setPhoneFormate('+###-##########');
    }
  }, [linkData]);
  return (
    <>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Plan Selected</SpH5>
          </Col>
          <Col>
            <SpText fontSize="20px" fontWeight="600">{planData.planName || <>&#8211;</>}</SpText>
          </Col>
          <Col>
            <span>
              Customer will be charged&nbsp;
              <SpH5>
                {
                  (getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))) < 0 ? (
                    `${prefix} 0.00`
                  ) : (
                    `${prefix} ${getAmountWithShippingFee(getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon))))}`
                  )
                }
              </SpH5>
              &nbsp;every <SpH5>{`${planData.period} ${getFrequency(planData.pfrequency)}`}</SpH5>
            </span>
          </Col>
        </Row>
      </div>
      <div className="mt-2 mb-1">
        <label htmlFor=""><SpH5>Product Details</SpH5></label>
      </div>
      <div
        className="mb-4"
        style={{
          border: '1px solid #C4C4C4',
          borderRadius: '6px',
          paddingLeft: '5px',
        }}
      >
        <Row type="flex" className="my-2 mb-3 mt-2">
          <Col span={8}>
            <div className="pt-1 pb-1 pl-1"><SpText fontWeight="600">Description</SpText></div>
          </Col>
          <Col span={16}>
            <Row type="flex" justify="end">
              <Col span={5} className="pt-1 mr-3" align="left">
                <SpText fontWeight="600" fontSize="14px">Recurring?</SpText>
              </Col>
              <Col span={4} className="pt-1 mr-3" align="left">
                <SpText fontWeight="600" fontSize="14px">Rate</SpText>
              </Col>
              <Col span={4} className="pt-1 mr-3" align="center">
                <SpText fontWeight="600" fontSize="14px">Quantity</SpText>
              </Col>
              <Col span={4} className="pt-1 mr-3" align="right">
                <SpText fontWeight="600" fontSize="14px">Total</SpText>
              </Col>
              <Col span={2} className="pt-1" align="right" />
            </Row>
          </Col>
        </Row>
        <Row type="flex" className="my-2 mb-3 mt-2" style={{ padding: '0 0 0 5px' }}>
          {lineItemDtoList.map((item) => (
            <>
              <Col span={8} className="pb-1">
                <div className="w-100 pr-3">
                  <div>
                    {item.name}
                  </div>
                </div>
              </Col>
              <Col span={16}>
                <Row type="flex" justify="end">
                  <Col span={4} className="pt-1 mr-2">
                    {(item.upfront === true) ? 'No' : 'Yes'}
                  </Col>
                  <Col span={5} className="pt-1 mr-4">
                    <SpText fontWeight="600">{prefix} {item.rate || '0.00'}</SpText>
                  </Col>
                  <Col span={3} className="pt-1 mr-3">
                    <SpText fontWeight="600">{item.quantity}</SpText>
                  </Col>
                  <Col span={5} className="pt-1 mr-4" align="right">
                    <SpText fontWeight="600">{prefix} {formatNumber((item.rate * item.quantity).toFixed(2), currencyObj)}</SpText>
                  </Col>
                </Row>
              </Col>
            </>
          ))}
        </Row>
        <Row type="flex" className="my-2 mb-3 mt-2">
          <Col span={4} />
          <Col span={20}>
            <Row type="flex" justify="end">
              <Col span={14} className="pt-1 ml-2" align="center">
                <SpText fontWeight="600" fontSize="14px">Subtotal</SpText>
              </Col>
              <Col span={4} className="pt-1 pr-1" align="right">
                <SpText fontWeight="600">
                  {`${prefix} ${formatNumber((totalAmount).toFixed(2), currencyObj)}`}
                </SpText>
              </Col>
              <Col span={1} className="pt-1" align="right" />
            </Row>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="ml-2 mb-2">
          {updatedCoupon.gid ? (
            <Col span={14}>
              <Row type="flex" justify="end">
                <Col span={12} className="pt-1 ml-3" align="left">
                  <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                    <SpText>
                      {updatedCoupon.name}
                    </SpText><br />
                    <SpText fontSize="12px">
                      {getDiscountOff(updatedCoupon)}
                    </SpText>
                  </div>
                </Col>
                <Col span={6} className="pt-2 mr-3" align="right">
                  <SpText fontWeight="600">({prefix} {getAmountOff(updatedCoupon)})</SpText>
                </Col>
                <Col span={1} className="pt-1" align="right" />
              </Row>
            </Col>
          ) : null}
        </Row>
        <Row type="flex" justify="end" className="ml-2">
          {
            allSelectedTaxrates.map((item) => (
              <Col span={14}>
                <Row type="flex" justify="end">
                  <Col span={9} className="pt-1 ml-3" align="left">
                    <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                      <SpText>
                        {item.taxRates.displayName}
                      </SpText><br />
                      <SpText fontSize="12px">
                        {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                      </SpText>
                    </div>
                  </Col>
                  <Col span={3} className="pt-1" align="right">{item.taxRates.percentage} %</Col>
                  <Col span={6} className="pt-2 mr-3" align="right">
                    <SpText fontWeight="600">
                      {prefix} {getTaxAmount(item.taxRates, totalAmount, getAmountOff(updatedCoupon), selectedAccount)}
                    </SpText>
                  </Col>
                  <Col span={1} className="pt-1" align="right" />
                </Row>
              </Col>
            ))
          }
        </Row>
        <Row type="flex" justify="end" className="ml-2 mb-2">
          <Col span={14}>
            <Row type="flex" justify="end">
              {shippingAmount ? (
                <>
                  <Col span={12} className="pt-1 ml-3" align="left">
                    <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                      <SpText>
                        Shipping Fee
                      </SpText>
                    </div>
                  </Col>
                  <Col span={8} className="pt-1 mr-3" align="right">
                    <SpText fontWeight="600">
                      {prefix} {shippingAmount}
                    </SpText>
                  </Col>
                </>
              )
                : <Col span={8} className="pt-1 mr-3" align="right" />}
            </Row>
          </Col>
        </Row>
        <hr />
        <Row type="flex" justify="end" className="mr-3 mb-1">
          <Col className="mt-1 pb-1 mr-3 pr-1">
            <SpText className="mr-5" fontWeight="500" fontSize="20px">
              Total Amount
            </SpText>
            <SpText fontWeight="600" fontSize="20px">
              {
                  (getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))) < 0 ? (
                    `${prefix} 0.00`
                  ) : (
                    `${prefix} ${getAmountWithShippingFee(getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon))))}`
                  )
                }
            </SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Subscription Start Date</SpH5>
          </Col>
          <Col>
            <span>{planData.planDate || <>&#8211;</>}</span>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Number of Cycles</SpH5>
          </Col>
          <Col>
            <span>{planData.counts || <>&#8211;</>}</span>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Customer Name</SpH5>
          </Col>
          <Col>
            <span>{linkData.customerName || <>&#8211;</>}</span>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Customer Email</SpH5>
          </Col>
          <Col>
            <span>{linkData.productEmail || <>&#8211;</>}</span>
          </Col>
        </Row>
      </div>
      <div>
        <Row justify="start">
          <Col>
            <SpH5>Customer Phone</SpH5>
          </Col>
          <Col>
            <NumberFormat
              className="numFormate"
              value={`${linkData.countryCodes}${linkData.productphNumber}`}
              format={phoneFormate}
              mask="_"
              disabled
              style={{
                fontWeight: '400',
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

// $FlowFixMe
export default ReviewSubscription;
