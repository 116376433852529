import { MBLVIEW } from '../actionTypes';

const initialState = {
  isMobileView: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MBLVIEW.FETCH:
      return {
        ...state,
        isMobileView: action.payload,
      };
    default:
      return state;
  }
};
