import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Icon,
} from 'antd';
import { PAGES } from 'appconstants';
import { SpButton } from 'components/DesignKit';
import Loading from 'components/Loading';
import Catalog from './components/catalog';
import Orders from './components/orders';

type Props = {
    loading: Boolean,
    history: {
      push: Function,
    },
    test: Boolean,
    selectedAccount: Object,
};

const Products = (props: Props) => {
  const {
    loading, history, test, selectedAccount,
  } = props;
  const [current, setCurrent] = useState('catalog');
  const [disableOption, setDisableOption] = useState(true);
  const setMenu = (val) => {
    setCurrent(val);
    history.push({
      pathname: PAGES.CATALOG_PRODUCTS,
    });
  };

  useEffect(() => {
    if (selectedAccount.status !== 'TO_ACTIVATE') {
      setDisableOption(false);
    }
  }, [selectedAccount, test]);

  useEffect(() => {
    history.push({
      pathname: PAGES.CATALOG_PRODUCTS,
    });
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {
      test ? (
        <Card
          className="mx-auto mt-5"
          style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
          bodyStyle={{ height: '100%' }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
            <Icon
              type="exclamation-circle"
              className="mb-4"
              style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
            />
            <h5>Products are only available for live data</h5>
          </div>
        </Card>
      ) : (
        <Card className="mb-2">
          {
            disableOption && (
            <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100 mt-4">
              <h5 className="text-danger">Business information needs to be verified before you can place an order</h5>
            </div>
            )
          }

          <Row type="flex" justify="start" gutter={[16, 16]} className="mt-2 mb-2">
            <Col className="mr-2">
              <SpButton
                type={current === 'catalog' ? 'primary' : 'secondary'}
                shape="round"
                onClick={() => setMenu('catalog')}
              >
                Catalog
              </SpButton>
            </Col>
            <Col className="ml-2">
              <SpButton
                type={current === 'orders' ? 'primary' : 'secondary'}
                shape="round"
                onClick={() => setMenu('orders')}
              >
                My orders
              </SpButton>
            </Col>
          </Row>
          {
         current === 'catalog' ? (
           <Catalog
             history={history}
             disableOption={disableOption}
           />
         ) : (
           <Orders
             history={history}
           />
         )
      }
        </Card>
      )
    }
    </>

  );
};

const mapStateToProps = state => ({
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  null,
)(Products);
