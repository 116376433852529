import { TRANSFER_SETTINGS } from '../actionTypes';

export const fetchTransferSetting = action => ({
  type: TRANSFER_SETTINGS.FETCH,
  payload: action.payload,
});

export const addTransferSetting = action => ({
  type: TRANSFER_SETTINGS.ADD,
  payload: action.payload,
});

export const updateTransferSetting = action => ({
  type: TRANSFER_SETTINGS.UPDATE,
  payload: action.payload,
});

export const enableLiveTransfer = action => ({
  type: TRANSFER_SETTINGS.ENABLE_LIVE,
  payload: action.payload,
});
