import { CATALOG } from '../actionTypes';

export const fetchCatalogs = (action) => ({
  type: CATALOG.FETCH,
  payload: action.payload,
});

export const orderCatalog = (action) => ({
  type: CATALOG.ADD,
  payload: action.payload,
});
