// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Select, Modal, Input } from 'antd';
// import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpButton } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  prefix: Any,
};

const SelectShippingFee = (props: Props) => {
  // eslint-disable-next-line object-curly-newline
  const { visible, close, submit, submitting, prefix } = props;
  // const [, forceUpdate] = useState();
  const [shippingAmount, setShippingAmount] = useState(null);
  // const simpleValidator = useRef(new SimpleReactValidator());
  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    const regex = /^\d*\.?\d*$/;

    if (regex.test(value) || value === '') {
      setShippingAmount(value);
    } else if (!regex.test(value) && name === 'rate') {
      setShippingAmount(0);
    }
  };

  const formatInputChange = (e) => {
    const { name, value } = e.currentTarget;
    const rateValue = parseFloat(value);
    if (Number.isInteger(rateValue)) {
      setShippingAmount(parseInt(value, 10).toFixed(2));
    }
  };
  const validate = (event) => {
    // event.preventDefault();
    // const formValid = simpleValidator.current.allValid();
    // if (!formValid) {
    //   simpleValidator.current.showMessages();
    //   forceUpdate(1);
    //   return;
    // }
    // const taxRate = {
    //   taxRates,
    // };
    submit(shippingAmount);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">
          Cancel
        </SpButton>,
        <SpButton onClick={validate}>Add Fee</SpButton>,
      ]}
      title="Add Shipping Fee"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div>
          <label htmlFor="">
            <SpH5>Shipping Fee</SpH5>
          </label>
          <Input
            name="rate"
            value={shippingAmount}
            placeholder="0.00"
            prefix={prefix}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e) => formatInputChange(e)}
            onKeyPress={(e) => {
              const keyCode = e.charCode || e.which;
              if (
                (keyCode < 48 || keyCode > 57)
                && keyCode !== 46
                && keyCode !== 44
              ) {
                e.preventDefault();
              }
            }}
          />
          {/* <Input
            placeholder="Enter Shipping Fee"
            className="w-100"
            style={{ height: '40px' }}
            type="number"
            // onSearch={onSearch}
            defaultValue={shippingAmount}
            onChange={(e) => setShippingAmount(e.target.value)}
          /> */}
          {/* <SpError>
            {simpleValidator.current.message('Tax', taxName, 'required')}
          </SpError> */}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  taxrateList: state.invoiceDetails.taxrateList,
});

const mapDispatchToProps = (dispatch) => ({
//   getTaxRateList: (param) => dispatch(
//     invoiceDetailsAction.getTaxRateList({
//         payload: param,
//       }),
//     ),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(SelectShippingFee);
