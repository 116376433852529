// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Input,
} from 'antd';
import {
  notificationActions,
} from 'store/actions';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';

const { TextArea } = Input;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
};

const AddTemplate = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [query, setQuery] = useState('');
  const [param1, setParam1] = useState('');
  const [param2, setParam2] = useState('');
  const [param3, setParam3] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = async (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      query,
      param1,
      param2,
      param3,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="New Custom Report Template"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Template Name</SpH5></label>
          <Input
            placeholder="Enter a name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Query</SpH5></label>
          <TextArea
            rows={10}
            placeholder="Enter Query"
            value={query}
            onChange={(e) => setQuery(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('query', query, 'required')}
          </SpError>
        </div>
        <div className={param2 === '' ? '' : 'mb-3'}>
          <label htmlFor=""><SpH5>Custom Report Param 1</SpH5></label>
          <Input
            placeholder="Enter a input name"
            value={param1}
            onChange={(e) => {
              setParam1(e.currentTarget.value);
            }}
          />
        </div>
        {
          (param1 !== '') && (
            <div className={param3 === '' ? 'mt-3' : 'mb-3'}>
              <label htmlFor=""><SpH5>Custom Report Param 2</SpH5></label>
              <Input
                placeholder="Enter a input name"
                value={param2}
                onChange={(e) => {
                  setParam2(e.currentTarget.value);
                }}
              />
            </div>
          )
        }
        {
          (param1 !== '') && (param2 !== '') && (
            <div className="mt-3">
              <label htmlFor=""><SpH5>Custom Report Param 3</SpH5></label>
              <Input
                placeholder="Enter a input name"
                value={param3}
                onChange={(e) => setParam3(e.currentTarget.value)}
              />
            </div>
          )
        }
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddTemplate);
