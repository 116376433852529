import { PAYMENTWIDGETS } from '../actionTypes';

export const fetchPaymentWidgets = (action) => ({
  type: PAYMENTWIDGETS.FETCH,
  payload: action.payload,
});

export const addPaymentWidget = (action) => ({
  type: PAYMENTWIDGETS.ADD,
  payload: action.payload,
});

export const setCurrentWidget = (action) => ({
  type: PAYMENTWIDGETS.CURRENT_WIDGET,
  payload: action.payload,
});

export const setSampleWidget = (action) => ({
  type: PAYMENTWIDGETS.SAMPLE_WIDGET,
  payload: action.payload,
});
