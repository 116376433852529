import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  INVENTORY_ORDERTYPES,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryOrderTypes = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=label&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ORDERTYPE}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ORDERTYPE}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ORDERTYPE}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ORDERTYPE}${query}${sorting}`);
};

const postInventoryOrderTypes = async ({ params }) => axiosAuthInstance.post(`${API_END_POINTS.GET_INVENTORY_ORDERTYPE}`, params);

const patchInventoryOrderTypes = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_ORDERTYPE}/${gid}`, params);

const deleteSelectedOrderTypes = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INVENTORY_ORDERTYPE}/${gid}`);

export function* getInventoryOrderTypes(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchInventoryOrderTypes, action.payload);
    yield put({ type: INVENTORY_ORDERTYPES.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_ORDERTYPES.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addInventoryOrderTypes(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postInventoryOrderTypes, action.payload);
    const { data: { entity } } = yield call(fetchInventoryOrderTypes, action.payload);
    yield put({ type: INVENTORY_ORDERTYPES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.ADD_ORDERTYPE,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateInventoryOrderTypes(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventoryOrderTypes, action.payload);
    const { data: { entity } } = yield call(fetchInventoryOrderTypes, action.payload);
    yield put({ type: INVENTORY_ORDERTYPES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.UPDATE_ORDERTYPE,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteInventoryOrderTypes(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteSelectedOrderTypes, action.payload);
    const { data: { entity } } = yield call(fetchInventoryOrderTypes, action.payload);
    yield put({ type: INVENTORY_ORDERTYPES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.DELETE_ORDERTYPE,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
