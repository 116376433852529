import { QUICKBOOKS } from '../actionTypes';

export const fetchQuickBooks = (action) => ({
  type: QUICKBOOKS.FETCH,
  payload: action.payload,
});

export const addQuickBooks = (action) => ({
  type: QUICKBOOKS.ADD,
  payload: action.payload,
});

export const setQuickBook = action => ({
  type: QUICKBOOKS.SET_QUICKBOOK,
  payload: action.payload,
});
