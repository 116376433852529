import { PAYMENTPAGE_STATUSES } from 'appconstants';

export default value => {
  if (value === 'ACTIVE') {
    return PAYMENTPAGE_STATUSES.ACTIVE;
  } if (value === 'DEACTIVATED') {
    return PAYMENTPAGE_STATUSES.DEACTIVATED;
  } if (value === 'DELETED') {
    return PAYMENTPAGE_STATUSES.DELETED;
  } if (value === 'SOLD_OUT') {
    return PAYMENTPAGE_STATUSES.SOLDOUT;
  } return PAYMENTPAGE_STATUSES.CANCELLED;
};
