import React, { useState, useEffect } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { COUNTRY_PHONE_CODE } from '../../../appconstants';
import { inputHeight, normalTextFontSize, smallTextFontSize } from '../../../utils/reseponsive';
import {
  Input, InputWrapper, LabelText, SpanText, Text,
} from '../../../design';

type Props = {
  control: any,
  errors: any,
  label: string,
  description: string,
  countryCallingCode: String,
  name: string,
  isRequired: boolean,
  isPreview: boolean,
  isAdaptive: boolean
}

const InputPhone = (props: Props) => {
  const {
    control, errors, label, description, name, isRequired, countryCallingCode, isAdaptive, isPreview,
  } = props;
  const [contryCode, setContryCode] = useState();
  const customStyles = {
    control: base => ({
      ...base,
      height: inputHeight(42, isPreview, isAdaptive),
      minHeight: inputHeight(42, isPreview, isAdaptive),
      border: 'none',
      borderRight: '1px solid #29B1CC',
      boxShadow: 'none',
      borderColor: '#29B1CC !important',
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
    option: (styles) => ({
      ...styles,
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
    input: () => ({
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
    menuList: base => ({
      ...base,
      minHeight: 'fit-content',
      maxHeight: 200,
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
  };

  useEffect(() => {
    const codeName = `${name}#code`;
    const getValue = control.getValues();
    setContryCode(getValue[codeName].value);
  }, [control]);

  // eslint-disable-next-line consistent-return
  const isPhoneNumberValidate = (data: string) => {
    if (!isValidPhoneNumber(`${contryCode}${data}`)) {
      return 'The phone number is invalid';
    }
  };

  return (
    <>
      <div className="form-group row mb-0 no-gutters d-flex align-items-center">
        <LabelText
          fontSize={normalTextFontSize(isPreview, isAdaptive)}
          color="#0000000"
          hidden={!label}
          htmlFor="staticEmail"
          className="col-sm-5 col-form-label font-weight-bolder m-0 text-break"
        >{label}
          <SpanText
            className="pl-2"
            hidden={isRequired}
            color="rgba(0, 0, 0, 0.6)"
            fontSize={smallTextFontSize(isPreview, isAdaptive)}
          >(Optional)
          </SpanText>
        </LabelText>
        <div className="col-sm-7 order-1">
          <InputWrapper className="row" display="flex" marginTop="8px" borderRadius="4px" width="100%">
            <Controller
              defaultValue={{ value: countryCallingCode }}
              marginBottom="0px"
              control={control}
              name={`${name}#code`}
              placeholder="select"
              rules={{
                required: isRequired && 'Calling Code is required',
              }}
              as={(
                <Select
                  className="col-4 px-0 border-0"
                  theme={theme => ({
                    ...theme,
                    borderRadius: '4px 0 0 4px',
                    boxShadow: 0,
                  })}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  getOptionLabel={(option) => option.value}
                  styles={customStyles}
                  options={COUNTRY_PHONE_CODE.map(value => ({ value }))}
                />
)}
            />
            <Controller
              width="100%"
              className="col bg-white"
              defaultValue=""
              border="0px !important"
              marginBottom="0px"
              marginTop="0px"
              marginLeft="0px"
              paddingRight="0"
              control={control}
              name={name}
              rules={{
                required: isRequired && 'Phone number is required',
                validate: isPhoneNumberValidate,
              }}
              as={<NumberFormat type="tel" height={inputHeight(42, isPreview, isAdaptive)} allowNegative={false} decimalScale={0} />}
              customInput={Input}
            />
          </InputWrapper>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text fontSize={smallTextFontSize(isPreview, isAdaptive)} marginTop="4px" marginBottom="0px" color="#ff1e1e">{message}</Text>
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${name}#code`}
            render={({ message }) => (
              <Text fontSize={smallTextFontSize(isPreview, isAdaptive)} marginTop="4px" marginBottom="0px" color="#ff1e1e">{message}</Text>
            )}
          />
        </div>
      </div>
      <div hidden={!description} className="col-sm-10 px-0 order-1">
        <LabelText fontSize={smallTextFontSize(isPreview, isAdaptive)} className="text-mute mt-1">{description}</LabelText>
      </div>
    </>
  );
};

export default InputPhone;
