import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  GETSHOPDETAILS,
  NOTIFICATION,
  LOADING,
} from '../actionTypes';

const fetchShopDetails = async (payload) => axiosAuthInstance.get(`shop-associate?email.EQ=${payload}`);
// eslint-disable-next-line
const fetchShopOrderDetails = async (payload) => {
  let pageNum = 1;
  if (payload.currentPage) {
    pageNum = payload.currentPage;
  }
  if (payload.selectedTab === 'Completed') {
    if (payload.params) {
      if (payload.params && payload.params.gid) {
        if (payload.params.newTime) {
          // eslint-disable-next-line max-len
          return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.params.gid}&status.IN=CLOSED&createdAt.GT=${payload.params.newTime}&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
        }
        // eslint-disable-next-line max-len
        return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.params.gid}&status.IN=CLOSED&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
      }
      // eslint-disable-next-line max-len
      return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&status.IN=CLOSED&createdAt.GT=${payload.params.newTime}&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
    } if (payload.shopGid) {
      if (payload.newTime) {
        // eslint-disable-next-line max-len
        return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.shopGid}&status.IN=CLOSED&createdAt.GT=${payload.newTime}&sortBy=updatedAt`);
      }
      // eslint-disable-next-line max-len
      return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.shopGid}&status.IN=CLOSED&sortBy=updatedAt`);
    }
    if (payload.newTime) {
      // eslint-disable-next-line max-len
      return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&status.IN=CLOSED&createdAt.GT=${payload.newTime}&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
    }
    // eslint-disable-next-line max-len
    return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&status.IN=CLOSED&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
  }
  if (payload.selectedTab === 'OutforDelivery') {
    if (payload.params) {
      if (payload.params && payload.params.gid) {
        // eslint-disable-next-line max-len
        return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.params.gid}&fulfillmentStatus.EQ=OUT_FOR_DELIVERY&sortBy=updatedAt`);
      }
      // eslint-disable-next-line max-len
      return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&fulfillmentStatus.EQ=OUT_FOR_DELIVERY&sortBy=updatedAt`);
    } if (payload.shopGid) {
      // eslint-disable-next-line max-len
      return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.shopGid}&fulfillmentStatus.EQ=OUT_FOR_DELIVERY&sortBy=updatedAt`);
    }
    // eslint-disable-next-line max-len
    return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&fulfillmentStatus.EQ=OUT_FOR_DELIVERY&sortBy=updatedAt`);
  }
  if (payload.selectedTab === 'AcceptedAffiliate') {
    if (payload.params) {
      if (payload.params && payload.params.gid) {
        // eslint-disable-next-line max-len
        return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.params.gid}&fulfillmentStatus.EQ=ACCEPTED_BY_AFFILIATE&sortBy=updatedAt`);
      }
      // eslint-disable-next-line max-len
      return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&fulfillmentStatus.EQ=ACCEPTED_BY_AFFILIATE&sortBy=updatedAt`);
    } if (payload.shopGid) {
      // eslint-disable-next-line max-len
      return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.shopGid}&fulfillmentStatus.EQ=ACCEPTED_BY_AFFILIATE&sortBy=updatedAt`);
    }
    // eslint-disable-next-line max-len
    return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&fulfillmentStatus.EQ=ACCEPTED_BY_AFFILIATE&sortBy=updatedAt`);
  }
  if (payload.selectedTab === 'Delivered') {
    if (payload.params) {
      if (payload.params && payload.params.gid) {
        // eslint-disable-next-line max-len
        return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.params.gid}&fulfillmentStatus.EQ=DELIVERED&sortBy=updatedAt`);
      }
      // eslint-disable-next-line max-len
      return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&fulfillmentStatus.EQ=DELIVERED&sortBy=updatedAt`);
    } if (payload.shopGid) {
      // eslint-disable-next-line max-len
      return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.shopGid}&fulfillmentStatus.EQ=DELIVERED&sortBy=updatedAt`);
    }
    // eslint-disable-next-line max-len
    return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&fulfillmentStatus.EQ=DELIVERED&sortBy=updatedAt`);
  }
  if (payload.params) {
    if (payload.params && payload.params.gid) {
      if (payload.params.newTime) {
        // eslint-disable-next-line max-len
        return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.params.gid}&status.IN=UPDATED&status.IN=IN_PROGRESS&status.IN=OPEN&createdAt.GT=${payload.params.newTime}&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
      }
      if (payload.params.newTime) {
        // eslint-disable-next-line max-len
        return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.params.gid}&status.IN=UPDATED&status.IN=IN_PROGRESS&status.IN=OPEN&createdAt.GT=${payload.params.newTime}&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
      }
      // eslint-disable-next-line max-len
      return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.params.gid}&status.IN=UPDATED&status.IN=IN_PROGRESS&status.IN=OPEN&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
    }
    // eslint-disable-next-line max-len
    return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&status.IN=UPDATED&status.IN=IN_PROGRESS&status.IN=OPEN&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
  } if (payload.shopGid) {
    if (payload.newTime) {
      // eslint-disable-next-line max-len
      return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.shopGid}&status.IN=UPDATED&status.IN=IN_PROGRESS&status.IN=OPEN&createdAt.GT=${payload.newTime}&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
    }
    // eslint-disable-next-line max-len
    return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&shopGid.IN=${payload.shopGid}&status.IN=UPDATED&status.IN=IN_PROGRESS&status.IN=OPEN&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
  }
  if (payload.newTime) {
    // eslint-disable-next-line max-len
    return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&status.IN=UPDATED&status.IN=IN_PROGRESS&status.IN=OPEN&createdAt.GT=${payload.newTime}&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
  }
  // eslint-disable-next-line max-len
  return axiosAuthInstance.get(`${API_END_POINTS.GET_KDS_ORDERS}?page=${pageNum}&size=10&direction=DESC&status.IN=UPDATED&status.IN=IN_PROGRESS&status.IN=OPEN&fulfillmentStatus.NULL=true&sortBy=updatedAt`);
};


const fetchAllShopDetails = async (payload) => {
  const queryParam = '?page=1&size=100';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_SHOPS}${queryParam}${sorting}`);
};

const fetchFullfillDetails = async () => axiosAuthInstance.get('inventory/order-fulfill');

const patchLineItems = async (params) => axiosAuthInstance.patch('inventory/order-line-item/bulk-patch', params);

const patchFullFill = async ({ infoID, updateItems }) => axiosAuthInstance.patch(`inventory/order/${infoID}/update-fulfilment`, updateItems);

const postFcmToken = async (payload) => axiosAuthInstance.post('fcmdetails', payload);

export function* getOrderShopDetails(action) {
  try {
    const { data: { entity } } = yield call(fetchShopDetails, action.payload);
    yield put({ type: GETSHOPDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: GETSHOPDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getShopOrdersDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchShopOrderDetails, action.payload);
    yield put({ type: GETSHOPDETAILS.ORDER_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: GETSHOPDETAILS.ORDER_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateLineItems(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchLineItems, action.payload && action.payload.params && action.payload.params.updateItems);
    const { data: { entity } } = yield call(fetchShopOrderDetails, action.payload && action.payload);
    yield put({ type: GETSHOPDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Order updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* sendFcmToken(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postFcmToken, action.payload);
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    console.log(e, 'e');
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getAllShopDetails(action) {
  try {
    const { data: { entity } } = yield call(fetchAllShopDetails, action.payload);
    yield put({ type: GETSHOPDETAILS.SHOP_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: GETSHOPDETAILS.SHOP_FAILED, payload: e.response.data });
  }
}

export function* getFullfillDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchFullfillDetails, action.payload);
    yield put({ type: GETSHOPDETAILS.FULLFILL_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: GETSHOPDETAILS.FULLFILL_SUCCESS, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateFullFill(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchFullFill, action.payload && action.payload.params);
    const { data: { entity } } = yield call(fetchShopOrderDetails, action.payload && action.payload && action.payload.params);
    yield put({ type: GETSHOPDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Order out for delivery',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
