// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Input,
  Upload,
  Icon,
  Switch,
} from 'antd';
import {
  axiosAuthInstance,
  API_END_POINTS,
  axiosEmptyInstance,
} from 'api';
import {
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
} from 'appconstants';

import {
  SpText,
  SpForm,
  SpTextMute,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';

type Props = {
  ownersData: Object,
  setNotification: Function,
  accountGid: String,
  getUpdatedData: Function,
  isSuperUser: boolean,
  benficiery: Object,
  updateStatus: Function,
}

const OwnersList = (props: Props) => {
  const {
    ownersData,
    setNotification,
    accountGid,
    getUpdatedData,
    isSuperUser,
    benficiery,
    updateStatus,
  } = props;
  const [fileName, setFileName] = useState('');
  const [loader, setLoader] = useState(false);
  const { gid } = ownersData;
  const { verified } = benficiery;
  const dwollaGid = ownersData.dwollaBeneficialOwnerDocument ? ownersData.dwollaBeneficialOwnerDocument.gid : '';

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const handleChange = async info => {
    if (info.file.status === 'uploading') {
      setLoader(true);
      return;
    }
    try {
      setLoader(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPLOAD_DWOLLA_DOCUMENT}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const documentDetails = {
          fileUrl: entity,
          keyType: 'LICENSE',
        };
        const { data } = await axiosAuthInstance
          .post(`${API_END_POINTS.UPLOAD_DWOLLA_DOCUMENT}`, documentDetails);
        const fileDetails = {
          kycProof: data.entity.fileUrl,
          type: data.entity.keyType,
        };
        await axiosAuthInstance
          .post(`${API_END_POINTS.USER_ACCOUNT}/${accountGid}/dwolla/beneficial-owner/${gid}/document`, fileDetails);
        setFileName(info.file.name);
        getUpdatedData();
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: MESSAGES.CUSTOM_DOCUMENT.UPDATE_SUCCESS,
        });
      }
    } catch (error) {
      setFileName('');
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.CUSTOM_DOCUMENT.FAILED,
      });
    } finally {
      setLoader(false);
    }
  };

  const downloadDocument = async () => {
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.USER_ACCOUNT}/${accountGid}/dwolla/document/${dwollaGid}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.CUSTOM_DOCUMENT.DOWNLOAD_ERROR,
      });
    }
  };

  const statusUpdate = (value) => {
    const benficieryOwner = benficiery.gid;
    updateStatus({
      benficieryOwner,
      value,
    });
  };

  return (
    <>
      <SpForm>
        <Row className="mr-4 mt-4">
          <Col>Beneficial Owner Given Name</Col>
          <Col className="mt-2" span={6}>
            <Input
              value={ownersData.firstName}
              disabled
            />
          </Col>
          <Col className="mt-2 ml-4" span={14}>
            <SpTextMute>
              These details were submitted as part of the Business verification under
              Business tab. Please raise a support ticket if anything is amiss.
            </SpTextMute>
          </Col>
        </Row>
        <Row className="mr-4 mt-4">
          <Col>Beneficial Owner Last Name</Col>
          <Col className="mt-2" span={6}>
            <Input
              value={ownersData.lastName}
              disabled
            />
          </Col>
        </Row>
        <Row className="mr-4 mt-4">
          <Col>Business Owner Verification Document</Col>
          {
              ownersData.dwollaBeneficialOwnerDocument && ownersData.dwollaBeneficialOwnerDocument.kycProof ? (
                <Col className="mt-2" span={24}>
                  {
                    !isSuperUser ? (
                      <SpButton
                        type="primary"
                        shape="round"
                        className="mr-3"
                        onClick={downloadDocument}
                      >
                        Download
                      </SpButton>
                    ) : (
                      <SpStatusTag style={{ backgroundColor: '#ececff', padding: '5px 12px' }}>
                        KYC Document Uploaded
                      </SpStatusTag>
                    )
                  }
                </Col>
              ) : (
                <>
                  <Col className="mt-2" span={24}>
                    <Upload
                      name="avatar"
                      className="avatar-uploader"
                      onChange={handleChange}
                      showUploadList={false}
                    >
                      <SpButton
                        type="secondary"
                        className="align-items-center"
                      >
                        <Icon type={loader ? 'loading' : 'plus'} />
                        <span>Upload</span>
                      </SpButton>
                      {
                      fileName && (
                        <span>
                          <br />{fileName}
                        </span>
                      )
                    }
                    </Upload>
                  </Col>
                  <Col className="mt-2" span={24}>
                    <SpTextMute>
                      Acceptable documents passport, license or valid state issued id card.
                    </SpTextMute>
                  </Col>
                </>
              )
            }
        </Row>
        {
          !isSuperUser && (
            <Row className="mr-4">
              <Col span={6}>
                <SpText className="mr-4" fontSize="14px">Verified</SpText>
                <Switch
                  checked={verified}
                  onChange={(value) => statusUpdate(value)}
                />
              </Col>
            </Row>
          )
        }
      </SpForm>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(
  null,
  mapDispatchToProps,
)(OwnersList);
