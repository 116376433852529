import {
  all,
  call,
  put,
  select,
} from 'redux-saga/effects';

import {
  API_END_POINTS,
  axiosAuthInstance,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  BUSINESS,
  LOADING,
  NOTIFICATION,
  ACCOUNT,
} from '../actionTypes';

let API_END_POINT = API_END_POINTS.USER_ACCOUNT;

const fetchBusinessInfo = async gid => axiosAuthInstance.get(`${API_END_POINT}/${gid}/business`);
const fetchBusinessDraftByAccountId = async gid => axiosAuthInstance.get(`${API_END_POINTS.DOCUSIGN}/${gid}/business/draft`);
const fetchBusinessCategories = async () => axiosAuthInstance.get(API_END_POINTS.GET_BUSINESS_CATEGORIES);
const fetchBusinessRepresentatives = async gid => axiosAuthInstance.get(`${API_END_POINT}/${gid}/business-representative`);
const postBusiness = async (gid, payload) => axiosAuthInstance.post(`${API_END_POINT}/${gid}/business`, payload);
const postBusinessDraft = async (gid, payload) => axiosAuthInstance.post(`${API_END_POINTS.DOCUSIGN}/${gid}/business/draft`, payload);
const fetchAccountInfo = async gid => axiosAuthInstance.get(`${API_END_POINTS.USER_ACCOUNT}/${gid}`);

const fetchDocusignStatus = async gid => axiosAuthInstance.get(`${API_END_POINTS.DOCUSIGN}/${gid}/docusign`);
// eslint-disable-next-line arrow-body-style
const patchBusiness = async (accountGid, businessGid, payload) => {
  return axiosAuthInstance.patch(`${API_END_POINT}/${accountGid}/business/${businessGid}`, payload);
};

export function* getDocusignStatus(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchDocusignStatus, action.payload);
    yield put({ type: BUSINESS.FETCH_DOCUSIGN_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: LOADING.ERROR, payload: e.response });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

// eslint-disable-next-line arrow-body-style
const patchBusinessRepresentatives = async (accountGid, businessReps) => {
  const repKey = Object.keys(businessReps)[0];
  return Promise.all(
    // eslint-disable-next-line max-len
    businessReps[repKey].map(businessRep => axiosAuthInstance.patch(`${API_END_POINT}/${accountGid}/business-representative/${businessRep.gid}`, {
      [repKey]: {
        ...businessRep,
      },
    })),
  );
};

export function* getBusinessDraftByAccountId(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchBusinessDraftByAccountId, action.payload);
    yield put({ type: BUSINESS.FETCH_BUSINESS_DRAFT_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: LOADING.ERROR, payload: e.response });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

const getInternalAccount = state => state.user && state.user.internalRole;

export function* getBusinessByAccountId(action) {
  try {
    yield put({ type: LOADING.SET });
    const internalAccount = yield select(getInternalAccount);
    if (internalAccount) {
      API_END_POINT = API_END_POINTS.INTERNAL_USER_ACCOUNT;
    }
    const { data: { entity } } = yield call(fetchBusinessInfo, action.payload);
    yield put({ type: BUSINESS.FETCH_BUSINESS_SUCCESS, payload: entity });
    const { data: { entity: representatives } } = yield call(fetchBusinessRepresentatives, action.payload);
    yield put({ type: BUSINESS.BUSINESS_REPRESENTATIVE_SUCCESS, payload: representatives });
    const { data: { entity: categories } } = yield call(fetchBusinessCategories);
    yield put({ type: BUSINESS.BUSINESS_CATEGORIES_SUCCESS, payload: categories });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: LOADING.ERROR, payload: e.response });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getBusinessCategory() {
  try {
    const { data: { entity: categories } } = yield call(fetchBusinessCategories);
    yield put({ type: BUSINESS.BUSINESS_CATEGORIES_SUCCESS, payload: categories });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addBusinessSignUp(action) {
  const { gid, param } = action.payload;
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(postBusiness, gid, param);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.BUSINESS.ADD_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addBusiness(action) {
  const { gid, param } = action.payload;
  try {
    yield put({ type: LOADING.SUBMITTING });
    const internalAccount = yield select(getInternalAccount);
    if (internalAccount) {
      API_END_POINT = API_END_POINTS.INTERNAL_USER_ACCOUNT;
    }
    yield call(postBusiness, gid, param);
    yield put({ type: BUSINESS.FETCH_BUSINESS_BY_ID, payload: gid });
    yield put({ type: ACCOUNT.FETCH_BY_ID, payload: gid });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.BUSINESS.ADD_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addDraftBusiness(action) {
  const { gid, param } = action.payload;
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(postBusinessDraft, gid, param);
    // const { data: { entity } } = yield call(fetchBusinessDraftByAccountId, gid);
    // yield put({ type: BUSINESS.FETCH_BUSINESS_DRAFT_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.BUSINESS.ADD_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* editBusiness(action) {
  const {
    accountGid,
    businessGid,
    payload,
  } = action.payload;

  try {
    const internalAccount = yield select(getInternalAccount);
    if (internalAccount) {
      API_END_POINT = API_END_POINTS.INTERNAL_USER_ACCOUNT;
    }
    const businessParam = payload.inBusiness ? {
      inBusiness: { ...payload.inBusiness },
    } : {
      usBusiness: { ...payload.usBusiness },
    };

    const businessRepresentativesParam = payload.inBusiness ? {
      inBusinessRep: payload.inBusinessReps,
    } : {
      usBusinessRep: payload.usBusinessReps,
    };

    yield all({
      business: call(patchBusiness, accountGid, businessGid, businessParam),
      businessReps: call(patchBusinessRepresentatives, accountGid, businessRepresentativesParam),
    });

    yield put({ type: BUSINESS.FETCH_BUSINESS_BY_ID, payload: accountGid });
    const { data: { entity } } = yield call(fetchAccountInfo, accountGid);
    yield put({
      type: ACCOUNT.SET_SELECTED_ACCOUNT,
      payload: entity,
    });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.BUSINESS.EDIT_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
