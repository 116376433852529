import { EVENTSDETAILS } from '../actionTypes';

const initialState = {
  cardDetails: [],
  offlineEventPayments: [],
  eventDetailsStatus: undefined,
  eventDetailsError: undefined,
  paymentSlug: [],
  addSeatedEvent: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EVENTSDETAILS.FETCH:
      return {
        ...state,
        eventDetailsStatus: 'STARTED',
      };
    case EVENTSDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        eventDetailsStatus: 'SUCCESS',
      };
    case EVENTSDETAILS.FAILED:
      return {
        ...state,
        eventDetailsError: action.payload,
        eventDetailsStatus: 'FAILED',
      };
    case EVENTSDETAILS.CARDS_SUCCESS:
      return {
        ...state,
        cardDetails: action.payload,
      };
    case EVENTSDETAILS.EVENTS_SLUG_SUCCESS:
      return {
        ...state,
        paymentSlug: action.payload,
      };
    case EVENTSDETAILS.SUCCESS_OFFLINE_PAYMENTS:
      return {
        ...state,
        offlineEventPayments: action.payload,
      };
    case EVENTSDETAILS.CLEAR_EVENT_DETAILS:
      return {
        ...state,
        addSeatedEvent: action.payload,
      };
    default:
      return state;
  }
};
