import { TRANSFERLINKS } from '../actionTypes';

export const fetchTransferLinks = (payload) => ({
  type: TRANSFERLINKS.FETCH,
  payload,
});

export const createTransferLinks = (action) => ({
  type: TRANSFERLINKS.CREATETRANSFERLINK,
  payload: action.payload,
});

export const confirmTransferLinkOtp = (action) => ({
  type: TRANSFERLINKS.CONFIRMTRANSFERLINKOTP,
  payload: action.payload,
});
