// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Input,
  AutoComplete,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import getSortedList from 'utils/getSortedList';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import getVendorType from 'utils/getVendorType';
import { SpH5, SpError } from 'components/DesignKit';
import {
  COUNTRY_PHONE_CODE,
} from 'appconstants';

const { Option } = Select;

type Props = {
  submitting: boolean,
  selectedAccount: Array<Object>,
  contactDetailsData: Object,
  fetchContactDetails: Function,
  cntryId: Number,
};

const ContactDetails = (props: Props, ref) => {
  const {
    submitting,
    contactDetailsData,
    selectedAccount,
    fetchContactDetails,
    cntryId,
  } = props;
  const [, forceUpdate] = useState();
  const [contactName, setContactName] = useState(contactDetailsData.contactName);
  const [email, setEmail] = useState(contactDetailsData.email);
  const [contactGid, setContactGid] = useState(contactDetailsData.contactGid);
  const [contactPhone, setContactPhone] = useState(contactDetailsData.contactPhone);
  // eslint-disable-next-line max-len
  const [phoneNumberCode, setPhoneCode] = useState(contactDetailsData.phoneNumberCode ? contactDetailsData.phoneNumberCode : getCountryPhoneCode(selectedAccount && selectedAccount.country && selectedAccount.country.id));
  const [phoneFormate, setPhoneFormate] = useState(contactDetailsData.phoneFormate);
  const [contactType, setContactType] = useState(contactDetailsData.contactType || 'CUSTOMER');
  const [contactTypeName, setContactTypeName] = useState(contactDetailsData.contactTypeName || 'Customer');
  const [customType, setCustomType] = useState(contactDetailsData.customType || '');
  const [customSuggestions, setCustomSuggestions] = useState(contactDetailsData.customSuggestions || []);
  const [notifyType, setNotifyType] = useState(contactDetailsData.notifyType || 'EMAIL');
  const [notifyTypeName, setNotifyTypeName] = useState(contactDetailsData.notifyTypeName || 'Email');
  const contacts = contactDetailsData.contactResult;
  const [disableContact, setDisableContact] = useState(contactDetailsData.disableContact);
  const [countryCode, setCountryCode] = useState(getCountryAlpha2(cntryId));
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      contactName,
      contactGid,
      email,
      phoneNumberCode,
      contactPhone,
      phoneFormate,
      contactType,
      customType,
      contactTypeName,
      disableContact,
      countryCode,
      notifyType,
      notifyTypeName,
    }),
  }));

  simpleValidator.current.purgeFields();

  const searchContact = (value) => {
    fetchContactDetails(value);
    setContactName(value);
    const results = contacts.filter((item) => (item.name === value));
    if (results.length === 0) {
      setDisableContact(false);
      setContactGid('');
    }
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setContactPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    setPhoneCode(value);
    const data = formatNumber(`${value}${contactPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const updateContact = (value) => {
    const results = contacts.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setEmail(results[0].email);
      setContactName(results[0].name);
      setContactGid(results[0].gid);
      setContactType(results[0].contactType);
      setCustomType(results[0].customType);
      setContactTypeName(getVendorType(results[0].contactType));
      setDisableContact(true);
      setCountryCode(getCountryAlpha2(results[0].address && results[0].address.country.id));
      const phoneResult = results[0].phoneNumber ? findPhoneNumbersInText(results[0].phoneNumber) : null;
      if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
        setPhoneCode(`+${phoneResult[0].number.countryCallingCode}`);
        setContactPhone(phoneResult[0].number.nationalNumber);
        const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
        const formater = data.substr(data.indexOf(' ') + 1);
        setPhoneFormate(formater);
      }
    }
  };

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2 mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <AutoComplete
            placeholder="Select Contact Name"
            showSearch
            className="w-100"
            dataSource={getSortedList(contacts).map((item, id) => (
              <Option key={id} value={item.gid}>
                {item.name}
              </Option>
            ))}
            style={{ height: '40px' }}
            onSearch={searchContact}
            defaultValue={contactName}
            onSelect={e => updateContact(e)}
          />
          <SpError>
            {simpleValidator.current.message('name', contactName, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            placeholder="Contact Email"
            value={email}
            disabled={disableContact}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('email', email, 'required|email|checkLowerCase')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                value={phoneNumberCode}
                onChange={onCountryCodeChange}
                disabled={disableContact}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormate}
                disabled={disableContact}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setContactPhone(e.currentTarget.value);
                    const value = formatNumber(`${phoneNumberCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormate(formater);
                  }
                }}
              />
              {
                !disableContact && (
                  <SpError>
                    {simpleValidator.current.message('phone number', contactPhone, `required|phoneNumValidation:${phoneNumberCode}${contactPhone}`)}
                  </SpError>
                )
              }
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Contact Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            value={contactTypeName}
            disabled={disableContact}
            onChange={(value) => {
              setContactType(value[0]);
              setContactTypeName(value[1]);
            }}
          >
            <Option key={1} value={['CUSTOMER', 'Customer']}>Customer</Option>
            <Option key={2} value={['EMPLOYEE', 'Employee']}>Employee</Option>
            <Option key={3} value={['VENDOR', 'Vendor']}>Vendor</Option>
            <Option key={4} value={['SELF', 'Self']}>Self</Option>
            <Option key={5} value={['OTHER', 'Other']}>Other</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('contact Type', contactType, 'required')}
          </SpError>
        </div>
        {contactType && contactType === 'OTHER' && (
          <div className="mb-3">
            <label htmlFor="">
              <SpH5>Custom Type</SpH5>
            </label>
            {customSuggestions.length > 0 ? (
              <>
                <Select
                  showSearch
                  placeholder="(Optional)"
                  className="w-100 mb-2"
                  value={customType}
                  onChange={(value) => setCustomType(value)}
                  onSearch={(value) => setCustomType(value)} // Handle typing a custom type
                  mode="combobox" // Allows users to input a value not in the list
                >
                  {customSuggestions.map((word, index) => (
                    <Select.Option key={index} value={word}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{word}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </>
            ) : (
              <Input
                placeholder="(Optional)"
                value={customType || ''}
                onChange={(e) => setCustomType(e.target.value)}
              />
            )}
          </div>
        )}
        <div>
          <label htmlFor=""><SpH5>Notification Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            value={notifyTypeName}
            onChange={(value) => {
              setNotifyType(value[0]);
              setNotifyTypeName(value[1]);
            }}
          >
            <Option key={1} value={['EMAIL', 'Email']}>Email</Option>
            <Option key={2} value={['SMS', 'SMS']}>SMS</Option>
            <Option key={3} value={['ALL', 'All']}>All</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('notify Type', notifyType, 'required')}
          </SpError>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(ContactDetails);
