/* eslint-disable */
import React from 'react';

const ClockIcon = () => (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 6.5C7.5 6.77614 7.27614 7 7 7H5C4.72386 7 4.5 6.77614 4.5 6.5C4.5 6.22386 4.72386 6 5 6H6.5V3C6.5 2.72386 6.72386 2.5 7 2.5C7.27614 2.5 7.5 2.72386 7.5 3V6.5Z" fill="#8C8C8C" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 12C9.81371 12 12.5 9.31371 12.5 6C12.5 2.68629 9.81371 0 6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 9.31371 3.18629 12 6.5 12ZM6.5 11C9.26142 11 11.5 8.76142 11.5 6C11.5 3.23858 9.26142 1 6.5 1C3.73858 1 1.5 3.23858 1.5 6C1.5 8.76142 3.73858 11 6.5 11Z" fill="#8C8C8C" />
    </svg>
);

export default ClockIcon;