import { REPORT_TEMPLATE_DETAILS } from '../actionTypes';

export const fetchReportTemplateDetails = (action) => ({
  type: REPORT_TEMPLATE_DETAILS.FETCH,
  payload: action.payload,
});

export const deleteReportTemplate = (action) => ({
  type: REPORT_TEMPLATE_DETAILS.DELETE,
  payload: action.payload,
});

export const updateReportTemplate = (action) => ({
  type: REPORT_TEMPLATE_DETAILS.UPDATE,
  payload: action.payload,
});
