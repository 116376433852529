export default (value) => {
  let offerTerm;
  if (value.valueQuantity) {
    if (value.claimType === 'QUANTITY' && value.claimQuantity) {
      offerTerm = `Buy ${value.valueQuantity} and Get ${value.claimQuantity} Off`;
    } else if (value.claimType === 'PERCENTAGE_OFF' && value.claimPercentageOff) {
      offerTerm = `Buy ${value.valueQuantity} and Get ${value.claimPercentageOff}% Off`;
    }
  }
  return offerTerm;
};
