import { WALLET } from '../actionTypes';

const initialState = {
  content: [],
  walletPin: {},
  walletAuth: false,
  walletSetup: false,
  updatePin: false,
  resetWalletPin: false,
  confirmWalletAlias: false,
  consfirmWalletPin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WALLET.SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case WALLET.FAILED: {
      return {
        state: {
          content: [],
          walletPin: {},
          walletAuth: false,
          walletSetup: false,
          updatePin: false,
          resetWalletPin: false,
          confirmWalletAlias: false,
          consfirmWalletPin: false,
        },
      };
    }
    case WALLET.FETCH_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        content: action.payload,
      };
    }
    case WALLET.REST_PIN_SUCCESS: {
      return {
        ...state,
        walletPin: action.payload,
      };
    }
    case WALLET.WALLET_AUTH: {
      return {
        ...state,
        walletAuth: action.payload,
      };
    }
    case WALLET.WALLET_SETUP: {
      return {
        ...state,
        walletAuth: action.payload,
      };
    }
    case WALLET.WALLET_PIN: {
      return {
        ...state,
        updatePin: action.payload,
      };
    }
    case WALLET.RESET_WALLET_PIN: {
      return {
        ...state,
        resetWalletPin: action.payload,
      };
    }
    case WALLET.CONFIRM_WALLET: {
      return {
        ...state,
        confirmWalletAlias: action.payload,
      };
    }
    case WALLET.CONFIRM_PIN: {
      return {
        ...state,
        consfirmWalletPin: action.payload,
      };
    }
    default:
      return state;
  }
};
