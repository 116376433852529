import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  Input,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpButton,
  SpH5,
} from 'components/DesignKit';
import formatAmount from 'utils/formatAmount';

type Props = {
  internalAccount: Object,
  submit: Function,
  openModal: Function,
  selectedAccount: Object,
};

const CommissionRate = (props: Props) => {
  const {
    internalAccount,
    submit,
    openModal,
    selectedAccount,
  } = props;
  const [commisionRate, setCommissionRate] = useState(internalAccount && internalAccount.partnerCommissionRate
    ? internalAccount.partnerCommissionRate
    : null);
  const [disabled, setDisabled] = useState(true);
  const simpleValidator = useRef(new SimpleReactValidator());
  const currencyType = selectedAccount && selectedAccount.currency;

  simpleValidator.current.purgeFields();

  const updatePartnerCommission = () => {
    submit({
      commisionRate,
    });
  };

  useEffect(() => {
    setCommissionRate(internalAccount.partnerCommissionRate || null);
    setDisabled(true);
  }, [openModal]);

  const reset = () => {
    setCommissionRate(internalAccount.partnerCommissionRate || null);
    setDisabled(true);
    openModal(false);
  };

  return (
    <>
      <div>
        <Row>
          <Col span={6} className="mb-2">
            <SpH5>Partner Commission Rate</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Input
              placeholder="0.00"
              value={commisionRate}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                const { value } = e.currentTarget;
                if (regex.test(value) || value === '') {
                  setCommissionRate(e.currentTarget.value);
                } else if (!regex.test(value)) {
                  setCommissionRate(0.00);
                }
                setDisabled(false);
                openModal(true);
              }}
              onBlur={e => {
                if (e.currentTarget.value) {
                  const value = formatAmount(e.currentTarget.value, currencyType);
                  setCommissionRate(value);
                }
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4" style={{ marginLeft: '-15px' }}>
          <Col span={2}>
            <Button
              type="link"
              onClick={reset}
              disabled={disabled}
            >
              Reset
            </Button>
          </Col>
          <Col span={2}>
            <SpButton
              type="primary"
              shape="round"
              disabled={disabled}
              onClick={updatePartnerCommission}
            >
              Update
            </SpButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(CommissionRate);
