import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  SpText,
} from 'components/DesignKit';
import TransferCenter from 'imgs/transfers.svg';
import { navigationCardCustomerSoluitons } from 'appconstants/navigationCard';
import '../../styles/_navigationCard.scss';

type Props = {
  history: {
    push: Function,
    replace: Function,
  },
  selectedAccount: Object,

};

const CustomerSolutions = (props: Props) => {
  const {
    history,
    selectedAccount,
  } = props;
  const [filteredCards, setFilteredCards] = useState(navigationCardCustomerSoluitons);
  useEffect(() => {
    const filtered = selectedAccount && !selectedAccount.legacySubscription
      ? navigationCardCustomerSoluitons.filter(item => item.name !== 'Products')
      : navigationCardCustomerSoluitons;

    setFilteredCards(filtered);
  }, [selectedAccount, navigationCardCustomerSoluitons]);
  return (
    <>
      <div
        className="mx-auto mt-5"
        style={{ width: 1000, height: 'auto' }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
          <img
            style={{ width: '350px' }}
            src={TransferCenter}
            alt="IMG"
          />
          <SpText fontSize="26px" fontWeight="600">CUSTOMER SOLUTIONS</SpText>
          <SpText fontSize="18px" fontWeight="400">Manage customer invoices, subscriptions, rewards, and associated products.</SpText>
        </div>
      </div>
      <div className="main_div">
        {filteredCards.map((item, index) => (
          <div
            key={index}
            className="card"
            onClick={() => history.push(item.path)}
          >
            <div className="mt-2">
              <img src={item.svg} alt={item.name} />
              <p style={{ fontSize: '14px', fontWeight: '400' }} className="mt-2">{item.name}</p>
            </div>
          </div>
        ))}
      </div>
    </>

  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,

});


export default connect(mapStateToProps)(CustomerSolutions);
