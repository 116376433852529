import { ADMIN_TERMINALDETAILS } from '../actionTypes';

export const fetchAdminTerminalDetails = (action) => ({
  type: ADMIN_TERMINALDETAILS.FETCH,
  payload: action.payload,
});

export const deleteAdminTerminal = (action) => ({
  type: ADMIN_TERMINALDETAILS.DELETE,
  payload: action.payload,
});

export const updateTerminalPin = (action) => ({
  type: ADMIN_TERMINALDETAILS.UPDATE,
  payload: action.payload,
});
