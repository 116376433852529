import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  INVENTORY_PREORDERS,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryPreorders = async (payload) => {
  if (payload === 'All') {
    return axiosAuthInstance.get(`${API_END_POINTS.SHOP_PREORDERS}?sortBy=createdAt&direction=DESC`);
  }

  const queryTemp = payload.split('-');
  if (payload && queryTemp[0] === 'shop') {
    const query = `?shopGid.EQ=${payload}&sortBy=createdAt&direction=DESC`;
    return axiosAuthInstance.get(`${API_END_POINTS.SHOP_PREORDERS}${query}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.SHOP_PREORDERS}?sortBy=createdAt&direction=DESC`);
};

const fetchFilterPreorders = async (payload) => {
  if (payload) {
    const { filterParams } = payload;
    const { sortParams } = payload;
    return axiosAuthInstance.get(`${API_END_POINTS.SHOP_PREORDERS}${filterParams}${sortParams}`);
  }
  const query = '?page=1&size=10&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.SHOP_PREORDERS}${query}`);
};


export function* getInventoryPreorders(action) {
  try {
    const { data: { entity } } = yield call(fetchInventoryPreorders, action.payload);
    yield put({ type: INVENTORY_PREORDERS.SUCCESS, payload: entity });
    yield put({ type: INVENTORY_PREORDERS.PREORDERS_FILTER_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_PREORDERS.FAILED, payload: e.response.data });
  }
}

export function* getFilterPreorders(action) {
  try {
    const { data: { entity } } = yield call(fetchFilterPreorders, action.payload);
    yield put({ type: INVENTORY_PREORDERS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_PREORDERS.FAILED, payload: e.response.data });
  }
}
