import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';

import {
  Row,
  Col,
} from 'antd';
import styled from 'styled-components';
import {
  SpText,
  SpH5,
  SpHead,
  SpH6,
  Line,
} from 'components/DesignKit';

import {
  pageReciptsActions,
} from 'store/actions';
import Loading from 'components/Loading';
import moment from 'moment-timezone';
import QrDownload from 'imgs/qrDownload.png';
import formatAmount from 'utils/formatAmount';
import ShowQrCode from './components/ShowQrCode';

// $FlowFixMe
const Wrapper = styled.div`
    background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
    width: 100%;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  width: '560px',
  minHeight: '520px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

// $FlowFixMe
const FormWrapper1 = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  width: '560px',
  minHeight: '120px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

type Props = ContextRouter & {
  fetchPageReceipts: Function,
  loading: boolean,
  submitting: Boolean,
  receipts: Object,
  history: {
    replace: Function,
  },
};

const PaymentPageRecipt = (props: Props) => {
  const {
    fetchPageReceipts,
    receipts,
    loading,
    submitting,
    match,
  } = props;

  const [nonPaymentItemList, setNonPaymentItemList] = useState([]);
  const [paymentItemList, setPaymentItemList] = useState([]);
  const [tabelsList, setTabelsList] = useState();
  const [splitInfo, setSplitUp] = useState({});
  const [paymentMethodType, setPaymentMethodType] = useState(null);
  const [offlinePayment, setOfflinePayment] = useState(false);
  const [comboBox, setComboBox] = useState([]);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const currentUrl = window.location.href;

  const receiptID = match.params.pageid;
  // eslint-disable-next-line no-unused-vars
  const accountGid = 'account-ff1166de992e429c82eb02f86dfdbbab';

  useEffect(() => {
    if (receiptID) {
      fetchPageReceipts(receiptID);
    }
  }, [receiptID]);

  const selectedQrCode = () => {
    setShowQrCodeModal(true);
  };

  const returnFromQrCode = (qrVlue) => {
    if (qrVlue === true) {
      setShowQrCodeModal(false);
    }
  };

  // const selectedQrCode = () => {
  //   setShowQrCodeModal(true);
  // };

  // const returnFromQrCode = (qrVlue) => {
  //   if (qrVlue === true) {
  //     setShowQrCodeModal(false);
  //   }
  // };

  useEffect(() => {
    if (receipts && receipts.receiptNumber) {
      const { paymentPageEntries } = receipts;
      const { splitUp } = receipts;
      const {
        paymentSession, checkTransaction, cashTransaction, customTransaction,
      } = receipts;
      const lineItems = paymentPageEntries.filter(item => item.type.split('_')[0] === 'PRICE');
      const nonPaymentLineItems = paymentPageEntries.filter(item => (item.type.split('_')[0] !== 'PRICE' && item.type !== 'COMBO_BOX' && item.type !== 'TIP' &&item.type!=='CONSENT_FORM'));
      const tableItems = paymentPageEntries.filter(item => item.label === 'Table Number');
      const comboBoxList = paymentPageEntries.filter(item => item.type === 'COMBO_BOX');
      // if (comboBoxList.length !== 0) {
      //   const comboList = comboBoxList[0].comboBoxValue ? (comboBoxList[0].comboBoxValue).split('&&') : '';
      //   setChildren(comboList);
      // }
      setComboBox(comboBoxList);
      setPaymentItemList([...lineItems]);
      setNonPaymentItemList([...nonPaymentLineItems]);
      setTabelsList(tableItems);
      setSplitUp(splitUp);
      if (paymentSession) {
        setPaymentMethodType(paymentSession);
      }
      if (checkTransaction) {
        setPaymentMethodType(checkTransaction);
        setOfflinePayment(true);
      }
      if (cashTransaction) {
        setPaymentMethodType(cashTransaction);
        setOfflinePayment(true);
      }
      if (customTransaction) {
        setPaymentMethodType(customTransaction);
        setOfflinePayment(true);
      }
    }
  }, [receipts]);

  const formattedData = (arr) => {
    const formattedArr = arr.filter(obj => obj.additionalPrice !== undefined).map(obj => {
      const displayValues = [];
      for (const [key, value] of Object.entries(obj)) {
        if (key !== 'isRedeemed' && key !== 'additionalPrice') {
          if (key !== 'isCheckBoxSelected') {
            displayValues.push(`${key}: ${value}`);
          }
        }
      }
      /* eslint-disable-next-line max-len */
      displayValues.push(`Price: ${(receipts.account && receipts.account.currency.prefix)}${formatAmount(obj.additionalPrice / 100, receipts.account.currency)}`);
      /* eslint-disable-next-line */
      return displayValues.join(", ");
    });
    return formattedArr;
  };

  const formattedData1 = (arr) => {
    const formattedArr1 = arr.map(obj => {
      const displayValues = [];
      for (const [key, value] of Object.entries(obj)) {
        if (key !== 'isRedeemed' && key !== 'additionalPrice') {
          if (key !== 'isCheckBoxSelected') {
            displayValues.push(`${key}: ${value}`);
          }
        }
      }
      return displayValues.join(', ');
    });
    return formattedArr1;
  };

  const formatDate = (date, timezone) => {
    if (date && timezone) {
      return moment.utc(date).tz(timezone).format('MMM DD, YYYY');
    }
    return date && moment.utc(date).format('MMM DD, YYYY');
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        receipts && receipts.account && receipts.account.gid ? (
          <Wrapper>
            <FormWrapper>
              <Row>
                <Col type="flex" align="middle">
                  <SpHead className="text-uppercase" fontWeight="600">Receipt For {receipts.account.name}</SpHead>
                </Col>
              </Row>
              {
                (tabelsList && (tabelsList.length > 0)) ? (
                  <>
                    <Row>
                      <Col type="flex" align="middle" className="mt-3"><SpH6 fontWeight="600">Receipt # {receiptID}</SpH6></Col>
                    </Row>
                    <Row>
                      <Col type="flex" align="middle" className="mt-3 mb-4"><SpH6 fontWeight="600">Table # {tabelsList[0].value}</SpH6></Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col type="flex" align="middle" className="mt-3"><SpH6 fontWeight="600">Receipt # {receiptID}</SpH6></Col>
                  </Row>
                )
              }
              {
                receipts && receipts.receiptNote ? (
                  <>
                    <Row>
                      <Col type="flex" align="middle" className="mt-1"><SpH6 fontWeight="600">{receipts.receiptNote}</SpH6></Col>
                    </Row>
                  </>
                ) : (<></>)
              }
              {
                receipts && receipts.venue ? (
                  <>
                    <Row>
                      <Col type="flex" align="middle" className="mt-2"><SpH6 fontWeight="600">Venue Details</SpH6></Col>
                    </Row>
                    <Row>
                      <Col type="flex" align="middle" className="mt-1"><SpText fontWeight="400" fontSize="14px">{receipts.venue}</SpText></Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )
              }
              {
                (receipts && receipts.offlinePaymentType !== 'CUSTOM') && (
                  <Row>
                    <Col type="flex" align="middle" className="mb-3">
                      <SpH6 fontWeight="600">{receipts.offlinePaymentNote}</SpH6>
                    </Col>
                  </Row>
                )
              }
              {
                (receipts && receipts.offlinePaymentType === 'CUSTOM') && (
                  <Row type="flex" justify="space-between">
                    {
                      ((receipts && receipts.paidAmount) === 0) ? ''
                        : ((receipts && receipts.paidAmount)) && ((receipts && receipts.paidAmount) > 0) && (
                          <Col span={8}>
                            <div className="pt-1 pb-1"><SpText color="#434343">Amount Paid</SpText></div>
                            <div className="pt-1 pb-1">
                              <SpH6 fontWeight="600">
                                <SpH6 fontWeight="600">
                                  {receipts.account.currency.prefix} {formatAmount(receipts.paidAmount / 100, receipts.account.currency)}
                                </SpH6>
                              </SpH6>
                            </div>
                          </Col>
                        )
                    }
                    <Col span={8}>
                      <div className="pt-1 pb-1"><SpText color="#434343">Payment Date</SpText></div>
                      <div className="pt-1 pb-1">
                        <SpH6 fontWeight="600">
                          {
                            (receipts.paymentDate
                              && formatDate(receipts.paymentDate, receipts.account.timezone)) || <>&#8211;</>
                          }
                        </SpH6>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="pt-1 pb-1"><SpText color="#434343">Offline Payment Note</SpText></div>
                      <div className="pt-1 pb-1">
                        <SpH6 fontWeight="600">{receipts.offlinePaymentNote}</SpH6>
                      </div>
                    </Col>
                  </Row>
                )
              }
              <Row type="flex" justify="end" className="mb-4">
                <Col>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => { e.stopPropagation(); selectedQrCode(); }}
                  >
                    <img
                      src={QrDownload}
                      alt="qrcode"
                    />
                  </div>
                </Col>
              </Row>
              {/* SHOW QR MODAL */}
              {showQrCodeModal && (
                <ShowQrCode
                  visible={showQrCodeModal}
                  submitting={submitting}
                  selectedQR={currentUrl}
                  returnFromQrCode={returnFromQrCode}
                  close={() => setShowQrCodeModal(false)}
                />
              )}
              <Line />
              <Row type="flex" justify="space-between" className="mb-4">
                {
                  paymentMethodType && (
                    <Col span={8}>
                      <div className="pt-1 pb-1"><SpText color="#434343">Amount Paid</SpText></div>
                      <div className="pt-1 pb-1">
                        {
                          receipts && receipts.paidAmount ? (
                            <SpH6 fontWeight="600">
                              {/* eslint-disable-next-line max-len */}
                              {paymentMethodType.currency && paymentMethodType.currency.prefix} {formatAmount(receipts.paidAmount / 100, receipts.account && receipts.account.currency)}
                            </SpH6>
                          ) : (
                            <SpH6 fontWeight="600">
                              {paymentMethodType.currency.prefix} {formatAmount(paymentMethodType.amount / 100, receipts.account.currency)}
                            </SpH6>
                          )
                        }
                      </div>
                    </Col>
                  )
                }
                {
                  (receipts.paymentSession) || (receipts.checkTransaction) || (receipts.cashTransaction) ? (
                    <>
                      <Col span={8}>
                        <div className="pt-1 pb-1"><SpText color="#434343">Date Paid</SpText></div>
                        <div className="pt-1 pb-1">
                          <SpH6 fontWeight="600">
                            {
                              (receipts.paymentSession) ? (
                                <SpH6 fontWeight="600">
                                  {
                                    (receipts.paymentSession
                                      && receipts.paymentSession.paymentDate
                                      && formatDate(receipts.paymentSession.paymentDate, receipts.account.timezone)) || <>&#8211;</>
                                  }
                                </SpH6>
                              ) : (receipts.checkTransaction) ? (
                                <SpH6 fontWeight="600">
                                  {
                                    (receipts.checkTransaction
                                      && receipts.checkTransaction.transactionDate
                                      && formatDate(receipts.checkTransaction.transactionDate, receipts.account.timezone)) || <>&#8211;</>
                                  }
                                </SpH6>
                              ) : (receipts.cashTransaction) ? (
                                <SpH6 fontWeight="600">
                                  {
                                    (receipts.cashTransaction
                                      && receipts.cashTransaction.transactionDate
                                      && formatDate(receipts.cashTransaction.transactionDate, receipts.account.timezone)) || <>&#8211;</>
                                  }
                                </SpH6>
                              ) : null
                            }
                          </SpH6>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="pt-1 pb-1"><SpText color="#434343">Payment Method</SpText></div>
                        <div className="pt-1 pb-1">
                          <SpH6 fontWeight="600">
                            {(receipts.paymentSession)
                              ? (receipts.paymentSession && receipts.paymentSession.method)
                              : (receipts.checkTransaction)
                                ? 'CHECK' : 'CASH'}
                          </SpH6>
                        </div>
                      </Col>
                    </>
                  ) : null
                }
              </Row>
              {
                  (nonPaymentItemList.length !== 0) && nonPaymentItemList.map((item) => (
                      <>
                        {
                            (item.value) && (item.value !== '') && (
                                <>
                                  <>
                                  
                                    <Line />
                                    <>
                                      <Row type="flex" className="pt-1 pb-1">
                                        <Col span={8}>
                                          <div>
                                            {item.label}
                                          </div>
                                        </Col>
                                        <Col span={12} style={{ alignItems: 'right' }}>
                                          <div>
                                            {/* eslint-disable-next-line max-len */}
                                            {item.value}
                                          </div>
                                        </Col>
                                      </Row>
                                    </>
                                    <Line />
                                  </>
                                </>
                            )
                        }
                      </>
                  ))
              }
              {
                (paymentItemList.length !== 0) && paymentItemList.map((item) => (
                  <>
                    {
                      (item.value) && (item.value !== '0') && (
                        <>
                          {
                            item.type === 'PRICE_AMOUNT_FIXED' ? (
                              <>
                                <Line />
                                <>
                                  <Row type="flex" className="pt-1 pb-1">
                                    <Col span={12}>
                                      <div>
                                        {item.label}
                                      </div>
                                    </Col>
                                    <Col span={6}>
                                      &nbsp;
                                    </Col>

                                    <Col span={6} style={{ alignItems: 'right' }}>
                                      <div>
                                        {/* eslint-disable-next-line max-len */}
                                        {(paymentMethodType && paymentMethodType.currency) ? paymentMethodType.currency.prefix : (receipts.account && receipts.account.currency.prefix)} {formatAmount(item.price / 100, receipts.account.currency)}
                                      </div>
                                    </Col>
                                  </Row>
                                  {
                                    item.comboBoxValue && (
                                      <>
                                        <Row type="flex" className="pt-1 pb-1">
                                          <Col span={12}>
                                            &nbsp;
                                          </Col>

                                          <Col span={12} style={{ alignItems: 'right' }}>
                                            {formattedData1(item.comboBoxValue)}
                                          </Col>
                                        </Row>
                                      </>
                                    )
                                  }
                                </>
                                <Line />
                              </>
                            ) : (
                              <>
                                {
                                  item.type === 'PRICE_AMOUNT_QUANTITY_BASED' ? (
                                    <>
                                      <Line />
                                      <Row type="flex" className="pt-1 pb-1">
                                        <Col span={12}>
                                          <div>
                                            {item.label}
                                          </div>
                                        </Col>
                                        <Col span={6}>
                                          <div>
                                            {item.value}
                                          </div>
                                        </Col>
                                        <Col span={6} style={{ alignItems: 'right' }}>
                                          <div>
                                            {/* eslint-disable-next-line max-len */}
                                            {(paymentMethodType && paymentMethodType.currency) ? (paymentMethodType.currency.prefix) : (receipts.account && receipts.account.currency.prefix)} {formatAmount((item.price / 100) * item.value, receipts.account.currency)}
                                          </div>
                                        </Col>
                                      </Row>
                                      {
                                        item.comboBoxValue && (
                                          <>
                                            <Line />
                                            <Row type="flex" className="pt-1 pb-1">
                                              <Col span={12}>
                                                &nbsp;
                                              </Col>
                                              <Col span={6}>
                                                &nbsp;
                                              </Col>
                                              <Col span={6} style={{ alignItems: 'right' }}>
                                                {formattedData(item.comboBoxValue)}
                                              </Col>
                                            </Row>
                                          </>
                                        )
                                      }
                                    </>
                                  ) : (
                                    <>
                                      <Line />
                                      <Row type="flex" className="pt-1 pb-1">
                                        <Col span={12}>
                                          <div>
                                            {item.label}
                                          </div>
                                        </Col>
                                        <Col span={6}>
                                          &nbsp;
                                        </Col>
                                        <Col span={6} style={{ alignItems: 'right' }}>
                                          <div>
                                            {/* eslint-disable-next-line max-len */}
                                            {(paymentMethodType && paymentMethodType.currency) ? (paymentMethodType.currency.prefix) : (receipts.account && receipts.account.currency.prefix)} {formatAmount(item.value / 100, receipts.account.currency)}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Line />
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                        </>
                      )
                    }
                  </>
                ))
              }
              {
                comboBox.length !== 0 && (
                  <>
                    {
                      comboBox.map((item) => (
                        <>
                          {
                            item.comboBoxValue && (
                              <>
                                <Line />
                                <Row type="flex" className="pt-1 pb-1">
                                  <Col span={12}>
                                    <div>
                                      {item.label}
                                    </div>
                                  </Col>
                                  <Col span={12} style={{ alignItems: 'right' }}>
                                    {
                                      item.comboBoxValue && (
                                        <>
                                          {formattedData1(item.comboBoxValue)}
                                        </>
                                      )
                                    }
                                  </Col>
                                </Row>
                                <Line />
                              </>
                            )
                          }

                        </>
                      ))
                    }
                  </>
                )
              }
              {
                receipts.offlinePaymentType !== 'CUSTOM' ? (
                  <>
                    {
                      splitInfo.discountedAmount && splitInfo.discountedAmount !== 0 ? (
                        <>
                          <Line />
                          <Row type="flex" className="pt-1 pb-1">
                            <Col span={12}>
                              <div>Discount</div>
                            </Col>
                            <Col span={6}>
                              &nbsp;
                            </Col>
                            <Col span={6} style={{ alignItems: 'right' }}>
                              <div>
                                {/* eslint-disable-next-line max-len */}
                                {(paymentMethodType && paymentMethodType.currency) ? (paymentMethodType.currency.prefix) : (receipts.account && receipts.account.currency.prefix)} ({formatAmount(splitInfo.discountedAmount / 100, receipts.account.currency)})
                              </div>
                            </Col>
                          </Row>
                          <Line />
                        </>

                      ) : (
                        <></>
                      )
                    }

                    {
                      !offlinePayment && splitInfo.subTotal && splitInfo.subTotal !== 0 ? (
                        <>
                          <Line />
                          <Row type="flex" className="pt-1 pb-1">
                            <Col span={12}>
                              <SpH5>Sub Total</SpH5>
                            </Col>
                            <Col span={6}>
                              &nbsp;
                            </Col>
                            <Col span={6} style={{ alignItems: 'right' }}>
                              <div>
                                <SpH5>
                                  {/* eslint-disable-next-line max-len */}
                                  {(paymentMethodType && paymentMethodType.currency) ? (paymentMethodType.currency.prefix) : (receipts.account && receipts.account.currency.prefix)} {formatAmount(splitInfo.subTotal / 100, receipts.account.currency)}
                                </SpH5>
                              </div>
                            </Col>
                          </Row>
                          <Line />
                        </>

                      ) : (
                        <></>
                      )
                    }

                    {
                      splitInfo.tax && splitInfo.tax !== 0 ? (
                        <>
                          <Line />
                          <Row type="flex" className="pt-1 pb-1">
                            <Col span={12}>
                              <div>
                                Tax
                              </div>
                            </Col>
                            <Col span={6}>
                              &nbsp;
                            </Col>
                            <Col span={6} style={{ alignItems: 'right' }}>
                              <div>
                                {/* eslint-disable-next-line max-len */}
                                {(paymentMethodType && paymentMethodType.currency) ? (paymentMethodType.currency.prefix) : (receipts.account && receipts.account.currency.prefix)} {formatAmount(splitInfo.tax / 100, receipts.account.currency)}
                              </div>
                            </Col>
                          </Row>
                          <Line />
                        </>

                      ) : (
                        <></>
                      )
                    }

                    {
                      splitInfo.serviceFee && splitInfo.serviceFee !== 0 ? (
                        <>
                          <Line />
                          <Row type="flex" className="pt-1 pb-1">
                            <Col span={12}>
                              <div>
                                Service Fee
                              </div>
                            </Col>
                            <Col span={6}>
                              &nbsp;
                            </Col>
                            <Col span={6} style={{ alignItems: 'right' }}>
                              <div>
                                {/* eslint-disable-next-line max-len */}
                                {(paymentMethodType && paymentMethodType.currency) ? (paymentMethodType.currency.prefix) : (receipts.account && receipts.account.currency.prefix)} {formatAmount(splitInfo.serviceFee / 100, receipts.account.currency)}
                              </div>
                            </Col>
                          </Row>
                          <Line />
                        </>

                      ) : (
                        <></>
                      )
                    }
                    {
                      splitInfo.tip && splitInfo.tip !== 0 ? (
                        <>
                          <Line />
                          <Row type="flex" className="pt-1 pb-1">
                            <Col span={12}>
                              <div>
                                Tip
                              </div>
                            </Col>
                            <Col span={6}>
                              &nbsp;
                            </Col>
                            <Col span={6} style={{ alignItems: 'right' }}>
                              <div>
                                {/* eslint-disable-next-line max-len */}
                                {(paymentMethodType && paymentMethodType.currency) ? (paymentMethodType.currency.prefix) : (receipts.account && receipts.account.currency.prefix)} {formatAmount(splitInfo.tip / 100, receipts.account.currency)}
                              </div>
                            </Col>
                          </Row>
                          <Line />
                        </>

                      ) : (
                        <></>
                      )
                    }
                    <Row type="flex" className="pt-1 pb-1">
                      <Col span={12}>
                        <div>
                          <SpH5>Total</SpH5>
                        </div>
                      </Col>
                      <Col span={6}>
                        <div>
                          &nbsp;
                        </div>
                      </Col>
                      {
                        (receipts && receipts.paidAmount) ? (
                          <Col span={6}>
                            <div>
                              <SpH5>
                                {/* eslint-disable-next-line max-len */}
                                {(paymentMethodType && paymentMethodType.currency) ? (paymentMethodType.currency.prefix) : (receipts.account && receipts.account.currency.prefix)} {formatAmount(receipts.paidAmount / 100, receipts.account.currency)}
                              </SpH5>
                            </div>
                          </Col>
                        )
                          : (splitInfo && splitInfo.totalPayable) ? (
                            <Col span={6}>
                              <div>
                                <SpH5>
                                  {/* eslint-disable-next-line max-len */}
                                  {receipts.account.currency.prefix} {formatAmount(splitInfo.totalPayable / 100, receipts.account.currency)}
                                </SpH5>
                              </div>
                            </Col>
                          ) : (
                            (paymentMethodType && paymentMethodType.amount) && (
                              <Col span={6}>
                                <div>
                                  <SpH5>
                                    {/* eslint-disable-next-line max-len */}
                                    {(paymentMethodType && paymentMethodType.currency) ? (paymentMethodType.currency.prefix) : (receipts.account && receipts.account.currency.prefix)} {formatAmount(paymentMethodType.amount / 100, receipts.account.currency)}
                                  </SpH5>
                                </div>
                              </Col>
                            )
                          )
                      }
                    </Row>
                  </>
                ) : (
                  <Row type="flex" className="pt-1 pb-1">
                    <Col span={12}>
                      <div>
                        <SpH5>Total</SpH5>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div>
                        &nbsp;
                      </div>
                    </Col>
                    {
                      (receipts && receipts.paidAmount) ? (
                        <Col span={6}>
                          <div>
                            <SpH5>
                              {/* eslint-disable-next-line max-len */}
                              {(receipts.account && receipts.account.currency.prefix)} {formatAmount(receipts.paidAmount / 100, receipts.account.currency)}
                            </SpH5>
                          </div>
                        </Col>
                      ) : (splitInfo && splitInfo.totalPayable) ? (
                        <Col span={6}>
                          <div>
                            <SpH5>
                              {/* eslint-disable-next-line max-len */}
                              {receipts.account.currency.prefix} {formatAmount(splitInfo.totalPayable / 100, receipts.account.currency)}
                            </SpH5>
                          </div>
                        </Col>
                      ) : <>&#8211;</>
                    }
                  </Row>
                )
              }
              <Line />
              {
                receipts.account.support && (
                  <Row>
                    <Col type="flex" align="middle" className="mt-3">
                      <div>For any questions and concerns, please contact</div>
                    </Col>
                    <Col type="flex" align="middle">
                      <div>
                        <SpText fontWeight="600" color="#1d9cff">
                          {(receipts.account.support && receipts.account.support.email) ? receipts.supportDto.email : ''}
                        </SpText>
                      </div>
                    </Col>
                  </Row>
                )
              }
            </FormWrapper>
          </Wrapper>
        ) : (
          <Wrapper>
            <FormWrapper1>
              <Row>
                <Col type="flex" align="middle">
                  <SpHead className="text-uppercase" fontWeight="600">The entry may deleted.</SpHead>
                </Col>
              </Row>

            </FormWrapper1>
          </Wrapper>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  receipts: state.pageRecipts,
  submitting: state.loading.submitting,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPageReceipts: gid => dispatch(pageReciptsActions.fetchPageReceipts({
    payload: gid,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPageRecipt);
