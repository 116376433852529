// @flow
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import {
  SpH5,
  SpButton,
} from 'components/DesignKit';
// import QRCode from 'react-qr-code';
import { QRCode } from 'react-qrcode-logo';
import InputColor from 'react-input-color';
// import { toPng } from 'html-to-image';
import {
  notificationActions,
} from 'store/actions';
import {
  NOTIFICATION,
} from 'store/actionTypes';
import {
  MESSAGES,
} from 'appconstants';
import Qrlogo from '../../../../assets/imgs/SwirepayQrlogo.png';


type Props = {
  close: Function,
  visible: boolean,
  selectedQR: string,
  setNotification: Function,
  returnFromQrCode: Function,
};

const ShowQrSlug = (props: Props) => {
  const {
    visible,
    close,
    selectedQR,
    setNotification,
    returnFromQrCode,
  } = props;
  const [primaryColor, setColorCode] = useState('#000000');
  const qrCodeRef = useRef(null);
  const qrSize = 256;

  const handleChange = (e) => {
    setColorCode(e.hex);
  };

  const updateColor = (e) => {
    setColorCode(e.currentTarget.value);
  };

  const downloadQRCode = async () => {
    const qrCode = qrCodeRef.current.canvas.current;
    if (!qrCode) {
      console.error('QR code element not found.');
      return;
    }
    try {
      const dataUrl = qrCode.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'qrcode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.QR_Download,
      });
      returnFromQrCode(true);
    } catch (error) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Error capturing QR code',
      });
      returnFromQrCode(false);
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      closable={false}
      title="View / Download QR Code"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={downloadQRCode}>Download</SpButton>,
      ]}
    >
      <div className="px-4">
        <div className="mb-3">
          <label htmlFor=""><SpH5>Color</SpH5></label>
          <div className="d-flex ml-1">
            <div>
              <InputColor
                placement="right"
                initialValue={primaryColor}
                onChange={handleChange}
                style={{
                  height: '32px',
                }}
              />
            </div>
            <Input
              className="ml-3"
              value={primaryColor}
              onChange={updateColor}
            />
          </div>
        </div>
        <div>
          <div>
            <label htmlFor=""><SpH5>QR Code</SpH5></label>
          </div>
          <QRCode
            size={qrSize}
            value={selectedQR}
            logoImage={Qrlogo}
            ref={qrCodeRef}
            logoPadding={5}
            fgColor={primaryColor}
            removeQrCodeBehindLogo
          />
          {/* <QRCode size={qrSize} value={selectedQR} fgColor={primaryColor} ref={qrCodeRef} /> */}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ShowQrSlug);
