import { SUBSCRIPTIONV2 } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  subscriptionStatus: undefined,
  subscriptionError: undefined,
  plans: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTIONV2.FETCH:
      return {
        ...state,
        subscriptionStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case SUBSCRIPTIONV2.SUCCESS:
      return {
        ...state,
        ...action.payload,
        subscriptionStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case SUBSCRIPTIONV2.FAILED:
      return {
        ...state,
        subscriptionError: action.payload,
        subscriptionStatus: CONSTANTS.API_STATUS.FAILED,
      };

    case SUBSCRIPTIONV2.SEARCH_SUCCESS:
      return {
        ...state,
        plans: action.payload,
      };
    default:
      return state;
  }
};
