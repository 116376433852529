import { QUICKBOOKS } from '../actionTypes';

const initialState = {
  content: [],
  hasQBDetails: false,
  accountQuickBooks: {},
  quickbooksStatus: undefined,
  quickbooksError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case QUICKBOOKS.SUCCESS:
      return {
        ...state,
        accountQuickBooks: action.payload,
      };
    case QUICKBOOKS.SET_QUICKBOOK:
      return {
        ...state,
        hasQBDetails: action.payload,
      };
    case QUICKBOOKS.FETCH:
      return {
        ...state,
        quickbooksStatus: 'STARTED',
      };
    case QUICKBOOKS.FAILED:
      return {
        ...state,
        quickbooksError: action.payload,
        quickbooksStatus: 'FAILED',
      };
    default:
      return state;
  }
};
