// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpButton, SpError,
} from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
};

const NewBankAccount = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
  } = props;

  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator({}));


  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      accountNumber,
      routingNumber,
      accountName,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add</SpButton>,
      ]}
      title="Add Bank Account"
    >
      <div className="px-4 mt-2">
        <div className="mb-3">
          <label htmlFor=""><SpH5>Account Name</SpH5></label>
          <Input
            placeholder="Enter bank account name"
            value={accountName}
            onChange={(e) => setAccountName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Account Name', accountName, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Account Number</SpH5></label>
          <Input
            placeholder="Enter bank account number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Account Number', accountNumber, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Routing Number</SpH5></label>
          <Input
            value={routingNumber}
            onChange={(e) => setRoutingNumber(e.currentTarget.value)}
            placeholder="Enter routing number"
          />
          <SpError>
            {simpleValidator.current.message('Routing Number', routingNumber, 'required')}
          </SpError>
        </div>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(NewBankAccount);
