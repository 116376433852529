import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import styled from 'styled-components';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import SimpleReactValidator from 'simple-react-validator';
import {
  Card,
  Table,
  Row,
  Col,
  Menu,
  Tooltip,
  Icon,
  Input,
} from 'antd';
import {
  SpForm,
  SpText,
  SpH5,
  SpTab,
  SpButton,
  SpError,
} from 'components/DesignKit';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import {
  PAGES,
  API_STATUS,
  MESSAGES,
  ROLES,
} from 'appconstants';
import { terminalDetailsActions, notificationActions } from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import formatNumber from 'utils/formatNumber';
import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';
import getBackground from 'utils/getAutoSettlementBackground';
import CancelIcon from 'components/NavIcons/CanceledIcon';
import UpdateIcon from 'components/NavIcons/UpdatedIcon';
import TerminalProof from './components/TerminalProof';

const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: '#434343',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  history: {
    push: Function,
    replace: Function,
  },
  location: {
    pathname: string,
    search: string,
  },
  fetchTerminalDetails: Function,
  terminalDetails: Object,
  fetchTerminalPayments: Function,
  selectedAccount: Object,
  test: Boolean,
  payments: Array<Object>,
  updateTerminalDetails: Function,
  submitting: Boolean,
  isSuperUser: Boolean,
  totalElements: Number,
  updateTerminalProof: Function,
  validator: Boolean,
  setNotification: Function,
};

const TerminalDetail = (props: Props) => {
  const {
    history,
    location,
    fetchTerminalDetails,
    terminalDetails,
    fetchTerminalPayments,
    selectedAccount,
    test,
    payments,
    updateTerminalDetails,
    submitting,
    isSuperUser,
    totalElements,
    updateTerminalProof,
    validator,
    setNotification,
  } = props;
  const [, forceUpdate] = useState();
  const [pagination, setPagination] = useState({});
  // eslint-disable-next-line react/prop-types
  const { gid } = props.match.params;
  const [current, setCurrent] = useState('succeeded');
  const { pathname } = location;
  // eslint-disable-next-line react/prop-types
  const gidPath = gid.split('-')[1];
  const currencyType = selectedAccount && selectedAccount.currency;
  const [showEdit, setShowEdit] = useState(false);
  const [deviceName, setDeviceName] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [autoSettlement, setAutosettlement] = useState();
  const [addTerminalProof, setAddTerminalProof] = useState(false);

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const searchParam = parsed['status.IN'];
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchTerminalPayments({
      searchParam,
      currentPage,
      sortParams,
      gid,
    });
  };

  useEffect(() => {
    fetchTerminalDetails(gid);
  }, [test, selectedAccount]);

  useEffect(() => {
    if (terminalDetails && terminalDetails.gid) {
      setAutosettlement(terminalDetails.autoSettlementEnabled);
    }
  }, [terminalDetails]);

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.TERMINALS);
    }
  }, []);

  useEffect(() => {
    setDeviceName(terminalDetails && terminalDetails.deviceName);
  }, [terminalDetails]);

  useEffect(() => {
    if (!submitting) {
      setShowEdit(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (!validator) {
      setAddTerminalProof(false);
    }
  }, [validator]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments]);

  const handleTableChange = (page) => {
    const tempPage = page.current;
    const parsed = queryString.parse(location.search);
    const searchParam = parsed['status.IN'];

    if (location.search && searchParam) {
      history.push({
        pathname: `${PAGES.TERMINALS}/${gid}`,
        search: `?status.IN=${searchParam}&pageNum=${tempPage}`,
      });
    } else if (!searchParam) {
      history.push({
        pathname: `${PAGES.TERMINALS}/${gid}`,
        search: `?pageNum=${tempPage}`,
      });
    }
  };

  const updateDeviceName = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      deviceName: deviceName || terminalDetails.deviceName,
      autoSettlementEnabled: terminalDetails.autoSettlementEnabled,
      isActive: terminalDetails.active,
    };
    updateTerminalDetails({
      gid,
      params,
    });
  };

  const uploadTerminalProof = (terminalProof: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'updatedAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      documentUrl,
    } = terminalProof;

    updateTerminalProof({
      gid,
      currentPage,
      sortParams,
      document: {
        proofUrl: documentUrl,
      },
    });
  };

  const downloadTerminalProof = async () => {
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.GET_TERMINALS}/${gid}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const setMenu = (query) => {
    const parsed = queryString.parse(query);
    if (parsed['status.IN']) {
      const value = parsed['status.IN'];
      switch (value) {
        case 'SUCCEEDED': {
          setCurrent('succeeded');
          break;
        }
        case 'REFUNDED': {
          setCurrent('refund');
          break;
        }
        case 'REQUIRE_CAPTURE': {
          setCurrent('uncaptured');
          break;
        }
        case 'ALL_PAYMENTS': {
          setCurrent('payments');
          break;
        }
        default:
          setCurrent('payments');
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      setMenu(location.search);
    } else {
      history.replace(`${pathname}?status.IN=SUCCEEDED`);
    }
  }, [location]);

  const selectedRecord = (value) => {
    const paymentGid = value.gid;
    history.push({
      pathname: `${PAGES.PAYMENTS}/${paymentGid}`,
      state: location.search,
    });
  };

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '30%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <StatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </StatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '30%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? record.paymentMethod.upi.vpa
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  return (
    <>
      {
        terminalDetails && terminalDetails.terminalDetailsStatus === API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              {
                !isSuperUser && (
                  <Row type="flex" justify="space-between" className="my-2 mb-3">
                    <Col>
                      {!showEdit && (
                        <>
                          <SpText className="mr-4" fontSize="20px">{terminalDetails.deviceName}</SpText>
                          <Tooltip placement="top" title="Edit">
                            <Icon
                              type="edit"
                              className="ml-3"
                              style={{ fontSize: '20px', cursor: 'pointer' }}
                              onClick={() => { setShowEdit(!showEdit); }}
                            />
                          </Tooltip>
                        </>
                      )}
                      {showEdit && (
                        <>
                          <div className="w-85 mt-2 d-flex">
                            <Input
                              placeholder="Name"
                              value={deviceName}
                              onChange={(e) => setDeviceName(e.currentTarget.value)}
                            />
                            <SpButton
                              className="ml-2 pl-2 pr-2"
                              type="primary"
                              borderRadius="none"
                              onClick={updateDeviceName}
                            >
                              <UpdateIcon />
                            </SpButton>
                            <SpButton
                              className="ml-2 pl-2 pr-2"
                              type="secondary"
                              borderRadius="none"
                              style={{ borderColor: '#C4C4C4' }}
                              onClick={() => {
                                setShowEdit(!showEdit);
                                simpleValidator.current.hideMessages();
                                forceUpdate();
                              }}
                            >
                              <CancelIcon />
                            </SpButton>
                          </div>
                          <SpError>
                            {simpleValidator.current.message('name', deviceName, 'required')}
                          </SpError>
                        </>
                      )}
                    </Col>
                    <Col className="align-items-center justify-content-end">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-3"
                        onClick={() => setAddTerminalProof(true)}
                      >
                        {'\u002B'}&nbsp;Add Terminal Proof
                      </SpButton>
                    </Col>
                  </Row>
                )
              }
              {
                isSuperUser && (
                  <Row type="flex" justify="space-between" className="my-2 mb-3">
                    <Col>
                      <SpText className="mr-4" fontSize="20px">{terminalDetails.deviceName}</SpText>
                    </Col>
                  </Row>
                )
              }
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Location</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{terminalDetails.terminalLocation ? terminalDetails.terminalLocation.name : <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Auto Settlement</span>
                  </Col>
                  <Col span={9}>
                    <StatusTag style={{ backgroundColor: getBackground(autoSettlement) }}>
                      {autoSettlement ? 'Enabled' : 'Disabled'}
                    </StatusTag>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Merchant No.</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{terminalDetails.terminalMid ? terminalDetails.terminalMid : <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Terminal No.</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{terminalDetails.terminalTid ? terminalDetails.terminalTid : <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              {
                terminalDetails.proofUrl ? (
                  <>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={12}>
                          <div style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={downloadTerminalProof}
                            >
                              Terminal Proof
                            </SpText>
                          </div>
                        </Col>
                      </Row>
                    </SpForm>
                    <SpForm>
                      <Row type="flex" justify="start">
                        <Col span={12}>
                          <div style={{ cursor: 'pointer' }}>
                            <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR,
                              ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.SUPERUSER]}
                            >
                              <SpButton
                                type="secondary"
                                shape="round"
                                className="mr-3"
                                ghost
                                onClick={() => history.push(PAGES.SUPPORT)}
                              >
                                {'\u002B'}&nbsp;New Service Request
                              </SpButton>
                            </RBAC>
                          </div>
                        </Col>
                        <Col span={12} className="d-flex justify-content-end mt-2">
                          <SpH5 color="#7f7f7f">{gid}</SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                ) : (
                  <SpForm>
                    <Row type="flex" justify="start">
                      <Col span={12}>
                        <div style={{ cursor: 'pointer' }}>
                          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR,
                            ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.SUPERUSER]}
                          >
                            <SpButton
                              type="secondary"
                              shape="round"
                              className="mr-3"
                              ghost
                              onClick={() => history.push(PAGES.SUPPORT)}
                            >
                              {'\u002B'}&nbsp;New Service Request
                            </SpButton>
                          </RBAC>
                        </div>
                      </Col>
                      <Col span={12} className="d-flex justify-content-end mt-2">
                        <SpH5 color="#7f7f7f">{gid}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                )
              }
            </Card>
            {/* ADD TERMINAL PROOF MODAL */}
            {addTerminalProof && (
              <TerminalProof
                visible={addTerminalProof}
                submitting={submitting}
                close={() => setAddTerminalProof(false)}
                submit={uploadTerminalProof}
              />
            )}
            <Card>
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">Terminal Payments</SpText>
                </Col>
              </Row>
              <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
                <Menu.Item key="succeeded">
                  <SpTab
                    to={`${pathname}?status.IN=SUCCEEDED`}
                    selected={current === 'succeeded'}
                  >
                    Succeeded
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="refund">
                  <SpTab
                    to={`${pathname}?status.IN=REFUNDED`}
                    selected={current === 'refund'}
                  >
                    Refunded
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="uncaptured">
                  <SpTab
                    to={`${pathname}?status.IN=REQUIRE_CAPTURE`}
                    selected={current === 'uncaptured'}
                  >
                    Uncaptured
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="payments">
                  <SpTab
                    to={`${pathname}?status.IN=ALL_PAYMENTS`}
                    selected={current === 'payments'}
                  >
                    All Payments
                  </SpTab>
                </Menu.Item>
              </Menu>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={columns}
                rowKey={(record) => record.gid}
                dataSource={payments}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  terminalDetails: state.terminalDetails,
  payments: state.terminalDetails.payments.content ? state.terminalDetails.payments.content : [],
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  isSuperUser: state.user.superUser,
  totalElements: state.terminalDetails.payments.totalElements ? state.terminalDetails.payments.totalElements : null,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTerminalDetails: param => dispatch(terminalDetailsActions.fetchTerminalDetails({
    payload: param,
  })),
  fetchTerminalPayments: param => dispatch(terminalDetailsActions.fetchTerminalPayments({
    payload: param,
  })),
  updateTerminalDetails: param => dispatch(terminalDetailsActions.updateTerminalDetails({
    payload: param,
  })),
  updateTerminalProof: param => dispatch(terminalDetailsActions.updateTerminalProof({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminalDetail);
