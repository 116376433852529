// @flow
import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import Payouts from './component/PayoutList';
import PayoutDetails from './component/PayoutDetails';

const Payout = () => (
  <Switch>
    <Route exact path="/payouts" component={Payouts} />
    <Route exact path="/payouts/:gid" component={PayoutDetails} />
  </Switch>
);

export default Payout;
