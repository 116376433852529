// @flow
import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Row,
  Col,
  Checkbox,
  Divider,
  Switch,
} from 'antd';
import {
  SpText,
} from 'components/PaymentWidgetDesignKit';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  widgetDetails: Object,
  setSampleWidget: Function,
};

const EmploymentInfo = (props: Props, ref) => {
  const {
    widgetDetails,
    setSampleWidget,
  } = props;

  const [, forceUpdate] = useState();
  const [employmentInfo, setEmploymentInfo] = useState();
  const [companyDonation, setCompanyDonation] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());


  useEffect(() => {
    if (widgetDetails && widgetDetails.currencyCode) {
      setEmploymentInfo(widgetDetails.needEmploymentStatus);
      setCompanyDonation(widgetDetails.companyDonation);
    }
  }, [widgetDetails]);

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
  }));

  return (
    <>
      <SpText className="text-uppercase mr-4" fontSize="16px">
        Employment Info
      </SpText>
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '18px' }}>
            <label htmlFor="">
              <Checkbox
                className="mr-2"
                checked={companyDonation}
                onChange={({ target: { checked } }) => {
                  widgetDetails.companyDonation = checked;
                  setSampleWidget(setSampleWidget);
                  setCompanyDonation(checked);
                  if (checked === false) {
                    widgetDetails.needEmploymentStatus = false;
                  }
                }}
              />
              <SpText fontSize="14px">Enable company payment</SpText>
            </label>
          </div>
        </Col>
      </Row>
      {
        (companyDonation === true) && (
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <div style={{ marginTop: '22px' }}>
                <label htmlFor="" className="mr-3">
                  <Switch
                    className="mr-2"
                    checked={employmentInfo}
                    onChange={(value) => {
                      widgetDetails.needEmploymentStatus = value;
                      setSampleWidget(setSampleWidget);
                      setEmploymentInfo(value);
                    }}
                  />
                  <SpText fontSize="14px">Collect employment info</SpText>
                </label>
              </div>
            </Col>
          </Row>
        )
      }
      <Divider style={{ marginTop: '31px' }} />
    </>
  );
};

// $FlowFixMe
export default forwardRef(EmploymentInfo);
