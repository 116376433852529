import { CASH_TRANSACTIONS_DETAILS } from '../actionTypes';

export const fetchCashTransactionDetails = (action) => ({
  type: CASH_TRANSACTIONS_DETAILS.FETCH,
  payload: action.payload,
});

export const notifyCashTransactionsDetails = (action) => ({
  type: CASH_TRANSACTIONS_DETAILS.NOTIFY,
  payload: action.payload,
});

export const voidPayment = (action) => ({
  type: CASH_TRANSACTIONS_DETAILS.VOIDED,
  payload: action.payload,
});

export const refundCashPayment = (action) => ({
  type: CASH_TRANSACTIONS_DETAILS.REFUND,
  payload: action.payload,
});
