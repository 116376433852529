// @flow
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  SpH5,
  SpText,
} from 'components/DesignKit';
import {
  BENE_TYPES,
} from 'appconstants';
import NumberFormat from 'react-number-format';

type Props = {
  isIndia: Boolean,
  contactDetailsData: Object,
  fundingSourceDetailsData: Object,
};

const Review = (props: Props) => {
  const {
    isIndia,
    contactDetailsData,
    fundingSourceDetailsData,
  } = props;
  const countryCode = contactDetailsData && contactDetailsData.phoneNumberCode;
  const accountNumber = (fundingSourceDetailsData && fundingSourceDetailsData.accountNumber);

  const [phoneFormate, setPhoneFormate] = useState('');

  useEffect(() => {
    if (countryCode === '+1') {
      setPhoneFormate('+# (###) ###-####');
    } else if (countryCode.length !== 4 && countryCode === '+91') {
      setPhoneFormate('+##-##### #####');
    } else if (countryCode.length === 2 && countryCode !== '+1') {
      setPhoneFormate('+#-##########');
    } else if (countryCode.length === 3 && countryCode !== '+91') {
      setPhoneFormate('+##-##########');
    } else if (countryCode.length === 4) {
      setPhoneFormate('+###-##########');
    }
  }, [contactDetailsData]);

  return (
    <>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>CONTACT DETAILS</SpH5>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Name</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Phone</SpH5>
          </Col>
          <Col>
            <NumberFormat
              className="numFormate"
              value={`${contactDetailsData.phoneNumberCode}${contactDetailsData.contactPhone}`}
              format={phoneFormate}
              mask="_"
              disabled
              style={{
                fontWeight: '400',
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Email</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.email || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Address Line</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>City</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>postal Code</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>State</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Country</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>ACCOUNT DETAILS</SpH5>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Customer Type</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Account Holder Name</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Account Type</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Account No.</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Routing No.</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Bank Name</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div>
        <Row justify="start">
          <Col>
            <SpH5>Vendor</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
    </>
  );
};

// $FlowFixMe
export default Review;
