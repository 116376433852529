// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Select,
} from 'antd';
import {
  SpText,
} from 'components/DesignKit';

import Loading from 'components/Loading';

import {
  inventoryPreordersActions,
  notificationActions,
  customerSupportActions,
  diningActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
} from 'appconstants';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import getReservation from 'utils/getPreorders';
import getReservationBackground from 'utils/getPreorderBackground';
import getReservationColor from 'utils/getPreorderFontColor'

const { Option } = Select;

type Props = {
  inventoryPreorders: Array<Object>,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  history: {
    push: Function,
  },
  validator: Boolean,
  fetchShops: Function,
  shops: Array<objects>,
  fetchFilterPreorders: Function,
  fetchDiningTable: Function,
};

// $FlowFixMe
const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '75px',
  textAlign: 'center',
});

const PreorderList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    inventoryPreorders,
    loading,
    totalElements,
    test,
    history,
    validator,
    fetchShops,
    shops,
    fetchFilterPreorders,
    fetchDiningTable,
  } = props;

  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [shopsList, setShopsList] = useState([]);
  const [selectedShop, setSelectedShop] = useState({});
  const todayDate = moment.utc(new Date()).tz(selectedAccount.timezone).format('MMM DD, YYYY, hh:mm a');
  const [selectedStatusType, setSelectedStatusType] = useState({});
  const statusTypes = [
    {
      id: 1,
      name: 'All',
      value: 'ALL',
    },
    {
      id: 2,
      name: 'Created',
      value: 'CREATED',
    },
    {
      id: 3,
      name: 'Moved',
      value: 'MOVED',
    },
    {
      id: 4,
      name: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      id: 5,
      name: 'Expired',
      value: 'EXPIRED',
    },
  ];

  const debouncedFetchShops = debounce(fetchShops, 2000, { leading: true });
  const debouncedFetchDiningTable = debounce(fetchDiningTable, 2000, { leading: true });

  useEffect(() => {
    if (shops && shops.length !== 0) {
      setShopsList(shops);
      // setSelectedShop(shops[0]);
      // setSelectedShop('All');
      const payload = {
        shopGid: shops[0].gid,
      };
      debouncedFetchDiningTable(payload);
    }
  }, [shops]);

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = '';
    debouncedFetchShops({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [selectedAccount, test]);

  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      const currentPage = parsed.page || 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const shopName = parsed.shopName || '';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      const status = parsed.status || '';
      const typeStatus = statusTypes.filter((item) => item.value === status);
      if (typeStatus && typeStatus.length !== 0) {
        setSelectedStatusType(typeStatus[0]);
      }
      const shopSelcted = shops.filter((item) => item.name === shopName);
      if (shopSelcted && shopSelcted.length !== 0) {
        setSelectedShop(shopSelcted[0]);
      }
      const filterParams = `?status.EQ=${status}&shopName.EQ=${(shopName)}&page=${currentPage}`;
      setTimeout(() => {
        fetchFilterPreorders({ sortParams, filterParams });
      }, 500);
    } else {
      fetchFilterPreorders();
    }
  }, [location]);

  useEffect(() => {
    if (!validator) {
      const parsed = queryString.parse(location.search);
      const currentPage = parsed.page || 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const shopName = parsed.shopName || '';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      const status = parsed.status || '';
      // eslint-disable-next-line max-len
      const filterParams = `?status.EQ=${status}&shopName.EQ=${(shopName)}&page=${currentPage}`;
      setTimeout(() => {
        fetchFilterPreorders({ sortParams, filterParams });
      }, 500);
    }
  }, [validator]);

  const updateSelectedShop = (shop) => {
    if (shop === 'All') {
      setSelectedShop('All');
    } else {
      (setSelectedShop(shop[1]));
    }
    const parsed = queryString.parse(location.search);
    const currentPage = 1;
    const status = parsed.status || '';
    if (shop === 'All') {
      history.push({
        pathname: PAGES.PRE_ORDERS,
        // eslint-disable-next-line max-len
        search: `?status=${status}&page=${currentPage}`,
      });
    } else {
      history.push({
        pathname: PAGES.PRE_ORDERS,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopName=${encodeURIComponent(shop[1].name)}&page=${currentPage}`,
      });
    }
    setSelectedStatusType({});
    const payload = {
      shopGid: shop[0],
    };
    debouncedFetchDiningTable(payload);
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.page || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [inventoryPreorders]);

  // eslint-disable-next-line no-unused-vars, no-shadow
  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const currentPage = page.current;
    setPage(currentPage);
    const status = parsed.status || '';
    if (selectedShop === 'All') {
      history.push({
        pathname: PAGES.PRE_ORDERS,
        // eslint-disable-next-line max-len
        search: `?&page=${currentPage}`,

      });
    } else if (selectedShop && selectedShop.name) {
      history.push({
        pathname: PAGES.PRE_ORDERS,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopName=${encodeURIComponent(selectedShop.name)}&page=${currentPage}`,
      });
    } else {
      history.push({
        pathname: PAGES.PRE_ORDERS,
        // eslint-disable-next-line max-len
        search: `?status=${status}&page=${currentPage}`,
      });
    }
  };

  const updateStatus = (suportType) => {
    const currentPage = 1;
    const status = suportType[0] !== 'ALL' ? suportType[0] : '';
    const selectShop = (selectedShop.name) || '';
    history.push({
      pathname: PAGES.PRE_ORDERS,
      // eslint-disable-next-line max-len
      search: `?status=${status}&shopName=${encodeURIComponent(selectShop)}&page=${currentPage}`,
    });
    setSelectedStatusType(suportType[1]);
  };

  const columns = [
    {
      title: 'S.NO',
      key: 'sno',
      width: '13%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '13%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {(record && record.customer && record.customer.name) || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'MOBILE NO',
      dataIndex: 'phoneNumber',
      width: '14%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {(record && record.customer && record.customer.phoneNumber) || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ORDER ITEMS',
      dataIndex: 'orderLineItems',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const { preOrderLineItems } = record;
        if (preOrderLineItems && preOrderLineItems.length !== 0) {
          return (
            preOrderLineItems.map((items) => (
              <span>{items && items.itemName},</span>
            ))
          );
        }
        return (
          <span>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'TABLE NO',
      dataIndex: 'table',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {(record && record.shopTableReservation && record.shopTableReservation.shopTable && record.shopTableReservation.shopTable.name)
            || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DATE & TIME',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>N/A</>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <Tooltip placement="top" title="">
          <StatusTag style={{ backgroundColor: getReservationBackground(status) , color:getReservationColor(status),fontWeight:600 }}>
            {getReservation(status)}
          </StatusTag>
        </Tooltip>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row type="flex" justify="space-between" className="my-2 pb-2" align="middle">
        <Col>
          <Select
            className="mr-4 mt-3"
            value={selectedShop.name || 'All'}
            style={{ width: '350px' }}
            bordered
            onChange={(value) => updateSelectedShop(value)}
          >
            <Option key={1} value="All">All</Option>

            {
              shopsList.map((option) => (
                <Option
                  key={option.gid}
                  value={[option.gid, option]}
                >
                  {option.name}
                </Option>
              ))
            }
          </Select>
          <Select
            className="mr-4 mt-3"
            value={selectedStatusType.name || 'Status'}
            style={{ width: 160 }}
            bordered
            showSearch
            onChange={(value) => updateStatus(value)}
          >
            {
              statusTypes.map((option) => (
                <Option
                  key={option.id}
                  value={[option.value, option]}
                >
                  {option.name}
                </Option>
              ))
            }
          </Select>
        </Col>
      </Row>
      <div style={{ border: '1px solid #959595', padding: '10px', borderRadius: '10px' }} className="mb-3">
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <SpText fontSize="22px" fontWeight="600" color="#292929">{selectedShop.name || 'All Shops'}</SpText>
          </Col>
          <Col>
            <SpText fontSize="16px" fontWeight="500" color="#292929">Date: {todayDate}</SpText>
          </Col>
        </Row>
      </div>
      <RBAC
        fallback={(
          <Table
            columns={[...columns]}
            rowKey={(record) => record.gid}
            dataSource={inventoryPreorders}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={
          [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS,
            ROLES.SUPERUSER, ROLES.KDSUSER1, ROLES.KDSADMIN1]
        }
      >
        <Table
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns]}
          rowKey={(record) => record.gid}
          dataSource={inventoryPreorders}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  inventoryPreorders: state.inventoryPreorders.content,
  totalElements: state.inventoryPreorders.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  shops: state.customerSupport.shops,
  diningTable: state.dining.diningTable.content,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryPreorders: param => dispatch(inventoryPreordersActions.fetchInventoryPreorders({
    payload: param,
  })),
  fetchFilterPreorders: param => dispatch(inventoryPreordersActions.fetchFilterPreorders({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchShops: param => dispatch(customerSupportActions.fetchShops({
    payload: param,
  })),
  fetchDiningTable: (param) => dispatch(diningActions.fetchDiningTable({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PreorderList);
