import { SCHEDULE_REPORTS } from '../actionTypes';

const initialState = {
  scheduleReportsStatus: undefined,
  scheduleReportsError: undefined,
  content: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_REPORTS.FETCH:
      return {
        ...state,
        scheduleReportsStatus: 'STARTED',
      };
    case SCHEDULE_REPORTS.FAILED:
      return {
        ...state,
        scheduleReportsStatus: 'FAILED',
      };
    case SCHEDULE_REPORTS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
