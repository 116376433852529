export default status => {
  if (status === 'ACTIVE') {
    return '#ffe0b1';
  } if (status === 'DEACTIVATED') {
    return '#e2e2e2';
  } if (status === 'DELETED') {
    return '#e2e2e2';
  } if (status === 'FAILED') {
    return '#e2e2e2';
  }
  return '#ff858d';
};
