/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
    notificationActions,
    inventoryShopsDetailsActions,
} from 'store/actions';
import {
    Input,
    Row,
    Col,
    Radio,
    Steps,
    Modal,
} from 'antd';
import {
    axiosAuthInstance,
    API_END_POINTS,
} from 'api';
import formatNumber from 'utils/formatNumber';
import {
    SpText,
    SpH5,
    SpButton,
    SpError,
} from 'components/DesignKit';
import Minus from 'imgs/Minus.png';
import Pluss from 'imgs/Pluss.png';
import Loading from 'components/Loading';

const { Step } = Steps;
const { TextArea } = Input;

type Props = {
    visible: boolean,
    close: Function,
    submit: Function,
    submitting: boolean,
    shopGid: string,
    fetchItemNameByShops: Function,
    selectedAccount: Object,
    shopsItemsList: Array,
};

const AddItem = (props: Props) => {
    const {
        visible,
        close,
        shopGid,
        submit,
        submitting,
        selectedAccount,
        shopsItemsList,
        fetchItemNameByShops,
    } = props;
    const [current, setCurrent] = useState(0);
    const [name, setName] = useState('');
    const [count, setCount] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemInfo, setSelectedItemInfo] = useState([]);
    const currencyType = selectedAccount && selectedAccount.currency;
    const [splitUpInfo, setSplitUpInfo] = useState(null);
    const [error, setError] = useState('');
    const [modiferData, setModiferData] = useState([]);
    const [instructions, setInstructions] = useState('');

    useEffect(() => {
        if (name) {
            fetchItemNameByShops({ gid: shopGid, name });
        } else {
            fetchItemNameByShops({ gid: shopGid });
        }
    }, [name]);

    const handleRadioChange = (e) => {
        setSelectedItem(e.target.value);
        setError('');
        setCount(0);
    };

    const decreaseQuantity = () => {
        if (count > 1) {
            setCount(count - 1);
            const findSelectedItem = shopsItemsList.find(info => info.gid === selectedItem);
            findSelectedItem.quantity = findSelectedItem.quantity ? findSelectedItem.quantity - 1 : 0;
            findSelectedItem.itemGid = selectedItem;
            setSelectedItemInfo([findSelectedItem]);
            getSplitUpInfo([findSelectedItem]);

        }
    };

    const increaseQuantity = () => {
        setCount(count + 1);
        const findSelectedItem = shopsItemsList.find(info => info.gid === selectedItem);
        findSelectedItem.quantity = findSelectedItem.quantity ? findSelectedItem.quantity + 1 : 0 + 1;
        findSelectedItem.itemGid = selectedItem;
        setSelectedItemInfo([findSelectedItem]);
        getSplitUpInfo([findSelectedItem]);
        setError('');
    };
    const handleSearchChange = (e) => {
        const term = e.target.value;
        setName(term);
    };

    const decreaseModQuantity = (modifierIndex) => {
        const updatedModifiers = [...modiferData];
        if (updatedModifiers[0].lineItemModifiers[modifierIndex].quantity > 0) {
            updatedModifiers[0].lineItemModifiers[modifierIndex].quantity--;
            updatedModifiers[0].count = updatedModifiers[0].count ? updatedModifiers[0].count - 1 : 1;
            setModiferData(updatedModifiers);
            getSplitUpInfo(updatedModifiers);
        }
    };

    const increaseModQuantity = (modifierIndex) => {
        const updatedModifiers = [...modiferData];
        updatedModifiers[0].lineItemModifiers[modifierIndex].quantity++;
        updatedModifiers[0].count = updatedModifiers[0].count ? updatedModifiers[0].count + 1 : 1;
        setModiferData(updatedModifiers);
        getSplitUpInfo(updatedModifiers);
    };


    const getSplitUpInfo = async (itemsInfos) => {
        const params = {
            shopGid: shopGid,
            items: itemsInfos,
        }
        try {
            const { data: { entity } } = await axiosAuthInstance.post(API_END_POINTS.GET_SPLIT_UP, params);
            setSplitUpInfo(entity);
        } catch (e) {
            console.error(e);
        }
    };

    const next = () => {
        if (current === 0) {
            if (selectedItem === null) {
                setError('Please choose at least one item');
                return
            } if (count === 0) {
                setError('Please select the quantity');
                return
            }
            if (selectedItem && count !== 0) {
                const outputData = selectedItemInfo.map(item => ({
                    quantity: item.quantity,
                    itemGid: item.itemGid,
                    maxAllowed: selectedItemInfo[0] && selectedItemInfo[0].modifierGroups && selectedItemInfo[0].modifierGroups[0] && selectedItemInfo[0].modifierGroups[0].length !== 0 ? selectedItemInfo[0].modifierGroups[0].maxAllowed : null,
                    minRequired: selectedItemInfo[0] && selectedItemInfo[0].modifierGroups && selectedItemInfo[0].modifierGroups[0] && selectedItemInfo[0].modifierGroups[0].length !== 0 ? selectedItemInfo[0].modifierGroups[0].minRequired : null,
                    lineItemModifiers: item.modifierGroups && item.modifierGroups.length !== 0 && item.modifierGroups.flatMap(group => group.modifiers.map(modifier => ({
                        gid: modifier.gid,
                        modifierGid: modifier.gid,
                        name: modifier.name,
                        alternateName: modifier.alternateName,
                        price: modifier.price,
                        available: modifier.available,
                        maxStackableQuantity: modifier.maxStackableQuantity,
                        modifierGroup: modifier.modifierGroup,
                        quantity: 0,
                    })))
                }));
                for (let i = 0; i < outputData.length; i += 1) {
                    if (!outputData[0].lineItemModifiers) {
                        delete outputData[0].lineItemModifiers;
                    }
                }
                setModiferData(outputData);
                setCurrent(current + 1);
            }
        }
        if (current === 2) {
            submit({
                modiferData,
                shopGid,
            });
        }
        else setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: 'Choose Item',
        },
        {
            title: 'Choose Modifer',
        },
        {
            title: 'Confirm',
        },
    ];

    const getInfo = (data) => {
        if (data.minRequired && data.maxAllowed) {
            return `(choose ${data.minRequired} max ${data.maxAllowed})`
        }
        if (!data.minRequired && data.maxAllowed) {
            return `(max ${data.maxAllowed})`
        }
        if (data.minRequired && !data.maxAllowed) {
            return `(choose ${data.minRequired})`
        }
        return ''
    }

    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            width="800px"
            footer={current === 0
                ? [
                    <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
                    <SpButton key="2" onClick={next}>Next</SpButton>,
                ]
                : current === 1
                    ? [
                        <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
                        <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
                        <SpButton key="2" onClick={next}>Next</SpButton>,
                    ]
                    : [
                        <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
                        <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
                        <SpButton key="2" onClick={next}>Place Order</SpButton>,
                    ]}
            title="Add Item to Existing Order"
        >
            <div style={{ marginTop: '-8px' }}>
                <Steps current={current} className="subscriptions">
                    {steps.map((item) => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <div>
                    {
                        (current === 0) && (
                            <div className="px-4">
                                <div className={submitting ? 'OVERLAY' : ''} />
                                <div className="mt-4 mb-3">
                                    <Input
                                        placeholder="Search"
                                        onChange={handleSearchChange}
                                        value={name}
                                    />
                                </div>
                                <Radio.Group
                                    style={{ width: '100%' }}
                                    onChange={handleRadioChange}
                                    value={selectedItem}>
                                    <div
                                        style={{
                                            background: '#EBEBEB',
                                            padding: '12px',
                                            marginTop: '10px',
                                            borderRadius: '10px 10px 0px 0px',
                                        }}
                                    >
                                        <Row type="flex" align="middle">
                                            <Col span={24}>
                                                <Row type="flex">
                                                    <Col span={3} className="mr-4" />
                                                    <Col span={4} className="mr-4">
                                                        <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                            ITEM
                                                        </SpText>
                                                    </Col>
                                                    <Col span={6} className="mr-4">
                                                        <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                            DESCRIPTION
                                                        </SpText>
                                                    </Col>
                                                    <Col span={3} className="mr-4">
                                                        <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                            QTY
                                                        </SpText>
                                                    </Col>
                                                    <Col span={4}>
                                                        <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                            PRICE
                                                        </SpText>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col />
                                        </Row>
                                    </div>
                                    <div
                                        style={{
                                            background: '#FFFFFF',
                                            padding: '12px',
                                            borderRadius: '0px 0px 10px 10px',
                                            maxHeight: '350px',
                                            minHeight: '100px',
                                            overflowY: 'scroll',
                                            overflowX: 'hidden',
                                        }}
                                    >
                                        <Row type="flex" align="middle">
                                            <Col span={24}>
                                                {
                                                    (shopsItemsList && shopsItemsList.length === 0) ? <Loading /> : (
                                                        <>
                                                            {(shopsItemsList && shopsItemsList.length != 0) && shopsItemsList.map((item, index) => (
                                                                <Row type="flex" className="mb-4" key={index}>
                                                                    <Col span={3} className="ml-4">
                                                                        <Radio value={item.gid} />
                                                                    </Col>
                                                                    <Col span={4} className="mr-4">
                                                                        <span>{item.name || <>&#8211;</>}</span>
                                                                    </Col>
                                                                    <Col span={6} className="mr-4">
                                                                        <span>{item.description || <>&#8211;</>}</span>
                                                                    </Col>
                                                                    <Col span={3} className="mr-4">
                                                                        <span>{item.quantity || <>&#8211;</>}</span>
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        <span>
                                                                            {item.price < 0
                                                                                ? `(${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                                                                                    (item.price / 100).toFixed(2), currencyType,
                                                                                )})`
                                                                                : item.price
                                                                                    ? `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber((item.price / 100).toFixed(2), currencyType)}`
                                                                                    : <>&#8211;</>}
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        </>
                                                    )
                                                }

                                            </Col>
                                            <Col />
                                        </Row>
                                    </div>
                                </Radio.Group>
                                <div
                                    className="mt-4"
                                    style={{
                                        border: '1px solid #C7C7C7',
                                        padding: '6px 12px 6px 12px',
                                        borderRadius: '6px',
                                        backGround: '#fff',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        Qty
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div style={{ cursor: 'pointer', marginRight: '10px' }} onClick={decreaseQuantity}>
                                            <img src={Minus} alt="cancel" />
                                        </div>
                                        <div style={{ marginRight: '10px' }}><SpH5>{count}</SpH5></div>
                                        <div style={{ cursor: 'pointer' }} onClick={increaseQuantity}>
                                            <img src={Pluss} alt="cancel" />
                                        </div>

                                    </div>
                                </div>
                                {
                                    error && (
                                        <SpError>
                                            {error}
                                        </SpError>
                                    )
                                }
                            </div >
                        )
                    }
                    {
                        (current === 1) && (
                            <div className="px-4">
                                <div className={submitting ? 'OVERLAY' : ''} />
                                <div
                                    className="mt-4"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <SpText fontWeight="600" fontSize="16px" color="#333333">
                                        {
                                            selectedItemInfo && selectedItemInfo[0].name
                                        }
                                    </SpText>
                                </div>
                                <div
                                    style={{
                                        background: '#EBEBEB',
                                        padding: '12px',
                                        marginTop: '10px',
                                        borderRadius: '10px 10px 0px 0px',
                                    }}
                                >
                                    <Row type="flex" align="middle">
                                        <Col span={20}>
                                            <Row type="flex">
                                                <Col span={6} className="mr-4">
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        ITEM
                                                    </SpText>
                                                </Col>
                                                <Col span={6} className="mr-4">
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        DESCRIPTION
                                                    </SpText>
                                                </Col>
                                                <Col span={4} className="mr-4">
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        QTY
                                                    </SpText>
                                                </Col>
                                                <Col span={4}>
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        PRICE
                                                    </SpText>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col />
                                    </Row>
                                </div>
                                <div
                                    style={{
                                        background: '#FFFFFF',
                                        padding: '12px',
                                        borderRadius: '0px 0px 10px 10px',
                                    }}
                                >
                                    <Row type="flex" align="middle">
                                        <Col span={20}>
                                            <Row type="flex">
                                                <Col span={6} className="mr-4">
                                                    <span>{(selectedItemInfo && selectedItemInfo[0].name) || <>&#8211;</>}</span>
                                                </Col>
                                                <Col span={6} className="mr-4">
                                                    <span>{(selectedItemInfo && selectedItemInfo[0].description) || <>&#8211;</>}</span>
                                                </Col>
                                                <Col span={4} className="mr-4">
                                                    <span>{(selectedItemInfo && selectedItemInfo[0].quantity) || <>&#8211;</>}</span>
                                                </Col>
                                                <Col span={4}>
                                                    <span>
                                                        {selectedItemInfo[0].price < 0
                                                            ? `(${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                                                                ((selectedItemInfo[0].price / 100) * (selectedItemInfo && selectedItemInfo[0].quantity)).toFixed(2), currencyType,
                                                            )})`
                                                            : selectedItemInfo[0].price
                                                                ? `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(((selectedItemInfo[0].price / 100) * (selectedItemInfo && selectedItemInfo[0].quantity)).toFixed(2), currencyType)}`
                                                                : <>&#8211;</>}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col />
                                    </Row>
                                </div>
                                <div
                                    className="mt-4"
                                >
                                    <SpText fontWeight="600" fontSize="16px" color="#333333">
                                        Addons {selectedItemInfo && selectedItemInfo[0].modifierGroups && selectedItemInfo[0].modifierGroups[0] && selectedItemInfo[0].modifierGroups[0].length !== 0
                                            ? getInfo(selectedItemInfo[0].modifierGroups[0])
                                            : ''}
                                    </SpText>
                                </div>
                                <div
                                    style={{
                                        background: '#EBEBEB',
                                        padding: '12px',
                                        marginTop: '10px',
                                        borderRadius: '10px 10px 0px 0px',
                                    }}
                                >
                                    <Row type="flex">
                                        <Col span={20}>
                                            <Row type="flex" justify="space-between">
                                                <Col className="mr-4">
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        ITEM
                                                    </SpText>
                                                </Col>
                                                <Col>
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        QTY
                                                    </SpText>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col />
                                    </Row>
                                </div>
                                <div
                                    style={{
                                        background: '#FFFFFF',
                                        padding: '12px',
                                        borderRadius: '0px 0px 10px 10px',
                                    }}
                                >
                                    <Row type="flex" align="middle">
                                        <Col span={20}>
                                            {(modiferData && modiferData.length != 0) && (modiferData[0].lineItemModifiers && modiferData[0].lineItemModifiers.length !== 0) && modiferData[0].lineItemModifiers.map((item, index) => (
                                                <Row type="flex" justify="space-between" key={index}>
                                                    <Col className="mb-4 mr-4">
                                                        <span>{item.name || <>&#8211;</>}</span>
                                                    </Col>
                                                    <Col>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <div
                                                                style={{ cursor: 'pointer', marginRight: '10px' }}
                                                                onClick={() => {
                                                                    decreaseModQuantity(index);
                                                                }}>
                                                                <img src={Minus} alt="cancel" />
                                                            </div>
                                                            <div style={{ marginRight: '10px' }}><SpH5>{item.quantity}</SpH5></div>
                                                            {
                                                                item.maxStackableQuantity !== item.quantity && modiferData[0].maxAllowed !== modiferData[0].count ? (
                                                                    <>

                                                                        {
                                                                            item.available ? (
                                                                                <div
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        increaseModQuantity(index);
                                                                                    }}>
                                                                                    <img src={Pluss} alt="cancel" />
                                                                                </div>) : (
                                                                                <div
                                                                                    style={{ cursor: 'pointer' }}>
                                                                                    <img src={Pluss} alt="cancel" />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </>
                                                                ) : ''
                                                            }


                                                        </div>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Col>
                                        <Col />
                                    </Row>
                                </div>
                                <div
                                    className="mt-4 mb-2"
                                >
                                    <SpText fontWeight="600" fontSize="16px" color="#333333">
                                        Special Instruction (optional)
                                    </SpText>
                                </div>
                                <div>
                                    <TextArea
                                        className="resize"
                                        rows={3}
                                        placeholder="Your requests are important but not all may be fulfilled."
                                        value={instructions}
                                        onChange={(e) => setInstructions(e.target.value)}
                                    />
                                </div>
                                <Row type="flex" justify="space-between" className="mt-4 pb-4">
                                    <Col className="mr-4">
                                        <SpText fontWeight="600" fontSize="14px" color="#333333">
                                            Total Item Cost
                                        </SpText>
                                    </Col>
                                    <Col className="pr-4">
                                        <SpText fontWeight="600" fontSize="14px" color="#333333">
                                            $ {((splitUpInfo ? splitUpInfo.subTotal : selectedItem.price * (selectedItemInfo && selectedItemInfo[0].quantity)) / 100).toFixed(2)}
                                        </SpText>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                    {
                        (current === 2) && (
                            <div className="px-4">
                                <div className={submitting ? 'OVERLAY' : ''} />
                                <div
                                    className="mt-4"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <SpText fontWeight="600" fontSize="16px" color="#333333">
                                        {
                                            selectedItemInfo && selectedItemInfo[0].name
                                        }
                                    </SpText>
                                </div>
                                <div
                                    style={{
                                        background: '#EBEBEB',
                                        padding: '12px',
                                        marginTop: '10px',
                                        borderRadius: '10px 10px 0px 0px',
                                    }}
                                >
                                    <Row type="flex" align="middle">
                                        <Col span={20}>
                                            <Row type="flex">
                                                <Col span={6} className="mr-4">
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        ITEM
                                                    </SpText>
                                                </Col>
                                                <Col span={6} className="mr-4">
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        DESCRIPTION
                                                    </SpText>
                                                </Col>
                                                <Col span={4} className="mr-4">
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        QTY
                                                    </SpText>
                                                </Col>
                                                <Col span={4}>
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        PRICE
                                                    </SpText>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col />
                                    </Row>
                                </div>
                                <div
                                    style={{
                                        background: '#FFFFFF',
                                        padding: '12px',
                                        borderRadius: '0px 0px 10px 10px',
                                    }}
                                >
                                    <Row type="flex" align="middle">
                                        <Col span={20}>
                                            <Row type="flex">
                                                <Col span={6} className="mr-4">
                                                    <span>{(selectedItemInfo && selectedItemInfo[0].name) || <>&#8211;</>}</span>
                                                </Col>
                                                <Col span={6} className="mr-4">
                                                    <span>{(selectedItemInfo && selectedItemInfo[0].description) || <>&#8211;</>}</span>
                                                </Col>
                                                <Col span={4} className="mr-4">
                                                    <span>{(selectedItemInfo && selectedItemInfo[0].quantity) || <>&#8211;</>}</span>
                                                </Col>
                                                <Col span={4}>
                                                    <span>
                                                        {selectedItemInfo[0].price < 0
                                                            ? `(${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                                                                ((selectedItemInfo[0].price / 100) * (selectedItemInfo && selectedItemInfo[0].quantity)).toFixed(2), currencyType,
                                                            )})`
                                                            : selectedItemInfo[0].price
                                                                ? `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(((selectedItemInfo[0].price / 100) * (selectedItemInfo && selectedItemInfo[0].quantity)).toFixed(2), currencyType)}`
                                                                : <>&#8211;</>}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col />
                                    </Row>
                                </div>
                                <div
                                    className="mt-4"
                                >
                                    <SpText fontWeight="600" fontSize="16px" color="#333333">
                                        Addons
                                    </SpText>
                                </div>
                                <div
                                    style={{
                                        background: '#EBEBEB',
                                        padding: '12px',
                                        marginTop: '10px',
                                        borderRadius: '10px 10px 0px 0px',
                                    }}
                                >
                                    <Row type="flex">
                                        <Col span={20}>
                                            <Row type="flex" justify="space-between">
                                                <Col className="mr-4">
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        ITEM
                                                    </SpText>
                                                </Col>
                                                <Col>
                                                    <SpText fontWeight="600" fontSize="14px" color="#333333">
                                                        QTY
                                                    </SpText>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col />
                                    </Row>
                                </div>
                                <div
                                    style={{
                                        background: '#FFFFFF',
                                        padding: '12px',
                                        borderRadius: '0px 0px 10px 10px',
                                    }}
                                >
                                    <Row type="flex" align="middle">
                                        <Col span={20}>
                                            {(modiferData && modiferData.length != 0) && (modiferData[0].lineItemModifiers && modiferData[0].lineItemModifiers.length !== 0) && modiferData[0].lineItemModifiers.map((item, index) => (
                                                <Row type="flex" justify="space-between" key={index}>
                                                    <Col className="mb-4 mr-4">
                                                        <span>{item.name || <>&#8211;</>}</span>
                                                    </Col>
                                                    <Col>
                                                        <SpH5>{item.quantity}</SpH5>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Col>
                                        <Col />
                                    </Row>
                                </div>
                                <div
                                    className="mt-4 mb-2"
                                >
                                    <SpText fontWeight="600" fontSize="16px" color="#333333">
                                        Special Instruction (optional)
                                    </SpText>
                                </div>
                                <div>
                                    <SpH5>{instructions || ''}</SpH5>
                                </div>
                                <Row type="flex" justify="space-between" className="mt-4 pb-4">
                                    <Col className="mr-4">
                                        <SpText fontWeight="600" fontSize="14px" color="#333333">
                                            Total Item Cost
                                        </SpText>
                                    </Col>
                                    <Col className="pr-4">
                                        <SpText fontWeight="600" fontSize="14px" color="#333333">
                                            $ {((splitUpInfo ? splitUpInfo.subTotal : (selectedItem.price * (selectedItemInfo && selectedItemInfo[0].quantity))) / 100).toFixed(2)}
                                        </SpText>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </div>

            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    selectedAccount: state.account.selectedAccount,
    inventoryOrderDetails: state.inventoryOrderDetails,
    shopsItemsList: state.inventoryShopsDetails.shopsItemNamesList,
    validator: state.loading.validator,
    loading: state.loading.loading,
});

const mapDispatchToProps = (dispatch) => ({
    setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
        type,
        payload,
    })),
    fetchItemNameByShops: param => dispatch(inventoryShopsDetailsActions.fetchItemNameByShops({
        payload: param,
    })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddItem);