import { CLOVER_SYNC } from '../actionTypes';

export const fetchCloverSync = (action) => ({
  type: CLOVER_SYNC.FETCH,
  payload: action.payload,
});

export const addCloverSync = (action) => ({
  type: CLOVER_SYNC.ADD,
  payload: action.payload,
});

export const updateCloverSync = (action) => ({
  type: CLOVER_SYNC.UPDATE,
  payload: action.payload,
});
