import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import PaymentsLinkList from './components/PaymentsLinkList';
import PaymentsLinkDetails from './components/PaymentsLinkDetails';
import PaymentsButtonList from '../PayamentsButton/PaymentsButtonList';
import PaymentsButtonDetails from '../PayamentsButton/PaymentsButtonDetails';
import SubscriptionButtonList from '../SubscriptionButton/SubscriptionButtonList';
import SubscriptionButtonDetails from '../SubscriptionButton/SubscriptionButtonDetails';
import PaymentWidgetsList from '../PaymentWidgets/PaymentWidgetsList';
import PaymentWidgetDetails from '../PaymentWidgets/PaymentWidgetsDetails';

const PaymentsLink = () => (
  <Switch>
    <Route exact path="/payment-links" component={PaymentsLinkList} />
    <Route exact path="/payment-links/:gid" component={PaymentsLinkDetails} />
    <Route exact path="/payment-buttons" component={PaymentsButtonList} />
    <Route exact path="/payment-buttons/:gid" component={PaymentsButtonDetails} />
    <Route exact path="/subscription-button" component={SubscriptionButtonList} />
    <Route exact path="/subscription-button/:gid" component={SubscriptionButtonDetails} />
    <Route exact path="/payment-widgets" component={PaymentWidgetsList} />
    <Route exact path="/payment-widgets/:gid" component={PaymentWidgetDetails} />
  </Switch>
);

export default PaymentsLink;
