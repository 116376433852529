// @flow
import React, { useEffect, useState } from 'react';
import {
  Icon,
} from 'antd';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import moment from 'moment-timezone';
import bookWithButton from '../../../imgs/Book With_Button_Rounded.svg';

type Props = {
  pageData: Object,
};

const ReviewSeatedPage = (props: Props) => {
  const {
    pageData,
  } = props;

  const [mobileView, setMobileView] = useState(false);
  const [mapDisplay, setMapDisplay] = useState(false);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 480) {
      setMobileView(true);
    }
  }, []);

  const toggleMap = () => {
    setMapDisplay(!mapDisplay);
  };

  const getOperatingTime = (time) => {
    if (time !== 0) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours > 12) {
        const hr = hours - 12;
        return `${hr}:${min} PM`;
      }
      if (hours === 0) {
        return `${12}:${min} AM`;
      }
      return `${hours}:${min} AM`;
    }
    return '12:00 AM';
  };

  const getDuration = (data) => {
    if (data) {
      const diff = data.endTime - data.startTime;
      const hours = Math.floor(diff / 60);
      const minutes = diff % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours === 24) {
        const hr = hours - 12;
        return `${hr}:${min}`;
      }
      if (hours === 0) {
        return `00:${min}`;
      }
      return `${hours}:${min}`;
    }
    return '12:00';
  };

  return (
    <div className="ml-4 mr-4">
      <div
        className="mt-4 mb-4"
        style={{
          justifyContent: 'start',
        }}
      >
        <p style={{ fontSize: '18px' }}>{pageData && moment.utc(pageData.eventDate).format('MMM DD, YYYY')}</p>
      </div>
      <div
        className="mt-4 mb-4"
        style={{
          justifyContent: 'start',
        }}
      >
        <p style={{ fontSize: '24px', fontWeight: '700' }}>{pageData && pageData.title}</p>
      </div>
      <div
        className="mt-4 mb-4"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={pageData && pageData.logo} style={{ width: '300px', objectFit: 'cover' }} alt="banner" />
      </div>
      <div
        className="mt-4 mb-4"
        style={{
          justifyContent: 'start',
        }}
      >
        <p style={{ fontSize: '20px', fontWeight: '600' }}>When and where</p>
      </div>
      <div
        className="mb-4"
        style={{
          display: mobileView ? '' : 'flex',
        }}
      >
        <div
          className="mb-4"
          style={{
            display: 'flex',
            width: mobileView ? '100%' : pageData && pageData.venueAddress ? '30%' : '50%',
            justifyContent: 'start',
          }}
        >
          <Icon type="calendar" style={{ marginRight: '10px', fontSize: '20px', color: '#3f77e7' }} />
          <div className="mr-2">
            <p className="mb-2" style={{ fontSize: '18px', fontWeight: '600' }}>Date and time</p>
            {
              pageData && pageData.endTime ? (
                <p style={{ fontSize: '16px' }}>
                  {pageData && moment.utc(pageData.eventDate).format('MMM DD, YYYY')},
                  {getOperatingTime(pageData && pageData.startTime)} - {getOperatingTime(pageData && pageData.endTime)}
                </p>
              ) : (
                <p style={{ fontSize: '16px' }}>
                  {pageData && moment.utc(pageData.eventDate).format('MMM DD, YYYY')},
                  {getOperatingTime(pageData && pageData.startTime)}
                </p>
              )
            }
          </div>

        </div>
        <div
          className="mb-4"
          style={{
            display: 'flex',
            width: mobileView ? '100%' : '30%',
            justifyContent: 'start',
          }}
        >
          {
            pageData && pageData.venueAddress && (
              <>
                <Icon type="pushpin" style={{ marginRight: '10px', fontSize: '20px', color: '#3f77e7' }} />
                <div className="mr-2">
                  <p className="mb-2" style={{ fontSize: '18px', fontWeight: '600' }}>Location</p>
                  <p style={{ fontSize: '16px' }}>{pageData && pageData.venueAddress}</p>
                  <p className="mt-2" style={{ fontSize: '16px' }}>
                    <span style={{ color: '#3f77e7', cursor: 'pointer' }} onClick={toggleMap}>{!mapDisplay ? 'Show map...' : 'Hide map...'}</span>
                  </p>
                </div>
              </>
            )
          }
        </div>
        <div
          className="mb-4"
          style={{
            width: mobileView ? '100%' : '30%',
            justifyContent: 'start',
          }}
        >
          <button
            className="border-0 mb-5"
            style={{ background: 'transparent' }}
            type="submit"
            disabled
          ><img src={bookWithButton} alt="payButton" height="60" />
          </button>

        </div>
      </div>


      <div
        className="mb-4"
        id="location-info"
        style={{
          justifyContent: 'start',
          height: '400px',
          position: 'relative',
          display: mapDisplay ? '' : 'none',
        }}
      >
        <Map
          google={props.google}
          zoom={14}
          style={{ height: '350px' }}
        >
          <Marker
            position={{ lat: pageData.coordinate && pageData.coordinate.x, lng: pageData.coordinate && pageData.coordinate.y }}
          />
        </Map>
      </div>


      <div
        className="mt-4 mb-4"
        style={{
          justifyContent: 'start',
        }}
      >
        <p style={{ fontSize: '20px', fontWeight: '600' }}>Refund Policy</p>
      </div>
      <div
        className="mb-4"
        style={{
          justifyContent: 'start',
        }}
      >
        <p style={{ fontSize: '16px' }}>No Refunds</p>
      </div>

      <div
        className="mt-4 mb-4"
        style={{
          justifyContent: 'start',
        }}
      >
        <p style={{ fontSize: '20px', fontWeight: '600' }}>About this event</p>
      </div>
      <div
        className="mb-4"
        style={{
          display: mobileView ? '' : 'flex',
        }}
      >
        <div
          className="mb-4"
          style={{
            display: 'flex',
            width: mobileView ? '100%' : '30%',
            justifyContent: 'start',
          }}
        >
          {
            pageData && pageData.endTime ? (
              <>
                <Icon type="clock-circle-o" style={{ marginRight: '10px', fontSize: '20px', color: '#3f77e7' }} />
                <div className="mr-2">
                  <p style={{ fontSize: '18px' }}>{getDuration(pageData)} hours</p>
                </div>
              </>
            ) : (
              <div className="mr-2">
                <p className="mt-1" style={{ fontSize: '18px' }}>&nbsp;</p>
              </div>
            )
          }
        </div>
        <div
          className="mb-4"
          style={{
            display: 'flex',
            width: mobileView ? '100%' : '30%',
            justifyContent: 'start',
          }}
        >
          <Icon type="scan" style={{ marginRight: '10px', fontSize: '20px', color: '#3f77e7' }} />
          <div className="mr-2">
            <p style={{ fontSize: '18px' }}>Mobile Tickets</p>
          </div>

        </div>
      </div>
      <div
        className="mb-4"
        style={{
          justifyContent: 'start',
          fontFamily: 'sans-serif',
        }}
      >
        <p className="mt-2" style={{ fontSize: '18px', wordBreak: 'break-word', lineHeight: '1.5em' }}>
          <div dangerouslySetInnerHTML={{ __html: pageData.content }} />
        </p>
      </div>
    </div>
  );
};

// $FlowFixMe
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds',
})(ReviewSeatedPage);
