export default (customers) => {
  let sortedCustomers;
  if (customers) {
    sortedCustomers = customers && customers.sort((a, b) => {
      const firstName = a.name.toUpperCase();
      const lastName = b.name.toUpperCase();
      if (firstName < lastName) {
        return -1;
      }
      if (firstName > lastName) {
        return 1;
      }
      return 0;
    });
  }
  return sortedCustomers;
};
