import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  INVENTORY_RESERVATIONS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryReservations = async (payload) => {
  if (payload === 'All') {
    return axiosAuthInstance.get(`${API_END_POINTS.SHOP_RESERVATION}?sortBy=createdAt&direction=DESC`);
  }

  const queryTemp = payload.split('-');
  if (payload && queryTemp[0] === 'shop') {
    const query = `?shopGid.EQ=${payload}&sortBy=createdAt&direction=DESC`;
    return axiosAuthInstance.get(`${API_END_POINTS.SHOP_RESERVATION}${query}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.SHOP_RESERVATION}?sortBy=createdAt&direction=DESC`);
};

const fetchFilterReservations = async (payload) => {
  if (payload) {
    const { filterParams } = payload;
    const { sortParams } = payload;
    return axiosAuthInstance.get(`${API_END_POINTS.SHOP_RESERVATION}${filterParams}${sortParams}`);
  }
  const query = '?page=1&size=10&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.SHOP_RESERVATION}${query}`);
};

const deleteSelectedReservation = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.SHOP_RESERVATION}/${gid}`, params);

const holdReservations = async ({ gid }) => axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ORDER}/${gid}/hold?hold=false`);

export function* getInventoryReservations(action) {
  try {
    const { data: { entity } } = yield call(fetchInventoryReservations, action.payload);
    yield put({ type: INVENTORY_RESERVATIONS.SUCCESS, payload: entity });
    yield put({ type: INVENTORY_RESERVATIONS.RESERVATIONS_FILTER_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_RESERVATIONS.FAILED, payload: e.response.data });
  }
}

export function* getFilterReservations(action) {
  try {
    const { data: { entity } } = yield call(fetchFilterReservations, action.payload);
    yield put({ type: INVENTORY_RESERVATIONS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_RESERVATIONS.FAILED, payload: e.response.data });
  }
}

export function* deleteInventoryReservations(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteSelectedReservation, action.payload);
    const { data: { entity } } = yield call(fetchFilterReservations);
    yield put({ type: INVENTORY_RESERVATIONS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: action.payload && action.payload.params && action.payload.params.status === 'CANCELED'
        ? 'Reservation cancelled'
        : 'Table status changed successfully!',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getHoldReservations(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(holdReservations, action.payload);
    yield put({ type: INVENTORY_RESERVATIONS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Reservation status changed successfully!',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_RESERVATIONS.FAILED, payload: e.response.data });
  }
}
