// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpButton, SpH5 } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  ratesDetails: Object,
};

const DeleteSelectedRate = (props: Props) => {
  const {
    visible,
    close,
    submit,
    ratesDetails,
  } = props;

  const deleteSelectedRate = () => {
    submit(ratesDetails && ratesDetails.gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={deleteSelectedRate} type="danger">Delete</SpButton>,
      ]}
      title="Delete Rate"
    >
      <SpH5 className="ml-4">
        Are you sure you want to delete this Rate?
      </SpH5>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteSelectedRate;
