import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import NumberFormat from 'react-number-format';
import { AmountDivideByHundred } from '../../../helper';
import { inputHeight, normalTextFontSize, smallTextFontSize } from '../../../utils/reseponsive';
import {
  InputWrapper, LabelText, SpanText, Text,
} from '../../../design';

type Props = {
  label: string,
  description: string,
  minAmount: number,
  maxAmount: number,
  currencyPrefix: string,
  control: any,
  errors: any,
  name: string,
  isRequired: boolean,
  isPreview: boolean,
  isAdaptive: boolean
}

const InputAdjustableAmount = (props: Props) => {
  const {
    label, description, minAmount, maxAmount, control, errors, name, isRequired, currencyPrefix, isPreview, isAdaptive,
  } = props;
  const [minimumAmount, setMinimumQuantity] = useState(minAmount || 0);
  const [maximumAmount, setMaximumQuantity] = useState(maxAmount || Number.MAX_SAFE_INTEGER);

  useEffect(() => {
    setMinimumQuantity(minAmount || 0);
    setMaximumQuantity(maxAmount || Number.MAX_SAFE_INTEGER);
  }, [minAmount, maxAmount]);

  // eslint-disable-next-line consistent-return
  const validateMinAndMaxAmount = data => {
    if (data) {
      if (parseFloat(data) < (minimumAmount / 100)) {
        return `Amount should be minimum ${currencyPrefix}${AmountDivideByHundred(minimumAmount)}`;
      }
      if (parseFloat(data) > (maximumAmount / 100)) {
        return `Amount should be maximum ${currencyPrefix}${AmountDivideByHundred(maximumAmount)}`;
      }
    }
  };

  return (
    <>
      <div className="form-group row mb-0 no-gutters">
        <div hidden={!label} className="col-sm-5">
          <LabelText
            fontSize={normalTextFontSize(isPreview, isAdaptive)}
            color="#0000000"
            hidden={!label}
            htmlFor="staticEmail"
            className="col-form-label font-weight-bolder m-0 text-break"
          >{label}
            <SpanText
              className="pl-2"
              hidden={isRequired}
              color="rgba(0, 0, 0, 0.6)"
              fontSize={smallTextFontSize(isPreview, isAdaptive)}
            >(Optional)
            </SpanText>
          </LabelText>
        </div>
        <div className="col-sm-7">
          <Controller
            defaultValue=""
            border="1px solid #29B1CC !important"
            control={control}
            name={name}
            rules={{
              required: isRequired && 'This field is required',
              validate: validateMinAndMaxAmount,
            }}
            as={(
              <InputWrapper
                width="100%"
                className="row no-gutters"
                border="1px solid #29B1CC !important"
                display="flex"
              >
                <InputWrapper
                  borderRight="1px solid #29B1CC !important"
                  borderRadius="4px 0 0 4px"
                  fontSize={smallTextFontSize(isPreview, isAdaptive)}
                  height={inputHeight(42, isPreview, isAdaptive)}
                  className="input-group-text d-flex justify-content-center border-0 py-1 bg-white col-2 align-items-center"
                >
                  <SpanText
                    className="d-flex align-items-center m-0"
                    fontSize={smallTextFontSize(isPreview, isAdaptive)}
                    height={inputHeight(42, isPreview, isAdaptive)}
                  >{currencyPrefix}
                  </SpanText>
                </InputWrapper>
                <NumberFormat
                  className="col-10 px-2"
                  style={
                  {
                    outline: 0,
                    marginTop: '0',
                    marginBottom: '0px',
                    borderRadius: '0px 0px 0px 4px',
                    borderRight: 'none',
                    border: '0',
                    fontSize: smallTextFontSize(isPreview, isAdaptive),
                    height: inputHeight(42, isPreview, isAdaptive),
                  }
                }
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={false}
                />
              </InputWrapper>
)}
          />
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text fontSize={smallTextFontSize(isPreview, isAdaptive)} marginTop="4px" marginBottom="0px" color="#ff1e1e">{message}</Text>
            )}
          />
        </div>
      </div>
      <div hidden={!description} className="col-sm-10 px-0">
        <LabelText className="text-mute mt-1" fontSize={smallTextFontSize(isPreview, isAdaptive)}>{description}</LabelText>
      </div>
    </>
  );
};

export default InputAdjustableAmount;
