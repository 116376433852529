// @flow
import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import WalletVendorinvoices from './components';
import NewWalletVendorInvoice from './components/NewInvoice';

const WalletVendorInvoice = () => (
  <Switch>
    <Route path="/vendorinvoice-wallet" component={WalletVendorinvoices} />
    <Route path="/vendorinvoice-wallet/add/new" component={NewWalletVendorInvoice} />
  </Switch>
);
export default WalletVendorInvoice;
