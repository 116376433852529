// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';
import moment from 'moment-timezone';

type Props = {
  visible: boolean,
  close: Function,
  selectedRecord: Object,
  selectedAccount: Object,
};

const OrderHistory = (props: Props) => {
  const {
    visible,
    close,
    selectedRecord,
    selectedAccount,
  } = props;

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="primary">Close</SpButton>,
      ]}
      title="View Order History"
    >
      <div className="px-4">
        <div className="d-flex mb-4">
          <div style={{ width: '50%' }}>
            <SpH5 className="pb-3">
              <div>Status</div>
            </SpH5>
            <div className="mt-1 mb-2">{selectedRecord.catalogOrderStatus}</div>
          </div>
          <div style={{ width: '50%' }}>
            <SpH5 className="pb-3">
              <div>Last Updated</div>
            </SpH5>
            <div className="mt-1 mb-2">{moment
              .utc(selectedRecord.updatedAt)
              .tz(selectedAccount.timezone)
              .format('MMM DD, YYYY, hh:mm a')}
            </div>
          </div>
        </div>
        <SpH5 className="pb-3">
          <div>Notes</div>
        </SpH5>
        <div className="mt-1">{selectedRecord.notes}</div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default OrderHistory;
