import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  INVENTORY_ITEMS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryItems = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=name&direction=ASC';
  const filterName = payload.nameSearch;
  if (filterName) {
    if (payload.filterParams && !payload.sortParams) {
      return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}?${filterName}`);
    }
    if (payload.filterParams && payload.sortParams) {
      return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}${query}${sorting}&${payload.filterParams}${filterName}`);
    }
    if (!payload.filterParams && payload.sortParams && payload.search) {
      return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}${filterName}`);
    }
    if (!payload.filterParams && payload.sortParams) {
      return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}${query}${sorting}${filterName}`);
    }
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}${query}${sorting}${filterName}`);
  }
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}${query}${sorting}`);
};

const fetchItemsList = async (payload) => {
  const queryParam = '?page=1&size=100';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=ASC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}${queryParam}${sorting}`);
};

const fetchModifierItemsList = async (payload) => {
  const queryParam = '?page=1&size=100';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=ASC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_MODIFIER}${queryParam}${sorting}`);
};

const fetchInventorySortItems = async (payload) => {
  const queryParam = '?page=1&size=1000';
  const sorting = payload.sortParam || '&sortBy=sortOrder&direction=ASC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}${queryParam}${sorting}`);
};

const postInventoryItem = async ({ params }) => axiosAuthInstance.post(`${API_END_POINTS.GET_INVENTORY_ITEM}`, params);

const patchInventoryItem = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_ITEM}/${gid}`, params);

const patchInventorySortItem = async ({ params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_ITEM}/sort-order`, params);

const deleteSelectedInventoryItem = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INVENTORY_ITEM}/${gid}`);

const patchBulkItem = async ({ params }) => axiosAuthInstance.patch(`${API_END_POINTS.ITEM_BULK_PATCH}`, params);

export function* getInventoryItemsList(action) {
  try {
    yield put({ type: LOADING.SET });
    console.log('Before request');
    const { data: { entity } } = yield call(fetchItemsList, action.payload);
    console.log('after request ', entity);
    yield put({ type: INVENTORY_ITEMS.LIST_SUCCESS, payload: entity });
  } catch (e) {
    console.log('error request ', e);
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_ITEMS.LIST_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getInventoryItems(action) {
  try {
    const { data: { entity } } = yield call(fetchInventoryItems, action.payload);
    yield put({ type: INVENTORY_ITEMS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_ITEMS.FAILED, payload: e.response.data });
  }
}

export function* updateInventoryItems(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventoryItem, action.payload);
    // const { data: { entity } } = yield call(fetchInventoryItems, action.payload.payload);
    // yield put({ type: INVENTORY_ITEMS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.UPDATE_ITEM,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addInventoryItems(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data } = yield call(postInventoryItem, action.payload);
    yield put({ type: INVENTORY_ITEMS.ITEM_ADD_SUCCESS, payload: data });
    const { data: { entity } } = yield call(fetchInventoryItems, action.payload);
    yield put({ type: INVENTORY_ITEMS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.ADD_ITEM,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getInventoryModifierItemsList(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchModifierItemsList, action.payload);
    yield put({ type: INVENTORY_ITEMS.MODIFIER_LIST_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_ITEMS.MODIFIER_LIST_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getInventorySortItems(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchInventorySortItems, action.payload);
    yield put({ type: INVENTORY_ITEMS.ORDER_LIST_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_ITEMS.ORDER_LIST_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateInventorySortItems(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventorySortItem, action.payload);
    const { data: { entity } } = yield call(fetchInventorySortItems, action.payload);
    yield put({ type: INVENTORY_ITEMS.ORDER_LIST_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.UPDATE_SORT_ITEM,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteInventoryItems(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteSelectedInventoryItem, action.payload);
    const { data: { entity } } = yield call(fetchInventoryItems, action.payload);
    yield put({ type: INVENTORY_ITEMS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.DELETE_ITEM,
    });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* updateBulkItems(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchBulkItem, action.payload);
    const { data: { entity } } = yield call(fetchInventoryItems, action.payload.payload);
    yield put({ type: INVENTORY_ITEMS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.UPDATE_ITEM,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
