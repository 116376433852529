// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  submitting: boolean,
  inventoryOrderDetails: Object,
};

const DeleteOrder = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    inventoryOrderDetails,
  } = props;
  const [disableButton, setDisableButton] = useState(false);

  const validate = () => {
    setDisableButton(true);
    submit(inventoryOrderDetails.gid);
  };

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton} type="danger">Delete</SpButton>,
      ]}
      title="Delete Order"
    >
      <div className="px-4">
        <div className="mt-1">Are you sure you want to delete this order?</div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(DeleteOrder);
