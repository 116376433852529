// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';

import {
  SpButton,
  SpStatusTag,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import getSubscriptionBackground from 'utils/getSubscriptionBackground';
import getSubscriptionStatus from 'utils/getSubscriptionStatus';
import getFilterOperators from 'utils/getFilterOperators';

import Loading from 'components/Loading';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import Filters from 'components/Filters';
import getStateList from 'utils/stateList';

import {
  subscriptionAction,
  subscriptionv2Actions,
  notificationActions,
  planv2Actions,
  invoiceDetailsAction,
  filterParamsActions,
} from 'store/actions';
import { NOTIFICATION, FILTERS_SPECS } from 'store/actionTypes';
import FilterIcon from 'components/NavIcons/filterIcon';
import {
  PAGES,
  ROLES,
  MESSAGES,
  SUBSCRIPTION_STATUSES,
  FILTERS_TYPES,
} from 'appconstants';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import RBAC from 'components/rbac';
import ExportIcon from 'components/NavIcons/exportIcon';
import Clipboard from 'components/NavIcons/copyclipboard';
import AddSubscription from './components/AddSubscription';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  subscription: Array<Object>,
  products: Array<Object>,
  plans: Array<Object>,
  profile: Array<Object>,
  fetchSubscriptionV2: Function,
  fetchProductList: Function,
  addSubscriptionV2: Function,
  fetchProfile: Function,
  setNotification: Function,
  fetchPlanV2: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  customersList: Array<Object>,
  getCustomersList: Function,
  setFilterData: Function,
  preserveFilters: Object,
};

const SubscriptionList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    subscription,
    products,
    plans,
    profile,
    setNotification,
    loading,
    addSubscriptionV2,
    fetchSubscriptionV2,
    fetchProductList,
    fetchPlanV2,
    fetchProfile,
    totalElements,
    submitting,
    validator,
    test,
    history,
    customersList,
    getCustomersList,
    setFilterData,
    preserveFilters,
  } = props;

  const [pagination, setPagination] = useState({});
  const [addSubscriptionModal, setAddSubscriptionModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const { role } = profile;
  const { id } = selectedAccount && selectedAccount.currency;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  let SUBSCRIPTION_FILTERS_TYPES = [];
  const [filterTypes, setFilterTypes] = useState([]);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterSubscription, setFilterSubscription] = useState((preserveFilters && preserveFilters.filterSubscription)
    ? (preserveFilters && preserveFilters.filterSubscription)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [couponRedirect, setCouponRedirect] = useState(false);
  const [taxRedirect, setTaxRedirect] = useState(false);
  const [addInventoryItemRedirect, setAddInventoryItemRedirect] = useState(false);
  const [itemModalRedirect, setItemModalRedirect] = useState('');
  const [subscriptionRedirect, setSubscriptionRedirect] = useState(false);
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [zipTag, setZipTag] = useState('');
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterSubscription: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });
  const supportedCurrency = (selectedAccount && selectedAccount.supportedCurrencies);

  const exportData = subscription ? subscription.map((values) => ({
    total: `${prefix}${formatNumber((values.amount / 100).toFixed(values.currency.toFixed), currencyType)}`,
    name: values.customer.name,
    email: values.customer.email,
    phoneNumber: `=""${values.customer.phoneNumber}""`,
    planName: values.plan2.name,
    status: getSubscriptionStatus(values.status),
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
  })) : '';

  const exportHeaders = [
    { label: 'AMOUNT', key: 'total' },
    { label: 'NAME', key: 'name' },
    { label: 'EMAIL', key: 'email' },
    { label: 'PHONE', key: 'phoneNumber' },
    { label: 'PLAN', key: 'planName' },
    { label: 'STATUS', key: 'status' },
    { label: 'CREATED ON', key: 'createdAt' },
  ];

  const debouncedFetchSubscriptionV2 = debounce(fetchSubscriptionV2, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const psortParams = '&sortBy=updatedAt&direction=DESC';
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchSubscriptionV2({
      currentPage,
      sortParams,
      filterParams,
    });
    fetchPlanV2({ psortParams });
    fetchProductList({ psortParams });
    /* fetchCustomers is for Add Subscription component */
    getCustomersList();
    fetchProfile();
  };

  useEffect(() => {
    if (profile && profile.role && profile.role.name === ROLES.PARTNER) {
      SUBSCRIPTION_FILTERS_TYPES = FILTERS_TYPES.PARTNER_SUBSCRIPTION;
      setFilterTypes(FILTERS_TYPES.PARTNER_SUBSCRIPTION);
    } else {
      SUBSCRIPTION_FILTERS_TYPES = FILTERS_TYPES.SUBSCRIPTION;
      setFilterTypes(FILTERS_TYPES.SUBSCRIPTION);
    }
  }, [profile]);

  useEffect(() => {
    getData(location.search);
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
      setstatesInCountry(getStateList(selectedAccount.country.id));
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator && !couponRedirect && !taxRedirect && !subscriptionRedirect && !addInventoryItemRedirect) {
      setAddSubscriptionModal(false);
    } else if (!validator && couponRedirect) {
      setCouponRedirect(false);
    } else if (!validator && taxRedirect) {
      setTaxRedirect(false);
    } else if (!validator && addInventoryItemRedirect) {
      setAddInventoryItemRedirect(false);
    }
  }, [validator]);

  const subScriptionModal = (val) => {
    setSubscriptionRedirect(val);
  };
  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [subscription]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.SUBSCRIPTION,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.SUBSCRIPTION,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const hanldleAddInventoryRedirect = (val) => {
    setAddInventoryItemRedirect(val);
  };

  const hanldleItemModalRedirect = (val) => {
    setItemModalRedirect(val);
  };

  const addNewSubscription = (subscriptions: Object) => {
    const {
      customersGid,
      plansGid,
      descriptions,
      counts,
      pfrequency,
      period,
      planAddGid,
      customerAddGid,
      apiPlanDate,
      applicationFee,
      notify,
      currencyCode,
      lineItemDtoList,
      cupoAaddon,
      allRatesAaddon,
      shippingAmount,
    } = subscriptions;
    const couponId = cupoAaddon ? cupoAaddon.gid : null;
    const taxesList = [];
    const listOfTaxes = [...allRatesAaddon];
    for (let i = 0; i < listOfTaxes.length; i += 1) {
      taxesList.push(listOfTaxes[i].taxRates.gid);
    }
    const finalLineItemsDto = [...lineItemDtoList];
    for (let j = 0; j < finalLineItemsDto.length; j += 1) {
      finalLineItemsDto[j].amount = (Number(finalLineItemsDto[j].rate)) * 100;
    }
    const plansDate = apiPlanDate;
    const payloadApplicationFee = applicationFee ? applicationFee.replace(/[^\d]/g, '') : '';
    addSubscriptionV2({
      currencyCode,
      customerGid: customersGid || customerAddGid,
      plan2Gid: plansGid || planAddGid,
      plan2BillingFrequency: pfrequency,
      plan2StartDate: plansDate,
      plan2BillingPeriod: period,
      plan2TotalPayments: counts,
      couponGid: couponId,
      taxRates: taxesList,
      description: descriptions,
      status: 'ACTIVE',
      applicationFeePercentage: payloadApplicationFee ? Number(payloadApplicationFee) : null,
      subscription2LineItems: finalLineItemsDto,
      notificationType: notify,
      shippingAmount: (shippingAmount * 100),
    });
  };

  const selectedRecord = (record) => {
    const { gid } = record;
    history.push({
      pathname: `${PAGES.SUBSCRIPTION}/${gid}`,
      state: location.search,
    });
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUBSCRIPTION.SUCCESS,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterSubscription];
    if (filterSubscription.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = SUBSCRIPTION_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterSubscription(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterSubscription];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'planAmount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'applicationFeePercentage') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = SUBSCRIPTION_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterSubscription(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterSubscription];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterSubscription(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterSubscription(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterSubscription];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterSubscription(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterSubscription(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterSubscription[index].filterName === 'createdAt') {
      if (filterSubscription[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterSubscription[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterSubscription[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterSubscription[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterSubscription];
    list[index].filterValue = selectedValue;
    setFilterSubscription(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterSubscription];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterSubscription[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterSubscription(list);
  };

  const handleAddClick = () => {
    const list = [...filterSubscription];
    setFilterSubscription([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterSubscription([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    if (profile && profile.role && profile.role.name === ROLES.PARTNER) {
      SUBSCRIPTION_FILTERS_TYPES = FILTERS_TYPES.PARTNER_SUBSCRIPTION;
      setFilterTypes(FILTERS_TYPES.PARTNER_SUBSCRIPTION);
    } else {
      SUBSCRIPTION_FILTERS_TYPES = FILTERS_TYPES.SUBSCRIPTION;
      setFilterTypes(FILTERS_TYPES.SUBSCRIPTION);
    }
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.SUBSCRIPTION);
  };

  const applyFilters = () => {
    const subscriptionFilters = filterSubscription.filter((item) => item.checked);
    for (let i = 0; i < subscriptionFilters.length; i += 1) {
      if (!subscriptionFilters[i].filterName || !subscriptionFilters[i].filterOperator || !subscriptionFilters[i].filterValue) {
        return;
      }
    }
    if (subscriptionFilters[0].filterValue || (subscriptionFilters[0].filterValueFrom && subscriptionFilters[0].filterValueTo)) {
      setCount(subscriptionFilters && subscriptionFilters.length);
      for (let i = 0; i < subscriptionFilters.length; i += 1) {
        if (subscriptionFilters[i].checked) {
          if (subscriptionFilters[i].filterName === 'createdAt') {
            subscriptionFilters[i] = subscriptionFilters[i].filterValue;
          } else if (subscriptionFilters[i].filterName === 'planAmount' && subscriptionFilters[i].filterOperator === 'BT') {
            const name = subscriptionFilters[i].filterName;
            const from = subscriptionFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = subscriptionFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            subscriptionFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (subscriptionFilters[i].filterName === 'planAmount' && subscriptionFilters[i].filterOperator !== 'BT') {
            const value = subscriptionFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            subscriptionFilters[i] = `${subscriptionFilters[i].filterName}.${subscriptionFilters[i].filterOperator}=${value}`;
          } else if (subscriptionFilters[i].filterName === 'applicationFeePercentage' && subscriptionFilters[i].filterOperator === 'BT') {
            const name = subscriptionFilters[i].filterName;
            const from = subscriptionFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = subscriptionFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            subscriptionFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (subscriptionFilters[i].filterName === 'applicationFeePercentage' && subscriptionFilters[i].filterOperator !== 'BT') {
            const value = subscriptionFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            subscriptionFilters[i] = `${subscriptionFilters[i].filterName}.${subscriptionFilters[i].filterOperator}=${value}`;
          } else if (subscriptionFilters[i].filterName === 'customer.email') {
            const email = encodeURIComponent(subscriptionFilters[i].filterValue);
            subscriptionFilters[i] = `${subscriptionFilters[i].filterName}.${subscriptionFilters[i].filterOperator}=${email}`;
          } else if (subscriptionFilters[i].filterName === 'customer.phoneNumber') {
            const value = subscriptionFilters[i].filterValue.replace('+', '%2B');
            subscriptionFilters[i] = `${subscriptionFilters[i].filterName}.${subscriptionFilters[i].filterOperator}=${value}`;
          } else {
            const name = subscriptionFilters[i].filterName;
            subscriptionFilters[i] = `${name}.${subscriptionFilters[i].filterOperator}=${subscriptionFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(subscriptionFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterSubscription,
        });
        history.push({
          pathname: PAGES.SUBSCRIPTION,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterSubscription,
        });
        history.push({
          pathname: PAGES.SUBSCRIPTION,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const cancelAddSubscription = () => {
    setAddSubscriptionModal(false);
  };

  const partnerColumns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(2);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix}${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'NAME',
      dataIndex: 'customer.name',
      width: '10%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (cname) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {cname || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'customer.email',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (email) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {email || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PHONE',
      dataIndex: 'customer.phoneNumber',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (phoneNumber) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {phoneNumber || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PLAN',
      dataIndex: 'plan2.name',
      width: '11%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (plan) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {plan || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'APPLICATION FEE',
      dataIndex: 'applicationFeePercentage',
      width: '18%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (applicationFeePercentage) => {
        const total = (applicationFeePercentage / 100).toFixed(2);
        return (
          <span> {`${formatNumber(total, currencyType)} %`}</span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <SpStatusTag style={{ backgroundColor: getSubscriptionBackground(status) }}>
            {getSubscriptionStatus(status)}
          </SpStatusTag>
        </Tooltip>
      ),
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(2);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix}${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'NAME',
      dataIndex: 'customer.name',
      width: '14%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (cname) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {cname || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'customer.email',
      width: '14%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (email) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {email || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PHONE',
      dataIndex: 'customer.phoneNumber',
      width: '14%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (phoneNumber) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {phoneNumber || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PLAN',
      dataIndex: 'plan2.name',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (plan) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {plan || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <SpStatusTag style={{ backgroundColor: getSubscriptionBackground(status) }}>
            {getSubscriptionStatus(status)}
          </SpStatusTag>
        </Tooltip>
      ),
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '18%',
    align: 'center',
    render: (subscription2Links, record) => {
      const recordLink = (record.subscription2Links) && (record.subscription2Links).length > 0
        ? record.subscription2Links[0].link : '';
      const { status } = record;
      if (recordLink && status !== SUBSCRIPTION_STATUSES.ACTIVECANCEL && status !== SUBSCRIPTION_STATUSES.ACTIVEDRAFT) {
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); copyCode(record.subscription2Links[0].link); }}
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      }
      return (
        <span>
          <SpButton
            type="secondary"
            borderRadius="50px"
            disabled
          >
            <Clipboard />
          </SpButton>
        </span>
      );
    },
  };

  if ((loading && !itemModalRedirect) || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC
            permit={
              [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                ROLES.OPERATIONS, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
            }
          >
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                ghost
                className="mr-3"
                onClick={() => setAddSubscriptionModal(true)}
              >
                {'\u002B'}&nbsp;Add Subscription
              </SpButton>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Subscriptions">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Subscriptions Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Subscriptions">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-88px' }}
                  >
                    <Filters
                      filtersGrid={filterSubscription}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                      handleFromValue={handleFromValue}
                      handleToValue={handleToValue}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterSubscription.length < SUBSCRIPTION_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD SUBSCRIPTION MODAL */}
      {addSubscriptionModal && (
        <AddSubscription
          visible={addSubscriptionModal}
          submitting={submitting}
          phoneCode={phoneCode}
          id={id}
          selectedAccount={selectedAccount}
          products={products}
          plans={plans}
          customers={customersList}
          role={profile && profile.role}
          close={cancelAddSubscription}
          submit={addNewSubscription}
          supportedCurrency={supportedCurrency}
          statesInCountry={statesInCountry}
          zipTag={zipTag}
          subScriptionModal={subScriptionModal}
          cntryId={selectedAccount && selectedAccount.country.id}
          hanldleAddInventoryRedirect={hanldleAddInventoryRedirect}
          hanldleItemModalRedirect={hanldleItemModalRedirect}
        />
      )}
      <Table
        onRow={(record) => ({
          onClick: () => {
            selectedRecord(record);
          },
        })}
        rowClassName={() => 'ant-table-clickable-row'}
        columns={(role && role.name === ROLES.PARTNER) ? partnerColumns : [...columns, actionColumn]}
        rowKey={(record) => (record.subscription2Links ? record.subscription2Links[0].gid : record.gid)}
        dataSource={subscription}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  subscription: state.subscriptionv2.content,
  products: state.subscription.products.content,
  profile: state.subscription.profile,
  plans: state.planv2.content,
  totalElements: state.subscriptionv2.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  newCoupon: state.invoiceDetails.newCoupon,
  customersList: state.invoiceDetails.customersList,
  newTaxrate: state.invoiceDetails.newTaxrate,
  taxList: state.invoiceDetails.taxList,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptionV2: param => dispatch(subscriptionv2Actions.fetchSubscriptionV2({
    payload: param,
  })),
  fetchPlanV2: param => dispatch(planv2Actions.fetchPlanV2({
    payload: param,
  })),
  fetchProductList: param => dispatch(subscriptionAction.fetchProductList({
    payload: param,
  })),
  addSubscriptionV2: param => dispatch(subscriptionv2Actions.addSubscriptionV2({
    payload: param,
  })),
  fetchProfile: param => dispatch(subscriptionAction.fetchProfile({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  addNewCoupon: param => dispatch(invoiceDetailsAction.addNewCoupon({
    payload: param,
  })),
  getCustomersList: param => dispatch(invoiceDetailsAction.getCustomersList({
    payload: param,
  })),
  addNewTaxRate: param => dispatch(invoiceDetailsAction.addNewTaxRate({
    payload: param,
  })),
  getTaxes: param => dispatch(invoiceDetailsAction.getTaxes({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionList);
