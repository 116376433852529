import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { Menu, Card, Icon } from 'antd';
import queryString from 'query-string';

import { SpTab } from 'components/DesignKit';
import AllTransactions from './AllTransactions';

type Props = {
  location: {
    pathname: string,
    search: string,
  },
  history: {
    push: Function,
  },
  selectedAccount: Object,
};

const TransactionsList = (props: Props) => {
  const [current, setCurrent] = useState('paymentsent');
  const { path } = useRouteMatch();
  const {
    location,
    history,
    selectedAccount,
  } = props;
  const countryCode = selectedAccount && selectedAccount.country && selectedAccount.country.alpha2;

  const setMenu = (query) => {
    const parsed = queryString.parse(query);
    if (parsed['type.EQ']) {
      const value = parsed['type.EQ'];
      switch (value) {
        case 'TRANSACTION_DEBIT': {
          setCurrent('paymentsent');
          break;
        }
        case 'TRANSACTION_CREDIT': {
          setCurrent('paymentrecieved');
          break;
        }
        case 'LOAD': {
          setCurrent('deposits');
          break;
        }
        case 'PAYOUT': {
          setCurrent('withdrawls');
          break;
        }
        case 'ALL': {
          setCurrent('payments');
          break;
        }
        default:
          setCurrent('payments');
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      setMenu(location.search);
    } else {
      history.push(`${path}?type.EQ=ALL`);
    }
  }, [location]);

  return (
    <>
      {
      (countryCode && countryCode !== 'US')
        ? (
          <Card
            className="mx-auto mt-5"
            style={{ width: 600, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
            bodyStyle={{ height: '100%' }}
          >
            <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
              <Icon
                type="exclamation-circle"
                className="mb-4"
                style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
              />
              <h5>Transactions are only available for the country USA.</h5>
            </div>
          </Card>
        ) : (
          <div>
            <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
              <Menu.Item key="payments">
                <SpTab
                  to={`${path}?type.EQ=ALL`}
                  selected={current === 'payments'}
                >
                  ALL
                </SpTab>
              </Menu.Item>
              <Menu.Item key="paymentsent">
                <SpTab
                  to={`${path}?type.EQ=TRANSACTION_DEBIT`}
                  selected={current === 'paymentsent'}
                >
                  PAYMENTS SENT
                </SpTab>
              </Menu.Item>
              <Menu.Item key="paymentrecieved">
                <SpTab
                  to={`${path}?type.EQ=TRANSACTION_CREDIT`}
                  selected={current === 'paymentrecieved'}
                >
                  PAYMENTS RECEIVED
                </SpTab>
              </Menu.Item>
              <Menu.Item key="deposits">
                <SpTab
                  to={`${path}?type.EQ=LOAD`}
                  selected={current === 'deposits'}
                >
                  DEPOSITS
                </SpTab>
              </Menu.Item>
              <Menu.Item key="withdrawls">
                <SpTab
                  to={`${path}?type.EQ=PAYOUT`}
                  selected={current === 'withdrawls'}
                >
                  WITHDRAWLS
                </SpTab>
              </Menu.Item>
            </Menu>
            <AllTransactions {...props} />
          </div>
        )
    }
    </>

  );
};

const mapStateToProps = (state) => ({
  selectedAccount: state.account.selectedAccount,
});

// export default withRouter(connect(TransactionsList));
export default withRouter(connect(mapStateToProps, null)(TransactionsList));
