// @flow
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  SpH5,
  SpText,
} from 'components/DesignKit';
import NumberFormat from 'react-number-format';

type Props = {
  contactDetailsData: Object,
  transferDetailsData: Object,
  selectedAccount: Array<Object>,
};

const ConfirmTransfer = (props: Props) => {
  const {
    contactDetailsData,
    transferDetailsData,
    selectedAccount,
  } = props;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const countryCode = contactDetailsData && contactDetailsData.phoneNumberCode;

  const [phoneFormate, setPhoneFormate] = useState('');

  useEffect(() => {
    if (countryCode === '+1') {
      setPhoneFormate('+# (###) ###-####');
    } else if (countryCode.length !== 4 && countryCode === '+91') {
      setPhoneFormate('+##-##### #####');
    } else if (countryCode.length === 2 && countryCode !== '+1') {
      setPhoneFormate('+#-##########');
    } else if (countryCode.length === 3 && countryCode !== '+91') {
      setPhoneFormate('+##-##########');
    } else if (countryCode.length === 4) {
      setPhoneFormate('+###-##########');
    }
  }, [contactDetailsData]);

  return (
    <>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Amount</SpH5>
          </Col>
          <Col>
            <SpText>{(transferDetailsData.transferAmount) ? `${prefix}${transferDetailsData.transferAmount}` : <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Description</SpH5>
          </Col>
          <Col>
            <SpText>{transferDetailsData.description || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Transfer Type</SpH5>
          </Col>
          <Col>
            <SpText>{transferDetailsData.transferType || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Transfer Date</SpH5>
          </Col>
          <Col>
            <SpText>{transferDetailsData.transferDate || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Name</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Email</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.email || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Phone</SpH5>
          </Col>
          <Col>
            <NumberFormat
              className="numFormate"
              value={`${contactDetailsData.phoneNumberCode}${contactDetailsData.contactPhone}`}
              format={phoneFormate}
              mask="_"
              disabled
              style={{
                fontWeight: '400',
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Contact Type</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      {contactDetailsData.customType && (
        <div className="mb-3">
          <Row justify="start">
            <Col>
              <SpH5>Custom Type</SpH5>
            </Col>
            <Col>
              <SpText>{contactDetailsData.customType || <>&#8211;</>}</SpText>
            </Col>
          </Row>
        </div>
      )}
      <div>
        <Row justify="start">
          <Col>
            <SpH5>Notification Type</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.notifyTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
    </>
  );
};

// $FlowFixMe
export default ConfirmTransfer;
