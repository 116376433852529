// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { createSelector } from 'reselect';
import _get from 'lodash.get';

import {
  Card,
  Row,
  Col,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  issuerrateDetailsActions,
  notificationActions,
} from 'store/actions';
import formatNumber from 'utils/formatNumber';
import CONSTANTS from 'store/constants';

type Props = {
  fetchIssuerRatesDetails: Function,
  issuerrateDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  issuerrateDetailsStatus: string,
  displayAmount: Object,
}

const amountFilter = (issuerrateDetails) => issuerrateDetails;

const displayAmountSelector = createSelector(
  [amountFilter],
  (issuerrateDetails) => ({
    prefix: _get(issuerrateDetails, 'issuerRate.currency.prefix', null),
    // eslint-disable-next-line max-len
    maxDisplay: formatNumber(issuerrateDetails.processingMax && (issuerrateDetails.processingMax / 100).toFixed(issuerrateDetails.issuerRate.currency.toFixed), issuerrateDetails.issuerRate && issuerrateDetails.issuerRate.currency),
    // eslint-disable-next-line max-len
    minDisplay: formatNumber(issuerrateDetails.processingMin && (issuerrateDetails.processingMin / 100).toFixed(issuerrateDetails.issuerRate.currency.toFixed), issuerrateDetails.issuerRate && issuerrateDetails.issuerRate.currency),
    // eslint-disable-next-line max-len
    rateDisplay: formatNumber(issuerrateDetails.accountRate && (issuerrateDetails.accountRate / 100).toFixed(issuerrateDetails.issuerRate.currency.toFixed), issuerrateDetails.issuerRate && issuerrateDetails.issuerRate.currency),
    // eslint-disable-next-line max-len
    feeDisplay: formatNumber(issuerrateDetails.accountFee && (issuerrateDetails.accountFee / 100).toFixed(issuerrateDetails.issuerRate.currency.toFixed), issuerrateDetails.issuerRate && issuerrateDetails.issuerRate.currency),
    // eslint-disable-next-line max-len
    taxDisplay: formatNumber(issuerrateDetails.accountTax && (issuerrateDetails.accountTax / 100).toFixed(issuerrateDetails.issuerRate.currency.toFixed), issuerrateDetails.issuerRate && issuerrateDetails.issuerRate.currency),
  }),
);

const IssuerRatesDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchIssuerRatesDetails,
    issuerrateDetails,
    loading,
    selectedAccount,
    issuerrateDetailsStatus,
    displayAmount,
  } = props;
  const { gid } = props.match.params;

  const getData = () => {
    fetchIssuerRatesDetails(gid);
  };

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        issuerrateDetails.gid && issuerrateDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  {issuerrateDetails.issuer && issuerrateDetails.issuer.name
                    ? <SpText className="text-uppercase mr-4" fontSize="20px">{issuerrateDetails.issuer.name}</SpText>
                    : ''}
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Category</span>
                  </Col>
                  <Col span={9}>
                    {issuerrateDetails.issuerRate && issuerrateDetails.issuerRate.category && issuerrateDetails.issuerRate.category.name
                      ? <SpH5>{issuerrateDetails.issuerRate.category.name}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Transfer Type</span>
                  </Col>
                  <Col span={9}>
                    {issuerrateDetails.issuerRate && issuerrateDetails.issuerRate.transferType && issuerrateDetails.issuerRate.transferType.value
                      ? <SpH5>{issuerrateDetails.issuerRate.transferType.value}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Sales Person</span>
                  </Col>
                  <Col span={9}>
                    {issuerrateDetails.salesPerson
                      ? <SpH5>{issuerrateDetails.salesPerson}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Rate</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.rateDisplay} %`}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Fee</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.prefix} ${displayAmount.feeDisplay}`}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Tax</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.taxDisplay} %`}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Maximum Processing</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.prefix} ${displayAmount.maxDisplay}`}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={16}>
                    <Col span={6}>
                      <span>Minimum Processing</span>
                    </Col>
                    <Col span={10}>
                      <SpH5>
                        {`${displayAmount.prefix} ${displayAmount.minDisplay}`}
                      </SpH5>
                    </Col>
                  </Col>
                  <Col span={24} className="d-flex justify-content-end">
                    <SpH5 color="#7f7f7f">{issuerrateDetails.gid}</SpH5>
                  </Col>
                </Row>
              </SpForm>
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  issuerrateDetails: state.issuerrateDetails,
  issuerrateDetailsStatus: state.issuerrateDetails.issuerrateDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  displayAmount: displayAmountSelector(state.issuerrateDetails),
});

const mapDispatchToProps = (dispatch) => ({
  fetchIssuerRatesDetails: gid => dispatch(issuerrateDetailsActions.fetchIssuerRatesDetails({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(IssuerRatesDetails);
