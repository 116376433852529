import { call, put } from 'redux-saga/effects';

import {
  axiosNoAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  AUTH,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const doLogin = async (payload) => axiosNoAuthInstance.post(API_END_POINTS.AUTHENTICATE, payload);

export function* authenticateUser(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data } = yield call(doLogin, action.payload);
    yield put({ type: AUTH.SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: AUTH.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
