// @flow
import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'antd';
import {
  SpH5,
  SpText,
} from 'components/DesignKit';
import formatAmount from 'utils/formatAmount';

type Props = {
  prev: Function,
  selectedAccount: Array<Object>,
  invoiceDetails: Object,
  fundingSourceDetailsData: Object,
};

const ConfirmTransfer = (props: Props) => {
  const {
    prev,
    selectedAccount,
    invoiceDetails,
    fundingSourceDetailsData,
  } = props;

  const { prefix } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;
  const accountNumber = (fundingSourceDetailsData && fundingSourceDetailsData.accountNumber);
  const lastFour = (accountNumber && (accountNumber > 4)) ? accountNumber.slice(-4) : accountNumber;
  const totalAmount = formatAmount((invoiceDetails && (invoiceDetails.amount / 100)), currencyType);

  return (
    <>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Invoice Amount</SpH5>
          </Col>
          <Col>
            <SpText>{(totalAmount) ? `${prefix}${totalAmount}` : <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Invoice No.</SpH5>
          </Col>
          <Col>
            <SpText>{(invoiceDetails && invoiceDetails.invoiceNumber) || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Vendor</SpH5>
          </Col>
          <Col>
            <SpText>{(invoiceDetails && invoiceDetails.contact && invoiceDetails.contact.name) || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div>
        <Row justify="start">
          <Col>
            <SpH5>Funding Source</SpH5>
          </Col>
          <Col>
            <SpText>{lastFour ? `${(fundingSourceDetailsData && fundingSourceDetailsData.bankName) || ''} ****** ${lastFour}` : <>&#8211;</>}</SpText>
            <Button type="link" onClick={prev}>&nbsp;Change</Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

// $FlowFixMe
export default ConfirmTransfer;
