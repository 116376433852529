// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Row,
  Col,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import { SpH5, SpText } from 'components/DesignKit';


type Props = {
  submitting: boolean,
  currencyType: Object,
  searchRateData: Object,
  seletedRecord: Object,
};

const RateDetails = (props: Props, ref) => {
  const {
    submitting,
    currencyType,
    searchRateData,
    seletedRecord,
  } = props;
  const [, forceUpdate] = useState();

  console.log(seletedRecord, 'seletedRecordseletedRecordseletedRecord');

  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      billingFrequency: 'ONE_TIME',
      subTotal: seletedRecord.price * searchRateData.quantity,
      shippingCost: seletedRecord.shippingCost,
      promotionalCredits: 0,
      totalAmountDue: (seletedRecord.price * searchRateData.quantity) + seletedRecord.shippingCost,
    }),
  }));

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2">
          <Row span={16}>
            <Col span={8}>
              <SpH5>Billing frequency</SpH5>
              <div>
                <SpText>Onetime Fee</SpText>
              </div>
            </Col>
            <Col span={8} className="ml-4">
              <SpH5>Price per unit</SpH5>
              <div>
                <SpText>{currencyType.prefix} {formatAmount(((seletedRecord.price) / 100), currencyType)}</SpText>
              </div>
            </Col>
          </Row>
          <Row span={16} className="mt-4">
            <Col span={8}>
              <SpH5>Quantity</SpH5>
              <div>
                <SpText>{searchRateData.quantity}</SpText>
              </div>
            </Col>
            <Col span={8} className="ml-4">
              <SpH5>Subtotal</SpH5>
              <div>
                <SpText>{currencyType.prefix} {formatAmount(((seletedRecord.price * searchRateData.quantity) / 100), currencyType)}</SpText>
              </div>
            </Col>
          </Row>
          <Row span={16} className="mt-4">
            <Col span={8}>
              <SpH5>Shipping cost</SpH5>
              <div>
                <SpText>{currencyType.prefix} {formatAmount((seletedRecord.shippingCost / 100), currencyType)}</SpText>
              </div>
            </Col>
            <Col span={8} className="ml-4">
              <SpH5>Promotional credits</SpH5>
              <div>
                <SpText>&#8211;</SpText>
              </div>
            </Col>
          </Row>
          <Row span={16} className="mt-4">
            <Col span={8}>
              <SpH5>Total Amount Due</SpH5>
              <div>
                <SpText>
                  {currencyType.prefix} {
                  formatAmount((((seletedRecord.price * searchRateData.quantity) / 100) + (seletedRecord.shippingCost / 100)), currencyType)
                  }
                </SpText>
              </div>
            </Col>
            <Col span={8} className="ml-4">
              <SpH5>Bill To</SpH5>
              <div>
                <SpText>&#8211;</SpText>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(RateDetails);
