export default status => {
  if (status === 'OPEN') {
    return '#ffe0b1';
  } if (status === 'PENDING') {
    return '#ffe0b1';
  } if (status === 'IN_PROGRESS') {
    return '#ffe0b1';
  } if (status === 'RESOLVED') {
    return '#ececff';
  } if (status === 'DUPLICATE') {
    return '#e2e2e2';
  } return '#ffe0b1';
};
