import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  Input,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpButton,
  SpH5,
  SpError,
} from 'components/DesignKit';

type Props = {
  internalAccount: Object,
  submit: Function,
  openModal: Function,
};

const PartnerImages = (props: Props) => {
  const {
    internalAccount,
    submit,
    openModal,
  } = props;

  const [, forceUpdate] = useState();
  const [bankImageUrl, setBankImageUrl] = useState(internalAccount && internalAccount.bankImageUrl ? internalAccount.bankImageUrl : null);
  const [partnerImageUrl, sePartnerImageUrl] = useState(internalAccount && internalAccount.partnerImageUrl
    ? internalAccount.partnerImageUrl
    : null);
  const [disabled, setDisabled] = useState(true);
  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  const updatePartnerImages = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      bankImageUrl,
      partnerImageUrl,
    });
  };

  useEffect(() => {
    setBankImageUrl(internalAccount.bankImageUrl || null);
    sePartnerImageUrl(internalAccount.partnerImageUrl || null);
    setDisabled(true);
  }, [openModal]);

  const reset = () => {
    setBankImageUrl(internalAccount.bankImageUrl || null);
    sePartnerImageUrl(internalAccount.partnerImageUrl || null);
    setDisabled(true);
    openModal(false);
  };

  return (
    <>
      <div>
        <Row>
          <Col span={3} className="mb-2">
            <SpH5>Bank Image Url</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Input
              placeholder="http://www.example.com"
              value={bankImageUrl}
              onChange={e => {
                setBankImageUrl(e.currentTarget.value);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpError>
              {simpleValidator.current.message('bank image url', bankImageUrl, 'url')}
            </SpError>
          </Col>
        </Row>
        <Row>
          <Col span={3} className="mb-2 mt-4">
            <SpH5>Partner Image Url </SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Input
              placeholder="http://www.example.com"
              value={partnerImageUrl}
              onChange={e => {
                sePartnerImageUrl(e.currentTarget.value);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpError>
              {simpleValidator.current.message('partner image url', partnerImageUrl, 'url')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4" style={{ marginLeft: '-15px' }}>
          <Col span={2}>
            <Button
              type="link"
              onClick={reset}
              disabled={disabled}
            >
              Reset
            </Button>
          </Col>
          <Col span={2}>
            <SpButton
              type="primary"
              shape="round"
              disabled={disabled}
              onClick={updatePartnerImages}
            >
              Update
            </SpButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(PartnerImages);
