import { LOADING } from '../actionTypes';

const initialState = {
  loading: true,
  error: '',
  success: '',
  submitting: false,
  validator: true,
  skeleton: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING.SET:
      return {
        ...state,
        loading: true,
      };
    case LOADING.UNSET:
      return {
        ...state,
        loading: false,
      };
    case LOADING.SUBMITTING:
      return {
        ...state,
        submitting: true,
      };
    case LOADING.SUBMITTED:
      return {
        ...state,
        submitting: false,
      };
    case LOADING.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        success: action.payload,
      };
    case LOADING.ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload,
      };
    case LOADING.VALIDATING:
      return {
        ...state,
        validator: true,
      };
    case LOADING.VALIDATED:
      return {
        ...state,
        validator: false,
      };
    case LOADING.SET_SKELETON:
      return {
        ...state,
        skeleton: true,
      };
    case LOADING.UNSET_SKELETON:
      return {
        ...state,
        skeleton: false,
      };
    default:
      return state;
  }
};
