// @flow
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
};

const AddSection = (props: Props) => {
  const {
    visible,
    close,
    submit,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Add</SpButton>,
      ]}
      title="Add New Section"
    >
      <div className="px-4">
        <div>
          <label htmlFor=""><SpH5>Section Name</SpH5></label>
          <Input
            placeholder="Section Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddSection);
