import { TABLE_DETAILS } from '../actionTypes';

export const fetchTableDetails = (action) => ({
  type: TABLE_DETAILS.FETCH,
  payload: action.payload,
});

export const fetchStaffDetails = (action) => ({
  type: TABLE_DETAILS.FETCH_STAFF,
  payload: action.payload,
});

export const fetchGuestDetails = (action) => ({
  type: TABLE_DETAILS.FETCH_GUEST,
  payload: action.payload,
});
