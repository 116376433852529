// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Row,
  Col,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  beneficiaryDetailsActions,
} from 'store/actions';
import CONSTANTS from 'store/constants';

type Props = {
  fetchBeneficiaryDetails: Function,
  beneficiaryDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  beneficiaryDetailsStatus: string,
}

const BeneficiaryDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchBeneficiaryDetails,
    beneficiaryDetails,
    loading,
    selectedAccount,
    beneficiaryDetailsStatus,
  } = props;
  const { gid } = props.match.params;
  const [phoneFormate, setPhoneFormate] = useState('');

  const getData = () => {
    fetchBeneficiaryDetails(gid);
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    if (beneficiaryDetails.gid) {
      const phone = beneficiaryDetails.phoneNumber ? beneficiaryDetails.phoneNumber : '';
      const cntryCode = beneficiaryDetails.phoneNumber ? beneficiaryDetails.phoneNumber.substring(0, 2) : '';
      if (cntryCode === '+1') {
        setPhoneFormate('+# (###) ###-####');
      } else if (phone.length !== 14 && cntryCode === '+9') {
        setPhoneFormate('+##-##### #####');
      } else if (phone.length === 12 && cntryCode !== '+1') {
        setPhoneFormate('+#-##########');
      } else if (phone.length === 13 && cntryCode !== '+9') {
        setPhoneFormate('+##-##########');
      } else if (phone.length === 14) {
        setPhoneFormate('+###-##########');
      }
    }
  }, [beneficiaryDetails]);


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        beneficiaryDetails.gid && beneficiaryDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    {beneficiaryDetails.name || <>&#8211;</>}
                  </SpText>
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Email</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{beneficiaryDetails.email || <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Wallet Alias</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{beneficiaryDetails.beneficiaryWalletAlias || <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Phone</span>
                  </Col>
                  <Col span={9}>
                    {
                      beneficiaryDetails.phoneNumber ? (
                        <NumberFormat
                          className="numFormate"
                          value={beneficiaryDetails.phoneNumber}
                          format={phoneFormate}
                          mask="_"
                          disabled
                        />
                      ) : <>&#8211;</>
                    }
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Payment Term Days</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{beneficiaryDetails.paymentTermsDays || <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              {(beneficiaryDetails && beneficiaryDetails.address)
                ? (
                  <>
                    <hr />
                    <SpForm>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <span>Street</span>
                        </Col>
                        <Col span={9}>
                          <SpH5>
                            {(beneficiaryDetails.address && beneficiaryDetails.address.street) || <>&#8211;</>}
                          </SpH5>
                        </Col>
                        <Col span={3}>
                          <span>City</span>
                        </Col>
                        <Col span={8}>
                          <SpH5>
                            {(beneficiaryDetails.address && beneficiaryDetails.address.city) || <>&#8211;</>}
                          </SpH5>
                        </Col>
                      </Row>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <span>State</span>
                        </Col>
                        <Col span={9}>
                          <SpH5>
                            {(beneficiaryDetails.address && beneficiaryDetails.address.state) || <>&#8211;</>}
                          </SpH5>
                        </Col>
                        <Col span={3}>
                          <span>Country</span>
                        </Col>
                        <Col span={8}>
                          <SpH5>
                            {(beneficiaryDetails.address && beneficiaryDetails.address.country)
                              ? (beneficiaryDetails.address.country.name)
                              : <>&#8211;</>}
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                )
                : ''}
              <Row className="d-flex justify-content-end">
                <Col>
                  <SpH5 color="#7f7f7f">{gid}</SpH5>
                </Col>
              </Row>
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  beneficiaryDetails: state.beneficiaryDetails,
  beneficiaryDetailsStatus: state.beneficiaryDetails.beneficiaryDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBeneficiaryDetails: gid => dispatch(beneficiaryDetailsActions.fetchBeneficiaryDetails({
    payload: gid,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryDetails);
