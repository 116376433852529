import { INVENTORY_CATEGORIES } from '../actionTypes';

export const fetchInventoryCategories = (action) => ({
  type: INVENTORY_CATEGORIES.FETCH,
  payload: action.payload,
});

export const updateInventoryCategory = (action) => ({
  type: INVENTORY_CATEGORIES.UPDATE,
  payload: action.payload,
});

export const addInventoryCategory = (action) => ({
  type: INVENTORY_CATEGORIES.ADD,
  payload: action.payload,
});

export const deleteInventoryCategory = (action) => ({
  type: INVENTORY_CATEGORIES.DELETE,
  payload: action.payload,
});

export const fetchInventorySortCategory = (action) => ({
  type: INVENTORY_CATEGORIES.ORDER_CATEGORIES_FETCH,
  payload: action.payload,
});

export const updateInventorySortCategory = (action) => ({
  type: INVENTORY_CATEGORIES.ORDER_CATEGORIES_UPDATE,
  payload: action.payload,
});


export const fetchCategoryOperatingHours = (action) => ({
  type: INVENTORY_CATEGORIES.FETCH_OPERATING_HOURS,
  payload: action.payload,
});

export const updateBulkCategories = (action) => ({
  type: INVENTORY_CATEGORIES.BULK_UPDATE,
  payload: action.payload,
});

export const fetchSearchCategories = (action) => ({
  type: INVENTORY_CATEGORIES.CATEGORY_SEARCH_SUCCESS,
  payload: action.payload,
});

export const fetchInventoryCategoriesWithItems = (action) => ({
  type: INVENTORY_CATEGORIES.FETCH_CATEGORY,
  payload: action.payload,
});
