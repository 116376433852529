// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5 } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  invoiceNo: string,
};

const DeleteInvoice = (props: Props) => {
  const {
    visible,
    close,
    submit,
    invoiceNo,
  } = props;

  const deleteInvoice = () => {
    submit();
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      onOk={deleteInvoice}
      onCancel={() => close()}
      okText="Delete"
      okButtonProps={{ shape: 'round', type: 'danger' }}
      cancelButtonProps={{ shape: 'round', ghost: true, type: 'primary' }}
      title="Delete Invoice"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to delete this invoice?</div>
        <SpH5 className="pb-3">
          <div>INVOICE # {invoiceNo}</div>
        </SpH5>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteInvoice;
