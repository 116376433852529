import { INVENTORY_MODIFIERS } from '../actionTypes';

export const fetchInventoryModifiers = (action) => ({
  type: INVENTORY_MODIFIERS.FETCH,
  payload: action.payload,
});

export const updateInventoryModifer = (action) => ({
  type: INVENTORY_MODIFIERS.UPDATE,
  payload: action.payload,
});

export const addInventoryModifer = (action) => ({
  type: INVENTORY_MODIFIERS.ADD,
  payload: action.payload,
});

export const deleteInventoryModifer = (action) => ({
  type: INVENTORY_MODIFIERS.DELETE,
  payload: action.payload,
});

export const fetchInventorySortModifer = (action) => ({
  type: INVENTORY_MODIFIERS.ORDER_MODIFIERS_FETCH,
  payload: action.payload,
});

export const updateInventorySortModifer = (action) => ({
  type: INVENTORY_MODIFIERS.ORDER_MODIFIERS_UPDATE,
  payload: action.payload,
});
