/* eslint-disable */
import React from 'react';
const itemClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <g fill="none" fillRule="evenodd" strokeLinecap="round">
        <g stroke="#C0C0C0" strokeWidth="2">
            <g>
                <path d="M0 0L12.936 12.936" transform="translate(-1355 -28) translate(1356 29)"/>
                <path d="M0 0L12.936 12.936" transform="translate(-1355 -28) translate(1356 29) matrix(-1 0 0 1 12.936 0)"/>
            </g>
        </g>
    </g>
</svg>
);
export default itemClose;