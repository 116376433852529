import { SETTLEMENT } from '../actionTypes';

export const addImsSettlement = action => ({
  type: SETTLEMENT.IMS_ADD,
  payload: action.payload,
});

export const addStripeSettlement = action => ({
  type: SETTLEMENT.STRIPE_ADD,
  payload: action.payload,
});

export const addAffinipaySettlement = action => ({
  type: SETTLEMENT.AFFINIPAY_ADD,
  payload: action.payload,
});

export const fetchCurrency = action => ({
  type: SETTLEMENT.FETCH_CURRENCY,
  payload: action.payload,
});

export const fetchSettlementRun = action => ({
  type: SETTLEMENT.FETCH_RUN,
  payload: action.payload,
});
