import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import PartnerList from './PartnerList';
import PartnerTransactions from './TransactionsList';
import PartnerEarnings from './Earnings';

const Partner = () => (
  <Switch>
    <Route exact path="/partner" component={PartnerList} />
    <Route exact path="/partner/transactions" component={PartnerTransactions} />
    <Route exact path="/partner/earnings" component={PartnerEarnings} />
  </Switch>
);

export default Partner;
