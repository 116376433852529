import React from 'react';
import { Col, Row } from 'antd';
import {
  SpText,
  SpH5,
  SpForm,
  SpStatusTag,
} from 'components/DesignKit';

import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';

type Props = {
  card: Object,
  cardDetails: Object,
  country: String,
  paymentGid: String,
}

const Upi = (props: Props) => {
  const {
    card,
    cardDetails,
    country,
    paymentGid,
  } = props;

  if (card) {
    return (
      <Col span={6}>
        <div>
          <span>Payment Method</span>
        </div>
        <div>
          <SpText fontSize="18px" fontWeight="600">
            {`${card.vpa} (UPI)`}
          </SpText>
        </div>
      </Col>
    );
  }
  if (cardDetails && cardDetails.paymentMethod && cardDetails.paymentMethod.upi) {
    return (
      <SpForm>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Customer Name</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {(cardDetails.paymentMethod.customer && cardDetails.paymentMethod.customer.name)
                ? cardDetails.paymentMethod.customer.name : <>&#8211;</>}
            </SpH5>
          </Col>
          <Col span={3}>
            <span>ID</span>
          </Col>
          <Col span={6}>
            <SpH5>
              {(cardDetails.paymentMethod.upi && cardDetails.paymentMethod.upi.gid)
                ? cardDetails.paymentMethod.upi.gid : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Country</span>
          </Col>
          <Col span={9}>
            <SpH5>{country || <>&#8211;</>}</SpH5>
          </Col>
          <Col span={3}>
            <span>Address</span>
          </Col>
          <Col span={6}>
            <SpStatusTag style={{ backgroundColor: getPaymentBackground('Provided') }}>
              {getPaymentStatus('Provided')}
            </SpStatusTag>
          </Col>
          <Col span={24} className="d-flex justify-content-end mt-4">
            <SpH5 color="#7f7f7f">{paymentGid}</SpH5>
          </Col>
        </Row>
      </SpForm>
    );
  } return null;
};

export default Upi;
