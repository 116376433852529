import { CUSTOMER_SUPPORT } from '../actionTypes';

export const fetchShops = (action) => ({
  type: CUSTOMER_SUPPORT.FETCH_SHOPS,
  payload: action.payload,
});

export const fetchSupport = (action) => ({
  type: CUSTOMER_SUPPORT.FETCH_SUPPORTS,
  payload: action.payload,
});

export const updateSupportType = (action) => ({
  type: CUSTOMER_SUPPORT.SUPPORTS_UPDATE,
  payload: action.payload,
});

export const fetchFilterSupport = (action) => ({
  type: CUSTOMER_SUPPORT.FETCH_FILTER_SUPPORTS,
  payload: action.payload,
});

export const fetchQuickCustomerSupport = (action) => ({
  type: CUSTOMER_SUPPORT.QUICKSUPPORT_FETCH,
  payload: action.payload,
});
