import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  INVENTORY_RESERVATIONS_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryReservationsDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.SHOP_RESERVATION}/${gid}`);

const deleteSelectedReservation = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.SHOP_RESERVATION}/${gid}`, params);

export function* getInventoryReservationsDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryReservationsDetails, action.payload);
      yield put({ type: INVENTORY_RESERVATIONS_DETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchInventoryReservationsDetails, action);
      yield put({ type: INVENTORY_RESERVATIONS_DETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: INVENTORY_RESERVATIONS_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* cancelInventoryReservations(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteSelectedReservation, action.payload);
    const { data: { entity } } = yield call(fetchInventoryReservationsDetails, action.payload.gid);
    yield put({ type: INVENTORY_RESERVATIONS_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: action.payload && action.payload.params && action.payload.params.status === 'CANCELED'
        ? 'Reservation cancelled'
        : 'Table status changed successfully!',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
