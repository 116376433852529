// eslint-disable-next-line import/named
import { SP_OBJECT } from '../actionTypes';

export const fetchSpObject = action => ({
  type: SP_OBJECT.FETCH,
  payload: action.payload,
});

export const fetchAllSpObject = action => ({
  type: SP_OBJECT.FETCH_ALL,
  payload: action.payload,
});

export const addSpObject = (action) => ({
  type: SP_OBJECT.ADD,
  payload: action.payload,
});

export const updateSpObject = (action) => ({
  type: SP_OBJECT.UPDATE,
  payload: action.payload,
});

export const deleteSpObject = (action) => ({
  type: SP_OBJECT.DELETE,
  payload: action.payload,
});

export const setSelectedUser = (action) => ({
  type: SP_OBJECT.SELECTED,
  payload: action.payload,
});
