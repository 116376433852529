import { WALLET_TRANSACTION_DETAILS } from '../actionTypes';

export const fetchWalletTransactionDetails = (action) => ({
  type: WALLET_TRANSACTION_DETAILS.FETCH,
  payload: action.payload,
});

export const applyCashBack = (action) => ({
  type: WALLET_TRANSACTION_DETAILS.ADD,
  payload: action.payload,
});
