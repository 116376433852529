import { call, put } from 'redux-saga/effects';
import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  POSKEY,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchPoskey = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.POSKEY}${query}${sorting}`);
};

const patchPoskey = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.POSKEY}/${gid}`, params);

export function* getPoskey(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchPoskey, action.payload);
    yield put({ type: POSKEY.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: POSKEY.FAILED, payload: e.response.data });
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updatePoskey(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchPoskey, action.payload);
    const { data: { entity } } = yield call(fetchPoskey, action.payload);
    yield put({ type: POSKEY.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.POSKEY.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
