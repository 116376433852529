import React from 'react';
import { connect } from 'react-redux';
import {
  Select,
  Checkbox,
  Input,
  DatePicker,
} from 'antd';
import {
  SpText,
} from 'components/DesignKit';
import moment from 'moment-timezone';
import { FILTER_TYPES } from 'appconstants';
import formatAmount from 'utils/formatAmount';
import getFilterOperators from 'utils/getFilterOperators';

const { Option } = Select;
const dateFormat = 'MMM DD, YYYY';
const { RangePicker } = DatePicker;

type Props = {
  filtersGrid: Array<Object>,
  filterTypes: Array,
  handleChange: Function,
  onCheck: Function,
  handleOperator: Function,
  handleValue: Function,
  currentDate: string,
  startDate: string,
  endDate: string,
  handleFromValue: Function,
  handleToValue: Function,
  stuses: Array<Object>,
  selectedAccount: Object,
  fiterText: String,
  placeholder: String,
}

const Filters = (props: Props) => {
  const {
    filtersGrid,
    filterTypes,
    handleChange,
    onCheck,
    handleOperator,
    handleValue,
    currentDate,
    startDate,
    endDate,
    handleFromValue,
    handleToValue,
    stuses,
    selectedAccount,
    fiterText,
    placeholder,
  } = props;

  const currencyType = selectedAccount && selectedAccount.currency;
  const placeholderText = placeholder || 'Select status';

  const handleFormater = (e, index, valueType) => {
    const fieldNamePercentage = filtersGrid.filter((item) => item.filterName === 'percentage');
    const fieldNameAccounts = filtersGrid.filter((item) => item.filterName === 'partnerEarnings.totalAccounts');
    const fieldNameTaransactions = filtersGrid.filter((item) => item.filterName === 'partnerEarnings.totalTransactions');
    const fieldTypePercentage = fieldNamePercentage[0] && fieldNamePercentage[0].filterType;
    const fieldTypeAccounts = fieldNameAccounts[0] && fieldNameAccounts[0].filterType;
    const fieldTypeTaransactions = fieldNameTaransactions[0] && fieldNameTaransactions[0].filterType;
    if (e.currentTarget && e.currentTarget.value) {
      if (valueType === 'equalValue') {
        if ((fieldTypePercentage === 'Number') || (fieldTypeAccounts === 'Number') || (fieldTypeTaransactions === 'Number')) {
          const amount = e.currentTarget.value;
          handleValue(amount, index);
        } else {
          const amount = formatAmount(e.currentTarget.value, currencyType);
          handleValue(amount, index);
        }
      } else if (valueType === 'fromValue') {
        if ((fieldTypePercentage === 'Number') || (fieldTypeAccounts === 'Number') || (fieldTypeTaransactions === 'Number')) {
          const amount = e.currentTarget.value;
          handleFromValue(amount, index);
        } else {
          const amount = formatAmount(e.currentTarget.value, currencyType);
          handleFromValue(amount, index);
        }
      } else if (valueType === 'toValue') {
        if ((fieldTypePercentage === 'Number') || (fieldTypeAccounts === 'Number') || (fieldTypeTaransactions === 'Number')) {
          const amount = e.currentTarget.value;
          handleToValue(amount, index);
        } else {
          const amount = formatAmount(e.currentTarget.value, currencyType);
          handleToValue(amount, index);
        }
      }
    }
  };

  return (
    <>
      {filtersGrid.map((item, i) => (
        <div className="px-3 mt-2">
          <div className="mb-2">
            <SpText color="rgba(0, 0, 0, 0.6)">Select filter type</SpText>
            <div className="d-flex align-items-center">
              <div>
                <Checkbox
                  checked={item.checked}
                  className="mr-2"
                  onChange={({ target: { checked } }) => {
                    onCheck(checked, i);
                  }}
                />
              </div>
              <div style={{ width: '92%' }}>
                <Select
                  style={{ width: '100%' }}
                  value={item.filterNameDisplay || 'Select filter type'}
                  onChange={(e) => handleChange(e, i)}
                >
                  {
                    filterTypes.map((option) => (
                      <Option
                        key={option.id}
                        value={[
                          option.display,
                          option.value,
                        ]}
                      >
                        {option.display}
                      </Option>
                    ))
                  }
                </Select>
              </div>
            </div>
          </div>
          <div className="pl-4">
            <div className="mb-2">
              <SpText color="rgba(0, 0, 0, 0.6)">Select operator type</SpText>
              <div>
                <Select
                  style={{ width: '100%' }}
                  value={item.filterOperator || 'Select operator type'}
                  onChange={(e) => handleOperator(e, i)}
                >
                  {getFilterOperators(filtersGrid[i].filterName).map((operator) => (
                    <Option
                      key={operator.id}
                      value={operator.value}
                    >
                      {operator.display}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {filtersGrid[i].filterType === FILTER_TYPES.DATE ? (
              <>
                {filtersGrid[i].filterOperator !== 'BT' ? (
                  <div className="mb-2">
                    <SpText color="rgba(0, 0, 0, 0.6)">Value</SpText>
                    {currentDate && currentDate.format(dateFormat) !== 'Invalid date' ? (
                      <DatePicker
                        defaultValue={moment(currentDate, dateFormat)}
                        format={dateFormat}
                        allowClear={false}
                        onChange={(e) => handleValue(e, i)}
                      />
                    ) : (
                      <DatePicker
                        format={dateFormat}
                        allowClear={false}
                        onChange={(e) => handleValue(e, i)}
                      />
                    )}
                  </div>
                ) : (
                  <div className="mb-2">
                    <SpText color="rgba(0, 0, 0, 0.6)">Value</SpText>
                    {
                      startDate && startDate.format(dateFormat) !== 'Invalid date' ? (
                        <RangePicker
                          defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                          format={dateFormat}
                          allowClear={false}
                          onChange={(e) => handleValue(e, i)}
                        />
                      ) : (
                        <RangePicker
                          format={dateFormat}
                          allowClear={false}
                          onChange={(e) => handleValue(e, i)}
                        />
                      )
                      }
                  </div>
                )}
              </>
            ) : (
              <>
                {filtersGrid[i].filterType === FILTER_TYPES.NUMBER ? (
                  <>
                    {filtersGrid[i].filterOperator === 'BT' ? (
                      <div className="d-flex mb-2">
                        <div className="w-50 mr-3">
                          <SpText color="rgba(0, 0, 0, 0.6)">From</SpText>
                          <Input
                            placeholder="From"
                            className="mr-2"
                            value={item.filterValueFrom}
                            onChange={(e) => handleFromValue(e, i)}
                            onKeyPress={e => {
                              const keyCode = e.charCode || e.which;
                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                e.preventDefault();
                              }
                            }}
                            onBlur={e => handleFormater(e, i, 'fromValue')}
                          />
                        </div>
                        <div className="w-50">
                          <SpText color="rgba(0, 0, 0, 0.6)">To</SpText>
                          <Input
                            placeholder="To"
                            value={item.filterValueTo}
                            onChange={(e) => handleToValue(e, i)}
                            onKeyPress={e => {
                              const keyCode = e.charCode || e.which;
                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                e.preventDefault();
                              }
                            }}
                            onBlur={e => handleFormater(e, i, 'toValue')}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="mb-2">
                        <SpText color="rgba(0, 0, 0, 0.6)">Value</SpText>
                        <Input
                          placeholder={fiterText || '0.00'}
                          value={item.filterValue}
                          onChange={(e) => handleValue(e, i)}
                          onKeyPress={e => {
                            const keyCode = e.charCode || e.which;
                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                              e.preventDefault();
                            }
                          }}
                          onBlur={e => handleFormater(e, i, 'equalValue')}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {filtersGrid[i].filterType === FILTER_TYPES.DROPDOWN ? (
                      <div className="mb-2">
                        <SpText color="rgba(0, 0, 0, 0.6)">Value</SpText>
                        <div>
                          <Select
                            style={{ width: '100%' }}
                            value={item.filterValue || placeholderText}
                            onChange={(e) => handleValue(e, i)}
                          >
                            {stuses.map((status) => (
                              <Option
                                key={status.id}
                                value={status.value}
                              >
                                {status.display}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    ) : (
                      <div className="mb-2">
                        <SpText color="rgba(0, 0, 0, 0.6)">Value</SpText>
                        <Input
                          placeholder="Enter value"
                          value={item.filterValue}
                          onChange={(e) => handleValue(e, i)}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedAccount: state.account.selectedAccount,
});

export default connect(mapStateToProps, null)(Filters);
