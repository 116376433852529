// @flow
import React, {
  useState, useRef, useImperativeHandle, forwardRef,
} from 'react';
import {
  Row,
  Col,
  Select,
  Input,
  DatePicker,
} from 'antd';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpForm,
  SpError,
  SpText,
} from 'components/DesignKit';

import {
  COUNTRY_CODES,
} from 'appconstants';

import getStateList from 'utils/stateList';

const { Option } = Select;

type Props = {
  selectedAccount: Object,
  zipTag: String,
  disputeEvidence: Object,
  disableFields: Boolean,
  displayFormat: String,
}

const PhysicalProduct = (props: Props, ref) => {
  const {
    selectedAccount,
    zipTag,
    disputeEvidence,
    disableFields,
    displayFormat,
  } = props;
  const [, forceUpdate] = useState();
  // eslint-disable-next-line max-len
  const [shippingAddress, setShippingAddress] = useState((disputeEvidence && disputeEvidence.billingAddress) ? (disputeEvidence.billingAddress && disputeEvidence.billingAddress.street) : null);
  // eslint-disable-next-line max-len
  const [shippingCity, setShippingCity] = useState((disputeEvidence && disputeEvidence.billingAddress) ? (disputeEvidence.billingAddress && disputeEvidence.billingAddress.city) : null);
  // eslint-disable-next-line max-len
  const [shippingState, setShippingState] = useState((disputeEvidence && disputeEvidence.billingAddress) ? (disputeEvidence.billingAddress && disputeEvidence.billingAddress.state) : null);
  const [statesInCountry, setStatesInCountry] = useState(getStateList((selectedAccount && selectedAccount.country && selectedAccount.country.id)));
  // eslint-disable-next-line max-len
  const [shippingPinCode, setShippingPinCode] = useState((disputeEvidence && disputeEvidence.billingAddress) ? (disputeEvidence.billingAddress && disputeEvidence.billingAddress.postalCode) : null);
  const [shippingDate, setShippingDate] = useState(displayFormat || null);
  const [trackingNumber, setTrackingNumber] = useState((disputeEvidence && disputeEvidence.trackingNumber) ? disputeEvidence.trackingNumber : null);
  const [courierName, setCourierName] = useState((disputeEvidence && disputeEvidence.carrierName) ? disputeEvidence.carrierName : null);
  // eslint-disable-next-line max-len
  const [shippingCountryId, setShippingCountryId] = useState((disputeEvidence && disputeEvidence.billingAddress) ? (disputeEvidence.billingAddress.country && disputeEvidence.billingAddress.country.id) : selectedAccount.country.id);
  const [fieldsRequired, setFieldsRequired] = useState('');

  const simpleValidator = useRef(new SimpleReactValidator());

  const onCountryUpdate = (country) => {
    setStatesInCountry(getStateList(country));
    setShippingCountryId(country);
    setFieldsRequired(country);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      fieldsRequired,
      shippingAddress,
      shippingCity,
      shippingState,
      shippingPinCode,
      shippingCountryId,
      shippingDate,
      trackingNumber,
      courierName,
    }),
  }));

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Shipping address</SpText>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Shipping address"
              defaultValue={shippingAddress}
              disabled={disableFields}
              onChange={(e) => { setFieldsRequired(e.currentTarget.value); setShippingAddress(e.currentTarget.value); }}
            />
            <SpError>
              {fieldsRequired && simpleValidator.current.message('Address', shippingAddress, 'required')}
            </SpError>
          </Col>
        </Row>
      </SpForm>
      <Row type="flex">
        <Col span={11}>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={7}>
                <SpText>City</SpText>
              </Col>
              <Col span={13}>
                <Input
                  placeholder="City"
                  defaultValue={shippingCity}
                  disabled={disableFields}
                  onChange={(e) => { setFieldsRequired(e.currentTarget.value); setShippingCity(e.currentTarget.value); }}
                />
                <SpError>
                  {fieldsRequired && simpleValidator.current.message('City', shippingCity, 'required')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
        </Col>
        <Col span={11}>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={7}>
                <SpText>State</SpText>
              </Col>
              <Col span={13}>
                <div className="w-100">
                  <Select
                    placeholder="Select State"
                    className="w-100"
                    disabled={disableFields}
                    value={shippingState || 'Select State'}
                    onChange={(e) => { setFieldsRequired(e); setShippingState(e); }}
                  >
                    {statesInCountry.map((item) => (
                      <Option
                        className="text-capitalize"
                        key={1}
                        value={item.id}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                  <SpError>
                    {fieldsRequired && simpleValidator.current.message('State', shippingState, 'required')}
                  </SpError>
                </div>
              </Col>
            </Row>
          </SpForm>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={11}>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={7}>
                <SpText>{zipTag}</SpText>
              </Col>
              <Col span={13}>
                <Input
                  placeholder={zipTag}
                  defaultValue={shippingPinCode}
                  disabled={disableFields}
                  onChange={(e) => { setFieldsRequired(e.currentTarget.value); setShippingPinCode(e.currentTarget.value); }}
                />
                <SpError>
                  {fieldsRequired && simpleValidator.current.message(zipTag, shippingPinCode, 'required|numeric')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
        </Col>
        <Col span={11}>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={7}>
                <SpText>Country</SpText>
              </Col>
              <Col span={13}>
                <div className="w-100">
                  <Select
                    placeholder="Select Country"
                    className="w-100"
                    disabled={disableFields}
                    defaultValue={shippingCountryId}
                    onChange={onCountryUpdate}
                  >
                    {COUNTRY_CODES.map((item) => (
                      <Option
                        className="text-capitalize"
                        key={1}
                        value={item.ID}
                      >
                        {item.NAME}
                      </Option>
                    ))}
                  </Select>
                  <SpError>
                    {fieldsRequired && simpleValidator.current.message('Country', shippingCountryId, 'required')}
                  </SpError>
                </div>
              </Col>
            </Row>
          </SpForm>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={11}>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={7}>
                <SpText>Courier Name</SpText>
              </Col>
              <Col span={13}>
                <Input
                  placeholder="Courier Name"
                  defaultValue={courierName}
                  disabled={disableFields}
                  onChange={e => setCourierName(e.currentTarget.value)}
                />
              </Col>
            </Row>
          </SpForm>
        </Col>
        <Col span={11}>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={7}>
                <SpText>Tracking Number</SpText>
              </Col>
              <Col span={13}>
                <Input
                  placeholder="Tracking Number"
                  defaultValue={trackingNumber}
                  disabled={disableFields}
                  onChange={e => setTrackingNumber(e.currentTarget.value)}
                />
              </Col>
            </Row>
          </SpForm>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Shipping Date</SpText>
          </Col>
          <Col span={6}>
            <DatePicker
              defaultValue={shippingDate ? moment(shippingDate) : ''}
              allowClear={false}
              disabled={disableFields}
              onChange={(date, dateString) => setShippingDate(dateString)}
            />
          </Col>
        </Row>
      </SpForm>
    </>
  );
};

// $FlowFixMe
export default forwardRef(PhysicalProduct);
