import React from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  SpForm,
  SpH5,
} from 'components/DesignKit';

type Props = {
  serviceDetails: Object,
  selectedAccount: Object,
};

const NewTerminal = (props: Props) => {
  const {
    serviceDetails,
    selectedAccount,
  } = props;

  const zipTag = selectedAccount.country.alpha2 === 'IN' ? 'Pin Code' : 'Zip Code';

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Contact Name</span>
          </Col>
          <Col span={9}>
            <SpH5>{serviceDetails.name ? serviceDetails.name : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Contact No.</span>
          </Col>
          <Col span={9}>
            <SpH5>{serviceDetails.contactNumber ? serviceDetails.contactNumber : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Type</span>
          </Col>
          <Col span={9}>
            <SpH5>{serviceDetails.serviceRequest ? serviceDetails.serviceRequest : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Field Agent</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {serviceDetails.fieldAgentLookup && serviceDetails.fieldAgentLookup.user
                ? serviceDetails.fieldAgentLookup.user && serviceDetails.fieldAgentLookup.user.givenName
                : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
      </SpForm>
      <hr />
      <SpForm>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Street</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {serviceDetails.terminalAddress ? serviceDetails.terminalAddress.street : <>&#8211;</>}
            </SpH5>
          </Col>
          <Col span={3}>
            <span>State</span>
          </Col>
          <Col span={8}>
            <SpH5>{serviceDetails.terminalAddress ? serviceDetails.terminalAddress.state : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>City</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {serviceDetails.terminalAddress ? serviceDetails.terminalAddress.city : <>&#8211;</>}
            </SpH5>
          </Col>
          <Col span={3}>
            <span>Country</span>
          </Col>
          <Col span={8}>
            <SpH5>
              {serviceDetails.terminalAddress ? serviceDetails.terminalAddress.country.name : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>{zipTag}</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {serviceDetails.address ? serviceDetails.address.postalCode : <>&#8211;</>}
            </SpH5>
          </Col>
          <Col span={3}>
            <span>No. Terminals</span>
          </Col>
          <Col span={8}>
            <SpH5>
              {serviceDetails.noOfTerminals ? serviceDetails.noOfTerminals : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <SpH5 color="#7f7f7f">{serviceDetails && serviceDetails.gid}</SpH5>
          </Col>
        </Row>
      </SpForm>
    </>
  );
};

export default NewTerminal;
