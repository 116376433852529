import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Checkbox,
  Button,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  OFFLINE_PAYMENT_TYPES,
} from 'appconstants';

import {
  SpButton,
} from 'components/DesignKit';


type Props = {
  internalAccount: Object,
  submit: Function,
  openModal: Function,
};

const OfflinePaymentType = (props: Props) => {
  const {
    internalAccount,
    submit,
    openModal,
  } = props;

  const paymentOptions = OFFLINE_PAYMENT_TYPES;
  const [offlinePaymentData, setOfflinePaymentData] = useState((internalAccount && internalAccount.supportedOfflinePaymentTypes));
  const [disabled, setDisabled] = useState(true);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());

  const updateOfflinePayment = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      offlinePaymentData,
    });
  };

  const onOfflineChange = (checkedValues) => {
    setOfflinePaymentData(checkedValues);
    setDisabled(false);
    openModal(true);
  };

  useEffect(() => {
    setOfflinePaymentData(internalAccount && internalAccount.supportedOfflinePaymentTypes);
    setDisabled(true);
  }, [openModal]);

  const reset = () => {
    setOfflinePaymentData(internalAccount && internalAccount.supportedOfflinePaymentTypes);
    setDisabled(true);
    openModal(false);
  };

  return (
    <>
      <div>
        <Row className="mb-2">
          <Checkbox.Group
            style={{ width: '100%' }}
            options={paymentOptions}
            value={offlinePaymentData}
            onChange={onOfflineChange}
          />
        </Row>
        <Row className="mt-4" style={{ marginLeft: '-15px' }}>
          <Col span={2}>
            <Button
              type="link"
              onClick={reset}
              disabled={disabled}
            >
              Reset
            </Button>
          </Col>
          <Col span={2}>
            <SpButton
              type="primary"
              shape="round"
              disabled={disabled}
              onClick={updateOfflinePayment}
            >
              Update
            </SpButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(OfflinePaymentType);
