import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import BankStatementList from './components/BankStatementList';
import BankStatementDetails from './components/BankStatementDetails';

const BankStatement = () => (
  <Switch>
    <Route exact path="/bankstatement" component={BankStatementList} />
    <Route exact path="/bankstatement/:gid" component={BankStatementDetails} />
  </Switch>
);

export default BankStatement;
