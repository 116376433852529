import { SALESFORCE } from '../actionTypes';

export const fetchSalesforce = (action) => ({
  type: SALESFORCE.FETCH,
  payload: action.payload,
});

export const addSalesforce = (action) => ({
  type: SALESFORCE.ADD,
  payload: action.payload,
});

export const fetchSpObjectList = (action) => ({
  type: SALESFORCE.SPOBJECT_FETCH,
  payload: action.payload,
});

export const updateCredentials = (action) => ({
  type: SALESFORCE.CREDENTIALS_UPDATE,
  payload: action.payload,
});

export const fetchSpField = (action) => ({
  type: SALESFORCE.SPFIELD_FETCH,
  payload: action.payload,
});

export const addSalesforceMapping = (action) => ({
  type: SALESFORCE.ADD_MAPPINGS,
  payload: action.payload,
});

export const fetchMappingList = (action) => ({
  type: SALESFORCE.FETCH_MAPPINGLIST,
  payload: action.payload,
});

export const fetchMappingDetails = (action) => ({
  type: SALESFORCE.FETCH_MAPPINGDETAILS,
  payload: action.payload,
});

export const updateSalesforceMapping = (action) => ({
  type: SALESFORCE.UPDATE_MAPPINGS,
  payload: action.payload,
});

export const deleteSalesforceMapping = (action) => ({
  type: SALESFORCE.DELETE_MAPPINGS,
  payload: action.payload,
});
