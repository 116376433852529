import { ORDERS_STATUSES } from 'appconstants';

export default value => {
  if (value === 'OPEN') {
    return ORDERS_STATUSES.OPEN;
  } if (value === 'IN_PROGRESS') {
    return ORDERS_STATUSES.IN_PROGRESS;
  } if (value === 'CLOSED') {
    return ORDERS_STATUSES.CLOSED;
  } if (value === 'CREATED') {
    return ORDERS_STATUSES.CREATED;
  } if (value === 'CANCELLED') {
    return ORDERS_STATUSES.CANCELLED;
  } return ORDERS_STATUSES.NO_STATUS;
};
