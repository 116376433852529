import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Button,
  Icon,
} from 'antd';
import {
  SpFormText,
  LoginWrapper,
  LoginFormWrapper,
} from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import { useLocation } from 'react-router-dom';

import {
  axiosLoginInstance,
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  AUTH_CHALLENGES,
  PAGES,
  TOKENS,
  MESSAGES,
  ROLES,
} from 'appconstants';
import {
  validators,
  validationRegex,
} from 'utils/validationMessages';

import {
  notificationActions,
  authActions,
  accountActions,
  userActions,
} from 'store/actions';
import {
  NOTIFICATION,
  ACCOUNT,
  AUTH,
  USER,
} from 'store/actionTypes';

import swireLogo from 'imgs/swirepay.png';

type Props = {
  authenticateUserSuccess: Function,
  fetchAccountSuccess: Function,
  setNotification: Function,
  setUser: Function,
  setSelectedAccount: Function,
  setTestData: Function,
  logout: Function,
  history: {
    replace: Function,
    push: Function,
  },
  isMobileView: Boolean,
};

const AuthChallenge = (props: Props) => {
  const {
    setNotification,
    authenticateUserSuccess,
    fetchAccountSuccess,
    setSelectedAccount,
    setUser,
    setTestData,
    history,
    logout,
    isMobileView,
  } = props;

  const [, forceUpdate] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [affiliateCode, setAffiliateCode] = useState('');

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      confirmPassword: {
        message: validators.setupPassword.validConfirmPassword,
        rule: (val, params) => val === params[0],
      },
      passwordValidate: {
        message: validators.setupPassword.validPassword,
        rule: val => validationRegex.password.test(val),
      },
    },
  }));

  const location = useLocation();

  useEffect(() => {
    const referral = localStorage.getItem(TOKENS.REFERRAL_CODE);
    const affiliate = localStorage.getItem(TOKENS.AFFILIATE_CODE);
    if (referral) {
      setReferralCode(referral);
    } else if (affiliate) {
      setAffiliateCode(affiliate);
    }
  }, []);

  useEffect(() => {
    // email is the only query param received
    const emailReceived = location.search && location.search.split('=')[1];
    const session = localStorage.getItem(TOKENS.SESSION_TOKEN);
    if ((!session) || (!emailReceived)) {
      // one has to come from login page to be able to land on this page
      history.replace(PAGES.LOGIN);
      return;
    }
    setEmail(emailReceived);
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setLoading(true);

    try {
      const { data: { entity } } = await axiosLoginInstance.post(API_END_POINTS.RESPOND_CHALLENGE, {
        challengeName: AUTH_CHALLENGES.NEW_PASSWORD_REQUIRED,
        session: localStorage.getItem(TOKENS.SESSION_TOKEN),
        challengeResponses: {
          NEW_PASSWORD: password,
          USERNAME: email,
        },
      });
      if (entity.token) {
        localStorage.setItem(TOKENS.ACCESS_TOKEN, entity.token.accessToken);
      }
      localStorage.removeItem(TOKENS.SESSION_TOKEN);

      const { data: { entity: { token } } } = await axiosLoginInstance.post(API_END_POINTS.AUTHENTICATE, {
        email,
        password,
      });
      if (token) {
        localStorage.setItem(TOKENS.ACCESS_TOKEN, token.accessToken);
        localStorage.setItem(TOKENS.REFRESH_TOKEN, token.refreshToken);

        // fetch user
        const { data: { entity: userEntity } } = await axiosAuthInstance.get(API_END_POINTS.USER_PROFILE);
        authenticateUserSuccess(entity.token);
        setUser(userEntity);
        setTestData(userEntity.test);
        const role = userEntity.role && userEntity.role.name ? userEntity.role.name : '';
        const internalRole = userEntity.internalRole && userEntity.internalRole.name ? userEntity.internalRole.name : '';
        if (internalRole && ((internalRole === ROLES.ONBOARDING) || (internalRole === ROLES.INTERNALSUPPORT))) {
          setLoading(false);
          setNotification({
            type: NOTIFICATION.SUCCESS,
            payload: MESSAGES.UPDATEPASSWORD,
          });
          logout();
          history.push(PAGES.LOGIN);
        } else {
          // fetch account
          const { data: { entity: accountEntity } } = await axiosAuthInstance.get(API_END_POINTS.USER_ACCOUNT);
          setSelectedAccount(accountEntity.content[0]);
          fetchAccountSuccess({
            type: ACCOUNT.SUCCESS,
            payload: accountEntity,
          });

          setLoading(false);
          if (accountEntity.content[0]) {
            if (role && role === ROLES.POS) {
              history.replace(PAGES.POS_USER);
            } else if (role && role === ROLES.ASSOCIATE) {
              props.history.replace(PAGES.KDSDASHBOARD);
            } else if (role && role === ROLES.KDSUSER) {
              props.history.replace(PAGES.KDSDASHBOARD);
            } else if (role && role === ROLES.KDSADMIN) {
              props.history.replace(PAGES.KDSDASHBOARD);
            } else if (role && role === ROLES.ANALYST) {
              props.history.replace(PAGES.KDSDASHBOARD);
            } else if (internalRole && internalRole) {
              if (internalRole && internalRole === ROLES.SUPERUSER) {
                history.push(PAGES.DASHBOARD);
              } else {
                history.push(PAGES.PAYMENTS);
              }
            } else {
              history.replace(PAGES.DASHBOARD);
            }
          } else {
            if (referralCode) {
              history.replace(`${PAGES.ACCOUNT}?referral_code=${referralCode}`);
            }
            if (affiliateCode) {
              history.replace(`${PAGES.ACCOUNT}?affiliate_code=${affiliateCode}`);
            }
            if (!referralCode && !affiliateCode) {
              history.replace(PAGES.ACCOUNT);
            }
          }
        }
      }
    } catch (e) {
      if (e.response.status !== 401) {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
        });
      } else if (e.response.status === 401) {
        history.push({
          pathname: `${PAGES.LOGIN}`,
          state: { errorMsg: MESSAGES.SESSION_ERROR },
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      <LoginWrapper>
        <LoginFormWrapper
          onSubmit={handleSubmit}
          style={{
            width: isMobileView ? '900px' : '',
            height: isMobileView ? '900px' : '',
            paddingTop: isMobileView ? '0px' : '',
            paddingBottom: isMobileView ? '0px' : '',
          }}
        >
          <div style={{ maxWidth: isMobileView ? '800px' : '350px' }}>
            <div className="d-flex justify-content-center align-items-center">
              <img src={swireLogo} alt="" style={{ width: isMobileView ? '420px' : '270px', margin: '32px 0px' }} />
            </div>
            <SpFormText fontSize={isMobileView ? '26px' : '18px'}>SETUP NEW PASSWORD</SpFormText>
            <div className="LOGIN">
              <div className="my-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>New Password</label>
                </div>
                <Input.Password
                  style={{ width: isMobileView ? '600px' : '', height: isMobileView ? '50px' : '' }}
                  placeholder="Your Password"
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="text-danger w-100">
                  {simpleValidator.current.message('password', password, 'required|passwordValidate',
                    { messages: { required: validators.setupPassword.newPassword } })}
                </div>
              </div>

              <div className="my-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Confirm New Password</label>
                </div>
                <Input.Password
                  style={{ width: isMobileView ? '600px' : '', height: isMobileView ? '50px' : '' }}
                  placeholder="Your Password"
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('Confirm Password', confirmPassword, `required|confirmPassword:${password}`,
                    { messages: { required: validators.setupPassword.confirmPassword } })}
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  type="primary"
                  shape="round"
                  className="w-50"
                  htmlType="submit"
                  loading={loading}
                  onClick={handleSubmit}
                  style={{ fontSize: isMobileView ? '18px' : '' }}
                >
                  Set Password
                </Button>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <Button
                  type="link"
                  block
                  onClick={() => history.replace(PAGES.LOGIN)}
                  style={{ fontSize: isMobileView ? '18px' : '' }}
                >
                  Go to Homepage
                </Button>
              </div>
            </div>
          </div>
        </LoginFormWrapper>
      </LoginWrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  authenticateUserSuccess: (param) => dispatch(authActions.authenticateUserSuccess({
    type: AUTH.SUCCESS,
    payload: param,
  })),
  setUser: (param) => dispatch(userActions.setUser({
    type: USER.FETCH,
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setTestData: (param) => dispatch(accountActions.setTestData({
    payload: param,
  })),
  logout: () => dispatch(authActions.logout()),
  fetchAccountSuccess: (params) => dispatch(accountActions.fetchAccountSuccess(params)),
  setSelectedAccount: accounts => dispatch(accountActions.setSelectedAccount(accounts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthChallenge);
