/* eslint-disable */
import React from 'react';

const hyperlinkIcon = ({ fill = "silver" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 26 26">
    <g fill={fill}>
      <path d="M16.923 12.817v6.106H3.077V5.077h4.615V2H1.538C.69 2 0 2.69 0 3.538v16.924C0 21.312.69 22 1.538 22h16.924c.85 0 1.538-.688 1.538-1.538v-7.645h-3.077z" />
      <path d="M24.683 5.038L18.35.163a.77.77 0 00-.828-.077.815.815 0 00-.439.727V3.25h-2.375C9.906 3.25 6 7.26 6 12.188a.81.81 0 00.61.79.79.79 0 00.89-.427l.186-.381c1.215-2.495 3.656-4.045 6.373-4.045h3.024v2.438c0 .307.17.588.437.726.268.138.59.109.83-.076l6.333-4.875a.82.82 0 00.317-.65.82.82 0 00-.317-.65z" />
    </g>
  </svg>
);

export default hyperlinkIcon;
