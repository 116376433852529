import { COUPON } from '../actionTypes';

const initialState = {
  content: [],
  customers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COUPON.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case COUPON.SUCCESS_CUSTOMERS:
      return {
        ...state,
        customers: action.payload.content,
      };
    default:
      return state;
  }
};
