import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  ACQUIRER,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchAcquirer = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  const size = payload.acquireParams;
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`/internal${API_END_POINTS.ACQUIRER}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`/internal${API_END_POINTS.ACQUIRER}${query}${sorting}&${payload.filterParams}`);
  }
  if (payload.acquireParams && payload.sortParams) {
    return axiosAuthInstance.get(`/internal${API_END_POINTS.ACQUIRER}${size}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`/internal${API_END_POINTS.ACQUIRER}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`/internal${API_END_POINTS.ACQUIRER}${query}${sorting}`);
};

const postAcquirer = async ({ params }) => axiosAuthInstance.post(`/internal${API_END_POINTS.ACQUIRER}`, params);

const patchAcquirer = async ({ id, params }) => axiosAuthInstance.patch(`/internal${API_END_POINTS.ACQUIRER}/${id}`, params);

const acquirerDelete = async ({ gid }) => axiosAuthInstance.delete(`/internal${API_END_POINTS.ACQUIRER}/${gid}`);

const fetchAcquirerSearch = async (payload) => {
  const queryParam = `?name.LIKE=${payload}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=DESC';
  return axiosAuthInstance.get(`/internal${API_END_POINTS.ACQUIRER}${queryParam}${sorting}`);
};

export function* getAcquirer(action) {
  try {
    const { data: { entity } } = yield call(fetchAcquirer, action.payload);
    yield put({ type: ACQUIRER.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: ACQUIRER.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addAcquirer(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postAcquirer, action.payload);
    const { data: { entity } } = yield call(fetchAcquirer, action.payload);
    yield put({ type: ACQUIRER.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACQUIRER.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteAcquirer(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(acquirerDelete, action.payload);
    const { data: { entity } } = yield call(fetchAcquirer, action.payload);
    yield put({ type: ACQUIRER.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACQUIRER.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateAcquirer(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchAcquirer, action.payload);
    const { data: { entity } } = yield call(fetchAcquirer, action.payload);
    yield put({ type: ACQUIRER.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACQUIRER.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getAcquirerSearch(action) {
  try {
    const { data: { entity } } = yield call(fetchAcquirerSearch, action.payload);
    yield put({ type: ACQUIRER.SEARCH_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: ACQUIRER.FAILED, payload: e.response.data });
  }
}
