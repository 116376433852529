// @flow
import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { SketchPicker } from 'react-color';
import {
  Row,
  Col,
  Input,
  Checkbox,
  Select,
  Divider,
} from 'antd';
import {
  SpError,
} from 'components/DesignKit';
import {
  SpText,
  SpColor,
  SpCover,
  SpPopover,
  SpWatch,
} from 'components/PaymentWidgetDesignKit';
import {
  PAYMENT_OPTIONS,
} from 'appconstants';
import SimpleReactValidator from 'simple-react-validator';
import { validators } from 'utils/validationMessages';
import star from 'imgs/star.png';

const { TextArea } = Input;
const { Option } = Select;

type Props = {
  widgetDetails: Object,
  setSampleWidget: Function,
};

const WidgetInfo = (props: Props, ref) => {
  const {
    widgetDetails,
    setSampleWidget,
  } = props;

  const [, forceUpdate] = useState();
  const [widgetTitle, setWidgetTitle] = useState('');
  const [message, setMessage] = useState('');
  const [oneTime, setOneTime] = useState(false);
  const [weekly, setWeekly] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [yearly, setYearly] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState('');
  const [suggestedInterval, setSuggestedInterval] = useState('');
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorCode, setColorCode] = useState('');
  const [intervalMsg, setIntervalMsg] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const myRef = useRef(null);
  const [fixErrors, setFixErrors] = useState(false);

  useEffect(() => {
    if (widgetDetails && widgetDetails.currencyCode) {
      setSelectedInterval(widgetDetails.defaultInterval);
      setSuggestedInterval(widgetDetails.recommendedInterval);
      setColorCode(widgetDetails.primaryColor);
      setWidgetTitle(widgetDetails.title);
      setMessage(widgetDetails.actionMessage);
      const interval = [...widgetDetails.intervals];
      const intervalList = [
        'ONETIME',
        'WEEK',
        'MONTH',
        'YEAR',
      ];

      const filteredList = intervalList.map((el) => {
        const obj = interval.filter(item => (item === el));
        return (obj[0]) ? obj[0] : null;
      });

      widgetDetails.intervals = filteredList.slice(0);

      if (interval.filter(item => item === 'ONETIME').length === 0) {
        setOneTime(false);
      } else {
        setOneTime(true);
      }
      if (interval.filter(item => item === 'WEEK').length === 0) {
        setWeekly(false);
      } else {
        setWeekly(true);
      }
      if (interval.filter(item => item === 'MONTH').length === 0) {
        setMonthly(false);
      } else {
        setMonthly(true);
      }
      if (interval.filter(item => item === 'YEAR').length === 0) {
        setYearly(false);
      } else {
        setYearly(true);
      }
    }
  }, [widgetDetails]);

  const updateTitle = (e) => {
    setWidgetTitle(e.currentTarget.value);
    widgetDetails.title = e.currentTarget.value;
    setSampleWidget(setSampleWidget);
  };

  const updateMessage = (e) => {
    setMessage(e.currentTarget.value);
    widgetDetails.actionMessage = e.currentTarget.value;
    setSampleWidget(setSampleWidget);
  };

  const updateDefaultInterval = (e) => {
    setIntervalMsg('');
    simpleValidator.current.purgeFields();
    setSelectedInterval(e);
    widgetDetails.defaultInterval = e;
    setSampleWidget(setSampleWidget);
  };

  const updateSuggestiveInterval = (e) => {
    setIntervalMsg('');
    simpleValidator.current.purgeFields();
    setSuggestedInterval(e);
    widgetDetails.recommendedInterval = e;
    setSampleWidget(setSampleWidget);
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (e) => {
    widgetDetails.primaryColor = e.hex;
    setColorCode(e.hex);
    setSampleWidget(setSampleWidget);
  };

  const updateColor = (e) => {
    setColorCode(e.currentTarget.value);
    widgetDetails.primaryColor = e.currentTarget.value;
    setSampleWidget(setSampleWidget);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const listInterval = [...widgetDetails.intervals];
      const list = listInterval.filter((item) => item !== null);
      const defaultList = listInterval.filter((item) => item === widgetDetails.defaultInterval);
      const recommendedList = listInterval.filter((item) => item === widgetDetails.recommendedInterval);
      if ((defaultList && defaultList.length === 0) && (recommendedList && recommendedList.length !== 0)) {
        simpleValidator.current.fields.interval = false;
        setIntervalMsg(validators.paymentWidget.default);
      } else if ((defaultList && defaultList.length !== 0) && (recommendedList && recommendedList.length === 0)) {
        simpleValidator.current.fields.interval = false;
        setIntervalMsg(validators.paymentWidget.recommended);
      } else if ((defaultList && defaultList.length === 0) && (recommendedList && recommendedList.length === 0)) {
        simpleValidator.current.fields.interval = false;
        setIntervalMsg(validators.paymentWidget.intervals);
      }
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        myRef.current.scrollIntoView();
        setFixErrors(true);
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      widgetDetails.intervals = list.splice(0);
      setSampleWidget(setSampleWidget);
      return true;
    },
  }));

  return (
    <>
      <SpText className="text-uppercase mr-4" fontSize="16px" style={{ marginTop: '36px' }} ref={myRef}>
        Widget Details*
      </SpText>
      {
        fixErrors && (
          <Row>
            <Col className="ml-3 mb-3">
              <SpError>
                Please fix one or more errors below.
              </SpError>
            </Col>
          </Row>
        )
      }
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '12px' }}>
            <label htmlFor=""><SpText fontSize="14px">Widget Title</SpText></label>
            <Input
              placeholder="Title"
              onChange={updateTitle}
              value={widgetTitle}
            />
            <SpError>
              {simpleValidator.current.message('title', widgetTitle, 'required')}
            </SpError>
          </div>
        </Col>
      </Row>
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '27px' }}>
            <label htmlFor="">
              <SpText fontSize="14px">Message</SpText>
            </label>
            <TextArea
              className="resize"
              rows={3}
              placeholder="Message"
              value={message}
              onChange={updateMessage}
            />
            <SpError>
              {simpleValidator.current.message('message', message, 'required')}
            </SpError>
          </div>
        </Col>
      </Row>
      <Divider style={{ marginTop: '28px', paddingRight: '10px' }} />
      <SpText className="text-uppercase mr-4" fontSize="16px" style={{ marginTop: '34px' }}>
        Primary Color
      </SpText>
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '11px' }}>
            <label htmlFor=""><SpText fontSize="13px">Pick a color or Change the hexadecimal code</SpText></label>
            <div className="d-flex ml-1" style={{ marginTop: '9px' }}>
              <div>
                <SpWatch onClick={handleClick}>
                  <SpColor className="color" style={{ backgroundColor: `${colorCode}` }} />
                </SpWatch>
                {displayColorPicker ? (
                  <>
                    <SpPopover>
                      <SpCover onClick={handleClose} />
                      <SketchPicker color={colorCode} onChange={handleChange} />
                    </SpPopover>
                  </>
                ) : null}
              </div>
              <Input
                style={{ width: '111px' }}
                className="ml-3"
                value={colorCode}
                onChange={updateColor}
              />
            </div>
            <SpError>
              {simpleValidator.current.message('primary color', colorCode, 'required')}
            </SpError>
          </div>
        </Col>
      </Row>
      <Divider style={{ marginTop: '26px' }} />
      <SpText className="text-uppercase mr-4" fontSize="16px" style={{ marginTop: '36px' }}>
        Payment Intervals
      </SpText>
      <Row type="flex" justify="start" align="middle">
        <Col span={24}>
          <div className="d-flex" style={{ marginTop: '15px' }}>
            <label htmlFor="" className="mr-3">
              <Checkbox
                className="mr-2"
                checked={oneTime}
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    setIntervalMsg('');
                    simpleValidator.current.purgeFields();
                    widgetDetails.intervals[0] = 'ONETIME';
                    setSampleWidget(setSampleWidget);
                    setOneTime(true);
                  } else if (!checked) {
                    widgetDetails.intervals[0] = null;
                    setSampleWidget(setSampleWidget);
                    setOneTime(false);
                  }
                }}
              />
              <SpText fontSize="14px">One-time</SpText>
            </label>
            <label htmlFor="" className="mr-3">
              <Checkbox
                className="mr-2"
                checked={weekly}
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    setIntervalMsg('');
                    simpleValidator.current.purgeFields();
                    widgetDetails.intervals[1] = 'WEEK';
                    setSampleWidget(setSampleWidget);
                    setWeekly(true);
                  } else if (!checked) {
                    widgetDetails.intervals[1] = null;
                    setSampleWidget(setSampleWidget);
                    setWeekly(false);
                  }
                }}
              />
              <SpText fontSize="14px">Weekly</SpText>
            </label>
            <label htmlFor="" className="mr-3">
              <Checkbox
                className="mr-2"
                checked={monthly}
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    setIntervalMsg('');
                    simpleValidator.current.purgeFields();
                    widgetDetails.intervals[2] = 'MONTH';
                    setSampleWidget(setSampleWidget);
                    setMonthly(true);
                  } else if (!checked) {
                    widgetDetails.intervals[2] = null;
                    setSampleWidget(setSampleWidget);
                    setMonthly(false);
                  }
                }}
              />
              <SpText fontSize="14px">Monthly</SpText>
            </label>
            <label htmlFor="" className="mr-3">
              <Checkbox
                className="mr-2"
                checked={yearly}
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    setIntervalMsg('');
                    simpleValidator.current.purgeFields();
                    widgetDetails.intervals[3] = 'YEAR';
                    setSampleWidget(setSampleWidget);
                    setYearly(true);
                  } else if (!checked) {
                    widgetDetails.intervals[3] = null;
                    setSampleWidget(setSampleWidget);
                    setYearly(false);
                  }
                }}
              />
              <SpText fontSize="14px">Yearly</SpText>
            </label>
          </div>
          <SpError>
            {intervalMsg}
          </SpError>
        </Col>
      </Row>
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '39px' }}>
            <div>
              <label htmlFor="">
                <SpText fontSize="14px">Set as Default</SpText>
              </label>
            </div>
            <label htmlFor="">
              <SpText fontSize="12px" style={{ marginTop: '8px' }}>The default interval will be pre-selected</SpText>
            </label>
            <Select
              className="w-100"
              value={selectedInterval}
              onChange={updateDefaultInterval}
            >
              {
              PAYMENT_OPTIONS
                .map((item) => (
                  <Option key={item.id} value={item.value}>
                    {item.display}
                  </Option>
                ))
            }
            </Select>
          </div>
        </Col>
      </Row>
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '35px', marginBottom: '35px' }}>
            <div>
              <label htmlFor="">
                <SpText fontSize="14px">Recommended Interval</SpText>
              </label>
            </div>
            <label htmlFor="">
              <SpText fontSize="12px" style={{ marginTop: '8px' }}>
                Interval will be indicated with a <img src={star} alt="star" style={{ width: '12px' }} /> icon
              </SpText>
            </label>
            <Select
              className="w-100"
              value={suggestedInterval}
              onChange={updateSuggestiveInterval}
            >
              {
              PAYMENT_OPTIONS
                .map((item) => (
                  <Option key={item.id} value={item.value}>
                    {item.display}
                  </Option>
                ))
            }
            </Select>
          </div>
        </Col>
      </Row>
    </>
  );
};

// $FlowFixMe
export default forwardRef(WidgetInfo);
