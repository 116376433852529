import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  STATIC_QR_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchStaticQRDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.STATICQR}/${gid}`);

const staticQRDelete = async gid => axiosAuthInstance.delete(`${API_END_POINTS.STATICQR}/${gid}`);

const patchStaticQR = async ({ infoGid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.STATICQRACCOUNT}/${infoGid}`, params);

export function* getStaticQRDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchStaticQRDetails, action.payload);
    yield put({ type: STATIC_QR_DETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: STATIC_QR_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateStaticQR(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(patchStaticQR, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.STATICQR.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteStaticQR(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(staticQRDelete, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.STATICQR.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
