import { ACCOUNT } from '../actionTypes';

const initialState = {
  test: false,
  selectedAccount: {},
  internalAccount: {},
  accounts: [],
  filteredList: [],
  customReport: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT.SET_TEST_DATA:
      return {
        ...state,
        test: action.payload,
      };
    case ACCOUNT.SUCCESS:
      return {
        ...state,
        accounts: action.payload,
      };
    case ACCOUNT.UPDATED:
      return {
        ...state,
        accounts: action.payload,
      };
    case ACCOUNT.SUCCESS_BY_ID:
      return {
        ...state,
        selectedAccount: action.payload,
      };
    case ACCOUNT.SUCCESS_BY_FILTER:
      return {
        ...state,
        filteredList: action.payload,
      };
    case ACCOUNT.SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.payload,
      };
    case ACCOUNT.ADD:
      return {
        ...state,
        selectedAccount: action.payload,
      };
    case ACCOUNT.SUCCESS_BY_INTERNAL_ID:
      return {
        ...state,
        internalAccount: action.payload,
      };
    case ACCOUNT.INTERNAL_UPDATED:
      return {
        ...state,
        accounts: action.payload,
      };
    case ACCOUNT.CUSTOM_DASHBOARD_SUCCESS:
      return {
        ...state,
        customReport: action.payload,
      };
    default:
      return state;
  }
};
