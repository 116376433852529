// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Icon,
  Tooltip,
  Badge,
  Input,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getStateList from 'utils/stateList';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getFilterOperators from 'utils/getFilterOperators';
import {
  notificationActions,
  vendorsActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  FILTERS_TYPES,
  FILTER_STATUSES,
} from 'appconstants';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';


import moment from 'moment-timezone';
import getFundingSourceBackground from 'utils/getVendorBackground';
import getFundingSourceStatus from 'utils/getVendorStatus';

import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import DeleteVendor from './components/DeleteVendor';
import AddVendor from './components/AddVendor';
import EditVendor from './components/EditVendor';
import BulkUploadModal from './components/BulkUploadModal';


const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const VENDORS_FILTERS_TYPES = FILTERS_TYPES.VENDORS;
const vendorTypes = FILTER_STATUSES.VENDORS;

type Props = {
  fetchVendors: Function,
  vendors: Array<Object>,
  loading: boolean,
  test: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  submitting: boolean,
  addVendor: Function,
  validator: boolean,
  deleteVendor: Function,
  updateVendor: Function,
  setFilterData: Function,
  preserveFilters: Object,
  multiSendFile: Function,
};

const Vendors = (props: Props) => {
  const {
    loading,
    fetchVendors,
    vendors,
    totalElements,
    test,
    history,
    selectedAccount,
    submitting,
    addVendor,
    validator,
    deleteVendor,
    updateVendor,
    setFilterData,
    preserveFilters,
    multiSendFile,
  } = props;

  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [deleteVendorModal, setDeleteVendorModal] = useState(false);
  const [editVendorModal, setEditVendorModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [addVendorModal, setAddVendorModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [zipTag, setZipTag] = useState('');
  const [filterTypes, setFilterTypes] = useState(VENDORS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterVendors, setFilterVendors] = useState((preserveFilters && preserveFilters.filterVendors)
    ? (preserveFilters && preserveFilters.filterVendors)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [sendFileDisabled, setSendFileDisabled] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterVendors: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });

  const exportData = vendors ? vendors.map((values) => ({
    name: values.name,
    email: values.email,
    type: values.contactType,
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
  })) : '';

  const exportHeaders = [
    { label: 'NAME', key: 'name' },
    { label: 'EMAIL', key: 'email' },
    { label: 'TYPE', key: 'type' },
    { label: 'CREATED ON', key: 'createdAt' },
  ];

  const debouncedFetchVendors = debounce(fetchVendors, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchVendors({ currentPage, sortParams, filterParams });
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    // This call is happennig for test mode and if it was live we should have issuer.
    if (test) {
      getData(location.search);
    } else if (selectedAccount && selectedAccount.issuer) {
      getData(location.search);
    }
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [test, selectedAccount, location]);

  useEffect(() => {
    setSelectedKeys([]);
    setSelectedRowKeys([]);
    setSendFileDisabled(true);
  }, [vendors]);

  useEffect(() => {
    setSendFileDisabled(!selectedKeys?.length > 0);
  }, [selectedKeys]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [vendors]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (!validator && !redirect) {
      setAddVendorModal(false);
      setEditVendorModal(false);
      setDeleteVendorModal(false);
      setSearchText('');
    }
    if (!validator && redirect) {
      setSelectedKeys([]);
      setSelectedRowKeys([]);
      setSendFileDisabled(true);
      setSearchText('');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [validator]);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.VENDORS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.VENDORS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const showDeleteVendorModal = (value: Object) => {
    setSelectedVendor(value?.contact ? value?.contact : value);
    setDeleteVendorModal(true);
  };

  const showEditVendorModal = (value: Object) => {
    setSelectedVendor(value?.contact ? value?.contact : value);
    setEditVendorModal(true);
  };

  const deleteSelectedVendor = (gid: string) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;

    deleteVendor({
      gid,
      currentPage,
      sortParams,
    });
  };

  const addNewVendor = (vendor: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;

    const {
      name,
      email,
      phone,
      countryCode,
      contactType,
      customType,
      streetName,
      cityName,
      stateName,
      zipCode,
      phoneNumberCode,
    } = vendor;

    const params = {
      name,
      contactType,
      customType,
      email,
      phoneNumber: `${phoneNumberCode}${phone}`,
      address: {
        street: streetName,
        city: cityName,
        state: stateName,
        postalCode: zipCode,
        countryCode,
      },
    };

    addVendor({
      params,
      currentPage,
      sortParams,
    });
  };

  const editSelectedVendor = (contact: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      name,
      email,
      customerPhone,
      gid,
      countryCode,
      contactType,
      customType,
      streetName,
      cityName,
      stateName,
      zipCode,
      phoneNumberCode,
    } = contact;

    const params = {
      name,
      contactType,
      customType,
      email,
      phoneNumber: `${phoneNumberCode}${customerPhone}`,
      address: {
        street: streetName,
        city: cityName,
        state: stateName,
        postalCode: zipCode,
        countryCode,
      },
    };
    updateVendor({
      currentPage,
      sortParams,
      gid,
      params,
    });
  };

  const selectedRecord = (record) => {
    const vendorsGid = record?.gid;
    history.push(`${PAGES.CONTACTS}/${vendorsGid}`);
  };

  const onCheck = (e, index) => {
    const list = [...filterVendors];
    if (filterVendors.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = VENDORS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterVendors(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterVendors];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'contactType') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = VENDORS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterVendors(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterVendors];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterVendors(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterVendors(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterVendors];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterVendors(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterVendors(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterVendors[index].filterName === 'createdAt') {
      if (filterVendors[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterVendors[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterVendors[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterVendors];
    list[index].filterValue = selectedValue;
    setFilterVendors(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterVendors];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterVendors[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterVendors(list);
  };

  const handleAddClick = () => {
    const list = [...filterVendors];
    setFilterVendors([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterVendors([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(VENDORS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.VENDORS);
  };

  const applyFilters = () => {
    const vendorsFilters = filterVendors.filter((item) => item.checked);
    for (let i = 0; i < vendorsFilters.length; i += 1) {
      if (!vendorsFilters[i].filterName || !vendorsFilters[i].filterOperator || !vendorsFilters[i].filterValue) {
        return;
      }
    }
    if (vendorsFilters[0].filterValue || (vendorsFilters[0].filterValueFrom && vendorsFilters[0].filterValueTo)) {
      setCount(vendorsFilters && vendorsFilters.length);
      for (let i = 0; i < vendorsFilters.length; i += 1) {
        if (vendorsFilters[i].checked) {
          if (vendorsFilters[i].filterName === 'createdAt') {
            vendorsFilters[i] = vendorsFilters[i].filterValue;
          } else if (vendorsFilters[i].filterName === 'amount' && vendorsFilters[i].filterOperator === 'BT') {
            const name = vendorsFilters[i].filterName;
            const from = vendorsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = vendorsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            vendorsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (vendorsFilters[i].filterName === 'amount' && vendorsFilters[i].filterOperator !== 'BT') {
            const value = vendorsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            vendorsFilters[i] = `${vendorsFilters[i].filterName}.${vendorsFilters[i].filterOperator}=${value}`;
          } else if (vendorsFilters[i].filterName === 'email') {
            const email = encodeURIComponent(vendorsFilters[i].filterValue);
            vendorsFilters[i] = `${vendorsFilters[i].filterName}.${vendorsFilters[i].filterOperator}=${email}`;
          } else {
            vendorsFilters[i] = `${vendorsFilters[i].filterName}.${vendorsFilters[i].filterOperator}=${vendorsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(vendorsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterVendors,
        });
        history.push({
          pathname: PAGES.VENDORS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterVendors,
        });
        history.push({
          pathname: PAGES.VENDORS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const handleSearch = (val) => {
    setSearchText(val);
    const searchParams = val;
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    debouncedFetchVendors({ currentPage, sortParams, searchParams });
  };

  const sendFundingSourceLink = () => {
    const params = [];
    for (let i = 0; i < selectedKeys.length; i += 1) {
      let tempObject = {};
      tempObject.contactGid = selectedKeys[i];
      tempObject.notificationType = 'ALL';
      tempObject.canUpdateContactFundingSource = true;
      params.push(tempObject);
      tempObject = {};
    }
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    const payload = {
      currentPage, sortParams, filterParams,
    };
    multiSendFile({
      params, payload,
    });
    setRedirect(true);
  };

  const onSelectChange = (selectedKey, selectedItems) => {
    setSelectedRowKeys(selectedItems);
    setSelectedKeys(selectedKey);
    setSendFileDisabled(false);
  };

  const rowSelection = {
    type: 'checkbox',
    selectedKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const name = record?.name ? record?.name : (record?.contact?.name ? record?.contact?.name : <>&#8211;</>);
        return (
          <span>
            {name}
          </span>
        );
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '20%',
      align: 'left',
      render: (text, record) => {
        const email = record?.email ? record?.email : (record?.contact?.email ? record?.contact?.email : <>&#8211;</>);
        return (
          <span>
            {email}
          </span>
        );
      },
    },
    {
      title: 'TYPE',
      dataIndex: 'contactType',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const contactType = record?.contactType ? record?.contactType : (record?.contact?.contactType ? record?.contact?.contactType : <>&#8211;</>);
        return (
          <span>
            {contactType}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '15%',
      align: 'left',
      render: (text, record) => {
        const status = record?.status ? record?.status : (record?.contact?.status ? record?.contact?.status : <>&#8211;</>);
        return (
          <span>
            <SpStatusTag style={{ backgroundColor: getFundingSourceBackground(status) }}>
              {getFundingSourceStatus(status)}
            </SpStatusTag>
          </span>
        );
      },
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (text, record) => {
        const createdAt = record?.contact?.createdAt ? record?.contact?.createdAt : (record?.createdAt ? record?.createdAt : <>&#8211;</>);
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      width: '15%',
      align: 'center',
      render: (text, record) => (
        <span>
          <Tooltip placement="top" title="Delete">
            <Icon
              type="delete"
              style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); showDeleteVendorModal(record); }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Edit">
            <Icon
              type="edit"
              className="ml-3"
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); showEditVendorModal(record); }}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      {
        ((!test) && (selectedAccount && (!selectedAccount.issuer)))
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <h5>Please go to settings and provide additional business information to enable live transfers</h5>
              </div>
            </Card>
          )
          : (
            <Card>
              <Row className="mt-4 mb-4">
                <Col>
                  <Input.Search
                    value={searchText}
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                    enterButton
                  />
                </Col>
              </Row>
              <Row className="my-2" type="flex" justify="space-between">
                <Col>
                  <SpButton
                    type="secondary"
                    shape="round"
                    ghost
                    className="mr-3"
                    onClick={() => sendFundingSourceLink()}
                    disabled={sendFileDisabled}
                  >
                    Send Funding Source link
                  </SpButton>
                </Col>
                <Col>
                  <SpButton
                    type="secondary"
                    shape="round"
                    ghost
                    className="mr-3"
                    onClick={() => setBulkUploadModal(true)}
                  >
                    {'\u002B'}&nbsp;Bulk Upload
                  </SpButton>
                  <SpButton
                    type="secondary"
                    shape="round"
                    ghost
                    className="mr-3"
                    onClick={() => setAddVendorModal(true)}
                  >
                    {'\u002B'}&nbsp;Add Contact
                  </SpButton>
                  <span>
                    {
                        exportData !== null
                          ? (
                            <Tooltip placement="left" title="Click here to export Contacts">
                              <CSVLink
                                target="_blank"
                                className="mr-3"
                                filename="Contacts Report.csv"
                                data={exportData}
                                headers={exportHeaders}
                                onMouseEnter={() => setFillColor(true)}
                                onMouseLeave={() => setFillColor(false)}
                              >
                                <ExportIcon
                                  fill={fillColor
                                    ? '#279dfe'
                                    : '#C0C0C0'}
                                />
                              </CSVLink>
                            </Tooltip>
                          ) : null
                      }
                  </span>
                  <span ref={myRef}>
                    <span
                      onClick={() => setOpen(!open)}
                      style={{ cursor: 'pointer' }}
                      onMouseEnter={() => setFilterColor(true)}
                      onMouseLeave={() => setFilterColor(false)}
                    >
                      {open && (
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                      )}
                      {!open && (
                      <Tooltip placement="left" title="Click here to filter Contacts">
                        <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                          <FilterIcon
                            fill={filterColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </Badge>
                      </Tooltip>
                      )}
                    </span>
                    {open && (
                    <FilterWrapper
                      style={{ marginLeft: '-120px' }}
                    >
                      <Filters
                        filtersGrid={filterVendors}
                        filterTypes={filterTypes}
                        handleChange={handleChange}
                        onCheck={onCheck}
                        fiterOperators={fiterOperators}
                        handleOperator={handleOperator}
                        handleValue={handleValue}
                        startDate={startDate}
                        endDate={endDate}
                        currentDate={currentDate}
                        handleFromValue={handleFromValue}
                        handleToValue={handleToValue}
                        stuses={vendorTypes}
                      />
                      <div className="px-4 mt-2">
                        {
                              filterVendors.length < VENDORS_FILTERS_TYPES.length && (
                                <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                                  <SpText
                                    fontWeight="600"
                                    color="#279dfe"
                                    onClick={handleAddClick}
                                  >
                                    +&nbsp; Add another filter
                                  </SpText>
                                </div>
                              )
                            }
                      </div>
                      <div className="d-flex mb-2 justify-content-end mr-3">
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-2"
                          ghost
                          onClick={resetFilters}
                        >
                          Clear
                        </SpButton>
                        <SpButton
                          type="primary"
                          shape="round"
                          onClick={applyFilters}
                        >
                          Save
                        </SpButton>
                      </div>
                    </FilterWrapper>
                    )}
                  </span>
                </Col>
              </Row>
              {/* DELETE VENDOR MODAL */}
              {deleteVendorModal && (
                <DeleteVendor
                  visible={deleteVendorModal}
                  selectedVendor={selectedVendor}
                  submitting={submitting}
                  close={() => setDeleteVendorModal(false)}
                  submit={deleteSelectedVendor}
                />
              )}

              {/* ADD VENDOR MODAL */}
              {addVendorModal && (
                <AddVendor
                  visible={addVendorModal}
                  submitting={submitting}
                  selectedAccount={selectedAccount}
                  phoneCode={phoneCode}
                  country={selectedAccount.country.name}
                  cntryId={selectedAccount.country.id}
                  statesInCountry={statesInCountry}
                  zipTag={zipTag}
                  close={() => setAddVendorModal(false)}
                  submit={addNewVendor}
                />
              )}

              {/* BULK UPLOAD MODAL */}
              {bulkUploadModal && (
              <BulkUploadModal
                visible={bulkUploadModal}
                submitting={submitting}
                selectedAccount={selectedAccount}
                close={() => setBulkUploadModal(false)}
              />
              )}

              {/* EDIT VENDOR MODAL */}
              {editVendorModal && (
                <EditVendor
                  visible={editVendorModal}
                  selectedVendor={selectedVendor}
                  phoneCode={phoneCode}
                  submitting={submitting}
                  country={selectedAccount.country.name}
                  selectedAccount={selectedAccount}
                  statesInCountry={statesInCountry}
                  zipTag={zipTag}
                  close={() => setEditVendorModal(false)}
                  submit={editSelectedVendor}
                />
              )}

              <Table
                rowSelection={rowSelection}
                rowClassName={() => 'ant-table-clickable-row'}
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                columns={columns}
                rowKey={(record) => record.gid}
                dataSource={vendors}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  vendors: state.vendors.content,
  loading: state.loading.loading,
  test: state.account.test,
  totalElements: state.vendors.totalElements,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVendors: param => dispatch(vendorsActions.fetchVendors({
    payload: param,
  })),
  multiSendFile: param => dispatch(vendorsActions.multiSendFile({
    payload: param,
  })),
  addVendor: param => dispatch(vendorsActions.addVendor({
    payload: param,
  })),
  deleteVendor: param => dispatch(vendorsActions.deleteVendor({
    payload: param,
  })),
  updateVendor: param => dispatch(vendorsActions.updateVendor({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
