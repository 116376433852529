import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  PAYMENTCHECKOUTDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchPaymentCheckoutDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.PAYMENTCHECKOUT}/${gid}`, {
  headers: {
    'X-secure': '164',
  },
});

const fetchPayments = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const queryParam = `&checkoutPageGid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYMENTS}${query}${queryParam}${sorting}`);
};

export function* getPaymentCheckoutDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchPaymentCheckoutDetails, action.payload);
    yield put({ type: PAYMENTCHECKOUTDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTCHECKOUTDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getPaymentCheckoutList(action) {
  try {
    const { data: { entity } } = yield call(fetchPayments, action.payload);
    yield put({ type: PAYMENTCHECKOUTDETAILS.PAYMENTS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTCHECKOUTDETAILS.FAILED, payload: e.response.data });
  }
}
