import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance2,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const postEvidence = async ({ gid, payload }) => axiosAuthInstance2.post(`${API_END_POINTS.SUBMIT_EVIDENCE}/${gid}/dispute/evidence`, payload);

export function* submitDisputeEvidence(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(postEvidence, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVIDENCE.SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
