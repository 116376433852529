// @flow
import React, { useState, useRef, useEffect } from 'react';
import {
  Input,
  Select,
  Modal,
  Switch,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';

const { Option } = Select;

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  locations: Array<Object>,
  selectedTerminal: Object,
};

const EditTerminal = (props: Props) => {
  const {
    visible,
    close,
    submit,
    locations,
    selectedTerminal,
  } = props;

  const [, forceUpdate] = useState();
  const [terminalName, setTerminalName] = useState(selectedTerminal.deviceName);
  const [locationId, setLocationId] = useState('');
  const [terminalLocation, setTerminalLocation] = useState('');
  const [isActive, setIsActive] = useState(selectedTerminal.active);
  const terminalGid = selectedTerminal && selectedTerminal.gid;

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    if (selectedTerminal.terminalLocation) {
      setLocationId(selectedTerminal.terminalLocation.gid);
      setTerminalLocation(selectedTerminal.terminalLocation.name);
    }
  }, []);

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      terminalName,
      locationId,
      isActive,
      terminalGid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Update</SpButton>,
      ]}
      title="Update Terminal"
    >
      <div className="px-4">
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            defaultValue={terminalName}
            onChange={(e) => setTerminalName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', terminalName, 'required')}
          </SpError>
        </div>

        <div className="pb-3">
          <label className="d-block"><SpH5>Location</SpH5></label>
          <Select
            className="w-100 mr-4"
            placeholder="Location"
            defaultValue={terminalLocation}
            onChange={(e) => setLocationId(e)}
          >
            {
                locations.map(s => (
                  <Option
                    key={s.gid}
                    value={s.gid}
                    className="text-capitalize"
                  >
                    { s.name }
                  </Option>
                ))
              }
          </Select>
          <SpError>
            {simpleValidator.current.message('location', locationId, 'required')}
          </SpError>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <label className="d-block"><SpH5>Active</SpH5></label>
            <Switch
              className="mx-2"
              defaultChecked={isActive}
              onChange={e => setIsActive(e)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default EditTerminal;
