export default status => {
  if (status === 'CANCELLED') {
    return '#e2e2e2';
  } if (status === 'ACTIVE') {
    return '#ffe0b1';
  } if (status === 'DRAFT') {
    return '#e2e2e2';
  } if (status === 'PAST_DUE') {
    return '#ffe0b1';
  } if (status === 'PAID') {
    return '#ececff';
  } if (status === 'INCOMPLETE') {
    return '#e2e2e2';
  } return '#e2e2e2';
};
