import { SUPPORT } from '../actionTypes';

export const fetchSupport = action => ({
  type: SUPPORT.FETCH,
  payload: action.payload,
});

export const addSupport = action => ({
  type: SUPPORT.ADD,
  payload: action.payload,
});

export const updateSupport = action => ({
  type: SUPPORT.UPDATE,
  payload: action.payload,
});
