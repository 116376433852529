/* eslint-disable react/no-unescaped-entities */
// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Icon,
  Select,
  // Divider,
} from 'antd';


import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import {
  MESSAGES,
} from 'appconstants';
import { NOTIFICATION } from 'store/actionTypes';
import {
  notificationActions,
} from 'store/actions';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5,
  SpText,
  SpButton,
} from 'components/DesignKit';
import AddNewAccountSlug from './AddNewAccountSlug';

import 'react-quill/dist/quill.snow.css';

const { Option } = Select;

type Props = {
  pageData: Object,
  setNotification: Function,
  accountSlug: Array<Object>,
  submitting: Boolean,
  newSlug: Function,
  withoutSlug: Function,
};

const AccontSlug = forwardRef((props: Props, ref) => {
  const {
    pageData,
    setNotification,
    accountSlug,
    submitting,
    newSlug,
    withoutSlug,
  } = props;
  const [, forceUpdate] = useState();
  const [slugLink, setSlugLink] = useState(pageData.slugLink);
  const [slugGid, setSlugGid] = useState(pageData.slugGid);
  const [addAccountSlugModal, setAddAccountSlugModal] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator({}));

  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      slugGid,
      slugLink,
    }),
  }));

  const addAccountSlugNew = async (payload) => {
    try {
      const { data: { entity } } = await axiosAuthInstance.post(`${API_END_POINTS.GET_ACCOUNTSLUG}`, payload);
      setSlugGid(entity.gid);
      setSlugLink(entity.slugName);
      newSlug();
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    } finally {
      setAddAccountSlugModal(false);
    }
  };

  return (
    <>
      <div>
        <Row>
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Link Account Slug</SpH5></label>
            <Select
              className="mt-2 w-100"
              showSearch
              value={slugLink || 'Select or create slug'}
              onSelect={(value) => {
                setSlugGid(value[0]);
                setSlugLink(value[1]);
              }}
              // dropdownRender={menu => (
              //   <div>
              //     {menu}
              //     <Divider style={{ margin: '2px 0' }} />
              //     <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
              //       <span
              //         style={{
              //           flex: 'none',
              //           padding: '8px',
              //           display: 'block',
              //           cursor: 'pointer',
              //           color: '#279dfe',
              //         }}
              //         onClick={() => { alert('test'); }}
              //       >
              //         <Icon type="plus" />&nbsp;Create Accont Slug
              //       </span>
              //     </div>
              //   </div>
              // )}
            >
              <Option
                key={1}
                name="defautInfo"
                value="defautInfo"
                disabled
                style={{ color: '#272727', borderBottom: '1px solid #8c8a8a' }}
              >
                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                  <span
                    style={{
                      flex: 'none',
                      display: 'block',
                      cursor: 'pointer',
                      color: '#279dfe',
                    }}
                    onClick={() => { setAddAccountSlugModal(true); }}
                  >
                    <Icon type="plus" />&nbsp;Create Accont Slug
                  </span>
                </div>
              </Option>
              {
                accountSlug.map(slug => (
                  slug.status === 'UNLINKED' && (
                    <Option
                      key={1}
                      value={[slug.gid, slug.slugName]}
                    >
                      {slug.slugName}
                    </Option>
                  )
                ))
              }
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <div style={{ border: '1px solid #C7C7C7', padding: '10px', borderRadius: '10px' }}>
              <div>
                <SpText fontStyle="italic" color="#000000">Note:</SpText>
              </div>
              <div>
                <SpText fontStyle="italic" color="#000000">
                &nbsp; 1. If you're setting up a new payment page, create an account slug to generate a QR code.
                </SpText>
              </div>
              <div>
                <SpText fontStyle="italic" color="#000000">
                &nbsp; 2. To update changes on the payment page, make sure to select the previous account slug before saving.
                </SpText>
              </div>
              <div>
                <SpText fontStyle="italic" color="#000000">
                &nbsp; 3. If you prefer not to create or select a slug, you can skip this step by clicking “Continue Without Slug”
                </SpText>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col span={16} push={4}>
            <SpButton
              type="secondary"
              style={{ width: '50%' }}
              onClick={() => withoutSlug()}
            >
              Continue Without Slug
            </SpButton>
          </Col>
        </Row>
        {/* ADD ACCOUNT SLUG MODAL */}
        {addAccountSlugModal && (
        <AddNewAccountSlug
          visible={addAccountSlugModal}
          submitting={submitting}
          close={() => setAddAccountSlugModal(false)}
          submit={addAccountSlugNew}
        />
        )}
      </div>
    </>
  );
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(AccontSlug);
