// @flow
import React, {
  useRef,
  useState,
  createRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Steps,
} from 'antd';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import {
  notificationActions,
  transfersActions,
  vendorsActions,
} from 'store/actions';
import {
  BENE_TYPES,
} from 'appconstants';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { NOTIFICATION } from 'store/actionTypes';
import { SpButton } from 'components/DesignKit';

import TransferDetails from './TransferDetails';
import ContactDetails from './ContactDetails';
import FundingSource from './FundingSource';
import ConfirmTransfer from './ConfirmTransfer';

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  contactList: Array<Object>,
  fundingSearchList: Array<Object>,
  setNotification: Function,
  getContactList: Function,
  searchFundingSource: Function,
  statesInCountry: Array<Object>,
  transferSettings: Object,
  supportsSchedule: boolean,
  issuerName: string,
  zipTag: String,
  phoneCode: string,
  submit: Function,
  isIndia: boolean,
  fetchCustomTypes: Function,
  customSuggestions: Array<Object>,
};

const { Step } = Steps;

const AddTransfer = (props: Props) => {
  const {
    visible,
    close,
    submitting,
    selectedAccount,
    phoneCode,
    submit,
    setNotification,
    getContactList,
    fundingSearchList,
    searchFundingSource,
    contactList,
    statesInCountry,
    transferSettings,
    supportsSchedule,
    issuerName,
    zipTag,
    isIndia,
    fetchCustomTypes,
    customSuggestions,
  } = props;

  const [, forceUpdate] = useState();
  const transferDetailsRef = createRef();
  const contactDetailsRef = createRef();
  const fundingSourceRef = createRef();
  const [refArray, setRefArray] = useState([transferDetailsRef, contactDetailsRef, fundingSourceRef]);
  const [current, setCurrent] = useState(0);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const [transferAmount, setTransferAmount] = useState('');
  const [description, setDescription] = useState('');
  const [transferType, setTransferType] = useState(isIndia ? 'IMPS' : 'ACH');
  const [transferDate, setTransferDate] = useState('');
  const [transferApiDate, setTransferApiDate] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactGid, setContactGid] = useState('');
  const [email, setEmail] = useState('');
  const [contactResult, setContactResult] = useState(contactList);
  const [phoneNumberCode, setPhoneCode] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [phoneFormate, setPhoneFormate] = useState();
  const [contactType, setContactType] = useState('');
  const [customType, setCustomType] = useState('');
  const [contactTypeName, setContactTypeName] = useState('');
  const [disableContact, setDisableContact] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [countryName, setCountryName] = useState('');
  const [fundingSourceName, setFundingSourceName] = useState('');
  const [fundingSourceGid, setFundingSourceGid] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountType, setAccountType] = useState('');
  const [bankName, setBankName] = useState('');
  const [upiId, setUpiId] = useState('');
  const [disableFunding, setDisableFunding] = useState(false);
  const [fundingResult, setFundingResult] = useState([]);
  const stateList = statesInCountry;
  const currencyType = selectedAccount && selectedAccount.currency;
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

  const transferDetailsData = {
    transferAmount,
    description,
    transferType,
    transferDate,
    transferApiDate,
  };

  const contactDetailsData = {
    contactName,
    contactGid,
    contactResult,
    email,
    phoneNumberCode,
    disableContact,
    contactPhone,
    phoneFormate,
    contactType,
    customType,
    customSuggestions,
    contactTypeName,
    countryCode,
    streetName,
    cityName,
    stateName,
    zipCode,
    countryName,
    stateList,
  };

  const fundingSourceDetailsData = {
    fundingSourceName,
    fundingSourceGid,
    accountNumber,
    routingNumber,
    accountType,
    fundingResult,
    bankName,
    upiId,
    disableFunding,
  };

  useEffect(() => {
    setContactResult(contactList);
    const displayFormat = moment().utc().tz(selectedAccount.timezone).startOf('day')
      .format(dateFormat);
    // eslint-disable-next-line
    const selectedDate = moment.utc().tz(selectedAccount.timezone).endOf('day').utc().format(MOMENT_FORMAT);
    if (selectedAccount && selectedAccount.timezone) {
      setTransferDate(displayFormat);
      setTransferApiDate(selectedDate);
    }
    fetchCustomTypes({ type: 'CONTACT_TYPE' });
  }, []);

  useEffect(() => {
    setContactResult(contactList);
  }, [contactList]);

  useEffect(() => {
    setFundingResult(fundingSearchList);
  }, [fundingSearchList]);

  useEffect(() => {
    if (current === 2 && !submitting) {
      setDisableButton(false);
    }
    if (current === 3 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const fetchContactDetails = (search) => {
    getContactList(search);
  };

  const fetchFundingSourceDetails = (search) => {
    searchFundingSource(search);
  };

  const clearFundingSource = () => {
    setFundingResult([]);
  };

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const next = async () => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setTransferAmount(valuesArray[0].transferAmount);
      setDescription(valuesArray[0].description);
      setTransferType(valuesArray[0].transferType);
      setTransferDate(valuesArray[0].transferDate);
      setTransferApiDate(valuesArray[0].transferApiDate);
      setCurrent(current + 1);
    } else if (current === 1) {
      validationArray.push(refArray[1].current.validate());
      const contactDetails = refArray[1].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setContactName(contactDetails.contactName);
      setContactGid(contactDetails.contactGid);
      setEmail(contactDetails.email);
      setContactPhone(contactDetails.contactPhone);
      setPhoneFormate(contactDetails.phoneFormate);
      setPhoneCode(contactDetails.phoneNumberCode);
      setContactType(contactDetails.contactType);
      setCustomType(contactDetails.customType);
      setContactTypeName(contactDetails.contactTypeName);
      setDisableContact(contactDetails.disableContact);
      setCountryCode(contactDetails.countryCode);
      setStreetName(contactDetails.streetName);
      setCityName(contactDetails.cityName);
      setStateName(contactDetails.stateName);
      setZipCode(contactDetails.zipCode);
      setCountryName(contactDetails.countryName);
      setCurrent(current + 1);
    } else if (current === 2) {
      validationArray.push(refArray[2].current.validate());
      const fundingSourceDetails = refArray[2].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setFundingSourceName(fundingSourceDetails.fundingSourceName);
      setFundingSourceGid(fundingSourceDetails.fundingSourceGid);
      setAccountNumber(fundingSourceDetails.accountNumber);
      setRoutingNumber(fundingSourceDetails.routingNumber);
      setAccountType(fundingSourceDetails.accountType);
      setBankName(fundingSourceDetails.bankName);
      setUpiId(fundingSourceDetails.upiId);
      setDisableFunding(fundingSourceDetails.disableFunding);
      setCurrent(current + 1);
    } else if (current === 3) {
      let contactAddGid;
      if (!contactDetailsData.contactGid) {
        const contactObject = {
          name: contactDetailsData.contactName,
          contactType: contactDetailsData.contactType,
          customType: contactDetailsData.customType,
          email: contactDetailsData.email,
          phoneNumber: `${contactDetailsData.phoneNumberCode}${contactDetailsData.contactPhone}`,
          address: {
            street: streetName,
            city: cityName,
            state: stateName,
            postalCode: zipCode,
            countryCode,
          },
        };
        try {
          const { data: { entity: { gid } } } = await axiosAuthInstance.post(API_END_POINTS.GET_CONTACTS, contactObject);
          contactAddGid = gid;
          setContactGid(gid);
        } catch (error) {
          setNotification({
            type: NOTIFICATION.ERROR,
            payload: error.response.data && error.response.data.message,
          });
        }
      }
      let fundingSourceAddGid;
      let issuerBank;
      if (transferType === BENE_TYPES.UPI) {
        issuerBank = {
          beneType: BENE_TYPES.VPA,
          vpa: fundingSourceDetailsData.upiId,
          countryCode,
        };
      } else {
        issuerBank = {
          accountNumber: fundingSourceDetailsData.accountNumber,
          routingNumber: fundingSourceDetailsData.routingNumber,
          accountType: fundingSourceDetailsData.accountType,
          bankName: fundingSourceDetailsData.bankName,
          name: fundingSourceDetailsData.fundingSourceName,
          beneType: BENE_TYPES.BANK,
          countryCode,
        };
      }
      if (!fundingSourceDetailsData.fundingSourceGid) {
        const fundingObject = {
          contactGid: contactDetailsData.contactGid || contactAddGid,
          issuerBank,
        };
        try {
          const { data: { entity: { gid } } } = await axiosAuthInstance.post(API_END_POINTS.GET_FUNDINGSOURCE, fundingObject);
          fundingSourceAddGid = gid;
          setFundingSourceGid(gid);
        } catch (error) {
          setNotification({
            type: NOTIFICATION.ERROR,
            payload: error.response.data && error.response.data.message,
          });
        }
      }
      let contactDataGid;
      if (contactAddGid) {
        contactDataGid = {
          contactAddGid,
        };
      }
      let fundingDataGid;
      if (fundingSourceAddGid) {
        fundingDataGid = {
          fundingSourceAddGid,
        };
      }
      if (fundingSourceGid || fundingDataGid) {
        const payload = Object.assign(transferDetailsData, contactDetailsData, fundingSourceDetailsData, contactDataGid, fundingDataGid);
        submit(
          payload,
        );
      }
    }
  };

  const prev = () => {
    setDisableButton(false);
    if (current === 0) {
      close();
    } else if (current === 1) {
      setCurrent(current - 1);
    } else if (current === 2) {
      setCurrent(current - 1);
    } else if (current === 3) {
      setCurrent(current - 1);
    } else if (current === 4) {
      setCurrent(current - 1);
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="675px"
      footer={current === 0
        ? [
          <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
          <SpButton key="2" onClick={next}>Next</SpButton>,
        ]
        : current === 1
          ? [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next}>Next</SpButton>,
          ]
          : current === 2
            ? [
              <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
              <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
              <SpButton key="2" onClick={next}>Next</SpButton>,
            ]
            : [
              <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
              <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
              <SpButton key="2" onClick={next} disabled={disableButton}>Issue Transfer</SpButton>,
            ]}
      title="Issue Transfer"
    >
      <>
        <div style={{ marginTop: '-8px' }}>
          <Steps current={current} className="subscriptions">
            <Step key="1" title="Transfer Details" />
            <Step key="2" title="Contact Details" />
            <Step key="3" title="Funding Source" />
            <Step key="4" title="Confirm" />
          </Steps>
        </div>
        <div className="px-5 mt-2">
          <div className={submitting ? 'OVERLAY' : ''} />
          {current === 0 && (
            <TransferDetails
              ref={refArray[0]}
              selectedAccount={selectedAccount}
              transferDetailsData={transferDetailsData}
              supportsSchedule={supportsSchedule}
              transferSettings={transferSettings}
              issuerName={issuerName}
              currencyType={currencyType}
            />
          )}
          {current === 1 && (
            <ContactDetails
              ref={refArray[1]}
              selectedAccount={selectedAccount}
              currencyType={currencyType}
              phoneCode={phoneCode}
              transferDetailsData={transferDetailsData}
              contactDetailsData={contactDetailsData}
              statesInCountry={statesInCountry}
              country={selectedAccount.country.name}
              cntryId={selectedAccount.country.id}
              zipTag={zipTag}
              fetchContactDetails={fetchContactDetails}
              fetchFundingSourceDetails={fetchFundingSourceDetails}
              clearFundingSource={clearFundingSource}
            />
          )}
          {current === 2 && (
            <FundingSource
              ref={refArray[2]}
              selectedAccount={selectedAccount}
              transferDetailsData={transferDetailsData}
              fundingSourceDetailsData={fundingSourceDetailsData}
              isIndia={isIndia}
              fetchContactDetails={fetchContactDetails}
              fundingResult={fundingResult}
              contactDetailsData={contactDetailsData}
            />
          )}
          {current === 3 && (
            <ConfirmTransfer
              isIndia={isIndia}
              selectedAccount={selectedAccount}
              transferDetailsData={transferDetailsData}
              contactDetailsData={contactDetailsData}
              fundingSourceDetailsData={fundingSourceDetailsData}
            />
          )}
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  contactList: state.transfers.contactList,
  fundingSearchList: state.transfers.searchFundingList,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  customSuggestions: state.vendors.customSuggestions,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  getContactList: param => dispatch(transfersActions.getContactList({
    payload: param,
  })),
  searchFundingSource: param => dispatch(transfersActions.searchFundingSource({
    payload: param,
  })),
  fetchCustomTypes: param => dispatch(vendorsActions.fetchCustomTypes({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddTransfer);
