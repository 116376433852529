import { DINING } from '../actionTypes';

export const fetchDiningSections = (action) => ({
  type: DINING.SECTION_FETCH,
  payload: action.payload,
});

export const addDiningSections = (action) => ({
  type: DINING.SECTION_ADD,
  payload: action.payload,
});

export const fetchDiningTable = (action) => ({
  type: DINING.TABLE_FETCH,
  payload: action.payload,
});

export const addDiningTable = (action) => ({
  type: DINING.TABLE_ADD,
  payload: action.payload,
});

export const updateDiningTable = (action) => ({
  type: DINING.TABLE_UPDATE,
  payload: action.payload,
});

export const deleteDiningTable = (action) => ({
  type: DINING.TABLE_DELETE,
  payload: action.payload,
});

export const updateSection = (action) => ({
  type: DINING.SECTION_UPDATE,
  payload: action.payload,
});

export const deleteDiningSection = (action) => ({
  type: DINING.SECTION_DELETE,
  payload: action.payload,
});

export const fetchDiningDetails = (action) => ({
  type: DINING.TABLE_DETAILS,
  payload: action.payload,
});

export const fetchAssignTable = (action) => ({
  type: DINING.ASSIGNTABLE_FETCH,
  payload: action.payload,
});

export const assignTable = (action) => ({
  type: DINING.ASSIGN_TABLE,
  payload: action.payload,
});

export const updateAssignTable = (action) => ({
  type: DINING.ASSIGN_TABLE_UPDATE,
  payload: action.payload,
});

export const deleteUserTable = (action) => ({
  type: DINING.ASSIGN_TABLE_DELETE,
  payload: action.payload,
});
