// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Icon,
} from 'antd';
import {
  SpH5, SpButton, SpText,
} from 'components/DesignKit';
import plaid from 'components/NavIcons/plaid';


type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
};

const PlaidAccount = (props: Props) => {
  const {
    visible,
    close,
    submit,
  } = props;

  const validate = () => {
    submit('test');
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Continue</SpButton>,
      ]}
    >
      <div className="px-4 mt-2">
        <div className="mb-3" style={{ textAlign: 'center' }}>
          <Icon
            component={plaid}
            style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
          />
          <div>
            <SpText fontSize="26px" color="#292929">
              Swirepay uses Plaid to
              connect your account
            </SpText>
          </div>
        </div>
        <div>
          <label htmlFor=""><SpH5>Connect effortlessly</SpH5></label>
          <div>
            <SpText fontSize="12px" color="#292929">
              Paid lets you securely connect your financial accounts in seconds
            </SpText>
          </div>
        </div>
        <div className="mt-3">
          <label htmlFor=""><SpH5>Your data belongs to you</SpH5></label>
          <div>
            <SpText fontSize="12px" color="#292929">
              Paid doesnot sell personal info, and will only use it with your permission
            </SpText>
          </div>
        </div>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(PlaidAccount);
