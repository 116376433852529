import React from 'react';
import { Spin } from 'antd';

const Loading = () => (
  <Spin style={{
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '10px',
    left: '100px',
    transform: 'translateY(50%)',
  }}
  />
);

export default Loading;
