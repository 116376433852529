// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  Switch,
  Upload,
  message,
  Icon,
} from 'antd';
import {
  COUNTRY_CODES,
  COUNTRIES,
  MAX_AMOUNT,
  MESSAGES,
} from 'appconstants';
import {
  API_END_POINTS,
  axiosAuthInstance,
  axiosEmptyInstance,
} from 'api';
import {
  notificationActions,
} from 'store/actions';
import {
  NOTIFICATION,
} from 'store/actionTypes';
import formatAmount from 'utils/formatAmount';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpError, SpButton, SpText,
} from 'components/DesignKit';
import { validators } from 'utils/validationMessages';

import Previewicon from 'components/NavIcons/previewIcon';
import Uploadicon from 'components/NavIcons/uploadIcon';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Function,
  setNotification: Function,
};


const AddCatalog = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    setNotification,
  } = props;

  const [, forceUpdate] = useState();
  const [amount, setAmount] = useState();
  const [shippingCost, setShippingCost] = useState();
  const [currency, setCurrency] = useState();
  const [quantity, setQuantity] = useState(1);
  const [maxQuantity, setMaxQuantity] = useState(2);
  const [name, setName] = useState('');
  const [orderList, setOrderList] = useState();
  const [description, setDescription] = useState();
  const [type, setType] = useState('');
  const [isTest, setTest] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const nameRegex = /^[ ]*([a-zA-Z'-.]{1,}( [a-zA-z'-. ]{1,})?)$/;
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [loader, setLoading] = useState(false);
  const [fileName, setFileName] = useState();
  const [preview, setPreview] = useState();
  const [logo, setLogo] = useState();

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      name: {
        message: validators.integrators.validName,
        rule: val => nameRegex.test(val),
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    const countryId = (selectedAccount && selectedAccount.country && selectedAccount.country.id);
    if (countryId === 1) {
      setCurrency('USD');
    } else if (countryId === 2) {
      setCurrency('INR');
    }
  }, []);

  const onCurrencyChange = (value) => {
    if (value === 1) {
      setCurrency('USD');
    } else if (value === 2) {
      setCurrency('INR');
    }
  };

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    const IMAGE = 'image/*';
    const pattern = file.type.match(IMAGE);
    if (!pattern) {
      message.error('File is not image');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 5MB!');
      return false;
    }
    return true;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const fileUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPDATE_CATALOG_LOGO}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        // eslint-disable-next-line max-len
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.UPDATE_CATALOG_LOGO}`, { fileUrl: entity, keyType: 'CATALOG_IMAGE' });
        setLogo(fileUrl);
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview(<img src={imageUrl} alt="..." style={{ width: '115px' }} />);
          });
        } else {
          setPreview(<Previewicon />);
        }
        setFileName(info.file.name);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <Uploadicon />}
      <div className="ant-upload-text mt-2">Click or drag the file here to upload</div>
      <div className="mt-2"><SpText color="rgba(0, 0, 0, 0.45)">Support extension: .jpg .png</SpText></div>
    </div>
  );

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      amount,
      quantity,
      maxQuantity,
      orderList,
      description,
      name,
      type,
      isTest,
      logo,
      shippingCost,
      currency,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Catalog Item"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Order Listing No.</SpH5></label>
          <Input
            placeholder="Order no"
            value={orderList}
            onChange={(e) => setOrderList(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Order list', orderList, 'required|numeric')}
          </SpError>
        </div>
        <div className="mb-3">
          <div>
            <label htmlFor=""><SpH5>Image</SpH5></label>
          </div>
          <Upload
            name="avatar"
            listType="picture-card upload-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={fileUpload}
          >
            {fileName ? preview : uploadButton}
          </Upload>
          <SpError>
            {simpleValidator.current.message('image', logo, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <TextArea
            maxLength={255}
            rows={5}
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('descriptiom', description, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Amount</SpH5></label>
          <div className="d-flex">
            <div style={{ width: '15%' }}>
              <Select
                className="w-100"
                defaultValue={currency}
                onChange={onCurrencyChange}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ID}>
                    {item.CURRENCY}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '85%' }}>
              <Input
                placeholder="0.00"
                value={amount}
                onChange={(e) => setAmount(e.currentTarget.value)}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setAmount(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('amount', amount, 'required|amount')}
              </SpError>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Shipping cost</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '15%' }}
            >
              <Select
                className="w-100"
                showSearch
                disabled
                value={currency}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ID}>
                    {item.CURRENCY}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '85%' }}>
              <Input
                value={shippingCost}
                placeholder="0.00"
                onChange={(e) => {
                  const regex = /^\d*\.?\d*$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setShippingCost(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setShippingCost(0.00);
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setShippingCost(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('shipping cost', shippingCost, 'required|amount')}
              </SpError>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>Quantity (min)</SpH5></label>
              <Input
                placeholder="Quantity"
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('quantity', quantity, 'required|quantityVal')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Quantity (max)</SpH5></label>
              <Input
                placeholder="Quantity"
                type="number"
                value={maxQuantity}
                onChange={(e) => setMaxQuantity(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('quantity', maxQuantity, 'required|numeric')}
              </SpError>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Type</SpH5></label>
          <Select
            className="w-100"
            defaultValue="Select Type"
            onChange={(e) => setType(e)}
          >
            <Option key={1} value="TERMINAL">Terminal</Option>
            <Option key={2} value="SERVICE">Service</Option>
            <Option key={3} value="OTHER">Others</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('Type', type, 'required')}
          </SpError>
        </div>
        <div>
          <Switch
            className="mr-2"
            checked={isTest}
            onChange={() => setTest(!isTest)}
          />
          <SpH5>Is available</SpH5>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddCatalog);
