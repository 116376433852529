import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Tooltip,
  Row,
  Col,
  Badge,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import formatNumber from 'utils/formatNumber';
import getFilterOperators from 'utils/getFilterOperators';

import { walletTransactionActions, filterParamsActions } from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import getWalletStatus from 'utils/getWalletStatuses';
import getWalletBackground from 'utils/getWalletBackground';
import star from 'imgs/StarIcon.svg';
import RBAC from 'components/rbac';
import {
  ROLES,
  PAGES,
  FILTERS_TYPES,
  FILTER_STATUSES,
} from 'appconstants';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const PaymentTypes = FILTER_STATUSES.ALLPAYMENTS;

type Props = {
  fetchWalletTransaction: Function,
  payments: Array<Object>,
  loading: boolean,
  test: boolean,
  history: {
    push: Function,
  };
  totalElements: number,
  selectedAccount: Object,
  setFilterData: Function,
  preserveFilters: Object,
};

const AllTransactions = (props: Props) => {
  const location = useLocation();
  const {
    loading,
    fetchWalletTransaction,
    payments,
    test,
    history,
    totalElements,
    selectedAccount,
    setFilterData,
    preserveFilters,
  } = props;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const { toFixed } = selectedAccount && selectedAccount.currency;
  const [pagination, setPagination] = useState({});
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const PAYMENTS_FILTERS_TYPES = FILTERS_TYPES.WALLET_TRANSACTION;
  const [filterTypes, setFilterTypes] = useState(PAYMENTS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterPayments, setFilterPayments] = useState((preserveFilters && preserveFilters.filterPayments)
    ? (preserveFilters && preserveFilters.filterPayments)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterPayments: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });
  const currencyType = selectedAccount && selectedAccount.currency;
  const exportData = payments ? payments.map((values) => ({
    name: (values.transactingAccount != null) ? values.transactingAccount && values.transactingAccount.name : '',
    amount: values
      && `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix}
    ${formatNumber((values.amount / 100).toFixed(2), currencyType)}`,
    gid: values && (values.gid.split('-')[1]).slice(-8),
    createdAt: moment
      .utc(values && values.createdAt)
      .tz(selectedAccount && selectedAccount.timezone)
      .format('MMM DD, YYYY'),
  })) : '';
  const exportHeaders = [
    { label: 'NAME', key: 'name' },
    { label: 'AMOUNT', key: 'amount' },
    { label: 'TRANSACTION ID', key: 'gid' },
    { label: 'DATE', key: 'createdAt' },
  ];

  const debouncedFetchWalletTransaction = debounce(fetchWalletTransaction, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const searchParam = parsed['type.EQ'];
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchWalletTransaction({
      searchParam,
      currentPage,
      sortParams,
      filterParams,
    });
  };

  const clearFilters = () => {
    setFilterPayments([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(PAYMENTS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e && e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn'
      && e.toElement.className !== 'ant-select-dropdown-menu  ant-select-dropdown-menu-root ant-select-dropdown-menu-vertical') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    if (location.search) {
      getData(location.search);
    }
    if (!filterParams) {
      clearFilters();
    }
  }, [location, test, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    const parsed = queryString.parse(location.search);
    const searchParam = parsed['type.EQ'];
    const filterParams = parsed.filterParams || '';
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && searchParam && !filterParams) {
      history.push({
        pathname: PAGES.WALLET_TRANSACTION,
        search: `?type.EQ=${searchParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else if (!searchParam && !filterParams) {
      history.push({
        pathname: PAGES.WALLET_TRANSACTION,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    } else if (searchParam && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.WALLET_TRANSACTION,
        search: `?type.EQ=${searchParam}&filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const selectedRecord = (record) => {
    const transactionListGid = record.gid;
    history.push({
      pathname: `${PAGES.WALLET_TRANSACTION}/${transactionListGid}`,
      state: location.search,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterPayments];
    if (filterPayments.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = PAYMENTS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterPayments(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterPayments];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = PAYMENTS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterPayments(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterPayments(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterPayments(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterPayments[index].filterName === 'createdAt') {
      if (filterPayments[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterPayments[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterPayments[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterPayments[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterPayments];
    list[index].filterValue = selectedValue;
    setFilterPayments(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterPayments];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterPayments[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterPayments(list);
  };

  const handleAddClick = () => {
    const list = [...filterPayments];
    setFilterPayments([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterPayments([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(PAYMENTS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.WALLET_TRANSACTION);
  };

  const applyFilters = () => {
    const paymentsFilters = filterPayments.filter((item) => item.checked);
    for (let i = 0; i < paymentsFilters.length; i += 1) {
      if (!paymentsFilters[i].filterName || !paymentsFilters[i].filterOperator || !paymentsFilters[i].filterValue) {
        return;
      }
    }
    if (paymentsFilters[0].filterValue || (paymentsFilters[0].filterValueFrom && paymentsFilters[0].filterValueTo)) {
      setCount(paymentsFilters && paymentsFilters.length);
      for (let i = 0; i < paymentsFilters.length; i += 1) {
        if (paymentsFilters[i].checked) {
          if (paymentsFilters[i].filterName === 'createdAt') {
            paymentsFilters[i] = paymentsFilters[i].filterValue;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else {
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${paymentsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(paymentsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const searchParam = parsed['type.EQ'];
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && searchParam && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?type.EQ=${searchParam}&pageNum=${currentPage}${sortParams}&filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.WALLET_TRANSACTION,
          search: `?type.EQ=${searchParam}&pageNum=${currentPage}${sortParams}&filterParams=${filterParams}`,
        });
      } else if (location.search && searchParam && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?type.EQ=${searchParam}&filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.WALLET_TRANSACTION,
          search: `?type.EQ=${searchParam}&filterParams=${filterParams}`,
        });
      } else if (location.search && !searchParam && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.WALLET_TRANSACTION,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'transactingAccount.name',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <span>
          {name || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, walletTransactionLog) => {
        const total = (amount / 100).toFixed(toFixed);
        const reqAmount = (walletTransactionLog.amount >= 0
          ? walletTransactionLog.amount - walletTransactionLog.cashbackRedeemed
          : walletTransactionLog.amount + walletTransactionLog.cashbackRedeemed);
        const finalReedemAmount = (reqAmount / 100).toFixed(toFixed);
        if (walletTransactionLog.cashbackRedeemed !== null && walletTransactionLog.type === 'TRANSACTION_DEBIT') {
          return (
            <span style={{ color: '#FF4B51' }}> {`${prefix} ${formatNumber(finalReedemAmount, currencyType)}`}&nbsp;&nbsp;
              {<s style={{ color: '#858585' }}>{`${prefix} ${formatNumber(total, currencyType)}`}</s>}
            </span>
          );
        }
        if ((walletTransactionLog.type === 'TRANSACTION_DEBIT') || (walletTransactionLog.type === 'PAYOUT')) {
          return (
            <span style={{ color: '#FF4B51' }}> {`${prefix} ${formatNumber(total, currencyType)}`} </span>
          );
        }
        if (walletTransactionLog.type === 'REFUND') {
          return (
            <span style={{ color: '#FF4B51' }}> {`${prefix} ${formatNumber(total, currencyType)}`} </span>
          );
        }
        return (
          <span style={{ color: '#00C289' }}> {`${prefix} ${formatNumber(total, currencyType)}`} </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'type',
      width: '18%',
      align: 'left',
      render: (status, record) => {
        if (status === 'TRANSACTION_DEBIT' && record.walletTransactionLog && record.walletTransactionLog.paymentSession) {
          const statusType = record.walletTransactionLog.paymentSession && record.walletTransactionLog.paymentSession.status;
          return (
            <Tooltip placement="top" title="Click here to view details">
              <SpStatusTag style={{ backgroundColor: getWalletBackground(statusType) }}>
                {statusType === 'REFUNDED' ? getWalletStatus(statusType) : status ? getWalletStatus(status) : <>&#8211;</>}
              </SpStatusTag>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <SpStatusTag style={{ backgroundColor: getWalletBackground(status) }}>
              {getWalletStatus(status) ? getWalletStatus(status) : <>&#8211;</>}
            </SpStatusTag>
          </Tooltip>
        );
      },
    },
    {
      title: 'TRANSACTION ID',
      dataIndex: 'gid',
      width: '20%',
      align: 'left',
      render: (gid) => {
        const trnsId = gid.split('-')[1];
        return (
          <Tooltip placement="top" title="Click here to view details">
            {
              trnsId
                ? trnsId.slice(-8) : <>&#8211;</>
            }
          </Tooltip>
        );
      },

      sorter: false,
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '25%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }

        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'REDEMPTION',
      dataIndex: 'walletTransactionLog',
      align: 'center',
      render: (walletTransactionLog) => {
        if ((walletTransactionLog && walletTransactionLog.canRedeem === true)
          && (walletTransactionLog && walletTransactionLog.cashbackRedeemed === 0)
        ) {
          return (
            <div style={{
              height: '25%', width: '25%', display: 'inline-block', backgroundColor: '#FFB55B', borderRadius: '50%',
            }}
            >
              <img src={star} alt="" style={{ width: '15px', height: '15px' }} />
            </div>
          );
        }
        return (
          <div style={{
            height: '25%', width: '25%', display: 'inline-block', backgroundColor: '#C7C7C7', borderRadius: '50%',
          }}
          >
            <img src={star} alt="" style={{ width: '15px', height: '15px', marginBottom: '2px' }} />
          </div>
        );
      },
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <span>
              <Tooltip placement="left" title="Click here to export Payments">
                <CSVLink
                  target="_blank"
                  className="mr-3"
                  filename="Payments Report.csv"
                  data={exportData}
                  headers={exportHeaders}
                  onMouseEnter={() => setFillColor(true)}
                  onMouseLeave={() => setFillColor(false)}
                >
                  <ExportIcon
                    fill={fillColor
                      ? '#279dfe'
                      : '#C0C0C0'}
                  />
                </CSVLink>
              </Tooltip>
            </span>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Payments">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && (
                <FilterWrapper
                  style={{ marginLeft: '-260px' }}
                >
                  <Filters
                    filtersGrid={filterPayments}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                    handleFromValue={handleFromValue}
                    handleToValue={handleToValue}
                    stuses={PaymentTypes}
                  />
                  <div className="px-4 mt-2">
                    {
                      (filterPayments.length) < (PAYMENTS_FILTERS_TYPES && PAYMENTS_FILTERS_TYPES.length) && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
            </span>
          </Col>
        </div>
      </Row>
      <RBAC
        fallback={(
          <Table
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={payments}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
      >
        <Table
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns]}
          rowKey={(record) => record.gid}
          dataSource={payments}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  walletTransactions: state.fetchWalletTransaction,
  walletTransactionStatus: state.walletTransactionStatus,
  payments: state.walletTransaction.content,
  loading: state.loading.loading,
  totalElements: state.walletTransaction.totalElements,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWalletTransaction: param => dispatch(walletTransactionActions.fetchWalletTransaction({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllTransactions);
