import { REPORT_TEMPLATE } from '../actionTypes';

export const fetchReportTemplate = action => ({
  type: REPORT_TEMPLATE.FETCH,
  payload: action.payload,
});

export const addReportTemplate = (action) => ({
  type: REPORT_TEMPLATE.ADD,
  payload: action.payload,
});
