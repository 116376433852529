// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import getPaymentButtonBackground from 'utils/getPaymentButtonBackground';
import getPaymentButtonStatus from 'utils/getPaymentButtonStatus';
import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';
import getUniquePaymentTypes from 'utils/getUniquePaymentTypes';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Icon,
  Input,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpError,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  paymentsButtonDetailsActions,
  paymentsButtonActions,
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
  PAGES,
  ROLES,
  PAYMENTSBUTTON_STATUS,
  ACQUIRER_TYPES,
} from 'appconstants';
import SimpleReactValidator from 'simple-react-validator';
import RBAC from 'components/rbac';
import Clipboard from 'components/NavIcons/copyclipboard';
import CancelIcon from 'components/NavIcons/CanceledIcon';
import UpdateIcon from 'components/NavIcons/UpdatedIcon';
import CONSTANTS from 'store/constants';

import DeletePaymentButton from './components/deletePaymentButton';
import CopyPaymentButton from './components/copyPaymentButton';
import AddPaymentButton from './components/AddPaymentButton';

const payButton = 'https://s3.ap-south-1.amazonaws.com/assets.swirepay.com/img/pay-button.png';

type Props = {
  fetchPaymentsButtonDetails: Function,
  fetchPayments: Function,
  setNotification: Function,
  updatePaymentsButton: Function,
  addPaymentsButton: Function,
  paymentsButtonDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  payments: Array<Object>,
  totalElements: number,
  paymentsButtonDetailsStatus: string,
  validator: boolean,
  DeactivePaymentButton: Function,
}

const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '75px',
  textAlign: 'center',
});

const PaymentsButtonDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchPaymentsButtonDetails,
    fetchPayments,
    setNotification,
    paymentsButtonDetails,
    updatePaymentsButton,
    addPaymentsButton,
    loading,
    submitting,
    history,
    payments,
    totalElements,
    selectedAccount,
    paymentsButtonDetailsStatus,
    validator,
    DeactivePaymentButton,
  } = props;
  const { gid } = props.match.params;
  const [, forceUpdate] = useState();
  const [pagination, setPagination] = useState({});
  const [deletePaymentButtonModal, setDeletePaymentButtonModal] = useState(false);
  const [redirect, SetRedirect] = useState(false);
  const [copyPaymentButtonModal, setCopyPaymentButtonModal] = useState(false);
  const [addPaymentButtonModal, setAddPaymentButtonModal] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [showEdit, setShowEdit] = useState(false);
  const [Description, setDescription] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const supportedCurrency = (selectedAccount && selectedAccount.supportedCurrencies);
  const paymentMethods = getUniquePaymentTypes(selectedAccount && selectedAccount.supportedPaymentTypes);
  const acquirerName = (selectedAccount && selectedAccount.acquirer && selectedAccount.acquirer.name);

  simpleValidator.current.purgeFields();

  const getData = (query) => {
    fetchPaymentsButtonDetails(gid);
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchPayments({ currentPage, sortParams, gid });
  };

  const copyHtml = (el) => {
    const payButtonHtml = document.getElementById(el).innerHTML;
    navigator.clipboard.writeText(payButtonHtml);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSBUTTON.BUTTON_SUCCESS,
    });
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSBUTTON.SUCCESS,
    });
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.PAYMENTS}/${searchParams}`,
      state: location.search,
    });
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (paymentsButtonDetails.gid && paymentsButtonDetails.description) {
      setDescription(paymentsButtonDetails.description);
    }
  }, [paymentsButtonDetails]);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.PAYMENTBUTTON);
    }
  }, [validator]);

  useEffect(() => {
    if (!validator) {
      setShowEdit(false);
      setAddPaymentButtonModal(false);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.PAYMENTBUTTON}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const showDeletePaymentButtonModal = () => {
    setDeletePaymentButtonModal(true);
  };

  const deleteSelectedPaymentButton = async (paymentButtonGid: string) => {
    DeactivePaymentButton(paymentButtonGid);
    SetRedirect(true);
  };

  const addNewPaymentButton = (paymentInfo: Object) => {
    const {
      amount,
      description,
      redirectUrl,
      paymentMethodData,
      currencyCode,
    } = paymentInfo;
    const currency = amount.replace(/[^\d]/g, '');
    addPaymentsButton({
      amount: Number(currency),
      redirectUri: redirectUrl,
      description,
      currencyCode,
      paymentMethodType: paymentMethodData,
    });
    SetRedirect(true);
  };

  const editNewPaymentButton = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      description: Description,
    };
    updatePaymentsButton({
      gid,
      params,
    });
  };

  const columns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net, record) => {
        const total = (net / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix}${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '25%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <StatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </StatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '25%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? (record.paymentMethod.upi.vpa || 'UPI')
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.googlePayUS) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.googlePayUS.paymentMethodInfo && record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails)
                  ? (`**** ${record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails}`)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.instantPayment && record.paymentMethod.instantPayment.walletAlias) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.instantPayment.walletAlias.alias ? (record.paymentMethod.instantPayment.walletAlias.alias)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType) {
          const QrName = (record.paymentMethod.paymentType && record.paymentMethod.paymentType.name);
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(QrName === 'QR') ? QrName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'paymentDate',
      width: '25%',
      align: 'left',
      render: (paymentDate) => {
        if (paymentDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(paymentDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const terminalColumns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net, record) => {
        const total = (net / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <StatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </StatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? (record.paymentMethod.upi.vpa || 'UPI')
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.googlePayUS) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.googlePayUS.paymentMethodInfo && record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails)
                  ? (`**** ${record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails}`)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.instantPayment && record.paymentMethod.instantPayment.walletAlias) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.instantPayment.walletAlias.alias ? (record.paymentMethod.instantPayment.walletAlias.alias)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType) {
          const QrName = (record.paymentMethod.paymentType && record.paymentMethod.paymentType.name);
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(QrName === 'QR') ? QrName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'TERMINAL NAME',
      dataIndex: 'terminal',
      width: '20%',
      align: 'left',
      render: (terminal) => {
        if (terminal && terminal.deviceName) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {terminal && terminal.deviceName}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DATE',
      dataIndex: 'paymentDate',
      width: '18%',
      align: 'left',
      render: (paymentDate) => {
        if (paymentDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(paymentDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        paymentsButtonDetails.gid && paymentsButtonDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    {paymentsButtonDetails.amount < 0
                      ? `(${paymentsButtonDetails.currency.prefix} 
                      ${formatNumber(
                        (paymentsButtonDetails.amount / 100).toFixed(paymentsButtonDetails.currency.toFixed).replace(/-/g, ''), currencyType,
                      )})`
                      : paymentsButtonDetails.amount
                        ? `${paymentsButtonDetails.currency.prefix} 
                      ${formatNumber((paymentsButtonDetails.amount / 100).toFixed(paymentsButtonDetails.currency.toFixed), currencyType)}`
                        : <>&#8211;</>}
                  </SpText>
                </Col>
                <Col>
                  <SpButton
                    type="secondary"
                    className="mr-4"
                    shape="round"
                    ghost
                    onClick={() => setAddPaymentButtonModal(true)}
                  >
                    Duplicate Payment Button
                  </SpButton>
                  {
                    paymentsButtonDetails.status === PAYMENTSBUTTON_STATUS.ACTIVE && (
                      <>
                        <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>

                          <SpButton
                            type="danger"
                            shape="round"
                            onClick={showDeletePaymentButtonModal}
                          >
                            Deactivate Payment Button
                          </SpButton>
                        </RBAC>
                      </>
                    )
                  }
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Description</span>
                  </Col>
                  {!showEdit && (
                    <Col span={6}>
                      {paymentsButtonDetails.description
                        ? <SpH5>{paymentsButtonDetails.description}</SpH5>
                        : <>&#8211;</>}
                      <Tooltip placement="top" title="Edit">
                        <Icon
                          type="edit"
                          className="ml-3"
                          style={{ fontSize: '20px', cursor: 'pointer' }}
                          onClick={() => { setShowEdit(!showEdit); }}
                        />
                      </Tooltip>
                    </Col>
                  )}
                  {showEdit && (
                    <Col span={6}>
                      <div className="w-85 mt-2 d-flex">
                        <Input
                          placeholder="Description"
                          value={Description}
                          onChange={(e) => setDescription(e.currentTarget.value)}
                        />
                        <SpButton
                          className="ml-2 pl-2 pr-2"
                          type="primary"
                          borderRadius="none"
                          onClick={editNewPaymentButton}
                        >
                          <UpdateIcon />
                        </SpButton>
                        <SpButton
                          className="ml-2 pl-2 pr-2"
                          type="secondary"
                          borderRadius="none"
                          style={{ borderColor: '#C4C4C4' }}
                          onClick={() => {
                            setShowEdit(!showEdit);
                            setDescription(Description);
                            simpleValidator.current.hideMessages();
                            forceUpdate();
                          }}
                        >
                          <CancelIcon />
                        </SpButton>
                      </div>
                      <SpError>
                        {simpleValidator.current.message('description', Description, 'required')}
                      </SpError>
                    </Col>
                  )}
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Status</span>
                  </Col>
                  <Col span={6}>
                    {paymentsButtonDetails.status
                      ? (
                        <StatusTag style={{ backgroundColor: getPaymentButtonBackground(paymentsButtonDetails.status) }}>
                          {getPaymentButtonStatus(paymentsButtonDetails.status)}
                        </StatusTag>
                      )
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Redirect Url</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {paymentsButtonDetails.redirectUri
                        ? paymentsButtonDetails.redirectUri
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Link</span>
                  </Col>
                  <Col span={12}>
                    {paymentsButtonDetails.link && paymentsButtonDetails.status !== PAYMENTSBUTTON_STATUS.DEACTIVE
                      && paymentsButtonDetails.status !== PAYMENTSBUTTON_STATUS.DELETED
                      ? (
                        <SpH5>{paymentsButtonDetails.link}
                          <SpButton
                            type="secondary"
                            className="ml-2"
                            borderRadius="50px"
                            onClick={() => { copyCode(paymentsButtonDetails.link); }}
                          >
                            <Clipboard />
                          </SpButton>
                        </SpH5>
                      )
                      : (
                        <>&#8211;</>
                      )}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <Col span={3}>
                      <span>Button</span>
                    </Col>
                    <Col span={12}>
                      {paymentsButtonDetails.link && paymentsButtonDetails.status !== PAYMENTSBUTTON_STATUS.DEACTIVE
                        && paymentsButtonDetails.status !== PAYMENTSBUTTON_STATUS.DELETED
                        ? (
                          <div className="d-flex align-items-center">
                            <span id="paynow-button">
                              <a
                                href={paymentsButtonDetails.link}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <img src={payButton} alt="payButton" width="150" height="35" />
                              </a>
                            </span>
                            <SpButton
                              type="secondary"
                              className="ml-2"
                              borderRadius="50px"
                              onClick={() => setCopyPaymentButtonModal(true)}
                            >
                              <Clipboard />
                            </SpButton>
                          </div>
                        )
                        : (
                          <div className="d-flex align-items-center">
                            <span>
                              <a
                                href={paymentsButtonDetails.link}
                                rel="noopener noreferrer"
                                target="_blank"
                                disabled
                              >
                                <img src={payButton} alt="payButton" width="150" height="35" />
                              </a>
                            </span>
                            <SpButton
                              type="secondary"
                              className="ml-2"
                              borderRadius="50px"
                              disabled
                            >
                              <Clipboard />
                            </SpButton>
                          </div>
                        )}
                    </Col>
                  </Col>
                  <Col span={24} className="d-flex justify-content-end">
                    <SpH5 color="#7f7f7f">{paymentsButtonDetails.gid}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              {/* ADD PAYMENTBUTTON MODAL */}
              {addPaymentButtonModal && (
                <AddPaymentButton
                  visible={addPaymentButtonModal}
                  submitting={submitting}
                  selectedAccount={selectedAccount}
                  paymentMethods={paymentMethods}
                  paymentsButtonDetails={paymentsButtonDetails}
                  supportedCurrency={supportedCurrency}
                  close={() => setAddPaymentButtonModal(false)}
                  submit={addNewPaymentButton}
                />
              )}
              {/* DELETE PAYMENT MODAL */}
              {deletePaymentButtonModal && (
                <DeletePaymentButton
                  visible={deletePaymentButtonModal}
                  paymentsButtonDetails={paymentsButtonDetails}
                  submitting={submitting}
                  close={() => setDeletePaymentButtonModal(false)}
                  submit={deleteSelectedPaymentButton}
                />
              )}
              {/* COPY PAYMENT MODAL */}
              {copyPaymentButtonModal && (
                <CopyPaymentButton
                  visible={copyPaymentButtonModal}
                  paymentsButtonDetails={paymentsButtonDetails}
                  submitting={submitting}
                  close={() => setCopyPaymentButtonModal(false)}
                  copyHtml={copyHtml}
                  payButton={payButton}
                />
              )}
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">Payment Sessions</SpText>
                </Col>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={
                  (acquirerName && acquirerName === ACQUIRER_TYPES.IMSPOS) ? terminalColumns : columns
                }
                rowKey={(record) => record.gid}
                dataSource={payments}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  paymentsButtonDetails: state.paymentsButtonDetails,
  paymentsButtonDetailsStatus: state.paymentsButtonDetails.paymentsButtonDetailsStatus,
  payments: state.paymentsButtonDetails.payments.content,
  totalElements: state.paymentsButtonDetails.payments ? state.paymentsButtonDetails.payments.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentsButtonDetails: gid => dispatch(paymentsButtonDetailsActions.fetchPaymentsButtonDetails({
    payload: gid,
  })),
  fetchPayments: param => dispatch(paymentsButtonDetailsActions.fetchPayments({
    payload: param,
  })),
  DeactivePaymentButton: param => dispatch(paymentsButtonDetailsActions.DeactivePaymentButton({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  updatePaymentsButton: param => dispatch(paymentsButtonDetailsActions.updatePaymentsButton({
    payload: param,
  })),
  addPaymentsButton: param => dispatch(paymentsButtonActions.addPaymentsButton({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsButtonDetails);
