import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import CouponsList from './components/CouponsList';
import CouponsDetails from './components/CouponsDetails';

const Coupons = () => (
  <Switch>
    <Route exact path="/coupons" component={CouponsList} />
    <Route exact path="/coupons/:gid" component={CouponsDetails} />
  </Switch>
);

export default Coupons;
