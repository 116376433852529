import { ACCOUNT_SLUG } from '../actionTypes';

export const fetchAccountSlug = (action) => ({
  type: ACCOUNT_SLUG.FETCH,
  payload: action.payload,
});

export const addAccountSlug = (action) => ({
  type: ACCOUNT_SLUG.ADD,
  payload: action.payload,
});

export const linkAccountSlug = (action) => ({
  type: ACCOUNT_SLUG.LINK,
  payload: action.payload,
});

export const unLinkAccountSlug = (action) => ({
  type: ACCOUNT_SLUG.UNLINK,
  payload: action.payload,
});
