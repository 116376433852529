import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  TRANSFER_SETTINGS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchTransferSetting = async () => axiosAuthInstance.get(`${API_END_POINTS.TRANSFER_SETTING}`);

const postTransferSetting = async payload => axiosAuthInstance.post(API_END_POINTS.TRANSFER_SETTING, payload);

const patchTransferSetting = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.TRANSFER_SETTING}/${gid}`, params);

const postLiveTransfer = async () => axiosAuthInstance.post(`${API_END_POINTS.TRANSFER_SETTING}/enable-live-transfer`);

export function* getTransferSetting(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchTransferSetting, action.payload);
    yield put({ type: TRANSFER_SETTINGS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: TRANSFER_SETTINGS.FAILED, payload: e.response.data });
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addTransferSetting(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(postTransferSetting, action.payload);
    const { data: { entity } } = yield call(fetchTransferSetting);
    yield put({ type: TRANSFER_SETTINGS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUPPORT.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateTransferSetting(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchTransferSetting, action.payload);
    const { data: { entity } } = yield call(fetchTransferSetting);
    yield put({ type: TRANSFER_SETTINGS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUPPORT.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* enableLiveTransfer(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(postLiveTransfer, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ENABLE_LIVETRANSFER,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
