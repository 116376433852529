import React from 'react';
import { Img } from '../design';
import VisaIcon from '../imgs/visa.svg';
import MasterIcon from '../assets/imgs/master.png';
import AeIcon from '../assets/imgs/ae.png';
import DefaultCardIcon from '../assets/imgs/default-card.svg';
import ApplepayIcon from '../assets/imgs/Apple_Pay_logo.svg';
import GooglepayIcon from '../assets/imgs/Google_Pay_Logo.svg';
import SwirepayUsIcon from '../assets/imgs/Swirepay_us_logo.svg';
import CashIcon from '../imgs/cashIcon.svg';
import { CardNames, SUPPORTED_PAYMENT_TYPES } from '../appconstants';

export const AmountDivideByHundred = (amount:number) => (amount ? (amount / 100).toFixed(2) : amount);

export const BrandLogo = (brandName:string) => {
  let icon;
  switch (brandName) {
    case CardNames.AMERICAN_EXPRESS:
      icon = AeIcon;
      break;
    case CardNames.VISA:
      icon = VisaIcon;
      break;
    case CardNames.MASTERCARD:
      icon = MasterIcon;
      break;
    case SUPPORTED_PAYMENT_TYPES.APPLE_PAYMENT:
      icon = ApplepayIcon;
      break;
    case SUPPORTED_PAYMENT_TYPES.GOOGLE_PAY_US:
      icon = GooglepayIcon;
      break;
    case SUPPORTED_PAYMENT_TYPES.SWIREPAY_US:
      icon = SwirepayUsIcon;
      break;
    case SUPPORTED_PAYMENT_TYPES.CASH:
      icon = CashIcon;
      break;
    default:
      icon = DefaultCardIcon;
  }
  return <Img verticalAlign="middle" borderRadius="3px" width="45px" src={icon} />;
};
