import { ADMIN_BIN } from '../actionTypes';

export const fetchAdminBin = (action) => ({
  type: ADMIN_BIN.FETCH,
  payload: action.payload,
});

export const addAdminBin = (action) => ({
  type: ADMIN_BIN.ADD,
  payload: action.payload,
});

export const updateAdminBin = (action) => ({
  type: ADMIN_BIN.UPDATE,
  payload: action.payload,
});
