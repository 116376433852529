// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Input,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  MESSAGES,
  TRANSFER_TYPES,
  BENE_TYPES,
  CASHFREE_TRANSFER_TYPES,
} from 'appconstants';
import {
  validationRegex,
  validators,
} from 'utils/validationMessages';
import { SpH5, SpError } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  submitting: boolean,
  isIndia: boolean,
  issuerName: string,
  fundingSourceDetailsData: Object,
  contactDetailsData: Object,
};

const Accont = (props: Props, ref) => {
  const {
    submitting,
    fundingSourceDetailsData,
    issuerName,
    isIndia,
    contactDetailsData,
  } = props;
  const [, forceUpdate] = useState();
  const [accountNumber, setAccountNumber] = useState(fundingSourceDetailsData.accountNumber);
  const [confirmAccountNumber, setConfirmAccountNumber] = useState(fundingSourceDetailsData.confirmAccountNumber);
  const [routingNumber, setRoutingNumber] = useState(fundingSourceDetailsData.routingNumber);
  const [accountType, setAccountType] = useState(fundingSourceDetailsData.accountType || (isIndia ? 'CURRENT' : 'CHECKING'));
  const [businessType, setBusinessType] = useState(fundingSourceDetailsData.businessType || 'INDIVIDUAL');
  const [bankName, setBankName] = useState(fundingSourceDetailsData.bankName);
  const [upiId, setUpiId] = useState(fundingSourceDetailsData.upiId);
  const [transferType, setTransferType] = useState(fundingSourceDetailsData.transferType);
  const accountRegex = /[0-9]{6,17}/;
  const routingRegex = /[0-9]{9}/;
  const upiRegex = /^\w.+@\w+$/;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      confirmAccount: {
        message: MESSAGES.BANK_DETAILS.MATCH,
        rule: (val, params) => val === params[0],
      },
      accountNumber: {
        message: MESSAGES.BANK_DETAILS.VALID,
        rule: val => accountRegex.test(val),
      },
      bankRoutingNumber: {
        message: MESSAGES.BANK_DETAILS.ROUTING,
        rule: val => routingRegex.test(val),
      },
      bankName: {
        message: validators.bussiness.validBankName,
        rule: val => validationRegex.nameRegex.test(val),
      },
      stringLength: {
        message: 'please enter a valid bank name',
        rule: val => val.length >= 4,
      },
      upiNumber: {
        message: MESSAGES.BANK_DETAILS.UPI,
        rule: val => upiRegex.test(val),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      accountNumber,
      confirmAccountNumber,
      routingNumber,
      accountType,
      bankName,
      upiId,
      transferType,
      businessType,
    }),
  }));

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3 mt-2">
          <label htmlFor=""><SpH5>Account Type</SpH5></label>
          {
            contactDetailsData.countryCode === 'IN' && (
              <Select
                className="w-100"
                showSearch
                value={accountType}
                onChange={(value) => {
                  setAccountType(value);
                }}
              >
                <Option key={1} value="CURRENT">CURRENT ACCOUNT</Option>
                <Option key={2} value="SAVINGS">SAVINGS</Option>
              </Select>
            )
          }
          {
            contactDetailsData.countryCode === 'US' && (
              <Select
                className="w-100"
                showSearch
                value={accountType}
                onChange={(value) => {
                  setAccountType(value);
                }}
              >
                <Option key={1} value="SAVINGS">SAVINGS</Option>
                <Option key={2} value="CHECKING">CHECKING</Option>
              </Select>
            )
          }
          <SpError>
            {simpleValidator.current.message('account type', accountType, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Account Holder Name</SpH5></label>
          <Input
            placeholder="Name"
            value={bankName}
            onChange={(e) => setBankName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('bank Name', bankName, 'required|bankName|stringLength')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Account Number</SpH5></label>
          <Input
            placeholder="Account Number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('account number', accountNumber, 'required|accountNumber')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Retype Account Number</SpH5></label>
          <Input
            placeholder="Confirm Account Number"
            value={confirmAccountNumber}
            onChange={(e) => setConfirmAccountNumber(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Confirm Account Number', confirmAccountNumber, `required|confirmAccount:${accountNumber}`)}
          </SpError>
        </div>
        {
          contactDetailsData.countryCode === 'US' && (
            <div>
              <label htmlFor=""><SpH5>Routing Number</SpH5></label>
              <Input
                placeholder="Routing Number"
                value={routingNumber}
                onChange={(e) => setRoutingNumber(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('routing number', routingNumber, 'required|bankRoutingNumber')}
              </SpError>
            </div>
          )
        }
        {
          contactDetailsData.countryCode === 'IN' && (
            <div>
              <label htmlFor=""><SpH5>IFSC Code</SpH5></label>
              <Input
                placeholder="IFSC Code"
                value={routingNumber}
                onChange={(e) => setRoutingNumber((e.currentTarget.value).toUpperCase())}
              />
              <SpError>
                {simpleValidator.current.message('ifsc code', routingNumber, 'required')}
              </SpError>
            </div>
          )
        }
        <div className="mb-3">
          <label htmlFor=""><SpH5>Bank Name</SpH5></label>
          <Input
            placeholder="Name"
            value={bankName}
            onChange={(e) => setBankName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('bank Name', bankName, 'required|bankName|stringLength')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Nick Name (Optional)</SpH5></label>
          <Input
            placeholder="Name"
            value={bankName}
            onChange={(e) => setBankName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('bank Name', bankName, 'required|bankName|stringLength')}
          </SpError>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(Accont);
