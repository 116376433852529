import React from 'react';

/* eslint-disable */

const rightArrow = ({ fill = "silver" }) => (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.99956 1.575L11.8491 5.42456M11.8491 5.42456L7.99956 9.27412M11.8491 5.42456L1 5.42456" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

export default rightArrow;
