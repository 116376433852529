import React from 'react';
import UpdatePaymentPageDetails from './UpdatePaymentPageDetails';
import '../../../utils/reseponsive';

type Props = {
  paymentPageMeta: Object,
  account: Object,
  phoneCode: String,
}

const UpdatePaymentPageIndex = (props: Props) => {
  const {
    paymentPageMeta,
    phoneCode,
    account,
  } = props;

  return (
    <UpdatePaymentPageDetails
      paymentPageMeta={paymentPageMeta}
      onSubmit={null}
      phoneCode={phoneCode}
      account={account}
      paymentHeading={paymentPageMeta && paymentPageMeta.paymentHeading}
      isPreview
      isAdaptive
    />
  );
};

export default UpdatePaymentPageIndex;
