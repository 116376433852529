import { BANKTRANSFER_STATUSES } from 'appconstants';

export default value => {
  if (value === 'BANK_VERIFICATION_PENDING') {
    return BANKTRANSFER_STATUSES.BANK_VERIFICATION_PENDING;
  } if (value === 'VERIFICATION_PENDING') {
    return BANKTRANSFER_STATUSES.VERIFICATION_PENDING;
  } if (value === 'VERIFIED') {
    return BANKTRANSFER_STATUSES.VERIFIED;
  } if (value === 'PENDING_FOR_SELF_APPROVAL') {
    return BANKTRANSFER_STATUSES.PENDING_FOR_SELF_APPROVAL;
  } return BANKTRANSFER_STATUSES.DISABLED;
};
