// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  selectedPayment: Object,
  submitting: boolean,
};

const UpdatePayment = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedPayment,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const { mrn } = selectedPayment;
  const [batchNumber, setBatchNumber] = useState();
  const [rrNumber, setRRNumber] = useState();
  const [maskedNumber, setMaskedNumber] = useState();
  const [authCode, setAuthCode] = useState();
  const [amid, setAmid] = useState();
  const [atid, setAtid] = useState();
  const [appName, setAppName] = useState('credit');
  const [cardType, setCardType] = useState('visa');
  const [disableButton, setDisableButton] = useState(false);
  const rrnRegex = /^$|^\d{12}$/;
  const authRegex = /^[A-Z0-9]{6}$/;
  const maskRegex = /^[0-9 *]*$/;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      rrn: {
        message: 'RRN must be valid',
        rule: val => rrnRegex.test(val),
      },
      auth: {
        message: 'Auth code must be valid',
        rule: val => authRegex.test(val),
      },
      mask: {
        message: 'Card number must be valid',
        rule: val => maskRegex.test(val),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      batchNumber,
      rrNumber,
      maskedNumber,
      authCode,
      appName,
      mrn,
      cardType,
      amid,
      atid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Payment"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Batch Number</SpH5></label>
          <Input
            placeholder="Enter Batch Number"
            value={batchNumber}
            onChange={(e) => setBatchNumber(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Batch Number', batchNumber, 'required|numeric|max:8')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>RRN</SpH5></label>
          <Input
            placeholder="Enter RRN"
            value={rrNumber}
            onChange={(e) => setRRNumber(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('rrn', rrNumber, 'required|numeric|rrn')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Masked Card Number</SpH5></label>
          <Input
            placeholder="Enter Card Number"
            value={maskedNumber}
            onChange={(e) => setMaskedNumber(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('card number', maskedNumber, 'required|mask|min:14|max:16')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Auth Code</SpH5></label>
          <Input
            placeholder="Enter Auth Code"
            value={authCode}
            onChange={(e) => setAuthCode((e.currentTarget.value))}
          />
          <SpError>
            {simpleValidator.current.message('auth code', authCode, 'required|auth')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>MID</SpH5></label>
          <Input
            placeholder="Enter MID"
            value={amid}
            onChange={(e) => setAmid(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('mid', amid, 'required|numeric')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>TID</SpH5></label>
          <Input
            placeholder="Enter TID"
            value={atid}
            onChange={(e) => setAtid(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('tid', atid, 'required|numeric')}
          </SpError>
        </div>
        <div className="mb-3">
          <label><SpH5>App Name</SpH5></label>
          <Select
            className="w-100"
            value={appName}
            onChange={(e) => setAppName(e)}
          >
            <Option key={1} value="credit">Credit</Option>
            <Option key={2} value="debit">Debit</Option>
          </Select>
        </div>
        <div className="mb-3">
          <label><SpH5>CardType</SpH5></label>
          <Select
            className="w-100"
            value={cardType}
            onChange={(e) => setCardType(e)}
          >
            <Option key={1} value="visa">Visa</Option>
            <Option key={2} value="master">Master</Option>
            <Option key={3} value="rupay">Rupay</Option>
            <Option key={4} value="amex">Amex</Option>
          </Select>
        </div>
        <div>
          <label htmlFor=""><SpH5>MRN</SpH5></label>
          <Input
            value={mrn}
            disabled
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(UpdatePayment);
