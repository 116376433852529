import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Icon,
  Row,
  Col,
  DatePicker,
  Select,
} from 'antd';

import queryString from 'query-string';

import moment from 'moment-timezone';

import {
  format,
  startOfYesterday,
  endOfYesterday,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
} from 'date-fns';

import {
  SpButton,
  SpForm,
  SpError,
} from 'components/DesignKit';

import SimpleReactValidator from 'simple-react-validator';

import {
  ADMIN_REPORTS_TYPES,
  ADMIN_REPORTS_CATEGORY,
  ACCOUNT_SPECIFIC_CATEGORY,
  ALL_REPORTS_TYPES,
  REPORTS_DATE_OPTIONAL,
  REPORTS_PERIOD,
  MESSAGES,
  PAGES,
  ROLES,
  TOKENS,
} from 'appconstants';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  adminreportsActions,
  notificationActions,
  accountActions,
  userActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';

import Loading from 'components/Loading';
import RBAC from 'components/rbac';

import ReportsTable from './ReportsTable';

const { Option } = Select;

const { MonthPicker, RangePicker } = DatePicker;

const PICKERS = {
  [REPORTS_PERIOD.yesterday]: DatePicker,
  [REPORTS_PERIOD.daily]: DatePicker,
  [REPORTS_PERIOD.monthly]: MonthPicker,
  [REPORTS_PERIOD.custom]: RangePicker,
  [REPORTS_PERIOD.default]: DatePicker,
};

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  fetchAdminReports: Function,
  setNotification: Function,
  reports: Array<Object>,
  loading: boolean,
  test: boolean,
  selectedAccount: Object,
  setSelectedAccount: Function,
  updateUserAccount: Function,
  role: Object,
  history: {
    push: Function,
  },
  isMobileView: Boolean,
};

const AdminReport = (props: Props) => {
  const {
    loading,
    fetchAdminReports,
    reports,
    setNotification,
    test,
    selectedAccount,
    setSelectedAccount,
    updateUserAccount,
    history,
    role,
    isMobileView,
  } = props;

  const {
    filteredList,
    accounts,
  } = useSelector(state => state.account);

  const location = useLocation();
  const [, forceUpdate] = useState();
  const [dateValue, setDateValue] = useState();
  const [reportType, setReportType] = useState();
  const [reportCategory, setReportCategory] = useState();
  const [reportPeriod, setReportPeriod] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sendEmail, setSendEmail] = useState(false);
  const [pickerLabel, setPickerLabel] = useState('Select a Date');
  const [yesterdayPicker, setYesterdayPicker] = useState(true);
  const [customRange, setCustomRange] = useState(false);
  const [dates, setDates] = useState([]);
  const [apiStartDate, setApiStartDate] = useState();
  const [apiEndDate, setApiEndDate] = useState();
  const accntGid = selectedAccount && selectedAccount.gid;

  const simpleValidator = useRef(new SimpleReactValidator({
    autoForceUpdate: {
      forceUpdate,
    },
  }));

  const getReport = async (reportGid) => {
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.GET_ADMIN_REPORTS}/${reportGid}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    } finally {
      history.push(PAGES.ADMIN_REPORT);
    }
  };

  simpleValidator.current.purgeFields();

  const switchAccountAndGetReport = async (gid, reportGid) => {
    try {
      // This call is happennig in Partnerlist component as well.
      // any change you make here please make sure to change in Partnerlist component as well.
      if ((role && role.name) === ROLES.SUPERUSER) {
        const API_END_POINT = `/internal${API_END_POINTS.JWE}/${gid}/switch?isTest=false`;
        const { data: { entity: { token: { accessToken } } } } = await axiosAuthInstance.get(API_END_POINT);
        localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
        setSelectedAccount(filteredList.content.find(a => a.gid === gid));
        updateUserAccount(gid);
      } else {
        const API_END_POINT = `${API_END_POINTS.JWE}/${gid}/switch?isTest=false`;
        const { data: { entity: { token: { accessToken } } } } = await axiosAuthInstance.get(API_END_POINT);
        localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
        // update selectedAccount
        setSelectedAccount(accounts.content.find(a => a.gid === gid));
        updateUserAccount(gid);
      }
      getReport(reportGid);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  };


  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const reportGid = parsed['report-gid'];
    const accountGid = parsed['account-gid'];
    if (reportGid) {
      if (accountGid && (accountGid === accntGid)) {
        getReport(reportGid);
      } else if (accountGid && (accountGid !== accntGid)) {
        switchAccountAndGetReport(accountGid, reportGid);
      } else {
        getReport(reportGid);
      }
    }
  }, [location]);

  useEffect(() => {
    // initialize start and end date, as values in picker are preselected as well.
    // eslint-disable-next-line no-use-before-define
    onDateChange(moment());

    switch (reportPeriod) {
      case REPORTS_PERIOD.yesterday:
        setPickerLabel('Select a Date');
        setDateValue(moment().add(-1, 'days'));
        break;
      case REPORTS_PERIOD.daily:
        setPickerLabel('Select a Date');
        setDateValue(moment());
        break;
      case REPORTS_PERIOD.custom:
        setPickerLabel('Select a Dates');
        setDateValue(moment());
        break;
      case REPORTS_PERIOD.monthly:
        setPickerLabel('Select a Month');
        setDateValue(moment());
        break;
      default:
        setPickerLabel('Select a Date');
    }
  }, [reportPeriod]);

  const selectReportPeriod = (typeOfReport) => {
    switch (typeOfReport) {
      case REPORTS_PERIOD.yesterday:
        setYesterdayPicker(true);
        setCustomRange(false);
        setReportPeriod(typeOfReport);
        setStartDate(format(startOfYesterday(), DATE_FORMAT));
        setEndDate(format(endOfYesterday(), DATE_FORMAT));
        break;
      case REPORTS_PERIOD.daily:
        setYesterdayPicker(true);
        setCustomRange(false);
        setReportPeriod(typeOfReport);
        break;
      case REPORTS_PERIOD.custom:
        setCustomRange(true);
        setYesterdayPicker(false);
        setDates([]);
        setReportPeriod(typeOfReport);
        setPickerLabel('Select Date Range');
        break;
      case REPORTS_PERIOD.monthly:
        setYesterdayPicker(true);
        setCustomRange(false);
        setReportPeriod(typeOfReport);
        setPickerLabel('Select a Month');
        break;
      default:
        setYesterdayPicker(true);
        setCustomRange(false);
    }
  };

  const onDateChange = (date) => {
    const selectedDate = date._d;

    // if report period is selected, set the picker value
    if (reportPeriod) {
      setDateValue(date);
    }
    if (reportPeriod === REPORTS_PERIOD.yesterday) {
      const startofYesterDay = moment().subtract(1, 'days').startOf('day').format(MOMENT_FORMAT);
      const selectedStartDate = moment.tz(startofYesterDay, selectedAccount.timezone);
      const utcYesterDayStartDate = selectedStartDate.utc().format(MOMENT_FORMAT);
      const endofYesterDay = moment().subtract(1, 'days').endOf('day').format(MOMENT_FORMAT);
      const selectedEndDate = moment.tz(endofYesterDay, selectedAccount.timezone);
      const utcYesterDayEndDate = selectedEndDate.utc().format(MOMENT_FORMAT);
      setApiStartDate(utcYesterDayStartDate);
      setApiEndDate(utcYesterDayEndDate);
    } else if (reportPeriod === REPORTS_PERIOD.daily) {
      const DailyDate = moment(selectedDate).format(MOMENT_FORMAT);
      const startofDay = moment.tz(DailyDate, selectedAccount.timezone).startOf('day');
      const utcDate = startofDay.utc().format(MOMENT_FORMAT);
      const endofDay = moment.tz(DailyDate, selectedAccount.timezone).endOf('day');
      const utcEndDate = endofDay.utc().format(MOMENT_FORMAT);
      setApiStartDate(utcDate);
      setApiEndDate(utcEndDate);
    } else if (reportPeriod === REPORTS_PERIOD.monthly) {
      const MonthlyDate = moment(selectedDate).format(MOMENT_FORMAT);
      const startofMonth = moment.tz(MonthlyDate, selectedAccount.timezone).startOf('month');
      const utcMonthStart = startofMonth.utc().format(MOMENT_FORMAT);
      const endofMonth = moment.tz(MonthlyDate, selectedAccount.timezone).endOf('month');
      const utcMonthEnd = endofMonth.utc().format(MOMENT_FORMAT);
      setApiStartDate(utcMonthStart);
      setApiEndDate(utcMonthEnd);
    }

    switch (reportPeriod) {
      case REPORTS_PERIOD.yesterday:
        setStartDate(format(startOfYesterday(), DATE_FORMAT));
        setEndDate(format(endOfYesterday(), DATE_FORMAT));
        break;
      case REPORTS_PERIOD.daily:
        setStartDate(format(startOfDay(selectedDate), DATE_FORMAT));
        setEndDate(format(endOfDay(selectedDate), DATE_FORMAT));
        break;
      case REPORTS_PERIOD.monthly:
        setStartDate(format(startOfMonth(selectedDate), DATE_FORMAT));
        setEndDate(format(endOfMonth(selectedDate), DATE_FORMAT));
        break;
      default:
        setStartDate(format(selectedDate, DATE_FORMAT));
        setEndDate(format(selectedDate, DATE_FORMAT));
    }
  };

  const customFunction = (date) => {
    /* eslint no-underscore-dangle: ["error", { "allow": ["_d"] }] */
    setStartDate(format(startOfDay(date[0]._d), DATE_FORMAT));
    setEndDate(format(endOfDay(date[1]._d), DATE_FORMAT));
    const StartDate = moment(date[0]._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(StartDate, selectedAccount.timezone).startOf('day');
    const utcStartDate = selectedDate.utc().format(MOMENT_FORMAT);
    const EndDate = moment(date[1]._d).format(MOMENT_FORMAT);
    const selectedEndDate = moment.tz(EndDate, selectedAccount.timezone).endOf('day');
    const utcEndDate = selectedEndDate.utc().format(MOMENT_FORMAT);
    setApiStartDate(utcStartDate);
    setApiEndDate(utcEndDate);
  };

  const disabledDate = current => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    let tooEarly = dates[0] && dates[0].diff(current, 'days') > 7;
    if (dates[1]) tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return tooEarly || tooLate;
  };
  const customStartDateReset = (open) => {
    if (!open && dates.length === 1) {
      setDates([]);
      setStartDate();
      setEndDate();
    }
  };

  const getPicker = () => {
    const SelectedPicker = PICKERS[reportPeriod];
    return (
      <SelectedPicker
        allowClear={false}
        value={dateValue}
        placeholder={pickerLabel}
        onChange={onDateChange}
      />
    );
  };

  const downloadReport = async (record) => {
    const { gid } = record;
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.GET_ADMIN_REPORTS}/${gid}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const generateReport = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    try {
      await axiosAuthInstance.post(API_END_POINTS.GET_ADMIN_REPORTS, {
        startDate: apiStartDate,
        endDate: apiEndDate,
        sendEmail,
        reportCategory,
        type: reportType,
      });
      setDateValue(null);
      setStartDate();
      setEndDate();
      setApiStartDate();
      setApiEndDate();
      setReportPeriod();
      setReportCategory();
      setReportType();
      setSendEmail();
      simpleValidator.current.hideMessages();
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.REPORT.GENERATE_SUCCESS,
      });
      fetchAdminReports(location.search);
      setCustomRange(false);
      setYesterdayPicker(true);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.GENERATE_ERROR,
      });
    }
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      {
        test
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <h5>Reports are only available for live data</h5>
              </div>
            </Card>
          )
          : (
            <Card>
              <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.ANALYST, ROLES.OPERATIONS, ROLES.DEVELOPER, ROLES.SUPERUSER]}>
                <SpForm className="px-0">
                  <Row type="flex" justify="space-between">
                    <Col>
                      <h5 className="text-uppercase mb-3 mt-2">Generate Report</h5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" className="pl-4">
                    <Col span={3}>
                      <span>Report type</span>
                    </Col>
                    <Col span={7}>
                      <div className="w-100">
                        <Select
                          className="w-100"
                          placeholder="Select a type"
                          onChange={value => setReportType(value)}
                          value={reportType}
                        >
                          {
                            ADMIN_REPORTS_TYPES.map(report => (
                              <Option
                                key={1}
                                value={report.value}
                                className="text-capitalize"
                              >
                                {report.display}
                              </Option>
                            ))
                          }
                        </Select>
                      </div>
                      <SpError>
                        {simpleValidator.current.message('Report Type', reportType, 'required')}
                      </SpError>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" className="pl-4">
                    <Col span={3}>
                      <span>Account</span>
                    </Col>
                    <Col span={7}>
                      <div className="w-100">
                        {
                          ((reportType === ALL_REPORTS_TYPES.IMSDATAEXPORT) || (reportType === ALL_REPORTS_TYPES.UNASSIGNEDSERVICECOMPLAINTS)) ? (
                            <Select
                              className="w-100"
                              placeholder="Select account type"
                              onChange={value => setReportCategory(value)}
                              value={reportCategory}
                            >
                              {
                                ADMIN_REPORTS_CATEGORY.map(report => (
                                  <Option
                                    key={1}
                                    value={report.value}
                                    className="text-capitalize"
                                  >
                                    {report.display}
                                  </Option>
                                ))
                              }
                            </Select>
                          ) : (
                            <Select
                              className="w-100"
                              placeholder="Select account type"
                              onChange={value => setReportCategory(value)}
                              value={reportCategory}
                            >
                              {
                                ACCOUNT_SPECIFIC_CATEGORY.map(report => (
                                  <Option
                                    key={1}
                                    value={report.value}
                                    className="text-capitalize"
                                  >
                                    {report.display}
                                  </Option>
                                ))
                              }
                            </Select>
                          )
                        }
                      </div>
                      <SpError>
                        {simpleValidator.current.message('account type', reportCategory, 'required')}
                      </SpError>
                    </Col>
                  </Row>
                </SpForm>
                {
                  ((reportType === REPORTS_DATE_OPTIONAL.DUPLICATECHECKOUTPAYMENTS)
                    || (reportType === REPORTS_DATE_OPTIONAL.UNASSIGNEDSERVICECOMPLAINTS))
                    ? '' : (
                      <>
                        <SpForm>
                          <Row type="flex" justify="start" className="pl-4">
                            <Col span={3}>
                              <span>Period</span>
                            </Col>
                            <Col span={7}>
                              <div className="w-100">
                                <Select
                                  className="w-100"
                                  placeholder="Select a period"
                                  onChange={selectReportPeriod}
                                  value={reportPeriod}
                                >
                                  {
                                    Object.keys(REPORTS_PERIOD).map((period, index) => (
                                      <Option key={index} value={REPORTS_PERIOD[period]}>
                                        {REPORTS_PERIOD[period]}
                                      </Option>
                                    ))
                                  }
                                </Select>
                              </div>
                              <SpError>
                                {simpleValidator.current.message('Period', reportPeriod, 'required')}
                              </SpError>
                            </Col>
                          </Row>
                        </SpForm>
                        {yesterdayPicker && (
                          <SpForm>
                            <Row type="flex" justify="start" className="pl-4">
                              <Col span={3}>
                                <span>{pickerLabel}</span>
                              </Col>
                              <Col span={7}>
                                <div className="w-100">
                                  {getPicker()}
                                </div>
                                <SpError>
                                  {!yesterdayPicker ? simpleValidator.current.message('Date', dateValue, 'required') : ''}
                                </SpError>
                              </Col>
                            </Row>
                          </SpForm>
                        )}
                        {customRange && (
                          <SpForm>
                            <Row type="flex" justify="start" className="pl-4">
                              <Col span={3}>
                                <span>{pickerLabel}</span>
                              </Col>
                              <Col span={7}>
                                <div className="w-100">
                                  <RangePicker
                                    allowClear={false}
                                    onChange={customFunction}
                                    disabledDate={disabledDate}
                                    onCalendarChange={value => {
                                      setDates(value);
                                    }}
                                    value={[moment(startDate), moment(endDate)]}
                                    onOpenChange={customStartDateReset}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </SpForm>
                        )}
                      </>
                    )
                }
                <SpForm>
                  <Row gutter={[32, 32]} className="pl-4">
                    <Col span={10}>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={generateReport}
                      >
                        Generate Report
                      </SpButton>
                    </Col>
                  </Row>
                </SpForm>
              </RBAC>
              <ReportsTable
                reports={reports}
                selectedAccount={selectedAccount}
                fetchAdminReports={fetchAdminReports}
                downloadReport={downloadReport}
                isMobileView={isMobileView}
              />
            </Card>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  reports: state.adminreports,
  user: state.user,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdminReports: param => dispatch(adminreportsActions.fetchAdminReports({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setSelectedAccount: account => dispatch(accountActions.setSelectedAccount(account)),
  updateUserAccount: account => dispatch(userActions.updateUserAccount(account)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminReport);
