import React, { useEffect, useState } from 'react';
import {
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { Menu } from 'antd';
import queryString from 'query-string';

import { SpTab } from 'components/DesignKit';
import AllPayments from './components/AllPayments';

type Props = {
  location: {
    pathname: string,
    search: string,
  },
  history: {
    push: Function,
    replace: Function,
  },
};

const Payments = (props: Props) => {
  const [current, setCurrent] = useState('succeeded');
  const { path } = useRouteMatch();
  const {
    location,
    history,
  } = props;

  const setMenu = (query) => {
    const parsed = queryString.parse(query);
    if (parsed['status.IN']) {
      const value = parsed['status.IN'];
      switch (value) {
        case 'SUCCEEDED': {
          setCurrent('succeeded');
          break;
        }
        case 'REFUNDED': {
          setCurrent('refund');
          break;
        }
        case 'REQUIRE_CAPTURE': {
          setCurrent('uncaptured');
          break;
        }
        case 'ALL_PAYMENTS': {
          setCurrent('payments');
          break;
        }
        default:
          setCurrent('payments');
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      setMenu(location.search);
    } else {
      history.replace(`${path}?status.IN=SUCCEEDED`);
    }
  }, [location]);

  return (
    <div>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
        <Menu.Item key="succeeded">
          <SpTab
            to={`${path}?status.IN=SUCCEEDED`}
            selected={current === 'succeeded'}
          >
            Succeeded
          </SpTab>
        </Menu.Item>
        <Menu.Item key="refund">
          <SpTab
            to={`${path}?status.IN=REFUNDED`}
            selected={current === 'refund'}
          >
            Refunded
          </SpTab>
        </Menu.Item>
        <Menu.Item key="uncaptured">
          <SpTab
            to={`${path}?status.IN=REQUIRE_CAPTURE`}
            selected={current === 'uncaptured'}
          >
            Uncaptured
          </SpTab>
        </Menu.Item>
        <Menu.Item key="payments">
          <SpTab
            to={`${path}?status.IN=ALL_PAYMENTS`}
            selected={current === 'payments'}
          >
            All Payments
          </SpTab>
        </Menu.Item>
      </Menu>
      <AllPayments {...props} />
    </div>
  );
};

export default withRouter(Payments);
