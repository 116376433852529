import { INVENTORY_ORDERS_DETAILS } from '../actionTypes';

const initialState = {
  content: [],
  payments: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_ORDERS_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case INVENTORY_ORDERS_DETAILS.PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
      };
    default:
      return state;
  }
};
