// @flow
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';

import {
  COUNTRY_PHONE_CODE,
} from 'appconstants';

const { Option } = Select;

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  submitting: Function,
  phoneCode: string,
  selectedCustomer: Object,
};

const EditAssosiate = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    phoneCode,
    selectedCustomer,
  } = props;

  const [, forceUpdate] = useState();
  const [firstName, setFirstName] = useState(selectedCustomer && selectedCustomer.firstName);
  const [lastName, setLastName] = useState(selectedCustomer && selectedCustomer.lastName);
  const [customerEmail, setCustomerEmail] = useState(selectedCustomer.email);
  const [customerPhone, setCustomerPhone] = useState('');
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [phoneFormate, setPhoneFormate] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const gid = selectedCustomer && selectedCustomer.gid;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useEffect(() => {
    const phone = selectedCustomer.phone ? selectedCustomer.phone : '';
    const phoneResult = phone ? findPhoneNumbersInText(phone) : null;
    if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
      setCountryCode(`+${phoneResult[0].number.countryCallingCode}`);
      setCustomerPhone(phoneResult[0].number.nationalNumber);
      const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
      const formater = data.substr(data.indexOf(' ') + 1);
      setPhoneFormate(formater);
    }
  }, [selectedCustomer]);

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setCustomerPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${customerPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    setDisableButton(true);
    setCustomerPhone(customerPhone.replace(/[^0-9+]/g, ''));
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      firstName,
      lastName,
      customerEmail,
      customerPhone,
      associateGid: gid,
      associateCountryCode: countryCode,
    });
  };
  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Shop Associate"
    >
      <div className="px-4">
        <div className="d-flex">
          <div className="mb-3 mr-3">
            <label htmlFor=""><SpH5>First Name</SpH5></label>
            <Input
              placeholder="First name"
              value={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />

            <SpError>
              {simpleValidator.current.message('first name', firstName, 'required')}
            </SpError>
          </div>

          <div className="mb-3">
            <label htmlFor=""><SpH5>Last Name</SpH5></label>
            <Input
              placeholder="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('last name', lastName, 'required')}
            </SpError>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            defaultValue={customerEmail}
            disabled
            onChange={(e) => setCustomerEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('email', customerEmail, 'required|email|checkLowerCase')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Phone Number</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                value={countryCode}
                onChange={onCountryCodeChange}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormate}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setCustomerPhone(e.currentTarget.value);
                    const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormate(formater);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('phone number', customerPhone, `phoneNumValidation:${countryCode}${customerPhone}`)}
              </SpError>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditAssosiate);
