// @flow
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpError, SpButton,
} from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  couponDetails: Object,
  submitting: boolean,
};

const EditCoupon = (props: Props) => {
  const {
    visible,
    close,
    submit,
    couponDetails,
    submitting,
  } = props;
  const [disableButton, setDisableButton] = useState(false);
  const [, forceUpdate] = useState();
  const [name, setName] = useState(couponDetails.name);
  const { active } = couponDetails;
  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const updateCoupon = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      active,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={updateCoupon} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Coupon"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <label htmlFor=""><SpH5>Name</SpH5></label>
        <Input
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <SpError>
          {simpleValidator.current.message('name', name, 'required')}
        </SpError>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.loading.submitting,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditCoupon);
