import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Icon,
} from 'antd';
import {
  SpText,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import formatAmount from 'utils/formatAmount';
import RBAC from 'components/rbac';

import {
  bankStatementDetailsActions,
  notificationActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';

import {
  PAGES,
  ROLES,
} from 'appconstants';

type Props = {
  bankStatementDetails: Array<Object>,
  bankStatementPayables: Array<Object>,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  fetchAccountReceivables: Function,
  fetchAccountPayables: Function,
  selectedAccount: Object,
  totalElements: number,
  loading: boolean,
  test: boolean,
  isMobileView: Boolean,
};

const BankStatementDetails = (props: Props) => {
  const {
    selectedAccount,
    bankStatementDetails,
    bankStatementPayables,
    loading,
    fetchAccountReceivables,
    fetchAccountPayables,
    totalElements,
    test,
    history,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const location = useLocation();
  const { gid } = props.match.params;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const [pagination, setPagination] = useState({});
  const currencyType = selectedAccount && selectedAccount.currency;
  const debouncedFetchAccountReceivables = debounce(fetchAccountReceivables, 2000, { leading: true });

  const getData = () => {
    debouncedFetchAccountReceivables(gid);
    fetchAccountPayables(gid);
  };

  useEffect(() => {
    getData(location.search);
  }, [test, selectedAccount, location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      current: parseInt(currentPage, 10),
      total: totalElements,
      showTotal: total => `Total ${total} items`,
    }));
  }, [bankStatementDetails]);


  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    const fieldName = filters.field;

    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${fieldName}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${fieldName}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.BANKSTATMENT,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.BANKSTATMENT,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const columns = [
    {
      title: 'TRANSACTION DATE',
      dataIndex: 'transactionDate',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '20%',
      align: 'left',
      sorter: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount) => {
        const total = (amount / 100);
        return (
          <span> {`${prefix} ${formatAmount(total, currencyType)}`}</span>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'MATCHED',
    dataIndex: 'actions',
    width: '20%',
    align: 'center',
    render: (text, record) => (
      <>
        {
          record && record.matched ? (
            <Icon
              type="check"
              style={{
                fontSize: '24px', marginRight: '8px', cursor: 'pointer', color: 'green',
              }}
            />
          ) : (
            <Icon
              type="close"
              style={{
                fontSize: '16px', marginRight: '8px', cursor: 'pointer', color: 'red',
              }}
            />
          )
        }
      </>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      <Card className="pl-2">
        <Row type="flex" className="my-2">
          <Col>
            <SpText className="mr-4" fontSize="16px" color="#999999" fontWeight="700">
              Receivables
            </SpText>
          </Col>
        </Row>
        <RBAC
          fallback={(
            <Table
              columns={columns}
              rowKey={(record) => record.gid}
              dataSource={bankStatementDetails}
              pagination={pagination}
              onChange={handleTableChange}
            />
          )}
          permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}
        >
          <Table
            columns={[...columns, actionColumn]}
            rowKey={(record) => record.gid}
            dataSource={bankStatementDetails}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </RBAC>
      </Card>
      <Card className="pl-2 mt-3">
        <Row type="flex" className="my-2">
          <Col>
            <SpText className="mr-4" fontSize="16px" color="#999999" fontWeight="700">
              Payables
            </SpText>
          </Col>
        </Row>
        <RBAC
          fallback={(
            <Table
              columns={columns}
              rowKey={(record) => record.gid}
              dataSource={bankStatementPayables}
              pagination={pagination}
              onChange={handleTableChange}
            />
          )}
          permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}
        >
          <Table
            columns={[...columns, actionColumn]}
            rowKey={(record) => record.gid}
            dataSource={bankStatementPayables}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </RBAC>

      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  bankStatementDetails: state.bankStatementDetails.content,
  totalDocumentElements: state.bankStatementDetails.totalElements,
  bankStatementPayables: state.bankStatementDetails.payables.content,
  totalBankStatementPayables: state.bankStatementDetails.payables ? state.bankStatementDetails.payables.totalElements : '',
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  user: state.user,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountReceivables: gid => dispatch(bankStatementDetailsActions.fetchAccountReceivables({
    payload: gid,
  })),
  fetchAccountPayables: gid => dispatch(bankStatementDetailsActions.fetchAccountPayables({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementDetails);
