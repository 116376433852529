// @flow
import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  Input,
  Modal,
  Select,
} from 'antd';

import SimpleReactValidator from 'simple-react-validator';
import NumberFormat from 'react-number-format';
import {
  SpH5, SpError, SpText, SpButton,
} from 'components/DesignKit';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import { countryCodeValidation } from 'utils/validationMessages';
import {
  COUNTRY_PHONE_CODE,
} from 'appconstants';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  confirmWalletAlias: Boolean,
};

const WalletAlias = (props: Props) => {
  const {
    close,
    submit,
    visible,
    submitting,
    selectedAccount,
    confirmWalletAlias,
  } = props;
  const [, forceUpdate] = useState();

  const [alias, setAlias] = useState('');
  const [code, setCode] = useState('');
  const [type, setType] = useState('EMAIL');
  const [contactPhone, setContactPhone] = useState();
  const [phoneFormate, setPhoneFormate] = useState();
  // eslint-disable-next-line max-len
  const [phoneNumberCode, setPhoneCode] = useState(getCountryPhoneCode((selectedAccount && selectedAccount.country && selectedAccount.country.id)));

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      type,
      alias,
      code,
      phone: contactPhone ? `${phoneNumberCode}${contactPhone}` : null,
      contactPhone,
      phoneNumberCode,
    });
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setContactPhone(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  useEffect(() => {
    if (phoneNumberCode === '+1') {
      setPhoneFormate('(###) ###-####');
    } else if (phoneNumberCode === '+91') {
      setPhoneFormate('##### #####');
    } else {
      setPhoneFormate('##########');
    }
  }, []);

  const onCountryCodeChange = (value) => {
    if (value === '+1') {
      setPhoneFormate('(###) ###-####');
      setPhoneCode(value);
    } else if (value === '+91') {
      setPhoneFormate('##### #####');
      setPhoneCode(value);
    } else {
      setPhoneFormate('##########');
      setPhoneCode(value);
    }
  };


  const selectType = (e) => {
    setType(e);
    simpleValidator.current.purgeFields();
    if (e === 'EMAIL') {
      setContactPhone('');
    } else if (e === 'PHONE') {
      setAlias('');
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>{confirmWalletAlias ? 'Submit' : 'Verify'}</SpButton>,
      ]}
      title="Update Wallet Alias"
    >
      <div className="px-4">
        <>
          {
            confirmWalletAlias ? (
              <div>
                <div className="mt-2">
                  <label htmlFor=""><SpH5>Verification Code</SpH5></label>
                  <Input
                    placeholder="Enter code"
                    value={code}
                    onChange={(e) => setCode(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('Verification Code', code, 'required')}
                  </SpError>
                </div>
                <SpText className="mt-4" fontSize="12px">Please use verification code sent to your email </SpText>
              </div>
            ) : (
              <div>
                <div className={submitting ? 'OVERLAY' : ''} />
                <div className="mt-2 mb-3">
                  <label htmlFor=""><SpH5>Type</SpH5></label>
                  <Select
                    className="w-100"
                    value={type}
                    onChange={(e) => selectType(e)}
                  >
                    <Option key={1} value="EMAIL">Email</Option>
                    <Option key={2} value="PHONE">Phone</Option>
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('Type', type, 'required')}
                  </SpError>
                </div>

                {
                  type === 'EMAIL' ? (
                    <div>
                      <label htmlFor=""><SpH5>Wallet Alias</SpH5></label>
                      <Input
                        placeholder="example@xyz.com"
                        value={alias}
                        onChange={(e) => setAlias(e.currentTarget.value)}
                      />
                      {
                        type === 'EMAIL' && (
                          <SpError>
                            {simpleValidator.current.message('Wallet alias', alias, 'required|email|checkLowerCase')}
                          </SpError>
                        )
                      }
                    </div>
                  ) : (
                    <div>
                      <label htmlFor=""><SpH5>Phone</SpH5></label>
                      <div className="d-flex">
                        <div
                          style={{ width: '20%' }}
                          onKeyPress={e => countryCodeValidation(e)}
                        >
                          <Select
                            className="w-100"
                            showSearch
                            value={phoneNumberCode}
                            onChange={onCountryCodeChange}
                          >
                            {COUNTRY_PHONE_CODE.map((item) => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div style={{ width: '80%' }}>
                          <NumberFormat
                            className="InputnumFormate"
                            value={contactPhone}
                            format={phoneFormate}
                            mask="_"
                            allowEmptyFormatting
                            onChange={onPhoneNumberChange}
                          />
                          {
                            type === 'PHONE' && (
                              <SpError>
                                {simpleValidator.current.message('phone number', contactPhone, 'required|phone')}
                              </SpError>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
        </>
      </div>
    </Modal>

  );
};

// $FlowFixMe
export default WalletAlias;
