// @flow
import React, { useRef, useState, useEffect } from 'react';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import getStateList from 'utils/stateList';
import {
  validators,
} from 'utils/validationMessages';

import { COUNTRY_CODES } from 'appconstants';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  zipTag: string,
  selectedAccount: Object,
  addressType: string,
  address: Object,
  addressMethod: string,
};

const AddNewAddress = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    zipTag,
    selectedAccount,
    addressType,
    address,
    addressMethod,
  } = props;

  const [, forceUpdate] = useState();
  const [streetName, setStreetName] = useState(address ? address.street : '');
  const [cityName, setCityName] = useState(address ? address.city : '');
  const [stateName, setStateName] = useState(address ? address.state : '');
  const [zipCode, setZipCode] = useState(address ? address.postalCode : '');
  const [countryId, setCountryId] = useState((selectedAccount && selectedAccount.country && selectedAccount.country.id));
  const [countryName, setCountryName] = useState('');
  const [stateList, setSateList] = useState([]);
  const cuntry = selectedAccount.country.name;
  const postalCodeRegex = cuntry === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
    },
  }));

  useEffect(() => {
    setSateList(getStateList(countryId));
    if (countryId === 2) {
      setCountryName('INDIA');
    } else if (countryId === 1) {
      setCountryName('USA');
    }
  }, []);

  const updateCountry = (value) => {
    setCountryId(value);
    setSateList(getStateList(value));
  };

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      streetName,
      cityName,
      stateName,
      zipCode,
      countryId,
      addressType,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>{`${addressMethod} ${addressType}`}</SpButton>,
      ]}
      title={`${addressMethod} ${addressType}`}
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Street Address</SpH5></label>
          <Input
            placeholder="Street Address"
            value={streetName}
            onChange={(e) => setStreetName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('street', streetName, 'required')}
          </SpError>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName}
                onChange={(e) => setCityName(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('city', cityName, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                value={stateName}
                className="w-100"
                placeholder="State"
                showSearch
                optionFilterProp="children"
                onChange={(e) => setStateName(e)}
              >
                {
                stateList.map(s => (
                  <Option
                    key={s.id}
                    value={s.id}
                    className="text-capitalize"
                  >
                    { s.name }
                  </Option>
                ))
              }
              </Select>
              <SpError>
                {simpleValidator.current.message('state', stateName, 'required')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipTag}</SpH5></label>
              <Input
                placeholder={zipTag}
                value={zipCode}
                onChange={(e) => setZipCode(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message(zipTag, zipCode, 'required|postalcode')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Country</SpH5></label>
              <Select
                defaultValue={countryName}
                style={{ width: '100%' }}
                onChange={updateCountry}
                disabled
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ID}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default AddNewAddress;
