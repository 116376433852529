import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Select,
  Input,
  Modal,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  visible: boolean,
  close: boolean,
  submit: boolean,
  paymentDetails: Object,
  submitting: boolean,
  refundAmount: Number,
  selectedAccount: Object,
}

const RefundPayment = (props: Props) => {
  const {
    visible,
    close,
    submit,
    paymentDetails,
    submitting,
    refundAmount,
    selectedAccount,
  } = props;
  const [, forceUpdate] = useState();
  const [amount, setAmount] = useState(refundAmount);
  const [note, setNote] = useState();
  const [reason, setReason] = useState();
  const gid = paymentDetails && paymentDetails.gid;
  const { description } = paymentDetails;
  const { prefix } = paymentDetails && paymentDetails.currency;
  const [disableButton, setDisableButton] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      refund: {
        message: `Refunded amount should be less than are equal to ${prefix}${refundAmount}.`,
        rule: (val) => Number(val.replace(/,/g, '')) <= Number(`${refundAmount}`.replace(/,/g, '')),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    submit({
      amount,
      reason,
      note,
      gid,
      description,
    });
  };

  const updateReason = (value) => {
    if (value === 'OTHER') {
      setReason(value);
    } else {
      setReason(value);
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      title="Refund Payment"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Refund</SpButton>,
      ]}
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Refund</SpH5></label>
          <Input
            value={amount}
            prefix={prefix}
            onChange={(e) => setAmount(e.currentTarget.value)}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
            onBlur={e => {
              if (e.currentTarget.value) {
                const value = formatAmount(e.currentTarget.value, currencyType);
                setAmount(value);
              }
            }}
          />
          <SpError>
            {simpleValidator.current.message('refund amount', amount, 'required|refund')}
          </SpError>
        </div>

        <div className="pb-3">
          <label><SpH5>Reason</SpH5></label>
          <Select
            placeholder="Select Reason"
            className="w-100"
            value={reason}
            onChange={updateReason}
          >
            <Option key={1} value="DUPLICATE">Duplicate</Option>
            <Option key={2} value="FRAUDULENT">Fraudulent</Option>
            <Option key={3} value="REQUESTED_BY_CUSTOMER">Requested by customer</Option>
            <Option key={4} value="OTHER">Other</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('reason', reason, 'required')}
          </SpError>
        </div>

        <div className="pb-3">
          <label htmlFor=""><SpH5>Note</SpH5></label>
          <TextArea
            rows={3}
            defaultValue={note}
            onChange={(e) => setNote(e.currentTarget.value)}
          />
          <SpError>
            {reason === 'OTHER'
              ? simpleValidator.current.message('note', note, 'required')
              : ''}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(RefundPayment);
