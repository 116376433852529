import { SERVICES_STATUS } from 'appconstants';

export default value => {
  if (value === 'OPEN') {
    return SERVICES_STATUS.OPEN;
  } if (value === 'PENDING') {
    return SERVICES_STATUS.PENDING;
  } if (value === 'IN_PROGRESS') {
    return SERVICES_STATUS.IN_PROGRESS;
  } if (value === 'RESOLVED') {
    return SERVICES_STATUS.RESOLVED;
  } if (value === 'DUPLICATE') {
    return SERVICES_STATUS.DUPLICATE;
  } return SERVICES_STATUS.OPEN;
};
