import React, { useState, useRef, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  Input,
  Table,
  Tooltip,
  Badge,
  Checkbox,
  Collapse,
  Icon,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { CSVLink } from 'react-csv';
import {
  SpText,
  SpButton,
  SpError,
  Page,
  SpStatusTag,
  FilterWrapper,
} from 'components/DesignKit';
import {
  woocommerceActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  FILTERS_TYPES,
  FILTER_STATUSES,
} from 'appconstants';
import { CaretRightOutlined } from '@ant-design/icons';
import Loading from 'components/Loading';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import SuccessModal from 'components/SuccessPage';
import getOrdersBackground from 'utils//getOrdersBackground';
import getOrderStatus from 'utils/getOrderStatus';
import Filters from 'components/Filters';
import moment from 'moment-timezone';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import debounce from 'lodash.debounce';
import queryString from 'query-string';

import getFilterOperators from 'utils/getFilterOperators';

import AceEditor from 'react-ace';

import 'brace/mode/yaml';
import 'brace/theme/kuroir';
import AddNewInstance from './components/AddNewInstance';
import UpdatInstance from './components/UpdatInstance';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const PaymentCheckoutTypes = FILTER_STATUSES.WOOCOMMERCE;
const PAYMENTCHECKOUT_FILTERS_TYPES = FILTERS_TYPES.WOOCOMMERCE;

const { Panel } = Collapse;

type Props = {
  wooCommerceList: Array<Object>,
  loader: boolean,
  validator: boolean,
  addWoocommerce: Function,
  fetchWoocommerce: Function,
  updateWoocommerce: Function,
  accountWoocommerce: Array,
  selectedAccount: Object,
  fetchWoocommerceList: Function,
  preserveFilters: Object,
  setFilterData: Function,
  totalElements: number,
  submitting: boolean,
};

const Woocommerce = (props: Props) => {
  const location = useLocation();
  const {
    wooCommerceList,
    loader,
    addWoocommerce,
    updateWoocommerce,
    fetchWoocommerce,
    accountWoocommerce,
    validator,
    selectedAccount,
    fetchWoocommerceList,
    preserveFilters,
    setFilterData,
    totalElements,
    submitting,
  } = props;
  const history = useHistory();
  const [, forceUpdate] = useState();
  const myRef = useRef();
  const { pathname } = location;
  const [instanceName, setInstanceName] = useState('');
  const [isdefault, setIsDefault] = useState('');
  const [consumerKey, setConsumerKey] = useState('');
  const [consumerSecret, setConsumerSecret] = useState('');
  const [siteUrl, setSiteUrl] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const gid = (accountWoocommerce && accountWoocommerce.length !== 0) ? accountWoocommerce[0].gid : '';
  const [args, setArgs] = useState('');
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(PAYMENTCHECKOUT_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterPayments, setFilterPayments] = useState((preserveFilters && preserveFilters.filterPayments)
    ? (preserveFilters && preserveFilters.filterPayments)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [pagination, setPagination] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [filterColor, setFilterColor] = useState(false);
  const [fillColor, setFillColor] = useState(false);
  const [isUpdateState, setIsUpdateState] = useState(false);
  const [addNewInstanceModal, setAddNewInstanceModal] = useState(false);
  const [updateNewInstanceModal, setUpdateInstanceModal] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterPayments: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });
  const [activePanel, setActivePanel] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState(null);

  const simpleValidator = useRef(new SimpleReactValidator({
    autoForceUpdate: {
      forceUpdate,
    },
    validators: {
      validUrl: {
        message: 'The url must be valid',
        // eslint-disable-next-line max-len
        rule: val => (new RegExp('^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$').exec(val) !== null),
      },
    },
  }));

  const getEnodedData = (data) => {
    const encodData = btoa(data);
    return encodData;
  };

  const addNewSalesforce = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const encodedArgs = getEnodedData(args);
    const params = {
      instanceName,
      default: isdefault,
      consumerKey,
      consumerSecret,
      siteUrl,
      skuYaml: encodedArgs,
    };
    if (accountWoocommerce && accountWoocommerce.length !== 0) {
      updateWoocommerce({
        gid,
        params,
      });
    } else {
      addWoocommerce(params);
    }

    setRedirect(true);
  };

  const addNewInstance = (newInstance: Object) => {
    const {
      name,
      isdefaults,
      consumerKeys,
      consumerSecrets,
      siteUrls,
      arg,
    } = newInstance;
    const encodedArgs = getEnodedData(arg);
    const params = {
      instanceName: name,
      default: isdefaults,
      consumerKey: consumerKeys,
      consumerSecret: consumerSecrets,
      siteUrl: siteUrls,
      skuYaml: arg ? encodedArgs : null,
    };
    addWoocommerce(params);
    setRedirect(true);
  };

  const updateNewInstance = (editInstance: Object) => {
    const {
      name,
      isdefaults,
      consumerKeys,
      consumerSecrets,
      siteUrls,
      arg,
      id,
    } = editInstance;
    const encodedArgs = getEnodedData(arg);
    let params;
    if (isdefaults === true) {
      params = {
        instanceName: name,
        default: isdefaults,
        consumerKey: consumerKeys,
        consumerSecret: consumerSecrets,
        siteUrl: siteUrls,
        skuYaml: arg ? encodedArgs : null,
      };
    } else {
      params = {
        instanceName: name,
        consumerKey: consumerKeys,
        consumerSecret: consumerSecrets,
        siteUrl: siteUrls,
        skuYaml: arg ? encodedArgs : null,
      };
    }
    updateWoocommerce({
      gid: id,
      params,
    });
    setRedirect(true);
  };

  useEffect(() => {
    fetchWoocommerce();
  }, []);

  useEffect(() => {
    if (accountWoocommerce && accountWoocommerce.length !== 0) {
      setConsumerKey(accountWoocommerce[0].consumerKey);
      setConsumerSecret(accountWoocommerce[0].consumerSecret);
      setSiteUrl(accountWoocommerce[0].siteUrl);
      setInstanceName(accountWoocommerce[0].instanceName);
      setIsDefault(accountWoocommerce[0].default);
      setIsUpdateState(true);
      if (accountWoocommerce[0].skuYaml) {
        setArgs(atob(accountWoocommerce[0].skuYaml));
      }
    }
  }, [accountWoocommerce]);

  const exportData = (wooCommerceList && wooCommerceList.length !== 0) ? wooCommerceList.map((values) => ({
    orderId: values.wcOrderId || '',
    status: values.spOrderStatus ? getOrderStatus(values.spOrderStatus) : '',
    email: values.wcBillingAddress ? (values.wcBillingAddress && values.wcBillingAddress.email) : '',
    phone: values.wcBillingAddress ? (values.wcBillingAddress && values.wcBillingAddress.phone) : '',
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
  })) : '';

  const exportHeaders = [
    { label: 'ORDER ID', key: 'orderId' },
    { label: 'STATUS', key: 'status' },
    { label: 'EMAIL', key: 'email' },
    { label: 'PHONE', key: 'phone' },
    { label: 'CREATED ON', key: 'createdAt' },
  ];

  const debouncedFetchWoocommerceList = debounce(fetchWoocommerceList, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchWoocommerceList({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  const selectDetails = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `/woocommerce-orders/${searchParams}`,
      state: location.search,
    });
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && (e.toElement && e.toElement.className !== 'ant-calendar-date')
      && (e.toElement && e.toElement.className !== 'ant-calendar-prev-month-btn')
      && (e.toElement && e.toElement.className !== 'ant-calendar-next-month-btn')) {
      setOpen(false);
    }
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.WOOCOMMERCE,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.WOOCOMMERCE,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterPayments];
    if (filterPayments.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = PAYMENTCHECKOUT_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterPayments(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterPayments];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else if (e[1] === 'spOrderStatus') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = PAYMENTCHECKOUT_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterPayments(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterPayments(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterPayments(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterPayments[index].filterName === 'createdAt') {
      if (filterPayments[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterPayments[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterPayments[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterPayments[index].filterName === 'status') {
      selectedValue = e;
    } else if (filterPayments[index].filterName === 'spOrderStatus') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterPayments];
    list[index].filterValue = selectedValue;
    setFilterPayments(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterPayments];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterPayments[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterPayments(list);
  };

  const handleAddClick = () => {
    const list = [...filterPayments];
    setFilterPayments([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterPayments([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(PAYMENTCHECKOUT_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.WOOCOMMERCE);
  };

  const applyFilters = () => {
    const paymentsFilters = filterPayments.filter((item) => item.checked);
    for (let i = 0; i < paymentsFilters.length; i += 1) {
      if (!paymentsFilters[i].filterName || !paymentsFilters[i].filterOperator || !paymentsFilters[i].filterValue) {
        return;
      }
    }
    if (paymentsFilters[0].filterValue || (paymentsFilters[0].filterValueFrom && paymentsFilters[0].filterValueTo)) {
      setCount(paymentsFilters && paymentsFilters.length);
      for (let i = 0; i < paymentsFilters.length; i += 1) {
        if (paymentsFilters[i].checked) {
          if (paymentsFilters[i].filterName === 'createdAt') {
            paymentsFilters[i] = paymentsFilters[i].filterValue;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else {
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${paymentsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(paymentsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.WOOCOMMERCE,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.WOOCOMMERCE,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [wooCommerceList]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      setSuccessModalOpen(true);
      setTimeout(() => {
        history && history.push(PAGES.INTEGRATIONS);
      }, 5000);
    }
  }, [validator]);

  const onArgsChange = (value) => {
    setArgs(value);
  };

  function callback(key) {
    setActivePanel(key[0]);
    if (key.length > 1) {
      const newKey = new Array(key.at(-1));
      setActivePanel(newKey);
    } else setActivePanel(key[0]);
  }

  const showEditInstanceModal = (value: Object) => {
    setSelectedInstance(value);
    setUpdateInstanceModal(true);
  };


  const columns = [
    {
      title: 'ORDER ID',
      dataIndex: 'wcOrderId',
      width: '15%',
      align: 'left',
      sorter: false,
      className: 'no-break-word',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (wcOrderId) => (
        <span>
          {wcOrderId || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'INSTANCE NAME',
      dataIndex: 'name',
      width: '20%',
      align: 'left',
      sorter: false,
      className: 'no-break-word',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name, record) => {
        if (record && record.wcInstance) {
          const mail = record.wcInstance && record.wcInstance.instanceName;
          return (<span>{mail || <>&#8211;</>}</span>);
        } return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'spOrderStatus',
      width: '13%',
      align: 'left',
      className: 'no-break-word',
      render: (spOrderStatus) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getOrdersBackground(spOrderStatus) }}>
            {getOrderStatus(spOrderStatus)}
          </SpStatusTag>
        </span>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '15%',
      align: 'left',
      sorter: false,
      className: 'no-break-word',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (email, record) => {
        if (record && record.wcBillingAddress) {
          const mail = record.wcBillingAddress && record.wcBillingAddress.email;
          return (<span>{mail || <>&#8211;</>}</span>);
        } if (record && record.wcShippingAddress && record.wcShippingAddress.email) {
          const mail = record.wcShippingAddress && record.wcShippingAddress.email;
          return (<span>{mail || <>&#8211;</>}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
      width: '13%',
      align: 'left',
      sorter: false,
      className: 'no-break-word',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (phone, record) => {
        if (record && record.wcBillingAddress) {
          const phoneNum = record.wcBillingAddress && record.wcBillingAddress.phone;
          return (<span>{phoneNum || <>&#8211;</>}</span>);
        } if (record && record.wcShippingAddress && record.wcShippingAddress.phone) {
          const phoneNum = record.wcShippingAddress && record.wcShippingAddress.phone;
          return (<span>{phoneNum || <>&#8211;</>}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      className: 'no-break-word',
      width: '13%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  if (loader) {
    return <Loading />;
  }

  return (
    <>
      {
        isSuccessModalOpen ? (
          <Page style={{
            position: 'fixed',
            left: 0,
            top: 0,
            backdropFilter: 'blur(2px)',
            backgroundColor: '',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%',
            height: '100%',
            zIndex: 1024,
            transition: 'opacity .15s linear',
          }}
          >
            <Col>
              <SuccessModal isUpdateState={isUpdateState} isOpen={isSuccessModalOpen} />
            </Col>
          </Page>
        ) : (
          <Card>
            <>
              {
                accountWoocommerce && accountWoocommerce.length > 1 ? (
                  <>
                    <Collapse
                      bordered={false}
                      onChange={callback}
                      activeKey={activePanel}
                      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                      className="site-collapse-custom-collapse"
                    >
                      {accountWoocommerce.map(item => (
                        <Panel
                          header={(
                            <>
                              {item.instanceName || <>&#8211;</>}
                              {item.default
                                ? (
                                  <SpStatusTag
                                    style={{
                                      backgroundColor: '#EAEBFF',
                                      color: '#4C51C2',
                                      marginLeft: '20px',
                                    }}
                                  >
                                    Default
                                  </SpStatusTag>
                                ) : null}
                            </>
                          )}
                          key={item.gid}
                        >
                          <Row type="flex" justify="end" align="middle">
                            <Col>
                              <Tooltip placement="top" title="Edit">
                                <Icon
                                  type="edit"
                                  className="ml-3"
                                  style={{ fontSize: '20px', cursor: 'pointer' }}
                                  onClick={() => showEditInstanceModal(item)}
                                />
                              </Tooltip>
                            </Col>
                          </Row>
                          <Row
                            type="flex"
                            justify="start"
                            align="middle"
                            className="ml-4 mb-1"
                            style={{
                              marginTop: '-5px',
                            }}
                          >
                            <Col span={5}>
                              <SpText>Instance Name</SpText>
                            </Col>
                            <Col span={7}>
                              <SpText>{item.instanceName || <>&#8211;</>}</SpText>
                            </Col>
                          </Row>
                          <Row type="flex" justify="start" align="middle" className="ml-4 mt-4 mb-1">
                            <Col span={5}>
                              <SpText>Consumer Key</SpText>
                            </Col>
                            <Col span={7}>
                              <SpText>{item.consumerKey || <>&#8211;</>}</SpText>
                            </Col>
                          </Row>
                          <Row type="flex" justify="start" align="middle" className="ml-4 mt-4 mb-1">
                            <Col span={5}>
                              <SpText>Consumer Secret</SpText>
                            </Col>
                            <Col span={7}>
                              <SpText>{item.consumerSecret || <>&#8211;</>}</SpText>
                            </Col>
                          </Row>
                          <Row type="flex" justify="start" align="middle" className="ml-4 mt-4 mb-1">
                            <Col span={5}>
                              <SpText>Site URL</SpText>
                            </Col>
                            <Col span={7}>
                              <SpText>{item.siteUrl || <>&#8211;</>}</SpText>
                            </Col>
                          </Row>
                          <Row type="flex" justify="start" align="middle" className="ml-4 mt-4 mb-1">
                            <Col span={5}>
                              <SpText>SKU Yaml</SpText>
                            </Col>
                            <Col span={7}>
                              <SpText>
                                {item.skuYaml
                                  ? (
                                    <AceEditor
                                      mode="yaml"
                                      theme="kuroir"
                                      style={{
                                        height: '250px',
                                        width: 'auto',
                                      }}
                                      value={atob(item.skuYaml)}
                                      readOnly
                                      fontSize={14}
                                      showPrintMargin={false}
                                      showGutter={false}
                                      editorProps={{
                                        $blockScrolling: true,
                                      }}
                                      setOptions={{
                                        enableBasicAutocompletion: false,
                                        enableLiveAutocompletion: false,
                                        enableSnippets: false,
                                        showLineNumbers: false,
                                        tabSize: 2,
                                      }}
                                    />
                                  )
                                  : <>&#8211;</>}
                              </SpText>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24} className="d-flex justify-content-end">
                              <SpText color="#7f7f7f">{item.gid}</SpText>
                            </Col>
                          </Row>
                        </Panel>
                      ))}
                    </Collapse>
                  </>
                ) : (
                  <>
                    <Row type="flex" justify="start" align="middle" className="mt-4 mb-1">
                      <Col span={7}>
                        <SpText>Instance Name</SpText>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle" className="mb-4">
                      <Col span={10}>
                        <Input
                          placeholder="Instance Name"
                          value={instanceName}
                          onChange={(e) => setInstanceName(e.currentTarget.value)}
                        />
                      </Col>
                      <Col span={5} className="ml-4">
                        <Checkbox
                          className="mr-2"
                          checked={isdefault}
                          onChange={({ target: { checked } }) => {
                            setIsDefault(checked);
                          }}
                        />
                        <SpText>Default</SpText>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle" className="mt-4 mb-1">
                      <Col span={7}>
                        <SpText>Consumer Key</SpText>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle" className="mb-4">
                      <Col span={10}>
                        <Input.Password
                          placeholder="Consumer Key"
                          autoComplete="new-password"
                          value={consumerKey}
                          onChange={(e) => setConsumerKey(e.currentTarget.value)}
                        />
                        <SpError>
                          {simpleValidator.current.message('consumer key', consumerKey, 'required')}
                        </SpError>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle" className="mt-4 mb-1">
                      <Col span={7}>
                        <SpText>Consumer Secret</SpText>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle" className="mb-4">
                      <Col span={10}>
                        <Input.Password
                          placeholder="Consumer Secret"
                          autoComplete="new-password"
                          value={consumerSecret}
                          onChange={(e) => setConsumerSecret(e.currentTarget.value)}
                        />
                        <SpError>
                          {simpleValidator.current.message('consumer secret', consumerSecret, 'required')}
                        </SpError>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle" className="mt-4 mb-1">
                      <Col span={7}>
                        <SpText>Site Url</SpText>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle" className="mb-4">
                      <Col span={10}>
                        <Input
                          placeholder="Site URL"
                          value={siteUrl}
                          onChange={(e) => setSiteUrl(e.currentTarget.value.trim())}
                        />
                        <SpError>
                          {simpleValidator.current.message('site url', siteUrl, 'required|validUrl',
                            { messages: { required: 'The url field is required' } })}
                        </SpError>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle" className="mt-4 mb-1">
                      <Col span={7}>
                        <SpText>SKU Yaml</SpText>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle" className="mb-4">
                      <Col span={10}>
                        <AceEditor
                          mode="yaml"
                          theme="kuroir"
                          style={{
                            height: '250px',
                            width: 'auto',
                          }}
                          value={args}
                          onChange={onArgsChange}
                          fontSize={14}
                          showPrintMargin={false}
                          showGutter={false}
                          editorProps={{
                            $blockScrolling: true,
                          }}
                          setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: false,
                            tabSize: 2,
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="d-flex justify-content-end">
                        <SpText color="#7f7f7f">{accountWoocommerce && accountWoocommerce.gid}</SpText>
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                accountWoocommerce && accountWoocommerce.length !== 0 ? (
                  <Row type="flex" justify="start" align="middle" className="mt-4 mb-4">
                    <Col span={7}>
                      <div className="pt-1 pb-1 mt-2" style={{ cursor: 'pointer' }}>
                        <SpText
                          fontWeight="600"
                          color="#279dfe"
                          onClick={
                            () => {
                              setAddNewInstanceModal(true);
                              setIsUpdateState(false);
                            }
                          }
                        >
                          {'\u002B'}&nbsp; Add New Instance
                        </SpText>
                      </div>
                    </Col>
                  </Row>
                ) : null
              }
              {
                (accountWoocommerce && accountWoocommerce.length === 0) ? (
                  <Row type="flex" justify="end" gutter={[16, 16]}>
                    <Col>
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-4"
                        onClick={() => history && history.push(PAGES.INTEGRATIONS)}
                      >
                        Cancel
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={() => addNewSalesforce()}
                      >
                        {
                          (accountWoocommerce && accountWoocommerce.length !== 0) ? 'Update Integration' : 'Add Integration'
                        }
                      </SpButton>
                    </Col>
                  </Row>
                ) : (accountWoocommerce && accountWoocommerce.length === 1) ? (
                  <Row type="flex" justify="end" gutter={[16, 16]}>
                    <Col>
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-4"
                        onClick={() => history && history.push(PAGES.INTEGRATIONS)}
                      >
                        Cancel
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={() => addNewSalesforce()}
                      >
                        {
                          (accountWoocommerce && accountWoocommerce.length !== 0) ? 'Update Integration' : 'Add Integration'
                        }
                      </SpButton>
                    </Col>
                  </Row>
                ) : null
              }

            </>
          </Card>
        )
      }
      <Card className="mt-4 mb-4">
        <Row className="my-2">
          <div className="d-flex justify-content-end">
            <Col>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Woocommerce">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Woocommerce Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter woocommerce">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-315px' }}
                  >
                    <Filters
                      filtersGrid={filterPayments}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                      stuses={PaymentCheckoutTypes}
                      handleFromValue={handleFromValue}
                      handleToValue={handleToValue}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterPayments.length < PAYMENTCHECKOUT_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </div>
        </Row>
        {/* ADD INSTANCE MODAL */}
        {addNewInstanceModal && (
          <AddNewInstance
            visible={addNewInstanceModal}
            submitting={submitting}
            selectedAccount={selectedAccount}
            close={() => setAddNewInstanceModal(false)}
            submit={addNewInstance}
          />
        )}
        {/* UPDATE INSTANCE MODAL */}
        {updateNewInstanceModal && (
          <UpdatInstance
            visible={updateNewInstanceModal}
            submitting={submitting}
            selectedAccount={selectedAccount}
            selectedInstance={selectedInstance}
            close={() => setUpdateInstanceModal(false)}
            submit={updateNewInstance}
          />
        )}
        <Table
          onRow={(record) => ({
            onClick: () => {
              selectDetails(record);
            },
          })}
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns]}
          rowKey={(record) => record.gid}
          dataSource={wooCommerceList}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  accountWoocommerce: state.woocommerce.accountWoocommerce.content,
  salesforceError: state.salesforce.salesforceError,
  wooCommerceList: state.woocommerce.wooCommerceList.content,
  totalElements: state.woocommerce.wooCommerceList.totalElements,
  loader: state.loading.loading,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWoocommerce: param => dispatch(woocommerceActions.fetchWoocommerce({
    payload: param,
  })),
  fetchWoocommerceList: param => dispatch(woocommerceActions.fetchWoocommerceList({
    payload: param,
  })),
  addWoocommerce: param => dispatch(woocommerceActions.addWoocommerce({
    payload: param,
  })),
  updateWoocommerce: param => dispatch(woocommerceActions.updateWoocommerce({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});


export default connect(mapStateToProps, mapDispatchToProps)(Woocommerce);
