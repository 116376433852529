// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import {
  Card,
  Row,
  Col,
  Table,
} from 'antd';

import {
  SpText,
  SpH5,
  SpForm,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  bulkTransferDetailsActions,
} from 'store/actions';
import getBulkTransferBackground from 'utils/getBulkTransferBackground';
import getBulkTransferStatus from 'utils/getBulkTransferStatus';
import getTransferBackground from 'utils/getTransferBackground';
import getTransferStatus from 'utils/getTransferStatus';
import {
  PAGES,
} from 'appconstants';

type Props = {
  fetchBulkTransferDetails: Function,
  fetchTransfers: Function,
  bulkTransferDetails: Object,
  transfers: Array<Object>,
  totalElements: number,
  loading: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  isMobileView: Boolean,
}

const BulkTransferDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchBulkTransferDetails,
    fetchTransfers,
    bulkTransferDetails,
    transfers,
    totalElements,
    loading,
    history,
    selectedAccount,
    isMobileView,
  } = props;
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const currencyType = selectedAccount && selectedAccount.currency;
  const [pagination, setPagination] = useState({});
  console.log(bulkTransferDetails, 'bulkTransferDetails');
  const getData = (query) => {
    fetchBulkTransferDetails(gid);
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchTransfers({ currentPage, sortParams, gid });
  };

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }


  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.BULKTRANSFER);
    }
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [transfers]);

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push(`${PAGES.TRANSFERS}/${searchParams}`);
  };

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.BULKTRANSFER}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <span>
            {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '15%',
      align: 'left',
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getTransferBackground(status) }}>
            {getTransferStatus(status)}
          </SpStatusTag>
        </span>
      ),
    },
    {
      title: 'TRANSFER ID',
      dataIndex: 'gid',
      width: '25%',
      align: 'left',
      render: (transferId) => (
        <span style={{ cursor: 'pointer' }}>
          {transferId || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'NAME',
      dataIndex: 'contact.name',
      width: '15%',
      align: 'left',
      render: (name) => (
        <span style={{ cursor: 'pointer' }}>
          {name || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'contact.email',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (email) => (
        <span style={{ cursor: 'pointer' }}>
          {email || <>&#8211;</>}
        </span>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        bulkTransferDetails.gid && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col>
                  {bulkTransferDetails.fileName
                    ? <SpText className="mr-5" fontSize="20px" fontWeight="600">{bulkTransferDetails.fileName}</SpText>
                    : <>&#8211;</>}
                  <SpStatusTag style={{ backgroundColor: getBulkTransferBackground(bulkTransferDetails.status) }}>
                    {getBulkTransferStatus(bulkTransferDetails.status)}
                  </SpStatusTag>
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>File Number</span>
                  </Col>
                  <Col span={9}>
                    {bulkTransferDetails.fileNumber
                      ? <SpH5>{bulkTransferDetails.fileNumber}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>File Name</span>
                  </Col>
                  <Col span={9}>
                    {bulkTransferDetails.fileName
                      ? <SpH5>{bulkTransferDetails.fileName}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Added On</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {
                        bulkTransferDetails.createdAt && selectedAccount && selectedAccount.timezone
                          ? (
                            moment
                              .utc(bulkTransferDetails.createdAt)
                              .tz(selectedAccount.timezone)
                              .format('MMM DD, YYYY')
                          )
                          : <>&#8211;</>
                      }
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Processed On</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {
                        bulkTransferDetails.processedAt && selectedAccount && selectedAccount.timezone
                          ? (
                            moment
                              .utc(bulkTransferDetails.processedAt)
                              .tz(selectedAccount.timezone)
                              .format('MMM DD, YYYY')
                          )
                          : <>&#8211;</>
                      }
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Total Amount</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {bulkTransferDetails.totalRevenue && selectedAccount
                        ? `${selectedAccount.currency.prefix}
                      ${formatNumber((bulkTransferDetails.totalRevenue / 100).toFixed(selectedAccount.currency.toFixed), currencyType)}`
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Total Transfer</span>
                  </Col>
                  <Col span={9}>
                    {bulkTransferDetails.transferCount
                      ? <SpH5>{bulkTransferDetails.transferCount}</SpH5>
                      : 0}
                  </Col>
                </Row>
              </SpForm>
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">Transfers</SpText>
                </Col>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={columns}
                rowKey={(record) => record.gid}
                dataSource={transfers}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  bulkTransferDetails: state.bulkTransferDetails,
  transfers: state.bulkTransferDetails.transfers.content,
  totalElements: state.bulkTransferDetails.transfers ? state.bulkTransferDetails.transfers.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  customersList: state.invoiceDetails.customersList,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBulkTransferDetails: gid => dispatch(bulkTransferDetailsActions.fetchBulkTransferDetails({
    payload: gid,
  })),
  fetchTransfers: param => dispatch(bulkTransferDetailsActions.fetchTransfers({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(BulkTransferDetails);
