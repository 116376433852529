import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  SUBSCRIPTION_BUTTONDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';


const fetchSubscriptionButtonDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.SUBSCRIPTION_BUTTON}/${gid}`);

const DeactiveSubscriptionButton = async gid => axiosAuthInstance.patch(`${API_END_POINTS.SUBSCRIPTION_BUTTON}/${gid}/deactivate`);

const patchSubscriptionButton = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.SUBSCRIPTION_BUTTON}/${gid}`, params);

const fetchPayments = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const queryParam = `&subscriptionButton.gid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.SUBSCRIPTION}${query}${queryParam}${sorting}`);
};

export function* getSubscriptionButtonDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchSubscriptionButtonDetails, action.payload);
    yield put({ type: SUBSCRIPTION_BUTTONDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SUBSCRIPTION_BUTTONDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getSubscriptionButtonList(action) {
  try {
    const { data: { entity } } = yield call(fetchPayments, action.payload);
    yield put({ type: SUBSCRIPTION_BUTTONDETAILS.PAYMENTS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SUBSCRIPTION_BUTTONDETAILS.FAILED, payload: e.response.data });
  }
}

export function* deactiveSubscription(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(DeactiveSubscriptionButton, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUBSCRIPTION_BUTTON.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateSubscriptionButton(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchSubscriptionButton, action.payload);
    const { data: { entity } } = yield call(fetchSubscriptionButtonDetails, action.payload.gid);
    yield put({ type: SUBSCRIPTION_BUTTONDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUBSCRIPTION_BUTTON.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
