import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { Input, LabelText, SpanText } from '../../../design';
import currencyFormatter from '../../../utils/currencyformatter';
import { AmountDivideByHundred } from '../../../helper';
import {
  inputHeight,
  selectHeight,
  checkBoxHeight,
  normalTextFontSize,
  smallTextFontSize,
} from '../../../utils/reseponsive';

type Props = {
  label: string,
  description: string,
  control: any,
  name: string,
  isRequired: boolean,
  amount: number,
  currencyName: string,
  setValue: any,
  options: any,
  isPreview: boolean,
  isAdaptive: boolean
};

const InputFixedAmount = (props: Props) => {
  const {
    label, description, control, name, options, isRequired, amount, currencyName, isPreview, isAdaptive, setValue,
  } = props;
  const [checked, setChecked] = useState(isRequired || null);

  useEffect(() => {
    setValue(`${name}`, checked);
  }, [checked]);

  const customStyles = {
    control: base => ({
      ...base,
      height: inputHeight(42, isPreview, isAdaptive),
      minHeight: selectHeight(30, isPreview, isAdaptive),
      border: '1px solid #29B1CC',
      boxShadow: 'none',
      borderColor: '#29B1CC !important',
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
    option: (styles) => ({
      ...styles,
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
    input: () => ({
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
    menuList: base => ({
      ...base,
      minHeight: 'fit-content',
      maxHeight: 200,
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
  };

  return (
    <>
      <div className="form-group row my-3 no-gutters d-flex align-items-center">
        <div className="col-sm-5">
          <LabelText
            fontSize={normalTextFontSize(isPreview, isAdaptive)}
            color="#0000000"
            hidden={!label}
            htmlFor="number"
            className="col-form-label font-weight-bolder m-0 text-break"
          >{label}
            <SpanText
              className="pl-2"
              hidden={isRequired}
              color="rgba(0, 0, 0, 0.6)"
              fontSize={smallTextFontSize(isPreview, isAdaptive)}
            >(Optional)
            </SpanText>
          </LabelText>
        </div>
        <div className="col-sm-7 text-left">
          <label className="w-100 d-sm-flex align-items-center justify-content-end">
            <Controller
              name={`${name}`}
              control={control}
              as={(
                <Input
                  disabled={isRequired}
                  type="checkbox"
                  fontSize={smallTextFontSize(isPreview, isAdaptive)}
                  height={checkBoxHeight(72, isPreview, isAdaptive)}
                  defaultChecked={checked}
                  onClick={({ target }) => setChecked(target.checked)}
                  width="auto"
                />
              )}
            />
            <SpanText
              fontSize={normalTextFontSize(isPreview, isAdaptive)}
              className="pl-3 font-weight-bold m-0"
            >
              {amount && currencyFormatter(AmountDivideByHundred(amount), currencyName)}
            </SpanText>
          </label>
        </div>
      </div>
      <div hidden={!description} className="col-sm-10 px-0">
        <LabelText className="text-mute mt-1" fontSize={smallTextFontSize(isPreview, isAdaptive)}>{description}</LabelText>
      </div>
      <div className="form-group row mb-0 no-gutters align-items-center">
        <div className="col-sm-5">
          <LabelText
            fontSize={normalTextFontSize(isPreview, isAdaptive)}
            color="#0000000"
            hidden={!label}
            htmlFor="content"
            className="col-form-label font-weight-bolder m-0 text-break"
          >
            <SpanText
              className="pl-2"
              hidden={isRequired}
              color="rgba(0, 0, 0, 0.6)"
              fontSize={smallTextFontSize(isPreview, isAdaptive)}
            >(Optional)
            </SpanText>
          </LabelText>
        </div>
      </div>
      {
        (options && options.length > 0) && (
          <>
            <div className="form-group row mb-0 no-gutters align-items-center">
              <div className="col-sm-5" />
            </div>
            {
              options && options.map((data, index) => (
                <>
                  <div className="form-group row mb-0 no-gutters align-items-center">
                    <div className="col-sm-5" />
                    <div display="flex" className="col-sm-7 mb-2">
                      <LabelText
                        fontSize={normalTextFontSize(isPreview, isAdaptive)}
                        color="#0000000"
                        hidden={!label}
                        htmlFor="content"
                        className="col-form-label font-weight-bolder m-0 text-break"
                      >{data.label}
                      </LabelText>
                      <Controller
                        marginBottom="0px"
                        control={control}
                        name={`${name}#child${index}`}
                        placeholder="select"
                        as={(
                          <Select
                            theme={theme => ({
                              ...theme,
                              borderRadius: '4px',
                              boxShadow: 0,
                            })}
                            styles={customStyles}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            options={data.children}
                          />
                      )}
                      />
                    </div>
                  </div>
                </>
              ))
            }
          </>
        )
      }
    </>
  );
};

export default InputFixedAmount;
