export default status => {
  if (status === 'PAID') {
    return '#ececff';
  } if (status === 'VOID') {
    return '#ffe0b1';
  } if (status === 'REFUNDED') {
    return '#e2e2e2';
  } if (status === 'UNCAPTURED') {
    return '#e2e2e2';
  } if (status === 'PARTIAL_REFUNDED') {
    return '#e2e2e2';
  } return '#ff858d';
};
