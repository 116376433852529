import React from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Card,
  Row,
  Skeleton,
  Icon,
} from 'antd';
import { SpText } from 'components/DesignKit';

type Props = {
    test: Boolean,
};

const AdminOverview = (props: Props) => {
  const {
    test,
  } = props;
  const currencyType = {
    id: 2,
    name: 'INR',
    prefix: '₹',
    toFixed: 2,
    countryAlpha2: 'IN',
  };
  return (
    <>
      {
                test ? (
                  <Card
                    className="mx-auto mt-5"
                    style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
                    bodyStyle={{ height: '100%' }}
                  >
                    <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                      <Icon
                        type="exclamation-circle"
                        className="mb-4"
                        style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                      />
                      <h5>Total volume only available for live data</h5>
                    </div>
                  </Card>
                ) : (
                  <Card
                    className="mx-auto mt-5"
                    style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
                    bodyStyle={{ height: '100%' }}
                  >
                    <Row gutter={[16, 24]}>
                      <Col xs={25} lg={24}>
                        <Card bodyStyle={{ minHeight: '230px' }}>
                          <SpText fontSize="24px" color="#354052">Total Processed All Time</SpText>
                          <Skeleton
                            title={false}
                            rows={2}
                            active
                            loading={false}
                          >
                            <h1 className="font-weight-light my-0 text-center my-3" style={{ paddingTop: '32px' }}>
                              {currencyType.prefix} 5142.00 Cr
                            </h1>
                          </Skeleton>
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                )
            }
    </>
  );
};

const mapStateToProps = state => ({
  test: state.account.test,
});

export default connect(
  mapStateToProps,
  null,
)(AdminOverview);
