import { call, put } from 'redux-saga/effects';

import {
  axiosNoAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  ORDER_RECEIPTS,
  LOADING,
} from '../actionTypes';

const fetchOrderReceipt = async (payload) => axiosNoAuthInstance.get(`${API_END_POINTS.ORDER_RECEIPT}?orderGid=${payload}`);

export function* getOrderReceipt(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchOrderReceipt, action.payload);
    yield put({ type: ORDER_RECEIPTS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: ORDER_RECEIPTS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
