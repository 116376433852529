// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Upload,
  Icon,
} from 'antd';
import {
  axiosAuthInstance,
  API_END_POINTS,
  axiosEmptyInstance,
} from 'api';
import {
  MESSAGES,
} from 'appconstants';
import { notificationActions } from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  uploadCustomDocument: Object,
  setNotification: Function,
};

const UploadProof = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    uploadCustomDocument,
    setNotification,
  } = props;

  const [, forceUpdate] = useState();
  const [documentUrl, setDocumentUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const { gid } = uploadCustomDocument;

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const handleChange = async info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setDocumentUrl('');
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(API_END_POINTS.UPLOAD_CUSTOM_DOCUMENT_RESPONSE);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const documentDetails = {
          keyType: 'CUSTOM_DOCUMENT_REQUEST_RESPONSE',
          fileUrl: entity,
        };
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(API_END_POINTS.UPLOAD_CUSTOM_DOCUMENT_RESPONSE, documentDetails);
        setDocumentUrl(fileUrl);
        setFileName(info.file.name);
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: MESSAGES.ADMIN_TERMINALDETAILS.TERMINAL_PROOF_UPDATE,
        });
      }
    } catch (error) {
      setDocumentUrl('');
      setFileName('');
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.CUSTOM_DOCUMENT.FAILED,
      });
    } finally {
      setLoading(false);
    }
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      documentUrl,
      gid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Submit</SpButton>,
      ]}
      title="Upload Custom Document"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div>
          <div className="d-flex mt-3">
            <label htmlFor="" className="mr-3 pt-1"><SpH5>Document Proof</SpH5></label>
            <Upload
              name="avatar"
              className="avatar-uploader"
              onChange={handleChange}
              showUploadList={false}
            >
              <SpButton
                type="secondary"
                className="align-items-center"
              >
                <Icon type={loading ? 'loading' : 'plus'} />
                <span>Upload</span>
              </SpButton>
              {
                fileName && (
                  <span>
                    <br />{fileName}
                  </span>
                )
              }
            </Upload>
          </div>
          <SpError>
            {simpleValidator.current.message('Document proof', documentUrl, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(UploadProof);
