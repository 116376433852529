/* eslint-disable */
import React from 'react';

const Timeline = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.00018" cy="8" r="7" stroke="#474973" stroke-width="2" />
        <path d="M8.61558 3.69229L8.61558 8.61537L4.30789 8.61537" stroke="#474973" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export default Timeline;