import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  REMINDERS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchReminders = async () => axiosAuthInstance.get(API_END_POINTS.REMINDER_API);

const postReminder = async ({ payload }) => axiosAuthInstance.post(API_END_POINTS.REMINDER_API, payload);

export function* getReminders() {
  try {
    const { data: { entity } } = yield call(fetchReminders);
    yield put({ type: REMINDERS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: REMINDERS.FAILED, payload: e.response.data });
  }
}

export function* sendReminders(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postReminder, action.payload);
    const { data: { entity } } = yield call(fetchReminders);
    yield put({ type: REMINDERS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.REMINDERS.SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      const { data: { entity } } = yield call(fetchReminders);
      yield put({ type: REMINDERS.SUCCESS, payload: entity });
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}
