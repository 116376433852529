import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Menu,
  Icon,
  Card,
  Row,
  Col,
} from 'antd';
import {
  SpTab,
  SpText,
  SpButton,
} from 'components/DesignKit';

type Props = {
  history: {
    push: Function,
    replace: Function,
  },
};

const AccountTransfers = (props: Props) => {
  const { transfers, setTransfers } = useState(false);

  useEffect(() => {
    // setTransfers(true);
  }, []);

  return (
    <Card>
      <h1>AccountTransfers</h1>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountTransfers);
