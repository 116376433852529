import { CHEQUE_TRANSACTIONS_DETAILS } from '../actionTypes';

const initialState = {
  chequetransactionsDetailsStatus: undefined,
  chequetransactionsDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHEQUE_TRANSACTIONS_DETAILS.FETCH:
      return {
        ...state,
        chequetransactionsDetailsStatus: 'STARTED',
      };
    case CHEQUE_TRANSACTIONS_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        chequetransactionsDetailsStatus: 'SUCCESS',
      };
    case CHEQUE_TRANSACTIONS_DETAILS.FAILED:
      return {
        ...state,
        chequetransactionsDetailsStatus: 'FAILED',
        chequetransactionsDetailsError: action.payload,
      };
    default:
      return state;
  }
};
