// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Upload,
  Icon,
  Select,
} from 'antd';
import {
  axiosAuthInstance,
  API_END_POINTS,
  axiosEmptyInstance,
} from 'api';
import {
  MESSAGES,
} from 'appconstants';
import { notificationActions } from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  SpH5,
  SpError,
  SpButton,
  SpCaption,
} from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  setNotification: Function,
};

const AddDocument = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    setNotification,
  } = props;

  const [, forceUpdate] = useState();
  const [type, setType] = useState('VERIFICATION');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const maxWords = 200;
  const [documentUrl, setDocumentUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const updateWordCount = (remainingWords) => {
    const wordCountElement = document.getElementById('wordCount');
    wordCountElement.textContent = `${remainingWords} word${remainingWords !== 1 ? 's' : ''} left`;
  };

  const handleWordChange = (event) => {
    const inputText = event.target.value;
    const words = inputText.split(/\s+/).filter(Boolean);
    if (words.length <= maxWords) {
      setDescription(inputText);
      updateWordCount(maxWords - words.length);
    }
  };

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const handleChange = async info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setDocumentUrl('');
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(API_END_POINTS.UPLOAD_CUSTOM_DOCUMENT_REQUEST);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const documentDetails = {
          keyType: 'CUSTOM_DOCUMENT_REQUEST',
          fileUrl: entity,
        };
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(API_END_POINTS.UPLOAD_CUSTOM_DOCUMENT_REQUEST, documentDetails);
        setDocumentUrl(fileUrl);
        setFileName(info.file.name);
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: MESSAGES.CUSTOM_DOCUMENT.UPDATE_SUCCESS,
        });
      }
    } catch (error) {
      setDocumentUrl('');
      setFileName('');
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.CUSTOM_DOCUMENT.FAILED,
      });
    } finally {
      setLoading(false);
    }
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      type,
      description,
      documentUrl,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Submit Request</SpButton>,
      ]}
      title="New Document Request"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Type</SpH5></label>
          <Select
            placeholder="Select a type"
            className="w-100"
            value={type}
            onChange={(e) => setType(e)}
          >
            <Option key={1} value="VERIFICATION">VERIFICATION</Option>
            <Option key={2} value="OTHER">OTHER</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('type', type, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Enter a name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />

          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <TextArea
            placeholder="Enter description"
            rows={6}
            value={description}
            onChange={handleWordChange}
          />
          <SpCaption>
            <div id="wordCount">{maxWords - description.split(/\s+/).filter(Boolean).length} words left</div>
          </SpCaption>
          <SpError>
            {simpleValidator.current.message('description', description, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Upload Document (optional)</SpH5></label>
          <Input
            placeholder="Upload a file"
            value={fileName}
            suffix={(
              <>
                <Upload
                  name="avatar"
                  className="avatar-uploader"
                  onChange={handleChange}
                  showUploadList={false}
                >
                  <Icon type={loading ? 'loading' : 'upload'} />
                </Upload>
              </>
            )}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddDocument);
