import { REFERRAL } from '../actionTypes';

export const fetchReferral = (action) => ({
  type: REFERRAL.FETCH,
  payload: action.payload,
});

export const addReferral = (action) => ({
  type: REFERRAL.ADD,
  payload: action.payload,
});

export const notifyReferral = (action) => ({
  type: REFERRAL.NOTIFY,
  payload: action.payload,
});
