// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpButton,
} from 'components/DesignKit';
import zelle from 'imgs/zelle.png';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
};

const ZelleAccount = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator({}));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);


  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit('ZELLE');
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Load</SpButton>,
      ]}
      title="Load Balance"
    >
      <div className="px-4 mt-2">
        <img src={zelle} alt="arrow" width="480px" />
      </div>

    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(ZelleAccount);
