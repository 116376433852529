import { TERMINAL_STATUSES } from 'appconstants';

export default value => {
  if (value === 'INVALID_STATE') {
    return TERMINAL_STATUSES.INVALID_STATE;
  } if (value === 'DEACTIVATED') {
    return TERMINAL_STATUSES.DEACTIVATED;
  } if (value === 'ACTIVATION_PENDING') {
    return TERMINAL_STATUSES.ACTIVATION_PENDING;
  } if (value === 'ACTIVE') {
    return TERMINAL_STATUSES.ACTIVE;
  }
  return null;
};
