import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  WOOCOMMERCE_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';


const fetchWooCommerceOrderDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_WOOCOMMERCE}/${gid}`);

const fetchWooCommerceOrderTimeline = async gid => (
  axiosAuthInstance.get(`${API_END_POINTS.GET_WOOCOMMERCE}/${gid}/woocommerce-order-timeline`)
);

const patchWooCommerceOrder = async (payload) => axiosAuthInstance.patch(`${API_END_POINTS.GET_WOOCOMMERCE}/${payload.gid}`, payload.params);

export function* getWooCommerceOrderDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchWooCommerceOrderDetails, action.payload);
    yield put({ type: WOOCOMMERCE_DETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: WOOCOMMERCE_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getWooCommerceOrderTimeline(action) {
  try {
    const { data: { entity } } = yield call(fetchWooCommerceOrderTimeline, action.payload);
    yield put({ type: WOOCOMMERCE_DETAILS.SUCCESS_TIMELINE, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: WOOCOMMERCE_DETAILS.FAILED, payload: e.response.data });
  }
}

export function* updateWooCommerceOrderStatus(action) {
  try {
    yield call(patchWooCommerceOrder, action.payload);
    const { data: { entity } } = yield call(fetchWooCommerceOrderDetails, action.payload.gid);
    yield put({ type: WOOCOMMERCE_DETAILS.SUCCESS, payload: entity });
    const { data } = yield call(fetchWooCommerceOrderTimeline, action.payload.gid);
    yield put({ type: WOOCOMMERCE_DETAILS.SUCCESS_TIMELINE, payload: data.entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: WOOCOMMERCE_DETAILS.FAILED, payload: e.response.data });
  }
}
