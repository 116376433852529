// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Select,
  Checkbox,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  validators,
} from 'utils/validationMessages';
import {
  MAX_AMOUNT,
  COUNTRIES,
} from 'appconstants';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedItemList: Object,
  selectedAccount: Object,
};

const EditItems = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    selectedItemList,
  } = props;

  const [, forceUpdate] = useState();
  const gid = selectedItemList ? selectedItemList.gid : '';
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [name, setName] = useState((selectedItemList && selectedItemList.name) || '');
  const [order, setOrder] = useState(selectedItemList && selectedItemList.sortOrder);
  const [entryPOS, setEntryPOS] = useState(selectedItemList && selectedItemList.showByDefault);
  const [minRequired, setMinRequired] = useState((selectedItemList && selectedItemList.minRequired) || 0);
  const [maxAllowed, setMaxAllowed] = useState((selectedItemList && selectedItemList.maxAllowed) || 0);
  const [disableCheck, setDisableCheck] = useState(!!(selectedItemList && selectedItemList.minRequired));
  const [disableButton, setDisableButton] = useState(false);
  const STRING_LENGTH = 1;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      minLimit: {
        message: 'Sort order must be valid',
        rule: val => val >= 0 && val < 999999,
      },
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      numericValidation: {
        message: 'Sort order must be valid',
        rule: (val) => (val ? !val.toString().includes('.') : true),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const isPOSChange = (value) => {
    const { target: { checked } } = value;
    setEntryPOS(checked);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      gid,
      name,
      order,
      entryPOS,
      minRequired,
      maxAllowed,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Save</SpButton>,
      ]}
      title="Edit Modifier Group"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Enter the name"
            onChange={(e) => setName(e.currentTarget.value)}
            value={name}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required|stringLength')}
          </SpError>
        </div>
        <div className="mb-3">
          <Checkbox
            className="mr-2"
            checked={entryPOS}
            disabled={disableCheck}
            onChange={isPOSChange}
          />
          <SpH5>Show upon order entry on POS</SpH5>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Min Required</SpH5></label>
          <Select
            style={{ width: '100%' }}
            value={minRequired}
            onChange={(e) => {
              setMinRequired(e);
              if (e >= 1) {
                setDisableCheck(true);
                setEntryPOS(true);
              } else {
                setDisableCheck(false);
              }
              if (maxAllowed && maxAllowed < e) {
                setMaxAllowed(e);
              }
            }}
          >
            <Option key={1} value={0}>None</Option>
            <Option key={2} value={1}>1</Option>
            <Option key={3} value={2}>2</Option>
            <Option key={4} value={3}>3</Option>
            <Option key={5} value={4}>4</Option>
            <Option key={6} value={5}>5</Option>
            <Option key={7} value={6}>6</Option>
            <Option key={8} value={7}>7</Option>
            <Option key={9} value={8}>8</Option>
            <Option key={10} value={9}>9</Option>
            <Option key={11} value={10}>10</Option>
            <Option key={12} value={11}>11</Option>
            <Option key={13} value={12}>12</Option>
            <Option key={14} value={13}>13</Option>
            <Option key={15} value={14}>14</Option>
            <Option key={16} value={15}>15</Option>
            <Option key={17} value={16}>16</Option>
            <Option key={18} value={17}>17</Option>
            <Option key={19} value={18}>18</Option>
            <Option key={20} value={19}>19</Option>
            <Option key={21} value={20}>20</Option>
            <Option key={22} value={21}>21</Option>
            <Option key={23} value={22}>22</Option>
            <Option key={24} value={23}>23</Option>
            <Option key={25} value={24}>24</Option>
            <Option key={26} value={25}>25</Option>
            <Option key={27} value={26}>26</Option>
            <Option key={28} value={27}>27</Option>
            <Option key={29} value={28}>28</Option>
            <Option key={30} value={29}>29</Option>
            <Option key={31} value={30}>30</Option>
          </Select>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Max Allowed</SpH5></label>
          <Select
            style={{ width: '100%' }}
            value={maxAllowed}
            onChange={(e) => {
              setMaxAllowed(e);
              if (minRequired && minRequired > e) {
                setMinRequired(e);
              }
            }}
          >
            <Option key={1} value={0}>None</Option>
            <Option key={2} value={1}>1</Option>
            <Option key={3} value={2}>2</Option>
            <Option key={4} value={3}>3</Option>
            <Option key={5} value={4}>4</Option>
            <Option key={6} value={5}>5</Option>
            <Option key={7} value={6}>6</Option>
            <Option key={8} value={7}>7</Option>
            <Option key={9} value={8}>8</Option>
            <Option key={10} value={9}>9</Option>
            <Option key={11} value={10}>10</Option>
            <Option key={12} value={11}>11</Option>
            <Option key={13} value={12}>12</Option>
            <Option key={14} value={13}>13</Option>
            <Option key={15} value={14}>14</Option>
            <Option key={16} value={15}>15</Option>
            <Option key={17} value={16}>16</Option>
            <Option key={18} value={17}>17</Option>
            <Option key={19} value={18}>18</Option>
            <Option key={20} value={19}>19</Option>
            <Option key={21} value={20}>20</Option>
            <Option key={22} value={21}>21</Option>
            <Option key={23} value={22}>22</Option>
            <Option key={24} value={23}>23</Option>
            <Option key={25} value={24}>24</Option>
            <Option key={26} value={25}>25</Option>
            <Option key={27} value={26}>26</Option>
            <Option key={28} value={27}>27</Option>
            <Option key={29} value={28}>28</Option>
            <Option key={30} value={29}>29</Option>
            <Option key={31} value={30}>30</Option>
          </Select>
        </div>
        <div>
          <label htmlFor=""><SpH5>Sort Order</SpH5></label>
          <Input
            placeholder="Enter the order"
            type="number"
            onChange={(e) => setOrder(e.currentTarget.value)}
            value={order}
          />
          <SpError>
            {simpleValidator.current.message('order', order, 'numeric|numericValidation|minLimit')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditItems);
