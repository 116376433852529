import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import WalletList from './components/WalletList';
import WalletDetails from './components/WalletDetails';
import TransactionsList from './components/TransactionsList';
import TransactionsDetails from './components/TransactionsDetails';
import Setup from './components/Setup';

const Wallet = () => (
  <Switch>
    <Route exact path="/wallet" component={WalletList} />
    <Route exact path="/walletdetails/:gid" component={WalletDetails} />
    <Route exact path="/transactionsList" component={TransactionsList} />
    <Route exact path="/transactionsList/:gid" component={TransactionsDetails} />
    <Route exact path="/setup" component={Setup} />
  </Switch>
);

export default Wallet;
