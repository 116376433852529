import React from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  Input, LabelText, SpanText, Text,
} from '../../../design';
import { inputHeight, normalTextFontSize, smallTextFontSize } from '../../../utils/reseponsive';

type Props = {
  label: string,
  description: string,
  control: any,
  errors: any,
  name: string,
  isRequired: boolean,
  isPreview: boolean,
  isAdaptive: boolean
}

const InputNumber = (props: Props) => {
  const {
    label, description, errors, control, name, isRequired, isPreview, isAdaptive,
  } = props;

  const OnInput = event => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  };

  return (
    <>
      <div className="form-group row mb-0 no-gutters align-items-center">
        <LabelText
          fontSize={normalTextFontSize(isPreview, isAdaptive)}
          color="#0000000"
          hidden={!label}
          htmlFor="number"
          className="col-sm-5 col-form-label font-weight-bolder m-0 text-break"
        >{label}
          <SpanText
            className="pl-2"
            hidden={isRequired}
            color="rgba(0, 0, 0, 0.6)"
            fontSize={smallTextFontSize(isPreview, isAdaptive)}
          >(Optional)
          </SpanText>
        </LabelText>
        <div className="col-sm-7">
          <Controller
            defaultValue=""
            border="1px solid #29B1CC !important"
            control={control}
            name={name}
            rules={{
              required: isRequired && 'This field is required',
            }}
            as={(
              <Input
                onInput={OnInput}
                type="text"
                fontSize={smallTextFontSize(isPreview, isAdaptive)}
                height={inputHeight(42, isPreview, isAdaptive)}
                width="100%"
                className="bg-white"
              />
          )}
          />
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text fontSize={smallTextFontSize(isPreview, isAdaptive)} marginTop="0" marginBottom="0px" color="#ff1e1e">{message}</Text>
            )}
          />
        </div>
      </div>
      <div hidden={!description} className="col-sm-10 px-0">
        <LabelText className="text-mute mt-1" fontSize={smallTextFontSize(isPreview, isAdaptive)}>{description}</LabelText>
      </div>
    </>
  );
};

export default InputNumber;
