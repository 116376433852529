import { connect } from 'react-redux';

type Props = {
  role: Object,
  internalRole: Object,
  permit: Array,
  children: Node,
  fallback: Node,
};

const RBAC = (props: Props) => {
  const {
    permit, role, children, fallback, internalRole,
  } = props;
  const type = (role && role.type) ? role.type : (internalRole && internalRole.type);
  const roleType = type && type.toLowerCase();
  return permit && permit.includes(roleType) ? (children || fallback || null) : fallback || null;
};

const mapStateToProps = (state) => ({
  role: state.user.role,
  internalRole: state.user.internalRole,
});

export default connect(mapStateToProps, null)(RBAC);
