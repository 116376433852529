import { WEBHOOK_STATUSES } from 'appconstants';

export default value => {
  if (value === 'ACTIVE') {
    return WEBHOOK_STATUSES.ACTIVE;
  } if (value === 'DEACTIVATED') {
    return WEBHOOK_STATUSES.DEACTIVATED;
  } if (value === 'DELETED') {
    return WEBHOOK_STATUSES.DELETED;
  } if (value === 'FAILED') {
    return WEBHOOK_STATUSES.FAILED;
  }
  return null;
};
