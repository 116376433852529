import React, { useState, useEffect } from 'react';
import { Upload, Icon, message } from 'antd';

import imageOnError from 'utils/imageOnError';
import Previewicon from '../NavIcons/previewIcon';

type Props = {
  setFiles: Function,
  url: string,
  name: string,
  accept: string,
}

const ImageUpload = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [url, setImageURL] = useState(props.url);
  const [preview, setPreview] = useState();
  const { accept } = props;

  useEffect(() => {
    if (props.url) {
      setPreview(<img src={`${props.url}?time=${new Date().getTime()}`} alt="" onError={event => imageOnError(event)} style={{ width: '100%' }} />);
    }
  }, []);

  const beforeUpload = (file) => {
    console.log(file.type, 'file.typefile.typefile.type');
    const isLt5M = file.size / 1024 / 1024 < 5;
    const IMAGE = ['image/png', 'image/jpeg'];
    const pattern = IMAGE.includes(file.type);
    if (!pattern) {
      message.error('File is not an image.');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 5MB!');
      return false;
    }
    return true;
  };

  const uploadButton = (
    <div>
      <Icon type={loading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = async info => {
    setLoading(true);
    const file = info.file.originFileObj;
    if (file) {
      const pattern = /image*/;
      if (file.type.match(pattern)) {
        getBase64(file, imageUrl => {
          setImageURL(imageUrl);
          setPreview(<img src={imageUrl} alt={props.name} title={props.name} onError={event => imageOnError(event)} style={{ width: '100%' }} />);
          setLoading(false);
        });
      } else {
        setImageURL(info.file.name);
        setPreview(<Previewicon />);
        setLoading(false);
      }
      props.setFiles && props.setFiles(file);
    }
    setLoading(false);
  };

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      accept={accept || ''}
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {url ? preview : uploadButton}
    </Upload>
  );
};

export default ImageUpload;
