// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
} from 'antd';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import styled from 'styled-components';
import formatNumber from 'utils/formatNumber';
import Loading from 'components/Loading';
import getBackgroundColor from 'utils/getBackgroundColor';
import getPayoutStatus from 'utils/getPayoutStatus';
import moment from 'moment-timezone';
import {
  PAGES,
  API_STATUS,
  COUNTRIES,
  TASTECLUB_GID,
} from 'appconstants';
import {
  payoutDetailsAction,
} from 'store/actions';

import {
  SpH5,
  SpText,
  SpForm,
} from 'components/DesignKit';

// $FlowFixMe
const StatusTag = styled('div')({
  display: 'inline-block',
  paddingLeft: '10px',
  paddingRight: '10px',
  borderRadius: '10px',
  height: '21px',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  fetchPayoutDetails: Function,
  fetchPayoutTransactions: Function,
  payoutDetails: Object,
  payoutTransactions: Array<Object>,
  loading: boolean,
  test: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  selectedAccount: Object,
  payoutDetailsStatus: string,
}

const PayoutDetail = (props: Props) => {
  const {
    fetchPayoutDetails,
    fetchPayoutTransactions,
    payoutDetails,
    payoutTransactions,
    loading,
    test,
    totalElements,
    history,
    selectedAccount,
    payoutDetailsStatus,
  } = props;
  const { gid } = props.match.params;
  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const gidPath = gid.split('-')[1];
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const labelText = isIndia ? 'UTR Number' : 'Confirmation Number';

  const getData = () => {
    fetchPayoutDetails(gid);
  };

  const getTransactionsData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchPayoutTransactions({ gid, currentPage, sortParams });
  };

  console.log(payoutDetails, 'payoutDetails');

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.PAYOUTS);
    }
  }, []);

  useEffect(() => {
    getData();
    getTransactionsData(location.search);
  }, []);

  useEffect(() => {
    getData();
    getTransactionsData(location.search);
  }, [test, selectedAccount]);

  useEffect(() => {
    getTransactionsData(location.search);
  }, [location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payoutTransactions]);

  const selectedRecord = (value) => {
    if (value.transaction && value.transaction.payout) {
      const payoutGid = value.transaction.payout.gid;
      history.push({
        pathname: `${PAGES.PAYOUTS}/${payoutGid}`,
        state: location.search,
      });
    } else if (value.transaction && value.transaction.paymentSession) {
      const paymentGid = value.transaction.paymentSession.gid;
      history.push({
        pathname: `${PAGES.PAYMENTS}/${paymentGid}`,
        state: location.search,
      });
    }
  };

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.PAYOUTS}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const inColumns = [
    {
      title: 'TYPE',
      dataIndex: 'transaction.type',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (type) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {type || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'transaction.amount',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.transaction.currency.toFixed);
        if (total < 0) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {`(${record.transaction.currency.prefix} ${formatNumber(total, currencyType).replace(/-/g, '')})`}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${record.transaction.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'transaction.description',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (description) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {!description ? <>&#8211;</> : description === ' ' ? <>&#8211;</> : description}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'transaction.createdAt',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <span>&#8211;</span>
        );
      },
    },
  ];

  const columns = [
    {
      title: 'TYPE',
      dataIndex: 'transaction.type',
      width: '10%',
      align: 'left',
      sorter: false,
      render: (type) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {type || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'NET',
      dataIndex: 'transaction.net',
      width: '10%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net, record) => {
        const netransaction = (net / 100).toFixed(record.transaction.currency.toFixed);
        if (netransaction < 0) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {`(${record.transaction.currency.prefix} ${formatNumber(netransaction, currencyType).replace(/-/g, '')})`}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${record.transaction.currency.prefix} ${formatNumber(netransaction, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'transaction.description',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (description) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {!description ? <>&#8211;</> : description === ' ' ? <>&#8211;</> : description}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'transaction.createdAt',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <span>&#8211;</span>
        );
      },
    },
  ];

  if (selectedAccount && selectedAccount.partnerAccountId) {
    const colectedFee = {
      title: 'APPLICATION FEE',
      dataIndex: 'transaction.paymentSession.applicationFee',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (appFee, record) => {
        const applicationfee = (appFee / 100).toFixed(record.transaction.currency.toFixed);
        if (applicationfee < 0) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {`(${record.transaction.currency.prefix} ${formatNumber(applicationfee, currencyType).replace(/-/g, '')})`}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${record.transaction.currency.prefix} ${formatNumber(applicationfee, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    };
    columns.splice(4, 0, colectedFee);
  }

  if (!TASTECLUB_GID.includes(selectedAccount.gid)) {
    const colectedFee = {
      title: 'FEE',
      dataIndex: 'transaction.fee',
      width: '10%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (fee, record) => {
        const transactionfee = (fee / 100 + record.transaction.tax / 100).toFixed(record.transaction.currency.toFixed);
        if (transactionfee < 0) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {`(${record.transaction.currency.prefix} ${formatNumber(transactionfee, currencyType).replace(/-/g, '')})`}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${record.transaction.currency.prefix} ${formatNumber(transactionfee, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    };
    columns.splice(3, 0, colectedFee);
  }

  if (!TASTECLUB_GID.includes(selectedAccount.gid)) {
    const colectedFee = {
      title: 'AMOUNT',
      dataIndex: 'transaction.amount',
      width: '10%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.transaction.currency.toFixed);
        if (total < 0) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {`(${record.transaction.currency.prefix} ${formatNumber(total, currencyType).replace(/-/g, '')})`}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${record.transaction.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    };
    columns.splice(2, 0, colectedFee);
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {
        payoutDetails.gid && payoutDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card className="mb-3">
              <Row type="flex" className="my-2 mb-3">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    {payoutDetails.amount < 0
                      ? `(${payoutDetails.currencyDto.prefix} 
                      ${formatNumber((payoutDetails.amount / 100).toFixed(payoutDetails.currencyDto.toFixed).replace(/-/g, ''), currencyType)})`
                      : `${payoutDetails.currencyDto.prefix} 
                      ${formatNumber((payoutDetails.amount / 100).toFixed(payoutDetails.currencyDto.toFixed), currencyType)}`}
                  </SpText>
                  <StatusTag style={{ backgroundColor: getBackgroundColor(payoutDetails.status) }}>
                    {getPayoutStatus(payoutDetails.status)}
                  </StatusTag>
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Date Paid</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {
                        payoutDetails.payoutDate
                          ? `${moment.utc(payoutDetails.payoutDate).tz(selectedAccount.timezone).format('MMM DD, YYYY hh:mm a')}` : <>&#8211;</>
                      }
                    </SpH5>
                  </Col>
                </Row>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Description</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{payoutDetails.description ? payoutDetails.description : <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Amount</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {payoutDetails.amount < 0
                        ? `(${payoutDetails.currencyDto.prefix} 
                      ${formatNumber((payoutDetails.amount / 100).toFixed(payoutDetails.currencyDto.toFixed).replace(/-/g, ''), currencyType)})`
                        : `${payoutDetails.currencyDto.prefix} 
                      ${formatNumber((payoutDetails.amount / 100).toFixed(payoutDetails.currencyDto.toFixed), currencyType)}`}
                    </SpH5>
                  </Col>
                </Row>
                {
                  payoutDetails && payoutDetails.referenceNumber && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>{labelText}</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>{payoutDetails && payoutDetails.referenceNumber}</SpH5>
                      </Col>
                    </Row>
                  )
                }
                {
                  payoutDetails && payoutDetails.ref1 && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Reference Number1</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>{payoutDetails && payoutDetails.ref1}</SpH5>
                      </Col>
                    </Row>
                  )
                }
                {
                  payoutDetails && payoutDetails.ref2 && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Reference Number2</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>{payoutDetails && payoutDetails.ref2}</SpH5>
                      </Col>
                    </Row>
                  )
                }
                {
                  payoutDetails && payoutDetails.ref3 && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Reference Number3</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>{payoutDetails && payoutDetails.ref3}</SpH5>
                      </Col>
                    </Row>
                  )
                }
                {
                  payoutDetails && payoutDetails.ref4 && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Reference Number4</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>{payoutDetails && payoutDetails.ref4}</SpH5>
                      </Col>
                    </Row>
                  )
                }
                {
                  (payoutDetails.fee > 0) && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Fee</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>
                          {`${payoutDetails.currencyDto.prefix} 
                              ${formatNumber((payoutDetails.fee / 100).toFixed(payoutDetails.currencyDto.toFixed), currencyType)}`}
                        </SpH5>
                      </Col>
                    </Row>
                  )
                }
                {
                  payoutDetails.exchangeRate && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Exchange Rate</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>
                          1 - &gt;&nbsp; {payoutDetails.exchangeRate}
                        </SpH5>
                      </Col>
                    </Row>
                  )
                }
                {
                  payoutDetails.settlementAmount && payoutDetails.settlementCurrency && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Settlement Amount</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>
                          {`${payoutDetails.settlementCurrency.prefix} 
                        ${formatNumber((payoutDetails.settlementAmount / 100).toFixed(payoutDetails.settlementCurrency.toFixed),
                            payoutDetails.settlementCurrency)}`}
                        </SpH5>
                      </Col>
                    </Row>
                  )
                }
              </SpForm>
              <Row type="flex" justify="space-between mb-3">
                <Col>
                  <SpText className="text-uppercase" fontSize="20px">
                    {payoutDetails.type === 'WALLET' ? 'BUSINESS WALLET DETAILS' : 'BUSINESS BANK DETAILS'}
                  </SpText>
                </Col>
              </Row>
              {
                payoutDetails.type === 'WALLET' ? (
                  <>
                    <SpForm>
                      <Row type="flex" justify="start">
                        <Col span={12}>
                          <Col span={6}>
                            <span>Wallet Alias</span>
                          </Col>
                          <Col span={14}>
                            <SpH5>
                              {payoutDetails.wallet && payoutDetails.wallet.aliases && payoutDetails.wallet.aliases[0].alias}
                            </SpH5>
                          </Col>
                        </Col>
                        <Col span={12} className="d-flex justify-content-end">
                          <SpH5 color="#7f7f7f">{payoutDetails.gid}</SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                ) : (
                  <>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={4}>
                          <span>Account Holder</span>
                        </Col>
                        <Col span={9}>
                          <SpH5>{payoutDetails.bankBeneficiaryName}</SpH5>
                        </Col>
                      </Row>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <span>Bank</span>
                        </Col>
                        <Col span={9}>
                          <SpH5>{payoutDetails.bankName}</SpH5>
                        </Col>
                      </Row>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <span>Account Number</span>
                        </Col>
                        <Col span={9}>
                          <SpH5>****{payoutDetails.bankAccountLastFour}</SpH5>
                        </Col>
                      </Row>
                      <Row type="flex" justify="start">
                        <Col span={12}>
                          <Col span={6}>
                            <span>{payoutDetails.currencyDto.id === 2 ? 'IFSC Code' : 'Routing Number'}</span>
                          </Col>
                          <Col span={12}>
                            <SpH5>{payoutDetails.bankRoutingNumber}</SpH5>
                          </Col>
                        </Col>
                        <Col span={12} className="d-flex justify-content-end">
                          <SpH5 color="#7f7f7f">{payoutDetails.gid}</SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                )
              }
            </Card>
            <Card>
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">TRANSACTIONS</SpText>
                </Col>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={isIndia ? inColumns : columns}
                rowKey={(record) => record.gid}
                dataSource={payoutTransactions}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};
const mapStateToProps = (state) => ({
  payoutDetails: state.payoutDetails,
  payoutDetailsStatus: state.payoutDetails.payoutDetailsStatus,
  payoutTransactions: state.payoutDetails.payoutTransactions ? state.payoutDetails.payoutTransactions.content : [],
  totalElements: state.payoutDetails.payoutTransactions ? state.payoutDetails.payoutTransactions.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  payouts: state.payouts.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPayoutDetails: gid => dispatch(payoutDetailsAction.fetchPayoutDetails({
    payload: gid,
  })),
  fetchPayoutTransactions: param => dispatch(payoutDetailsAction.fetchPayoutTransactions({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PayoutDetail);
