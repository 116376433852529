export default status => {
  if (status === 'OCCUPIED') {
    return '#e2e2e2';
  } if (status === 'RESERVED') {
    return '#C9FFEF';
  } if (status === 'ABANDONED') {
    return '#e2e2e2';
  } if (status === 'CANCELED') {
    return '#FFE9E9';
  } return '#ffe0b1';
};
