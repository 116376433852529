import { SCHEDULE_ADMIN_REPORTS } from '../actionTypes';

export const fetchAdminScheduleReports = (action) => ({
  type: SCHEDULE_ADMIN_REPORTS.FETCH,
  payload: action.payload,
});

export const addAdminScheduleReport = (action) => ({
  type: SCHEDULE_ADMIN_REPORTS.ADD,
  payload: action.payload,
});

export const deleteAdminScheduleReport = (action) => ({
  type: SCHEDULE_ADMIN_REPORTS.DELETE,
  payload: action.payload,
});
