import { RESERVATION_STATUSES } from 'appconstants';

export default value => {
  if (value === 'RESERVED') {
    return RESERVATION_STATUSES.RESERVED;
  } if (value === 'OCCUPIED') {
    return RESERVATION_STATUSES.OCCUPIED;
  } if (value === 'CLOSED') {
    return RESERVATION_STATUSES.CLOSED;
  } if (value === 'ABANDONED') {
    return RESERVATION_STATUSES.ABANDONED;
  } if (value === 'CANCELED') {
    return RESERVATION_STATUSES.CANCELED;
  } return null;
};
