// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Button,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import { transfersDetailsActions } from 'store/actions';
import {
  SpH5,
  SpError,
  SpButton,
} from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  lastFour: string,
  phoneCode: string,
  resendOtp: Function,
  gid: string,
};

const ConfirmOtp = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    lastFour,
    phoneCode,
    resendOtp,
    gid,
  } = props;

  const [, forceUpdate] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const otpRegex = /[0-9]{6}/;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      validOtp: {
        message: 'Please enter a valid Otp.',
        rule: val => otpRegex.test(val),
      },
    },
  }));
  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const getOtp = () => {
    resendOtp(gid);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      otpCode,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Confirm</SpButton>,
      ]}
      title="Confirm OTP"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-4 mt-4">
          <SpH5>
            We have sent an OTP to {phoneCode} ******{lastFour}
          </SpH5>
        </div>
        <div>
          <label htmlFor=""><SpH5>Enter OTP to verify</SpH5></label>
          <Input
            placeholder="Enter OTP"
            onChange={(e) => setOtpCode(e.currentTarget.value)}
            suffix={<Button type="link" onClick={getOtp}>Resend OTP</Button>}
          />
          <SpError>
            {simpleValidator.current.message('otp', otpCode, 'required|validOtp')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resendOtp: gid => dispatch(transfersDetailsActions.resendOtp({
    payload: gid,
  })),
});

// $FlowFixMe
export default connect(null, mapDispatchToProps)(ConfirmOtp);
