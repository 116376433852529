// @flow
import React, {
  useRef,
  useState,
  createRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Steps,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  notificationActions,
  transfersActions,
} from 'store/actions';
import {
  BENE_TYPES,
} from 'appconstants';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { NOTIFICATION } from 'store/actionTypes';
import { SpButton } from 'components/DesignKit';

import FundingSource from './FundingSource';
import ConfirmTransfer from './ConfirmTransfer';

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  fundingSearchList: Array<Object>,
  setNotification: Function,
  submit: Function,
  isIndia: boolean,
  contactGid: String,
  invoiceDetails: Object,
  searchFundingSource: Function,
};

const { Step } = Steps;

const AddFundingSource = (props: Props) => {
  const {
    visible,
    close,
    submitting,
    selectedAccount,
    submit,
    setNotification,
    fundingSearchList,
    searchFundingSource,
    contactGid,
    invoiceDetails,
    isIndia,
  } = props;

  const [, forceUpdate] = useState();
  const fundingSourceRef = createRef();
  const [refArray, setRefArray] = useState([fundingSourceRef]);
  const [current, setCurrent] = useState(0);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const transferType = (isIndia ? 'IMPS' : 'ACH');
  const [fundingSourceName, setFundingSourceName] = useState('');
  const [fundingSourceGid, setFundingSourceGid] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountType, setAccountType] = useState('');
  const [bankName, setBankName] = useState('');
  const [upiId, setUpiId] = useState('');
  const [disableFunding, setDisableFunding] = useState(false);
  const [fundingResult, setFundingResult] = useState([]);

  const fundingSourceDetailsData = {
    fundingSourceName,
    fundingSourceGid,
    accountNumber,
    routingNumber,
    accountType,
    fundingResult,
    bankName,
    upiId,
    disableFunding,
  };

  const fetchFundingSourceDetails = (search) => {
    searchFundingSource(search);
  };

  useEffect(() => {
    if (contactGid) {
      const searchParam = `page=1&size=100&contact.gid.EQ=${contactGid}`;
      fetchFundingSourceDetails(searchParam);
    }
  }, []);

  useEffect(() => {
    setFundingResult(fundingSearchList);
  }, [fundingSearchList]);

  useEffect(() => {
    if (current === 1 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const next = async () => {
    const validationArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      const fundingSourceDetails = refArray[0].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setFundingSourceName(fundingSourceDetails.fundingSourceName);
      setFundingSourceGid(fundingSourceDetails.fundingSourceGid);
      setAccountNumber(fundingSourceDetails.accountNumber);
      setRoutingNumber(fundingSourceDetails.routingNumber);
      setAccountType(fundingSourceDetails.accountType);
      setBankName(fundingSourceDetails.bankName);
      setUpiId(fundingSourceDetails.upiId);
      setDisableFunding(fundingSourceDetails.disableFunding);
      setCurrent(current + 1);
    } else if (current === 1) {
      let fundingSourceAddGid;
      let issuerBank;
      if (transferType === BENE_TYPES.UPI) {
        issuerBank = {
          beneType: BENE_TYPES.VPA,
          vpa: fundingSourceDetailsData.upiId,
          countryCode: selectedAccount.country.alpha2,
        };
      } else {
        issuerBank = {
          accountNumber: fundingSourceDetailsData.accountNumber,
          routingNumber: fundingSourceDetailsData.routingNumber,
          accountType: fundingSourceDetailsData.accountType,
          bankName: fundingSourceDetailsData.bankName,
          name: fundingSourceDetailsData.fundingSourceName,
          beneType: BENE_TYPES.BANK,
          countryCode: selectedAccount.country.alpha2,
        };
      }
      if (!fundingSourceDetailsData.fundingSourceGid) {
        const fundingObject = {
          contactGid,
          issuerBank,
        };
        try {
          const { data: { entity: { gid } } } = await axiosAuthInstance.post(API_END_POINTS.GET_FUNDINGSOURCE, fundingObject);
          fundingSourceAddGid = gid;
          setFundingSourceGid(gid);
        } catch (error) {
          setNotification({
            type: NOTIFICATION.ERROR,
            payload: error.response.data && error.response.data.message,
          });
        }
      }
      let fundingDataGid;
      if (fundingSourceAddGid) {
        fundingDataGid = fundingSourceAddGid;
      } else {
        fundingDataGid = fundingSourceDetailsData.fundingSourceGid;
      }
      if (fundingDataGid) {
        submit(
          fundingDataGid,
        );
      }
    }
  };

  const prev = () => {
    setDisableButton(false);
    if (current === 0) {
      close();
    } else if (current === 1) {
      setCurrent(current - 1);
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="500px"
      footer={current === 0
        ? [
          <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
          <SpButton key="2" onClick={next}>Continue to Pay</SpButton>,
        ]
        : [
          <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
          <SpButton key="2" onClick={next} disabled={disableButton}>Pay</SpButton>,
        ]}
      title="Pay Invoice"
    >
      <>
        <div style={{ marginTop: '-8px' }}>
          <Steps current={current} className="subscriptions">
            <Step key="1" title="Funding Source" />
            <Step key="2" title="Confirm" />
          </Steps>
        </div>
        <div className="px-5 mt-2">
          <div className={submitting ? 'OVERLAY' : ''} />
          {current === 0 && (
            <FundingSource
              ref={refArray[0]}
              selectedAccount={selectedAccount}
              fundingSourceDetailsData={fundingSourceDetailsData}
              isIndia={isIndia}
              fundingResult={fundingResult}
            />
          )}
          {current === 1 && (
            <ConfirmTransfer
              isIndia={isIndia}
              selectedAccount={selectedAccount}
              invoiceDetails={invoiceDetails}
              prev={prev}
              fundingSourceDetailsData={fundingSourceDetailsData}
            />
          )}
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  contactList: state.transfers.contactList,
  fundingSearchList: state.transfers.searchFundingList,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  searchFundingSource: param => dispatch(transfersActions.searchFundingSource({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddFundingSource);
