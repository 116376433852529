import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  Input,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpButton,
  SpH5,
  SpError,
} from 'components/DesignKit';
import AddIcon from 'components/NavIcons/addIcon';
import RemoveIcon from 'components/NavIcons/removeIcon';

type Props = {
  internalAccount: Object,
  submit: Function,
  openModal: Function,
};

const SupportedBins = (props: Props) => {
  const {
    internalAccount,
    submit,
    openModal,
  } = props;
  const [, forceUpdate] = useState();
  const [binNumber, setBinNumber] = useState('');
  const [addBin, setAddBin] = useState((internalAccount && internalAccount.supportedBins)
    ? (internalAccount && internalAccount.supportedBins)
    : []);
  const [disabled, setDisabled] = useState(true);
  const [errormsg, setErrormsg] = useState(null);
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      bin: {
        message: 'Please enter valid bin',
        rule: val => val.length === 6,
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const updateBinRate = (event) => {
    event.preventDefault();
    let formValid = true;
    if (addBin.length > 0) {
      setErrormsg('');
      const uniqueArray = [...new Set(addBin)];
      if (addBin.length !== uniqueArray.length) {
        formValid = !formValid;
        setErrormsg('Please remove duplicate bins');
      } else {
        setErrormsg('');
      }
    }
    if (!formValid) {
      forceUpdate(1);
      return;
    }
    submit({
      addBin,
    });
  };

  useEffect(() => {
    setDisabled(true);
    setErrormsg('');
    simpleValidator.current.hideMessages();
    forceUpdate();
  }, [openModal]);

  const reset = () => {
    simpleValidator.current.hideMessages();
    forceUpdate();
    setBinNumber('');
    setErrormsg('');
    setAddBin((internalAccount && internalAccount.supportedBins)
      ? (internalAccount && internalAccount.supportedBins)
      : []);
    setDisabled(true);
    openModal(false);
  };

  const addNewBin = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else if (addBin.length > 0) {
      const newArray = [...addBin, binNumber];
      const uniqueArray = [...new Set(newArray)];
      if (newArray.length !== uniqueArray.length) {
        setAddBin([...uniqueArray]);
        setErrormsg('Duplicate bins not allowed');
      } else {
        setErrormsg('');
        setAddBin([...addBin, binNumber]);
        setBinNumber('');
        simpleValidator.current.hideMessages();
        forceUpdate();
      }
    } else {
      setAddBin([binNumber]);
      setErrormsg('');
    }
  };

  const removeBin = (i) => {
    const list = [...addBin];
    list.splice(i, 1);
    setAddBin(list);
    setErrormsg('');
    setDisabled(false);
  };

  return (
    <>
      <div>
        <Row>
          <Col span={6} className="mb-2">
            <SpH5>Bins</SpH5>
          </Col>
        </Row>
        <Row span={14}>
          <Col>
            <div className="d-flex">
              <div
                style={{
                  width: '38%',
                }}
              >
                <Input
                  placeholder="Enter bin number"
                  value={binNumber}
                  maxlength="6"
                  onChange={(e) => {
                    setBinNumber(e.currentTarget.value);
                    setDisabled(false);
                    openModal(true);
                  }}
                />
              </div>
              <div
                className="ml-2"
              >
                <span
                  onClick={addNewBin}
                  style={{ cursor: 'pointer' }}
                >
                  <AddIcon />
                </span>
              </div>
            </div>
            <SpError>
              {simpleValidator.current.message('bin number', binNumber, 'required|numeric|bin')}
            </SpError>
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            width: '45%',
            flexWrap: 'wrap',
          }}
        >
          <>
            {
              addBin && addBin.map((result, i) => (
                <div
                  style={{
                    width: '31%',
                  }}
                >
                  <Col className={i === 0 ? '' : (i % 3) === 0 ? '' : 'ml-3'}>
                    <div className="d-flex mt-4">
                      <Input
                        value={result}
                        disabled
                      />
                      <div
                        className="w-10 ml-1"
                      >
                        <span
                          onClick={() => removeBin(i)}
                          style={{ cursor: 'pointer' }}
                        >
                          <RemoveIcon />
                        </span>
                      </div>
                    </div>
                  </Col>
                </div>
              ))
            }
          </>
        </div>
        {
          errormsg && (
            <SpError>{errormsg}</SpError>
          )
        }
        <Row className="mt-4" style={{ marginLeft: '-15px' }}>
          <Col span={2}>
            <Button
              type="link"
              onClick={reset}
              disabled={disabled}
            >
              Reset
            </Button>
          </Col>
          <Col span={2}>
            <SpButton
              type="primary"
              shape="round"
              disabled={disabled}
              onClick={updateBinRate}
            >
              Update
            </SpButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(SupportedBins);
