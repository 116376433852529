// @flow
import React, {
  useRef,
  useState,
  createRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  Row,
  Col,
} from 'antd';
import {
  SpWidgetDetails,
} from 'components/PaymentWidgetDesignKit';
import SimpleReactValidator from 'simple-react-validator';
import {
  paymentWidgetsActions,
} from 'store/actions';

import { SpButton } from 'components/DesignKit';

import {
  PAGES,
} from 'appconstants';

import WidgetInfo from './widgetInfo';
import AmountInfo from './amountInfo';
import EmploymentInfo from './employmentInfo';
import ThanksInfo from './thanksInfo';

type Props = {
  selectedAccount: Object,
  setCurrentWidget: Function,
  currentWidget: Number,
  setSampleWidget: Function,
  sampleWidget: Object,
  history: {
    push: Function,
  },
  addWidget: Function,
  paymentWidgetsDetails: Object,
};

const WidgetDetails = (props: Props) => {
  const {
    selectedAccount,
    history,
    setCurrentWidget,
    currentWidget,
    setSampleWidget,
    sampleWidget,
    addWidget,
    paymentWidgetsDetails,
  } = props;

  const [, forceUpdate] = useState();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const widgetDetailsRef = createRef();
  const amountDetailsRef = createRef();
  const employmentDetailsRef = createRef();
  const thanksDetailsRef = createRef();
  const [refArray, setRefArray] = useState([
    widgetDetailsRef,
    amountDetailsRef,
    employmentDetailsRef,
    thanksDetailsRef,
  ]);
  const [current, setCurrent] = useState(0);
  const [btnText, setBtnText] = useState('Cancel');
  const [nxtBtnTxt, setNxtBtnTxt] = useState('Next');
  const [numberWidget, setNumberWidget] = useState(currentWidget);
  const [widgetDetails, setWidgetDetails] = useState(sampleWidget);

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  useEffect(() => {
    setWidgetDetails(sampleWidget);
  }, [sampleWidget]);

  useEffect(() => {
    setNumberWidget(currentWidget);
  }, [currentWidget]);

  const onNext = () => {
    const validationArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      setBtnText('Back');
      if (current <= 2) {
        setCurrentWidget(numberWidget + 1);
      }
    } else if (current === 1) {
      validationArray.push(refArray[1].current.validate());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      if (current <= 2) {
        setCurrentWidget(numberWidget + 1);
      }
    } else if (current === 2) {
      validationArray.push(refArray[2].current.validate());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      setNxtBtnTxt('Finish');
      if (current <= 2) {
        setCurrentWidget(numberWidget + 1);
      }
    } else if (current === 3) {
      validationArray.push(refArray[3].current.validate());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      const params = sampleWidget;
      const gid = parsed['WIDGET.ID'];
      addWidget(params, gid);
    }
  };

  const onPrev = () => {
    setCurrent(current - 1);
    setCurrentWidget(numberWidget - 1);
    setNxtBtnTxt('Next');
    if (current === 1) {
      setBtnText('Cancel');
    } else if (current === 0) {
      history.push(PAGES.PAYMENTWIDGETS);
    }
  };

  return (
    <>
      <SpWidgetDetails className="mt-2">
        {current === 0 && (
          <WidgetInfo
            ref={refArray[0]}
            selectedAccount={selectedAccount}
            widgetDetails={widgetDetails}
            setSampleWidget={setSampleWidget}
          />
        )}
        {current === 1 && (
          <AmountInfo
            ref={refArray[1]}
            selectedAccount={selectedAccount}
            widgetDetails={widgetDetails}
            setSampleWidget={setSampleWidget}
          />
        )}
        {current === 2 && (
          <EmploymentInfo
            ref={refArray[2]}
            selectedAccount={selectedAccount}
            widgetDetails={widgetDetails}
            setSampleWidget={setSampleWidget}
          />
        )}
        {current === 3 && (
          <ThanksInfo
            ref={refArray[3]}
            selectedAccount={selectedAccount}
            widgetDetails={widgetDetails}
            setSampleWidget={setSampleWidget}
            paymentWidgetsDetails={paymentWidgetsDetails}
          />
        )}
      </SpWidgetDetails>
      <Row type="flex" justify="end" className="mr-5">
        <Col>
          <SpButton
            type="secondary"
            shape="round"
            className="mr-3"
            onClick={onPrev}
          >
            {btnText}
          </SpButton>
        </Col>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={onNext}
          >
            {nxtBtnTxt}
          </SpButton>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  currentWidget: state.paymentWidgets.currentWidget,
  sampleWidget: state.paymentWidgets.sampleWidget,
  paymentWidgetsDetails: state.paymentWidgetsDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentWidget: param => dispatch(paymentWidgetsActions.setCurrentWidget({
    payload: param,
  })),
  setSampleWidget: param => dispatch(paymentWidgetsActions.setSampleWidget({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(WidgetDetails);
