import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import {
  Row,
  Col,
  Select,
} from 'antd';
import {
  SpForm,
  SpText,
  SpError,
} from 'components/DesignKit';
import {
  notificationActions,
} from 'store/actions';
import {
  MAX_AMOUNT,
} from 'appconstants';
import { NOTIFICATION } from 'store/actionTypes';
import NewBankAccount from './NewBankAccount';
import AddNewDebitCard from './AddDebitCard';
import AddNewAccount from './AddNewAccount';
import PlaidAccount from './PlaidAccount';
import ConfirmLoad from './ConfirmLoad';
import LoadAmount from './LoadAmount';
import ZelleAccount from './AddZelleAccount';

const { Option } = Select;

type Props = {
  history: {
    push: Function,
  },
  walletBanks: Array<Object>,
  newAccount: Function,
  submitting: Boolean,
  addBalance: Function,
  typeOfBank: Function,
  newCard: Function,
  walletCards: Array<object>,
  setNotification: Function,
};

const LoadBalance = (props: Props) => {
  const {
    walletBanks,
    newAccount,
    submitting,
    addBalance,
    typeOfBank,
    newCard,
    walletCards,
    setNotification,
  } = props;
  const [addNewCardModal, setAddNewCardModal] = useState(false);
  const [addNewAccountModal, setAddNewAccountModal] = useState(false);
  const [plaidAccountModal, setPlaidAccountModal] = useState(false);
  const [accountModal, setAccountModal] = useState(false);
  const bankAccount = false;
  const debitCard = true;
  const [confirmLoadModal, setConfirmLoadModal] = useState(false);

  const [bankDisplayName, setBankDisplayName] = useState('');
  const [bankGid, setBankGid] = useState('');
  const [cardDisplayName, setCardDisplayName] = useState('');
  const [cardGid, setCardGid] = useState('');
  const [loadedAmount, setLoadedAmount] = useState(null);
  const displayAmount = false;
  const [loadAmountModal, setLoadAmountModal] = useState(false);
  const [bankAccountType, setBankAccountType] = useState('');
  const bankLast = '';
  const [zelleAccountModal, setZelleAccountModal] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: 'Amount must be greater than or equal to $5',
        rule: (val) => (MAX_AMOUNT.FEE_LIMIT_USD <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, ''))),
      },
    },
  }));

  const addNewDebitCard = (card) => {
    const expiryMonth = card.expiry.split('/')[0];
    const yearOfExpiry = card.expiry.split('/')[1];
    let expiryYear = '';
    if (yearOfExpiry.length === 2) {
      expiryYear = `20${yearOfExpiry}`;
    } else if (yearOfExpiry.length === 4) {
      expiryYear = yearOfExpiry;
    }
    const cardData = {
      cvv: card.cvv,
      expiryMonth,
      expiryYear,
      name: card.name,
      default: card.defaultCard,
      number: card.cardNumber.replaceAll(' ', ''),
    };
    newCard(cardData);
  };

  const newAccountType = (e) => {
    const val = e ? e.target.value : 'Plaid';
    if (val === 'Plaid') {
      setAddNewAccountModal(false);
      setPlaidAccountModal(true);
    } else if (val === 'Account') {
      setAddNewAccountModal(false);
      setAccountModal(true);
    }
  };

  const handleCard = (e) => {
    setCardDisplayName(`${e[0]} ${e[2]}`);
    setCardGid(e[1]);
  };

  const addNewBankAccount = (data) => {
    newAccount(data);
  };
  useEffect(() => {
    if (!submitting) {
      setAddNewCardModal(false);
      setAddNewAccountModal(false);
      setPlaidAccountModal(false);
      setConfirmLoadModal(false);
      setAccountModal(false);
      setBankDisplayName('');
      setCardDisplayName('');
      setBankGid('');
      setCardGid('');
      setLoadedAmount(null);
      setLoadAmountModal(false);
    }
  }, [submitting]);

  const loadAmount = (loadBalance) => {
    let payload;
    if (bankAccount) {
      payload = {
        walletBankGid: bankGid,
        amount: loadBalance.amount,
        bankAccount,
        bankAccountType,
        confirmationCode: loadBalance.confirmationCode,
      };
    } else if (debitCard) {
      payload = {
        walletCardGid: cardGid,
        amount: loadBalance.amount,
        debitCard,
      };
    }
    addBalance(payload);
    simpleValidator.current.hideMessages();
  };

  const addAccountType = (account) => {
    if (account === 'Plaid') {
      typeOfBank('ZELLE');
    } else {
      typeOfBank('ACH');
    }
  };

  const selectedType = (type) => {
    setBankAccountType(type);
    if (type === 'ZELLE') {
      setLoadAmountModal(false);
      setZelleAccountModal(true);
    } else if (type === 'ACH') {
      setLoadAmountModal(false);
      setConfirmLoadModal(true);
    }
  };

  const zellePayment = () => {
    setZelleAccountModal(false);
    setConfirmLoadModal(true);
  };

  const addPlaidAccount = () => {
    setPlaidAccountModal(false);
    setNotification({
      type: NOTIFICATION.ERROR,
      payload: 'Plaid is not available for Test Accounts',
    });
  };

  return (
    <div className="my-2 mt-4">
      <SpForm>
        <Row>
          <Col span={8}>
            <>
              <label htmlFor="">
                <SpText fontWeight="600" fontSize="14px" color="#292929">Select Card</SpText>
              </label>
              <Select
                style={{ width: '100%' }}
                disabled={bankAccount}
                value={cardDisplayName || 'Select card'}
                onChange={handleCard}

              >
                {
                  walletCards.map((option) => (
                    <Option
                      key={option.gid}
                      value={[
                        option.card.bankName,
                        option.gid,
                        option.card.lastFour,
                      ]}
                    >
                      {option.card.bankName} {option.card.lastFour}
                    </Option>
                  ))
                }
              </Select>
              {
                debitCard && (
                  <SpError>
                    {simpleValidator.current.message('Debit Card', cardDisplayName, 'required')}
                  </SpError>
                )
              }
              <SpText
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'end',
                  paddingTop: '5px',
                }}
                fontSize="14px"
                fontWeight="600"
                color="#3E8BFF"
                onClick={() => setAddNewCardModal(true)}
              >
                Manage Cards
              </SpText>
            </>
          </Col>
        </Row>
      </SpForm>
      {/* ADD AddNewDebitCard MODAL */}
      {addNewCardModal && (
        <AddNewDebitCard
          visible={addNewCardModal}
          submitting={false}
          countryId={1}
          selectedAccount={{}}
          supportedCurrency={{}}
          paymentCheckoutDetails={{}}
          close={() => setAddNewCardModal(false)}
          submit={addNewDebitCard}
          walletCards={walletCards}
        />
      )}
      {/* ADD AddNewAccount MODAL */}
      {addNewAccountModal && (
        <AddNewAccount
          visible={addNewAccountModal}
          submitting={false}
          countryId={1}
          selectedAccount={{}}
          supportedCurrency={{}}
          paymentCheckoutDetails={{}}
          close={() => setAddNewAccountModal(false)}
          submit={addAccountType}
          newAccountType={newAccountType}
          walletBanks={walletBanks}
        />
      )}
      {/* ADD NewBankAccount MODAL */}
      {accountModal && (
        <NewBankAccount
          visible={accountModal}
          submitting={false}
          countryId={1}
          selectedAccount={{}}
          supportedCurrency={{}}
          paymentCheckoutDetails={{}}
          close={() => setAccountModal(false)}
          submit={addNewBankAccount}
        />
      )}
      {/* ADD PlaidAccount MODAL */}
      {plaidAccountModal && (
        <PlaidAccount
          visible={plaidAccountModal}
          submitting={false}
          countryId={1}
          selectedAccount={{}}
          supportedCurrency={{}}
          paymentCheckoutDetails={{}}
          close={() => setPlaidAccountModal(false)}
          submit={addPlaidAccount}
        />
      )}
      {/* ADD ConfirmLoad MODAL */}
      {confirmLoadModal && (
        <ConfirmLoad
          visible={confirmLoadModal}
          close={() => setConfirmLoadModal(false)}
          submit={loadAmount}
          loadedAmount={loadedAmount}
          displayAmount={displayAmount}
          bankAccountType={bankAccountType}
        />
      )}
      {/* ADD LoadAmount MODAL */}
      {loadAmountModal && (
        <LoadAmount
          visible={loadAmountModal}
          close={() => setLoadAmountModal(false)}
          submit={selectedType}
          displayAmount={displayAmount}
          loadedAmount={loadedAmount}
          bankDisplayName={bankDisplayName}
          bankLast={bankLast}
        />
      )}
      {zelleAccountModal && (
        <ZelleAccount
          visible={zelleAccountModal}
          close={() => setZelleAccountModal(false)}
          submit={zellePayment}
          displayAmount={displayAmount}
          loadedAmount={loadedAmount}
          bankDisplayName={bankDisplayName}
          bankLast={bankLast}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadBalance);
