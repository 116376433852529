// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Upload,
  message,
  Icon,
} from 'antd';
import { validators } from 'utils/validationMessages';
import SimpleReactValidator from 'simple-react-validator';
import { NOTIFICATION } from 'store/actionTypes';
import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';
import {
  notificationActions,
} from 'store/actions';

import imageOnError from 'utils/imageOnError';
import {
  MESSAGES,
} from 'appconstants';
import {
  SpH5,
  SpError,
  SpText,
  SpButton,
} from 'components/DesignKit';
import Previewicon from 'components/NavIcons/previewIcon';
import Uploadicon from 'components/NavIcons/uploadIcon';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  setNotification: Function,
  inventoryQuickSupport: Array,
};

const EditSupport = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    setNotification,
    inventoryQuickSupport,
  } = props;

  const [, forceUpdate] = useState();
  const [label, setLabel] = useState('');
  const [logoUrl, setLogo] = useState('');
  const [preview, setPreview] = useState('');
  const [fileName, setFileName] = useState('');
  const [loader, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const STRING_LENGTH = 1;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  simpleValidator.current.purgeFields();
  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (inventoryQuickSupport && inventoryQuickSupport.length !== 0) {
      setLabel((inventoryQuickSupport && inventoryQuickSupport.name));
    }
    if (inventoryQuickSupport && inventoryQuickSupport.length !== 0) {
      if (inventoryQuickSupport.iconUrl) {
        setFileName(inventoryQuickSupport.iconUrl);
        setLogo(inventoryQuickSupport.iconUrl);
        setPreview(
          <img
            src={`${inventoryQuickSupport.iconUrl}?time=${new Date().getTime()}`}
            alt=""
            onError={event => imageOnError(event)}
            style={{ width: '100%' }}
          />,
        );
      }
    } else {
      setPreview('');
      setFileName('');
    }
  }, [inventoryQuickSupport]);

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <Uploadicon />}
      <div className="ant-upload-text mt-2">Click or drag the file here to upload</div>
      <div className="mt-2"><SpText color="rgba(0, 0, 0, 0.45)">Support extension: .jpg .png</SpText></div>
    </div>
  );

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    const IMAGE = 'image/*';
    const pattern = file.type.match(IMAGE);
    if (!pattern) {
      message.error('File is not image');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 5MB!');
      return false;
    }
    return true;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.QUICKSUPPORT_LOGO}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const imageDetails = {
          fileUrl: entity,
          keyType: 'ICON_URL',
        };
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.QUICKSUPPORT_LOGO}`, imageDetails);
        setLogo(fileUrl);
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview(<img src={imageUrl} alt="..." style={{ width: '115px' }} />);
          });
        } else {
          setPreview(<Previewicon />);
        }
        setFileName(info.file.name);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      label,
      logoUrls: logoUrl,
      quickGid: inventoryQuickSupport && inventoryQuickSupport.gid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="600px"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Edit Support"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2 mb-4">
          <label htmlFor=""><SpH5>Label</SpH5></label>
          <Input
            placeholder="Label"
            onChange={(e) => setLabel(e.currentTarget.value)}
            value={label}
          />
          <SpError>
            {simpleValidator.current.message('label', label, 'required|stringLength')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Image</SpH5></label>
          <Upload
            name="avatar"
            listType="picture-card upload-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={fileUpload}
          >
            {fileName ? preview : uploadButton}
          </Upload>
        </div>
        <div>&nbsp;</div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(EditSupport);
