import { VENDORSDETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  transfers: [],
  vendorsDetailsStatus: undefined,
  vendorsDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VENDORSDETAILS.FETCH:
      return {
        ...state,
        vendorsDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case VENDORSDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        vendorsDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case VENDORSDETAILS.FAILED:
      return {
        ...state,
        vendorsDetailsError: action.payload,
        vendorsDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case VENDORSDETAILS.TRANSFERS_SUCCESS:
      return {
        ...state,
        transfers: action.payload,
      };
    default:
      return state;
  }
};
