import { BULKTRANSFER_DETAILS } from '../actionTypes';

const initialState = {
  transfers: [],
  bulkTransferDetailsStatus: undefined,
  bulkTransferDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BULKTRANSFER_DETAILS.FETCH:
      return {
        ...state,
        bulkTransferDetailsStatus: 'STARTED',
      };
    case BULKTRANSFER_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        bulkTransferDetailsStatus: 'SUCCESS',
      };
    case BULKTRANSFER_DETAILS.FAILED:
      return {
        ...state,
        bulkTransferDetailsError: action.payload,
        bulkTransferDetailsStatus: 'FAILED',
      };
    case BULKTRANSFER_DETAILS.TRANSFERS_SUCCESS:
      return {
        ...state,
        transfers: action.payload,
      };
    default:
      return state;
  }
};
