import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  PRODUCT,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchProducts = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.PRODUCT_API}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.PRODUCT_API}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.PRODUCT_API}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.PRODUCT_API}${query}${sorting}`);
};

const postProduct = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.PRODUCT_API, params);

const patchProduct = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.PRODUCT_API}/${gid}/update`, params);

const productDelete = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.PRODUCT_API}/${gid}`);

export function* getProducts(action) {
  try {
    const { data: { entity } } = yield call(fetchProducts, action.payload);
    yield put({ type: PRODUCT.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: PRODUCT.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addProduct(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postProduct, action.payload);
    const { data: { entity } } = yield call(fetchProducts, action.payload);
    yield put({ type: PRODUCT.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PRODUCT.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateProduct(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchProduct, action.payload);
    const { data: { entity } } = yield call(fetchProducts, action.payload);
    yield put({ type: PRODUCT.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PRODUCT.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteProduct(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(productDelete, action.payload);
    const { data: { entity } } = yield call(fetchProducts, action.payload);
    yield put({ type: PRODUCT.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PRODUCT.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
