import { ACCOUNT } from '../actionTypes';

export const setTestData = action => ({
  type: ACCOUNT.SET_TEST_DATA,
  payload: action.payload,
});

export const setSelectedAccount = account => ({
  type: ACCOUNT.SET_SELECTED_ACCOUNT,
  payload: account,
});

export const fetchAccount = action => ({
  type: ACCOUNT.FETCH,
  payload: action.payload,
});

export const fetchAccountById = action => ({
  type: ACCOUNT.FETCH_BY_ID,
  payload: action.payload,
});

export const fetchFilteredAccounts = action => ({
  type: ACCOUNT.FETCH_BY_FILTER,
  payload: action,
});

export const fetchAccountSuccess = action => ({
  type: ACCOUNT.SUCCESS,
  payload: action.payload,
});

export const updateUserAccount = action => ({
  type: ACCOUNT.UPDATE,
  payload: action.payload,
});

export const addNewAccount = action => ({
  type: ACCOUNT.ADD,
  payload: action.payload,
});

export const fetchAccountByInternalId = action => ({
  type: ACCOUNT.FETCH_BY_INTERNAL_ID,
  payload: action.payload,
});

export const updateInternalUserAccount = action => ({
  type: ACCOUNT.INTERNAL_UPDATE,
  payload: action.payload,
});

export const customDashboardSucess = action => ({
  type: ACCOUNT.CUSTOM_DASHBOARD_SUCCESS,
  payload: action.payload,
});
