import { COUPON } from '../actionTypes';

export const fetchCoupons = (action) => ({
  type: COUPON.FETCH,
  payload: action.payload,
});

export const addCoupons = (action) => ({
  type: COUPON.ADD,
  payload: action.payload,
});

export const deleteCustomer = (action) => ({
  type: COUPON.DELETE,
  payload: action.payload,
});

export const fetchCustomers = (action) => ({
  type: COUPON.FETCH_CUSTOMERS,
  payload: action.payload,
});
