import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { NOTIFICATION } from 'store/actionTypes';
import { notificationActions } from 'store/actions';
import {
  ROLES,
} from 'appconstants';
import {
  axiosSuperestInstance,
  ROOT_SUPEREST_DOMAIN,
  ROOT_SUPEREST_DOMAIN_NO_EXPORT,
  API_END_POINTS,
} from 'api';

type Props = ContextRouter & {
    setNotification: Function,
    selectedAccount: Object,
    test: Boolean,
    dashId: String,
    role: Object,
  };

const Dashboard = (props: Props) => {
  let tempToken;
  const {
    test,
    selectedAccount,
    setNotification,
    dashId,
    role,
  } = props;

  const getDashboardGId = async () => {
    try {
      const payload = {
        dashboardid: dashId,
      };
      payload.export = 'T';
      if (role && role.name === ROLES.ANALYST) {
        payload.export = 'F';
      }
      const { data } = await axiosSuperestInstance.post(API_END_POINTS.GUEST_TOKEN, payload);
      tempToken = data;
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : '',
      });
    }
  };

  const fetchGuestToken = () => tempToken;

  useEffect(() => {
    const embed = async () => {
      let supersetUrl = ROOT_SUPEREST_DOMAIN;
      if (role && role.name === ROLES.ANALYST) {
        supersetUrl = ROOT_SUPEREST_DOMAIN_NO_EXPORT;
      }
      if (tempToken) {
        await embedDashboard({
          id: dashId,
          supersetDomain: supersetUrl,
          mountPoint: document.getElementById('dashboard'),
          fetchGuestToken: () => fetchGuestToken(),
          dashboardUiConfig: {
            hideTitle: true,
            hideChartControls: false,
            hideTab: true,
          },
        });
      }
    };
    if (document.getElementById('dashboard')) {
      (async () => {
        await getDashboardGId();
        embed();
      })();
    }
  }, [selectedAccount, test]);

  useEffect(() => {
    const layoutsDiv = document.getElementsByClassName('ant-layout');
    if (layoutsDiv[1]) {
      layoutsDiv[1].style.minHeight = 'auto';
    }
    return () => {
      if (layoutsDiv[1]) {
        layoutsDiv[1].style.minHeight = '1250px';
      }
    };
  }, [selectedAccount, test]);


  return (
    <div id="dashboard" style={{ width: '85vw', height: '100vh' }} />
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  role: state.user.role,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
