import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  CUSTOMER_SUPPORT,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchShops = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_ALL_SHOPS}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_ALL_SHOPS}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_ALL_SHOPS}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_ALL_SHOPS}${query}${sorting}`);
};

const fetchSupport = async (payload) => {
  const query = `?shopGid.EQ=${payload}&sortBy=createdAt&direction=DESC`;
  return axiosAuthInstance.get(`${API_END_POINTS.GET_ALL_SUPPORTS}${query}`);
};

const fetchQuickCustomerSupport = async (payload) => {
  const query = `?shopGid.EQ=${payload}`;
  return axiosAuthInstance.get(`${API_END_POINTS.GET_SUPPORTS}${query}`);
};

const fetchFilterSupport = async (payload) => {
  const { filterParams } = payload;
  const { sortParams } = payload;
  return axiosAuthInstance.get(`${API_END_POINTS.GET_ALL_SUPPORTS}${filterParams}${sortParams}`);
};

const patchSupport = async (payload) => axiosAuthInstance.patch(`${API_END_POINTS.GET_ALL_SUPPORTS}/${payload.supportGid}`, payload.params);

export function* getShops(action) {
  try {
    const { data: { entity } } = yield call(fetchShops, action.payload);
    yield put({ type: CUSTOMER_SUPPORT.SHOPS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: CUSTOMER_SUPPORT.FAILED, payload: e.response.data });
  }
}

export function* getFilterSupportType(action) {
  try {
    const { data: { entity } } = yield call(fetchFilterSupport, action.payload);
    yield put({ type: CUSTOMER_SUPPORT.SUPPORTS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: CUSTOMER_SUPPORT.FAILED, payload: e.response.data });
  }
}

export function* getSupportType(action) {
  try {
    const { data: { entity } } = yield call(fetchSupport, action.payload);
    yield put({ type: CUSTOMER_SUPPORT.SUPPORTS_SUCCESS, payload: entity });
    yield put({ type: CUSTOMER_SUPPORT.SUPPORTS_FILTER_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: CUSTOMER_SUPPORT.FAILED, payload: e.response.data });
  }
}

export function* updateSupportType(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchSupport, action.payload);
    const { data: { entity } } = yield call(fetchFilterSupport, action.payload.filter);
    yield put({ type: CUSTOMER_SUPPORT.SUPPORTS_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Request updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getQuickSupportType(action) {
  try {
    const { data: { entity } } = yield call(fetchQuickCustomerSupport, action.payload);
    yield put({ type: CUSTOMER_SUPPORT.QUICKSUPPORT_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: CUSTOMER_SUPPORT.QUICKSUPPORT_FAILED, payload: e.response.data });
  }
}
