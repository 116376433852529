export default status => {
  if (status === 'CUSTOMER') {
    return 'Customer';
  } if (status === 'EMPLOYEE') {
    return 'Employee';
  } if (status === 'VENDOR') {
    return 'Vendor';
  } if (status === 'SELF') {
    return 'Self';
  }
  return 'Other';
};
