// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5,
  SpError,
  SpButton,
} from 'components/DesignKit';
import {
  COUNTRIES,
  MAX_AMOUNT,
} from 'appconstants';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
};

const AddPlan = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [period, setPeriod] = useState(1);
  const [description, setDescription] = useState('');
  const [note, setNote] = useState('');
  const [frequency, setFrequency] = useState('MONTH');
  const [disableButton, setDisableButton] = useState(false);
  const currencyCode = (selectedAccount.currency && selectedAccount.currency.name);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  simpleValidator.current.purgeFields();
  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      description,
      period,
      frequency,
      note,
      currencyCode,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      title="Add Plan"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add Plan</SpButton>,
      ]}
    >
      <div className="px-5 mt-3">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Plan Name</SpH5></label>
          <Input
            placeholder="Enter a plan name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('plan name', name, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <Input
            placeholder="Optional"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Billing Frequency</SpH5></label>
          <div className="d-flex align-items-center" style={{ width: '55%' }}>
            <span>Every</span>
            <div style={{ width: '21%', minWidth: '40px' }}>
              <Input
                className="ml-1"
                value={period}
                maxlength="3"
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setPeriod(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setPeriod();
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div>
              <Select
                className="ml-3"
                style={{ width: '90%' }}
                value={frequency}
                onChange={(e) => setFrequency(e)}
              >
                <Option key={1} value="DAY">Day(s)</Option>
                <Option key={2} value="WEEK">Week(s)</Option>
                <Option key={3} value="MONTH">Month(s)</Option>
                <Option key={4} value="YEAR">Year(s)</Option>
              </Select>
            </div>
          </div>
          <SpError>
            {simpleValidator.current.message('period', period, 'required')}
          </SpError>
        </div>

        <div>
          <label htmlFor=""><SpH5>Internal Notes</SpH5></label>
          <Input
            placeholder="Optional"
            value={note}
            onChange={(e) => setNote(e.currentTarget.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddPlan);
