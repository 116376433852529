// @flow
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  SpH5,
  SpText,
} from 'components/DesignKit';
import {
  BENE_TYPES,
} from 'appconstants';
import NumberFormat from 'react-number-format';

type Props = {
  isIndia: Boolean,
  contactDetailsData: Object,
  fundingSourceDetailsData: Object,
};

const Review = (props: Props) => {
  const {
    isIndia,
    contactDetailsData,
    fundingSourceDetailsData,
  } = props;
  const countryCode = contactDetailsData && contactDetailsData.phoneNumberCode;
  const accountNumber = (fundingSourceDetailsData && fundingSourceDetailsData.accountNumber);

  const [phoneFormate, setPhoneFormate] = useState('');

  useEffect(() => {
    if (countryCode === '+1') {
      setPhoneFormate('+# (###) ###-####');
    } else if (countryCode.length !== 4 && countryCode === '+91') {
      setPhoneFormate('+##-##### #####');
    } else if (countryCode.length === 2 && countryCode !== '+1') {
      setPhoneFormate('+#-##########');
    } else if (countryCode.length === 3 && countryCode !== '+91') {
      setPhoneFormate('+##-##########');
    } else if (countryCode.length === 4) {
      setPhoneFormate('+###-##########');
    }
  }, [contactDetailsData]);

  return (
    <>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Name</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Email</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.email || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Phone</SpH5>
          </Col>
          <Col>
            <NumberFormat
              className="numFormate"
              value={`${contactDetailsData.phoneNumberCode}${contactDetailsData.contactPhone}`}
              format={phoneFormate}
              mask="_"
              disabled
              style={{
                fontWeight: '400',
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Contact Type</SpH5>
          </Col>
          <Col>
            <SpText>{contactDetailsData.contactTypeName || <>&#8211;</>}</SpText>
          </Col>
        </Row>
      </div>
      {contactDetailsData.customType && (
        <div className="mb-3">
          <Row justify="start">
            <Col>
              <SpH5>Custom Type</SpH5>
            </Col>
            <Col>
              <SpText>{contactDetailsData.customType || <>&#8211;</>}</SpText>
            </Col>
          </Row>
        </div>
      )}
      {
        (fundingSourceDetailsData && (fundingSourceDetailsData.transferType === BENE_TYPES.UPI)) ? (
          <>
            <div>
              <Row justify="start">
                <Col>
                  <SpH5>UPI Id</SpH5>
                </Col>
                <Col>
                  <SpText>{fundingSourceDetailsData.upiId || <>&#8211;</>}</SpText>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <>
            <div className="mb-3">
              <Row justify="start">
                <Col>
                  <SpH5>Bank Name</SpH5>
                </Col>
                <Col>
                  <SpText>{fundingSourceDetailsData.bankName || <>&#8211;</>}</SpText>
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row justify="start">
                <Col>
                  <SpH5>Business Type</SpH5>
                </Col>
                <Col>
                  <SpText>{fundingSourceDetailsData.businessType || <>&#8211;</>}</SpText>
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row justify="start">
                <Col>
                  <SpH5>Account Type</SpH5>
                </Col>
                <Col>
                  <SpText>{fundingSourceDetailsData.accountType || <>&#8211;</>}</SpText>
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row justify="start">
                <Col>
                  <SpH5>Account Number</SpH5>
                </Col>
                <Col>
                  <SpText>{accountNumber || <>&#8211;</>}</SpText>
                </Col>
              </Row>
            </div>
            <div>
              <Row justify="start">
                <Col>
                  <SpH5>{isIndia ? 'IFSC Code' : 'Routing Number'}</SpH5>
                </Col>
                <Col>
                  <SpText>{fundingSourceDetailsData.routingNumber || <>&#8211;</>}</SpText>
                </Col>
              </Row>
            </div>
          </>
        )
}
    </>
  );
};

// $FlowFixMe
export default Review;
