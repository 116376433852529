import { WALLET } from '../actionTypes';

export const fetchWallet = action => ({
  type: WALLET.FETCH,
  payload: action.payload,
});

export const addWallet = (action) => ({
  type: WALLET.ADD,
  payload: action.payload,
});

export const addConfirmWallet = (action) => ({
  type: WALLET.ADD_CONFIRM,
  payload: action.payload,
});

export const fetchTransactions = (action) => ({
  type: WALLET.FETCH_TRANSACTIONS,
  payload: action.payload,
});

export const pinSetUp = (action) => ({
  type: WALLET.REST_PIN,
  payload: action.payload,
});

export const verifyPin = (action) => ({
  type: WALLET.VERIFY_PIN,
  payload: action.payload,
});
