// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Row,
  Col,
  Input,
  Checkbox,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpButton, SpText, SpError } from 'components/DesignKit';
import AceEditor from 'react-ace';

import 'brace/mode/yaml';
import 'brace/theme/kuroir';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedInstance: Object,
};

const UpdatInstance = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedInstance,
  } = props;

  const [, forceUpdate] = useState();
  const [instanceNames, setInstanceNames] = useState('');
  const [isdefault, setIsDefault] = useState('');
  const [consumerKey, setConsumerKey] = useState('');
  const [consumerSecret, setConsumerSecret] = useState('');
  const [siteUrl, setSiteUrl] = useState('');
  const [args, setArgs] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      validUrl: {
        message: 'The url must be valid',
        // eslint-disable-next-line max-len
        rule: val => (new RegExp('^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$').exec(val) !== null),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (selectedInstance && selectedInstance.length !== 0) {
      setConsumerKey(selectedInstance.consumerKey);
      setConsumerSecret(selectedInstance.consumerSecret);
      setSiteUrl(selectedInstance.siteUrl);
      setInstanceNames(selectedInstance.instanceName);
      setIsDefault(selectedInstance.default);
      if (selectedInstance.skuYaml) {
        setArgs(atob(selectedInstance.skuYaml));
      }
    }
  }, [selectedInstance]);

  const onArgsChange = (value) => {
    setArgs(value);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name: instanceNames,
      isdefaults: isdefault || false,
      consumerKeys: consumerKey,
      consumerSecrets: consumerSecret,
      siteUrls: siteUrl,
      arg: args,
      id: selectedInstance.gid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Save</SpButton>,
      ]}
      title="Edit Instance"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <Row className="mt-2 mb-1">
          <Col>
            <SpText>Instance Name</SpText>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Input
              placeholder="Instance Name"
              value={instanceNames}
              onChange={(e) => setInstanceNames(e.currentTarget.value)}
            />
          </Col>
        </Row>
        <Row className="mt-3 mb-1">
          <Col>
            <SpText>Consumer Key</SpText>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Input.Password
              placeholder="Consumer Key"
              autoComplete="new-password"
              value={consumerKey}
              onChange={(e) => setConsumerKey(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('consumer key', consumerKey, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-3 mb-1">
          <Col>
            <SpText>Consumer Secret</SpText>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Input.Password
              placeholder="Consumer Secret"
              autoComplete="new-password"
              value={consumerSecret}
              onChange={(e) => setConsumerSecret(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('consumer secret', consumerSecret, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-3 mb-1">
          <Col>
            <SpText>Site Url</SpText>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Input
              placeholder="Site URL"
              value={siteUrl}
              onChange={(e) => setSiteUrl(e.currentTarget.value.trim())}
            />
            <SpError>
              {simpleValidator.current.message('site url', siteUrl, 'required|validUrl',
                { messages: { required: 'The url field is required' } })}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-3 mb-1">
          <Col>
            <SpText>SKU Yaml</SpText>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <AceEditor
              mode="yaml"
              theme="kuroir"
              style={{
                height: '130px',
                width: 'auto',
              }}
              value={args}
              onChange={onArgsChange}
              fontSize={14}
              showPrintMargin={false}
              showGutter={false}
              editorProps={{
                $blockScrolling: true,
              }}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: false,
                tabSize: 2,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col span={5}>
            <Checkbox
              className="mr-2"
              checked={isdefault}
              onChange={({ target: { checked } }) => {
                setIsDefault(checked);
              }}
            />
            <SpText>Default</SpText>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(UpdatInstance);
