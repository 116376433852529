import {
  call,
  put,
} from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  LOADING,
  NOTIFICATION,
  SETTLEMENT,
} from '../actionTypes';

const createImsSettlement = async payload => axiosAuthInstance.post(API_END_POINTS.IMS_SETTLEMENTS, payload);
const createStripeSettlement = async payload => axiosAuthInstance.post(API_END_POINTS.STRIPE_SETTLEMENTS, payload);
const createAffinipaySettlement = async payload => axiosAuthInstance.post(API_END_POINTS.AFFINIPAY_SETTLEMENTS, payload);
const fetchCurrency = async () => axiosAuthInstance.get(`${API_END_POINTS.CURRENCY}`);

const fetchSettlementRun = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.SETTLEMENT_RUN}${query}${sorting}`);
};

export function* addImsSettlement(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(createImsSettlement, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SETTLEMENT.IMS_ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addStripeSettlement(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(createStripeSettlement, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SETTLEMENT.STRIPE_ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addAffinipaySettlement(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(createAffinipaySettlement, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SETTLEMENT.AFFINIPAY_ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getCurrency(action) {
  try {
    const { data: { entity } } = yield call(fetchCurrency, action.payload);
    yield put({ type: SETTLEMENT.FETCH_CURRENCY_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SETTLEMENT.FETCH_CURRENCY_FAILED, payload: e.response.data });
  }
}

export function* getSettlementRun(action) {
  try {
    const { data: { entity } } = yield call(fetchSettlementRun, action.payload);
    yield put({ type: SETTLEMENT.FETCH_RUN_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: SETTLEMENT.FETCH_RUN_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
