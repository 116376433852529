// @flow
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Input,
  Radio,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import Loading from 'components/Loading';
import {
  SpButton,
  SpForm,
  SpError,
  SpText,
} from 'components/DesignKit';
import {
  advanceFeesActions,
} from 'store/actions';
import {
  MAX_AMOUNT,
  COUNTRIES,
} from 'appconstants';
import ItemsList from './ItemsList';

type Props = {
  currencyType: Object,
  close: Function,
  submit: Function,
  selectedAccount: Object,
  selectedAdvanceFee: Object,
  fetchAdvanceFeeById: Function,
  advanceFeesById: Object
};

const EditAdvanceFees = (props: Props) => {
  const {
    currencyType,
    close,
    submit,
    selectedAccount,
    selectedAdvanceFee,
    fetchAdvanceFeeById,
    advanceFeesById,
  } = props;
  const [, forceUpdate] = useState();
  const [accountName, setAccountName] = useState();
  const [trigger, setTrigger] = useState();
  const [apply, setApply] = useState();
  const [percentage, setPercentage] = useState();
  const [amount, setAmount] = useState();
  const [amountOff, setAmountOff] = useState();
  // eslint-disable-next-line max-len
  const [percentageOff, setPercentageOff] = useState();
  const [assignItems, setAssignItems] = useState();
  const [enableFee, setEnableFee] = useState();
  const [itemsAssign, setItemsAssign] = useState(false);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [itemsRemoved, setItemsRemoved] = useState([]);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;

  useEffect(() => {
    if (advanceFeesById && advanceFeesById.gid) {
      setAccountName(advanceFeesById.name);
      setTrigger(advanceFeesById.triggerWhen);
      setApply(advanceFeesById.applyTo);
      setPercentage(!!((advanceFeesById.type === 'PERCENTAGE')));
      setAmount(!!((advanceFeesById.type === 'AMOUNT')));
      setAmountOff((advanceFeesById.type === 'AMOUNT') ? (advanceFeesById.value / 100) : null);
      setPercentageOff((advanceFeesById.type === 'PERCENTAGE') ? advanceFeesById.value : null);
      setAssignItems(advanceFeesById.itemCount ? advanceFeesById.itemCount : null);
      setEnableFee(advanceFeesById.enabled);
    }
  }, [advanceFeesById]);

  useEffect(() => {
    if (selectedAdvanceFee && selectedAdvanceFee.gid) {
      fetchAdvanceFeeById(selectedAdvanceFee.gid);
    }
  }, [selectedAdvanceFee]);

  const onChangeTrigger = (e: RadioChangeEvent) => {
    setTrigger(e.target.value);
  };

  const onChangeApply = (e: RadioChangeEvent) => {
    setApply(e.target.value);
  };

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      validname: {
        message: 'Please enter a valid Account Name',
        // eslint-disable-next-line no-useless-escape
        rule: val => (new RegExp(/^[^<>'\"\\\\`*]{4,}$/g).exec(val) !== null),
      },
      percentage: {
        message: MAX_AMOUNT.MESSAGE_PERCENTAGE,
        rule: (val) => MAX_AMOUNT.LIMIT_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_MAX,
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
    },
  }));

  const sendSelectedItems = (selectedItems, removedItems) => {
    setItemsAssign(false);
    setItemsRemoved(removedItems);
    if (selectedItems && selectedItems.length !== 0) {
      setAssignItems(selectedItems.length);
      setItemsSelected(selectedItems);
    }
  };

  const addAdvanceFee = (event) => {
    let amountFinal;
    if (advanceFeesById.type === 'AMOUNT' && advanceFeesById.value === (amountOff * 100)) {
      amountFinal = amountOff * 100;
    } else {
      amountFinal = amountOff ? parseInt((amountOff).replace(/[_\W]+/g, ''), 10) : null;
    }
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      name: accountName,
      value: percentage ? (percentageOff ? parseFloat(percentageOff) : null) : amountFinal,
      type: percentage ? 'PERCENTAGE' : 'AMOUNT',
      applyTo: apply,
      triggerWhen: trigger,
      enabled: enableFee,
      itemGids: itemsSelected,
      REMOVED: itemsRemoved,
    };
    submit({
      params,
      gid: selectedAdvanceFee.gid,
    });
  };

  if (advanceFeesById && !advanceFeesById.gid) {
    return <Loading />;
  }

  return (
    <>
      {
        itemsAssign ? (
          <ItemsList
            close={() => setItemsAssign(false)}
            sendSelectedItems={sendSelectedItems}
            selectedAdvanceFeeId={selectedAdvanceFee.gid}
          />
        ) : (
          <>
            <Row type="flex" justify="space-between" className="my-2 mb-3">
              <Col>
                <SpText className="text-uppercase" fontSize="20px">
                  Update Fees
                </SpText>
              </Col>
            </Row>
            <SpForm>
              <Row type="flex" justify="start" align="middle" className="mb-2">
                <Col span={3}>
                  <span>Fee Name</span>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={6}>
                  <Input
                    value={accountName}
                    onChange={e => setAccountName(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('Fee Name', accountName, 'required|validname')}
                  </SpError>
                </Col>
              </Row>
            </SpForm>
            <SpForm>
              <Row type="flex" justify="start" align="middle" className="mb-2">
                <Col span={3}>
                  <span>Type</span>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={6}>
                  <div className="d-flex pb-3">
                    <div className="mr-3">
                      <Radio
                        checked={percentage}
                        onChange={({ target: { checked } }) => {
                          if (checked) {
                            setPercentage(true);
                            setAmount(false);
                            setAmountOff(null);
                          } else if (!checked) {
                            setPercentage(false);
                            setAmount(true);
                          }
                        }}
                      />
                      <SpText>Percentage</SpText>
                    </div>
                    <div>
                      <Radio
                        checked={amount}
                        onChange={({ target: { checked } }) => {
                          if (checked) {
                            setPercentage(false);
                            setAmount(true);
                            setPercentageOff(null);
                          } else if (!checked) {
                            setPercentage(true);
                            setAmount(false);
                          }
                        }}
                      />
                      <SpText>Fixed Amount</SpText>
                    </div>
                  </div>
                  <div className="w-50 mr-3">
                    {
                      percentage ? (
                        <>
                          <Input
                            value={percentageOff}
                            suffix="%"
                            onChange={(e) => setPercentageOff(e.currentTarget.value)}
                            onKeyPress={e => {
                              const keyCode = e.charCode || e.which;
                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                e.preventDefault();
                              }
                            }}
                            onBlur={e => {
                              if (e.currentTarget.value) {
                                const { value } = e.currentTarget;
                                setPercentageOff(value);
                              }
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Input
                            value={amountOff}
                            prefix={currencyType.prefix}
                            placeholder="0.00"
                            onChange={(e) => {
                              const regex = /^\d*\.?\d*$/;
                              const { value } = e.currentTarget;
                              if (regex.test(value) || value === '') {
                                setAmountOff(e.currentTarget.value);
                              } else if (!regex.test(value)) {
                                setAmountOff(null);
                              }
                            }}
                            onKeyPress={e => {
                              const keyCode = e.charCode || e.which;
                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                e.preventDefault();
                              }
                            }}
                            onBlur={e => {
                              if (e.currentTarget.value) {
                                const value = formatAmount(e.currentTarget.value, currencyType);
                                setAmountOff(value);
                              }
                            }}
                          />
                        </>
                      )
                    }
                    {/* {
                      percentage ? (
                        <SpError>
                          {simpleValidator.current.message('percentage', percentageOff, 'required|percentage')}
                        </SpError>
                      ) : (
                        <SpError>
                          {simpleValidator.current.message('amount', amountOff, 'required|amount')}
                        </SpError>
                      )
                    } */}

                  </div>
                </Col>
              </Row>
            </SpForm>
            <SpForm>
              <Row type="flex" justify="start" align="middle" className="mb-2">
                <Col span={3}>
                  <span>Apply to</span>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={6}>
                  <Radio.Group onChange={onChangeApply} value={apply}>
                    <Radio value="ITEM">Item</Radio>
                    <Radio value="ORDER">Order</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </SpForm>
            <SpForm>
              <Row type="flex" justify="start" align="middle" className="mb-2">
                <Col span={3}>
                  <span>Assigned items</span>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={3} className="mr-3">
                  <Input
                    value={assignItems}
                    // onChange={e => setAccountName(e.currentTarget.value)}
                    disabled
                  />
                  <SpError>
                    {simpleValidator.current.message('Assigned items', assignItems, 'required')}
                  </SpError>
                </Col>
                <Col span={3}>
                  <span style={{ color: '#3E8BFF', cursor: 'pointer', fontWeight: '600' }} onClick={() => setItemsAssign(true)}>
                    {'\u002B'}&nbsp;Assign Item(s)
                  </span>
                </Col>
              </Row>
            </SpForm>
            <SpForm>
              <Row type="flex" justify="start" align="middle" className="mb-2">
                <Col span={3}>
                  <span>Trigger when</span>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4} className="mr-3">
                  <Radio.Group onChange={onChangeTrigger} value={trigger}>
                    <Radio value="ALWAYS">Always</Radio>
                    <Radio value="CONDITIONAL">Conditional</Radio>
                  </Radio.Group>
                </Col>
                <Col span={6}>
                  <Input
                    defaultValue="Would you like to include this fee in the item's price?"
                    disabled
                  />
                  <div>
                    <span>Yes/No</span>
                  </div>
                </Col>
              </Row>
            </SpForm>
            <SpForm>
              <Row justify="start" align="middle">
                <Col span={3}>
                  <span style={{ fontWeight: '600', fontSize: '16px', color: '#000000' }}>Enable/Disable Fee</span>
                </Col>
                <Col span={6}>
                  <Switch
                    className="mx-3"
                    checked={enableFee}
                    onChange={val => setEnableFee(val)}
                  />
                </Col>
              </Row>
            </SpForm>
            <Row type="flex" justify="end" gutter={[16, 16]}>
              <Col>
                <SpButton
                  type="secondary"
                  shape="round"
                  onClick={() => close()}
                >
                  Cancel
                </SpButton>
              </Col>
              <Col>
                <SpButton
                  type="primary"
                  shape="round"
                  onClick={(e) => addAdvanceFee(e)}
                >
                  Save
                </SpButton>
              </Col>
            </Row>
          </>
        )
      }

    </>
  );
};

const mapStateToProps = state => ({
  advanceFeesById: state.advanceFees.advanceFeesById,
});

const mapDispatchToProps = dispatch => ({
  fetchAdvanceFeeById: param => dispatch(advanceFeesActions.fetchAdvanceFeeById({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAdvanceFees);
