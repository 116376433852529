import { ADMIN_TERMINAL } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  adminTerminalStatus: undefined,
  adminTerminalError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_TERMINAL.FETCH:
      return {
        ...state,
        adminTerminalStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case ADMIN_TERMINAL.SUCCESS:
      return {
        ...state,
        ...action.payload,
        adminTerminalStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case ADMIN_TERMINAL.FAILED:
      return {
        ...state,
        adminTerminalError: action.payload,
        adminTerminalStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
