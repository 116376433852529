import { DASHBOARD } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  duration: CONSTANTS.DASHBOARD.LAST_1_DAYS,
  accountSummary: {},
  accountSummaryStatus: undefined,
  accountSummaryError: undefined,
  dailySummary: {},
  dailySummaryStatus: undefined,
  dailySummaryError: undefined,
  hourlySummary: {},
  hourlySummaryStatus: undefined,
  hourlySummaryError: undefined,
  statusSummary: {},
  statusSummaryStatus: undefined,
  statusSummaryError: undefined,
  customerSummary: [],
  customerSummaryStatus: undefined,
  customerSummaryError: undefined,
  topPayments: { content: [] },
  topPaymentsStatus: undefined,
  topPaymentsError: undefined,
  customers: {},
  customersStatus: undefined,
  customersError: undefined,
  summaryType: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD.SET_DURATION:
      return {
        ...state,
        duration: action.payload.duration,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY:
      return {
        ...state,
        accountSummaryStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY_SUCCESS:
      return {
        ...state,
        accountSummary: action.payload,
        accountSummaryStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY_FAILED:
      return {
        ...state,
        accountSummaryStatus: CONSTANTS.API_STATUS.FAILED,
        accountSummaryError: action.payload,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_DAILY_SUMMARY:
      return {
        ...state,
        dailySummaryStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_DAILY_SUMMARY_SUCCESS:
      return {
        ...state,
        dailySummary: action.payload,
        dailySummaryStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_DAILY_SUMMARY_FAILED:
      return {
        ...state,
        dailySummaryStatus: CONSTANTS.API_STATUS.FAILED,
        dailySummaryError: action.payload,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_HOURLY_SUMMARY:
      return {
        ...state,
        hourlySummaryStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_HOURLY_SUMMARY_SUCCESS:
      return {
        ...state,
        hourlySummary: action.payload,
        hourlySummaryStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_HOURLY_SUMMARY_FAILED:
      return {
        ...state,
        hourlySummaryStatus: CONSTANTS.API_STATUS.FAILED,
        hourlySummaryError: action.payload,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_STATUS_SUMMARY:
      return {
        ...state,
        statusSummaryStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_STATUS_SUMMARY_SUCCESS:
      return {
        ...state,
        statusSummary: action.payload,
        statusSummaryStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_STATUS_SUMMARY_FAILED:
      return {
        ...state,
        statusSummaryStatus: CONSTANTS.API_STATUS.FAILED,
        statusSummaryError: action.payload,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY:
      return {
        ...state,
        customerSummaryStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY_SUCCESS:
      return {
        ...state,
        customerSummary: action.payload,
        customerSummaryStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case DASHBOARD.FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY_FAILED:
      return {
        ...state,
        customerSummaryStatus: CONSTANTS.API_STATUS.FAILED,
        customerSummaryError: action.payload,
      };
    case DASHBOARD.FETCH_TOP_PAYMENTS:
      return {
        ...state,
        topPaymentsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case DASHBOARD.FETCH_TOP_PAYMENTS_SUCCESS:
      return {
        ...state,
        topPayments: action.payload,
        topPaymentsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case DASHBOARD.FETCH_TOP_PAYMENTS_FAILED:
      return {
        ...state,
        topPaymentsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case DASHBOARD.FETCH_CUSTOMERS:
      return {
        ...state,
        customersStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case DASHBOARD.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        customersStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case DASHBOARD.FETCH_CUSTOMERS_FAILED:
      return {
        ...state,
        customersStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case DASHBOARD.SET_SUMMARY_TYPE:
      return {
        ...state,
        summaryType: action.payload,
      };
    default:
      return state;
  }
};
