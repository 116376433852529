import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Badge,
  Tooltip,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import RBAC from 'components/rbac';
import Clipboard from 'components/NavIcons/copyclipboard';
import getFilterOperators from 'utils/getFilterOperators';
import getBackgroundColor from 'utils/getWebhookBackground';
import getWebhookStatus from 'utils/getWebhookStatus';
import moment from 'moment-timezone';

import {
  webhooksActions,
  notificationActions,
  filterParamsActions,
} from 'store/actions';
import { NOTIFICATION, FILTERS_SPECS } from 'store/actionTypes';

import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
  MESSAGES,
} from 'appconstants';

import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddWebhook from './components/NewWebhook';
import EditWebhook from './components/EditWebhook';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const WEBHOOKS_FILTERS_TYPES = FILTERS_TYPES.WEBHOOKS;

type Props = {
  webhooks: Array<Object>,
  history: {
    push: Function,
  },
  fetchWebhooks: Function,
  selectedAccount: Object,
  totalElements: number,
  loading: boolean,
  test: boolean,
  validator: boolean,
  submitting: boolean,
  addWebhook: Function,
  deleteWebhooks: Function,
  updateWebhook: Function,
  setNotification: Function,
  fetchWebhookevents: Function,
  webhookEvents: Object,
  setFilterData: Function,
  preserveFilters: Object,
};

const Webhook = (props: Props) => {
  const {
    selectedAccount,
    webhooks,
    loading,
    fetchWebhooks,
    totalElements,
    test,
    history,
    validator,
    submitting,
    addWebhook,
    deleteWebhooks,
    updateWebhook,
    setNotification,
    fetchWebhookevents,
    webhookEvents,
    setFilterData,
    preserveFilters,
  } = props;

  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [selectedWebhook, setSelectedWebhook] = useState({});
  const [filterTypes, setFilterTypes] = useState(WEBHOOKS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterWebhooks, setFilterWebhooks] = useState((preserveFilters && preserveFilters.filterWebhooks)
    ? (preserveFilters && preserveFilters.filterWebhooks)
    : [{
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [addWebhookModal, setAddWebhookModal] = useState(false);
  const [editWebhookModal, setEditWebhookModal] = useState(false);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterWebhooks: [{
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });

  const exportData = webhooks ? webhooks.map((values) => ({
    url: values.url,
    status: getWebhookStatus(values.status),
    event: values.webhookEvent ? `${values.webhookEvent.length} events` : '',
    updatedAt: moment
      .utc(values.updatedAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
  })) : '';

  const exportHeaders = [
    { label: 'URL', key: 'url' },
    { label: 'STATUS', key: 'status' },
    { label: 'TOTAL EVENTS', key: 'event' },
    { label: 'LAST PUSHED', key: 'updatedAt' },
  ];

  const debouncedFetchWebhooks = debounce(fetchWebhooks, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchWebhooks({ currentPage, sortParams, filterParams });
    fetchWebhookevents();
  };

  useEffect(() => {
    getData(location.search);
  }, [test, selectedAccount, location]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      current: parseInt(currentPage, 10),
      total: totalElements,
      showTotal: total => `Total ${total} items`,
    }));
  }, [webhooks]);

  useEffect(() => {
    if (!validator) {
      setAddWebhookModal(false);
      setEditWebhookModal(false);
    }
  }, [validator]);

  const copyCode = (record) => {
    const el = record.secret;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WEBHOOK.BUTTON_SUCCESS,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    let fieldName;

    if (filters.field === 'user') {
      fieldName = 'user.givenName';
    } else {
      fieldName = filters.field;
    }

    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${fieldName}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${fieldName}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.WEBHOOK,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.WEBHOOK,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const showEditWebhookModal = (value: Object) => {
    setSelectedWebhook(value);
    setEditWebhookModal(true);
  };

  const addNewWebhook = (webhook: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const params = {
      url: webhook.url,
      email: webhook.email,
      isTest: webhook.testMode,
      status: webhook.status,
      events: webhook.eventList,
      secret: webhook.secret,
    };
    addWebhook({
      params,
      currentPage,
      sortParams,
    });
  };

  const deactiveWebhook = (gid: string) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    deleteWebhooks({
      currentPage,
      sortParams,
      gid,
    });
  };

  const editWebhook = (webhook: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const { gid } = webhook;
    const params = {
      url: webhook.url,
      email: webhook.email,
      isTest: webhook.testMode,
      status: webhook.status,
      events: webhook.eventList,
      secret: webhook.secret,
    };
    updateWebhook({
      currentPage,
      sortParams,
      gid,
      params,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterWebhooks];
    if (filterWebhooks.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = WEBHOOKS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterWebhooks(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterWebhooks];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amountDisputed') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = WEBHOOKS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterWebhooks(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterWebhooks[index].filterName === 'createdAt') {
      if (filterWebhooks[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterWebhooks[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterWebhooks[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterWebhooks[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterWebhooks];
    list[index].filterValue = selectedValue;
    setFilterWebhooks(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterWebhooks];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterWebhooks[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterWebhooks(list);
  };

  const handleAddClick = () => {
    const list = [...filterWebhooks];
    setFilterWebhooks([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterWebhooks([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(WEBHOOKS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.WEBHOOK);
  };

  const applyFilters = () => {
    const webhookFilters = filterWebhooks.filter((item) => item.checked);
    for (let i = 0; i < webhookFilters.length; i += 1) {
      if (!webhookFilters[i].filterName || !webhookFilters[i].filterOperator || !webhookFilters[i].filterValue) {
        return;
      }
    }
    if (webhookFilters[0].filterValue || (webhookFilters[0].filterValueFrom && webhookFilters[0].filterValueTo)) {
      setCount(webhookFilters && webhookFilters.length);
      for (let i = 0; i < webhookFilters.length; i += 1) {
        if (webhookFilters[i].checked) {
          if (webhookFilters[i].filterName === 'createdAt') {
            webhookFilters[i] = webhookFilters[i].filterValue;
          } else if (webhookFilters[i].filterName === 'email') {
            const email = encodeURIComponent(webhookFilters[i].filterValue);
            webhookFilters[i] = `${webhookFilters[i].filterName}.${webhookFilters[i].filterOperator}=${email}`;
          } else {
            webhookFilters[i] = `${webhookFilters[i].filterName}.${webhookFilters[i].filterOperator}=${webhookFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(webhookFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterWebhooks,
        });
        history.push({
          pathname: PAGES.WEBHOOK,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterWebhooks,
        });
        history.push({
          pathname: PAGES.WEBHOOK,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const columns = [
    {
      title: 'URL',
      dataIndex: 'url',
      width: '30%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (url) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {url}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '19%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getBackgroundColor(status) }}>
              {getWebhookStatus(status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'TOTAL EVENTS',
      dataIndex: 'event',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.webhookEvent && record.webhookEvent.length) {
          const eventsList = record.webhookEvent.length;
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {eventsList} events
              </span>
            </Tooltip>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'LAST PUSHED',
      dataIndex: 'updatedAt',
      width: '18%',
      align: 'left',
      defaultSortOrder: 'descend',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (updatedAt) => {
        if (updatedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(updatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];
  const actionColumn = {
    title: 'SECRET',
    dataIndex: 'action',
    width: '13%',
    align: 'center',
    render: (text, record) => (
      <span>
        <SpButton
          type="secondary"
          borderRadius="50px"
          onClick={(e) => { e.stopPropagation(); copyCode(record); }}
        >
          <Clipboard />
        </SpButton>
      </span>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}>
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                onClick={() => setAddWebhookModal(true)}
              >
                +&nbsp;Add Webhook
              </SpButton>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Webhooks">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Webhooks Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Webhooks">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-108px' }}
                  >
                    <Filters
                      filtersGrid={filterWebhooks}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterWebhooks.length < WEBHOOKS_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD Webhook MODAL */}
      {addWebhookModal && (
        <AddWebhook
          visible={addWebhookModal}
          submitting={submitting}
          close={() => setAddWebhookModal(false)}
          submit={addNewWebhook}
          webhookEvents={webhookEvents}
        />
      )}

      {/* EDIT Webhook MODAL */}
      {editWebhookModal && (
        <EditWebhook
          visible={editWebhookModal}
          submitting={submitting}
          selectedWebhook={selectedWebhook}
          close={() => setEditWebhookModal(false)}
          submit={editWebhook}
          deactive={deactiveWebhook}
          webhookEvents={webhookEvents}
        />
      )}

      <RBAC
        fallback={(
          <Table
            onRow={(record) => ({
              onClick: () => {
                showEditWebhookModal(record);
              },
            })}
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={webhooks}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR]}
      >
        <Table
          onRow={(record) => ({
            onClick: () => {
              showEditWebhookModal(record);
            },
          })}
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={webhooks}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  webhooks: state.webhooks.content,
  totalElements: state.membership.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  user: state.user,
  webhookEvents: state.webhooks.webhookEvents,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWebhooks: param => dispatch(webhooksActions.fetchWebhooks({
    payload: param,
  })),
  addWebhook: param => dispatch(webhooksActions.addWebhook({
    payload: param,
  })),
  updateWebhook: param => dispatch(webhooksActions.updateWebhook({
    payload: param,
  })),
  deleteWebhooks: param => dispatch(webhooksActions.deleteWebhooks({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchWebhookevents: param => dispatch(webhooksActions.fetchWebhookevents({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Webhook);
