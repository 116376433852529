import { BULKTRANSFER } from '../actionTypes';

export const fetchBulkTransfer = (payload) => ({
  type: BULKTRANSFER.FETCH,
  payload,
});

export const addBulkTransfer = (action) => ({
  type: BULKTRANSFER.ADD,
  payload: action.payload,
});

export const fetchBulkTransferSearch = (payload) => ({
  type: BULKTRANSFER.FETCH_SEARCH,
  payload,
});
