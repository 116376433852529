// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpButton, SpError } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  diningTable: Array,
  users: Array,
};

const AddCustomer = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    diningTable,
    users,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [userName, setUserName] = useState('');
  const [userGid, setUserGid] = useState('');
  const [tableName, setTableName] = useState('');
  const [tableGid, setTableGid] = useState('');

  useEffect(() => {
    if (diningTable && diningTable.length !== 0) {
      setName(diningTable[0].shop.name);
    }
  }, [diningTable]);
  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      userGid,
      tableGid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add</SpButton>,
      ]}
      title="Assign Table"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Shop Name</SpH5></label>
          <Input
            placeholder="Customer Name"
            value={name}
            disabled
          />
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Table</SpH5></label>
          <div>
            <Select
              style={{ width: '100%' }}
              showSearch
              value={tableName || 'Select table'}
              onChange={(value) => {
                setTableGid(value[0]);
                setTableName(value[1]);
              }}
            >
              {
                diningTable && diningTable.length !== 0 ? diningTable.map((info) => (
                  <Option
                    key={info.gid}
                    value={[info.gid, info.name]}
                  >
                    {info && info.name}
                  </Option>
                )) : ''
              }
            </Select>
            <SpError>
              {simpleValidator.current.message('table', tableName, 'required')}
            </SpError>
          </div>
        </div>

        <div className="pb-3">
          <label htmlFor=""><SpH5>Staff</SpH5></label>
          <div>
            <Select
              style={{ width: '100%' }}
              showSearch
              value={userName || 'Select staff'}
              onChange={(value) => {
                setUserGid(value[0]);
                setUserName(value[1]);
              }}
            >
              {
                users && users.length !== 0 ? users.map((info) => (
                  <Option
                    key={info.user && info.user.gid}
                    value={[info.user && info.user.gid, info.user && info.user.fullName]}
                  >
                    {info.user && info.user.fullName}
                  </Option>
                )) : ''
              }
            </Select>
            <SpError>
              {simpleValidator.current.message('staff', userName, 'required')}
            </SpError>
          </div>
        </div>

      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddCustomer);
