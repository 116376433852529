// @flow
import React, { useState, useRef } from 'react';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import { SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import {
  SERVICES_TYPES,
} from 'appconstants';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  fieldAgentList: Array<Object>,
  serviceDetails: Object,
};

const EditService = (props: Props) => {
  const {
    visible,
    close,
    submit,
    fieldAgentList,
    serviceDetails,
  } = props;

  const [, forceUpdate] = useState();
  const [content, setContent] = useState('');
  const [status, setStatus] = useState(serviceDetails.status);
  const [fieldAgentLookupId, setFieldAgentLookupId] = useState();

  const simpleValidator = useRef(new SimpleReactValidator());

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      status,
      content,
      fieldAgentLookupId,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Update</SpButton>,
      ]}
      title="Update Service Request"
    >
      <div className="px-4">
        <div className="pb-3">
          <label className="d-block font-weight-bold">Status</label>
          <Select
            className="w-100 mr-4"
            placeholder="Location"
            showSearch
            optionFilterProp="children"
            defaultValue={status}
            onChange={(e) => setStatus(e)}
          >
            {
                SERVICES_TYPES.map(s => (
                  <Option
                    key={s.id}
                    value={s.value}
                  >
                    { s.display }
                  </Option>
                ))
              }
          </Select>
        </div>

        <div className="pb-3">
          <label className="d-block font-weight-bold">Field Agent</label>
          <Select
            className="w-100 mr-4"
            placeholder="Field agent"
            showSearch
            optionFilterProp="children"
            onChange={(e) => setFieldAgentLookupId(e)}
          >
            {
                fieldAgentList && fieldAgentList.map(s => (
                  <Option
                    key={s.id}
                    value={s.id}
                  >
                    { s.user && s.user.fullName }
                  </Option>
                ))
              }
          </Select>
        </div>

        <div>
          <label className="d-block font-weight-bold">Internal Notes</label>
          <TextArea
            rows={5}
            value={content}
            maxlength="1000"
            onChange={(e) => setContent(e.currentTarget.value)}
          />
          <div className="text-danger">
            {simpleValidator.current.message('internal notes', content, 'required')}
          </div>
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default EditService;
