// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  inventoryQuickSupport: Array,
};

const DeleteSupport = (props: Props) => {
  const {
    visible,
    close,
    submit,
    inventoryQuickSupport,
  } = props;

  const DeleteSelectedItem = () => {
    submit(inventoryQuickSupport.gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={DeleteSelectedItem} type="danger">Delete</SpButton>,
      ]}
      title="Delete Support"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to delete this support?</div>
        <SpH5>
          <div>
            {
              (inventoryQuickSupport && inventoryQuickSupport.length !== 0)
                ? (inventoryQuickSupport && inventoryQuickSupport.name)
                : <>&#8211;</>
            }
          </div>
        </SpH5>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteSupport;
