import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  MANAGE_ACCOUNTS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchSelfAccounts = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParam && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}?${payload.filterParams}&contact.contactType.EQ=SELF`);
  }
  if (payload.filterParam && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}${query}${sorting}&${payload.filterParams}&contact.contactType.EQ=SELF`);
  }
  if (!payload.filterParam && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}${query}${sorting}&contact.contactType.EQ=SELF`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}${query}${sorting}&contact.contactType.EQ=SELF`);
};

const postFundingSource = async (params) => axiosAuthInstance.post(API_END_POINTS.GET_FUNDINGSOURCE, params);

const patchFundingSource = async (gid) => axiosAuthInstance.patch(`${API_END_POINTS.SET_DEFAULT_CONTACT}/${gid}`);

const deleteFundingSource = async (gid) => axiosAuthInstance.delete(`${API_END_POINTS.GET_FUNDINGSOURCE}/${gid}`);

const getSelfAccountById = async (gid) => axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}/${gid}`);

export function* getSelfAccounts(action) {
  try {
    const { data: { entity } } = yield call(fetchSelfAccounts, action.payload);
    yield put({ type: MANAGE_ACCOUNTS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: MANAGE_ACCOUNTS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* fetchSelfAccountById(action) {
  try {
    const { data: { entity } } = yield call(getSelfAccountById, action.payload);
    yield put({ type: MANAGE_ACCOUNTS.SUCCESS_BY_ID, payload: entity });
  } catch (e) {
    yield put({ type: MANAGE_ACCOUNTS.FAILED_BY_ID, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addSelfAccount(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postFundingSource, action.payload.data);
    const { data: { entity } } = yield call(fetchSelfAccounts, action.payload.payload);
    yield put({ type: MANAGE_ACCOUNTS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'New account added successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateSelfAccount(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchFundingSource, action.payload.contactGid);
    if (action.payload.payload) {
      const { data: { entity } } = yield call(fetchSelfAccounts, action.payload.payload);
      yield put({ type: MANAGE_ACCOUNTS.SUCCESS, payload: entity });
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Default account changed successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteSelefAccount(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteFundingSource, action.payload.data);
    if (action.payload.payload) {
      const { data: { entity } } = yield call(fetchSelfAccounts, action.payload.payload);
      yield put({ type: MANAGE_ACCOUNTS.SUCCESS, payload: entity });
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Accont deleted successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
