// @flow
import React, { useRef, useState } from 'react';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';
import {
  TAX_STATUS,
  TAX_TYPES,
  COUNTRY_PHONE_CODE,
} from 'appconstants';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
};

const AddNewCustomer = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState(getCountryPhoneCode((selectedAccount && selectedAccount.country && selectedAccount.country.id)));
  const [taxStatus, setTaxStatus] = useState('');
  const [taxValue, setTaxValue] = useState(null);
  const [taxId, setTaxId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [phoneFormate, setPhoneFormate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const updateTaxVale = (value) => {
    if (value === 'NONE') {
      setDisabled(true);
      setShow(false);
      setTaxId(value);
      setTaxValue(null);
    } else {
      setTaxId(value);
      setDisabled(false);
      setShow(true);
    }
  };

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    setPhone(phoneNumber.replace(/[^0-9+]/g, ''));
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      email,
      phoneNumber,
      countryCode,
      taxStatus,
      taxValue,
      taxId,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Add Customer</SpButton>,
      ]}
      title="Add New Customer"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Customer Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            placeholder="Customer email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('email', email, 'required|email|checkLowerCase')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone Number</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                value={countryCode}
                onChange={onCountryCodeChange}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormate}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setPhone(e.currentTarget.value);
                    const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormate(formater);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('phone number', phoneNumber, `phoneNumValidation:${countryCode}${phoneNumber}`)}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <label className="d-block"><SpH5>Tax Status</SpH5></label>
          <div className="w-50 mr-3">
            <Select
              className="w-100"
              placeholder="Select Tax Status"
              onChange={(e) => setTaxStatus(e)}
            >
              {
                TAX_STATUS.map(s => (
                  <Option
                    key={1}
                    value={s.value}
                    className="text-capitalize"
                  >
                    {s.display}
                  </Option>
                ))
              }
            </Select>

            <SpError>
              {simpleValidator.current.message('tax status', taxStatus, 'required')}
            </SpError>
          </div>
        </div>

        <div className="pb-3">
          <label className="d-block"><SpH5>Tax Id</SpH5></label>
          <div className="d-flex">
            <div className="w-50 mr-3">
              <Select
                className="w-100"
                placeholder="Select Tax Id"
                onChange={updateTaxVale}
              >
                {
                  TAX_TYPES.map(j => (
                    <Option
                      key={1}
                      value={j.value}
                      className="text-capitalize"
                    >
                      {j.display}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('tax Id', taxId, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <Input
                placeholder="Enter Tax value"
                value={taxValue}
                disabled={disabled}
                onChange={(e) => setTaxValue(e.currentTarget.value)}
              />
              <SpError>
                {show
                  ? simpleValidator.current.message('Tax value', taxValue, 'required')
                  : ''}
              </SpError>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default AddNewCustomer;
