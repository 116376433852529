import { PLANSDETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  subscription: [],
  plansDetailsStatus: undefined,
  plansDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PLANSDETAILS.FETCH:
      return {
        ...state,
        plansDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case PLANSDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        plansDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case PLANSDETAILS.FAILED:
      return {
        ...state,
        plansDetailsError: action.payload,
        plansDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case PLANSDETAILS.PAYMENTS_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
      };
    default:
      return state;
  }
};
