// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  Input,
  Modal,
  TimePicker,
  Icon,
  Row,
  Col,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpError, SpButton,
} from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
};

const AddOperatingHours = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const [operatingHours, setOperatingHours] = useState([]);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    const list = [
      {
        dayOfWeek: 'MONDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'TUESDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'WEDNESDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'THURSDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'FRIDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'SATURDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'SUNDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
    ];
    setOperatingHours([...list]);
  }, []);

  const getTimeVal = (id, index, type) => {
    if (type === 'From') {
      const fromTime = operatingHours[id].operatingHours[index].openingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          return moment(`${hours}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    } if (type === 'To') {
      const fromTime = operatingHours[id].operatingHours[index].closingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          const hr = 0;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    }
    return null;
  };

  const selectOperatingHours = (e, type, i, j) => {
    const list = [...operatingHours];
    const time = e.format('hh:mm a');
    const timeStamp = time.split(' ')[1];
    const actualTime = time.split(' ')[0];
    const a = actualTime.split(':');
    let minutes;
    if (a[0] === '12' && timeStamp === 'am') {
      minutes = +a[1];
    } else if (a[0] === '12' && timeStamp === 'pm' && type === 'From') {
      minutes = +a[0] * 60;
    } else {
      minutes = (+a[0] + (timeStamp === 'pm' ? 12 : 0)) * 60 + (+a[1]);
    }
    if (type === 'From') {
      list[i].operatingHours[j].openingTime = minutes;
    } else if (type === 'To') {
      list[i].operatingHours[j].closingTime = minutes;
    }
    setOperatingHours([...list]);
  };

  const updateStatus = (val, i, j) => {
    const list = [...operatingHours];
    list[i].operatingHours[j].open = val;
    setOperatingHours([...list]);
  };

  const removeTimeSlot = (dayIndex, slotIndex) => {
    setOperatingHours((prevData) => {
      const updatedData = [...prevData];
      updatedData[dayIndex].operatingHours.splice(slotIndex, 1);
      return updatedData;
    });
  };

  const addTimeSlot = (dayIndex) => {
    const newTimeSlot = {
      openingTime: '',
      closingTime: '',
      open: false,
    };
    setOperatingHours((prevData) => {
      const updatedData = [...prevData];
      updatedData[dayIndex].operatingHours.push(newTimeSlot);
      return updatedData;
    });
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      description,
      operatingHours,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Operating Hours"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            defaultValue={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <Input
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('description', description, 'required')}
          </SpError>
        </div>
        {operatingHours.map((day, dayIndex) => (
          <div key={dayIndex}>
            {day.operatingHours.map((timeSlot, slotIndex) => (
              <div key={slotIndex}>
                <Row className="mt-3" type="flex" justify="start">
                  <Col span={4}>
                    {slotIndex === 0 && <span style={{ fontSize: '13px' }}>{day.dayOfWeek}</span>}
                  </Col>
                  <Col span={6}>
                    <TimePicker
                      style={{ width: '100px ', marginRight: '10px' }}
                      allowClear={false}
                      use12Hours
                      format="h:mm a"
                      defaultValue={getTimeVal(dayIndex, slotIndex, 'From')}
                      onChange={(e) => selectOperatingHours(e, 'From', dayIndex, slotIndex)}
                    />
                  </Col>
                  <Col span={6}>
                    <TimePicker
                      style={{ width: '100px ', marginLeft: '10px' }}
                      allowClear={false}
                      use12Hours
                      format="h:mm a"
                      defaultValue={getTimeVal(dayIndex, slotIndex, 'To')}
                      onChange={(e) => selectOperatingHours(e, 'To', dayIndex, slotIndex)}
                    />
                  </Col>
                  <Col span={7}>
                    &nbsp;&nbsp;&nbsp;<Switch
                      className="mx-1"
                      defaultChecked={timeSlot.open}
                      onChange={val => updateStatus(val, dayIndex, slotIndex)}
                    /> Available
                  </Col>
                  <Col span={1}>
                    {slotIndex === 0 ? (
                      <Icon
                        type="plus-circle"
                        style={{
                          cursor: 'pointer', color: '#3E8BFF',
                        }}
                        onClick={() => addTimeSlot(dayIndex)}
                      />
                    )
                      : (
                        <Icon
                          type="minus-circle"
                          style={{
                            cursor: 'pointer', color: '#FF4B51',
                          }}
                          onClick={() => removeTimeSlot(dayIndex, slotIndex)}
                        />
                      )}
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.loading.submitting,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddOperatingHours);
