/* eslint-disable */
import React from 'react';

const payments = ({ fill = "silver" }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.9 0C2.82305 0 1.95 0.873044 1.95 1.95V4.55H3.25V1.95C3.25 1.59101 3.54101 1.3 3.9 1.3H11.05C11.409 1.3 11.7 1.59101 11.7 1.95V7.68904C12.4574 7.42135 13 6.69904 13 5.85V1.95C13 0.873045 12.127 0 11.05 0H3.9Z" fill={fill} />
    <path d="M5.44089 10.4C6.15886 10.4 6.74089 9.81797 6.74089 9.1C6.74089 8.38203 6.15886 7.8 5.44089 7.8C4.72292 7.8 4.14089 8.38203 4.14089 9.1C4.14089 9.81797 4.72292 10.4 5.44089 10.4Z" fill={fill} />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.15C0 6.07304 0.873045 5.2 1.95 5.2H9.1C10.177 5.2 11.05 6.07304 11.05 7.15V11.05C11.05 12.127 10.177 13 9.1 13H1.95C0.873045 13 0 12.127 0 11.05V7.15ZM2.53 6.5C2.26902 7.03714 1.8348 7.47454 1.3 7.73956V10.4604C1.8348 10.7255 2.26902 11.1629 2.53 11.7H8.51706C8.77411 11.1534 9.21032 10.7078 9.75 10.4387V7.72513C9.22228 7.46202 8.7935 7.03006 8.53443 6.5H2.53Z" fill={fill} />
  </svg>
);

export default payments;
