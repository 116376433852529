// @flow
import React, {
  useRef,
  useState,
} from 'react';
import {
  Input,
  Modal,
  Row,
} from 'antd';
import OtpInput from 'react-otp-input';

import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpError, SpText, SpButton,
} from 'components/DesignKit';

    type Props = {
      close: Function,
      submit: Function,
      visible: boolean,
      consfirmWalletPin: Boolean,
    };

const ResetPin = (props: Props) => {
  const {
    close,
    submit,
    visible,
    consfirmWalletPin,
  } = props;
  const [, forceUpdate] = useState();

  const [walletPin, setWalletPin] = useState('');
  const [code, setCode] = useState('');
  const [confirmWalletPin, setConfirmWalletPin] = useState('');
  const [showPin, setShowPin] = useState(true);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      walletPinData: {
        message: 'Incorrect Wallet PIN',
        rule: (val) => val.length === 6,
      },
      confirmPin: {
        message: 'Confirm Wallet PIN is not Matching with the Wallet PIN',
        rule: (val, params) => val === params[0],
      },
    },
  }));

  const handleChange = (val) => {
    setWalletPin(val);
  };

  const handleChangeConfirm = (val) => {
    setConfirmWalletPin(val);
  };

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      walletPin,
      code,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>{consfirmWalletPin ? 'Submit' : 'Reset' }</SpButton>,
      ]}
      title="Reset Wallet Pin"
    >
      <div className="px-4">
        {
        consfirmWalletPin ? (
          <div>
            <label htmlFor=""><SpH5>Verification Code</SpH5></label>
            <Input
              placeholder="Enter code"
              value={code}
              onChange={(e) => setCode(e.currentTarget.value.trim())}
            />
            <SpText fontSize="10px" color="#333333" fontWeight="400">Please use verification code sent to your email</SpText>
            <SpError>
              {simpleValidator.current.message('Verification code', code, 'required')}
            </SpError>
          </div>
        ) : (
          <div>
            <div className="mt-2 mb-3">
              <Row type="flex" justify="space-between">
                <div><label htmlFor=""><SpH5>New Wallet PIN</SpH5></label></div>
                <div>
                  <label htmlFor=""><SpH5 color="#3E8BFF" style={{ cursor: 'pointer' }} onClick={() => setShowPin(!showPin)}>Show</SpH5></label>
                </div>
              </Row>
              <OtpInput
                className="pinSet"
                value={walletPin}
                onChange={handleChange}
                numInputs={6}
                isInputNum
                isInputSecure={showPin}
              />
              <SpError>
                {simpleValidator.current.message('wallet pin', walletPin, 'required|walletPinData')}
              </SpError>
            </div>
            <div>
              <label htmlFor=""><SpH5>Confirm New Wallet Pin</SpH5></label>
              <OtpInput
                className="pinSet"
                value={confirmWalletPin}
                onChange={handleChangeConfirm}
                numInputs={6}
                isInputNum
                isInputSecure={showPin}
              />
              <SpError>
                {simpleValidator.current.message('confirm wallet pin', confirmWalletPin, `required|confirmPin:${walletPin}`)}
              </SpError>
            </div>
          </div>

        )
      }
      </div>
    </Modal>

  );
};

// $FlowFixMe
export default ResetPin;
