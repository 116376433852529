export default status => {
  if (status === 'ACTIVE') {
    return '#ffe0b1';
  } if (status === 'SUCCEEDED') {
    return '#ececff';
  } if (status === 'EXPIRED') {
    return '#ee6972';
  } if (status === 'CANCELLED') {
    return '#e2e2e2';
  } return '#e2e2e2';
};
