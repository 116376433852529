import { REWARD_SETTINGS } from '../actionTypes';

const initialState = {
  content: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REWARD_SETTINGS.SUCCESS:
      return {
        ...state,
        content: action.payload,
      };
    default:
      return state;
  }
};
