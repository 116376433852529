// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
} from 'antd';

import {
  SpText,
  SpH5,
  SpButton,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  inventoryPrinterLabelDetailsActions,
  inventoryPrinterLabelActions,
  inventoryItemsActions,
  notificationActions,
} from 'store/actions';
import {
  PAGES,
} from 'appconstants';
import formatNumber from 'utils/formatNumber';
import EditItems from './components/EditItems';
import AddRemoveItems from './components/AddRemoveItems';
import DeletePrinterLabel from './components/DeleteOrderType';

type Props = {
  fetchInventoryPrinterLabelDetails: Function,
  fetchInventorySortItems: Function,
  addItemPrinterLabel: Function,
  fetchItemByPrinterLabel: Function,
  deleteInventoryPrinterLabel: Function,
  inventoryPrinterLabelDetails: Object,
  printerLabelItemsList: Array,
  inventoryItems: Array,
  loading: boolean,
  selectedAccount: Object,
  updateInventoryPrinterLabel: Function,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  submitting: Boolean,
  validator: Boolean,
  totalElements: Number,
}

const PrinterLabelDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchInventoryPrinterLabelDetails,
    addItemPrinterLabel,
    deleteInventoryPrinterLabel,
    fetchInventorySortItems,
    fetchItemByPrinterLabel,
    inventoryPrinterLabelDetails,
    inventoryItems,
    updateInventoryPrinterLabel,
    printerLabelItemsList,
    loading,
    history,
    submitting,
    validator,
    selectedAccount,
    totalElements,
  } = props;
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const [addItemsModal, setAddItemsModal] = useState(false);
  const [editItemsModal, setEditItemsModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [pagination, setPagination] = useState({});
  const currencyType = selectedAccount && selectedAccount.currency;
  const [deletePrinterLabelModal, setDeletePrinterLabelModal] = useState(false);
  const [selectedPrinterLabel, setSelectedPrinterLabel] = useState({});
  const kdsRole = localStorage.getItem('kdsRole');

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'sortOrder';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    fetchInventoryPrinterLabelDetails(gid);
    fetchItemByPrinterLabel(gid);
    fetchInventorySortItems({ currentPage, sortParams, filterParams });
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.PRINTER_LABEL);
    }
  }, []);

  useEffect(() => {
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      showTotal: total => `Total ${total} items`,
    }));
  }, [printerLabelItemsList]);
  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    if (redirect && !submitting) {
      setDeletePrinterLabelModal(false);
      history.push({
        pathname: PAGES.PRINTER_LABEL,
      });
    }
  }, [submitting]);

  useEffect(() => {
    if (redirect && !validator) {
      setEditItemsModal(false);
      setAddItemsModal(false);
      getData(location.search);
    }
  }, [validator]);
  const editSelectedItem = (customer: Object) => {
    const {
      name,
    } = customer;

    const params = {
      name,
    };
    updateInventoryPrinterLabel({
      gid,
      params,
    });
    setRedirect(true);
  };

  const addSelectedItem = (customer: Object) => {
    const {
      categories,
    } = customer;

    const listOfItems = (printerLabelItemsList && printerLabelItemsList.length !== 0) ? printerLabelItemsList.map((option) => option.gid) : null;
    const removedValues = (listOfItems && printerLabelItemsList.length !== 0) ? listOfItems.filter(item => !categories.includes(item)) : null;
    let params;
    if (removedValues && removedValues.length !== 0) {
      params = {
        ADDED: categories,
        REMOVED: removedValues,
      };
    } else {
      params = {
        ADDED: categories,
      };
    }
    addItemPrinterLabel({
      gid,
      params,
    });
    setRedirect(true);
  };

  const selectedRecord = (record) => {
    const itemGid = record.gid;
    history.push({
      pathname: `${PAGES.INVENTORY_ITEMS}/${itemGid}`,
      state: location.search,
    });
  };

  const showDeletePrinterLabelModal = () => {
    setSelectedPrinterLabel(inventoryPrinterLabelDetails);
    setDeletePrinterLabelModal(true);
  };

  const deleteSelectedOrderType = (customerGid: Object) => {
    deleteInventoryPrinterLabel({
      gid: customerGid,
    });
    setRedirect(true);
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (price) => {
        const total = (price / 100).toFixed(2);
        return (
          <span> {`${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'COST',
      dataIndex: 'cost',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (cost) => {
        const total = (cost / 100).toFixed(2);
        return (
          <span> {`${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'SORT ORDER',
      dataIndex: 'sortOrder',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (sortOrder) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {sortOrder || 0}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ON POS',
      dataIndex: 'hidden',
      width: '9%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (hidden) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {hidden ? 'No' : 'Yes'}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'AVAILABLE',
      dataIndex: 'available',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (available) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {available ? 'Yes' : 'No'}
          </span>
        </Tooltip>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        inventoryPrinterLabelDetails.gid && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-3" fontWeight="600" fontSize="20px">
                    {(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.name) || <>&#8211;</>}
                  </SpText>
                </Col>
                {
                  kdsRole !== 'KDS-USER' && (
                    <Col>
                      <SpButton
                        shape="round"
                        type="danger"
                        className="mr-3"
                        onClick={() => showDeletePrinterLabelModal(true)}
                      >
                        Delete Label
                      </SpButton>
                      <SpButton
                        shape="round"
                        type="secondary"
                        onClick={() => setEditItemsModal(true)}
                      >
                        Edit Label
                      </SpButton>
                    </Col>
                  )
                }
              </Row>
              <Row>
                <Col span={24} className="d-flex justify-content-end">
                  <SpH5 color="#7f7f7f">{inventoryPrinterLabelDetails.gid}</SpH5>
                </Col>
              </Row>
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">ITEMS</SpText>
                </Col>
                {
                  (printerLabelItemsList && printerLabelItemsList.length !== 0) && (
                    <Col>
                      {
                        kdsRole !== 'KDS-USER' && (
                          <SpButton
                            type="secondary"
                            shape="round"
                            ghost
                            className="mr-3"
                            onClick={() => setAddItemsModal(true)}
                          >
                            {'\u002B'}&nbsp;Add or Remove Items
                          </SpButton>
                        )
                      }
                    </Col>
                  )
                }
              </Row>
              {/* DELETE ORDER TYPE MODAL */}
              {deletePrinterLabelModal && (
                <DeletePrinterLabel
                  visible={deletePrinterLabelModal}
                  submitting={submitting}
                  inventoryItemDetails={selectedPrinterLabel}
                  selectedAccount={selectedAccount}
                  close={() => setDeletePrinterLabelModal(false)}
                  submit={deleteSelectedOrderType}
                />
              )}
              {/* Add ITEMS MODAL */}
              {addItemsModal && (
                <AddRemoveItems
                  visible={addItemsModal}
                  submitting={submitting}
                  selectedItemList={inventoryPrinterLabelDetails}
                  itemsList={inventoryItems}
                  printerLabelItemsList={printerLabelItemsList}
                  selectedAccount={selectedAccount}
                  close={() => setAddItemsModal(false)}
                  submit={addSelectedItem}
                />
              )}
              {/* UPDATE ITEMS MODAL */}
              {editItemsModal && (
                <EditItems
                  visible={editItemsModal}
                  submitting={submitting}
                  selectedItemList={inventoryPrinterLabelDetails}
                  inventoryItems={inventoryItems}
                  selectedAccount={selectedAccount}
                  printerLabelItemsList={printerLabelItemsList}
                  close={() => setEditItemsModal(false)}
                  submit={editSelectedItem}
                />
              )}
              {
                printerLabelItemsList && printerLabelItemsList.length !== 0 ? (
                  <>
                    <Table
                      rowClassName={() => 'ant-table-clickable-row'}
                      columns={columns}
                      onRow={
                        (record) => ({
                          onClick: () => {
                            selectedRecord(record);
                          },
                        })
                      }
                      rowKey={(record) => record.gid}
                      className="custom-table"
                      dataSource={printerLabelItemsList}
                      pagination={pagination}
                    />
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        border: '1px solid #CBCBCB',
                        minHeight: '253px',
                        width: '1014 px',
                        marginTop: '10px',
                        marginBottom: '25px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <SpH5 color="#333333">
                          No items assigned yet
                        </SpH5>
                        <div
                          style={{
                            marginTop: '15px',
                          }}
                        >
                          {
                            kdsRole !== 'KDS-USER' && (
                              <SpButton
                                type="secondary"
                                onClick={() => setAddItemsModal(true)}
                              >
                                {'\u002B'}&nbsp;Add Items
                              </SpButton>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </>
                )
              }

            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  inventoryPrinterLabelDetails: state.inventoryPrinterLabelDetails,
  inventoryItems: state.inventoryItems.sortItemsList.content,
  printerLabelItemsList: state.inventoryPrinterLabelDetails.printerLabelItemsList,
  totalElements: state.inventoryPrinterLabelDetails.printerLabelItemsList ? state.inventoryPrinterLabelDetails.printerLabelItemsList.length : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  profile: state.subscription.profile,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryPrinterLabelDetails: gid => dispatch(inventoryPrinterLabelDetailsActions.fetchInventoryPrinterLabelDetails({
    payload: gid,
  })),
  addItemPrinterLabel: gid => dispatch(inventoryPrinterLabelDetailsActions.addItemPrinterLabel({
    payload: gid,
  })),
  fetchItemByPrinterLabel: gid => dispatch(inventoryPrinterLabelDetailsActions.fetchItemByPrinterLabel({
    payload: gid,
  })),
  fetchInventorySortItems: param => dispatch(inventoryItemsActions.fetchInventorySortItems({
    payload: param,
  })),
  updateInventoryPrinterLabel: param => dispatch(inventoryPrinterLabelActions.updateInventoryPrinterLabel({
    payload: param,
  })),
  deleteInventoryPrinterLabel: param => dispatch(inventoryPrinterLabelActions.deleteInventoryPrinterLabel({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PrinterLabelDetails);
