import { WOOCOMMERCE } from '../actionTypes';

const initialState = {
  content: [],
  wooCommerceList: [],
  accountWoocommerce: {},
  woocommerceStatus: undefined,
  woocommerceError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WOOCOMMERCE.SUCCESS:
      return {
        ...state,
        accountWoocommerce: action.payload,
      };
    case WOOCOMMERCE.FETCH:
      return {
        ...state,
        woocommerceStatus: 'STARTED',
      };
    case WOOCOMMERCE.FAILED:
      return {
        ...state,
        woocommerceError: action.payload,
        woocommerceStatus: 'FAILED',
      };
    case WOOCOMMERCE.LIST_SUCCESS:
      return {
        ...state,
        wooCommerceList: action.payload,
      };
    default:
      return state;
  }
};
