import {
  call,
  put,
} from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  LOADING,
  WALLET_DETAILS,
  NOTIFICATION,
} from '../actionTypes';

const fetchWalletDetails = async () => axiosAuthInstance.get(`${API_END_POINTS.GET_WALLET}`);

const postNewBankAccount = async (params) => axiosAuthInstance.post(API_END_POINTS.WALLET_BANK, params);

const postLoadBalance = async (params) => axiosAuthInstance.post(API_END_POINTS.WALLET_LOAD, params);

const confirmWallet = async (params) => axiosAuthInstance.post(API_END_POINTS.SET_UP_WALLET, params);

const getTransactions = async () => axiosAuthInstance.get(`${API_END_POINTS.WALLET_HISTORY_LOG}?size=5&sortBy=createdAt`);

const postNewCard = async (params) => axiosAuthInstance.post(API_END_POINTS.WALLET_CARD, params);

const fetchCardDetails = async () => axiosAuthInstance.get(`${API_END_POINTS.WALLET_CARD}`);

const postAmount = async (params) => axiosAuthInstance.post(API_END_POINTS.WALLET_PAYOUT, params);

const deleteNewCard = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.WALLET_CARD}/${gid}`);

const patchCard = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.WALLET_CARD}/${gid}`, params);

export function* getWalletDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchWalletDetails, action.payload);
    yield put({ type: WALLET_DETAILS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: WALLET_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addNewBankAccount(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postNewBankAccount, action.payload);
    const { data: { entity } } = yield call(fetchWalletDetails);
    yield put({ type: WALLET_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WALLET.BANK_ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addConfirmWallet(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(confirmWallet, action.payload);
    const { data: { entity } } = yield call(fetchWalletDetails);
    yield put({ type: WALLET_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WALLET.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* loadBalance(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data } = yield call(postLoadBalance, action.payload);
    const { data: { entity } } = yield call(fetchWalletDetails);
    yield put({ type: WALLET_DETAILS.SUCCESS, payload: entity });
    if (data && data.entity && data.entity.status === 'FAILED') {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: data.entity.errorDescription,
      });
    } else {
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.WALLET.LOAD_BALANCE,
      });
    }
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* fetchTransactions(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(getTransactions, action.payload);
    yield put({ type: WALLET_DETAILS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: WALLET_DETAILS.FETCH_TRANSACTIONS_SUCCESS, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addNewCard(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postNewCard, action.payload);
    const { data: { entity } } = yield call(fetchCardDetails, action.payload);
    yield put({ type: WALLET_DETAILS.FETCH_CARDS_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WALLET.CARD_ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getDebitCards(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(fetchCardDetails, action.payload);
    yield put({ type: WALLET_DETAILS.FETCH_CARDS_SUCCESS, payload: entity });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    yield put({ type: WALLET_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* withdrawAmount(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    const { data } = yield call(postAmount, action.payload);
    const { data: { entity } } = yield call(fetchWalletDetails, action.payload);
    yield put({ type: WALLET_DETAILS.SUCCESS, payload: entity });
    if (data && data.entity && data.entity.status === 'FAILED') {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: data.entity.errorDescription,
      });
    } else {
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.WALLET.WITHDRAW_BALANCE,
      });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteCard(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteNewCard, action.payload);
    const { data: { entity } } = yield call(fetchCardDetails, action.payload);
    yield put({ type: WALLET_DETAILS.FETCH_CARDS_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WALLET.CARD_DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateCard(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCard, action.payload);
    const { data: { entity } } = yield call(fetchCardDetails, action.payload);
    yield put({ type: WALLET_DETAILS.FETCH_CARDS_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WALLET.CARD_UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
