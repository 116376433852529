import { call, put } from 'redux-saga/effects';

import {
  API_END_POINTS,
  axiosNoAuthInstance,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  RECAPTCHA_DETAILS,
  NOTIFICATION,
} from '../actionTypes';

const fetchRecaptchaDetails = () => axiosNoAuthInstance.get(`${API_END_POINTS.GET_RECAPTCHA}`);

export function* getRecaptchaDetails(action) {
  try {
    const { data: { entity } } = yield call(fetchRecaptchaDetails, action.payload);
    yield put({ type: RECAPTCHA_DETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: RECAPTCHA_DETAILS.FAILED, payload: e.response.data });
  }
}
