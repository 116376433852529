export default status => {
  if (status === 'VERIFICATION_PENDING') {
    return '#f6d87e';
  } if (status === 'VERIFIED') {
    return '#ececff';
  } if (status === 'NOT_VERIFIED') {
    return '#e2e2e2';
  } if (status === 'PROCESSED') {
    return '#e2e2e2';
  } if (status === 'EXPIRED') {
    return '#ee6972';
  } if (status === 'PROCESSED') {
    return '#f6d87e';
  } return '#e2e2e2';
};
