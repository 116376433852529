// @flow
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  nodes: Array<object>,
};

const TableInfo = (props: Props) => {
  const {
    visible,
    close,
    submit,
    nodes,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [number, setNumber] = useState(1);
  const index = nodes.length - 1;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      number,
      index,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Add</SpButton>,
      ]}
      title="Add Table Information"
    >
      <div className="px-4">
        <div className="mb-3">
          <label htmlFor=""><SpH5>Table Name</SpH5></label>
          <Input
            placeholder="Table Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>No of Guest</SpH5></label>
          <Input
            placeholder="No of Guest"
            value={number}
            onChange={(e) => setNumber(e.currentTarget.value)}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
          />
          <SpError>
            {simpleValidator.current.message('No of guests', number, 'required|numeric')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(TableInfo);
