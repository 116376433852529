// @flow
import React, { useRef, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import { countryCodeValidation } from 'utils/validationMessages';
import { COUNTRY_PHONE_CODE } from 'appconstants';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  phoneCode: string,
  id: Number,
};

const NotifyPaymentPage = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    phoneCode,
    id,
  } = props;

  const [, forceUpdate] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [emailId, setEmail] = useState();
  const [phoneNumber, setPhone] = useState(null);
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [phoneFormate, setPhoneFormate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));


  useEffect(() => {
    if (phoneCode === '+1') {
      setPhoneFormate('(###) ###-####');
    } else if (phoneCode === '+91') {
      setPhoneFormate('##### #####');
    } else {
      setPhoneFormate('##########');
    }
  }, []);

  useEffect(() => {
    if (id === 1) {
      setPhoneFormate('(###) ###-####');
    } else if (id === 2) {
      setPhoneFormate('##### #####');
    } else {
      setPhoneFormate('##########');
    }
  }, []);

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    if (value === '+1') {
      setPhoneFormate('(###) ###-####');
      setCountryCode(value);
    } else if (value === '+91') {
      setPhoneFormate('##### #####');
      setCountryCode(value);
    } else {
      setPhoneFormate('##########');
      setCountryCode(value);
    }
  };

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    if (phoneNumber) {
      setPhone(phoneNumber.replace(/[^0-9+]/g, ''));
    }
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      emailId,
      phoneNumber,
      countryCode,
      subject,
      message,
      type: (phoneNumber) && (emailId) ? 'ALL' : phoneNumber ? 'SMS' : 'EMAIL',
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Notify</SpButton>,
      ]}
      title="Notify Payment Page"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Subject</SpH5></label>
          <Input
            placeholder="Subject"
            value={subject}
            onChange={(e) => setSubject(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Subject', subject, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Message</SpH5></label>
          <TextArea
            rows={4}
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('message', message, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            placeholder="Email"
            value={emailId}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          {
            !phoneNumber ? (
              <SpError>
                {simpleValidator.current.message('email', emailId, 'required|email|checkLowerCase')}
              </SpError>
            ) : ''
          }
        </div>

        <div>
          <label htmlFor=""><SpH5>Phone Number</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                defaultValue={countryCode}
                onChange={onCountryCodeChange}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <NumberFormat
                className="InputnumFormate"
                value={phoneNumber}
                format={phoneFormate}
                mask="_"
                allowEmptyFormatting
                onChange={onPhoneNumberChange}
              />
              {
                !emailId ? (
                  <SpError>
                    {simpleValidator.current.message('phone number', phoneNumber, 'required|phone')}
                  </SpError>
                ) : ''
              }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default NotifyPaymentPage;
