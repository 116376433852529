// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Card,
  Radio,
  Button,
} from 'antd';
import {
  SpButton, SpText,
} from 'components/DesignKit';

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  newAccountType: Function,
  walletBanks: Array<Object>,
};

const AddNewAccount = (props: Props) => {
  const {
    visible,
    close,
    submitting,
    newAccountType,
    walletBanks,
  } = props;

  const [accountType, setAccountType] = useState('');

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const selectedOption = (val) => {
    setAccountType(val);
  };

  const typeOfAccount = () => {
    newAccountType(accountType);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={typeOfAccount} disabled={disableButton}>Done</SpButton>,
      ]}
      title="Manage Accounts"
    >
      <Card>
        <SpText fontWeight="600" fontSize="14px" color="#292929">Added Accounts</SpText>
        <div className="px-4 mt-2 bank-accounts">
          {
            walletBanks.length !== 0 ? (
              <div>
                {
                  walletBanks.map((option) => (
                    <div className="mt-2">
                      <div>
                        <SpText fontWeight="400" fontSize="14px" color="#292929">{option.bankName}</SpText>
                      </div>
                      <div>
                        <SpText fontWeight="400" fontSize="14px" color="#8183A6">Routing No: {option.routingNumber}</SpText>
                      </div>
                    </div>
                  ))
                }
              </div>
            ) : (
              <SpText fontWeight="400" fontSize="14px" color="#292929">No Bank Account added</SpText>
            )
          }
        </div>
      </Card>
      <Card className="my-2">
        <SpText fontWeight="600" fontSize="14px" color="#292929">Add Bank Account</SpText>
        <div className="px-4 mt-2">

          <div className="pb-3">

            <div className="d-flex">

              <Radio.Group defaultValue="Plaid" onChange={selectedOption}>
                <Radio value="Plaid">Login using Plaid</Radio>
                <Radio value="Account">Account & Routing Number</Radio>
              </Radio.Group>
            </div>
            <Button
              type="secondary"
              shape="round"
              className="w-100 mt-2"
              onClick={typeOfAccount}

            >
              Add
            </Button>
          </div>
        </div>
      </Card>

    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddNewAccount);
