import React, { useEffect, useState } from 'react';
import {
  useRouteMatch,
  withRouter,
} from 'react-router-dom';

import { Menu } from 'antd';
import queryString from 'query-string';

import { SpTab } from 'components/DesignKit';
import DisputesList from './DisputesList';

type Props = {
  location: {
    pathname: string,
    search: string,
  },
  history: {
    push: Function,
    replace: Function,
  },
};

const Disputes = (props: Props) => {
  const [current, setCurrent] = useState('response');
  const { path } = useRouteMatch();
  const {
    location,
    history,
  } = props;

  const setMenu = (query) => {
    const parsed = queryString.parse(query);
    if (parsed['status.EQ']) {
      const value = parsed['status.EQ'];
      switch (value) {
        case 'NEEDS_RESPONSE': {
          setCurrent('response');
          break;
        }
        case 'WON': {
          setCurrent('won');
          break;
        }
        case 'LOST': {
          setCurrent('lost');
          break;
        }
        case 'UNDER_REVIEW': {
          setCurrent('review');
          break;
        }
        case 'ALL_DISPUTES': {
          setCurrent('disputes');
          break;
        }
        default:
          setCurrent('disputes');
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      setMenu(location.search);
    } else {
      history.replace(`${path}?status.EQ=NEEDS_RESPONSE`);
    }
  }, [location]);

  return (
    <div>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="response">
          <SpTab
            to={`${path}?status.EQ=NEEDS_RESPONSE`}
            selected={current === 'response'}
          >
            Needs Response
          </SpTab>
        </Menu.Item>
        <Menu.Item key="won">
          <SpTab
            to={`${path}?status.EQ=WON`}
            selected={current === 'won'}
          >
            Won
          </SpTab>
        </Menu.Item>
        <Menu.Item key="lost">
          <SpTab
            to={`${path}?status.EQ=LOST`}
            selected={current === 'lost'}
          >
            Lost
          </SpTab>
        </Menu.Item>
        <Menu.Item key="review">
          <SpTab
            to={`${path}?status.EQ=UNDER_REVIEW`}
            selected={current === 'review'}
          >
            Under Review
          </SpTab>
        </Menu.Item>
        <Menu.Item key="disputes">
          <SpTab
            to={`${path}?status.EQ=ALL_DISPUTES`}
            selected={current === 'disputes'}
          >
            All Disputes
          </SpTab>
        </Menu.Item>
      </Menu>
      <DisputesList {...props} />
    </div>
  );
};

export default withRouter(Disputes);
