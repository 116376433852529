import { call, put } from 'redux-saga/effects';

import {
  axiosNoAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  TRANSFER_RECEIPTS,
  LOADING,
} from '../actionTypes';

const fetchTransferReceipts = async gid => axiosNoAuthInstance.get(`${API_END_POINTS.TRANSFER_RECEIPTS}/${gid}`);

export function* getTransferReceipts(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchTransferReceipts, action.payload);
    yield put({ type: TRANSFER_RECEIPTS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: TRANSFER_RECEIPTS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
