import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import PaymentPagesList from './components/PaymentPagesList';
import PaymentPagesDetails from './components/PaymentPagesDetails';

const PaymentPages = () => (
  <Switch>
    <Route exact path="/payment-pages" component={PaymentPagesList} />
    <Route exact path="/payment-links/:gid" component={PaymentPagesDetails} />
  </Switch>
);

export default PaymentPages;
