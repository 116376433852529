// @flow
import React from 'react';
import {
  SpText,
  SpThanksMessgae,
} from 'components/PaymentWidgetDesignKit';

type Props = {
  widgetDetails: Object,
};

const ThanksInfo = (props: Props) => {
  const {
    widgetDetails,
  } = props;

  const msg = 'Here you can thank your payers for the payments.';
  return (
    <>
      <div style={{ backgroundColor: '#FAFAFC', paddingTop: '30px' }}>
        <SpText
          fontSize="25px"
          color="#5F5F65"
          fontWeight="600"
        >
          Thank You
        </SpText>
        <div className="mt-2">
          <hr style={{
            width: '20%',
            textAlign: 'center',
            height: '6px',
            backgroundColor: `${widgetDetails.primaryColor}`,
            marginLeft: '40%',
          }}
          />
        </div>
      </div>
      <SpThanksMessgae className="mt-5">
        <SpText fontSize="14px">{widgetDetails.thankYouMessage ? widgetDetails.thankYouMessage : msg}</SpText>
      </SpThanksMessgae>
    </>
  );
};

// $FlowFixMe
export default ThanksInfo;
