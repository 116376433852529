/* eslint-disable */
import React from 'react';

const settings = ({ fill = "silver", width = "13", height = "13"}) => (
  <svg width={width} height={height} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.56251 8.0547C7.42114 8.0547 8.1172 7.35864 8.1172 6.50001C8.1172 5.64137 7.42114 4.94531 6.56251 4.94531C5.70387 4.94531 5.00781 5.64137 5.00781 6.50001C5.00781 7.35864 5.70387 8.0547 6.56251 8.0547Z" fill={fill} />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.35 0.5V2.0337L9.43449 0.949212C9.62976 0.75395 9.94634 0.75395 10.1416 0.949212L12.0508 2.8584C12.246 3.05366 12.2461 3.37024 12.0508 3.56551L10.9663 4.65H12.5C12.7761 4.65 13 4.87386 13 5.15V7.85C13 8.12614 12.7761 8.35 12.5 8.35H10.9663L12.0508 9.43449C12.246 9.62976 12.2461 9.94634 12.0508 10.1416L10.1416 12.0508C9.94634 12.246 9.62976 12.2461 9.43449 12.0508L8.35 10.9663V12.5C8.35 12.7761 8.12614 13 7.85 13H5.15C4.87386 13 4.65 12.7761 4.65 12.5V10.9663L3.56551 12.0508C3.37025 12.246 3.05366 12.246 2.8584 12.0508L0.949212 10.1416C0.75395 9.94634 0.75395 9.62976 0.949212 9.43449L2.03371 8.35H0.5C0.223858 8.35 0 8.12614 0 7.85V5.15C0 4.87386 0.223858 4.65 0.5 4.65H2.03371L0.949213 3.56551C0.753951 3.37024 0.75395 3.05366 0.949213 2.8584L2.8584 0.949212C3.05366 0.75395 3.37025 0.75395 3.56551 0.949211L4.65 2.0337V0.5C4.65 0.223858 4.87386 0 5.15 0H7.85C8.12614 0 8.35 0.223858 8.35 0.5ZM8.74289 3.47929C8.25432 3.12602 7.67665 2.88837 7.05 2.80646V1.3H5.95V2.82496C5.34088 2.92572 4.78163 3.17426 4.31033 3.53251L3.21195 2.43414L2.43414 3.21195L3.54179 4.31961C3.20029 4.79189 2.96684 5.34744 2.87767 5.95H1.3L1.3 7.05H2.87767C2.96684 7.65256 3.20029 8.20811 3.54179 8.68039L2.43414 9.78805L3.21195 10.5659L4.31033 9.46749C4.78163 9.82574 5.34088 10.0743 5.95 10.175V11.7H7.05V10.1935C7.67665 10.1116 8.25432 9.87398 8.74289 9.52071L9.78805 10.5659L10.5659 9.78805L9.52999 8.75217C9.90046 8.26479 10.1536 7.68337 10.2473 7.05H11.7V5.95H10.2473C10.1536 5.31663 9.90046 4.73521 9.52999 4.24783L10.5659 3.21195L9.78805 2.43414L8.74289 3.47929Z" fill={fill} />
  </svg>

);

export default settings;
