import { call, put } from 'redux-saga/effects';

import {
  axiosQuickBooksAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  QUICKBOOKSDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchQuickBookDetails = async () => axiosQuickBooksAuthInstance.get(API_END_POINTS.QUICKBOOKS_DETAILS);

export function* getQuickBookDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchQuickBookDetails, action.payload);
    yield put({ type: QUICKBOOKSDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: QUICKBOOKSDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
