import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  Row,
  Col,
  Layout,
  Card,
} from 'antd';
import {
  paymentWidgetsActions,
  paymentWidgetsDetailActions,
} from 'store/actions';
import {
  PAGES,
} from 'appconstants';
import CONSTANTS from 'store/constants';
import PaymentClose from 'components/NavIcons/paymentClose';

import WidgetDetails from './components/widgetDetails';
import ReviewDetails from './components/reviewDetails';

const { Header } = Layout;

type Props = {
  history: {
    push: Function,
  },
  updatePaymentWidget: Function,
  addPaymentWidget: Function,
  validator: boolean,
  setSampleWidget: Function,
  selectedAccount: object,
  fetchPaymentWidgetDetails: Function,
  paymentWidgetsDetails: Object,
  paymentWidgetDetailsStatus: string,
  setCurrentWidget: Function,
};

const AddPaymentWidget = (props: Props) => {
  const {
    history,
    updatePaymentWidget,
    addPaymentWidget,
    validator,
    setSampleWidget,
    selectedAccount,
    fetchPaymentWidgetDetails,
    paymentWidgetsDetails,
    paymentWidgetDetailsStatus,
    setCurrentWidget,
  } = props;

  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const { name } = selectedAccount.currency;
  const [redirect, setRedirect] = useState(false);
  const [updateWidget, setUpdateWidget] = useState(false);
  const addPayload = {
    currencyCode: name,
    payButtonLabel: 'Pay',
    title: '',
    primaryColor: '#00C6BA',
    intervals: [
      'ONETIME',
      'WEEK',
      'MONTH',
      'YEAR',
    ],
    defaultInterval: 'ONETIME',
    recommendedInterval: 'MONTH',
    actionMessage: '',
    thankYouMessage: '',
    termsAndServices: '',
    amounts: [
      null,
    ],
    showCustomAmount: false,
    minAmount: null,
    maxAmount: null,
    companyDonation: false,
    needEmploymentStatus: false,
    widgetType: 'ADD',
    // serviceFeeEnabled: false,
    // serviceFeeRate: null,
    // serviceFee: null,
  };

  useEffect(() => {
    setCurrentWidget(0);
    setSampleWidget(addPayload);
  }, []);

  useEffect(() => {
    const widgetId = parsed['WIDGET.ID'];
    if (widgetId && widgetId.split('-')[1]) {
      fetchPaymentWidgetDetails(widgetId);
    }
  }, []);

  useEffect(() => {
    const widgetId = parsed['WIDGET.ID'];
    if (widgetId && paymentWidgetsDetails.gid && paymentWidgetDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED) {
      const payload = {
        currencyCode: name,
        payButtonLabel: paymentWidgetsDetails.payButtonLabel,
        title: paymentWidgetsDetails.title,
        primaryColor: paymentWidgetsDetails.primaryColor,
        intervals: paymentWidgetsDetails.intervals,
        defaultInterval: paymentWidgetsDetails.defaultInterval,
        recommendedInterval: paymentWidgetsDetails.recommendedInterval,
        actionMessage: paymentWidgetsDetails.actionMessage,
        thankYouMessage: paymentWidgetsDetails.thankYouMessage,
        termsAndServices: paymentWidgetsDetails.termsAndServices,
        amounts: paymentWidgetsDetails.amounts,
        showCustomAmount: paymentWidgetsDetails.showCustomAmount,
        minAmount: paymentWidgetsDetails.minAmount,
        maxAmount: paymentWidgetsDetails.maxAmount,
        companyDonation: paymentWidgetsDetails.companyDonation,
        needEmploymentStatus: paymentWidgetsDetails.needEmploymentStatus,
        widgetType: 'EDIT',
        // serviceFeeEnabled: paymentWidgetsDetails.serviceFeeEnabled,
        // serviceFeeRate: paymentWidgetsDetails.serviceFeeRate,
        // serviceFee: paymentWidgetsDetails.serviceFee,
      };
      setUpdateWidget(true);
      setSampleWidget(payload);
    } else {
      setUpdateWidget(false);
      setSampleWidget(addPayload);
    }
  }, [paymentWidgetsDetails]);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.PAYMENTWIDGETS);
    }
  }, [validator]);

  const addWidget = (params, gid) => {
    if (updateWidget && gid) {
      updatePaymentWidget({
        gid,
        params,
      });
    } else if (!updateWidget) {
      addPaymentWidget(params);
    }
    setRedirect(true);
  };

  return (
    <>
      <Layout style={{ marginLeft: '0px', minHeight: '0px' }}>
        <Header style={{ color: '#fff', fontSize: '20px', background: '#18294c !important' }}>
          <Row type="flex" justify="space-between">
            <Col className="align-items-center">
              Create Payment Widget
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
              <span style={{ cursor: 'pointer' }} onClick={() => history.push(PAGES.PAYMENTWIDGETS)}>
                <PaymentClose />
              </span>
            </Col>
          </Row>
        </Header>
        <Card>
          <Row type="flex">
            <Col xs={24} lg={10}>
              <WidgetDetails
                history={history}
                addWidget={addWidget}
              />
            </Col>
            <Col xs={24} lg={14}>
              <ReviewDetails />
            </Col>
          </Row>
        </Card>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.loading.submitting,
  currentWidget: state.paymentWidgets.currentWidget,
  sampleWidget: state.paymentWidgets.sampleWidget,
  validator: state.loading.validator,
  paymentWidgetsDetails: state.paymentWidgetsDetails,
  paymentWidgetDetailsStatus: state.paymentWidgetsDetails.paymentWidgetDetailsStatus,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentWidget: param => dispatch(paymentWidgetsActions.setCurrentWidget({
    payload: param,
  })),
  setSampleWidget: param => dispatch(paymentWidgetsActions.setSampleWidget({
    payload: param,
  })),
  addPaymentWidget: param => dispatch(paymentWidgetsActions.addPaymentWidget({
    payload: param,
  })),
  fetchPaymentWidgetDetails: gid => dispatch(paymentWidgetsDetailActions.fetchPaymentWidgetDetails({
    payload: gid,
  })),
  updatePaymentWidget: param => dispatch(paymentWidgetsDetailActions.updatePaymentWidget({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentWidget);
