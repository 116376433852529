import React from 'react';
import {
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { createGlobalStyle } from 'styled-components';

import ProtectedRoute from 'components/ProtectedRoute';
import BasePage from 'components/BasePage';
import 'utils/moment-timezone';
import routes from './config/routes';

const GlobalStyle = createGlobalStyle`
  html {
  };
  body {
    font-family: Open Sans;
    font-size: 14px;
  }
`;

const App = () => (
  <BasePage>
    <GlobalStyle />
    <Sentry.ErrorBoundary fallback="An error has occurred">
      <Switch>
        {routes.map((route) => {
          if (route.protected) {
            return (
              <ProtectedRoute
                key={route.key}
                path={route.path}
                exact={route.exact}
                proteced={route.protected}
                component={route.component}
              />
            );
          }
          return (
            <Route
              key={route.key}
              path={route.path}
              exact={route.exact}
              proteced={route.protected}
              render={(props) => (
                <route.component {...props} />
              )}
            />
          );
        })}
      </Switch>
    </Sentry.ErrorBoundary>
  </BasePage>
);

export default withRouter(App);
