// @flow
import React, {
  useState, useRef, useImperativeHandle, forwardRef,
} from 'react';
import {
  Row,
  Col,
  DatePicker,
} from 'antd';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpForm,
  SpError,
  SpText,
} from 'components/DesignKit';

type Props = {
  displayFormat: String,
  disableFields: Boolean,
}

const OfflineProduct = (props: Props, ref) => {
  const {
    displayFormat,
    disableFields,
  } = props;
  const [, forceUpdate] = useState();
  const [serviceDate, setServiceDate] = useState(displayFormat || '');

  const simpleValidator = useRef(new SimpleReactValidator());

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      serviceDate,
    }),
  }));

  return (
    <SpForm>
      <Row type="flex" justify="start" align="middle">
        <Col span={3}>
          <SpText>Service Date</SpText>
        </Col>
        <Col span={6}>
          <DatePicker
            defaultValue={serviceDate ? moment(serviceDate) : ''}
            allowClear={false}
            disabled={disableFields}
            onChange={(date, dateString) => setServiceDate(dateString)}
          />
          <SpError>
            {simpleValidator.current.message('Service date', serviceDate, 'required')}
          </SpError>
        </Col>
      </Row>
    </SpForm>
  );
};

// $FlowFixMe
export default forwardRef(OfflineProduct);
