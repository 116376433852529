import { PAYMENTPAGES } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  paymentPagesStatus: undefined,
  paymentPagesError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENTPAGES.FETCH:
      return {
        ...state,
        paymentPagesStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case PAYMENTPAGES.SUCCESS:
      return {
        ...state,
        ...action.payload,
        paymentPagesStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case PAYMENTPAGES.FAILED:
      return {
        ...state,
        paymentPagesError: action.payload,
        paymentPagesStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
