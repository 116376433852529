import { PAYMENTPAGES } from '../actionTypes';

export const fetchPaymentPages = (action) => ({
  type: PAYMENTPAGES.FETCH,
  payload: action.payload,
});

export const addPaymentPages = (action) => ({
  type: PAYMENTPAGES.ADD,
  payload: action.payload,
});
