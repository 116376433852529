import { INVOICEDETAILS } from '../actionTypes';

const initialState = {
  invoiceDetailsStatus: undefined,
  invoiceDetailsError: undefined,
  customersList: [],
  newCustomer: {},
  productsList: [],
  newProduct: {},
  couponsList: [],
  couponsItemList: [],
  newCoupon: {},
  taxrateList: [],
  newTaxrate: {},
  taxList: [],
  invoice: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INVOICEDETAILS.FETCH:
      return {
        ...state,
        invoiceDetailsStatus: 'STARTED',
      };
    case INVOICEDETAILS.SUCCESS:
      return {
        ...state,
        invoice: action.payload,
        invoiceDetailsStatus: 'SUCCESS',
      };
    case INVOICEDETAILS.FAILED:
      return {
        ...state,
        invoiceDetailsError: action.payload,
        invoiceDetailsStatus: 'FAILED',
      };
    case INVOICEDETAILS.CUSTOMERS_SUCCESS:
      return {
        ...state,
        customersList: action.payload,
      };
    case INVOICEDETAILS.CUSTOMER_ADD_SUCCESS:
      return {
        ...state,
        newCustomer: action.payload,
      };
    case INVOICEDETAILS.PRODUCTS_SUCCESS:
      return {
        ...state,
        productsList: action.payload,
      };
    case INVOICEDETAILS.PRODUCT_ADD_SUCCESS:
      return {
        ...state,
        newProduct: action.payload,
      };
    case INVOICEDETAILS.COUPON_SUCCESS:
      return {
        ...state,
        couponsList: action.payload,
      };
    case INVOICEDETAILS.ITEMCOUPON_SUCCESS:
      return {
        ...state,
        couponsItemList: action.payload,
      };
    case INVOICEDETAILS.COUPON_ADD_SUCCESS:
      return {
        ...state,
        newCoupon: action.payload,
      };
    case INVOICEDETAILS.TAX_RATE_SUCCESS:
      return {
        ...state,
        taxrateList: action.payload,
      };
    case INVOICEDETAILS.TAX_RATE_ADD_SUCCESS:
      return {
        ...state,
        newTaxrate: action.payload,
      };
    case INVOICEDETAILS.TAXES_SUCCESS:
      return {
        ...state,
        taxList: action.payload,
      };
    default:
      return state;
  }
};
