import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { Menu } from 'antd';
import { SpTab } from 'components/DesignKit';

import Acquirer from './components/Acquirer';
import Issuer from './components/Issuer';

type Props = {
  location: {
    pathname: string
  },
};

const AdminAcquirer = (props: Props) => {
  const [current, setCurrent] = useState('acquirer');
  const { path } = useRouteMatch();
  const {
    location,
  } = props;

  const setMenu = (query) => {
    switch (query) {
      case '/acquirer': {
        setCurrent('acquirer');
        break;
      }
      case '/acquirer/issuer': {
        setCurrent('issuer');
        break;
      }
      default:
        setCurrent('acquirer');
    }
  };

  useEffect(() => {
    const { pathname } = location;
    setMenu(pathname);
  }, [location]);

  return (
    <div>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
        <Menu.Item key="acquirer">
          <SpTab
            to={path}
            selected={current === 'acquirer'}
          >
            Acquirer
          </SpTab>
        </Menu.Item>
        <Menu.Item key="issuer">
          <SpTab
            to={`${path}/issuer`}
            selected={current === 'issuer'}
          >
            Issuer
          </SpTab>
        </Menu.Item>
      </Menu>

      <Switch>
        <Route exact path={path} component={Acquirer} />
        <Route path={`${path}/issuer`} component={Issuer} />
      </Switch>
    </div>
  );
};

export default withRouter(AdminAcquirer);
