import { INVENTORY_MODIFIERS } from '../actionTypes';

const initialState = {
  content: [],
  sortModifierGroupList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_MODIFIERS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case INVENTORY_MODIFIERS.ORDER_MODIFIERS_SUCCESS:
      return {
        ...state,
        sortModifierGroupList: action.payload,
      };
    default:
      return state;
  }
};
