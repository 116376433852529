import { TRANSFERS } from '../actionTypes';

const initialState = {
  content: [],
  searchFundingList: [],
  contactList: [],
  fundingList: [],
  issuerGid: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRANSFERS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case TRANSFERS.CONTACT_SUCCESS:
      return {
        ...state,
        contactList: action.payload,
      };
    case TRANSFERS.FUNDING_SUCCESS:
      return {
        ...state,
        fundingList: action.payload,
      };
    case TRANSFERS.SEARCH_FUNDING_SUCCESS:
      return {
        ...state,
        searchFundingList: action.payload,
      };
    case TRANSFERS.ISSUETRGID:
      return {
        ...state,
        issuerGid: action.payload,
      };
    default:
      return state;
  }
};
