// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  description: String,
};

const VoidPayment = (props: Props) => {
  const {
    visible,
    close,
    submit,
    description,
  } = props;

  const deleteCustomer = () => {
    const status = 'VOID';
    submit({
      status,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={deleteCustomer} type="danger">Yes</SpButton>,
      ]}
      title="Void Payment"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to void this payment?</div>
        <SpH5 className="pb-3">
          <div>{description || <>&#8211;</>}</div>
        </SpH5>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default VoidPayment;
