import { TRANSFERSDETAILS } from '../actionTypes';

const initialState = {
  transferTimeline: [],
  transferDisputeDetails: [],
  transferDetailsStatus: undefined,
  transferDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRANSFERSDETAILS.FETCH:
      return {
        ...state,
        transferDetailsStatus: 'STARTED',
      };
    case TRANSFERSDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        transferDetailsStatus: 'SUCCESS',
      };
    case TRANSFERSDETAILS.FAILED:
      return {
        ...state,
        transferDetailsError: action.payload,
        transferDetailsStatus: 'FAILED',
      };
    case TRANSFERSDETAILS.TIMELINE_SUCCESS:
      return {
        ...state,
        transferTimeline: action.payload,
      };
    default:
      return state;
  }
};
