import { CLOVER_WEBHOOK } from '../actionTypes';

const initialState = {
  content: [],
  cloverWebhookStatus: undefined,
  cloverWebhookError: undefined,
  cloverInternalCredentials: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLOVER_WEBHOOK.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case CLOVER_WEBHOOK.FETCH:
      return {
        ...state,
        cloverWebhookStatus: 'STARTED',
      };
    case CLOVER_WEBHOOK.FAILED:
      return {
        ...state,
        cloverWebhookError: action.payload,
        cloverWebhookStatus: 'FAILED',
      };
    case CLOVER_WEBHOOK.INTERNAL_SUCCESS:
      return {
        ...state,
        cloverInternalCredentials: action.payload,
      };
    default:
      return state;
  }
};
