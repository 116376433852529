// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Icon,
  Switch,
} from 'antd';
import {
  SpButton,
} from 'components/DesignKit';

import Loading from 'components/Loading';

import {
  notificationActions,
  filterParamsActions,
  menuActions,
  inventoryCategoriesActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
} from 'appconstants';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import RBAC from 'components/rbac';
import AddMenu from './components/AddMenu';
import DeleteMenu from './components/DeleteMenu';
import EditMenu from './components/EditMenu';

type Props = {
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  history: {
    push: Function,
  },
  setFilterData: Function,
  preserveFilters: Object,
  submitting: boolean,
  validator: boolean,
  fetchInventoryMenus: Function,
  menus: Array<Object>,
  inventoryCategories: Array,
  fetchInventoryCategoriesWithItems: Function,
  fetchMenusOperatingHours: Function,
  menuOperatingHours: Array,
  addInventoryMenu: Function,
  deleteInventoryMenus: Function,
  updateInventoryMenus: Function,
};

const MenuList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    loading,
    totalElements,
    test,
    history,
    preserveFilters,
    setFilterData,
    submitting,
    validator,
    fetchInventoryMenus,
    menus,
    inventoryCategories,
    fetchInventoryCategoriesWithItems,
    fetchMenusOperatingHours,
    menuOperatingHours,
    addInventoryMenu,
    deleteInventoryMenus,
    updateInventoryMenus,
  } = props;

  const [pagination, setPagination] = useState({});
  const { pathname } = location;
  const myRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterPayments: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });
  const [addMenuModal, setAddMenuModal] = useState(false);
  const [deleteMenuModal, setDeleteMenuModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('');
  const [editMenuModal, setEditMenuModal] = useState(false);
  const [prevPageNum, setPrevPageNum] = useState(1);
  const kdsRole = localStorage.getItem('kdsRole');
  const [sorting, setSorting] = useState({
    field: 'name',
    order: 'ascend',
  });

  const debouncedFetchInventoryMenus = debounce(fetchInventoryMenus, 2000, { leading: true });
  const debouncedFetchInventoryCategories = debounce(fetchInventoryCategoriesWithItems, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    // eslint-disable-next-line no-unused-vars
    const sortParam = '&sortBy=sortOrder&direction=ASC';
    const filterParam = parsed.filterParams || '';
    const catForMenu = true;
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchInventoryMenus({ currentPage, sortParams, filterParams });
    debouncedFetchInventoryCategories({
      currentPage,
      sortParams,
      filterParams,
      catForMenu,
    });
    fetchMenusOperatingHours();
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [menus]);

  useEffect(() => {
    if (!validator) {
      setAddMenuModal(false);
      setDeleteMenuModal(false);
      setEditMenuModal(false);
      getData(location.search);
    }
  }, [validator]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addSelectedItem = (menu: Object) => {
    const {
      name,
      categories,
      operatingHours,
      isItemAvailable,
    } = menu;

    const parsed = queryString.parse(location.search);
    const currentPage = 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const param = {
      name,
      operationalHoursMasterGid: operatingHours,
      categoryGids: categories,
      available: isItemAvailable,
    };
    addInventoryMenu({
      param,
      currentPage,
      sortParams,
    });
  };

  const deleteSelectedMenu = (selectedItem: String) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    deleteInventoryMenus(
      {
        gid: selectedItem,
        currentPage,
        sortParams,
      },
    );
  };

  const editSelectedMenu = (menu: Object) => {
    const {
      name,
      categories,
      operatingHours,
      selectedGid,
      isItemAvailable,
    } = menu;
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const param = {
      name,
      operationalHoursMasterGid: operatingHours,
      categoryGids: categories,
      available: isItemAvailable,
    };
    updateInventoryMenus({
      gid: selectedGid,
      param,
      currentPage,
      sortParams,
    });
  };

  const updateSelectedMenu = (e, menu) => {
    const {
      name,
      categories,
      operatingHoursMaster,
      gid,
    } = menu;
    const param = {
      name,
      operationalHoursMasterGid: operatingHoursMaster && operatingHoursMaster.gid ? operatingHoursMaster.gid : null,
      categoryGids: (categories && categories.length !== 0)
        ? categories.map((option) => option.gid) : [],
      available: e,
    };
    updateInventoryMenus({
      gid,
      param,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    setPrevPageNum(Number(page.current));
    const tempPage = page.current;
    let tempParams = '';
    // if (sorting.order === 'ascend') {
    //   setSorting({
    //     field: 'sortOrder',
    //     order: 'descend',
    //   });
    // } else {
    //   setSorting({
    //     field: 'sortOrder',
    //     order: 'ascend',
    //   });
    // }
    setSorting({
      field: filters.field,
      order: filters.order,
    });
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.MENUS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.MENUS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  //   const selectedRecord = (record) => {
  //     const itemGid = record.gid;
  //     history.push({
  //       pathname: `${PAGES.MENUS}/${itemGid}`,
  //       state: location.search,
  //     });
  //   };


  const columns = [
    {
      title: 'MENU NAME',
      dataIndex: 'name',
      width: '15%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (name) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'categories',
      width: '50%',
      align: 'center',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const categories = record && record.categories ? record.categories : [];
        return (
          <span>
            {
                categories.map((item) => (
                  <span>{item.name},&nbsp;</span>
                ))
            }
          </span>
        );
      },
    },
    {
      title: 'OPERATING HOURS',
      dataIndex: 'operatingHoursMaster',
      width: '20%',
      align: 'center',
      sorter: false,
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (text, record) => {
        const operatingHours = record && record.operatingHoursMaster ? record.operatingHoursMaster : '';
        return (
          <span>{operatingHours.name || <>&#8211;</>}</span>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '15%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); setSelectedMenu(record); setEditMenuModal(true); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            className="ml-3 mr-3"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); setSelectedMenu(record); setDeleteMenuModal(true); }}
          />
        </Tooltip>
        <Switch
          checked={record && record.available}
          onChange={(e) => updateSelectedMenu(e, record)}
        />
      </span>
    ),
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC
            permit={
              [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS,
                ROLES.SUPERUSER, ROLES.KDSUSER1, ROLES.KDSADMIN1]
            }
          >
            <Col>
              {
                kdsRole !== 'KDS-USER' && (
                  <>
                    <SpButton
                      type="secondary"
                      shape="round"
                      ghost
                      className="mr-3"
                      onClick={() => setAddMenuModal(true)}
                    >
                      {'\u002B'}&nbsp;Add Menu
                    </SpButton>
                  </>
                )
              }
              {/* <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Modifers">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Modifers Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span> */}
              {/* <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Modifiers">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-265px' }}
                  >
                    <Filters
                      filtersGrid={filterPayments}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                      handleFromValue={handleFromValue}
                      handleToValue={handleToValue}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterPayments.length < PAYMENTLINKS_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span> */}
            </Col>
          </RBAC>
        </div>
      </Row>
      {/* DELETE MENU MODAL */}
      {editMenuModal && (
        <EditMenu
          visible={editMenuModal}
          submitting={submitting}
          selectedAccount={selectedAccount}
          close={() => setEditMenuModal(false)}
          submit={editSelectedMenu}
          selectedMenu={selectedMenu}
          inventoryCategories={inventoryCategories}
          menuOperatingHours={menuOperatingHours}
        />
      )}
      {/* DELETE MENU MODAL */}
      {deleteMenuModal && (
        <DeleteMenu
          visible={deleteMenuModal}
          submitting={submitting}
          selectedAccount={selectedAccount}
          close={() => setDeleteMenuModal(false)}
          submit={deleteSelectedMenu}
          selectedMenu={selectedMenu}
        />
      )}
      {/* ADD MENU MODAL */}
      {addMenuModal && (
        <AddMenu
          visible={addMenuModal}
          submitting={submitting}
          selectedAccount={selectedAccount}
          close={() => setAddMenuModal(false)}
          submit={addSelectedItem}
          inventoryCategories={inventoryCategories}
          menuOperatingHours={menuOperatingHours}
        />
      )}
      <RBAC
        fallback={(
          <Table
            columns={columns.map(column => {
              if (column.dataIndex === sorting.field) {
                return {
                  ...column,
                  sortOrder: sorting.order,
                };
              }
              return column; // Return the original column if it doesn't match the condition
            }).concat(actionColumn)}
            rowKey={(record) => record.gid}
            dataSource={menus}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
      >
        <Table
          rowClassName={() => 'ant-table-clickable-row'}
          columns={columns.map(column => {
            if (column.dataIndex === sorting.field) {
              return {
                ...column,
                sortOrder: sorting.order,
              };
            }
            return column; // Return the original column if it doesn't match the condition
          }).concat(actionColumn)}
          rowKey={(record) => record.gid}
          dataSource={menus}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  inventoryModifiers: state.inventoryModifiers.content,
  menus: state.menus.content,
  totalElements: state.menus.totalElements,
  inventoryCategories: state.inventoryCategories.categoryItems,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  menuOperatingHours: state.menus.menuOperatingHours.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryMenus: param => dispatch(menuActions.fetchInventoryMenus({
    payload: param,
  })),
  fetchMenusOperatingHours: param => dispatch(menuActions.fetchMenusOperatingHours({
    payload: param,
  })),
  addInventoryMenu: param => dispatch(menuActions.addInventoryMenu({
    payload: param,
  })),
  deleteInventoryMenus: param => dispatch(menuActions.deleteInventoryMenus({
    payload: param,
  })),
  updateInventoryMenus: param => dispatch(menuActions.updateInventoryMenus({
    payload: param,
  })),
  fetchInventoryCategoriesWithItems: param => dispatch(inventoryCategoriesActions.fetchInventoryCategoriesWithItems({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(MenuList);
