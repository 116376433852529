import { DISPUTEEVIDENCE } from '../actionTypes';

export const setEvidenceSelected = (action) => ({
  type: DISPUTEEVIDENCE.SELECTED_EVIDENCES,
  payload: action.payload,
});

export const submitDisputeEvidence = (action) => ({
  type: DISPUTEEVIDENCE.SUBMIT_EVIDENCE,
  payload: action.payload,
});
