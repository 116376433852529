import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  RATES,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchRates = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`/internal${API_END_POINTS.RATES}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`/internal${API_END_POINTS.RATES}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`/internal${API_END_POINTS.RATES}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`/internal${API_END_POINTS.RATES}${query}${sorting}`);
};

const postRate = async payload => axiosAuthInstance.post(`/internal${API_END_POINTS.RATES}`, payload);

const fetchRateAcquirer = async (payload) => {
  const query = '?page=1&size=1000';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=DESC';
  return axiosAuthInstance.get(`/internal${API_END_POINTS.ACQUIRER}${query}${sorting}`);
};

const fetchRateCategory = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.CATEGORY}${query}${sorting}`);
};

const fetchPaymentType = async (payload) => {
  const query = '?page=1&size=200';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.PAYMENT_TYPE}${query}${sorting}`);
};

export function* getRates(action) {
  try {
    const { data: { entity } } = yield call(fetchRates, action.payload);
    yield put({ type: RATES.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: RATES.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addRate(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postRate, action.payload);
    const { data: { entity } } = yield call(fetchRates, action.payload);
    yield put({ type: RATES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.RATES.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getRateAcquirer(action) {
  try {
    const { data: { entity } } = yield call(fetchRateAcquirer, action.payload);
    yield put({ type: RATES.ACQUIRER_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: RATES.FAILED, payload: e.response.data });
  }
}

export function* getRateCategory(action) {
  try {
    const { data: { entity } } = yield call(fetchRateCategory, action.payload);
    yield put({ type: RATES.CATEGORY_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: RATES.FAILED, payload: e.response.data });
  }
}

export function* getPaymentType(action) {
  try {
    const { data: { entity } } = yield call(fetchPaymentType, action.payload);
    yield put({ type: RATES.PAYMENTTYPE_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: RATES.FAILED, payload: e.response.data });
  }
}
