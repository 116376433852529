import React from 'react';
import { Col, Row } from 'antd';
import {
  SpText,
  SpH5,
  SpForm,
} from 'components/DesignKit';

type Props = {
  bank: Object,
  bankDetails: Object,
  paymentGid: String,
}
const Ach = (props: Props) => {
  const {
    bank,
    paymentGid,
    bankDetails,
  } = props;

  if (bank) {
    return (
      <Col span={6}>
        <div>
          <span>Payment Method</span>
        </div>
        <div>
          <SpText fontSize="18px" fontWeight="600">
            {bank.bankName}
            &nbsp;&nbsp;****{bank.lastFour}
          </SpText>
        </div>
      </Col>
    );
  }
  if (bankDetails) {
    return (
      <SpForm>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Account Holder</span>
          </Col>
          <Col span={8}>
            <SpH5>{(bankDetails && bankDetails.name) ? bankDetails.name : <>&#8211;</>}</SpH5>
          </Col>
          <Col span={4}>
            <span>ID</span>
          </Col>
          <Col span={8}>
            <SpH5>{(bankDetails && bankDetails.gid) ? bankDetails.gid : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Account Number</span>
          </Col>
          <Col span={8}>
            <SpH5>{(bankDetails && bankDetails.lastFour) ? `****${bankDetails.lastFour}` : <>&#8211;</>}</SpH5>
          </Col>
          <Col span={4}>
            <span>Routing Number</span>
          </Col>
          <Col span={8}>
            <SpH5>{(bankDetails && bankDetails.routingNumber) ? bankDetails.routingNumber : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Bank Name</span>
          </Col>
          <Col span={8}>
            <SpH5>{(bankDetails && bankDetails.bankName) ? bankDetails.bankName : <>&#8211;</>}</SpH5>
          </Col>
          <Col span={24} className="d-flex justify-content-end">
            <SpH5 color="#7f7f7f">{paymentGid}</SpH5>
          </Col>
        </Row>
      </SpForm>
    );
  } return null;
};

export default Ach;
