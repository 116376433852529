import { TRANSFERSDETAILS } from '../actionTypes';

export const fetchTransferDetails = (action) => ({
  type: TRANSFERSDETAILS.FETCH,
  payload: action.payload,
});

export const fetchTransferTimeline = (action) => ({
  type: TRANSFERSDETAILS.TIMELINE_FETCH,
  payload: action.payload,
});

export const notifyTransferDetails = (action) => ({
  type: TRANSFERSDETAILS.NOTIFY,
  payload: action.payload,
});

export const resendOtp = (action) => ({
  type: TRANSFERSDETAILS.RESENDOTP,
  payload: action.payload,
});
