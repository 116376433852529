import { MEMBERSHIP } from '../actionTypes';

export const fetchMembership = action => ({
  type: MEMBERSHIP.FETCH,
  payload: action.payload,
});

export const addMembership = (action) => ({
  type: MEMBERSHIP.ADD,
  payload: action.payload,
});

export const updateMembership = (action) => ({
  type: MEMBERSHIP.UPDATE,
  payload: action.payload,
});

export const deleteMembership = (action) => ({
  type: MEMBERSHIP.DELETE,
  payload: action.payload,
});

export const setSelectedUser = (action) => ({
  type: MEMBERSHIP.SELECTED,
  payload: action.payload,
});
