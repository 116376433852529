import { VENDORS } from '../actionTypes';

export const fetchVendors = action => ({
  type: VENDORS.FETCH,
  payload: action.payload,
});

export const addVendor = (action) => ({
  type: VENDORS.ADD,
  payload: action.payload,
});

export const updateVendor = (action) => ({
  type: VENDORS.UPDATE,
  payload: action.payload,
});

export const deleteVendor = (action) => ({
  type: VENDORS.DELETE,
  payload: action.payload,
});

export const fetchCustomTypes = action => ({
  type: VENDORS.FETCH_CUSTOM_TYPES,
  payload: action.payload,
});

export const deleteSingleCustomType = (action) => ({
  type: VENDORS.DELETE_SINGLE_CUSTOM_TYPE,
  payload: action.payload,
});

export const deleteAllCustomTypes = (action) => ({
  type: VENDORS.DELETE_ALL_CUSTOM_TYPES,
  payload: action.payload,
});

export const multiSendFile = (action) => ({
  type: VENDORS.MULTI_SEND,
  payload: action.payload,
});
