import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

type Props = {
  startTime: any,
  selectedAccount: Object,
};

const TimerComponent = (props: Props) => {
  const { startTime, selectedAccount } = props;
  const [timer, setTimer] = useState('');

  useEffect(() => {
    const updateTimer = () => {
      const currentTime = moment.utc().tz(selectedAccount && selectedAccount.timezone);
      const givenTime = moment.utc(startTime).tz(selectedAccount && selectedAccount.timezone);
      const difference = moment.duration(currentTime.diff(givenTime));
      // eslint-disable-next-line max-len
      const formattedTimer = `${String(difference.hours()).padStart(2, '0')}:${String(difference.minutes()).padStart(2, '0')}:${String(difference.seconds()).padStart(2, '0')}`;
      setTimer(formattedTimer);
    };
    updateTimer();
    const intervalId = setInterval(updateTimer, 1000);
    return () => clearInterval(intervalId);
  }, [startTime, selectedAccount]);

  return <>{timer}</>;
};

export default TimerComponent;
