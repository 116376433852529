// @flow
import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Row,
  Col,
  Input,
  Divider,
} from 'antd';
import {
  SpError,
} from 'components/DesignKit';
import {
  SpText,
} from 'components/PaymentWidgetDesignKit';
import SimpleReactValidator from 'simple-react-validator';

const { TextArea } = Input;

type Props = {
  widgetDetails: Object,
  setSampleWidget: Function,
  paymentWidgetsDetails: Object,
};

const ThanksInfo = (props: Props, ref) => {
  const {
    widgetDetails,
    setSampleWidget,
    paymentWidgetsDetails,
  } = props;

  const [, forceUpdate] = useState();
  const [thnksMsg, setThnksMsg] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());


  useEffect(() => {
    if (widgetDetails && widgetDetails.currencyCode) {
      setThnksMsg(widgetDetails.thankYouMessage);
    }
  }, [widgetDetails]);

  useEffect(() => {
    if (widgetDetails && widgetDetails.widgetType === 'EDIT') {
      setRedirectUrl(paymentWidgetsDetails.paymentWidgetLinks[0].redirectUri);
    }
  }, [paymentWidgetsDetails]);

  const updateThnksMsg = (e) => {
    setThnksMsg(e.currentTarget.value);
    widgetDetails.thankYouMessage = e.currentTarget.value;
    setSampleWidget(setSampleWidget);
  };

  const updateUrl = (e) => {
    setRedirectUrl(e.currentTarget.value);
    widgetDetails.redirectUri = e.currentTarget.value;
    setSampleWidget(setSampleWidget);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
  }));

  return (
    <>
      <Row type="flex" justify="start" align="middle">
        <Col span={14} className="mb-3">
          <div style={{ marginTop: '12px' }}>
            <label htmlFor="">
              <SpText fontSize="14px">
                Redirect Url
              </SpText>
            </label>
            <Input
              placeholder="http://www.example.com"
              value={redirectUrl}
              onChange={(e) => updateUrl(e)}
            />
            <SpError>
              {simpleValidator.current.message('Redirect Url', redirectUrl, 'url')}
            </SpError>
          </div>
        </Col>
      </Row>
      <SpText className="text-uppercase mr-4 mt-4" fontSize="16px">
        Thank you message
      </SpText>
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '18px' }}>
            <label htmlFor="">
              <SpText fontSize="14px">A short message to show after user pays</SpText>
            </label>
            <TextArea
              className="resize"
              rows={3}
              value={thnksMsg}
              onChange={updateThnksMsg}
            />
            <SpError>
              {simpleValidator.current.message('thank you message', thnksMsg, 'required')}
            </SpError>
          </div>
        </Col>
      </Row>
      <Divider style={{ marginTop: '31px' }} />
    </>
  );
};

// $FlowFixMe
export default forwardRef(ThanksInfo);
