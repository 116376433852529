import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  INVENTORY_CATEGORIES,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryCategories = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=name&direction=ASC';
  const filterName = payload.nameSearch;
  if (filterName) {
    if (payload.filterParams && !payload.sortParams) {
      return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}?${payload.filterParams}${filterName}`);
    }
    if (payload.filterParams && payload.sortParams) {
      return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}${query}${sorting}&${payload.filterParams}${filterName}`);
    }
    if (!payload.filterParams && payload.sortParams) {
      return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}${query}${sorting}${filterName}`);
    }
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}${query}${sorting}${filterName}`);
  }
  if (payload.catForMenu) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}?size=100000`);
  }
  if (payload.filterParams && !payload.sortParams && !payload.catForMenu) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams && !payload.catForMenu) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams && !payload.catForMenu) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}${query}${sorting}`);
};

const getInventoryCategoriesWithItems = async () => axiosAuthInstance.get(`${API_END_POINTS.GET_CATEGORY_WITH_ITEMS}`);

const fetchInventorySortCategory = async (payload) => {
  const queryParam = '?page=1&size=1000';
  const sorting = payload.sortParam || '&sortBy=sortOrder&direction=ASC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}${queryParam}${sorting}`);
};

const postInventoryCategory = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.GET_INVENTORY_CATEGORY, params);

const patchInventoryCategory = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_CATEGORY}/${gid}`, params);

const deleteSelectedInventoryCategory = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INVENTORY_CATEGORY}/${gid}`);

const patchInventorySortCategory = async ({ params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_CATEGORY}/sort-order`, params);

const patchBulkCategory = async ({ params }) => axiosAuthInstance.patch(`${API_END_POINTS.CATEGORY_BULK_PATCH}`, params);

const getfetchCategoryOperatingHours = async () => axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS_OPERATING_HOURS}?size=100000&sortBy=createdAt`);

export function* getInventoryCategories(action) {
  try {
    const { data: { entity } } = yield call(fetchInventoryCategories, action.payload);
    yield put({ type: INVENTORY_CATEGORIES.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_CATEGORIES.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* fetchCategoryOperatingHours(action) {
  try {
    const { data: { entity } } = yield call(getfetchCategoryOperatingHours, action.payload);
    yield put({ type: INVENTORY_CATEGORIES.FETCH_OPERATING_HOURS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_CATEGORIES.FAILED, payload: e.response.data });
  }
}

export function* updateInventoryCategory(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventoryCategory, action.payload);
    const { data: { entity } } = yield call(fetchInventoryCategories, action.payload);
    yield put({ type: INVENTORY_CATEGORIES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.UPDATE_CATEGORY,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addInventoryCategory(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postInventoryCategory, action.payload);
    const { data: { entity } } = yield call(fetchInventoryCategories, action.payload);
    yield put({ type: INVENTORY_CATEGORIES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.ADD_CATEGORY,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getInventorySortCategory(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchInventorySortCategory, action.payload);
    yield put({ type: INVENTORY_CATEGORIES.ORDER_CATEGORIES_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_CATEGORIES.ORDER_CATEGORIES_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateInventorySortCategory(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventorySortCategory, action.payload);
    const { data: { entity } } = yield call(fetchInventorySortCategory, action.payload);
    yield put({ type: INVENTORY_CATEGORIES.ORDER_CATEGORIES_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.UPDATE_SORT_CATEGORY,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteInventoryCategory(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteSelectedInventoryCategory, action.payload);
    // const { data: { entity } } = yield call(fetchInventoryCategories, action.payload);
    // yield put({ type: INVENTORY_CATEGORIES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.DELETE_CATEGORY,
    });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* updateBulkCategories(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchBulkCategory, action.payload);
    const { data: { entity } } = yield call(fetchInventoryCategories, action.payload.payload);
    yield put({ type: INVENTORY_CATEGORIES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.UPDATE_CATEGORY,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* fetchInventoryCategoriesWithItems() {
  try {
    const { data: { entity } } = yield call(getInventoryCategoriesWithItems);
    yield put({ type: INVENTORY_CATEGORIES.SUCCESS_CATEGORY, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
