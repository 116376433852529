import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  ADMIN_TERMINAL,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchAdminTerminal = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.ADMIN_TERMINAL}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.ADMIN_TERMINAL}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.ADMIN_TERMINAL}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.ADMIN_TERMINAL}${query}${sorting}`);
};

const postAdminTerminal = async payload => axiosAuthInstance.post(API_END_POINTS.ADMIN_TERMINAL, payload);

const patchAdminTerminal = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.ADMIN_TERMINAL}/${gid}`, params);

export function* getAdminTerminal(action) {
  try {
    const { data: { entity } } = yield call(fetchAdminTerminal, action.payload);
    yield put({ type: ADMIN_TERMINAL.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: ADMIN_TERMINAL.FAILED, payload: e.response.data });
  }
}

export function* addAdminTerminal(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postAdminTerminal, action.payload);
    const { data: { entity } } = yield call(fetchAdminTerminal, action.payload);
    yield put({ type: ADMIN_TERMINAL.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ADMIN_TERMINAL.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateAdminTerminal(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchAdminTerminal, action.payload);
    const { data: { entity } } = yield call(fetchAdminTerminal, action.payload);
    yield put({ type: ADMIN_TERMINAL.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TERMINAL.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
