export default (value) => {
  let discountOff;
  const months = value && value.validiyInMonths;
  if (months && value.percentageOff) {
    discountOff = `${value.percentageOff}% off for ${months} months`;
  } else if (!months && value.percentageOff) {
    discountOff = `${value.percentageOff}% off ${value.validity}`;
  } else if (months && value.amountOff) {
    discountOff = `${value.amountOff / 100} off for ${months} months`;
  } else if (!months && value.amountOff) {
    discountOff = `${value.amountOff / 100} off ${value.validity}`;
  }
  return discountOff;
};
