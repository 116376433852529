import { PAYOUTSDETAILS } from '../actionTypes';

export const fetchPayoutDetails = (action) => ({
  type: PAYOUTSDETAILS.FETCH,
  payload: action.payload,
});

export const fetchPayoutTransactions = (action) => ({
  type: PAYOUTSDETAILS.TRANSACTIONS_FETCH,
  payload: action.payload,
});
