// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Switch,
  Select,
  AutoComplete,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  IMEI,
  DEVICEIP,
  COUNTRY_PHONE_CODE,
  ADMIN_INTEGRATION_TYPES,
} from 'appconstants';
import {
  validationRegex,
  validators,
  countryCodeValidation,
} from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  phoneCode: string,
  selectedTerminal: Object,
};

const EditAdminTerminal = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    phoneCode,
    selectedTerminal,
    inventoryShops

  } = props;

  const [, forceUpdate] = useState();
  const [deviceName, setDeviceName] = useState(selectedTerminal.deviceName);
  const [appVersion, setAppVersion] = useState(selectedTerminal.appVersion);
  const [shopGid , setShopGid] = useState("")
  const [deviceId, setDeviceId] = useState(selectedTerminal.deviceId);
  const [disableButton, setDisableButton] = useState(false);
  const [mid, setMid] = useState(selectedTerminal.terminalMid);
  const [tid, setTid] = useState(selectedTerminal.terminalTid);
  const [bankName, setBankName] = useState(selectedTerminal.bankName);
  const [deviceIP, setDeviceIP] = useState(selectedTerminal.deviceIp || '');
  const [integrationType, setIntegrationType] = useState(selectedTerminal.integrationType || '');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [phoneFormat, setPhoneFormat] = useState();
  const [contactName, setContactName] = useState(selectedTerminal.contactName || null);
  const [serialNumber, setSerialNumber] = useState(selectedTerminal.serialNumber || null);
  const gid = selectedTerminal && selectedTerminal.gid;
  const imeiRegex = /^[1-9][0-9]{14}$/;
  // eslint-disable-next-line max-len
  const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  const [autoSettlement, setAutosettlement] = useState(selectedTerminal.autoSettlementEnabled);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      imei: {
        message: IMEI.MESSAGE,
        rule: val => imeiRegex.test(val),
      },
      ip: {
        message: DEVICEIP.MESSAGE,
        rule: val => ipRegex.test(val),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      bankName: {
        message: validators.bussiness.validBankName,
        rule: val => validationRegex.nameRegex.test(val),
      },
    },
  }));

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhoneNumber(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormat(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  useEffect(() => {
    const phone = (selectedTerminal && selectedTerminal.contactNumber) ? selectedTerminal.contactNumber : '';
    const phoneResult = phone ? findPhoneNumbersInText(phone) : null;
    if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
      setCountryCode(`+${phoneResult[0].number.countryCallingCode}`);
      setPhoneNumber(phoneResult[0].number.nationalNumber);
      const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
      const formater = data.substr(data.indexOf(' ') + 1);
      setPhoneFormat(formater);
    }
  }, [selectedTerminal]);

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormat(formater);
  };

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      deviceName,
      appVersion,
      mid,
      tid,
      autoSettlement,
      gid,
      deviceIP,
      bankName,
      phoneNumber,
      countryCode,
      contactName,
      serialNumber,
      integrationType,
      shopGid
    };

    if (selectedTerminal.deviceId !== deviceId) {
      params.deviceId = deviceId;
    }
    submit(params);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Terminal"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Device Name</SpH5></label>
          <Input
            placeholder="Device Name"
            value={deviceName}
            onChange={(e) => setDeviceName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('device name', deviceName, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Device ID (IMEI etc.)</SpH5></label>
          <Input
            placeholder="Device ID"
            value={deviceId}
            onChange={(e) => setDeviceId(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('device id', deviceId, 'required|alpha_num')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Device IP</SpH5></label>
          <Input
            placeholder="Device IP"
            value={deviceIP}
            onChange={(e) => setDeviceIP(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('device ip', deviceIP, 'ip')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Merchant No.</SpH5></label>
          <Input
            placeholder="MID"
            value={mid}
            onChange={(e) => setMid(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('mid', mid, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Terminal No.</SpH5></label>
          <Input
            placeholder="TID"
            value={tid}
            onChange={(e) => setTid(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('tid', tid, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Serial Number</SpH5></label>
          <Input
            placeholder="Serial Number"
            value={serialNumber}
            onChange={(e) => setSerialNumber(e.currentTarget.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Contact Name</SpH5></label>
          <Input
            placeholder="Contact Name"
            value={contactName}
            onChange={(e) => setContactName(e.currentTarget.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Contact Number</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                value={countryCode}
                onChange={onCountryCodeChange}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormat}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setPhoneNumber(e.currentTarget.value);
                    const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormat(formater);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('phone number', phoneNumber, `phoneNumValidation:${countryCode}${phoneNumber}`)}
              </SpError>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Bank Name</SpH5></label>
          <Input
            placeholder="Bank Name"
            value={bankName}
            onChange={(e) => setBankName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('bank name', bankName, 'required|bankName')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Integration Type</SpH5></label>
          <div className="d-flex">
            <div className="w-100">
              <Select
                className="w-100"
                defaultValue={integrationType || 'Select Type'}
                onChange={value => setIntegrationType(value)}
              >
                {
                  ADMIN_INTEGRATION_TYPES.map(type => (
                    <Option
                      key={1}
                      value={type.value}
                    >
                      {type.display}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('Integration Type', integrationType, 'required')}
              </SpError>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Shop</SpH5></label>
          <div className="d-flex">
            <div className="w-100">

              <AutoComplete
                placeholder="Shop"
                showSearch
                className="w-100"
                dataSource={
                  inventoryShops.map(type => (
                    <Option
                      key={1}
                      value={type.gid}
                    >
                      {type.name}
                    </Option>
                  ))
                }
                style={{ height: '40px' }}
                defaultValue={selectedTerminal?.shop?.name ?? "Select Shop"}
                onSelect={value => {
                  setShopGid(value)
                }} />
          
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>App Version</SpH5></label>
          <Input
            placeholder="Version Name"
            value={appVersion}
            onChange={(e) => setAppVersion(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('appVersion name', appVersion, 'maxLength50')}
          </SpError>
        </div>
        <div>
          <div className="d-flex">
            <label className="d-block font-weight-bold">Auto Settlement</label>
            <Switch
              className="mx-2"
              checked={autoSettlement}
              onChange={e => setAutosettlement(e)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditAdminTerminal);
