// @flow
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Input, Modal, Select } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpButton, SpError, SpH5 } from 'components/DesignKit';
import {
  SP_OBJECT_TYPES,
} from 'appconstants';

const { Option } = Select;
type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedSpObject: Object
};

const EditSpObject = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedSpObject,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  useEffect(() => {
    console.log(selectedSpObject);
    if (selectedSpObject) {
      setName(selectedSpObject.name);
      setType(selectedSpObject.type);
    }
  }, [selectedSpObject]);

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      type,
    });
  };

  const onChangeType = (value) => {
    setType(value);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={submitting}>Update</SpButton>,
      ]}
      title="Update SP Object"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div className="pb-3">
          <label htmlFor=""><SpH5>Type</SpH5></label>
          <Select
            className="w-100"
            placeholder="Select Type"
            onChange={onChangeType}
            value={type}
          >
            {
              SP_OBJECT_TYPES.map((j, index) => (
                <Option
                  key={index}
                  value={j.value}
                  className="text-capitalize"
                >
                  {j.display}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('type', type, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditSpObject);
