import React from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  SpForm,
  SpH5,
} from 'components/DesignKit';
import moment from 'moment-timezone';

type Props = {
  serviceDetails: Object,
  selectedAccount: Object,
};

const TerminalIssue = (props: Props) => {
  const {
    serviceDetails,
    selectedAccount,
  } = props;

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Contact Name</span>
          </Col>
          <Col span={9}>
            <SpH5>{serviceDetails.name ? serviceDetails.name : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Contact No.</span>
          </Col>
          <Col span={9}>
            <SpH5>{serviceDetails.contactNumber ? serviceDetails.contactNumber : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Type</span>
          </Col>
          <Col span={9}>
            <SpH5>{serviceDetails.serviceRequest ? serviceDetails.serviceRequest : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Terminal TID</span>
          </Col>
          <Col span={9}>
            <SpH5>{serviceDetails.terminal ? serviceDetails.terminal.terminalTid : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Device Name</span>
          </Col>
          <Col span={9}>
            <SpH5>{serviceDetails.terminal ? serviceDetails.terminal.deviceName : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Field Agent</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {serviceDetails.fieldAgentLookup && serviceDetails.fieldAgentLookup.user
                ? serviceDetails.fieldAgentLookup.user && serviceDetails.fieldAgentLookup.user.givenName
                : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
      </SpForm>
      <hr />
      <SpForm>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Issue Type</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {serviceDetails.problemType ? serviceDetails.problemType : <>&#8211;</>}
            </SpH5>
          </Col>
          <Col span={3}>
            <span>Inspection Date</span>
          </Col>
          <Col span={8}>
            <SpH5>
              {
                serviceDetails.desiredDateVisit
                  ? moment.utc(serviceDetails.desiredDateVisit).tz(selectedAccount.timezone).format('MMM DD, YYYY') : <>&#8211;</>
              }
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Issue Description</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {serviceDetails.terminalProblemDetails ? serviceDetails.terminalProblemDetails : <>&#8211;</>}
            </SpH5>
          </Col>
          <Col span={24} className="d-flex justify-content-end">
            <SpH5 color="#7f7f7f">{serviceDetails && serviceDetails.gid}</SpH5>
          </Col>
        </Row>
      </SpForm>
    </>
  );
};

export default TerminalIssue;
