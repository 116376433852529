import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosSalesForceAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  SPFIELD,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const getSpFields = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SP_FIELD}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SP_FIELD}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SP_FIELD}${query}${sorting}`);
  }
  return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SP_FIELD}${query}${sorting}`);
};

const postSpField = async ({ params }) => axiosSalesForceAuthInstance.post(API_END_POINTS.SP_FIELD, params);

const patchSpField = async ({ gid, params }) => axiosSalesForceAuthInstance.patch(`${API_END_POINTS.SP_FIELD}/${gid}`, params);

const deleteSpFieldByGid = async ({ gid }) => axiosSalesForceAuthInstance.delete(`${API_END_POINTS.SP_FIELD}/${gid}`);

export function* fetchSpFields(action) {
  try {
    const { data: { entity } } = yield call(getSpFields, action.payload);
    yield put({ type: SPFIELD.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SPFIELD.FAILED, payload: e.response.data });
  }
}

export function* addSpField(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postSpField, action.payload);
    const { data: { entity } } = yield call(getSpFields, action.payload);
    yield put({ type: SPFIELD.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SP_FIELD.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateSpField(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchSpField, action.payload);
    const { data: { entity } } = yield call(getSpFields, action.payload);
    yield put({ type: SPFIELD.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SP_FIELD.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteSpField(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteSpFieldByGid, action.payload);
    const { data: { entity } } = yield call(getSpFields, action.payload);
    yield put({ type: SPFIELD.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SP_FIELD.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
