import { DISPUTEEVIDENCE } from '../actionTypes';

const initialState = {
  selectedEvidences: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISPUTEEVIDENCE.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case DISPUTEEVIDENCE.SELECTED_EVIDENCES:
      return {
        ...state,
        selectedEvidences: action.payload,
      };
    default:
      return state;
  }
};
