import { MANAGE_ACCOUNTS } from '../actionTypes';

export const fetchSelfAccounts = (action) => ({
  type: MANAGE_ACCOUNTS.FETCH,
  payload: action.payload,
});

export const addSelfAccount = (action) => ({
  type: MANAGE_ACCOUNTS.ADD_ACCOUNT,
  payload: action.payload,
});

export const updateSelfAccount = (action) => ({
  type: MANAGE_ACCOUNTS.UPDATE_ACCOUNT,
  payload: action.payload,
});

export const deleteSelefAccount = (action) => ({
  type: MANAGE_ACCOUNTS.DELETE,
  payload: action.payload,
});

export const fetchSelfAccountById = (action) => ({
  type: MANAGE_ACCOUNTS.FETCH_BY_ID,
  payload: action.payload,
});
