import React from 'react';
import moment from 'moment-timezone';
import { Icon } from 'antd';
import {
  SpText,
} from 'components/DesignKit';

import getPaymentType from 'utils/getPaymentType';
import formatAmount from 'utils/formatAmount';
import getInvoiceTimelineStatus from 'utils/getInvoiceTimelineStatus';
import getInvoiceTimelineEvent from 'utils/getInvoiceTimelineEvent';

type Props = {
  paymentTimeline: Array<Object>,
  selectedAccount: Object,
}

const InvoiceTimelineBar = (props: Props) => {
  const {
    paymentTimeline,
    selectedAccount,
  } = props;
  const currencyType = selectedAccount && selectedAccount.currency;

  const getPaymentDate = (updatedAt) => moment
    .utc(updatedAt)
    .tz(selectedAccount.timezone)
    .format('MMM DD, YYYY, hh:mm a');

  function getTimelineData(text) {
    let currency = '';
    // eslint-disable-next-line no-unused-vars
    let index = 0;

    if (text.indexOf('₹') > -1) {
      index = text.indexOf('₹');
      currency = '₹';
    }
    if (text.indexOf('$') > -1) {
      index = text.indexOf('$');
      currency = '$';
    }
    if (currency !== '') {
      const tempString = text.split(currency);
      const newArray = tempString.map((item, id) => {
        if (id !== 0 && item.length !== 0) {
          const tempArray = item.split(' ');
          tempArray[0] = formatAmount(tempArray[0] / 100, currencyType);
          return tempArray.join(' ');
        }
        return item;
      });
      return newArray.join(currency);
    }

    return text;
  }

  return (
    <div className="bar-container">
      {
        paymentTimeline.map((data, id, arr) => (
          <div className="item" key={id}>
            <div className="left1" />
            <div className="middle">
              <Icon
                type={getPaymentType(data.type)}
                style={{ color: data.type === 'WARNING' ? '#FF0000' : '#00C289' }}
              />
              {id !== arr.length - 1 ? <div className="invoiceline" /> : null}
            </div>
            <div className="right1">
              <div>
                {
                  getInvoiceTimelineEvent(data.timelineStatus) !== null ? (
                    <>
                      <SpText fontWeight="600" fontSize="16px" color="#474747">
                        {getInvoiceTimelineStatus(data.timelineStatus)} - &nbsp;&nbsp;
                      </SpText>
                      <SpText fontWeight="600" fontSize="16px" color="#6E6E6E">
                        {getInvoiceTimelineEvent(data.timelineStatus)}
                      </SpText>
                    </>
                  ) : (
                    <SpText fontWeight="600" fontSize="16px" color="#474747">
                      {getInvoiceTimelineStatus(data.timelineStatus)}
                    </SpText>
                  )
                }
              </div>
              {data.text && (
                <div className="mt-2">
                  <SpText fontWeight="400" fontSize="12px" color="#000000">{getTimelineData(data.text)}</SpText>
                </div>
              )}
              {
                data.timestamp && (
                  <div className="mt-2">
                    <SpText fontWeight="400" fontSize="12px" color="#6E6E6E">{getPaymentDate(data.timestamp)}</SpText>
                  </div>
                )
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default InvoiceTimelineBar;
