import { INVENTORY_ITEMS } from '../actionTypes';

export const fetchInventoryItems = (action) => ({
  type: INVENTORY_ITEMS.FETCH,
  payload: action.payload,
});

export const fetchItemsList = (action) => ({
  type: INVENTORY_ITEMS.LIST_FETCH,
  payload: action.payload,
});

export const updateInventoryItems = (action) => ({
  type: INVENTORY_ITEMS.UPDATE,
  payload: action.payload,
});

export const addInventoryItems = (action) => ({
  type: INVENTORY_ITEMS.ADD,
  payload: action.payload,
});

export const deleteInventoryItems = (action) => ({
  type: INVENTORY_ITEMS.DELETE,
  payload: action.payload,
});

export const fetchModifierItemsList = (action) => ({
  type: INVENTORY_ITEMS.MODIFIER_LIST_FETCH,
  payload: action.payload,
});

export const fetchInventorySortItems = (action) => ({
  type: INVENTORY_ITEMS.ORDER_LIST_FETCH,
  payload: action.payload,
});

export const updateInventorySortItems = (action) => ({
  type: INVENTORY_ITEMS.ORDER_LIST_UPDATE,
  payload: action.payload,
});

export const updateBulkItems = (action) => ({
  type: INVENTORY_ITEMS.BULK_UPDATE_ITEM,
  payload: action.payload,
});

export const fetchInventoryItemsSearch = (action) => ({
  type: INVENTORY_ITEMS.ORDER_LIST_SEARCH,
  payload: action.payload,
});
