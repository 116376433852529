import {
  call,
  put,
} from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  ACH_PAYMENT,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const createAchCustomer = async payload => axiosAuthInstance.post(`/account/${payload}${API_END_POINTS.DOWLLA_CUSTOMER}`);

const createBenficialOwner = async (accntGid, gid) => axiosAuthInstance
  .post(`/account/${accntGid}${API_END_POINTS.DOWLLA_CUSTOMER}/${gid}${API_END_POINTS.BENIFICIAL_OWNER}`);

const getBeneficiars = async gid => axiosAuthInstance.get(`${API_END_POINTS.USER_ACCOUNT}/${gid}/dwolla${API_END_POINTS.BENIFICIAL_OWNER}`);

const patchUpdate = async payload => axiosAuthInstance
  .patch(`${API_END_POINTS.INTERNAL_USER_ACCOUNT}/${payload.gid}/dwolla/beneficial-owner/${payload.benficieryId}`, payload.params);

export function* addAchCustomer(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const accntGid = action.payload.gid;
    const { businessType } = action.payload;
    const { data: { entity: { gid } } } = yield call(createAchCustomer, action.payload.gid);
    if (businessType !== 'INDIVIDUAL_OR_SOLE_PROPRIETORSHIP') {
      yield call(createBenficialOwner, accntGid, gid);
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACHPAYMENT.CUSTOMER_ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* fetchBeneficiars(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(getBeneficiars, action.payload);
    yield put({ type: ACH_PAYMENT.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
      yield put({ type: LOADING.VALIDATING });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateBenficiaryStatus(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchUpdate, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SETTINGS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
      yield put({ type: LOADING.VALIDATING });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
