import { FUNDINGSOURCE_LINKS } from '../actionTypes';

export const fetchFundingSourceLink = (payload) => ({
  type: FUNDINGSOURCE_LINKS.FETCH,
  payload,
});

export const createFundingSourceLink = (action) => ({
  type: FUNDINGSOURCE_LINKS.CREATE,
  payload: action.payload,
});
