import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  DatePicker,
} from 'antd';
import moment from 'moment-timezone';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';

const dateFormat = 'MMM DD, YYYY';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  visible: boolean,
  close: boolean,
  submit: boolean,
  submitting: boolean,
  selectedAccount: Object,
}

const RaiseDispute = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
  } = props;
  const [, forceUpdate] = useState();
  const [type, setType] = useState();
  const [dateDisplay, setDateDisplay] = useState(null);
  const [respondBy, setRespondBy] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (selectedAccount) {
      setDateDisplay(moment().utc().tz(selectedAccount.timezone).startOf('day')
        .format(dateFormat));
      setRespondBy(moment.utc().tz(selectedAccount.timezone).startOf('day').utc()
        .format(MOMENT_FORMAT));
    }
  }, [selectedAccount]);

  const onDateChange = (date, dateString) => {
    setDateDisplay(dateString);
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    setRespondBy(utcDate);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    submit({
      type,
      respondBy,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      title="Raise Dispute"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label><SpH5>Dispute Type</SpH5></label>
          <Input
            placeholder="Enter Dispute type"
            value={type}
            onChange={(e) => setType(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('type', type, 'required')}
          </SpError>
        </div>
        <div>
          <label><SpH5>Respond By</SpH5></label>
          <DatePicker
            value={moment(dateDisplay, dateFormat)}
            format={dateFormat}
            allowClear={false}
            disabledDate={current => current && current < moment().subtract(1, 'days')}
            onChange={onDateChange}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(RaiseDispute);
