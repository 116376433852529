/* eslint-disable */
import React from 'react';

const removeIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" stroke="#999999" />
        <line x1="23.3024" y1="17" x2="7.99999" y2="17" stroke="#292929" stroke-width="2" />
    </svg>
);

export default removeIcon;
