import { PAYMENTCHECKOUT_DISPLAY_STATUS } from 'appconstants';

export default value => {
  if (value === 'ACTIVE') {
    return PAYMENTCHECKOUT_DISPLAY_STATUS.ACTIVE;
  } if (value === 'EXPIRED') {
    return PAYMENTCHECKOUT_DISPLAY_STATUS.EXPIRED;
  } if (value === 'SUCCEEDED') {
    return PAYMENTCHECKOUT_DISPLAY_STATUS.SUCCEEDED;
  } if (value === 'CANCELLED') {
    return PAYMENTCHECKOUT_DISPLAY_STATUS.CANCELLED;
  } return PAYMENTCHECKOUT_DISPLAY_STATUS.ACTIVE;
};
