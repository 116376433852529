import React from 'react';
import PaymentPageDetails from './components/PaymentPageDetails';
import '../../utils/reseponsive';

type Props = {
  paymentPageMeta: Object,
  account: Object,
  phoneCode: String,
}

const PaymentPageIndex = (props: Props) => {
  const {
    paymentPageMeta,
    phoneCode,
    account,
  } = props;

  return (
    <PaymentPageDetails
      paymentPageMeta={paymentPageMeta}
      onSubmit={null}
      phoneCode={phoneCode}
      account={account}
      paymentHeading={paymentPageMeta && paymentPageMeta.paymentHeading}
      isPreview
      isAdaptive
    />
  );
};

export default PaymentPageIndex;
