import { OPERATINGHOURS } from '../actionTypes';

export const fetchHoursOfOperations = (action) => ({
  type: OPERATINGHOURS.FETCH,
  payload: action.payload,
});

export const addHoursOfOperations = (action) => ({
  type: OPERATINGHOURS.ADD_OPERATING_HOURS,
  payload: action.payload,
});

export const updateHoursOfOperations = (action) => ({
  type: OPERATINGHOURS.UPDATE_OPERATING_HOURS,
  payload: action.payload,
});

export const deleteHoursOfOperations = (action) => ({
  type: OPERATINGHOURS.DELETE_OPERATING_HOURS,
  payload: action.payload,
});
