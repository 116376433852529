// @flow
import React, {
  useState, useRef, useImperativeHandle, forwardRef,
} from 'react';
import {
  Row,
  Col,
  Input,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpForm,
  SpError,
  SpText,
} from 'components/DesignKit';

type Props = {
  disputeEvidence: Object,
  disableFields: Boolean,
}

const DigitalProduct = (props: Props, ref) => {
  const {
    disputeEvidence,
    disableFields,
  } = props;
  const [, forceUpdate] = useState();
  const [activityLog, setActivityLog] = useState((disputeEvidence && disputeEvidence.accessActivityLog) ? disputeEvidence.accessActivityLog : '');

  const simpleValidator = useRef(new SimpleReactValidator());

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      activityLog,
    }),
  }));

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Access activity log</SpText>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Activity log"
              defaultValue={activityLog}
              disabled={disableFields}
              onChange={e => setActivityLog(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Activity log', activityLog, 'required')}
            </SpError>
          </Col>
        </Row>
      </SpForm>
    </>
  );
};

// $FlowFixMe
export default forwardRef(DigitalProduct);
