/* eslint-disable */
import React from 'react';

const transferIcon = ({ fill = "silver" }) => (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="70" cy="70" r="70" fill="#F5F5F5" />
        <rect x="33" y="45" width="75" height="49" fill="#C5C5C5" />
        <circle cx="71" cy="69" r="12" fill="#E4E4E4" />
        <path d="M48 45C48 46.9698 47.612 48.9204 46.8582 50.7403C46.1044 52.5601 44.9995 54.2137 43.6066 55.6066C42.2137 56.9995 40.5601 58.1044 38.7403 58.8582C36.9204 59.612 34.9698 60 33 60L33 45H48Z" fill="#DADADA" />
        <path d="M93 45C93 46.9698 93.388 48.9204 94.1418 50.7403C94.8956 52.5601 96.0005 54.2137 97.3934 55.6066C98.7863 56.9995 100.44 58.1044 102.26 58.8582C104.08 59.612 106.03 60 108 60L108 45H93Z" fill="#DADADA" />
        <path d="M48 94C48 92.0302 47.612 90.0796 46.8582 88.2597C46.1044 86.4399 44.9995 84.7863 43.6066 83.3934C42.2137 82.0005 40.5601 80.8956 38.7403 80.1418C36.9204 79.388 34.9698 79 33 79L33 94H48Z" fill="#DADADA" />
        <rect x="82" y="83" width="14" height="15" fill="#DBDBDB" />
        <path d="M121 90.5L95.5 104.789L95.5 76.2106L121 90.5Z" fill="#DBDBDB" />
    </svg>
);

export default transferIcon;
