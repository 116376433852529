// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  COUNTRY_CODES,
  TYPE_SCHEME,
} from 'appconstants';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
};

const AddAdminBin = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const [bin, setBin] = useState('');
  const [brand, setBrand] = useState('');
  const [country, setCountry] = useState('');
  const [currency, setCurrency] = useState('');
  const [scheme, setScheme] = useState('');
  const [type, setType] = useState('');
  const [bank, setBank] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      bin: {
        message: 'Please enter valid bin',
        rule: val => val.length >= 6,
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      bin,
      brand,
      country,
      currency,
      type,
      scheme,
      bank,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Bin"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Bin</SpH5></label>
          <Input
            placeholder="Bin"
            value={bin}
            onChange={(e) => setBin(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Bin', bin, 'required|numeric|bin')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Brand</SpH5></label>
          <Input
            placeholder="Brand"
            value={brand}
            onChange={(e) => setBrand(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Brand', brand, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Country</SpH5></label>
          <Select
            style={{ width: '100%' }}
            value={country || 'Select country'}
            onChange={(e) => setCountry(e)}
          >
            {
              COUNTRY_CODES.map((option) => (
                <Option
                  key={option.id}
                  value={option.VALUE}
                >
                  {option.VALUE}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('Country', country, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Currency</SpH5></label>
          <Select
            style={{ width: '100%' }}
            value={currency || 'Select currency'}
            onChange={(e) => setCurrency(e)}
          >
            {
              COUNTRY_CODES.map((option) => (
                <Option
                  key={option.id}
                  value={option.CURRENCY}
                >
                  {option.CURRENCY}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('Currency', currency, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Type</SpH5></label>
          <Select
            style={{ width: '100%' }}
            value={type || 'Select type'}
            onChange={(e) => setType(e)}
          >
            {
              TYPE_SCHEME.map((option) => (
                <Option
                  key={option.id}
                  value={option.value}
                >
                  {option.value}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('Type', type, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Scheme</SpH5></label>
          <Input
            placeholder="Scheme"
            value={scheme}
            onChange={(e) => setScheme(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Scheme', scheme, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Bank</SpH5></label>
          <Input
            placeholder="Bank"
            value={bank}
            onChange={(e) => setBank(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Bank', bank, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddAdminBin);
