export default status => {
  if (status === 'ACTIVE') {
    return '#ececff';
  } if (status === 'DEACTIVE') {
    return '#ffe0b1';
  } if (status === 'EXPIRED') {
    return '#ee6972';
  } if (status === 'DELETED') {
    return '#e2e2e2';
  } return '#e2e2e2';
};
