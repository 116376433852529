import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  DINING,
  NOTIFICATION,
  LOADING,
} from '../actionTypes';

const fetchDiningSections = async (payload) => axiosAuthInstance.get(`${API_END_POINTS.GET_SHOP_SECTION}?shopGid=${payload}&size=1000`);

// eslint-disable-next-line max-len
const fetchDiningTable = async (payload) => {
  if (payload.shopGid && payload.sectionGid) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_SHOP_TABLE}?shopGid=${payload.shopGid}&shopSectionGid.EQ=${payload.sectionGid}&size=1000`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_SHOP_TABLE}?shopGid=${payload.shopGid}&size=1000`);
};

const postDiningSection = async (params) => axiosAuthInstance.post(API_END_POINTS.GET_SHOP_SECTION, params);

const postDiningTable = async (params) => axiosAuthInstance.post(`${API_END_POINTS.GET_SHOP_TABLE}/bulk`, params);

const patchDiningTable = async (params) => axiosAuthInstance.patch(`${API_END_POINTS.GET_SHOP_TABLE}/bulk`, params);

const patchDiningSection = async (params) => axiosAuthInstance.patch(`${API_END_POINTS.GET_SHOP_SECTION}/${params.sectionGid}`, params.payload);

const diningTableDelete = async (params) => axiosAuthInstance.delete(`${API_END_POINTS.GET_SHOP_TABLE}/${params}`);

const diningAssignDelete = async (params) => axiosAuthInstance.delete(`${API_END_POINTS.GET_SHOP_TABLE}/user/${params}`);

const diningSectionDelete = async (params) => axiosAuthInstance.delete(`${API_END_POINTS.GET_SHOP_SECTION}/${params.sectionGid}`);

const getDiningDetails = async (payload) => axiosAuthInstance.get(`${API_END_POINTS.GET_SHOP_TABLE}/${payload}`);

const fetchAssignTable = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const shopGid = payload.gid;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_SHOP_TABLE}/user?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_SHOP_TABLE}/user${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_SHOP_TABLE}/user${query}&shopSectionGid.EQ=${shopGid}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_SHOP_TABLE}/user${query}&shopSectionGid.EQ=${shopGid}${sorting}`);
};

const postAssignTable = async (params) => axiosAuthInstance.post(`${API_END_POINTS.TABLE_USER}`, params);

const patchAssignTable = async (params) => axiosAuthInstance.patch(`${API_END_POINTS.TABLE_USER}/${params.userSupportGid}`, params.params);

export function* getDiningSections(action) {
  try {
    const { data: { entity } } = yield call(fetchDiningSections, action.payload);
    yield put({ type: DINING.SECTION_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: DINING.SECTION_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addDiningSection(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(postDiningSection, action.payload);
    const { data: { entity } } = yield call(fetchDiningSections, action.payload.shopGid);
    yield put({ type: DINING.SECTION_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Table Section added successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getDiningTable(action) {
  try {
    const { data: { entity } } = yield call(fetchDiningTable, action.payload);
    yield put({ type: DINING.TABLE_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: DINING.TABLE_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addDiningTable(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(postDiningTable, action.payload);
    const payload = {
      shopGid: action.payload[0].shopGid,
      sectionGid: action.payload[0].shopSectionGid,
    };
    const { data: { entity } } = yield call(fetchDiningTable, payload);
    yield put({ type: DINING.TABLE_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Tables added successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateDiningTable(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchDiningTable, action.payload);
    const payload = {
      shopGid: action.payload[0].shopGid,
      sectionGid: action.payload[0].shopSectionGid,
    };
    const { data: { entity } } = yield call(fetchDiningTable, payload);
    yield put({ type: DINING.TABLE_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Tables updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* deleteDiningTable(action) {
  console.log(action.payload, 'action.payloadaction.payloadaction.payload');
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(diningTableDelete, action.payload.tableGid);
    const payload = {
      shopGid: action.payload.shopGid,
      sectionGid: action.payload.sectionGid,
    };
    const { data: { entity } } = yield call(fetchDiningTable, payload);
    yield put({ type: DINING.TABLE_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Tables deleted successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* deleteDiningSection(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(diningSectionDelete, action.payload);
    const { data: { entity } } = yield call(fetchDiningSections, action.payload.shopGid);
    yield put({ type: DINING.SECTION_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Section deleted successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}


export function* updateSection(action) {
  try {
    yield call(patchDiningSection, action.payload);
    const { data: { entity } } = yield call(fetchDiningSections, action.payload.shopGid);
    yield put({ type: DINING.SECTION_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Section updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* fetchDiningDetails(action) {
  try {
    if (action.payload && !action.payload.gid) {
      const { data: { entity } } = yield call(getDiningDetails, action.payload);
      yield put({ type: DINING.TABLE_DETAILS, payload: entity });
    }
  } catch (e) {
    yield put({ type: DINING.TABLE_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* assignTable(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(postAssignTable, action.payload);
    const payload = {
      gid: action.payload.sectionGid,
    };
    const { data: { entity } } = yield call(fetchAssignTable, payload);
    yield put({ type: DINING.ASSIGNTABLE_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Tables assigned successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateAssignTable(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchAssignTable, action.payload);
    const payload = {
      gid: action.payload.shopGid,
    };
    const { data: { entity } } = yield call(fetchAssignTable, payload);
    yield put({ type: DINING.ASSIGNTABLE_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Tables updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getAssignTable(action) {
  try {
    const { data: { entity } } = yield call(fetchAssignTable, action.payload);
    yield put({ type: DINING.ASSIGNTABLE_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: DINING.ASSIGNTABLE_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}


export function* deleteUserTable(action) {
  console.log(action.payload, 'action.payloadaction.payloadaction.payload');
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(diningAssignDelete, action.payload.tableGid);
    const payload = {
      gid: action.payload.sectionGid,
    };
    const { data: { entity } } = yield call(fetchAssignTable, payload);
    yield put({ type: DINING.ASSIGNTABLE_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Tables deleted successfully',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
