export default status => {
  if (status === 'WITHDRAWN') {
    return '#e2e2e2';
  } if (status === 'FAILED') {
    return '#ffe0b1';
  } if (status === 'PAID') {
    return '#ececff';
  } if (status === 'IN_TRANSIT') {
    return '#e2e2e2';
  }
  return '#e2e2e2';
};
