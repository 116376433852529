// @flow
import React, { useRef, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import {
  Input,
  Select,
  Modal,
  Checkbox,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpError, SpButton, SpText,
} from 'components/DesignKit';
import formatAmount from 'utils/formatAmount';
import {
  validators,
  validationRegex,
  countryCodeValidation,
} from 'utils/validationMessages';
import {
  COUNTRY_PHONE_CODE,
  OfflinePaymentTypeIn,
  OfflinePaymentTypeUs,
  COUNTRIES,
  MAX_AMOUNT,
} from 'appconstants';
import moment from 'moment-timezone';
import QuantityCombo from './quantityCombo';

const { Option } = Select;
const { TextArea } = Input;
const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  phoneCode: string,
  id: Number,
  paymentPagesDetails: Object,
  selectedAccount: Object,
  offlineNameDetails: Array,
};

const CheqPaymentPage = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    phoneCode,
    id,
    paymentPagesDetails,
    selectedAccount,
    offlineNameDetails,
  } = props;

  const [, forceUpdate] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [phoneNumber, setPhone] = useState(null);
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [phoneFormate, setPhoneFormate] = useState();
  const [paymentType, setPaymentType] = useState('');
  const [paymentPageMeta, setPaymentPageMeta] = useState([]);
  const prefix = selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix;
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [OfflinePaymentType, setOfflinePaymentType] = useState([]);
  const [checkLabel, setCheckLabel] = useState('');
  const [comboboxList, setComboboxList] = useState([]);
  const [comboValueList, setComboValueList] = useState([]);
  const [comboBox, setComboBox] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [comboIndex, setComboIndex] = useState(null);
  const [showName, setShowName] = useState(false);
  const [name, setName] = useState('');
  const qntAmtRegex = /^[0-9]+$/;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.new_email.test(val),
      },
      name: {
        message: 'Name should be minimum 4 characters',
        rule: (val) => val.length >= 4,
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      minQuantity: {
        message: 'Please enter valid Quantity',
        rule: (val, params) => (
          params[1] ? val && (Number(params[0] && params[0]) <= Number(val && val)) && (Number(params[1] && params[1]) >= Number(val && val))
            : val && (Number(params[0] && params[0]) <= Number(val && val))),
      },
      maxQuantity: {
        message: 'Please enter valid Amount',
        rule: (val, params) => (
          params[1] ? val && (Number(params[0] && params[0]) <= Number(val && val)) && (Number(params[1] && params[1]) >= Number(val && val))
            : val && (Number(params[0] && params[0]) <= Number(val && val))),
      },
      largeText: {
        message: 'Maximum 255 Characters Only',
        rule: (val) => val.length <= 255,
      },
      onlyInteger: {
        message: 'Decimal format is not accepted',
        rule: val => (qntAmtRegex.test(val)),
      },
    },
  }));
  const [comboData, setComboData] = useState({});

  useEffect(() => {
    setPaymentPageMeta([]);
    if (paymentPagesDetails && paymentPagesDetails.paymentPageMetaFields && paymentPagesDetails.paymentPageMetaFields.length !== 0) {
      const list = [...paymentPagesDetails.paymentPageMetaFields];
      const comboBoxItem = list.filter(item => item.type === 'COMBO_BOX');
      const typeComboBox = paymentPageMeta.findIndex((data) => data.type === 'COMBO_BOX');
      setComboIndex(typeComboBox);
      setComboBox(comboBoxItem);
      if (comboBoxItem.length !== 0) {
        setComboboxList(comboBoxItem[0].comboBox);
      }
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].type === 'PRICE_AMOUNT_QUANTITY_BASED') {
          const amount = list[i].amount / 100;
          list[i].quantity = null;
          list[i].value = amount;
        }
        if (list[i].type === 'PRICE_AMOUNT_CUSTOMER_DECIDED') {
          list[i].val = 0;
        }
      }
      setPaymentPageMeta(list);
    }
    const filterNameLabel = (offlineNameDetails.paymentPageMetaFields) && (offlineNameDetails.paymentPageMetaFields).filter((item) => (
      ((item.label && item.label.toLowerCase()) === 'name')
      && (item.type === 'INPUT_SINGLE_LINE_TEXT' || item.type === 'INPUT_ALPHA')
    ));
    if (filterNameLabel.length !== 0) {
      setShowName(false);
    } else {
      setShowName(true);
    }
  }, [paymentPagesDetails]);

  useEffect(() => {
    if (isIndia) {
      setOfflinePaymentType(OfflinePaymentTypeIn);
      setCheckLabel('Cheque');
    } else if (!isIndia) {
      setOfflinePaymentType(OfflinePaymentTypeUs);
      setCheckLabel('Check');
    }
  }, [selectedAccount]);


  useEffect(() => {
    if (phoneCode === '+1') {
      setPhoneFormate('(###) ###-####');
    } else if (phoneCode === '+91') {
      setPhoneFormate('##### #####');
    } else {
      setPhoneFormate('##########');
    }
  }, []);

  useEffect(() => {
    if (id === 1) {
      setPhoneFormate('(###) ###-####');
    } else if (id === 2) {
      setPhoneFormate('##### #####');
    } else {
      setPhoneFormate('##########');
    }
  }, []);

  const onPhoneNumberChange = (e, i) => {
    const list = [...paymentPageMeta];
    const updatedPhone = e.currentTarget.value;
    list[i].value = updatedPhone.replace(/[^0-9+]/g, '');
    setPaymentPageMeta(list);
  };

  const onCountryCodeChange = (value) => {
    if (value === '+1') {
      setPhoneFormate('(###) ###-####');
      setCountryCode(value);
    } else if (value === '+91') {
      setPhoneFormate('##### #####');
      setCountryCode(value);
    } else {
      setPhoneFormate('##########');
      setCountryCode(value);
    }
  };

  const onPaymentTypeChange = (val) => {
    setPaymentType(val);
  };

  simpleValidator.current.purgeFields();

  const sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const callCombo = (list, idx) => {
    comboData[`${idx}`] = list;
    setComboData(comboData);
  };

  const updateChildern = (e, i) => {
    const listItem = [...comboBox];
    const list = [...comboboxList];
    list[i].val = e;
    list[i].value = e;
    listItem[0].comboBox[i].value = e;
    setComboBox(listItem);
    setComboboxList(list);
  };

  const updateComboChildern = (e, i, j) => {
    const listItem = [...paymentPageMeta];
    listItem[i].comboBox[j].value = e;
    const val = {};
    const key = listItem[i].comboBox[j].label;
    const value = e;
    val[key] = value;
    setComboValueList([...comboValueList, val]);
    setPaymentPageMeta(listItem);
  };

  const updatPaymentMeta = (e, i) => {
    const list = [...paymentPageMeta];
    if (list[i].type === 'PRICE_AMOUNT_CUSTOMER_DECIDED') {
      list[i].value = e;
      list[i].val = e;
      setPaymentPageMeta(list);
    } else if (list[i].type === 'INPUT_PHONE' || list[i].type === 'INPUT_EMAIL' || list[i].type === 'INPUT_ALPHA') {
      list[i].value = e;
      setPaymentPageMeta(list);
    } else if (list[i].type === 'PROMOTIONAL_CODES') {
      list[i].value = e;
      setPaymentPageMeta(list);
    } else if (list[i].type === 'TIP') {
      list[i].value = e;
      setPaymentPageMeta(list);
    } else if (list[i].type === 'INPUT_ALPHA_NUMERIC') {
      list[i].value = e;
      setPaymentPageMeta(list);
    } else if (list[i].type === 'COMBO_BOX') {
      list[i].value = e;
      setPaymentPageMeta(list);
    } else if (list[i].type === 'INPUT_SINGLE_LINE_TEXT') {
      list[i].value = e;
      setPaymentPageMeta(list);
    } else if (list[i].type === 'INPUT_LARGE_TEXT') {
      list[i].value = e;
      setPaymentPageMeta(list);
    } else if (list[i].type === 'REFERENCE_NUMBER') {
      list[i].value = e;
      setPaymentPageMeta(list);
    } else if (list[i].type === 'INPUT_NUMBER') {
      list[i].value = e;
      setPaymentPageMeta(list);
    }
  };

  const onCheck = (checked, i) => {
    const list = [...paymentPageMeta];
    if (checked) {
      list[i].checked = true;
      list[i].value = list[i].amount;
    } else if (!checked) {
      list[i].checked = false;
      list[i].value = null;
    }
    setPaymentPageMeta(list);
  };

  const updateQuantityBased = (e, i) => {
    const list = [...paymentPageMeta];
    const qnty = Number(e);
    list[i].quantity = qnty === 0 ? null : qnty;
    if (qnty !== 0) {
      const amount = qnty * (list[i].amount / 100);
      list[i].value = amount;
    } else if (qnty === 0) {
      const amount = list[i].amount / 100;
      list[i].value = amount;
    }
    setPaymentPageMeta(list);
  };

  const updateTip = (e, i) => {
    const list = [...paymentPageMeta];
    list[i].value = e / 100;
    setPaymentPageMeta(list);
  };

  const validate = (event) => {
    if (phoneNumber) {
      setPhone(phoneNumber.replace(/[^0-9+]/g, ''));
    }
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const typeMobile = paymentPageMeta.findIndex((data) => data.type === 'INPUT_PHONE');
    const typeEmail = paymentPageMeta.findIndex((data) => data.type === 'INPUT_EMAIL');
    const nameValue = (paymentPageMeta).filter((item) => (
      ((item.label && item.label.toLowerCase()) === 'name')
      && (item.type === 'INPUT_SINGLE_LINE_TEXT' || item.type === 'INPUT_ALPHA')
    ));
    let payloadName;
    if (nameValue && nameValue.length !== 0) {
      payloadName = nameValue && nameValue[0].value;
    }
    const list = [...paymentPageMeta];
    for (let i = 0; i < list.length; i += 1) {
      if (list[i].type === 'TIP') {
        const amount = list[i].value * 100;
        list[i].value = amount;
      }
      if (list[i].type === 'INPUT_PHONE') {
        list[i].value = `${countryCode}${list[i].value}`;
      }
      if (list[i].type === 'COMBO_BOX') {
        if (!list[i].checked) {
          list[i].value = null;
        }
        if ((list[i].isComboBoxRequired === true) && (comboValueList && comboValueList.length === 0)) {
          const output = (list[i].comboBox).reduce((acc, curr) => {
            acc[curr.label] = '';
            return acc;
          }, {});
          list[i].comboBoxValue = output ? [output] : null;
        } else {
          let mergedObj;
          if (comboValueList && comboValueList.length > 0) {
            mergedObj = comboValueList.reduce((acc, curr) => ({ ...acc, ...curr }), {});
          }
          list[i].comboBoxValue = mergedObj ? [mergedObj] : null;
        }
      }
      if (list[i].type === 'PRICE_AMOUNT_QUANTITY_BASED') {
        const price = list[i].quantity;
        list[i].value = price;
        const lengthCheck = list[i].comboBox && list[i].comboBox.length;
        const comboDataSet = comboData[`${list[i].gid}`];
        const info = comboDataSet && comboDataSet.length !== 0 ? [...comboDataSet] : [];
        const finalComboData = sliceIntoChunks(info, lengthCheck);
        const finalComboBox = [];
        let testObject = {};
        let amount = 0;
        for (let k = 0; k < finalComboData.length; k += 1) {
          for (let j = 0; j < finalComboData[k].length; j += 1) {
            amount += finalComboData[k][j].price;
            const { label } = finalComboData[k][j];
            testObject[`${label}`] = finalComboData[k][j].type;
            testObject[`${label}`] = finalComboData[k][j].type ? finalComboData[k][j].type : '';
            testObject.additionalPrice = amount || 0;
            testObject.isRedeemed = false;
          }
          finalComboBox.push(testObject);
          amount = 0;
          testObject = {};
        }
        list[i].comboBoxValue = (finalComboBox && finalComboBox.length > 0) ? finalComboBox : null;
      }
      if (list[i].type === 'PRICE_AMOUNT_CUSTOMER_DECIDED') {
        const amount = list[i].value * 100;
        list[i].value = amount;
      }
      if (list[i].type === 'CONSENT_FORM') {
        list[i].value = 'ACCEPTED';
      }
      if (list[i].type === 'PRICE_AMOUNT_FIXED') {
        if (!list[i].checked) {
          list[i].value = 0;
        }
        if ((list[i].isComboBoxRequired === true) && (comboValueList && comboValueList.length === 0)) {
          const output = (list[i].comboBox).reduce((acc, curr) => {
            acc[curr.label] = '';
            return acc;
          }, {});
          list[i].comboBoxValue = output ? [output] : null;
        } else {
          let mergedObj;
          if (comboValueList && comboValueList.length > 0) {
            mergedObj = comboValueList.reduce((acc, curr) => ({ ...acc, ...curr }), {});
          }
          list[i].comboBoxValue = mergedObj ? [mergedObj] : null;
        }
      }
      list[i].paymentPageMetaFieldGid = list[i].gid;
      list[i].description = null;
    }
    const selectedDate = moment.tz(new Date(), selectedAccount.timezone);
    const dateStart = selectedDate.utc().format(MOMENT_FORMAT);
    const currency = paymentAmount !== 0 ? paymentAmount.replace(/[^\d]/g, '') : paymentAmount;
    let params;
    if (paymentType === 'CUSTOM') {
      params = {
        paymentPageMetaGid: paymentPagesDetails.gid,
        customerDetails: {
          name: showName ? name : payloadName,
          email: list[typeEmail].value,
          phoneNumber: list[typeMobile].value,
        },
        offlinePaymentType: paymentType,
        issuedBy,
        transactionDate: dateStart,
        offlinePaymentNote: description,
        amount: Number(currency),
        paymentPageEntries: list,
      };
    } else {
      params = {
        paymentPageMetaGid: paymentPagesDetails.gid,
        customerDetails: {
          name: showName ? name : payloadName,
          email: list[typeEmail].value,
          phoneNumber: list[typeMobile].value,
        },
        offlinePaymentType: paymentType,
        issuedBy,
        transactionDate: dateStart,
        amount: Number(currency),
        paymentPageEntries: list,
      };
    }
    submit(params);
  };

  const resetValues = () => {
    // const list = [...paymentPagesDetails.paymentPageMetaFields];
    // for (let i = 0; i < list.length; i += 1) {
    //   if (list[i].type === 'PRICE_AMOUNT_FIXED') {
    //     list[i].checked = false;
    //     list[i].comboBoxValue = null;
    //   }
    //   delete list[i].value;
    // }
    setPaymentPageMeta([]);
    close();
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={resetValues} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Add Offline Payment</SpButton>,
      ]}
      title={paymentPagesDetails.title}
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Issued By</SpH5></label>
          <Input
            placeholder="Issued By"
            value={issuedBy}
            onChange={(e) => setIssuedBy(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('issued by', issuedBy, 'required|name')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Payment Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            defaultValue={paymentType || 'Select Payment Type'}
            onChange={onPaymentTypeChange}
          >
            {OfflinePaymentType.map((item) => (
              <Option key={item.key} value={item.value}>
                {item.key}
              </Option>
            ))}
          </Select>
          <SpError>
            {simpleValidator.current.message('Payment type', paymentType, 'required')}
          </SpError>
        </div>

        {
          paymentType === 'CUSTOM' && (
            <div className="mb-3">
              <label htmlFor=""><SpH5>Offline Payment Note</SpH5></label>
              <Input
                placeholder="Offline Payment Note"
                value={description}
                onChange={(e) => setDescription(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('Payment type', paymentType, 'required')}
              </SpError>
            </div>
          )
        }

        {
          paymentType && (
            <>
              <div className="mb-3">
                {
                  paymentType === 'CASH' && (
                    <label htmlFor=""><SpH5>Cash Amount</SpH5></label>
                  )
                }
                {
                  paymentType === 'CUSTOM' && (
                    <label htmlFor=""><SpH5>Amount</SpH5></label>
                  )
                }
                {
                  paymentType === 'CHECK' && (
                    <label htmlFor=""><SpH5>{`${checkLabel} Amount`}</SpH5></label>
                  )
                }
                <Input
                  value={paymentAmount}
                  prefix={prefix}
                  placeholder="0.00"
                  onChange={(e) => {
                    const regex = /^\d*\.?\d*$/;
                    const { value } = e.currentTarget;
                    if (regex.test(value) || value === '') {
                      setPaymentAmount(e.currentTarget.value);
                    } else if (!regex.test(value)) {
                      setPaymentAmount(0.00);
                    }
                  }}
                  onKeyPress={e => {
                    const keyCode = e.charCode || e.which;
                    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                      e.preventDefault();
                    }
                  }}
                  onBlur={e => {
                    if (e.currentTarget.value) {
                      const value = formatAmount(e.currentTarget.value, currencyType);
                      setPaymentAmount(value);
                    }
                  }}
                />
                <SpError>
                  {simpleValidator.current.message('amount', paymentAmount, 'required')}
                </SpError>
              </div>
            </>
          )
        }

        {
          paymentPageMeta.length !== 0 && (
            <>
              {
                paymentPageMeta.map((item, i) => (
                  <div className={paymentPageMeta.length - 1 !== i ? 'mb-3' : ''}>
                    {
                      item.type !== 'COMBO_BOX' && (
                        <label htmlFor=""><SpH5>{item.label}</SpH5></label>
                      )
                    }
                    {
                      item.type === 'INPUT_EMAIL' && (
                        <>
                          <Input
                            placeholder="Email"
                            value={item.value}
                            onChange={(e) => updatPaymentMeta(e.currentTarget.value, i)}
                          />
                          <SpError>
                            {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'required|emailId|checkLowerCase')}
                          </SpError>
                        </>
                      )
                    }
                    {
                      item.type === 'INPUT_PHONE' && (
                        <>
                          <div className="mb-3">
                            <div className="d-flex">
                              <div
                                style={{ width: '20%' }}
                                onKeyPress={e => countryCodeValidation(e)}
                              >
                                <Select
                                  className="w-100"
                                  showSearch
                                  defaultValue={countryCode}
                                  onChange={onCountryCodeChange}
                                >
                                  {COUNTRY_PHONE_CODE.map((items) => (
                                    <Option key={items} value={items}>
                                      {items}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                              <div style={{ width: '80%' }}>
                                <NumberFormat
                                  className="InputnumFormate"
                                  value={item.value}
                                  format={phoneFormate}
                                  mask="_"
                                  allowEmptyFormatting
                                  onChange={(e) => onPhoneNumberChange(e, i)}
                                />
                                <SpError>
                                  {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'required|phone')}
                                </SpError>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }
                    {
                      item.type === 'PRICE_AMOUNT_CUSTOMER_DECIDED' && (
                        <>
                          <Input
                            placeholder={`${item.minPrice / 100} - ${item.maxPrice ? (item.maxPrice / 100) : 'Add max'}`}
                            value={item.value ? item.value : 0}
                            onChange={(e) => updatPaymentMeta(Number(e.currentTarget.value), i)}
                            prefix={item && item.currency && item.currency.prefix}
                            onKeyPress={e => {
                              const keyCode = e.charCode || e.which;
                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <div>
                            <span className="text-muted" style={{ fontSize: '12px' }}>
                              {item.label} must be in between {item.minPrice / 100} and {item.maxPrice ? (item.maxPrice / 100) : ''}
                            </span>
                          </div>
                          {
                            item.isRequired ? (
                              <SpError>
                                {simpleValidator.current.message(item.label, paymentPageMeta[i].value,
                                  `required|maxQuantity:${item.minPrice / 100},${item.maxPrice ? (item.maxPrice / 100) : ''}`)}
                              </SpError>
                            ) : (
                              <SpError>
                                {simpleValidator.current.message(item.label, paymentPageMeta[i].value,
                                  `maxQuantity:${item.minPrice / 100},${item.maxPrice ? (item.maxPrice / 100) : ''}`)}
                              </SpError>
                            )
                          }
                        </>
                      )
                    }
                    {
                      item.type === 'PRICE_AMOUNT_FIXED' && (
                        <div>
                          <Checkbox
                            checked={item.checked}
                            className="mr-2"
                            onChange={({ target: { checked } }) => {
                              onCheck(checked, i);
                            }}
                          />
                          <SpText>{item && item.currency && item.currency.prefix} {item.amount / 100}</SpText>
                          {
                            item.isRequired && (
                              <SpError>
                                {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'required')}
                              </SpError>
                            )
                          }
                          {
                            (item.comboBox && item.comboBox.length !== 0) && (
                              <div className="mt-3 mb-3">
                                {
                                  item.comboBox.map((list, j) => (
                                    <div className="mb-3">
                                      <label htmlFor=""><SpH5>{list.label}</SpH5></label>
                                      <Select
                                        className="w-100"
                                        showSearch
                                        defaultValue={list.val || 'Select'}
                                        onChange={(e) => updateComboChildern(e, i, j)}
                                      >
                                        {
                                          list.children.map((items) => (
                                            <Option key={items.label} value={items.value}>
                                              {items.label}
                                            </Option>
                                          ))
                                        }
                                      </Select>
                                    </div>
                                  ))
                                }
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                    {
                      item.type === 'COMBO_BOX' && (
                        <>
                          {
                            comboboxList.length !== 0 && (
                              <>
                                <label htmlFor=""><SpH5>{comboBox[0].label}</SpH5></label>
                                {
                                  comboboxList.map((list, j) => (
                                    <div className="mb-3">
                                      <label htmlFor=""><SpH5>{list.label}</SpH5></label>
                                      <Select
                                        className="w-100"
                                        showSearch
                                        defaultValue={list.val || 'Select'}
                                        onChange={(e) => updateChildern(e, j)}
                                      >
                                        {
                                          list.children.map((items) => (
                                            <Option key={items.label} value={items.value}>
                                              {items.label}
                                            </Option>
                                          ))
                                        }
                                      </Select>
                                    </div>
                                  ))
                                }
                              </>
                            )
                          }
                        </>
                      )
                    }
                    {
                      item.type === 'PRICE_AMOUNT_QUANTITY_BASED' && (
                        <>
                          <div className="d-flex">
                            <div className="mr-2">
                              <Input
                                placeholder={`${item.quantityMinLimit} - ${item.quantityMaxLimit ? item.quantityMaxLimit : 'Add max'}`}
                                defaultValue={item.quantity}
                                onChange={(e) => updateQuantityBased(e.currentTarget.value, i)}
                                onKeyPress={e => {
                                  const keyCode = e.charCode || e.which;
                                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                    e.preventDefault();
                                  }
                                }}
                                onKeyDown={event => {
                                  if (event.key === '.' || event.key === '-') {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              {
                                item.isRequired ? (
                                  <SpError>
                                    {simpleValidator.current.message('Quantity', paymentPageMeta[i].quantity,
                                      // eslint-disable-next-line max-len
                                      `required|onlyInteger|minQuantity:${paymentPageMeta[i].quantityMinLimit},${paymentPageMeta[i].quantityMaxLimit ? (paymentPageMeta[i].quantityMaxLimit) : ''}`)}
                                  </SpError>
                                ) : (
                                  <SpError>
                                    {simpleValidator.current.message('Quantity', paymentPageMeta[i].quantity,
                                      // eslint-disable-next-line max-len
                                      `onlyInteger|minQuantity:${paymentPageMeta[i].quantityMinLimit},${paymentPageMeta[i].quantityMaxLimit ? (paymentPageMeta[i].quantityMaxLimit) : ''}`)}
                                  </SpError>
                                )
                              }
                            </div>
                            <div style={{ width: '80%' }} className="pt-1">
                              <SpText fontSize="16px" fontWeight="600">{item && item.currency && item.currency.prefix} {item.value}</SpText>
                            </div>
                          </div>
                          {
                            (item.comboBox && item.comboBox.length !== 0) && (
                              <QuantityCombo
                                options={item.comboBox || []}
                                prefix={item && item.currency && item.currency.prefix}
                                currency={currencyType}
                                id={item.gid}
                                quantity={item.quantity}
                                callCombo={callCombo}
                              // onComboBoxChange={() => onComboBoxChange(item.comboBox)}
                              />
                            )
                          }
                        </>
                      )
                    }
                    {
                      item.type === 'INPUT_ALPHA' && (
                        <>
                          <Input
                            value={item.value}
                            onChange={(e) => updatPaymentMeta(e.currentTarget.value, i)}
                          />
                          {
                            item.isRequired && (
                              <SpError>
                                {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'required')}
                              </SpError>
                            )
                          }
                        </>
                      )
                    }
                    {
                      item.type === 'INPUT_SINGLE_LINE_TEXT' && (
                        <>
                          <Input
                            placeholder={item.label}
                            value={item.value}
                            onChange={(e) => updatPaymentMeta(e.currentTarget.value, i)}
                          />
                          {
                            item.isRequired && (
                              <SpError>
                                {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'required')}
                              </SpError>
                            )
                          }
                        </>
                      )
                    }
                    {
                      item.type === 'INPUT_LARGE_TEXT' && (
                        <>
                          <TextArea
                            rows={5}
                            placeholder={item.label}
                            value={item.value}
                            onChange={(e) => updatPaymentMeta(e.currentTarget.value, i)}
                          />
                          <div>
                            <span className="text-muted" style={{ fontSize: '12px' }}>
                              Maximum 255 Characters
                            </span>
                          </div>
                          {
                            item.isRequired ? (
                              <SpError>
                                {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'required|largeText')}
                              </SpError>
                            ) : (
                              <SpError>
                                {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'largeText')}
                              </SpError>
                            )
                          }
                        </>
                      )
                    }
                    {
                      item.type === 'PROMOTIONAL_CODES' && (
                        <>
                          <Input
                            placeholder="Promotional Code"
                            value={item.value}
                            onChange={(e) => updatPaymentMeta(e.currentTarget.value, i)}
                          />
                          {
                            item.isRequired && (
                              <SpError>
                                {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'required')}
                              </SpError>
                            )
                          }
                        </>
                      )
                    }
                    {
                      item.type === 'INPUT_ALPHA_NUMERIC' && (
                        <>
                          <Input
                            placeholder={item.label}
                            value={item.value}
                            onChange={(e) => updatPaymentMeta(e.currentTarget.value, i)}
                          />
                          {
                            item.isRequired && (
                              <SpError>
                                {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'required')}
                              </SpError>
                            )
                          }
                        </>
                      )
                    }

                    {
                      item.type === 'REFERENCE_NUMBER' && (
                        <>
                          <Input
                            placeholder={item.label}
                            value={item.value}
                            onChange={(e) => updatPaymentMeta(e.currentTarget.value, i)}
                          />
                          {
                            item.isRequired && (
                              <SpError>
                                {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'required')}
                              </SpError>
                            )
                          }
                        </>
                      )
                    }

                    {
                      item.type === 'INPUT_NUMBER' && (
                        <>
                          <Input
                            placeholder={item.label}
                            value={item.value}
                            onChange={(e) => updatPaymentMeta(e.currentTarget.value, i)}
                            onKeyPress={e => {
                              const keyCode = e.charCode || e.which;
                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {
                            item.isRequired && (
                              <SpError>
                                {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'required')}
                              </SpError>
                            )
                          }
                        </>
                      )
                    }

                    {
                      item.type === 'TIP' && (
                        <div>
                          {
                            item.tipPercentages && item.tipPercentages.length !== 0 && (
                              <>
                                {
                                  item.tipPercentages.map((tip) => (
                                    <SpButton
                                      className="mr-2"
                                      type="secondary"
                                      shape="round"
                                      ghost
                                      onClick={() => updateTip(tip, i)}
                                    >
                                      {tip / 100}
                                    </SpButton>
                                  ))
                                }
                              </>
                            )
                          }
                          <div>
                            <label htmlFor=""><SpH5>Custom Tip</SpH5></label>
                            <Input
                              value={item.value}
                              placeholder="Enter custom tip"
                              onChange={(e) => updatPaymentMeta(e.currentTarget.value, i)}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {
                              item.isRequired && (
                                <SpError>
                                  {simpleValidator.current.message(item.label, paymentPageMeta[i].value, 'required|Numeric')}
                                </SpError>
                              )
                            }
                          </div>
                        </div>
                      )
                    }
                  </div>
                ))
              }
            </>
          )
        }
        {
          showName && (
            <>
              <div>
                <label htmlFor=""><SpH5>Name</SpH5></label>
                <Input
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('name', name, 'required|name')}
                </SpError>
              </div>
            </>
          )
        }
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default CheqPaymentPage;
