import { RATESDETAILS } from '../actionTypes';

export const fetchRatesDetails = (action) => ({
  type: RATESDETAILS.FETCH,
  payload: action.payload,
});

export const DeleteRate = (action) => ({
  type: RATESDETAILS.DELETE,
  payload: action.payload,
});
