// @flow
import React, {
  useRef,
  useState,
  createRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Steps,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  notificationActions,
  transfersActions,
  vendorsActions,
} from 'store/actions';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { NOTIFICATION } from 'store/actionTypes';
import { SpButton } from 'components/DesignKit';

import ContactDetails from './ContactDetails';
import Accont from './Account';
import Review from './Review';

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  contactList: Array<Object>,
  setNotification: Function,
  getContactList: Function,
  statesInCountry: Array<Object>,
  phoneCode: string,
  submit: Function,
  isIndia: boolean,
  fetchCustomTypes: Function,
  customSuggestions: Array<Object>,
};

const { Step } = Steps;

const AddAccount = (props: Props) => {
  const {
    visible,
    close,
    submitting,
    selectedAccount,
    phoneCode,
    submit,
    setNotification,
    getContactList,
    contactList,
    statesInCountry,
    isIndia,
    fetchCustomTypes,
    customSuggestions,
  } = props;

  const [, forceUpdate] = useState();
  const contactDetailsRef = createRef();
  const fundingSourceRef = createRef();
  const [refArray, setRefArray] = useState([contactDetailsRef, fundingSourceRef]);
  const [current, setCurrent] = useState(0);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const [contactName, setContactName] = useState('');
  const [contactGid, setContactGid] = useState('');
  const [email, setEmail] = useState('');
  const [contactResult, setContactResult] = useState(contactList);
  const [phoneNumberCode, setPhoneCode] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [phoneFormate, setPhoneFormate] = useState();
  const [contactType, setContactType] = useState('');
  const [customType, setCustomType] = useState('');
  const [contactTypeName, setContactTypeName] = useState('');
  const [disableContact, setDisableContact] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState();
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountType, setAccountType] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [bankName, setBankName] = useState('');
  const [disableFunding, setDisableFunding] = useState(false);
  const [transferType, setTransferType] = useState(isIndia ? 'IMPS' : 'ACH');
  const [upiId, setUpiId] = useState();
  const currencyType = selectedAccount.currency;
  const issuerName = (selectedAccount.issuer && selectedAccount.issuer.name);
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [customerType, setCustomerType] = useState('INDIVIDUAL');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [nickName, setNickName] = useState('');

  const contactDetailsData = {
    contactName,
    contactGid,
    contactResult,
    email,
    phoneNumberCode,
    disableContact,
    contactPhone,
    contactType,
    customType,
    customSuggestions,
    contactTypeName,
    phoneFormate,
    countryCode,
    streetName,
    cityName,
    stateName,
    zipCode,
  };

  const fundingSourceDetailsData = {
    accountNumber,
    confirmAccountNumber,
    routingNumber,
    accountType,
    bankName,
    upiId,
    transferType,
    disableFunding,
    businessType,
    customerType,
    accountHolderName,
    nickName,
  };

  useEffect(() => {
    setContactResult(contactList);
  }, []);

  useEffect(() => {
    setContactResult(contactList);
  }, [contactList]);

  useEffect(() => {
    if (current === 2 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const fetchContactDetails = (search) => {
    getContactList(search);
  };

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
    fetchCustomTypes({ type: 'CONTACT_TYPE' });
  }, []);

  const next = async () => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setContactName(valuesArray[0].contactName);
      setContactGid(valuesArray[0].contactGid);
      setEmail(valuesArray[0].email);
      setStreetName(valuesArray[0].streetName);
      setCityName(valuesArray[0].cityName);
      setStateName(valuesArray[0].stateName);
      setZipCode(valuesArray[0].zipCode);
      setContactPhone(valuesArray[0].contactPhone);
      setPhoneFormate(valuesArray[0].phoneFormate);
      setPhoneCode(valuesArray[0].phoneNumberCode);
      setContactType(valuesArray[0].contactType);
      setCustomType(valuesArray[0].customType);
      setContactTypeName(valuesArray[0].contactTypeName);
      setDisableContact(valuesArray[0].disableContact);
      setCountryCode(valuesArray[0].countryCode);
      setCurrent(current + 1);
    } else if (current === 1) {
      validationArray.push(refArray[1].current.validate());
      const fundingSourceDetails = refArray[1].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setAccountNumber(fundingSourceDetails.accountNumber);
      setConfirmAccountNumber(fundingSourceDetails.accountNumber);
      setRoutingNumber(fundingSourceDetails.routingNumber);
      setAccountType(fundingSourceDetails.accountType);
      setBusinessType(fundingSourceDetails.businessType);
      setBankName(fundingSourceDetails.bankName);
      setUpiId(fundingSourceDetails.upiId);
      setTransferType(fundingSourceDetails.transferType);
      setDisableFunding(fundingSourceDetails.disableFunding);
      setCustomerType(fundingSourceDetails.customerType);
      setAccountHolderName(fundingSourceDetails.accountHolderName);
      setNickName(fundingSourceDetails.nickName);
      setCurrent(current + 1);
    } else if (current === 2) {
      setDisableButton(true);
      let contactAddGid;
      if (!contactDetailsData.contactGid) {
        const contactObject = {
          name: contactDetailsData.contactName,
          contactType: contactDetailsData.contactType,
          customType: contactDetailsData.customType,
          email: contactDetailsData.email,
          phoneNumber: `${contactDetailsData.phoneNumberCode}${contactDetailsData.contactPhone}`,
          address: {
            street: streetName,
            city: cityName,
            state: stateName,
            postalCode: zipCode,
            countryCode,
          },
        };
        try {
          const { data: { entity: { gid } } } = await axiosAuthInstance.post(API_END_POINTS.GET_CONTACTS, contactObject);
          contactAddGid = gid;
        } catch (error) {
          setNotification({
            type: NOTIFICATION.ERROR,
            payload: error.response.data && error.response.data.message,
          });
        }
      }
      let contactDataGid;
      if (contactAddGid) {
        contactDataGid = {
          contactAddGid,
        };
      }
      if (contactDataGid) {
        const payload = Object.assign(contactDetailsData, fundingSourceDetailsData, contactDataGid);
        submit(
          payload,
        );
      }
    }
  };

  const prev = () => {
    setDisableButton(false);
    if (current === 0) {
      close();
    } else if (current === 1) {
      setCurrent(current - 1);
    } else if (current === 2) {
      setCurrent(current - 1);
    } else if (current === 3) {
      setCurrent(current - 1);
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="850"
      footer={current === 0
        ? [
          <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
          <SpButton key="2" onClick={next}>Next</SpButton>,
        ]
        : current === 1
          ? [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next}>Next</SpButton>,
          ]
          : [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next} disabled={disableButton}>Create</SpButton>,
          ]}
      title="Add your External Account"
    >
      <>
        <div style={{ marginTop: '-8px' }}>
          <Steps current={current} className="subscriptions">
            <Step key="1" title="Contact &nbsp;&nbsp;" />
            <Step key="2" title="Account &nbsp;&nbsp;" />
            <Step key="3" title="Confirm &nbsp;&nbsp;" />
          </Steps>
        </div>
        <div className="px-5 mt-2">
          <div className={submitting ? 'OVERLAY' : ''} />
          {current === 0 && (
            <ContactDetails
              ref={refArray[0]}
              selectedAccount={selectedAccount}
              currencyType={currencyType}
              phoneCode={phoneCode}
              contactDetailsData={contactDetailsData}
              statesInCountry={statesInCountry}
              country={selectedAccount.country.name}
              cntryId={selectedAccount.country.id}
              fetchContactDetails={fetchContactDetails}
            />
          )}
          {current === 1 && (
            <Accont
              ref={refArray[1]}
              selectedAccount={selectedAccount}
              fundingSourceDetailsData={fundingSourceDetailsData}
              isIndia={isIndia}
              issuerName={issuerName}
              fetchContactDetails={fetchContactDetails}
              contactDetailsData={contactDetailsData}
            />
          )}
          {current === 2 && (
            <Review
              isIndia={isIndia}
              selectedAccount={selectedAccount}
              contactDetailsData={contactDetailsData}
              fundingSourceDetailsData={fundingSourceDetailsData}
            />
          )}
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  contactList: state.transfers.contactList,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  customSuggestions: state.vendors.customSuggestions,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  getContactList: param => dispatch(transfersActions.getContactList({
    payload: param,
  })),
  fetchCustomTypes: param => dispatch(vendorsActions.fetchCustomTypes({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddAccount);
