// @flow
import React from 'react';
import {
  Row,
  Col,
} from 'antd';
import {
  SpH5,
  SpText,
} from 'components/DesignKit';

type Props = {
  searchRateData: Object,
  addRateData: Object,
};

const ReviewRate = (props: Props) => {
  const { searchRateData, addRateData } = props;
  const prefix = (searchRateData && searchRateData.countryId === 1) ? '$' : '₹';

  return (
    <>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Issuer Type</SpH5>
          </Col>
          <Col>
            <SpText>{searchRateData.issuerName}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Category Type</SpH5>
          </Col>
          <Col>
            <SpText>{searchRateData.categoryName}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Transfer Type</SpH5>
          </Col>
          <Col>
            <SpText>{searchRateData.transferTypeName}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Country</SpH5>
          </Col>
          <Col>
            <SpText>{searchRateData.countryName}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Processing Min</SpH5>
          </Col>
          <Col>
            <SpText>{prefix}{addRateData.processingMin}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Processing Max</SpH5>
          </Col>
          <Col>
            <SpText>{prefix}{addRateData.processingMax}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Rate</SpH5>
          </Col>
          <Col>
            <SpText>{addRateData.rate}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Tax</SpH5>
          </Col>
          <Col>
            <SpText>{addRateData.tax}</SpText>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row justify="start">
          <Col>
            <SpH5>Fee</SpH5>
          </Col>
          <Col>
            <SpText>{addRateData.fee}</SpText>
          </Col>
        </Row>
      </div>
      <div>
        <Row justify="start">
          <Col>
            <SpH5>Sales Person</SpH5>
          </Col>
          <Col>
            <SpText>{addRateData.salesPerson}</SpText>
          </Col>
        </Row>
      </div>
    </>
  );
};

// $FlowFixMe
export default ReviewRate;
