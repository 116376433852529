import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Icon,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import RBAC from 'components/rbac';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getFilterOperators from 'utils/getFilterOperators';
import moment from 'moment-timezone';

import {
  membershipActions,
  rolesActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';

import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
} from 'appconstants';

import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddUser from './components/AddUser';
import EditUser from './components/EditUser';
import DeleteUser from './components/DeleteUser';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const TEAM_FILTERS_TYPES = FILTERS_TYPES.TEAM;

type Props = {
  users: Array<Object>,
  roles: Array<Object>,
  history: {
    push: Function,
  },
  fetchMembership: Function,
  addMembership: Function,
  updateMembership: Function,
  deleteMembership: Function,
  fetchRoles: Function,
  selectedAccount: Object,
  totalElements: number,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  user: Object,
  test: boolean,
  setFilterData: Function,
  preserveFilters: Object,
};

const Team = (props: Props) => {
  const {
    selectedAccount,
    roles,
    users,
    loading,
    user,
    addMembership,
    updateMembership,
    deleteMembership,
    fetchMembership,
    totalElements,
    fetchRoles,
    submitting,
    validator,
    test,
    history,
    setFilterData,
    preserveFilters,
  } = props;

  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [filterTypes, setFilterTypes] = useState(TEAM_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterTeams, setFilterTeams] = useState((preserveFilters && preserveFilters.filterTeams)
    ? (preserveFilters && preserveFilters.filterTeams)
    : [{
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterTeams: [{
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });

  const exportData = users ? users.map((values) => ({
    name: values.user.fullName,
    email: values.user.email,
    lastLogin: values.user.lastLogin ? moment
      .utc(values.user.lastLogin)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a') : '',
    role: values.role.type,
  })) : '';

  const exportHeaders = [
    { label: 'USER NAME', key: 'name' },
    { label: 'EMAIL', key: 'email' },
    { label: 'LAST SESSION', key: 'lastLogin' },
    { label: 'ROLE', key: 'role' },
  ];

  const debouncedFetchMembership = debounce(fetchMembership, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchMembership({ currentPage, sortParams, filterParams });
    fetchRoles();
  };

  useEffect(() => {
    getData(location.search);
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [test, selectedAccount, location]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && (e.toElement && e.toElement.className !== 'ant-calendar-date')
      && (e.toElement && e.toElement.className !== 'ant-calendar-prev-month-btn')
      && (e.toElement && e.toElement.className !== 'ant-calendar-next-month-btn')) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (!validator) {
      setAddUserModal(false);
      setEditUserModal(false);
      setDeleteUserModal(false);
    }
  }, [validator]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      current: parseInt(currentPage, 10),
      total: totalElements,
      showTotal: total => `Total ${total} items`,
    }));
  }, [users]);

  // eslint-disable-next-line no-shadow
  const addMember = (user: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      firstName,
      lastName,
      email,
      phone,
      role,
      countryCode,
    } = user;

    addMembership({
      currentPage,
      sortParams,
      roleGid: role,
      user: {
        givenName: firstName,
        familyName: lastName,
        phoneNumber: `${countryCode}${phone}`,
        email,
      },
    });
  };

  // eslint-disable-next-line no-shadow
  const editMember = (user: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      firstName,
      lastName,
      email,
      role,
    } = user;
    updateMembership({
      currentPage,
      sortParams,
      memberId: selectedUser.gid,
      setTo: role,
      user: {
        givenName: firstName,
        familyName: lastName,
        email,
      },
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    let fieldName;

    if (filters.field === 'user') {
      fieldName = 'user.givenName';
    } else {
      fieldName = filters.field;
    }

    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${fieldName}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${fieldName}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.TEAM,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.TEAM,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const deleteMember = (gid: string) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    deleteMembership({
      currentPage,
      sortParams,
      gid,
    });
  };

  const showDeleteUserModal = (value: Object) => {
    setSelectedUser(value);
    setDeleteUserModal(true);
  };

  const showEditUserModal = (value: Object) => {
    setSelectedUser(value);
    setEditUserModal(true);
  };

  const onCheck = (e, index) => {
    const list = [...filterTeams];
    if (filterTeams.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = TEAM_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTeams(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterTeams];
    list[index].filterType = 'String';
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = TEAM_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTeams(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterTeams[index].filterName === 'createdAt') {
      if (filterTeams[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterTeams[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterTeams[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterTeams];
    list[index].filterValue = selectedValue;
    setFilterTeams(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTeams];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterTeams[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterTeams(list);
  };

  const handleAddClick = () => {
    const list = [...filterTeams];
    setFilterTeams([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterTeams([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(TEAM_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.TEAM);
  };

  const applyFilters = () => {
    const teamsFilters = filterTeams.filter((item) => item.checked);
    for (let i = 0; i < teamsFilters.length; i += 1) {
      if (!teamsFilters[i].filterName || !teamsFilters[i].filterOperator || !teamsFilters[i].filterValue) {
        return;
      }
    }
    if (teamsFilters[0].filterValue) {
      setCount(teamsFilters && teamsFilters.length);
      for (let i = 0; i < teamsFilters.length; i += 1) {
        if (teamsFilters[i].checked) {
          if (teamsFilters[i].filterName === 'createdAt') {
            teamsFilters[i] = teamsFilters[i].filterValue;
          } else if (teamsFilters[i].filterName === 'email') {
            const email = encodeURIComponent(teamsFilters[i].filterValue);
            teamsFilters[i] = `${teamsFilters[i].filterName}.${teamsFilters[i].filterOperator}=${email}`;
          } else {
            teamsFilters[i] = `${teamsFilters[i].filterName}.${teamsFilters[i].filterOperator}=${teamsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(teamsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterTeams,
        });
        history.push({
          pathname: PAGES.TEAM,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterTeams,
        });
        history.push({
          pathname: PAGES.TEAM,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'user.fullName',
      width: '16%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'EMAIL',
      dataIndex: 'user.email',
      width: '22%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'LAST SESSION',
      dataIndex: 'user.lastLogin',
      width: '20%',
      align: 'left',
      render: (lastLogin) => {
        if (lastLogin && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(lastLogin)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: false,
    },
    {
      title: 'ROLE',
      dataIndex: 'role.type',
      width: '13%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];
  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '13%',
    align: 'center',
    render: (text, record) => (
      <span>
        {!((user && user.user && record.user && (user.user.gid === record.user.gid)) || (record.role && record.role.type === 'Owner')) ? (
          <span>
            <Tooltip placement="top" title="Delete">
              <Icon
                type="delete"
                style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
                onClick={() => showDeleteUserModal(record)}
              />
            </Tooltip>
            <Tooltip placement="top" title="Edit">
              <Icon
                type="edit"
                className="ml-3"
                style={{ fontSize: '20px', cursor: 'pointer' }}
                onClick={() => showEditUserModal(record)}
              />
            </Tooltip>
          </span>
        ) : <span>&#8211;</span>}
      </span>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}>
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                onClick={() => setAddUserModal(true)}
              >
                {'\u002B'}&nbsp;Add New
              </SpButton>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Team">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Team Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Team">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper>
                    <Filters
                      filtersGrid={filterTeams}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterTeams.length < TEAM_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD USER MODAL */}
      {addUserModal && (
        <AddUser
          visible={addUserModal}
          roles={roles}
          submitting={submitting}
          phoneCode={phoneCode}
          close={() => setAddUserModal(false)}
          submit={addMember}
        />
      )}

      {/* EDIT USER MODAL */}
      {editUserModal && (
        <EditUser
          visible={editUserModal}
          selectedUser={selectedUser}
          submitting={submitting}
          roles={roles}
          close={() => setEditUserModal(false)}
          submit={editMember}
        />
      )}

      {/* DELETE USER MODAL */}
      {deleteUserModal && (
        <DeleteUser
          visible={deleteUserModal}
          selectedUser={selectedUser}
          submitting={submitting}
          close={() => setDeleteUserModal(false)}
          submit={deleteMember}
        />
      )}

      <RBAC
        fallback={(
          <Table
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={users}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}
      >
        <Table
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={users}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  users: state.membership.content,
  totalElements: state.membership.totalElements,
  roles: state.roles.content,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  user: state.user,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMembership: param => dispatch(membershipActions.fetchMembership({
    payload: param,
  })),
  addMembership: param => dispatch(membershipActions.addMembership({
    payload: param,
  })),
  updateMembership: param => dispatch(membershipActions.updateMembership({
    payload: param,
  })),
  deleteMembership: param => dispatch(membershipActions.deleteMembership({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
  fetchRoles: () => dispatch(rolesActions.fetchRoles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
