import { OFFERDETAILS } from '../actionTypes';

export const fetchOfferDetails = (action) => ({
  type: OFFERDETAILS.FETCH,
  payload: action.payload,
});

export const deleteOffer = (action) => ({
  type: OFFERDETAILS.DELETE,
  payload: action.payload,
});

export const updateOffer = (action) => ({
  type: OFFERDETAILS.UPDATE,
  payload: action.payload,
});

export const fetchRedemptionsDetails = (action) => ({
  type: OFFERDETAILS.FETCH_REDEMPTIONS,
  payload: action.payload,
});
