import { QUICKSUPPORT } from '../actionTypes';

export const fetchInventoryQuickSupport = (action) => ({
  type: QUICKSUPPORT.FETCH,
  payload: action.payload,
});

export const addInventoryQuickSupport = (action) => ({
  type: QUICKSUPPORT.ADD,
  payload: action.payload,
});

export const updateInventoryQuickSupport = (action) => ({
  type: QUICKSUPPORT.UPDATE,
  payload: action.payload,
});

export const deleteInventoryQuickSupport = (action) => ({
  type: QUICKSUPPORT.DELETE,
  payload: action.payload,
});
