/* eslint-disable */
import React from 'react';

const PayLinks = ({ fill = "silver" }) => (
<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.8877 9.33472H10.2703L13 6.22418V7.12997C13 8.34762 12.0543 9.33472 10.8877 9.33472Z" fill={fill}/>
  <path d="M2.11232 0.5H8.33211L6.95299 1.96983H2.11232C1.72345 1.96983 1.40821 2.29887 1.40821 2.70475V7.12997C1.40821 7.33541 1.48897 7.52116 1.61916 7.65452L0.633849 8.70464C0.242692 8.30448 0 7.74681 0 7.12997V2.70475C0 1.4871 0.945716 0.5 2.11232 0.5Z" fill={fill}/>
  <path d="M12.0214 2.25629C13.1118 3.39439 13.1118 5.23961 12.0214 6.37771L9.75757 8.74064C8.66719 9.87873 6.89932 9.87874 5.80894 8.74064C5.53397 8.45364 5.53397 7.98831 5.80894 7.70131C6.08391 7.41431 6.52973 7.41431 6.8047 7.70131C7.34514 8.2654 8.22137 8.2654 8.76182 7.70131L11.0257 5.33838C11.5661 4.77428 11.5661 3.85971 11.0257 3.29562C10.4852 2.73153 9.60901 2.73153 9.06857 3.29562L7.57054 4.85919C7.29557 5.14619 6.84976 5.14619 6.57479 4.85919C6.29982 4.57219 6.29982 4.10687 6.57479 3.81986L8.07281 2.25629C9.16319 1.11819 10.9311 1.11819 12.0214 2.25629Z" fill={fill}/>
  <path d="M1.72413 12.6464C0.633751 11.5083 0.633751 9.66311 1.72414 8.52501L3.988 6.16208C5.07839 5.02398 6.84625 5.02398 7.93664 6.16208C8.21161 6.44908 8.21161 6.9144 7.93664 7.20141C7.66166 7.48841 7.21585 7.48841 6.94088 7.20141C6.40044 6.63731 5.5242 6.63731 4.98376 7.20141L2.71989 9.56434C2.17945 10.1284 2.17945 11.043 2.71989 11.6071C3.26033 12.1712 4.13657 12.1712 4.67701 11.6071L6.17503 10.0435C6.45 9.75652 6.89582 9.75652 7.17079 10.0435C7.44576 10.3305 7.44576 10.7959 7.17079 11.0829L5.67277 12.6464C4.58238 13.7845 2.81452 13.7845 1.72413 12.6464Z" fill={fill}/>
</svg>

);

export default PayLinks;