// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Icon,
} from 'antd';

import { SpH6 } from 'components/DesignKit';

import {
  MESSAGES,
} from 'appconstants';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { NOTIFICATION } from 'store/actionTypes';

import {
  notificationActions,
} from 'store/actions';

type Props = {
  setNotification: Function,
  disputeEvidence: Array<Object>,
}

// $FlowFixMe
const DownloadEvidence = (props: Props) => {
  const {
    disputeEvidence,
    setNotification,
  } = props;

  const downloadDispute = async (record) => {
    try {
      const { data: { entity: { outputFileUrl } } } = await axiosAuthInstance
        .post(`${API_END_POINTS.DOWNLOAD_KYC}`, {
          inputFileUrl: record,
          documentType: 'DISPUTE_EVIDENCE',
        });
      window.open(outputFileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  return (
    <>
      {
        disputeEvidence && (
          <>
            <Row type="flex">
              <Col className="mt-2">
                <SpH6
                  fontWeight="600"
                >
                  Upload documents
                </SpH6>
                {
                  (disputeEvidence && disputeEvidence.customerCommunicationKey) && (
                    <div className="position-relative mr-3 my-2">
                      Customer communication
                      <Icon
                        type="download"
                        style={{ fontSize: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => downloadDispute(disputeEvidence.customerCommunicationKey)}
                      />
                    </div>
                  )
                }
                {
                  (disputeEvidence && disputeEvidence.customerSignatureKey) && (
                    <div className="position-relative mr-3 my-2">
                      Customer signature
                      <Icon
                        type="download"
                        style={{ fontSize: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => downloadDispute(disputeEvidence.customerSignatureKey)}
                      />
                    </div>
                  )
                }
                {
                  (disputeEvidence && disputeEvidence.duplicateChargeDocKey) && (
                    <div className="position-relative mr-3 my-2">
                      Duplicate charge documentation
                      <Icon
                        type="download"
                        style={{ fontSize: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => downloadDispute(disputeEvidence.duplicateChargeDocKey)}
                      />
                    </div>
                  )
                }
                {
                  (disputeEvidence && disputeEvidence.receiptKey) && (
                    <div className="position-relative mr-3 my-2">
                      Receipt
                      <Icon
                        type="download"
                        style={{ fontSize: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => downloadDispute(disputeEvidence.receiptKey)}
                      />
                    </div>
                  )
                }
                {
                  (disputeEvidence && disputeEvidence.refundPolicyKey) && (
                    <div className="position-relative mr-3 my-2">
                      Refund Policy
                      <Icon
                        type="download"
                        style={{ fontSize: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => downloadDispute(disputeEvidence.refundPolicyKey)}
                      />
                    </div>
                  )
                }
                {
                  (disputeEvidence && disputeEvidence.cancellationPolicyKey) && (
                    <div className="position-relative mr-3 my-2">
                      Cancellation Policy
                      <Icon
                        type="download"
                        style={{ fontSize: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => downloadDispute(disputeEvidence.cancellationPolicyKey)}
                      />
                    </div>
                  )
                }
                {
                  (disputeEvidence && disputeEvidence.serviceDocumentationKey) && (
                    <div className="position-relative mr-3 my-2">
                      Service documentation
                      <Icon
                        type="download"
                        style={{ fontSize: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => downloadDispute(disputeEvidence.serviceDocumentationKey)}
                      />
                    </div>
                  )
                }
                {
                  (disputeEvidence && disputeEvidence.shippingDocumentationKey) && (
                    <div className="position-relative mr-3 my-2">
                      Shipping documentation
                      <Icon
                        type="download"
                        style={{ fontSize: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => downloadDispute(disputeEvidence.shippingDocumentationKey)}
                      />
                    </div>
                  )
                }
                {
                  (disputeEvidence && disputeEvidence.creditVoucherKey) && (
                    <div className="position-relative mr-3 my-2">
                      Credit voucher
                      <Icon
                        type="download"
                        style={{ fontSize: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => downloadDispute(disputeEvidence.creditVoucherKey)}
                      />
                    </div>
                  )
                }
                {
                  (disputeEvidence && disputeEvidence.governmentOrderKey) && (
                    <div className="position-relative mr-3 my-2">
                      Government voucher
                      <Icon
                        type="download"
                        style={{ fontSize: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => downloadDispute(disputeEvidence.governmentOrderKey)}
                      />
                    </div>
                  )
                }
                {
                  (disputeEvidence && disputeEvidence.termsDisclosureKey) && (
                    <div className="position-relative mr-3 my-2">
                      Terms disclosure
                      <Icon
                        type="download"
                        style={{ fontSize: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => downloadDispute(disputeEvidence.termsDisclosureKey)}
                      />
                    </div>
                  )
                }
              </Col>
            </Row>
          </>
        )
      }
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(null, mapDispatchToProps)(DownloadEvidence);
