// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import {
  inventoryShopsDetailsActions,
} from 'store/actions';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  inventoryItemDetails: Object,
  fetchTablesShops: Function,
  shopTables: Object,
};

const DeleteOrderType = (props: Props) => {
  const {
    visible,
    close,
    submit,
    inventoryItemDetails,
    shopTables,
    fetchTablesShops,
  } = props;

  const [unpaidTables, setUnpaidTables] = useState(false);

  useEffect(() => {
    fetchTablesShops(inventoryItemDetails.gid);
  }, [inventoryItemDetails]);

  useEffect(() => {
    if (
      shopTables
      && shopTables.shopTableOccupiedCount === 0
      && shopTables.shopTableReservedCount === 0
      && shopTables.unpaidOrderCount === 0
    ) {
      setUnpaidTables(false);
    } else {
      setUnpaidTables(true);
    }
  }, [shopTables]);

  const DeleteSelectedItem = () => {
    submit(inventoryItemDetails.gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <>
          {
            unpaidTables ? (
              <><SpButton onClick={() => close()} type="secondary">Okay</SpButton></>
            ) : (
              <><SpButton onClick={() => close()} type="secondary">Cancel</SpButton>
                <SpButton onClick={DeleteSelectedItem} type="danger">Delete</SpButton>
              </>
            )
          }
        </>,
      ]}
      title={unpaidTables ? 'Action Required' : 'Confirm Deletion'}
    >
      <div className="px-4">
        {
          unpaidTables ? (
            <>
              <div className="mt-1 mb-2" style={{ color: '#000' }}>
                Please review the following and make sure all unpaid payments are settled and table entries are closed before deleting the shop.
              </div>
              <SpH5>
                <div>Unpaid Orders: {shopTables.unpaidOrderCount}</div>
              </SpH5>
              <SpH5>
                <div>Table Reservation Status: Reserved - {shopTables.shopTableReservedCount}, Occupied - {shopTables.shopTableOccupiedCount}</div>
              </SpH5>
            </>
          ) : (
            <>
              <div className="mt-1 mb-2" style={{ color: '#000' }}>Are you sure you want to delete this shop?</div>
              <div
                className="mb-2 mt-2"
                style={{
                  color: '#000',
                  backgroundColor: '#FFE9E9',
                  padding: '10px',
                  border: '1px solid #FF4B51',
                  fontStyle: 'italic',
                }}
              >
                This action will remove the shop from your account. This action cannot be undone.
              </div>
              <SpH5>
                <div>Shop Name: {inventoryItemDetails ? inventoryItemDetails.name : <>&#8211;</>}</div>
              </SpH5>
              <div className="mt-2" style={{ color: '#000' }}>Do you want to proceed?</div>
            </>
          )
        }
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  shopTables: state.inventoryShopsDetails.shopTables,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTablesShops: gid => dispatch(inventoryShopsDetailsActions.fetchTablesShops({
    payload: gid,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(DeleteOrderType);
