// @flow
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Button,
  Icon,
  Alert,
} from 'antd';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';
import type { ContextRouter } from 'react-router-dom';

import Loading from 'components/Loading';

import {
  axiosLoginInstance,
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  SpFormText,
  SpText,
} from 'components/DesignKit';

import {
  PAGES,
  TOKENS,
  MESSAGES,
  ROLES,
} from 'appconstants';
import { validators } from 'utils/validationMessages';

import {
  accountActions,
  authActions,
  userActions,
  notificationActions,
} from 'store/actions';
import {
  ACCOUNT,
  AUTH,
  USER,
  NOTIFICATION,
} from 'store/actionTypes';

// $FlowFixMe
const Wrapper = styled.div`
  background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '511px',
  minHeight: '420px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

type Props = ContextRouter & {
  authenticateUserSuccess: Function,
  fetchAccountSuccess: Function,
  selectedAccount: Object,
  setUser: Function,
  setTestData: Function,
  history: {
    push: Function,
  },
  children: Node,
  error: boolean,
  loading: boolean,
  setNotification: Function,
  logout: Function,
  setSelectedAccount: Function,
  unlock: Function,
};

const UnlockAccount = (props: Props) => {
  const {
    logout,
    history,
    authenticateUserSuccess,
    setUser,
    setTestData,
    setSelectedAccount,
    fetchAccountSuccess,
    setNotification,
    unlock,
  } = props;
  const [, forceUpdate] = useState();
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const email = localStorage.getItem('email');

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    unlock();
    setLoggedIn(false);
  }, []);

  const handleSignIn = () => {
    logout();
    history.push(PAGES.LOGIN);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setLoading(true);
    try {
      const { data: { entity } } = await axiosLoginInstance.post(API_END_POINTS.AUTHENTICATE, {
        email,
        password,
      });

      if (entity.token) {
        localStorage.setItem(TOKENS.ACCESS_TOKEN, entity.token.accessToken);
        localStorage.setItem(TOKENS.REFRESH_TOKEN, entity.token.refreshToken);

        // fetch user
        const { data: { entity: userEntity } } = await axiosAuthInstance.get(API_END_POINTS.USER_PROFILE);
        authenticateUserSuccess(entity.token);
        setUser(userEntity);
        setTestData(userEntity.test);

        // internalRole
        const { internalRole } = userEntity;

        // fetch account
        const { data: { entity: accountEntity } } = await axiosAuthInstance.get(`${internalRole ? 'internal/' : ''}${API_END_POINTS.USER_ACCOUNT}`);
        setLoading(false);
        if (accountEntity && accountEntity.content[0]) {
          setSelectedAccount(accountEntity.content[0]);
          fetchAccountSuccess({
            type: ACCOUNT.SUCCESS,
            payload: accountEntity,
          });
          if (internalRole && internalRole.name === ROLES.SUPERUSER) {
            const selectedGid = accountEntity.content[0].gid;
            const API_END_POINT = `/internal${API_END_POINTS.JWE}/${selectedGid}/switch?isTest=false`;
            const { data: { entity: { token: { accessToken } } } } = await axiosAuthInstance.get(API_END_POINT);
            localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
          }
          if (internalRole && internalRole.name) {
            if (internalRole && internalRole.name === ROLES.SUPERUSER) {
              history.push(PAGES.DASHBOARD);
            } else {
              history.push(PAGES.PAYMENTS);
            }
          } else {
            history.replace(PAGES.DASHBOARD);
          }
        } else {
          history.replace(PAGES.ACCOUNT);
        }
      }
    } catch (e) {
      localStorage.removeItem(TOKENS.ACCESS_TOKEN);
      localStorage.removeItem(TOKENS.REFRESH_TOKEN);
      localStorage.removeItem('fcmToken');
      setError(401);
      setLoading(false);
      if (e.response && e.response.status !== 401) {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
        });
      }
    }
  };

  if (loggedIn) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <div>
        <FormWrapper onSubmit={handleLogin}>
          <div style={{ maxWidth: '510px' }}>
            <SpFormText>YOUR ACCOUNT IS LOCKED</SpFormText>
            <div className="LOGIN" style={{ maxWidth: '350px', margin: '0 auto' }}>
              <div className="my-3">
                <Input.Password
                  placeholder="Your Password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('password', password, 'required',
                    { messages: { required: validators.login.password } })}
                </div>
              </div>
              {
                error === 401 && (
                  <Alert
                    message={MESSAGES.LOGIN.ERROR}
                    type="error"
                    className="mb-2"
                  />
                )
              }
              <div className="d-flex justify-content-center">
                <Button
                  type="primary"
                  shape="round"
                  className="w-50"
                  htmlType="submit"
                  loading={loading}
                  onClick={handleLogin}
                >
                  Unlock
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <SpText fontSize="16px" fontWeight="400">
                Logged in as {email}
              </SpText>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <SpText fontSize="16px" fontWeight="400">
                Not you?
              </SpText>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <Button type="link" onClick={handleSignIn}>
                Login as a different user
              </Button>
            </div>
          </div>
        </FormWrapper>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  error: state.loading.error,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  authenticateUserSuccess: (param) => dispatch(authActions.authenticateUserSuccess({
    type: AUTH.SUCCESS,
    payload: param,
  })),
  setUser: (param) => dispatch(userActions.setUser({
    type: USER.FETCH,
    payload: param,
  })),
  setTestData: (param) => dispatch(accountActions.setTestData({
    payload: param,
  })),
  fetchAccountSuccess: (params) => dispatch(accountActions.fetchAccountSuccess(params)),
  logout: () => dispatch(authActions.logout()),
  unlock: () => dispatch(authActions.unlock()),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setSelectedAccount: account => dispatch(accountActions.setSelectedAccount(account)),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnlockAccount);
