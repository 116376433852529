import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  ACCOUNT_SETLLEMENT,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchAccountSettlement = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=settlementDate&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.ACCOUNT_SETTLEMENT}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.ACCOUNT_SETTLEMENT}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.ACCOUNT_SETTLEMENT}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.ACCOUNT_SETTLEMENT}${query}${sorting}`);
};
const postAccountSettlement = async payload => axiosAuthInstance.post(API_END_POINTS.ADD_ACCOUNT_SETTLEMENT, payload);

const accountSettlementDelete = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.ADD_ACCOUNT_SETTLEMENT}/${gid}`);

export function* getAccountSettlement(action) {
  try {
    const { data: { entity } } = yield call(fetchAccountSettlement, action.payload);
    yield put({ type: ACCOUNT_SETLLEMENT.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: ACCOUNT_SETLLEMENT.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addAccountSettlement(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postAccountSettlement, action.payload);
    const { data: { entity } } = yield call(fetchAccountSettlement, action.payload);
    yield put({ type: ACCOUNT_SETLLEMENT.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACCOUNT_SETTLEMENT.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteAccountSettlement(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(accountSettlementDelete, action.payload);
    const { data: { entity } } = yield call(fetchAccountSettlement, action.payload);
    yield put({ type: ACCOUNT_SETLLEMENT.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACCOUNT_SETTLEMENT.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
