import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  CUSTOM_REPORT,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchCustomReports = async (payload) => {
  if (payload.filterParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.CUSTOMREPORT}?${payload.filterParams}`);
  }
  const gid = payload.gid
    ? payload.gid : payload.params
      ? payload.params.customReportTemplateGid : '';
  const query = generateQuery(payload.currentPage);
  const queryParam = `&customReportTemplateGid.EQ=${gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.CUSTOMREPORT}${query}${queryParam}${sorting}`);
};

const postCustomReport = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.CUSTOMREPORT, params);

export function* getCustomReports(action) {
  try {
    const { data: { entity } } = yield call(fetchCustomReports, action.payload);
    yield put({ type: CUSTOM_REPORT.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: CUSTOM_REPORT.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addCustomReport(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postCustomReport, action.payload);
    const { data: { entity } } = yield call(fetchCustomReports, action.payload);
    yield put({ type: CUSTOM_REPORT.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.REPORT.GENERATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
