import React, { useEffect, useState } from 'react';
import {
  useRouteMatch,
  withRouter,
} from 'react-router-dom';

import { Menu } from 'antd';
import queryString from 'query-string';

import { SpTab } from 'components/DesignKit';
import StaticQrList from './component/StaticQrList';

type Props = {
  location: {
    pathname: string,
    search: string,
  },
  history: {
    push: Function,
    replace: Function,
  },
};

const Payouts = (props: Props) => {
  const [current, setCurrent] = useState('linked');
  const { path } = useRouteMatch();
  const {
    location,
    history,
  } = props;

  const setMenu = (query) => {
    const parsed = queryString.parse(query);
    if (parsed['status.EQ']) {
      const value = parsed['status.EQ'];
      switch (value) {
        case 'NONLINKED': {
          setCurrent('nonlinked');
          break;
        }
        case 'LINKED': {
          setCurrent('linked');
          break;
        }
        default:
          setCurrent('linked');
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      setMenu(location.search);
    } else {
      history.replace(`${path}?status.EQ=LINKED`);
    }
  }, [location]);

  return (
    <div>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="linked">
          <SpTab
            to={`${path}?status.EQ=LINKED`}
            selected={current === 'linked'}
          >
            LINKED
          </SpTab>
        </Menu.Item>
        <Menu.Item key="nonlinked">
          <SpTab
            to={`${path}?status.EQ=NONLINKED`}
            selected={current === 'nonlinked'}
          >
            NON LINKED
          </SpTab>
        </Menu.Item>
      </Menu>
      <StaticQrList {...props} />
    </div>
  );
};

export default withRouter(Payouts);
