// @flow
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Modal,
  Input,
  Icon,
} from 'antd';
import {
  SpH5,
  SpButton,
  SpForm,
  SpError,
} from 'components/DesignKit';
import formatNumber from 'utils/formatNumber';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import applyCashback from 'components/NavIcons/applyCashback';

type Props = {
  walletTransactionDetails: Object,
  wallet: Object,
  visible: boolean,
  close: Function,
  submitting: boolean,
  submit: Function,
  selectedAccount: Object,
};

const ApplyCashBack = (props: Props) => {
  const {
    walletTransactionDetails,
    wallet,
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
  } = props;

  const { prefix } = selectedAccount && selectedAccount.currency;
  const { toFixed } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;
  const maxAvailbleCashbackPoints = walletTransactionDetails
    && walletTransactionDetails.walletTransactionLog
    && walletTransactionDetails.walletTransactionLog.maxRedeemablePoints;
  const availbleCashback = wallet && wallet.availableCashback;
  const [, forceUpdate] = useState();
  const [amount, setAmount] = useState('');
  const [revisedAmount, setRevisedAmount] = useState(walletTransactionDetails && walletTransactionDetails.amount);
  const initialRevisedAmount = walletTransactionDetails && walletTransactionDetails.amount;
  const [step, setStep] = useState(0);
  const [applyPartial, setApplyPartial] = useState(false);
  const revisedPayment = (initialRevisedAmount >= 0)
    ? ((initialRevisedAmount / 100) - (maxAvailbleCashbackPoints / 100))
    : ((initialRevisedAmount / 100) + (maxAvailbleCashbackPoints / 100));

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      applycashback: {
        message: `Cashback amount should be less than are equal to ${prefix}${(maxAvailbleCashbackPoints / 100)}.`,
        rule: (val) => Number(val.replace(/,/g, '')) <= Number(`${(maxAvailbleCashbackPoints / 100)}`.replace(/,/g, '')),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    event.preventDefault();
    if (!applyPartial) {
      setStep(step + 1);
      const amnt = formatAmount(
        (maxAvailbleCashbackPoints / 100),
        currencyType,
      );
      setAmount(amnt);
    } else {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
      } else {
        setStep(step + 1);
      }
    }
  };


  const onSubmit = (event) => {
    event.preventDefault();
    submit({
      amount,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">
          Cancel
        </SpButton>,
        <SpButton onClick={step === 0 ? validate : onSubmit}>{step === 0 ? 'Continue' : 'Apply'}</SpButton>,
      ]}
      title="Apply Cashback"
    >
      {
        (step === 0) && (
          <div className="px-4 py-2 ">
            <div className={submitting ? 'OVERLAY' : ''} />
            <div
              style={{
                width: '470px',
                height: '220px',
                background: '#FFFFFF',
                borderRadius: '13px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
              }}
            >
              <SpForm className="pt-2">
                <Row type="flex" className="mt-2" justify="start" style={{ color: '#3D3F66' }}>
                  <Col
                    span={12}
                    style={{
                      fontSize: '13px',
                      fontWeight: '400',
                      color: '#3D3F66',
                    }}
                  >
                    Maximum Cashback Available<br />
                    For This payment
                  </Col>
                  {
                    maxAvailbleCashbackPoints && (
                      <Col
                        span={12}
                        style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          textAlign: 'end',
                          color: '#2B2C4D',
                        }}
                      >
                        {`${prefix} ${formatNumber(
                          (maxAvailbleCashbackPoints / 100).toFixed(toFixed),
                          currencyType,
                        )}`}
                      </Col>
                    )
                  }
                </Row>

                <hr />
                {
                  !applyPartial ? (
                    <>
                      <div
                        className="mb-2"
                        style={{
                          background: '#E0ECFF',
                          borderRadius: '8px',
                          textAlign: 'center',
                          height: '48px',
                          fontSize: '32px',
                          fontWeight: '600',
                          color: '#2B2C4D',
                        }}
                      >
                        {
                          maxAvailbleCashbackPoints && (
                            <>
                              {`${prefix} ${formatNumber(
                                (maxAvailbleCashbackPoints / 100).toFixed(toFixed),
                                currencyType,
                              )}`}
                            </>
                          )
                        }
                      </div>
                      <Row type="flex" className="mt-2" justify="start" style={{ color: '#3D3F66' }}>
                        <Col
                          span={12}
                          style={{
                            fontSize: '12px',
                            fontWeight: '400',
                            color: '#333333',
                          }}
                        >
                          Partial Cashback can be Applicable
                        </Col>
                        <Col
                          span={12}
                          onClick={() => setApplyPartial(true)}
                          style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            textAlign: 'end',
                            color: '#3E8BFF',
                            cursor: 'pointer',
                          }}
                        >
                          Apply Partial
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <div className="mb-2">
                        <label htmlFor="">
                          <SpH5>Cashback Amount</SpH5>
                        </label>
                        <Input
                          value={amount}
                          prefix={prefix}
                          placeholder="0.00"
                          onChange={(e) => {
                            const regex = /^\d*\.?\d*$/;
                            const { value } = e.currentTarget;
                            if (regex.test(value) || value === '') {
                              setAmount(e.currentTarget.value);
                              if (e.currentTarget.value && initialRevisedAmount) {
                                if (initialRevisedAmount >= 0) {
                                  setRevisedAmount(
                                    initialRevisedAmount - e.currentTarget.value * 100,
                                  );
                                } else {
                                  setRevisedAmount(
                                    initialRevisedAmount + e.currentTarget.value * 100,
                                  );
                                }
                              }
                            } else if (!regex.test(value)) {
                              setAmount(0.0);
                            }
                          }}
                          onKeyPress={(e) => {
                            const keyCode = e.charCode || e.which;
                            if (
                              (keyCode < 48 || keyCode > 57)
                              && keyCode !== 46
                              && keyCode !== 44
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            if (e.currentTarget.value) {
                              const value = formatAmount(
                                e.currentTarget.value,
                                currencyType,
                              );
                              setAmount(value);
                            }
                          }}
                        />
                        <SpError>
                          {simpleValidator.current.message('amount', amount, 'required|applycashback')}
                        </SpError>
                      </div>
                      <Row type="flex" className="mt-2" justify="start" style={{ color: '#3D3F66' }}>
                        <Col
                          span={12}
                          style={{
                            fontSize: '12px',
                            fontWeight: '400',
                            color: '#333333',
                          }}
                        >
                          Partial Cashback can be Applicable
                        </Col>
                        <Col
                          span={12}
                          onClick={() => setApplyPartial(false)}
                          style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            textAlign: 'end',
                            color: '#3E8BFF',
                            cursor: 'pointer',
                          }}
                        >
                          Apply Full
                        </Col>
                      </Row>
                    </>
                  )
                }
              </SpForm>
            </div>
          </div>
        )
      }
      {
        (step === 1) && (
          <div className="px-4 py-2 " style={{ backgroundColor: '#F2F4F7' }}>
            <div className={submitting ? 'OVERLAY' : ''} />
            <div>
              <Row
                align="start"
                style={{
                  height: '126px',
                  width: '470px',
                  background: 'linear-gradient(180deg, #3D3F66 0%, #2B2C4D 100%)',
                  borderRadius: '12px',
                  boxShadow: '0px 3px 10px rgb(0 0 0 / 15%);',
                }}
              >
                <Col
                  className="px-3 pt-2"
                  span={15}
                  style={{ color: '#D0D1F2', textAlign: 'left' }}
                >
                  <p
                    className="mt-2"
                    style={{ fontSize: '26px', fontWeight: '400' }}
                  >
                    {availbleCashback || <>&#8211;</>}
                  </p>
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: '600',
                      marginTop: '-10px',
                    }}
                  >
                    Total Available Cashback Points
                  </p>
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      marginTop: '-10px',
                    }}
                  >
                    {
                      availbleCashback && (
                        <>
                          {`${prefix} ${formatNumber(availbleCashback / 100, currencyType)}`} Total Cashback Amount
                        </>
                      )
                    }
                  </p>
                </Col>
              </Row>

              <div
                className="mt-4"
                style={{
                  width: '470px',
                  height: '250px',
                  background: 'linear-gradient(180deg, #C7DDFF 0%, #FFFFFF 60.51%)',
                  borderRadius: '13px',
                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
                }}
              >
                <div>
                  <Row
                    class="pt-4 mb-4"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Icon
                      component={applyCashback}
                    />
                  </Row>
                  <Row>
                    <Col
                      className="mb-2 ml-3"
                      style={{
                        marginTop: '20px',
                        fontSize: '13px',
                        fontWeight: '700',
                        color: '#3D3F66',
                      }}
                    >
                      Cashback
                    </Col>
                  </Row>
                  <SpForm className="pt-2">
                    <Row type="flex" justify="start" style={{ color: '#3D3F66' }}>
                      <Col
                        span={12}
                        style={{
                          fontSize: '13px',
                          fontWeight: '400',
                          color: '#3D3F66',
                        }}
                      >
                        Original Payment
                      </Col>
                      <Col
                        span={12}
                        style={{
                          fontSize: '13px',
                          fontWeight: '600',
                          textAlign: 'end',
                        }}
                      >
                        {`${prefix} ${formatNumber(
                          (walletTransactionDetails.amount / 100).toFixed(toFixed),
                          currencyType,
                        )}`}
                      </Col>
                    </Row>

                    <Row
                      type="flex"
                      justify="start"
                      style={{ marginTop: '-15px', color: '#3D3F66' }}
                    >
                      <Col
                        span={10}
                        style={{ fontSize: '13px', fontWeight: '400' }}
                      >
                        Revised Payment
                      </Col>
                      <Col
                        span={7}
                        style={{
                          fontSize: '18px',
                          fontWeight: '400',
                          textAlign: 'end',
                          color: '#FF4B51',
                        }}
                      >
                        {
                          (applyPartial === true) ? (
                            `${prefix} ${(amount)}`
                          ) : (
                            `${prefix} ${formatNumber(
                              (maxAvailbleCashbackPoints / 100).toFixed(toFixed),
                              currencyType,
                            )}`
                          )
                        }
                      </Col>
                      <Col
                        span={7}
                        style={{
                          fontSize: '16px',
                          fontWeight: '700',
                          textAlign: 'end',
                        }}
                      >
                        {
                          (applyPartial === true) ? (
                            `${prefix} ${formatNumber(
                              (revisedAmount / 100).toFixed(toFixed),
                              currencyType,
                            )}`
                          ) : (
                            `${prefix} ${formatNumber(
                              (revisedPayment).toFixed(toFixed),
                              currencyType,
                            )}`
                          )
                        }
                      </Col>
                    </Row>
                  </SpForm>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.loading.submitting,
  walletTransactionDetails: state.walletTransactionDetails,
  wallet: state.wallet,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(ApplyCashBack);
