import { call, put } from 'redux-saga/effects';

import {
  axiosSalesForceAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  SALESFORCE,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchSalesforce = async () => axiosSalesForceAuthInstance.get(API_END_POINTS.SALESFORCE_API);

const postSalesforce = async (payload) => axiosSalesForceAuthInstance.post(API_END_POINTS.SALESFORCE_API, payload);

const fetchSpObjectList = async () => axiosSalesForceAuthInstance.get(API_END_POINTS.SPOBJECT);

const patchCredentials = async ({ gid, params }) => axiosSalesForceAuthInstance.patch(`${API_END_POINTS.CREDENTIALS}/${gid}`, params);

const fetchSpField = async (payload) => {
  const queryParam = `?spObject.type.EQ=${payload}`;
  return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SPFIELD}${queryParam}`);
};

const fetchMappingDetails = async (payload) => {
  const queryParam = `?type=${payload}`;
  if (payload) {
    return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SALESFORCE_DETAILS}${queryParam}`);
  } return axiosSalesForceAuthInstance.get(API_END_POINTS.SALESFORCE_DETAILS);
};

const fetchMappingList = async () => axiosSalesForceAuthInstance.get(API_END_POINTS.SALESFORCE_MAPPING);

const postSalesforceMapping = async (payload) => axiosSalesForceAuthInstance.post(API_END_POINTS.SALESFORCE_MAPPING, payload);

const patchSalesforceMapping = async (payload) => axiosSalesForceAuthInstance.put(API_END_POINTS.SALESFORCE_MAPPING, payload);

const deleteSalesforceMappingByGid = async ({ gid }) => axiosSalesForceAuthInstance.delete(`${API_END_POINTS.SALESFORCE_MAPPING_DELETE}/${gid}`);

export function* getSalesforce(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchSalesforce, action.payload);
    yield put({ type: SALESFORCE.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SALESFORCE.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addSalesforce(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postSalesforce, action.payload);
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* getSpObject(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchSpObjectList, action.payload);
    yield put({ type: SALESFORCE.SPOBJECT_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SALESFORCE.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateCredentials(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCredentials, action.payload);
    yield put({ type: LOADING.VALIDATED });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SALESFORCE.UPDATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* getSpField(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchSpField, action.payload);
    yield put({ type: SALESFORCE.SPFIELD_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SALESFORCE.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addSalesforceMapping(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postSalesforceMapping, action.payload);
    const { data: { entity } } = yield call(fetchSalesforce, action.payload);
    yield put({ type: SALESFORCE.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SALESFORCE.ADD_MAPPING_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SALESFORCE.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* getMappingList(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchMappingList, action.payload);
    yield put({ type: SALESFORCE.FETCH_MAPPINGLIST_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SALESFORCE.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getMappingDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchMappingDetails, action.payload);
    yield put({ type: SALESFORCE.FETCH_MAPPINGDETAILS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SALESFORCE.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateSalesforceMapping(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchSalesforceMapping, action.payload);
    yield put({ type: LOADING.VALIDATED });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SALESFORCE.UPDATE_MAPPING_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* deleteSalesforceMapping(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteSalesforceMappingByGid, action.payload);
    const { data: { entity } } = yield call(fetchMappingList, action.payload);
    yield put({ type: SALESFORCE.FETCH_MAPPINGLIST_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SALESFORCE.DELETE_MAPPING_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
