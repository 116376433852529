import React from 'react';
import {
  SpText,
  FlexContainer,
} from 'components/DesignKit';
import success from 'imgs/success.svg';

type Props = {
  isOpen: boolean,
  isUpdateState: boolean,
}

const SuccessPage = (props: Props) => (
  props.isOpen
  && (
    <>
      <div style={{
        background: '#ffffff',
      }}
      >
        <FlexContainer
          height="400px"
          width="500px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          box-shadow="0 0 20px 0 rgba(0, 0, 0, 0)"
          backdropFilter="none"
          borderRadius="4px"
          backgroundColor="#f2f5f8"
          textAlign="center"
          alignContent="none"
        >
          <img alt="" width="108px" height="108px" style={{ paddingBottom: '25px' }} src={success} />
          <div>
            <SpText className="mt-4" fontSize="19px" fontWeight="600">
              {
                props.isUpdateState ? 'Integration updated successfully' : 'Integration Successful'
              }
            </SpText>
          </div>
          <SpText className="mt-4" fontSize="15px" fontWeight="400">
            You will be redirected automatically.
          </SpText>
        </FlexContainer>
      </div>
    </>
  )
);

export default SuccessPage;
