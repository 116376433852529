import { REHYDRATE } from 'redux-persist/lib/constants';

const initialState = {};

export default (state = initialState, action) => {
  if (action.type === REHYDRATE) {
    return {
      ...state,
      persistedState: action.payload,
    };
  }
  return state;
};
