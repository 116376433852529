import { SUBSCRIPTION_BUTTONV2 } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  subscriptionButtonStatus: undefined,
  subscriptionButtonError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_BUTTONV2.FETCH:
      return {
        ...state,
        subscriptionButtonStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case SUBSCRIPTION_BUTTONV2.SUCCESS:
      return {
        ...state,
        ...action.payload,
        subscriptionButtonStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case SUBSCRIPTION_BUTTONV2.FAILED:
      return {
        ...state,
        subscriptionButtonError: action.payload,
        subscriptionButtonStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
