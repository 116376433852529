// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  inventoryItemDetails: Object,
};

const EditDefaultOrder = (props: Props) => {
  const {
    visible,
    close,
    submit,
    inventoryItemDetails,
  } = props;

  const DeleteSelectedItem = () => {
    submit({
      defaultOrderType: !(inventoryItemDetails && inventoryItemDetails.default),
      gid: inventoryItemDetails && inventoryItemDetails.gid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={DeleteSelectedItem}>Update</SpButton>,
      ]}
      title="Update Default Order Type"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to change the default order type?</div>
        <SpH5 className="pb-3">
          <div>{inventoryItemDetails ? inventoryItemDetails.label : <>&#8211;</>}</div>
        </SpH5>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default EditDefaultOrder;
