/* eslint-disable */
import React from 'react';

const walletIcon = ({ fill = "silver" }) => (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="70" cy="70" r="70" fill="#F5F5F5" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M45.694 38C39.7879 38 35 39.0939 35 45V80.776C35 86.6821 39.7879 91.47 45.694 91.47H77.776C83.6821 91.47 88.47 86.6821 88.47 80.776V48.694C88.47 42.7879 83.6821 38 77.776 38L45.694 38Z" fill="#C9C9C9" />
        <rect x="35" y="54.041" width="53.47" height="37.429" fill="#D9D9D9" />
        <rect x="88.47" y="70.082" width="10.694" height="21.388" fill="#D9D9D9" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M35 47.5V96.817C35 102.723 39.7879 107.511 45.694 107.511H88.47C94.3762 107.511 99.164 102.723 99.164 96.817V64.735C99.164 58.8289 94.3762 54.041 88.47 54.041H41.541C37.9285 54.041 35 51.1125 35 47.5Z" fill="#DBDBDB" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M88.4701 70.082C82.5639 70.082 77.7761 74.8699 77.7761 80.776C77.7761 86.6821 82.5639 91.47 88.4701 91.47H101.541C103.181 91.47 104.511 90.14 104.511 88.4994V73.0526C104.511 71.412 103.181 70.082 101.541 70.082H88.4701Z" fill="#F5F5F5" />
    </svg>
);

export default walletIcon;
