import { PAYMENTSBUTTONDETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  payments: [],
  paymentsButtonDetailsStatus: undefined,
  paymentsButtonDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENTSBUTTONDETAILS.FETCH:
      return {
        ...state,
        paymentsButtonDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case PAYMENTSBUTTONDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        paymentsButtonDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case PAYMENTSBUTTONDETAILS.FAILED:
      return {
        ...state,
        paymentsButtonDetailsError: action.payload,
        paymentsButtonDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case PAYMENTSBUTTONDETAILS.PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
      };
    default:
      return state;
  }
};
