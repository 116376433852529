// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  Checkbox,
  DatePicker,
  AutoComplete,
  Icon,
} from 'antd';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import formatNumber from 'utils/formatNumber';
import getDiscountOff from 'utils/getDiscountOff';
import { validators } from 'utils/validationMessages';
import {
  SpH5,
  SpError,
  SpButton,
  SpText,
} from 'components/DesignKit';
import {
  COUNTRIES,
  MAX_AMOUNT,
  RATE_TYPES,
} from 'appconstants';
import getPrefix from 'utils/getPrefix';
import getSortedList from 'utils/getSortedList';

const { Option } = Select;
type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  fetchPlanDetails: Function,
  subscriptionButtonDetails: Object,
  planData: Object,
  updatedCoupon: Object,
  applyCoupon: Function,
  removeCoupon: Function,
  allSelectedTaxrates: Array<Object>,
  removeTax: Function,
  applyTaxRate: Function,
  displayDate: string,
  supportedCurrency: Array,
};

const AddSubscriptionButton = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    fetchPlanDetails,
    planData,
    updatedCoupon,
    applyCoupon,
    removeCoupon,
    allSelectedTaxrates,
    subscriptionButtonDetails,
    removeTax,
    applyTaxRate,
    displayDate,
    supportedCurrency,
  } = props;

  const [, forceUpdate] = useState();
  const currencyType = selectedAccount.currency;
  const [name, setName] = useState(subscriptionButtonDetails && subscriptionButtonDetails.plan && subscriptionButtonDetails.plan.name);
  // eslint-disable-next-line max-len
  const [amount, setAmount] = useState(subscriptionButtonDetails ? formatAmount(subscriptionButtonDetails.planAmount / 100, currencyType) : '');
  const [description, setDescription] = useState(subscriptionButtonDetails && subscriptionButtonDetails.description);
  const [billAmount, setBillAmount] = useState('');
  const [qty, setQty] = useState(subscriptionButtonDetails ? subscriptionButtonDetails.planQuantity : 1);
  const [period, setPeriod] = useState(subscriptionButtonDetails ? subscriptionButtonDetails.planBillingPeriod : 1);
  const [frequency, setFrequency] = useState(subscriptionButtonDetails ? subscriptionButtonDetails.planBillingFrequency : 'MONTH');
  const [redirectUrl, setRedirectUrl] = useState(subscriptionButtonDetails ? (subscriptionButtonDetails.redirectUri) : '');
  const [disableButton, setDisableButton] = useState(false);
  const [dop, setDop] = useState(displayDate || null);
  const [startDate, setStartDate] = useState(false);
  const [apiPlanDate, setApiPlanDate] = useState(null);
  const [totalCount, setCount] = useState(subscriptionButtonDetails ? (subscriptionButtonDetails.planTotalPayments) : null);
  const [dateDisable, setDateDisable] = useState(false);
  const [planId, setPlanId] = useState(subscriptionButtonDetails.plan && subscriptionButtonDetails.plan.gid);
  const currencyObj = selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const plans = getSortedList(planData.planList);
  const [disablePlanDetails, setDisablePlanDetails] = useState(false);
  const [prefix, setPrefix] = useState(subscriptionButtonDetails
    ? (subscriptionButtonDetails.currency && subscriptionButtonDetails.currency.prefix)
    : (selectedAccount.currency && selectedAccount.currency.prefix));
  const [currencyCode, setCurrencyCode] = useState(subscriptionButtonDetails
    ? (subscriptionButtonDetails.currency && subscriptionButtonDetails.currency.name)
    : (selectedAccount.currency && selectedAccount.currency.name));
  const [couponMsg, setCouponMsg] = useState('');
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const disableDate = moment().utc().tz(selectedAccount.timezone).startOf('day')
    .format(dateFormat);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const searchPlan = (value) => {
    fetchPlanDetails(encodeURIComponent(value));
    setName(value);
    setDisablePlanDetails(false);
    const results = plans.filter((item) => (item.name === value));
    if (results && !results[0]) {
      setPlanId('');
    }
  };

  const updatePlan = (value) => {
    const results = plans.filter((item) => (item.name === value));
    if (results && results[0].name) {
      setPlanId(results[0].gid);
      setName(results[0].name);
      setDescription(results[0].description);
      setAmount(formatAmount(results[0].amount / 100, currencyType));
      setPeriod(results[0].billingPeriod);
      setFrequency(results[0].billingFrequency);
      setDisablePlanDetails(true);
    }
  };

  useEffect(() => {
    if (subscriptionButtonDetails && subscriptionButtonDetails.plan && subscriptionButtonDetails.plan.name) {
      setDisablePlanDetails(true);
    }
  }, [subscriptionButtonDetails]);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const onDateChange = (date, dateString) => {
    setDop(dateString);
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    setApiPlanDate(utcDate);
  };

  const onCurrencyChange = (e) => {
    setCurrencyCode(e);
    setPrefix(getPrefix(e));
  };

  const onCheckChange = (e) => {
    const dateChecked = e.target.checked;
    setStartDate(dateChecked);
    const displayFormat = moment().utc().tz(selectedAccount.timezone).startOf('day')
      .format(dateFormat);
    // eslint-disable-next-line
    const selectedDate = moment.utc().tz(selectedAccount.timezone).startOf('day').utc().format(MOMENT_FORMAT);
    setApiPlanDate(selectedDate);
    if (dateChecked) {
      setDateDisable(true);
      setDop(displayFormat);
    } else {
      setDateDisable(false);
      setDop(null);
    }
  };

  const getAmountOff = (value) => {
    let amountOff;
    if (value.percentageOff) {
      const quantity = qty ? parseFloat(qty) : 1;
      if (amount) {
        const discountAmount = ((parseFloat(amount.replace(/,/g, '') * quantity) * value.percentageOff) / 100);
        amountOff = formatNumber((discountAmount).toFixed(2), currencyObj);
      } else if (!amount) {
        const discountAmount = ((parseFloat(billAmount.replace(/,/g, '') * quantity) * value.percentageOff) / 100);
        amountOff = formatNumber((discountAmount).toFixed(2), currencyObj);
      }
    } else if (value.amountOff) {
      amountOff = formatNumber((value.amountOff / 100).toFixed(2), currencyObj);
    }
    return amountOff;
  };

  const getTaxAmount = (taxRate: Object, discAmount) => {
    let taxAmount;
    const quantity = qty ? parseFloat(qty) : 1;
    if (taxRate && taxRate.inclusive) {
      let discountAmount;
      if (amount) {
        if (discAmount) {
          discountAmount = (parseFloat(amount.replace(/,/g, '')) * quantity) - parseFloat(discAmount.replace(/,/g, ''));
        } else if (!discAmount) {
          discountAmount = (parseFloat(amount.replace(/,/g, '')) * quantity);
        }
        const percent = (taxRate.percentage / 100) + 1;
        const amountTax = (discountAmount / percent) * (taxRate.percentage / 100);
        taxAmount = amountTax.toFixed(2);
      } else if (!amount) {
        if (discAmount) {
          discountAmount = (parseFloat(billAmount.replace(/,/g, '')) * quantity) - parseFloat(discAmount.replace(/,/g, ''));
        } else if (!discAmount) {
          discountAmount = (parseFloat(billAmount.replace(/,/g, '')) * quantity);
        }
        const percent = (taxRate.percentage / 100) + 1;
        const amountTax = (discountAmount / percent) * (taxRate.percentage / 100);
        taxAmount = amountTax.toFixed(2);
      }
    } else if (taxRate && !taxRate.inclusive) {
      if (discAmount) {
        if (amount) {
          const discountAmount = (parseFloat(amount.replace(/,/g, '')) * quantity) - parseFloat(discAmount.replace(/,/g, ''));
          taxAmount = formatNumber(((discountAmount * taxRate.percentage) / 100).toFixed(2), currencyObj);
        } else if (!amount) {
          const discountAmount = (parseFloat(billAmount.replace(/,/g, '')) * quantity) - parseFloat(discAmount.replace(/,/g, ''));
          taxAmount = formatNumber(((discountAmount * taxRate.percentage) / 100).toFixed(2), currencyObj);
        }
      } else if (!discAmount) {
        if (amount) {
          const amountDetails = (parseFloat(amount.replace(/,/g, '')) * quantity);
          taxAmount = formatNumber(((amountDetails / 100) * taxRate.percentage).toFixed(2), currencyObj);
        } else if (!amount) {
          const amountDetails = (parseFloat(billAmount.replace(/,/g, '')) * quantity);
          taxAmount = formatNumber(((amountDetails / 100) * taxRate.percentage).toFixed(2), currencyObj);
        }
      }
    }
    return taxAmount;
  };

  const getFinalAmount = (discAmount) => {
    let finalAmount;
    const listOfTaxes = [...allSelectedTaxrates];
    const taxLists = listOfTaxes.filter((item) => item.taxRates.inclusive === false);
    if (taxLists.length > 0) {
      if (discAmount !== 0) {
        const percentageAmount = taxLists.reduce(
          (total, item) => total + ((parseFloat(discAmount.replace(/,/g, '')) * item.taxRates.percentage) / 100), 0,
        );
        finalAmount = formatNumber((parseFloat(discAmount.replace(/,/g, '')) + percentageAmount).toFixed(2), currencyObj);
      } else if (discAmount === 0) {
        const percentageAmount = taxLists.reduce(
          (total, item) => total + ((discAmount * item.taxRates.percentage) / 100), 0,
        );
        finalAmount = formatNumber((discAmount + percentageAmount).toFixed(2), currencyObj);
      }
    } else {
      finalAmount = formatNumber(parseFloat(discAmount.replace(/,/g, '')).toFixed(2), currencyObj);
    }
    return finalAmount;
  };

  const getTotalAmount = (value) => {
    let finalAmount;
    const quantity = qty ? parseFloat(qty) : 1;
    if (value) {
      const finalValue = parseFloat(value.replace(/,/g, ''));
      if (amount) {
        finalAmount = formatNumber(((parseFloat(amount.replace(/,/g, '')) * quantity) - finalValue).toFixed(2), currencyObj);
      } else if (!amount) {
        finalAmount = formatNumber(((parseFloat(billAmount.replace(/,/g, '')) * quantity) - finalValue).toFixed(2), currencyObj);
      }
    } else if (!value) {
      if (amount) {
        finalAmount = formatNumber((parseFloat(amount.replace(/,/g, '')) * quantity), currencyObj);
      } else if (!amount) {
        finalAmount = formatNumber((parseFloat(billAmount.replace(/,/g, '')) * quantity), currencyObj);
      }
    }
    return finalAmount;
  };

  const getSubscriptionButtonAmount = () => {
    let amountForBill;
    if (getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon))) < 0) {
      amountForBill = 0.00;
    } else {
      amountForBill = getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)));
    }
    return amountForBill;
  };

  const removeSelectedCoupon = () => {
    removeCoupon();
    setCouponMsg('');
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    if (getSubscriptionButtonAmount() <= 0) {
      simpleValidator.current.fields.coupon = false;
      setCouponMsg(validators.coupon.validCoupon);
    }
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      amount,
      billAmount,
      description: description || null,
      period,
      totalCount,
      qty,
      dop,
      frequency,
      startDate,
      apiPlanDate,
      redirectUrl,
      planId,
      currencyCode,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="640px"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Subscription Button"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Plan Name</SpH5></label>
          <AutoComplete
            placeholder="Enter a Plan name"
            showSearch
            className="w-100"
            dataSource={plans.map((item, idx) => (
              <Option key={idx} value={item.name}>
                {item.name}
              </Option>
            ))}
            onSearch={searchPlan}
            defaultValue={name}
            onSelect={e => updatePlan(e)}
          >
            <Input.Search enterButton />
          </AutoComplete>
          <SpError>
            {simpleValidator.current.message('plan name', name, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Plan Description</SpH5></label>
          <Input
            placeholder="Optional"
            value={description}
            disabled={disablePlanDetails}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </div>
        <div className="mb-3">
          <div className="d-flex">
            <div style={{ width: '50%' }}>
              <label htmlFor=""><SpH5>Plan Amount</SpH5></label>
              <div>
                <div className="d-flex">
                  <div style={{ width: '75%' }}>
                    <Input
                      value={amount}
                      prefix={prefix}
                      placeholder="0.00"
                      disabled={disablePlanDetails}
                      onChange={(e) => {
                        const regex = /^\d*\.?\d*$/;
                        const { value } = e.currentTarget;
                        if (regex.test(value) || value === '') {
                          setAmount(e.currentTarget.value);
                        } else if (!regex.test(value)) {
                          setAmount(0.00);
                        }
                      }}
                      onKeyPress={e => {
                        const keyCode = e.charCode || e.which;
                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={e => {
                        if (e.currentTarget.value) {
                          const value = formatAmount(e.currentTarget.value, currencyType);
                          setAmount(value);
                          const amt = Number(value.replace(/,/g, ''));
                          setBillAmount(formatAmount((amt * qty), currencyType));
                        }
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('amount', amount, 'required|amount')}
                    </SpError>
                  </div>
                  <div
                    style={{ width: '25%' }}
                  >
                    <Select
                      className="w-100"
                      showSearch
                      defaultValue={currencyCode}
                      disabled={disablePlanDetails}
                      onChange={(e) => onCurrencyChange(e)}
                    >
                      {supportedCurrency.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-5">
              <label htmlFor=""><SpH5>Plan  Quantity</SpH5></label>
              <div style={{ width: '26%', minWidth: '45px' }}>
                <Input
                  value={qty}
                  maxlength="3"
                  onChange={(e) => {
                    const regex = /^[0-9]+$/;
                    const { value } = e.currentTarget;
                    if (regex.test(value) || value === '') {
                      setQty(e.currentTarget.value);
                    } else if (!regex.test(value)) {
                      setQty();
                    }
                  }}
                  onBlur={e => {
                    const val = Number(e.currentTarget.value);
                    let amt;
                    if (amount) {
                      amt = Number(amount.replace(/,/g, ''));
                    }
                    const value = amt ? val * amt : val;
                    if (value >= 0) {
                      setBillAmount(formatAmount(value, currencyType));
                    }
                  }}
                  onKeyPress={e => {
                    const keyCode = e.charCode || e.which;
                    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <SpError>
                {simpleValidator.current.message('quantity', qty, 'required')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="mb-3">
          {
            updatedCoupon.gid ? (
              <div className="d-flex" style={{ width: '75%' }}>
                <div className="pt-1 pb-1" style={{ width: '50%' }}>
                  <SpText>
                    {updatedCoupon.name}
                  </SpText><br />
                  <SpText fontSize="12px">
                    {getDiscountOff(updatedCoupon)}
                  </SpText>
                  <div>
                    <SpError>
                      {couponMsg}
                    </SpError>
                  </div>
                </div>
                <div className="pt-1 pb-1" style={{ width: '15%' }} />
                <div className="pt-1 pb-1 ml-2" style={{ width: '25%' }}>
                  <SpText fontWeight="600">({prefix} {getAmountOff(updatedCoupon)})</SpText>
                </div>
                <div className="pt-1 pb-1 ml-2" style={{ width: '10%' }}>
                  <Icon
                    type="minus-circle"
                    className="mr-2 mt-1"
                    style={{ cursor: 'pointer', color: '#ff4d50' }}
                    onClick={removeSelectedCoupon}
                  />
                </div>
              </div>
            ) : (
              <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                <SpText
                  fontWeight="600"
                  color="#279dfe"
                  onClick={applyCoupon}
                >
                  Apply Coupon
                </SpText>
              </div>
            )
          }
        </div>

        <div className="mb-3">
          {
            allSelectedTaxrates.map((item, i) => (
              <div className="d-flex" style={{ width: '75%' }}>
                <div className="pt-1 pb-1" style={{ width: '50%' }}>
                  <SpText>
                    {item.taxRates.displayName}
                  </SpText><br />
                  <SpText fontSize="12px">
                    {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                  </SpText>
                </div>
                <div className="pt-1 pb-1" style={{ width: '15%' }}>
                  <SpText>
                    {item.taxRates.percentage} %
                  </SpText>
                </div>
                <div className="pt-1 pb-1 ml-2" style={{ width: '25%' }}>
                  <SpText fontWeight="600">{prefix} {getTaxAmount(item.taxRates, getAmountOff(updatedCoupon))}</SpText>
                </div>
                <div className="pt-1 pb-1 ml-2" style={{ width: '10%' }}>
                  <Icon
                    type="minus-circle"
                    className="mr-2 mt-1"
                    style={{ cursor: 'pointer', color: '#ff4d50' }}
                    onClick={() => removeTax(i)}
                  />
                </div>
              </div>
            ))
          }
        </div>

        <div className="mb-3">
          <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
            <SpText
              fontWeight="600"
              color="#279dfe"
              onClick={applyTaxRate}
            >
              {
                allSelectedTaxrates.length !== 0 && (
                  <span>{'\u002B'}&nbsp;</span>
                )
              }
              Add Tax Rates
            </SpText>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Billing Amount</SpH5></label>
          <Input
            placeholder="0.00"
            value={getSubscriptionButtonAmount()}
            prefix={prefix}
            disabled
          />
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Billing Frequency</SpH5></label>
          <div className="d-flex align-items-center" style={{ width: '50%' }}>
            <span>Every</span>
            <div style={{ width: '18%', minWidth: '35px' }}>
              <Input
                className="ml-1"
                value={period}
                maxlength="3"
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setPeriod(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setPeriod();
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div>
              <Select
                className="ml-3"
                style={{ width: '125%' }}
                value={frequency}
                onChange={(e) => setFrequency(e)}
              >
                <Option key={1} value="DAY">Day(s)</Option>
                <Option key={2} value="WEEK">Week(s)</Option>
                <Option key={3} value="MONTH">Month(s)</Option>
                <Option key={4} value="YEAR">Year(s)</Option>
              </Select>
            </div>
          </div>
          <SpError>
            {simpleValidator.current.message('period', period, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <div className="d-flex">
            <div style={{ width: '50%' }}>
              <label htmlFor=""><SpH5>Subscription Start Date (Optional)</SpH5></label>
              <div>
                <DatePicker
                  value={dop ? moment(dop) : ''}
                  disabled={dateDisable}
                  format={dateFormat}
                  allowClear={false}
                  onChange={onDateChange}
                  disabledDate={current => current && current < moment(disableDate)}
                />
              </div>
            </div>
            <div className="ml-4 mt-3">
              <label htmlFor=""> </label>
              <div>
                <Checkbox
                  checked={startDate}
                  onChange={onCheckChange}
                /> Start Today
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3" style={{ width: '50%' }}>
          <label htmlFor=""><SpH5>Total Count (Optional)</SpH5></label>
          <Input
            placeholder="No. of billing cycles to be charged"
            value={totalCount}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              const { value } = e.currentTarget;
              if (regex.test(value) || value === '') {
                setCount(e.currentTarget.value);
              } else if (!regex.test(value)) {
                setCount(null);
              }
            }}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
          />
          <SpError>
            {simpleValidator.current.message('count', totalCount, 'numeric')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Redirect Url (Optional)</SpH5></label>
          <Input
            placeholder="https://www.example.com"
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('redirectUrl', redirectUrl, 'url')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddSubscriptionButton);
