// @flow
import React, { useState, useRef, useEffect } from 'react';
import {
  Input,
  Select,
  Modal,
  Switch,
  AutoComplete,
} from 'antd';
import {
  SpButton,
  SpError,
} from 'components/DesignKit';
import {
  IMEI,
} from 'appconstants';
import SimpleReactValidator from 'simple-react-validator';

const { Option } = Select;

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  getLocations: Function,
  locationList: Array<Object>,
  selectedTerminal: Object,
};

const EditTerminal = (props: Props) => {
  const {
    visible,
    close,
    submit,
    locationList,
    getLocations,
    selectedTerminal,
  } = props;

  const [, forceUpdate] = useState();
  const [terminalName, setTerminalName] = useState(selectedTerminal.deviceName);
  const [locationId, setLocationId] = useState(null);
  const [terminalLocation, setTerminalLocation] = useState('');
  const [isActive, setIsActive] = useState(selectedTerminal.active);
  const imeiRegex = /^[1-9][0-9]{14}$/;
  const gid = selectedTerminal && selectedTerminal.gid;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      imei: {
        message: IMEI.MESSAGE,
        rule: val => imeiRegex.test(val),
      },
    },
  }));

  useEffect(() => {
    if (selectedTerminal.terminalLocation) {
      setLocationId(selectedTerminal.terminalLocation.gid);
      setTerminalLocation(selectedTerminal.terminalLocation.name);
    }
  }, []);

  const searchCustomer = (searchText) => {
    getLocations(searchText);
    setTerminalLocation(searchText);
  };

  const updateLocation = (value) => {
    if (value) {
      const results = locationList.filter((item) => (item.gid === value));
      if (results && results[0].gid) {
        setTerminalLocation(results[0].name);
        setLocationId(results[0].gid);
      } else {
        setLocationId('');
        setTerminalLocation('');
      }
    }
  };

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      terminalName,
      locationId,
      isActive,
      gid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Update</SpButton>,
      ]}
      title="Update Terminal"
    >
      <div className="px-4">
        <div className="mb-3">
          <label htmlFor="" className="font-weight-bold">Device Name</label>
          <Input
            defaultValue={terminalName}
            onChange={(e) => setTerminalName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', terminalName, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor="" className="font-weight-bold">Location</label>
          <AutoComplete
            placeholder="Name"
            showSearch
            className="w-100"
            dataSource={locationList.map((item) => (
              <Option key={item.gid} value={item.gid}>
                {item.name}
              </Option>
            ))}
            onSearch={searchCustomer}
            defaultValue={terminalLocation}
            onSelect={e => updateLocation(e)}
          />
        </div>
        <div className="pb-3">
          <div className="d-flex">
            <label className="d-block font-weight-bold">Active</label>
            <Switch
              className="mx-2"
              defaultChecked={isActive}
              onChange={e => setIsActive(e)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default EditTerminal;
