/* eslint-disable */
import React from 'react';

const toggleOn = () => (
    <svg width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="27" height="14" rx="7" fill="#3E8BFF" />
        <circle cx="20" cy="7" r="6" fill="#242F40" />
    </svg>

);

export default toggleOn;
