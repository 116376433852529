import { PAYMENTLINK_STATUSES } from 'appconstants';

export default value => {
  if (value === 'ACTIVE') {
    return PAYMENTLINK_STATUSES.ACTIVE;
  } if (value === 'PAID') {
    return PAYMENTLINK_STATUSES.PAID;
  } if (value === 'EXPIRED') {
    return PAYMENTLINK_STATUSES.EXPIRED;
  } if (value === 'DECLINED') {
    return PAYMENTLINK_STATUSES.DECLINED;
  }
  return PAYMENTLINK_STATUSES.CANCELLED;
};
