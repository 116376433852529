import { PRINTER_LABEL_DETAILS } from '../actionTypes';

const initialState = {
  content: [],
  printerLabelItemsList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PRINTER_LABEL_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case PRINTER_LABEL_DETAILS.LABEL_ITEM_SUCCESS:
      return {
        ...state,
        printerLabelItemsList: action.payload,
      };
    default:
      return state;
  }
};
