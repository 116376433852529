// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Tooltip,
  Row,
  Col,
  Icon,
} from 'antd';
import {
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';

import getReferralBackground from 'utils/getReferralBackground';
import getReferralStatus from 'utils/getReferralStatus';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';

import {
  PAGES,
  ROLES,
  MESSAGES,
} from 'appconstants';
import {
  referralActions,
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import Clipboard from 'components/NavIcons/copyclipboard';
import RBAC from 'components/rbac';
import AddReferral from './components/addReferral';

type Props = {
  fetchReferral: Function,
  referrals: Array<Object>,
  loading: boolean,
  test: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  addReferral: Function,
  validator: boolean,
  notifyReferral: Function,
  setNotification: Function,
};

const Referrals = (props: Props) => {
  const [pagination, setPagination] = useState({});
  const location = useLocation();
  const {
    loading,
    fetchReferral,
    totalElements,
    referrals,
    test,
    history,
    selectedAccount,
    addReferral,
    validator,
    notifyReferral,
    setNotification,
  } = props;
  const [addReferralModal, setAddReferralModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');

  const debouncedFetchReferral = debounce(fetchReferral, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    debouncedFetchReferral({ currentPage, sortParams });
  };

  useEffect(() => {
    getData(location.search);
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [test, selectedAccount, location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [referrals]);

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.REFERRAL.REFERRAL_SUCCESS,
    });
  };

  useEffect(() => {
    if (!validator) {
      setAddReferralModal(false);
    }
  }, [validator]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: PAGES.REFERRALS,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const addNewReferral = (referral: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      name,
      email,
      phoneNumber,
      countryCode,
      notificationType,
    } = referral;
    const params = {
      name,
      phoneNumber: `${countryCode}${phoneNumber}`,
      email,
      notificationType,
    };
    addReferral({
      params,
      currentPage,
      sortParams,
    });
  };

  const sendNotification = (record: Object) => {
    const {
      gid,
      name,
      notificationType,
    } = record;
    const params = {
      type: notificationType,
    };
    notifyReferral({
      params,
      gid,
      name,
    });
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '18%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'PHONE',
      dataIndex: 'phoneNumber',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'CODE',
      dataIndex: 'referralCode',
      width: '15%',
      align: 'left',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '12%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getReferralBackground(status) }}>
              {getReferralStatus(status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      width: '15%',
      align: 'left',
      render: (text, record) => (
        <span>
          <Tooltip placement="top" title="Copy URL">
            <SpButton
              type="secondary"
              borderRadius="50px"
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); copyCode(record.referralCodeUrl); }}
            >
              <Clipboard />
            </SpButton>
          </Tooltip>
          <Tooltip placement="top" title="Notify">
            <SpButton
              type="secondary"
              borderRadius="50px"
              className="ml-3"
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); sendNotification(record); }}
            >
              <Icon
                type="mail"
                style={{ fontSize: '20px', cursor: 'pointer' }}
              />
            </SpButton>
          </Tooltip>
        </span>
      ),
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      {
        test
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <h5>Referrals are only available for live data</h5>
              </div>
            </Card>
          )
          : (
            <Card>
              <Row className="my-2">
                <div className="d-flex justify-content-end">
                  <RBAC
                    permit={
                      [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                        ROLES.OPERATIONS, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.SUPPORT, ROLES.ONBOARDING]
                    }
                  >
                    <Col>
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-3"
                        ghost
                        onClick={() => setAddReferralModal(true)}
                      >
                        {'\u002B'}&nbsp;Add Referral
                      </SpButton>
                    </Col>
                  </RBAC>
                </div>
              </Row>

              {/* ADD REFERRAL MODAL */}
              {addReferralModal && (
                <AddReferral
                  visible={addReferralModal}
                  phoneCode={phoneCode}
                  close={() => setAddReferralModal(false)}
                  submit={addNewReferral}
                />
              )}

              <Table
                columns={columns}
                rowKey={(record) => record.gid}
                dataSource={referrals}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          )
      }
    </>

  );
};

const mapStateToProps = (state) => ({
  referrals: state.referral.content,
  loading: state.loading.loading,
  totalElements: state.referral.totalElements,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchReferral: param => dispatch(referralActions.fetchReferral({
    payload: param,
  })),
  addReferral: param => dispatch(referralActions.addReferral({
    payload: param,
  })),
  notifyReferral: param => dispatch(referralActions.notifyReferral({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Referrals);
