// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Icon,
} from 'antd';
import {
  SpText,
  SpButton,
  Line,
  SpStatusTag,
  SpWrapper1,
  SpFormWrapper1,
  SpFormWrapper2,
  SpH5,
} from 'components/DesignKit';
import formatNumber from 'utils/formatNumber';
import formatAmount from 'utils/formatAmount';
import getTaxAmount from 'utils/getTaxAmount';
import getDiscountOff from 'utils/getDiscountOff';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import _get from 'lodash.get';
import {
  INVOICE_TYPES,
  MESSAGES,
  RATE_TYPES,
} from 'appconstants';
import {
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import getInvoiceBackground from 'utils/getInvoiceBackground';
import getInvoiceStatus from 'utils/getInvoiceStatus';
import Download from 'components/NavIcons/downloadIcon';
import Clipboard from 'components/NavIcons/copyclipboard';
import swirepaylogo from 'components/NavIcons/swirepay';
import TimelineImg from 'components/NavIcons/Timeline';
import InvoiceTimelineBar from 'components/InvoiceTimelineBar';
import PosLine from 'imgs/line.svg';
import CancelInvoice from './CancelInvoice';
import RefundInvoice from './RefundInvoice';

type Props = {
  invoiceDetails: Object,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  sendInvoice: Function,
  cancelInvoice: Function,
  submitting: boolean,
  validator: boolean,
  invoiceDownload: Function,
  createNewInvoice: Function,
  setNotification: Function,
  displayAmount: Object,
  refundInvoice: Function,
};

const amountFilter = (invoiceDetails) => invoiceDetails;

const displayAmountSelector = createSelector(
  [amountFilter],
  (invoiceDetails) => ({
    prefix: _get(invoiceDetails, 'currency.prefix', null),
    refundDisplay:
      formatNumber(
        invoiceDetails.amountRefunded && (invoiceDetails.amountRefunded / 100).toFixed(invoiceDetails.currency.toFixed),
        invoiceDetails.currency,
      ),
    refundAmount: (invoiceDetails.amount && invoiceDetails.amountRefunded)
      ? formatNumber(((invoiceDetails.amount / 100) - (invoiceDetails.amountRefunded / 100)), invoiceDetails.currency)
      : formatNumber(invoiceDetails.amount / 100, invoiceDetails.currency),
  }),
);

const ViewInvoice = (props: Props) => {
  const {
    invoiceDetails,
    selectedAccount,
    sendInvoice,
    cancelInvoice,
    submitting,
    validator,
    invoiceDownload,
    createNewInvoice,
    setNotification,
    displayAmount,
    refundInvoice,
  } = props;

  const [cancelInvoiceModal, setCancelInvoiceModal] = useState(false);
  const [refundInvoiceModal, setRefundInvoiceModal] = useState(false);
  const invoiceLineItems = invoiceDetails.invoiceLineItems ? invoiceDetails.invoiceLineItems : [];
  const totalAmount = invoiceDetails && (invoiceDetails.amount / 100);
  const currencyType = selectedAccount && selectedAccount.currency;
  const allSelectedTaxrates = invoiceDetails.taxAmount ? invoiceDetails.taxAmount : [];
  const currencyPrefix = selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix;
  const currencyObj = selectedAccount && selectedAccount.currency;
  const updatedCoupon = invoiceDetails.coupon ? invoiceDetails.coupon : {};
  const shippingAmount = invoiceDetails && invoiceDetails.shippingAmount > 0 && (invoiceDetails.shippingAmount / 100);

  useEffect(() => {
    if (!validator) {
      setCancelInvoiceModal(false);
      setRefundInvoiceModal(false);
    }
  }, [validator]);

  const updateInvoice = () => {
    sendInvoice();
  };

  const deleteInvoice = () => {
    cancelInvoice();
  };

  const downloadPdf = () => {
    invoiceDownload();
  };

  const createInvoice = () => {
    createNewInvoice();
  };

  const refundedInvoice = (data: object) => {
    refundInvoice(data);
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.SUCCESS,
    });
  };
  const getAmountWithShippingFee = (amount) => {
    if (shippingAmount) {
      const numericAmount = parseFloat(amount.replace(/,/g, '')) || 0;
      const numericShippingAmount = parseFloat(shippingAmount) || 0;
      const addedShippingAmount = numericAmount + numericShippingAmount;
      return addedShippingAmount.toFixed(2);
    }
    return amount;
  };

  const getAmountOff = (value) => {
    let amountOff;
    if (value.percentageOff) {
      const amount = (totalAmount * value.percentageOff) / 100;
      amountOff = formatNumber((amount).toFixed(2), currencyObj);
    } else if (value.amountOff) {
      amountOff = formatNumber((value.amountOff / 100).toFixed(2), currencyObj);
    }
    return amountOff;
  };

  const getTotalAmount = (value) => {
    let finalAmount;
    if (value) {
      const finalValue = parseFloat(value.replace(/,/g, ''));
      finalAmount = formatNumber((totalAmount - finalValue).toFixed(2), currencyObj);
    } else {
      finalAmount = formatNumber((totalAmount).toFixed(2), currencyObj);
    }
    return finalAmount;
  };

  const getFinalAmount = (amount) => {
    let finalAmount;
    const listOfTaxes = [...allSelectedTaxrates];
    const taxLists = listOfTaxes.filter((item) => item.taxRates.inclusive === false);
    if (taxLists.length > 0) {
      const percentageAmount = taxLists.reduce(
        (total, item) => total + ((parseFloat(amount.replace(/,/g, '')) * item.taxRates.percentage) / 100), 0,
      );
      finalAmount = formatNumber((parseFloat(amount.replace(/,/g, '')) + percentageAmount).toFixed(2), currencyObj);
    } else {
      finalAmount = amount;
    }
    return finalAmount;
  };

  return (
    <Card>
      <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
        <Col className="d-flex align-items-center">
          <SpText className="mr-5" fontSize="20px" fontWeight="600">INVOICE # {invoiceDetails.invoiceNumber}</SpText>
          <SpStatusTag style={{ backgroundColor: getInvoiceBackground(invoiceDetails.status), height: '21px' }}>
            {getInvoiceStatus(invoiceDetails.status)}
          </SpStatusTag>
        </Col>
        <Col>
          <SpButton
            type="secondary"
            borderRadius="50px"
            style={{ cursor: 'pointer' }}
            className="mr-4"
            onClick={() => copyCode(invoiceDetails.invoiceLinks[0].link)}
          >
            <Clipboard />
          </SpButton>
          <Icon
            style={{
              cursor: 'pointer',
              marginRight: '21px',
            }}
            component={Download}
            onClick={downloadPdf}
          />
          <SpButton
            type="secondary"
            shape="round"
            className="mr-4"
            ghost
            onClick={createInvoice}
          >
            Duplicate Invoice
          </SpButton>
          {
            (invoiceDetails.status === INVOICE_TYPES.PAID || invoiceDetails.status === INVOICE_TYPES.PARTIALLY_REFUNDED) && (
              <>
                <SpButton
                  type="secondary"
                  shape="round"
                  className="mr-4"
                  ghost
                  onClick={() => setRefundInvoiceModal(true)}
                >
                  Refund
                </SpButton>
              </>
            )
          }
          {
            invoiceDetails.status === INVOICE_TYPES.ACTIVE && !invoiceDetails.qbInvoiceId && (
              <>
                <SpButton
                  type="secondary"
                  shape="round"
                  className="mr-4"
                  ghost
                  onClick={() => setCancelInvoiceModal(true)}
                >
                  Cancel Invoice
                </SpButton>
                <SpButton
                  type="primary"
                  shape="round"
                  className="mr-4"
                  onClick={updateInvoice}
                >
                  Resend Invoice
                </SpButton>
              </>
            )
          }
          {
            invoiceDetails.status === INVOICE_TYPES.PAST_DUE && (
              <>
                <SpButton
                  type="secondary"
                  shape="round"
                  className="mr-4"
                  ghost
                  onClick={() => setCancelInvoiceModal(true)}
                >
                  Cancel Invoice
                </SpButton>
                <SpButton
                  type="primary"
                  shape="round"
                  className="mr-4"
                  onClick={updateInvoice}
                >
                  Resend Invoice
                </SpButton>
              </>
            )
          }
        </Col>
      </Row>
      {/* CANCEL INVOICE MODAL */}
      {cancelInvoiceModal && (
        <CancelInvoice
          visible={cancelInvoiceModal}
          invoiceNo={invoiceDetails.invoiceNumber}
          submitting={submitting}
          close={() => setCancelInvoiceModal(false)}
          submit={deleteInvoice}
        />
      )}
      {/* REFUND INVOICE MODAL */}
      {refundInvoiceModal && (
        <RefundInvoice
          visible={refundInvoiceModal}
          invoiceNo={invoiceDetails.invoiceNumber}
          invoiceDetails={invoiceDetails}
          submitting={submitting}
          selectedAccount={selectedAccount}
          refundAmount={formatAmount((invoiceDetails.total / 100), currencyType)}
          close={() => setRefundInvoiceModal(false)}
          submit={refundedInvoice}
        />
      )}
      <Row type="flex">
        <Col span={17}>
          <SpWrapper1>
            <SpFormWrapper1>
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center">
                  {
                    selectedAccount && selectedAccount.largeLogo ? (
                      <img src={selectedAccount.largeLogo} alt={selectedAccount.name} style={{ width: '108px', height: '74px' }} />
                    ) : (
                      <SpText className="mr-5" fontSize="20px" fontWeight="600">{selectedAccount.name}</SpText>
                    )
                  }
                </Col>
                <Col className="d-flex">
                  <div className="mr-2 mt-3">
                    <SpText color="rgba(0, 0, 0, 0.7)">Powered by</SpText>
                  </div>
                  <Icon
                    style={{
                      fontSize: '24px',
                    }}
                    component={swirepaylogo}
                  />
                </Col>
              </Row>
              <Row type="flex" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center" span={12}>
                  <SpText className="mr-2" fontSize="18px">INVOICE No.</SpText>
                  <SpText fontSize="18px" fontWeight="600">{invoiceDetails.invoiceNumber}</SpText>
                </Col>
              </Row>
              <Row type="flex" className="my-2 mb-3 mt-2">
                <Col span={12}>
                  <div className="pt-1 pb-1"><SpText fontWeight="600">Description</SpText></div>
                  <div className="pt-1 pb-1 pr-5"><SpText color="#434343">{invoiceDetails.description}</SpText></div>
                </Col>
                <Col span={12}>
                  <div className="pt-1 pb-1"><SpText fontWeight="600">Payment Due Date</SpText></div>
                  <div className="pt-1 pb-1">
                    <SpText color="#434343">
                      {
                        invoiceDetails.paymentDueDate && selectedAccount
                          ? (
                            moment
                              .utc(invoiceDetails.paymentDueDate)
                              .tz(selectedAccount.timezone)
                              .format('MMM DD, YYYY')
                          )
                          : <>&#8211;</>
                      }
                    </SpText>
                  </div>
                </Col>
              </Row>
              <Row type="flex" className="my-2 mb-3 mt-2">
                <Col span={12}>
                  <div className="pt-1 pb-1"><SpText fontWeight="600">Bill to</SpText></div>
                  <div className="pt-1 pb-1"><SpText color="#434343">{invoiceDetails.customer.name}</SpText></div>
                </Col>
                <Col span={12}>
                  <div className="pt-1 pb-1"><SpText fontWeight="600">Issue Date</SpText></div>
                  <div className="pt-1 pb-1">
                    <SpText color="#434343">
                      {
                        invoiceDetails.issueDate && selectedAccount
                          ? (
                            moment
                              .utc(invoiceDetails.issueDate)
                              .tz(selectedAccount.timezone)
                              .format('MMM DD, YYYY')
                          )
                          : <>&#8211;</>
                      }
                    </SpText>
                  </div>
                </Col>
              </Row>
              <Row type="flex" className="my-2 mb-3 mt-2">
                <Col span={12}>
                  <div className="pt-1 pb-1"><SpText fontWeight="600">Billing Address</SpText></div>
                  <div className="pt-1 pb-1 pr-5">
                    {
                      invoiceDetails.billingAddress && invoiceDetails.billingAddress.street ? (
                        <SpText color="#434343">
                          {`${invoiceDetails.billingAddress.street}, ${invoiceDetails.billingAddress.city}, 
                      ${invoiceDetails.billingAddress.state} ${invoiceDetails.billingAddress.postalCode}`}
                        </SpText>
                      ) : (
                        <SpText color="#434343">
                          N/A
                        </SpText>
                      )
                    }
                  </div>
                </Col>
                <Col span={12}>
                  <div className="pt-1 pb-1"><SpText fontWeight="600">Shipping Address</SpText></div>
                  <div className="pt-1 pb-1 pr-5">
                    {
                      invoiceDetails.shippingAddress && invoiceDetails.shippingAddress.street ? (
                        <SpText color="#434343">
                          {`${invoiceDetails.shippingAddress.street}, ${invoiceDetails.shippingAddress.city}, 
                      ${invoiceDetails.shippingAddress.state} ${invoiceDetails.shippingAddress.postalCode}`}
                        </SpText>
                      ) : (
                        <SpText color="#434343">
                          N/A
                        </SpText>
                      )
                    }
                  </div>
                </Col>
              </Row>
              {
                invoiceDetails.recipientsEmail && invoiceDetails.recipientsEmail.length !== 0 ? (
                  <>
                    <Row type="flex">
                      <Col span={12}>
                        <div className="pt-1 pb-1">
                          <SpText fontWeight="600">Recipient List</SpText>
                        </div>
                      </Col>
                    </Row>
                    {(invoiceDetails.recipientsEmail).map((item) => (
                      <Row>
                        <Col span={12}>
                          <div className="pb-1"><SpText>{item}</SpText></div>
                        </Col>
                      </Row>
                    ))}
                  </>
                ) : null
              }

              <Row type="flex" className="my-2 mb-3 mt-2" style={{ background: '#eeeeff', padding: '5px' }}>
                <Col span={12}>
                  <div className="pt-1 pb-1 pl-2"><SpText fontWeight="600">Description</SpText></div>
                </Col>
                <Col span={12}>
                  <Row type="flex" justify="end">
                    <Col span={6} className="pt-1 mr-3" align="center">
                      <SpText fontWeight="600" fontSize="14px">Rate</SpText>
                    </Col>
                    <Col span={6} className="pt-1 mr-3" align="center">
                      <SpText fontWeight="600" fontSize="14px">Quantity</SpText>
                    </Col>
                    <Col span={6} className="pt-1 mr-3" align="right">
                      <SpText fontWeight="600" fontSize="14px">Total</SpText>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" className="my-2 mb-3 mt-2" style={{ padding: '0 5px' }}>
                {invoiceLineItems.map((item) => (
                  <>
                    <Col span={12}>
                      <div className="pt-1 pb-1 pl-2"><SpText>{item.name}</SpText></div>
                    </Col>
                    <Col span={12}>
                      <Row type="flex" justify="end">
                        <Col span={6} className="mr-3" align="center">
                          <SpText fontWeight="600">{invoiceDetails.currency.prefix} {(item.amount / 100).toFixed(2)}</SpText>
                        </Col>
                        <Col span={6} className="mr-3" align="center">
                          <SpText fontWeight="600">{item.quantity}</SpText>
                        </Col>
                        <Col span={6} className="mr-3" align="right">
                          <SpText fontWeight="600">{invoiceDetails.currency.prefix}
                            {formatNumber(((item.amount / 100) * (item.quantity)).toFixed(2), currencyType)}
                          </SpText>
                        </Col>
                      </Row>
                    </Col>
                  </>
                ))}
              </Row>
              <Row type="flex" justify="end" className="ml-5 mb-2">
                {updatedCoupon.gid && (
                  <Col span={14}>
                    <Row type="flex" justify="end">
                      <Col span={12} className="pt-1 ml-3" align="left">
                        <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                          <SpText>
                            {updatedCoupon.name}
                          </SpText><br />
                          <SpText fontSize="12px">
                            {getDiscountOff(updatedCoupon)}
                          </SpText>
                        </div>
                      </Col>
                      <Col span={6} className="pt-2 ml-5 mr-3" align="right">
                        <SpText fontWeight="600">({currencyPrefix} {getAmountOff(updatedCoupon)})</SpText>
                      </Col>
                      <Col span={2} className="pt-1" align="right" />
                    </Row>
                  </Col>
                )}
              </Row>
              <Row type="flex" justify="end" className="ml-5">
                {
                  allSelectedTaxrates.map((item) => (
                    <Col span={14}>
                      <Row type="flex" justify="end">
                        <Col span={9} className="pt-1 ml-3" align="left">
                          <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                            <SpText>
                              {item.taxRates.displayName}
                            </SpText><br />
                            <SpText fontSize="12px">
                              {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                            </SpText>
                          </div>
                        </Col>
                        <Col span={3} className="pt-1" align="right">{item.taxRates.percentage} %</Col>
                        <Col span={6} className="pt-2 ml-5 mr-3" align="right">
                          <SpText fontWeight="600">
                            {currencyPrefix} {getTaxAmount(item.taxRates, totalAmount, getAmountOff(updatedCoupon), selectedAccount)}
                          </SpText>
                        </Col>
                        <Col span={2} className="pt-1" align="right" />
                      </Row>
                    </Col>
                  ))
                }
              </Row>
              {shippingAmount > 0 && (
              <Row type="flex" justify="end" className="ml-5 mb-2">
                <Col span={14}>
                  <Row type="flex" justify="end">
                    <Col span={12} className="pt-1 ml-3" align="left">
                      <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                        <SpText>
                          Shipping Fee
                        </SpText>
                      </div>
                    </Col>
                    <Col span={6} className="pt-2 ml-5 mr-3" align="right">
                      <SpText fontWeight="600">{currencyPrefix} {shippingAmount}</SpText>
                    </Col>
                    <Col span={2} className="pt-1" align="right" />
                  </Row>
                </Col>
              </Row>
              )}
              <Line opacity="0.3" className="mt-1 mb-1" />
              <Row type="flex" justify="end" className="mr-3">
                <Col className="mt-1 pb-1">
                  <SpText className="mr-5" fontWeight="500" fontSize="20px">
                    Total Amount
                  </SpText>
                  <SpText fontWeight="600" fontSize="20px">
                    {
                      (getAmountWithShippingFee(getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon))))) < 0 ? (
                        `${currencyPrefix} 0.00`
                      ) : (
                        `${currencyPrefix} ${getAmountWithShippingFee(getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon))))}`
                      )
                    }
                  </SpText>
                </Col>
              </Row>
              <Line />
              <Row className="my-2 mb-3 mt-2">
                <Col>
                  <div className="pt-1 pb-1"><SpText fontWeight="600">Notes</SpText></div>
                  <div className="pt-1 pb-1">
                    <SpText color="#434343">
                      {invoiceDetails.customerNote}
                    </SpText>
                  </div>
                </Col>
              </Row>
              <Row className="my-2 mb-3 mt-2" style={{ background: '#eeeeff', padding: '5px' }}>
                <Col>
                  <SpText fontWeight="600">{selectedAccount.name}</SpText>
                </Col>
              </Row>
            </SpFormWrapper1>
          </SpWrapper1>
        </Col>
        <Col span={7}>
          <SpWrapper1>
            <SpFormWrapper2>
              <Row type="flex">
                <Icon
                  component={TimelineImg}
                />
                <SpText
                  className="ml-2"
                  fontWeight="600"
                  fontSize="16px"
                  color="#474973"
                  style={{
                    marginTop: '-4px',
                  }}
                >
                  Timeline
                </SpText>
              </Row>
              <Row className="mt-1">
                <img src={PosLine} alt="" />
              </Row>
              <Row>
                <InvoiceTimelineBar
                  paymentTimeline={invoiceDetails.invoiceTimeLines || []}
                  selectedAccount={selectedAccount}
                />
              </Row>
            </SpFormWrapper2>
          </SpWrapper1>
        </Col>
      </Row>
      <Row type="flex" justify="end" gutter={[16, 16]} className="pt-3">
        <Col>
          <SpH5 color="#7f7f7f">{invoiceDetails.gid}</SpH5>
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  invoiceDetails: state.invoiceDetails.invoice,
  invoiceDetailsStatus: state.invoiceDetails.invoiceDetailsStatus,
  loading: state.loading.loading,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  displayAmount: displayAmountSelector(state.invoiceDetails.invoice),
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoice);
