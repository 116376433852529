/* eslint-disable */
import React from 'react';

const terminal = ({ fill = "silver" }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.77319 0V2.34058H1.65C0.738729 2.34058 0 3.07931 0 3.99058V12.9548H1.3V3.99058C1.3 3.79728 1.4567 3.64058 1.65 3.64058H2.77319V4.52667C2.50063 4.52936 2.27977 4.75039 2.27827 5.02392C2.27677 5.30006 2.4994 5.52513 2.77554 5.52664L10.2246 5.56733C10.5008 5.56884 10.7258 5.34621 10.7274 5.07007C10.7289 4.79393 10.5062 4.56885 10.2301 4.56734L10.2264 4.56732V3.64058H11.3514C11.5447 3.64058 11.7014 3.79728 11.7014 3.99058V12.9548H13.0014V3.99058C13.0014 3.07931 12.2627 2.34058 11.3514 2.34058H10.2264V0H2.77319ZM8.92642 1.3H4.07319V3.78074H8.92642V1.3Z" fill={fill} />
      <path d="M2.42566 11.4091H4.03678V12.9548H2.42566V11.4091Z" fill={fill} />
      <path d="M7.28983 11.4091H5.67871V12.9548H7.28983V11.4091Z" fill={fill} />
      <path d="M8.93164 11.4091H10.5428V12.9548H8.93164V11.4091Z" fill={fill} />
      <path d="M2.92566 6.92798C2.64952 6.92798 2.42566 7.15184 2.42566 7.42798V9.88816C2.42566 10.1643 2.64952 10.3882 2.92566 10.3882H10.0428C10.3189 10.3882 10.5428 10.1643 10.5428 9.88816V7.42798C10.5428 7.15184 10.3189 6.92798 10.0428 6.92798H2.92566Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="13" height="13" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default terminal;
