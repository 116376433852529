import { INVENTORY_ORDERTYPES } from '../actionTypes';

export const fetchInventoryOrderTypes = (action) => ({
  type: INVENTORY_ORDERTYPES.FETCH,
  payload: action.payload,
});

export const updateInventoryOrderTypes = (action) => ({
  type: INVENTORY_ORDERTYPES.UPDATE,
  payload: action.payload,
});

export const addInventoryOrderTypes = (action) => ({
  type: INVENTORY_ORDERTYPES.ADD,
  payload: action.payload,
});

export const deleteInventoryOrderTypes = (action) => ({
  type: INVENTORY_ORDERTYPES.DELETE,
  payload: action.payload,
});
