// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Row,
  Col,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpButton } from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
};

const AddGlobalRestrictedKey = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const [isPos, setIsPos] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const isPosChange = (value) => {
    setIsPos(value);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      isPos,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add</SpButton>,
      ]}
      title="Add GR Key"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <Row className="mt-3">
          <Col>
            <Switch
              className="mr-2"
              checked={isPos}
              onChange={isPosChange}
            /> POS Driven Reconcilation
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddGlobalRestrictedKey);
