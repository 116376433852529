import React, { useState, useRef } from 'react';
import {
  Row,
  Col,
  Select,
  Button,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpButton,
  SpH5,
} from 'components/DesignKit';

const { Option } = Select;

type Props = {
  internalAccount: Object,
  submit: Function,
  openModal: Function,
};

const Mdr = (props: Props) => {
  const {
    internalAccount,
    submit,
    openModal,
  } = props;

  const [, forceUpdate] = useState();
  const [mdrName, setMdrName] = useState(internalAccount && internalAccount.mdr);
  const [disabled, setDisabled] = useState(true);
  const simpleValidator = useRef(new SimpleReactValidator());

  const updateMdr = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      mdrName,
    });
  };

  const reset = () => {
    setMdrName(internalAccount && internalAccount.mdr);
    setDisabled(true);
    openModal(false);
  };

  return (
    <>
      <div>
        <Row>
          <Col span={3} className="mb-2">
            <SpH5>MDR</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              value={mdrName}
              className="w-100"
              onChange={value => {
                setMdrName(value);
                setDisabled(false);
                openModal(true);
              }}
              showSearch
            >
              <Option key={1} value="MERCHANT_MDR">Merchant</Option>
              <Option key={2} value="CUSTOMER_MDR">Customer</Option>
              <Option key={3} value="CUSTOMER_MDR_PRE_CALC">Customer Pre Calc</Option>
            </Select>
          </Col>
        </Row>
        <Row className="mt-4" style={{ marginLeft: '-15px' }}>
          <Col span={2}>
            <Button
              type="link"
              onClick={reset}
              disabled={disabled}
            >
              Reset
            </Button>
          </Col>
          <Col span={2}>
            <SpButton
              type="primary"
              shape="round"
              disabled={disabled}
              onClick={updateMdr}
            >
              Update
            </SpButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(Mdr);
