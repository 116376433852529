/* eslint-disable */
import React from 'react';

const downloadDisable = () => (
    <svg width="29" height="33" viewBox="0 0 29 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 3.5C1 2.39543 1.89543 1.5 3 1.5H13.7846C14.5914 1.5 15.3642 1.82498 15.9286 2.40158L18.444 4.9716C18.9927 5.53225 19.3 6.28553 19.3 7.07002V20.7424C19.3 22.3993 17.9569 23.7424 16.3 23.7424H3C1.89543 23.7424 1 22.847 1 21.7424V3.5Z" stroke="#C7C7C7" stroke-width="2" />
        <line x1="3.86664" y1="5.31818" x2="14.5" y2="5.31818" stroke="#C7C7C7" stroke-width="2" />
        <line x1="3.86664" y1="9.19696" x2="16.4333" y2="9.19696" stroke="#C7C7C7" stroke-width="2" />
        <rect x="14.0333" y="13.6061" width="11.5667" height="16.4545" fill="white" stroke="#C7C7C7" />
        <path d="M28 21.8334C28 27.1751 23.6841 31.5 18.3667 31.5C13.0493 31.5 8.73334 27.1751 8.73334 21.8334C8.73334 16.4916 13.0493 12.1667 18.3667 12.1667C23.6841 12.1667 28 16.4916 28 21.8334Z" fill="white" stroke="#C7C7C7" stroke-width="2" />
        <path d="M13.5333 20.8636V24.9041H23.2V20.8636" stroke="#C7C7C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18.3667 16.8232V21.2677M18.3667 21.2677L19.9778 19.6515M18.3667 21.2677L16.7556 19.6515" stroke="#C7C7C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export default downloadDisable;