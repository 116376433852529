import React, { useEffect } from 'react';
import {
  Col, Row, Card, Table, Tooltip,
} from 'antd';

import moment from 'moment-timezone';
import {
  ROLES,
  PAGES,
} from 'appconstants';

import { SpButton, SpText, SpStatusTag } from 'components/DesignKit';
import Loading from 'components/Loading';
import RBAC from 'components/rbac';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { salesforceActions } from 'store/actions';
import getSalesforceBackground from 'utils/getSalesforceBackground';
import getSalesforceStatus from 'utils/getSalesforceStatus';

type Props = {
  fetchSpObjectList: Function,
  fetchMappingDetails: Function,
  mappingDetails: Array,
  loader: boolean,
  selectedAccount: Object,
};

const SalesForceDetails = (props: Props) => {
  const history = useHistory();
  const {
    fetchSpObjectList,
    fetchMappingDetails,
    mappingDetails,
    loader,
    selectedAccount,
  } = props;

  const getData = () => {
    fetchSpObjectList();
    fetchMappingDetails();
  };

  useEffect(() => {
    getData();
  }, []);


  const columns = [
    {
      title: 'SWIREPAY',
      dataIndex: 'webhookType',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (webhookType) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            {(webhookType && webhookType.name) || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'SALESFORCE',
      dataIndex: 'webhookType',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (webhookType) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            {(webhookType && webhookType.name) || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'webhookInstanceEvent',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (webhookInstanceEvent) => {
        const status = (webhookInstanceEvent && webhookInstanceEvent.status);
        if (status) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <SpStatusTag style={{ backgroundColor: getSalesforceBackground(status) }}>
                {getSalesforceStatus(status)}
              </SpStatusTag>
            </Tooltip>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'webhookInstanceEvent',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (webhookInstanceEvent) => {
        const updatedAt = (webhookInstanceEvent && webhookInstanceEvent.updatedAt);
        if (updatedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(updatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loader) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
          <Col className="d-flex align-items-center">
            <SpText color="#474747" fontSize="18px">
              INTEGRATION SALESFORCE DETAILS
            </SpText>
          </Col>
          <Col>
            {
              <RBAC permit={[ROLES.SUPERUSER]}>
                <SpButton
                  type="secondary"
                  shape="round"
                  className="mr-4"
                  ghost
                  onClick={() => history && history.push(PAGES.INTEGRATIONS_CONFIGURE)}
                >
                  {'\u002B'}&nbsp;Add
                </SpButton>
              </RBAC>
            }
            {
              <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS]}>
                <Tooltip placement="left" title="Please, contact devsupport@swirepay.com to assist with Salesforce integration">
                  <SpButton
                    type="secondary"
                    shape="round"
                    className="mr-4"
                    ghost
                    disabled
                  >
                    {'\u002B'}&nbsp;Add
                  </SpButton>
                </Tooltip>
              </RBAC>
            }
            <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
              <SpButton
                shape="round"
                type="primary"
                className="mr-4"
                onClick={() => history && history.push(PAGES.INTEGRATIONS_UPDATE_CREDENTIALS)}
              >
                Update Credentials
              </SpButton>
            </RBAC>
          </Col>
        </Row>
        <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
          <Table
            onRow={() => ({
              onClick: () => history && history.push(PAGES.INTEGRATIONS_UPDATE_INVOICE),
            })}
            columns={columns}
            pagination={false}
            dataSource={mappingDetails}
          />
        </RBAC>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  spObject: state.salesforce.spObject,
  mappingList: state.salesforce.mappingList,
  mappingDetails: state.salesforce.mappingDetails,
  loader: state.loading.loading,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMappingList: param => dispatch(salesforceActions.fetchMappingList({
    payload: param,
  })),
  fetchMappingDetails: param => dispatch(salesforceActions.fetchMappingDetails({
    payload: param,
  })),
  fetchSpObjectList: param => dispatch(salesforceActions.fetchSpObjectList({
    payload: param,
  })),
});


export default connect(mapStateToProps, mapDispatchToProps)(SalesForceDetails);
