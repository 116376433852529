// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  Table,
  Icon,
  Tooltip,
} from 'antd';
import {
  SpButton,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';

import {
  PAGES,
} from 'appconstants';

import { globalRestrictedKeyActions } from 'store/actions';
import moment from 'moment-timezone';

import AddGlobalRestrictedKey from './components/AddGlobalRestrictedKey';
import EditGlobalRestrictedKey from './components/EditGlobalRestrictedKey';
import DeleteGlobalRestrictedKey from './components/DeleteGlobalRestrictedKey';

type Props = {
  fetchGlobalRestrictedKey: Function,
  updateGlobalRestrictedKey: Function,
  addGlobalRestrictedKey: Function,
  deleteGlobalRestrictedKey: Function,
  globalRestrictedKey: Array<Object>,
  loading: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  submitting: boolean,
  validator: boolean,
};

const GlobalRestrictedKey = (props: Props) => {
  const {
    loading,
    fetchGlobalRestrictedKey,
    updateGlobalRestrictedKey,
    addGlobalRestrictedKey,
    deleteGlobalRestrictedKey,
    globalRestrictedKey,
    totalElements,
    history,
    selectedAccount,
    submitting,
    validator,
  } = props;

  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const [selectedGlobalRestrictedKey, setSelectedGlobalRestrictedKey] = useState({});
  const [addGlobalRestrictedKeyModal, setAddGlobalRestrictedKeyModal] = useState(false);
  const [editGlobalRestrictedKeyModal, setEditGlobalRestrictedKeyModal] = useState(false);
  const [deleteGlobalRestrictedKeyModal, setDeleteGlobalRestrictedKeyModal] = useState(false);

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchGlobalRestrictedKey({ currentPage, sortParams });
  };

  useEffect(() => {
    getData(location.search);
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [globalRestrictedKey]);

  useEffect(() => {
    if (!validator) {
      setEditGlobalRestrictedKeyModal(false);
      setAddGlobalRestrictedKeyModal(false);
      setDeleteGlobalRestrictedKeyModal(false);
    }
  }, [validator]);


  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.GLOBAL_RESTRICTED_KEY,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.GLOBAL_RESTRICTED_KEY,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const editSelectedGlobalRestrictedKey = (grKey: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      gid,
      isEnabled,
      isPos,
    } = grKey;

    const params = {
      isEnabled,
      isPos,
    };

    updateGlobalRestrictedKey({
      currentPage,
      sortParams,
      gid,
      params,
    });
  };

  const deleteSelectedGlobalRestrictedKey = (gid: Object) => {
    deleteGlobalRestrictedKey({
      gid,
    });
  };

  const addNewGlobalRestrictedKey = (grKey: Object) => {
    const {
      isEnabled,
      isPos,
    } = grKey;

    const params = {
      isEnabled,
      isPos,
    };

    addGlobalRestrictedKey({
      params,
    });
  };

  const showEditGlobalRestrictedKeyModal = (value: Object) => {
    setSelectedGlobalRestrictedKey(value);
    setEditGlobalRestrictedKeyModal(true);
  };

  const showDeleteGlobalRestrictedKeyModal = (value: Object) => {
    setSelectedGlobalRestrictedKey(value);
    setDeleteGlobalRestrictedKeyModal(true);
  };

  const showAddGlobalRestrictedKeyModal = () => {
    setAddGlobalRestrictedKeyModal(true);
  };

  const columns = [
    {
      title: 'GID',
      dataIndex: 'gid',
      width: '30%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (gid) => (
        <span>
          {gid || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'VALUE',
      dataIndex: 'apiKey',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (apiKey) => (
        <span>
          {apiKey || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'updatedAt',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (updatedAt) => {
        if (updatedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(updatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      width: '15%',
      align: 'center',
      render: (text, record) => (
        <span>
          <Tooltip placement="top" title="Delete">
            <Icon
              type="delete"
              className="ml-3"
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => showDeleteGlobalRestrictedKeyModal(record)}
            />
          </Tooltip>
          <Tooltip placement="top" title="Edit">
            <Icon
              type="edit"
              className="ml-3"
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => showEditGlobalRestrictedKeyModal(record)}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <SpButton
              type="secondary"
              shape="round"
              ghost
              className="mr-3"
              onClick={showAddGlobalRestrictedKeyModal}
            >
              {'\u002B'}&nbsp;Add GR Key
            </SpButton>
          </Col>
        </div>
      </Row>
      {/* UPDATE GLOBALRESTRICTEDKEY MODAL */}
      { editGlobalRestrictedKeyModal && (
        <EditGlobalRestrictedKey
          visible={editGlobalRestrictedKeyModal}
          submitting={submitting}
          selectedGlobalRestrictedKey={selectedGlobalRestrictedKey}
          selectedAccount={selectedAccount}
          close={() => setEditGlobalRestrictedKeyModal(false)}
          submit={editSelectedGlobalRestrictedKey}
        />
      )}
      {/* ADD GLOBALRESTRICTEDKEY MODAL */}
      { addGlobalRestrictedKeyModal && (
        <AddGlobalRestrictedKey
          visible={addGlobalRestrictedKeyModal}
          submitting={submitting}
          selectedAccount={selectedAccount}
          close={() => setAddGlobalRestrictedKeyModal(false)}
          submit={addNewGlobalRestrictedKey}
        />
      )}
      {/* DELETE GLOBALRESTRICTEDKEY MODAL */}
      { deleteGlobalRestrictedKeyModal && (
        <DeleteGlobalRestrictedKey
          visible={deleteGlobalRestrictedKeyModal}
          submitting={submitting}
          selectedGlobalRestrictedKey={selectedGlobalRestrictedKey}
          selectedAccount={selectedAccount}
          close={() => setDeleteGlobalRestrictedKeyModal(false)}
          submit={deleteSelectedGlobalRestrictedKey}
        />
      )}

      <Table
        rowClassName={() => 'ant-table-clickable-row'}
        columns={columns}
        rowKey={(record) => record.gid}
        dataSource={globalRestrictedKey}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  globalRestrictedKey: state.globalRestrictedKey.content,
  loading: state.loading.loading,
  test: state.account.test,
  totalElements: state.globalRestrictedKey.totalElements,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchGlobalRestrictedKey: param => dispatch(globalRestrictedKeyActions.fetchGlobalRestrictedKey({
    payload: param,
  })),
  updateGlobalRestrictedKey: param => dispatch(globalRestrictedKeyActions.updateGlobalRestrictedKey({
    payload: param,
  })),
  addGlobalRestrictedKey: param => dispatch(globalRestrictedKeyActions.addGlobalRestrictedKey({
    payload: param,
  })),
  deleteGlobalRestrictedKey: param => dispatch(globalRestrictedKeyActions.deleteGlobalRestrictedKey({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(GlobalRestrictedKey);
