import { RATES } from '../actionTypes';

export const fetchRates = (action) => ({
  type: RATES.FETCH,
  payload: action.payload,
});

export const addRate = (action) => ({
  type: RATES.ADD,
  payload: action.payload,
});

export const fetchRateAcquirer = (action) => ({
  type: RATES.ACQUIRER_FETCH,
  payload: action.payload,
});

export const fetchRateCategory = (action) => ({
  type: RATES.CATEGORY_FETCH,
  payload: action.payload,
});

export const fetchPaymentType = (action) => ({
  type: RATES.PAYMENTTYPE_FETCH,
  payload: action.payload,
});
