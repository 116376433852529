export default (value) => {
  switch (value) {
    case 'PAYMENT_LINK_1_DAY_BEFORE_EXPIRY':
      return '1 day before expiry';
    case 'PAYMENT_LINK_3_DAYS_BEFORE_EXPIRY':
      return '3 days before expiry';
    case 'PAYMENT_LINK_5_DAYS_BEFORE_EXPIRY':
      return '5 days before expiry';
    case 'PAYMENT_LINK_7_DAYS_BEFORE_EXPIRY':
      return '7 days before expiry';
    case 'PAYMENT_LINK_10_DAYS_BEFORE_EXPIRY':
      return '10 days before expiry';
    case 'PAYMENT_LINK_14_DAYS_BEFORE_EXPIRY':
      return '14 days before expiry';
    case 'PAYMENT_LINK_21_DAYS_BEFORE_EXPIRY':
      return '21 days before expiry';
    case 'PAYMENT_LINK_1_DAY_BEFORE_NO_EXPIRY':
      return '1 day after issue date';
    case 'PAYMENT_LINK_3_DAYS_BEFORE_NO_EXPIRY':
      return '3 days after issue date';
    case 'PAYMENT_LINK_5_DAYS_BEFORE_NO_EXPIRY':
      return '5 days after issue date';
    case 'PAYMENT_LINK_7_DAYS_BEFORE_NO_EXPIRY':
      return '7 days after issue date';
    case 'PAYMENT_LINK_10_DAYS_BEFORE_NO_EXPIRY':
      return '10 days after issue date';
    case 'PAYMENT_LINK_14_DAYS_BEFORE_NO_EXPIRY':
      return '14 days after issue date';
    case 'PAYMENT_LINK_21_DAYS_BEFORE_NO_EXPIRY':
      return '21 days after issue date';
    case 'INVOICE_10_DAYS_BEFORE_DUE':
      return '10 days before due';
    case 'INVOICE_2_DAYS_BEFORE_DUE':
      return '2 days before due';
    case 'INVOICE_ON_DUE_DATE':
      return 'On due date';
    case 'INVOICE_3_DAYS_AFTER_DUE':
      return '3 days after it was due';
    case 'INVOICE_7_DAYS_AFTER_DUE':
      return '7 days after it was due';
    case 'INVOICE_15_DAYS_AFTER_DUE':
      return '15 days after it was due';
    case 'INVOICE_30_DAYS_AFTER_DUE':
      return '30 days after it was due';
    case 'INVOICE_60_DAYS_AFTER_DUE':
      return '60 days after it was due';
    default:
      return '';
  }
};
