// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
} from 'antd';
import moment from 'moment-timezone';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';
import Loading from 'components/Loading';
import {
  offerDetailsActions,
  inventoryItemsActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
  API_STATUS,
} from 'appconstants';
import getOfferBackground from 'utils/getCouponBackground';
import formatNumber from 'utils/formatNumber';
import RBAC from 'components/rbac';
import queryString from 'query-string';
import DeleteSelectedOffer from './components/DeleteSelectedOffer';
import EditOffer from './components/EditOffer';

type Props = {
  fetchOfferDetails: Function,
  fetchOfferRedemptionsDetails: Function,
  offerDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  validator: boolean,
  submitting: boolean,
  totalRedemptionElements: number,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  offerDetailsStatus: string,
  deleteOffer: Function,
  updateOffer: Function,
  redemptions: Array,
  itemsList: Array,
  fetchInventorySortItems: Function,
}

const OfferDetails = (props: Props) => {
  const {
    fetchOfferDetails,
    fetchOfferRedemptionsDetails,
    totalRedemptionElements,
    offerDetails,
    loading,
    submitting,
    history,
    selectedAccount,
    offerDetailsStatus,
    deleteOffer,
    validator,
    updateOffer,
    redemptions,
    itemsList,
    fetchInventorySortItems,
  } = props;

  const { gid } = props.match.params;
  const location = useLocation();
  const [deleteOfferModal, setDeleteOfferModal] = useState(false);
  const [editOfferModal, seteditOfferModal] = useState(false);
  const [pagination, setPagination] = useState({});
  const gidPath = gid.split('-')[1];
  const [redirect, setRedirect] = useState(false);
  const [status, setStatus] = useState();
  const isActive = offerDetails && offerDetails.isActive;
  const currencyType = selectedAccount && selectedAccount.currency;
  const currencyPrefix = selectedAccount && selectedAccount.currency.prefix;
  const debouncedFetchOffersRedemptions = debounce(fetchOfferRedemptionsDetails, 2000, { leading: true });

  const getData = () => {
    fetchInventorySortItems('&sortBy=name&direction=ASC');
    fetchOfferDetails(gid);
    const parsed = queryString.parse('');
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    debouncedFetchOffersRedemptions({ currentPage, sortParams, gid });
  };

  useEffect(() => {
    setPagination(existingElements => ({
      ...existingElements,
      total: totalRedemptionElements,
      showTotal: total => `Total ${total} items `,
    }));
  }, [redemptions]);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.OFFERS);
    }
  }, []);

  useEffect(() => {
    if (offerDetails && offerDetails.gid) {
      setStatus((offerDetails && offerDetails.isActive) ? 'Active' : 'Deactivated');
    }
  }, [offerDetails]);

  useEffect(() => {
    if (!validator && redirect) {
      getData();
      seteditOfferModal(false);
      setDeleteOfferModal(false);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData();
  }, [selectedAccount]);


  const deleteSelectedOffers = () => {
    deleteOffer(gid);
    setRedirect(true);
  };

  const goToReferencePage = (record) => {
    if (record && record.type === 'INVENTORY_ORDER') {
      const orderGid = record.referenceGid;
      history.push({
        pathname: `${PAGES.INVENTORY_ORDERS}/${orderGid}`,
      });
    }
  };

  const editSelectedOffer = (offer: object) => {
    const {
      code,
      startsAt,
      expiresAt,
      valueQuantity,
      claimType,
      claimQuantity,
      claimPercentageOff,
      valueItemGids,
      claimableItemGids,
    } = offer;
    const params = {
      code,
      startsAt,
      expiresAt,
      valueQuantity,
      claimType,
      claimQuantity,
      claimPercentageOff,
      valueItemGids,
      claimableItemGids,
    };
    updateOffer({
      params,
      gid,
    });
    setRedirect(true);
  };


  const columns = (type) => [
    {
      title: `${type} ITEM`,
      dataIndex: 'name',
      key: 'name',
      render: (name) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: `${type} PRICE`,
      dataIndex: 'price',
      key: 'price',
      render: (price) => {
        const total = (price / 100).toFixed(2);
        return (
          <Tooltip placement="top" title="">
            <span style={{ cursor: 'pointer' }}>
              {total !== '0.00' ? (
                `${selectedAccount.currency?.prefix} ${formatNumber(total, currencyType)}`
              ) : (
                <>&#8211;</>
              )}
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const redemptionsColumns = [
    {
      title: 'TYPE',
      dataIndex: 'type',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (type) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {type}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'REFERENCE',
      dataIndex: 'referenceNumber',
      width: '15%',
      align: 'left',
      render: (referenceNumber) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {referenceNumber}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'GET ITEM',
      dataIndex: 'claimedItemName',
      width: '15%',
      align: 'left',
      render: (claimedItemName) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {claimedItemName}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'discountAmount',
      width: '15%',
      align: 'left',
      sorter: false,
      render: (discountAmount) => {
        const totalAmount = (discountAmount / 100).toFixed(2);
        if (discountAmount < 0) {
          return (
            <span>{`(${currencyPrefix} ${formatNumber(totalAmount, currencyType).replace(/-/g, '')})`}</span>
          );
        }
        return (
          <span> {`${currencyPrefix} ${formatNumber(totalAmount, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        offerDetails.gid && offerDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="text-uppercase mr-4" fontSize="20px">
                    {offerDetails.code}
                  </SpText>
                </Col>
                <RBAC
                  permit={
                    [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                      ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
                  }
                >
                  <Col>
                    {isActive && (
                      <SpButton
                        className="mr-3"
                        type="secondary"
                        shape="round"
                        onClick={() => seteditOfferModal(true)}
                      >
                        Edit Offer
                      </SpButton>
                    )}
                    <SpButton
                      type="danger"
                      shape="round"
                      onClick={() => setDeleteOfferModal(true)}
                      disabled={!isActive}
                    >
                      Deactivate Offer
                    </SpButton>
                  </Col>
                </RBAC>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={5}>
                    <span>Status</span>
                  </Col>
                  <Col span={6}>
                    <SpStatusTag style={{ backgroundColor: getOfferBackground(status) }}>{status}</SpStatusTag>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={5}>
                    <span>Buy Item Quantity</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>{offerDetails.valueQuantity}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                { offerDetails && offerDetails.claimType === 'QUANTITY' && offerDetails.claimQuantity ? (
                  <Row type="flex" justify="start" align="middle">
                    <Col span={5}>
                      <span>Get Item Quantity</span>
                    </Col>
                    <Col span={6}>
                      <SpH5>{offerDetails.claimQuantity}</SpH5>
                    </Col>
                  </Row>
                ) : offerDetails && offerDetails.claimType === 'PERCENTAGE_OFF' && offerDetails.claimPercentageOff ? (
                  <Row type="flex" justify="start" align="middle">
                    <Col span={5}>
                      <span>Get Item Percentage Off</span>
                    </Col>
                    <Col span={6}>
                      <SpH5>{offerDetails.claimPercentageOff}%</SpH5>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={12}>
                    <Col span={10}>
                      <span>Starts from</span>
                    </Col>
                    <Col span={12}>
                      {
                        offerDetails && offerDetails.startsAt ? (
                          <SpH5>{
                            moment
                              .utc(offerDetails && offerDetails.startsAt)
                              .tz(selectedAccount.timezone)
                              .format('MMM DD, YYYY, hh:mm a')
                          }
                          </SpH5>
                        ) : (
                          <SpH5>&#8211;</SpH5>
                        )
                      }
                    </Col>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={12}>
                    <Col span={10}>
                      <span>Expires at</span>
                    </Col>
                    <Col span={12}>
                      {
                        offerDetails && offerDetails.expiresAt ? (
                          <SpH5>{
                            moment
                              .utc(offerDetails && offerDetails.expiresAt)
                              .tz(selectedAccount.timezone)
                              .format('MMM DD, YYYY, hh:mm a')
                          }
                          </SpH5>
                        ) : (
                          <SpH5>&#8211;</SpH5>
                        )
                      }
                    </Col>
                  </Col>
                </Row>
              </SpForm>
              {/* DELETE COUPON MODAL */}
              {deleteOfferModal && (
                <DeleteSelectedOffer
                  visible={deleteOfferModal}
                  offerDetails={offerDetails}
                  selectedAccount={selectedAccount}
                  submitting={submitting}
                  close={() => setDeleteOfferModal(false)}
                  submit={deleteSelectedOffers}
                />
              )}
              {/* EDIT COUPON MODAL */}
              {editOfferModal && (
                <EditOffer
                  visible={editOfferModal}
                  offerDetails={offerDetails}
                  selectedAccount={selectedAccount}
                  itemsList={itemsList}
                  submitting={submitting}
                  close={() => seteditOfferModal(false)}
                  submit={editSelectedOffer}
                />
              )}
              <hr />
              <Row>
                <SpText className="pl-2 mb-3" fontSize="16px">
                  <span style={{ color: '#999999' }}><b>ITEM INFO</b></span>
                </SpText>
              </Row>
              <Row type="flex" justify="space-between" className="my-2" gutter={16}>
                <Col span={12}>
                  <Table
                    columns={columns('BUY')}
                    dataSource={offerDetails.valueItems}
                    pagination={false}
                  />
                </Col>
                <Col span={12}>
                  <Table
                    columns={columns('GET')}
                    dataSource={offerDetails.claimableItems}
                    pagination={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <SpH5 color="#7f7f7f">{offerDetails.gid}</SpH5>
                </Col>
              </Row>
            </Card>
            <Card className="pl-2 mb-3">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">REDEMPTIONS</SpText>
                </Col>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    goToReferencePage(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={redemptionsColumns}
                rowKey={(record) => record.gid}
                dataSource={redemptions}
                pagination={pagination}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  offerDetails: state.offerDetails.content,
  offerDetailsStatus: state.offerDetails.offerDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  redemptions: state.offerDetails.offerRedemptions,
  totalRedemptionElements: state.offerDetails.totalRedemptionElements,
  itemsList: state.inventoryItems.sortItemsList.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOfferDetails: gid => dispatch(offerDetailsActions.fetchOfferDetails({
    payload: gid,
  })),
  fetchOfferRedemptionsDetails: gid => dispatch(offerDetailsActions.fetchRedemptionsDetails({
    payload: gid,
  })),
  fetchInventorySortItems: param => dispatch(inventoryItemsActions.fetchInventorySortItems({
    payload: param,
  })),
  deleteOffer: param => dispatch(offerDetailsActions.deleteOffer({
    payload: param,
  })),
  updateOffer: param => dispatch(offerDetailsActions.updateOffer({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OfferDetails);
