import React, { useState, useRef } from 'react';
import {
  Row,
  Col,
  Input,
} from 'antd';
import {
  SpError,
  SpButton,
  SpH5,
  SpText,
} from 'components/DesignKit';

import SimpleReactValidator from 'simple-react-validator';
import {
  MAX_AMOUNT,
} from 'appconstants';
import { validationRegex } from 'utils/validationMessages';


type Props = {
  addSetting: Function,
  isIndia: Boolean,
  prefix: String,
};

const Add = (props: Props) => {
  const {
    addSetting,
    isIndia,
    prefix,
  } = props;
  const [, forceUpdate] = useState();
  const [email, setEmail] = useState();
  const [minLimt, setMinLimt] = useState();
  const [maxLimt, setMaxLimt] = useState();
  const [approveLimt, setApproveLimt] = useState();

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const formatMinAmount = (e) => {
    const { value } = e.currentTarget;
    const amount = parseFloat(value);
    if (Number(amount)) {
      setMinLimt(parseInt(amount, 10).toFixed(2));
    }
  };

  const updateMinAmount = (e) => {
    const { value } = e.currentTarget;
    if (validationRegex.amountRegex.test(value) || value === '') {
      setMinLimt(value);
    } else if (!validationRegex.amountRegex.test(value)) {
      setMinLimt(0);
    }
  };

  const updateMaxAmount = (e) => {
    const { value } = e.currentTarget;
    if (validationRegex.amountRegex.test(value) || value === '') {
      setMaxLimt(value);
    } else if (!validationRegex.amountRegex.test(value)) {
      setMaxLimt(0);
    }
  };

  const formatMaxAmount = (e) => {
    const { value } = e.currentTarget;
    const amount = parseFloat(value);
    if (Number(amount)) {
      setMaxLimt(parseInt(amount, 10).toFixed(2));
    }
  };

  const formatApproveAmount = (e) => {
    const { value } = e.currentTarget;
    const amount = parseFloat(value);
    if (Number(amount)) {
      setApproveLimt(parseInt(amount, 10).toFixed(2));
    }
  };

  const updateApproveAmount = (e) => {
    const { value } = e.currentTarget;
    if (validationRegex.amountRegex.test(value) || value === '') {
      setApproveLimt(value);
    } else if (!validationRegex.amountRegex.test(value)) {
      setApproveLimt(0);
    }
  };


  const addTransferSettings = () => {
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    addSetting({
      email,
      minLimt,
      maxLimt,
      approveLimt,
    });
  };

  return (
    <>
      <Row className="my-2 mb-4 mt-4">
        <Col>
          <SpText className="text-uppercase" fontSize="14px" fontWeight="700" color="#999999">
            LIVE TRANSFER SETTINGS
          </SpText>
        </Col>
      </Row>
      <>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Minimum Limit</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="0.00"
              prefix={prefix}
              value={minLimt && minLimt}
              onBlur={formatMinAmount}
              onChange={updateMinAmount}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('min limit', minLimt, 'required|amount',
                { messages: { required: 'The min limit field is required.' } })}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" className="mb-4 mt-1" justify="start" align="middle">
          <Col span={4}>
            <SpH5>&nbsp;</SpH5>
          </Col>
          <Col span={8}>
            Minimum limit should be {prefix}1 and above
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Maximum Limit</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="0.00"
              prefix={prefix}
              value={maxLimt && maxLimt}
              onBlur={formatMaxAmount}
              onChange={updateMaxAmount}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('max limit', maxLimt, 'required|amount',
                { messages: { required: 'The max limit field is required.' } })}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" className="mb-4 mt-1" justify="start" align="middle">
          <Col span={4}>
            <SpH5>&nbsp;</SpH5>
          </Col>
          <Col span={8}>
            Maximum limit should be under {prefix}100,000
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Minimum Approval Limit</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="0.00"
              prefix={prefix}
              value={approveLimt && approveLimt}
              onBlur={formatApproveAmount}
              onChange={updateApproveAmount}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('min approve limit', approveLimt, 'required|amount',
                { messages: { required: 'The min approve limit field is required.' } })}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" className="mb-4 mt-1" justify="start" align="middle">
          <Col span={4}>
            <SpH5>&nbsp;</SpH5>
          </Col>
          <Col span={8}>
            Approval limit should be {prefix}1 and above
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Approval Email</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Enter Email"
              defaultValue={email}
              onChange={e => setEmail(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Email', email, 'required|email|checkLowerCase')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" className="mb-4 mt-1" justify="start" align="middle">
          <Col span={4}>
            <SpH5>&nbsp;</SpH5>
          </Col>
          <Col span={16}>
            Email to send approval links (Administrators can always approve via Dashboard).
          </Col>
        </Row>
      </>
      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={addTransferSettings}
          >
            Transfer
          </SpButton>
        </Col>
      </Row>
    </>
  );
};

export default Add;
