import { TERMINALDETAILS } from '../actionTypes';

export const fetchTerminalDetails = (action) => ({
  type: TERMINALDETAILS.FETCH,
  payload: action.payload,
});

export const fetchTerminalPayments = (action) => ({
  type: TERMINALDETAILS.TERMINALS_PAYMENTS_FETCH,
  payload: action.payload,
});

export const updateTerminalDetails = (action) => ({
  type: TERMINALDETAILS.TERMINALS_UPDATE,
  payload: action.payload,
});

export const updateTerminalProof = (action) => ({
  type: TERMINALDETAILS.ADD_PROOF,
  payload: action.payload,
});
