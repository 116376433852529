// @flow
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Input,
  Icon,
} from 'antd';
import {
  SpText,
  SpWidgetButton,
  SpAmountWidget,
  SpAmountWidgetItem,
  SpMessageText,
  SpTermsAndServices,
} from 'components/PaymentWidgetDesignKit';
import swirepaylogo from 'components/NavIcons/swirepay';
import formatAmount from 'utils/formatAmount';
import star from 'imgs/star.png';
import step1 from 'imgs/step1.png';
import step2 from 'imgs/step2.png';
import arrow from 'imgs/arrow.png';


type Props = {
  selectedAccount: Object,
  widgetDetails: Object,
};

const WidgetInfo = (props: Props) => {
  const {
    selectedAccount,
    widgetDetails,
  } = props;

  const { prefix } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;
  const [amountList, setAmountList] = useState([]);

  useEffect(() => {
    if (widgetDetails && widgetDetails.currencyCode) {
      setAmountList(widgetDetails.amounts);
    }
  }, [widgetDetails]);

  return (
    <>
      <SpText fontSize="17px">
        Choose Amount
      </SpText>
      <div style={{ textAlign: 'center' }}>
        <img src={step1} alt="step" className="mr-2" />
        <img src={step2} alt="step" className="mr-2" />
        <img src={step2} alt="step" />
      </div>
      <SpMessageText className="mt-4">
        <SpText fontSize="15px">
          {widgetDetails.actionMessage ? widgetDetails.actionMessage : 'Message'}
        </SpText>
      </SpMessageText>
      <div className="mt-3" style={{ padding: '0% 13%' }}>
        {
          widgetDetails.intervals && widgetDetails.intervals.length !== 0 && (
            <div style={{ backgroundColor: '#f2f2f6', padding: '2% 0% 2% 0%', borderRadius: '15px' }}>
              {
                widgetDetails.intervals.map((item) => (
                  <SpText
                    fontSize="14px"
                    className="mr-3"
                    borderRadius={widgetDetails.defaultInterval === item ? '13px' : '0px'}
                    padding={widgetDetails.defaultInterval === item ? '4px 14px' : '0px'}
                    backgroundColor={widgetDetails.defaultInterval === item ? widgetDetails.primaryColor : 'none'}
                    color={widgetDetails.defaultInterval === item ? '#fff' : '#000'}
                  >
                    <span>
                      {
                        widgetDetails.recommendedInterval === item && (
                          <span>
                            <img src={star} alt="star" style={{ width: '10px', paddingBottom: '3px' }} />
                            &nbsp;
                          </span>
                        )
                      }
                    </span>
                    {
                      item === 'ONETIME' && (
                        <span>One-time</span>
                      )
                    }
                    {
                      item === 'WEEK' && (
                        <span>Weekly</span>
                      )
                    }
                    {
                      item === 'MONTH' && (
                        <span>Monthly</span>
                      )
                    }
                    {
                      item === 'YEAR' && (
                        <span>Yearly</span>
                      )
                    }
                  </SpText>
                ))
              }
            </div>
          )
        }
      </div>
      <SpAmountWidget className="mt-4">
        {
          amountList.map((item) => (
            <SpAmountWidgetItem className="mr-3 mb-3">{prefix} {formatAmount((item / 100), currencyType)}</SpAmountWidgetItem>
          ))
        }
      </SpAmountWidget>
      <div className="mt-3" style={{ padding: '0% 13%' }}>
        {
          !widgetDetails.showCustomAmount ? (
            <Input
              style={{ height: '48px', borderRadius: '6px' }}
              prefix={prefix}
            />
          ) : (
            <span>&nbsp;</span>
          )
        }
      </div>
      <div className="mt-4" style={{ padding: '0% 12%' }}>
        <SpWidgetButton
          backgroundColor={widgetDetails.primaryColor}
        >
          Next <img src={arrow} alt="arrow" />
        </SpWidgetButton>
      </div>
      <div className="mt-4" style={{ padding: '0% 13%' }}>
        <SpText className="mr-2" fontSize="10px" color="rgba(0, 0, 0, 0.7)">Powered by</SpText>
        <Icon
          style={{
            fontSize: '20px',
            paddingBottom: '10px',
          }}
          component={swirepaylogo}
        />
      </div>
      <SpTermsAndServices className="mt-2">
        <SpText fontSize="11px" color="rgba(0, 0, 0, 0.7)">
          {widgetDetails.termsAndServices ? widgetDetails.termsAndServices : 'Terms and Services text'}
        </SpText>
      </SpTermsAndServices>
    </>
  );
};

// $FlowFixMe
export default WidgetInfo;
