const DateTypes = [
  {
    id: 1,
    display: 'Is Before',
    value: 'LT',
  },
  {
    id: 2,
    display: 'Is After',
    value: 'GT',
  },
  {
    id: 3,
    display: 'Is Between',
    value: 'BT',
  },
];

const AmountTypes = [
  {
    id: 1,
    display: 'Less Than',
    value: 'LT',
  },
  {
    id: 2,
    display: 'Greater Than',
    value: 'GT',
  },
  {
    id: 3,
    display: 'Is Between',
    value: 'BT',
  },
  {
    id: 4,
    display: 'Exactly',
    value: 'EQ',
  },
];

const NameType = [
  {
    id: 1,
    display: 'Is Exactly',
    value: 'EQ',
  },
  {
    id: 2,
    display: 'Is Similar',
    value: 'LIKE',
  },
];

const exactType = [
  {
    id: 1,
    display: 'Is Exactly',
    value: 'EQ',
  },
];

const TransactionType = [
  {
    id: 1,
    display: 'Is Exactly',
    value: 'EQ',
  },
];

const contactType = [
  {
    id: 1,
    display: 'Is Exactly',
    value: 'EQ',
  },
];

export default value => {
  switch (value) {
    case 'createdAt':
      return DateTypes;
    case 'settlementDate':
      return DateTypes;
    case 'partnerSettlementDate':
      return DateTypes;
    case 'email':
      return NameType;
    case 'name':
      return NameType;
    case 'label':
      return NameType;
    case 'city':
      return NameType;
    case 'fullName':
      return NameType;
    case 'roleType':
      return NameType;
    case 'amount':
      return AmountTypes;
    case 'description':
      return NameType;
    case 'status':
      return TransactionType;
    case 'paymentStatus':
      return TransactionType;
    case 'bankName':
      return NameType;
    case 'type':
      return TransactionType;
    case 'net':
      return AmountTypes;
    case 'amountDisputed':
      return AmountTypes;
    case 'fee':
      return AmountTypes;
    case 'phoneNumber':
      return NameType;
    case 'gid':
      return NameType;
    case 'customer.name':
      return NameType;
    case 'customer.email':
      return NameType;
    case 'plan.name':
      return NameType;
    case 'contact.name':
      return NameType;
    case 'terminalLocation.name':
      return NameType;
    case 'terminalMid':
      return NameType;
    case 'terminalTid':
      return NameType;
    case 'authCode':
      return NameType;
    case 'invoiceNumber':
      return NameType;
    case 'paymentDueDate':
      return DateTypes;
    case 'transferDueDate':
      return DateTypes;
    case 'issueDate':
      return DateTypes;
    case 'customer.phoneNumber':
      return NameType;
    case 'deviceName':
      return NameType;
    case 'terminal.deviceName':
      return NameType;
    case 'terminalLocation.address.city':
      return NameType;
    case 'customer':
      return NameType;
    case 'respondBy':
      return DateTypes;
    case 'title':
      return NameType;
    case 'totalSales':
      return NameType;
    case 'url':
      return NameType;
    case 'planAmount':
      return AmountTypes;
    case 'paymentDate':
      return DateTypes;
    case 'payoutDate':
      return DateTypes;
    case 'acquirer.name':
      return NameType;
    case 'issuer.name':
      return NameType;
    case 'internalRole.name':
      return NameType;
    case 'internalRole.type':
      return NameType;
    case 'issuerRate.transferType.value':
      return NameType;
    case 'rate.category.name':
      return NameType;
    case 'displayName':
      return NameType;
    case 'jurisdiction':
      return NameType;
    case 'issuerBank.bankName':
      return NameType;
    case 'percentage':
      return AmountTypes;
    case 'expiresAt':
      return DateTypes;
    case 'commission':
      return AmountTypes;
    case 'feesCollected':
      return AmountTypes;
    case 'partnerEarnings.totalTransactionAmount':
      return AmountTypes;
    case 'partnerEarnings.totalFeesCollected':
      return AmountTypes;
    case 'partnerEarnings.totalAccounts':
      return AmountTypes;
    case 'partnerEarnings.totalTransactions':
      return AmountTypes;
    case 'affiliateAccount.name':
      return NameType;
    case 'frequency':
      return NameType;
    case 'deviceId':
      return NameType;
    case 'mrn':
      return NameType;
    case 'receiptNumber':
      return NameType;
    case 'serviceType':
      return TransactionType;
    case 'updatedAt':
      return DateTypes;
    case 'transferDate':
      return DateTypes;
    case 'bin':
      return exactType;
    case 'applicationFeePercentage':
      return AmountTypes;
    case 'applicationFee':
      return AmountTypes;
    case 'scheme':
      return NameType;
    case 'contactType':
      return contactType;
    case 'fileNumber':
      return NameType;
    case 'transferCount':
      return AmountTypes;
    case 'settlementFilename':
      return NameType;
    case 'partnerSettlementFilename':
      return NameType;
    case 'authorizationId':
      return NameType;
    case 'wcOrderId':
      return NameType;
    case 'spOrderStatus':
      return TransactionType;
    case 'cloverPosOrderId':
      return NameType;
    case 'orderNumber':
      return NameType;
    case 'poNumber':
      return NameType;
    case 'shopName':
      return NameType;
    case 'shopTableName':
      return NameType;
    case 'reservationCode':
      return NameType;
    case 'customerName':
      return NameType;
    case 'noOfGuest':
      return NameType;
    case 'price':
      return AmountTypes;
    case 'cost':
      return AmountTypes;
    case 'hidden':
      return TransactionType;
    case 'available':
      return TransactionType;
    default:
      return [];
  }
};
