import { PLANV2 } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  plansStatus: undefined,
  plansError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PLANV2.FETCH:
      return {
        ...state,
        plansStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case PLANV2.SUCCESS:
      return {
        ...state,
        ...action.payload,
        plansStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case PLANV2.FAILED:
      return {
        ...state,
        plansError: action.payload,
        plansStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
