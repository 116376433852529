import { PUBLICKEYS } from '../actionTypes';

export const fetchPublickeys = (action) => ({
  type: PUBLICKEYS.FETCH,
  payload: action.payload,
});

export const updatePublickey = (action) => ({
  type: PUBLICKEYS.UPDATE,
  payload: action.payload,
});
