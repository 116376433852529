import { call, put } from 'redux-saga/effects';
import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  GLOBAL_RESTRICTED_KEY,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchGlobalRestrictedKey = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GLOBAL_RESTRICTED_KEY}${query}${sorting}`);
};

const postGlobalRestrictedKey = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.GLOBAL_RESTRICTED_KEY, params);

const patchGlobalRestrictedKey = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GLOBAL_RESTRICTED_KEY}/${gid}`, params);

const deleteGlobalRestrictedKeys = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GLOBAL_RESTRICTED_KEY}/${gid}`);

export function* getGlobalRestrictedKey(action) {
  try {
    const { data: { entity } } = yield call(fetchGlobalRestrictedKey, action.payload);
    yield put({ type: GLOBAL_RESTRICTED_KEY.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: GLOBAL_RESTRICTED_KEY.FAILED, payload: e.response.data });
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  }
}

export function* addGlobalRestrictedKey(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postGlobalRestrictedKey, action.payload);
    const { data: { entity } } = yield call(fetchGlobalRestrictedKey, action.payload);
    yield put({ type: GLOBAL_RESTRICTED_KEY.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.GLOBAL_RESTRICTED_KEY.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateGlobalRestrictedKey(action) {
  try {
    yield yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchGlobalRestrictedKey, action.payload);
    const { data: { entity } } = yield call(fetchGlobalRestrictedKey, action.payload);
    yield put({ type: GLOBAL_RESTRICTED_KEY.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.GLOBAL_RESTRICTED_KEY.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteGlobalRestrictedKey(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteGlobalRestrictedKeys, action.payload);
    const { data: { entity } } = yield call(fetchGlobalRestrictedKey, action.payload);
    yield put({ type: GLOBAL_RESTRICTED_KEY.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.GLOBAL_RESTRICTED_KEY.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
