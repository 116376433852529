/* eslint-disable */
import React from 'react';

const lockClose = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4.5C9.51472 4.5 7.5 6.51472 7.5 9V12.5C6.39543 12.5 5.5 13.3954 5.5 14.5V19.5C5.5 20.6046 6.39543 21.5 7.5 21.5H16.5C17.6046 21.5 18.5 20.6046 18.5 19.5V14.5C18.5 13.3954 17.6046 12.5 16.5 12.5H9.5V9C9.5 7.61929 10.6193 6.5 12 6.5C13.3807 6.5 14.5 7.61929 14.5 9V12.5H16.5V9C16.5 6.51472 14.4853 4.5 12 4.5ZM11.5 17C11.5 16.7239 11.7239 16.5 12 16.5C12.2761 16.5 12.5 16.7239 12.5 17V19C12.5 19.2761 12.2761 19.5 12 19.5C11.7239 19.5 11.5 19.2761 11.5 19V17Z" fill="#A8A8A8" />
    </svg>
);

export default lockClose;
