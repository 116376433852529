// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  notificationActions,
} from 'store/actions';
import {
  SpH5,
  SpButton,
  SpError,
} from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedItemList: Object,
  shopsRatesList: Array,
  ratesList: Array,
};

const AddRemoveTaxes = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedItemList,
    shopsRatesList,
    ratesList,
  } = props;

  const [, forceUpdate] = useState();
  const gid = selectedItemList ? selectedItemList.gid : '';
  const [disableButton, setDisableButton] = useState(false);
  const [categories, setCategories] = useState((shopsRatesList && shopsRatesList.length !== 0)
    ? shopsRatesList.map((option) => option.gid) : []);
  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [categories, submitting]);

  const removeItems = () => {
    setCategories([]);
  };

  const handleSelectChange = (values) => {
    setCategories(values);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      gid,
      categories,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="650px"
      style={{
        top: '50px',
      }}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Save</SpButton>,
      ]}
      title={(shopsRatesList && shopsRatesList.length !== 0)
        ? 'Add or Remove Taxes'
        : 'Add Taxes'}
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        {
          (shopsRatesList && shopsRatesList.length !== 0)
            ? (
              <div className="d-flex justify-content-end mt-2 mb-2">
                <SpButton onClick={removeItems} type="secondary">
                  Remove all taxes
                </SpButton>
              </div>
            ) : null
        }
        <div>
          <label htmlFor=""><SpH5>Taxes</SpH5></label>
          <Select
            className="w-100"
            mode="multiple"
            allowClear
            placeholder="Select taxes"
            onChange={handleSelectChange}
            value={categories}
            showSearch
            optionFilterProp="name"
            style={{ width: '100%' }}
          >
            {ratesList.map((item) => (
              <Option key={item.gid} name={item.displayName} value={item.gid}>
                {item.displayName}
              </Option>
            ))}
          </Select>
          {
            (shopsRatesList && shopsRatesList.length !== 0)
              ? null
              : (
                <SpError>
                  {simpleValidator.current.message('taxes', categories, 'required')}
                </SpError>
              )
          }
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddRemoveTaxes);
