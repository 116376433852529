// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Select,
  Modal,
  AutoComplete,
} from 'antd';
import moment from 'moment-timezone';
import getDiscountOff from 'utils/getDiscountOff';
import SimpleReactValidator from 'simple-react-validator';
import { validators } from 'utils/validationMessages';
import {
  SpH5,
  SpError,
  SpButton,
  SpText,
} from 'components/DesignKit';
import {
  invoiceDetailsAction,
} from 'store/actions';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  couponsList: Array<Object>,
  getCouponsList: Function,
  newCouponAdd: Function,
};

const SelectCoupon = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    couponsList,
    getCouponsList,
    newCouponAdd,
  } = props;

  const [, forceUpdate] = useState();
  const [couponName, setCouponName] = useState('');
  const [couponSelected, setCouponSelected] = useState({});
  const [couponMsg, setCouponMsg] = useState('');

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    getCouponsList();
  }, []);

  const onSearch = (searchText) => {
    getCouponsList(searchText);
  };

  const getCoupon = (e) => {
    setCouponMsg('');
    simpleValidator.current.purgeFields();
    const selectedCoupon = couponsList.filter((item) => item.gid === e);
    setCouponName(selectedCoupon[0].name);
    setCouponSelected(selectedCoupon[0]);
  };

  const addNewCoupon = () => {
    newCouponAdd();
  };


  const validate = (event) => {
    event.preventDefault();
    const maxRedemptions = couponSelected.maximumRedemption;
    const redemptions = couponSelected.redemptionCount;
    const expiryDate = moment(couponSelected.expiresAt).utc();
    const currentDate = moment.utc();
    if (maxRedemptions === redemptions) {
      simpleValidator.current.fields.coupon = false;
      setCouponMsg(validators.coupon.redemptionCoupon);
    } else if (expiryDate < currentDate) {
      simpleValidator.current.fields.coupon = false;
      setCouponMsg(validators.coupon.expiredCoupon);
    }
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit(couponSelected);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <span className="mr-5" style={{ cursor: 'pointer' }}>
          <SpText
            fontWeight="600"
            color="#279dfe"
            onClick={addNewCoupon}
          >
            Add new Coupon
          </SpText>
        </span>,
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Apply</SpButton>,
      ]}
      title="Apply Coupon"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div>
          <label htmlFor=""><SpH5>Coupon</SpH5></label>
          <AutoComplete
            placeholder="Select Coupon"
            showSearch
            className="w-100"
            dataSource={couponsList.map((item) => (
              <Option key={item.gid} value={item.gid}>
                {`${item.name} (${getDiscountOff(item)})`}
              </Option>
            ))}
            style={{ height: '40px' }}
            onSearch={onSearch}
            defaultValue={couponName}
            onSelect={(e) => getCoupon(e)}
          />
          <SpError>
            {simpleValidator.current.message('Coupon', couponName, 'required')}
          </SpError>
          <SpError>
            {(couponName && couponMsg) ? couponMsg : null}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  couponsList: state.invoiceDetails.couponsList,
});

const mapDispatchToProps = (dispatch) => ({
  getCouponsList: param => dispatch(invoiceDetailsAction.getCouponsList({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(SelectCoupon);
