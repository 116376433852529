import { TRANSFERLINKS_STATUSES } from 'appconstants';

export default value => {
  if (value === 'ACTIVE') {
    return TRANSFERLINKS_STATUSES.ACTIVE;
  } if (value === 'PAID') {
    return TRANSFERLINKS_STATUSES.PAID;
  } if (value === 'CANCELLED') {
    return TRANSFERLINKS_STATUSES.CANCELLED;
  } if (value === 'DECLINED') {
    return TRANSFERLINKS_STATUSES.DECLINED;
  } if (value === 'EXPIRED') {
    return TRANSFERLINKS_STATUSES.EXPIRED;
  } if (value === 'READY_TO_FUND') {
    return TRANSFERLINKS_STATUSES.READY_TO_FUND;
  } if (value === 'PROCESSING') {
    return TRANSFERLINKS_STATUSES.PROCESSING;
  } return null;
};
