import { LOCATIONSDETAILS } from '../actionTypes';

export const fetchLocationDetails = (action) => ({
  type: LOCATIONSDETAILS.FETCH,
  payload: action.payload,
});

export const fetchTerminalDetails = (action) => ({
  type: LOCATIONSDETAILS.TERMINALS_FETCH,
  payload: action.payload,
});

export const updateTerminals = (action) => ({
  type: LOCATIONSDETAILS.TERMINALS_UPDATE,
  payload: action.payload,
});

export const removeTerminal = (action) => ({
  type: LOCATIONSDETAILS.TERMINALS_DELETE,
  payload: action.payload,
});
