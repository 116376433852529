// @flow
import React, {
  useState,
  useImperativeHandle,
  useRef,
  forwardRef,
} from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Select,
  Upload,
  Icon,
  message,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpError,
  SpButton,
  SpH6,
} from 'components/DesignKit';

import {
  MESSAGES,
} from 'appconstants';

import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';
import { NOTIFICATION } from 'store/actionTypes';

import {
  notificationActions,
  disputeEvidenceAction,
} from 'store/actions';

import Previewicon from 'components/NavIcons/previewIcon';

const { Option } = Select;

type Props = {
  setEvidenceSelected: Function,
  setNotification: Function,
  disputeEvidence: Array<Object>,
}

// $FlowFixMe
const SupportingEvidence = forwardRef((props: Props, ref) => {
  const {
    setEvidenceSelected,
    disputeEvidence,
    setNotification,
  } = props;

  const [, forceUpdate] = useState();
  const [evidenceType, setEvidenceType] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [keyType, setKeyType] = useState('');
  const [evidenceKey, setEvidenceKey] = useState('');

  const simpleValidator = useRef(new SimpleReactValidator());

  const selectEvidence = (value) => {
    setKeyType(value);
    const typeOfEvience = disputeEvidence.filter(list => list.value === value);
    setEvidenceType(typeOfEvience[0].name);
    if (disputeEvidence.length !== 1) {
      // removing selected document type from the list and updatig the state.
      const evidences = disputeEvidence.filter(list => list.value !== value);
      setEvidenceSelected(evidences);
    }
  };

  const uploadButton = (
    <div>
      <Icon type={loading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    const PDF = 'application/pdf';
    const IMAGE = 'image/*';
    const pattern = file.type.match(IMAGE) || file.type.match(PDF);
    if (!pattern) {
      message.error('Only image or pdf');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('Evidence must smaller than 5MB!');
      return false;
    }
    return true;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = info => {
    setLoading(true);
    const file = info.file.originFileObj;
    if (file) {
      const pattern = /image*/;
      if (file.type.match(pattern)) {
        getBase64(file, imageUrl => {
          setPreview(<img src={imageUrl} alt="..." style={{ width: '100%' }} />);
        });
      } else {
        setPreview(<Previewicon />);
      }
      setFileName(info.file.name);
      setSelectedFile(file);
    }
    setLoading(false);
  };

  // if we use this call in redux it is hard to find the document type. for each call state either replace or add new item.
  const getEvidenceKey = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPLOAD_EVIDENCE}`);
      await axiosEmptyInstance.put(entity, await fileReader(selectedFile), { headers: { 'Content-Type': selectedFile.type } });
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.UPLOAD_EVIDENCE}/${keyType}`, { fileUrl: entity });
      setEvidenceKey(fileUrl);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.FILE_UPLOAD.SUCCESS,
      });
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      evidenceType,
      evidenceKey,
    }),
  }));

  return (
    <>
      {
        disputeEvidence && (
          <>
            <Row type="flex">
              <Col>
                <SpH6
                  fontWeight="600"
                >
                  Upload documents
                </SpH6>
                <div className="position-relative mr-3 my-2">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={fileUpload}
                  >
                    {fileName ? preview : uploadButton}
                  </Upload>
                  <SpError>
                    {simpleValidator.current.message('document', evidenceKey, 'required')}
                  </SpError>
                </div>
              </Col>
              <Col span={4} className="pt-5">
                <div className="w-100 my-2">
                  <Select
                    placeholder="Choose Type"
                    className="w-100"
                    onChange={selectEvidence}
                  >
                    {
                      disputeEvidence.map((item) => (
                        <Option
                          className="text-capitalize"
                          key={item.id}
                          value={item.value}
                        >
                          {item.display}
                        </Option>
                      ))
                    }
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('type', evidenceType, 'required')}
                  </SpError>
                </div>
                <SpButton
                  disabled={!(selectedFile && evidenceType)}
                  type="primary"
                  shape="round"
                  onClick={getEvidenceKey}
                >
                  {evidenceKey ? 'Re-Upload' : 'Upload'}
                </SpButton>
              </Col>
            </Row>
          </>
        )
      }
    </>
  );
});

const mapStateToProps = (state) => ({
  disputeEvidence: state.disputeEvidence.selectedEvidences,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setEvidenceSelected: evidences => dispatch(disputeEvidenceAction.setEvidenceSelected({
    payload: evidences,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(SupportingEvidence);
