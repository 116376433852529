import { CASH_TRANSACTIONS_DETAILS } from '../actionTypes';

const initialState = {
  cashtransactionsDetailsStatus: undefined,
  cashtransactionsDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CASH_TRANSACTIONS_DETAILS.FETCH:
      return {
        ...state,
        cashtransactionsDetailsStatus: 'STARTED',
      };
    case CASH_TRANSACTIONS_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        cashtransactionsDetailsStatus: 'SUCCESS',
      };
    case CASH_TRANSACTIONS_DETAILS.FAILED:
      return {
        ...state,
        cashtransactionsDetailsStatus: 'FAILED',
        cashtransactionsDetailsError: action.payload,
      };
    default:
      return state;
  }
};
