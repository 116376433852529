/* eslint-disable */
import React from 'react';

const support = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <path fill="#515967" d="M21.944 10.089c.662-1.043 1.025-2.262 1.025-3.522C22.969 2.946 20.025 0 16.406 0H6.563C2.942 0 0 2.946 0 6.567v11.364l4.459-4.797h1.586c-.642 1.014-1.014 2.216-1.014 3.502 0 3.621 2.944 6.567 6.563 6.567H23.54L28 28V16.636c0-3.45-2.673-6.287-6.056-6.547zm-18.2.73l-1.516 1.906V6.567c0-2.598 1.969-4.27 4.334-4.27h9.844c2.714 0 4.074 1.773 4.074 4.07 0 1.319-.353 2.443-1.462 3.213h-7.424c-1.538 0-2.955.35-4.075 1.24H3.744zm22.24 11.49l-1.728-1.401H11.594c-2.714 0-4.11-2.164-4.11-4.272 0-2.107 1.396-4.747 4.11-4.747h9.164c2.714 0 5.225 2.032 5.225 4.747v5.672z"/>
  </svg>
);

export default support;
