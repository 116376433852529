/* eslint-disable */
import React from 'react';

const downloadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g fill="none" fillRule="evenodd">
            <g fill="#0090FF" fillRule="nonzero">
                <g>
                    <g>
                        <path d="M24.77 8h-5.385c-.595 0-1.077.471-1.077 1.053 0 .581.482 1.052 1.077 1.052h5.384c.595 0 1.077.472 1.077 1.053v13.684c0 .582-.482 1.053-1.077 1.053H3.231c-.595 0-1.077-.471-1.077-1.053V11.158c0-.581.482-1.053 1.077-1.053h5.384c.595 0 1.077-.471 1.077-1.052C9.692 8.47 9.21 8 8.615 8H3.231C1.446 8 0 9.414 0 11.158v13.684C0 26.586 1.446 28 3.23 28h21.54c1.784 0 3.23-1.414 3.23-3.158V11.158C28 9.414 26.554 8 24.77 8z" transform="translate(-1326 -106) translate(251 77) translate(1075 29)" />
                        <path d="M19.669 14.973c-.421-.392-1.088-.392-1.508 0l-3.577 3.45V1.048C14.584.469 14.098 0 13.5 0c-.6 0-1.085.469-1.085 1.048v17.375l-3.575-3.45c-.43-.402-1.118-.39-1.534.026-.407.406-.407 1.05 0 1.455l5.427 5.238c.423.41 1.11.41 1.534.002l.002-.002 5.427-5.238c.417-.416.405-1.079-.026-1.48z" transform="translate(-1326 -106) translate(251 77) translate(1075 29)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default downloadIcon;