import { CUSTOM_DOCUMENT_REQUEST_DETAILS } from '../actionTypes';

export const fetchCustomDocumentRequestDetails = action => ({
  type: CUSTOM_DOCUMENT_REQUEST_DETAILS.FETCH,
  payload: action.payload,
});

export const deleteCustomDocumentRequest = (action) => ({
  type: CUSTOM_DOCUMENT_REQUEST_DETAILS.DELETE,
  payload: action.payload,
});
