// @flow
import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Input,
  Select,
} from 'antd';
import {
  SpH5,
  SpError,
} from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import { CATALOAG_COUNTRY_CODES, COUNTRY_PHONE_CODE } from 'appconstants';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';
import getStateList from 'utils/stateList';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';

const { Option } = Select;

type Props = {
  submitting: Boolean,
  selectedAccount: Object,
};

const ReviewRate = (props: Props, ref) => {
  const {
    submitting, selectedAccount,
  } = props;
  const [, forceUpdate] = useState();
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [countryId, setCountryId] = useState('');
  const [countryName, setCountryName] = useState('');
  const [stateList, setSateList] = useState([]);
  const [email, setEmail] = useState();
  const [phoneNumber, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState(getCountryPhoneCode(selectedAccount.country.id));
  const [phoneFormate, setPhoneFormate] = useState();
  const cntryId = selectedAccount.country.id;
  const postalCodeRegex = selectedAccount.country.id === 2 ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;

  useEffect(() => {
    setSateList(getStateList(cntryId));
    if (cntryId && cntryId === 1) {
      setCountryName('USA');
    } else if (cntryId && cntryId === 2) {
      setCountryName('INDIA');
    }
  }, []);

  const updateCountry = (value) => {
    setCountryId(value);
    setSateList(getStateList(value));
  };

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
    },
  }));

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      email,
      streetName,
      cityName,
      stateName,
      zipCode,
      countryId,
      phoneNumber: phoneNumber.replace(/[^0-9+]/g, ''),
      countryCode,
    }),
  }));

  return (
    <div className="px-4">
      <div className={submitting ? 'OVERLAY' : ''} />
      <div className="mb-3">
        <label htmlFor=""><SpH5>Email</SpH5></label>
        <Input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <SpError>
          {simpleValidator.current.message('email', email, 'required|email|checkLowerCase')}
        </SpError>
      </div>
      <div className="pb-3">
        <label htmlFor=""><SpH5>Street Name</SpH5></label>
        <Input
          placeholder="Street Address"
          value={streetName}
          onChange={(e) => setStreetName(e.currentTarget.value)}
        />
        <SpError>
          {simpleValidator.current.message('street', streetName, 'required')}
        </SpError>
      </div>

      <div className="pb-3">
        <div className="d-flex">
          <div className="w-50 mr-3">
            <label className="d-block"><SpH5>City</SpH5></label>
            <Input
              placeholder="City"
              value={cityName}
              onChange={(e) => setCityName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('city', cityName, 'required')}
            </SpError>
          </div>
          <div className="w-50">
            <label className="d-block"><SpH5>State</SpH5></label>
            <Select
              className="w-100"
              showSearch
              optionFilterProp="children"
              defaultValue={stateName}
              onChange={(e) => setStateName(e)}
            >
              {
                stateList.map(s => (
                  <Option
                    key={s.id}
                    value={s.id}
                    className="text-capitalize"
                  >
                    { s.name }
                  </Option>
                ))
              }
            </Select>
            <SpError>
              {simpleValidator.current.message('state', stateName, 'required')}
            </SpError>
          </div>
        </div>
      </div>

      <div className="pb-3">
        <div className="d-flex">
          <div className="w-50 mr-3">
            <label className="d-block"><SpH5>Postal Code</SpH5></label>
            <Input
              placeholder="Postal Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Area code', zipCode, 'required|postalcode')}
            </SpError>
          </div>
          <div className="w-50">
            <label className="d-block font-weight-bold">Country</label>
            <Select
              defaultValue={countryName}
              style={{ width: '100%' }}
              onChange={updateCountry}
            >
              {CATALOAG_COUNTRY_CODES.map((item) => (
                <Option key={item.ID} value={item.ID}>
                  {item.NAME}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="pb-3">
        <label htmlFor=""><SpH5>Phone Number</SpH5></label>
        <div className="d-flex">
          <div
            style={{ width: '20%' }}
            onKeyPress={e => countryCodeValidation(e)}
          >
            <Select
              className="w-100"
              showSearch
              defaultValue={countryCode}
              onChange={onCountryCodeChange}
            >
              {COUNTRY_PHONE_CODE.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ width: '80%' }}>
            <Input
              className="InputnumFormate"
              placeholder="Phone Number"
              value={phoneFormate}
              onChange={onPhoneNumberChange}
              onBlur={e => {
                if (e.currentTarget.value) {
                  setPhone(e.currentTarget.value);
                  const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                  const formater = value.substr(value.indexOf(' ') + 1);
                  setPhoneFormate(formater);
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('phone number', phoneNumber, `required|phoneNumValidation:${countryCode}${phoneNumber}`)}
            </SpError>
          </div>
        </div>
      </div>
    </div>
  );
};

// $FlowFixMe
export default forwardRef(ReviewRate);
