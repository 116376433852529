import { PAYMENTCHECKOUTDETAILS } from '../actionTypes';

export const fetchPaymentCheckoutDetails = (action) => ({
  type: PAYMENTCHECKOUTDETAILS.FETCH,
  payload: action.payload,
});

export const fetchPayments = (action) => ({
  type: PAYMENTCHECKOUTDETAILS.PAYMENTS_FETCH,
  payload: action.payload,
});
