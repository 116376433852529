/* eslint-disable */
import React, { useState } from 'react';

const customersupport = ({ count }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {
        isHovered ? (
          <>
            <g filter="url(#filter0_f_1873_39399)">
              <path d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45Z" fill="white" />
              <path d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45Z" stroke="#F28C26" stroke-miterlimit="10" />
            </g>
            <g opacity="0.4" filter="url(#filter1_f_1873_39399)">
              <path d="M24.5 42C34.165 42 42 34.165 42 24.5C42 14.835 34.165 7 24.5 7C14.835 7 7 14.835 7 24.5C7 34.165 14.835 42 24.5 42Z" fill="#F28C26" />
            </g>
            <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="14" fill="white">
              {count}
            </text>
            <defs>
              <filter id="filter0_f_1873_39399" x="0.5" y="0.5" width="47" height="47" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_1873_39399" />
              </filter>
              <filter id="filter1_f_1873_39399" x="5" y="5" width="39" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_1873_39399" />
              </filter>
            </defs>
          </>
        ) : (
          <>
            <g filter="url(#filter0_f_1873_39401)">
              <path d="M23 44C34.598 44 44 34.598 44 23C44 11.402 34.598 2 23 2C11.402 2 2 11.402 2 23C2 34.598 11.402 44 23 44Z" fill="#F28C26" />
            </g>
            <path d="M22.9455 37.8901C31.1993 37.8901 37.8904 31.1991 37.8904 22.9452C37.8904 14.6914 31.1993 8.00037 22.9455 8.00037C14.6917 8.00037 8.00061 14.6914 8.00061 22.9452C8.00061 31.1991 14.6917 37.8901 22.9455 37.8901Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
            <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="14" fill="white">
              {count}
            </text>
            <defs>
              <filter id="filter0_f_1873_39401" x="0" y="0" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_1873_39401" />
              </filter>
            </defs>
          </>
        )
      }

    </svg>
  );
};

export default customersupport;

