import { PAYMENTCHECKOUT } from '../actionTypes';

export const fetchPaymentCheckout = (action) => ({
  type: PAYMENTCHECKOUT.FETCH,
  payload: action.payload,
});

export const addPaymentCheckout = (action) => ({
  type: PAYMENTCHECKOUT.ADD,
  payload: action.payload,
});
