import React, { useEffect, useState } from 'react';
import Logo from '../../../assets/imgs/swirepay-newlogo.svg';
import EmailIcon from '../../../assets/imgs/email.svg';
import PhoneIcon from '../../../assets/imgs/call.svg';
import { SWIREPAY_SUPPORT_EMAIL, SWIREPAY_SUPPORT_PHONE } from '../../../appconstants';
import { imgWith, normalTextFontSize, h1FontSize } from '../../../utils/reseponsive';
import { PText, SpanText } from '../../../design';
import EPaymentImg from '../../../assets/imgs/e payments.svg';

type Props = {
  email: string,
  phone: string,
  isPreview: boolean,
  isAdaptive: boolean,
  termsAndCondition: string,
  agregatorName: String,
  primaryColor: String,
}

const PaymentPageSupport = (props: Props) => {
  const [email, setEmail] = useState(SWIREPAY_SUPPORT_EMAIL);
  const [phone, setPhone] = useState(SWIREPAY_SUPPORT_PHONE);

  const isBlank = str => (!str || /^\s*$/.test(str));

  useEffect(() => {
    if (!isBlank(props.email)) { setEmail(props.email); }
    if (!isBlank(props.phone)) { setPhone(props.phone); }
  }, [props.email, props.phone]);

  return (
    <div className="pt-4 position-sticky" style={{ top: '100%' }}>
      <footer className="footer w-100" style={{ bottom: 0 }}>
        <div>
          <div className="my-3" hidden={!props.termsAndCondition}>
            <div className="mt-3 d-flex">
              <div
                style={{
                  height: '9px', width: '9px', backgroundColor: (props.primaryColor || '#40A9FF'), marginTop: '0.9rem', marginLeft: '-1.0rem',
                }}
                className="d-flex align-items-center"
              >&nbsp;
              </div>
              <div style={{ marginLeft: '0.5rem' }}>
                <PText fontWeight={600} fontSize={h1FontSize(props.isPreview, props.isAdaptive)}>Terms & Conditions:</PText>
              </div>
            </div>
            <PText
              fontWeight={400}
              fontSize={normalTextFontSize(props.isPreview, props.isAdaptive)}
              className="mt-1 pb-2 text-muted"
            >
              {props.termsAndCondition}
            </PText>
            <hr />
          </div>
          <div className="text-muted pt-2">
            <PText fontSize={normalTextFontSize(props.isPreview, props.isAdaptive)}>For any inquiries, please contact</PText>
            <div className="row">
              <div className="col">
                <div className="d-flex align-items-center">
                  <span className="pr-2">
                    <img width={imgWith(52)} src={EmailIcon} alt="" />
                  </span>
                  <SpanText fontSize={normalTextFontSize(props.isPreview, props.isAdaptive)}><a href={`mailto:${email}`}>{email}</a></SpanText>
                </div>
                <div className="d-flex align-items-center">
                  <span className="pr-2">
                    <img width={imgWith(48)} src={PhoneIcon} alt="" />
                  </span>
                  <SpanText fontSize={normalTextFontSize(props.isPreview, props.isAdaptive)}><a href={`mailto:${email}`}>{phone}</a></SpanText>
                </div>
              </div>
              <table className="col">
                <tbody>
                  <tr>
                    {
                      props.agregatorName === 'EPAYMENTS' ? (
                        <>
                          <td style={{ verticalAlign: 'middle' }}>
                            <img src={Logo} width={imgWith(8)} alt="" />
                          </td>
                          <td style={{ verticalAlign: 'right', paddingLeft: '10%', paddingTop: '7%' }}>
                            <img src={EPaymentImg} width={imgWith(8)} alt="" />
                          </td>
                        </>
                      ) : props.agregatorName === 'OMEGAITR' ? (
                        <>
                          <td style={{ verticalAlign: 'middle' }}>
                            <img src={Logo} width={imgWith(8)} alt="" />
                          </td>
                          <td style={{ verticalAlign: 'right', paddingLeft: '10%', paddingTop: '7%' }}>
                            <img src={EPaymentImg} width={imgWith(8)} alt="" />
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ verticalAlign: 'right', paddingLeft: '30%' }}>
                            <img src={Logo} width={imgWith(8)} alt="" />
                          </td>
                        </>
                      )
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PaymentPageSupport;
