import React from 'react';
import styles from './Sidebar.module.css';
import actionStyle from './ActionNode.module.css';

const onDragStart = (event, node) => {
  const stringNode = JSON.stringify(node);
  event.dataTransfer.setData('application/reactflow', stringNode);
  // eslint-disable-next-line no-param-reassign
  event.dataTransfer.effectAllowed = 'move';
};

const Sidebar = () => (
  <aside className={styles.sidebar}>
    <div style={{ textAlign: 'center' }}>
      <h2>Add a Table</h2>
      <section className={styles.actionNodes}>
        <div className={styles.description}>Drag and drop the table shape you want to add</div>
        <div
          className={`${styles.actionNode} ${actionStyle.actionNode} mt-5`}
          onDragStart={(event) => onDragStart(event, { type: 'actionNode', name: 'Square' })}
          draggable
          style={{
            height: '150px',
            width: '150px',
            backgroundColor: '#fff',
          }}
        >
          Square
        </div>
        <div
          className={`${styles.actionNode} ${actionStyle.actionNode} mt-5`}
          onDragStart={(event) => onDragStart(event, { type: 'actionNode', name: 'Circle' })}
          draggable
          style={{
            height: '150px',
            width: '150px',
            backgroundColor: '#fff',
            borderRadius: '50%',
          }}
        >
          Circle
        </div>
        <div
          className={`${styles.actionNode} ${actionStyle.actionNode} mt-5`}
          onDragStart={(event) => onDragStart(event, { type: 'actionNode', name: 'Rectangle' })}
          draggable
          style={{
            height: '125px',
            width: '200px',
            backgroundColor: '#fff',
            border: '1px solid #ccc !important',
          }}
        >
          Rectangle
        </div>
      </section>
    </div>
  </aside>
);

export default Sidebar;
