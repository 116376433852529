// @flow
import React, { useRef, useState } from 'react';
import { Modal, Input } from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';

const { TextArea } = Input;

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  inventoryItemDetails: Object,
};

const DeleteOrderType = (props: Props) => {
  const {
    visible,
    close,
    submit,
    inventoryItemDetails,
  } = props;

  const [, forceUpdate] = useState();
  const [note, setNote] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      tableGid: inventoryItemDetails.gid,
      note,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Do not Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton} type="danger">Yes, cancel</SpButton>,
      ]}
      title="Cancel Reservation"
    >
      <div className="px-4">
        <SpH5 className="mt-1 mb-2">Are you sure you want to cancel the reservation?</SpH5>
        <div className="mt-3 pb-3">
          <label htmlFor="">Add Comments</label>
          <TextArea
            rows={3}
            defaultValue={note}
            placeholder="Comments"
            onChange={(e) => setNote(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('comments', note, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteOrderType;
