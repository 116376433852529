export const CurrencyPrefixList = [
  {
    id: 1,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'US',
  },
  {
    id: 2,
    name: 'INR',
    prefix: '₹',
    toFixed: 2,
    countryAlpha2: 'IN',
  },
  {
    id: 3,
    name: 'AFN',
    prefix: '؋',
    toFixed: 2,
    countryAlpha2: 'AF',
  },
  {
    id: 4,
    name: 'ALL',
    prefix: 'Lek',
    toFixed: 2,
    countryAlpha2: 'AL',
  },
  {
    id: 5,
    name: 'DZD',
    prefix: 'د.ج',
    toFixed: 2,
    countryAlpha2: 'DZ',
  },
  {
    id: 6,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'AS',
  },
  {
    id: 7,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'AD',
  },
  {
    id: 8,
    name: 'AOA',
    prefix: 'Kz',
    toFixed: 2,
    countryAlpha2: 'AO',
  },
  {
    id: 9,
    name: 'XCD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'AI',
  },
  {
    id: 10,
    name: 'XCD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'AG',
  },
  {
    id: 11,
    name: 'ARS',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'AR',
  },
  {
    id: 12,
    name: 'AMD',
    prefix: '֏',
    toFixed: 2,
    countryAlpha2: 'AM',
  },
  {
    id: 13,
    name: 'AWG',
    prefix: 'ƒ',
    toFixed: 2,
    countryAlpha2: 'AW',
  },
  {
    id: 14,
    name: 'AUD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'AU',
  },
  {
    id: 15,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'AT',
  },
  {
    id: 16,
    name: 'AZN',
    prefix: 'ман',
    toFixed: 2,
    countryAlpha2: 'AZ',
  },
  {
    id: 17,
    name: 'BSD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'BS',
  },
  {
    id: 18,
    name: 'BHD',
    prefix: 'ب.د',
    toFixed: 2,
    countryAlpha2: 'BH',
  },
  {
    id: 19,
    name: 'BDT',
    prefix: '৳',
    toFixed: 2,
    countryAlpha2: 'BD',
  },
  {
    id: 20,
    name: 'BBD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'BB',
  },
  {
    id: 21,
    name: 'BYN',
    prefix: 'Br',
    toFixed: 2,
    countryAlpha2: 'BY',
  },
  {
    id: 22,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'BE',
  },
  {
    id: 23,
    name: 'BZD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'BZ',
  },
  {
    id: 24,
    name: 'XOF',
    prefix: 'CFA',
    toFixed: 2,
    countryAlpha2: 'BJ',
  },
  {
    id: 25,
    name: 'BMD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'BM',
  },
  {
    id: 26,
    name: 'BTN',
    prefix: 'Nu.',
    toFixed: 2,
    countryAlpha2: 'BT',
  },
  {
    id: 27,
    name: 'BOB',
    prefix: 'Bs.',
    toFixed: 2,
    countryAlpha2: 'BO',
  },
  {
    id: 28,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'BQ',
  },
  {
    id: 29,
    name: 'BAM',
    prefix: 'КМ',
    toFixed: 2,
    countryAlpha2: 'BA',
  },
  {
    id: 30,
    name: 'BWP',
    prefix: 'P',
    toFixed: 2,
    countryAlpha2: 'BW',
  },
  {
    id: 31,
    name: 'NOK',
    prefix: 'kr',
    toFixed: 2,
    countryAlpha2: 'BV',
  },
  {
    id: 32,
    name: 'BRL',
    prefix: 'R$',
    toFixed: 2,
    countryAlpha2: 'BR',
  },
  {
    id: 33,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'IO',
  },
  {
    id: 34,
    name: 'BND',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'BN',
  },
  {
    id: 35,
    name: 'BGN',
    prefix: 'лв',
    toFixed: 2,
    countryAlpha2: 'BG',
  },
  {
    id: 36,
    name: 'XOF',
    prefix: 'CFA',
    toFixed: 2,
    countryAlpha2: 'BF',
  },
  {
    id: 37,
    name: 'BIF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'BI',
  },
  {
    id: 38,
    name: 'CVE',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'CV',
  },
  {
    id: 39,
    name: 'KHR',
    prefix: '៛',
    toFixed: 2,
    countryAlpha2: 'KH',
  },
  {
    id: 40,
    name: 'XAF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'CM',
  },
  {
    id: 41,
    name: 'CAD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'CA',
  },
  {
    id: 42,
    name: 'KYD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'KY',
  },
  {
    id: 43,
    name: 'XAF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'CF',
  },
  {
    id: 44,
    name: 'XAF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'TD',
  },
  {
    id: 45,
    name: 'CLP',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'CL',
  },
  {
    id: 46,
    name: 'CNY',
    prefix: '¥',
    toFixed: 2,
    countryAlpha2: 'CN',
  },
  {
    id: 47,
    name: 'AUD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'CX',
  },
  {
    id: 48,
    name: 'AUD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'CC',
  },
  {
    id: 49,
    name: 'COP',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'CO',
  },
  {
    id: 50,
    name: 'KMF',
    prefix: 'CF',
    toFixed: 2,
    countryAlpha2: 'KM',
  },
  {
    id: 51,
    name: 'CDF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'CD',
  },
  {
    id: 52,
    name: 'XAF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'CG',
  },
  {
    id: 53,
    name: 'NZD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'CK',
  },
  {
    id: 54,
    name: 'CRC',
    prefix: '₡',
    toFixed: 2,
    countryAlpha2: 'CR',
  },
  {
    id: 55,
    name: 'HRK',
    prefix: 'Kn',
    toFixed: 2,
    countryAlpha2: 'HR',
  },
  {
    id: 56,
    name: 'CUP',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'CU',
  },
  {
    id: 57,
    name: 'ANG',
    prefix: 'ƒ',
    toFixed: 2,
    countryAlpha2: 'CW',
  },
  {
    id: 58,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'CY',
  },
  {
    id: 59,
    name: 'CZK',
    prefix: 'Kč',
    toFixed: 2,
    countryAlpha2: 'CZ',
  },
  {
    id: 60,
    name: 'XOF',
    prefix: 'CFA',
    toFixed: 2,
    countryAlpha2: 'CI',
  },
  {
    id: 61,
    name: 'DKK',
    prefix: 'kr',
    toFixed: 2,
    countryAlpha2: 'DK',
  },
  {
    id: 62,
    name: 'DJF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'DJ',
  },
  {
    id: 63,
    name: 'XCD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'DM',
  },
  {
    id: 64,
    name: 'DOP',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'DO',
  },
  {
    id: 65,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'EC',
  },
  {
    id: 66,
    name: 'EGP',
    prefix: '£',
    toFixed: 2,
    countryAlpha2: 'EG',
  },
  {
    id: 67,
    name: 'SVC',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'SV',
  },
  {
    id: 68,
    name: 'XAF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'GQ',
  },
  {
    id: 69,
    name: 'ERN',
    prefix: 'Nfk',
    toFixed: 2,
    countryAlpha2: 'ER',
  },
  {
    id: 70,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'EE',
  },
  {
    id: 71,
    name: 'SZL',
    prefix: 'L',
    toFixed: 2,
    countryAlpha2: 'SZ',
  },
  {
    id: 72,
    name: 'ETB',
    prefix: 'Br',
    toFixed: 2,
    countryAlpha2: 'ET',
  },
  {
    id: 73,
    name: 'FKP',
    prefix: '£',
    toFixed: 2,
    countryAlpha2: 'FK',
  },
  {
    id: 74,
    name: 'DKK',
    prefix: 'kr',
    toFixed: 2,
    countryAlpha2: 'FO',
  },
  {
    id: 75,
    name: 'FJD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'FJ',
  },
  {
    id: 76,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'FI',
  },
  {
    id: 77,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'FR',
  },
  {
    id: 78,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'GF',
  },
  {
    id: 79,
    name: 'XPF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'PF',
  },
  {
    id: 80,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'TF',
  },
  {
    id: 81,
    name: 'XAF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'GA',
  },
  {
    id: 82,
    name: 'GMD',
    prefix: 'D',
    toFixed: 2,
    countryAlpha2: 'GM',
  },
  {
    id: 83,
    name: 'GEL',
    prefix: 'ლ',
    toFixed: 2,
    countryAlpha2: 'GE',
  },
  {
    id: 84,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'DE',
  },
  {
    id: 85,
    name: 'GHS',
    prefix: '₵',
    toFixed: 2,
    countryAlpha2: 'GH',
  },
  {
    id: 86,
    name: 'GIP',
    prefix: '£',
    toFixed: 2,
    countryAlpha2: 'GI',
  },
  {
    id: 87,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'GR',
  },
  {
    id: 88,
    name: 'DKK',
    prefix: 'kr',
    toFixed: 2,
    countryAlpha2: 'GL',
  },
  {
    id: 89,
    name: 'XCD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'GD',
  },
  {
    id: 90,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'GP',
  },
  {
    id: 91,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'GU',
  },
  {
    id: 92,
    name: 'GTQ',
    prefix: 'Q',
    toFixed: 2,
    countryAlpha2: 'GT',
  },
  {
    id: 93,
    name: 'GBP',
    prefix: '£',
    toFixed: 2,
    countryAlpha2: 'GG',
  },
  {
    id: 94,
    name: 'GNF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'GN',
  },
  {
    id: 95,
    name: 'XOF',
    prefix: 'CFA',
    toFixed: 2,
    countryAlpha2: 'GW',
  },
  {
    id: 96,
    name: 'GYD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'GY',
  },
  {
    id: 97,
    name: 'HTG',
    prefix: 'G',
    toFixed: 2,
    countryAlpha2: 'HT',
  },
  {
    id: 98,
    name: 'AUD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'HM',
  },
  {
    id: 99,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'VA',
  },
  {
    id: 100,
    name: 'HNL',
    prefix: 'L',
    toFixed: 2,
    countryAlpha2: 'HN',
  },
  {
    id: 101,
    name: 'HKD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'HK',
  },
  {
    id: 102,
    name: 'HUF',
    prefix: 'Ft',
    toFixed: 2,
    countryAlpha2: 'HU',
  },
  {
    id: 103,
    name: 'ISK',
    prefix: 'Kr',
    toFixed: 2,
    countryAlpha2: 'IS',
  },
  {
    id: 104,
    name: 'IDR',
    prefix: 'Rp',
    toFixed: 2,
    countryAlpha2: 'ID',
  },
  {
    id: 105,
    name: 'IRR',
    prefix: '﷼',
    toFixed: 2,
    countryAlpha2: 'IR',
  },
  {
    id: 106,
    name: 'IQD',
    prefix: 'ع.د',
    toFixed: 2,
    countryAlpha2: 'IQ',
  },
  {
    id: 107,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'IE',
  },
  {
    id: 108,
    name: 'GBP',
    prefix: '£',
    toFixed: 2,
    countryAlpha2: 'IM',
  },
  {
    id: 109,
    name: 'ILS',
    prefix: '₪',
    toFixed: 2,
    countryAlpha2: 'IL',
  },
  {
    id: 110,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'IT',
  },
  {
    id: 111,
    name: 'JMD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'JM',
  },
  {
    id: 112,
    name: 'JPY',
    prefix: '¥',
    toFixed: 2,
    countryAlpha2: 'JP',
  },
  {
    id: 113,
    name: 'GBP',
    prefix: '£',
    toFixed: 2,
    countryAlpha2: 'JE',
  },
  {
    id: 114,
    name: 'JOD',
    prefix: 'د.ا',
    toFixed: 2,
    countryAlpha2: 'JO',
  },
  {
    id: 115,
    name: 'KZT',
    prefix: '〒',
    toFixed: 2,
    countryAlpha2: 'KZ',
  },
  {
    id: 116,
    name: 'KES',
    prefix: 'Sh',
    toFixed: 2,
    countryAlpha2: 'KE',
  },
  {
    id: 117,
    name: 'AUD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'KI',
  },
  {
    id: 118,
    name: 'KPW',
    prefix: '₩',
    toFixed: 2,
    countryAlpha2: 'KP',
  },
  {
    id: 119,
    name: 'KRW',
    prefix: '₩',
    toFixed: 2,
    countryAlpha2: 'KR',
  },
  {
    id: 120,
    name: 'KWD',
    prefix: 'د.ك',
    toFixed: 2,
    countryAlpha2: 'KW',
  },
  {
    id: 121,
    name: 'KGS',
    prefix: 'Лв',
    toFixed: 2,
    countryAlpha2: 'KG',
  },
  {
    id: 122,
    name: 'LAK',
    prefix: '₭',
    toFixed: 2,
    countryAlpha2: 'LA',
  },
  {
    id: 123,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'LV',
  },
  {
    id: 124,
    name: 'LBP',
    prefix: 'ل.ل',
    toFixed: 2,
    countryAlpha2: 'LB',
  },
  {
    id: 125,
    name: 'LSL',
    prefix: 'L',
    toFixed: 2,
    countryAlpha2: 'LS',
  },
  {
    id: 126,
    name: 'LRD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'LR',
  },
  {
    id: 127,
    name: 'LYD',
    prefix: 'ل.د',
    toFixed: 2,
    countryAlpha2: 'LY',
  },
  {
    id: 128,
    name: 'CHF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'LI',
  },
  {
    id: 129,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'LT',
  },
  {
    id: 130,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'LU',
  },
  {
    id: 131,
    name: 'MOP',
    prefix: 'P',
    toFixed: 2,
    countryAlpha2: 'MO',
  },
  {
    id: 132,
    name: 'MGA',
    prefix: 'Ar',
    toFixed: 2,
    countryAlpha2: 'MG',
  },
  {
    id: 133,
    name: 'MWK',
    prefix: 'MK',
    toFixed: 2,
    countryAlpha2: 'MW',
  },
  {
    id: 134,
    name: 'MYR',
    prefix: 'RM',
    toFixed: 2,
    countryAlpha2: 'MY',
  },
  {
    id: 135,
    name: 'MVR',
    prefix: 'ރ.',
    toFixed: 2,
    countryAlpha2: 'MV',
  },
  {
    id: 136,
    name: 'XOF',
    prefix: 'CFA',
    toFixed: 2,
    countryAlpha2: 'ML',
  },
  {
    id: 137,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'MT',
  },
  {
    id: 138,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'MH',
  },
  {
    id: 139,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'MQ',
  },
  {
    id: 140,
    name: 'MRU',
    prefix: 'UM',
    toFixed: 2,
    countryAlpha2: 'MR',
  },
  {
    id: 141,
    name: 'MUR',
    prefix: 'Rs',
    toFixed: 2,
    countryAlpha2: 'MU',
  },
  {
    id: 142,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'YT',
  },
  {
    id: 143,
    name: 'MXN',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'MX',
  },
  {
    id: 144,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'FM',
  },
  {
    id: 145,
    name: 'MDL',
    prefix: 'L',
    toFixed: 2,
    countryAlpha2: 'MD',
  },
  {
    id: 146,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'MC',
  },
  {
    id: 147,
    name: 'MNT',
    prefix: '₮',
    toFixed: 2,
    countryAlpha2: 'MN',
  },
  {
    id: 148,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'ME',
  },
  {
    id: 149,
    name: 'XCD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'MS',
  },
  {
    id: 150,
    name: 'MAD',
    prefix: 'د.م.',
    toFixed: 2,
    countryAlpha2: 'MA',
  },
  {
    id: 151,
    name: 'MZN',
    prefix: 'MTn',
    toFixed: 2,
    countryAlpha2: 'MZ',
  },
  {
    id: 152,
    name: 'MMK',
    prefix: 'K',
    toFixed: 2,
    countryAlpha2: 'MM',
  },
  {
    id: 153,
    name: 'NAD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'NA',
  },
  {
    id: 154,
    name: 'AUD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'NR',
  },
  {
    id: 155,
    name: 'NPR',
    prefix: 'Rs',
    toFixed: 2,
    countryAlpha2: 'NP',
  },
  {
    id: 156,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'NL',
  },
  {
    id: 157,
    name: 'XPF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'NC',
  },
  {
    id: 158,
    name: 'NZD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'NZ',
  },
  {
    id: 159,
    name: 'NIO',
    prefix: 'C$',
    toFixed: 2,
    countryAlpha2: 'NI',
  },
  {
    id: 160,
    name: 'XOF',
    prefix: 'CFA',
    toFixed: 2,
    countryAlpha2: 'NE',
  },
  {
    id: 161,
    name: 'NGN',
    prefix: '₦',
    toFixed: 2,
    countryAlpha2: 'NG',
  },
  {
    id: 162,
    name: 'NZD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'NU',
  },
  {
    id: 163,
    name: 'AUD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'NF',
  },
  {
    id: 164,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'MP',
  },
  {
    id: 165,
    name: 'NOK',
    prefix: 'kr',
    toFixed: 2,
    countryAlpha2: 'NO',
  },
  {
    id: 166,
    name: 'OMR',
    prefix: 'ر.ع.',
    toFixed: 2,
    countryAlpha2: 'OM',
  },
  {
    id: 167,
    name: 'PKR',
    prefix: 'Rs',
    toFixed: 2,
    countryAlpha2: 'PK',
  },
  {
    id: 168,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'PW',
  },
  {
    id: 169,
    name: 'ILS',
    prefix: '₪',
    toFixed: 2,
    countryAlpha2: 'PS',
  },
  {
    id: 170,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'PA',
  },
  {
    id: 171,
    name: 'PGK',
    prefix: 'K',
    toFixed: 2,
    countryAlpha2: 'PG',
  },
  {
    id: 172,
    name: 'PYG',
    prefix: '₲',
    toFixed: 2,
    countryAlpha2: 'PY',
  },
  {
    id: 173,
    name: 'PEN',
    prefix: 'S/.',
    toFixed: 2,
    countryAlpha2: 'PE',
  },
  {
    id: 174,
    name: 'PHP',
    prefix: '₱',
    toFixed: 2,
    countryAlpha2: 'PH',
  },
  {
    id: 175,
    name: 'NZD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'PN',
  },
  {
    id: 176,
    name: 'PLN',
    prefix: 'zł',
    toFixed: 2,
    countryAlpha2: 'PL',
  },
  {
    id: 177,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'PT',
  },
  {
    id: 178,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'PR',
  },
  {
    id: 179,
    name: 'QAR',
    prefix: 'ر.ق',
    toFixed: 2,
    countryAlpha2: 'QA',
  },
  {
    id: 180,
    name: 'MKD',
    prefix: 'ден',
    toFixed: 2,
    countryAlpha2: 'MK',
  },
  {
    id: 181,
    name: 'RON',
    prefix: 'L',
    toFixed: 2,
    countryAlpha2: 'RO',
  },
  {
    id: 182,
    name: 'RUB',
    prefix: 'р.',
    toFixed: 2,
    countryAlpha2: 'RU',
  },
  {
    id: 183,
    name: 'RWF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'RW',
  },
  {
    id: 184,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'RE',
  },
  {
    id: 185,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'BL',
  },
  {
    id: 186,
    name: 'SHP',
    prefix: '£',
    toFixed: 2,
    countryAlpha2: 'SH',
  },
  {
    id: 187,
    name: 'XCD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'KN',
  },
  {
    id: 188,
    name: 'XCD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'LC',
  },
  {
    id: 189,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'MF',
  },
  {
    id: 190,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'PM',
  },
  {
    id: 191,
    name: 'XCD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'VC',
  },
  {
    id: 192,
    name: 'WST',
    prefix: 'T',
    toFixed: 2,
    countryAlpha2: 'WS',
  },
  {
    id: 193,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'SM',
  },
  {
    id: 194,
    name: 'STN',
    prefix: 'Db',
    toFixed: 2,
    countryAlpha2: 'ST',
  },
  {
    id: 195,
    name: 'SAR',
    prefix: 'ر.س',
    toFixed: 2,
    countryAlpha2: 'SA',
  },
  {
    id: 196,
    name: 'XOF',
    prefix: 'CFA',
    toFixed: 2,
    countryAlpha2: 'SN',
  },
  {
    id: 197,
    name: 'RSD',
    prefix: 'din',
    toFixed: 2,
    countryAlpha2: 'RS',
  },
  {
    id: 198,
    name: 'SCR',
    prefix: 'Rs',
    toFixed: 2,
    countryAlpha2: 'SC',
  },
  {
    id: 199,
    name: 'SLL',
    prefix: 'Le',
    toFixed: 2,
    countryAlpha2: 'SL',
  },
  {
    id: 200,
    name: 'SGD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'SG',
  },
  {
    id: 201,
    name: 'ANG',
    prefix: 'ƒ',
    toFixed: 2,
    countryAlpha2: 'SX',
  },
  {
    id: 202,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'SK',
  },
  {
    id: 203,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'SI',
  },
  {
    id: 204,
    name: 'SBD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'SB',
  },
  {
    id: 205,
    name: 'SOS',
    prefix: 'Sh',
    toFixed: 2,
    countryAlpha2: 'SO',
  },
  {
    id: 206,
    name: 'ZAR',
    prefix: 'R',
    toFixed: 2,
    countryAlpha2: 'ZA',
  },
  {
    id: 207,
    name: 'FKP',
    prefix: '£',
    toFixed: 2,
    countryAlpha2: 'GS',
  },
  {
    id: 208,
    name: 'SSP',
    prefix: '£',
    toFixed: 2,
    countryAlpha2: 'SS',
  },
  {
    id: 209,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'ES',
  },
  {
    id: 210,
    name: 'LKR',
    prefix: 'Rs',
    toFixed: 2,
    countryAlpha2: 'LK',
  },
  {
    id: 211,
    name: 'SDG',
    prefix: '£',
    toFixed: 2,
    countryAlpha2: 'SD',
  },
  {
    id: 212,
    name: 'SRD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'SR',
  },
  {
    id: 213,
    name: 'NOK',
    prefix: 'kr',
    toFixed: 2,
    countryAlpha2: 'SJ',
  },
  {
    id: 214,
    name: 'CHF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'CH',
  },
  {
    id: 215,
    name: 'SEK',
    prefix: 'kr',
    toFixed: 2,
    countryAlpha2: 'SE',
  },
  {
    id: 216,
    name: 'SYP',
    prefix: 'ل.س',
    toFixed: 2,
    countryAlpha2: 'SY',
  },
  {
    id: 217,
    name: 'TWD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'TW',
  },
  {
    id: 218,
    name: 'TJS',
    prefix: 'ЅМ',
    toFixed: 2,
    countryAlpha2: 'TJ',
  },
  {
    id: 219,
    name: 'TZS',
    prefix: 'Sh',
    toFixed: 2,
    countryAlpha2: 'TZ',
  },
  {
    id: 220,
    name: 'THB',
    prefix: '฿',
    toFixed: 2,
    countryAlpha2: 'TH',
  },
  {
    id: 221,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'TL',
  },
  {
    id: 222,
    name: 'XOF',
    prefix: 'CFA',
    toFixed: 2,
    countryAlpha2: 'TG',
  },
  {
    id: 223,
    name: 'NZD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'TK',
  },
  {
    id: 224,
    name: 'TOP',
    prefix: 'T$',
    toFixed: 2,
    countryAlpha2: 'TO',
  },
  {
    id: 225,
    name: 'TTD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'TT',
  },
  {
    id: 226,
    name: 'TND',
    prefix: 'د.ت',
    toFixed: 2,
    countryAlpha2: 'TN',
  },
  {
    id: 227,
    name: 'TRY',
    prefix: '₤',
    toFixed: 2,
    countryAlpha2: 'TR',
  },
  {
    id: 228,
    name: 'TMT',
    prefix: 'm',
    toFixed: 2,
    countryAlpha2: 'TM',
  },
  {
    id: 229,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'TC',
  },
  {
    id: 230,
    name: 'AUD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'TV',
  },
  {
    id: 231,
    name: 'UGX',
    prefix: 'Sh',
    toFixed: 2,
    countryAlpha2: 'UG',
  },
  {
    id: 232,
    name: 'UAH',
    prefix: '₴',
    toFixed: 2,
    countryAlpha2: 'UA',
  },
  {
    id: 233,
    name: 'AED',
    prefix: 'د.إ',
    toFixed: 2,
    countryAlpha2: 'AE',
  },
  {
    id: 234,
    name: 'GBP',
    prefix: '£',
    toFixed: 2,
    countryAlpha2: 'GB',
  },
  {
    id: 235,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'UM',
  },
  {
    id: 236,
    name: 'UYI',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'UY',
  },
  {
    id: 237,
    name: 'UZS',
    prefix: "so'm",
    toFixed: 2,
    countryAlpha2: 'UZ',
  },
  {
    id: 238,
    name: 'VUV',
    prefix: 'Vt',
    toFixed: 2,
    countryAlpha2: 'VU',
  },
  {
    id: 239,
    name: 'VEF',
    prefix: 'Bs F',
    toFixed: 2,
    countryAlpha2: 'VE',
  },
  {
    id: 240,
    name: 'VND',
    prefix: '₫',
    toFixed: 2,
    countryAlpha2: 'VN',
  },
  {
    id: 241,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'VG',
  },
  {
    id: 242,
    name: 'USD',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'VI',
  },
  {
    id: 243,
    name: 'XPF',
    prefix: '₣',
    toFixed: 2,
    countryAlpha2: 'WF',
  },
  {
    id: 244,
    name: 'MAD',
    prefix: 'د.م.',
    toFixed: 2,
    countryAlpha2: 'EH',
  },
  {
    id: 245,
    name: 'YER',
    prefix: '﷼',
    toFixed: 2,
    countryAlpha2: 'YE',
  },
  {
    id: 246,
    name: 'ZMW',
    prefix: 'ZK',
    toFixed: 2,
    countryAlpha2: 'ZM',
  },
  {
    id: 247,
    name: 'ZWL',
    prefix: '$',
    toFixed: 2,
    countryAlpha2: 'ZW',
  },
  {
    id: 248,
    name: 'EUR',
    prefix: '€',
    toFixed: 2,
    countryAlpha2: 'AX',
  },
];
