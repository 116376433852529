export default status => {
  if (status === 'LOAD') {
    return '#ececff';
  } if (status === 'PAYOUT') {
    return '#ececff';
  } if (status === 'CANCELLED') {
    return '#e2e2e2';
  } if (status === 'TRANSACTION_CREDIT') {
    return '#e2e2e2';
  } if (status === 'TRANSACTION_DEBIT') {
    return '#e2e2e2';
  } if (status === 'REFUND') {
    return '#e2e2e2';
  } if (status === 'PARTIAL_REFUNDED') {
    return '#e2e2e2';
  }
  return '#ececff';
};
