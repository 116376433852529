import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { SpTab } from 'components/DesignKit';
import { ROLES } from 'appconstants';

import TransactionsList from './components/TransactionsList';
import CashList from './components/CashList';
import ChequeList from './components/ChequeList';
import CashDetails from './components/CashDetails';
import ChequeDetails from './components/ChequeDetails';

type Props = {
  location: {
    pathname: string
  },
};

const Transaction = (props: Props) => {
  const { internalRole, role } = useSelector(state => state.user);
  const type = internalRole ? internalRole.type : role.type;
  const roleType = type && type.toLowerCase();
  const [current, setCurrent] = useState('transactions');
  const {
    location,
  } = props;

  const setMenu = (query) => {
    switch (query) {
      case '/transaction': {
        setCurrent('transactions');
        break;
      }
      case '/transaction/cash': {
        setCurrent('cashtransaction');
        break;
      }
      case '/transaction/cheque': {
        setCurrent('chequetransaction');
        break;
      }
      default:
        setCurrent('transactions');
    }
  };

  useEffect(() => {
    setMenu(location.pathname);
  }, [location]);

  return (
    <div>
      {
        (location.pathname === '/transaction' || location.pathname === '/transaction/cash' || location.pathname === '/transaction/cheque') && (
          <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
            {
              [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER].includes(roleType) ? (
                <Menu.Item key="transactions">
                  <SpTab
                    to="/transaction"
                    selected={current === 'transactions'}
                  >
                    All Digital Payments
                  </SpTab>
                </Menu.Item>
              ) : ''
            }
            <Menu.Item key="cashtransaction">
              <SpTab
                to="/transaction/cash"
                selected={current === 'cashtransaction'}
              >
                Cash
              </SpTab>
            </Menu.Item>
            <Menu.Item key="chequetransaction">
              <SpTab
                to="/transaction/cheque"
                selected={current === 'chequetransaction'}
              >
                Cheque
              </SpTab>
            </Menu.Item>
          </Menu>
        )
      }
      <Switch>
        <Route exact path="/transaction" component={TransactionsList} />
        <Route exact path="/transaction/cash" component={CashList} />
        <Route exact path="/transaction/cheque" component={ChequeList} />
        <Route exact path="/transaction/cash/:gid" component={CashDetails} />
        <Route exact path="/transaction/cheque/:gid" component={ChequeDetails} />
      </Switch>
    </div>
  );
};

export default withRouter(Transaction);
