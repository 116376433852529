import { FEEDBACK } from '../actionTypes';

export const fetchInventoryFeedback = (action) => ({
  type: FEEDBACK.FETCH,
  payload: action.payload,
});

export const getInventoryOrderFeedack = (action) => ({
  type: FEEDBACK.ORDER_FEEDBACK_FETCH,
  payload: action.payload,
});

export const addInventoryFeedback = (action) => ({
  type: FEEDBACK.ADD,
  payload: action.payload,
});

export const updateInventoryFeedback = (action) => ({
  type: FEEDBACK.UPDATE,
  payload: action.payload,
});

export const deleteInventoryFeedback = (action) => ({
  type: FEEDBACK.DELETE,
  payload: action.payload,
});
