import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import moment from 'moment';
import {
  Icon,
  Row,
  Table,
  Col,
  Badge,
  Tooltip,
} from 'antd';
import queryString from 'query-string';
import {
  PAGES,
  FILTERS_TYPES,
  FILTER_STATUSES,
} from 'appconstants';
import {
  SpButton,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import useInterval from 'utils/useInterval';
import Filters from 'components/Filters';
import getFilterOperators from 'utils/getFilterOperators';
import Download from 'components/NavIcons/downloadProof';
import DisableDownload from 'components/NavIcons/downloadNoProof';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const REPORTS_FILTERS_TYPES = FILTERS_TYPES.REPORTS;
const ReportTypes = FILTER_STATUSES.ADMIN_REPORTS;

type Props = {
  history: Object,
  reports: Array<Object>,
  selectedAccount: Function,
  fetchAdminReports: Function,
  downloadReport: Function,
  isMobileView: Boolean,
};

const POLLING_INTERVAL = 30 * 1000; // 15 Seconds

const ReportsTable = ({
  history,
  reports: newReports,
  selectedAccount,
  fetchAdminReports,
  downloadReport,
  isMobileView,
}: Props) => {
  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const [reports, setReports] = useState([]);
  const [sortParams, setSortParams] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const myRef = useRef();
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(REPORTS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterReports, setFilterReports] = useState([{
    filterType: '',
    filterName: '',
    filterOperator: '',
    filterValue: '',
    filterNameDisplay: '',
    filterValueFrom: '',
    filterValueTo: '',
    checked: true,
  }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const exportData = reports.content ? reports.content.map((values) => ({
    type: values.type,
    generatedAt: values.generatedAt ? moment
      .utc(values.generatedAt)
      .tz(selectedAccount && selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a') : '',
  })) : '';

  const exportHeaders = [
    { label: 'REPORT TYPE', key: 'type' },
    { label: 'REQUESTED ON', key: 'generatedAt' },
  ];

  const debouncedFetchReports = debounce(fetchAdminReports, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPageFromQs = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParamsFromQs = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    debouncedFetchReports({
      currentPage: currentPageFromQs,
      sortParams: sortParamsFromQs,
      filterParams,
    });
    setPagination({
      current: parseInt(currentPageFromQs, 10),
    });
    setSortParams(sortParamsFromQs);
    setCurrentPage(currentPageFromQs);
  };

  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    setReports((curReports) => {
      if (!curReports.content) {
        // Easy decision to make. The grid has no records
        // to speak of
        return newReports;
      }

      if (curReports.totalElements !== newReports.totalElements) {
        // The number of entries has changed, so
        // re-render the grid with new data
        return newReports;
      }

      // The number of records are same, lets see
      // if the status have changed
      //

      // Create a map of new reports to attribute
      const newReportsMap = {};
      newReports
        .content
        .forEach(newReport => {
          newReportsMap[newReport.gid] = {
            status: newReport.status,
          };
        });

      // Check if any of the attributes have changed
      for (let i = 0; i < curReports.content.length; i += 1) {
        const curStatus = curReports.content[i].status;
        const newStatus = newReportsMap[curReports.content[i].gid]
          && newReportsMap[curReports.content[i].gid];
        if (curStatus !== newStatus) {
          return newReports;
        }
      }

      // return the same state so there are no
      // updates to the grid
      return curReports;
    });
    setPagination(existingElements => {
      if (existingElements.total !== newReports.totalElements) {
        return {
          ...existingElements,
          current: newReports.number,
          total: newReports.totalElements,
          showTotal: total => `Total ${total} items`,
        };
      }
      return existingElements;
    });
  }, [newReports]);

  useInterval(() => {
    fetchAdminReports({ currentPage, sortParams });
  }, POLLING_INTERVAL);

  useEffect(() => {
    fetchAdminReports({ currentPage, sortParams });
  }, []);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: PAGES.ADMIN_REPORT,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterReports];
    if (filterReports.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = REPORTS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterReports(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterReports];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'type') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = REPORTS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterReports(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterReports];
    const regex = /^\d*\.?\d*$/;
    if (regex.test(e.currentTarget.value)) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterReports(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterReports];
    const regex = /^\d*\.?\d*$/;
    if (regex.test(e.currentTarget.value)) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterReports(list);
    }
    setFilterReports(list);
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterReports[index].filterName === 'createdAt') {
      if (filterReports[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterReports[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterReports[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterReports];
    list[index].filterValue = selectedValue;
    setFilterReports(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterReports];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterReports[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterReports(list);
  };

  const handleAddClick = () => {
    const list = [...filterReports];
    setFilterReports([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterReports([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(REPORTS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
  };

  const applyFilters = () => {
    const reportssFilters = filterReports.filter((item) => item.checked);
    for (let i = 0; i < reportssFilters.length; i += 1) {
      if (!reportssFilters[i].filterName || !reportssFilters[i].filterOperator || !reportssFilters[i].filterValue) {
        return;
      }
    }
    if (reportssFilters[0].filterValue || (reportssFilters[0].filterValueFrom && reportssFilters[0].filterValueTo)) {
      setCount(reportssFilters && reportssFilters.length);
      for (let i = 0; i < reportssFilters.length; i += 1) {
        if (reportssFilters[i].checked) {
          if (reportssFilters[i].filterName === 'createdAt') {
            reportssFilters[i] = reportssFilters[i].filterValue;
          } else {
            reportssFilters[i] = `${reportssFilters[i].filterName}.${reportssFilters[i].filterOperator}=${reportssFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(reportssFilters.join('&'));
      history.push({
        pathname: PAGES.ADMIN_REPORT,
        search: `?filterParams=${filterParams}`,
      });
    }
  };

  const columns = [
    {
      title: 'REPORT TYPE',
      dataIndex: 'type',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'ACCOUNT',
      dataIndex: 'reportCategory',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'REQUESTED ON',
      dataIndex: 'generatedAt',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (generatedAt) => {
        if (generatedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(generatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DURATION',
      dataIndex: 'date',
      width: '30%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (date, record) => {
        if (record.startDate && record.endDate && selectedAccount && selectedAccount.timezone) {
          const fromDate = moment
            .utc(record.startDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          const toDate = moment
            .utc(record.endDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{fromDate} To {toDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'actions',
      width: '20%',
      align: 'center',
      render: (text, record) => (
        <>
          {
            record.status === 'GENERATED' ? (
              <Icon
                component={Download}
                style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
                onClick={() => downloadReport(record)}
              />
            ) : (
              <Icon
                component={DisableDownload}
                style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
              />
            )
          }
        </>
      ),
    },
  ];

  return (
    <>
      <Row type="flex" justify="space-between" className="mb-4 mt-5">
        <Col>
          <h5 className="text-uppercase">Recent Reports</h5>
        </Col>
        <Col>
          <span>
            {
              exportData !== null
                ? (
                  <Tooltip placement="left" title="Click here to export Reports">
                    <CSVLink
                      target="_blank"
                      className="mr-3"
                      filename="Recent Reports.csv"
                      data={exportData}
                      headers={exportHeaders}
                      onMouseEnter={() => setFillColor(true)}
                      onMouseLeave={() => setFillColor(false)}
                    >
                      <ExportIcon
                        fill={fillColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </CSVLink>
                  </Tooltip>
                ) : null
            }
          </span>
          <span ref={myRef}>
            <span
              onClick={() => setOpen(!open)}
              style={{ cursor: 'pointer' }}
              onMouseEnter={() => setFilterColor(true)}
              onMouseLeave={() => setFilterColor(false)}
            >
              {open && (
                <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                  <FilterIcon
                    fill={filterColor
                      ? '#279dfe'
                      : '#C0C0C0'}
                  />
                </Badge>
              )}
              {!open && (
                <Tooltip placement="left" title="Click here to filter Reports">
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                </Tooltip>
              )}
            </span>
            {open && (
              <FilterWrapper
                style={{ marginLeft: '-264px' }}
              >
                <Filters
                  filtersGrid={filterReports}
                  filterTypes={filterTypes}
                  handleChange={handleChange}
                  onCheck={onCheck}
                  fiterOperators={fiterOperators}
                  handleOperator={handleOperator}
                  handleValue={handleValue}
                  startDate={startDate}
                  endDate={endDate}
                  currentDate={currentDate}
                  handleFromValue={handleFromValue}
                  handleToValue={handleToValue}
                  stuses={ReportTypes}
                />
                <div className="px-4 mt-2">
                  {
                    filterReports.length < REPORTS_FILTERS_TYPES.length && (
                      <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                        <SpText
                          fontWeight="600"
                          color="#279dfe"
                          onClick={handleAddClick}
                        >
                          +&nbsp; Add another filter
                        </SpText>
                      </div>
                    )
                  }
                </div>
                <div className="d-flex mb-2 justify-content-end mr-3">
                  <SpButton
                    type="secondary"
                    shape="round"
                    className="mr-2"
                    ghost
                    onClick={resetFilters}
                  >
                    Clear
                  </SpButton>
                  <SpButton
                    type="primary"
                    shape="round"
                    onClick={applyFilters}
                  >
                    Save
                  </SpButton>
                </div>
              </FilterWrapper>
            )}
          </span>
        </Col>
      </Row>
      <Table
        columns={columns}
        rowKey={(record) => record.gid}
        dataSource={reports.content}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  );
};

export default withRouter(ReportsTable);
