import { BULKTRANSFER_STATUSES } from 'appconstants';

export default value => {
  if (value === 'CREATED') {
    return BULKTRANSFER_STATUSES.CREATED;
  } if (value === 'PROCESSING') {
    return BULKTRANSFER_STATUSES.PROCESSING;
  } if (value === 'SUCCEEDED') {
    return BULKTRANSFER_STATUSES.SUCCEEDED;
  } if (value === 'PARTIAL_SUCCEEDED') {
    return BULKTRANSFER_STATUSES.PARTIAL_SUCCEEDED;
  } if (value === 'FAILED') {
    return BULKTRANSFER_STATUSES.FAILED;
  } if (value === 'NOT_VERIFIED') {
    return BULKTRANSFER_STATUSES.NOT_VERIFIED;
  } if (value === 'VERIFIED') {
    return BULKTRANSFER_STATUSES.VERIFIED;
  } if (value === 'VERIFICATION_FAILED') {
    return BULKTRANSFER_STATUSES.VERIFICATION_FAILED;
  } return null;
};
