/* eslint-disable */
import React from 'react';

const report = ({ fill = "silver" }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M3.28869 8.39879C2.95654 8.39879 2.68727 8.66806 2.68727 9.00022C2.68727 9.33237 2.95654 9.60164 3.28869 9.60164H8.72657C9.05873 9.60164 9.32799 9.33237 9.32799 9.00022C9.32799 8.66806 9.05873 8.39879 8.72657 8.39879H3.28869Z" fill={fill} />
      <path d="M2.68727 6.12891C2.68727 5.79676 2.95654 5.52749 3.28869 5.52749H6.47123C6.80339 5.52749 7.07265 5.79676 7.07265 6.12891C7.07265 6.46107 6.80339 6.73034 6.47123 6.73034H3.28869C2.95654 6.73034 2.68727 6.46107 2.68727 6.12891Z" fill={fill} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.80427 13.0058H11.1957C12.1922 13.0058 13 12.198 13 11.2015V3.96739C13 3.4233 12.7545 2.90826 12.3318 2.56568L9.66276 0.402554C9.34142 0.142117 8.94035 0 8.52673 0H1.80427C0.8078 0 0 0.8078 0 1.80427V11.2015C0 12.198 0.807797 13.0058 1.80427 13.0058ZM1.30309 1.80427C1.30309 1.52747 1.52747 1.30309 1.80427 1.30309H7.38112V3.66808C7.38112 4.30471 7.89722 4.8208 8.53385 4.8208H11.6969V11.2015C11.6969 11.4783 11.4725 11.7027 11.1957 11.7027H1.80427C1.52747 11.7027 1.30309 11.4783 1.30309 11.2015V1.80427ZM11.4369 3.51772L8.84229 1.41491C8.79491 1.3765 8.74128 1.34736 8.68421 1.32847V3.51772H11.4369Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="13" height="13" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default report;
