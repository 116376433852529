import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { businessActions } from 'store/actions';
import debounce from 'lodash.debounce';
import { LARGE_VOLUME_MERCHANTS } from '../../appconstants';

import DashboardView from './dashbord';
import DashboardErr from './dashboardErr';

type Props = {
  selectedAccount: Object,
  history: {
    push: Function,
  };
  business: Object,
  partner: boolean,
  fetchBusinessByAccountId: Function,
};

const Dashboard = (props: Props) => {
  const {
    selectedAccount,
    history,
    business,
    partner,
    fetchBusinessByAccountId,
  } = props;

  const [largeVolumeMerchants, setLargeVolumeMerchants] = useState(false);
  const debouncedBussiness = debounce(fetchBusinessByAccountId, 2000, { leading: true });

  useEffect(() => {
    const gid = selectedAccount && selectedAccount.gid;
    const largeMerchant = LARGE_VOLUME_MERCHANTS.includes(gid);
    setLargeVolumeMerchants(largeMerchant);
    // if (!partner) {
    //   if (gid) {
    //     debouncedBussiness(gid);
    //   }
    // }
  }, [selectedAccount]);

  return (
    <>
      {
        largeVolumeMerchants ? (
          <DashboardErr />
        ) : (
          <DashboardView
            history={history}
            debouncedBussiness={debouncedBussiness}
            selectedAccount={selectedAccount}
            partner={partner}
            business={business}
          />
        )
      }
    </>

  );
};

const mapStateToProps = (state) => ({
  selectedAccount: state.account.selectedAccount,
  business: state.business.business,
  partner: state.partnerParams.partner,
});

const mapDispatchToProps = dispatch => ({
  fetchBusinessByAccountId: gid => dispatch(businessActions.fetchBusinessByAccountId({
    payload: gid,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
