// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Button,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { NOTIFICATION } from 'store/actionTypes';
import {
  notificationActions,
} from 'store/actions';
import {
  MESSAGES,
} from 'appconstants';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import {
  SpH5,
  SpError,
  SpButton,
} from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  lastFour: string,
  phoneCode: string,
  issuerGid: Object,
  otpRequires: boolean,
  setNotification: Function,
  transferSettings: Object,
};

const ConfirmOtp = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    lastFour,
    phoneCode,
    issuerGid,
    otpRequires,
    setNotification,
    transferSettings,
  } = props;

  const [, forceUpdate] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [UniqueId, setUniqueId] = useState('');
  const gid = issuerGid && issuerGid.gid;
  const otpRegex = /[0-9]{6}/;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      validOtp: {
        message: 'Please enter a valid Otp.',
        rule: val => otpRegex.test(val),
      },
    },
  }));

  const getOtp = async () => {
    try {
      const { data: { entity: { uniqueId } } } = await axiosAuthInstance.post(API_END_POINTS.TRANSFER_OTP);
      setUniqueId(uniqueId);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  };

  useEffect(() => {
    if (!otpRequires) {
      getOtp();
    }
  }, []);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      otpCode,
      gid,
      UniqueId,
      transferEmail: transferSettings && transferSettings.approvalEmail,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Confirm</SpButton>,
      ]}
      title="Confirm OTP"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        {
          otpRequires ? (
            <div className="mb-4 mt-4">
              <SpH5>
                We have sent an OTP to {phoneCode} ******{lastFour}
              </SpH5>
            </div>
          ) : (!otpRequires && (transferSettings && transferSettings.approvalEmail)) ? (
            <div className="mb-4 mt-4">
              <SpH5>
                We have sent an OTP to {transferSettings && transferSettings.approvalEmail}
              </SpH5>
            </div>
          ) : ''
        }
        <div>
          <label htmlFor=""><SpH5>Enter OTP to verify</SpH5></label>
          <Input
            placeholder="Enter OTP"
            onChange={(e) => setOtpCode(e.currentTarget.value)}
            suffix={<Button type="link" onClick={getOtp}>Resend OTP</Button>}
          />
          <SpError>
            {simpleValidator.current.message('otp', otpCode, 'required|validOtp')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  test: state.account.test,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOtp);
