import { SERVICERATE } from '../actionTypes';

export const fetchServicerates = (action) => ({
  type: SERVICERATE.FETCH,
  payload: action.payload,
});

export const addServicerate = (action) => ({
  type: SERVICERATE.ADD,
  payload: action.payload,
});

export const updateServicerate = (action) => ({
  type: SERVICERATE.UPDATE,
  payload: action.payload,
});

export const deleteServicerate = (action) => ({
  type: SERVICERATE.DELETE,
  payload: action.payload,
});
