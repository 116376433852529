import React from 'react';
import formatNumber from 'utils/formatNumber';
import {
  Row,
  Col,
} from 'antd';
import {
  SpForm,
  SpH5,
} from 'components/DesignKit';

type Props = {
  rewardSettings: Object,
  selectedAccount: Object,
};

const View = (props: Props) => {
  const {
    rewardSettings,
    selectedAccount,
  } = props;

  const { prefix } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Reward Rate</span>
          </Col>
          <Col span={9}>
            <SpH5 color={!rewardSettings?.enableRewards ? '#999999' : 'initial'}>
              {rewardSettings?.rewardRate
                ? `${formatNumber((rewardSettings.rewardRate / 100).toFixed(2), currencyType)}%`
                : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Reward Cap</span>
          </Col>
          <Col span={9}>
            <SpH5 color={!rewardSettings?.enableRewards ? '#999999' : 'initial'}>
              {rewardSettings.rewardCap
                ? `${prefix}${formatNumber((rewardSettings.rewardCap / 100).toFixed(2), currencyType)}`
                : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Cashback Rate</span>
          </Col>
          <Col span={9}>
            <SpH5 color={!rewardSettings?.enableRewards ? '#999999' : 'initial'}>
              {rewardSettings.redemptionRate
                ? `${formatNumber((rewardSettings.redemptionRate / 100).toFixed(2), currencyType)}%`
                : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Cashback Cap</span>
          </Col>
          <Col span={9}>
            <SpH5 color={!rewardSettings?.enableRewards ? '#999999' : 'initial'}>
              {rewardSettings.redemptionCap
                ? `${prefix}${formatNumber((rewardSettings.redemptionCap / 100).toFixed(2), currencyType)}`
                : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Points Expires in</span>
          </Col>
          <Col span={9}>
            <SpH5 color={!rewardSettings?.enableRewards ? '#999999' : 'initial'}>
              {rewardSettings.daysToExpiry ? `${rewardSettings.daysToExpiry} Days` : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
      </SpForm>
    </>
  );
};

export default React.memo(View);
