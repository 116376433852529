import { TERMINAL } from '../actionTypes';

const initialState = {
  content: [],
  locationList: [],
  searchLocation: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TERMINAL.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case TERMINAL.LOCATIONS_SUCCESS:
      return {
        ...state,
        locationList: action.payload,
      };
    case TERMINAL.SEARCH_LOCATION:
      return {
        ...state,
        searchLocation: action.payload,
      };
    default:
      return state;
  }
};
