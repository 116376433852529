import {
  call,
  put,
} from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  BANK_TRANSFERS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const createIcicCustomer = async ({ gid, params }) => axiosAuthInstance.post(`/account/${gid}${API_END_POINTS.ICIC_CUSTOMER}`, params);

const addFundingSource = async ({ gid, params }) => axiosAuthInstance.post(`/account/${gid}${API_END_POINTS.ICIC_FUNDING}`, params);

const fetchAccountByID = async gid => axiosAuthInstance.get(`${API_END_POINTS.USER_ACCOUNT}/${gid}`);


export function* addIcicCustomer(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(createIcicCustomer, action.payload);
    yield put({ type: BANK_TRANSFERS.SUCCESS, payload: entity });
    const { data } = yield call(fetchAccountByID, action.payload.gid);
    yield put({ type: BANK_TRANSFERS.ACCOUNT_BY_ID_SUCCESS, payload: data.entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ICICI_CUSTOMER.REGISTER_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* createIciciFundingSource(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(addFundingSource, action.payload);
    const { data: { entity } } = yield call(fetchAccountByID, action.payload.gid);
    yield put({ type: BANK_TRANSFERS.ACCOUNT_BY_ID_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ICICI_CUSTOMER.FUNDING_SOURCE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* accountById(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    const { data: { entity } } = yield call(fetchAccountByID, action.payload);
    yield put({ type: BANK_TRANSFERS.ACCOUNT_BY_ID_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
