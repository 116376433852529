import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  REWARD_SETTINGS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchRewardSettings = async () => axiosAuthInstance.get(`${API_END_POINTS.REWARD_SETTINGS}`);

const postRewardSettings = async payload => axiosAuthInstance.post(API_END_POINTS.REWARD_SETTINGS, payload);

const patchRewardSettings = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.REWARD_SETTINGS}/${gid}`, params);

export function* getRewardSettings(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchRewardSettings, action.payload);
    yield put({ type: REWARD_SETTINGS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: REWARD_SETTINGS.FAILED, payload: e.response.data });
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addRewardSettings(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(postRewardSettings, action.payload);
    const { data: { entity } } = yield call(fetchRewardSettings);
    yield put({ type: REWARD_SETTINGS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUPPORT.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    yield put({
      type: NOTIFICATION.ERROR,
      payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
    });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateRewardSettings(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchRewardSettings, action.payload);
    const { data: { entity } } = yield call(fetchRewardSettings);
    yield put({ type: REWARD_SETTINGS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUPPORT.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
