import { BULKTRANSFER_DETAILS } from '../actionTypes';

export const fetchBulkTransferDetails = (action) => ({
  type: BULKTRANSFER_DETAILS.FETCH,
  payload: action.payload,
});

export const fetchTransfers = (action) => ({
  type: BULKTRANSFER_DETAILS.TRANSFERS_FETCH,
  payload: action.payload,
});
