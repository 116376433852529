import { PRODUCT } from '../actionTypes';

export const fetchProducts = (action) => ({
  type: PRODUCT.FETCH,
  payload: action.payload,
});

export const addProduct = (action) => ({
  type: PRODUCT.ADD,
  payload: action.payload,
});

export const updateProduct = (action) => ({
  type: PRODUCT.UPDATE,
  payload: action.payload,
});

export const deleteProduct = (action) => ({
  type: PRODUCT.DELETE,
  payload: action.payload,
});
