import { ACHPAYMENT_STATUSES } from 'appconstants';

export default value => {
  if (value === 'TO_ACTIVATE') {
    return ACHPAYMENT_STATUSES.ACTIVE;
  } if (value === 'DISABLED') {
    return ACHPAYMENT_STATUSES.DISABLED;
  } if (value === 'BANK_VERIFICATION_PENDING') {
    return ACHPAYMENT_STATUSES.PENDING;
  } if (value === 'VERIFICATION_PENDING') {
    return ACHPAYMENT_STATUSES.VERIFICATION_PENDING;
  } if (value === 'VERIFIED') {
    return ACHPAYMENT_STATUSES.VERIFIED;
  } return ACHPAYMENT_STATUSES.DISABLED;
};
