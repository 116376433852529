// @flow
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { SpH5 } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';


type Props = {
  submitting: boolean,
  itemsList: Array,
  reviewData: Object,
};

const Review = forwardRef((props: Props, ref) => {
  const {
    submitting,
    itemsList,
    reviewData,
  } = props;
  const dateFormat = 'MMM DD, YYYY';
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [code] = useState(reviewData && reviewData.code);
  const [startDate] = useState(reviewData && reviewData.startDate);
  const [expiryDate] = useState(reviewData && reviewData.expiryDate);
  const [valueQuantity] = useState(reviewData && reviewData.valueQuantity);
  const [claimType] = useState(reviewData && reviewData.claimType);
  const [claimQuantity] = useState(reviewData && reviewData.claimQuantity);
  const [claimPercentageOff] = useState(reviewData && reviewData.claimPercentageOff);
  const [valueItems] = useState((reviewData && reviewData.valueItems) || []);
  const [claimableItems] = useState((reviewData && reviewData.claimableItems) || []);

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      code,
      startDate,
      expiryDate,
      valueQuantity,
      claimType,
      claimQuantity,
      claimPercentageOff,
      valueItems,
      claimableItems,
    }),
  }));

  const getItemName = (record) => {
    let itemsName = '';

    record.forEach((element, index) => {
      const itemDto = itemsList.find(item => item.gid === element);

      if (itemDto) {
        if (index === record.length - 1) {
          itemsName += `${itemDto.name}.`;
        } else {
          itemsName += `${itemDto.name}, `;
        }
      }
    });

    return itemsName;
  };

  const getFormattedDate = (date) => moment(date).startOf('day').format(dateFormat);


  return (
    <>
      <div className="px-5">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div style={{ backgroundColor: '#eeeeee', marginTop: '15px' }}>
          <div className="px-3 pt-3 pb-3">
            <div>
              <label htmlFor=""><SpH5>Name :</SpH5> {code}</label>
            </div>
            { startDate && startDate !== '' && (
              <div>
                <label htmlFor=""><SpH5>Start Date :</SpH5> {getFormattedDate(startDate)}</label>
              </div>
            )}
            { expiryDate && expiryDate !== '' && (
              <div>
                <label htmlFor=""><SpH5>Expiry Date :</SpH5> {getFormattedDate(expiryDate)}</label>
              </div>
            )}
            <div>
              <label htmlFor=""><SpH5>Buy Item Details :</SpH5></label>
            </div>
            <div className="ml-3">
              <label htmlFor=""><SpH5>Quantity :</SpH5> {valueQuantity} </label>
            </div>
            <div className="ml-3 pr-3">
              <label htmlFor=""><SpH5>Items :</SpH5> {getItemName(valueItems)} </label>
            </div>
            <div>
              <label htmlFor=""><SpH5>Get Item Details :</SpH5></label>
            </div>
            <div className="ml-3">
              { claimType === 'QUANTITY' ? (
                <label htmlFor=""><SpH5>Quantity :</SpH5> {claimQuantity} </label>
              ) : (
                <label htmlFor=""><SpH5>Offer :</SpH5> {claimPercentageOff}% </label>
              )}
            </div>
            <div className="ml-3 pr-3">
              <label htmlFor=""><SpH5>Items :</SpH5> {getItemName(claimableItems)} </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

// $FlowFixMe
export default connect(
  null,
  null,
  null,
  { forwardRef: true },
)(Review);
