import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import OffersList from './components/OffersList';
import OffersDetails from './components/OffersDetails';

const offers = () => (
  <Switch>
    <Route exact path="/offers" component={OffersList} />
    <Route exact path="/offers/:gid" component={OffersDetails} />
  </Switch>
);

export default offers;
