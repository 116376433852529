// @flow
/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react';
import {
  Input,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
// import formatNumber from 'utils/formatNumber';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';
import {
  COUNTRY_PHONE_CODE,
} from 'appconstants';
import {
  SpH5, SpError, SpButton, SpText,
} from 'components/DesignKit';
import {
  validators,
  countryCodeValidation,
} from 'utils/validationMessages';

const { Option } = Select;

type Props = {
  comboData: Array,
  prefix: string,
  currency: object,
  index: number,
  quantity: number,
  options: Array,
  callCombo: Function,
  id: String,
  onComboBoxChange: function,
};

const QuantityCombo = (props: Props) => {
  const {
    comboData,
    options,
    prefix,
    quantity,
    currency,
    index,
    id,
    callCombo,
    onComboBoxChange,
  } = props;
  const [, forceUpdate] = useState();
  const [comboList, setComboList] = useState(comboData);
  const [comboValueList, setComboValueList] = useState(comboData);
  const [comboAddons, setComboAddons] = useState([]);
  const addtionalComboBox = options;
  const [phoneNumberCode, setPhoneCode] = useState('+1');
  const [phoneFormate, setPhoneFormate] = useState('');
  const [contactPhone, setContactPhone] = useState('');

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      }, 
    },
  }));

  useEffect(() => {
    if (quantity === 0) {
      setComboAddons([]);
    }
    if (!quantity) {
      setComboAddons([]);
    }
    if (quantity && quantity === 1) {
      setComboAddons(options);
      callCombo(options, id);
    }
    if (quantity && quantity > 1) {
      const list = Array.from({ length: quantity }, () => [...options]).flat();;
      setComboAddons(list);
      callCombo(list, id);
    }
  }, [quantity]);

  const updateComboValue = (e, j) => {
    const selectedValue = e;
    const list = [...comboAddons];
    const obj = {};
    obj.type = selectedValue.split("&&")[0];
    obj.price = Number(selectedValue.split("&&")[1]);
    obj.label = list[j].label;
    obj.value = list[j].value;
    obj.children = list[j].children;
    list[j] = obj;
    setComboAddons(list);
    callCombo(list, id);
  }

  const updateName = (e, j) => {
    const selectedValue = e;
    const list = [...comboAddons];
    const obj = {};
    obj.type = selectedValue;
    obj.price = 0;
    obj.label = list[j].label;
    obj.value = list[j].value;
    obj.acceptText = true;
    list[j] = obj;
    setComboAddons(list);
    callCombo(list, id);
  };

  const updateEmail = (e, j) => {
    console.log(e, j, 'updateEmail');
    const selectedValue = e;
    const list = [...comboAddons];
    const obj = {};
    obj.type = selectedValue;
    obj.price = 0;
    obj.label = list[j].label;
    obj.value = list[j].value;
    obj.acceptText = true;
    list[j] = obj;
    setComboAddons(list);
    callCombo(list, id);
  };

  const onPhoneNumberChange = (e, j) => {
    const list = [...comboAddons];
    const obj = {};
    const updatedPhone = e.currentTarget.value;
    setContactPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
    obj.countryCode = list[j].countryCode ? list[j].countryCode : phoneNumberCode;
    obj.phone = updatedPhone.replace(/[^0-9+]/g, '');
    obj.price = 0;
    obj.label = list[j].label;
    obj.value = list[j].value;
    obj.type = `${obj.countryCode}${obj.phone}`;
    obj.acceptText = true;
    list[j] = obj;
    setComboAddons(list);
    callCombo(list, id);
  };

  const onCountryCodeChange = (e, j) => {
    const list = [...comboAddons];
    const obj = {};
    setPhoneCode(e);
    const data = formatNumber(`${e}${list[j].phone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
    obj.countryCode = e;
    obj.phone = list[j].phone;
    obj.type = `${obj.countryCode}${obj.phone}`;;
    obj.price = 0;
    obj.label = list[j].label;
    obj.value = list[j].value;
    obj.acceptText = true;
    list[j] = obj;
    setComboAddons(list);
    callCombo(list, id);
  };

  const updateAge = (e, j) => {
    const selectedValue = e;
    const list = [...comboAddons];
    const obj = {};
    obj.type = selectedValue;
    obj.price = 0;
    obj.label = list[j].label;
    obj.value = list[j].value;
    obj.acceptText = true;
    list[j] = obj;
    setComboAddons(list);
    callCombo(list, id);
  };

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
  }

  return (
    <div className="mt-3 mb-3">
      {
        (comboAddons && comboAddons.length > 0) && (
          comboAddons.map((list, j) => (
            <div className="mb-3">
              <label htmlFor=""><SpH5>{list.label}</SpH5></label>
              {
                list.acceptText ? (
                  <div>
                    {
                      list.label === 'Name' && (
                        <Input
                          placeholder={list.label}
                          value={list.type}
                          onChange={(e) => updateName(e.currentTarget.value, j)}
                        />
                      )
                    }
                    {
                      list.label === 'Email' && (
                        <>
                          <Input
                            placeholder={list.label}
                            value={list.type}
                            onChange={(e) => updateEmail(e.currentTarget.value, j)}
                            onBlur={(e) => validate(e)}
                          />
                          {
                            list.type && (
                              <div>
                              <SpError>
                                {simpleValidator.current.message('email', list.type, 'required|email')}
                              </SpError>
                              </div>
                            )
                          }
                          
                        </>
                      )
                    }
                    {
                      list.label === 'Phone' && (
                        <div className="d-flex">
                          <div
                            style={{ width: '20%' }}
                            onKeyPress={e => countryCodeValidation(e)}
                          >
                            <Select
                              className="w-100"
                              showSearch
                              value={list.countryCode ? list.countryCode : phoneNumberCode}
                              onChange={() => onCountryCodeChange(e, j)}
                            >
                              {COUNTRY_PHONE_CODE.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div style={{ width: '80%' }}>
                            <Input
                              className="InputnumFormate"
                              placeholder={list.label}
                              value={list.phone}
                              onChange={(e) => onPhoneNumberChange(e, j)}
                              onBlur={e => {
                                if (e.currentTarget.value) {
                                  setContactPhone(e.currentTarget.value);
                                  const value = formatNumber(`${list.countryCode ? list.countryCode : phoneNumberCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                                  const formater = value.substr(value.indexOf(' ') + 1);
                                  setPhoneFormate(formater);
                                }
                              }}
                            />
                            {
                              list.type && (
                                <SpError>
                                  {simpleValidator.current.message('phone number', list.type, `required|phoneNumValidation:${phoneNumberCode}${contactPhone}`)}
                                </SpError>
                              )
                            }
                            
                          </div>
                        </div>
                      )
                    }
                    {
                      list.label === 'Age' && (
                        <Input
                          placeholder={list.label}
                          value={list.type}
                          onChange={(e) => updateAge(e.currentTarget.value, j)}
                          onKeyPress={e => {
                            const keyCode = e.charCode || e.which;
                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )
                    }
                  </div>
                ) : (
                  <div className="d-flex">
                    <div style={{ width: '80%' }}>
                      <Select
                        className="w-100"
                        showSearch
                        defaultValue={list.val || 'Select'}
                        onChange={(e) => updateComboValue(e, j)}
                      >
                        {
                          list.children.map((items) => (
                            <Option key={items.label} value={`${items.value}&&${items.additionalPrice ? items.additionalPrice : 0}`}>
                              {`${items.label}: ${prefix}${formatNumber((items.additionalPrice ? items.additionalPrice / 100 : 0), currency)}`}</Option>
                          ))
                        }
                      </Select>
                    </div>
                  </div>
                )
              }
            </div>
          ))
        )
      }
    </div>
  );
};

// $FlowFixMe
export default QuantityCombo;
