export default status => {
  if (status === 'TO_REVERIFY') {
    return {
      text: 'Verifying',
      color: '#e2e2e2',
    };
  } if (status === 'TO_ACTIVATE') {
    return {
      text: 'Activate',
      color: '#ffe0b1',
    };
  } if (status === 'VERIFIED') {
    return {
      text: 'Verified',
      color: '#ececff',
    };
  }
  if (status === 'PENDING_TO_SIGN') {
    return {
      text: 'Pending To Sign',
      color: '#ececff',
    };
  }
  return {
    text: 'Verifying',
    color: '#e2e2e2',
  };
};
