import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Tooltip,
  Row,
  Col,
  Badge,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import Loading from 'components/Loading';
import Filters from 'components/Filters';
import formatNumber from 'utils/formatNumber';
import getFilterOperators from 'utils/getFilterOperators';

import {
  vendorInvoicesActions,
  notificationActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import getInvoiceBackground from 'utils/getInvoiceBackground';
import getInvoiceStatus from 'utils/getInvoiceStatus';
import {
  PAGES,
  FILTERS_TYPES,
} from 'appconstants';
import FilterIcon from 'components/NavIcons/filterIcon';
import SettingsIcon from 'components/NavIcons/settings';
import ExportIcon from 'components/NavIcons/exportIcon';
import {
  SpButton,
  SpStatusTag,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import InvoiceSeqGenerator from 'routes/Invoices/components/invoiceSeqGenerator';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const INVOICES_FILTERS_TYPES = FILTERS_TYPES.VENDORINVOICES;


type Props = {
  fetchVendorInvoices: Function,
  vendorInvoices: Array<Object>,
  loading: boolean,
  test: boolean,
  history: {
    push: Function,
  };
  totalElements: number,
  selectedAccount: Object,
  setFilterData: Function,
  preserveFilters: Object,
};

const AllInvoices = (props: Props) => {
  const location = useLocation();
  const {
    loading,
    fetchVendorInvoices,
    vendorInvoices,
    test,
    history,
    totalElements,
    selectedAccount,
    setFilterData,
    preserveFilters,
  } = props;
  const [pagination, setPagination] = useState({});
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(INVOICES_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterInvoice, setFilterInvoice] = useState((preserveFilters && preserveFilters.filterInvoice)
    ? (preserveFilters && preserveFilters.filterInvoice)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [settingsColor, setSettingsColor] = useState(false);
  const [invoiceOrderSeqWrapper, setInvoiceOrderSeqWrapper] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterInvoice: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });

  const getTotalAmount = (record) => {
    const totalAmount = (record.total / 100).toFixed(record.currency.toFixed);
    const value = formatNumber(totalAmount, currencyType).replace(/-/g, '');
    return value;
  };

  const exportData = vendorInvoices ? vendorInvoices.map((values) => ({
    total: `${values.currency.prefix} ${getTotalAmount(values)}`,
    name: values.contact.name,
    invoiceNumber: values.invoiceNumber,
    status: getInvoiceStatus(values.status),
    issueDate: moment
      .utc(values.issueDate)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY'),
    transferDueDate: moment
      .utc(values.transferDueDate)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY'),
  })) : '';

  const exportHeaders = [
    { label: 'AMOUNT', key: 'total' },
    { label: 'CONTACT', key: 'name' },
    { label: 'INVOICE NUMBER', key: 'invoiceNumber' },
    { label: 'STATUS', key: 'status' },
    { label: 'ISSUE DATE', key: 'issueDate' },
    { label: 'DUE DATE', key: 'transferDueDate' },
  ];

  const debouncedFetchVendorInvoices = debounce(fetchVendorInvoices, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const searchParam = parsed['status.IN'];
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchVendorInvoices({
      searchParam,
      currentPage,
      sortParams,
      filterParams,
    });
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.VENDORINVOICES}/${searchParams}`,
      state: location.search,
    });
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [vendorInvoices]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    const parsed = queryString.parse(location.search);
    const searchParam = parsed['status.IN'];
    const filterParams = parsed.filterParams || '';
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && searchParam && !filterParams) {
      history.push({
        pathname: PAGES.VENDORINVOICES,
        search: `?status.IN=${searchParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else if (!searchParam && !filterParams) {
      history.push({
        pathname: PAGES.VENDORINVOICES,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    } else if (searchParam && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.VENDORINVOICES,
        search: `?status.IN=${searchParam}&filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else if (!searchParam && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.VENDORINVOICES,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterInvoice];
    if (filterInvoice.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = INVOICES_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterInvoice(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterInvoice];
    if (e[1] === 'issueDate') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else if (e[1] === 'transferDueDate') {
      list[index].filterType = 'Date';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = INVOICES_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterInvoice(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterInvoice];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterInvoice(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterInvoice(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterInvoice];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterInvoice(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterInvoice(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    const filter = filterInvoice[index].filterName;
    if (filterInvoice[index].filterType === 'Date') {
      if (filterInvoice[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `${filter}.GT=${dateStart}&${filter}.LT=${dateEnd}`;
      } else if (filterInvoice[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `${filter}.LT=${dateStart}`;
      } else if (filterInvoice[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `${filter}.GT=${dateStart}`;
      }
    } else if (filterInvoice[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterInvoice];
    list[index].filterValue = selectedValue;
    setFilterInvoice(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterInvoice];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterType === 'Date') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterType === 'Date') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterInvoice[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterInvoice(list);
  };

  const handleAddClick = () => {
    const list = [...filterInvoice];
    setFilterInvoice([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterInvoice([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(INVOICES_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.VENDORINVOICES);
  };

  const applyFilters = () => {
    const invoicesFilters = filterInvoice.filter((item) => item.checked);
    for (let i = 0; i < invoicesFilters.length; i += 1) {
      if (!invoicesFilters[i].filterName || !invoicesFilters[i].filterOperator || !invoicesFilters[i].filterValue) {
        return;
      }
    }
    if (invoicesFilters[0].filterValue || (invoicesFilters[0].filterValueFrom && invoicesFilters[0].filterValueTo)) {
      setCount(invoicesFilters && invoicesFilters.length);
      for (let i = 0; i < invoicesFilters.length; i += 1) {
        if (invoicesFilters[i].checked) {
          if (invoicesFilters[i].filterType === 'Date') {
            invoicesFilters[i] = invoicesFilters[i].filterValue;
          } else if (invoicesFilters[i].filterName === 'amount' && invoicesFilters[i].filterOperator === 'BT') {
            const name = invoicesFilters[i].filterName;
            const from = invoicesFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = invoicesFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            invoicesFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (invoicesFilters[i].filterName === 'amount' && invoicesFilters[i].filterOperator !== 'BT') {
            const value = invoicesFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            invoicesFilters[i] = `${invoicesFilters[i].filterName}.${invoicesFilters[i].filterOperator}=${value}`;
          } else {
            invoicesFilters[i] = `${invoicesFilters[i].filterName}.${invoicesFilters[i].filterOperator}=${invoicesFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(invoicesFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const searchParam = parsed['status.IN'];
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'issueDate';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && searchParam && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?status.IN=${searchParam}&pageNum=${currentPage}${sortParams}&filterParams=${filterParams}`,
          filterInvoice,
        });
        history.push({
          pathname: PAGES.VENDORINVOICES,
          search: `?status.IN=${searchParam}&pageNum=${currentPage}${sortParams}&filterParams=${filterParams}`,
        });
      } else if (location.search && searchParam && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?status.IN=${searchParam}&filterParams=${filterParams}`,
          filterInvoice,
        });
        history.push({
          pathname: PAGES.VENDORINVOICES,
          search: `?status.IN=${searchParam}&filterParams=${filterParams}`,
        });
      } else if (location.search && !searchParam && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterInvoice,
        });
        history.push({
          pathname: PAGES.VENDORINVOICES,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'total',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (total, record) => {
        const totalAmount = (total / 100).toFixed(record.currency.toFixed);
        if (total < 0) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span>{`(${record.currency.prefix} ${formatNumber(totalAmount, currencyType).replace(/-/g, '')})`}</span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span> {`${record.currency.prefix} ${formatNumber(totalAmount, currencyType)}`}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'CONTACT',
      dataIndex: 'contact.name',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (contact, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>{(record.contact && record.contact.name) || <>&#8211;</>}</span>
        </Tooltip>
      ),
    },
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'invoiceNumber',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (invoiceNumber) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>{invoiceNumber}</span>
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '14%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getInvoiceBackground(status) }}>
              {getInvoiceStatus(status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ISSUE DATE',
      dataIndex: 'issueDate',
      width: '15%',
      align: 'left',
      render: (issueDate) => {
        if (issueDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(issueDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span>{tzDate}</span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>&#8211;</span>
          </Tooltip>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DUE DATE',
      dataIndex: 'transferDueDate',
      width: '13%',
      align: 'left',
      render: (transferDueDate) => {
        if (transferDueDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(transferDueDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span>{tzDate}</span>
            </Tooltip>
          );
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];
  const handleInvoiceWrapper = () => {
    setInvoiceOrderSeqWrapper(!invoiceOrderSeqWrapper);
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <SpButton
              type="secondary"
              shape="round"
              className="mr-3"
              ghost
              onClick={() => history.push(PAGES.NEWVENDOR_INVOICE)}
            >
              {'\u002B'}&nbsp;Create Invoice
            </SpButton>
            <span>
              {
                exportData !== null
                  ? (
                    <Tooltip placement="left" title="Click here to export Invoices">
                      <CSVLink
                        target="_blank"
                        className="mr-3"
                        filename="Invoices Report.csv"
                        data={exportData}
                        headers={exportHeaders}
                        onMouseEnter={() => setFillColor(true)}
                        onMouseLeave={() => setFillColor(false)}
                      >
                        <ExportIcon
                          fill={fillColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </CSVLink>
                    </Tooltip>
                  ) : null
              }
            </span>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                className="mr-3"
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Invoices">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && !invoiceOrderSeqWrapper && (
                <FilterWrapper
                  style={{ marginLeft: '-125px' }}
                >
                  <Filters
                    filtersGrid={filterInvoice}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                    handleFromValue={handleFromValue}
                    handleToValue={handleToValue}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterInvoice.length < INVOICES_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
              {/* ------------------------------SeetingsIcon-orderSeqWrapper---------------------------------------------------- */}
              <span
                onClick={handleInvoiceWrapper}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setSettingsColor(true)}
                onMouseLeave={() => setSettingsColor(false)}
              >
                {invoiceOrderSeqWrapper && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <SettingsIcon
                      width="21"
                      height="22"
                      fill={settingsColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!invoiceOrderSeqWrapper && (
                  <Tooltip placement="left" title="Click here to Configure Order Sequence">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <SettingsIcon
                        width="21"
                        height="22"
                        fill={settingsColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {invoiceOrderSeqWrapper && (
                <InvoiceSeqGenerator
                  visibe={handleInvoiceWrapper}
                  type="VENDOR_INVOICE"
                />
              )}
            </span>
          </Col>
        </div>
      </Row>

      <Table
        onRow={(record) => ({
          onClick: () => {
            selectedRecord(record);
          },
        })}
        rowClassName={() => 'ant-table-clickable-row'}
        columns={columns}
        rowKey={(record) => (record.invoiceLinks ? record.invoiceLinks[0].gid : record.gid)}
        dataSource={vendorInvoices}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  vendorInvoices: state.vendorInvoices.content,
  loading: state.loading.loading,
  totalElements: state.vendorInvoices.totalElements,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVendorInvoices: param => dispatch(vendorInvoicesActions.fetchVendorInvoices({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllInvoices);
