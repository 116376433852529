// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  Checkbox,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5,
  SpError,
  SpButton,
  SpText,
} from 'components/DesignKit';
import formatAmount from 'utils/formatAmount';
import getPrefix from 'utils/getPrefix';
import {
  COUNTRIES,
  MAX_AMOUNT,
} from 'appconstants';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  test: boolean,
  submitting: boolean,
  selectedAccount: Object,
  selectedProduct: Object,
  supportedCurrency: Array,
};

const EditProduct = (props: Props) => {
  const {
    visible,
    close,
    submit,
    test,
    submitting,
    selectedAccount,
    selectedProduct,
    supportedCurrency,
  } = props;

  const [, forceUpdate] = useState();
  const [productName, setProductName] = useState(selectedProduct && selectedProduct.name);
  const [description, setDescription] = useState(selectedProduct && selectedProduct.description);
  const [amount, setAmount] = useState(selectedProduct ? (selectedProduct.amount / 100).toFixed(2) : '');
  const [notes, setNotes] = useState(selectedProduct && selectedProduct.note);
  const [disableButton, setDisableButton] = useState(false);
  const [promotion, setPromotion] = useState(selectedProduct && selectedProduct.promotionalProduct);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [prefix, setPrefix] = useState(selectedAccount.currency && selectedAccount.currency.prefix);
  const [currencyCode, setCurrencyCode] = useState(selectedAccount.currency && selectedAccount.currency.name);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const businessType = selectedAccount && selectedAccount.businessType;
  const gid = selectedProduct && selectedProduct.gid;
  // eslint-disable-next-line
  const [upfront, setUpfront] = useState(selectedProduct && selectedProduct.upfront === true ? false : true);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const onCurrencyChange = (e) => {
    setCurrencyCode(e);
    setPrefix(getPrefix(e));
  };

  const amountUpdate = (value) => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value)) {
      setAmount(value);
    } else {
      setAmount(0);
    }
  };

  const isUpfrontChange = (value) => {
    setUpfront(value);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      productName,
      currencyCode,
      description,
      amount,
      notes,
      promotion,
      isIndia,
      gid,
      // eslint-disable-next-line
      upfront: upfront === true ? false : true,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update Product</SpButton>,
      ]}
      title="Update Product"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Product Name"
            value={productName}
            onChange={(e) => setProductName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Product name', productName, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <Input
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Description', description, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Amount</SpH5></label>
          <div className="d-flex">
            <div style={{ width: '85%' }}>
              <Input
                placeholder="0.00"
                value={amount}
                prefix={prefix}
                onChange={(e) => amountUpdate(e.currentTarget.value)}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setAmount(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('amount', amount, 'required|amount')}
              </SpError>
            </div>
            <div style={{ width: '15%' }}>
              <Select
                className="w-100"
                showSearch
                defaultValue={currencyCode}
                onChange={(e) => onCurrencyChange(e)}
              >
                {supportedCurrency.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        {
          (!test && (businessType === 'B2C')) ? (
            <>
              <div className="mb-3">
                <label htmlFor=""><SpH5>Internal Notes</SpH5></label>
                <Input
                  placeholder="Optional notes for internal use"
                  value={notes}
                  onChange={(e) => setNotes(e.currentTarget.value)}
                />
              </div>
              <div>
                <label htmlFor="">
                  <Checkbox
                    className="mr-2"
                    checked={promotion}
                    onChange={({ target: { checked } }) => {
                      setPromotion(checked);
                    }}
                  />
                  <SpText fontSize="14px">Promotional Product</SpText>
                </label>
              </div>
            </>
          ) : (
            <div>
              <label htmlFor=""><SpH5>Internal Notes</SpH5></label>
              <Input
                placeholder="Optional notes for internal use"
                value={notes}
                onChange={(e) => setNotes(e.currentTarget.value)}
              />
            </div>
          )
        }
        <div className="mt-3">
          <Switch
            className="mr-2"
            checked={upfront}
            onChange={isUpfrontChange}
          /> Is Recurring?
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditProduct);
