import { RECAPTCHA_DETAILS } from '../actionTypes';

const initialState = {
  content: [],
  recaptchaDetailsStatus: undefined,
  recaptchaDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECAPTCHA_DETAILS.FETCH:
      return {
        ...state,
        recaptchaDetailsStatus: 'STARTED',
      };
    case RECAPTCHA_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        recaptchaDetailsStatus: 'SUCCESS',
      };
    case RECAPTCHA_DETAILS.FAILED:
      return {
        ...state,
        recaptchaDetailsError: action.payload,
        recaptchaDetailsStatus: 'FAILED',
      };
    default:
      return state;
  }
};
