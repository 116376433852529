import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  BENEFICIARY,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchBeneficiary = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.WALLETBENEFICIARY}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.WALLETBENEFICIARY}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.WALLETBENEFICIARY}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.WALLETBENEFICIARY}${query}${sorting}`);
};

const postBeneficiary = async payload => axiosAuthInstance.post(API_END_POINTS.WALLETBENEFICIARY, payload);

const patchBeneficiary = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.WALLETBENEFICIARY}/${gid}`, params);

const deleteSelectedBeneficiary = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.WALLETBENEFICIARY}/${gid}`);

export function* getBeneficiary(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchBeneficiary, action.payload);
    yield put({ type: BENEFICIARY.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: BENEFICIARY.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addBeneficiary(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postBeneficiary, action.payload);
    const { data: { entity } } = yield call(fetchBeneficiary, action.payload);
    yield put({ type: BENEFICIARY.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.BENEFICIARY.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateBeneficiary(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchBeneficiary, action.payload);
    const { data: { entity } } = yield call(fetchBeneficiary, action.payload);
    yield put({ type: BENEFICIARY.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.BENEFICIARY.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteBeneficiary(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteSelectedBeneficiary, action.payload);
    const { data: { entity } } = yield call(fetchBeneficiary, action.payload);
    yield put({ type: BENEFICIARY.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.BENEFICIARY.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
