import { SUBSCRIPTION } from '../actionTypes';

export const fetchSubscription = (action) => ({
  type: SUBSCRIPTION.FETCH,
  payload: action.payload,
});

export const addSubscription = (action) => ({
  type: SUBSCRIPTION.ADD,
  payload: action.payload,
});

export const fetchPlanSearch = (action) => ({
  type: SUBSCRIPTION.SEARCH_FETCH,
  payload: action.payload,
});

export const fetchCustomerSearch = (action) => ({
  type: SUBSCRIPTION.CUSTOMER_FETCH,
  payload: action.payload,
});

export const fetchProductList = (action) => ({
  type: SUBSCRIPTION.PRODUCT_FETCH,
  payload: action.payload,
});

export const fetchSearchProduct = (action) => ({
  type: SUBSCRIPTION.PRODUCT_SEARCH_FETCH,
  payload: action.payload,
});

export const fetchProfile = (action) => ({
  type: SUBSCRIPTION.PROFILE_FETCH,
  payload: action.payload,
});
