import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  OFFERDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchOfferDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.OFFERS}/${gid}`);

const patchOffer = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.OFFERS}/${gid}`, params);

const deactivateOffer = async (gid) => axiosAuthInstance.patch(`${API_END_POINTS.OFFERS}/${gid}/deactivate`);

const fetchOfferRedemptionsDetails = async (payload) => {
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  const filterByOffer = `&offerGid.EQ=${payload.gid}`;
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.OFFER_REDEMPTIONS}?${payload.filterParams}${filterByOffer}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.OFFER_REDEMPTIONS}?size=1000${sorting}&${payload.filterParams}${filterByOffer}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.OFFER_REDEMPTIONS}?size=1000${sorting}${filterByOffer}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.OFFER_REDEMPTIONS}?size=1000${sorting}${filterByOffer}`);
};

export function* getOfferDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchOfferDetails, action.payload);
    yield put({ type: OFFERDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: OFFERDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getOfferRedemptions(action) {
  try {
    const { data: { entity } } = yield call(fetchOfferRedemptionsDetails, action.payload);
    yield put({ type: OFFERDETAILS.REDEMPTIONS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: OFFERDETAILS.FAILED, payload: e.response.data });
  }
}

export function* updateOffer(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchOffer, action.payload);
    const { data: { entity } } = yield call(fetchOfferDetails, action.payload.gid);
    yield put({ type: OFFERDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.OFFERS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteOffer(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deactivateOffer, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.OFFERS.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
