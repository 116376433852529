import { call, put } from 'redux-saga/effects';

import {
  axiosQuickBooksAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  QUICKBOOKS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchQuickBooks = async () => axiosQuickBooksAuthInstance.get(API_END_POINTS.QUICKBOOKS_ACCOUNT);

const postQuickBooks = async (payload) => axiosQuickBooksAuthInstance.get(`${API_END_POINTS.QUICKBOOKS_OAUTH2}?${payload.params}`);

export function* getQuickBooks(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchQuickBooks, action.payload);
    yield put({ type: QUICKBOOKS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: QUICKBOOKS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addQuickBooks(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postQuickBooks, action.payload);
    const { data: { entity } } = yield call(fetchQuickBooks, action.payload);
    yield put({ type: QUICKBOOKS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.QUICKBOOKS.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: QUICKBOOKS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}
