import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  CHEQUE_RECEIPTS,
  LOADING,
} from '../actionTypes';

const fetchChequeReceipts = async gid => axiosAuthInstance.get(`${API_END_POINTS.CHEQUE_RECEIPTS}/${gid}`);

export function* getChequeReceipts(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchChequeReceipts, action.payload);
    yield put({ type: CHEQUE_RECEIPTS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: CHEQUE_RECEIPTS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
