import { call, put } from 'redux-saga/effects';

import generateDetailsQuery from 'utils/generateDetailsQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  LOCATIONSDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchLocationDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_LOCATIONS}/${gid}`);

const fetchTerminalDetails = async (payload) => {
  if (payload.gid) {
    const { gid } = payload;
    const { currentPage } = payload;
    const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
    const query = generateDetailsQuery(currentPage);
    return axiosAuthInstance.get(`${API_END_POINTS.GET_LOCATION_TERMINALS}=${gid}&${query}${sorting}`);
  }
  const pagination = generateDetailsQuery('');
  return axiosAuthInstance.get(`${API_END_POINTS.GET_LOCATION_TERMINALS}=${payload}&${pagination}`);
};

const patchTerminal = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_TERMINALS}/${gid}`, params);

const deleteTerminal = async gid => axiosAuthInstance.post(`${API_END_POINTS.GET_TERMINALS}/${gid}/location/remove`);

export function* getLocationDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchLocationDetails, action.payload);
      yield put({ type: LOCATIONSDETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchLocationDetails, action);
      yield put({ type: LOCATIONSDETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: LOCATIONSDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getTerminalDetails(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(fetchTerminalDetails, action.payload);
      yield put({ type: LOCATIONSDETAILS.TERMINALS_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchTerminalDetails, action);
      yield put({ type: LOCATIONSDETAILS.TERMINALS_SUCCESS, payload: entity });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: LOCATIONSDETAILS.FAILED, payload: e.response.data });
  }
}

export function* updateTerminals(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchTerminal, action.payload);
    yield put({ type: LOCATIONSDETAILS.SUCCESS });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.LOCATION_DETAILS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* removeTerminal(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteTerminal, action.payload.terminalGid);
    yield put({ type: LOCATIONSDETAILS.SUCCESS });
    const { data: { entity } } = yield call(fetchTerminalDetails, action.payload.gid);
    yield put({ type: LOCATIONSDETAILS.TERMINALS_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.LOCATION_DETAILS.REMOVE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
