import { VENDORSDETAILS } from '../actionTypes';

export const fetchVendorDetails = (action) => ({
  type: VENDORSDETAILS.FETCH,
  payload: action.payload,
});

export const fetchTransfers = (action) => ({
  type: VENDORSDETAILS.TRANSFERS_FETCH,
  payload: action.payload,
});

export const DeleteVendor = (action) => ({
  type: VENDORSDETAILS.DELETE,
  payload: action.payload,
});
