// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  paymentWidgetsDetails: Object,
};

const DeleteSelectedPaymentWidget = (props: Props) => {
  const {
    visible,
    close,
    submit,
    paymentWidgetsDetails,
  } = props;

  const deleteSelectedPaymentLink = () => {
    submit(paymentWidgetsDetails.gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">cancel</SpButton>,
        <SpButton onClick={deleteSelectedPaymentLink} type="danger">deactivate</SpButton>,
      ]}
      title="Deactivate Payment Widget"
    >
      <div className="px-4">
        <h6 className="mt-1 mb-2">Are you sure you want to deactivate this Payment widget?</h6>
        <SpH5 className="pb-3">
          <div>{paymentWidgetsDetails.title ? paymentWidgetsDetails.title : <>&#8211;</>}</div>
        </SpH5>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteSelectedPaymentWidget;
