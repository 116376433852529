import { TRANSFERS } from '../actionTypes';

export const fetchTransfers = (payload) => ({
  type: TRANSFERS.FETCH,
  payload,
});

export const getContactList = (action) => ({
  type: TRANSFERS.CONTACT_FETCH,
  payload: action.payload,
});

export const searchFundingSource = (action) => ({
  type: TRANSFERS.SEARCH_FUNDING_FETCH,
  payload: action.payload,
});

export const issueTransfer = (action) => ({
  type: TRANSFERS.ISSUETRANSFER,
  payload: action.payload,
});

export const fetchFundingSource = (payload) => ({
  type: TRANSFERS.FUNDING_FETCH,
  payload,
});

export const confirmTransferOtp = (action) => ({
  type: TRANSFERS.CONFIRMTRANSFEROTP,
  payload: action.payload,
});

export const cancelTransferInDetails = (action) => ({
  type:TRANSFERS.CANCELTRANSFERINDETAILS,
  payload:action.payload
})
export const updateTransfers = (payload) => ({
  type: TRANSFERS.UPDATE,
  payload,
});
