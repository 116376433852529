import { INVENTORY_MODIFIERS_DETAILS } from '../actionTypes';

export const fetchInventoryModifierDetails = (action) => ({
  type: INVENTORY_MODIFIERS_DETAILS.FETCH,
  payload: action.payload,
});

export const addInventoryModifier = (action) => ({
  type: INVENTORY_MODIFIERS_DETAILS.ADD_MODIFIER,
  payload: action.payload,
});

export const deleteInventoryModifier = (action) => ({
  type: INVENTORY_MODIFIERS_DETAILS.DELETE_MODIFIER,
  payload: action.payload,
});

export const updateInventoryModifier = (action) => ({
  type: INVENTORY_MODIFIERS_DETAILS.UPDATE_MODIFIER,
  payload: action.payload,
});
