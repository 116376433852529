import React, { useEffect, useState } from 'react';
import {
  useRouteMatch,
  withRouter,
} from 'react-router-dom';

import { Menu } from 'antd';
import queryString from 'query-string';

import { SpTab } from 'components/DesignKit';
import PayoutList from './component/PayoutList';

type Props = {
  location: {
    pathname: string,
    search: string,
  },
  history: {
    push: Function,
    replace: Function,
  },
};

const Payouts = (props: Props) => {
  const [current, setCurrent] = useState('all');
  const { path } = useRouteMatch();
  const {
    location,
    history,
  } = props;

  const setMenu = (query) => {
    const parsed = queryString.parse(query);
    if (parsed['status.EQ']) {
      const value = parsed['status.EQ'];
      switch (value) {
        case 'PENDING': {
          setCurrent('pending');
          break;
        }
        case 'SETTLED': {
          setCurrent('settled');
          break;
        }
        case 'ALL': {
          setCurrent('all');
          break;
        }
        default:
          setCurrent('all');
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      setMenu(location.search);
    } else {
      history.replace(`${path}?status.EQ=ALL`);
    }
  }, [location]);

  return (
    <div>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="all">
          <SpTab
            to={`${path}?status.EQ=ALL`}
            selected={current === 'all'}
          >
            All Payouts
          </SpTab>
        </Menu.Item>
        <Menu.Item key="pending">
          <SpTab
            to={`${path}?status.EQ=PENDING`}
            selected={current === 'pending'}
          >
            Pending
          </SpTab>
        </Menu.Item>
        <Menu.Item key="settled">
          <SpTab
            to={`${path}?status.EQ=SETTLED`}
            selected={current === 'settled'}
          >
            Settled
          </SpTab>
        </Menu.Item>
      </Menu>
      <PayoutList {...props} />
    </div>
  );
};

export default withRouter(Payouts);
