import { PLANV2DETAILS } from '../actionTypes';

export const fetchPlanV2Details = (action) => ({
  type: PLANV2DETAILS.FETCH,
  payload: action.payload,
});

export const fetchPlanV2Subscription = (action) => ({
  type: PLANV2DETAILS.PAYMENTS_FETCH,
  payload: action.payload,
});
