import { SUBSCRIPTIONV2DETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  payments: [],
  subscriptionData: [],
  subscriptionDetailsStatus: undefined,
  subscriptionDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTIONV2DETAILS.FETCH:
      return {
        ...state,
        subscriptionDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case SUBSCRIPTIONV2DETAILS.SUCCESS:
      return {
        ...state,
        subscriptionData: action.payload,
        subscriptionDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case SUBSCRIPTIONV2DETAILS.FAILED:
      return {
        ...state,
        subscriptionDetailsError: action.payload,
        subscriptionDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case SUBSCRIPTIONV2DETAILS.PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
      };
    default:
      return state;
  }
};
