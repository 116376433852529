import { CLOVER_SYNC } from '../actionTypes';

const initialState = {
  content: [],
  cloverSyncStatus: undefined,
  cloverSyncError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLOVER_SYNC.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case CLOVER_SYNC.FETCH:
      return {
        ...state,
        cloverSyncStatus: 'STARTED',
      };
    case CLOVER_SYNC.FAILED:
      return {
        ...state,
        cloverSyncError: action.payload,
        cloverSyncStatus: 'FAILED',
      };
    default:
      return state;
  }
};
