import { PAYMENTSLINK } from '../actionTypes';

export const fetchPaymentsLink = (action) => ({
  type: PAYMENTSLINK.FETCH,
  payload: action.payload,
});

export const addPaymentsLink = (action) => ({
  type: PAYMENTSLINK.ADD,
  payload: action.payload,
});

export const customerAdd = (action) => ({
  type: PAYMENTSLINK.ADD_CUSTOMER,
  payload: action.payload,
});

export const clearPaymentsLink = (action) => ({
  type: PAYMENTSLINK.CLEAR_DATA,
  payload: action.payload,
});
