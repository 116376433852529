// @flow
import React from 'react';
// import PaymentPage from '@zetametrics/gateway-secure';
import getPaymentMetaData from 'utils/getPaymentMetaData';
import getPaymentCloneData from 'utils/getPaymentCloneData';
import PaymentPage from 'routes/paymentpage';

type Props = {
  pageData: Object,
  account: Object,
  addParent: Array,
  productDetailsArray: Array,
  customerDetailsArray: Array,
  enableTip: Boolean,
  isTipOptional: Boolean,
  firstOption: String,
  secondOption: String,
  thirdOption: String,
  cloneId: String,
};

const ReviewPaymentPage = (props: Props) => {
  const {
    pageData,
    account,
    addParent,
    productDetailsArray,
    customerDetailsArray,
    enableTip,
    isTipOptional,
    firstOption,
    secondOption,
    thirdOption,
    cloneId,
  } = props;
  const {
    contactPhone,
    phoneNumberCode,
  } = pageData;
  const contactNumber = `${phoneNumberCode}${contactPhone}`;
  const contactList = { contactUsPhone: contactNumber, status: 'ACTIVE' };
  const data = Object.assign(pageData, contactList);
  const paymentPageMeta = cloneId ? getPaymentCloneData(data,
    productDetailsArray,
    customerDetailsArray,
    addParent,
    enableTip,
    isTipOptional,
    firstOption,
    secondOption,
    thirdOption) : getPaymentMetaData(data,
    productDetailsArray,
    customerDetailsArray,
    addParent,
    enableTip,
    isTipOptional,
    firstOption,
    secondOption,
    thirdOption);

  return (
    <>
      <div className="mb-3">
        {/* eslint-disable */}
        <PaymentPage
          paymentPageMeta={paymentPageMeta}
          onSubmit={null}
          phoneCode={phoneNumberCode}
          account={account}
          isPreview={true}
          isAdaptive={true}
        />
      </div>
    </>
  );
};

// $FlowFixMe
export default ReviewPaymentPage;
