import { RATES } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  acquirer: [],
  category: [],
  paymenttype: [],
  ratesStatus: undefined,
  ratesError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RATES.FETCH:
      return {
        ...state,
        ratesStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case RATES.SUCCESS:
      return {
        ...state,
        ...action.payload,
        ratesStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case RATES.FAILED:
      return {
        ...state,
        ratesError: action.payload,
        ratesStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case RATES.ACQUIRER_SUCCESS:
      return {
        ...state,
        acquirer: action.payload,
      };
    case RATES.CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload,
      };
    case RATES.PAYMENTTYPE_SUCCESS:
      return {
        ...state,
        paymenttype: action.payload,
      };
    default:
      return state;
  }
};
