import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  AFFILIATE,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchAffiliate = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.AFFILIATE_ACCOUNT}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.AFFILIATE_ACCOUNT}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.AFFILIATE_ACCOUNT}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.AFFILIATE_ACCOUNT}${query}${sorting}`);
};

const postAffiliate = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.AFFILIATE_ACCOUNT, params);

const notificationAffiliate = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.AFFILIATE_ACCOUNT}/${gid}/notify`, params);

export function* getAffiliate(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchAffiliate, action.payload);
    yield put({ type: AFFILIATE.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: AFFILIATE.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addAffiliate(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postAffiliate, action.payload);
    const { data: { entity } } = yield call(fetchAffiliate, action.payload);
    yield put({ type: AFFILIATE.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.AFFILIATE.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* notifyAffiliate(action) {
  try {
    yield call(notificationAffiliate, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: `${MESSAGES.AFFILIATE.NOTIFY_SUCCESS}${action.payload.name}`,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
