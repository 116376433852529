// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {
  Row,
  Col,
  Input,
  Select,
  AutoComplete,
} from 'antd';

import {
  SpButton,
  SpForm,
  SpText,
  SpError,
} from 'components/DesignKit';

import getStateList from 'utils/stateList';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';
import getCountryAlpha2 from 'utils/getCountryAlpha2';

import {
  MESSAGES,
  COUNTRY_CODES,
  COUNTRY_PHONE_CODE,
} from 'appconstants';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { notificationActions } from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import ButtonDelete from 'components/NavIcons/ButtonDelete';

const { Option } = Select;

type Props = {
  setNotification: Function,
  selectedAccount: Object,
  getTerminalMid: Function,
  countryName: string,
  terminalList: Array<Object>,
};

const NewPaperRoll = (props: Props) => {
  const {
    selectedAccount,
    getTerminalMid,
    terminalList,
    countryName,
  } = props;
  const [, forceUpdate] = useState();
  const [cntryCode, setCntryCode] = useState(getCountryPhoneCode(selectedAccount && selectedAccount.country && selectedAccount.country.id));
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [name, setName] = useState(null);
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [stateList, setSateList] = useState([]);
  const [countryId, setCountryId] = useState(selectedAccount && selectedAccount.country && selectedAccount.country.id);
  const [phoneFormate, setPhoneFormate] = useState();
  const [terminals, setTerminals] = useState(null);
  const [mid, setMid] = useState();
  const [fixErrors, setFixErrors] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [zipTag, setZipTag] = useState('');
  const [addRecipient, setAddRecipient] = useState([]);
  const postalCodeRegex = countryName === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      terminals: {
        message: 'Terminals should be within 1 to 100',
        rule: val => val >= 1 && val <= 100,
      },
      zipcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useEffect(() => {
    setSateList(getStateList(countryId));
    if (countryId === 2) {
      setCountry('INDIA');
      setZipTag('Pin code');
    } else if (countryId === 1) {
      setCountry('USA');
      setZipTag('Zip code');
    }
  }, [selectedAccount]);

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhoneNumber(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const updateCountry = (value) => {
    setCountry(value);
    setCountryId(value);
    setSateList(getStateList(value));
  };

  const addNewRecipient = (event) => {
    event.preventDefault();
    setAddRecipient([...addRecipient, '']);
  };
  const handleRemoveEmail = (i) => {
    const list = [...addRecipient];
    list.splice(i, 1);
    setAddRecipient(list);
    const hasNoEmails = (list.length === 0);
    if (hasNoEmails) {
      simpleValidator.current.purgeFields();
    }
  };

  const searchTerminalMid = (searchText) => {
    getTerminalMid(searchText);
    setMid(searchText);
  };

  const updateLocation = (value) => {
    if (value) {
      const results = terminalList.filter((item) => (item.gid === value));
      if (results && results[0].gid) {
        setMid(results[0].terminalMid);
        if (results[0].terminalLocation) {
          setName(results[0].terminalLocation && results[0].terminalLocation.name);
          setStreet(results[0].terminalLocation.address && results[0].terminalLocation.address.street);
          setCity(results[0].terminalLocation.address && results[0].terminalLocation.address.city);
          setPinCode(results[0].terminalLocation.address && results[0].terminalLocation.address.postalCode);
          setState(results[0].terminalLocation.address && results[0].terminalLocation.address.state);
          const countryValue = results[0].terminalLocation.address
            && results[0].terminalLocation.address.country && results[0].terminalLocation.address.country.id;
          setCountryId(results[0].terminalLocation.address
            && results[0].terminalLocation.address.country && results[0].terminalLocation.address.country.id);
          if (countryValue === 2) {
            setCountry('INDIA');
            setZipTag('Pin code');
          } else if (countryValue === 1) {
            setCountry('USA');
            setZipTag('Zip code');
          } else {
            setCountry('CANADA');
            setZipTag('Zip code');
          }
        }
      } else {
        setMid('');
      }
    }
  };

  const onCountryCodeChange = (value) => {
    setCntryCode(value);
    const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const handleClick = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setFixErrors(true);
      return;
    }
    const params = {
      noOfTerminals: terminals,
      mid,
      emailRecipientList: addRecipient,
      address: {
        street,
        city,
        state,
        countryCode: getCountryAlpha2(countryId),
        postalCode: pinCode,
      },
      terminalAddress: {
        street,
        city,
        state,
        countryCode: getCountryAlpha2(countryId),
        postalCode: pinCode,
      },
    };

    if (phoneNumber) {
      params.contactNumber = `${cntryCode}${phoneNumber}`;
    }

    if (name) {
      params.name = name;
    }

    setDisableBtn(true);

    try {
      await axiosAuthInstance.post(API_END_POINTS.ADD_TERMINAL, params);
      props.setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.PAPER_ROLL.SUCCESS,
      });
      simpleValidator.current.hideMessages();
      setPhoneNumber(null);
      setName(null);
      setPhoneFormate();
      setStreet('');
      setCity('');
      setState('');
      setPinCode('');
      setMid();
      setTerminals(null);
      setAddRecipient([]);
    } catch (e) {
      props.setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.PAPER_ROLL.ERROR,
      });
    } finally {
      setFixErrors(false);
      setDisableBtn(false);
    }
  };

  return (
    <>
      <Row className="my-2">
        <Col>
          <SpText color="#7B7B7B" fontSize="18px">ADDITIONAL TERMINAL</SpText>
        </Col>
      </Row>
      <hr />
      {
        fixErrors && (
          <Row>
            <Col className="ml-3 mb-3">
              <SpError>
                Please fix one or more errors below.
              </SpError>
            </Col>
          </Row>
        )
      }
      <Row type="flex" justify="space-between" className="my-2">
        <Col span={18}>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>No. of Terminals</SpText>
              </Col>
              <Col span={18}>
                <Input
                  placeholder="01"
                  value={terminals}
                  onChange={(e) => setTerminals(e.currentTarget.value)}
                  maxlength="3"
                />
                <SpError>
                  {simpleValidator.current.message('No. of Terminals', terminals, 'required|numeric|terminals')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>Merchant No (MID)</SpText>
              </Col>
              <Col span={18}>
                <AutoComplete
                  placeholder="Select merchant No"
                  showSearch
                  className="w-100"
                  dataSource={terminalList.map((item) => (
                    <Option key={item.gid} value={item.gid}>
                      {item.terminalMid}
                    </Option>
                  ))}
                  onSearch={searchTerminalMid}
                  value={mid}
                  onSelect={e => updateLocation(e)}
                />
                <SpError>
                  {simpleValidator.current.message('Merchant no', mid, 'required|numeric')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>Contact person (Optional)</SpText>
              </Col>
              <Col span={18}>
                <Input
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>Contact No. &nbsp;(Optional)</SpText>
              </Col>
              <Col span={18}>
                <div className="d-flex">
                  <div
                    style={{ width: '20%' }}
                    onKeyPress={e => countryCodeValidation(e)}
                  >
                    <Select
                      className="w-100"
                      showSearch
                      defaultValue={cntryCode}
                      onChange={onCountryCodeChange}
                    >
                      {COUNTRY_PHONE_CODE.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div style={{ width: '80%' }}>
                    <Input
                      className="InputnumFormate"
                      placeholder="Phone Number"
                      value={phoneFormate}
                      onChange={onPhoneNumberChange}
                      onBlur={e => {
                        if (e.currentTarget.value) {
                          setPhoneNumber(e.currentTarget.value);
                          const value = formatNumber(`${cntryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                          const formater = value.substr(value.indexOf(' ') + 1);
                          setPhoneFormate(formater);
                        }
                      }}
                    />
                  </div>
                </div>
                <SpError>
                  {simpleValidator.current.message('phone number', phoneNumber, `phoneNumValidation:${cntryCode}${phoneNumber}`)}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>Street Address</SpText>
              </Col>
              <Col span={18}>
                <Input
                  placeholder="Street"
                  value={street}
                  onChange={(e) => setStreet(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('Street', street, 'required')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>City</SpText>
              </Col>
              <Col span={18}>
                <Input
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('City', city, 'required')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>State</SpText>
              </Col>
              <Col span={18}>
                <Select
                  value={state || 'Select State'}
                  className="w-100"
                  placeholder="State"
                  showSearch
                  optionFilterProp="children"
                  onChange={(e) => setState(e)}
                >
                  {
                    stateList.map(s => (
                      <Option
                        key={s.id}
                        value={s.id}
                        className="text-capitalize"
                      >
                        {s.name}
                      </Option>
                    ))
                  }
                </Select>
                <SpError>
                  {simpleValidator.current.message('State', state, 'required')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>Country</SpText>
              </Col>
              <Col span={18}>
                <Select
                  value={country}
                  style={{ width: '100%' }}
                  onChange={updateCountry}
                >
                  {COUNTRY_CODES.map((item) => (
                    <Option key={item.ID} value={item.ID}>
                      {item.NAME}
                    </Option>
                  ))}
                </Select>
                <SpError>
                  {simpleValidator.current.message('Country', country, 'required')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>{zipTag}</SpText>
              </Col>
              <Col span={18}>
                <Input
                  placeholder={zipTag}
                  value={pinCode}
                  onChange={(e) => setPinCode(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('Zip code', pinCode, 'required|zipcode')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          {
            ((addRecipient.length) === 0) ? (
              <>
                <SpForm>
                  <Row type="flex" justify="start" align="top">
                    <Col span={6}>
                      <SpText>Recipient List</SpText>
                    </Col>
                    <Col span={18} style={{ cursor: 'pointer' }}>
                      <SpText
                        fontWeight="600"
                        color="#279dfe"
                        onClick={addNewRecipient}
                      >
                        + Add New Recipient
                      </SpText>
                    </Col>
                  </Row>
                </SpForm>
              </>
            ) : (
              <>
                <SpForm>
                  {addRecipient.map((item, i) => (
                    <>
                      <Row type="flex" justify="start" align="top" className="mb-3">
                        <Col span={6}>
                          <SpText>{i === 0 ? 'Recipient List' : ' '}</SpText>
                        </Col>
                        <Col span={16}>
                          <Input
                            value={item}
                            key={`Email${i}`}
                            onChange={(e) => {
                              const list = [...addRecipient];
                              list[i] = e.currentTarget.value;
                              setAddRecipient(list);
                            }}
                            placeholder="Enter recipient name"
                          />
                          <SpError className="mb-4">
                            {simpleValidator.current.message('email', item, 'required|email|checkLowerCase',
                              { messages: { email: 'The email must be valid' } })}
                          </SpError>
                        </Col>
                        <Col className="ml-3" span={1}>
                          <span onClick={() => handleRemoveEmail(i)}>
                            <ButtonDelete />
                          </span>
                        </Col>
                      </Row>
                    </>
                  ))}
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="top">
                    <Col span={6}>
                      <SpText>&nbsp;</SpText>
                    </Col>
                    <Col span={18} style={{ cursor: 'pointer' }}>
                      <SpText
                        fontWeight="600"
                        color="#279dfe"
                        onClick={addNewRecipient}
                      >
                        + Add New Recipient
                      </SpText>
                    </Col>
                  </Row>
                </SpForm>
              </>
            )
          }
        </Col>
      </Row>
      <div className="service-btn">
        <SpButton
          type="primary"
          shape="round"
          onClick={handleClick}
          disabled={disableBtn}
          style={{
            width: '339px',
            height: '40px',
            fontSize: '16px',
          }}
        >
          Submit Request
        </SpButton>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(NewPaperRoll);
