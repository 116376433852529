// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import getPaymentCheckoutBackground from 'utils/getPaymentCheckoutBackground';
import getPaymentCheckoutStatus from 'utils/getPaymentCheckoutStatus';
import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
} from 'antd';

import {
  SpForm,
  SpText,
  SpButton,
  SpStatusTag,
  SpH5,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  paymentCheckoutDetailsActions,
  paymentCheckoutActions,
} from 'store/actions';
import {
  PAGES,
  ACQUIRER_TYPES,
} from 'appconstants';
import getUniquePaymentTypes from 'utils/getUniquePaymentTypes';
import CONSTANTS from 'store/constants';
import AddPaymentCheckout from './components/AddPaymentCheckout';

type Props = {
  fetchPaymentCheckoutDetails: Function,
  fetchPayments: Function,
  addPaymentCheckout: Function,
  paymentCheckoutDetails: Object,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  payments: Array<Object>,
  totalElements: number,
  paymentCheckoutDetailsStatus: string,
}

const PaymentCheckoutDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchPaymentCheckoutDetails,
    fetchPayments,
    addPaymentCheckout,
    paymentCheckoutDetails,
    loading,
    history,
    payments,
    totalElements,
    selectedAccount,
    submitting,
    validator,
    paymentCheckoutDetailsStatus,
  } = props;
  const { gid } = props.match.params;
  const [pagination, setPagination] = useState({});
  const [addPaymentCheckoutModal, setAddPaymentCheckoutModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [id, setId] = useState('');
  const [currencyType, setcurrencyType] = useState();
  const paymentMethods = getUniquePaymentTypes(selectedAccount && selectedAccount.supportedPaymentTypes);
  const supportedCurrency = (selectedAccount && selectedAccount.supportedCurrencies);
  const acquirerName = (selectedAccount && selectedAccount.acquirer && selectedAccount.acquirer.name);

  const getData = (query) => {
    fetchPaymentCheckoutDetails(gid);
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchPayments({ currentPage, sortParams, gid });
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.PAYMENTS}/${searchParams}`,
      state: location.search,
    });
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
    if (selectedAccount && selectedAccount.currency) {
      setId(selectedAccount.currency.id);
      setcurrencyType(selectedAccount.currency);
    }
  }, [location, selectedAccount]);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.PAYMENTCHECKOUT);
    } else if (!validator) {
      setAddPaymentCheckoutModal(false);
    }
  }, [validator]);

  const addNewPaymentCheckout = (paymentInfo: Object) => {
    const {
      amount,
      redirectUrl,
      sessionTime,
      paymentMethodData,
      currencyCode,
    } = paymentInfo;
    const currency = amount.replace(/[^\d]/g, '');
    setRedirect(true);
    addPaymentCheckout({
      amount: Number(currency),
      redirectUri: redirectUrl,
      sessionTimeout: sessionTime * 60,
      currencyCode,
      paymentMethodType: paymentMethodData,
    });
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.PAYMENTCHECKOUT}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const columns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net, record) => {
        const total = (net / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix}${formatNumber(total, (currencyType || selectedAccount.currency))}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '25%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '25%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? (record.paymentMethod.upi.vpa || 'UPI')
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.googlePayUS) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.googlePayUS.paymentMethodInfo && record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails)
                  ? (`**** ${record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails}`)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.instantPayment && record.paymentMethod.instantPayment.walletAlias) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.instantPayment.walletAlias.alias ? (record.paymentMethod.instantPayment.walletAlias.alias)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType) {
          const QrName = (record.paymentMethod.paymentType && record.paymentMethod.paymentType.name);
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(QrName === 'QR') ? QrName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'paymentDate',
      width: '25%',
      align: 'left',
      render: (paymentDate) => {
        if (paymentDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(paymentDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const terminalColumns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net, record) => {
        const total = (net / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix} ${formatNumber(total, (currencyType || selectedAccount.currency))}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? (record.paymentMethod.upi.vpa || 'UPI')
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.googlePayUS) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.googlePayUS.paymentMethodInfo && record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails)
                  ? (`**** ${record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails}`)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.instantPayment && record.paymentMethod.instantPayment.walletAlias) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.instantPayment.walletAlias.alias ? (record.paymentMethod.instantPayment.walletAlias.alias)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType) {
          const QrName = (record.paymentMethod.paymentType && record.paymentMethod.paymentType.name);
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(QrName === 'QR') ? QrName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'TERMINAL NAME',
      dataIndex: 'terminal',
      width: '20%',
      align: 'left',
      render: (terminal) => {
        if (terminal && terminal.deviceName) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {terminal && terminal.deviceName}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        paymentCheckoutDetails.gid && paymentCheckoutDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    {paymentCheckoutDetails.amount < 0
                      ? `(${paymentCheckoutDetails.currency.prefix} 
                      ${formatNumber(
                        // eslint-disable-next-line max-len
                        (paymentCheckoutDetails.amount / 100).toFixed(paymentCheckoutDetails.currency.toFixed).replace(/-/g, ''), (currencyType || selectedAccount.currency),
                      )})`
                      : paymentCheckoutDetails.amount
                        ? `${paymentCheckoutDetails.currency.prefix}        
                      ${formatNumber(
                          // eslint-disable-next-line max-len
                          (paymentCheckoutDetails.amount / 100).toFixed(paymentCheckoutDetails.currency.toFixed), (currencyType || selectedAccount.currency),
                        )}`
                        : <>&#8211;</>}
                  </SpText>
                </Col>
                <Col>
                  <SpButton
                    type="secondary"
                    className="mr-4"
                    shape="round"
                    ghost
                    onClick={() => setAddPaymentCheckoutModal(true)}
                  >
                    Duplicate Payment Checkout
                  </SpButton>
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Status</span>
                  </Col>
                  <Col span={6}>
                    {paymentCheckoutDetails.status
                      ? (
                        <SpStatusTag style={{ backgroundColor: getPaymentCheckoutBackground(paymentCheckoutDetails.status) }}>
                          {getPaymentCheckoutStatus(paymentCheckoutDetails.status)}
                        </SpStatusTag>
                      )
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Expires At</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {
                        paymentCheckoutDetails.expiresAt && selectedAccount && selectedAccount.timezone
                          ? (
                            moment
                              .utc(paymentCheckoutDetails.expiresAt)
                              .tz(selectedAccount.timezone)
                              .format('MMM DD, YYYY')
                          )
                          : <>&#8211;</>
                      }
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Redirect Url</span>
                  </Col>
                  <Col span={6}>
                    {paymentCheckoutDetails.redirectUri
                      ? <SpH5>{paymentCheckoutDetails.redirectUri}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Server Callback Url</span>
                  </Col>
                  <Col span={6}>
                    {paymentCheckoutDetails.serverCallbackUrl
                      ? <SpH5>{paymentCheckoutDetails.serverCallbackUrl}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              {
                paymentCheckoutDetails.serverCallbackUrl && (
                  <>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={3}>
                          <span>Server Callback Status</span>
                        </Col>
                        <Col span={6}>
                          {paymentCheckoutDetails.serverCallbackStatus
                            ? <SpH5>{paymentCheckoutDetails.serverCallbackStatus}</SpH5>
                            : <>&#8211;</>}
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                )
              }
              {/* ADD PAYMENTCHECKOUT MODAL */}
              {addPaymentCheckoutModal && (
                <AddPaymentCheckout
                  visible={addPaymentCheckoutModal}
                  submitting={submitting}
                  countryId={id}
                  selectedAccount={selectedAccount}
                  supportedCurrency={supportedCurrency}
                  paymentMethods={paymentMethods}
                  paymentCheckoutDetails={paymentCheckoutDetails}
                  close={() => setAddPaymentCheckoutModal(false)}
                  submit={addNewPaymentCheckout}
                />
              )}
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">Payment Sessions</SpText>
                </Col>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={
                  (acquirerName && acquirerName === ACQUIRER_TYPES.IMSPOS) ? terminalColumns : columns
                }
                rowKey={(record) => record.gid}
                dataSource={payments}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  paymentCheckoutDetails: state.paymentCheckoutDetails,
  paymentCheckoutDetailsStatus: state.paymentCheckoutDetails.paymentCheckoutDetailsStatus,
  payments: state.paymentCheckoutDetails.payments.content,
  totalElements: state.paymentCheckoutDetails.payments ? state.paymentCheckoutDetails.payments.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentCheckoutDetails: gid => dispatch(paymentCheckoutDetailsActions.fetchPaymentCheckoutDetails({
    payload: gid,
  })),
  fetchPayments: param => dispatch(paymentCheckoutDetailsActions.fetchPayments({
    payload: param,
  })),
  addPaymentCheckout: param => dispatch(paymentCheckoutActions.addPaymentCheckout({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PaymentCheckoutDetails);
