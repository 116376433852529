import { CUSTOM_REPORT } from '../actionTypes';

const initialState = {
  customReportStatus: undefined,
  customReportError: undefined,
  content: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CUSTOM_REPORT.FETCH:
      return {
        ...state,
        customReportStatus: 'STARTED',
      };
    case CUSTOM_REPORT.FAILED:
      return {
        ...state,
        customReportStatus: 'FAILED',
      };
    case CUSTOM_REPORT.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
