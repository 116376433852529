import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  INVENTORY_MODIFIERS_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryModifierDetails = async (gid) => axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_MODIFIER}/${gid}`);

const postInventoryyModifier = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.ADD_INVENTORY_MODIFIER, params);

const deleteSelectedInventoryModifier = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.ADD_INVENTORY_MODIFIER}/${gid}`);

const patchInventoryModifier = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.ADD_INVENTORY_MODIFIER}/${gid}`, params);

export function* getInventoryModifierDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryModifierDetails, action.payload);
      yield put({ type: INVENTORY_MODIFIERS_DETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchInventoryModifierDetails, action);
      yield put({ type: INVENTORY_MODIFIERS_DETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: INVENTORY_MODIFIERS_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addInventoryModifier(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postInventoryyModifier, action.payload);
    // eslint-disable-next-line max-len
    const { data: { entity } } = yield call(fetchInventoryModifierDetails, action.payload && action.payload.params && action.payload.params.modifierGroupGid);
    yield put({ type: INVENTORY_MODIFIERS_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.ADD_MODIFIER,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteInventoryModifier(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteSelectedInventoryModifier, action.payload);
    const { data: { entity } } = yield call(fetchInventoryModifierDetails, action.payload && action.payload.mGid);
    yield put({ type: INVENTORY_MODIFIERS_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.DELETE_MODIFIER,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* updateInventoryModifier(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventoryModifier, action.payload);
    const { data: { entity } } = yield call(fetchInventoryModifierDetails, action.payload && action.payload.mGid);
    yield put({ type: INVENTORY_MODIFIERS_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Modifier updated successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
