/* eslint-disable */
import React from 'react';

const uploadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <g>
                            <path fill="#1890FF" d="M39 3H9c-.83 0-1.5.67-1.5 1.5v39c0 .83.67 1.5 1.5 1.5h30c.83 0 1.5-.67 1.5-1.5v-39c0-.83-.67-1.5-1.5-1.5zm-1.875 38.625h-26.25v-9.422h4.59c.543 1.538 1.5 2.92 2.77 3.97C19.851 37.51 21.9 38.25 24 38.25c2.1 0 4.148-.736 5.766-2.077 1.27-1.05 2.226-2.432 2.77-3.97h4.589V29.25h-6.956l-.244 1.158C29.362 33.21 26.869 35.25 24 35.25c-2.869 0-5.363-2.04-5.93-4.842l-.243-1.158h-6.952V6.375h26.25v35.25zM15 15.985h18c.206 0 .375-.17.375-.376v-2.25c0-.206-.169-.375-.375-.375H15c-.206 0-.375.17-.375.375v2.25c0 .207.169.375.375.375zm0 7.5h18c.206 0 .375-.17.375-.376v-2.25c0-.206-.169-.375-.375-.375H15c-.206 0-.375.17-.375.375v2.25c0 .207.169.375.375.375z" transform="translate(-686 -636) translate(300 174) translate(182 399) translate(204 63)" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

);

export default uploadIcon;