// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Table,
  Icon,
  Tooltip,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';

import {
  PAGES,
} from 'appconstants';

import { poskeyActions } from 'store/actions';
import moment from 'moment-timezone';

import EditPosKey from './components/EditPosKey';

type Props = {
  fetchPoskey: Function,
  updatePoskey: Function,
  poskey: Array<Object>,
  loading: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  submitting: boolean,
  validator: boolean,
};

const PosKeys = (props: Props) => {
  const {
    loading,
    fetchPoskey,
    updatePoskey,
    poskey,
    totalElements,
    history,
    selectedAccount,
    submitting,
    validator,
  } = props;

  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const [selectedPosKey, setSelectedPosKey] = useState({});
  const [editPosKeyModal, setEditPosKeyModal] = useState(false);


  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchPoskey({ currentPage, sortParams });
  };

  useEffect(() => {
    getData(location.search);
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [poskey]);

  useEffect(() => {
    if (!validator) {
      setEditPosKeyModal(false);
    }
  }, [validator]);


  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.POSKEY,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.POSKEY,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const editSelectedPosKey = (posKey: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      gid,
      apiKey,
    } = posKey;

    const params = {
      apiKey,
    };

    updatePoskey({
      currentPage,
      sortParams,
      gid,
      params,
    });
  };

  const showEditPosKeyModal = (value: Object) => {
    setSelectedPosKey(value);
    setEditPosKeyModal(true);
  };

  const columns = [
    {
      title: 'GID',
      dataIndex: 'gid',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (gid) => (
        <span>
          {gid || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'VALUE',
      dataIndex: 'apiKey',
      width: '25%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (apiKey) => (
        <span>
          {apiKey || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'updatedAt',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (updatedAt) => {
        if (updatedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(updatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      width: '15%',
      align: 'center',
      render: (text, record) => (
        <span>
          <Tooltip placement="top" title="Edit">
            <Icon
              type="edit"
              className="ml-3"
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => showEditPosKeyModal(record)}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      {/* UPDATE POSKEY MODAL */}
      { editPosKeyModal && (
        <EditPosKey
          visible={editPosKeyModal}
          submitting={submitting}
          selectedPosKey={selectedPosKey}
          selectedAccount={selectedAccount}
          close={() => setEditPosKeyModal(false)}
          submit={editSelectedPosKey}
        />
      )}

      <Table
        rowClassName={() => 'ant-table-clickable-row'}
        columns={columns}
        rowKey={(record) => record.gid}
        dataSource={poskey}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  poskey: state.poskey.content,
  loading: state.loading.loading,
  test: state.account.test,
  totalElements: state.poskey.totalElements,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPoskey: param => dispatch(poskeyActions.fetchPoskey({
    payload: param,
  })),
  updatePoskey: param => dispatch(poskeyActions.updatePoskey({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PosKeys);
