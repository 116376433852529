import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance2,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  SUBSCRIPTIONV2,
  NOTIFICATION,
  LOADING,
} from '../actionTypes';

const fetchSubscriptionV2 = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance2.get(`${API_END_POINTS.SUBSCRIPTION}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance2.get(`${API_END_POINTS.SUBSCRIPTION}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance2.get(`${API_END_POINTS.SUBSCRIPTION}${query}${sorting}`);
  }
  return axiosAuthInstance2.get(`${API_END_POINTS.SUBSCRIPTION}${query}${sorting}`);
};

const fetchPlanV2Search = async (payload) => {
  const queryParam = `?page=1&size=100&name.LIKE=${payload}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=ASC';
  const planFilters = '&isInternal.EQ=false';
  return axiosAuthInstance2.get(`${API_END_POINTS.PLAN}${queryParam}${sorting}${planFilters}`);
};

const postSubscriptionV2 = async payload => axiosAuthInstance2.post(API_END_POINTS.SUBSCRIPTION, payload);

export function* getSubscriptionV2(action) {
  try {
    const { data: { entity } } = yield call(fetchSubscriptionV2, action.payload);
    yield put({ type: SUBSCRIPTIONV2.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SUBSCRIPTIONV2.FAILED, payload: e.response.data });
  }
}

export function* getPlanV2Search(action) {
  try {
    const { data: { entity } } = yield call(fetchPlanV2Search, action.payload);
    yield put({ type: SUBSCRIPTIONV2.SEARCH_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SUBSCRIPTIONV2.SEARCH_FAILED, payload: e.response.data });
  }
}

export function* addSubscriptionV2(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postSubscriptionV2, action.payload);
    const { data: { entity } } = yield call(fetchSubscriptionV2, action.payload);
    yield put({ type: SUBSCRIPTIONV2.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUBSCRIPTION.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
