import { INVENTORY_ITEMS } from '../actionTypes';

const initialState = {
  content: [],
  itemsList: [],
  modifierItemsList: [],
  sortItemsList: [],
  searchTypes: '',
  addedItem: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_ITEMS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case INVENTORY_ITEMS.LIST_SUCCESS:
      return {
        ...state,
        itemsList: action.payload,
      };
    case INVENTORY_ITEMS.MODIFIER_LIST_SUCCESS:
      return {
        ...state,
        modifierItemsList: action.payload,
      };
    case INVENTORY_ITEMS.ORDER_LIST_SUCCESS:
      return {
        ...state,
        sortItemsList: action.payload,
      };
    case INVENTORY_ITEMS.ORDER_LIST_SEARCH:
      return {
        ...state,
        searchTypes: action.payload,
      };
    case INVENTORY_ITEMS.ITEM_ADD_SUCCESS:
      return {
        ...state,
        addedItem: action.payload,
      };
    default:
      return state;
  }
};
