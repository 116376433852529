import React, { useState, useRef, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
} from 'antd';
import {
  SpForm,
  SpError,
  SpButton,
  SpText,
  SpCaption,
} from 'components/DesignKit';
import { validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import SimpleReactValidator from 'simple-react-validator';

import {
  COUNTRY_PHONE_CODE,
} from 'appconstants';

const { Option } = Select;

type Props = {
  support: Object,
  updateSupportSetting: Function,
  setEditSettings: Function,
};

const Edit = (props: Props) => {
  const {
    setEditSettings,
    updateSupportSetting,
  } = props;
  const {
    email,
    phone,
    website,
  } = props.support;

  const [, forceUpdate] = useState();
  const [contactEmail, setContactEmail] = useState(email);
  const [accountPhone, setAccountPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [supportWebsite, setSupportWebsite] = useState(website);
  const [phoneFormate, setPhoneFormate] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setAccountPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  useEffect(() => {
    const phoneResult = phone ? findPhoneNumbersInText(phone) : null;
    if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
      setCountryCode(`+${phoneResult[0].number.countryCallingCode}`);
      setAccountPhone(phoneResult[0].number.nationalNumber);
      const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
      const formater = data.substr(data.indexOf(' ') + 1);
      setPhoneFormate(formater);
    }
  }, [phone]);

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${accountPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const updateSupportSettings = () => {
    setAccountPhone(accountPhone.replace(/[^0-9+]/g, ''));
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    updateSupportSetting({
      email: contactEmail,
      phone: `${countryCode}${accountPhone}`,
      website: supportWebsite,
    });
  };

  return (
    <>
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Manage Support Settings
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Support Email</span>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={contactEmail}
              onChange={e => setContactEmail(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Email', contactEmail, 'required|email|checkLowerCase')}
            </SpError>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Phone Number</span>
          </Col>
          <Col span={6}>
            <div className="d-flex">
              <Select
                value={countryCode}
                style={{ width: '30%' }}
                onChange={onCountryCodeChange}
                showSearch
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <div style={{ width: '70%' }}>
                <Input
                  className="InputnumFormate"
                  placeholder="Phone Number"
                  value={phoneFormate}
                  onChange={onPhoneNumberChange}
                  onBlur={e => {
                    if (e.currentTarget.value) {
                      setAccountPhone(e.currentTarget.value);
                      const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                      const formater = value.substr(value.indexOf(' ') + 1);
                      setPhoneFormate(formater);
                    }
                  }}
                />
                <SpError>
                  {simpleValidator.current.message('phone number', accountPhone, `required|phoneNumValidation:${countryCode}${accountPhone}`)}
                </SpError>
              </div>
            </div>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Support Website</span>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={supportWebsite}
              onChange={e => setSupportWebsite(e.currentTarget.value)}
            />
          </Col>
          <Col>
            <SpCaption className="pl-4">(Optional)</SpCaption>
          </Col>
        </Row>
      </SpForm>
      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="secondary"
            shape="round"
            className="mr-4"
            onClick={() => setEditSettings(false)}
          >
            Cancel
          </SpButton>
          <SpButton
            type="primary"
            shape="round"
            onClick={updateSupportSettings}
          >
            Save
          </SpButton>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Edit);
