import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  OPERATINGHOURS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchHoursOfOperations = async (payload) => {
  const query = generateQuery(payload.currentPage);
  // const sorting = payload.sortParams ? payload.sortParams : "";
  const sorting = 'sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS_OPERATING_HOURS}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS_OPERATING_HOURS}${query}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS_OPERATING_HOURS}${query}&${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS_OPERATING_HOURS}${query}&${sorting}`);
};

const deleteHours = async (payload) => axiosAuthInstance.delete(`${API_END_POINTS.GET_MENUS_OPERATING_HOURS}/${payload.gid}`);

const addHours = async (params) => axiosAuthInstance.post(`${API_END_POINTS.GET_MENUS_OPERATING_HOURS}`, params);

const patchHours = async (payload) => axiosAuthInstance.patch(`${API_END_POINTS.GET_MENUS_OPERATING_HOURS}/${payload.gid}`, payload.params);

export function* getHoursOfOPeratins(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchHoursOfOperations, action.payload);
    yield put({ type: OPERATINGHOURS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: OPERATINGHOURS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addHoursOfOperations(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(addHours, action.payload.params);
    const { data: { entity } } = yield call(fetchHoursOfOperations, action.payload);
    yield put({ type: OPERATINGHOURS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Added new Hours successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateHoursOfOperations(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchHours, action.payload);
    const { data: { entity } } = yield call(fetchHoursOfOperations, action.payload);
    yield put({ type: OPERATINGHOURS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Selected Hours are updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}


export function* deleteHoursOfOperations(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteHours, action.payload);
    const { data: { entity } } = yield call(fetchHoursOfOperations, action.payload);
    yield put({ type: OPERATINGHOURS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Selected Hours deleted successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
