// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Row,
  Col,
  Upload,
  Icon,
  message,
  DatePicker,
} from 'antd';
import moment from 'moment-timezone';
import {
  MESSAGES,
} from 'appconstants';
import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';
import {
  notificationActions,
} from 'store/actions';
import {
  NOTIFICATION,
} from 'store/actionTypes';
import SimpleReactValidator from 'simple-react-validator';
import CsvIcon from 'components/NavIcons/csvIcon';
import {
  SpH5,
  SpError,
  SpButton,
  SpTextMute,
} from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  otpRequires: boolean,
  lastFour: string,
  phoneCode: string,
  selectedAccount: Object,
  transferSettings: Object,
  setNotification: Function,
  supportsSchedule: boolean,
};

const UploadBulk = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    lastFour,
    phoneCode,
    selectedAccount,
    otpRequires,
    transferSettings,
    setNotification,
    supportsSchedule,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [bulkTransferFile, setBulkTransferFile] = useState('');
  const [preview, setPreview] = useState();
  const [fileName, setFileName] = useState();
  const [loader, setLoader] = useState(false);
  const [dop, setDop] = useState(null);
  const [transferApiDate, setTransferApiDate] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [UniqueId, setUniqueId] = useState('');
  const [otp, setOtp] = useState('');
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const currentDate = moment().format(MOMENT_FORMAT);
  const disableDate = moment().tz(currentDate, selectedAccount.timezone).endOf('day');

  const simpleValidator = useRef(new SimpleReactValidator());


  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <SpButton type="secondary">Upload</SpButton>}
    </div>
  );

  const onDateChange = (date, dateString) => {
    setDop(dateString);
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    setTransferApiDate(utcDate);
  };

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 2;
    const fileType = (file.name).substring((file.name).length - 4);
    const CSV = '.csv';
    const textPlain = 'text/plain';
    const textCSV = 'text/csv';
    let pattern = false;
    if ((fileType === CSV) && (file.type.match(textPlain) || file.type.match(textCSV))) {
      pattern = true;
    } else if (fileType === CSV) {
      pattern = true;
    }
    if (!pattern) {
      message.error('File format is not csv');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 2MB!');
      return false;
    } return true;
  };

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = async (info) => {
    const { file } = info;
    try {
      setLoader(true);
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(API_END_POINTS.UPLOAD_BULKTRANSFER);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const bulkFileDetails = {
          fileUrl: entity,
          keyType: 'BULK_TRANSFER',
        };
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(API_END_POINTS.UPLOAD_BULKTRANSFER, bulkFileDetails);
        setBulkTransferFile(fileUrl);
        setFileName(file.name);
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: MESSAGES.FILE_UPLOAD.SUCCESS,
        });
        if (fileUrl && otpRequires) {
          try {
            const { data: { entity: { uniqueId } } } = await axiosAuthInstance.post(API_END_POINTS.BULKTRANSFER_OTP);
            setUniqueId(uniqueId);
          } catch (e) {
            setNotification({
              type: NOTIFICATION.ERROR,
              payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
            });
          }
        } else if (fileUrl && (transferSettings && transferSettings.approvalEmail)) {
          try {
            const { data: { entity: { uniqueId } } } = await axiosAuthInstance.post(API_END_POINTS.BULKTRANSFER_OTP);
            setUniqueId(uniqueId);
          } catch (e) {
            setNotification({
              type: NOTIFICATION.ERROR,
              payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
            });
          }
        }
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleChange = ({ file }) => {
    if (file.status === 'uploading') {
      setLoader(true);
    }
    const fileType = (file.name).substring((file.name).length - 4);
    const CSV = '.csv';
    const textPlain = 'text/plain';
    const textCSV = 'text/csv';
    let pattern = false;
    if ((fileType === CSV) && (file.type.match(textPlain) || file.type.match(textCSV))) {
      pattern = true;
    } else if (fileType === CSV) {
      pattern = true;
    }
    if (pattern) {
      setPreview(<CsvIcon />);
    }
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      bulkTransferFile,
      UniqueId,
      otp,
      transferApiDate,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={!bulkTransferFile ? true : disableButton}>Done</SpButton>,
      ]}
      title="Upload Bulk Transfer"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-4">
          <label htmlFor=""><SpH5>File Name</SpH5></label>
          <Input
            placeholder="Sample Name 1.csv"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('file name', name, 'required')}
          </SpError>
        </div>
        <div>
          <Row type="flex" justify="start" align="middle">
            <Col span={6} className="mb-4">
              <SpH5>Upload .csv file</SpH5>
            </Col>
            <Col className="ml-4">
              <div className="w-100 mb-2">
                <Upload
                  name="avatar"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={fileUpload}
                  onChange={handleChange}
                >
                  {fileName ? preview : uploadButton}
                </Upload>
                <SpError>
                  {simpleValidator.current.message('upload file', bulkTransferFile, 'required')}
                </SpError>
              </div>
              <SpTextMute>
                File size should be within 2 MB
              </SpTextMute>
            </Col>
          </Row>
        </div>
        {
          otpRequires ? (
            <>
              <div className="mb-4 mt-4">
                <SpH5>
                  We have sent an OTP to {phoneCode} ******{lastFour}
                </SpH5>
              </div>
              <div className={supportsSchedule ? 'mb-4' : ''}>
                <label htmlFor=""><SpH5>Enter OTP to verify</SpH5></label>
                <Input
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('otp', otp, 'required|numeric')}
                </SpError>
              </div>
            </>
          ) : (transferSettings && transferSettings.approvalEmail) ? (
            <>
              <div className="mb-4 mt-4">
                <SpH5>
                  We have sent an OTP to {transferSettings.approvalEmail}
                </SpH5>
              </div>
              <div className={supportsSchedule ? 'mb-4' : ''}>
                <label htmlFor=""><SpH5>Enter OTP to verify</SpH5></label>
                <Input
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('otp', otp, 'required|numeric')}
                </SpError>
              </div>
            </>
          ) : ''
        }
        {
          supportsSchedule && (
            <div>
              <label htmlFor=""><SpH5>Transfer Date</SpH5></label>
              <DatePicker
                value={dop ? moment(dop) : ''}
                format={dateFormat}
                allowClear={false}
                onChange={onDateChange}
                disabledDate={current => current && current < disableDate}
              />
              <SpError>
                {simpleValidator.current.message('date', dop, 'required')}
              </SpError>
            </div>
          )
        }
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(UploadBulk);
