import { REWARD_SETTINGS } from '../actionTypes';

export const fetchRewardSettings = (action) => ({
  type: REWARD_SETTINGS.FETCH,
  payload: action.payload,
});

export const addRewardSettings = (action) => ({
  type: REWARD_SETTINGS.ADD,
  payload: action.payload,
});

export const updateRewardSettings = (action) => ({
  type: REWARD_SETTINGS.UPDATE,
  payload: action.payload,
});
