export default status => {
  if (status === 'CREATED') {
    return '#e2e2e2';
  } if (status === 'OPEN') {
    return '#00c289a1';
  } if (status === 'IN_PROGRESS') {
    return '#f28c26bf';
  } if (status === 'CLOSED') {
    return '#ececff';
  } if (status === 'CANCELLED') {
    return '#ececff';
  } return '#e2e2e2';
};
