import { TABLE_DETAILS } from '../actionTypes';

const initialState = {
  content: [],
  staffData: [],
  guestData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TABLE_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case TABLE_DETAILS.SUCCESS_STAFF:
      return {
        ...state,
        staffData: action.payload,
      };
    case TABLE_DETAILS.SUCCESS_GUEST:
      return {
        ...state,
        guestData: action.payload,
      };
    default:
      return state;
  }
};
