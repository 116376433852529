// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';

import type { ContextRouter } from 'react-router-dom';

import { notificationActions } from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';

type Props = ContextRouter & {
  success: boolean,
  error: boolean,
  resetNotification: Function,
};

const KEY = 'updatable';

const openNotification = ({ success, error, onClose }) => {
  notification.open({
    KEY,
    message: success ? 'Success' : 'Error',
    description: success || error,
    duration: success ? 3 : 5,
    className: `${success ? 'bg-success' : 'bg-danger'} text-white`,
    onClose: () => onClose(),
  });
};

const Notification = (props: Props) => {
  const { success, error, resetNotification } = props;

  useEffect(() => {
    openNotification({
      success,
      error,
      onClose: resetNotification,
    });
  }, [success, error]);

  return <div />;
};

const mapStateToProps = (state) => ({
  success: state.notification.success,
  error: state.notification.error,
});

const mapDispatchToProps = (dispatch) => ({
  resetNotification: () => dispatch(notificationActions.resetNotification({
    type: NOTIFICATION.RESET,
  })),
});

export default connect<*, *, *, *, *, *>(
  mapStateToProps,
  mapDispatchToProps,
)(Notification);
