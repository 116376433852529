import { ACQUIRER } from '../actionTypes';

const initialState = {
  content: [],
  acquirerSearch: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACQUIRER.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case ACQUIRER.SEARCH_SUCCESS:
      return {
        ...state,
        acquirerSearch: action.payload,
      };
    default:
      return state;
  }
};
