// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
};

const AddAccountSlug = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  // const nameRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
  const nameRegex = /^[a-zA-Z0-9-]{6,}$/;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      slugname: {
        message: 'Slug name should have a minimum of 6 characters.',
        rule: val => (nameRegex.test(val)),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      slug,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Create Account Slug"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Alias</SpH5></label>
          <Input
            placeholder="Please enter Alias"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />

          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Slug Name</SpH5></label>
          <Input
            placeholder="Slug name"
            value={slug}
            onChange={(e) => setSlug(e.currentTarget.value)}
          />

          <SpError>
            {simpleValidator.current.message('slug', slug, 'required|slugname')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddAccountSlug);
