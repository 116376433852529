import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance2,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  SUBSCRIPTION_BUTTONV2,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchSubscriptionButtonV2 = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance2.get(`${API_END_POINTS.SUBSCRIPTION_BUTTON}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance2.get(`${API_END_POINTS.SUBSCRIPTION_BUTTON}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance2.get(`${API_END_POINTS.SUBSCRIPTION_BUTTON}${query}${sorting}`);
  }
  return axiosAuthInstance2.get(`${API_END_POINTS.SUBSCRIPTION_BUTTON}${query}${sorting}`);
};

const postSubscriptionButtonV2 = async payload => axiosAuthInstance2.post(API_END_POINTS.SUBSCRIPTION_BUTTON, payload);

export function* getSubscriptionButtonV2(action) {
  try {
    const { data: { entity } } = yield call(fetchSubscriptionButtonV2, action.payload);
    yield put({ type: SUBSCRIPTION_BUTTONV2.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SUBSCRIPTION_BUTTONV2.FAILED, payload: e.response.data });
  }
}

export function* addSubscriptionButtonV2(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postSubscriptionButtonV2, action.payload);
    const { data: { entity } } = yield call(fetchSubscriptionButtonV2, action.payload);
    yield put({ type: SUBSCRIPTION_BUTTONV2.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUBSCRIPTION_BUTTON.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
