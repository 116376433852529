// @flow
import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Tooltip,
  Row,
  Col,
  Icon,
} from 'antd';
import {
  SpButton,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';

import {
  ROLES,
} from 'appconstants';
import {
  customdashboardActions,
  notificationActions,
} from 'store/actions';
import RBAC from 'components/rbac';
import AddDashboard from './components/addReferral';
import UpdateDashboard from './components/updateDashboard';

type Props = {
  fetchCustomDashboard: Function,
  customdashboard: Array<Object>,
  loading: boolean,
  test: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  addCustomDashboard: Function,
  validator: boolean,
  updateCustomDashboard: Function,
};

const CustomDashboardSettings = (props: Props) => {
  const [pagination, setPagination] = useState({});
  const location = useLocation();
  const {
    loading,
    fetchCustomDashboard,
    totalElements,
    customdashboard,
    test,
    history,
    selectedAccount,
    addCustomDashboard,
    validator,
    updateCustomDashboard,
  } = props;
  const [addDashboardModal, setAddDashboardModal] = useState(false);
  const [updateDashboardModal, setUpdateDashboardModal] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState({});
  const { internalRole } = useSelector(state => state.user);

  const debouncedFetchCustomDashboard = debounce(fetchCustomDashboard, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    debouncedFetchCustomDashboard({ currentPage, sortParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [test, selectedAccount, location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [customdashboard]);

  useEffect(() => {
    if (!validator) {
      setAddDashboardModal(false);
      setUpdateDashboardModal(false);
    }
  }, [validator]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: '/settings/custom-dashboard',
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const addNewDashboard = (referral: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const {
      name,
      dashboardId,
    } = referral;
    const params = {
      dashboardName: name,
      dashboardId,
    };
    addCustomDashboard({
      params,
      currentPage,
      sortParams,
    });
  };

  const showEditDashboard = (value: Object) => {
    setSelectedDashboard(value);
    setUpdateDashboardModal(true);
  };

  const updateSelectedCustomDashboard = (record: Object) => {
    const {
      gid,
      name,
      dashboardId,
    } = record;
    const params = {
      dashboardName: name,
      dashboardId,
    };
    updateCustomDashboard({
      params,
      gid,
      name,
    });
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'dashboardName',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (dashboardName) => (
        <span>
          {dashboardName || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'dashboardId',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (dashboardId) => (
        <span>
          {dashboardId || <>&#8211;</>}
        </span>
      ),
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '12%',
    align: 'center',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showEditDashboard(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <Row className="my-2">
          <div className="d-flex justify-content-end">
            <RBAC
              permit={
                [ROLES.SUPERUSER, ROLES.INTERNALSUPPORT]
              }
            >
              <Col>
                <SpButton
                  type="secondary"
                  shape="round"
                  className="mr-3"
                  ghost
                  onClick={() => setAddDashboardModal(true)}
                >
                  {'\u002B'}&nbsp;Add Custom Dashboard
                </SpButton>
              </Col>
            </RBAC>
          </div>
        </Row>

        {/* ADD DASHBOARD MODAL */}
        {addDashboardModal && (
          <AddDashboard
            visible={addDashboardModal}
            close={() => setAddDashboardModal(false)}
            submit={addNewDashboard}
          />
        )}

        {/* UPDATE DASHBOARD MODAL */}
        {updateDashboardModal && (
          <UpdateDashboard
            visible={updateDashboardModal}
            selectedDashboard={selectedDashboard}
            close={() => setUpdateDashboardModal(false)}
            submit={updateSelectedCustomDashboard}
          />
        )}

        <Table
          columns={internalRole ? [...columns, actionColumn] : columns}
          rowKey={(record) => record.gid}
          dataSource={customdashboard}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Card>
    </>

  );
};

const mapStateToProps = (state) => ({
  customdashboard: state.customdashboard.content,
  loading: state.loading.loading,
  totalElements: state.customdashboard.totalElements,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomDashboard: param => dispatch(customdashboardActions.fetchCustomDashboard({
    payload: param,
  })),
  addCustomDashboard: param => dispatch(customdashboardActions.addCustomDashboard({
    payload: param,
  })),
  updateCustomDashboard: param => dispatch(customdashboardActions.updateCustomDashboard({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CustomDashboardSettings);
