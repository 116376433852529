import React, { useState, useRef } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
} from 'antd';
import {
  SpForm,
  SpError,
  SpButton,
  SpText,
  SpCaption,
} from 'components/DesignKit';
import { validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';

import SimpleReactValidator from 'simple-react-validator';
import {
  COUNTRY_PHONE_CODE,
} from 'appconstants';

const { Option } = Select;

type Props = {
  addSupportSetting: Function,
  phoneCode: string,
};

const Add = (props: Props) => {
  const {
    phoneCode,
    addSupportSetting,
  } = props;
  const [, forceUpdate] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [accountPhone, setAccountPhone] = useState();
  const [supportWebsite, setSupportWebsite] = useState();
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [phoneFormate, setPhoneFormate] = useState('');

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${accountPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setAccountPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const addSupportSettings = () => {
    if (accountPhone) {
      setAccountPhone(accountPhone.replace(/[^0-9+]/g, ''));
    }
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    addSupportSetting({
      contactEmail,
      accountPhone,
      supportWebsite,
      countryCode,
    });
  };

  return (
    <>
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Manage Support Settings
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Support Email</span>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={contactEmail}
              onChange={e => setContactEmail(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Email', contactEmail, 'required|email|checkLowerCase')}
            </SpError>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Phone Number</span>
          </Col>
          <Col span={6}>
            <div className="d-flex">
              <Select
                defaultValue={phoneCode}
                style={{ width: '30%' }}
                onChange={onCountryCodeChange}
                showSearch
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <div style={{ width: '70%' }}>
                <Input
                  className="InputnumFormate"
                  placeholder="Phone Number"
                  value={phoneFormate}
                  onChange={onPhoneNumberChange}
                  onBlur={e => {
                    if (e.currentTarget.value) {
                      setAccountPhone(e.currentTarget.value);
                      const value = formatNumber(`${phoneCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                      const formater = value.substr(value.indexOf(' ') + 1);
                      setPhoneFormate(formater);
                    }
                  }}
                />
                <SpError>
                  {simpleValidator.current.message('phone number', accountPhone, `required|phoneNumValidation:${phoneCode}${accountPhone}`)}
                </SpError>
              </div>
            </div>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Support Website</span>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={supportWebsite}
              onChange={e => setSupportWebsite(e.currentTarget.value)}
            />
          </Col>
          <Col>
            <SpCaption className="pl-4">(Optional)</SpCaption>
          </Col>
        </Row>
      </SpForm>
      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={addSupportSettings}
          >
            Save
          </SpButton>
        </Col>
      </Row>
    </>
  );
};

export default Add;
