export default status => {
  if (status === 'CANCELLED') {
    return '#e2e2e2';
  } if (status === 'SUCCEEDED') {
    return '#ececff';
  } if (status === 'REQUIRES_CONFIRMATION') {
    return '#e2e2e2';
  } if (status === 'REQUIRE_FUNDING_SOURCE') {
    return '#e2e2e2';
  } if (status === 'FAILED') {
    return '#ffe0b1';
  } if (status === 'TRANSFER_SCHEDULED') {
    return '#f6d87e';
  } if (status === 'PROCESSING') {
    return '#FFEDCC';
  } return '#e2e2e2';
};
