import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosNoAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  ACCOUNT_SESSION,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchAccountSession = async (payload) => {
  const customerId = payload?.custGid;
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosNoAuthInstance.get(`${API_END_POINTS.ACCOUNT_SESSION}/${customerId}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosNoAuthInstance.get(`${API_END_POINTS.ACCOUNT_SESSION}/${customerId}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosNoAuthInstance.get(`${API_END_POINTS.ACCOUNT_SESSION}/${customerId}${query}${sorting}`);
  }
  return axiosNoAuthInstance.get(`${API_END_POINTS.ACCOUNT_SESSION}/${customerId}${query}${sorting}`);
};

export function* getAccountSession(action) {
  yield put({ type: LOADING.SET });
  try {
    const { data: { entity } } = yield call(fetchAccountSession, action.payload);
    yield put({ type: ACCOUNT_SESSION.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: ACCOUNT_SESSION.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
