// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Upload,
  message,
  Icon,
} from 'antd';
import { validators } from 'utils/validationMessages';
import SimpleReactValidator from 'simple-react-validator';

import {
  notificationActions,
} from 'store/actions';

import {
  SpH5,
  SpError,
  SpText,
  SpButton,
} from 'components/DesignKit';

const { TextArea } = Input;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  setNotification: Function,
  inventoryFeedback: Array,
};

const EditFeedback = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    setNotification,
    inventoryFeedback,
  } = props;

  const [, forceUpdate] = useState();
  const [label, setLabel] = useState('');
  const [question, setQuestion] = useState('')
  const [disableButton, setDisableButton] = useState(false);
  const STRING_LENGTH = 1;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      descriptionValidation: {
        message: 'The description must be between 1 and 250 characters.',
        rule: val => val.length >= 1 && val.length <= 250,
      },
      labelValidation: {
        message: 'The label must be below 50 characters.',
        rule: val => val.length <= 50,
      },
    },
  }));

  simpleValidator.current.purgeFields();
  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);
  useEffect(() => {
    if (inventoryFeedback && inventoryFeedback.length !== 0) {
      setLabel((inventoryFeedback && inventoryFeedback.label));
      setQuestion((inventoryFeedback && inventoryFeedback.question))
    }
else {
      setLabel('');
      setQuestion('');
    }
  }, [inventoryFeedback]);



 



  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      label,
      question,
      feedbackQuestionGid: inventoryFeedback && inventoryFeedback.gid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="600px"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Edit Feedback"
    >
        <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2 mb-4">
          <label htmlFor=""><SpH5>Label</SpH5></label>
          <Input
            placeholder="Enter your label here"
            onChange={(e) => setLabel(e.currentTarget.value)}
            value={label}
          />
         <p className="text-muted" style={{fontSize:"10px" , textAlign:"end"}}>Maximum 15 characters.</p>
          <SpError>
            {simpleValidator.current.message('label', label, 'required|stringLength|labelValidation')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Enter your feedback question here </SpH5></label>
          <TextArea
          maxLength={255}
          rows={5}
          placeholder="Description"
          value={question}
          onChange={(e) => setQuestion(e.currentTarget.value)}
        />
                 <p className="text-muted" style={{fontSize:"10px" , textAlign:"end"}}>Maximum 250 characters.</p>

             <SpError>
            {simpleValidator.current.message('Description',question, 'required|stringLength|descriptionValidation')}
          </SpError>
        </div>
        <div>&nbsp;</div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(EditFeedback);
