import { PARTNER_SETLLEMENT } from '../actionTypes';

export const fetchPartnerSettlement = action => ({
  type: PARTNER_SETLLEMENT.FETCH,
  payload: action.payload,
});

export const addPartnerSettlement = (action) => ({
  type: PARTNER_SETLLEMENT.ADD,
  payload: action.payload,
});

export const deletePartnerSettlement = (action) => ({
  type: PARTNER_SETLLEMENT.DELETE,
  payload: action.payload,
});
