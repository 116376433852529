import React from 'react';

/* eslint-disable */

const Search = ({ fill = "silver" }) => (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.9212 8.86518C11.3302 6.92775 11.1552 4.19946 9.40248 2.45928C7.4666 0.537249 4.33915 0.548478 2.41712 2.48436C0.495089 4.42024 0.506318 7.54769 2.4422 9.46972C4.19936 11.2143 6.93824 11.3661 8.86631 9.93158L11.9356 12.979L12.9925 11.9145L9.9212 8.86518ZM8.34564 3.52374C9.69363 4.86209 9.70145 7.0398 8.3631 8.3878C7.02475 9.73579 4.84704 9.74361 3.49904 8.40526C2.15105 7.06691 2.14323 4.8892 3.48158 3.5412C4.81993 2.19321 6.99764 2.18539 8.34564 3.52374Z" fill={fill}/>
</svg>
);

export default Search;



