import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  PRINTER_LABEL_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryPrinterLabelDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_TAG}/${gid}`);

const fetchItemByPrinterLabel = async gid => axiosAuthInstance.get(`/inventory/item/tag/${gid}`);

const patchItemPrinterLabel = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_TAG}/${gid}/items`, params);

export function* getInventoryPrinterLabelDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryPrinterLabelDetails, action.payload);
      yield put({ type: PRINTER_LABEL_DETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchInventoryPrinterLabelDetails, action);
      yield put({ type: PRINTER_LABEL_DETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: PRINTER_LABEL_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getInventoryItemByPrinterLabel(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchItemByPrinterLabel, action.payload);
      yield put({ type: PRINTER_LABEL_DETAILS.LABEL_ITEM_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchItemByPrinterLabel, action);
      yield put({ type: PRINTER_LABEL_DETAILS.LABEL_ITEM_SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: PRINTER_LABEL_DETAILS.LABEL_ITEM_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addItemPrinterLabel(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(patchItemPrinterLabel, action.payload);
    const { data: { entity } } = yield call(fetchItemByPrinterLabel, action.payload && action.payload.gid);
    yield put({ type: PRINTER_LABEL_DETAILS.LABEL_ITEM_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Items Updated successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
