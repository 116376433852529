import { DASHBOARD } from '../actionTypes';

export const fetchPaymentSessionAccountSummary = action => ({
  type: DASHBOARD.FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY,
  payload: action.payload,
});

export const fetchPaymentSessionDailySummary = action => ({
  type: DASHBOARD.FETCH_PAYMENT_SESSION_DAILY_SUMMARY,
  payload: action.payload,
});

export const fetchPaymentSessionHourlySummary = action => ({
  type: DASHBOARD.FETCH_PAYMENT_SESSION_HOURLY_SUMMARY,
  payload: action.payload,
});

export const fetchPaymentSessionStatusSummary = action => ({
  type: DASHBOARD.FETCH_PAYMENT_SESSION_STATUS_SUMMARY,
  payload: action.payload,
});

export const fetchPaymentSessionCustomerSummary = action => ({
  type: DASHBOARD.FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY,
  payload: action.payload,
});

export const fetchTopPayments = action => ({
  type: DASHBOARD.FETCH_TOP_PAYMENTS,
  payload: action.payload,
});

export const fetchCustomers = action => ({
  type: DASHBOARD.FETCH_CUSTOMERS,
  payload: action.payload,
});

export const setDuration = (duration, tz, currencyCode, summaryType) => ({
  type: DASHBOARD.SET_DURATION,
  payload: {
    duration, tz, currencyCode, summaryType,
  },
});

export const setSummaryType = (action) => ({
  type: DASHBOARD.SET_SUMMARY_TYPE,
  payload: action.payload,
});
