import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Empty,
} from 'antd';
import { useLocation } from 'react-router-dom';

import getStateList from 'utils/stateList';
import { businessActions, accountActions } from 'store/actions';
import {
  ACCOUNT,
} from 'store/actionTypes';

import Loading from 'components/Loading';

import RBAC from 'components/rbac';
import { ROLES, COUNTRIES, BUSINESS_TYPES } from 'appconstants';
import Forbidden from 'components/forbidden';
import Add from './Add';
import View from './View';
import Edit from './Edit';

type Props = {
  fetchBusinessByAccountId: Function,
  // fetchAccountById: Function,
  fetchBusinessDraftByAccountId: Function,
  business: Object,
  representatives: Array<Object>,
  loading: boolean,
  selectedAccount: Object,
  internalRole: Object,
   fetchDocusignStatus:Function,
   docsdata:Object,
};

const BusinessSettings = (props: Props) => {
  const {
    selectedAccount,
    business,
    representatives,
    loading,
    fetchBusinessByAccountId,
    fetchAccountById,
    fetchDocusignStatus,
    internalRole,
    docsdata,
    fetchBusinessDraftByAccountId,
  } = props;

  const gid = selectedAccount && selectedAccount.gid;
  const location = useLocation();
  const [editSettings, setEditSettings] = useState(false);
  const [statesInCountry, setStatesInCountry] = useState([]);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [businessTypes, setBusinessTypes] = useState([]);


  const debouncedfetchBusinessByAccountId = debounce(fetchBusinessByAccountId, 2000, { leading: true });

  // const fetchAccountView = fetchDocusignStatus(gid);

  const getData = () => {
    debouncedfetchBusinessByAccountId(gid);
    fetchBusinessDraftByAccountId(gid);
    // fetchDocusignStatus(gid);
  };


  useEffect(() => {
    fetchAccountById(gid);
  }, []);

  useEffect(() => {
    if (!business) {
      setEditSettings(false);
    }
  }, [business]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setStatesInCountry(getStateList(selectedAccount.country.id));
      setBusinessTypes(BUSINESS_TYPES[selectedAccount.country.id]);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.gid) {
      getData();
    }
    if (selectedAccount && selectedAccount.country) {
      setStatesInCountry(getStateList(selectedAccount.country.id));
      setBusinessTypes(BUSINESS_TYPES[selectedAccount.country.id]);
    }
  }, [location, selectedAccount]);

  const LoadComponent = () => {
    if (Boolean(business && Object.keys(business).length) && !editSettings) {
      return (
        <View
          business={business}
          status={selectedAccount.status}
          businessRepresentatives={representatives}
          selectedAccount={selectedAccount}
          docs={docsdata}
          editBusiness={value => setEditSettings(value)}
          internalRole={internalRole}
          isIndia={isIndia}
          businessTypes={businessTypes}
          fetchAccountById={fetchAccountById}
          fetchDocusignStatus={fetchDocusignStatus}
          debouncedfetchBusinessByAccountId={debouncedfetchBusinessByAccountId}
        />
      );
    }
    return editSettings ? (
      <RBAC fallback={<Forbidden />} permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.ONBOARDING, ROLES.SUPERUSER]}>
        <Edit
          business={business}
          statesInCountry={statesInCountry}
          businessRepresentatives={representatives}
          cancelEdit={() => setEditSettings(false)}
          editDone={() => setEditSettings(false)}
        />
      </RBAC>
    ) : (
      <RBAC fallback={<Empty style={{ padding: '40px' }} />} permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.ONBOARDING, ROLES.SUPERUSER]}>
        <Add
          status={selectedAccount.status}
          statesInCountry={statesInCountry}
        />
      </RBAC>
    );
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      {
        gid && (
          <Card className="mb-2" key={(selectedAccount && selectedAccount.country && selectedAccount.country.id)}>
            <LoadComponent />
          </Card>
        )
      }
    </>
  );
};


const mapStateToProps = state => ({
  selectedAccount: state.account.selectedAccount,
  business: state.business.business,
  representatives: state.business.businessRepresentatives,
  loading: state.loading.loading,
  internalRole: state.user.internalRole,
  docsdata: state.business.businessDocusign,
});

const mapDispatchToProps = dispatch => ({
  fetchBusinessByAccountId: gid => dispatch(businessActions.fetchBusinessByAccountId({
    payload: gid,
  })),
  fetchBusinessDraftByAccountId: gid => dispatch(businessActions.fetchBusinessDraftByAccountId({
    payload: gid,
  })),
  fetchAccountById: gid => dispatch(accountActions.fetchAccountById({
    type: ACCOUNT.FETCH_BY_ID,
    payload: gid,
  })),
  fetchDocusignStatus: gid => dispatch(businessActions.fetchDocusignStatus({
    payload: gid,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BusinessSettings);
