import { ACH_PAYMENT } from '../actionTypes';

const initialState = {
  test: false,
  benficiers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACH_PAYMENT.SUCCESS:
      return {
        ...state,
        benficiers: action.payload,
      };
    case ACH_PAYMENT.CUSTOMER_ADD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
