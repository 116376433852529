/* eslint-disable */
import React from 'react';

const previewIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="43" viewBox="0 0 20 21">
        <g>
            <path d="M4.807 8.462H3.522c-.161 0-.316.064-.43.178-.114.114-.178.27-.177.43l.009 4.614c0 .335.272.607.607.607.336 0 .608-.272.608-.607v-1.4l.668-.003c1.067 0 1.936-.857 1.936-1.91s-.869-1.91-1.936-1.91zm0 2.604l-.672.003-.002-.698c0-.137 0-.425-.002-.695h.676c.39 0 .72.318.72.695 0 .377-.33.695-.72.695zM9.973 8.462H8.704c-.16 0-.315.064-.43.178-.113.114-.177.269-.177.43l.01 4.48c0 .16.064.315.179.428.114.113.267.177.428.177h.002c.038 0 .943-.004 1.305-.01 1.372-.024 2.368-1.217 2.368-2.837 0-1.702-.971-2.846-2.416-2.846zm.026 4.468c-.157.003-.428.005-.68.007-.002-.528-.005-2.713-.006-3.26h.66c1.115 0 1.201 1.248 1.201 1.631 0 .797-.363 1.608-1.175 1.622zM16.391 9.63c.336 0 .608-.273.608-.608 0-.335-.272-.607-.608-.607h-1.857c-.335 0-.607.272-.607.607v4.581c0 .336.272.608.607.608.336 0 .608-.272.608-.608v-1.726h1.102c.335 0 .607-.272.607-.608 0-.335-.272-.607-.607-.607h-1.102V9.629h1.25z" />
            <path d="M18.178 5.87h-.445v-.173c0-.78-.296-1.522-.833-2.089L14.377.948C13.807.344 13.004 0 12.174 0H4.089C3.084 0 2.267.817 2.267 1.822V5.87h-.445C.817 5.87 0 6.688 0 7.692v7.288c0 1.004.817 1.822 1.822 1.822h.445v2.105c0 1.004.817 1.822 1.822 1.822h11.822c1.005 0 1.822-.818 1.822-1.822v-2.105h.445c1.005 0 1.822-.818 1.822-1.822V7.692c0-1.004-.817-1.822-1.822-1.822zM3.482 1.822c0-.335.272-.607.607-.607h8.085c.498 0 .98.207 1.322.568l2.523 2.66c.322.34.5.786.5 1.254v.173H3.481V1.822zm13.036 17.085c0 .335-.272.607-.607.607H4.089c-.335 0-.607-.272-.607-.607v-2.105h13.036v2.105zm2.267-3.927c0 .335-.272.607-.607.607H1.822c-.335 0-.607-.272-.607-.607V7.692c0-.335.272-.607.607-.607h16.356c.335 0 .607.272.607.607v7.288z" />
        </g>
    </svg>
);

export default previewIcon;