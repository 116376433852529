// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
} from 'antd';

import {
  SpButton,
  SpHead,
} from 'components/DesignKit';

import { userProfileAction } from 'store/actions';

import Loading from 'components/Loading';

import { USERPROFILE } from 'store/actionTypes';

import Edit from './components/Edit';
import View from './components/View';

type Props = {
  selectedAccount: Object,
  fetchUserProfile: Function,
  updateUserProfile: Function,
  isSuperUser: boolean,
  loading: boolean,
  userId: Object,
  userProfile: Object,
  validator: boolean,
};

const UserProfile = (props: Props) => {
  const {
    selectedAccount,
    fetchUserProfile,
    userProfile,
    loading,
    isSuperUser,
    updateUserProfile,
    userId,
    validator,
  } = props;
  const [editSettings, setEdit] = useState(false);
  const gid = userId && userId.gid;

  const getData = () => {
    fetchUserProfile(gid);
  };

  useEffect(() => {
    getData();
  }, [selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setEdit(false);
    }
  }, [validator]);

  const updateProfile = (params) => {
    updateUserProfile({
      gid,
      params,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card className={loading ? 'OVERLAY mb-2' : 'mb-2'}>
      <Row type="flex" justify="space-between" className="my-2">
        <Col>
          <SpHead>USER PROFILE</SpHead>
        </Col>
        <Col>
          <div>
            {!editSettings && isSuperUser && (
              <SpButton
                type="secondary"
                shape="round"
                onClick={() => setEdit(true)}
              >
                Edit User Profile
              </SpButton>
            )}
          </div>
        </Col>
      </Row>
      {
        editSettings
          ? (
            <Edit
              selectedAccount={selectedAccount}
              userProfile={userProfile}
              updateProfile={updateProfile}
              setEdit={() => setEdit(false)}
            />
          )
          : <View userProfile={userProfile} />
      }
    </Card>
  );
};

const mapStateToProps = state => ({
  selectedAccount: state.account.selectedAccount,
  userProfile: state.userProfile,
  isSuperUser: state.user.superUser,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  userId: state.user.user,
  loading: state.loading.loading,
});

const mapDispatchToProps = dispatch => ({
  fetchUserProfile: gid => dispatch(userProfileAction.fetchUserProfile({
    type: USERPROFILE.FETCH,
    payload: gid,
  })),
  updateUserProfile: params => dispatch(userProfileAction.updateUserProfile({
    type: USERPROFILE.UPDATE,
    payload: params,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfile);
