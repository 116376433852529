import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Checkbox,
  Button,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SUPPORTED_TRANSFER_TYPES,
} from 'appconstants';

import {
  SpButton,
  SpError,
} from 'components/DesignKit';


type Props = {
  internalAccount: Object,
  submit: Function,
  openModal: Function,
};

const SupportedTransferTypes = (props: Props) => {
  const {
    internalAccount,
    submit,
    openModal,
  } = props;

  const [currencyData, setCurrencyData] = useState((internalAccount?.supportedTransferTypes));
  const [disabled, setDisabled] = useState(true);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());

  const updateCurrency = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      transferMethodData: currencyData,
    });
  };

  useEffect(() => {
    setCurrencyData((internalAccount?.supportedTransferTypes));
    setDisabled(true);
  }, [openModal]);

  const onCurrencyChange = (checkedValues) => {
    setCurrencyData(checkedValues);
    setDisabled(false);
    openModal(false);
  };

  function renderCurrencyRows(data) {
    const content = [];
    data.forEach((result, i) => {
      if ((i + 1) % 6 === 0) {
        content.push(
          <Row className="mb-2">
            <Col span={2}>
              <Checkbox value={result}>
                {result}
              </Checkbox>
            </Col>
          </Row>,
        );
      } else {
        content.push(
          <Col span={2}>
            <Checkbox value={result}>
              {result}
            </Checkbox>
          </Col>,
        );
      }
    });
    return content;
  }

  const reset = () => {
    setCurrencyData(internalAccount?.supportedTransferTypes);
    setDisabled(true);
  };

  return (
    <>
      <div>
        <Row className="mb-2">
          <Checkbox.Group style={{ width: '100%' }} value={currencyData} onChange={onCurrencyChange}>
            <Row>
              {renderCurrencyRows(SUPPORTED_TRANSFER_TYPES)}
            </Row>
          </Checkbox.Group>
          <SpError>
            {simpleValidator.current.message('transfer type', currencyData, 'required')}
          </SpError>
        </Row>
        <Row className="mt-4" style={{ marginLeft: '-15px' }}>
          <Col span={2}>
            <Button
              type="link"
              onClick={reset}
              disabled={disabled}
            >
              Reset
            </Button>
          </Col>
          <Col span={2}>
            <SpButton
              type="primary"
              shape="round"
              disabled={disabled}
              onClick={updateCurrency}
            >
              Update
            </SpButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(SupportedTransferTypes);
