// @flow
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Card,
  Row,
  Col,
  Icon,
  Tooltip,
  Table,
} from 'antd';
import queryString from 'query-string';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import {
  SpH5,
  SpButton,
  SpForm,
  SpText,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  tableDetailsActions,
  notificationActions,
  inventoryShopsDetailsActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  PAGES,
  MESSAGES,
} from 'appconstants';
import moment from 'moment-timezone';
import { QRCode } from 'react-qrcode-logo';
import getReservation from 'utils/getReservation';
import getReservationBackground from 'utils/getReservationBackground';
import Clipboard from 'components/NavIcons/copyclipboard';
import Qrlogo from '../../../assets/imgs/SwirepayQrlogo.png';

type Props = {
  fetchTableDetails: Function,
  fetchStaffDetails: Function,
  fetchGuestDetails: Function,
  inventoryPrinterLabelDetails: Object,
  loading: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  setNotification: Function,
  paymentSlug: Array,
  fetchShopsSlug: Function,
  inventoryStaffDetails: Array,
  inventoryGuestDetails: Array,
  totalElements: Number,
  selectedAccount: Object,
  isMobileView: Boolean,
}

// $FlowFixMe
const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '75px',
  textAlign: 'center',
});

const DiningDetailsView = (props: Props) => {
  const location = useLocation();
  const {
    fetchTableDetails,
    fetchStaffDetails,
    fetchGuestDetails,
    inventoryPrinterLabelDetails,
    loading,
    history,
    setNotification,
    paymentSlug,
    fetchShopsSlug,
    inventoryStaffDetails,
    inventoryGuestDetails,
    totalElements,
    selectedAccount,
    isMobileView,
  } = props;
  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const qrCodeRef = useRef(null);
  const [slugName, setSlugName] = useState('');
  const [firstTime, setFirstTime] = useState(true);
  const [firstGuest, setFirstGuest] = useState(true);
  const [pagination, setPagination] = useState({});
  const todayDate = selectedAccount ? moment.utc(new Date()).tz(selectedAccount.timezone).format('MMM DD, YYYY') : null;

  useEffect(() => {
    if (paymentSlug && paymentSlug.length !== 0) {
      setSlugName(paymentSlug[0].slugName);
    }
  }, [paymentSlug]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [inventoryGuestDetails]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    fetchGuestDetails({ gid: inventoryPrinterLabelDetails.name, currentPage: tempPage });
    history.push({
      pathname: `/diningdetails/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  useEffect(() => {
    if (firstTime) {
      if (inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.shop && inventoryPrinterLabelDetails.shop.gid) {
        fetchShopsSlug(inventoryPrinterLabelDetails.shop.gid);
        setFirstTime(false);
      }
    }
    if (firstGuest) {
      if (inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.name) {
        fetchGuestDetails({ gid: inventoryPrinterLabelDetails.name });
        setFirstGuest(false);
      }
    }
  }, [inventoryPrinterLabelDetails]);

  const getData = () => {
    fetchTableDetails(gid);
    if (gid) {
      fetchStaffDetails(gid);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getLink = (id) => {
    const url = process.env.REACT_APP_ENV === 'Production' ? 'https://order.swirepay.com' : 'https://staging-order.swirepay.com';
    const selectedSlugName = `${url}/${slugName}?tableGid=${id}`;
    return selectedSlugName;
  };

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.TABLE_RESERVATION);
    }
  }, []);

  const downloadQRCode = async () => {
    const qrCode = qrCodeRef.current.canvas.current;
    if (!qrCode) {
      console.error('QR code element not found.');
      return;
    }
    try {
      const dataUrl = qrCode.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'qrcode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.QR_Download,
      });
    } catch (error) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Error capturing QR code',
      });
    }
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: 'Inventory order link copied sucessfully.',
    });
  };

  const getStaffInfo = (data) => {
    if ((data && data.length) !== 0) {
      const filteredArray = data.filter((item) => item.user !== null);
      return (
        <>
          {filteredArray.map((item) => (
            <span key={item.gid}>
              {`${item.user.fullName},`}
            </span>
          ))}
          {filteredArray.length === 0 && <>&#8211;</>}
        </>
      );
    } return <>&#8211;</>;
  };

  const viewOrders = (record) => {
    const itemGid = record && record.gid;
    window.open(`${PAGES.INVENTORY_ORDERS}/${itemGid}`);
  };

  const columns = [
    {
      title: 'GUEST NAME',
      dataIndex: 'name',
      width: '14%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name, record) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {(record && record.customer && record.customer.name) || (name || <>&#8211;</>)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PHONE',
      dataIndex: 'customer.phoneNumber',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (phoneNumber) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {phoneNumber || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'GUEST COUNT',
      dataIndex: 'noOfGuest',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (noOfGuest) => (
        <span style={{ cursor: 'pointer' }}>
          {noOfGuest || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'ORDER ID',
      dataIndex: 'name',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (name, record) => {
        if (record && record.inventoryOrder) {
          const orderId = (record && record.inventoryOrder && record.inventoryOrder.orderNumber)
            || (record && record.inventoryOrder && record.inventoryOrder.cloverPosOrderId);
          return (
            <div
              onClick={(e) => { e.stopPropagation(); viewOrders(record && record.inventoryOrder); }}
              style={{
                color: '#3E8BFF',
                textDecoration: 'underline',
              }}
            >
              {orderId || <>&#8211;</>}
            </div>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DATE & TIME',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>N/A</>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <Tooltip placement="top" title="">
          <StatusTag style={{ backgroundColor: getReservationBackground(status) }}>
            {getReservation(status)}
          </StatusTag>
        </Tooltip>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        inventoryPrinterLabelDetails.gid && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col span={18}>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Shop</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>
                          {/* eslint-disable-next-line max-len */}
                          {(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.shop && inventoryPrinterLabelDetails.shop.name) || <>&#8211;</>}
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Table</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>
                          {(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.name)
                            || <>&#8211;</>}
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Table Section</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>
                          {/* eslint-disable-next-line max-len */}
                          {(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.shopSection && inventoryPrinterLabelDetails.shopSection.name)
                            || <>&#8211;</>}
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  {
                    (inventoryStaffDetails && inventoryStaffDetails.length !== 0) ? (
                      <SpForm>
                        <Row type="flex" justify="start" align="middle">
                          <Col span={5}>
                            <span>Staff</span>
                          </Col>
                          <Col span={10}>
                            <SpH5>
                              {getStaffInfo(inventoryStaffDetails)}
                            </SpH5>
                          </Col>
                        </Row>
                      </SpForm>
                    ) : null
                  }

                  {/* <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Orders</span>
                      </Col>
                      <Col span={6}>
                        <SpH5 color="#3E8BFF">
                          {
                            inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.inventoryOrder
                              ? (
                                <div style={{ cursor: 'pointer' }} onClick={() => viewOrders(inventoryPrinterLabelDetails.inventoryOrder)}>
                                  View Orders
                                </div>
                              )
                              : <>&#8211;</>
                          }
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm> */}
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Link</span>
                      </Col>
                      <Col span={16}>
                        {/* eslint-disable-next-line max-len */}
                        {inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.gid
                          ? (
                            <SpH5>{getLink(inventoryPrinterLabelDetails.gid)}
                              <SpButton
                                type="secondary"
                                className="ml-2"
                                borderRadius="50px"
                                // eslint-disable-next-line
                                // disabled={inventoryPrinterLabelDetails.status === 'RESERVED' ? false : inventoryPrinterLabelDetails.status === 'OCCUPIED' ? false : true}
                                onClick={() => { copyCode(getLink(inventoryPrinterLabelDetails.gid) || ''); }}
                              >
                                <Clipboard />
                              </SpButton>
                            </SpH5>
                          )
                          : <>&#8211;</>}
                      </Col>
                    </Row>
                  </SpForm>
                </Col>
                <Col span={6} style={{ textAlign: 'center' }}>
                  <QRCode
                    size="256"
                    value={getLink(inventoryPrinterLabelDetails && inventoryPrinterLabelDetails.gid)}
                    logoImage={Qrlogo}
                    ref={qrCodeRef}
                    logoPadding={5}
                    removeQrCodeBehindLogo
                  />
                  <div className="mt-2">
                    <SpButton
                      type="secondary"
                      shape="round"
                      // eslint-disable-next-line
                      // disabled={inventoryPrinterLabelDetails.status === 'RESERVED' ? false : inventoryPrinterLabelDetails.status === 'OCCUPIED' ? false : true}
                      onClick={() => downloadQRCode()}
                    >
                      <Icon type="download" />
                    </SpButton>
                  </div>
                </Col>
              </Row>
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="17px" fontWeight="700" color="#999999">GUEST INFO</SpText>
                </Col>
                {
                  todayDate && (
                    <Col>
                      <SpText fontSize="14px" fontWeight="600" color="#292929">Order dated: {todayDate}</SpText>
                    </Col>
                  )
                }
              </Row>
              <Table
                rowClassName={() => 'ant-table-clickable-row'}
                columns={columns}
                rowKey={(record) => record.gid}
                dataSource={inventoryGuestDetails}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  inventoryPrinterLabelDetails: state.tableDetails,
  inventoryStaffDetails: state.tableDetails.staffData.content,
  inventoryGuestDetails: state.tableDetails.guestData.content,
  totalElements: state.tableDetails.guestData ? state.tableDetails.guestData.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  profile: state.subscription.profile,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  paymentSlug: state.inventoryShopsDetails.shopsSlug,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTableDetails: gid => dispatch(tableDetailsActions.fetchTableDetails({
    payload: gid,
  })),
  fetchStaffDetails: gid => dispatch(tableDetailsActions.fetchStaffDetails({
    payload: gid,
  })),
  fetchGuestDetails: gid => dispatch(tableDetailsActions.fetchGuestDetails({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchShopsSlug: param => dispatch(inventoryShopsDetailsActions.fetchShopsSlug({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(DiningDetailsView);
