// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  status: string,
  paymentPagesDetails: Object,
};

const soldOutSelectedPaymentPage = (props: Props) => {
  const {
    visible,
    close,
    submit,
    status,
    paymentPagesDetails,
  } = props;

  console.log(status, 'status');
  const payloadStatus = ((status === 'ACTIVE') ? 'SOLD_OUT' : 'ACTIVE');

  const soldoutSelectedPaymentPage = () => {
    submit({
      status: payloadStatus,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton
          onClick={soldoutSelectedPaymentPage}
          type={status === 'ACTIVE' ? 'danger' : 'primary'}
        >
          {status === 'ACTIVE' ? 'Confirm' : 'Resume'}
        </SpButton>,
      ]}
      title={status === 'ACTIVE' ? 'Sold Out Payment Page' : 'Resume Sales'}
    >
      <div className="px-4">
        <div className="mt-1 mb-2">
          Are you sure you want to {status === 'ACTIVE' ? 'sold out this Payment Page?' : 'Resume Sales?'}
        </div>
        <SpH5 className="pb-3">
          <div>{paymentPagesDetails.title ? paymentPagesDetails.title : <>&#8211;</>}</div>
        </SpH5>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default soldOutSelectedPaymentPage;
