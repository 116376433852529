import { SETTINGS } from '../actionTypes';

export const updateAccountInfo = action => ({
  type: SETTINGS.UPDATE,
  payload: action.payload,
});

export const allowCrossTransfer = action => ({
  type: SETTINGS.UPDATE_CROSS,
  payload: action.payload,
});
