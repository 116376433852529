// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Icon,
  Tooltip,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';

import {
  PAGES,
  MESSAGES,
} from 'appconstants';
import {
  SpButton,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';

import { NOTIFICATION } from 'store/actionTypes';
import { accountSlugActions, notificationActions } from 'store/actions';
import Clipboard from 'components/NavIcons/copyclipboard';
import DownloadQr from 'components/NavIcons/downloadqr';
import getAccountSlugBackground from 'utils/getAccountSlugBackground';
import getAccountSlugStatus from 'utils/getAccountSlugStatus';

import AddAccountSlug from './components/AddAccountSlug';
import ShowQrCode from './components/ShowQrCode';

type Props = {
  fetchAccountSlug: Function,
  accountSlug: Array<Object>,
  loading: boolean,
  test: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  submitting: boolean,
  setNotification: Function,
  addAccountSlug: Function,
  validator: boolean,
  isMobileView: Boolean,
};

const AccountSlug = (props: Props) => {
  const {
    loading,
    fetchAccountSlug,
    accountSlug,
    totalElements,
    test,
    history,
    submitting,
    addAccountSlug,
    setNotification,
    validator,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const [addAccountSlugModal, setAddAccountSlugModal] = useState(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [selectedQR, setSelectedQR] = useState('');
  const debouncedFetchAccountSlug = debounce(fetchAccountSlug, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    debouncedFetchAccountSlug({ currentPage, sortParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [test, location]);


  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [accountSlug]);

  useEffect(() => {
    if (!validator) {
      setAddAccountSlugModal(false);
    }
  }, [validator]);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.ACCOUNTSLUG,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.ACCOUNTSLUG,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const addNewAccountSlug = (acntslug: Object) => {
    const {
      name,
      slug,
    } = acntslug;

    addAccountSlug({
      alias: name,
      slugName: slug,
    });
  };

  const selectedRecord = (record) => {
    const slugGid = record.gid;
    history.push(`${PAGES.ACCOUNTSLUG}/${slugGid}`);
  };

  const selectedQrCode = (qrslug) => {
    setSelectedQR(qrslug);
    setShowQrCodeModal(true);
  };

  const returnFromQrCode = (qrVlue) => {
    if (qrVlue === true) {
      setShowQrCodeModal(false);
    }
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSLINK.SUCCESS,
    });
  };

  const columns = [
    {
      title: 'ALIAS NAME',
      dataIndex: 'alias',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (alias) => (
        <Tooltip placement="top" title="Click here to view details">
          {alias || <>&#8211;</>}
        </Tooltip>
      ),
    },
    {
      title: 'SLUG NAME',
      dataIndex: 'slugName',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (slugName) => (
        <Tooltip placement="top" title="Click here to view details">
          {slugName || <>&#8211;</>}
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (status) => {
        if (status) {
          const { color, background } = getAccountSlugBackground(status);
          return (
            <Tooltip placement="top" title="Click here to view details">
              <SpStatusTag style={{ color, backgroundColor: background }}>
                {getAccountSlugStatus(status)}
              </SpStatusTag>
            </Tooltip>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '20%',
    align: 'center',
    render: (text, record) => {
      const { status } = record;
      if (status === 'LINKED') {
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              className="mr-2"
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); selectedQrCode(record && record.accountSlug); }}
            >
              <DownloadQr />
            </SpButton>
            <SpButton
              type="secondary"
              borderRadius="50px"
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); copyCode(record && record.accountSlug); }}
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      }
      return (
        <span>
          <SpButton
            type="secondary"
            borderRadius="50px"
            className="mr-2"
            disabled
          >
            <DownloadQr />
          </SpButton>
          <SpButton
            type="secondary"
            borderRadius="50px"
            disabled
          >
            <Clipboard />
          </SpButton>
        </span>
      );
    },
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        test
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <h5>Slug is only available for live data</h5>
              </div>
            </Card>
          )
          : (
            <Card>
              <Row className="my-2" type="flex" justify="space-between">
                <Col className="ml-1">
                  <SpText color="#999999" fontSize="12px" fontWeight="700">
                    ACCOUNT SLUG
                  </SpText>
                </Col>
                <Col>
                  <SpButton
                    type="secondary"
                    shape="round"
                    ghost
                    className="mr-3"
                    onClick={() => setAddAccountSlugModal(true)}
                  >
                    {'\u002B'}&nbsp;Create Account Slug
                  </SpButton>
                </Col>
              </Row>

              {/* ADD ACCOUNT SLUG MODAL */}
              {addAccountSlugModal && (
                <AddAccountSlug
                  visible={addAccountSlugModal}
                  submitting={submitting}
                  close={() => setAddAccountSlugModal(false)}
                  submit={addNewAccountSlug}
                />
              )}

              {/* SHOW QR MODAL */}
              {showQrCodeModal && (
                <ShowQrCode
                  visible={showQrCodeModal}
                  submitting={submitting}
                  selectedQR={selectedQR}
                  returnFromQrCode={returnFromQrCode}
                  close={() => setShowQrCodeModal(false)}
                />
              )}

              <Table
                rowClassName={() => 'ant-table-clickable-row'}
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                columns={[...columns, actionColumn]}
                rowKey={(record) => record.gid}
                dataSource={accountSlug}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  accountSlug: state.accountSlug.content,
  loading: state.loading.loading,
  test: state.account.test,
  totalElements: state.accountSlug.totalElements,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountSlug: param => dispatch(accountSlugActions.fetchAccountSlug({
    payload: param,
  })),
  addAccountSlug: param => dispatch(accountSlugActions.addAccountSlug({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AccountSlug);
