// @flow
import React, {
  useRef,
  useState,
  createRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Steps,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  notificationActions,
} from 'store/actions';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { NOTIFICATION } from 'store/actionTypes';
import { SpButton } from 'components/DesignKit';

import SearchRate from './SearchRate';
import RateDetails from './RateDetails';
import ReviewRate from './ReviewRate';

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  setNotification: Function,
  phoneCode: string,
  submit: Function,
  transferType: Array<Object>,
  issuer: Array<Object>,
  category: Array<Object>,
};

const { Step } = Steps;

const AddRate = (props: Props) => {
  const {
    visible,
    close,
    submitting,
    selectedAccount,
    phoneCode,
    submit,
    setNotification,
    transferType,
    issuer,
    category,
  } = props;

  const [, forceUpdate] = useState();
  const searchRateRef = createRef();
  const rateDetailsRef = createRef();
  const [refArray, setRefArray] = useState([searchRateRef, rateDetailsRef]);
  const [current, setCurrent] = useState(0);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const [issuerId, setIssuerId] = useState('');
  const [issuerName, setIssuerName] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [transferTypeId, setTransferTypeId] = useState('');
  const [transferTypeName, setTransferTypeName] = useState('');
  const [countryId, setCountryId] = useState('');
  const [countryName, setCountryName] = useState('');
  const [rateGid, setRateGid] = useState('');
  const [processingMin, setProcessingMin] = useState('0.00');
  const [processingMax, setProcessingMax] = useState('0.00');
  const [rate, setRate] = useState('0.00');
  const [tax, setTax] = useState('0.00');
  const [fee, setFee] = useState('0.00');
  const [salesPerson, setSalesPerson] = useState('');
  const currencyType = selectedAccount && selectedAccount.currency;

  const searchRateData = {
    issuerId,
    categoryId,
    transferTypeId,
    countryId,
    rateGid,
    issuerName,
    categoryName,
    transferTypeName,
    countryName,
  };

  const addRateData = {
    processingMin,
    processingMax,
    rate,
    tax,
    fee,
    salesPerson,
  };

  useEffect(() => {
    if (current === 2 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const next = async () => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setIssuerId(valuesArray[0].issuerId);
      setCategoryId(valuesArray[0].categoryId);
      setTransferTypeId(valuesArray[0].transferTypeId);
      setCountryId(valuesArray[0].countryId);
      setIssuerName(valuesArray[0].issuerName);
      setCategoryName(valuesArray[0].categoryName);
      setTransferTypeName(valuesArray[0].transferTypeName);
      setCountryName(valuesArray[0].countryName);
      let rateAddGid;
      // eslint-disable-next-line max-len
      const filterParams = `currency.id.EQ=${Number(valuesArray[0].countryId)}&country.id.EQ=${Number(valuesArray[0].countryId)}&issuer.id.EQ=${Number(valuesArray[0].issuerId)}&category.id.EQ=${Number(valuesArray[0].categoryId)}&transferType.id.EQ=${Number(valuesArray[0].transferTypeId)}`;
      try {
        const { data: { entity: { content } } } = await axiosAuthInstance.get(`${API_END_POINTS.ISSUER_RATE_FILTER}?${filterParams}`);
        if (content.length > 1) {
          setNotification({
            type: NOTIFICATION.ERROR,
            payload: 'Multiple Rates found for this search.',
          });
        } else if (content && content[0]) {
          rateAddGid = content[0].gid;
          if (rateGid !== rateAddGid) {
            setProcessingMin((content[0].processingMin) / 100);
            setProcessingMax((content[0].processingMax) / 100);
            setRate((content[0].rate) / 100);
            setTax((content[0].tax) / 100);
            setFee((content[0].fee) / 100);
            setRateGid(rateAddGid);
            setSalesPerson('');
          } else {
            setProcessingMin(processingMin);
            setProcessingMax(processingMax);
            setRate(rate);
            setTax(tax);
            setFee(fee);
            setSalesPerson(salesPerson);
            setRateGid(rateAddGid);
          }
        } else {
          setNotification({
            type: NOTIFICATION.ERROR,
            payload: 'Rate not found for this search.',
          });
        }
      } catch (error) {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: error.response.data && error.response.data.message,
        });
      }
      if (rateAddGid || rateGid) {
        setCurrent(current + 1);
      }
    } else if (current === 1) {
      validationArray.push(refArray[1].current.validate());
      const rateDetails = refArray[1].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setProcessingMin(rateDetails.processingMin);
      setProcessingMax(rateDetails.processingMax);
      setRate(rateDetails.rate);
      setTax(rateDetails.tax);
      setFee(rateDetails.fee);
      setSalesPerson((rateDetails.salesPerson).trim());
      setCurrent(current + 1);
    } else if (current === 2) {
      setDisableButton(true);
      const payload = Object.assign(searchRateData, addRateData, rateGid);
      submit(
        payload,
      );
    }
  };

  const prev = () => {
    setDisableButton(false);
    if (current === 0) {
      close();
    } else if (current === 1) {
      setCurrent(current - 1);
    } else if (current === 2) {
      setCurrent(current - 1);
    } else if (current === 3) {
      setCurrent(current - 1);
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="540px"
      footer={current === 0
        ? [
          <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
          <SpButton key="2" onClick={next}>Next</SpButton>,
        ]
        : current === 1
          ? [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next}>Next</SpButton>,
          ]
          : [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next} disabled={disableButton}>Add Rate</SpButton>,
          ]}
      title="Add Rate"
    >
      <>
        <div style={{ marginTop: '-8px' }}>
          <Steps current={current} className="subscriptions">
            <Step key="1" title="Search Rate" />
            <Step key="2" title="Add Rate" />
            <Step key="3" title="Review" />
          </Steps>
        </div>
        <div className="px-5 mt-2">
          <div className={submitting ? 'OVERLAY' : ''} />
          {current === 0 && (
            <SearchRate
              ref={refArray[0]}
              selectedAccount={selectedAccount}
              searchRateData={searchRateData}
              transferType={transferType}
              issuer={issuer}
              category={category}
            />
          )}
          {current === 1 && (
            <RateDetails
              ref={refArray[1]}
              selectedAccount={selectedAccount}
              currencyType={currencyType}
              phoneCode={phoneCode}
              searchRateData={searchRateData}
              addRateData={addRateData}
            />
          )}
          {current === 2 && (
            <ReviewRate
              selectedAccount={selectedAccount}
              searchRateData={searchRateData}
              addRateData={addRateData}
            />
          )}
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddRate);
