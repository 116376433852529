// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Tabs,
  Card,
  Checkbox,
  Col,
  Row,
} from 'antd';

import {
  SpText,
  SpButton,
} from 'components/DesignKit';

import {
  terminalActions,
  inventoryCategorieDetailsActions,
} from 'store/actions';

// import {
//   ROLES,
// } from 'appconstants';

// import queryString from 'query-string';

const { TabPane } = Tabs;

type Props = {
  inventoryCategorieDetails: Array<Objects>,
  getAllItemsByCategory: Function,
  categoryItemsList: Array<Objects>,
  fetchItemByCategory: Function,
  selectedAccount: Object,
  close: Function,
  sendSelectedItems: Function,
  selectedAdvanceFeeId: String,
};

const ItemsList = (props: Props) => {
  const {
    inventoryCategorieDetails,
    getAllItemsByCategory,
    categoryItemsList,
    fetchItemByCategory,
    selectedAccount,
    close,
    sendSelectedItems,
    selectedAdvanceFeeId,
  } = props;

  const [selectedItems, setSelectedItems] = useState([]);
  const [removedItems, setRemovedItems] = useState([]);

  useEffect(() => {
    getAllItemsByCategory();
  }, []);

  useEffect(() => {
    if (categoryItemsList && categoryItemsList.length > 0) {
      const adSelItm = [...selectedItems];
      for (let i = 0; i < categoryItemsList.length; i += 1) {
        for (let j = 0; j < categoryItemsList[i].advancedFees.length; j += 1) {
          if (categoryItemsList[i].advancedFees[j].gid === selectedAdvanceFeeId) {
            adSelItm.push(categoryItemsList[i].gid);
          }
        }
      }
      const uniq = [...new Set(adSelItm)];
      setSelectedItems(uniq);
    }
  }, [categoryItemsList]);

  useEffect(() => {
    if (inventoryCategorieDetails && inventoryCategorieDetails.length !== 0) {
      fetchItemByCategory(inventoryCategorieDetails[0].gid);
    }
  }, [inventoryCategorieDetails]);

  const tabInfo = (category) => (
    <div style={{ textAlign: 'left', padding: '8px 24px 8px 0px' }}>
      <SpText fontSize="18px" color="#2E2E2E">{category.name}Name</SpText><br />
      <SpText fontSize="13px" color="#818181">{category.description}</SpText>
    </div>
  );

  const getItemsByCategory = (catGid) => {
    fetchItemByCategory(catGid);
  };

  const updateSelecteddItems = () => {
    sendSelectedItems(selectedItems, removedItems);
  };

  return (
    <Card>
      <SpText
        fontSize="18px"
        color="#7B7B7B"
        fontWeight="700"
        style={{
          paddingLeft: '10px',
        }}
      >
        Assign Item(s)
      </SpText>
      {
        inventoryCategorieDetails && inventoryCategorieDetails.length > 0 && (
        <Tabs defaultActiveKey={inventoryCategorieDetails[0].gid} tabPosition="left" onChange={getItemsByCategory}>
          {
            inventoryCategorieDetails.map((category) => (
              <TabPane tab={tabInfo(category)} key={category.gid}>
                <Row type="flex">
                  {
                  categoryItemsList.map((item) => (
                    <Col
                      span={6}
                      key={item.gid}
                      style={{
                        border: '1px solid', padding: '10px', cursor: 'pointer',
                      }}
                      className="mr-2 mb-2"
                    >
                      <p><Checkbox
                        checked={selectedItems.includes(item.gid)}
                        className="m-1"
                        onChange={({ target: { checked } }) => {
                          const itemsLIst = [...selectedItems];
                          const rItemsList = [...removedItems];
                          if (checked) {
                            itemsLIst.push(item.gid);
                          } else if (!checked) {
                            const index = itemsLIst.indexOf(item.gid);
                            rItemsList.push(item.gid);
                            if (index > -1) {
                              itemsLIst.splice(index, 1);
                            }
                          }
                          setSelectedItems(itemsLIst);
                          setRemovedItems(rItemsList);
                        }}
                      /> {item.name}
                      </p>
                      <p className="ml-4">{selectedAccount.currency.prefix} {item.price / 100}</p>
                    </Col>
                  ))
                }
                </Row>
              </TabPane>
            ))
        }
        </Tabs>
        )
      }
      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="secondary"
            shape="round"
            onClick={() => close()}
          >
            Cancel
          </SpButton>
        </Col>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={() => updateSelecteddItems()}
          >
            Save
          </SpButton>
        </Col>
      </Row>

    </Card>
  );
};

const mapStateToProps = (state) => ({
  inventoryCategorieDetails: state.inventoryCategorieDetails.entity,
  categoryItemsList: state.inventoryCategorieDetails.categoryItemsList,
  terminals: state.terminals.content,
  loading: state.loading.loading,
  submitting: state.loading.submitting,
  test: state.account.test,
  totalElements: state.terminals.totalElements,
  selectedAccount: state.account.selectedAccount,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
});

const mapDispatchToProps = (dispatch) => ({
  fetchTerminals: param => dispatch(terminalActions.fetchTerminals({
    payload: param,
  })),
  getAllItemsByCategory: param => dispatch(inventoryCategorieDetailsActions.getAllItemsByCategory({
    payload: param,
  })),
  fetchItemByCategory: gid => dispatch(inventoryCategorieDetailsActions.fetchItemByCategory({
    payload: gid,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ItemsList);
