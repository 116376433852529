import { CLOVER_WEBHOOK } from '../actionTypes';

export const fetchCloverWebhook = (action) => ({
  type: CLOVER_WEBHOOK.FETCH,
  payload: action.payload,
});

export const updateCloverWebhook = (action) => ({
  type: CLOVER_WEBHOOK.UPDATE,
  payload: action.payload,
});

export const fetchInternalCloverWebhook = (action) => ({
  type: CLOVER_WEBHOOK.INTERNAL_FETCH,
  payload: action,
});

export const updateInternalCloverWebhook = (action) => ({
  type: CLOVER_WEBHOOK.INTERNAL_UPDATE,
  payload: action.payload,
});

export const addBulkImportWebHook = (action) => ({
  type: CLOVER_WEBHOOK.BULKIMPORT,
  paylaod: action.paylaod,
});
