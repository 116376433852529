import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  TERMINALDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchTerminalDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_TERMINALS}/${gid}`);

const fetchTerminalPayments = async (payload) => {
  let parm = '';
  const queryParam = payload.searchParam;
  const { gid } = payload;
  if (queryParam && queryParam !== 'REFUNDED' && queryParam !== 'ALL_PAYMENTS') {
    parm = `status.IN=${payload.searchParam}`;
  } else if (queryParam && queryParam === 'ALL_PAYMENTS') {
    parm = '';
  } else if (queryParam) {
    parm = 'status.IN=REFUNDED&status.IN=PARTIAL_REFUNDED';
  }
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (parm) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_TERMINALS}/${gid}/payment-session${query}&${parm}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_TERMINALS}/${gid}/payment-session${query}${sorting}`);
};

const patchTerminalDetails = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_TERMINALS}/${gid}`, params);

const postTerminalProof = async ({ gid, document }) => axiosAuthInstance.patch(`${API_END_POINTS.ADMIN_TERMINAL}/${gid}`, document);

export function* getTerminalsDetail(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchTerminalDetails, action.payload);
    yield put({ type: TERMINALDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: TERMINALDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getTerminalPayments(action) {
  try {
    const { data: { entity } } = yield call(fetchTerminalPayments, action.payload);
    yield put({ type: TERMINALDETAILS.TERMINALS_PAYMENTS_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: TERMINALDETAILS.FAILED, payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* updateTerminalDetails(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(patchTerminalDetails, action.payload);
    const { data: { entity } } = yield call(fetchTerminalDetails, action.payload.gid);
    yield put({ type: TERMINALDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TERMINAL.UPDATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateTerminalProof(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postTerminalProof, action.payload);
    const { data: { entity } } = yield call(fetchTerminalDetails, action.payload.gid);
    yield put({ type: TERMINALDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ADMIN_TERMINALDETAILS.TERMINAL_PROOF_ADD,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
