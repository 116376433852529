import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Select,
  Modal,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';

const { Option } = Select;

type Props = {
  visible: boolean,
  close: boolean,
  submit: boolean,
  submitting: boolean,
}

const ResolveDispute = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
  } = props;
  const [, forceUpdate] = useState();
  const [status, setStatus] = useState('WON');
  const [disableButton, setDisableButton] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    submit({
      status,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      title="Resolve Dispute"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Resolve</SpButton>,
      ]}
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div>
          <label><SpH5>Status</SpH5></label>
          <Select
            className="w-100"
            value={status}
            onChange={(e) => setStatus(e)}
          >
            <Option key={1} value="WON">Won</Option>
            <Option key={2} value="LOST">Lost</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('status', status, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(ResolveDispute);
