import { ADMIN_TERMINALDETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  payments: [],
  adminTerminalDetailsStatus: undefined,
  adminTerminalDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_TERMINALDETAILS.FETCH:
      return {
        ...state,
        adminTerminalDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case ADMIN_TERMINALDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        adminTerminalDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case ADMIN_TERMINALDETAILS.FAILED:
      return {
        ...state,
        adminTerminalDetailsError: action.payload,
        adminTerminalDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case ADMIN_TERMINALDETAILS.PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
      };
    default:
      return state;
  }
};
