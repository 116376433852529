export default (value, currency) => {
  if (!value) { return '0.00'; }
  // eslint-disable-next-line no-param-reassign
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  const num = prefix ? list[0].slice(1) : list[0];
  let result = new Intl.NumberFormat('en-US', {}).format(num);
  if (currency.countryAlpha2 === 'IN') {
    result = new Intl.NumberFormat('en-IN', {}).format(num);
  } else if (currency.countryAlpha2 === 'US') {
    result = new Intl.NumberFormat('en-US', {}).format(num);
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
};
