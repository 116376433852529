import { PAYMENTS } from '../actionTypes';

export const fetchPayments = (payload) => ({
  type: PAYMENTS.FETCH,
  payload,
});

export const refundPayment = (action) => ({
  type: PAYMENTS.REFUND,
  payload: action.payload,
});

export const setPaymentSelected = (action) => ({
  type: PAYMENTS.SELECTED,
  payload: action.payload,
});

export const updatePayment = (action) => ({
  type: PAYMENTS.UPDATE,
  payload: action.payload,
});
