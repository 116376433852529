import { POSKEY } from '../actionTypes';

export const fetchPoskey = (action) => ({
  type: POSKEY.FETCH,
  payload: action.payload,
});

export const updatePoskey = (action) => ({
  type: POSKEY.UPDATE,
  payload: action.payload,
});
