import { persistor } from 'store/configure';

import { AUTH } from '../actionTypes';

export const authenticateUserSuccess = (action) => ({
  type: AUTH.SUCCESS,
  payload: action.payload,
});

export const logout = () => {
  persistor.purge();
  return {
    type: AUTH.LOGOUT,
  };
};

export const unlock = () => {
  persistor.purge();
  return {
    type: AUTH.UNLOCK,
  };
};

export const customDashboardSucess = (action) => ({
  type: AUTH.CUSTOM_DASHBOARD_SUCCESS,
  payload: action.payload,
});
