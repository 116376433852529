import React from 'react';
import GitInfo from 'react-git-info/macro';
import styled from 'styled-components';

const gitInfo = GitInfo();

// $FlowFixMe
const Wrapper = styled.div`
  background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '24px 15px',
  alignItems: 'left',
  width: '511px',
  minHeight: '250px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

const Release = () => (
  <Wrapper>
    <FormWrapper>
      <h6 className="mb-3"><span className="font-weight-bold">Commit SHA</span>: {gitInfo.commit.hash}</h6>
      <h6><span className="font-weight-bold">Commit</span>: {gitInfo.commit.shortHash}</h6>
    </FormWrapper>
  </Wrapper>
);

export default Release;
