import { NOTIFICATION } from '../actionTypes';

export const setNotification = (action) => ({
  type: action.type,
  payload: action.payload,
});

export const resetNotification = () => ({
  type: NOTIFICATION.RESET,
});
