import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { Menu } from 'antd';
import { SpTab } from 'components/DesignKit';

import Catalog from './components/catalog';
import Order from './components/order';

type Props = {
  location: {
    pathname: string
  },
};

const AdminCatalog = (props: Props) => {
  const [current, setCurrent] = useState('adminCatalog');
  const { path } = useRouteMatch();
  const {
    location,
  } = props;

  const setMenu = (query) => {
    switch (query) {
      case '/catalog': {
        setCurrent('adminCatalog');
        break;
      }
      case '/catalog/adminorder': {
        setCurrent('adminOrder');
        break;
      }
      default:
        setCurrent('adminCatalog');
    }
  };

  useEffect(() => {
    const { pathname } = location;
    setMenu(pathname);
  }, [location]);

  return (
    <div>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
        <Menu.Item key="adminCatalog">
          <SpTab
            to={path}
            selected={current === 'adminCatalog'}
          >
            Catalog
          </SpTab>
        </Menu.Item>
        <Menu.Item key="adminOrder">
          <SpTab
            to={`${path}/adminorder`}
            selected={current === 'adminOrder'}
          >
            Order History
          </SpTab>
        </Menu.Item>
      </Menu>

      <Switch>
        <Route exact path={path} component={Catalog} />
        <Route path={`${path}/adminorder`} component={Order} />
      </Switch>
    </div>
  );
};

export default withRouter(AdminCatalog);
