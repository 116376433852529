import formatNumber from './formatNumber';

export default (taxRate, totalAmount, discountAmount, selectedAccount) => {
  const currencyObj = selectedAccount && selectedAccount.currency;
  let taxAmount;
  if (taxRate && taxRate.inclusive) {
    // tax caluclation for Iclusive type
    let amount;
    if (discountAmount) {
      // if User applied coupon subtracted discount amount from total amount
      amount = totalAmount - parseFloat(discountAmount.replace(/,/g, ''));
    } else if (!discountAmount) {
      // if user havn't applied any coupon total amount remain same
      amount = totalAmount;
    }
    const percent = (taxRate.percentage / 100) + 1;
    const amountTax = (amount / percent) * (taxRate.percentage / 100);
    taxAmount = amountTax.toFixed(2);
  } else if (taxRate && !taxRate.inclusive) {
    // tax caluclation for Exclusive type
    if (discountAmount) {
      // if User applied coupon subtracted discount amount from total amount
      const amount = totalAmount - parseFloat(discountAmount.replace(/,/g, ''));
      taxAmount = formatNumber(((amount * taxRate.percentage) / 100).toFixed(2), currencyObj);
    } else if (!discountAmount) {
      // if user havn't applied any coupon total amount remain same
      taxAmount = formatNumber(((totalAmount / 100) * taxRate.percentage).toFixed(2), currencyObj);
    }
  }
  return taxAmount;
};
