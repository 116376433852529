import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  Card,
  Row,
  Col,
  Tooltip,
  Icon,
  Select,
} from 'antd';
import styled from 'styled-components';
import {
  SpText,
  SpH5,
  SpButton,
  SpForm,
} from 'components/DesignKit';
import {
  PAGES,
  ORDERS_STATUSES,
  ORDERS_STATUSES_ENUM,
} from 'appconstants';
import {
  wooCommerceDetailsActions,
} from 'store/actions';
import PaymentTimeline from 'components/PaymentTimeline';
import getOrdersBackground from 'utils//getOrdersBackground';
import getOrderStatus from 'utils/getOrderStatus';
import formatAmount from 'utils/formatAmount';
import CancelIcon from 'components/NavIcons/CanceledIcon';
import UpdateIcon from 'components/NavIcons/UpdatedIcon';

const { Option } = Select;

type Props = {
  history: {
    push: Function,
    location: Object,
  };
  fetchWooCommerceOrderDetails: Function,
  selectedAccount: Object,
  test: Boolean,
  orderDetails: Object,
  fetchWooCommerceOrderTimeline: Function,
  orderTimeline: Object,
  updateWooCommerceOrderStatus: Function,
};

// $FlowFixMe
const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  height: '21px',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

const WooCommerceOrderDetails = (props: Props) => {
  const {
    history,
    fetchWooCommerceOrderDetails,
    selectedAccount,
    test,
    orderDetails,
    fetchWooCommerceOrderTimeline,
    orderTimeline,
    updateWooCommerceOrderStatus,
  } = props;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const [showEdit, setShowEdit] = useState(false);
  const [status, setStatus] = useState();
  // eslint-disable-next-line react/prop-types
  const { gid } = props.match.params;
  // eslint-disable-next-line react/prop-types
  const gidPath = gid.split('-')[1];
  const [paymentItemList, setPaymentItemList] = useState([]);

  const getData = () => {
    fetchWooCommerceOrderDetails(gid);
    fetchWooCommerceOrderTimeline(gid);
  };

  useEffect(() => {
    if (gid) {
      getData();
    }
  }, [test, selectedAccount]);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.INTEGRATIONS);
    }
  }, []);

  useEffect(() => {
    if (orderDetails && orderDetails.gid) {
      setStatus(orderDetails.spOrderStatus);
      const lineItems = orderDetails.wcOrderLineItems;
      setPaymentItemList([...lineItems]);
    }
  }, [orderDetails]);

  const updateStatus = (val) => {
    const params = { spOrderStatus: val };
    updateWooCommerceOrderStatus({ gid, params });
  };

  return (
    <>
      {
        orderDetails && orderDetails.gid && (
          <>
            <Card>
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">
                    {orderDetails.wcOrderId || <>&#8211;</>}
                  </SpText>
                  <StatusTag style={{ backgroundColor: getOrdersBackground(orderDetails.spOrderStatus) }}>
                    {getOrderStatus(orderDetails.spOrderStatus)}
                  </StatusTag>
                </Col>
              </Row>
              <SpForm>
                <Row type="flex">
                  <Col span={4}>
                    <div>
                      <span>Amount</span>
                    </div>
                    <div>
                      {
                        orderDetails.paymentSession && orderDetails.paymentSession.amount ? (
                          <SpText fontSize="18px" fontWeight="600">
                            {/* eslint-disable-next-line max-len */}
                            {prefix} {formatAmount(orderDetails.paymentSession && ((orderDetails.paymentSession.amount) / 100), selectedAccount.currency)}
                          </SpText>
                        ) : <>&#8211;</>
                      }
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>
                      <span className="mr-3">Date</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        {
                          orderDetails.paymentSession && orderDetails.paymentSession.paymentDate && selectedAccount && selectedAccount.timezone
                            ? (
                              moment
                                .utc(orderDetails.paymentSession.paymentDate)
                                .tz(selectedAccount.timezone)
                                .format('MMM DD, YYYY, hh:mm a')
                            )
                            : <>&#8211;</>
                        }
                      </SpText>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>
                      <span>Payment Method</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        {(orderDetails.paymentSession && orderDetails.paymentSession.method)
                          ? orderDetails.paymentSession.method : <>&#8211;</>}
                      </SpText>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>
                      <span>Receipt Number</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        {(orderDetails.paymentSession && orderDetails.paymentSession.receiptNumber)
                          ? orderDetails.paymentSession.receiptNumber : <>&#8211;</>}
                      </SpText>
                    </div>
                  </Col>

                </Row>
              </SpForm>
              <hr />
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Status</span>
                  </Col>
                  {!showEdit && (
                    <Col span={6}>
                      <SpH5>{getOrderStatus(orderDetails.spOrderStatus)}</SpH5>
                      {
                        orderDetails.paymentSession && orderDetails.paymentSession.length !== 0 ? (
                          <Tooltip placement="top" title="Edit">
                            <Icon
                              type="edit"
                              className="ml-3"
                              style={{ fontSize: '20px', cursor: 'pointer' }}
                              onClick={() => { setShowEdit(!showEdit); }}
                            />
                          </Tooltip>
                        ) : null
                      }
                    </Col>
                  )}
                  {showEdit && (
                    <Col span={6}>
                      <div className="w-85 mt-2 d-flex">
                        <Select
                          className="ml-4"
                          defaultValue={status}
                          style={{ width: 160 }}
                          bordered
                          onChange={(value) => setStatus(value)}
                        >
                          <Option value={ORDERS_STATUSES_ENUM.OPEN}>{ORDERS_STATUSES.OPEN}</Option>
                          <Option value={ORDERS_STATUSES_ENUM.IN_PROGRESS}>{ORDERS_STATUSES.IN_PROGRESS}</Option>
                          <Option value={ORDERS_STATUSES_ENUM.CLOSED}>{ORDERS_STATUSES.CLOSED}</Option>
                          <Option value={ORDERS_STATUSES_ENUM.CANCELLED}>{ORDERS_STATUSES.CANCELLED}</Option>
                        </Select>
                        <SpButton
                          className="ml-2 pl-2 pr-2"
                          type="primary"
                          borderRadius="none"
                          onClick={() => {
                            setShowEdit(!showEdit);
                            updateStatus(status);
                          }}
                        >
                          <UpdateIcon />
                        </SpButton>
                        <SpButton
                          className="ml-2 pl-2 pr-2"
                          type="secondary"
                          borderRadius="none"
                          style={{ borderColor: '#C4C4C4' }}
                          onClick={() => {
                            setShowEdit(!showEdit);
                          }}
                        >
                          <CancelIcon />
                        </SpButton>
                      </div>
                    </Col>
                  )}
                </Row>
                {
                  (paymentItemList && paymentItemList.length !== 0) ? paymentItemList.map((item) => (
                    <>
                      {
                        (item.quantity !== '0') && (
                          <>
                            <Row type="flex" className="pt-1 pb-1">
                              <Col span={4}>
                                <div>
                                  {item.name} X {item.quantity}
                                </div>
                              </Col>
                              <Col span={9}>
                                <SpH5>
                                  {prefix}
                                  {
                                    (item.price && item.quantity) ? (
                                      formatAmount((parseFloat(item.price)) * item.quantity, selectedAccount.currency)
                                    ) : (item.price) ? (
                                      formatAmount((parseFloat(item.price)) * 1, selectedAccount.currency)
                                    ) : '0.00'
                                  }
                                </SpH5>
                              </Col>
                            </Row>
                          </>
                        )
                      }
                    </>
                  )) : null
                }
                {
                  (orderDetails.total && parseFloat(orderDetails.total) !== 0) ? (
                    <>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <div>Sub Total</div>
                        </Col>
                        <Col span={9}>
                          <div>
                            {prefix} {
                              orderDetails.total && orderDetails.totalTax ? (
                                <SpH5>
                                  {formatAmount((parseFloat(orderDetails.total) - parseFloat(orderDetails.totalTax)), selectedAccount.currency)}
                                </SpH5>
                              ) : (
                                <SpH5>
                                  {formatAmount((parseFloat(orderDetails.total)), selectedAccount.currency)}
                                </SpH5>
                              )
                            }
                          </div>
                        </Col>
                      </Row>
                    </>

                  ) : (
                    <></>
                  )
                }
                {
                  (orderDetails.feeLines) && ((orderDetails.feeLines).length !== 0) ? (
                    <>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <div>Tip</div>
                        </Col>
                        <Col span={9}>
                          <div>
                            {prefix} {
                              orderDetails.feeLines[0] && orderDetails.feeLines[0].total
                                ? (
                                  <SpH5>
                                    {formatAmount((parseFloat(orderDetails.feeLines[0].total)), selectedAccount.currency)}
                                  </SpH5>
                                ) : <SpH5>0.00</SpH5>
                            }
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )
                }
                <Row type="flex" justify="end">
                  <Col>
                    <SpH5>
                      {gid}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
            </Card>
            <Card className="mb-3 mt-3">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">TIMELINE</SpText>
                </Col>
              </Row>
              <Row className="pl-3">
                <PaymentTimeline
                  paymentTimeline={orderTimeline}
                  selectedAccount={selectedAccount}
                  wooCommerceOrder
                />
              </Row>
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  orderDetails: state.wooCommerceDetails,
  orderTimeline: state.wooCommerceDetails.orderTimeline,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  splitUp: state.paymentPagesDetails.splitUp,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWooCommerceOrderDetails: gid => dispatch(wooCommerceDetailsActions.fetchWooCommerceOrderDetails({
    payload: gid,
  })),
  fetchWooCommerceOrderTimeline: gid => dispatch(wooCommerceDetailsActions.fetchWooCommerceOrderTimeline({
    payload: gid,
  })),
  updateWooCommerceOrderStatus: param => dispatch(wooCommerceDetailsActions.updateWooCommerceOrderStatus({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(WooCommerceOrderDetails);
