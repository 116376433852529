/* eslint-disable */
import React from 'react';

const transfers = ({ fill = "silver" }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.65 0C0.73873 0 0 0.73873 0 1.65V7.65C0 8.56127 0.73873 9.3 1.65 9.3H3.56607L3.56607 8H3.40389C3.10049 6.94514 2.31435 6.09464 1.3 5.70304V3.23717C2.19623 2.89117 2.91431 2.18693 3.27867 1.3H9.94182C10.2751 2.11133 10.9044 2.7698 11.6953 3.141V5.79921C11.0562 6.0992 10.5225 6.5868 10.1654 7.19107L12.467 8.86008C12.792 8.55873 12.9953 8.12811 12.9953 7.65V1.65C12.9953 0.73873 12.2566 0 11.3453 0H1.65Z" fill={fill} />
    <path d="M7.99768 4.65002C7.99768 5.47845 7.32611 6.15002 6.49768 6.15002C5.66925 6.15002 4.99768 5.47845 4.99768 4.65002C4.99768 3.8216 5.66925 3.15002 6.49768 3.15002C7.32611 3.15002 7.99768 3.8216 7.99768 4.65002Z" fill={fill} />
    <path d="M12.3687 10.4636L8.84762 12.9938V12.0859H6.9977V8.8414H8.84762V7.93347L12.3687 10.4636Z" fill={fill} />
    <path d="M4.90283 8.8414V12.0859H6.19158V8.8414H4.90283Z" fill={fill} />
  </svg>
);

export default transfers;
