import { SUBSCRIPTION_BUTTON_DISPLAY_STATUS } from 'appconstants';

export default value => {
  if (value === 'ACTIVE') {
    return SUBSCRIPTION_BUTTON_DISPLAY_STATUS.ACTIVE;
  } if (value === 'EXPIRED') {
    return SUBSCRIPTION_BUTTON_DISPLAY_STATUS.EXPIRED;
  } if (value === 'DEACTIVATED') {
    return SUBSCRIPTION_BUTTON_DISPLAY_STATUS.DEACTIVE;
  } return SUBSCRIPTION_BUTTON_DISPLAY_STATUS.ACTIVE;
};
