import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { paymentPagesDetailsActions } from 'store/actions';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  Card, Col, Row, List,
} from 'antd';

import { SpH5, SpText } from 'components/DesignKit';
import formatNumber from 'utils/formatNumber';
import { DownloadOutlined, FileOutlined } from '@ant-design/icons';

// Define the Props type if you are using TypeScript
type Props = {
  fetchPaymentPagesEntries: Function,
  test: boolean,
  selectedAccount: Object,
  paymentPagesEntries: Array<Object>,



};

const PaymentPagesEntries = (props: Props) => {
  const {
    fetchPaymentPagesEntries,
    test,
    selectedAccount,
    paymentPagesEntries,
  } = props;
  const { gid } = props.match.params;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;

  const location = useLocation();
  const getData = (query) => {
    const parsed = queryString.parse(query);

    const sortBy = parsed.sortBy || 'createdAt';

    const currentPage = parsed.pageNum || 1;
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchPaymentPagesEntries({ currentPage, sortParams, gid });
  };
  useEffect(() => {
    getData();
  }, [location, test, selectedAccount]);
  // Replace `find` with `filter` to handle multiple 'FILE_UPLOAD' entries in `selectedPaymentPagesFileDetails`
  // eslint-disable-next-line max-len
  const selectedPaymentPagesFileDetails = paymentPagesEntries && paymentPagesEntries?.paymentPageEntries?.find((entries) => entries.type === 'FILE_UPLOAD');

  return (
    <>
      <Card>
        <Row type="flex" justify="start" className="m-3">
          <Col span={4}>
            <span>Name</span>
          </Col>
          <Col span={9}>
            <SpH5>{(paymentPagesEntries && paymentPagesEntries.name)
              ? (paymentPagesEntries.name === ' ' ? <>&#8211;</> : paymentPagesEntries.name) : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" className="m-3">
          <Col span={4}>
            <span>Email</span>
          </Col>
          <Col span={9}>
            <SpH5>{(paymentPagesEntries && paymentPagesEntries.email)
              ? (paymentPagesEntries.email === ' ' ? <>&#8211;</> : paymentPagesEntries.email) : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" className="m-3">
          <Col span={4}>
            <span>Phone</span>
          </Col>
          <Col span={9}>
            <SpH5>{(paymentPagesEntries && paymentPagesEntries.phone)
              ? (paymentPagesEntries.phone === ' ' ? <>&#8211;</> : paymentPagesEntries.phone) : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" className="m-3">
          <Col span={4}>
            <span>Subscription</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {paymentPagesEntries && paymentPagesEntries.amount
                ? paymentPagesEntries.amount === ''
                  ? <>&#8211;</>
                  : `${prefix}${formatNumber((paymentPagesEntries.amount / 100).toFixed(2), currencyType)}`
                : <>&#8211;</>
              }
            </SpH5>

          </Col>
        </Row>
      </Card>
      <Card className="mt-3">
        <Row type="flex" justify="start" className="m-3">
          <Col span={4}>
            <SpText className="text-uppercase mb-3" fontSize="20px">Documents</SpText>
          </Col>
        </Row>
        <Row type="flex" justify="start" className="m-3">
          <Col span={4}>
            <span>Name</span>
          </Col>
          <Col span={9}>
            <SpH5>{(selectedPaymentPagesFileDetails && selectedPaymentPagesFileDetails.label)
              ? (selectedPaymentPagesFileDetails.label === ' ' ? <>&#8211;</> : selectedPaymentPagesFileDetails.label) : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" className="m-3">
          <Col span={4}>
            <span>Description</span>
          </Col>
          <Col span={9}>
            <SpH5>{(selectedPaymentPagesFileDetails && selectedPaymentPagesFileDetails.description)
              ? (selectedPaymentPagesFileDetails.description === ' ' ? <>&#8211;</> : selectedPaymentPagesFileDetails.description) : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" className="m-3">
          <Col span={4}>
            <span>Attachments</span>
          </Col>
          <Col span={9}>
            {selectedPaymentPagesFileDetails && selectedPaymentPagesFileDetails.documentUrls ? (
              <List
                bordered
                dataSource={selectedPaymentPagesFileDetails.documentUrls}
                renderItem={(url, index) => (
                  <List.Item style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FileOutlined style={{ color: '#3E8BFF', fontSize: '1rem', marginRight: '1rem' }} />
                      <span>Document {index + 1}</span>
                    </div>
                    <DownloadOutlined
                      style={{ fontSize: '1rem', cursor: 'pointer', color: '#000' }}
                      onClick={() => window.open(url, '_blank')}
                    />
                  </List.Item>
                )}
              />
            ) : (
              <span>&#8211;</span> // Use <span> for better semantics
            )}

          </Col>
        </Row>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  paymentPagesEntries: state.paymentPagesDetails.paymentPagesEntries,
  selectedAccount: state.account.selectedAccount,

});

const mapDispatchToProps = (dispatch) => ({

  fetchPaymentPagesEntries: param => dispatch(paymentPagesDetailsActions.fetchPaymentPagesEntries({
    payload: param,
  })),

});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PaymentPagesEntries);
