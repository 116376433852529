import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import {
  Card,
  Icon,
  Row,
  Col,
  Select,
  Input,
  Switch,
  Tooltip,
  Badge,
  Table,
} from 'antd';

import queryString from 'query-string';

import {
  SpButton,
  SpError,
  SpH5,
  SpText,
  FilterWrapper,
  SpStatusTag,
} from 'components/DesignKit';
import getScheduleReportBackground from 'utils/getScheduleReportBackground';
import getScheduleReportStatus from 'utils/getScheduleReportStatus';
import SimpleReactValidator from 'simple-react-validator';

import {
  SCHEDULE_REPORTS_TYPES,
  SCHEDULE_REPORTS_PERIOD,
  PAGES,
  ROLES,
  FILTERS_TYPES,
  MESSAGES,
  TOKENS,
} from 'appconstants';

import {
  scheduleReportsActions,
  notificationActions,
  accountActions,
  userActions,
} from 'store/actions';

import { NOTIFICATION } from 'store/actionTypes';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import moment from 'moment-timezone';
import getFilterOperators from 'utils/getFilterOperators';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';

import Filters from 'components/Filters';
import Loading from 'components/Loading';
import RBAC from 'components/rbac';

import DeleteScheduleReport from './components/DeleteScheduleReport';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const SCHEDULE_REPORTS_FILTERS_TYPES = FILTERS_TYPES.SCHEDULE_REPORTS;


const { Option } = Select;

type Props = {
  fetchScheduleReports: Function,
  addScheduleReport: Function,
  deleteScheduleReport: Function,
  setNotification: Function,
  totalElements: number,
  reports: Array<Object>,
  loading: boolean,
  test: boolean,
  submitting: boolean,
  validator: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  setSelectedAccount: Function,
  updateUserAccount: Function,
  role: Object,
};

const ScheduleReport = (props: Props) => {
  const {
    loading,
    fetchScheduleReports,
    addScheduleReport,
    setNotification,
    deleteScheduleReport,
    reports,
    totalElements,
    submitting,
    test,
    validator,
    selectedAccount,
    history,
    setSelectedAccount,
    updateUserAccount,
    role,
  } = props;

  const location = useLocation();
  const {
    filteredList,
    accounts,
  } = useSelector(state => state.account);
  const [, forceUpdate] = useState();
  const [pagination, setPagination] = useState({});
  const [reportType, setReportType] = useState();
  const [reportPeriod, setReportPeriod] = useState();
  const [addRecipient, setAddRecipient] = useState(['']);
  const [notify, setNotify] = useState(false);
  const [disable, setDisable] = useState(false);
  const [url, setUrl] = useState();
  const [secret, setSecret] = useState();
  const myRef = useRef();
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(SCHEDULE_REPORTS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterScheduleReports, setfilterScheduleReports] = useState([{
    filterType: '',
    filterName: '',
    filterOperator: '',
    filterValue: '',
    filterNameDisplay: '',
    filterValueFrom: '',
    filterValueTo: '',
    checked: true,
  }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [deleteScheduleReportModal, setDeleteScheduleReportModal] = useState(false);
  const accntGid = selectedAccount && selectedAccount.gid;

  const simpleValidator = useRef(new SimpleReactValidator({
    autoForceUpdate: {
      forceUpdate,
    },
    validators: {
      validUrl: {
        message: 'The URL must be valid',
        // eslint-disable-next-line max-len
        rule: val => (new RegExp('^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$').exec(val) !== null),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    fetchScheduleReports({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData(location.search);
  }, []);

  const getReport = async (reportGid) => {
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.DOWNLOAD_SCHEDULE_REPORTS}/${reportGid}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.SCHEDULE_REPORTS.DOWNLOAD_ERROR,
      });
    } finally {
      history.push(PAGES.DOWNLOAD_SCHEDULE_REPORTS);
    }
  };

  const switchAccountAndGetReport = async (gid, reportGid) => {
    try {
      // This call is happennig in Partnerlist component as well.
      // any change you make here please make sure to change in Partnerlist component as well.
      if ((role && role.name) === ROLES.SUPERUSER) {
        const API_END_POINT = `/internal${API_END_POINTS.JWE}/${gid}/switch?isTest=false`;
        const { data: { entity: { token: { accessToken } } } } = await axiosAuthInstance.get(API_END_POINT);
        localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
        setSelectedAccount(filteredList.content.find(a => a.gid === gid));
        updateUserAccount(gid);
      } else {
        const API_END_POINT = `${API_END_POINTS.JWE}/${gid}/switch?isTest=false`;
        const { data: { entity: { token: { accessToken } } } } = await axiosAuthInstance.get(API_END_POINT);
        localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
        // update selectedAccount
        setSelectedAccount(accounts.content.find(a => a.gid === gid));
        updateUserAccount(gid);
      }
      getReport(reportGid);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const reportGid = parsed['scheduled-report-attempt-gid'];
    const accountGid = parsed['account-gid'];
    if (reportGid) {
      if (accountGid && (accountGid === accntGid)) {
        getReport(reportGid);
      } else if (accountGid && (accountGid !== accntGid)) {
        switchAccountAndGetReport(accountGid, reportGid);
      } else {
        getReport(reportGid);
      }
    }
  }, [location]);

  const exportData = reports ? reports.map((values) => ({
    type: values.type,
    frequency: values.frequency,
    lastGeneratedAt: values.lastGeneratedAt ? moment
      .utc(values.lastGeneratedAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a') : '',
    status: getScheduleReportStatus(values.status),
  })) : '';

  const exportHeaders = [
    { label: 'Report Type', key: 'type' },
    { label: 'Period', key: 'frequency' },
    { label: 'Last Processed', key: 'lastGeneratedAt' },
    { label: 'Status', key: 'status' },
  ];

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && (e.toElement && e.toElement.className !== 'ant-calendar-date')
      && (e.toElement && e.toElement.className !== 'ant-calendar-prev-month-btn')
      && (e.toElement && e.toElement.className !== 'ant-calendar-next-month-btn')) {
      setOpen(false);
    }
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);


  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [reports]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addNewRecipient = (event) => {
    event.preventDefault();
    setAddRecipient([...addRecipient, '']);
  };
  const handleRemoveEmail = (i) => {
    const list = [...addRecipient];
    list.splice(i, 1);
    setAddRecipient(list);
    const hasNoEmails = (list.length === 0);
    if (hasNoEmails) {
      simpleValidator.current.purgeFields();
    }
  };

  const generateScheduleReport = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    let notification;
    if (addRecipient.length !== 0 && notify) {
      notification = 'BOTH';
    } else if (notify) {
      notification = 'URI';
    } else if (addRecipient.length > 0) {
      notification = 'EMAIL';
    }

    const params = {
      type: reportType,
      notification,
      frequency: reportPeriod,
      recipientList: addRecipient,
      secret,
      uri: url,
    };
    simpleValidator.current.hideMessages();
    addScheduleReport({ params });
  };

  const showScheduleReportModal = (value: Object) => {
    setSelectedReport(value);
    setDeleteScheduleReportModal(true);
  };

  const deleteSelectedReport = (gid: string) => {
    deleteScheduleReport({ gid });
  };

  useEffect(() => {
    if (!validator) {
      setDeleteScheduleReportModal(false);
      setReportType();
      setReportPeriod();
      setAddRecipient(['']);
      setNotify(false);
      setUrl();
      setSecret();
    }
  }, [validator]);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.DOWNLOAD_SCHEDULE_REPORTS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.DOWNLOAD_SCHEDULE_REPORTS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterScheduleReports];
    if (filterScheduleReports.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = SCHEDULE_REPORTS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setfilterScheduleReports(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterScheduleReports];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = SCHEDULE_REPORTS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setfilterScheduleReports(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterScheduleReports];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setfilterScheduleReports(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setfilterScheduleReports(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterScheduleReports];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setfilterScheduleReports(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setfilterScheduleReports(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterScheduleReports[index].filterName === 'createdAt') {
      if (filterScheduleReports[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterScheduleReports[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterScheduleReports[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterScheduleReports[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterScheduleReports];
    list[index].filterValue = selectedValue;
    setfilterScheduleReports(list);
  };

  const changeNotifyType = (event) => {
    setNotify(event);
    if (!event) {
      simpleValidator.current.purgeFields();
    }
  };

  const handleOperator = (e, index) => {
    const list = [...filterScheduleReports];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterScheduleReports[index].filterValue;
        handleValue(value, index);
      }
    }
    setfilterScheduleReports(list);
  };

  const handleAddClick = () => {
    const list = [...filterScheduleReports];
    setfilterScheduleReports([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setfilterScheduleReports([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(SCHEDULE_REPORTS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.DOWNLOAD_SCHEDULE_REPORTS);
  };

  const applyFilters = () => {
    const paymentsFilters = filterScheduleReports.filter((item) => item.checked);
    for (let i = 0; i < paymentsFilters.length; i += 1) {
      if (!paymentsFilters[i].filterName || !paymentsFilters[i].filterOperator || !paymentsFilters[i].filterValue) {
        return;
      }
    }
    if (paymentsFilters[0].filterValue || (paymentsFilters[0].filterValueFrom && paymentsFilters[0].filterValueTo)) {
      setCount(paymentsFilters && paymentsFilters.length);
      for (let i = 0; i < paymentsFilters.length; i += 1) {
        if (paymentsFilters[i].checked) {
          if (paymentsFilters[i].filterName === 'createdAt') {
            paymentsFilters[i] = paymentsFilters[i].filterValue;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else if (paymentsFilters[i].filterName === 'email') {
            const email = encodeURIComponent(paymentsFilters[i].filterValue);
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${email}`;
          } else {
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${paymentsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(paymentsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        history.push({
          pathname: PAGES.DOWNLOAD_SCHEDULE_REPORTS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        history.push({
          pathname: PAGES.DOWNLOAD_SCHEDULE_REPORTS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const columns = [
    {
      title: 'Report Type',
      dataIndex: 'type',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {name}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Period',
      dataIndex: 'frequency',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (email) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {email}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Last Processed',
      dataIndex: 'lastGeneratedAt',
      width: '20%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (lastGeneratedAt) => {
        if (lastGeneratedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(lastGeneratedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <SpStatusTag style={{ backgroundColor: getScheduleReportBackground(status) }}>
          {getScheduleReportStatus(status)}
        </SpStatusTag>
      ),
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'center',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showScheduleReportModal(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      {
        test
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <SpText fontSize="20px">Schedule Reports are only available for live data</SpText>
              </div>
            </Card>
          )
          : (
            <Card>
              <RBAC
                permit={
                  [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.ANALYST,
                    ROLES.OPERATIONS, ROLES.DEVELOPER, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
                }
              >
                <Row className="my-2 mb-3">
                  <Col>
                    <SpText fontSize="20px">Generate Report</SpText>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <SpH5 fontSize="20px">Report Type</SpH5>
                  </Col>
                </Row>
                <Row className="mb-3 mt-3">
                  <Col span={7}>
                    <Select
                      className="w-100"
                      placeholder="Select Report Type"
                      onChange={value => setReportType(value)}
                      value={reportType}
                    >
                      {
                        SCHEDULE_REPORTS_TYPES.map(report => (
                          <Option
                            key={1}
                            value={report.value}
                            className="text-capitalize"
                          >
                            {report.display}
                          </Option>
                        ))
                      }
                    </Select>
                    <SpError>
                      {simpleValidator.current.message('Report Type', reportType, 'required')}
                    </SpError>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <SpH5 fontSize="20px">Period</SpH5>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col span={7}>
                    <Select
                      className="w-100"
                      placeholder="Select Report Period"
                      onChange={value => setReportPeriod(value)}
                      value={reportPeriod}
                    >
                      {
                        SCHEDULE_REPORTS_PERIOD.map(report => (
                          <Option
                            key={1}
                            value={report.value}
                          >
                            {report.display}
                          </Option>
                        ))
                      }
                    </Select>
                    <SpError>
                      {simpleValidator.current.message('Report Period', reportPeriod, 'required')}
                    </SpError>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <SpH5 fontSize="20px">Email Recipient List</SpH5>
                  </Col>
                </Row>
                {addRecipient.map((item, i) => (
                  <div className="mb-3">
                    <Row>
                      <Col span={7}>
                        <Input
                          value={item}
                          key={`Email${i}`}
                          onChange={(e) => {
                            const list = [...addRecipient];
                            list[i] = e.currentTarget.value;
                            setAddRecipient(list);
                          }}
                          placeholder="Email"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} className="d-flex justify-content-end">
                        <Icon
                          type="minus-circle"
                          style={{
                            cursor: 'pointer', color: '#ff4d50', marginTop: '-25px', marginRight: '25px',
                          }}
                          onClick={() => handleRemoveEmail(i)}
                        />
                      </Col>
                    </Row>
                    <SpError className="mb-4">
                      {simpleValidator.current.message('email', item, 'required|email|checkLowerCase',
                        { messages: { email: 'The email must be valid' } })}
                    </SpError>
                  </div>
                ))}
                <Row className="mt-3 mb-3">
                  <Col style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={addNewRecipient}
                    >
                      Add New Recipient
                    </SpText>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <SpH5 fontSize="20px">Webhooks</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Switch
                      className="mr-2"
                      checked={notify}
                      onChange={changeNotifyType}
                    /> Enable webhook based report notifications
                  </Col>
                </Row>
                {
                  notify && (
                    <>
                      <Row className="mb-3">
                        <Col>
                          <SpH5 fontSize="20px">URL</SpH5>
                        </Col>
                      </Row>
                      <Row className="mt-3 mb-3">
                        <Col span={7}>
                          <Input
                            placeholder="http://www.example.com"
                            value={url}
                            onChange={(e) => setUrl(e.currentTarget.value)}
                          />
                          <SpError>
                            {simpleValidator.current.message('url', url, 'required|validUrl',
                              { messages: { required: 'The URL field is required' } })}
                          </SpError>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <SpH5 fontSize="20px">Secret</SpH5>
                        </Col>
                      </Row>
                      <Row className="mt-3 mb-3">
                        <Col span={7}>
                          <Input
                            placeholder="Secret"
                            type="password"
                            value={secret}
                            disabled={disable}
                            autoComplete="new-password"
                            onChange={(e) => setSecret(e.currentTarget.value)}
                            suffix={<Icon onClick={() => setDisable(!disable)} type={!disable ? 'unlock' : 'lock'} />}
                          />
                          <SpError>
                            {simpleValidator.current.message('Secret', secret, 'required')}
                          </SpError>
                        </Col>
                      </Row>
                    </>
                  )
                }
                <Row className="mt-3 mb-3">
                  <Col>
                    <SpButton
                      className="mr-4"
                      type="primary"
                      shape="round"
                      onClick={generateScheduleReport}
                    >
                      Add Schedule Report
                    </SpButton>
                  </Col>
                </Row>
              </RBAC>
              <>
                <Row type="flex" justify="space-between" className="mb-4 mt-5">
                  <Col>
                    <SpText className="mb-3" fontSize="20px">Schedule Reports</SpText>
                  </Col>
                  <Col>
                    <span>
                      {
                        exportData !== null
                          ? (
                            <Tooltip placement="left" title="Click here to export Schedule Reports">
                              <CSVLink
                                target="_blank"
                                className="mr-3"
                                filename="Schedule Reports.csv"
                                data={exportData}
                                headers={exportHeaders}
                                onMouseEnter={() => setFillColor(true)}
                                onMouseLeave={() => setFillColor(false)}
                              >
                                <ExportIcon
                                  fill={fillColor
                                    ? '#279dfe'
                                    : '#C0C0C0'}
                                />
                              </CSVLink>
                            </Tooltip>
                          ) : null
                      }
                    </span>
                    <span ref={myRef}>
                      <span
                        onClick={() => setOpen(!open)}
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={() => setFilterColor(true)}
                        onMouseLeave={() => setFilterColor(false)}
                      >
                        {open && (
                          <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                            <FilterIcon
                              fill={filterColor
                                ? '#279dfe'
                                : '#C0C0C0'}
                            />
                          </Badge>
                        )}
                        {!open && (
                          <Tooltip placement="left" title="Click here to filter Reports">
                            <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                              <FilterIcon
                                fill={filterColor
                                  ? '#279dfe'
                                  : '#C0C0C0'}
                              />
                            </Badge>
                          </Tooltip>
                        )}
                      </span>
                      {open && (
                        <FilterWrapper
                          style={{ marginLeft: '-264px' }}
                        >
                          <Filters
                            filtersGrid={filterScheduleReports}
                            filterTypes={filterTypes}
                            handleChange={handleChange}
                            onCheck={onCheck}
                            fiterOperators={fiterOperators}
                            handleOperator={handleOperator}
                            handleValue={handleValue}
                            startDate={startDate}
                            endDate={endDate}
                            currentDate={currentDate}
                            handleFromValue={handleFromValue}
                            handleToValue={handleToValue}
                          />
                          <div className="px-4 mt-2">
                            {
                              filterScheduleReports.length < SCHEDULE_REPORTS_FILTERS_TYPES.length && (
                                <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                                  <SpText
                                    fontWeight="600"
                                    color="#279dfe"
                                    onClick={handleAddClick}
                                  >
                                    +&nbsp; Add another filter
                                  </SpText>
                                </div>
                              )
                            }
                          </div>
                          <div className="d-flex mb-2 justify-content-end mr-3">
                            <SpButton
                              type="secondary"
                              shape="round"
                              className="mr-2"
                              ghost
                              onClick={resetFilters}
                            >
                              Clear
                            </SpButton>
                            <SpButton
                              type="primary"
                              shape="round"
                              onClick={applyFilters}
                            >
                              Save
                            </SpButton>
                          </div>
                        </FilterWrapper>
                      )}
                    </span>
                  </Col>
                </Row>
                {/* DELETE SCHEDULE REPORT MODAL */}
                {deleteScheduleReportModal && (
                  <DeleteScheduleReport
                    visible={deleteScheduleReportModal}
                    selectedReport={selectedReport}
                    submitting={submitting}
                    close={() => setDeleteScheduleReportModal(false)}
                    submit={deleteSelectedReport}
                  />
                )}
                <Table
                  columns={[...columns, actionColumn]}
                  dataSource={reports}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </>
            </Card>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  reports: state.scheduleReports.content,
  user: state.user,
  test: state.account.test,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  totalElements: state.scheduleReports.totalElements,
  selectedAccount: state.account.selectedAccount,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
});

const mapDispatchToProps = (dispatch) => ({
  fetchScheduleReports: param => dispatch(scheduleReportsActions.fetchScheduleReports({
    payload: param,
  })),
  addScheduleReport: param => dispatch(scheduleReportsActions.addScheduleReport({
    payload: param,
  })),
  deleteScheduleReport: param => dispatch(scheduleReportsActions.deleteScheduleReport({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setSelectedAccount: account => dispatch(accountActions.setSelectedAccount(account)),
  updateUserAccount: account => dispatch(userActions.updateUserAccount(account)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleReport);
