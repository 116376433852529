import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';

import {
  Row,
  Col,
  Input,
  Select,
  Radio,
  DatePicker,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';

import {
  SpForm, SpError, SpTextMute, SpCaption,
} from 'components/DesignKit';

import {
  validators,
} from 'utils/validationMessages';

import { API_END_POINTS } from 'api';

import { MESSAGES, COUNTRY_CODES, PHONE_LENGTH } from 'appconstants';

import formatAmount from 'utils/formatAmount';

import LockClose from 'components/NavIcons/lockClose';
import LockOpen from 'components/NavIcons/lockOpen';

import KYCFileUpload from 'components/KycFileUpload';

const { Option } = Select;
const dateFormat = 'MMM DD, YYYY';

type Props = {
  business: Array<Object>,
  statesInCountry: Array<Object>,
  country: string,
  isIndia: boolean,
  selectedAccount: Object,
  businessTypes: Array<Object>,
  businessCategories: Array<Object>,
  internalRole: Object,
}

const EditBusinessDetails = (props: Props, ref) => {
  const {
    isIndia,
    statesInCountry,
    country,
    business,
    selectedAccount,
    businessTypes,
    businessCategories,
    internalRole,
  } = props;

  const {
    name,
    type,
    category,
    url,
    statementDescriptor,
    address,
    businessPhoneNumber,
  } = business;

  const BUSINESS_VALUES = ['PRIVATE_LIMITED', 'PUBLIC_LIMITED', 'NONPROFIT_ORGANIZATION'];

  const getCountryCode = () => {
    if (businessPhoneNumber) {
      return businessPhoneNumber.substring(0, 2) === '+1' ? '+1' : '+91';
    }
    return isIndia ? '+91' : '+1';
  };

  const [, forceUpdate] = useState();
  const [businessName, setBusinessName] = useState(name);
  const [businessType, setBusinessType] = useState(type);
  const [businessCategory, setBusinessCategory] = useState(category && category.id);
  const [businessUrl, setBusinessUrl] = useState(url);
  const [businessPan, setBusinessPan] = useState(business.businessPan);
  const [llp, setLlp] = useState(business.type === 'LIMITED_LIABILITY_PARTNERSHIP' ? business.llpin : '');
  const [cin, setCin] = useState(business.type !== 'LIMITED_LIABILITY_PARTNERSHIP' ? business.cin : '');
  const [gst, setGst] = useState(business.gst);
  const [tan, setTan] = useState(business.tan);
  const [employerIdNumber, setEmployerIdNumber] = useState(!internalRole && business.ein ? null : business.ein);
  const [registrationProofUrl, setRegistrationProofUrl] = useState(business.kycRegistrationProofUrl);
  const [panUrl, setPanUrl] = useState(business.kycCompanyPanUrl);
  const [streetAddress, setStreetAddress] = useState((address && address.street) || '');
  const [zipCode, setZipCode] = useState((address && address.postalCode) || '');
  const [businessCity, setBusinessCity] = useState((address && address.city) || '');
  const [businessState, setBusinessState] = useState((address && address.state) || '');
  const [businessStatementDescriptor, setBusinessStatementDescriptor] = useState(statementDescriptor);
  const [zipTag, setZipTag] = useState('');
  const [accountType, setAccountType] = useState(business.accountType);
  const [annualTurnOver, setAnnualTurnOver] = useState((business.annualTurnOver / 100).toFixed(2) || '');
  const [annualCardVolume, setAnnualCardVolume] = useState((business.annualCardVolume / 100).toFixed(2) || '');
  const [annualUpiVolume, setAnnualUpiVolume] = useState((business.annualUpiVolume / 100).toFixed(2) || '');
  const [frontUrl, setFrontUrl] = useState(business.kycStoreFront);
  const [insideUrl, setInsideUrl] = useState(business.kycStoreInside);
  const [businessAddressUrl, setBusinessAddressUrl] = useState(business.currentAddressProofUrl);
  const [boardResolutionUrl, setBoardResolutionUrl] = useState(business.boardResolutionUrl);
  const [gstProofUrl, setGstProofUrl] = useState(business.gstCertificateUrl);
  const [eightyTwelveUrl, setEightyTwelveUrl] = useState((business.eightyGAndTwelveACertificate) || null);
  const [companyStartDate, setCompanyStartDate] = useState(business.companyStartDate);
  const [isTaxExemptOrganisation, setIsTaxExemptOrganisation] = useState(business.isTaxExemptOrganisation);
  const currencyType = selectedAccount && selectedAccount.currency;
  const gstRegex = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
  const tanRegex = /^([a-zA-Z]){4}([0-9]){5}([a-zA-Z]){1}?$/;
  const cinRegex = /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
  const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  const einRegex = /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/;
  const llpRegex = /[a-zA-Z0-9]{7}/;
  const postalCodeRegex = country === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;
  const descriptorRegex = /[<>'"*]/;
  const businessNameRegex = /^[ ]*([a-zA-Z'-.]{1,}( [a-zA-z'-. ]{1,})?)$/;
  const ifNumber = /^[0-9]+$/;
  const [countryCode, setCountryCode] = useState(getCountryCode());
  const [phoneFormate, setPhoneFormate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hide, setHide] = useState(false);

  const dateIssued = moment.utc(companyStartDate).tz(selectedAccount.timezone).format(dateFormat);

  const defaultDate = () => (companyStartDate ? moment(dateIssued, dateFormat) : '');

  useEffect(() => {
    if ((selectedAccount && selectedAccount.country && selectedAccount.country.id) === 1) {
      setZipTag('Zip code');
    } else if ((selectedAccount && selectedAccount.country && selectedAccount.country.id) === 2) {
      setZipTag('Pin code');
    }
  }, []);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      validUrl: {
        message: 'Business website must be valid',
        // eslint-disable-next-line max-len
        rule: val => (new RegExp('^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$').exec(val) !== null),
      },
      cin: {
        message: MESSAGES.BUSINESS_DETAILS.CIN,
        rule: val => cinRegex.test(val),
      },
      llp: {
        message: MESSAGES.BUSINESS_DETAILS.LLP,
        rule: val => llpRegex.test(val),
      },
      tan: {
        message: MESSAGES.BUSINESS_DETAILS.TAN,
        rule: val => tanRegex.test(val),
      },
      gst: {
        message: MESSAGES.BUSINESS_DETAILS.GST,
        rule: val => gstRegex.test(val),
      },
      pan: {
        message: MESSAGES.BUSINESS_DETAILS.PAN,
        rule: val => panRegex.test(val),
      },
      EIN: {
        message: MESSAGES.BUSINESS_DETAILS.EIN,
        rule: val => einRegex.test(val),
      },
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      descriptor: {
        message: 'Statement Descriptor min 5 and max 22 characters',
        // eslint-disable-next-line max-len
        rule: val => val && val.length >= 5 && val.length <= 22 && !descriptorRegex.test(val) && Boolean(val.charAt(0).match(/[a-zA-Z]/)) && Boolean(val.charAt(val.length - 1).match(/[a-zA-Z]/)),
      },
      phoneLength: {
        message: MESSAGES.ADD_USER.PHONE,
        rule: val => val.length >= PHONE_LENGTH.MIN && val.length <= PHONE_LENGTH.MAX,
      },
      isNumberField: {
        message: 'Enter number',
        rule: (val) => (ifNumber.test(val)),
      },
      isBusinessValid: {
        message: 'Business Name should be at least 4 characters long',
        rule: (val) => (val.length >= 4),
      },
      validname: {
        message: 'Please enter a valid Business Name',
        // eslint-disable-next-line no-useless-escape
        rule: val => (new RegExp(/^[^<>'\"\\\\`*]{4,}$/g).exec(val) !== null),
      },
      legalNameValidation: {
        message: 'Business name must be alphanumeric and may include &,-, . and empty spaces only.',
        rule: val => /^[A-Za-z0-9&,\s.-]{1,50}$/.test(val),
      },
      validBusinessName: {
        message: 'Business Name cannot have special characters or numbers',
        rule: (val) => (businessNameRegex.test(val)),
      },
    },
  }));

  useEffect(() => {
    const cntryCode = getCountryCode();
    if (businessPhoneNumber && businessPhoneNumber.length === 12 && cntryCode === '+1') {
      setCountryCode('+1');
      setPhoneNumber(businessPhoneNumber.slice(2));
      setPhoneFormate('(###) ###-####');
    } else if (businessPhoneNumber && businessPhoneNumber.length === 13 && cntryCode === '+91') {
      setCountryCode('+91');
      setPhoneNumber(businessPhoneNumber.slice(3));
      setPhoneFormate('##### #####');
    }
  }, []);

  useEffect(() => {
    if (countryCode === '+1' && !businessPhoneNumber) {
      setPhoneFormate('(###) ###-####');
    } else if (countryCode === '+91' && !businessPhoneNumber) {
      setPhoneFormate('##### #####');
    }
  }, []);

  const onCountryCodeChange = (value) => {
    if (value === '+1') {
      setPhoneFormate('(###) ###-####');
      setCountryCode(value);
    } else if (value === '+91') {
      setPhoneFormate('##### #####');
      setCountryCode(value);
    } else {
      setPhoneFormate('##########');
      setCountryCode(value);
    }
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhoneNumber(updatedPhone.replace(/[^0-9+]/g, ''));
  };


  useEffect(() => {
    if (accountType !== 'POS') {
      simpleValidator.current.purgeFields();
    }
  }, [accountType]);

  const onAccountChange = (e) => {
    setAccountType(e.target.value);
  };

  const updateGst = (e) => {
    if (e.currentTarget.value) {
      setGst(e.currentTarget.value.toUpperCase());
    } else if (!e.currentTarget.value) {
      setGst(null);
      simpleValidator.current.purgeFields();
    }
  };

  const updateTan = (e) => {
    if (e.currentTarget.value) {
      setTan(e.currentTarget.value.toUpperCase());
    } else if (!e.currentTarget.value) {
      setTan(null);
      simpleValidator.current.purgeFields();
    }
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      businessName,
      businessType,
      businessCategory,
      businessUrl,
      companyStartDate,
      phoneNumber,
      countryCode,
      isTaxExemptOrganisation,
      businessPan,
      streetAddress,
      zipCode,
      businessCity,
      businessState,
      businessStatementDescriptor,
      panUrl,
      registrationProofUrl,
      employerIdNumber: (!internalRole && !hide) ? null : (employerIdNumber || ''),
      accountType,
      annualTurnOver: annualTurnOver.replace(/[^\d]/g, ''),
      annualCardVolume: annualCardVolume.replace(/[^\d]/g, ''),
      annualUpiVolume: annualUpiVolume.replace(/[^\d]/g, ''),
      frontUrl,
      insideUrl,
      cin: businessType === 'LIMITED_LIABILITY_PARTNERSHIP' ? llp : cin,
      gst,
      tan,
      isLLP: businessType !== 'PROPRIETORSHIP' && !BUSINESS_VALUES.includes(businessType),
      currentAddressProofUrl: businessAddressUrl,
      boardResolutionUrl,
      gstCertificateUrl: gstProofUrl,
      eightyGAndTwelveACertificate: eightyTwelveUrl,
    }),
  }));

  const bussinessTypeChange = (event) => {
    if (event === 'PROPRIETORSHIP') {
      setCin(null);
      setLlp(null);
    }
    setBusinessType(event);
    simpleValidator.current.purgeFields();
  };

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Business Name</span>
          </Col>
          <Col span={6}>
            <Input
              value={businessName}
              placeholder="Legal Business Name"
              onBlur={() => setBusinessName(businessName.trim())}
              onChange={e => setBusinessName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Business Name', businessName, 'required|validname|legalNameValidation')}
            </SpError>
          </Col>
        </Row>

        {
          internalRole && (
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Business Type</span>
              </Col>
              <Col span={6}>
                <Select
                  placeholder="Select Business Type"
                  className="w-100"
                  value={businessType}
                  onChange={bussinessTypeChange}
                >
                  {
                    businessTypes.map(item => (
                      <Option
                        key={1}
                        value={item.value}
                        className="text-capitalize"
                      >
                        {item.display}
                      </Option>
                    ))
                  }
                </Select>
                <SpError>
                  {simpleValidator.current.message('Business Type', businessType, 'required')}
                </SpError>
              </Col>
              <Col span={4} className="ml-4" align="left">
                <span>Business Category</span>
              </Col>
              <Col span={6} className="ml-2">
                <Select
                  placeholder="Select Business Category"
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  value={businessCategory}
                  onChange={value => setBusinessCategory(value)}
                >
                  {
                    businessCategories.map(item => (
                      <Option
                        key={1}
                        value={item.id}
                        className="text-capitalize"
                      >
                        {item.name}
                      </Option>
                    ))
                  }
                </Select>
                <SpError>
                  {simpleValidator.current.message('Business Category', businessCategory, 'required')}
                </SpError>
              </Col>
            </Row>
          )
        }

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Business Website</span>
          </Col>
          <Col span={6}>
            <Input
              value={businessUrl}
              placeholder="Legal Business URL"
              onBlur={() => setBusinessUrl(businessUrl.trim())}
              onChange={e => setBusinessUrl(e.currentTarget.value && e.currentTarget.value.toLowerCase())}
            />
            <SpError>
              {simpleValidator.current.message('Business Url', businessUrl, 'required|validUrl')}
            </SpError>
          </Col>
        </Row>

        {!isIndia && (
          <Row type="flex" justify="start" align="middle">
            <Col span={4}>
              <span>Company Start Date</span>
            </Col>
            <Col span={6}>
              <DatePicker
                defaultValue={defaultDate()}
                allowClear={false}
                onChange={(date, dateString) => setCompanyStartDate(dateString)}
                disabledDate={current => current && current > moment().subtract(1, 'days')}
              />
              <SpError>
                {simpleValidator.current.message('Company Start Date', companyStartDate, 'required')}
              </SpError>
            </Col>
            <Col span={8} className="ml-4">
              <SpTextMute>
                Ensure it matches with provided EIN
              </SpTextMute>
            </Col>
          </Row>
        )}

        {
          isIndia ? (
            <>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Business PAN</span>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="Business PAN"
                    value={businessPan}
                    onBlur={() => setBusinessPan(businessPan.trim())}
                    onChange={e => setBusinessPan((e.currentTarget.value).toUpperCase())}
                  />
                  <SpError>
                    {simpleValidator.current.message('pan', businessPan, 'required|pan',
                      { messages: { required: 'The PAN field is required.' } })}
                  </SpError>
                </Col>
                {
                  (businessType !== 'PROPRIETORSHIP') && (
                    <>
                      {
                        businessType === 'LIMITED_LIABILITY_PARTNERSHIP' ? (
                          <>
                            <Col span={4} className="ml-4" align="left">
                              <span>
                                LLP ID
                              </span>
                            </Col>
                            <Col span={6}>
                              <Input
                                value={llp}
                                placeholder="LLP ID"
                                onBlur={() => setLlp(llp.trim())}
                                onChange={e => setLlp((e.currentTarget.value).toUpperCase())}
                              />
                              <SpError>
                                {simpleValidator.current.message('llp', llp, 'required|llp',
                                  { messages: { required: 'The LLP ID field is required' } })}
                              </SpError>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={4} className="ml-4" align="left">
                              <span>
                                Company Identification Number (CIN)
                              </span>
                            </Col>
                            <Col span={6}>
                              <Input
                                value={cin}
                                placeholder="Company Identification Number"
                                onBlur={() => setCin(cin.trim())}
                                onChange={e => setCin((e.currentTarget.value).toUpperCase())}
                              />
                              {
                                businessType === 'NONPROFIT_ORGANIZATION' ? (
                                  <SpError>
                                    {simpleValidator.current.message('CIN', cin, 'cin')}
                                  </SpError>
                                ) : (
                                  <SpError>
                                    {simpleValidator.current.message('CIN', cin, 'required|cin',
                                      { messages: { required: 'The CIN field is required' } })}
                                  </SpError>
                                )
                              }
                            </Col>
                            {
                              businessType === 'NONPROFIT_ORGANIZATION' ? (
                                <Col>
                                  <SpCaption className="pl-2">(Optional)</SpCaption>
                                </Col>
                              ) : null
                            }
                          </>
                        )
                      }
                    </>
                  )
                }
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>GST Identification Number</span>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="GST Identification Number"
                    value={gst}
                    onBlur={() => setGst(gst.trim())}
                    onChange={updateGst}
                  />
                  <SpError>
                    {gst ? simpleValidator.current.message('gst', gst, 'gst') : ''}
                  </SpError>
                </Col>
                <Col>
                  <SpCaption className="pl-4">(Optional)</SpCaption>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <span>Tax deduction account number (TAN)</span>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="TAN Number"
                    value={tan}
                    onBlur={() => setTan(tan.trim())}
                    onChange={updateTan}
                  />
                  <SpError>
                    {tan ? simpleValidator.current.message('tan', tan, 'tan') : ''}
                  </SpError>
                </Col>
                <Col>
                  <SpCaption className="pl-4">(Optional)</SpCaption>
                </Col>
              </Row>
            </>
          ) : (
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Employer Identification No.</span>
              </Col>
              <Col span={6}>
                <div className="d-flex">
                  <Input
                    autoComplete="new-password"
                    placeholder="EIN of the company"
                    value={!hide
                      ? '*********' : employerIdNumber}
                    onBlur={() => setEmployerIdNumber(employerIdNumber.trim())}
                    onChange={e => setEmployerIdNumber(e.currentTarget.value)}
                    disabled={!hide}
                  />
                  <div
                    className="w-35 ml-2"
                    style={{ border: '1px solid #BFC9CD', borderRadius: '5px' }}
                  >
                    <span
                      onClick={() => setHide(!hide)}
                      style={{ cursor: 'pointer' }}
                    >
                      {!hide
                        ? (<LockClose />)
                        : (<LockOpen />)}
                    </span>
                  </div>
                </div>
                {(businessType !== 'INDIVIDUAL_OR_SOLE_PROPRIETORSHIP')
                  ? (
                    internalRole ? (
                      <SpError>
                        {simpleValidator.current.message('Employer Id Number', employerIdNumber, 'required|EIN')}
                      </SpError>
                    ) : (
                      <SpError>
                        {simpleValidator.current.message('Employer Id Number', employerIdNumber, 'EIN')}
                      </SpError>
                    )
                  ) : (
                    <SpError>
                      {simpleValidator.current.message('Employer Id Number', employerIdNumber, 'EIN')}
                    </SpError>
                  )}
              </Col>
              {(businessType === 'INDIVIDUAL_OR_SOLE_PROPRIETORSHIP') || (businessType === 'LIMITED_LIABILITY_COMPANY')
                ? (
                  <Col>
                    <SpCaption className="pl-4">(Optional)</SpCaption>
                  </Col>
                ) : null}
            </Row>
          )
        }
        {!isIndia && (
          <Row type="flex" justify="start" align="middle">
            <Col span={4}>
              <span>Business Phone Number</span>
            </Col>
            <Col span={6}>
              <div className="d-flex">
                <Select
                  style={{ width: '25%' }}
                  defaultValue={countryCode}
                  onChange={onCountryCodeChange}
                >
                  {COUNTRY_CODES.map((item) => (
                    <Option key={item.ID} value={item.PHONE_CODE}>
                      {item.PHONE_CODE}
                    </Option>
                  ))}
                </Select>
                <div style={{ width: '75%' }}>
                  <NumberFormat
                    className="InputnumFormate"
                    value={phoneNumber}
                    format={phoneFormate}
                    mask="_"
                    allowEmptyFormatting
                    onChange={onPhoneNumberChange}
                  />
                  <SpError>
                    {simpleValidator.current.message('Phone', phoneNumber, 'required|phoneLength')}
                  </SpError>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Street Address</span>
          </Col>
          <Col span={6}>
            <Input
              value={streetAddress}
              placeholder="Street address for business"
              onBlur={() => setStreetAddress(streetAddress.trim())}
              onChange={e => setStreetAddress(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Street Address', streetAddress, 'required')}
            </SpError>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>{zipTag}</span>
          </Col>
          <Col span={4}>
            <Input
              value={zipCode}
              placeholder={zipTag}
              onBlur={() => setZipCode(zipCode.trim())}
              onChange={e => setZipCode(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message(zipTag, zipCode, 'required|postalcode')}
            </SpError>
          </Col>

          <Col span={2} className="ml-4">
            <span>City</span>
          </Col>
          <Col span={4}>
            <Input
              value={businessCity}
              placeholder="City"
              onBlur={() => setBusinessCity(businessCity.trim())}
              onChange={e => setBusinessCity(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('City', businessCity, 'required')}
            </SpError>
          </Col>

          <Col span={2} className="ml-4">
            <span>State</span>
          </Col>
          <Col span={4}>
            <Select
              value={businessState}
              placeholder="Please select"
              showSearch
              optionFilterProp="children"
              className="w-100"
              onChange={value => setBusinessState(value)}
            >
              {
                statesInCountry.map(s => (
                  <Option
                    key={s.id}
                    value={s.id}
                    className="text-capitalize"
                  >
                    {s.name}
                  </Option>
                ))
              }
            </Select>
            <SpError>
              {simpleValidator.current.message('State', businessState, 'required')}
            </SpError>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Country</span>
          </Col>
          <Col span={4}>
            <Select value={country} style={{ width: '100%' }} disabled>
              <Option key={country} value={country}>{country}</Option>
            </Select>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Statement Descriptor</span>
          </Col>
          <Col span={6}>
            <Input
              value={businessStatementDescriptor}
              placeholder="Statement Descriptor"
              onBlur={() => setBusinessStatementDescriptor(businessStatementDescriptor.trim())}
              onChange={e => setBusinessStatementDescriptor(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Statement Descriptor', businessStatementDescriptor, 'required|descriptor')}
            </SpError>
          </Col>

          <Col span={8} className="ml-4">
            <SpTextMute>
              This business name show up on your customer’s statements.
              Choose something they will recognize to help prevent disputes.
            </SpTextMute>
          </Col>
        </Row>

        {
          !isIndia && (
            <>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Is Business Tax Exempted Organisation</span>
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="Is Business Tax Exempted Organisation"
                    optionFilterProp="children"
                    className="w-100"
                    showSearch
                    value={isTaxExemptOrganisation}
                    onChange={(value) => {
                      setIsTaxExemptOrganisation(value);
                    }}
                  >
                    <Option key={1} value>Yes</Option>
                    <Option key={2} value={false}>No</Option>
                  </Select>
                </Col>
                <Col>
                  <SpCaption className="pl-4">(Optional)</SpCaption>
                </Col>
              </Row>
            </>
          )
        }

        {
          isIndia && (
            <>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Account Type</span>
                </Col>
                <Col span={9}>
                  <Radio.Group onChange={onAccountChange} value={accountType}>
                    <Radio value="PAYMENT_GATEWAY">Online Payment Gateway</Radio>
                    <Radio value="POS">POS</Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Estimated Annual Turnover</span>
                </Col>
                <Col span={6}>
                  <Input
                    value={(annualTurnOver === '0.00') ? null : annualTurnOver}
                    placeholder="Estimated Annual Turnover"
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/;
                      const { value } = e.currentTarget;
                      if (regex.test(value) || value === '') {
                        setAnnualTurnOver(e.currentTarget.value);
                      } else if (!regex.test(value)) {
                        setAnnualTurnOver(0.00);
                      }
                    }}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      if (e.currentTarget.value) {
                        const value = formatAmount(e.currentTarget.value, currencyType);
                        setAnnualTurnOver(value);
                      }
                    }}
                  />
                </Col>
                <Col>
                  <SpCaption className="pl-4">(Optional)</SpCaption>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Estimated Annual Card Volume</span>
                </Col>
                <Col span={6}>
                  <Input
                    value={(annualCardVolume === '0.00') ? null : annualCardVolume}
                    placeholder="Estimated Annual Card Volume"
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/;
                      const { value } = e.currentTarget;
                      if (regex.test(value) || value === '') {
                        setAnnualCardVolume(e.currentTarget.value);
                      } else if (!regex.test(value)) {
                        setAnnualCardVolume(0.00);
                      }
                    }}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      if (e.currentTarget.value) {
                        const value = formatAmount(e.currentTarget.value, currencyType);
                        setAnnualCardVolume(value);
                      }
                    }}
                  />
                </Col>
                <Col>
                  <SpCaption className="pl-4">(Optional)</SpCaption>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Estimated Annual UPI Volume</span>
                </Col>
                <Col span={6}>
                  <Input
                    value={(annualUpiVolume === '0.00') ? null : annualUpiVolume}
                    placeholder="Estimated Annual UPI Volume"
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/;
                      const { value } = e.currentTarget;
                      if (regex.test(value) || value === '') {
                        setAnnualUpiVolume(e.currentTarget.value);
                      } else if (!regex.test(value)) {
                        setAnnualUpiVolume(0.00);
                      }
                    }}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      if (e.currentTarget.value) {
                        const value = formatAmount(e.currentTarget.value, currencyType);
                        setAnnualUpiVolume(value);
                      }
                    }}
                  />
                </Col>
                <Col>
                  <SpCaption className="pl-4">(Optional)</SpCaption>
                </Col>
              </Row>
              {accountType === 'POS' && (
                <>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Store Front Picture</span>
                    </Col>
                    <Col span={6}>
                      <KYCFileUpload
                        name="FRONT"
                        endPoint={API_END_POINTS.UPLOAD_STORE_FRONT}
                        url={frontUrl}
                        setUrl={value => setFrontUrl(value)}
                        type="Business"
                      />
                      <SpError>
                        {accountType === 'POS' ? simpleValidator.current.message('Store Front Url', frontUrl, 'required') : ''}
                      </SpError>
                    </Col>
                    <Col span={8} className="ml-4">
                      <SpTextMute>
                        Upload an Image with Store front details of business. Self attested mandatory.
                      </SpTextMute>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Store Inside Picture</span>
                    </Col>
                    <Col span={6}>
                      <KYCFileUpload
                        name="INSIDE"
                        endPoint={API_END_POINTS.UPLOAD_STORE_INSIDE}
                        url={insideUrl}
                        setUrl={value => setInsideUrl(value)}
                        type="Business"
                      />
                      <SpError>
                        {accountType === 'POS' ? simpleValidator.current.message('Store Inside Url', insideUrl, 'required') : ''}
                      </SpError>
                    </Col>
                    <Col span={8} className="ml-4">
                      <SpTextMute>
                        Upload an Image with Store inside details of business. Self attested mandatory.
                      </SpTextMute>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )
        }
        {
          internalRole && (
            <>
              {
                isIndia && (
                  <>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={4}>
                        <span>Business Registration Proof</span>
                      </Col>
                      <Col span={6}>
                        <KYCFileUpload
                          name="Registration"
                          url={registrationProofUrl}
                          endPoint={API_END_POINTS.UPLOAD_REGISTRATION}
                          setUrl={value => setRegistrationProofUrl(value)}
                        />
                        <SpError>
                          {simpleValidator.current.message('Registration Proof Url', registrationProofUrl, 'required')}
                        </SpError>
                      </Col>
                      <Col span={8} className="ml-4">
                        {
                          businessType === 'PROPRIETORSHIP' ? (
                            <SpTextMute>
                              GST Certificate / Shop Establishment Act Certificate / Registration Certificate
                            </SpTextMute>
                          ) : (
                            <SpTextMute>
                              {
                                BUSINESS_VALUES.includes(businessType)
                                  ? 'Upload an Image or pdf of Certification of Incorporation'
                                  : 'Upload an Image or pdf of Partnership Deed'
                              }
                            </SpTextMute>
                          )
                        }
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={4}>
                        <span>Business PAN Proof</span>
                      </Col>
                      <Col span={6}>
                        <KYCFileUpload
                          name="PAN"
                          endPoint={API_END_POINTS.UPLOAD_PAN}
                          url={panUrl}
                          setUrl={value => setPanUrl(value)}
                        />
                        <SpError>
                          {simpleValidator.current.message('pan Url', panUrl, 'required',
                            { messages: { required: 'The PAN proof url field is required' } })}
                        </SpError>
                      </Col>
                      <Col span={8} className="ml-4">
                        <SpTextMute>
                          Upload an Image or pdf with PAN details of business. Self attested mandatory.
                        </SpTextMute>
                      </Col>
                    </Row>
                  </>
                )
              }
            </>
          )
        }
        {
          isIndia && (
            <>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Current Address Proof</span>
                </Col>
                <Col span={6}>
                  <KYCFileUpload
                    name="Address"
                    endPoint={API_END_POINTS.UPLOAD_BUSINESS_ADDRESS}
                    url={businessAddressUrl}
                    keyType="BUSINESS_CURRENT_ADDRESS_PROOF"
                    setUrl={value => setBusinessAddressUrl(value)}
                    type="Business"
                  />
                  <SpError>
                    {simpleValidator.current.message('address Url', businessAddressUrl, 'required',
                      { messages: { required: 'The Address proof url field is required' } })}
                  </SpError>
                </Col>
                <Col span={8} className="ml-4">
                  {
                    businessType === 'PROPRIETORSHIP' ? (
                      <SpTextMute>
                        Address Proof:
                        Any Utility Bill (Electricity / Telephone / Water Bill / Gas bill / Municipal Tax (not more than 3 months old)
                        or Insurance policy or Aadhar card). Self attested mandatory.
                      </SpTextMute>
                    ) : businessType === 'LIMITED_LIABILITY_PARTNERSHIP' ? (
                      <SpTextMute>
                        Current address proof of the partnership firm (e.g Utility bills not more than 3 months old). Self attested mandatory.
                      </SpTextMute>
                    ) : (
                      <SpTextMute>
                        Company current Address proof (e.g Utility bills not more than 3 months old). Self attested mandatory.
                      </SpTextMute>
                    )
                  }
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Board Resolution</span>
                </Col>
                <Col span={6}>
                  <KYCFileUpload
                    name="Boardresolution"
                    endPoint={API_END_POINTS.UPLOAD_BOARD_RESOLUTION}
                    url={boardResolutionUrl}
                    keyType="BUSINESS_BOARD_RESOLUTION_PROOF"
                    setUrl={value => setBoardResolutionUrl(value)}
                    type="Business"
                  />
                  <SpError>
                    {simpleValidator.current.message('board resolution', boardResolutionUrl, 'required',
                      { messages: { required: 'The board resolution url field is required' } })}
                  </SpError>
                </Col>
                <Col span={8} className="ml-4">
                  {
                    businessType === 'PROPRIETORSHIP' ? (
                      <SpTextMute>
                        Bank statement of last 12 months or front copy of bank statement if the account is new.
                      </SpTextMute>
                    ) : businessType === 'LIMITED_LIABILITY_PARTNERSHIP' ? (
                      <SpTextMute>
                        Board Resolution (should be signed by atleast two partners. Should not be self-attested).
                      </SpTextMute>
                    ) : businessType === 'NONPROFIT_ORGANIZATION' ? (
                      <SpTextMute>
                        Board Resolution (should be signed by atleast two trustees. Should not be self-attested).
                      </SpTextMute>
                    ) : (
                      <SpTextMute>
                        Board Resolution (should be signed by atleast two directors or Company Secretary. Should not be self-attested).
                      </SpTextMute>
                    )
                  }
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>GST Certificate</span>
                </Col>
                <Col span={6}>
                  <KYCFileUpload
                    name="GSTCertificate"
                    endPoint={API_END_POINTS.UPLOAD_GST_CERTIFICATE}
                    url={gstProofUrl}
                    keyType="BUSINESS_GST_CERTIFICATE"
                    setUrl={value => setGstProofUrl(value)}
                    type="Business"
                  />
                  <SpError>
                    {simpleValidator.current.message('gst Url', gstProofUrl, 'required',
                      { messages: { required: 'The GST certificate url field is required' } })}
                  </SpError>
                </Col>
                <Col span={8} className="ml-4">
                  <SpTextMute>
                    GST Certificate. Self attested mandatory.
                  </SpTextMute>
                </Col>
              </Row>
              {
                businessType === 'NONPROFIT_ORGANIZATION' && (
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>80G and 12A Certficate</span>
                    </Col>
                    <Col span={6}>
                      <KYCFileUpload
                        name="80GCertificate"
                        endPoint={API_END_POINTS.UPLOAD_EIGHTY_TWELVE}
                        url={eightyTwelveUrl}
                        keyType="BUSINESS_EIGHTY_G_AND_TWELVE_A_CERTIFICATE"
                        setUrl={value => setEightyTwelveUrl(value)}
                        type="Business"
                      />
                      <SpError>
                        {simpleValidator.current.message('80g and 12a Url', eightyTwelveUrl, 'required',
                          { messages: { required: 'The 80G and 12A certificate url field is required' } })}
                      </SpError>
                    </Col>
                    <Col span={8} className="ml-4">
                      <SpTextMute>
                        80G certificate and 12A Certficate
                      </SpTextMute>
                    </Col>
                  </Row>
                )
              }
            </>
          )
        }
      </SpForm>
    </>
  );
};

// $FlowFixMe
export default forwardRef(EditBusinessDetails);
