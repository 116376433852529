/* eslint-disable */
import React from 'react';

const dragIcon = () => (
    <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="0.5" x2="19" y2="0.5" stroke="#B3B3B3" />
        <line y1="3.5" x2="19" y2="3.5" stroke="#B3B3B3" />
        <line y1="6.5" x2="19" y2="6.5" stroke="#B3B3B3" />
        <line y1="9.5" x2="19" y2="9.5" stroke="#B3B3B3" />
        <line y1="12.5" x2="19" y2="12.5" stroke="#B3B3B3" />
    </svg>
);

export default dragIcon;
