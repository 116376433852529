import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  PLANS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchPlans = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  const planFilters = '&isInternal.EQ=false';
  const size = payload.planParams;
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.PLAN}?${payload.filterParams}${planFilters}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.PLAN}${query}${sorting}&${payload.filterParams}${planFilters}`);
  }
  if (payload.planParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.PLAN}${size}${sorting}${planFilters}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.PLAN}${query}${sorting}${planFilters}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.PLAN}${query}${sorting}${planFilters}`);
};

const postPlans = async payload => axiosAuthInstance.post(API_END_POINTS.PLAN, payload);

export function* getPlans(action) {
  try {
    const { data: { entity } } = yield call(fetchPlans, action.payload);
    yield put({ type: PLANS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PLANS.FAILED, payload: e.response.data });
  }
}

export function* addPlans(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postPlans, action.payload);
    const { data: { entity } } = yield call(fetchPlans, action.payload);
    yield put({ type: PLANS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PLANS.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
