// @flow
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Input,
  Button,
  Icon,
  Alert,
} from 'antd';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';
import type { ContextRouter } from 'react-router-dom';

import Loading from 'components/Loading';

import {
  axiosLoginInstance,
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  SpFormText,
} from 'components/DesignKit';

import {
  AUTH_CHALLENGES,
  PAGES,
  TOKENS,
  MESSAGES,
  PRIVACY_STATEMENT,
  CONTACT_US_EMAIL,
  NEED_HELP_EMAIL,
  ROLES,
} from 'appconstants';
import { validators } from 'utils/validationMessages';

import {
  accountActions,
  authActions,
  userActions,
  notificationActions,
  recaptchaDetailsActions,
} from 'store/actions';
import {
  ACCOUNT,
  AUTH,
  USER,
  NOTIFICATION,
} from 'store/actionTypes';
import swireLogo from 'imgs/swirepay.png';
import ReCAPTCHA from 'react-google-recaptcha';

// $FlowFixMe
const Wrapper = styled.div`
  background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '24px',
  paddingBottom: '24px',
  alignItems: 'center',
  width: '511px',
  minHeight: '520px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

type Props = ContextRouter & {
  authenticateUserSuccess: Function,
  fetchAccountSuccess: Function,
  setUser: Function,
  setTestData: Function,
  history: {
    push: Function,
  },
  children: Node,
  error: boolean,
  loading: boolean,
  setNotification: Function,
  setSelectedAccount: Function,
  fetchRecaptchaDetails: Function,
  fetchRecaptcha: Object,
  selectedAccount: Object,
  user: Object,
  isMobileView: Boolean,
};

const Login = (props: Props) => {
  const {
    fetchRecaptchaDetails,
    fetchRecaptcha,
    selectedAccount,
    user,
    isMobileView,
  } = props;
  const [, forceUpdate] = useState();
  const [email, setEmail] = useState('');
  const [loggedIn, setLoggedIn] = useState(true);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  const [errorText, setErrorText] = useState('');
  const [recaptchaToken, setRecaptchToken] = useState('');
  const [roleName, setRoleName] = useState('');
  const recaptchaRef = useRef(null);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));
  useEffect(() => {
    if (history.location.state && history.location.state.errorMsg) {
      setErrorText(history.location.state.errorMsg);
    }
    if (localStorage.getItem(TOKENS.ACCESS_TOKEN) && selectedAccount && selectedAccount.gid) {
      const nameRole = localStorage.getItem('kdsRole');
      if ((roleName && roleName === ROLES.ASSOCIATE) || (nameRole && nameRole === ROLES.ASSOCIATE)) {
        props.history.replace(PAGES.KDSDASHBOARD);
      } else if ((roleName && roleName === 'KDS-USER') || (nameRole && nameRole === 'KDS-USER')) {
        props.history.replace(PAGES.KDSDASHBOARD);
      } else if ((roleName && roleName === 'KDS-ADMIN') || (nameRole && nameRole === 'KDS-ADMIN')) {
        props.history.replace(PAGES.KDSDASHBOARD);
      } else if ((roleName && roleName === 'KdsUser') || (nameRole && nameRole === 'KdsUser')) {
        props.history.replace(PAGES.KDSDASHBOARD);
      } else if ((roleName && roleName === ROLES.ANALYST) || (nameRole && nameRole === ROLES.ANALYST)) {
        props.history.replace(PAGES.KDSDASHBOARD);
      } else if (user.internalRole && user.internalRole.name) {
        if (user.internalRole && user.internalRole.name === ROLES.SUPERUSER) {
          props.history.replace(PAGES.DASHBOARD);
        } else {
          props.history.replace(PAGES.PAYMENTS);
        }
      } else {
        props.history.replace(PAGES.DASHBOARD);
      }
    } else {
      setLoggedIn(false);
      localStorage.removeItem(TOKENS.ACCESS_TOKEN);
      localStorage.removeItem(TOKENS.REFRESH_TOKEN);
      localStorage.removeItem('email');
    }
  }, []);

  const handleReload = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      setRecaptchToken('');
    }
  };

  useEffect(() => {
    fetchRecaptchaDetails();
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setLoading(true);
    localStorage.setItem('email', email);
    localStorage.removeItem('fcmToken');
    localStorage.removeItem('kdsRole');
    try {
      const { data: { entity } } = await axiosLoginInstance.post(API_END_POINTS.AUTHENTICATE, {
        email,
        password,
        recaptchaToken,
        platformType: 'WEB',
      });

      if (entity.token) {
        localStorage.setItem(TOKENS.ACCESS_TOKEN, entity.token.accessToken);
        localStorage.setItem(TOKENS.REFRESH_TOKEN, entity.token.refreshToken);

        // fetch user
        const { data: { entity: userEntity } } = await axiosAuthInstance.get(API_END_POINTS.USER_PROFILE);
        props.authenticateUserSuccess(entity.token);
        props.setUser(userEntity);
        props.setTestData(userEntity.test);

        // internalRole
        const { internalRole } = userEntity;
        const role = userEntity.role && userEntity.role.name ? userEntity.role.name : '';
        setRoleName(role);
        localStorage.setItem('kdsRole', role);
        // fetch account
        const { data: { entity: accountEntity } } = await axiosAuthInstance.get(`${internalRole ? 'internal/' : ''}${API_END_POINTS.USER_ACCOUNT}`);
        setLoading(false);
        if (accountEntity && accountEntity.content[0]) {
          if (accountEntity.content[0].accountType && accountEntity.content[0].accountType === 'INDIVIDUAL' && !internalRole) {
            localStorage.removeItem(TOKENS.ACCESS_TOKEN);
            localStorage.removeItem(TOKENS.REFRESH_TOKEN);
            localStorage.removeItem('email');
            localStorage.removeItem('fcmToken');
            localStorage.removeItem('kdsRole');
            setEmail('');
            setPassword('');
            setRecaptchToken('');
            props.setNotification({
              type: NOTIFICATION.ERROR,
              payload: 'Please use mobile app to login to your account',
            });
          } else {
            if (internalRole
              && (internalRole.name !== 'superuser') && (internalRole.name !== 'internalsupport') && (internalRole.name !== 'onboarding')
            ) {
              props.setSelectedAccount(accountEntity.content[0]);
            }
            props.fetchAccountSuccess({
              type: ACCOUNT.SUCCESS,
              payload: accountEntity,
            });
            if (internalRole
              && (internalRole.name === ROLES.SUPERUSER || internalRole.name === ROLES.INTERNALSUPPORT || internalRole.name === ROLES.ONBOARDING)
            ) {
              const selectedGid = accountEntity.content[0].gid;
              const API_END_POINT = `/internal${API_END_POINTS.JWE}/${selectedGid}/switch?isTest=false`;
              const { data: { entity: { token: { accessToken } } } } = await axiosAuthInstance.get(API_END_POINT);
              localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
            }
            if (role !== ROLES.POS && role !== ROLES.ASSOCIATE && role !== ROLES.KDSUSER && role !== ROLES.KDSADMIN && role !== ROLES.ANALYST) {
              const pathName = props.location.state ? props.location.state.referrer.pathname : '';
              const searchParam = props.location.state ? props.location.state.referrer.search : '';
              if (pathName && pathName === PAGES.DASHBOARD) {
                if (internalRole && internalRole.name) {
                  if (internalRole && internalRole.name === ROLES.SUPERUSER) {
                    props.history.push({
                      pathname: pathName,
                      search: searchParam,
                    });
                  } else {
                    props.history.replace(PAGES.PAYMENTS);
                  }
                } else {
                  props.history.push({
                    pathname: pathName,
                    search: searchParam,
                  });
                }
              } else if (internalRole && internalRole.name) {
                if (internalRole && internalRole.name === ROLES.SUPERUSER) {
                  props.history.replace(PAGES.DASHBOARD);
                } else {
                  props.history.replace(PAGES.PAYMENTS);
                }
              } else {
                props.history.replace(PAGES.DASHBOARD);
              }
            } else if (role && role === ROLES.POS) {
              props.history.replace(PAGES.POS_USER);
            } else if (role && role === ROLES.ASSOCIATE) {
              props.history.replace(PAGES.KDSDASHBOARD);
            } else if (role && role === ROLES.KDSUSER) {
              props.history.replace(PAGES.KDSDASHBOARD);
            } else if (role && role === ROLES.KDSADMIN) {
              props.history.replace(PAGES.KDSDASHBOARD);
            } else if (role && role === ROLES.ANALYST) {
              props.history.replace(PAGES.KDSDASHBOARD);
            }
          }
        } else {
          props.history.replace(PAGES.ACCOUNT);
        }
      } else {
        localStorage.setItem(TOKENS.SESSION_TOKEN, entity.session);
        localStorage.removeItem(TOKENS.ACCESS_TOKEN);
        localStorage.removeItem(TOKENS.REFRESH_TOKEN);
        if (entity.challengeName === AUTH_CHALLENGES.NEW_PASSWORD_REQUIRED) {
          setLoading(false);
          props.history.push({
            pathname: PAGES.SET_PASSWORD,
            search: `?email=${email}`,
          });
        }
      }
    } catch (e) {
      localStorage.removeItem(TOKENS.ACCESS_TOKEN);
      localStorage.removeItem(TOKENS.REFRESH_TOKEN);
      localStorage.removeItem(TOKENS.SESSION_TOKEN);
      localStorage.removeItem('email');
      localStorage.removeItem('fcmToken');
      localStorage.removeItem('kdsRole');
      localStorage.removeItem('dynamicStaticQr');
      setError(401);
      setLoading(false);
      handleReload();
      setErrorText('');
      if (e.response && e.response.status !== 401) {
        props.setNotification({
          type: NOTIFICATION.ERROR,
          payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
        });
      }
    }
  };

  if (loggedIn) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <div>
        <FormWrapper
          onSubmit={handleLogin}
          style={{
            width: isMobileView ? '900px' : '',
            height: isMobileView ? '900px' : '',
            paddingTop: isMobileView ? '0px' : '',
            paddingBottom: isMobileView ? '0px' : '',
          }}
        >
          <img src={swireLogo} alt="" style={{ width: isMobileView ? '420px' : '270px' }} />
          <div style={{ maxWidth: isMobileView ? '800px' : '350px' }}>
            <SpFormText fontSize={isMobileView ? '26px' : '18px'}>Login to your account</SpFormText>
            {
              errorText && (
                <div className="text-danger">
                  {errorText}
                </div>
              )
            }
            <div className="LOGIN">
              <div className="mb-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Email</label>
                </div>
                <Input
                  style={{ width: isMobileView ? '450px' : '', height: isMobileView ? '50px' : '' }}
                  placeholder="Your Email"
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  value={email}
                  onBlur={() => setEmail(email.trim())}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('Email', email, 'required|email|checkLowerCase',
                    { messages: { required: validators.login.email, email: validators.login.validEmail } })}
                </div>
              </div>
              <div className="my-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Password</label>
                </div>
                <Input.Password
                  style={{ width: isMobileView ? '450px' : '', height: isMobileView ? '50px' : '' }}
                  placeholder="Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('password', password, 'required',
                    { messages: { required: validators.login.password } })}
                </div>
              </div>
              {fetchRecaptcha.login === true && fetchRecaptcha.recaptchaDetailsStatus === 'SUCCESS'
                ? (
                  <>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      className="g-recaptcha"
                      sitekey={fetchRecaptcha.siteKey}
                      value={recaptchaToken}
                      onChange={(value) => setRecaptchToken(value)}
                    />
                    <div className="text-danger">
                      {simpleValidator.current.message('captcha', recaptchaToken, 'required',
                        { messages: { required: validators.login.recaptchaToken } })}
                    </div>
                  </>
                )
                : ''}
              <br />
              {
                error === 401 && (
                  <Alert
                    message={MESSAGES.LOGIN.ERROR}
                    type="error"
                    className="mb-2"
                  />
                )
              }
              <div className="d-flex justify-content-center">
                <Button
                  type="primary"
                  shape="round"
                  className="w-50"
                  htmlType="submit"
                  loading={loading}
                  onClick={handleLogin}
                  style={{ fontSize: isMobileView ? '18px' : '' }}
                >
                  Login
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <Button
                type="link"
                onClick={() => props.history.push(PAGES.REGISTER, fetchRecaptchaDetails())}
                style={{ fontSize: isMobileView ? '18px' : '' }}
              >
                Register here
              </Button>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <Button
                type="link"
                onClick={() => props.history.push(PAGES.FORGOT_PASSWORD)}
                style={{ fontSize: isMobileView ? '18px' : '' }}
              >
                Forgot password?
              </Button>
            </div>
          </div>
        </FormWrapper>
        <div className="d-flex justify-content-between align-items-center" style={{ width: isMobileView ? '900px' : '511px' }}>
          <Button type="link" className="pl-1" style={{ fontSize: isMobileView ? '16px' : '' }}>
            <a className="link-style" href={PRIVACY_STATEMENT}>
              Privacy & Terms
            </a>
          </Button>
          <div className="d-flex align-items-center">
            <Button type="link" className="pr-0" style={{ fontSize: isMobileView ? '16px' : '' }}>
              <a href={`mailto:${NEED_HELP_EMAIL}`} className="link-style">
                Need Help?
              </a>
            </Button>
            <Button type="link" className="pr-0" style={{ fontSize: isMobileView ? '16px' : '' }}>
              <a href={`mailto:${CONTACT_US_EMAIL}`} className="link-style">
                Contact Us
              </a>
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  error: state.loading.error,
  fetchRecaptcha: state.recaptchaDetails,
  selectedAccount: state.account.selectedAccount,
  user: state.user,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  authenticateUserSuccess: (param) => dispatch(authActions.authenticateUserSuccess({
    type: AUTH.SUCCESS,
    payload: param,
  })),
  setUser: (param) => dispatch(userActions.setUser({
    type: USER.FETCH,
    payload: param,
  })),
  setTestData: (param) => dispatch(accountActions.setTestData({
    payload: param,
  })),
  fetchAccountSuccess: (params) => dispatch(accountActions.fetchAccountSuccess(params)),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setSelectedAccount: account => dispatch(accountActions.setSelectedAccount(account)),
  fetchRecaptchaDetails: (payload) => dispatch(recaptchaDetailsActions.fetchRecaptchaDetails({
    payload,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
