// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Input,
  Select,
  Checkbox,
  AutoComplete,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getSortedList from 'utils/getSortedList';
import { SpH5, SpError } from 'components/DesignKit';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import {
  COUNTRY_PHONE_CODE,
  COUNTRIES,
  MAX_AMOUNT,
  COUNTRY_CODES,
} from 'appconstants';

const { Option } = Select;

type Props = {
  submitting: boolean,
  selectedAccount: Array<Object>,
  fetchCustomerDetails: Function,
  linkData: Object,
  statesInCountry: Array<Object>,
  zipTag: string,
  countryName: string,
  shippingAmount: Number,
};

const LinkDetails = (props: Props, ref) => {
  const {
    submitting,
    selectedAccount,
    linkData,
    fetchCustomerDetails,
    statesInCountry,
    zipTag,
    countryName,
    shippingAmount,
  } = props;
  const [, forceUpdate] = useState();
  const [customersName, setCustomersName] = useState(linkData.customerName);
  const customer = linkData.customerList;
  const [email, setEmail] = useState(linkData.productEmail);
  const [phoneNumber, setPhone] = useState(linkData.productphNumber);
  const [countryCode, setCountryCode] = useState(linkData.countryCodes ? linkData.countryCodes : getCountryPhoneCode(selectedAccount.country.id));
  const [phoneFormate, setPhoneFormate] = useState(linkData.phoneFormate);
  const [note, setNote] = useState(linkData.notes);
  const [customerGid, setCustomerGid] = useState(linkData.customersGid);
  const [disableCustomerDetails, setDisableCustomerDetails] = useState(linkData.disableSubscriptionCustomer);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [streetName, setStreetName] = useState(linkData.streetName);
  const [cityName, setCityName] = useState(linkData.cityName);
  const [stateName, setStateName] = useState(linkData.stateName);
  const [zipCode, setZipCode] = useState(linkData.zipCode);
  const [streetName1, setStreetName1] = useState(linkData.streetName1);
  const [cityName1, setCityName1] = useState(linkData.cityName1);
  const [stateName1, setStateName1] = useState(linkData.stateName1);
  const [zipCode1, setZipCode1] = useState(linkData.zipCode1);
  const [addon, setAddon] = useState(linkData.addon);
  const [disableAddressDetails, setDisableAddressDetails] = useState(linkData.disableAddressDetails);
  const postalCodeRegex = countryName === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;
  const validateShippingFee = shippingAmount && shippingAmount > 0;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      validname: {
        message: 'Please enter a valid Customer Name',
        // eslint-disable-next-line no-useless-escape
        rule: val => (new RegExp(/^[^<>'\"\\\\`*]{4,}$/g).exec(val) !== null),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      customersName,
      email,
      phoneNumber,
      phoneFormate,
      note,
      customerGid,
      countryCode,
      disableCustomerDetails,
      streetName,
      cityName,
      stateName,
      zipCode,
      streetName1,
      cityName1,
      stateName1,
      zipCode1,
      disableAddressDetails,
      addon,
    }),
  }));

  const searchCustomer = (value) => {
    fetchCustomerDetails(value);
    setCustomersName(value);
    const results = customer.filter((item) => (item.name === value));
    if (results.length === 0) {
      setDisableCustomerDetails(false);
      setDisableAddressDetails(false);
      setCustomerGid('');
    }
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const onAddonChange = ({ target: { checked } }) => {
    setAddon(checked);
    if (checked === true) {
      setStreetName1(streetName);
      setCityName1(cityName);
      setStateName1(stateName);
      setZipCode1(zipCode);
    } else {
      setStreetName1('');
      setCityName1('');
      setStateName1('');
      setZipCode1('');
    }
  };

  const updateCustomer = (value) => {
    const results = customer.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setEmail(results[0].email);
      setCustomersName(results[0].name);
      setCustomerGid(results[0].gid);
      setDisableCustomerDetails(true);
      if (results[0].phoneNumber) {
        const phoneResult = results[0].phoneNumber ? findPhoneNumbersInText(results[0].phoneNumber) : null;
        if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
          setCountryCode(`+${phoneResult[0].number.countryCallingCode}`);
          setPhone(phoneResult[0].number.nationalNumber);
          const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
          const formater = data.substr(data.indexOf(' ') + 1);
          setPhoneFormate(formater);
        }
      }
      if (results[0].billingAddress) {
        setDisableAddressDetails(true);
        setStreetName(results[0].billingAddress && results[0].billingAddress.street);
        setStateName(results[0].billingAddress && results[0].billingAddress.state);
        setCityName(results[0].billingAddress && results[0].billingAddress.city);
        setZipCode(results[0].billingAddress && results[0].billingAddress.postalCode);
        setStreetName1(results[0].shippingAddress && results[0].shippingAddress.street);
        setStateName1(results[0].shippingAddress && results[0].shippingAddress.state);
        setCityName1(results[0].shippingAddress && results[0].shippingAddress.city);
        setZipCode1(results[0].shippingAddress && results[0].shippingAddress.postalCode);
      }
    }
  };

  return (
    <>
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2 mb-3">
          <label htmlFor=""><SpH5>Customer Name</SpH5></label>
          <AutoComplete
            placeholder="Select Customer Name"
            showSearch
            className="w-100"
            dataSource={getSortedList(customer).map((item, id) => (
              <Option key={id} value={item.gid}>
                {item.name}
              </Option>
            ))}
            style={{ height: '40px' }}
            onSearch={searchCustomer}
            defaultValue={customersName}
            onSelect={e => updateCustomer(e)}
          />
          <SpError>
            {simpleValidator.current.message('customer Name', customersName, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Customer Email</SpH5></label>
          <Input
            placeholder="Enter a contact email for subscription"
            value={email}
            disabled={disableCustomerDetails}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('email', email, 'required|email|checkLowerCase')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Customer Phone No.</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                value={countryCode}
                onChange={onCountryCodeChange}
                disabled={disableCustomerDetails}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormate}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setPhone(e.currentTarget.value);
                    const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormate(formater);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('phone number', phoneNumber, `required|phoneNumValidation:${countryCode}${phoneNumber}`)}
              </SpError>
            </div>
          </div>
        </div>
        <div className="mb-1">
          <label htmlFor=""><SpH5>Billing Address</SpH5></label>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Street</SpH5></label>
          <Input
            placeholder="Street Address"
            value={streetName}
            disabled={disableAddressDetails}
            onChange={(e) => setStreetName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('street', streetName, 'required')}
          </SpError>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName}
                disabled={disableAddressDetails}
                onChange={(e) => setCityName(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('city', cityName, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                className="w-100"
                placeholder="State"
                showSearch
                disabled={disableAddressDetails}
                optionFilterProp="children"
                value={stateName}
                onChange={(e) => setStateName(e)}
              >
                {
                  statesInCountry.map(s => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-capitalize"
                    >
                      {s.name}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('state', stateName, 'required')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipTag}</SpH5></label>
              <Input
                placeholder={zipTag}
                value={zipCode}
                disabled={disableAddressDetails}
                onChange={(e) => setZipCode(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message(zipTag, zipCode, 'required|postalcode')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Country</SpH5></label>
              <Select
                defaultValue={countryName}
                style={{ width: '100%' }}
                disabled
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ALPHA2}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Add-on</SpH5></label>
          <div className="mb-2">
            <Checkbox
              checked={addon}
              onChange={onAddonChange}
              disabled={disableAddressDetails}
            /> Same as the billing address
          </div>
        </div>
        <div className="mb-1">
          <label htmlFor=""><SpH5>Shipping Address</SpH5></label>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Street</SpH5></label>
          <Input
            placeholder="Street Address"
            value={streetName1}
            disabled={disableAddressDetails}
            onChange={(e) => setStreetName1(e.currentTarget.value)}
          />
        </div>
        {validateShippingFee && (
        <SpError>
          {simpleValidator.current.message('street', streetName1, 'required')}
        </SpError>
        )}
        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName1}
                disabled={disableAddressDetails}
                onChange={(e) => setCityName1(e.currentTarget.value)}
              />
              {validateShippingFee && (
              <SpError>
                {simpleValidator.current.message('city', cityName1, 'required')}
              </SpError>
              )}
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                className="w-100"
                placeholder="State"
                showSearch
                optionFilterProp="children"
                disabled={disableAddressDetails}
                value={stateName1}
                onChange={(e) => setStateName1(e)}
              >
                {
                  statesInCountry.map(s => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-capitalize"
                    >
                      {s.name}
                    </Option>
                  ))
                }
                {/* {validateShippingFee && (
                <SpError>
                {simpleValidator.current.message('state', stateName1, 'required')}
              </SpError>
                )} */}
              </Select>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipTag}</SpH5></label>
              <Input
                placeholder={zipTag}
                value={zipCode1}
                disabled={disableAddressDetails}
                onChange={(e) => setZipCode1(e.currentTarget.value)}
              />
              { validateShippingFee && (
              <SpError>
                {simpleValidator.current.message(zipTag, zipCode1, 'required|postalcode')}
              </SpError>
              )}
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Country</SpH5></label>
              <Select
                defaultValue={countryName}
                style={{ width: '100%' }}
                disabled
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ALPHA2}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div>
          <label htmlFor=""><SpH5>Internal Notes</SpH5></label>
          <Input
            placeholder="Optional"
            value={note}
            onChange={(e) => setNote(e.currentTarget.value)}
          />
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(LinkDetails);
