import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import generateQuery from 'utils/generateQuery';
import { MESSAGES } from 'appconstants';

import {
  WOOCOMMERCE,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchWoocommerce = async () => axiosAuthInstance.get(API_END_POINTS.WOOCOMMERCE_API);

const postWoocommerce = async (payload) => axiosAuthInstance.post(API_END_POINTS.WOOCOMMERCE_API, payload);

const patchWoocommerce = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.WOOCOMMERCE_API}/${gid}`, params);

const fetchWoocommerceList = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_WOOCOMMERCE}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_WOOCOMMERCE}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_WOOCOMMERCE}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_WOOCOMMERCE}${query}${sorting}`);
};


export function* getWoocommerce(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchWoocommerce, action.payload);
    yield put({ type: WOOCOMMERCE.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: WOOCOMMERCE.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addWoocommerce(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postWoocommerce, action.payload);
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateWoocommerce(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchWoocommerce, action.payload);
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* getWoocommerceList(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchWoocommerceList, action.payload);
    yield put({ type: WOOCOMMERCE.LIST_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: WOOCOMMERCE.LIST_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
