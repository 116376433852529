// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  Checkbox,
  Switch,
  Radio,
  DatePicker,
  TimePicker,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';
import formatAmount from 'utils/formatAmount';
import {
  SpH5, SpError, SpButton, SpText,
} from 'components/DesignKit';
import {
  COUPON_DURATION,
  MAX_AMOUNT,
  COUNTRIES,
} from 'appconstants';

const { Option } = Select;
const dateFormat = 'MMM DD, YYYY';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  fetchCustomer: Function,
  allCustomers: Array<Object>,
};

const AddNewCoupon = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    fetchCustomer,
    allCustomers,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState();
  const [percentageOff, setPercentageOff] = useState(null);
  const [percentage, setPercentage] = useState(true);
  const [amountOff, setAmountOff] = useState(null);
  const [amount, setAmount] = useState(false);
  const [validity, setValidity] = useState('');
  const [months, setMonths] = useState(false);
  const [validiyInMonths, setvalidiyInMonths] = useState(null);
  const [maximumRedemption, setMaximumRedemption] = useState(null);
  const [promotional, setPromotional] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [couponRedeemed, setCouponRedeemed] = useState(false);
  const [couponExpiry, setCouponExpiry] = useState(false);
  const [couponDate, setCouponDate] = useState();
  const [couponTime, setCouponTime] = useState();
  const [code, setCode] = useState();
  const [minimumAmount, setMinimumAmount] = useState();
  const [minAmount, setMinAmount] = useState(false);
  const [promoExpiry, setPromoExpiry] = useState(false);
  const [promoDate, setPromoDate] = useState();
  const [promoTime, setPromoTime] = useState();
  const [promotionalRedeemed, setPromotionalRedeemed] = useState();
  const [promoRedeemed, setPromoRedeemed] = useState(false);
  const { prefix } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;
  const [customerGid, setCustomerGid] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customer, setCustomer] = useState(false);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      percentage: {
        message: MAX_AMOUNT.MESSAGE_PERCENTAGE,
        rule: (val) => MAX_AMOUNT.LIMIT_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_MAX,
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      redeemed: {
        message: MAX_AMOUNT.MESSAGE_REDEMEED,
        rule: (val) => (MAX_AMOUNT.LIMIT_INR_MIN <= val),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const setDuration = (e) => {
    setValidity(e);
    if (e === COUPON_DURATION[2].value) {
      setMonths(true);
    } else if (e !== COUPON_DURATION[2].value) {
      setMonths(false);
    }
  };

  const onExpiryDateChange = (date) => {
    const utcDate = moment(date).startOf('day');
    setCouponDate(moment(utcDate).format(MOMENT_FORMAT));
  };

  const onExpiryTimeChange = (date) => {
    // eslint-disable-next-line
    const expiryTime = moment(date).format('HH:mm:ss');
    setCouponTime(expiryTime);
  };

  const promoExpiryDateChange = (date) => {
    const utcDate = moment(date).startOf('day');
    setPromoDate(moment(utcDate).format(MOMENT_FORMAT));
  };

  const promoExpiryTimeChange = (date) => {
    // eslint-disable-next-line
    const expiryTime = moment(date).format('HH:mm:ss');
    setPromoTime(expiryTime);
  };

  const selectCustomer = (e) => {
    const gid = e[1];
    const selectedName = e[0];
    setCustomerGid(gid);
    setCustomerName(selectedName);
  };

  const searchCustomer = (value) => {
    fetchCustomer(value);
  };

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    let expiresAt = null;
    if (couponDate && couponDate) {
      const dateTime = couponDate.split('T')[0];
      const expireTime = `${dateTime}T${couponTime}`;
      expiresAt = moment.tz(expireTime, selectedAccount.timezone).utc().format(MOMENT_FORMAT);
    }

    let promoExpiresAt;
    if (promotional && promoDate && promoTime) {
      const promoDateTime = promoDate.split('T')[0];
      const promoExpireTime = `${promoDateTime}T${promoTime}`;
      promoExpiresAt = moment.tz(promoExpireTime, selectedAccount.timezone).utc().format(MOMENT_FORMAT);
    }

    submit({
      name,
      percentageOff,
      amountOff,
      validity,
      validiyInMonths,
      expiresAt,
      maximumRedemption,
      code,
      minimumAmount,
      promotionalRedeemed,
      promoExpiresAt,
      promotional,
      customerGid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Coupon"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>

        <div className="pb-3">
          <label className="d-block"><SpH5>Type</SpH5></label>
          <div className="d-flex pb-3">
            <div className="mr-3">
              <Radio
                checked={percentage}
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    setPercentage(true);
                    setAmount(false);
                    setAmountOff(null);
                  } else if (!checked) {
                    setPercentage(false);
                    setAmount(true);
                  }
                }}
              />
              <SpText>Percentage discount</SpText>
            </div>
            <div>
              <Radio
                checked={amount}
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    setPercentage(false);
                    setAmount(true);
                    setPercentageOff(null);
                  } else if (!checked) {
                    setPercentage(true);
                    setAmount(false);
                  }
                }}
              />
              <SpText>Fixed amount discount</SpText>
            </div>
          </div>
          <div className="w-50 mr-3">
            {
              percentage && (
                <>
                  <Input
                    value={percentageOff}
                    suffix="%"
                    onChange={(e) => setPercentageOff(e.currentTarget.value)}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      if (e.currentTarget.value) {
                        const { value } = e.currentTarget;
                        setPercentageOff(value);
                      }
                    }}
                  />
                  <SpError>
                    {percentage && simpleValidator.current.message('percentage', percentageOff, 'required|percentage')}
                  </SpError>
                </>
              )
            }
            {
              amount && (
                <>
                  <Input
                    value={amountOff}
                    prefix={prefix}
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/;
                      const { value } = e.currentTarget;
                      if (regex.test(value) || value === '') {
                        setAmountOff(e.currentTarget.value);
                      } else if (!regex.test(value)) {
                        setAmountOff(null);
                      }
                    }}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      if (e.currentTarget.value) {
                        const value = formatAmount(e.currentTarget.value, currencyType);
                        setAmountOff(value);
                      }
                    }}
                  />
                  <SpError>
                    {amount && simpleValidator.current.message('amount', amountOff, 'required|amount')}
                  </SpError>
                </>
              )
            }
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Duration</SpH5></label>
          <Select
            className="w-100"
            value={validity}
            onChange={setDuration}
          >
            {
              COUPON_DURATION.map(s => (
                <Option
                  key={s.id}
                  value={s.value}
                >
                  {s.display}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('duration', validity, 'required')}
          </SpError>
        </div>

        {
          months && (
            <div className="mb-3 w-50">
              <Input
                suffix="months"
                value={validiyInMonths}
                onChange={(e) => setvalidiyInMonths(e.currentTarget.value)}
              />
              <SpError>
                {months && simpleValidator.current.message('no of months', validiyInMonths, 'required|integer')}
              </SpError>
            </div>
          )
        }

        <div className="pb-3">
          <label className="d-block"><SpH5>Limits</SpH5></label>
          <div className="mb-2">
            <Checkbox
              className="mr-2"
              checked={couponExpiry}
              onChange={({ target: { checked } }) => {
                setCouponExpiry(checked);
              }}
            />
            <SpText>Limit the date by when this coupon can be redeemed</SpText>
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="w-30">
              <SpText>Redeem by</SpText>
            </div>
            <div className="w-20 ml-2">
              <DatePicker
                format={dateFormat}
                allowClear={false}
                disabled={!couponExpiry}
                disabledDate={current => current && current < moment().subtract(1, 'days')}
                onChange={onExpiryDateChange}
              />
              <SpError>
                {couponExpiry && simpleValidator.current.message('date', couponDate, 'required')}
              </SpError>
            </div>
            <div className="w-20 ml-2">
              <TimePicker
                disabled={!couponExpiry}
                onChange={onExpiryTimeChange}
                allowClear={false}
              />
              <SpError>
                {couponExpiry && simpleValidator.current.message('time', couponTime, 'required')}
              </SpError>
            </div>
          </div>
          {
            validity !== 'ONCE' && (
              <>
                <div className="mb-2">
                  <Checkbox
                    className="mr-2"
                    checked={couponRedeemed}
                    onChange={({ target: { checked } }) => {
                      setCouponRedeemed(checked);
                    }}
                  />
                  <SpText>Limit the number of times this coupon can redeemed</SpText>
                </div>
                <div className="w-50">
                  <Input
                    suffix="time"
                    disabled={!couponRedeemed}
                    onChange={(e) => setMaximumRedemption(e.currentTarget.value)}
                    value={maximumRedemption}
                  />
                  <SpError>
                    {couponRedeemed && simpleValidator.current.message('coupon redeemed', maximumRedemption, 'required|integer')}
                  </SpError>
                </div>
              </>
            )
          }
        </div>
        <div className="pb-3">
          <div className="d-flex align-items-center mb-3">
            <Switch
              className="border-light"
              checked={promotional}
              onChange={(e) => setPromotional(e)}
            />
            <SpH5 className="ml-3">Add customer-facing promotional codes</SpH5>
          </div>
          {
            promotional && (
              <>
                <div className="mb-3">
                  <label htmlFor=""><SpH5>Code</SpH5></label>
                  <Input
                    placeholder="GET25OFF"
                    value={code}
                    onChange={(e) => setCode((e.currentTarget.value).toUpperCase())}
                  />
                  <SpError>
                    {promotional && simpleValidator.current.message('code', code, 'required')}
                  </SpError>
                </div>
                <div className="mb-2">
                  <Checkbox
                    className="mr-2"
                    checked={minAmount}
                    onChange={({ target: { checked } }) => {
                      setMinAmount(checked);
                    }}
                  />
                  <SpText>Require minimum order value</SpText>
                </div>
                <div className="w-50 pb-3">
                  <Input
                    prefix={prefix}
                    placeholder="0.00"
                    value={minimumAmount}
                    disabled={!minAmount}
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/;
                      const { value } = e.currentTarget;
                      if (regex.test(value) || value === '') {
                        setMinimumAmount(e.currentTarget.value);
                      } else if (!regex.test(value)) {
                        setMinimumAmount(null);
                      }
                    }}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      if (e.currentTarget.value) {
                        const value = formatAmount(e.currentTarget.value, currencyType);
                        setMinimumAmount(value);
                      }
                    }}
                  />
                  <SpError>
                    {minAmount && simpleValidator.current.message('order value', minimumAmount, 'required|amount')}
                  </SpError>
                </div>
                <div className="mb-2">
                  <Checkbox
                    className="mr-2"
                    checked={customer}
                    onChange={({ target: { checked } }) => {
                      setCustomer(checked);
                    }}
                  />
                  <SpText>Limit to a specific customer</SpText>
                </div>
                <div className="pb-3">
                  <Select
                    className="w-100"
                    onSelect={(e) => selectCustomer(e)}
                    onSearch={searchCustomer}
                    suffix="time"
                    showSearch
                    value={customerName}
                    disabled={!customer}
                  >
                    {
                      allCustomers.map(item => (
                        <Option
                          key={item.id}
                          value={[
                            item.name,
                            item.gid,
                          ]}
                        >
                          {item.name}
                        </Option>
                      ))
                    }
                  </Select>
                  <SpError>
                    {customer && simpleValidator.current.message('customer', customerGid, 'required')}
                  </SpError>
                </div>
                <div className="mb-2">
                  <Checkbox
                    className="mr-2"
                    checked={promoExpiry}
                    onChange={({ target: { checked } }) => {
                      setPromoExpiry(checked);
                    }}
                  />
                  <SpText>Add an expiration date for the promotional code</SpText>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="w-30">
                    <SpText>Redeem by</SpText>
                  </div>
                  <div className="w-20 ml-2">
                    <DatePicker
                      format={dateFormat}
                      allowClear={false}
                      disabled={!promoExpiry}
                      disabledDate={current => current && current < moment().subtract(1, 'days')}
                      onChange={promoExpiryDateChange}
                    />
                    <SpError>
                      {promoExpiry && simpleValidator.current.message('date', promoDate, 'required')}
                    </SpError>
                  </div>
                  <div className="w-20 ml-2">
                    <TimePicker
                      disabled={!promoExpiry}
                      onChange={promoExpiryTimeChange}
                      allowClear={false}
                    />
                    <SpError>
                      {promoExpiry && simpleValidator.current.message('time', promoTime, 'required')}
                    </SpError>
                  </div>
                </div>
                <div className="mb-2">
                  <Checkbox
                    className="mr-2"
                    checked={promoRedeemed}
                    onChange={({ target: { checked } }) => {
                      setPromoRedeemed(checked);
                    }}
                  />
                  <SpText>Limit the number of times this promotional code can redeemed</SpText>
                </div>
                <div className="w-50">
                  <Input
                    suffix="time"
                    value={promotionalRedeemed}
                    disabled={!promoRedeemed}
                    onChange={(e) => setPromotionalRedeemed(e.currentTarget.value)}
                  />
                  <SpError>
                    {promoRedeemed && simpleValidator.current.message('redeemed', promotionalRedeemed, 'required|redeemed')}
                  </SpError>
                </div>
              </>
            )
          }
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddNewCoupon);
