import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  BENEFICIARYDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchBeneficiaryDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.WALLETBENEFICIARY}/${gid}`);

export function* getBeneficiaryDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchBeneficiaryDetails, action.payload);
    yield put({ type: BENEFICIARYDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: BENEFICIARYDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
