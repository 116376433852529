// @flow
import React, {
  useRef,
  useState,
  createRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Steps,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  notificationActions,
} from 'store/actions';
import { SpButton } from 'components/DesignKit';
import Details from './Details';
import RateDetails from './RateDetails';
import ReviewRate from './ReviewRate';

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  submit: Function,
  selectedName: String,
  seletedRecord: Object,
};

const { Step } = Steps;

const CreateOrder = (props: Props) => {
  const {
    visible,
    close,
    submitting,
    selectedAccount,
    submit,
    selectedName,
    seletedRecord,
  } = props;

  const [, forceUpdate] = useState();
  const searchRateRef = createRef();
  const rateDetailsRef = createRef();
  const addressDetailsRef = createRef();
  const [refArray, setRefArray] = useState([searchRateRef, rateDetailsRef, addressDetailsRef]);
  const [current, setCurrent] = useState(0);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [poNumber, setPoNumber] = useState();
  const [notes, setNotes] = useState('');
  const [billingFrequency, setBillingFrequency] = useState();
  const [subTotal, setSubTotal] = useState();
  const [shippingCost, setShippingCost] = useState();
  const [promotionalCredits, setPromotionalCredits] = useState();
  const [totalAmountDue, setTotalAmountDue] = useState();
  const currencyType = selectedAccount && selectedAccount.currency;

  const searchRateData = {
    quantity,
    poNumber,
    notes,
  };

  const addRateData = {
    billingFrequency,
    subTotal,
    shippingCost,
    promotionalCredits,
    totalAmountDue,
  };

  useEffect(() => {
    if (current === 2 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const next = async () => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setQuantity(valuesArray[0].quantity);
      setPoNumber(valuesArray[0].poNumber);
      setNotes(valuesArray[0].notes);
      setCurrent(current + 1);
    } else if (current === 1) {
      validationArray.push(refArray[1].current.validate());
      const rateDetails = refArray[1].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setBillingFrequency(rateDetails.billingFrequency);
      setSubTotal(rateDetails.subTotal);
      setShippingCost(rateDetails.shippingCost);
      setPromotionalCredits(rateDetails.promotionalCredits);
      setTotalAmountDue(rateDetails.totalAmountDue);
      setCurrent(current + 1);
    } else if (current === 2) {
      validationArray.push(refArray[2].current.validate());
      const addressDetails = refArray[2].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      const address = {
        city: addressDetails.cityName,
        countryCode: addressDetails.countryId,
        deleted: false,
        postalCode: addressDetails.zipCode,
        state: addressDetails.stateName,
        street: addressDetails.streetName,
      };
      const emailId = {
        confirmationEmail: addressDetails.email,
        contactPhone: `${addressDetails.countryCode}${addressDetails.phoneNumber}`,
      };
      setDisableButton(true);
      const payload = Object.assign(searchRateData, addRateData, address, emailId);
      submit(
        payload,
      );
    }
  };

  const prev = () => {
    setDisableButton(false);
    if (current === 0) {
      close();
    } else if (current === 1) {
      setCurrent(current - 1);
    } else if (current === 2) {
      setCurrent(current - 1);
    } else if (current === 3) {
      setCurrent(current - 1);
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="540px"
      footer={current === 0
        ? [
          <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
          <SpButton key="2" onClick={next}>Next</SpButton>,
        ]
        : current === 1
          ? [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next}>Next</SpButton>,
          ]
          : [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next} disabled={disableButton}>Place order</SpButton>,
          ]}
      title={selectedName}
    >
      <>
        <div style={{ marginTop: '-8px' }}>
          <Steps current={current} className="subscriptions">
            <Step key="1" title="Details" />
            <Step key="2" title="Payment" />
            <Step key="3" title="Shipping" />
          </Steps>
        </div>
        <div className="px-5 mt-2">
          <div className={submitting ? 'OVERLAY' : ''} />
          {current === 0 && (
            <Details
              ref={refArray[0]}
              selectedAccount={selectedAccount}
              searchRateData={searchRateData}
              seletedRecord={seletedRecord}
            />
          )}
          {current === 1 && (
            <RateDetails
              ref={refArray[1]}
              selectedAccount={selectedAccount}
              currencyType={currencyType}
              seletedRecord={seletedRecord}
              searchRateData={searchRateData}
            />
          )}
          {current === 2 && (
            <ReviewRate
              ref={refArray[2]}
              selectedAccount={selectedAccount}
              seletedRecord={seletedRecord}
            />
          )}
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder);
