/* eslint-disable */
import React from 'react';

const copyclipboard = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25">
        <g fill="none" fillRule="evenodd">
            <g fill="#515967" fillRule="nonzero">
                <g>
                    <path d="M16.184 0H2.312C1.035 0 0 1.016 0 2.27v15.886h2.312V2.27h13.872V0z" transform="translate(-779 -1239) matrix(-1 0 0 1 801 1239)" />
                    <path d="M19.652 4.539H6.936c-1.277 0-2.312 1.016-2.312 2.27v15.886c0 1.254 1.035 2.27 2.312 2.27h12.716c1.277 0 2.312-1.016 2.312-2.27V6.81c0-1.254-1.035-2.27-2.312-2.27zm0 18.156H6.936V6.81h12.716v15.886z" transform="translate(-779 -1239) matrix(-1 0 0 1 801 1239)" />
                </g>
            </g>
        </g>
    </svg>
);

export default copyclipboard;