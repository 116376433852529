import { PAYMENTPAGESDETAILS } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  orders: [],
  payments: [],
  offlinePayments: [],
  paymentSlug: [],
  orderDetails: {},
  orderTimeline: [],
  paymentReports: [],
  paymentPagesEntries: [],
  splitUp: {},
  paymentPagesDetailsStatus: undefined,
  paymentPagesDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENTPAGESDETAILS.FETCH:
      return {
        ...state,
        paymentPagesDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case PAYMENTPAGESDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        paymentPagesDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case PAYMENTPAGESDETAILS.FAILED:
      return {
        ...state,
        paymentPagesDetailsError: action.payload,
        paymentPagesDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case PAYMENTPAGESDETAILS.PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
      };
    case PAYMENTPAGESDETAILS.ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      };
    case PAYMENTPAGESDETAILS.ORDER_SUCCESS_BY_ID:
      return {
        ...state,
        orderDetails: action.payload,
      };
    case PAYMENTPAGESDETAILS.SUCCESS_TIMELINE:
      return {
        ...state,
        orderTimeline: action.payload,
      };
    case PAYMENTPAGESDETAILS.SLUG_SUCCESS:
      return {
        ...state,
        paymentSlug: action.payload,
      };
    case PAYMENTPAGESDETAILS.SPLIT_UP_SUCCESS:
      return {
        ...state,
        splitUp: action.payload,
      };
    case PAYMENTPAGESDETAILS.SUCCESS_OFFLINE_PAYMENTS:
      return {
        ...state,
        offlinePayments: action.payload,
      };
    case PAYMENTPAGESDETAILS.SUCCESS_PAYMENTS_REPORT:
      return {
        ...state,
        paymentReports: action.payload,
      };
    case PAYMENTPAGESDETAILS.SUCCESS_PAYMENTS_PAGE_ENTRIES:
      return {
        ...state,
        paymentPagesEntries: action.payload,
      };
    default:
      return state;
  }
};
