import { FEEDBACK } from '../actionTypes';

const initialState = {
  content: [],
  orderFeedback: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FEEDBACK.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
      case FEEDBACK.ORDER_FEEDBACK_FETCH_SUCCESS:
        return {
          ...state,
          orderFeedback:action.payload
        }
    default:
      return state;
  }
};

