// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import {
  SpButton,
  SpStatusTag,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import getSubscriptionButtonBackground from 'utils/getSubscriptionButtonBackground';
import getSubscriptionButtonStatus from 'utils/getSubscriptionButtonStatus';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getFilterOperators from 'utils/getFilterOperators';

import {
  subscriptionButtonV2Actions,
  notificationActions,
  planv2Actions,
  subscriptionv2Actions,
  invoiceDetailsAction,
  filterParamsActions,
} from 'store/actions';
import {
  axiosAuthInstance2,
  API_END_POINTS,
} from 'api';
import { NOTIFICATION, FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  MESSAGES,
  FILTERS_TYPES,
} from 'appconstants';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import RBAC from 'components/rbac';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import Clipboard from 'components/NavIcons/copyclipboard';
import AddSubscriptionButton from './components/AddSubscriptionButton';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const SUBSCRIPTION_BUTTON_FILTERS_TYPES = FILTERS_TYPES.SUBSCRIPTION_BUTTON;

type Props = {
  subscriptionButton: Array<Object>,
  fetchSubscriptionButtonV2: Function,
  fetchPlanV2: Function,
  fetchPlanV2Search: Function,
  addSubscriptionButtonV2: Function,
  setNotification: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  plans: Array<Object>,
  planResult: Array<Object>,
  history: {
    push: Function,
  },
  getCustomersList: Function,
  setFilterData: Function,
  preserveFilters: Object,
};

const SubscriptionButton = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    subscriptionButton,
    setNotification,
    loading,
    addSubscriptionButtonV2,
    fetchSubscriptionButtonV2,
    fetchPlanV2,
    fetchPlanV2Search,
    totalElements,
    submitting,
    validator,
    test,
    history,
    plans,
    planResult,
    getCustomersList,
    setFilterData,
    preserveFilters,
  } = props;

  const [pagination, setPagination] = useState({});
  const [addSubscriptionButtonModal, setAddSubscriptionButtonModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [id, setId] = useState('');
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(SUBSCRIPTION_BUTTON_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterPayments, setFilterPayments] = useState((preserveFilters && preserveFilters.filterPayments)
    ? (preserveFilters && preserveFilters.filterPayments)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [clearFilters, setClearFilters] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [itemModalRedirect, setItemModalRedirect] = useState('');
  const [addInventoryItemRedirect, setAddInventoryItemRedirect] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [planList, setPlanList] = useState([]);
  const [couponRedirect, setCouponRedirect] = useState(false);
  const [taxRedirect, setTaxRedirect] = useState(false);
  const [subscriptionRedirect, setSubscriptionRedirect] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterPayments: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });
  const supportedCurrency = (selectedAccount && selectedAccount.supportedCurrencies);

  const planData = {
    planList,
  };

  const exportData = subscriptionButton ? subscriptionButton.map((values) => ({
    amount: `${values.currency.prefix}${formatNumber((values.amount / 100).toFixed(values.currency.toFixed), currencyType)}`,
    name: values.plan2.name,
    description: values.description,
    status: getSubscriptionButtonStatus(values.status),
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
  })) : '';

  const exportHeaders = [
    { label: 'AMOUNT', key: 'amount' },
    { label: 'NAME', key: 'name' },
    { label: 'DESCRIPTION', key: 'description' },
    { label: 'STATUS', key: 'status' },
    { label: 'CREATED ON', key: 'createdAt' },
  ];

  const debouncedFetchSubscriptionButtonV2 = debounce(fetchSubscriptionButtonV2, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const planParams = '?page=1&size=100';
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchSubscriptionButtonV2({ currentPage, sortParams, filterParams });
    if (!clearFilters) {
      fetchPlanV2({ planParams, sortParams });
      getCustomersList();
    }
  };

  useEffect(() => {
    getData(location.search);
    if (selectedAccount && selectedAccount.country) {
      setId(selectedAccount.currency.id);
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (plans && plans.length !== 0) {
      setPlanList(plans);
    }
  }, [plans]);

  useEffect(() => {
    if (!validator && !couponRedirect && !taxRedirect && !subscriptionRedirect && !addInventoryItemRedirect) {
      setAddSubscriptionButtonModal(false);
    } else if (!validator && couponRedirect) {
      setCouponRedirect(false);
    } else if (!validator && taxRedirect) {
      setTaxRedirect(false);
    } else if (!validator && addInventoryItemRedirect) {
      setAddInventoryItemRedirect(false);
    }
  }, [validator]);

  const fetchPlanDetails = (search) => {
    fetchPlanV2Search(search);
  };

  useEffect(() => {
    setPlanList(planResult || planList);
  }, [planResult]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [subscriptionButton]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addNewSubscriptionButton = async (planInfo: Object) => {
    setSubscriptionRedirect(false);
    const {
      name,
      description,
      period,
      totalCount,
      qty,
      frequency,
      apiPlanDate,
      redirectUrl,
      currencyCode,
      lineItemDtoList,
      allSelectedTaxrates,
      updatedCoupon,
    } = planInfo;
    let { planId } = planInfo;
    const couponId = updatedCoupon.gid ? updatedCoupon.gid : null;
    const taxesList = [];
    const listOfTaxes = [...allSelectedTaxrates];
    for (let i = 0; i < listOfTaxes.length; i += 1) {
      taxesList.push(listOfTaxes[i].taxRates.gid);
    }
    if (!planId) {
      const planObject = {
        name,
        description,
        currencyCode,
        billingFrequency: frequency,
        billingPeriod: period,
      };
      try {
        const { data: { entity: { gid } } } = await axiosAuthInstance2.post(API_END_POINTS.PLAN, planObject);
        planId = gid;
      } catch (error) {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: error.response.data && error.response.data.message,
        });
      }
    }
    addSubscriptionButtonV2({
      currencyCode,
      plan2Gid: planId,
      couponGid: couponId,
      description,
      planQuantity: qty,
      taxRates: taxesList,
      plan2StartDate: apiPlanDate,
      plan2TotalPayments: totalCount,
      plan2BillingPeriod: period,
      plan2BillingFrequency: frequency,
      redirectUri: redirectUrl,
      subscription2ButtonLineItems: lineItemDtoList,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.SUBSCRIPTION_BUTTON,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.SUBSCRIPTION_BUTTON,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const selectedRecord = (record) => {
    const subscriptionButtonGid = record.gid;
    history.push({
      pathname: `${PAGES.SUBSCRIPTION_BUTTON}/${subscriptionButtonGid}`,
      state: location.search,
    });
  };

  const copyCode = (record) => {
    const el = record.link;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUBSCRIPTION_BUTTON.SUCCESS,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterPayments];
    if (filterPayments.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = SUBSCRIPTION_BUTTON_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterPayments(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterPayments];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'planAmount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = SUBSCRIPTION_BUTTON_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterPayments(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterPayments(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterPayments(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterPayments[index].filterName === 'createdAt') {
      if (filterPayments[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterPayments[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterPayments[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterPayments[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterPayments];
    list[index].filterValue = selectedValue;
    setFilterPayments(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterPayments];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterPayments[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterPayments(list);
  };

  const handleAddClick = () => {
    const list = [...filterPayments];
    setFilterPayments([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setClearFilters(true);
    setFilterData({
      pathname,
      search: '',
    });
    setFilterPayments([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(SUBSCRIPTION_BUTTON_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.SUBSCRIPTION_BUTTON);
  };

  const applyFilters = () => {
    const paymentsFilters = filterPayments.filter((item) => item.checked);
    for (let i = 0; i < paymentsFilters.length; i += 1) {
      if (!paymentsFilters[i].filterName || !paymentsFilters[i].filterOperator || !paymentsFilters[i].filterValue) {
        return;
      }
    }
    if (paymentsFilters[0].filterValue || (paymentsFilters[0].filterValueFrom && paymentsFilters[0].filterValueTo)) {
      setCount(paymentsFilters && paymentsFilters.length);
      for (let i = 0; i < paymentsFilters.length; i += 1) {
        if (paymentsFilters[i].checked) {
          if (paymentsFilters[i].filterName === 'createdAt') {
            paymentsFilters[i] = paymentsFilters[i].filterValue;
          } else if (paymentsFilters[i].filterName === 'planAmount' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'planAmount' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else {
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${paymentsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(paymentsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = parsed.pageNum || 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.SUBSCRIPTION_BUTTON,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.SUBSCRIPTION_BUTTON,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const subScriptionModal = (val) => {
    setSubscriptionRedirect(val);
  };

  const cancelAddSubscription = () => {
    setAddSubscriptionButtonModal(false);
  };


  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '18%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const totalAmount = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <span> {`${record.currency.prefix} ${formatNumber(totalAmount, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'plan2.name',
      width: '18%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '18%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (description) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {description || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '18%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <SpStatusTag style={{ backgroundColor: getSubscriptionButtonBackground(status) }}>
            {getSubscriptionButtonStatus(status)}
          </SpStatusTag>
        </Tooltip>
      ),
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '15%',
    align: 'center',
    render: (text, record) => {
      const { status } = record;
      if (status !== 'DEACTIVATED' && status !== 'DELETED') {
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              onClick={(e) => { e.stopPropagation(); copyCode(record); }}
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      }
      return (
        <span>
          <SpButton
            type="secondary"
            borderRadius="50px"
            disabled
          >
            <Clipboard />
          </SpButton>
        </span>
      );
    },
  };
  const hanldleItemModalRedirect = (val) => {
    setItemModalRedirect(val);
  };
  const hanldleAddInventoryRedirect = (val) => {
    setAddInventoryItemRedirect(val);
  };
  if ((loading && !itemModalRedirect) || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                ghost
                className="mr-3"
                onClick={() => setAddSubscriptionButtonModal(true)}
              >
                {'\u002B'}&nbsp;Add Subscription Button
              </SpButton>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Subscription Buttons">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Subscription Button Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Subscription Buttons">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-35px' }}
                  >
                    <Filters
                      filtersGrid={filterPayments}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                      handleFromValue={handleFromValue}
                      handleToValue={handleToValue}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterPayments.length < SUBSCRIPTION_BUTTON_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD SUBSCRIPTIONBUTTON MODAL */}
      {addSubscriptionButtonModal && (
        <AddSubscriptionButton
          visible={addSubscriptionButtonModal}
          fetchPlanDetails={fetchPlanDetails}
          planData={planData}
          submitting={submitting}
          phoneCode={phoneCode}
          id={id}
          selectedAccount={selectedAccount}
          close={cancelAddSubscription}
          submit={addNewSubscriptionButton}
          supportedCurrency={supportedCurrency}
          subScriptionModal={subScriptionModal}
          hanldleItemModalRedirect={hanldleItemModalRedirect}
          hanldleAddInventoryRedirect={hanldleAddInventoryRedirect}
        />
      )}
      <RBAC
        fallback={(
          <Table
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={subscriptionButton}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
      >
        <Table
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={subscriptionButton}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  subscriptionButton: state.subscriptionButtonV2.content,
  plans: state.planv2.content,
  planResult: state.subscriptionv2.plans.content,
  totalElements: state.subscriptionButtonV2.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  customersList: state.invoiceDetails.customersList,
  taxList: state.invoiceDetails.taxList,
  newCoupon: state.invoiceDetails.newCoupon,
  newTaxrate: state.invoiceDetails.newTaxrate,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptionButtonV2: param => dispatch(subscriptionButtonV2Actions.fetchSubscriptionButtonV2({
    payload: param,
  })),
  fetchPlanV2: param => dispatch(planv2Actions.fetchPlanV2({
    payload: param,
  })),
  fetchPlanV2Search: param => dispatch(subscriptionv2Actions.fetchPlanV2Search({
    payload: param,
  })),
  addSubscriptionButtonV2: param => dispatch(subscriptionButtonV2Actions.addSubscriptionButtonV2({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  addNewCoupon: param => dispatch(invoiceDetailsAction.addNewCoupon({
    payload: param,
  })),
  getCustomersList: param => dispatch(invoiceDetailsAction.getCustomersList({
    payload: param,
  })),
  addNewTaxRate: param => dispatch(invoiceDetailsAction.addNewTaxRate({
    payload: param,
  })),
  getTaxes: param => dispatch(invoiceDetailsAction.getTaxes({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionButton);
