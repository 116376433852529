import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Row,
  Col,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
} from 'components/DesignKit';

type Props = {
    userProfile: Object,
};

const View = (props: Props) => {
  const {
    givenName,
    familyName,
    email,
    phoneNumber,
  } = props.userProfile;

  const [phoneFormate, setPhoneFormate] = useState('');

  useEffect(() => {
    if (phoneNumber) {
      const cntryCode = phoneNumber.substring(0, 2);
      if (cntryCode === '+1') {
        setPhoneFormate('+# (###) ###-####');
      } else if (cntryCode === '+9') {
        setPhoneFormate('+##-##### #####');
      }
    }
  }, [phoneNumber]);

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Given Name</SpText>
          </Col>
          <Col span={9}>
            <SpH5>{givenName}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Family Name</SpText>
          </Col>
          <Col span={9}>
            <SpH5>{familyName}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <SpText>Email</SpText>
          </Col>
          <Col span={9}>
            <SpH5>{email}</SpH5>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start">
          <Col span={3}>
            <SpText>Phone</SpText>
          </Col>
          <Col span={6}>
            <NumberFormat
              className="numFormate"
              value={phoneNumber}
              format={phoneFormate}
              mask="_"
              disabled
            />
          </Col>
        </Row>
      </SpForm>
    </>
  );
};

export default React.memo(View);
