import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Checkbox,
  Icon,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpText,
  SpH5,
  SpButton,
} from 'components/DesignKit';
import Loading from 'components/Loading';
import {
  LINKS_WITH_EXPIRY,
  LINKS_WITHOUT_EXPIRY,
  INVOICE_REMINDERS,
  SUBSCRIPTION_EXPIRY,
} from 'appconstants';

import { remindersActions } from 'store/actions';
import getReminderLabels from 'utils/getReminderLabels';

const { Option } = Select;

type Props = {
  loading: boolean,
  selectedAccount: Object,
  sendReminders: Function,
  fetchReminders: Function,
  remindersList: Array<Object>,
};

const SupportSettings = (props: Props) => {
  const {
    loading,
    selectedAccount,
    sendReminders,
    fetchReminders,
    remindersList,
  } = props;
  const [, forceUpdate] = useState();
  const [email, setEmail] = useState(false);
  const [sms, setSms] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [linksWithExpiry, setLinksWithExpiry] = useState([]);
  const [linksWithNoExpiry, setLinksWithNoExpiry] = useState([]);
  const [invoiceReminders, setInvoiceReminders] = useState([]);
  const [withExpiry, setWithExpiry] = useState(LINKS_WITH_EXPIRY);
  const [withOutExpiry, setWithOutExpiry] = useState(LINKS_WITHOUT_EXPIRY);
  const [reminders, setReminders] = useState(INVOICE_REMINDERS);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [types, setTypes] = useState('');

  const getReminders = () => {
    fetchReminders();
  };

  useEffect(() => {
    fetchReminders();
  }, [selectedAccount]);

  useEffect(() => {
    if (remindersList && remindersList.length !== 0) {
      if (remindersList[0].type === 'SMS') {
        setSms(true);
        setEmail(false);
        setTypes('SMS');
      } else if (remindersList[0].type === 'EMAIL') {
        setSms(false);
        setEmail(true);
        setTypes('EMAIL');
      } else if (remindersList[0].type === 'ALL') {
        setSms(true);
        setEmail(true);
        setTypes('ALL');
      }
      const allReminderList = [...remindersList];
      for (let i = 0; i < allReminderList.length; i += 1) {
        allReminderList[i].value = allReminderList[i].settings;
        allReminderList[i].name = getReminderLabels(allReminderList[i].settings);
      }
      const paymentLinkList = allReminderList.filter(item => item.value.split('_')[0] === 'PAYMENT');
      const invoiceList = allReminderList.filter(item => item.value.split('_')[0] === 'INVOICE');
      const subscriptionList = allReminderList.filter(item => item.value.split('_')[0] === 'SUBSCRIPTION');

      const paymentWthExpiryList = paymentLinkList.filter(item => item.value.split('_').length === 6);
      const withExpiryListOptions = [...new Map(paymentWthExpiryList.map(item => [item.value, item])).values()];
      setLinksWithExpiry(withExpiryListOptions);
      setWithExpiry(LINKS_WITH_EXPIRY.filter(({ value: id1 }) => !withExpiryListOptions.some(({ value: id2 }) => id2 === id1)));

      const paymentNoExpiryList = paymentLinkList.filter(item => item.value.split('_').length === 7);
      const noExpiryListOptions = [...new Map(paymentNoExpiryList.map(item => [item.value, item])).values()];
      setLinksWithNoExpiry(noExpiryListOptions);
      setWithOutExpiry(LINKS_WITHOUT_EXPIRY.filter(({ value: id1 }) => !noExpiryListOptions.some(({ value: id2 }) => id2 === id1)));

      const invoiceListOptions = [...new Map(invoiceList.map(item => [item.value, item])).values()];
      setInvoiceReminders(invoiceListOptions);
      setReminders(INVOICE_REMINDERS.filter(({ value: id1 }) => !invoiceListOptions.some(({ value: id2 }) => id2 === id1)));
      setSubscription(subscriptionList.length !== 0);
    } else {
      setLinksWithExpiry([]);
      setLinksWithNoExpiry([]);
      setInvoiceReminders([]);
      setWithExpiry(LINKS_WITH_EXPIRY);
      setWithOutExpiry(LINKS_WITHOUT_EXPIRY);
      setReminders(INVOICE_REMINDERS);
      setEmail(false);
      setSms(false);
      setSubscription(false);
    }
  }, [remindersList]);

  const addLinksWithExpiry = (event) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setLinksWithExpiry([...linksWithExpiry, {
      id: linksWithExpiry.length || 0,
      name: '',
      value: '',
    }]);
  };

  const handleRemoveWithExpiry = index => {
    const list = [...linksWithExpiry];
    const selectedOption = {
      id: list[index].id,
      display: list[index].name,
      value: list[index].value,
    };
    list.splice(index, 1);
    const option = LINKS_WITH_EXPIRY.filter((item) => (item.value === selectedOption.value));
    const options = withExpiry.concat(option);
    setWithExpiry(options.sort((a, b) => (a.id - b.id)));
    setLinksWithExpiry(list);
  };

  const handleWithExpiry = (e, index) => {
    const list = [...linksWithExpiry];
    list[index].id = e[0];
    list[index].value = e[1];
    list[index].name = e[2];
    const listOptions = LINKS_WITH_EXPIRY.filter(({ value: id1 }) => !list.some(({ value: id2 }) => id2 === id1));
    setWithExpiry(listOptions);
    setLinksWithExpiry(list);
  };

  const addLinksWithNoExpiry = (event) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setLinksWithNoExpiry([...linksWithNoExpiry, {
      id: linksWithNoExpiry.length || 0,
      name: '',
      value: '',
    }]);
  };

  const handleRemoveWithNoExpiry = index => {
    const list = [...linksWithNoExpiry];
    const selectedOption = {
      id: list[index].id,
      display: list[index].name,
      value: list[index].value,
    };
    list.splice(index, 1);
    const option = LINKS_WITHOUT_EXPIRY.filter((item) => (item.value === selectedOption.value));
    const options = withOutExpiry.concat(option);
    setWithOutExpiry(options.sort((a, b) => (a.id - b.id)));
    setLinksWithNoExpiry(list);
  };

  const handleWithNoExpiry = (e, index) => {
    const list = [...linksWithNoExpiry];
    list[index].id = e[0];
    list[index].value = e[1];
    list[index].name = e[2];
    const listOptions = LINKS_WITHOUT_EXPIRY.filter(({ value: id1 }) => !list.some(({ value: id2 }) => id2 === id1));
    setWithOutExpiry(listOptions);
    setLinksWithNoExpiry(list);
  };

  const addInvoiceReminders = (event) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setInvoiceReminders([...invoiceReminders, {
      id: invoiceReminders.length || 0,
      name: '',
      value: '',
    }]);
  };

  const handleRemoveInvoice = index => {
    const list = [...invoiceReminders];
    const selectedOption = {
      id: list[index].id,
      display: list[index].name,
      value: list[index].value,
    };
    list.splice(index, 1);
    const option = INVOICE_REMINDERS.filter((item) => (item.value === selectedOption.value));
    const options = reminders.concat(option);
    setReminders(options.sort((a, b) => (a.id - b.id)));
    setInvoiceReminders(list);
  };

  const handleInvoice = (e, index) => {
    const list = [...invoiceReminders];
    list[index].id = e[0];
    list[index].value = e[1];
    list[index].name = e[2];
    const listOptions = INVOICE_REMINDERS.filter(({ value: id1 }) => !list.some(({ value: id2 }) => id2 === id1));
    setReminders(listOptions);
    setInvoiceReminders(list);
  };

  const sendReminder = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    let payload;
    let type = '';
    if (email && sms) {
      type = 'ALL';
    } else if (!email && sms) {
      type = 'SMS';
    } else if (email && !sms) {
      type = 'EMAIL';
    }
    const remidersList = [...linksWithExpiry, ...linksWithNoExpiry, ...invoiceReminders];
    for (let i = 0; i < remidersList.length; i += 1) {
      remidersList[i].type = type;
      remidersList[i].settings = remidersList[i].value;
      delete remidersList[i].value;
      delete remidersList[i].name;
    }

    let sendSubscription = {};
    if (subscription) {
      sendSubscription = {
        type,
        settings: SUBSCRIPTION_EXPIRY.value,
      };
      payload = [...remidersList, sendSubscription];
    } else if (!subscription) {
      payload = [...remidersList];
    }
    sendReminders({
      payload,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2 mb-3">
        <Col>
          <SpText fontSize="20px">Channels</SpText>
        </Col>
        <Col className="d-flex mt-2">
          <div className="d-flex mr-5">
            <Checkbox
              checked={sms}
              className="mb-1 mr-2"
              onChange={({ target: { checked } }) => {
                setSms(checked);
                if (checked) {
                  setTypes('SMS');
                } else if (!checked && !email) {
                  setTypes('');
                }
              }}
            />
            SMS
          </div>
          <div className="d-flex">
            <Checkbox
              checked={email}
              className="mb-1 mr-2"
              onChange={({ target: { checked } }) => {
                setEmail(checked);
                if (checked) {
                  setTypes('EMAIL');
                } else if (!checked && !sms) {
                  setTypes('');
                }
              }}
            />
            EMAIL
          </div>
        </Col>
        <div className="text-danger">
          {simpleValidator.current.message('channels', types, 'required')}
        </div>
      </Row>
      <Row className="my-2 mb-3">
        <Col>
          <SpText fontSize="20px">Payment Links</SpText>
        </Col>
        <Col className="mt-3">
          <SpH5 fontSize="20px">For links with expiry</SpH5>
          {linksWithExpiry.map((item, i) => (
            <div>
              <div className="w-25 mt-2 d-flex">
                <Select
                  value={item.name || 'Select a reminder'}
                  style={{ width: '100%' }}
                  showSearch
                  onSelect={e => handleWithExpiry(e, i)}
                >
                  {withExpiry.map((reminder) => (
                    <Option
                      key={reminder.id}
                      value={[
                        reminder.id,
                        reminder.value,
                        reminder.display,
                      ]}
                    >
                      {reminder.display}
                    </Option>
                  ))}
                </Select>
                <Icon
                  type="minus-circle"
                  className="ml-2 mt-2"
                  style={{ cursor: 'pointer', color: '#ff4d50' }}
                  onClick={() => handleRemoveWithExpiry(i)}
                />
              </div>
              <div className="text-danger">
                {simpleValidator.current.message('payment links', linksWithExpiry[i].value, 'required')}
              </div>
            </div>
          ))}
          {
            linksWithExpiry.length <= 2 && (
              <div className="pt-1 pb-1 mt-2" style={{ cursor: 'pointer' }}>
                <SpText
                  fontWeight="600"
                  color="#279dfe"
                  onClick={addLinksWithExpiry}
                >
                  {'\u002B'}&nbsp; Add Reminder
                </SpText>
              </div>
            )
          }
        </Col>
        <Col className="mt-3">
          <SpH5 fontSize="20px">For links without expiry</SpH5>
          {linksWithNoExpiry.map((item, i) => (
            <div>
              <div className="w-25 mt-2 d-flex">
                <Select
                  value={item.name || 'Select a reminder'}
                  style={{ width: '100%' }}
                  showSearch
                  onSelect={e => handleWithNoExpiry(e, i)}
                >
                  {withOutExpiry.map((reminder) => (
                    <Option
                      key={reminder.id}
                      value={[
                        reminder.id,
                        reminder.value,
                        reminder.display,
                      ]}
                    >
                      {reminder.display}
                    </Option>
                  ))}
                </Select>
                <Icon
                  type="minus-circle"
                  className="ml-2 mt-2"
                  style={{ cursor: 'pointer', color: '#ff4d50' }}
                  onClick={() => handleRemoveWithNoExpiry(i)}
                />
              </div>
              <div className="text-danger">
                {simpleValidator.current.message('payment links', linksWithNoExpiry[i].value, 'required')}
              </div>
            </div>
          ))}
          {
            linksWithNoExpiry.length <= 2 && (
              <div className="pt-1 pb-1 mt-2" style={{ cursor: 'pointer' }}>
                <SpText
                  fontWeight="600"
                  color="#279dfe"
                  onClick={addLinksWithNoExpiry}
                >
                  {'\u002B'}&nbsp; Add Reminder
                </SpText>
              </div>
            )
          }
        </Col>
      </Row>
      <Row className="my-2 mb-3">
        <Col>
          <SpText fontSize="20px">Subscription</SpText>
        </Col>
        <Col className="mt-3">
          <div className="d-flex mt-1">
            <Checkbox
              checked={subscription}
              className="mb-1 mr-2"
              onChange={({ target: { checked } }) => {
                setSubscription(checked);
              }}
            />
            Send emails about expiring cards
          </div>
        </Col>
      </Row>
      <Row className="my-2 mb-3">
        <Col>
          <SpText fontSize="20px">Invoice</SpText>
          {invoiceReminders.map((item, i) => (
            <div>
              <div className="w-25 mt-2 d-flex">
                <Select
                  value={item.name || 'Select a reminder'}
                  style={{ width: '100%' }}
                  showSearch
                  onSelect={e => handleInvoice(e, i)}
                >
                  {reminders.map((reminder) => (
                    <Option
                      key={reminder.id}
                      value={[
                        reminder.id,
                        reminder.value,
                        reminder.display,
                      ]}
                    >
                      {reminder.display}
                    </Option>
                  ))}
                </Select>
                <Icon
                  type="minus-circle"
                  className="ml-2 mt-2"
                  style={{ cursor: 'pointer', color: '#ff4d50' }}
                  onClick={() => handleRemoveInvoice(i)}
                />
              </div>
              <div className="text-danger">
                {simpleValidator.current.message('invoice', invoiceReminders[i].value, 'required')}
              </div>
            </div>
          ))}
        </Col>
        <Col>
          {
            invoiceReminders.length <= 2 && (
              <div className="pt-1 pb-1 mt-2" style={{ cursor: 'pointer' }}>
                <SpText
                  fontWeight="600"
                  color="#279dfe"
                  onClick={addInvoiceReminders}
                >
                  {'\u002B'}&nbsp; Add Reminder
                </SpText>
              </div>
            )
          }
        </Col>
      </Row>
      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="secondary"
            shape="round"
            onClick={getReminders}
          >
            Cancel
          </SpButton>

        </Col>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={sendReminder}
          >
            Save
          </SpButton>
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = state => ({
  loading: state.loading.loading,
  validator: state.loading.validator,
  selectedAccount: state.account.selectedAccount,
  remindersList: state.reminders.content,
});

const mapDispatchToProps = dispatch => ({
  fetchReminders: param => dispatch(remindersActions.fetchReminders({
    payload: param,
  })),
  sendReminders: payload => dispatch(remindersActions.sendReminders({
    payload,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupportSettings);
