import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  Card,
  Row,
  Col,
  Tooltip,
  Icon,
  Select,
} from 'antd';
import styled from 'styled-components';
import {
  SpText,
  SpH5,
  SpButton,
  SpForm,
} from 'components/DesignKit';
import {
  PAGES,
  ORDERS_STATUSES,
  ORDERS_STATUSES_ENUM,
} from 'appconstants';
import {
  paymentPagesDetailsActions,
} from 'store/actions';
import PaymentTimeline from 'components/PaymentTimeline';
import getOrdersBackground from 'utils//getOrdersBackground';
import getOrderStatus from 'utils/getOrderStatus';
import formatAmount from 'utils/formatAmount';
import CancelIcon from 'components/NavIcons/CanceledIcon';
import UpdateIcon from 'components/NavIcons/UpdatedIcon';

const { Option } = Select;

type Props = {
    history: {
        push: Function,
        location: Object,
    };
    fetchPaymentOrderById: Function,
    selectedAccount: Object,
    test: Boolean,
    orderDetails: Object,
    fetchOrderTimeline: Function,
    orderTimeline: Object,
    splitUp: Object,
    updateOrderStatus: Function,
};

// $FlowFixMe
const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  height: '21px',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

const PaymentOrderDetails = (props: Props) => {
  const {
    history,
    fetchPaymentOrderById,
    selectedAccount,
    test,
    orderDetails,
    fetchOrderTimeline,
    orderTimeline,
    splitUp,
    updateOrderStatus,
  } = props;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const [showEdit, setShowEdit] = useState(false);
  const [status, setStatus] = useState();
  // eslint-disable-next-line react/prop-types
  const { gid } = props.match.params;
  // eslint-disable-next-line react/prop-types
  const gidPath = gid.split('-')[1];
  const [paymentItemList, setPaymentItemList] = useState([]);

  const getData = () => {
    fetchPaymentOrderById(gid);
    fetchOrderTimeline(gid);
  };

  useEffect(() => {
    if (gid) {
      getData();
    }
  }, [test, selectedAccount]);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.PAYMENTS);
    }
  }, []);

  useEffect(() => {
    if (orderDetails && orderDetails.gid) {
      setStatus(orderDetails.status);
      const { paymentPageEntries } = orderDetails.paymentPage;
      const lineItems = paymentPageEntries.filter(item => item.type.split('_')[0] === 'PRICE');
      setPaymentItemList([...lineItems]);
    }
  }, [orderDetails]);

  const updateStatus = (val) => {
    const params = { status: val };
    updateOrderStatus({ gid, params });
  };

  return (
    <>
      {
      orderDetails && orderDetails.gid && (
      <>
        <Card>
          <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
            <Col className="d-flex align-items-center">
              <SpText className="mr-5" fontSize="20px" fontWeight="600">
                {orderDetails.paymentPage.name}
              </SpText>
              <StatusTag style={{ backgroundColor: getOrdersBackground(orderDetails.status) }}>
                {getOrderStatus(orderDetails.status)}
              </StatusTag>
            </Col>
          </Row>
          <SpForm>
            <Row type="flex">
              <Col span={4}>
                <div>
                  <span>Amount</span>
                </div>
                <div>
                  <SpText fontSize="18px" fontWeight="600">{prefix} {splitUp && (splitUp.totalPayable / 100)}</SpText>
                </div>
              </Col>
              <Col span={6}>
                <div>
                  <span className="mr-3">Date</span>
                </div>
                <div>
                  <SpText fontSize="18px" fontWeight="600">
                    {
                          orderDetails.paymentSession.paymentDate && selectedAccount && selectedAccount.timezone
                            ? (
                              moment
                                .utc(orderDetails.paymentSession.paymentDate)
                                .tz(selectedAccount.timezone)
                                .format('MMM DD, YYYY, hh:mm a')
                            )
                            : <>&#8211;</>
                        }
                  </SpText>
                </div>
              </Col>
              <Col span={6}>
                <div>
                  <span>Payment Method</span>
                </div>
                {
                  orderDetails.paymentSession.paymentType && orderDetails.paymentSession.paymentType === 'INSTANT_PAYMENT' ? (
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        INSTANT PAYMENT
                      </SpText>
                    </div>
                  ) : (
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        {orderDetails.paymentSession.method}
                      </SpText>
                    </div>
                  )
                }

              </Col>
              <Col span={6}>
                <div>
                  <span>Receipt Number</span>
                </div>
                <div>
                  <SpText fontSize="18px" fontWeight="600">
                    {orderDetails.paymentSession.receiptNumber}
                  </SpText>
                </div>
              </Col>

            </Row>
          </SpForm>
          <hr />
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Status</span>
              </Col>
              {!showEdit && (
              <Col span={6}>
                <SpH5>{getOrderStatus(orderDetails.status)}</SpH5>
                <Tooltip placement="top" title="Edit">
                  <Icon
                    type="edit"
                    className="ml-3"
                    style={{ fontSize: '20px', cursor: 'pointer' }}
                    onClick={() => { setShowEdit(!showEdit); }}
                  />
                </Tooltip>
              </Col>
              )}
              {showEdit && (
              <Col span={6}>
                <div className="w-85 mt-2 d-flex">
                  <Select
                    className="ml-4"
                    defaultValue={status}
                    style={{ width: 160 }}
                    bordered
                    onChange={(value) => setStatus(value)}
                  >
                    <Option value={ORDERS_STATUSES_ENUM.OPEN}>{ORDERS_STATUSES.OPEN}</Option>
                    <Option value={ORDERS_STATUSES_ENUM.IN_PROGRESS}>{ORDERS_STATUSES.IN_PROGRESS}</Option>
                    <Option value={ORDERS_STATUSES_ENUM.CLOSED}>{ORDERS_STATUSES.CLOSED}</Option>
                  </Select>
                  <SpButton
                    className="ml-2 pl-2 pr-2"
                    type="primary"
                    borderRadius="none"
                    onClick={() => {
                      setShowEdit(!showEdit);
                      updateStatus(status);
                    }}
                  >
                    <UpdateIcon />
                  </SpButton>
                  <SpButton
                    className="ml-2 pl-2 pr-2"
                    type="secondary"
                    borderRadius="none"
                    style={{ borderColor: '#C4C4C4' }}
                    onClick={() => {
                      setShowEdit(!showEdit);
                    }}
                  >
                    <CancelIcon />
                  </SpButton>
                </div>
              </Col>
              )}
            </Row>
            {
                paymentItemList.map((item) => (
                  <>
                    {
                      (item.value !== '0') && (
                        <>
                          {
                            item.type === 'PRICE_AMOUNT_FIXED' ? (
                              <>
                                <Row type="flex" className="pt-1 pb-1">
                                  <Col span={4}>
                                    <div>
                                      {item.label}
                                    </div>
                                  </Col>
                                  <Col span={9}>
                                    <SpH5>
                                      {prefix} {formatAmount(item.price / 100, selectedAccount.currency)}
                                    </SpH5>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                {
                                  item.type === 'PRICE_AMOUNT_QUANTITY_BASED' ? (
                                    <>
                                      <Row type="flex" className="pt-1 pb-1">
                                        <Col span={4}>
                                          <div>
                                            {item.label} * {item.value}
                                          </div>
                                        </Col>
                                        <Col span={9}>
                                          <SpH5>
                                            {prefix}
                                            {formatAmount((item.price / 100) * item.value, selectedAccount.currency)}
                                          </SpH5>
                                        </Col>
                                      </Row>
                                    </>
                                  ) : (
                                    <>
                                      <Row type="flex" className="pt-1 pb-1">
                                        <Col span={4}>
                                          <div>
                                            {item.label}
                                          </div>
                                        </Col>
                                        <Col span={9}>
                                          <SpH5>
                                            {prefix} {formatAmount(item.value / 100, selectedAccount.currency)}
                                          </SpH5>
                                        </Col>
                                      </Row>
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                        </>
                      )
                    }
                  </>
                ))
              }
            {
                splitUp.discountedAmount && splitUp.discountedAmount !== 0 ? (
                  <>
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <div>Discount</div>
                      </Col>
                      <Col span={9}>
                        <div>
                          {prefix} ({formatAmount(splitUp.discountedAmount / 100, selectedAccount.currency)})
                        </div>
                      </Col>
                    </Row>
                  </>

                ) : (
                  <></>
                )
              }
            {
                splitUp.subTotal && splitUp.subTotal !== 0 ? (
                  <>
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <div>Sub Total</div>
                      </Col>
                      <Col span={9}>
                        <div>
                          {prefix} {formatAmount(splitUp.subTotal / 100, selectedAccount.currency)}
                        </div>
                      </Col>
                    </Row>
                  </>

                ) : (
                  <></>
                )
              }
            {
                splitUp.tax && splitUp.tax !== 0 ? (
                  <>
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <div>Tax</div>
                      </Col>
                      <Col span={9}>
                        <div>
                          {prefix} {formatAmount(splitUp.tax / 100, selectedAccount.currency)}
                        </div>
                      </Col>
                    </Row>
                  </>

                ) : (
                  <></>
                )
              }
            {
                splitUp.serviceFee && splitUp.serviceFee !== 0 ? (
                  <>
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <div>Service Fee</div>
                      </Col>
                      <Col span={9}>
                        <div>
                          {prefix} {formatAmount(splitUp.serviceFee / 100, selectedAccount.currency)}
                        </div>
                      </Col>
                    </Row>
                  </>

                ) : (
                  <></>
                )
              }
            {
                splitUp.tip && splitUp.tip !== 0 ? (
                  <>
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <div>Tip</div>
                      </Col>
                      <Col span={9}>
                        <div>
                          {prefix} {formatAmount(splitUp.tip / 100, selectedAccount.currency)}
                        </div>
                      </Col>
                    </Row>
                  </>

                ) : (
                  <></>
                )
              }
            <Row type="flex" justify="end">
              <Col>
                <SpH5>
                  {gid}
                </SpH5>
              </Col>
            </Row>
          </SpForm>
        </Card>
        <Card className="mb-3 mt-3">
          <Row type="flex" justify="space-between" className="my-2">
            <Col>
              <SpText className="text-uppercase mb-3" fontSize="20px">TIMELINE</SpText>
            </Col>
          </Row>
          <Row className="pl-3">
            <PaymentTimeline
              paymentTimeline={orderTimeline}
              selectedAccount={selectedAccount}
            />
          </Row>
        </Card>
      </>
      )
    }
    </>
  );
};

const mapStateToProps = (state) => ({
  orderDetails: state.paymentPagesDetails.orderDetails,
  orderTimeline: state.paymentPagesDetails.orderTimeline,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  splitUp: state.paymentPagesDetails.splitUp,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentOrderById: gid => dispatch(paymentPagesDetailsActions.fetchPaymentOrderById({
    payload: gid,
  })),
  fetchOrderTimeline: gid => dispatch(paymentPagesDetailsActions.fetchOrderTimeline({
    payload: gid,
  })),
  updateOrderStatus: param => dispatch(paymentPagesDetailsActions.updateOrderStatus({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PaymentOrderDetails);
