import { CUSTOM_DOCUMENT_REQUEST } from '../actionTypes';

export const fetchCustomDocumentRequest = action => ({
  type: CUSTOM_DOCUMENT_REQUEST.FETCH,
  payload: action.payload,
});

export const addCustomDocumentRequest = (action) => ({
  type: CUSTOM_DOCUMENT_REQUEST.ADD,
  payload: action.payload,
});

export const updateCustomDocumentRequest = (action) => ({
  type: CUSTOM_DOCUMENT_REQUEST.UPDATE,
  payload: action.payload,
});
