// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';
import getCustomDocumentBackground from 'utils/getCustomDocumentBackground';
import getCustomDocumentStatus from 'utils/getCustomDocumentStatus';
import {
  Card,
  Row,
  Col,
} from 'antd';

import {
  SpForm,
  SpText,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  customDocumentRequestDetailsActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
} from 'appconstants';

import RBAC from 'components/rbac';
import CONSTANTS from 'store/constants';
import DeleteCustomDocument from './components/deleteCustomDocument';

type Props = {
  fetchCustomDocumentRequestDetails: Function,
  deleteCustomDocumentRequest: Function,
  documentRequestDetails: Object,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  documentRequestDetailsStatus: string,
}

const CustomDocumentsDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchCustomDocumentRequestDetails,
    deleteCustomDocumentRequest,
    documentRequestDetails,
    loading,
    history,
    selectedAccount,
    submitting,
    validator,
    documentRequestDetailsStatus,
  } = props;
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const [deleteCustomDocumentModal, setDeleteCustomDocumentModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const getData = () => {
    fetchCustomDocumentRequestDetails(gid);
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.CUSTOM_DOCUMENT);
    }
    setDeleteCustomDocumentModal(false);
  }, [validator]);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.CUSTOM_DOCUMENT);
    }
  }, []);

  const deleteSelectedCustomDocument = () => {
    deleteCustomDocumentRequest(gid);
    setRedirect(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        documentRequestDetails.gid && documentRequestDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <SpForm>
                <Row type="flex" className="mt-4">
                  <Col span={6}>
                    <SpText className="mr-4" fontSize="18px" fontWeight="600">
                      {documentRequestDetails.type || <>&#8211;</>}
                    </SpText>
                  </Col>
                  <Col span={6}>
                    <SpStatusTag style={{ backgroundColor: getCustomDocumentBackground(documentRequestDetails.status) }}>
                      {getCustomDocumentStatus(documentRequestDetails.status)}
                    </SpStatusTag>
                  </Col>
                  <Col span={12} className="d-flex justify-content-end">
                    <RBAC permit={[ROLES.SUPERUSER]}>
                      <SpButton
                        type="danger"
                        className="ml-4"
                        shape="round"
                        ghost
                        onClick={() => setDeleteCustomDocumentModal(true)}
                      >
                        Delete Request
                      </SpButton>
                    </RBAC>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex">
                  <Col span={6}>
                    <div>
                      <span className="mr-3">Request Date</span>
                    </div>
                    <div className="mt-2">
                      <SpText fontSize="14px" fontWeight="600">
                        {
                          documentRequestDetails.createdAt && selectedAccount && selectedAccount.timezone
                            ? (
                              moment
                                .utc(documentRequestDetails.createdAt)
                                .tz(selectedAccount.timezone)
                                .format('MMM DD, YYYY')
                            )
                            : <>&#8211;</>
                        }
                      </SpText>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>
                      <span>Updated Date</span>
                    </div>
                    <div className="mt-2">
                      <SpText fontSize="14px" fontWeight="600">
                        {
                          documentRequestDetails.updatedAt && selectedAccount && selectedAccount.timezone
                            ? (
                              moment
                                .utc(documentRequestDetails.updatedAt)
                                .tz(selectedAccount.timezone)
                                .format('MMM DD, YYYY')
                            )
                            : <>&#8211;</>
                        }
                      </SpText>
                    </div>
                  </Col>
                </Row>
              </SpForm>
              <hr />
              <>
                <SpForm>
                  <Row type="flex">
                    <Col span={6}>
                      <span className="mr-3">Request Name</span>
                    </Col>
                    <Col span={6}>
                      <SpText className="mr-4" fontSize="13px" fontWeight="600">
                        {documentRequestDetails.name || <>&#8211;</>}
                      </SpText>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex">
                    <Col span={6}>
                      <span className="mr-3">Description</span>
                    </Col>
                    <Col span={6}>
                      <SpText className="mr-4" fontSize="13px" fontWeight="600">
                        {documentRequestDetails.description || <>&#8211;</>}
                      </SpText>
                    </Col>
                  </Row>
                </SpForm>
              </>
              {/* DELETE CUSTOM DOCUMENT MODAL */}
              {deleteCustomDocumentModal && (
                <DeleteCustomDocument
                  visible={deleteCustomDocumentModal}
                  submitting={submitting}
                  selectedAccount={selectedAccount}
                  documentRequestDetails={documentRequestDetails}
                  close={() => setDeleteCustomDocumentModal(false)}
                  submit={deleteSelectedCustomDocument}
                />
              )}
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  documentRequestDetails: state.customDocumentRequestDetails,
  documentRequestDetailsStatus: state.customDocumentRequestDetails.customDocumentRequestDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomDocumentRequestDetails: gid => dispatch(customDocumentRequestDetailsActions.fetchCustomDocumentRequestDetails({
    payload: gid,
  })),
  deleteCustomDocumentRequest: param => dispatch(customDocumentRequestDetailsActions.deleteCustomDocumentRequest({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CustomDocumentsDetails);
