// @flow
import React, {
  useRef,
  useState,
  createRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Steps,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpButton } from 'components/DesignKit';

import WalletDetails from './WalletDetails';
import PinDetails from './PinDetails';

  type Props = {
    close: Function,
    visible: boolean,
    selectedAccount: Object,
    submit: Function,
    setUpWallet: Function,
    validator: Boolean,
    confirmWalletAlias: Boolean,
    current: Number,
    backToAlias: Function,
    consfirmWalletPin: Boolean,
    backToPin: Function,
  };

const { Step } = Steps;

const AddWallet = (props: Props) => {
  const {
    visible,
    close,
    selectedAccount,
    submit,
    setUpWallet,
    validator,
    confirmWalletAlias,
    current,
    backToAlias,
    consfirmWalletPin,
    backToPin,
  } = props;

  const [, forceUpdate] = useState();
  const transferDetailsRef = createRef();
  const contactDetailsRef = createRef();
  const [refArray, setRefArray] = useState([transferDetailsRef, contactDetailsRef]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);

  const [type, setType] = useState('');
  const [alias, setAlias] = useState('');
  const [phoneNumberCode, setPhoneCode] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [walletPin, setWalletPin] = useState('');
  const [code, setCode] = useState('');
  const [codePin, setCodePin] = useState('');

  const walletDetailsData = {
    type,
    alias,
    phoneNumberCode,
    contactPhone,
    code,
  };

  const pinDetailsData = {
    walletPin,
    codePin,
  };

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const next = async () => {
    const validationArray = [];
    const valuesArray = [];
    validationArray.push(refArray[0].current.validate());
    valuesArray.push(refArray[0].current.getValues());
    if (!validationArray[0]) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setType(valuesArray[0].type);
    setAlias(valuesArray[0].alias);
    setContactPhone(valuesArray[0].contactPhone);
    setPhoneCode(valuesArray[0].phoneNumberCode);
    setCode(valuesArray[0].code);
    const payload = {
      alias: valuesArray[0].alias,
      type: valuesArray[0].type,
      code: valuesArray[0].code,
      phone: valuesArray[0].contactPhone ? `${valuesArray[0].phoneNumberCode}${valuesArray[0].contactPhone}` : null,
    };
    setUpWallet({
      payload,
    });
  };

  const nextPin = async () => {
    const validationArray = [];
    const valuesArray = [];
    validationArray.push(refArray[1].current.validate());
    valuesArray.push(refArray[1].current.getValues());
    if (!validationArray[0]) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setWalletPin(valuesArray[0].walletPin);
    setCodePin(valuesArray[0].code);
    setDisableButton(true);
    const encodedString = Buffer.from(valuesArray[0].walletPin).toString('base64');
    const payload = {
      pin: encodedString,
      code: valuesArray[0].code,
    };
    submit({
      payload,
    });
  };

  const prev = () => {
    backToAlias();
  };

  const back = () => {
    backToPin();
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="625px"
      footer={current === 0
        ? [
          <SpButton key="1" onClick={confirmWalletAlias ? prev : () => close()} type="secondary">{confirmWalletAlias ? 'Back' : 'Cancel'}</SpButton>,
          <SpButton key="2" onClick={next}>{confirmWalletAlias ? 'Verify' : 'Submit'}</SpButton>,
        ]
        : current === 1
          && [
            <SpButton key="1" onClick={consfirmWalletPin ? back : () => close()} type="secondary">{consfirmWalletPin ? 'Back' : 'Cancel'}</SpButton>,
            <SpButton key="2" onClick={nextPin}>{consfirmWalletPin ? 'Verify' : 'Set'}</SpButton>,
          ]}
      title="Setup Wallet"
    >
      <>
        <div style={{ marginTop: '-8px' }}>
          <Steps current={current} className="subscriptions">
            <Step key="1" title="Details" />
            <Step key="2" title="Pin Setup" />
          </Steps>
        </div>
        <div className="px-5 mt-2">
          {current === 0 && (
          <WalletDetails
            ref={refArray[0]}
            walletDetailsData={walletDetailsData}
            selectedAccount={selectedAccount}
            confirmWalletAlias={confirmWalletAlias}
          />
          )}
          {current === 1 && (
          <PinDetails
            ref={refArray[1]}
            pinDetailsData={pinDetailsData}
            validator={validator}
            disableButton={disableButton}
            consfirmWalletPin={consfirmWalletPin}
          />
          )}
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  contactList: state.transfers.contactList,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  walletAuth: state.wallet.walletAuth,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddWallet);
