import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  SHOPS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryShops = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_SHOPS}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_SHOPS}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_SHOPS}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_SHOPS}${query}${sorting}`);
};

const fetchShopRates = async (payload) => {
  const queryParam = '?page=1&size=100';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=ASC';
  return axiosAuthInstance.get(`${API_END_POINTS.TAXRATES}${queryParam}${sorting}`);
};

const fetchPromosList = async (payload) => {
  const queryParam = '?page=1&size=10000';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=ASC';
  return axiosAuthInstance.get(`${API_END_POINTS.PROMOTIONAL_CODES}${queryParam}${sorting}`);
};

const postInventoryShops = async ({ params }) => axiosAuthInstance.post(`${API_END_POINTS.GET_INVENTORY_SHOPS}`, params);

const patchInventoryShops = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_SHOPS}/${gid}`, params);

const deleteSelectedShops = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INVENTORY_SHOPS}/${gid}`);
// eslint-disable-next-line
const postInventoryShopAssociate = async ({ shopGid, assiocateParams }) => axiosAuthInstance.post(`${API_END_POINTS.GET_INVENTORY_SHOPS}/${shopGid}/shop-associate`, assiocateParams);
// eslint-disable-next-line
const patchInventoryShopAssociate = async ({ shopGid, assosiateGid, assiocateParams }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_SHOPS}/${shopGid}/shop-associate/${assosiateGid}`, assiocateParams);

// eslint-disable-next-line
const removeInventoryShopAssociate = async ({ shopGid, assosiateGid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INVENTORY_SHOPS}/${shopGid}/shop-associate/${assosiateGid}`);

const fetchInventoryShopAssociate = async gid => axiosAuthInstance.get(`shop/${gid}/shop-associate`);

export function* getInventoryShops(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchInventoryShops, action.payload);
    yield put({ type: SHOPS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SHOPS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getInventoryRatesList(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchShopRates, action.payload);
    yield put({ type: SHOPS.RATES_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SHOPS.RATES_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addInventoryShops(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postInventoryShops, action.payload);
    const { data: { entity } } = yield call(fetchInventoryShops, action.payload);
    yield put({ type: SHOPS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.ADD_SHOPS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateInventoryShops(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventoryShops, action.payload);
    const { data: { entity } } = yield call(fetchInventoryShops, action.payload);
    yield put({ type: SHOPS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: (action.payload && action.payload.type === 'Tax')
        ? 'Tax added succesfully' : (action.payload && action.payload.type === 'Service')
          ? 'Service charge added succesfully' : (action.payload && action.payload.type === 'Service Delete')
            ? 'Service charge deleted succesfully' : (action.payload && action.payload.type === 'Service SMS')
              ? 'SMS Configured succesfully' : MESSAGES.INVENTORY_ORDERS.UPDATE_SHOPS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteInventoryShops(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteSelectedShops, action.payload);
    const { data: { entity } } = yield call(fetchInventoryShops, action.payload);
    yield put({ type: SHOPS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.DELETE_SHOPS,
    });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getInventoryPromosList(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchPromosList, action.payload);
    yield put({ type: SHOPS.PROMOS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SHOPS.PROMOS_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addInventoryShopAssociate(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postInventoryShopAssociate, action.payload);
    const { data: { entity } } = yield call(fetchInventoryShops, action.payload);
    yield put({ type: SHOPS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: (action.payload && action.payload.type === 'Associate')
        ? 'Shop Associate created succesfully.' : MESSAGES.INVENTORY_ORDERS.ADD_SHOPS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateInventoryShopAssociate(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventoryShopAssociate, action.payload);
    const { data: { entity } } = yield call(fetchInventoryShops, action.payload);
    yield put({ type: SHOPS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Shop Associate updated succesfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getInventoryShopAssociate(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchInventoryShopAssociate, action.payload);
    yield put({ type: SHOPS.ASSOCIATE_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      console.log(e.response.data);
    }
    yield put({ type: SHOPS.ASSOCIATE_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* deleteInventoryShopAssociate(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(removeInventoryShopAssociate, action.payload);
    const { data: { entity } } = yield call(fetchInventoryShops, action.payload);
    yield put({ type: SHOPS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Shop Associate deleted succesfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
