// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  selectedTerminal: Object,
  submitting: boolean,
};

const RemoveTerminal = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedTerminal,
    submitting,
  } = props;

  const deleteTerminal = () => {
    submit(selectedTerminal.gid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={deleteTerminal} type="danger">Remove</SpButton>,
      ]}
      title="Remove Terminal from Location"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-1 mb-2">Are you sure you want to remove this terminal from the location ?</div>
        <div className="pb-3">
          <SpH5>{selectedTerminal.deviceName}</SpH5>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveTerminal;
