import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  TAXRATE,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchTaxrates = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.TAXRATES}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.TAXRATES}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.TAXRATES}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.TAXRATES}${query}${sorting}`);
};

const fetchTaxes = async (payload) => axiosAuthInstance.get(`${API_END_POINTS.TAXES}?type.LIKE=${payload}`);

const postCustomer = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.TAXRATES, params);

const deleteCustomer = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.TAXRATES}/${gid}`);

export function* getTaxrates(action) {
  try {
    const { data: { entity } } = yield call(fetchTaxrates, action.payload);
    yield put({ type: TAXRATE.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: TAXRATE.FAILED, payload: e.response.data });
  }
}

export function* getTaxes(action) {
  try {
    const { data: { entity } } = yield call(fetchTaxes, action.payload);
    yield put({ type: TAXRATE.SUCCESS_TAXES, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: TAXRATE.FAILED, payload: e.response.data });
  }
}

export function* addTaxrate(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postCustomer, action.payload);
    const { data: { entity } } = yield call(fetchTaxrates, action.payload);
    yield put({ type: TAXRATE.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TAXRATE.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteTaxrate(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteCustomer, action.payload);
    const { data: { entity } } = yield call(fetchTaxrates, action.payload);
    yield put({ type: TAXRATE.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TAXRATE.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
