// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Input,
  Row,
  Col,
  Radio,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import { SpH5, SpError } from 'components/DesignKit';


type Props = {
  submitting: boolean,
  addRateData: Object,
  currencyType: Object,
  searchRateData: Object,
  test: boolean,
};

const RateDetails = (props: Props, ref) => {
  const {
    submitting,
    addRateData,
    currencyType,
    searchRateData,
    test,
  } = props;
  const [, forceUpdate] = useState();
  const [processingMin, setProcessingMin] = useState(addRateData ? formatAmount(addRateData.processingMin, currencyType) : '0.00');
  const [processingMax, setProcessingMax] = useState(addRateData ? formatAmount(addRateData.processingMax, currencyType) : '0.00');
  const [rate, setRate] = useState(addRateData ? formatAmount(addRateData.rate, currencyType) : '0.00');
  const [tax, setTax] = useState(addRateData ? formatAmount(addRateData.tax, currencyType) : '0.00');
  const [fee, setFee] = useState(addRateData ? formatAmount(addRateData.fee, currencyType) : '0.00');
  const [salesPerson, setSalesPerson] = useState(addRateData ? addRateData.salesPerson : '');
  const [applyReward, setApplyReward] = useState(addRateData.applyReward || false);
  const [rewardRate, setRewardRate] = useState(addRateData ? formatAmount(addRateData.rewardRate, currencyType) : '0.00');
  const [rewardCap, setRewardCap] = useState(addRateData ? formatAmount(addRateData.rewardCap, currencyType) : '0.00');
  const prefix = (searchRateData && searchRateData.countryId === 2) ? '₹' : '$';

  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      processingMin,
      processingMax,
      rate,
      tax,
      fee,
      salesPerson,
      applyReward,
      rewardRate,
      rewardCap,
    }),
  }));

  const onRewardChange = (e) => {
    setApplyReward(e.target.value);
  };

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2 mb-3">
          <Row span={16}>
            <Col span={8}>
              <SpH5>Processing Min</SpH5>
            </Col>
            <Col span={8} className="ml-4">
              <SpH5>Processing Max</SpH5>
            </Col>
          </Row>
          <Row span={16}>
            <Col span={8}>
              <Input
                value={processingMin}
                prefix={prefix}
                onChange={(e) => {
                  const regex = /^\d*\.?\d*$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setProcessingMin(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setProcessingMin(0.00);
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setProcessingMin(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('Minimum amount', processingMin, 'required')}
              </SpError>
            </Col>
            <Col span={8} className="ml-4">
              <Input
                value={processingMax}
                prefix={prefix}
                onChange={(e) => {
                  const regex = /^\d*\.?\d*$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setProcessingMax(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setProcessingMax(0.00);
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setProcessingMax(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('Maximum amount', processingMax, 'required')}
              </SpError>
            </Col>
          </Row>
        </div>
        <div>
          <Row span={18}>
            <Col span={6}>
              <SpH5>Rate</SpH5>
            </Col>
            <Col span={6} className="ml-4">
              <SpH5>Tax</SpH5>
            </Col>
            <Col span={6} className="ml-4">
              <SpH5>Fee</SpH5>
            </Col>
          </Row>
          <Row span={16}>
            <Col span={6}>
              <Input
                value={rate}
                prefix="%"
                onChange={(e) => {
                  const regex = /^\d*\.?\d*$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setRate(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setRate(0.00);
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setRate(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('rate', rate, 'required')}
              </SpError>
            </Col>
            <Col span={6} className="ml-4">
              <Input
                value={tax}
                prefix="%"
                onChange={(e) => {
                  const regex = /^\d*\.?\d*$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setTax(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setTax(0.00);
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setTax(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('tax', tax, 'required')}
              </SpError>
            </Col>
            <Col span={6} className="ml-4">
              <Input
                value={fee}
                prefix={prefix}
                onChange={(e) => {
                  const regex = /^\d*\.?\d*$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setFee(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setFee(0.00);
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setFee(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('fee', fee, 'required')}
              </SpError>
            </Col>
          </Row>
        </div>
        {
          !test && (
            <>
              <div>
                <Row className="mt-3 mb-3">
                  <Col>
                    <SpH5>Apply Reward</SpH5>
                  </Col>
                  <Col>
                    <Radio.Group onChange={onRewardChange} value={applyReward}>
                      {/* eslint-disable-next-line */}
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </div>
              {
                applyReward && (
                  <>
                    <div>
                      <Row span={18}>
                        <Col span={9}>
                          <SpH5>Reward Rate</SpH5>
                        </Col>
                        <Col span={9} className="ml-4">
                          <SpH5>Reward Cap</SpH5>
                        </Col>
                      </Row>
                      <Row span={18}>
                        <Col span={9}>
                          <Input
                            value={rewardRate}
                            prefix="%"
                            onChange={(e) => {
                              const regex = /^\d*\.?\d*$/;
                              const { value } = e.currentTarget;
                              if (regex.test(value) || value === '') {
                                setRewardRate(e.currentTarget.value);
                              } else if (!regex.test(value)) {
                                setRewardRate(0.00);
                              }
                            }}
                            onKeyPress={e => {
                              const keyCode = e.charCode || e.which;
                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                e.preventDefault();
                              }
                            }}
                            onBlur={e => {
                              if (e.currentTarget.value) {
                                const value = formatAmount(e.currentTarget.value, currencyType);
                                setRewardRate(value);
                              }
                            }}
                          />
                          <SpError>
                            {simpleValidator.current.message('reward rate', rewardRate, 'required')}
                          </SpError>
                        </Col>
                        <Col span={9} className="ml-4">
                          <Input
                            value={rewardCap}
                            prefix={prefix}
                            onChange={(e) => {
                              const regex = /^\d*\.?\d*$/;
                              const { value } = e.currentTarget;
                              if (regex.test(value) || value === '') {
                                setRewardCap(e.currentTarget.value);
                              } else if (!regex.test(value)) {
                                setRewardCap(0.00);
                              }
                            }}
                            onKeyPress={e => {
                              const keyCode = e.charCode || e.which;
                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                e.preventDefault();
                              }
                            }}
                            onBlur={e => {
                              if (e.currentTarget.value) {
                                const value = formatAmount(e.currentTarget.value, currencyType);
                                setRewardCap(value);
                              }
                            }}
                          />
                          <SpError>
                            {simpleValidator.current.message('reward cap', rewardCap, 'required')}
                          </SpError>
                        </Col>
                      </Row>
                    </div>
                  </>
                )
              }
            </>
          )
        }
        <div className="mt-2">
          <label htmlFor=""><SpH5>Salesperson</SpH5></label>
          <Input
            placeholder="Salesperson"
            style={{ width: '86%' }}
            value={salesPerson}
            onChange={(e) => setSalesPerson(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('salesperson', salesPerson, 'required')}
          </SpError>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(RateDetails);
