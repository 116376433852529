import { WALLETINVOICEDETAILS } from '../actionTypes';

export const fetchWalletInvoiceDetails = (action) => ({
  type: WALLETINVOICEDETAILS.FETCH,
  payload: action.payload,
});

export const updateWalletInvoice = (action) => ({
  type: WALLETINVOICEDETAILS.UPDATE,
  payload: action.payload,
});

export const getBeneficiaryList = (action) => ({
  type: WALLETINVOICEDETAILS.BENEFICIARY_FETCH,
  payload: action.payload,
});

export const addNewWalletInvoice = (action) => ({
  type: WALLETINVOICEDETAILS.INVOICE_ADD,
  payload: action.payload,
});

export const addWalletBeneficiary = (action) => ({
  type: WALLETINVOICEDETAILS.BENEFICIARY_ADD,
  payload: action.payload,
});

export const deleteWalletInvoice = (action) => ({
  type: WALLETINVOICEDETAILS.DELETE,
  payload: action.payload,
});

export const cancelWalletInvoices = (action) => ({
  type: WALLETINVOICEDETAILS.CANCEL,
  payload: action.payload,
});

export const notifyWalletInvoice = (action) => ({
  type: WALLETINVOICEDETAILS.NOTIFY_SUCCESS,
  payload: action.payload,
});

export const payWalletInvoice = (action) => ({
  type: WALLETINVOICEDETAILS.INVOICE_PAY,
  payload: action.payload,
});
