import { INVENTORY_ORDERS } from '../actionTypes';

const initialState = {
  content: [],
  supportTypes: {},
  searchTypes: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_ORDERS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case INVENTORY_ORDERS.ORDERS_FILTER_SUCCESS:
      return {
        ...state,
        supportTypes: action.payload,
      };
    case INVENTORY_ORDERS.ORDERS_SEARCH_SUCCESS:
      return {
        ...state,
        searchTypes: action.payload,
      };
    default:
      return state;
  }
};
