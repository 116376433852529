import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  VENDORINVOICEDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchVendorInvoiceDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.VENDORINVOICE_API}/${gid}`);

// eslint-disable-next-line max-len
const patchVendorInvoice = async ({ gid, params, endPoint }) => axiosAuthInstance.patch(`${API_END_POINTS.VENDORINVOICE_API}/${gid}/${endPoint}`, params);

const getContactList = async (payload) => {
  if (payload) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}?page=1&size=100&name.LIKE=${payload}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}?page=1&size=100`);
};

const postNewVendorInvoice = async (payload) => axiosAuthInstance.post(`${API_END_POINTS.VENDORINVOICE_API}`, payload);

const postContact = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.GET_CONTACTS, params);

const deleteVendorInvoices = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.VENDORINVOICE_API}/${gid}/cancel`, params);

const getVendorNotify = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.VENDORINVOICE_API}/${gid}/notify`, params);

// eslint-disable-next-line max-len
const patchPayVendorInvoice = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.VENDORINVOICE_API}/${gid}/pay`, params);

export function* getVendorInvoiceDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchVendorInvoiceDetails, action.payload);
    yield put({ type: VENDORINVOICEDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: VENDORINVOICEDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateVendorInvoice(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchVendorInvoice, action.payload);
    const { data: { entity } } = yield call(fetchVendorInvoiceDetails, action.payload.gid);
    yield put({ type: VENDORINVOICEDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* fetchVendorContactList(action) {
  try {
    const { data: { entity } } = yield call(getContactList, action.payload);
    yield put({ type: VENDORINVOICEDETAILS.CONTACT_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: VENDORINVOICEDETAILS.CONTACT_FAILED, payload: e.response.data });
  }
}

export function* addNewVendorInvoice(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(postNewVendorInvoice, action.payload.params);
    yield put({ type: VENDORINVOICEDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addContact(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(postContact, action.payload);
    yield put({ type: VENDORINVOICEDETAILS.CONTACT_ADD_SUCCESS, payload: entity });
    const { data } = yield call(getContactList);
    yield put({ type: VENDORINVOICEDETAILS.CONTACT_SUCCESS, payload: data.entity.content });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOMERS.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteVendorInvoice(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteVendorInvoices, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* cancelVendorInvoices(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteVendorInvoices, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.CANCEL_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* notifyVendorInvoice(action) {
  try {
    yield call(getVendorNotify, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.NOTIFY.SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* payVendorInvoice(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchPayVendorInvoice, action.payload);
    const { data: { entity } } = yield call(fetchVendorInvoiceDetails, action.payload.gid);
    yield put({ type: VENDORINVOICEDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.PAY_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}
