import React from 'react';
import { Col, Row } from 'antd';
import {
  SpText,
  SpH5,
  SpForm,
  SpStatusTag,
} from 'components/DesignKit';

import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';

type Props = {
  card: Object,
  cardDetails: Object,
  paymentGid: String,
}
const ApplePay = (props: Props) => {
  const {
    card,
    cardDetails,
    paymentGid,
  } = props;

  if (card) {
    return (
      <Col span={6}>
        <div>
          <span>Payment Method</span>
        </div>
        <div>
          <SpText fontSize="18px" fontWeight="600">
            APPLE PAY
          </SpText>
        </div>
      </Col>
    );
  }
  if (cardDetails && cardDetails.fingerprint) {
    const trunc = cardDetails.fingerprint.substr(0, 15);
    let month = cardDetails.expiryMonth;
    if (month < 10) {
      month = `0${month}`;
    }
    return (
      <SpForm>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Card Owner</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {(cardDetails && cardDetails.name) ? (cardDetails.name === ' ' ? <>&#8211;</> : cardDetails.name) : <>&#8211;</>}
            </SpH5>
          </Col>
          <Col span={3}>
            <span>ID</span>
          </Col>
          <Col span={8}>
            <SpH5>{(cardDetails && cardDetails.gid) ? cardDetails.gid : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Fingerprint</span>
          </Col>
          <Col span={9}>
            <SpH5 style={{ wordWrap: 'break-word' }} title={cardDetails && cardDetails.fingerprint}>
              {trunc}....
            </SpH5>
          </Col>
          <Col span={3}>
            <span>Expiry Date</span>
          </Col>
          <Col span={8}>
            <SpH5>
              {month}/{cardDetails.expiryYear}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Country</span>
          </Col>
          <Col span={9}>
            <SpH5>{(cardDetails && cardDetails.country && cardDetails.country.name) ? cardDetails.country.name : <>&#8211;</>}</SpH5>
          </Col>
          <Col span={3}>
            <span>CVC Check</span>
          </Col>
          <Col span={8}>
            <SpStatusTag style={{ backgroundColor: getPaymentBackground('Passed') }}>
              {getPaymentStatus('Passed')}
            </SpStatusTag>
          </Col>
          <Col span={24} className="mt-4 d-flex justify-content-end">
            <SpH5 color="#7f7f7f">{paymentGid}</SpH5>
          </Col>
        </Row>
      </SpForm>
    );
  } if (cardDetails) {
    return (
      <SpForm>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>ID</span>
          </Col>
          <Col span={9}>
            <SpH5>{(cardDetails && cardDetails.gid) ? cardDetails.gid : <>&#8211;</>}</SpH5>
          </Col>
          <Col span={3}>
            <span>Country</span>
          </Col>
          <Col span={8}>
            <SpH5>{(cardDetails && cardDetails.country && cardDetails.country.name) ? cardDetails.country.name : <>&#8211;</>}</SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={24} className="d-flex justify-content-end">
            <SpH5 color="#7f7f7f">{paymentGid}</SpH5>
          </Col>
        </Row>
      </SpForm>
    );
  } return null;
};

export default ApplePay;
