import React from 'react';

import { SpText } from 'components/DesignKit';
import { Icon } from 'antd';

type Props = {
  value: Number,
};

const GrowthIndicator = ({ value }: Props) => (
  value !== null && value !== '0.0'
    ? (value > 0
      ? (
        <>
          <SpText
            fontWeight="600"
            fontSize="16px"
            color="#70d600"
            className="mr-2"
          >
            { `+${value}%` }
          </SpText>
          <Icon
            type="caret-up"
            style={{ color: '#70D600' }}
          />
        </>
      )
      : (
        <>
          <SpText
            fontWeight="600"
            fontSize="16px"
            color="#FF4D50"
            className="mr-2"
          >
            { `${value}%` }
          </SpText>
          <Icon
            type="caret-down"
            style={{ color: '#FF4D50' }}
          />
        </>
      )
    )
    : (
      <SpText
        fontWeight="600"
        fontSize="16px"
        color="#A1A1A1"
        className="mr-2"
      >
        0%&nbsp;-
      </SpText>
    )
);

export default GrowthIndicator;
