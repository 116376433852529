// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Table,
  Icon,
  Row,
  Col,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  SpButton,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import DownloadQr from 'components/NavIcons/downloadqr';

import {
  customersActions,
  filterParamsActions,
  diningActions,
  inventoryShopsDetailsActions,
  membershipActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
} from 'appconstants';
import RBAC from 'components/rbac';
import AddCustomer from './components/AddCustomer';
import EditCustomer from './components/EditCustomer';
import DeleteCustomer from './components/DeleteCustomer';
import ShowQrCode from './components/ShowQrCode';

type Props = {
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  diningTable: Array<Objects>,
  fetchDiningTable: Function,
  deleteUserTable: Function,
  fetchShopsSlug: Function,
  paymentSlug: Array,
  fetchMembership: Function,
  users: Array,
  assignTable: Function,
  fetchAssignTable: Function,
  assignTableList: Array,
  updateAssignTable: Function,
  isMobileView: Boolean,
};

const DiningDetailsList = (props: Props) => {
  const {
    selectedAccount,
    loading,
    totalElements,
    submitting,
    validator,
    test,
    history,
    diningTable,
    fetchDiningTable,
    deleteUserTable,
    fetchShopsSlug,
    paymentSlug,
    fetchMembership,
    users,
    assignTable,
    fetchAssignTable,
    assignTableList,
    updateAssignTable,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const location = useLocation();
  const { gid } = props.match.params;
  const [pagination, setPagination] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [deleteTableModal, setDeleteTableModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [selectedQR, setSelectedQR] = useState('');
  const [slugName, setSlugName] = useState('');

  useEffect(() => {
    if (paymentSlug && paymentSlug.length !== 0) {
      setSlugName(paymentSlug[0].slugName);
    }
  }, [paymentSlug]);

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchAssignTable({ currentPage, sortParams, gid });
  };

  useEffect(() => {
    if (gid) {
      getData(location.search);
      const parsed = queryString.parse(location.search);
      const { shopGid } = parsed;
      const payload = {
        shopGid,
        sectionGid: gid,
      };
      fetchDiningTable(payload);
      fetchShopsSlug(shopGid);
      fetchMembership('');
    }
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [location, test, selectedAccount, gid]);

  useEffect(() => {
    if (!validator) {
      setAddCustomerModal(false);
      setEditCustomerModal(false);
      setDeleteTableModal(false);
    }
  }, [validator]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [assignTableList]);

  const addNewCustomer = (customer: Object) => {
    const {
      userGid,
      tableGid,
    } = customer;
    const params = {
      sectionGid: gid,
      userGid,
      shopTableGid: tableGid,
    };
    assignTable(params);
  };

  const editSelectedCustomer = (customer: Object) => {
    const payload = {
      shopGid: gid,
      userSupportGid: customer.gid,
      params: customer.payload,
    };
    updateAssignTable(payload);
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    console.log(parsed, 'parsedparsedparsed');
    const shopId = parsed.shopGid || '';
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: `${PAGES.DINING_LIST}/${gid}`,
        search: `?shopGid=${shopId}&filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: `${PAGES.DINING_LIST}/${gid}`,
        search: `?shopGid=${shopId}&pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const deleteSelectedCustomer = (tablGid: string) => {
    const parsed = queryString.parse(location.search);
    const { shopGid } = parsed;
    const payload = {
      shopGid,
      sectionGid: gid,
      tableGid: tablGid,
    };
    deleteUserTable(payload);
    setDeleteTableModal(false);
  };

  const showDeleteCustomerrModal = (value: Object) => {
    setSelectedCustomer(value);
    setDeleteTableModal(true);
  };

  const selectedQrCode = (record) => {
    const url = process.env.REACT_APP_ENV === 'Production' ? 'https://order.swirepay.com' : 'https://staging-order.swirepay.com';
    const selectedSlugName = `${url}/${slugName}?tableGid=${record.shopTable.gid}`;
    setSelectedQR(selectedSlugName);
    setShowQrCodeModal(true);
  };

  const returnFromQrCode = (qrVlue) => {
    if (qrVlue === true) {
      setShowQrCodeModal(false);
    }
  };

  const showEditCustomerModal = (value: Object) => {
    setSelectedCustomer(value);
    setEditCustomerModal(true);
  };

  // const selectedRecord = (record) => {
  //   const itemGid = record && record.shopTable && record.shopTable.gid;
  //   history.push({
  //     pathname: `${PAGES.DINNINGDETAILS}/${itemGid}`,
  //     state: location.search,
  //   });
  // };

  const columns = [
    {
      title: 'SHOP',
      dataIndex: 'name',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const { name } = record.shopTable.shop;
        return (
          <span>
            {name || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'TABLE',
      dataIndex: 'name',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const { name } = record.shopTable;
        return (
          <span>
            {name || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'STAFF ASSIGNED',
      dataIndex: 'name',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const name = record.user && record.user.fullName;
        return (
          <span>
            {name || <>&#8211;</>}
          </span>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '30%',
    align: 'left',
    render: (text, record) => (
      <span>
        {
          slugName && (
            <SpButton
              type="secondary"
              borderRadius="50px"
              className="mr-3"
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); selectedQrCode(record); }}
            >
              <DownloadQr />
            </SpButton>
          )
        }
        <Icon
          type="delete"
          style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
          onClick={(e) => { e.stopPropagation(); showDeleteCustomerrModal(record); }}
        />
        <Icon
          type="edit"
          className="ml-3"
          style={{ fontSize: '20px', cursor: 'pointer' }}
          onClick={(e) => { e.stopPropagation(); showEditCustomerModal(record); }}
        />
      </span>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }
  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS,
            ROLES.SUPERUSER, ROLES.KDSUSER1, ROLES.KDSADMIN1]}
          >
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                ghost
                onClick={() => setAddCustomerModal(true)}
              >
                {'\u002B'}&nbsp;Assign Table
              </SpButton>
            </Col>
          </RBAC>
        </div>
      </Row>

      {/* ADD CUSTOMER MODAL */}
      {addCustomerModal && (
        <AddCustomer
          visible={addCustomerModal}
          submitting={submitting}
          phoneCode={phoneCode}
          diningTable={diningTable}
          assignTableList={assignTableList}
          users={users}
          close={() => setAddCustomerModal(false)}
          submit={addNewCustomer}
        />
      )}

      {/* SHOW QR MODAL */}
      {showQrCodeModal && (
        <ShowQrCode
          visible={showQrCodeModal}
          submitting={submitting}
          selectedQR={selectedQR}
          returnFromQrCode={returnFromQrCode}
          close={() => setShowQrCodeModal(false)}
        />
      )}

      {/* EDIT CUSTOMER MODAL */}
      {editCustomerModal && (
        <EditCustomer
          visible={editCustomerModal}
          selectedCustomer={selectedCustomer}
          phoneCode={phoneCode}
          diningTable={diningTable}
          users={users}
          submitting={submitting}
          close={() => setEditCustomerModal(false)}
          submit={editSelectedCustomer}
        />
      )}

      {/* DELETE CUSTOMER MODAL */}
      {deleteTableModal && (
        <DeleteCustomer
          visible={deleteTableModal}
          selectedCustomer={selectedCustomer}
          submitting={submitting}
          close={() => setDeleteTableModal(false)}
          submit={deleteSelectedCustomer}
        />
      )}
      <RBAC
        fallback={(
          <Table
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={assignTableList}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER,
          ROLES.KDSUSER1, ROLES.KDSADMIN1]}
      >
        <Table
          rowClassName={() => 'ant-table-clickable-row'}
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          // onRow={(record) => ({
          //   onClick: () => {
          //     selectedRecord(record);
          //   },
          // })}
          dataSource={assignTableList}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers.content,
  diningTable: state.dining.diningTable.content,
  totalElements: state.dining.assignTables.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  paymentSlug: state.inventoryShopsDetails.shopsSlug,
  users: state.membership.content,
  assignTableList: state.dining.assignTables.content,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomers: param => dispatch(customersActions.fetchCustomers({
    payload: param,
  })),
  addCustomers: param => dispatch(customersActions.addCustomers({
    payload: param,
  })),
  updateCustomer: param => dispatch(customersActions.updateCustomer({
    payload: param,
  })),
  deleteCustomer: param => dispatch(customersActions.deleteCustomer({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
  fetchDiningTable: (param) => dispatch(diningActions.fetchDiningTable({
    payload: param,
  })),
  deleteUserTable: (param) => dispatch(diningActions.deleteUserTable({
    payload: param,
  })),
  fetchShopsSlug: param => dispatch(inventoryShopsDetailsActions.fetchShopsSlug({
    payload: param,
  })),
  fetchMembership: param => dispatch(membershipActions.fetchMembership({
    payload: param,
  })),
  assignTable: (param) => dispatch(diningActions.assignTable({
    payload: param,
  })),
  fetchAssignTable: (param) => dispatch(diningActions.fetchAssignTable({
    payload: param,
  })),
  updateAssignTable: (param) => dispatch(diningActions.updateAssignTable({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(DiningDetailsList);
