import { call, put } from 'redux-saga/effects';

import {
  axiosNoAuthInstance,
  API_END_POINTS,
} from 'api';

import {
  CLOVER_RECEIPTS,
  LOADING,
} from '../actionTypes';

const fetchCloverReceipts = async (payload) => {
  const urlParams = new URLSearchParams(payload);
  const orderId = urlParams.get('orderId');
  const merchantId = urlParams.get('merchantId');
  if (orderId && merchantId !== null && merchantId !== 'null') {
    return axiosNoAuthInstance.get(`${API_END_POINTS.CLOVER_RECEIPT}?orderId=${orderId}&merchantId=${merchantId}`);
  } return axiosNoAuthInstance.get(`${API_END_POINTS.CLOVER_RECEIPT}?orderId=${orderId}`);
};

export function* getCloverReceipts(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchCloverReceipts, action.payload);
    yield put({ type: CLOVER_RECEIPTS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: CLOVER_RECEIPTS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
