// @flow
import React, { useRef, useState, useEffect } from 'react';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import formatAmount from 'utils/formatAmount';
import {
  COUNTRY_CODES,
  COUNTRIES,
  MAX_AMOUNT,
} from 'appconstants';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
};

const AddNewProduct = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
  } = props;

  const [, forceUpdate] = useState();
  const [productName, setProductName] = useState('');
  const [productDescription, setDescription] = useState('');
  const [amount, setAmount] = useState();
  const [currency, setCurrency] = useState();
  const [currencyId, setCurrencyId] = useState();
  const [productNotes, setNotes] = useState('');
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
    },
  }));

  useEffect(() => {
    const countryId = (selectedAccount && selectedAccount.country && selectedAccount.country.id);
    setCurrencyId(countryId);
    if (countryId === 1) {
      setCurrency('USD');
    } else if (countryId === 2) {
      setCurrency('INR');
    }
  }, []);

  const onCurrencyChange = (value) => {
    setCurrencyId(value);
    if (value === 1) {
      setCurrency('USD');
    } else if (value === 2) {
      setCurrency('INR');
    }
  };

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      productName,
      currencyId,
      currency,
      productDescription,
      amount,
      productNotes,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Add Product</SpButton>,
      ]}
      title="Add Product"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Product Name"
            value={productName}
            onChange={(e) => setProductName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Product name', productName, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <Input
            placeholder="Description"
            value={productDescription}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Description', productDescription, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Amount</SpH5></label>
          <div className="d-flex">
            <div style={{ width: '15%' }}>
              <Select
                className="w-100"
                defaultValue={currency}
                onChange={onCurrencyChange}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ID}>
                    {item.CURRENCY}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '85%' }}>
              <Input
                placeholder="0.00"
                value={amount}
                onChange={(e) => setAmount(e.currentTarget.value)}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setAmount(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('amount', amount, 'required|amount')}
              </SpError>
            </div>
          </div>
        </div>

        <div>
          <label htmlFor=""><SpH5>Internal Notes</SpH5></label>
          <Input
            placeholder="Optional notes for internal use"
            value={productNotes}
            onChange={(e) => setNotes(e.currentTarget.value)}
          />
        </div>

      </div>
    </Modal>
  );
};

// $FlowFixMe
export default AddNewProduct;
