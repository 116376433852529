import { call, put } from 'redux-saga/effects';

import generateDetailsQuery from 'utils/generateDetailsQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  EVENTSDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchEventDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_EVENTS}/${gid}`);

const fetchCardDetails = async (payload) => {
  const { gid } = payload;
  const { currentPage } = payload;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  const query = generateDetailsQuery(currentPage);
  return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYMENTS}?${query}&seatPageMetaGid.EQ=${gid}${sorting}`);
};

const patchEvent = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_EVENTS}/${gid}`, params);

const eventDeactivate = async gid => axiosAuthInstance.patch(`${API_END_POINTS.ADD_SEATED_VENUE}/${gid}/deactivate`);

const eventSoldOut = async gid => axiosAuthInstance.patch(`${API_END_POINTS.ADD_SEATED_VENUE}/${gid}/sold-out`);

const salesResume = async gid => axiosAuthInstance.patch(`${API_END_POINTS.ADD_SEATED_VENUE}/${gid}/resume-sale`);

const offlinePayment = async ({ gid, params }) => axiosAuthInstance.post(`${API_END_POINTS.OFFLINE_SEATED_VENUE}/${gid}/offline-payment`, params);

const fetchEventsSlug = async gid => axiosAuthInstance.get(`${API_END_POINTS.SEATPAGE_SLUG_API}/${gid}`);

const notifySeatedPageDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_NOTIFY}/${gid}`);

const notifySeatedPage = async payload => axiosAuthInstance.post(`${API_END_POINTS.GET_NOTIFY}/notify`, payload);

const fetchOfflineEventsPayments = async (payload) => {
  const defaultPage = 1;
  const defaultSize = 10;
  let query;
  if (payload.currentPage) {
    query = `&page=${payload.currentPage}&size=${defaultSize}`;
  } else {
    query = `&page=${defaultPage}&size=${defaultSize}`;
  }
  const queryParam = `?seatPage.seatPageMetaGid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  const offline = '&seatPage.offlinePaymentType.NULL=false';
  return axiosAuthInstance.get(`${API_END_POINTS.EVENT_RECEIPTS}${queryParam}${offline}${sorting}${query}`);
};


export function* getEventDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchEventDetails, action.payload);
    yield put({ type: EVENTSDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENTSDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getCardDetail(action) {
  try {
    const { data: { entity } } = yield call(fetchCardDetails, action.payload);
    yield put({ type: EVENTSDETAILS.CARDS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENTSDETAILS.FAILED, payload: e.response.data });
  }
}

export function* updateEvent(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchEvent, action.payload);
    const { data: { entity } } = yield call(fetchEventDetails, action.payload.gid);
    yield put({ type: EVENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOMERS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deactivateEvent(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(eventDeactivate, action.payload);
    const { data: { entity } } = yield call(fetchEventDetails, action.payload);
    yield put({ type: EVENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.DEACTIVATED,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* soldOutEvent(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(eventSoldOut, action.payload);
    const { data: { entity } } = yield call(fetchEventDetails, action.payload);
    yield put({ type: EVENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.SOLDOUT,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* resumeSales(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(salesResume, action.payload);
    const { data: { entity } } = yield call(fetchEventDetails, action.payload);
    yield put({ type: EVENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.SOLDOUT,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* offlinePayments(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(offlinePayment, action.payload);
    const { data: { entity } } = yield call(fetchEventDetails, action.payload.params.seatPageMetaGid);
    yield put({ type: EVENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* getEventsSlug(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchEventsSlug, action.payload);
    yield put({ type: EVENTSDETAILS.EVENTS_SLUG_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENTSDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getOfflineEventsPaymentsList(action) {
  try {
    const { data: { entity } } = yield call(fetchOfflineEventsPayments, action.payload);
    yield put({ type: EVENTSDETAILS.SUCCESS_OFFLINE_PAYMENTS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENTSDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getNotifySeatedPageDetails(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(notifySeatedPageDetails, action.payload);
      yield put({ type: EVENTSDETAILS.SUCCESS, payload: entity });
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.NOTIFY.SUCCESS,
      });
    } else {
      const { data: { entity } } = yield call(notifySeatedPageDetails, action);
      yield put({ type: EVENTSDETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getNotifySeatedPage(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(notifySeatedPage, action.payload);
    yield put({ type: LOADING.VALIDATED });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.NOTIFY.SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
