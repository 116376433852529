// @flow
import React, { useState, useRef, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import getStateList from 'utils/stateList';
import {
  validators,
} from 'utils/validationMessages';

import { COUNTRY_CODES } from 'appconstants';

const { Option } = Select;

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  editAddress: Object,
  submitting: boolean,
  addressType: string,
  zipTag: string,
  cntryId: Number,
  addressMethod: string,
  country: String,
};

const Address = (props: Props) => {
  const {
    visible,
    close,
    submit,
    editAddress,
    submitting,
    zipTag,
    addressType,
    cntryId,
    addressMethod,
    country,
  } = props;

  const [, forceUpdate] = useState();
  const [streetName, setStreetName] = useState(editAddress ? editAddress.street : '');
  const [cityName, setCityName] = useState(editAddress ? editAddress.city : '');
  const [stateName, setStateName] = useState(editAddress ? editAddress.state : '');
  const [zipCode, setZipCode] = useState(editAddress ? editAddress.postalCode : '');
  const [countryId, setCountryId] = useState(editAddress && editAddress.country ? editAddress.country.id : '');
  const [countryName, setCountryName] = useState('');
  const [stateList, setSateList] = useState([]);
  const postalCodeRegex = country === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;
  const [location, setLocation] = useState();
  const GOOGLE_MAPS_API_KEY = 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds';

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
    },
  }));

  useEffect(() => {
    setSateList(getStateList(cntryId));
    if (cntryId && cntryId === 1) {
      setCountryName('USA');
    } else if (cntryId && cntryId === 2) {
      setCountryName('INDIA');
    }
  }, []);

  const updateCountry = (value) => {
    setCountryId(value);
    setSateList(getStateList(value));
  };

  const updateBillingAddress = (address) => {
    setLocation(address);
    if (address.value && address.value.terms && address.value.terms.length > 2) {
      if (
        (address.value.terms[address.value.terms.length - 1].value === 'USA')
        || (address.value.terms[address.value.terms.length - 1].value === 'India')
      ) {
        setStreetName(address.value.structured_formatting.main_text);
        setCityName(address.value.terms[address.value.terms.length - 3].value);
        if (address.value.terms[address.value.terms.length - 1].value === 'India') {
          // setCountryId('IN');
          setCountryName('INDIA');
          setSateList(getStateList(2));
          const statesInIndia = getStateList(2);
          const slectedState = statesInIndia.filter(state => state.name === address.value.terms[address.value.terms.length - 2].value);
          if (slectedState && slectedState.length > 0) {
            setStateName(slectedState[0].id);
          }
          if (slectedState && slectedState.length === 0) {
            const slectedState1 = statesInIndia.filter(state => state.name === address.value.terms[address.value.terms.length - 3].value);
            if (slectedState1 && slectedState1.length > 0) {
              setStateName(slectedState1[0].id);
            }
          }
        }
        if (address.value.terms[2].value === 'USA') {
          // setCountryId('US');
          setCountryName('USA');
          const statesInUs = getStateList(1);
          const slectedState = statesInUs.filter(state => state.name === address.value.terms[address.value.terms.length - 2].value);
          if (slectedState && slectedState.length > 0) {
            setStateName(slectedState[0].id);
          }
          if (slectedState && slectedState.length === 0) {
            const slectedState1 = statesInUs.filter(state => state.name === address.value.terms[address.value.terms.length - 3].value);
            if (slectedState1 && slectedState1.length > 0) {
              setStateName(slectedState1[0].id);
            }
          }
          setSateList(getStateList(1));
        }
      }
    }
  };

  const validate = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      cityName,
      zipCode,
      stateName,
      streetName,
      countryId,
      addressType,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Update</SpButton>,
      ]}
      title={`${addressMethod} ${addressType}`}
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Street Name</SpH5></label>
          <div>
            <GooglePlacesAutocomplete
              apiKey={GOOGLE_MAPS_API_KEY}
              selectProps={{
                location,
                onChange: updateBillingAddress,
                defaultValue: streetName,
                placeholder: `${editAddress.street ? editAddress.street : 'Enter an address'}`,
              }}
              defaultValue={streetName}
              inputStyle={{
                width: '100%',
                height: '32px',
                padding: '4px 11px',
                color: '#272727',
                fontSize: '14px',
                lineHeight: 1.5,
                backgroundColor: '#fff',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
              }}
            />
          </div>
          {/* <Input
            placeholder="Street Address"
            value={streetName}
            onChange={(e) => setStreetName(e.currentTarget.value)}
          /> */}
          <SpError>
            {simpleValidator.current.message('street', streetName, 'required')}
          </SpError>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName}
                onChange={(e) => setCityName(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('city', cityName, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                className="w-100"
                showSearch
                optionFilterProp="children"
                value={stateName}
                onChange={(e) => setStateName(e)}
              >
                {
                stateList.map(s => (
                  <Option
                    key={s.id}
                    value={s.id}
                    className="text-capitalize"
                  >
                    { s.name }
                  </Option>
                ))
              }
              </Select>
              <SpError>
                {simpleValidator.current.message('state', stateName, 'required')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipTag}</SpH5></label>
              <Input
                placeholder={zipTag}
                value={zipCode}
                onChange={(e) => setZipCode(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('Area code', zipCode, 'required|postalcode')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block font-weight-bold">Country</label>
              <Select
                value={countryName}
                style={{ width: '100%' }}
                onChange={updateCountry}
                disabled
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ID}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default Address;
