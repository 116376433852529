import { BENEFICIARY } from '../actionTypes';

export const fetchBeneficiary = (action) => ({
  type: BENEFICIARY.FETCH,
  payload: action.payload,
});

export const addBeneficiary = (action) => ({
  type: BENEFICIARY.ADD,
  payload: action.payload,
});

export const updateBeneficiary = (action) => ({
  type: BENEFICIARY.UPDATE,
  payload: action.payload,
});

export const deleteBeneficiary = (action) => ({
  type: BENEFICIARY.DELETE,
  payload: action.payload,
});
