import { ACCOUNT_SETLLEMENT } from '../actionTypes';

export const fetchAccountSettlement = action => ({
  type: ACCOUNT_SETLLEMENT.FETCH,
  payload: action.payload,
});

export const addAccountSettlement = (action) => ({
  type: ACCOUNT_SETLLEMENT.ADD,
  payload: action.payload,
});

export const deleteAccountSettlement = (action) => ({
  type: ACCOUNT_SETLLEMENT.DELETE,
  payload: action.payload,
});
