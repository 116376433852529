import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  STATIC_QR,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchStaticQR = async (payload) => {
  console.log(payload, 'payloadpayload');
  let parms = '';
  const queryParam = payload.searchParam;
  if (queryParam && queryParam === 'LINKED') {
    parms = 'type.EQ=LINKED';
  } else if (queryParam && queryParam === 'NONLINKED') {
    parms = 'type.EQ=NON_LINKED';
  }
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (parms && !payload.filterParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.STATICQR}${query}&${parms}${sorting}`);
  }
  if (payload.filterParams && !parms && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.STATICQR}${query}&${payload.filterParams}`);
  }
  if (payload.filterParams && parms && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.STATICQR}${query}&${parms}&${payload.filterParams}${sorting}`);
  }
  if (payload.filterParams && !parms && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.STATICQR}${query}&${payload.filterParams}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.STATICQR}${query}${sorting}`);
};

const postStaticQR = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.STATICQR, params);

const postBulkStaticQR = async ({ count }) => axiosAuthInstance.post(`${API_END_POINTS.STATICQR}/${count}`);

export function* getStaticQR(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchStaticQR, action.payload);
    yield put({ type: STATIC_QR.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: STATIC_QR.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addStaticQR(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postStaticQR, action.payload);
    const { data: { entity } } = yield call(fetchStaticQR, action.payload);
    yield put({ type: STATIC_QR.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.STATICQR.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addBulkStaticQR(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postBulkStaticQR, action.payload);
    const { data: { entity } } = yield call(fetchStaticQR, action.payload);
    yield put({ type: STATIC_QR.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.STATICQR.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
