import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Tooltip,
  Row,
  Col,
  Icon,
  Badge,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import formatNumber from 'utils/formatNumber';
import getFilterOperators from 'utils/getFilterOperators';

import { paymentsActions, globalRestrictedKeyActions, filterParamsActions } from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';
import {
  PAGES,
  FILTERS_TYPES,
  ACQUIRER_TYPES,
  FILTER_STATUSES,
} from 'appconstants';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';

import UpdatePayment from './components/UpdatePayment';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const PaymentTypes = FILTER_STATUSES.ALLPAYMENTS;

type Props = {
  fetchPayments: Function,
  fetchGlobalRestrictedKey: Function,
  updatePayment: Function,
  payments: Array<Object>,
  globalRestrictedKey: Array<Object>,
  loading: boolean,
  test: boolean,
  validator: boolean,
  history: {
    push: Function,
  };
  totalElements: number,
  selectedAccount: Object,
  setFilterData: Function,
  preserveFilters: Object,
  isMobileView: Boolean,
};

const AllPayments = (props: Props) => {
  const location = useLocation();
  const {
    loading,
    fetchPayments,
    fetchGlobalRestrictedKey,
    payments,
    globalRestrictedKey,
    test,
    history,
    validator,
    totalElements,
    updatePayment,
    selectedAccount,
    setFilterData,
    preserveFilters,
    isMobileView,
  } = props;
  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }
  const [pagination, setPagination] = useState({});
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const PAYMENTS_FILTERS_TYPES = FILTERS_TYPES.ADMINPAYMENTS;
  const [filterTypes, setFilterTypes] = useState(PAYMENTS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterPayments, setFilterPayments] = useState((preserveFilters && preserveFilters.filterPayments)
    ? (preserveFilters && preserveFilters.filterPayments)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [updatePaymentModal, setUpdatePaymentModal] = useState(false);
  const getApiKey = (globalRestrictedKey) ? globalRestrictedKey.filter((data) => data.isEnabled) : '';
  const currencyType = selectedAccount && selectedAccount.currency;
  const acquirerName = (selectedAccount && selectedAccount.acquirer && selectedAccount.acquirer.name);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterPayments: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });

  const exportData = payments ? payments.map((values) => ({
    amount: `${values.currency.prefix}${formatNumber((values.net / 100).toFixed(values.currency.toFixed), currencyType)}`,
    status: getPaymentStatus(values.status),
    method: values.paymentMethod && values.paymentMethod.card
      ? values.paymentMethod.card.bankName
        // eslint-disable-next-line max-len
        ? `${values.paymentMethod.card.bankName} ${(values.paymentMethod.card && values.paymentMethod.card.lastFour) ? `**** ${values.paymentMethod.card.lastFour}` : ''}`
        : (values.paymentMethod.card) && (values.paymentMethod.card.lastFour)
          ? `**** ${values.paymentMethod.card.lastFour}` : ''
      : values.paymentMethod && values.paymentMethod.bank
        ? values.paymentMethod.bank.bankName
          // eslint-disable-next-line max-len
          ? `${values.paymentMethod.bank.bankName} ${(values.paymentMethod.bank && values.paymentMethod.bank.lastFour) ? `**** ${values.paymentMethod.bank.lastFour}` : ''}`
          : (values.paymentMethod.bank) && (values.paymentMethod.bank.lastFour)
            ? `**** ${values.paymentMethod.bank.lastFour}`
            : '' : ((values.paymentMethod) && (values.paymentMethod.upi))
          ? values.paymentMethod.upi.vpa : values.paymentMethod && values.paymentMethod.netbanking
            ? values.paymentMethod.netbanking.bankName : '',
    terminal: values.terminal && values.terminal.deviceName
      ? values.terminal.deviceName : '',
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY'),
  })) : '';

  const exportHeaders = (acquirerName && acquirerName === ACQUIRER_TYPES.IMSPOS) ? [
    { label: 'NET', key: 'amount' },
    { label: 'STATUS', key: 'status' },
    { label: 'METHOD', key: 'method' },
    { label: 'TERMINAL NAME', key: 'terminal' },
    { label: 'DATE', key: 'createdAt' },
  ] : [
    { label: 'NET', key: 'amount' },
    { label: 'STATUS', key: 'status' },
    { label: 'METHOD', key: 'method' },
    { label: 'DATE', key: 'createdAt' },
  ];

  const debouncedFetchPayments = debounce(fetchPayments, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const globalParams = `&sortBy=${sortBy}&direction=${direction}&isEnabled.EQUALS=true`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchPayments({
      currentPage,
      sortParams,
      filterParams,
    });
    fetchGlobalRestrictedKey({ currentPage, globalParams });
  };

  const clearFilters = () => {
    setFilterPayments([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(PAYMENTS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.ADMIN_PAYMENTS}/${searchParams}`,
      state: location.search,
    });
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e && e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn'
      && e.toElement.className !== 'ant-select-dropdown-menu  ant-select-dropdown-menu-root ant-select-dropdown-menu-vertical') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    if (location.search) {
      getData(location.search);
    } else {
      getData();
    }
    if (!filterParams) {
      clearFilters();
    }
  }, [location, test, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const showUpdatePaymentModal = (value: Object) => {
    setSelectedPayment(value);
    setUpdatePaymentModal(true);
  };

  useEffect(() => {
    if (!validator) {
      setUpdatePaymentModal(false);
    }
  }, [validator]);

  const updateNewPayment = (paymentInfo: Object) => {
    const {
      batchNumber,
      rrNumber,
      maskedNumber,
      authCode,
      appName,
      mrn,
      cardType,
      amid,
      atid,
    } = paymentInfo;
    const payload = {
      functionCode: 'SP_IMS_POS_MANUAL_RECON',
      responseCode: 'Transaction Success',
      batchNo: batchNumber,
      rrn: rrNumber,
      maskedCardNo: maskedNumber,
      authCode,
      appName,
      cardType,
      amid,
      atid,
      mrn,
    };
    const xApiKey = getApiKey && getApiKey[0].apiKey;
    const config = {
      headers: {
        'x-api-key': xApiKey,
      },
    };
    updatePayment({
      payload,
      config,
    });
  };


  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && !filterParams) {
      history.push({
        pathname: PAGES.ADMIN_PAYMENTS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    } else if (!filterParams) {
      history.push({
        pathname: PAGES.ADMIN_PAYMENTS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    } else if (filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.ADMIN_PAYMENTS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterPayments];
    if (filterPayments.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = PAYMENTS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterPayments(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterPayments];
    if (e[1] === 'paymentDate') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'net') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = PAYMENTS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterPayments(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterPayments(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterPayments(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterPayments[index].filterName === 'paymentDate') {
      if (filterPayments[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `paymentDate.GT=${dateStart}&paymentDate.LT=${dateEnd}`;
      } else if (filterPayments[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `paymentDate.LT=${dateStart}`;
      } else if (filterPayments[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `paymentDate.GT=${dateStart}`;
      }
    } else if (filterPayments[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterPayments];
    list[index].filterValue = selectedValue;
    setFilterPayments(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterPayments];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'paymentDate') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'paymentDate') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterPayments[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterPayments(list);
  };

  const handleAddClick = () => {
    const list = [...filterPayments];
    setFilterPayments([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterPayments([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(PAYMENTS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.ADMIN_PAYMENTS);
  };

  const applyFilters = () => {
    const paymentsFilters = filterPayments.filter((item) => item.checked);
    for (let i = 0; i < paymentsFilters.length; i += 1) {
      if (!paymentsFilters[i].filterName || !paymentsFilters[i].filterOperator || !paymentsFilters[i].filterValue) {
        return;
      }
    }
    if (paymentsFilters[0].filterValue || (paymentsFilters[0].filterValueFrom && paymentsFilters[0].filterValueTo)) {
      setCount(paymentsFilters && paymentsFilters.length);
      for (let i = 0; i < paymentsFilters.length; i += 1) {
        if (paymentsFilters[i].checked) {
          if (paymentsFilters[i].filterName === 'paymentDate') {
            paymentsFilters[i] = paymentsFilters[i].filterValue;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else if (paymentsFilters[i].filterName === 'net' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'net' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else {
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${paymentsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(paymentsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.ADMIN_PAYMENTS,
          search: `?pageNum=${currentPage}${sortParams}&filterParams=${filterParams}`,
        });
      } else if (location.search && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.ADMIN_PAYMENTS,
          search: `?filterParams=${filterParams}`,
        });
      } else if (location.search && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.ADMIN_PAYMENTS,
          search: `?filterParams=${filterParams}`,
        });
      } else if (filterParams) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.ADMIN_PAYMENTS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const terminalColumns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '11%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net, record) => {
        const total = (net / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '14%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '14%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? (record.paymentMethod.upi.vpa || 'UPI')
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'AUTH CODE',
      dataIndex: 'authCode',
      width: '12%',
      align: 'left',
      render: (authCode) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {authCode || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
      sorter: false,
    },
    {
      title: 'AUTH ID',
      dataIndex: 'authorizationId',
      width: '12%',
      align: 'left',
      render: (authorizationId) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {authorizationId || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
      sorter: false,
    },
    {
      title: 'TERMINAL NAME',
      dataIndex: 'terminal',
      width: '14%',
      align: 'left',
      render: (terminal) => {
        if (terminal && terminal.deviceName) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {terminal && terminal.deviceName}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
      sorter: false,
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '12%',
    align: 'center',
    render: (text, record) => {
      const { status } = record;
      if (acquirerName === ACQUIRER_TYPES.IMSPOS && status === 'REQUIRE_PAYMENT_METHOD') {
        return (
          <span>
            <Tooltip placement="top" title="Update Payment">
              <Icon
                type="edit"
                style={{ fontSize: '20px', cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); showUpdatePaymentModal(record); }}
              />
            </Tooltip>
          </span>
        );
      }
      return (
        <>&#8211;</>
      );
    },
  };


  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <span>
              {
                exportData !== null
                  ? (
                    <Tooltip placement="left" title="Click here to export Payments">
                      <CSVLink
                        target="_blank"
                        className="mr-3"
                        filename="Payments Report.csv"
                        data={exportData}
                        headers={exportHeaders}
                        onMouseEnter={() => setFillColor(true)}
                        onMouseLeave={() => setFillColor(false)}
                      >
                        <ExportIcon
                          fill={fillColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </CSVLink>
                    </Tooltip>
                  ) : null
              }
            </span>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Payments">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && (
                <FilterWrapper
                  style={{ marginLeft: '-265px' }}
                >
                  <Filters
                    filtersGrid={filterPayments}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                    handleFromValue={handleFromValue}
                    handleToValue={handleToValue}
                    stuses={PaymentTypes}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterPayments.length < PAYMENTS_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
            </span>
          </Col>
        </div>
      </Row>
      {/* UPDATE PAYMENT MODAL */}
      {updatePaymentModal && (
        <UpdatePayment
          visible={updatePaymentModal}
          selectedPayment={selectedPayment}
          selectedAccount={selectedAccount}
          submit={updateNewPayment}
          close={() => setUpdatePaymentModal(false)}
        />
      )}
      <Table
        onRow={(record) => ({
          onClick: () => {
            selectedRecord(record);
          },
        })}
        rowClassName={() => 'ant-table-clickable-row'}
        columns={[...terminalColumns, actionColumn]}
        rowKey={(record) => record.gid}
        dataSource={payments}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  payments: state.payments.content,
  globalRestrictedKey: state.globalRestrictedKey.content,
  loading: state.loading.loading,
  validator: state.loading.validator,
  totalElements: state.payments.totalElements,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPayments: param => dispatch(paymentsActions.fetchPayments({
    payload: param,
  })),
  updatePayment: param => dispatch(paymentsActions.updatePayment({
    payload: param,
  })),
  fetchGlobalRestrictedKey: param => dispatch(globalRestrictedKeyActions.fetchGlobalRestrictedKey({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllPayments);
