import { WALLET_STATUSES } from 'appconstants';

export default value => {
  if (value === 'LOAD') {
    return WALLET_STATUSES.LOAD;
  } if (value === 'PAYOUT') {
    return WALLET_STATUSES.PAYOUT;
  } if (value === 'TRANSACTION_CREDIT') {
    return WALLET_STATUSES.TRANSACTION_CREDIT;
  } if (value === 'TRANSACTION_DEBIT') {
    return WALLET_STATUSES.TRANSACTION_DEBIT;
  } if (value === 'CASHBACK') {
    return WALLET_STATUSES.CASHBACK;
  } if (value === 'REFUNDED') {
    return WALLET_STATUSES.REFUNDED;
  } if (value === 'REFUND') {
    return WALLET_STATUSES.REFUNDED;
  } if (value === 'PARTIAL_REFUNDED') {
    return WALLET_STATUSES.PARTIAL_REFUNDED;
  }
  return null;
};
