import {
  call,
  put,
} from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  LOADING,
  ACCOUNT,
  NOTIFICATION,
} from '../actionTypes';

const fetchAccountInfo = async gid => axiosAuthInstance.get(`${API_END_POINTS.USER_ACCOUNT}/${gid}`);
const patchAccountInfo = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.USER_ACCOUNT}/${gid}`, params);
const patchCrossTransfer = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.INTERNAL_USER_ACCOUNT}/${gid}`, params);

export function* updateAccountInfo(action) {
  try {
    yield put({ type: LOADING.SET });
    yield call(patchAccountInfo, action.payload);
    const { data: { entity } } = yield call(fetchAccountInfo, action.payload.gid);
    yield put({
      type: ACCOUNT.SET_SELECTED_ACCOUNT,
      payload: entity,
    });
    // notify user
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SETTINGS.UPDATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* allowCrossTransfer(action) {
  try {
    yield put({ type: LOADING.SET });
    yield call(patchCrossTransfer, action.payload);
    const { data: { entity } } = yield call(fetchAccountInfo, action.payload.gid);
    yield put({
      type: ACCOUNT.SET_SELECTED_ACCOUNT,
      payload: entity,
    });
    // notify user
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SETTINGS.UPDATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
