import { CATALOGORDER_STATUSES } from 'appconstants';

export default value => {
  if (value === 'PROCESSED') {
    return CATALOGORDER_STATUSES.PROCESSING;
  } if (value === 'PROCESSING') {
    return CATALOGORDER_STATUSES.PROCESSING;
  } if (value === 'SHIPPED') {
    return CATALOGORDER_STATUSES.SHIPPED;
  } if (value === 'DELIVERED') {
    return CATALOGORDER_STATUSES.DELIVERED;
  } if (value === 'RETURNED') {
    return CATALOGORDER_STATUSES.RETURNED;
  } return CATALOGORDER_STATUSES.CANCELLED;
};
