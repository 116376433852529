import { MENUS } from '../actionTypes';

const initialState = {
  content: [],
  menuOperatingHours: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MENUS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case MENUS.SUCCESS_OPERATING_HOURS:
      return {
        ...state,
        menuOperatingHours: action.payload,
      };
    default:
      return state;
  }
};
