import React from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select from 'react-select';
import {
  LabelText, SpanText, Text,
} from '../../../design';

import { selectHeight, normalTextFontSize, smallTextFontSize } from '../../../utils/reseponsive';

type Props = {
  label: string,
  description: string,
  control: any,
  errors: any,
  name: string,
  isRequired: boolean,
  isPreview: boolean,
  isAdaptive: boolean,
  options: any,
  filterObject: object,
}

const ComboBox = (props: Props) => {
  const {
    label, description, control, errors, name, isRequired, isPreview, isAdaptive, options, filterObject,
  } = props;

  const customStyles = {
    control: base => ({
      ...base,
      height: selectHeight(42, isPreview, isAdaptive),
      minHeight: selectHeight(27, isPreview, isAdaptive),
      border: '1px solid #29B1CC',
      boxShadow: 'none',
      borderColor: '#29B1CC !important',
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
    option: (styles) => ({
      ...styles,
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
    input: () => ({
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
    menuList: base => ({
      ...base,
      minHeight: 'fit-content',
      maxHeight: 200,
      fontSize: smallTextFontSize(isPreview, isAdaptive),
    }),
  };

  return (
    <>
      <div className="form-group row mb-0 no-gutters align-items-center">
        <div className="col-sm-5">
          <LabelText
            fontSize={normalTextFontSize(isPreview, isAdaptive)}
            color="#0000000"
            hidden={!label}
            htmlFor="content"
            className="col-form-label font-weight-bolder m-0 text-break"
          >{label}
            <SpanText
              className="pl-2"
              hidden={isRequired}
              color="rgba(0, 0, 0, 0.6)"
              fontSize={smallTextFontSize(isPreview, isAdaptive)}
            >(Optional)
            </SpanText>
          </LabelText>
        </div>
        <div display="flex" className="col-sm-7">
          <Controller
            marginBottom="0px"
            control={control}
            name={`${name}`}
            placeholder="select"
            rules={{
              required: isRequired,
            }}
            as={(
              <Select
                className="pb-2"
                theme={theme => ({
                  ...theme,
                  borderRadius: '4px',
                  boxShadow: 0,
                })}
                styles={customStyles}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                options={options}
              />
            )}
          />
          <Controller
            marginBottom="0px"
            control={control}
            name="child"
            placeholder="select"
            rules={{
              required: isRequired,
            }}
            as={(
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: '4px',
                  boxShadow: 0,
                })}
                styles={customStyles}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                options={(filterObject && filterObject.children) || ''}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text fontSize={smallTextFontSize(isPreview, isAdaptive)} marginTop="0" marginBottom="0px" color="#ff1e1e">{message}</Text>
            )}
          />
        </div>
      </div>
      <div hidden={!description} className="col-sm-10 px-0">
        <LabelText className="text-mute mt-1" fontSize={smallTextFontSize(isPreview, isAdaptive)}>{description}</LabelText>
      </div>
    </>
  );
};

export default ComboBox;
