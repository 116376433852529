import { STATIC_QR_DETAILS } from '../actionTypes';

export const fetchStaticQRDetails = (action) => ({
  type: STATIC_QR_DETAILS.FETCH,
  payload: action.payload,
});

export const deleteStaticQR = (action) => ({
  type: STATIC_QR_DETAILS.DELETE,
  payload: action.payload,
});

export const updateStaticQR = (action) => ({
  type: STATIC_QR_DETAILS.UPDATE,
  payload: action.payload,
});
