export default status => {
  if (status === 'NEEDS_RESPONSE') {
    return '#ffe0b1';
  } if (status === 'WON') {
    return '#ececff';
  } if (status === 'LOST') {
    return '#e2e2e2';
  } if (status === 'UNDER_REVIEW') {
    return '#e2e2e2';
  }
  return null;
};
