// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  Select,
  Modal,
  Input,
  AutoComplete,
} from 'antd';
import {
  ROLES,
} from 'appconstants';
import {
  notificationActions,
  accountActions,
} from 'store/actions';
import {
  SpH5,
  SpError,
  SpButton,
  SpText,
} from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';

const { Option } = Select;
let timeout;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
};

const AddStaticQr = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
  } = props;
  const dispatch = useDispatch();

  const {
    filteredList,
  } = useSelector(state => state.account);

  const { internalRole } = useSelector(state => state.user);

  const [, forceUpdate] = useState();
  const [accountGid, setAccountGid] = useState(selectedAccount && selectedAccount.gid);
  const [tipAvailable, setTipAvailable] = useState('');
  const [options, setOptions] = useState([]);
  const selectOption = 'name';
  const [qrType, setQrType] = useState('Linked');
  const [disableButton, setDisableButton] = useState(false);
  const [disableAccount, setDisableAccount] = useState(false);
  const [disableQuantity, setDisableQuantity] = useState(true);
  const [count, setCount] = useState(null);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      quantity: {
        message: 'Please enter valid quantity',
        rule: (val) => val <= 100,
      },
    },
  }));

  simpleValidator.current.purgeFields();

  useEffect(() => {
    if (internalRole
      && ((internalRole.name === ROLES.SUPERUSER) || (internalRole.name === ROLES.ONBOARDING) || (internalRole.name === ROLES.INTERNALSUPPORT))) {
      dispatch(accountActions.fetchFilteredAccounts());
    }
  }, []);

  useEffect(() => {
    if (filteredList.content) {
      const list = filteredList.content.map(
        (c) => ({ gid: c.gid, name: c.name }),
      );
      setOptions(list);
    }
  }, [filteredList]);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const handleChange = (e) => {
    setAccountGid(e);
  };

  const handleTypeChange = (e) => {
    setQrType(e);
    if (e === 'Linked') {
      setDisableAccount(false);
      setDisableQuantity(true);
    } if (e === 'NonLinked') {
      setDisableAccount(true);
      setDisableQuantity(false);
    }
  };

  const handleSearch = value => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(dispatch(accountActions.fetchFilteredAccounts({ selectOption, value })), 300);
  };

  const validate = async (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      accountGid,
      tipAvailable,
      qrType,
      count,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="New Static QR"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>QR Type</SpH5></label>
          <Select
            className="w-100"
            defaultValue={qrType || 'Select QR Type'}
            onChange={(e) => handleTypeChange(e)}
          >
            <Option key={1} value="Linked">Linked</Option>
            <Option key={2} value="NonLinked">Non Linked</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('qr type', qrType, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor="">
            <SpH5>For Account</SpH5>
          </label>
          <AutoComplete
            showSearch
            className="w-100"
            dataSource={options.map((item) => (
              <Option
                key={item.gid}
                value={item.gid}
              >
                {item.name}
              </Option>
            ))}
            defaultValue={selectedAccount && selectedAccount.name}
            onSearch={handleSearch}
            onSelect={e => handleChange(e)}
            disabled={disableAccount}
          />
        </div>
        <div>
          <label htmlFor=""><SpH5>Tip Availability</SpH5></label>
          <Select
            className="w-100"
            defaultValue={tipAvailable || 'Select'}
            onChange={(e) => setTipAvailable(e)}
            disabled={disableAccount}
          >
            <Option key={1} value="Yes">Yes</Option>
            <Option key={2} value="No">No</Option>
          </Select>
          {
            !disableAccount && (
              <SpError>
                {simpleValidator.current.message('tip Available', tipAvailable, 'required')}
              </SpError>
            )
          }
        </div>
        <div className="mt-3">
          <label htmlFor=""><SpH5>QR Quantity</SpH5></label>
          <Input
            placeholder="Quantity"
            value={count}
            disabled={disableQuantity}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              const { value } = e.currentTarget;
              if (regex.test(value) || value === '') {
                setCount(e.currentTarget.value);
              } else if (!regex.test(value)) {
                setCount(null);
              }
            }}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
          />
          {
            !disableQuantity && (
              <SpError>
                {simpleValidator.current.message('count', count, 'numeric|required|quantity')}
              </SpError>
            )
          }
          <div className="mt-2">
            <SpText>Maximum 100 at a time</SpText>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddStaticQr);
