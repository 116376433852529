import { OFFERDETAILS } from '../actionTypes';

const initialState = {
  offerDetailsStatus: undefined,
  offerDetailsError: undefined,
  offerRedemptions: [],
  totalRedemptionElements: undefined,
  content: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OFFERDETAILS.FETCH:
      return {
        ...state,
        offerDetailsStatus: 'STARTED',
      };
    case OFFERDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        content: action.payload,
        offerDetailsStatus: 'SUCCESS',
      };
    case OFFERDETAILS.REDEMPTIONS_SUCCESS:
      console.log(action.payload.content);
      return {
        ...state,
        ...action.payload,
        offerRedemptions: action.payload.content,
        totalRedemptionElements: action.payload.totalElements,
        offerDetailsStatus: 'SUCCESS',
        content: state.content,
      };
    case OFFERDETAILS.FAILED:
      return {
        ...state,
        offerDetailsError: action.payload,
        offerDetailsStatus: 'FAILED',
      };
    default:
      return state;
  }
};
