import {
  call,
  put,
} from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  LOADING,
  USERPROFILE,
  NOTIFICATION,
} from '../actionTypes';

const fetchUserProfile = async gid => axiosAuthInstance.get(`${API_END_POINTS.USER_DETAILS}/${gid}`);
const patchUserProfile = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.USER_DETAILS}/${gid}`, params);

export function* getUserProfile(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchUserProfile, action.payload);
      yield put({ type: USERPROFILE.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchUserProfile, action);
      yield put({ type: USERPROFILE.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: USERPROFILE.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateUserProfile(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchUserProfile, action.payload);
    const { data: { entity } } = yield call(fetchUserProfile, action.payload.gid);
    yield put({ type: USERPROFILE.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.USERDETAILS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
