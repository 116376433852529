import { PRINTER_LABEL_DETAILS } from '../actionTypes';

export const fetchInventoryPrinterLabelDetails = (action) => ({
  type: PRINTER_LABEL_DETAILS.FETCH,
  payload: action.payload,
});

export const addItemPrinterLabel = (action) => ({
  type: PRINTER_LABEL_DETAILS.LABEL_ITEM_ADD,
  payload: action.payload,
});

export const fetchItemByPrinterLabel = (action) => ({
  type: PRINTER_LABEL_DETAILS.LABEL_ITEM_FETCH,
  payload: action.payload,
});
