import { FUNDINGSOURCE_LINK_STATUSES } from 'appconstants';

export default value => {
  if (value === 'CREATED') {
    return FUNDINGSOURCE_LINK_STATUSES.CREATED;
  } if (value === 'SUCCEEDED') {
    return FUNDINGSOURCE_LINK_STATUSES.SUCCEEDED;
  } if (value === 'EXPIRED') {
    return FUNDINGSOURCE_LINK_STATUSES.EXPIRED;
  } return null;
};
