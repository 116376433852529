import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import {
  Col, Row, Spin, Steps, Card, Table,
} from 'antd';

import moment from 'moment-timezone';

import { SpText, SpStatusTag } from 'components/DesignKit';
import Loading from 'components/Loading';
import QuickBooksIcon from 'imgs/quickbook.png';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { quickbooksActions, quickbookDetailsActions } from 'store/actions';
import QuickBookStart from 'imgs/qbstart.png';
import QuickBookLogin from 'imgs/qblogin.png';
import QuickBookCompany from 'imgs/qbcompany.png';
import SelectedCompany from 'imgs/companyselected.png';
import InvoiceImage from 'imgs/startintegration.png';
import ConnectImage from 'imgs/swirepayconnect.png';
import ConnectedImage from 'imgs/connected.png';
import { ROOT_QUICKBOOKS_BASE_URL } from 'api';
import {
  QUICKBOOKS,
} from 'store/actionTypes';
import getQuickBookBackground from 'utils/getQuickBookBackground';
import getQuickBookStatus from 'utils/getQuickBookStatus';

type Props = {
  accountQuickBooks: Object,
  quickbookAccountDetails: Array<Object>,
  fetchQuickBooks: Function,
  fetchQuickBookDetails: Function,
  addQuickBooks: Function,
  loader: boolean,
  submitting: boolean,
  test: boolean,
  hasQBDetails: boolean,
  selectedAccount: Object,
  setQuickBook: Function,
};

const QuickBooks = (props: Props) => {
  const { Step } = Steps;
  const {
    accountQuickBooks,
    quickbookAccountDetails,
    fetchQuickBooks,
    fetchQuickBookDetails,
    addQuickBooks,
    loader,
    submitting,
    test,
    setQuickBook,
    selectedAccount,
    hasQBDetails,
  } = props;
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const connectQuickBooks = (path) => {
    window.open(path, '_self', '');
  };

  const isEmpty = (obj) => Object.keys(obj).length === 0;

  const debouncedFetchQuickBooks = debounce(fetchQuickBooks, 2000, { leading: true });

  const getData = () => {
    debouncedFetchQuickBooks();
  };

  useEffect(() => {
    if (!isEmpty(accountQuickBooks || '')) {
      setQuickBook(true);
    }
  }, [accountQuickBooks]);

  useEffect(() => {
    if (hasQBDetails) {
      fetchQuickBookDetails();
    }
  }, [hasQBDetails]);

  useEffect(() => {
    getData();
  }, [location]);

  const redirect = (url) => {
    url && history && history.push(url);
  };

  useEffect(() => {
    if (!submitting) {
      setLoading(false);
      redirect('/integrations/quickbooks');
    }
  }, [submitting]);

  useEffect(() => {
    const { code, state, realmId } = queryString.parse(location.search);
    if (code && state && realmId) {
      setLoading(true);
      const params = `&code=${code}&state=${state}&realmId=${realmId}`;
      addQuickBooks({
        code, state, realmId, params,
      });
    }
  }, [location]);

  const localSteps = [{
    title: 'Select the + New icon on the left and select Invoice',
    image: QuickBookStart,
  },
  {
    title: 'In a new Invoice, you will see the option to make Online payments. Click Setup now.',
    image: InvoiceImage,
  }, {
    title: 'A decision box will appear asking whether you want to leave without saving. Click Yes.',
    image: QuickBookLogin,
  }, {
    title: 'You will be directed to a Swirepay QuickBooks integration page. Under Connect '
      + 'your Swirepay Account, click one to create a new Swirepay account.',
    image: SelectedCompany,
  }, {
    title: 'Please select your newly created or existing Quickbooks account and click Next.',
    image: QuickBookCompany,
  }, {
    title: 'Please review and confirm by clicking the Continue button on the next screen.',
    image: ConnectImage,
  }, {
    // eslint-disable-next-line
    title: 'That\'s it! You have successfully connected Swirepay with your Quickbooks account. Your Swirepay Integerations screen will display that Quickbooks is connected. Please, click on Connected link to learn more on how data is being synced with Quickbooks. Rest easy as all your payments, refunds, payouts and more are being reconciled near real time so you can focus more on your business.',
    image: ConnectedImage,
  },
  ];
  const columns = [
    {
      title: 'SWIREPAY',
      dataIndex: 'webhookType.name',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (description) => (
        <span>
          {description || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'QUICKBOOKS',
      dataIndex: 'webhookType.name',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (description) => (
        <span>
          {description || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => {
        if (status) {
          return (
            <SpStatusTag style={{ backgroundColor: getQuickBookBackground(status) }}>
              {getQuickBookStatus(status)}
            </SpStatusTag>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'updatedAt',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (updatedAt) => {
        if (updatedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(updatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading || loader) {
    return <Loading />;
  }

  return (
    <>
      {
        !loader && !test && (!accountQuickBooks || isEmpty(accountQuickBooks)) ? (
          <div className="site-card-wrapper p-2 px-3">
            {
              loader && (<div className="modal fade show"> <Spin /></div>)
            }
            <div className="site-card-border-less-wrapper">
              <div className="p-0 bg-white rounded">
                <div
                  className="px-2 py-2 d-flex justify-content-between align-items-center rounded-top"
                  style={{ background: 'linear-gradient(93deg, #04c8fb, #007ee5 100%)' }}
                >
                  <SpText fontSize="18px" fontWeight="bold" color="#ffffff" className="px-2">INTUIT QUICKBOOKS</SpText>
                  {
                    !loader && !test && (!accountQuickBooks || isEmpty(accountQuickBooks)) && (
                      <button
                        onClick={() => connectQuickBooks(`${ROOT_QUICKBOOKS_BASE_URL}/connect`)}
                        type="button"
                        className="btn btn-outline-light rounded-pill px-4 py-1"
                      >
                        Start Integration <span className="px-2">&#8594;</span>
                      </button>
                    )
                  }
                </div>
                <div className="p-5">
                  <Row gutter={[8, 8]}>
                    <Col lg={8}>
                      <div className="col-10 mb-4">
                        <img src={QuickBooksIcon} alt="" className="mb-4" />
                        <br />
                        <SpText color="#767676">
                          With your accounting data organized on the cloud,
                          you can track sales, create and send invoices, and know how your
                          business is doing at any time.
                          <br /><br />
                          What&apos;s more, it&apos;s easy to use and you need not be an accounting or
                          finance expert to use QuickBooks Accounting Software.<br /><br />
                          Connect your bank account to automatically import and categorize transactions.
                          Sync with popular apps and easily snap photos of your receipts to store them with QuickBooks Mobile.<br /><br />
                          Powerful invoicing features such as invoice tracking, payment reminders are at your
                          fingertips. What&apos;s more, is that you can access dozens of reports & manage your expenses.
                          All power packed in one single solution — QuickBooks Accounting Software. What&apos;s more,
                          you can also use our mobile app to manage your business on the go!
                        </SpText>
                      </div>
                    </Col>
                    {
                      localSteps && localSteps.length > 0 && (
                        <>
                          <Col span={16}>
                            <Steps direction="vertical">
                              {
                                localSteps.map((value, index) => (
                                  <Step
                                    key={index}
                                    status="process"
                                    className="w-100"
                                    description={(
                                      <Row className="mb-5 w-100">
                                        <Col lg={12}>
                                          <div>{value && value.title}</div>
                                        </Col>
                                        <Col lg={12}>
                                          <div
                                            hidden={!(value && value.image)}
                                            className="w-100"
                                            style={{ height: '180px', border: 'solid 5px #0190eb' }}
                                          >
                                            <div
                                              className="hover position-absolute"
                                              style={{
                                                height: '40px',
                                                width: '97.5%',
                                                bottom: '5px',
                                                backgroundImage: 'linear-gradient(to bottom, rgb(89 87 87 / 0%), rgb(56 55 55))',
                                              }}
                                            />
                                            <img className="w-100 h-100" src={value && value.image} alt="" />
                                          </div>
                                        </Col>
                                      </Row>
                                    )}
                                  />
                                ))
                              }
                            </Steps>
                          </Col>
                        </>
                      )
                    }
                  </Row>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Card>
            <Table
              columns={columns}
              dataSource={quickbookAccountDetails}
              pagination={false}
            />
          </Card>
        )
      }
    </>

  );
};

const mapStateToProps = (state) => ({
  accountQuickBooks: state.quickbooks.accountQuickBooks,
  quickbookAccountDetails: state.quickbookDetails.content,
  quickbooksError: state.quickbooks.quickbooksError,
  hasQBDetails: state.quickbooks.hasQBDetails,
  loader: state.loading.loading,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  fetchQuickBooks: param => dispatch(quickbooksActions.fetchQuickBooks({
    payload: param,
  })),
  addQuickBooks: param => dispatch(quickbooksActions.addQuickBooks({
    payload: param,
  })),
  fetchQuickBookDetails: param => dispatch(quickbookDetailsActions.fetchQuickBookDetails({
    payload: param,
  })),
  setQuickBook: (value) => dispatch(quickbooksActions.setQuickBook({
    type: QUICKBOOKS.SET_QUICKBOOK,
    payload: value,
  })),
});


export default connect(mapStateToProps, mapDispatchToProps)(QuickBooks);
