/* eslint-disable */
import React from 'react';

const shops = ({ fill = "silver" }) => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.57953 7.30347V9.39305C1.57953 12.0196 1.57953 13.3333 2.39585 14.1489C3.21218 14.9645 4.52513 14.9652 7.15172 14.9652H7.84825C10.4748 14.9652 11.7885 14.9652 12.6041 14.1489C13.4197 13.3326 13.4204 12.0196 13.4204 9.39305V7.30347" stroke={fill} strokeWidth="1.5"/>
    <path d="M5.75871 1.03476H9.24133L9.69546 5.57401C9.72538 5.88031 9.69087 6.18949 9.59416 6.48166C9.49744 6.77384 9.34065 7.04254 9.13388 7.27049C8.9271 7.49845 8.67492 7.68061 8.39353 7.80527C8.11214 7.92994 7.80779 7.99434 7.50002 7.99434C7.19226 7.99434 6.8879 7.92994 6.60651 7.80527C6.32512 7.68061 6.07294 7.49845 5.86616 7.27049C5.65939 7.04254 5.5026 6.77384 5.40588 6.48166C5.30917 6.18949 5.27466 5.88031 5.30458 5.57401L5.75871 1.03476Z" stroke={fill} strokeWidth="1.5"/>
    <path d="M1.46114 3.36881C1.58512 2.74891 1.64711 2.43895 1.77318 2.18751C1.90454 1.92564 2.0895 1.69432 2.31605 1.50857C2.5426 1.32282 2.80568 1.18678 3.08822 1.10929C3.35986 1.03476 3.67608 1.03476 4.30853 1.03476H5.75869L5.25371 6.08108C5.22706 6.36988 5.14175 6.6502 5.003 6.90488C4.86425 7.15957 4.67498 7.38325 4.44678 7.56225C4.21858 7.74124 3.95625 7.87178 3.67584 7.94587C3.39544 8.01996 3.10287 8.03605 2.81603 7.99314C2.5292 7.95023 2.25414 7.84923 2.00769 7.69633C1.76125 7.54342 1.5486 7.34183 1.38277 7.10389C1.21694 6.86595 1.10142 6.59667 1.04327 6.31253C0.985127 6.02839 0.985584 5.73537 1.04462 5.45142L1.46114 3.36881ZM13.5389 3.36881C13.4149 2.74891 13.3529 2.43895 13.2268 2.18751C13.0955 1.92564 12.9105 1.69432 12.6839 1.50857C12.4574 1.32282 12.1943 1.18678 11.9118 1.10929C11.6401 1.03476 11.3239 1.03476 10.6915 1.03476H9.24131L9.74629 6.08108C9.77294 6.36988 9.85825 6.6502 9.997 6.90488C10.1357 7.15957 10.325 7.38325 10.5532 7.56225C10.7814 7.74124 11.0438 7.87178 11.3242 7.94587C11.6046 8.01996 11.8971 8.03605 12.184 7.99314C12.4708 7.95023 12.7459 7.84923 12.9923 7.69633C13.2388 7.54342 13.4514 7.34183 13.6172 7.10389C13.7831 6.86595 13.8986 6.59667 13.9567 6.31253C14.0149 6.02839 14.0144 5.73537 13.9554 5.45142L13.5389 3.36881Z" stroke={fill} strokeWidth="1.5"/>
    <path d="M5.75867 14.617V12.5274C5.75867 11.8761 5.75867 11.5509 5.89867 11.3085C5.99036 11.1497 6.12225 11.0178 6.28106 10.9261C6.52345 10.7861 6.84873 10.7861 7.49998 10.7861C8.15123 10.7861 8.4765 10.7861 8.71889 10.9261C8.87771 11.0178 9.00959 11.1497 9.10129 11.3085C9.24129 11.5509 9.24129 11.8761 9.24129 12.5274V14.617" stroke={fill} strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);

export default shops;
