import { Visible } from 'react-awesome-styled-grid';
import React from 'react';

type Props = {
  children: Node
}

const SmallScreenView = (props:Props) => {
  const { children } = props;
  return (
    <Visible xs sm>
      {children}
    </Visible>
  );
};

export default SmallScreenView;
