export default status => {
  if (status === 'PENDING') {
    return '#e2e2e2';
  } if (status === 'PAID') {
    return '#ececff';
  } if (status === 'CANCELLED') {
    return '#e2e2e2';
  } if (status === 'VOID') {
    return '#ffe0b1';
  } return '#ff858d';
};
