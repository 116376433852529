import { INVENTORY_RESERVATIONS } from '../actionTypes';

export const fetchInventoryReservations = (action) => ({
  type: INVENTORY_RESERVATIONS.FETCH,
  payload: action.payload,
});

export const deleteInventoryReservations = (action) => ({
  type: INVENTORY_RESERVATIONS.DELETE,
  payload: action.payload,
});

export const fetchFilterReservations = (action) => ({
  type: INVENTORY_RESERVATIONS.FETCH_FILTER_RESERVATIONS,
  payload: action.payload,
});

export const holdReservations = (action) => ({
  type: INVENTORY_RESERVATIONS.FETCH_HOLD_RESERVATIONS,
  payload: action.payload,
});

export const fetchSearchReservations = (action) => ({
  type: INVENTORY_RESERVATIONS.FETCH_SEARCH_RESERVATIONS,
  payload: action.payload,
});
