// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Upload,
  message,
  Icon,
  Row,
  Col,
  Switch,
  Select,
  TimePicker,
  Radio,
} from 'antd';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';
import SimpleReactValidator from 'simple-react-validator';
import { NOTIFICATION } from 'store/actionTypes';
import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';
import {
  notificationActions,
} from 'store/actions';
import {
  MESSAGES,
  COUNTRY_CODES,
  COUNTRY_PHONE_CODE,
  ORDER_MODES,
  STORE_TYPES
} from 'appconstants';
import getStateList from 'utils/stateList';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import {
  SpH5,
  SpError,
  SpText,
  SpButton,
} from 'components/DesignKit';
import moment from 'moment-timezone';
import Previewicon from 'components/NavIcons/previewIcon';
import Uploadicon from 'components/NavIcons/uploadIcon';
import OrderTypes from '../../OrderTypes';

const { Option } = Select;
const RadioGroup = Radio.Group;



type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  country: String,
  statesInCountry: Array<Object>,
  ratesList: Array<Object>,
  zipTag: String,
  cntryId: Number,
  phoneCode: string,
  setNotification: Function,
};

const AddCategory = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    country,
    statesInCountry,
    zipTag,
    cntryId,
    ratesList,
    phoneCode,
    setNotification,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [logoUrl, setLogo] = useState('');
  const [preview, setPreview] = useState('');
  const [fileName, setFileName] = useState('');
  const [loader, setLoading] = useState(false);
  const [operatingHours, setOperatingHours] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [countryCode, setCountryCode] = useState(getCountryAlpha2(cntryId));
  const [countryPhoneCode, setCountryPhoneCode] = useState(phoneCode);
  const [countryName, setCountryName] = useState('');
  const [enableOperationHours, setEnableOperationHours] = useState(false);
  const [enableShopAssociate, setEnableShopAssociate] = useState(false);
  const [syncAllItems, setSyncAllItems] = useState(false);
  const [enablePayAtStore, setEnablePayAtStore] = useState(false);
  const [stateList, setSateList] = useState(statesInCountry);
  const [taxRates, setTaxRates] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneFormate, setPhoneFormate] = useState();
  const [bannerText, setBannerText] = useState('');
  const [addZipRecipient, setAddZipRecipient] = useState(['']);
  const [selectedOption, setSelectedOption] = useState(ORDER_MODES[0].value);
  const postalCodeRegex = country === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;
  const STRING_LENGTH = 1;
  const STRING_LENGTH_NAME = 50;
  const [shopMode, setShopMode] = useState("WEB");


  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      stringLengthName: {
        message: 'Shop name must be less than 50 characters long.',
        rule: val => val.length <= STRING_LENGTH_NAME,
      },
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  useEffect(() => {
    if (country && country === 'India') {
      setCountryName('INDIA');
    } else if (country && country === 'United States of America') {
      setCountryName('USA');
    }
  }, []);

  const updateCountry = (value) => {
    setCountryCode(value);
    setSateList(getStateList(value));
  };

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const onRadioChange = (e) => {
    setSelectedOption(e.target.value);

    if(e.target.value !=='ONLINE_ORDER'){
     setEnablePayAtStore(false);
    // alert(e.target.value);
    }
  };

  const onRadioChangeStore = (e) => {
    setShopMode(e.target.value);
  };

  useEffect(() => {
    const list = [
      {
        dayOfWeek: 'MONDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'TUESDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'WEDNESDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'THURSDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'FRIDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'SATURDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'SUNDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
    ];
    setOperatingHours([...list]);
  }, []);

  const selectOperatingHours = (e, type, i, j) => {
    const list = [...operatingHours];
    const time = e.format('hh:mm a');
    const timeStamp = time.split(' ')[1];
    const actualTime = time.split(' ')[0];
    const a = actualTime.split(':');
    let minutes;
    if (a[0] === '12' && timeStamp === 'am') {
      minutes = +a[1];
    } else if (a[0] === '12' && timeStamp === 'pm' && type === 'From') {
      minutes = +a[0] * 60;
    } else {
      minutes = (+a[0] + (timeStamp === 'pm' ? 12 : 0)) * 60 + (+a[1]);
    }
    if (type === 'From') {
      list[i].operatingHours[j].openingTime = minutes;
    } else if (type === 'To') {
      list[i].operatingHours[j].closingTime = minutes;
    }
    setOperatingHours([...list]);
  };

  const updateStatus = (val, i, j) => {
    const list = [...operatingHours];
    list[i].operatingHours[j].open = val;
    setOperatingHours([...list]);
  };

  const getTimeVal = (id, index, type) => {
    if (type === 'From') {
      const fromTime = operatingHours[id].operatingHours[index].openingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          return moment(`${hours}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    } if (type === 'To') {
      const fromTime = operatingHours[id].operatingHours[index].closingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          const hr = 0;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    }
    return null;
  };

  const isAvailableChange = (value) => {
    setEnableOperationHours(value);
  };

  const isAvailableAssociate = (value) => {
    setEnableShopAssociate(value);
  };

  const isSyncItems = (value) => {
    setSyncAllItems(value);
  };

  const isEnablePayAtStore = (value) => {
    
    setEnablePayAtStore(value);
  };

  const onCountryCodeChange = (value) => {
    setCountryPhoneCode(value);
    const data = formatNumber(`${value}${phone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <Uploadicon />}
      <div className="ant-upload-text mt-2">Click or drag the file here to upload</div>
      <div className="mt-2"><SpText color="rgba(0, 0, 0, 0.45)">Support extension: .jpg .png</SpText></div>
    </div>
  );

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    const IMAGE = 'image/*';
    const pattern = file.type.match(IMAGE);
    if (!pattern) {
      message.error('File is not image');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 5MB!');
      return false;
    }
    return true;
  };

  const addNewRecipient = (event) => {
    event.preventDefault();
    setAddZipRecipient([...addZipRecipient, '']);
  };
  const handleRemoveZipCode = (i) => {
    const list = [...addZipRecipient];
    list.splice(i, 1);
    setAddZipRecipient(list);
  };

  const handleSelectChange = (values) => {
    setTaxRates(values);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.SHOP_LOGO}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const imageDetails = {
          fileUrl: entity,
          keyType: 'ACCOUNT_BANNER',
        };
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.SHOP_LOGO}`, imageDetails);
        setLogo(fileUrl);
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview(<img src={imageUrl} alt="..." style={{ width: '115px' }} />);
          });
        } else {
          setPreview(<Previewicon />);
        }
        setFileName(info.file.name);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const addTimeSlot = (dayIndex) => {
    const newTimeSlot = {
      openingTime: '',
      closingTime: '',
      open: false,
    };
    setOperatingHours((prevData) => {
      const updatedData = [...prevData];
      updatedData[dayIndex].operatingHours.push(newTimeSlot);
      return updatedData;
    });
  };

  const removeTimeSlot = (dayIndex, slotIndex) => {
    setOperatingHours((prevData) => {
      const updatedData = [...prevData];
      updatedData[dayIndex].operatingHours.splice(slotIndex, 1);
      return updatedData;
    });
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      logoUrl,
      enableOperationHours,
      operatingHours,
      streetName,
      cityName,
      stateName,
      zipCode,
      countryCode,
      taxRates,
      firstName,
      lastName,
      email,
      phone,
      enableShopAssociate,
      syncAllItems,
      enablePayAtStore,
      countryPhoneCode,
      orderModeData: [selectedOption],
      shopType:shopMode, 
      addZipRecipient,
      bannerText,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="630px"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add</SpButton>,
      ]}
      title="Add Shop"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2 mb-4">
          <label htmlFor=""><SpH5>Shop Name</SpH5></label>
          <Input
            placeholder="Shop Name"
            onChange={(e) => setName(e.currentTarget.value)}
            value={name}
          />
          <SpError>
            {simpleValidator.current.message('shop name', name, 'required|stringLength|stringLengthName')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Tax Rates</SpH5></label>
          <Select
            className="w-100"
            mode="multiple"
            allowClear
            placeholder="Select rates"
            onChange={handleSelectChange}
            showSearch
            optionFilterProp="displayName"
            style={{ width: '100%' }}
          >
            {ratesList.map((item) => (
              <Option key={item.gid} name={item.displayName} value={item.gid}>
                {item.displayName}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Shop Address</SpH5></label>
          <Input
            placeholder="Street"
            value={streetName}
            onChange={(e) => setStreetName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('street', streetName, 'required')}
          </SpError>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName}
                onChange={(e) => setCityName(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('city', cityName, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                className="w-100"
                placeholder="State"
                showSearch
                optionFilterProp="children"
                onChange={(e) => setStateName(e)}
              >
                {
                  stateList.map(s => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-capitalize"
                    >
                      {s.name}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('state', stateName, 'required')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipTag}</SpH5></label>
              <Input
                placeholder={zipTag}
                value={zipCode}
                onChange={(e) => setZipCode(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message(zipTag, zipCode, 'required|postalcode')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Country</SpH5></label>
              <Select
                defaultValue={countryName}
                style={{ width: '100%' }}
                disabled
                onChange={updateCountry}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ALPHA2}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor=""><SpH5>Banner Text</SpH5></label>
          <Input
            placeholder="Banner Text"
            onChange={(e) => setBannerText(e.currentTarget.value)}
            value={bannerText}
          />
          <SpError>
            {simpleValidator.current.message('banner text', bannerText, 'stringLength')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Add Image</SpH5></label>
          <Upload
            name="avatar"
            listType="picture-card upload-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={fileUpload}
          >
            {fileName ? preview : uploadButton}
          </Upload>
        </div>
        <div>&nbsp;</div>
        <div className="mb-4">
          <label htmlFor=""><SpH5>Order Modes</SpH5></label>
          <Row>
            <RadioGroup onChange={onRadioChange} value={selectedOption}>
              {ORDER_MODES.map((option) => (
                <Radio key={option.id} value={option.value}>
                  {option.value.replace(/_/g, " ")}
                </Radio>
              ))}
            </RadioGroup>
          </Row>

          {selectedOption === "ONLINE_ORDER" && (
            <div className="mb-4">
              <label className="d-block" />
              <Switch
                className="mr-2"
                checked={enablePayAtStore}
                onChange={isEnablePayAtStore}
              />
              Enable Pay At Store
            </div>
          )}
          {/* <Checkbox.Group style={{ width: '100%' }} value={orderModeData} onChange={onCheckChange}>
            <Row>
              {
                ORDER_MODES.map(item => (
                  <Col span={5}>
                    <Checkbox value={item.value}>
                      {(item.value === 'PRE_ORDER')
                        ? ORDER_MODE_DISPLAY.PRE_ORDER : item.value}
                    </Checkbox>
                  </Col>
                ))
              }
            </Row>
          </Checkbox.Group> */}
        </div>
        <div className="mb-4">
          <label htmlFor=""><SpH5> Shop Mode</SpH5></label>
          <Row>
            <RadioGroup onChange={onRadioChangeStore} value={shopMode}>
              {STORE_TYPES.map((option) => (
                <Radio key={option.id} value={option.value}>
                  {option.value.replace(/_/g, ' ')}
                </Radio>
              ))}
            </RadioGroup>
          </Row>
          </div>
        
        <Row className="mb-2">
          <Col>
            <SpH5>{`Available ${zipTag} For Delivery`}</SpH5>
          </Col>
        </Row>
        {addZipRecipient.map((item, i) => (
          <div className="mb-3 d-flex">
            <div className="w-50">
              <Input
                value={item}
                key={`${zipTag}${i}`}
                onChange={(e) => {
                  const list = [...addZipRecipient];
                  list[i] = e.currentTarget.value;
                  setAddZipRecipient(list);
                }}
                placeholder={zipTag}
              />
            </div>
            {
              i !== 0 && (
                <div>
                  <Icon
                    type="minus-circle"
                    className="ml-2 mt-2"
                    style={{
                      cursor: 'pointer', color: '#ff4d50',
                    }}
                    onClick={() => handleRemoveZipCode(i)}
                  />
                </div>
              )
            }
            <SpError className="mb-4">
              {simpleValidator.current.message(zipTag, item, 'postalcode')}
            </SpError>
          </div>
        ))}
        <Row className="mt-3 mb-4">
          <Col style={{ cursor: 'pointer' }}>
            <SpText
              fontWeight="600"
              color="#279dfe"
              onClick={addNewRecipient}
            >
              {`Add New ${zipTag}`}
            </SpText>
          </Col>
        </Row>
        <div className="mb-4">
          <label className="d-block" />
          <Switch
            className="mr-2"
            checked={syncAllItems}
            onChange={isSyncItems}
          /> Sync All Items
        </div>
        <div className="mb-4">
          <label className="d-block" />
          <Switch
            className="mr-2"
            checked={enableShopAssociate}
            onChange={isAvailableAssociate}
          /> Shop Associate
        </div>
        {
          enableShopAssociate && (
            <>
              <div className="d-flex">
                <div className="mb-3 mr-3">
                  <label htmlFor=""><SpH5>First Name</SpH5></label>
                  <Input
                    placeholder="First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.currentTarget.value)}
                  />

                  <SpError>
                    {simpleValidator.current.message('first name', firstName, 'required')}
                  </SpError>
                </div>

                <div className="mb-3">
                  <label htmlFor=""><SpH5>Last Name</SpH5></label>
                  <Input
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('last name', lastName, 'required')}
                  </SpError>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor=""><SpH5>Email</SpH5></label>
                <Input
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('email', email, 'required|email|checkLowerCase')}
                </SpError>
              </div>

              <div className="mb-3">
                <label htmlFor=""><SpH5>Phone Number</SpH5></label>
                <div className="d-flex">
                  <div
                    style={{ width: '15%' }}
                    onKeyPress={e => countryCodeValidation(e)}
                  >
                    <Select
                      className="w-100"
                      showSearch
                      defaultValue={countryPhoneCode}
                      onChange={onCountryCodeChange}
                    >
                      {COUNTRY_PHONE_CODE.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div style={{ width: '85%' }}>
                    <Input
                      className="InputnumFormate"
                      placeholder="Phone Number"
                      value={phoneFormate}
                      onChange={onPhoneNumberChange}
                      onBlur={e => {
                        if (e.currentTarget.value) {
                          setPhone(e.currentTarget.value);
                          const value = formatNumber(`${countryPhoneCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                          const formater = value.substr(value.indexOf(' ') + 1);
                          setPhoneFormate(formater);
                        }
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('phone number', phone, `required|phoneNumValidation:${countryPhoneCode}${phone}`)}
                    </SpError>
                  </div>
                </div>
              </div>
            </>
          )
        }
        <Row type="flex" className="mb-4" justify="start">
          <Col span={9}>
            <SpText fontSize="20px">Open 24/7</SpText><br />
            <SpText fontSize="12px">
              You can use the Open 24/7 toggle to select if your shop is open 24 hours a day, 7 days a week.
            </SpText>
          </Col>
          <Col className="mt-4 ml-4">
            <Switch
              className="mr-2"
              checked={enableOperationHours}
              onChange={isAvailableChange}
            /> Disabled
          </Col>
        </Row>
        <div className="mt-4">
          <label htmlFor=""><SpH5>Operating Hours</SpH5></label>
          <div className="ml-3">
            <Row className="my-2 mb-3" type="flex" justify="start">
              <Col span={4}>
                <span className="text-muted">Days</span>
              </Col>
              <Col span={6}>
                <span className="text-muted">From</span>
              </Col>
              <Col span={6}>
                <span className="text-muted">To</span>
              </Col>
            </Row>
            {operatingHours.map((day, dayIndex) => (
              <div key={dayIndex}>
                {day.operatingHours.map((timeSlot, slotIndex) => (
                  <div key={slotIndex}>
                    <Row className="my-2 mb-3" type="flex" justify="start">
                      <Col span={5}>
                        {slotIndex === 0 && <span>{day.dayOfWeek}</span>}
                      </Col>
                      <Col span={6}>
                        <TimePicker
                          allowClear={false}
                          use12Hours
                          format="h:mm a"
                          defaultValue={getTimeVal(dayIndex, slotIndex, 'From')}
                          onChange={(e) => selectOperatingHours(e, 'From', dayIndex, slotIndex)}
                        />
                      </Col>
                      <Col span={6}>
                        <TimePicker
                          allowClear={false}
                          use12Hours
                          format="h:mm a"
                          defaultValue={getTimeVal(dayIndex, slotIndex, 'To')}
                          onChange={(e) => selectOperatingHours(e, 'To', dayIndex, slotIndex)}
                        />
                      </Col>
                      <Col span={6}>
                        <Switch
                          className="mx-3"
                          defaultChecked={timeSlot.open}
                          onChange={val => updateStatus(val, dayIndex, slotIndex)}
                        /> Open
                      </Col>
                      <Col span={1}>
                        {slotIndex === 0 ? (
                          <Icon
                            type="plus-circle"
                            style={{
                              cursor: 'pointer', color: '#3E8BFF',
                            }}
                            onClick={() => addTimeSlot(dayIndex)}
                          />
                        )
                          : (
                            <Icon
                              type="minus-circle"
                              style={{
                                cursor: 'pointer', color: '#FF4B51',
                              }}
                              onClick={() => removeTimeSlot(dayIndex, slotIndex)}
                            />
                          )}
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            ))}
            {/* {
              operatingHours[0] && (
                <>
                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Monday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(0, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 0)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(0, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 0)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[0].open}
                        onChange={val => updateStatus(val, 0)}
                      /> Open
                    </Col>
                  </Row>

                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Tuesday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(1, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 1)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(1, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 1)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[1].open}
                        onChange={val => updateStatus(val, 1)}
                      /> Open
                    </Col>
                  </Row>
                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Wednesday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(2, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 2)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(2, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 2)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[2].open}
                        onChange={val => updateStatus(val, 2)}
                      /> Open
                    </Col>
                  </Row>
                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Thursday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(3, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 3)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(3, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 3)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[3].open}
                        onChange={val => updateStatus(val, 3)}
                      /> Open
                    </Col>
                  </Row>
                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Friday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(4, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 4)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(4, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 4)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[4].open}
                        onChange={val => updateStatus(val, 4)}
                      /> Open
                    </Col>
                  </Row>
                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Saturday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(5, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 5)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(5, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 5)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[5].open}
                        onChange={val => updateStatus(val, 5)}
                      /> Open
                    </Col>
                  </Row>
                  <Row className="my-2" type="flex" justify="start">
                    <Col span={4}>
                      <span>Sunday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(6, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 6)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(6, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 6)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[6].open}
                        onChange={val => updateStatus(val, 6)}
                      /> Open
                    </Col>
                  </Row>
                </>
              )
            } */}
          </div>
        </div>
        <div>&nbsp;</div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);
