import React from 'react';
import { SpText } from 'components/DesignKit';
import { Card, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DescriptionText, PText } from '../../../design';

const InputFileUpload = (props: Props) => {
  const {
    label,
    description,
    quantityMaxLimit,
  } = props;


  const cardStyle = {
    borderRadius: '10px',
    width: '100%',
    marginTop: '20px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card style={cardStyle} bordered={false}>
          <DescriptionText fontSize="12px" color="#474747">{label && label}</DescriptionText>
          <PText fontSize="10px">{description || ' '}</PText>
          <DescriptionText fontSize="12px" color="#474747">Upload Document</DescriptionText>
          <Input
            placeholder="Upload a file"
            maxLength={100}
            suffix={(
              <Upload>
                <UploadOutlined style={{ cursor: 'pointer' }} />
              </Upload>
              )}
          />
          <SpText fontStyle="italic" fontSize="9px" color="rgba(0, 0, 0, 0.5)">Maximum {quantityMaxLimit && quantityMaxLimit} Documents</SpText>


        </Card>
      </div>
    </>
  );
};

export default InputFileUpload;
