// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Select,
  DatePicker,
  Input,
  Skeleton,
} from 'antd';
import {
  SpButton,
  SpText,
  SpError,
} from 'components/DesignKit';
import styled from 'styled-components';
import getOrdersPaymentBackground from 'utils/getOrdersPaymentBackground';
import getOrdersPaymentStatus from 'utils/getOrdersPaymentStatus';

import Loading from 'components/Loading';

import {
  inventoryOrdersActions,
  customerSupportActions,
  notificationActions,
  filterParamsActions,
} from 'store/actions';
import { NOTIFICATION, FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  MESSAGES,
} from 'appconstants';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import RBAC from 'components/rbac';
import Clipboard from 'components/NavIcons/copyclipboard';
import Reset from 'imgs/Restart.png';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const env = process.env.REACT_APP_ENV;

const { Option } = Select;
const dateFormat = 'MMM DD, YYYY';
const kdsRole = localStorage.getItem('kdsRole');

type Props = {
  inventoryOrders: Array<Object>,
  setNotification: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  skeltonLoading: boolean,
  history: {
    push: Function,
  },
  fetchShops: Function,
  shops: Array<objects>,
  fetchFilterOrders: Function,
  fetchSearchOrders: Function,
  searchTypes: String,
  isMobileView: Boolean,
};

// $FlowFixMe
const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '75px',
  textAlign: 'center',
});

const OrdersList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    inventoryOrders,
    setNotification,
    loading,
    skeltonLoading,
    totalElements,
    test,
    history,
    fetchShops,
    shops,
    fetchFilterOrders,
    fetchSearchOrders,
    searchTypes,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const [pagination, setPagination] = useState({});
  const [shopsList, setShopsList] = useState([]);
  const [selectedShop, setSelectedShop] = useState({});
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [fromDateWithTimezone, setFromDateWithTimezone] = useState('');
  const [toDateWithTimeZone, setToDateWithTimeZone] = useState('');
  const todayDate = moment.utc(new Date()).tz(selectedAccount.timezone).format('MMM DD, YYYY, hh:mm a');
  const disableDate = moment().endOf('day');
  const [showMessage, setShowMessage] = useState(false);
  const [orderID, setOrderID] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const currencyType = selectedAccount && selectedAccount.currency;
  const debouncedFetchShops = debounce(fetchShops, 2000, { leading: true });

  useEffect(() => {
    if (shops && shops.length > 0) {
      setShopsList(shops);
      if (!location.search) {
        fetchFilterOrders('');
      }
    }
  }, [shops]);


  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = '';
    debouncedFetchShops({ currentPage, sortParams, filterParams });
  };


  useEffect(() => {
    getData(location.search);
  }, [selectedAccount, test]);

  useEffect(() => {
    if (searchTypes) {
      history.push({
        pathname: PAGES.INVENTORY_ORDERS,
        // eslint-disable-next-line max-len
        search: searchTypes,
      });
      fetchSearchOrders('');
    }
  }, [searchTypes]);

  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      const currentPage = parsed.page || 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const shopName = parsed.shopGid || '';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      const dateStart = parsed.fromDate || '';
      const dateEnd = parsed.toDate || '';
      const cloverPosOrder = parsed.orderNumber || '';
      const employee = parsed.customerName || '';
      setEmployeeName(employee);
      setOrderID(cloverPosOrder);
      const shopSelcted = shops.filter((item) => item.name === shopName);
      if (shopSelcted && shopSelcted.length !== 0) {
        setSelectedShop(shopSelcted[0]);
      }
      // Date with TimeZone Might change
      // setFromDate(dateStart);
      // setToDate(dateEnd);
      let filterParams;
      if (dateStart && dateEnd) {
        // eslint-disable-next-line max-len
        filterParams = `?orderNumber=${cloverPosOrder}&customerName=${employee}&shopGid=${shopName}&orderCreatedDate.AF=${dateStart}&orderCreatedDate.BF=${dateEnd}&page=${currentPage}`;
      } else {
        // eslint-disable-next-line max-len
        filterParams = `?orderNumber=${cloverPosOrder}&customerName=${employee}&shopGid=${shopName}&page=${currentPage}`;
      }
      fetchFilterOrders({ sortParams, filterParams });
    }
  }, [location]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const currentPage = page.current;
    const fromDat = parsed.fromDate || '';
    const toDat = parsed.toDate || '';
    if (fromDat && toDat) {
      history.push({
        pathname: PAGES.INVENTORY_ORDERS,
        // eslint-disable-next-line max-len
        search: `?orderNumber=${orderID}&customerName=${employeeName}&shopGid=${selectedShop?.gid || ''}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`,
      });
    } else {
      history.push({
        pathname: PAGES.INVENTORY_ORDERS,
        // eslint-disable-next-line max-len
        search: `?orderNumber=${orderID}&customerName=${employeeName}&shopGid=${selectedShop?.gid || ''}&page=${currentPage}`,
      });
    }
  };
  const handleSearch = (value) => {
    const parsed = queryString.parse(location.search);
    const currentPage = 1;
    const fromDat = parsed.fromDate || '';
    const toDat = parsed.toDate || '';
    setOrderID(value);
    if (fromDat && toDat) {
      history.push({
        pathname: PAGES.INVENTORY_ORDERS,
        // eslint-disable-next-line max-len
        search: `?orderNumber=${value}&customerName=${employeeName}&shopGid=${selectedShop?.gid || ''}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`,
      });
    } else {
      history.push({
        pathname: PAGES.INVENTORY_ORDERS,
        // eslint-disable-next-line max-len
        search: `?orderNumber=${value}&customerName=${employeeName}&shopGid=${selectedShop?.gid || ''}&page=${currentPage}`,
      });
    }
  };

  const handleEmployee = (value) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.page || 1;
    const fromDat = parsed.fromDate || '';
    const toDat = parsed.toDate || '';
    setEmployeeName(value);
    if (fromDat && toDat) {
      history.push({
        pathname: PAGES.INVENTORY_ORDERS,
        // eslint-disable-next-line max-len
        search: `?orderNumber=${orderID}&customerName=${value}&shopGid=${selectedShop?.gid || ''}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`,
      });
    } else {
      history.push({
        pathname: PAGES.INVENTORY_ORDERS,
        // eslint-disable-next-line max-len
        search: `?orderNumber=${orderID}&customerName=${value}&shopGid=${selectedShop?.gid || ''}&page=${currentPage}`,
      });
    }
  };

  const onFromDateChange = (date) => {
    // eslint-disable-next-line no-underscore-dangle
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone).startOf('day');
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    const tempDate = moment(utcDate).format(MOMENT_FORMAT);
    setFromDateWithTimezone(tempDate);
    setFromDate(Date);
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.page || 1;
    if (toDate && toDate > tempDate) {
      setShowMessage(false);
      history.push({
        pathname: PAGES.INVENTORY_ORDERS,
        // eslint-disable-next-line max-len
        search: `?orderNumber=${orderID}&customerName=${employeeName}&shopGid=${selectedShop?.gid || ''}&fromDate=${tempDate}&toDate=${toDateWithTimeZone}&page=${currentPage}`,
      });
    } else if (tempDate && toDate) {
      // setShowMessage(true);
      history.push({
        pathname: PAGES.INVENTORY_ORDERS,
        // eslint-disable-next-line max-len
        search: `?orderNumber=${orderID}&customerName=${employeeName}&shopGid=${selectedShop?.gid || ''}&page=${currentPage}`,
      });
    }
  };

  const onToDateChange = (date) => {
    // eslint-disable-next-line no-underscore-dangle
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone).endOf('day');
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    const tempDate = moment(utcDate).format(MOMENT_FORMAT);
    setToDateWithTimeZone(tempDate);
    setToDate(Date);
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.page || 1;
    if (fromDate && fromDate < tempDate) {
      setShowMessage(false);
      history.push({
        pathname: PAGES.INVENTORY_ORDERS,
        // eslint-disable-next-line max-len
        search: `?orderNumber=${orderID}&customerName=${employeeName}&shopGid=${selectedShop?.gid || ''}&fromDate=${fromDateWithTimezone}&toDate=${tempDate}&page=${currentPage}`,
      });
    } else {
      setShowMessage(true);
      history.push({
        pathname: PAGES.INVENTORY_ORDERS,
        // eslint-disable-next-line max-len
        search: `?orderNumber=${orderID}&customerName=${employeeName}&shopGid=${selectedShop?.gid || ''}&page=${currentPage}`,
      });
    }
  };

  const handleReset = () => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.page || 1;
    setFromDate('');
    setToDate('');
    history.push({
      pathname: PAGES.INVENTORY_ORDERS,
      // eslint-disable-next-line max-len
      search: `?shopName=${encodeURIComponent(selectedShop.name || '')}&page=${currentPage}`,
    });
  };

  const updateSelectedShop = (shop) => {
    setSelectedShop(shop[1]);
    const parsed = queryString.parse(location.search);
    const currentPage = 1;
    const fromDat = parsed.fromDate || '';
    const toDat = parsed.toDate || '';
    history.push({
      pathname: PAGES.INVENTORY_ORDERS,
      // eslint-disable-next-line max-len
      search: `?orderNumber=${orderID}&customerName=${employeeName}&shopGid=${shop[1]?.gid || ''}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`,
    });
    setFromDate('');
    setToDate('');
    setOrderID('');
    setEmployeeName('');
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.page || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [inventoryOrders]);

  const copyCode = (record) => {
    let path = 'http://localhost:3000';
    if (env === 'Staging') {
      path = 'https://staging.swirepay.com';
    }
    if (env === 'Production') {
      path = 'https://dashboard.swirepay.com';
    }
    const merchantId = record && record.cloverPosOrderId;
    const el = `${path}${PAGES.CLOVER_RECEIPT}?o=${merchantId}`;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.SUCCESS,
    });
  };

  const selectedRecord = (record) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.page || 1;
    const fromDat = parsed.fromDate || '';
    const toDat = parsed.toDate || '';
    // eslint-disable-next-line max-len
    fetchSearchOrders(`?orderNumber=${orderID}&customerName=${employeeName}&shopGid=${selectedShop?.gid || ''}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`);
    const orderGid = record.gid;
    history.push({
      pathname: `${PAGES.INVENTORY_ORDERS}/${orderGid}`,
      state: location.search,
    });
  };


  const columns = [
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '10%',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'ID',
      dataIndex: 'cloverPosOrderId',
      width: '11%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (cloverPosOrderId, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {(cloverPosOrderId) || ((record && record.orderNumber) || <>&#8211;</>)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (total) => {
        const amount = total === 0 ? '0.00' : total ? (total / 100).toFixed(2) : '';
        return (
          <span>
            {
              amount === '0.00'
                ? `${selectedAccount.currency && selectedAccount.currency.prefix} 0.00`
                : amount ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(amount, currencyType)}`
                  : <>&#8211;</>
            }
          </span>
        );
      },
    },
    {
      title: 'TIPS',
      dataIndex: 'totalTip',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (totalTip) => {
        const amount = totalTip === 0 ? '0.00' : totalTip ? (totalTip / 100).toFixed(2) : '';
        return (
          <span>
            {
              amount === '0.00'
                ? `${selectedAccount.currency && selectedAccount.currency.prefix} 0.00`
                : amount ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(amount, currencyType)}`
                  : <>&#8211;</>
            }
          </span>
        );
      },
    },
    {
      title: 'TAX',
      dataIndex: 'totalTax',
      width: '9%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (totalTax) => {
        const amount = totalTax === 0 ? '0.00' : totalTax ? (totalTax / 100).toFixed(2) : '';
        return (
          <span>
            {
              amount === '0.00'
                ? `${selectedAccount.currency && selectedAccount.currency.prefix} 0.00`
                : amount ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(amount, currencyType)}`
                  : <>&#8211;</>
            }
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'paymentStatus',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (paymentStatus) => (
        <Tooltip placement="top" title="Click here to view details">
          <StatusTag style={{ backgroundColor: getOrdersPaymentBackground(paymentStatus) }}>
            {getOrdersPaymentStatus(paymentStatus)}
          </StatusTag>
        </Tooltip>
      ),
    },
    {
      title: 'SHOP',
      dataIndex: 'shopName',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (shopName) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>{shopName || <>&#8211;</>}</span>
        </Tooltip>
      ),
    },
    {
      title: 'CUSTOMER',
      dataIndex: 'customerName',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (employee, record) => {
        const employeeNames = record?.customerName;
        return (
          <span>
            {
              employeeNames || <>&#8211;</>
            }
          </span>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '15%',
    align: 'center',
    render: (text, record) => {
      const { status } = record;
      if (status === 'CREATED') {
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              disabled
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      }
      return (
        <span>
          <SpButton
            type="secondary"
            borderRadius="50px"
            onClick={(e) => { e.stopPropagation(); copyCode(record); }}

          >
            <Clipboard />
          </SpButton>
        </span>
      );
    },
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row type="flex" justify="space-between" className="my-2 pb-2" align="middle">
        <Col>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <Select
                className="mr-3 mt-3"
                value={selectedShop.name || 'Select Shop'}
                style={{ width: '350px' }}
                bordered
                onChange={(value) => updateSelectedShop(value)}
              >
                <Option key={1} value="All">All</Option>
                {
                  shopsList.map((option) => (
                    <Option
                      key={option.gid}
                      value={[option.gid, option]}
                    >
                      {option.name}
                    </Option>
                  ))
                }
              </Select>
            </Col>
            <Col>
              <Input.Search
                className="mr-3 mt-3"
                style={{ width: 135 }}
                value={orderID}
                placeholder="Order Id"
                onChange={(e) => handleSearch((e.target.value).trim())}
              />
            </Col>
            <Col>
              <Input.Search
                className="mt-3"
                style={{ width: 135 }}
                value={employeeName}
                placeholder="Customer"
                onChange={(e) => handleEmployee(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        {
          kdsRole !== 'KDS-USER' && (
            <Col>
              <Row type="flex" justify="space-between" align="middle">
                <Col className="mr-2" style={{ width: 155 }}>
                  <SpText color="rgba(0, 0, 0, 0.6)">From</SpText>
                  <DatePicker
                    value={fromDate ? moment(fromDate) : ''}
                    format={dateFormat}
                    allowClear={false}
                    onChange={onFromDateChange}
                    disabledDate={current => current && current > moment(disableDate)}
                  />
                </Col>
                <Col style={{ width: 155 }}>
                  <SpText color="rgba(0, 0, 0, 0.6)">To</SpText>
                  <DatePicker
                    value={toDate ? moment(toDate) : ''}
                    format={dateFormat}
                    allowClear={false}
                    onChange={onToDateChange}
                    disabledDate={current => current && current > moment(disableDate)}
                  />
                </Col>
                <Col className="ml-2 mt-3">
                  <div
                    onClick={handleReset}
                    style={{ cursor: 'pointer' }}
                  >
                    <img src={Reset} alt="" />
                  </div>
                </Col>
              </Row>
              {
                showMessage && (
                  <SpError>Please select valid from to dates</SpError>
                )
              }
            </Col>
          )
        }
      </Row>
      <div style={{ border: '1px solid #959595', padding: '10px', borderRadius: '10px' }} className="mb-3">
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <SpText fontSize="22px" fontWeight="600" color="#292929">{selectedShop.name || 'All Shops'}</SpText>
          </Col>
          <Col>
            <SpText fontSize="16px" fontWeight="500" color="#292929">Date: {todayDate}</SpText>
          </Col>
        </Row>
      </div>

      {skeltonLoading ? <Skeleton active /> : (
        <RBAC
          fallback={(
            <Table
              columns={columns}
              onRow={(record) => ({
                onClick: () => {
                  selectedRecord(record);
                },
              })}
              rowKey={(record) => record.gid}
              dataSource={inventoryOrders}
              pagination={pagination}
              onChange={handleTableChange}
            />
          )}
          permit={
            [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS,
              ROLES.SUPERUSER, ROLES.KDSUSER1, ROLES.KDSADMIN1]
          }
        >
          <Table
            rowClassName={() => 'ant-table-clickable-row'}
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            columns={[...columns, actionColumn]}
            rowKey={(record) => record.gid}
            dataSource={inventoryOrders}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </RBAC>
      )}
    </Card>
  );
};

const mapStateToProps = (state) => ({
  inventoryOrders: state.inventoryOrders.content,
  totalElements: state.inventoryOrders.totalElements,
  loading: state.loading.loading,
  skeltonLoading: state.loading.skeleton,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  searchTypes: state.inventoryOrders.searchTypes,
  shops: state.customerSupport.shops,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryOrders: param => dispatch(inventoryOrdersActions.fetchInventoryOrders({
    payload: param,
  })),
  fetchFilterOrders: param => dispatch(inventoryOrdersActions.fetchFilterOrders({
    payload: param,
  })),
  fetchSearchOrders: param => dispatch(inventoryOrdersActions.fetchSearchOrders({
    payload: param,
  })),
  fetchShops: param => dispatch(customerSupportActions.fetchShops({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);
