import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance2,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  INVENTORY_ORDERS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryOrders = async (payload) => {
  const query = payload ? `?shopGid.EQ=${payload}&sortBy=createdAt&direction=DESC` : '?sortBy=createdAt&direction=DESC';
  return axiosAuthInstance2.get(`${API_END_POINTS.GET_INVENTORY_ORDER}${query}`);
};

const fetchFilterOrders = async (payload) => {
  if (payload) {
    const { filterParams } = payload;
    const { sortParams } = payload;
    return axiosAuthInstance2.get(`${API_END_POINTS.GET_INVENTORY_ORDER}${filterParams}${sortParams}`);
  }
  const query = '?page=1&size=10&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance2.get(`${API_END_POINTS.GET_INVENTORY_ORDER}${query}`);
};

export function* getInventoryOrders(action) {
  try {
    const { data: { entity } } = yield call(fetchInventoryOrders, action.payload);
    yield put({ type: INVENTORY_ORDERS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_ORDERS.FAILED, payload: e.response.data });
  }
}

export function* getInventoryFilterOrders(action) {
  const isEmptyPayload = action.payload === '';
  try {
    // Set loading state based on whether the action.payload is an empty string or not,
    // specifically for handling the debounced search functionality when the user inputs a key.
    yield put({ type: isEmptyPayload ? LOADING.SET : LOADING.SET_SKELETON });
    const { data: { entity } } = yield call(fetchFilterOrders, action.payload);
    yield put({ type: INVENTORY_ORDERS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response && e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_ORDERS.FAILED, payload: e.response ? e.response.data : null });
  } finally {
    yield put({ type: LOADING.UNSET });
    yield put({ type: LOADING.UNSET_SKELETON });
  }
}
