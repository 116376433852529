/* eslint-disable */
import React from 'react';

const upArrow = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.464524 6.12135C0.0739998 5.73082 0.0739997 5.09766 0.464524 4.70714L3.29295 1.87871C3.68348 1.48818 4.31664 1.48818 4.70716 1.87871L7.53559 4.70714C7.92612 5.09766 7.92612 5.73082 7.53559 6.12135C7.14507 6.51187 6.5119 6.51187 6.12138 6.12135L4.00006 4.00003L1.87874 6.12135C1.48821 6.51187 0.855048 6.51187 0.464524 6.12135Z" fill="#CCE0FF" />
  </svg>
);

export default upArrow;
