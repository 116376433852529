import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
} from 'antd';

import { supportActions } from 'store/actions';
import { useLocation } from 'react-router-dom';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';

import Loading from 'components/Loading';
import { SpButton, SpText } from 'components/DesignKit';

import RBAC from 'components/rbac';
import { ROLES } from 'appconstants';
import Add from './Add';
import View from './View';
import Edit from './Edit';

type Props = {
  fetchSupport: Function,
  updateSupport: Function,
  addSupport: Function,
  support: Object,
  loading: boolean,
  test: boolean,
  selectedAccount: Object,
  validator: boolean,
};

const SupportSettings = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    support,
    loading,
    test,
    fetchSupport,
    updateSupport,
    addSupport,
    validator,
  } = props;
  const [editSettings, setEditSettings] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');

  const getData = () => {
    fetchSupport(location.search);
  };

  useEffect(() => {
    getData();
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [location, test]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setEditSettings(false);
    }
  }, [validator]);

  const updateSupportSetting = (record: Object) => {
    const { gid } = support;
    const {
      email,
      phone,
      website,
    } = record;

    const params = {
      email,
      phone,
      website,
    };

    updateSupport({
      gid,
      params,
    });
  };

  const addSupportSetting = (record: Object) => {
    const {
      contactEmail,
      accountPhone,
      supportWebsite,
      countryCode,
    } = record;

    addSupport({
      email: contactEmail,
      phone: `${countryCode}${accountPhone}`,
      website: supportWebsite,
    });
  };

  const LoadComponent = () => {
    if (Boolean(support && Object.keys(support).length) && !editSettings) {
      return (
        <>
          <Row type="flex" justify="space-between" className="my-2 mb-3">
            <Col>
              <SpText className="text-uppercase" fontSize="20px">Manage Support Settings</SpText>
            </Col>
            <Col>
              {!editSettings && (
                <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}>
                  <SpButton
                    type="secondary"
                    shape="round"
                    ghost
                    onClick={() => setEditSettings(true)}
                  >
                    Edit Support Settings
                  </SpButton>
                </RBAC>
              )}
            </Col>
          </Row>
          <View
            support={support}
          />
        </>
      );
    }
    return editSettings ? (
      <Edit
        support={support}
        phoneCode={phoneCode}
        updateSupportSetting={updateSupportSetting}
        setEditSettings={() => setEditSettings(false)}
      />
    ) : (
      <Add
        status={selectedAccount.status}
        phoneCode={phoneCode}
        addSupportSetting={addSupportSetting}
      />
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card className="mb-2">
      <LoadComponent />
    </Card>
  );
};

const mapStateToProps = state => ({
  selectedAccount: state.account.selectedAccount,
  support: state.support.content,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  validator: state.loading.validator,
});

const mapDispatchToProps = dispatch => ({

  fetchSupport: gid => dispatch(supportActions.fetchSupport({
    payload: gid,
  })),
  addSupport: payload => dispatch(supportActions.addSupport({
    payload,
  })),
  updateSupport: params => dispatch(supportActions.updateSupport({
    payload: params,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupportSettings);
