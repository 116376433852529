import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosSalesForceAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  SP_OBJECT,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const getSpObjects = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SP_OBJECT}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SP_OBJECT}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SP_OBJECT}${query}${sorting}`);
  }
  return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SP_OBJECT}${query}${sorting}`);
};

const getAllSpObjects = async (payload) => {
  if (payload.filterParams) {
    return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SP_OBJECT_LIST}?${payload.filterParams}`);
  }
  return axiosSalesForceAuthInstance.get(`${API_END_POINTS.SP_OBJECT_LIST}`);
};

const postSpObject = async ({ params }) => axiosSalesForceAuthInstance.post(API_END_POINTS.SP_OBJECT, params);

const patchSpObject = async ({ gid, params }) => axiosSalesForceAuthInstance.patch(`${API_END_POINTS.SP_OBJECT}/${gid}`, params);

const deleteSpObjectByGid = async ({ gid }) => axiosSalesForceAuthInstance.delete(`${API_END_POINTS.SP_OBJECT}/${gid}`);

export function* fetchSpObjects(action) {
  try {
    const { data: { entity } } = yield call(getSpObjects, action.payload);
    yield put({ type: SP_OBJECT.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SP_OBJECT.FAILED, payload: e.response.data });
  }
}

export function* addSpObject(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postSpObject, action.payload);
    const { data: { entity } } = yield call(getSpObjects, action.payload);
    yield put({ type: SP_OBJECT.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SP_OBJECT.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateSpObject(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchSpObject, action.payload);
    const { data: { entity } } = yield call(getSpObjects, action.payload);
    yield put({ type: SP_OBJECT.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SP_OBJECT.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteSpObject(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteSpObjectByGid, action.payload);
    const { data: { entity } } = yield call(getSpObjects, action.payload);
    yield put({ type: SP_OBJECT.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SP_OBJECT.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* fetchAllSpObject(action) {
  try {
    const { data: { entity } } = yield call(getAllSpObjects, action.payload);
    yield put({ type: SP_OBJECT.SUCCESS, payload: { entityList: entity } });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SP_OBJECT.FAILED, payload: e.response.data });
  }
}
