import { COUPONDETAILS } from '../actionTypes';

const initialState = {
  couponDetailsStatus: undefined,
  couponDetailsError: undefined,
  redemptions: [],
  promotionals: [],
  couponListShops: [],
  couponListMenus: [],
  couponListCategories: [],
  couponListItems: [],
  couponShop: [],
  couponMenu: {},
  couponCategory: {},
  content: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COUPONDETAILS.FETCH:
      return {
        ...state,
        couponDetailsStatus: 'STARTED',
      };
    case COUPONDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        content: action.payload,
        promotionals: action.payload.promotionalCodes ? action.payload.promotionalCodes : [],
        redemptions: action.payload.couponRedemption ? action.payload.couponRedemption : [],
        couponDetailsStatus: 'SUCCESS',
      };
    case COUPONDETAILS.COUPON_SHOPS_SUCCESS:
      return {
        ...state,
        couponListShops: action.payload.content ? action.payload.content : [],
      };

    case COUPONDETAILS.COUPON_CATEGORIES_SUCCESS:
      return {
        ...state,
        couponListCategories: action.payload ? action.payload : [],
      };
    case COUPONDETAILS.COUPON_MENUS_SUCCESS:
      return {
        ...state,
        couponListMenus: action.payload ? action.payload : [],
      };
    case COUPONDETAILS.COUPON_ITEMS_SUCCESS:
      return {
        ...state,
        couponListItems: action.payload ? action.payload : [],
      };
    case COUPONDETAILS.COUPON_SHOP_SUCCESS:
      return {
        ...state,
        couponShop: action.payload.content ? action.payload.content : [],
      };

    case COUPONDETAILS.COUPON_CATEGORY_SUCCESS:
      return {
        ...state,
        couponCategory: action.payload ? action.payload : {},
      };
    case COUPONDETAILS.COUPON_MENU_SUCCESS:
      return {
        ...state,
        couponMenu: action.payload ? action.payload : {},
      };
    case COUPONDETAILS.FAILED:
      return {
        ...state,
        couponDetailsError: action.payload,
        couponDetailsStatus: 'FAILED',
      };
    default:
      return state;
  }
};
