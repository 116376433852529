import { QUICKBOOKSDETAILS } from '../actionTypes';

const initialState = {
  content: [],
  quickbookDetailsStatus: undefined,
  quickbookDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case QUICKBOOKSDETAILS.SUCCESS:
      return {
        ...state,
        content: action.payload,
      };
    case QUICKBOOKSDETAILS.FETCH:
      return {
        ...state,
        quickbookDetailsStatus: 'STARTED',
      };
    case QUICKBOOKSDETAILS.FAILED:
      return {
        ...state,
        quickbookDetailsError: action.payload,
        quickbookDetailsStatus: 'FAILED',
      };
    default:
      return state;
  }
};
