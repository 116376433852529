import { WEBHOOKS } from '../actionTypes';

export const fetchWebhooks = (action) => ({
  type: WEBHOOKS.FETCH,
  payload: action.payload,
});

export const addWebhook = (action) => ({
  type: WEBHOOKS.ADD,
  payload: action.payload,
});

export const updateWebhook = (action) => ({
  type: WEBHOOKS.UPDATE,
  payload: action.payload,
});

export const deleteWebhooks = (action) => ({
  type: WEBHOOKS.DELETE,
  payload: action.payload,
});

export const fetchWebhookevents = (action) => ({
  type: WEBHOOKS.EVENTS_FETCH,
  payload: action.payload,
});
