// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedDashboard: object
};

const UpdateDashboard = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedDashboard,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState(selectedDashboard && selectedDashboard.dashboardName);
  const [dashboardId, setDashboardId] = useState(selectedDashboard && selectedDashboard.dashboardId);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const gid = selectedDashboard && selectedDashboard.gid;

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      gid,
      name,
      dashboardId,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Custom Dashboard"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Custom Dashboard Name</SpH5></label>
          <Input
            placeholder="Name"
            defaultValue={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Custom Dashboard ID</SpH5></label>
          <Input
            placeholder="ID"
            value={dashboardId}
            onChange={(e) => setDashboardId(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('id', dashboardId, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.loading.submitting,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(UpdateDashboard);
