import { ISSUER } from '../actionTypes';

export const fetchIssuer = (action) => ({
  type: ISSUER.FETCH,
  payload: action.payload,
});

export const addIssuer = (action) => ({
  type: ISSUER.ADD,
  payload: action.payload,
});
