import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  MENUS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryMenus = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : 'sortBy=name&direction=ASC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS}${query}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS}${query}&${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS}${query}&${sorting}`);
};

const deleteMenus = async (payload) => axiosAuthInstance.delete(`${API_END_POINTS.GET_MENUS}/${payload.gid}`);

const fetchMenusOperatingHours = async () => axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS_OPERATING_HOURS}?size=100000&sortBy=createdAt`);

const addMenus = async (params) => axiosAuthInstance.post(`${API_END_POINTS.GET_MENUS}`, params);

const patchMenus = async (payload) => axiosAuthInstance.patch(`${API_END_POINTS.GET_MENUS}/${payload.gid}`, payload.param);

export function* getInventoryMenus(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchInventoryMenus, action.payload);
    yield put({ type: MENUS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: MENUS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getMenusOperatingHours(action) {
  try {
    const { data: { entity } } = yield call(fetchMenusOperatingHours, action.payload);
    yield put({ type: MENUS.SUCCESS_OPERATING_HOURS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: MENUS.FAILED, payload: e.response.data });
  }
}

export function* addInventoryMenu(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(addMenus, action.payload.param);
    // const { data: { entity } } = yield call(fetchInventoryMenus, action.payload);
    // yield put({ type: MENUS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Added new menu successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateInventoryMenus(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchMenus, action.payload);
    // const { data: { entity } } = yield call(fetchInventoryMenus, action.payload);
    // yield put({ type: MENUS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Selected menu updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}


export function* deleteInventoryMenus(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteMenus, action.payload);
    // const { data: { entity } } = yield call(fetchInventoryMenus, action.payload);
    // yield put({ type: MENUS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Selected menu deleted successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
