import { STATIC_QR } from '../actionTypes';

export const fetchStaticQR = action => ({
  type: STATIC_QR.FETCH,
  payload: action.payload,
});

export const addStaticQR = (action) => ({
  type: STATIC_QR.ADD,
  payload: action.payload,
});

export const addBulkStaticQR = (action) => ({
  type: STATIC_QR.BULK,
  payload: action.payload,
});
