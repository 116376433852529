import { PAYMENTSBUTTON } from '../actionTypes';

export const fetchPaymentsButton = (action) => ({
  type: PAYMENTSBUTTON.FETCH,
  payload: action.payload,
});

export const addPaymentsButton = (action) => ({
  type: PAYMENTSBUTTON.ADD,
  payload: action.payload,
});
