// @flow
import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import StaticQrs from './component/StaticQrList';
import StaticQrDetails from './component/StaticQrDetails';

const StaticQr = () => (
  <Switch>
    <Route exact path="/staticqr-admin" component={StaticQrs} />
    <Route exact path="/staticqr-admin/:gid" component={StaticQrDetails} />
  </Switch>
);

export default StaticQr;
