import { TAXRATE } from '../actionTypes';

export const fetchTaxrates = (action) => ({
  type: TAXRATE.FETCH,
  payload: action.payload,
});

export const addTaxrate = (action) => ({
  type: TAXRATE.ADD,
  payload: action.payload,
});

export const deleteTaxrate = (action) => ({
  type: TAXRATE.DELETE,
  payload: action.payload,
});

export const fetchTaxes = (action) => ({
  type: TAXRATE.FETCH_TAXES,
  payload: action.payload,
});
