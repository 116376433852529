import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  DatePicker,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  validationRegex,
  validators,
} from 'utils/validationMessages';

import { API_END_POINTS } from 'api';
import { MESSAGES } from 'appconstants';

import {
  SpForm,
  SpError,
  SpTextMute,
  SpText,
  SpCaption,
} from 'components/DesignKit';

import moment from 'moment-timezone';

import formatAmount from 'utils/formatAmount';

import KYCFileUpload from 'components/KycFileUpload';

const { Option } = Select;


let inpersonValue = 0;
const getInpersonValue = () => inpersonValue;

const setInpersonValue = (value) => {
  inpersonValue = value;
};

type Props = {
  isIndia: boolean,
  selectedAccount: Object,
}

const BusinessBankDetails = (props: Props, ref) => {
  const { isIndia, selectedAccount } = props;

  const [, forceUpdate] = useState();
  const [bankName, setBankName] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [bankRoutingNumber, setBankRoutingNumber] = useState('');
  const [ifscNumber, setIfscNumber] = useState('');
  const [bankStatementUrl, setBankStatementUrl] = useState('');
  const [accountType, setAccountType] = useState();
  const [whenIsCardCharged, setWhenIsCardCharged] = useState('');
  const [averageMonthlyVolume, setAverageMonthlyVolume] = useState('');
  const [averageTicketAmount, setAverageTicketAmount] = useState('');
  const [servicesDeliveredIn, setServicesDeliveredIn] = useState('');
  const [refundPolicy, setRefundPolicy] = useState('');
  const [seasonalBusiness, setSeasonalBusiness] = useState('');
  const [inPerson, setInPerson] = useState('');
  const [online, setOnline] = useState('');
  const [EBTCheckBox, setEBTCheckBoxChange] = useState(false);
  const [isServiceDateFlexible, setIsServiceDateFlexible] = useState(null);
  const [requestedServiceDate, setRequestedServiceDate] = useState('');
  const [voidCheckUrl, setVoidCheckUrl] = useState('');
  const currencyType = selectedAccount && selectedAccount.currency;
  const accountRegex = /[0-9]{6,17}/;
  const routingRegex = /[0-9]{9}/;
  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  const averagemonthlyVolumeHandler = useRef();

  const EBTCheckBoxChange = (e) => {
    setEBTCheckBoxChange(e.target.checked);
  };


  const getPercentage = (inprocess, val) => {
    let result = 0;
    if (inprocess && val) {
      result = inprocess[1] + val[1];
    } if (inprocess && !val) {
      result = inprocess[1] + 0;
    } if (!inprocess && val) {
      result = 0 + val[1];
    }
    return `${result}%`;
  };

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      confirmAccount: {
        message: MESSAGES.BANK_DETAILS.MATCH,
        rule: (val, params) => val === params[0],
      },
      accountNumber: {
        message: MESSAGES.BANK_DETAILS.VALID,
        rule: val => accountRegex.test(val),
      },
      bankIFSC: {
        message: MESSAGES.BANK_DETAILS.IFSC,
        rule: val => ifscRegex.test(val),
      },
      bankRoutingNumber: {
        message: MESSAGES.BANK_DETAILS.ROUTING,
        rule: val => routingRegex.test(val),
      },
      bankNameValidation: {
        message: validators.bussiness.validBankName,
        rule: val => /^[A-Za-z0-9&,\s.-]{1,50}$/.test(val),
      },
      accountName: {
        message: validators.bussiness.validBankAccountName,
        rule: val => /^[A-Za-z0-9&,\s.-]{1,50}$/.test(val),
      },
      modeOfTransactionValditation: {
        message: 'Mode of transaction Online and In Person must add up to 100%',
        rule: (val) => ((getInpersonValue()[1] + val[1]) === 100),
      },
      averageTicketAmountCheck: {
        message: 'Average ticket amount should be less than average monthly amount',
        rule: (val) => Number(averagemonthlyVolumeHandler?.current?.input?.value.replace(',', '')) > Number(val ? val.replace(',', '') : 0.00),
      },

    },
  }));

  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      bankName,
      accountHolderName,
      accountNumber,
      confirmAccountNumber,
      bankRoutingNumber,
      bankStatementUrl,
      accountType,
      ifscNumber,
      whenIsCardCharged,
      averageMonthlyVolume: averageMonthlyVolume ? averageMonthlyVolume.replace(/[^\d]/g, '') / 100 : '',
      averageTicketAmount: averageTicketAmount ? averageTicketAmount.replace(/[^\d]/g, '') / 100 : '',
      servicesDeliveredIn,
      refundPolicy,
      seasonalBusiness,
      modeOfTransactionOnline: online[1],
      modeOfTransactionPerson: inPerson[1],
      EBTCheckBox,
      requestedServiceDate,
      isServiceDateFlexible,
      voidCheckUrl,
    }),
  }));

  return (
    <>
      <Row className="my-2 mb-3">
        <Col>
          <SpText fontSize="20px" fontWeight="600">
            Manage Bank Information
          </SpText>
        </Col>
      </Row>
      <SpForm>

        <Row type="flex" justify="start" align="middle">
          <Col span={8}>
            <span>Account Holder Name</span>
          </Col>
          <Col span={16}>
            <Input
              placeholder="Name of the account holder"
              value={accountHolderName}
              onBlur={() => setAccountHolderName(accountHolderName && accountHolderName.trim())}
              onChange={e => setAccountHolderName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Account Holder Name', accountHolderName, 'required|accountName')}
            </SpError>
          </Col>
          <Col span={24}>
            <SpTextMute>
              Name should match the legal business name or legal
              name of the individual or sole proprietor
            </SpTextMute>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={8}>
            <span>Bank Name</span>
          </Col>
          <Col span={16}>
            <Input
              placeholder="Bank Name"
              value={bankName}
              onBlur={() => setBankName(bankName && bankName.trim())}
              onChange={e => setBankName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Bank name', bankName, 'required|bankNameValidation')}
            </SpError>
          </Col>
        </Row>

        {
          !isIndia && (
            <Row type="flex" justify="start" align="middle">
              <Col span={8}>
                <span>Account Type</span>
              </Col>
              <Col span={16}>
                <Select
                  placeholder="Select Account Type"
                  optionFilterProp="children"
                  className="w-100"
                  showSearch
                  value={accountType}
                  onChange={(value) => {
                    setAccountType(value);
                  }}
                >
                  <Option key={1} value="SAVINGS">SAVINGS</Option>
                  <Option key={2} value="CHECKING">CHECKING</Option>
                </Select>
                <SpError>
                  {simpleValidator.current.message('account Type', accountType, 'required')}
                </SpError>
              </Col>
            </Row>
          )
        }

        <Row type="flex" justify="start" align="middle">
          <Col span={8}>
            <span>Account Number</span>
          </Col>
          <Col span={16}>
            <Input
              placeholder="Bank account number"
              value={accountNumber}
              onBlur={() => setAccountNumber(accountNumber && accountNumber.trim())}
              onChange={e => setAccountNumber(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Account Number', accountNumber, 'required|accountNumber')}
            </SpError>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={8}>
            <span>Confirm Account No.</span>
          </Col>
          <Col span={16}>
            <Input
              placeholder="Confirm bank account number"
              value={confirmAccountNumber}
              onBlur={() => setConfirmAccountNumber(confirmAccountNumber && confirmAccountNumber.trim())}
              onChange={e => setConfirmAccountNumber(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Confirm Account Number', confirmAccountNumber, `required|confirmAccount:${accountNumber}`)}
            </SpError>
          </Col>
        </Row>

        {
          !isIndia && (
            <>
              <Row type="flex" justify="start" align="middle">
                <Col span={8}>
                  <span>Routing Number</span>
                </Col>
                <Col span={16}>
                  <Input
                    placeholder="Routing number"
                    value={bankRoutingNumber}
                    onBlur={() => setBankRoutingNumber(bankRoutingNumber && bankRoutingNumber.trim())}
                    onChange={e => setBankRoutingNumber(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('Bank Routing Number', bankRoutingNumber, 'required|bankRoutingNumber')}
                  </SpError>
                </Col>
              </Row>

              <Row type="flex" justify="start" align="middle">
                <Col span={8}>
                  <span>Void Check or Bank Letter/Statement</span>
                </Col>
                <Col span={16}>
                  <KYCFileUpload
                    name="Void Check"
                    endPoint={API_END_POINTS.US_BUSINESS_VOID_CHECK}
                    url={voidCheckUrl}
                    keyType="US_BUSINESS_VOID_CHECK"
                    setUrl={value => setVoidCheckUrl(value)}
                    type="Business"
                  />
                  {
                    (inPerson && inPerson[1] !== 0) ? (
                      <SpError>
                        {simpleValidator.current.message('Void Check', voidCheckUrl, 'required')}
                      </SpError>
                    ) : ''
                  }
                </Col>
                <Col span={8} className="ml-4" />
              </Row>

              <Row className="my-2 mb-3" style={{ marginLeft: '-16px' }}>
                <Col>
                  <SpText className="text-uppercase" fontSize="20px">
                    Processing Information
                  </SpText>
                </Col>
              </Row>

              <Row type="flex" justify="start" align="middle">
                <Col span={8}>
                  <span>Average Monthly Volume ($)</span>
                </Col>
                <Col span={16}>
                  <Input
                    value={averageMonthlyVolume}
                    placeholder="0.00"
                    ref={averagemonthlyVolumeHandler}
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/;
                      const { value } = e.currentTarget;
                      if (regex.test(value) || value === '') {
                        setAverageMonthlyVolume(e.currentTarget.value);
                      } else if (!regex.test(value)) {
                        setAverageMonthlyVolume(0.00);
                      }
                    }}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      if (e.currentTarget.value) {
                        const value = formatAmount(e.currentTarget.value, currencyType);
                        setAverageMonthlyVolume(value);
                      }
                    }}
                  />
                  <SpError>
                    {simpleValidator.current.message('average monthly volume', averageMonthlyVolume, 'required')}
                  </SpError>
                </Col>
              </Row>

              <Row type="flex" justify="start" align="middle">
                <Col span={8}>
                  <span>Average Ticket Amount ($)</span>
                </Col>
                <Col span={16}>
                  <Input
                    value={averageTicketAmount}
                    placeholder="0.00"
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/;
                      const { value } = e.currentTarget;
                      if (regex.test(value) || value === '') {
                        setAverageTicketAmount(e.currentTarget.value);
                      } else if (!regex.test(value)) {
                        setAverageTicketAmount(0.00);
                      }
                    }}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      if (e.currentTarget.value) {
                        const value = formatAmount(e.currentTarget.value, currencyType);
                        setAverageTicketAmount(value);
                      }
                    }}
                  />
                  <SpError>
                    {simpleValidator.current.message('average ticket amount', averageTicketAmount, 'required|averageTicketAmountCheck')}
                  </SpError>
                </Col>
              </Row>

              <Row type="flex" justify="start" align="middle">
                <Col span={8}>
                  <span>When Are Goods and Services Typically Delivered?</span>
                </Col>
                <Col span={16}>
                  <Select
                    placeholder="Services delivered in"
                    optionFilterProp="children"
                    className="w-100"
                    showSearch
                    value={servicesDeliveredIn}
                    onChange={(value) => {
                      setServicesDeliveredIn(value);
                    }}
                  >
                    <Option key={1} value="ZERO_TO_SEVEN_DAYS">0-7 Days</Option>
                    <Option key={2} value="EIGHT_TO_FOURTEEN_DAYS">8-14 Days</Option>
                    <Option key={3} value="FIFTEEN_TO_THIRTY_DAYS">15-30 Days</Option>
                    <Option key={4} value="OVER_THIRTY_DAYS">Over 30 Days</Option>
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('services delivered in', servicesDeliveredIn, 'required')}
                  </SpError>
                </Col>
              </Row>

              <Row type="flex" justify="start" align="middle">
                <Col span={8}>
                  <span>What is Your Current Refund Policy?</span>
                </Col>
                <Col span={16}>
                  <Select
                    placeholder="Select refund policy"
                    optionFilterProp="children"
                    className="w-100"
                    showSearch
                    value={refundPolicy}
                    onChange={(value) => {
                      setRefundPolicy(value);
                    }}
                  >
                    <Option key={1} value="EXCHANGE_ONLY">Exchange Only</Option>
                    <Option key={2} value="NO_REFUND_OR_EXCHANGE">No Refund or Exchange</Option>
                    <Option key={3} value="MORE_THAN_THIRTY_DAYS">More than 30 Days</Option>
                    <Option key={4} value="THIRTY_DAYS_OR_LESS">30 Days or Less</Option>
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('refund policy', refundPolicy, 'required')}
                  </SpError>
                </Col>
              </Row>

              <Row type="flex" justify="start" align="middle">
                <Col span={8}>
                  <span>Do Customers Typically Receive Goods/Services at the Time of Purchase?</span>
                </Col>
                <Col span={16}>
                  <Select
                    placeholder="When card is charged"
                    optionFilterProp="children"
                    className="w-100"
                    showSearch
                    value={whenIsCardCharged}
                    onChange={(value) => {
                      setWhenIsCardCharged(value);
                    }}
                  >
                    <Option key={1} value="WHEN_SERVICE_PROVIDED">Yes</Option>
                    <Option key={2} value="IN_ADVANCE">No</Option>
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('when is card charged', whenIsCardCharged, 'required')}
                  </SpError>
                </Col>
              </Row>

              <Row type="flex" justify="start" align="middle">
                <Col span={8}>
                  <span>Is Your Business Seasonal or do you Process Payments Throughout the Year?</span>
                </Col>
                <Col span={16}>
                  <Select
                    placeholder="Select seasonal business"
                    optionFilterProp="children"
                    className="w-100"
                    showSearch
                    value={seasonalBusiness}
                    onChange={(value) => {
                      setSeasonalBusiness(value);
                    }}
                  >
                    <Option key={1} value>SEASONAL</Option>
                    <Option key={2} value={false}>ALL YEAR</Option>
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('seasonal business', seasonalBusiness, 'required')}
                  </SpError>
                </Col>
              </Row>

              <Row type="flex" justify="start" align="middle">
                <Col span={8}>
                  <span>Mode of Transaction</span>
                </Col>
                <Col span={16} />
              </Row>
              <Row type="flex" className="mt-2" justify="start" align="middle">
                <Col span={6}>
                  <span>In Person</span>
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="Select in person"
                    optionFilterProp="children"
                    className="w-100"
                    showSearch
                    value={inPerson[0]}
                    onChange={(value) => {
                      setInPerson(value);
                      setInpersonValue(value);
                    }}
                  >
                    <Option key={1} value={['0%', 0]}>0%</Option>
                    <Option key={2} value={['25%', 25]}>25% </Option>
                    <Option key={3} value={['50%', 50]}>50%</Option>
                    <Option key={4} value={['75%', 75]}>75%</Option>
                    <Option key={5} value={['100%', 100]}>100%</Option>
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('in person', inPerson, 'required')}
                  </SpError>
                </Col>
                {(inPerson[1] > 0) && (
                  <Col span={10} className="ml-4">
                    <Checkbox onChange={EBTCheckBoxChange}>Accept Electronic Benefits Transfer (EBT) Payments</Checkbox>
                  </Col>
                )}
              </Row>

              <Row type="flex" className="mt-2" justify="start" align="middle">
                <Col span={6}>
                  <span>Online</span>
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="Select online"
                    optionFilterProp="children"
                    className="w-100"
                    showSearch
                    value={online[0]}
                    onChange={(value) => {
                      setOnline(value);
                    }}
                  >
                    <Option key={1} value={['0%', 0]}>0%</Option>
                    <Option key={2} value={['25%', 25]}>25% </Option>
                    <Option key={3} value={['50%', 50]}>50%</Option>
                    <Option key={4} value={['75%', 75]}>75%</Option>
                    <Option key={5} value={['100%', 100]}>100%</Option>
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('online', online, 'required|modeOfTransactionValditation')}
                  </SpError>
                </Col>
              </Row>

              <Row type="flex" className="mt-2" justify="start" align="middle">
                <Col span={6}>
                  <span>Total</span>
                </Col>
                <Col span={6} align="right" justify="end">
                  <span>
                    {getPercentage(inPerson, online)}
                  </span>
                </Col>
              </Row>
              {(inPerson[1] > 0) && (
                <Row type="flex" justify="start" align="middle">
                  <Col span={8}>
                    <span>Requested Service Date</span>
                  </Col>
                  <Col span={16}>
                    <DatePicker
                      allowClear={false}
                      onChange={(date, dateString) => setRequestedServiceDate(dateString)}
                      disabledDate={current => current && current < moment().subtract(1, 'days')}
                    />
                  </Col>
                  <Col>
                    <SpCaption className="pl-4">(Optional)</SpCaption>
                  </Col>
                </Row>
              )}
              {(inPerson[1] > 0) && (
                <Row type="flex" justify="start" align="middle">
                  <Col span={8}>
                    <span>Is Service Date Flexible</span>
                  </Col>
                  <Col span={16}>
                    <Select
                      placeholder="Is Service Date Flexible"
                      optionFilterProp="children"
                      className="w-100"
                      showSearch
                      value={isServiceDateFlexible}
                      onChange={(value) => {
                        setIsServiceDateFlexible(value);
                      }}
                    >
                      <Option key={1} value>Yes</Option>
                      <Option key={2} value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col>
                    <SpCaption className="pl-4">(Optional)</SpCaption>
                  </Col>
                </Row>
              )}
            </>
          )
        }

        {
          isIndia && (
            <>
              <Row type="flex" justify="start" align="middle">
                <Col span={8}>
                  <span>IFSC Number</span>
                </Col>
                <Col span={16}>
                  <Input
                    placeholder="IFSC number"
                    value={ifscNumber}
                    onBlur={() => setIfscNumber(ifscNumber && ifscNumber.trim())}
                    onChange={e => setIfscNumber((e.currentTarget.value).toUpperCase())}
                  />
                  <SpError>
                    {simpleValidator.current.message('ifsc Number', ifscNumber, 'required|bankIFSC',
                      { messages: { required: 'The IFSC Number field is required.' } })}
                  </SpError>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={8}>
                  <span>Bank Account Statement</span>
                </Col>
                <Col span={8}>
                  <KYCFileUpload
                    name="Bank Statement"
                    endPoint={API_END_POINTS.UPLOAD_BANK_STATEMENT}
                    url={bankStatementUrl}
                    setUrl={value => setBankStatementUrl(value)}
                    type="Business"
                  />
                  <SpError>
                    {simpleValidator.current.message('Bank statement proof', bankStatementUrl, 'required')}
                  </SpError>
                </Col>
                <Col span={6} className="ml-2">
                  <SpTextMute>
                    Upload an Image or pdf with bank account number, IFSC code,
                    company name all in one document. Self attested mandatory.
                  </SpTextMute>
                </Col>
              </Row>
            </>
          )
        }
      </SpForm>
    </>
  );
};

// $FlowFixMe
export default forwardRef(BusinessBankDetails);
