// @flow
import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Row,
  Col,
  Input,
  Checkbox,
  Divider,
  Icon,
} from 'antd';
import {
  SpError,
} from 'components/DesignKit';
import {
  SpText,
} from 'components/PaymentWidgetDesignKit';
import SimpleReactValidator from 'simple-react-validator';
import {
  COUNTRIES,
  MAX_AMOUNT,
} from 'appconstants';
import formatAmount from 'utils/formatAmount';
import { validationRegex } from 'utils/validationMessages';

const { TextArea } = Input;

type Props = {
  selectedAccount: Object,
  widgetDetails: Object,
  setSampleWidget: Function,
};

const AmountInfo = (props: Props, ref) => {
  const {
    widgetDetails,
    setSampleWidget,
    selectedAccount,
  } = props;

  const [, forceUpdate] = useState();
  const [amountList, setAmountList] = useState([]);
  const [customAmount, setCustomAmount] = useState();
  const [minAmount, setMinAmount] = useState();
  const [maxAmount, setMaxAmount] = useState();
  // eslint-disable-next-line
  const [payLabel, setPayLabel] = useState('');
  const [pay, setPay] = useState();
  const [terms, setTerms] = useState('');
  const [enforceMaxPayment, setEnforceMaxPayment] = useState(false);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const currencyType = selectedAccount.currency;
  const myRef = useRef(null);
  const [fixErrors, setFixErrors] = useState(false);
  const [minAmountvalid, setMinAmountValid] = useState(false);
  const [maxAmountvalid, setMaxAmountValid] = useState(false);
  // const [serviceFeeEnabled, setServiceFeeEnabled] = useState(false);
  // const [serviceFeeRate, setServiceFeeRate] = useState(null);
  // const [serviceFee, setServiceFee] = useState(null);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
    },
  }));


  useEffect(() => {
    myRef.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (widgetDetails && widgetDetails.currencyCode) {
      setCustomAmount(widgetDetails.showCustomAmount);
      if (widgetDetails.minAmount) {
        setMinAmount(formatAmount((widgetDetails.minAmount / 100), currencyType));
      }
      if (widgetDetails.maxAmount) {
        setMaxAmount(formatAmount((widgetDetails.maxAmount / 100), currencyType));
      }
      setPayLabel(widgetDetails.payButtonLabel);
      setTerms(widgetDetails.termsAndServices);
      if (widgetDetails.maxAmount && widgetDetails.maxAmount > 0) {
        setEnforceMaxPayment(true);
      }
      // setServiceFeeEnabled(widgetDetails.serviceFeeEnabled);
      // if (widgetDetails.serviceFeeEnabled && widgetDetails.serviceFeeRate) {
      //   setServiceFeeRate(formatAmount((widgetDetails.serviceFeeRate / 100), currencyType));
      // }
      // if (widgetDetails.serviceFeeEnabled && widgetDetails.serviceFee) {
      //   setServiceFee(formatAmount((widgetDetails.serviceFee / 100), currencyType));
      // }
      if (widgetDetails.payButtonLabel === 'Pay') {
        setPay(false);
      } else if (widgetDetails.payButtonLabel === 'Donate') {
        setPay(true);
      }
      const list = [...widgetDetails.amounts];
      for (let i = 0; i < list.length; i += 1) {
        if (list[i]) {
          list[i] = formatAmount((list[i] / 100), currencyType);
        }
      }
      setAmountList(list);
    }
  }, [widgetDetails]);

  const handleRemoveClick = (index) => {
    const list = [...amountList];
    list.splice(index, 1);
    widgetDetails.amounts.splice(index, 1);
    setAmountList(list);
    setSampleWidget(setSampleWidget);
    simpleValidator.current.purgeFields();
  };

  const handleAddClick = () => {
    setAmountList([...amountList, null]);
    widgetDetails.amounts.push(null);
    setSampleWidget(setSampleWidget);
  };

  const updateMinAmount = (e) => {
    setMinAmountValid(false);
    const { value } = e.currentTarget;
    if (validationRegex.amountRegex.test(value) || value === '') {
      setMinAmount(value);
    } else if (!validationRegex.amountRegex.test(value)) {
      setMinAmount(0);
    }
  };

  const formatMinAmount = (e) => {
    const { value } = e.currentTarget;
    const amount = parseFloat(value);
    if (Number(amount)) {
      setMinAmount(parseInt(amount, 10).toFixed(2));
      widgetDetails.minAmount = amount * 100;
    }
    setSampleWidget(setSampleWidget);
  };

  const updateMaxAmount = (e) => {
    setMaxAmountValid(false);
    const { value } = e.currentTarget;
    if (validationRegex.amountRegex.test(value) || value === '') {
      setMaxAmount(value);
    } else if (!validationRegex.amountRegex.test(value)) {
      setMaxAmount(0);
    }
  };

  const formatMaxAmount = (e) => {
    const { value } = e.currentTarget;
    const amount = parseFloat(value);
    if (Number(amount)) {
      setMaxAmount(parseInt(amount, 10).toFixed(2));
      widgetDetails.maxAmount = amount * 100;
    }
    setSampleWidget(setSampleWidget);
  };

  // const updateFeeAmount = (e) => {
  //   const { value } = e.currentTarget;
  //   if (validationRegex.amountRegex.test(value) || value === '') {
  //     setServiceFee(value);
  //   } else if (!validationRegex.amountRegex.test(value)) {
  //     setServiceFee(0);
  //   }
  // };

  // const formatFeeAmount = (e) => {
  //   const { value } = e.currentTarget;
  //   const amount = parseFloat(value);
  //   if (Number(amount)) {
  //     setServiceFee(parseInt(amount, 10).toFixed(2));
  //     widgetDetails.serviceFee = amount * 100;
  //   }
  //   setSampleWidget(setSampleWidget);
  // };

  // const updateRateAmount = (e) => {
  //   const { value } = e.currentTarget;
  //   if (validationRegex.amountRegex.test(value) || value === '') {
  //     setServiceFeeRate(value);
  //   } else if (!validationRegex.amountRegex.test(value)) {
  //     setServiceFeeRate(0);
  //   }
  // };

  // const formatRateAmount = (e) => {
  //   const { value } = e.currentTarget;
  //   const amount = parseFloat(value);
  //   if (Number(amount)) {
  //     setServiceFeeRate(parseInt(amount, 10).toFixed(2));
  //     widgetDetails.serviceFeeRate = amount * 100;
  //   }
  //   setSampleWidget(setSampleWidget);
  // };

  const updateTerms = (e) => {
    widgetDetails.termsAndServices = e.currentTarget.value;
    setSampleWidget(setSampleWidget);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const minVlue = Math.min(...amountList);
      const maxValue = Math.max(...amountList);
      if (minVlue < parseFloat(minAmount)) {
        setMinAmountValid(true);
        return false;
      }
      if (maxValue > parseFloat(maxAmount)) {
        setMaxAmountValid(true);
        return false;
      }
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        setFixErrors(true);
        myRef.current.scrollIntoView();
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
  }));

  const handleInputChange = (e, index) => {
    const { value } = e.currentTarget;
    const list = [...amountList];
    if (validationRegex.amountRegex.test(value) || value === '') {
      list[index] = value;
    } else if (!validationRegex.amountRegex.test(value)) {
      list[index] = 0;
    }
    setAmountList(list);
  };

  const formatInputChange = (e, index) => {
    const { value } = e.currentTarget;
    const list = [...amountList];
    const amount = parseFloat(value);
    if (Number(amount)) {
      list[index] = parseInt(amount, 10).toFixed(2);
      widgetDetails.amounts[index] = amount * 100;
    }
    setAmountList(list);
    setSampleWidget(setSampleWidget);
  };

  return (
    <>
      <SpText className="text-uppercase mr-4" fontSize="16px" ref={myRef}>
        Amounts
      </SpText>
      {
        fixErrors && (
          <Row>
            <Col className="ml-3 mb-3">
              <SpError>
                Please fix one or more errors below.
              </SpError>
            </Col>
          </Row>
        )
      }
      <Row type="flex" justify="start" align="middle">
        <Col span={14} className="mr-2" style={{ marginTop: '11px', marginBottom: '5px' }}>
          <SpText fontSize="12px">Amounts</SpText>
        </Col>
      </Row>
      <Row type="flex" justify="start" align="middle">
        {
          amountList.map((item, i) => (
            <Col span={14} style={{ marginTop: '8px' }}>
              <Row type="flex" justify="start" align="middle">
                <Col span={18} className="mr-2">
                  <Input
                    value={item && item}
                    placeholder="0.00"
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onChange={e => handleInputChange(e, i)}
                    onBlur={e => formatInputChange(e, i)}
                  />
                </Col>
                <Col span={4}>
                  {i !== 0 ? (
                    <Icon
                      type="minus-circle"
                      className="ml-2"
                      style={{ cursor: 'pointer', color: '#ff4d50' }}
                      onClick={() => handleRemoveClick(i)}
                    />
                  ) : (
                    <span className="ml-2">&nbsp;&nbsp;&nbsp;</span>
                  )}
                </Col>
              </Row>
              <SpError>
                {simpleValidator.current.message(`amount${i}`, amountList[i], 'required|amount',
                  { messages: { required: 'The amount field is required.' } })}
              </SpError>
            </Col>
          ))
        }
      </Row>
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div className="ml-2" style={{ cursor: 'pointer', marginTop: '29px' }}>
            <SpText
              fontWeight="600"
              color="#3DA8FE"
              onClick={handleAddClick}
              fontSize="14px"
            >
              {'\u002B'}&nbsp; Add Amount
            </SpText>
          </div>
        </Col>
      </Row>
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '24px' }}>
            <label htmlFor="">
              <Checkbox
                className="mr-2"
                checked={customAmount}
                onChange={({ target: { checked } }) => {
                  widgetDetails.showCustomAmount = checked;
                  setSampleWidget(setSampleWidget);
                  setCustomAmount(checked);
                }}
              />
              <SpText fontSize="14px">Disable custom amount</SpText>
            </label>
          </div>
        </Col>
      </Row>
      {/* <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '24px' }}>
            <label htmlFor="">
              <Checkbox
                className="mr-2"
                checked={serviceFeeEnabled}
                onChange={({ target: { checked } }) => {
                  setServiceFeeEnabled(checked);
                  simpleValidator.current.purgeFields();
                  if (checked) {
                    widgetDetails.serviceFeeEnabled = true;
                    setSampleWidget(setSampleWidget);
                  }
                  if (!checked) {
                    widgetDetails.serviceFeeEnabled = false;
                    widgetDetails.serviceFeeRate = null;
                    widgetDetails.serviceFee = null;
                    setServiceFeeRate(null);
                    setServiceFee(null);
                    setSampleWidget(setSampleWidget);
                  }
                }}
              />
              <SpText fontSize="14px">Apply Service Rate & Fees</SpText>
            </label>
          </div>
        </Col>
      </Row> */}
      {/* {
        serviceFeeEnabled && (
          <>
            <Row type="flex" justify="start" align="middle">
              <Col span={14}>
                <div style={{ marginTop: '24px' }}>
                  <div>
                    <label htmlFor=""><SpText fontSize="14px">Service Fee</SpText></label>
                  </div>
                  <Input
                    className="w-25"
                    placeholder="0.00"
                    value={serviceFee || null}
                    onBlur={formatFeeAmount}
                    onChange={updateFeeAmount}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <SpError>
                    {simpleValidator.current.message('service fee', serviceFee, 'required|amount')}
                  </SpError>
                </div>
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={14}>
                <div style={{ marginTop: '24px' }}>
                  <div>
                    <label htmlFor=""><SpText fontSize="14px">Service Fee Rate</SpText></label>
                  </div>
                  <Input
                    className="w-25"
                    placeholder="0.00"
                    value={serviceFeeRate || null}
                    onBlur={formatRateAmount}
                    onChange={updateRateAmount}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <SpError>
                    {simpleValidator.current.message('service rate', serviceFeeRate, 'required|amount')}
                  </SpError>
                </div>
              </Col>
            </Row>
          </>
        )
      } */}

      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '24px' }}>
            <div>
              <label htmlFor=""><SpText fontSize="14px">Minimum Payment Amount</SpText></label>
            </div>
            <Input
              className="w-25"
              placeholder="0.00"
              value={minAmount && minAmount}
              onBlur={formatMinAmount}
              onChange={updateMinAmount}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('min amount', minAmount, 'required|amount',
                { messages: { required: 'The min amount field is required.' } })}
            </SpError>
            <div>
              {
                minAmountvalid && (
                  <SpError>
                    The minimum amount should be lessthan or equal to minimum payable amount.
                  </SpError>
                )
              }
            </div>
          </div>
        </Col>
      </Row>
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '35px' }}>
            <label htmlFor="">
              <Checkbox
                className="mr-2"
                checked={enforceMaxPayment}
                onChange={({ target: { checked } }) => {
                  setEnforceMaxPayment(checked);
                  simpleValidator.current.purgeFields();
                  if (checked) {
                    widgetDetails.enforceMaxPayment = true;
                    setSampleWidget(setSampleWidget);
                  } else if (!checked) {
                    widgetDetails.enforceMaxPayment = false;
                    setMaxAmount(0);
                    widgetDetails.maxAmount = null;
                    setSampleWidget(setSampleWidget);
                  }
                }}
              />
              <SpText fontSize="14px">Enforce max Payment amount</SpText>
            </label>
          </div>
        </Col>
      </Row>
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '12px' }}>
            <div>
              <label htmlFor=""><SpText fontSize="14px">Maximum Payment Amount</SpText></label>
            </div>
            <Input
              className="w-25"
              placeholder="0.00"
              value={maxAmount && maxAmount}
              onBlur={formatMaxAmount}
              onChange={updateMaxAmount}
              disabled={!enforceMaxPayment}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
            />
            <SpError>
              {
                enforceMaxPayment && (
                  <>
                    {simpleValidator.current.message('max amount', maxAmount, 'required|amount',
                      { messages: { required: 'The max amount field is required.' } })}
                  </>
                )
              }
            </SpError>
            <div>
              {
                (enforceMaxPayment && maxAmountvalid) ? (
                  <SpError>
                    The maximum amount should be more than or equal to payable amount.
                  </SpError>
                ) : null
              }
            </div>
          </div>
        </Col>
      </Row>
      <Divider style={{ marginTop: '32px' }} />
      <SpText className="text-uppercase mr-4" fontSize="16px" style={{ marginTop: '35px' }}>
        &quot;PAY&quot; Labels
      </SpText>
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '12px' }}>
            <label htmlFor="">
              <Checkbox
                className="mr-2"
                checked={pay}
                onChange={({ target: { checked } }) => {
                  setPay(checked);
                  if (checked) {
                    setPayLabel('Donate');
                    widgetDetails.payButtonLabel = 'Donate';
                    setSampleWidget(setSampleWidget);
                  } else if (!checked) {
                    setPayLabel('Pay');
                    widgetDetails.payButtonLabel = 'Pay';
                    setSampleWidget(setSampleWidget);
                  }
                }}
              />
              <SpText fontSize="14px">Change &quot;Pay&quot; labels to &quot;Donate&quot;</SpText>
            </label>
          </div>
        </Col>
      </Row>
      <Divider style={{ marginTop: '28px' }} />
      <Row type="flex" justify="start" align="middle">
        <Col span={14}>
          <div style={{ marginTop: '28px', marginBottom: '35px' }}>
            <div>
              <label htmlFor="">
                <SpText fontSize="16px">TERMS OF SERVICES</SpText>
              </label>
            </div>
            <label htmlFor="">
              <SpText fontSize="12px" style={{ marginTop: '9px' }}>(Put your terms and condition info here)</SpText>
            </label>
            <TextArea
              className="resize"
              rows={3}
              value={terms}
              onChange={updateTerms}
              placeholder="Message"
            />
            <SpError>
              {simpleValidator.current.message('terms of services', terms, 'required')}
            </SpError>
          </div>
        </Col>
      </Row>
    </>
  );
};

// $FlowFixMe
export default forwardRef(AmountInfo);
