function currencyFormat(num) {
  return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function currencyFormatINR(num) {
  return num.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
}

export default (value, currency) => {
  if (value === null || value === undefined) return '0';
  const sValue = value.toString().replace(/,/g, '');
  const fVal = parseFloat(sValue).toFixed(currency.toFixed);
  const parts = fVal.split('.');
  let left = currencyFormat(parts[0]);
  if (currency.countryAlpha2 === 'IN') {
    left = currencyFormatINR(parts[0]);
  } else if (currency.countryAlpha2 === 'US') {
    left = currencyFormat(parts[0]);
  }
  return `${left}.${parts.length > 1 ? parts[1] : '00'}`;
};
