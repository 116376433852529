// @flow
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import { validators } from 'utils/validationMessages';
import { isValidPhoneNumber } from 'libphonenumber-js';


const { Option } = Select;

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  submitting: Function,
  selectedCustomer: Object,
  users: Array,
  diningTable: Array,
};

const EditCustomer = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedCustomer,
    users,
    diningTable,
  } = props;

  const [, forceUpdate] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [userName, setUserName] = useState('');
  const [userGid, setUserGid] = useState('');
  const [tableName, setTableName] = useState('');
  const [tableGid, setTableGid] = useState('');
  const gid = selectedCustomer && selectedCustomer.gid;

  useState(() => {
    setTableGid(selectedCustomer.shopTable.gid);
    setTableName(selectedCustomer.shopTable.name);
    setUserGid(selectedCustomer.user ? selectedCustomer.user.gid : '');
    setUserName(selectedCustomer.user ? selectedCustomer.user.fullName : '');
  }, [selectedCustomer]);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const payload = {
      shopTableGid: tableGid,
      userGid,
    };
    submit({
      gid,
      payload,
    });
  };
  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Table"
    >
      <div className="px-4">
        <div className="mb-3">
          <label htmlFor=""><SpH5>Shop</SpH5></label>
          <Input
            defaultValue={selectedCustomer.shopTable.shop.name}
            disabled
          />
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Table</SpH5></label>
          <div>
            <Select
              style={{ width: '100%' }}
              showSearch
              value={tableName || 'Select table'}
              onChange={(value) => {
                setTableGid(value[0]);
                setTableName(value[1]);
              }}
              disabled
            >
              {
                diningTable && diningTable.length !== 0 ? diningTable.map((info) => (
                  <Option
                    key={info.gid}
                    value={[info.gid, info.name]}
                  >
                    {info && info.name}
                  </Option>
                )) : ''
              }
            </Select>
            <SpError>
              {simpleValidator.current.message('table', tableName, 'required')}
            </SpError>
          </div>
        </div>

        <div className="pb-3">
          <label htmlFor=""><SpH5>Staff</SpH5></label>
          <div>
            <Select
              style={{ width: '100%' }}
              showSearch
              value={userName || 'Select staff'}
              onChange={(value) => {
                setUserGid(value[0]);
                setUserName(value[1]);
              }}
            >
              {
                users && users.length !== 0 ? users.map((info) => (
                  <Option
                    key={info.user && info.user.gid}
                    value={[info.user && info.user.gid, info.user && info.user.fullName]}
                  >
                    {info.user && info.user.fullName}
                  </Option>
                )) : ''
              }
            </Select>
            <SpError>
              {simpleValidator.current.message('staff', userName, 'required')}
            </SpError>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditCustomer);
