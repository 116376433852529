// @flow
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  Checkbox,
  DatePicker,
  TimePicker,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';
import formatAmount from 'utils/formatAmount';
import {
  SpH5, SpError, SpButton, SpText,
} from 'components/DesignKit';
import {
  MAX_AMOUNT,
  COUNTRIES,
} from 'appconstants';

const { Option } = Select;
const dateFormat = 'MMM DD, YYYY';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  submitting: boolean,
  selectedAccount: Object,
  allCustomers: Array<Object>,
  fetchCustomer: Function,
};

const AddPromotionalCode = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    allCustomers,
    fetchCustomer,
  } = props;
  const [disableButton, setDisableButton] = useState(false);
  const [, forceUpdate] = useState();
  const [code, setCode] = useState();
  const [minimumAmount, setMinimumAmount] = useState();
  const [minAmount, setMinAmount] = useState(false);
  const [promoExpiry, setPromoExpiry] = useState(false);
  const [promoDate, setPromoDate] = useState();
  const [promoTime, setPromoTime] = useState();
  const [promotionalRedeemed, setPromotionalRedeemed] = useState();
  const [promoRedeemed, setPromoRedeemed] = useState(false);
  const { prefix } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;
  const [customerGid, setCustomerGid] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customer, setCustomer] = useState(false);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      redeemed: {
        message: MAX_AMOUNT.MESSAGE_REDEMEED,
        rule: (val) => (MAX_AMOUNT.LIMIT_INR_MIN <= val),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const promoExpiryDateChange = (date) => {
    const utcDate = moment(date).startOf('day');
    setPromoDate(moment(utcDate).format(MOMENT_FORMAT));
  };

  const promoExpiryTimeChange = (date) => {
    // eslint-disable-next-line
    const expiryTime = moment(date._d).format('HH:mm:ss');
    setPromoTime(expiryTime);
  };

  const selectCustomer = (e) => {
    const gid = e[1];
    const selectedName = e[0];
    setCustomerGid(gid);
    setCustomerName(selectedName);
  };

  const searchCustomer = (value) => {
    fetchCustomer(value);
  };

  const updateCoupon = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    let promoExpiresAt;
    if (promoDate && promoTime) {
      const promoDateTime = promoDate.split('T')[0];
      const promoExpireTime = `${promoDateTime}T${promoTime}`;
      promoExpiresAt = moment.tz(promoExpireTime, selectedAccount.timezone).utc().format(MOMENT_FORMAT);
    }

    submit({
      code,
      minimumAmount,
      promotionalRedeemed,
      promoExpiresAt,
      customerGid,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={updateCoupon} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Promotional Code"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Code</SpH5></label>
          <Input
            placeholder="GET25OFF"
            value={code}
            onChange={(e) => setCode((e.currentTarget.value).toUpperCase())}
          />
          <SpError>
            {simpleValidator.current.message('code', code, 'required')}
          </SpError>
        </div>
        <div className="mb-2">
          <Checkbox
            className="mr-2"
            checked={minAmount}
            onChange={({ target: { checked } }) => {
              setMinAmount(checked);
              simpleValidator.current.purgeFields();
            }}
          />
          <SpText>Require minimum order value</SpText>
        </div>
        <div className="w-50 pb-3">
          <Input
            prefix={prefix}
            placeholder="0.00"
            value={minimumAmount}
            disabled={!minAmount}
            onChange={(e) => {
              const regex = /^\d*\.?\d*$/;
              const { value } = e.currentTarget;
              if (regex.test(value) || value === '') {
                setMinimumAmount(e.currentTarget.value);
              } else if (!regex.test(value)) {
                setMinimumAmount(null);
              }
            }}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
            onBlur={e => {
              if (e.currentTarget.value) {
                const value = formatAmount(e.currentTarget.value, currencyType);
                setMinimumAmount(value);
              }
            }}
          />
          <SpError>
            {minAmount && simpleValidator.current.message('order value', minimumAmount, 'required|amount')}
          </SpError>
        </div>
        <div className="mb-2">
          <Checkbox
            className="mr-2"
            checked={customer}
            onChange={({ target: { checked } }) => {
              setCustomer(checked);
              simpleValidator.current.purgeFields();
            }}
          />
          <SpText>Limit to a specific customer</SpText>
        </div>
        <div className="pb-3">
          <Select
            className="w-100"
            onSelect={(e) => selectCustomer(e)}
            onSearch={searchCustomer}
            suffix="time"
            showSearch
            value={customerName}
            disabled={!customer}
          >
            {
              allCustomers.map(item => (
                <Option
                  key={item.id}
                  value={[
                    item.name,
                    item.gid,
                  ]}
                >
                  {item.name}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {customer && simpleValidator.current.message('customer', customerGid, 'required')}
          </SpError>
        </div>
        <div className="mb-2">
          <Checkbox
            className="mr-2"
            checked={promoExpiry}
            onChange={({ target: { checked } }) => {
              setPromoExpiry(checked);
              simpleValidator.current.purgeFields();
            }}
          />
          <SpText>Add an expiration date for the promotional code</SpText>
        </div>
        <div className="d-flex align-items-center mb-3">
          <div className="w-30">
            <SpText>Redeem by</SpText>
          </div>
          <div className="w-20 ml-2">
            <DatePicker
              format={dateFormat}
              allowClear={false}
              disabled={!promoExpiry}
              disabledDate={current => current && current < moment().subtract(1, 'days')}
              onChange={promoExpiryDateChange}
            />
            <SpError>
              {promoExpiry && simpleValidator.current.message('date', promoDate, 'required')}
            </SpError>
          </div>
          <div className="w-20 ml-2">
            <TimePicker
              disabled={!promoExpiry}
              onChange={promoExpiryTimeChange}
              allowClear={false}
            />
            <SpError>
              {promoExpiry && simpleValidator.current.message('time', promoTime, 'required')}
            </SpError>
          </div>
        </div>
        <div className="mb-2">
          <Checkbox
            className="mr-2"
            checked={promoRedeemed}
            onChange={({ target: { checked } }) => {
              setPromoRedeemed(checked);
              simpleValidator.current.purgeFields();
            }}
          />
          <SpText>Limit the number of times this promotional code can redeemed</SpText>
        </div>
        <div className="w-50">
          <Input
            suffix="time"
            value={promotionalRedeemed}
            disabled={!promoRedeemed}
            onChange={(e) => setPromotionalRedeemed(e.currentTarget.value)}
          />
          <SpError>
            {promoRedeemed && simpleValidator.current.message('redeemed', promotionalRedeemed, 'required|redeemed')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.loading.submitting,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddPromotionalCode);
