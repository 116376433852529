import { PAYMENTSBUTTONDETAILS } from '../actionTypes';

export const fetchPaymentsButtonDetails = (action) => ({
  type: PAYMENTSBUTTONDETAILS.FETCH,
  payload: action.payload,
});

export const fetchPayments = (action) => ({
  type: PAYMENTSBUTTONDETAILS.PAYMENTS_FETCH,
  payload: action.payload,
});

export const DeactivePaymentButton = (action) => ({
  type: PAYMENTSBUTTONDETAILS.DELETE,
  payload: action.payload,
});

export const updatePaymentsButton = (action) => ({
  type: PAYMENTSBUTTONDETAILS.UPDATE,
  payload: action.payload,
});
