import React, { useState, useEffect } from 'react';
import {
  Button,
  Icon,
} from 'antd';
import type { ContextRouter } from 'react-router-dom';

import {
  PAGES,
  TOKENS,
} from 'appconstants';

import { loginError } from 'components/NavIcons';
import styled from 'styled-components';
import account from 'imgs/account.svg';

// $FlowFixMe
const Wrapper = styled.div`
  background-image: url(${account});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
`;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '24px',
  paddingBottom: '24px',
  alignItems: 'center',
  width: '511px',
  minHeight: '520px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

type Props = ContextRouter & {
  history: {
    replace: Function,
  },
};

const LoginError = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    const reqAccountInfo = JSON.parse(localStorage.getItem('accountInfo'));
    if (reqAccountInfo) {
      setName(reqAccountInfo.givenName);
    }
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    localStorage.removeItem(TOKENS.ACCESS_TOKEN);
    localStorage.removeItem('accountInfo');
    props.history.replace(PAGES.LOGIN);
    setLoading(false);
  };

  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      <Wrapper>
        <FormWrapper onSubmit={handleSubmit}>
          <div style={{ maxWidth: '350px' }}>
            <div className="mb-5 text-left">
              <Icon
                style={{
                  float: 'left',
                  lineHeight: '40px',
                  className: 'pb-5',
                }}
                component={loginError}
              />
            </div>
            <p
              className="my-4 text-left"
            >
              &nbsp;
            </p>
            <p
              className="my-4 text-left"
            >
              Hello {name},
            </p>
            <p
              className="my-4 text-left"
            >
              You are not a member of any accounts yet.
            </p>
            <p
              className="my-4 text-left"
            >
              if this is a mistake. please contact your administrator or email us at
              &nbsp;<span style={{ color: '#03a6f1' }}>support@swirepay.com</span>
            </p>
            <div className="LOGIN">
              <div className="d-flex justify-content-center">
                <Button
                  type="primary"
                  shape="round"
                  className="w-50"
                  htmlType="submit"
                  loading={loading}
                  onClick={handleSubmit}
                >
                  Log Out
                </Button>
              </div>
            </div>
          </div>
        </FormWrapper>
      </Wrapper>
    </div>
  );
};

export default LoginError;
