// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Icon,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getFilterOperators from 'utils/getFilterOperators';

import { catalogOrdersActions, filterParamsActions } from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
} from 'appconstants';

import getCatalogOrderBackground from 'utils/getCatalogOrderBackground';
import getCatalogOrderStatus from 'utils/getCatalogOrderStatus';
import getOrderType from 'utils/getOrderType';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import formatAmount from 'utils/formatAmount';
import OrderHistory from './orderHistory';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const CUSTOMERS_FILTERS_TYPES = FILTERS_TYPES.CATALOG_ORDERS;

type Props = {
  catalogOrders: Array<Object>,
  fetchCatalogOrders: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  history: {
    push: Function,
  },
  setFilterData: Function,
  preserveFilters: Object,
};

const Orders = (props: Props) => {
  const {
    selectedAccount,
    catalogOrders,
    loading,
    fetchCatalogOrders,
    totalElements,
    test,
    history,
    setFilterData,
    preserveFilters,
  } = props;

  const [, forceUpdate] = useState();
  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const currencyType = selectedAccount && selectedAccount.currency;
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [filterTypes, setFilterTypes] = useState(CUSTOMERS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [filterCustomers, setFilterCustomers] = useState((preserveFilters && preserveFilters.filterCustomers)
    ? (preserveFilters && preserveFilters.filterCustomers)
    : [{
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterCustomers: [{
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });

  const debouncedfetchCatalogOrders = debounce(fetchCatalogOrders, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedfetchCatalogOrders({ currentPage, sortParams, filterParams });
  };

  const exportData = catalogOrders ? catalogOrders.map((values) => ({
    id: values && values.gid.slice(-6),
    status: values && values.catalogOrderStatus,
    createdAt: moment
      .utc(values && values.createdAt)
      .tz(selectedAccount && selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
    product: values && values.catalog.name,
    qnty: values && values.quantity,
    price: values && values.catalog.price / 100,
    frequency: values && values.billingFrequency,
    type: values && values.catalogOrderType,
  })) : '';

  const exportHeaders = [
    { label: 'ID', key: 'id' },
    { label: 'STATUS', key: 'status' },
    { label: 'DATE', key: 'createdAt' },
    { label: 'PRODUCT', key: 'product' },
    { label: 'QUANTITY', key: 'qnty' },
    { label: 'PRICE', key: 'price' },
    { label: 'FREQUENCY', key: 'frequency' },
    { label: 'ORDER TYPE', key: 'type' },
  ];

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      // inside click
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      // Outside click
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [catalogOrders]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: PAGES.CATALOG_PRODUCTS,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterCustomers];
    if (filterCustomers.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = CUSTOMERS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterCustomers(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterCustomers];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = CUSTOMERS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterCustomers(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterCustomers[index].filterName === 'createdAt') {
      if (filterCustomers[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterCustomers[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterCustomers[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterCustomers];
    list[index].filterValue = selectedValue;
    setFilterCustomers(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterCustomers];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterCustomers[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterCustomers(list);
  };

  const handleAddClick = () => {
    const list = [...filterCustomers];
    setFilterCustomers([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterCustomers([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
    setFilterTypes(CUSTOMERS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.CATALOG_PRODUCTS);
  };

  const applyFilters = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    const customersFilters = filterCustomers.filter((item) => item.checked);
    if (customersFilters[0].filterValue) {
      setCount(customersFilters && customersFilters.length);
      for (let i = 0; i < customersFilters.length; i += 1) {
        if (customersFilters[i].checked) {
          if (customersFilters[i].filterName === 'createdAt') {
            customersFilters[i] = customersFilters[i].filterValue;
          } else if (customersFilters[i].filterName === 'email') {
            const email = encodeURIComponent(customersFilters[i].filterValue);
            customersFilters[i] = `${customersFilters[i].filterName}.${customersFilters[i].filterOperator}=${email}`;
          } else {
            customersFilters[i] = `${customersFilters[i].filterName}.${customersFilters[i].filterOperator}=${customersFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(customersFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterCustomers,
        });
        history.push({
          pathname: PAGES.CATALOG_PRODUCTS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterCustomers,
        });
        history.push({
          pathname: PAGES.CATALOG_PRODUCTS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const orderSummary = (record) => {
    setSelectedRecord(record);
    setOrderHistoryModal(true);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'gid',
      width: '10%',
      align: 'left',
      sorter: false,
      className: 'no-break-word',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (gid) => (
        <span style={{ cursor: 'pointer' }}>
          {gid.slice(-6) || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'catalogOrderStatus',
      width: '12%',
      align: 'left',
      className: 'no-break-word',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (catalogOrderStatus) => (
        <SpStatusTag style={{ backgroundColor: getCatalogOrderBackground(catalogOrderStatus) }}>
          {getCatalogOrderStatus(catalogOrderStatus)}
        </SpStatusTag>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '10%',
      align: 'left',
      sorter: true,
      className: 'no-break-word',
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'PRODUCT',
      dataIndex: 'catalog.name',
      width: '15%',
      align: 'left',
      className: 'no-break-word',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <span style={{ cursor: 'pointer' }}>
          {(record.catalog && record.name) || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'QUANTITY',
      dataIndex: 'quantity',
      width: '11%',
      align: 'left',
      className: 'no-break-word',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (quantity) => (
        <span style={{ cursor: 'pointer' }}>
          {quantity || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'PRICE',
      dataIndex: 'quantity',
      width: '10%',
      align: 'left',
      className: 'no-break-word',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <span style={{ cursor: 'pointer' }}>
          {currencyType.prefix} {formatAmount((record.price / 100), currencyType)}
        </span>
      ),
    },
    {
      title: 'FREQUENCY',
      dataIndex: 'billingFrequency',
      width: '12%',
      align: 'left',
      className: 'no-break-word',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (billingFrequency) => (
        <span style={{ cursor: 'pointer' }}>
          {billingFrequency || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'ORDER TYPE',
      className: 'no-break-word',
      dataIndex: 'catalogOrderType',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (catalogOrderType) => (
        <span style={{ cursor: 'pointer' }}>
          {getOrderType(catalogOrderType) || <>&#8211;</>}
        </span>
      ),
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '10%',
    align: 'left',
    className: 'no-break-word',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="View">
          <Icon
            type="copy"
            className="ml-3"
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={() => orderSummary(record)}
          />
        </Tooltip>
      </span>
    ),
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
            <Col>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Orders">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Orders Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Orders">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-262px' }}
                  >
                    <Filters
                      filtersGrid={filterCustomers}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterCustomers.length < CUSTOMERS_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              {'\u002B'}&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>
      {
        orderHistoryModal && (
          <OrderHistory
            visible={orderHistoryModal}
            selectedRecord={selectedRecord}
            close={() => setOrderHistoryModal(false)}
            selectedAccount={selectedAccount}
          />
        )
      }
      <RBAC
        fallback={(
          <Table
            columns={columns}
            rowKey={(record) => record.gid}
            dataSource={catalogOrders}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
      >
        <Table
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={catalogOrders}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  catalogOrders: state.catalogOrders.content,
  totalElements: state.catalogOrders.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCatalogOrders: param => dispatch(catalogOrdersActions.fetchCatalogOrders({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
