import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  COUPON,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchCoupons = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.COUPONS}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.COUPONS}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.COUPONS}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.COUPONS}${query}${sorting}`);
};

const addPromotionalCode = async (payload) => axiosAuthInstance.post(API_END_POINTS.PROMOTIONAL_CODES, payload);

const postCoupon = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.COUPONS, params);

const fetchCustomers = async (payload) => {
  if (payload) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMERS}?name.LIKE=${payload}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMERS}`);
};

export function* getCoupons(action) {
  try {
    const { data: { entity } } = yield call(fetchCoupons, action.payload);
    yield put({ type: COUPON.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPON.FAILED, payload: e.response.data });
  }
}

export function* getCustomer(action) {
  try {
    const { data: { entity } } = yield call(fetchCustomers, action.payload);
    yield put({ type: COUPON.SUCCESS_CUSTOMERS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPON.FAILED, payload: e.response.data });
  }
}

export function* addCoupons(action) {
  let couponStatus = false;
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postCoupon, action.payload);
    const { data: { entity } } = yield call(fetchCoupons, action.payload);
    yield put({ type: COUPON.SUCCESS, payload: entity });
    couponStatus = true;
    if (action.payload && action.payload.payload) {
      const { payload } = action.payload;
      payload.couponGid = entity.content[0].gid;
      yield call(addPromotionalCode, payload);
      const { data } = yield call(fetchCoupons, action.payload);
      yield put({ type: COUPON.SUCCESS, payload: data.entity });
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.COUPONS.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      if (couponStatus) {
        yield put({
          type: NOTIFICATION.ERROR,
          payload: MESSAGES.COUPONS.ADD_FAILED,
        });
        yield put({ type: LOADING.VALIDATED });
      } else {
        yield put({
          type: NOTIFICATION.ERROR,
          payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
        });
      }
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
