import { SCHEDULE_REPORTS } from '../actionTypes';

export const fetchScheduleReports = (action) => ({
  type: SCHEDULE_REPORTS.FETCH,
  payload: action.payload,
});

export const addScheduleReport = (action) => ({
  type: SCHEDULE_REPORTS.ADD,
  payload: action.payload,
});

export const deleteScheduleReport = (action) => ({
  type: SCHEDULE_REPORTS.DELETE,
  payload: action.payload,
});
