import { CASH_STATUSES } from 'appconstants';

export default value => {
  if (value === 'VOID') {
    return CASH_STATUSES.VOID;
  } if (value === 'PAID') {
    return CASH_STATUSES.PAID;
  } if (value === 'REFUNDED') {
    return CASH_STATUSES.REFUNDED;
  } if (value === 'PARTIALLY_REFUNDED') {
    return CASH_STATUSES.PARTIAL_REFUNDED;
  } return CASH_STATUSES.PAID;
};
