import { SUBSCRIPTION_BUTTONV2 } from '../actionTypes';

export const fetchSubscriptionButtonV2 = (action) => ({
  type: SUBSCRIPTION_BUTTONV2.FETCH,
  payload: action.payload,
});

export const addSubscriptionButtonV2 = (action) => ({
  type: SUBSCRIPTION_BUTTONV2.ADD,
  payload: action.payload,
});
