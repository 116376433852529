// @flow
import React, {
  useRef,
  createRef,
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Steps,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';
import { SpButton } from 'components/DesignKit';
import {
  MAX_AMOUNT,
} from 'appconstants';
import Review from '../../Review';
import Create from '../../Create';
import SelectItem from '../../SelectItem';

const { Step } = Steps;

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  itemsList: Array,
};

const AddOffer = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    itemsList,
  } = props;

  const createOfferRef = createRef();
  const selectItemRef = createRef();
  const ReviewRef = createRef();
  const currencyType = selectedAccount && selectedAccount.currency;
  const [refArray, setRefArray] = useState([createOfferRef, selectItemRef, ReviewRef]);
  const [, forceUpdate] = useState();
  const [current, setCurrent] = useState(0);
  const [code, setCode] = useState(null);
  const [startDate, setStartDate] = useState(false);
  const [expiryDate, setExpiryDate] = useState(false);
  const [valueQuantity, setValueQuantity] = useState(1);
  const [claimType, setClaimType] = useState('QUANTITY');
  const [claimQuantity, setClaimQuantity] = useState(1);
  const [claimPercentageOff, setClaimPercentageOff] = useState(100);
  const [valueItems, setValueItems] = useState([]);
  const [claimableItems, setClaimableItems] = useState([]);

  const createData = {
    code,
    startDate,
    expiryDate,
    valueQuantity,
    claimType,
    claimQuantity,
    claimPercentageOff,
  };

  const selectItemData = {
    code,
    valueQuantity,
    valueItems,
    claimableItems,
  };

  const reviewData = {
    code,
    startDate,
    expiryDate,
    valueQuantity,
    claimType,
    claimQuantity,
    claimPercentageOff,
    valueItems,
    claimableItems,
  };

  const [disableButton, setDisableButton] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      percentage: {
        message: MAX_AMOUNT.MESSAGE_PERCENTAGE,
        rule: (val) => MAX_AMOUNT.LIMIT_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_MAX,
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  simpleValidator.current.purgeFields();

  const prev = () => {
    const validationArray = [];
    setDisableButton(false);
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      const createOffer = refArray[0].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCode(createOffer.code);
      setStartDate(createOffer.startDate);
      setExpiryDate(createOffer.expiryDate);
      setValueQuantity(createOffer.valueQuantity);
      setClaimType(createOffer.claimType);
      setClaimQuantity(createOffer.claimQuantity);
      setClaimPercentageOff(createOffer.claimPercentageOff);
      setCurrent(current + 1);
      close();
    } else if (current === 1) {
      validationArray.push(refArray[1].current.validate('prev'));
      const selectItem = refArray[1].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCode(selectItem.code);
      setValueQuantity(selectItem.valueQuantity);
      setValueItems(selectItem.valueItems);
      setClaimableItems(selectItem.claimableItems);
      setCurrent(current + 1);
      setCurrent(current - 1);
    } else if (current === 2) {
      validationArray.push(refArray[2].current.validate());
      const review = refArray[2].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCode(review.code);
      setValueQuantity(review.valueQuantity);
      setClaimType(review.claimType);
      setClaimQuantity(review.claimQuantity);
      setClaimPercentageOff(review.claimPercentageOff);
      setValueItems(review.valueItems);
      setClaimableItems(review.claimableItems);
      setCurrent(current - 1);
    } else if (current === 3) {
      setCurrent(current - 1);
    }
  };

  const next = async () => {
    const validationArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      const createOffer = refArray[0].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCode(createOffer.code);
      setStartDate(createOffer.startDate);
      setExpiryDate(createOffer.expiryDate);
      setValueQuantity(createOffer.valueQuantity);
      setClaimType(createOffer.claimType);
      setClaimQuantity(createOffer.claimQuantity);
      setClaimPercentageOff(createOffer.claimPercentageOff);
      setCurrent(current + 1);
    } else if (current === 1) {
      validationArray.push(refArray[1].current.validate('next'));
      const selectItem = refArray[1].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setValueItems(selectItem.valueItems);
      setClaimableItems(selectItem.claimableItems);
      setCurrent(current + 1);
    } else if (current === 2) {
      validationArray.push(refArray[2].current.validate());
      setDisableButton(true);
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }

      const payload = {
        code,
        startsAt: startDate && moment.tz(startDate, selectedAccount.timezone).utc().format(MOMENT_FORMAT),
        expiresAt: expiryDate && moment.tz(expiryDate, selectedAccount.timezone).utc().format(MOMENT_FORMAT),
        valueQuantity,
        claimType,
        claimQuantity,
        claimPercentageOff,
        valueItemGids: valueItems,
        claimableItemGids: claimableItems,
      };
      submit(
        payload,
      );
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="520px"
      footer={current === 0
        ? [
          <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
          <SpButton key="2" onClick={next}>Next</SpButton>,
        ]
        : current === 1
          ? [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next}>Next</SpButton>,
          ]
          : [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next} disabled={disableButton}>Add</SpButton>,
          ]}
      title="Add Offer"
    >
      <>
        <div style={{ marginTop: '-8px' }}>
          <Steps current={current} className="subscriptions">
            <Step key="1" title="Create" />
            <Step key="2" title="Select Item" />
            <Step key="3" title="Review" />
          </Steps>
        </div>
        <div style={{ backgroundColor: '#F2F4F7' }}>
          <div className="mt-2">
            <div className={submitting ? 'OVERLAY' : ''} />
            {current === 0 && (
              <Create
                ref={refArray[0]}
                selectedAccount={selectedAccount}
                createData={createData}
                currencyType={currencyType}
              />
            )}
            {current === 1 && (
              <SelectItem
                ref={refArray[1]}
                selectedAccount={selectedAccount}
                createData={createData}
                selectItemData={selectItemData}
                itemsList={itemsList}
              />
            )}
            {current === 2 && (
              <Review
                ref={refArray[2]}
                selectedAccount={selectedAccount}
                createData={createData}
                selectItemData={selectItemData}
                reviewData={reviewData}
                itemsList={itemsList}
              />
            )}
          </div>
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddOffer);
