/* eslint-disable */
import React from 'react';

const downloadqr = () => (
    <svg width="25" height="32" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.01919 1.60416H1.60419V4.01916M12.3959 4.01916V1.60416H9.98085M9.98085 12.3958H12.3959V9.98082M3.0771 7.70291V10.9112H6.28544V7.70291H3.0771ZM4.01627 8.64207H5.35794V9.98374H4.01627V8.64207ZM3.0771 3.07707V6.28541H6.28544V3.07707H3.0771ZM4.01627 4.01624H5.35794V5.35791H4.01627V4.01624ZM7.70294 3.07707V6.28541H10.9113V3.07707H7.70294ZM8.6421 4.01624H9.98377V5.35791H8.6421V4.01624ZM9.31294 10.9229C9.71311 10.8474 10.0725 10.6299 10.325 10.3104C10.7263 9.84883 10.9397 9.25345 10.9229 8.64207V7.70291H7.7146V8.64207C7.69846 9.25336 7.91177 9.84853 8.31252 10.3104C8.56209 10.6276 8.91699 10.8448 9.31294 10.9229Z" stroke="black" stroke-width="0.3" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.60419 9.98084V12.3958H4.01919M9.31294 9.98376C9.19836 9.91294 9.09261 9.82874 8.99794 9.73292C8.74639 9.42699 8.61934 9.03751 8.6421 8.64209H9.98377C10.0057 9.03789 9.87762 9.42742 9.62502 9.73292C9.53034 9.82874 9.4246 9.91294 9.31002 9.98376H9.31294Z" stroke="black" stroke-width="0.3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export default downloadqr;