import React, { useState, useRef, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Input,
  Select,
  Card,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import queryString from 'query-string';
import styled from 'styled-components';
import Loading from 'components/Loading';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import ImageUpload from 'components/ImageUpload';

import {
  SpButton,
  SpForm,
  SpError,
  SpText,
} from 'components/DesignKit';
import {
  API_END_POINTS,
  axiosAuthInstance,
  axiosEmptyInstance,
} from 'api';

import {
  accountActions,
  notificationActions,
} from 'store/actions';

import {
  COUNTRY_PHONE_CODE,
  TIME_ZONES,
  PAGES,
  TOKENS,
  MESSAGES,
  COUNTRY_CODES_ACCOUNT,
  ROLES,
} from 'appconstants';

import {
  NOTIFICATION,
} from 'store/actionTypes';

const { Option } = Select;

const StyledDiv = styled.div`
  cursor: pointer;
  &: before {
    font-size: 12px;
    content: "+ Add";
    opacity: 0;
    color: #29b1cc;
    position: absolute; 
    top: 50%;
    left: 50%;
    font-weight: 500;
    border: 1px solid #29b1cc;
    padding: 2px 10px;
    border-radius: 20px;
    background-color: #fff;
    transform: translateX(-50%) translateY(-50%);
 }
 &: hover: before {
  opacity: 1;
 }
`;

type Props = {
  selectedAccount: Object,
  loading: boolean,
  addNewAccount: Function,
  history: {
    push: Function,
  };
  validator: boolean,
  test: boolean,
  setNotification: Function,
  location: {
    pathname: string,
    search: string,
  },
  user: Object,
};

const NewAccount = (props: Props) => {
  const {
    timezone,
  } = props.selectedAccount;
  const {
    selectedAccount,
    loading,
    addNewAccount,
    history,
    validator,
    test,
    setNotification,
    location,
    user,
  } = props;

  const [, forceUpdate] = useState();
  const [userGivenName, setUserGivenName] = useState('');
  const [userFamilyName, setUserFamilyName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountPhone, setAccountPhone] = useState();
  const [accountLargeLogo, setAccountLargeLogo] = useState('');
  const [accountSmallLogo, setAccountSmallLogo] = useState('');
  const [accountLargeLogoFile, setAccountLargeLogoFile] = useState(null);
  const [accountSmallLogoFile, setAccountSmallLogoFile] = useState(null);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [phoneFormate, setPhoneFormate] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [timeZone, setTimeZone] = useState(timezone);
  const [redirect, setRedirect] = useState(false);
  const [notify, setNotify] = useState(false);
  const [countryName, setCountryName] = useState('');
  const [countryCode, setCountryCode] = useState(null);
  const gid = selectedAccount && selectedAccount.gid;
  const parsed = queryString.parse(location.search);
  const referralCode = parsed ? parsed.referral_code : '';
  const affiliateCode = parsed ? parsed.affiliate_code : '';
  const [email, setEmail] = useState(selectedAccount && selectedAccount.contactEmail);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setCountryCode(getCountryAlpha2(selectedAccount.country.id));
      const cntryCode = getCountryPhoneCode(selectedAccount.country.id);
      setPhoneCode(cntryCode);
      setEmail(selectedAccount && selectedAccount.contactEmail);
      if (cntryCode === '+1') {
        setPhoneFormate('(###) ###-####');
      } else if (cntryCode === '+91') {
        setPhoneFormate('##### #####');
      }
    }
  }, []);

  const getAccessTocken = async () => {
    try {
      const { data: { entity: { token: { accessToken } } } } = await axiosAuthInstance.get(`${API_END_POINTS.JWE}/${gid}/switch?isTest=${test}`);
      localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
      localStorage.removeItem(TOKENS.REFERRAL_CODE);
      if (user.internalRole && user.internalRole.name) {
        if (user.internalRole && user.internalRole.name === ROLES.SUPERUSER) {
          history.push(PAGES.DASHBOARD);
        } else {
          history.push(PAGES.PAYMENTS);
        }
      } else {
        history.push(PAGES.DASHBOARD);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  };

  useEffect(() => {
    if (!validator && redirect) {
      getAccessTocken();
    }
  }, [validator]);

  const onCountryCodeChange = (value) => {
    if (value === '+1') {
      setPhoneFormate('(###) ###-####');
      setPhoneCode(value);
    } else if (value === '+91') {
      setPhoneFormate('##### #####');
      setPhoneCode(value);
    } else {
      setPhoneFormate('##########');
      setPhoneCode(value);
    }
  };

  const selectCountry = (e) => {
    setCountryName(e[0]);
    setCountryCode(getCountryAlpha2(e[2]));
    if (e[1] === '+1') {
      setPhoneFormate('(###) ###-####');
      setPhoneCode(e[1]);
    } else if (e[1] === '+91') {
      setPhoneFormate('##### #####');
      setPhoneCode(e[1]);
    } else {
      setPhoneFormate('##########');
      setPhoneCode(e[1]);
    }
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setAccountPhone(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const uploadLogo = async (file, type) => {
    const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPDATE_LOGO}/${type}`);
    await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
    const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.UPDATE_LOGO}/${type}`, { fileUrl: entity });
    return fileUrl;
  };

  const updateAccount = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    let largeLogoUrl; let smallLogoUrl;
    if (accountLargeLogoFile) {
      largeLogoUrl = await uploadLogo(accountLargeLogoFile, 'large');
    }
    if (accountSmallLogoFile) {
      smallLogoUrl = await uploadLogo(accountSmallLogoFile, 'small');
    }

    const params = {
      name: accountName,
      countryCode,
      contactEmail: email,
      contactFamilyName: userFamilyName,
      contactGivenName: userGivenName,
      contactNumber: `${phoneCode}${accountPhone}`,
      largeLogo: largeLogoUrl,
      smallLogo: smallLogoUrl,
      timezone: timeZone,
      shouldNotify: notify,
      referralCode,
      affiliateCode,
    };

    setRedirect(true);
    addNewAccount({
      params,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Account Information
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Name</span>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Account Name"
              value={accountName}
              onChange={e => setAccountName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Account Name', accountName, 'required')}
            </SpError>
          </Col>
        </Row>
      </SpForm>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Given Name</span>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Given Name"
              value={userGivenName}
              onChange={e => setUserGivenName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Given Name', userGivenName, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Family Name</span>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Family Name"
              value={userFamilyName}
              onChange={e => setUserFamilyName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Family Name', userFamilyName, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Email</span>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={email}
              disabled
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Country</span>
          </Col>
          <Col span={6}>
            <Select
              className="w-100"
              onSelect={(e) => selectCountry(e)}
              suffix="time"
              showSearch
              value={countryName || 'Select Country'}
            >
              {
                COUNTRY_CODES_ACCOUNT.map(item => (
                  <Option
                    key={item.ID}
                    value={[
                      item.NAME,
                      item.PHONE_CODE,
                      item.ID,
                    ]}
                  >
                    {item.NAME}
                  </Option>
                ))
              }
            </Select>
            <SpError>
              {simpleValidator.current.message('Country', countryName, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Phone</span>
          </Col>
          <Col span={6}>
            <div className="d-flex">
              <div style={{ width: '30%', height: '40px' }}>
                <Select
                  className="w-100"
                  value={phoneCode}
                  onChange={onCountryCodeChange}
                  showSearch
                >
                  {COUNTRY_PHONE_CODE.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ width: '70%' }}>
                <NumberFormat
                  className="InputnumFormate"
                  value={accountPhone}
                  format={phoneFormate}
                  mask="_"
                  allowEmptyFormatting
                  onChange={onPhoneNumberChange}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('Phone Number', accountPhone, 'required|phone')}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Branding Images
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" gutter={[32, 16]}>
          <Col>
            <div>
              <div style={{ fontSize: '12px' }}>Company Logo</div>
              <label>
                <StyledDiv>
                  <ImageUpload
                    name="Company Logo"
                    accept="image/*"
                    endPoint={`${API_END_POINTS.UPDATE_LOGO}/large`}
                    url={accountLargeLogo}
                    setUrl={value => setAccountLargeLogo(value)}
                    setFiles={value => setAccountLargeLogoFile(value)}
                  />
                </StyledDiv>
              </label>
            </div>
          </Col>
          <Col>
            <div>
              <div style={{ fontSize: '12px' }}>Company Icon</div>
              <label>
                <StyledDiv>
                  <ImageUpload
                    name="Company Icon"
                    accept="image/*"
                    endPoint={`${API_END_POINTS.UPDATE_LOGO}/small`}
                    url={accountSmallLogo}
                    setUrl={value => setAccountSmallLogo(value)}
                    setFiles={value => setAccountSmallLogoFile(value)}
                  />
                </StyledDiv>
              </label>
            </div>
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Timezone
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row>
          <Col span={3}>
            <span>Timezone</span>
          </Col>
          <Col span={6}>
            <Select
              showSearch
              value={timeZone}
              placeholder="Please select"
              className="w-100"
              onChange={value => setTimeZone(value)}
            >
              {
                TIME_ZONES.map((item) => (
                  <Option key={item} value={item}>
                    {item.replace(/_/g, ' ')}
                  </Option>
                ))
              }
            </Select>
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Customer Emails
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row>
          <Col span={3}>
            <span>Email Customers</span>
          </Col>
          <Col span={6}>
            <Switch
              className="mx-3"
              defaultChecked={notify}
              onChange={val => setNotify(val)}
            /> Successful Payments
          </Col>
        </Row>
      </SpForm>
      {
        referralCode && (
          <SpForm>
            <Row justify="start" align="middle">
              <Col span={6}>
                <div className="mb-2">
                  <SpText fontSize="14px">
                    Referral Code
                  </SpText>
                </div>
                <Input
                  value={referralCode}
                  readOnly
                />
              </Col>
            </Row>
          </SpForm>
        )
      }
      {
        affiliateCode && (
          <SpForm>
            <Row justify="start" align="middle">
              <Col span={6}>
                <div className="mb-2">
                  <SpText fontSize="14px">
                    Affiliate Code
                  </SpText>
                </div>
                <Input
                  value={affiliateCode}
                  readOnly
                />
              </Col>
            </Row>
          </SpForm>
        )
      }
      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={updateAccount}
          >
            Save
          </SpButton>
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = state => ({
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  loading: state.loading.loading,
  validator: state.loading.validator,
  test: state.account.test,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  addNewAccount: params => dispatch(accountActions.addNewAccount({
    payload: params,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewAccount);
