import { EVENTSDETAILS } from '../actionTypes';

export const fetchEventDetails = (action) => ({
  type: EVENTSDETAILS.FETCH,
  payload: action.payload,
});

export const fetchCardDetails = (action) => ({
  type: EVENTSDETAILS.CARDS_FETCH,
  payload: action.payload,
});

export const updateEvent = (action) => ({
  type: EVENTSDETAILS.UPDATE,
  payload: action.payload,
});

export const deactivateEvent = (action) => ({
  type: EVENTSDETAILS.DEACTIVE,
  payload: action.payload,
});

export const soldOutEvent = (action) => ({
  type: EVENTSDETAILS.SOLDOUT,
  payload: action.payload,
});

export const resumeSales = (action) => ({
  type: EVENTSDETAILS.RESUME_SALES,
  payload: action.payload,
});

export const offlinePayments = (action) => ({
  type: EVENTSDETAILS.OFFLINE_PAYMENTS,
  payload: action.payload,
});

export const fetchEventsSlug = (action) => ({
  type: EVENTSDETAILS.EVENTS_SLUG_FETCH,
  payload: action.payload,
});

export const fetchOfflineEventsPayments = (action) => ({
  type: EVENTSDETAILS.FETCH_OFFLINE_PAYMENTS,
  payload: action.payload,
});

export const clearEventDetails = (action) => ({
  type: EVENTSDETAILS.CLEAR_EVENT_DETAILS,
  payload: action.payload,
});

export const notifySeatedPageDetails = (action) => ({
  type: EVENTSDETAILS.PAGE_NOTIFY,
  payload: action.payload,
});

export const notifySeatedPage = (action) => ({
  type: EVENTSDETAILS.SEATED_NOTIFY,
  payload: action.payload,
});
