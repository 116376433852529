import { LOCATIONSDETAILS } from '../actionTypes';

const initialState = {
  locationDetailsStatus: undefined,
  locationDetailsError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATIONSDETAILS.FETCH:
      return {
        ...state,
        locationDetailsStatus: 'STARTED',
      };
    case LOCATIONSDETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        locationDetailsStatus: 'SUCCESS',
      };
    case LOCATIONSDETAILS.FAILED:
      return {
        ...state,
        locationDetailsError: action.payload,
        locationDetailsStatus: 'FAILED',
      };
    case LOCATIONSDETAILS.TERMINALS_SUCCESS:
      return {
        ...state,
        terminalDetails: action.payload,
      };
    default:
      return state;
  }
};
