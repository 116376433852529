import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Row,
  Col,
  Input,
} from 'antd';
import {
  SpText,
  SpForm,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import Loading from 'components/Loading';
import { NOTIFICATION, ACCOUNT } from 'store/actionTypes';
import getAchPaymentBackground from 'utils/getAchPaymentBackground';
import getAchPaymentStatus from 'utils/getAchPaymentStatus';

import {
  achPaymentsActions,
  notificationActions,
  accountActions,
} from 'store/actions';
import Dwolla from 'react-dwolla-iav';

import OwnersList from './components/OwnersList';

type Props = {
  loading: boolean,
  selectedAccount: Object,
  addAchCustomer: Function,
  setNotification: Function,
  fetchAccountById: Function,
  fetchBeneficiars: Function,
  validator: boolean,
  benficiers: Array,
  isSuperUser: boolean,
  submitting: Boolean,
  updateBenficiaryStatus: Function,
};

const AchPayments = (props: Props) => {
  const {
    loading,
    selectedAccount,
    addAchCustomer,
    setNotification,
    fetchAccountById,
    fetchBeneficiars,
    validator,
    benficiers,
    isSuperUser,
    submitting,
    updateBenficiaryStatus,
  } = props;
  const env = process.env.REACT_APP_ENV;
  const dwollaEnv = (env === 'Production') ? 'production' : 'sandbox';
  const [dwollaConfig, setDwollaConfig] = useState();
  const [dowllaLoading, setDowllaLoading] = useState(false);
  const [showDwolla, setShowDwolla] = useState(false);
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const { gid } = selectedAccount;
  const status = (selectedAccount && selectedAccount.dwollaCustomerStatus);
  const dwollaBusinessId = (selectedAccount && selectedAccount.usBusiness && selectedAccount.usBusiness.dwollaBusinessClassificationId);
  const dwollaCustomerId = (selectedAccount && selectedAccount.dwollaCustomer && selectedAccount.dwollaCustomer.gid);
  const fundingSource = (selectedAccount && selectedAccount.fundingSource);
  const bankName = (selectedAccount && selectedAccount.fundingSource
    && selectedAccount.fundingSource.issuerBank && selectedAccount.fundingSource.issuerBank.bankName);
  const debouncedFetchAccountById = debounce(fetchAccountById, 2000, { leading: true });
  const [benficieryStatus, setBenficieryStatus] = useState(false);
  const businessType = (selectedAccount && selectedAccount.usBusiness) ? selectedAccount.usBusiness.type : '';

  const getData = () => {
    debouncedFetchAccountById(gid);
    fetchBeneficiars(gid);
  };

  useEffect(() => {
    getData();
  }, [gid, validator]);

  useEffect(() => {
    if (!submitting) {
      setBtnLoader(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (!submitting && benficieryStatus) {
      getData();
      setBenficieryStatus(true);
    }
  }, [submitting]);

  const addbankIav = async () => {
    setDowllaLoading(true);
    try {
      const { data: { entity } } = await axiosAuthInstance
        .post(`/account/${gid}${API_END_POINTS.DOWLLA_CUSTOMER}/${dwollaCustomerId}/iav-token`);
      const { iavToken } = entity;
      setShowDwolla(true);
      setLoader(true);
      setDwollaConfig({
        backButton: false,
        customerToken: iavToken,
        environment: dwollaEnv,
        fallbackToMicroDeposits: false,
        microDeposits: false,
        stylesheets: [],
        subscriber: () => {
          setLoader(false);
        },
      });
    } catch (error) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: error.response.data && error.response.data.message,
      });
    } finally {
      setDowllaLoading(false);
    }
  };

  const fetchDowlaFundingSource = async (data) => {
    try {
      const payload = {
        referenceId: data,
      };
      const { data: { entity } } = await axiosAuthInstance
        .post(`/account/${gid}${API_END_POINTS.DOWLLA_CUSTOMER}/${dwollaCustomerId}/funding-source`, payload);
      if (entity) {
        fetchAccountById(gid);
      }
    } catch (error) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: error.response.data && error.response.data.message,
      });
    } finally {
      setShowDwolla(false);
    }
  };

  const onSuccess = (data) => {
    fetchDowlaFundingSource(data);
  };
  const onError = () => {
    setShowDwolla(false);
  };

  const activeCustomer = () => {
    setBtnLoader(true);
    const payload = {
      gid,
      businessType,
    };
    addAchCustomer(payload);
  };

  const updateStatus = async (benficiery: Object) => {
    const params = {
      verified: benficiery.value,
    };
    const benficieryId = benficiery.benficieryOwner;
    updateBenficiaryStatus({
      params,
      benficieryId,
      gid,
    });
    setBenficieryStatus(true);
  };

  if (loading || dowllaLoading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row type="flex" justify="space-between">
        <Col>
          <SpText className="mr-4" fontSize="20px">ACH Payments</SpText>
          <SpStatusTag style={{ backgroundColor: getAchPaymentBackground(status) }}>
            {getAchPaymentStatus(status)}
          </SpStatusTag>
        </Col>
      </Row>
      <Row className="mb-3">
        {fundingSource ? (
          <>
            {
              benficiers.map((data, index) => (
                <OwnersList
                  key={index}
                  ownersData={data}
                  getUpdatedData={getData}
                  accountGid={gid}
                  isSuperUser={isSuperUser}
                  benficiery={benficiers[index]}
                  updateStatus={updateStatus}
                />
              ))
            }
            <SpForm>
              <Row className="mr-4 mt-4">
                <Col>Bank Account</Col>
                <Col className="mt-2" span={6}>
                  <Input
                    value={bankName}
                    disabled
                  />
                </Col>
              </Row>
            </SpForm>
          </>
        ) : showDwolla ? (
          <div className="mt-4">
            {loader ? <div style={{ marginLeft: '150px' }} className="spinner-border text-muted mr-4"> </div> : null}
            <Dwolla
              onSuccess={onSuccess}
              onError={onError}
              dwollaConfig={dwollaConfig}
            />
          </div>
        ) : dwollaCustomerId ? (
          <>
            <Col className="mt-4">
              <SpText className="mr-4" fontSize="20px">Verify Bank Account</SpText>
            </Col>
            <Col className="mt-4">
              <SpButton
                type="secondary"
                shape="round"
                className="mr-4"
                ghost
                onClick={addbankIav}
              >
                {'\u002B'}&nbsp;US Bank Account
              </SpButton>
            </Col>
            <Col className="mt-4">
              <SpText className="mr-4" fontSize="14px" fontWeight="600">Before enabling ACH payments, we must first verify your bank account.</SpText>
            </Col>
          </>
        ) : (
          <>
            {
              dwollaBusinessId ? (
                <Col className="mt-4">
                  <SpButton
                    type="primary"
                    shape="round"
                    onClick={activeCustomer}
                    loading={btnLoader}
                  >
                    Activate
                  </SpButton>
                </Col>
              ) : (
                <>
                  <Col className="mt-4">
                    <SpButton
                      type="primary"
                      shape="round"
                      disabled
                    >
                      Activate
                    </SpButton>
                  </Col>
                  <Col className="mt-4">
                    <SpText className="mr-4" fontSize="14px" fontWeight="600">
                      Please complete setup using the business tab to enabled ACH payments
                    </SpText>
                  </Col>
                </>
              )
            }
          </>
        )}
      </Row>
    </Card>
  );
};

const mapStateToProps = state => ({
  loading: state.loading.loading,
  validator: state.loading.validator,
  selectedAccount: state.account.selectedAccount,
  benficiers: state.achPayments.benficiers,
  isSuperUser: state.user.superUser,
  submitting: state.loading.submitting,
});

const mapDispatchToProps = dispatch => ({
  addAchCustomer: param => dispatch(achPaymentsActions.addAchCustomer({
    payload: param,
  })),
  fetchBeneficiars: param => dispatch(achPaymentsActions.fetchBeneficiars({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchAccountById: gid => dispatch(accountActions.fetchAccountById({
    type: ACCOUNT.FETCH_BY_ID,
    payload: gid,
  })),
  updateBenficiaryStatus: param => dispatch(achPaymentsActions.updateBenficiaryStatus({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AchPayments);
