import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  INVOICEDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInvoiceDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.INVOICE_API}/${gid}`);

const patchInvoice = async ({ gid, params, endPoint }) => axiosAuthInstance.patch(`${API_END_POINTS.INVOICE_API}/${gid}/${endPoint}`, params);

const getCustomersList = async (payload) => {
  if (payload) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMERS}?page=1&size=100&name.LIKE=${payload}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMERS}?page=1&size=100`);
};

const postNewInvoice = async (payload) => axiosAuthInstance.post(`${API_END_POINTS.INVOICE_API}`, payload);

const postCustomer = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.ADD_CUSTOMERS, params);

const deleteInvoices = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.INVOICE_API}/${gid}/cancel`, params);

const getProductsList = async (payload) => {
  if (payload) {
    return axiosAuthInstance.get(`${API_END_POINTS.PRODUCT_API}?name.LIKE=${payload}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.PRODUCT_API}`);
};

const postProduct = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.PRODUCT_API, params);

const getCouponsList = async (payload) => {
  if (payload) {
    return axiosAuthInstance.get(`${API_END_POINTS.COUPONS}?name.LIKE=${payload}&isActive.EQ=true`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.COUPONS}?isActive.EQ=true`);
};

const getItemCouponsList = async (payload) => {
  if (payload) {
    return axiosAuthInstance.get(`${API_END_POINTS.COUPONS}?name.LIKE=${payload}&isActive.EQ=true&validity.EQ=FOREVER&maximumRedemption.NULL=true`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.COUPONS}?isActive.EQ=true&validity.EQ=FOREVER&maximumRedemption.NULL=true`);
};

const addPromotionalCode = async (payload) => axiosAuthInstance.post(API_END_POINTS.PROMOTIONAL_CODES, payload);

const postCoupon = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.COUPONS, params);

const getTaxRateList = async (payload) => {
  if (payload) {
    return axiosAuthInstance.get(`${API_END_POINTS.TAXRATES}?displayName.LIKE=${payload}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.TAXRATES}`);
};

const postTaxrate = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.TAXRATES, params);

const fetchTaxes = async (payload) => axiosAuthInstance.get(`${API_END_POINTS.TAXES}?type.LIKE=${payload}`);

const getNotify = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.INVOICE_API}/${gid}/notify`, params);

const refundInvoice = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.INVOICE_API}/${gid}/refund`, params);

export function* getInvoiceDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchInvoiceDetails, action.payload);
    yield put({ type: INVOICEDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVOICEDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateInvoice(action) {
  const { endPoint } = action.payload;
  const { gid } = action.payload;
  const params = {
    type: 'ALL',
  };
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInvoice, action.payload);
    const { data: { entity } } = yield call(fetchInvoiceDetails, action.payload.gid);
    yield put({ type: INVOICEDETAILS.SUCCESS, payload: entity });
    if (endPoint === 'active') {
      const payload = {
        gid,
        params,
      };
      yield call(getNotify, payload);
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* fetchCustomersList(action) {
  try {
    const { data: { entity } } = yield call(getCustomersList, action.payload);
    yield put({ type: INVOICEDETAILS.CUSTOMERS_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVOICEDETAILS.CUSTOMERS_FAILED, payload: e.response.data });
  }
}

export function* addNewInvoice(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(postNewInvoice, action.payload.params);
    if (action.payload.params.status === 'ACTIVE') {
      const params = {
        type: 'ALL',
      };
      const payload = {
        gid: entity.gid,
        params,
      };
      yield call(getNotify, payload);
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addCustomer(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(postCustomer, action.payload);
    yield put({ type: INVOICEDETAILS.CUSTOMER_ADD_SUCCESS, payload: entity });
    const { data } = yield call(getCustomersList);
    yield put({ type: INVOICEDETAILS.CUSTOMERS_SUCCESS, payload: data.entity.content });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOMERS.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteInvoice(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteInvoices, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* cancelInvoices(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteInvoices, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.CANCEL_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* fetchProductsList(action) {
  try {
    const { data: { entity } } = yield call(getProductsList, action.payload);
    yield put({ type: INVOICEDETAILS.PRODUCTS_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVOICEDETAILS.CUSTOMERS_FAILED, payload: e.response.data });
  }
}

export function* addNewProcut(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(postProduct, action.payload);
    yield put({ type: INVOICEDETAILS.PRODUCT_ADD_SUCCESS, payload: entity });
    const { data } = yield call(getProductsList);
    yield put({ type: INVOICEDETAILS.PRODUCTS_SUCCESS, payload: data.entity.content });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PRODUCT.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* fetchCouponsList(action) {
  try {
    const { data: { entity } } = yield call(getCouponsList, action.payload);
    yield put({ type: INVOICEDETAILS.COUPON_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVOICEDETAILS.CUSTOMERS_FAILED, payload: e.response.data });
  }
}

export function* fetchItemCouponsList(action) {
  try {
    const { data: { entity } } = yield call(getItemCouponsList, action.payload);
    yield put({ type: INVOICEDETAILS.ITEMCOUPON_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVOICEDETAILS.CUSTOMERS_FAILED, payload: e.response.data });
  }
}

export function* addNewCoupon(action) {
  let couponStatus = false;
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(postCoupon, action.payload);
    yield put({ type: INVOICEDETAILS.COUPON_ADD_SUCCESS, payload: entity });
    couponStatus = true;
    if (action.payload && action.payload.payload) {
      const { payload } = action.payload;
      payload.couponGid = entity.gid;
      yield call(addPromotionalCode, payload);
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.COUPONS.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      if (couponStatus) {
        yield put({
          type: NOTIFICATION.ERROR,
          payload: MESSAGES.COUPONS.ADD_FAILED,
        });
        yield put({ type: LOADING.VALIDATED });
      } else {
        yield put({
          type: NOTIFICATION.ERROR,
          payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
        });
      }
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* fetchTaxrates(action) {
  try {
    const { data: { entity } } = yield call(getTaxRateList, action.payload);
    yield put({ type: INVOICEDETAILS.TAX_RATE_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVOICEDETAILS.CUSTOMERS_FAILED, payload: e.response.data });
  }
}

export function* addTaxrates(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(postTaxrate, action.payload);
    yield put({ type: INVOICEDETAILS.TAX_RATE_ADD_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TAXRATE.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getTax(action) {
  try {
    const { data: { entity } } = yield call(fetchTaxes, action.payload);
    yield put({ type: INVOICEDETAILS.TAXES_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVOICEDETAILS.CUSTOMERS_FAILED, payload: e.response.data });
  }
}

export function* notifyInvoice(action) {
  try {
    yield call(getNotify, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.NOTIFY.SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* refundInvoices(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(refundInvoice, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.REFUND_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
