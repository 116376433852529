import React, {
  useReducer,
  createRef,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
} from 'antd';

import {
  SpButton,
  SpTextMute,
  SpText,
  SpError,
} from 'components/DesignKit';
import {
  businessActions,
} from 'store/actions';

import {
  isVariableNullOrUndefined,
} from 'utils/validationMessages';

import {
  BUSINESS_TYPES,
  COUNTRIES,
  PROPRIETORSHIP_BUSINESS_TYPE,
  PAGES,
  TOKENS,
} from 'appconstants';
import getStateList from 'utils/stateList';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';

import addGeneratePayload from '../../Settings/components/BusinessSettings/helper/addGeneratePayload';

import BusinessDetails from './components/BusinessDetails';
import BusinessBankDetails from './components/BusinessBankDetails';
import BusinessRepresentativeDetails from './components/BusinessRepresentativeDetails';

type Props = {
  businessCategories: Array<Object>,
  business: Object,
  representatives: Array<Object>,
  selectedAccount: Object,
  submitting: boolean,
  selectedBusinessType: Function,
  businessType: string,
  next: Function,
  getBusinessCategory: Function,
  pageData: Object,
  addDraftBusiness: Function,
  history: {
    replace: Function,
  },
};

const MAX_REPS = 4;
const MIN_REPS = 1;

function reducer(state, action) {
  switch (action.type) {
    case 'ADD': {
      const newVisibility = state
        .visibility
        .slice();
      const firstInvisible = newVisibility
        .findIndex(v => !v);
      if (firstInvisible !== -1) {
        newVisibility[firstInvisible] = true;
        const visibleRef = state
          .refArray[firstInvisible + 2];
        window
          .scrollTo({ left: 0, top: visibleRef.current.offsetTop, behavior: 'smooth' });
      }
      return {
        ...state,
        visibility: newVisibility,
        numBusinessRep: state.numBusinessRep + 1,
        disableAdd: state.numBusinessRep + 1 === MAX_REPS,
        showRemove: true,
      };
    }
    case 'BUSINESS_TYPE': {
      // for indian bussiness type except PROPRIETORSHIP need two bussiness representatives
      let { numBusinessRep, showRemove, disableAdd } = state;
      const newVisibility = state
        .visibility
        .slice();
      if (action.payload.isIndia && action.payload.businessType !== PROPRIETORSHIP_BUSINESS_TYPE && state.numBusinessRep < 2) {
        showRemove = false;
        numBusinessRep += 1;
        disableAdd = numBusinessRep + 1 === MAX_REPS;
        const firstInvisible = newVisibility
          .findIndex(v => !v);
        if (firstInvisible !== -1) {
          newVisibility[firstInvisible] = true;
        }
      } else if (action.payload.isIndia && action.payload.businessType === PROPRIETORSHIP_BUSINESS_TYPE && state.numBusinessRep === 2) {
        numBusinessRep -= 1;
        showRemove = false;
        disableAdd = false;
        let index = 0;
        for (let i = 0; i < newVisibility.length; i += 1) {
          if (newVisibility[i]) index = i;
        }
        newVisibility[index] = false;
      } else if (action.payload.isIndia && action.payload.businessType !== PROPRIETORSHIP_BUSINESS_TYPE && state.numBusinessRep === 2) {
        showRemove = false;
        disableAdd = false;
      }

      return {
        ...state,
        visibility: newVisibility,
        numBusinessRep,
        disableAdd,
        showRemove,
      };
    }
    case 'REMOVE': {
      let { showRemove } = state;
      showRemove = state.numBusinessRep - 1 > MIN_REPS;
      if (action.payload.isIndia && action.payload.businessType !== PROPRIETORSHIP_BUSINESS_TYPE && state.numBusinessRep === 3) {
        showRemove = false;
      }
      const newVisibility = state
        .visibility
        .slice();
      newVisibility[action.payload.id] = false;
      return {
        ...state,
        visibility: newVisibility,
        refArray: [
          ...state.refArray.slice(0, action.payload.id + 2),
          createRef(),
          ...state.refArray.slice(action.payload.id + 2 + 1),
        ],
        numBusinessRep: state.numBusinessRep - 1,
        disableAdd: false,
        showRemove,
      };
    }
    default:
      return state;
  }
}

const initialState = {
  refArray: [
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ],
  visibility: [
    true,
    false,
    false,
    false,
  ],
  numBusinessRep: 1,
  disableAdd: false,
  showRemove: false,
};

const PageDetails = (props: Props) => {
  const {
    selectedAccount,
    businessCategories,
    business,
    representatives,
    submitting,
    selectedBusinessType,
    businessType,
    next,
    getBusinessCategory,
    pageData,
    addDraftBusiness,
    history,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const [businessTypes, setBusinessTypes] = useState([]);
  const [statesInCountry, setStatesInCountry] = useState([]);
  const [phoneCode, setPhoneCode] = useState('');
  const [fixErrors, setFixErrors] = useState(false);
  const [isIndia, setIsIndia] = useState((selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id);
  const [isUsa, setIsUsa] = useState((selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.USA.id);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    getBusinessCategory();
    if (selectedAccount && selectedAccount.country) {
      setStatesInCountry(getStateList(selectedAccount.country.id));
      setBusinessTypes(BUSINESS_TYPES[selectedAccount.country.id]);
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, []);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setStatesInCountry(getStateList(selectedAccount.country.id));
      setBusinessTypes(BUSINESS_TYPES[selectedAccount.country.id]);
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
      setIsIndia(selectedAccount.country.id === COUNTRIES.INDIA.id);
      setIsUsa(selectedAccount.country.id === COUNTRIES.USA.id);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (businessType) {
      dispatch({
        type: 'BUSINESS_TYPE',
        payload: {
          isIndia,
          businessType,
        },
      });
    }
  }, [businessType]);

  const onRemoveRepresentatives = (id) => {
    dispatch({ type: 'REMOVE', payload: { id, businessType, isIndia } });
  };

  const addBusinessRepresentative = () => {
    dispatch({ type: 'ADD' });
  };

  useEffect(() => {
    if (!submitting && redirect) {
      localStorage.removeItem(TOKENS.ACCESS_TOKEN);
      localStorage.removeItem(TOKENS.REFERRAL_CODE);
      localStorage.removeItem('dynamicStaticQr');
      localStorage.removeItem('fcmToken');
      history.replace(PAGES.LOGIN);
    }
  }, [submitting]);

  const handleDraftSubmit = () => {
    const payloadArray = state
      .refArray
      .filter((_, index) => index < 2 || state.visibility[index - 2]);

    // submit form here
    const payload = addGeneratePayload({
      refArray: payloadArray,
      countryId: selectedAccount.country.id,
    });
    if (selectedAccount.country.id === 1) {
      payload.usBusiness.accountType = payload.usBusiness.accountType ? payload.usBusiness.accountType : null;
      payload.usBusiness.type = payload.usBusiness.type ? payload.usBusiness.type : null;
      payload.usBusiness.whenIsCardCharged = payload.usBusiness.whenIsCardCharged ? payload.usBusiness.whenIsCardCharged : null;
      payload.usBusiness.servicesDeliveredIn = payload.usBusiness.servicesDeliveredIn ? payload.usBusiness.servicesDeliveredIn : null;
      payload.usBusiness.refundPolicy = payload.usBusiness.refundPolicy ? payload.usBusiness.refundPolicy : null;
    }
    addDraftBusiness(selectedAccount.gid, payload);
    setRedirect(true);
  };

  const handleSubmit = () => {
    const validationArray = state
      .refArray
      .filter((_, index) => index < 2 || state.visibility[index - 2])
      .map(arr => arr.current.validate());
    const formValid = validationArray
      .every(elem => elem === true);
    const getRepresentativeInfo = state.refArray.slice(2).map(ref => ref.current.getValues());
    const filterRepresentative = getRepresentativeInfo.filter(obj => (obj.givenName !== '') && (obj.contactEmail !== ''));
    let ownership = 0;
    filterRepresentative.forEach(obj => {
      if (isVariableNullOrUndefined(obj.ownerShipPercentage)) {
        ownership += Number(obj.ownerShipPercentage.replace('%', ''));
      }
    });

    const validateHasControl = filterRepresentative && filterRepresentative.some(obj => obj.hasControl === true);
    if (!formValid) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setFixErrors(true);
      return;
    }
    if (!validateHasControl) {
      message.error('At least one director with control is needed. You should not be able to save a business with everybody saying No.');
      return;
    }
    if (!isIndia) {
      if (ownership > 100) {
        message.error('Cumulative Ownership of all Representatives should not exceed 100%');
        return;
      }
      if (ownership <= 50) {
        message.error('Cumulative Ownership of all Representatives should be atleast 51%');
        return;
      }
    }
    const payloadArray = state
      .refArray
      .filter((_, index) => index < 2 || state.visibility[index - 2]);

    // submit form here
    const payload = addGeneratePayload({
      refArray: payloadArray,
      countryId: selectedAccount.country.id,
    });
    next(payload);
  };

  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <>
      <Row type="flex" justify="space-between" className="mt-2 my-2 mb-3">
        <Col className="d-flex align-items-center">
          <SpText className="mr-4" fontSize="20px" fontWeight="600">Business Information</SpText>
        </Col>
      </Row>
      {
        fixErrors && (
          <Row>
            <Col className="ml-3 mb-3">
              <SpError>
                Please fix one or more errors below.
              </SpError>
            </Col>
          </Row>
        )
      }
      <BusinessDetails
        ref={state.refArray[0]}
        businessTypes={businessTypes}
        businessCategories={businessCategories}
        business={business}
        businessRepresentatives={representatives}
        statesInCountry={statesInCountry}
        country={selectedAccount.country.name || 'India'}
        isIndia={isIndia}
        selectedAccount={selectedAccount}
        selectedBusinessType={selectedBusinessType}
        pageData={pageData}
      />
      <BusinessBankDetails
        ref={state.refArray[1]}
        isIndia={isIndia}
        business={business}
        businessRepresentatives={representatives}
        selectedAccount={selectedAccount}
        pageData={pageData}
      />
      {
        state
          .refArray
          .filter((_, index) => index > 1)
          .map((ref, index) => state.refArray[0].current && (
            <BusinessRepresentativeDetails
              key={index}
              ref={ref}
              statesInCountry={statesInCountry}
              country={selectedAccount.country.name}
              countryId={selectedAccount.country.id}
              business={business}
              businessRepresentatives={representatives}
              onRemoveRepresentatives={onRemoveRepresentatives}
              phoneCode={phoneCode}
              id={index}
              show={state.showRemove}
              visible={state.visibility[index]}
              businessRef={state.refArray[0]}
              isIndia={isIndia}
              isUsa={isUsa}
              selectedAccount={selectedAccount}
              pageData={pageData}
            />
          ))
      }
      <div className="d-flex justify-content-between ml-3">
        <div>
          <SpButton
            type="secondary"
            shape="round"
            icon="plus"
            className="mr-4"
            disabled={state.disableAdd}
            onClick={addBusinessRepresentative}
          >
            Add Another Representative
          </SpButton>
        </div>
      </div>
      <Row>
        <Col className="ml-3 mt-2">
          <SpTextMute>
            Add any individual who owns 25% or more of the company.
          </SpTextMute>
        </Col>
      </Row>
      <Row>
        <div className="d-flex justify-content-between ml-3 mt-3">
          <div>
            <SpButton
              push={18}
              type="secondary"
              shape="round"
              disabled={submitting}
              onClick={handleDraftSubmit}
            >
              Save as Draft
            </SpButton>
          </div>
          <SpButton
            push={18}
            type="primary"
            shape="round"
            disabled={submitting}
            onClick={handleSubmit}
          >
            Create
          </SpButton>
        </div>
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  selectedAccount: state.account.accounts,
  business: state.business.business,
  representatives: state.business.businessRepresentatives,
  businessCategories: state.business.businessCategories,
  loading: state.loading.loading,
  submitting: state.loading.submitting,
  businessType: state.business.businessType,
});

const mapDispatchToProps = dispatch => ({
  selectedBusinessType: param => dispatch(businessActions.selectedBusinessType({
    payload: param,
  })),
  addDraftBusiness: (gid, param) => dispatch(businessActions.addDraftBusiness({
    gid,
    param,
  })),
  getBusinessCategory: param => dispatch(businessActions.getBusinessCategory({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageDetails);
