import { PAYOUTS } from '../actionTypes';

export const fetchPayouts = (action) => ({
  type: PAYOUTS.FETCH,
  payload: action.payload,
});

export const updatePayout = (action) => ({
  type: PAYOUTS.UPDATE,
  payload: action.payload,
});
