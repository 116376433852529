import { REMINDERS } from '../actionTypes';

export const fetchReminders = (action) => ({
  type: REMINDERS.FETCH,
  payload: action.payload,
});

export const sendReminders = action => ({
  type: REMINDERS.UPDATE,
  payload: action.payload,
});
