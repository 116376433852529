import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES, REFUND_STATUS } from 'appconstants';

import {
  CASH_TRANSACTIONS_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchCashTransactionDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_CASH_TRANSACTION}/${gid}`);

const notifyCashTransactionsDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_CASH_TRANSACTION}/${gid}/notify`);

const patchPayment = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_CASH_TRANSACTION}/${gid}`, params);

const patchPaymentDetails = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_CASH_TRANSACTION}/${gid}/refund`, params);

export function* getCashTransactionDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchCashTransactionDetails, action.payload);
    yield put({ type: CASH_TRANSACTIONS_DETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: CASH_TRANSACTIONS_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getNotifyCashTransactionDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(notifyCashTransactionsDetails, action.payload);
    yield put({ type: CASH_TRANSACTIONS_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.NOTIFY.SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: CASH_TRANSACTIONS_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* voidPayment(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchPayment, action.payload);
    const { data: { entity } } = yield call(fetchCashTransactionDetails, action.payload.gid);
    yield put({ type: CASH_TRANSACTIONS_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CASH_TRANSACTIONS.VOID_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* refundCashPayment(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(patchPaymentDetails, action.payload);
    yield put({ type: CASH_TRANSACTIONS_DETAILS.SUCCESS, payload: entity });
    if (REFUND_STATUS.includes(entity.status)) {
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.PAYMENTSDETAILS.REFUND_SUCCESS,
      });
    } else if (!REFUND_STATUS.includes(entity.status)) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.PAYMENTSDETAILS.ERROR,
      });
    }
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
