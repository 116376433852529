import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  WALLETINVOICEDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchWalletInvoiceDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.WALLETINVOICE_API}/${gid}`);

// eslint-disable-next-line max-len
const patchWalletInvoice = async ({ gid, params, endPoint }) => axiosAuthInstance.patch(`${API_END_POINTS.WALLETINVOICE_API}/${gid}/${endPoint}`, params);

const getBeneficiaryList = async (payload) => {
  if (payload) {
    return axiosAuthInstance.get(`${API_END_POINTS.WALLETBENEFICIARY}?page=1&size=100&name.LIKE=${payload}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.WALLETBENEFICIARY}?page=1&size=100`);
};

const postNewWalletInvoice = async (payload) => axiosAuthInstance.post(`${API_END_POINTS.WALLETINVOICE_API}`, payload);

const postWalletBeneficiary = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.WALLETBENEFICIARY, params);

const deleteWalletInvoices = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.WALLETINVOICE_API}/${gid}/cancel`, params);

const getVendorNotify = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.WALLETINVOICE_API}/${gid}/notify`, params);

// eslint-disable-next-line max-len
const patchPayWalletInvoice = async ({ gid }) => axiosAuthInstance.patch(`${API_END_POINTS.WALLETINVOICE_API}/${gid}/pay`);

export function* getWalletInvoiceDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchWalletInvoiceDetails, action.payload);
    yield put({ type: WALLETINVOICEDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: WALLETINVOICEDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateWalletInvoice(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchWalletInvoice, action.payload);
    const { data: { entity } } = yield call(fetchWalletInvoiceDetails, action.payload.gid);
    yield put({ type: WALLETINVOICEDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* fetchWalletBeneficiaryList(action) {
  try {
    const { data: { entity } } = yield call(getBeneficiaryList, action.payload);
    yield put({ type: WALLETINVOICEDETAILS.BENEFICIARY_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: WALLETINVOICEDETAILS.CONTACT_FAILED, payload: e.response.data });
  }
}

export function* addNewWalletInvoice(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(postNewWalletInvoice, action.payload.params);
    yield put({ type: WALLETINVOICEDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addWalletBeneficiary(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(postWalletBeneficiary, action.payload);
    yield put({ type: WALLETINVOICEDETAILS.BENEFICIARY_ADD_SUCCESS, payload: entity });
    const { data } = yield call(getBeneficiaryList);
    yield put({ type: WALLETINVOICEDETAILS.BENEFICIARY_SUCCESS, payload: data.entity.content });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.BENEFICIARY.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteWalletInvoice(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteWalletInvoices, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* cancelWalletInvoices(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteWalletInvoices, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.CANCEL_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* notifyWalletInvoice(action) {
  try {
    yield call(getVendorNotify, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.NOTIFY.SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* payWalletInvoice(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchPayWalletInvoice, action.payload);
    const { data: { entity } } = yield call(fetchWalletInvoiceDetails, action.payload.gid);
    yield put({ type: WALLETINVOICEDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVOICE.PAY_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}
