import { BANK_STATEMENT_DETAILS } from '../actionTypes';

export const fetchAccountReceivables = (action) => ({
  type: BANK_STATEMENT_DETAILS.FETCH,
  payload: action.payload,
});

export const fetchAccountPayables = (action) => ({
  type: BANK_STATEMENT_DETAILS.PAYABLES_FETCH,
  payload: action.payload,
});

export const addBankStatement = (action) => ({
  type: BANK_STATEMENT_DETAILS.ADD,
  payload: action.payload,
});
