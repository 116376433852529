import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  CLOVER_WEBHOOK,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchCloverWebhook = async () => axiosAuthInstance.get(API_END_POINTS.CLOVER_WEBHOOK);

const patchCloverWebhook = async (params) => axiosAuthInstance.patch(`${API_END_POINTS.CLOVER_WEBHOOK}`, params);

const fetchInternalCloverWebhook = async () => axiosAuthInstance.get(API_END_POINTS.INTERNAL_CLOVER_WEBHOOK);

const patchInternalCloverWebhook = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.INTERNAL_CLOVER_WEBHOOK}/${gid}`, params);

const postCloverWebhook = async () => axiosAuthInstance.post(`${API_END_POINTS.CLOVER_IMPORT}`);

export function* getCloverWebhook(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchCloverWebhook, action.payload);
    yield put({ type: CLOVER_WEBHOOK.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: CLOVER_WEBHOOK.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateCloverWebhook(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCloverWebhook, action.payload);
    const { data: { entity } } = yield call(fetchCloverWebhook, action.payload);
    yield put({ type: CLOVER_WEBHOOK.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WEBHOOK.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getInternalCloverWebhook(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchInternalCloverWebhook, action.payload);
    yield put({ type: CLOVER_WEBHOOK.INTERNAL_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: CLOVER_WEBHOOK.INTERNAL_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateInternalCloverWebhook(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInternalCloverWebhook, action.payload);
    const { data: { entity } } = yield call(fetchInternalCloverWebhook, action.payload);
    yield put({ type: CLOVER_WEBHOOK.INTERNAL_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WEBHOOK.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addBulkImportWebHook(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postCloverWebhook, action.payload);
    const { data: { entity } } = yield call(fetchInternalCloverWebhook, action.payload);
    yield put({ type: CLOVER_WEBHOOK.INTERNAL_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.WEBHOOK.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
