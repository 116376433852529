/* eslint-disable react/prop-types */
// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
} from 'antd';

import {
  SpForm,
  SpText,
  SpButton,
  SpH5,
} from 'components/DesignKit';
import {
  walletTransactionDetailsActions,
} from 'store/actions';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import Loading from 'components/Loading';
import {
  API_STATUS,
  PAGES,
} from 'appconstants';
import VLine from 'imgs/VerticalLine.svg';
import swirePayIcon from 'imgs/swirePayIcon.svg';
import ApplyCashBack from './components/ApplyCashBack';

type Props = {
  fetchWalletTransactionDetails: Function,
  applyCashBack: Function,
  walletTransactionDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  test: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  walletTransactionDetailsStatus: string,
}

const TransactionsDetails = (props: Props) => {
  const {
    fetchWalletTransactionDetails,
    walletTransactionDetails,
    loading,
    selectedAccount,
    test,
    applyCashBack,
    submitting,
    validator,
    history,
    walletTransactionDetailsStatus,
  } = props;
  // eslint-disable-next-line react/prop-types
  const { gid } = props.match.params;
  const [applyCashback, setApplyCashBack] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { prefix } = selectedAccount && selectedAccount.currency;
  const { toFixed } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;
  // eslint-disable-next-line max-len
  const walletTransactionGid = walletTransactionDetails && walletTransactionDetails.walletTransactionLog && walletTransactionDetails.walletTransactionLog.gid;

  const getData = () => {
    fetchWalletTransactionDetails(gid);
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, [test, selectedAccount]);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.WALLET_TRANSACTION);
    }
  }, [validator]);

  const setUpcashback = (payload) => {
    const {
      amount,
    } = payload;
    const cashBackamount = amount.replace(/[^\d]/g, '');
    applyCashBack({
      amount: Number(cashBackamount),
      gid: walletTransactionGid,
    });
    setRedirect(true);
  };

  if (loading) {
    return <Loading />;
  }
  const cashBackRedemAmount = walletTransactionDetails.walletTransactionLog;
  const reqAmount = (walletTransactionDetails.amount
    - (walletTransactionDetails.walletTransactionLog && walletTransactionDetails.walletTransactionLog.cashbackRedeemed));
  const finalReedemAmount = (reqAmount / 100).toFixed(toFixed);
  const cashBack = cashBackRedemAmount && cashBackRedemAmount.cashbackRedeemed;
  const finalCashBack = (cashBack / 100).toFixed(toFixed);
  const payMethod = walletTransactionDetails && walletTransactionDetails.walletTransactionLog
    && walletTransactionDetails.walletTransactionLog.paymentSession
    && walletTransactionDetails.walletTransactionLog.paymentSession.paymentMethod;
  const loadMethod = walletTransactionDetails && walletTransactionDetails.walletLoadLog
    && walletTransactionDetails.walletLoadLog.walletCard
    && walletTransactionDetails.walletLoadLog.walletCard.card
    && walletTransactionDetails.walletLoadLog.walletCard.card.bankName;
  const txnId = walletTransactionDetails && walletTransactionDetails.walletTransactionLog;
  const finalTxnId = txnId && (txnId.gid.split('-')[1]).slice(-15).toUpperCase();
  const paymentMethod = payMethod && (payMethod.gid.split('-')[1]).slice(-4).toUpperCase();

  const paidTo = () => {
    const bank = walletTransactionDetails && walletTransactionDetails.walletLoadLog
      && walletTransactionDetails.walletLoadLog.walletBank
      && walletTransactionDetails.walletLoadLog.walletBank.bankName;

    if (walletTransactionDetails && walletTransactionDetails.type === 'TRANSACTION_DEBIT') {
      return (
        <SpText fontSize="13px" fontWeight="600">
          {walletTransactionDetails && walletTransactionDetails.transactingAccount && walletTransactionDetails.transactingAccount.name}
        </SpText>
      );
    }
    if (walletTransactionDetails.type === 'LOAD') {
      if (walletTransactionDetails && walletTransactionDetails.walletLoadLog && walletTransactionDetails.walletLoadLog.walletBank) {
        return (
          <SpText fontSize="13px" fontWeight="600">
            {walletTransactionDetails.walletLoadLog.walletBank.bankName}
          </SpText>
        );
      } if (walletTransactionDetails.walletLoadLog && walletTransactionDetails.walletLoadLog.walletCard.card) {
        return (
          <SpText fontSize="13px" fontWeight="600">
            {walletTransactionDetails.walletLoadLog.walletCard.card.bankName}
          </SpText>
        );
      }

      return (
        <SpText><>&#8211;</></SpText>
      );
    } if (walletTransactionDetails.type === 'PAYOUT') {
      return (
        <SpText fontSize="13px" fontWeight="600">
          {
            bank || <>&#8211;</>
          }
        </SpText>
      );
    }
    if (walletTransactionDetails && walletTransactionDetails.type === 'TRANSACTION_CREDIT') {
      return (
        <SpText fontSize="13px" fontWeight="600">
          {walletTransactionDetails && walletTransactionDetails.transactingAccount && walletTransactionDetails.transactingAccount.name}
        </SpText>
      );
    }
    return (
      <>&#8211;</>
    );
  };


  return (
    <>
      {
        walletTransactionDetails.gid && walletTransactionDetailsStatus === API_STATUS.SUCCESS
        && (
          <>
            <Card className="mb-3">
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={6}>
                    <SpText>
                      {cashBackRedemAmount && cashBackRedemAmount.cashbackRedeemed !== null
                        ? (
                          <SpText fontSize="19px" fontWeight="600" color="#333333">
                            {`${prefix} ${formatNumber(finalReedemAmount, currencyType)}`}
                          </SpText>
                        )
                        : (
                          <SpText fontSize="19px" fontWeight="600" color="#333333">
                            {`${prefix} ${formatNumber((walletTransactionDetails.amount / 100).toFixed(toFixed), currencyType)}`}
                          </SpText>
                        )}
                    </SpText>
                  </Col>
                  {
                    (walletTransactionDetails && walletTransactionDetails.walletTransactionLog
                      && walletTransactionDetails.walletTransactionLog.paymentSession) && (
                      <Col span={2}>
                        <div style={{
                          width: '78px', height: '19px', background: '#EAEBFF', borderRadius: '4px',
                        }}
                        >
                          <SpText className="d-flex" fontSize="12px" fontWeight="600" color="#4C51C2" style={{ marginLeft: '6px' }}>
                            {(walletTransactionDetails && walletTransactionDetails.walletTransactionLog
                              && walletTransactionDetails.walletTransactionLog.paymentSession
                              && walletTransactionDetails.walletTransactionLog.paymentSession.status)
                              || <>&#8211;</>}
                          </SpText>
                        </div>
                      </Col>
                    )
                  }
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle" style={{ marginBottom: '10px' }}>
                  <Col span={6}>
                    <SpText>Date and Time</SpText>
                  </Col>
                  <Col span={9}>
                    <SpText>Payment Method</SpText>
                  </Col>
                  {
                    (walletTransactionDetails.type !== 'LOAD')
                    && (walletTransactionDetails.type !== 'PAYOUT') && (walletTransactionDetails.type !== 'TRANSACTION_CREDIT') && (
                      <Col span={9}>
                        <SpText>
                          {
                            walletTransactionDetails.type === 'TRANSACTION_DEBIT' ? 'Paid to' : ''
                          }
                        </SpText>
                      </Col>
                    )
                  }
                </Row>
                <Row type="flex" justify="start" align="middle">
                  <Col span={5}>
                    <SpText fontSize="13px" fontWeight="600">
                      {walletTransactionDetails && walletTransactionDetails.createdAt
                        ? moment
                          .utc(walletTransactionDetails.createdAt)
                          .tz(selectedAccount.timezone)
                          .format('hh:mm a, on MMM DD, YYYY,').toUpperCase()
                        : <>&#8211;</>}
                    </SpText>
                  </Col>

                  <Col span={1} style={{ bottom: '12px', left: '12px' }}>
                    <img src={VLine} alt="" style={{ width: '36px', height: '35px' }} />
                  </Col>
                  {
                    (payMethod && payMethod.paymentType && ((payMethod.paymentType.name) === 'INSTANT_PAYMENT')) ? (
                      <Col span={8}>
                        <SpText fontSize="13px" fontWeight="600">
                          INSTANT PAYMENT
                        </SpText>
                      </Col>
                    ) : (
                      <Col span={8}>
                        {paymentMethod ? <img src={swirePayIcon} alt="" style={{ width: '20px', height: '20px' }} /> : ''}&nbsp;
                        <SpText fontSize="13px" fontWeight="600">
                          {paymentMethod ? 'XXXXXXXXX'.concat(paymentMethod) : (loadMethod || <>&#8211;</>)}
                        </SpText>
                      </Col>
                    )
                  }
                  {
                    // eslint-disable-next-line max-len
                    (walletTransactionDetails.type !== 'LOAD') && (walletTransactionDetails.type !== 'PAYOUT') && (walletTransactionDetails.type !== 'TRANSACTION_CREDIT') && (
                      <>
                        <Col span={1} style={{ bottom: '12px', left: '12px' }}>
                          <img src={VLine} alt="" style={{ width: '36px', height: '35px' }} />
                        </Col>
                        <Col>
                          <SpText fontSize="13px" fontWeight="600">

                            {
                              walletTransactionDetails.type === 'TRANSACTION_DEBIT' ? paidTo() : ''
                            }
                          </SpText>
                        </Col>
                      </>
                    )
                  }
                </Row>
              </SpForm>
              <>
                <hr />
                <SpForm>
                  <Row type="flex" justify="start">
                    <Col span={6}>
                      <SpText>
                        Amount
                      </SpText>
                    </Col>
                    <Col span={3}>
                      <SpH5 fontSize="13px" fontWeight="600" color="#333333">
                        {`${prefix} ${formatNumber((walletTransactionDetails.amount / 100).toFixed(toFixed), currencyType)}`}
                      </SpH5>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start">
                    <Col span={6}>
                      <SpText>
                        Txn ID
                      </SpText>
                    </Col>
                    <Col span={9}>
                      <SpH5>
                        {finalTxnId || (walletTransactionDetails.walletPayoutLog && walletTransactionDetails.walletPayoutLog.referenceNumber
                          ? walletTransactionDetails.walletPayoutLog.referenceNumber
                          : walletTransactionDetails.walletLoadLog && walletTransactionDetails.walletLoadLog.authorizationId
                            ? walletTransactionDetails.walletLoadLog.authorizationId
                            : <>&#8211;</>)}
                      </SpH5>

                    </Col>
                  </Row>
                  {
                    (walletTransactionDetails.walletTransactionLog
                      && walletTransactionDetails.walletTransactionLog.paymentSession)
                    && (
                      <Row type="flex" justify="start">
                        <Col span={6}>
                          <SpText>
                            Description
                          </SpText>
                        </Col>
                        <Col span={9}>
                          <SpH5>
                            {(walletTransactionDetails.walletTransactionLog && walletTransactionDetails.walletTransactionLog.paymentSession
                              && walletTransactionDetails.walletTransactionLog.paymentSession.description) || <>&#8211;</>}
                          </SpH5>
                        </Col>
                      </Row>
                    )
                  }
                </SpForm>
              </>
              <Row className="d-flex justify-content-end">
                <Col>
                  <SpH5 color="#7f7f7f">{gid.slice(17, 30)}</SpH5>
                </Col>
              </Row>

            </Card>
            {
              (walletTransactionDetails && walletTransactionDetails.type !== 'TRANSACTION_CREDIT') && (
                <Card className="pl-2">
                  <Row type="flex" justify="space-between" className="my-2">
                    <Col>
                      <SpText className="text-uppercase mb-3" fontSize="12px" fontWeight="700" color="#999999">CASHBACK APPLIED</SpText>
                    </Col>
                    <Col>
                      {
                        (walletTransactionDetails && walletTransactionDetails.walletTransactionLog)
                          && (walletTransactionDetails && walletTransactionDetails.type === 'TRANSACTION_DEBIT')
                          ? (cashBackRedemAmount && cashBackRedemAmount.cashbackRedeemed)
                            ? (
                              <SpButton
                                style={{ top: '22px' }}
                                type="primary"
                                shape="round"
                                disabled
                              >
                                Apply Cashback
                              </SpButton>
                            ) : (walletTransactionDetails.walletTransactionLog
                              && walletTransactionDetails.walletTransactionLog.paymentSession
                              && walletTransactionDetails.walletTransactionLog.paymentSession.status === 'REFUNDED')
                              ? (
                                <SpButton
                                  style={{ top: '22px' }}
                                  type="primary"
                                  shape="round"
                                  disabled
                                >
                                  Apply Cashback
                                </SpButton>
                              ) : (
                                <SpButton
                                  style={{ top: '22px' }}
                                  type="primary"
                                  shape="round"
                                  onClick={() => setApplyCashBack(true)}
                                >
                                  Apply Cashback
                                </SpButton>
                              ) : (
                                <SpButton
                                  style={{ top: '22px' }}
                                  type="primary"
                                  shape="round"
                                  disabled
                                >
                                  Apply Cashback
                                </SpButton>
                          )
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <>
                        {cashBackRedemAmount && cashBackRedemAmount.cashbackRedeemed
                          ? (
                            <><SpText color="#333333"> {`${prefix} ${formatNumber(finalCashBack, currencyType)}`}</SpText>&nbsp;&nbsp;
                              <span style={{
                                width: '75px', height: '20px', background: '#C9FFEF', borderRadius: '4px',
                              }}
                              >
                                <SpText fontSize="12px" fontWeight="600" color="#099075">Applied</SpText>
                              </span>
                            </>
                          )
                          : <>{prefix} 0.00</>}
                      </>
                    </Col>
                  </Row>
                  {applyCashback && (
                    <ApplyCashBack
                      visible={applyCashback}
                      submitting={submitting}
                      submit={setUpcashback}
                      close={() => setApplyCashBack(false)}
                    />
                  )}
                </Card>
              )
            }
          </>
        )
      }
    </>
  );
};


const mapStateToProps = (state) => ({
  walletTransactionDetails: state.walletTransactionDetails,
  walletTransactionDetailsStatus: state.walletTransactionDetails.walletTransactionDetailsStatus,
  walletTransactions: state.walletTransaction.content,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWalletTransactionDetails: gid => dispatch(walletTransactionDetailsActions.fetchWalletTransactionDetails({
    payload: gid,
  })),
  applyCashBack: param => dispatch(walletTransactionDetailsActions.applyCashBack({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsDetails);
