// @flow
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {
  Row,
  Col,
  Input,
} from 'antd';

import {
  SpButton,
  SpForm,
  SpText,
  SpError,
} from 'components/DesignKit';

import groupLogo from 'imgs/group.jpg';

import {
  MESSAGES,
} from 'appconstants';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { notificationActions } from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';

const { TextArea } = Input;

type Props = {
  setNotification: Function,
};

const MessageSupport = (props: Props) => {
  const [, forceUpdate] = useState();
  const [emailSubject, setSubject] = useState('');
  const [emailMessage, setMessage] = useState('');
  const [fixErrors, setFixErrors] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator({
    autoForceUpdate: {
      forceUpdate,
    },
  }));

  const handleClick = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setFixErrors(true);
      return;
    }
    setDisableBtn(true);
    const params = {
      subject: emailSubject,
      message: emailMessage,
    };

    try {
      await axiosAuthInstance.post(API_END_POINTS.SUPPORT, params);
      props.setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.SUPPORT.SUCCESS,
      });
      setMessage('');
      setSubject('');
      simpleValidator.current.hideMessages();
    } catch (e) {
      props.setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.SUPPORT.ERROR,
      });
    } finally {
      setFixErrors(false);
      setDisableBtn(false);
    }
  };

  return (
    <>
      <Row className="my-2">
        <Col>
          <SpText color="#7B7B7B" fontSize="18px">MESSAGE</SpText>
        </Col>
      </Row>
      <hr />
      {
        fixErrors && (
          <Row>
            <Col className="ml-3 mb-3">
              <SpError>
                Please fix one or more errors below.
              </SpError>
            </Col>
          </Row>
        )
      }
      <Row type="flex" justify="space-between" className="my-2">
        <Col span={16}>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={6}>
                <SpText>Subject</SpText>
              </Col>
              <Col span={18}>
                <Input
                  value={emailSubject}
                  onChange={(e) => setSubject(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('subject', emailSubject, 'required')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="top">
              <Col span={6}>
                <SpText>Message</SpText>
              </Col>
              <Col span={18}>
                <TextArea
                  rows={10}
                  value={emailMessage}
                  onChange={(e) => setMessage(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('message', emailMessage, 'required')}
                </SpError>
              </Col>
            </Row>
          </SpForm>
        </Col>
        <Col span={8} className="pl-5">
          <img src={groupLogo} alt="group" width="180" />
        </Col>
      </Row>
      <div className="service-btn">
        <SpButton
          type="primary"
          shape="round"
          onClick={handleClick}
          disabled={disableBtn}
          style={{
            width: '339px',
            height: '40px',
            fontSize: '16px',
          }}
        >
          Submit
        </SpButton>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(null, mapDispatchToProps)(MessageSupport);
