export default status => {
  if (status === 'CREATED') {
    return '#C9FFEF';
  } if (status === 'RESERVED') {
    return '#C9FFEF';
  } if (status === 'MOVED') {
    return '#FFEDCC';
  } if (status === 'CANCELLED') {
    return '#FFE9E9';
  } if (status === 'EXPIRED') {
    return '#FFE9E9';
  } return '#ffe0b1';
};
